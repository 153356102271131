import queryString from "query-string";
import { store } from "src";

export const shouldShowDecoupling = () => true;
export const shouldShowDiscrete = () => true;
export const shouldShowRateNegotiation = () => hasQueryParam(rateNegotiationFeatureFlag);
export const shouldShowProgrammaticAnalytics = () => true;
export const shouldShowTierAContentOnly = () => false; // No longer Needed
export const shouldShowTierBContentOnly = () => false; // No longer Needed
export const shouldShowTierCContentOnly = () => false; // No longer Needed
export const shouldShowTierDContentOnly = () => false; // No longer Needed
export const shouldShowTierOGContentOnly = () => false; // No longer Needed
export const shouldShowUpdatePlan = () =>
  hasQueryParam(shouldShowUpdatePlanFlag) || shouldShowTierPerm();
export const shouldShowTierPerm = () => true;
export const shouldShowAnalyticsUpgrade = () => true;

const hasQueryParam = (flag: string) => {
  return queryString.parse(window.location.search)?.[flag] === "1";
};

const rateNegotiationFeatureFlag = "ratenegotiation";
const shouldShowUpdatePlanFlag = "updatePlan";

/**
 * User based flags
 */

/**
 * Only checks if Jocko is not the user
 */
export const shouldShowEarnings = () => {
  const { user } = store.getState().user;

  return user.uuid !== "1697624f-91bb-4a13-8aeb-22927ccd3a91";
};
