export const emptyShows = [
  { name: "Pending", value: "calculating" },
  { name: "Pending", value: "" },
];

export const searchNames = [
  { name: "0-100 Listeners", value: "0-100" },
  { name: "100-1k Listeners", value: "100-1000" },
  { name: "1k-5k Listeners", value: "1000-5000" },
  { name: "5k-20k Listeners", value: "5000-20000" },
  { name: "20k+ Listeners", value: "20000+" },
];

export const displayNames = [...emptyShows, ...searchNames];
