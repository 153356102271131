/*
 * action types
 */

export const SIGN_OUT_REQUEST_START = "SIGN_OUT_REQUEST_START";
export const SIGN_OUT_REQUEST_END = "SIGN_IN_REQUEST_END";

export const UPDATE_ACCOUNT_CLEAR = "UPDATE_ACCOUNT_CLEAR";
export const UPDATE_ACCOUNT_START = "UPDATE_ACCOUNT_START";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_INVALID = "UPDATE_ACCOUNT_INVALID";
export const UPDATE_ACCOUNT_ERROR = "UPDATE_ACCOUNT_ERROR";
export const UPDATE_CURRENT_ROLE = "UPDATE_CURRENT_ROLE";

/*
 * action creators
 */

export function signOutStart() {
  return {
    type: SIGN_OUT_REQUEST_START,
  };
}

export function signOutEnd() {
  return {
    type: SIGN_OUT_REQUEST_END,
  };
}

export function updateAccountClear() {
  return {
    type: UPDATE_ACCOUNT_CLEAR,
  };
}

export function updateAccountStart() {
  return {
    type: UPDATE_ACCOUNT_START,
  };
}

export function updateAccountSuccess(updatedUser) {
  return {
    type: UPDATE_ACCOUNT_SUCCESS,
    user: updatedUser,
  };
}

export function updateAccountInvalid(validationErrors) {
  return {
    type: UPDATE_ACCOUNT_INVALID,
    validationErrors: validationErrors,
  };
}

export function updateAccountError(errorMessage) {
  return {
    type: UPDATE_ACCOUNT_ERROR,
    errorMessage: errorMessage,
  };
}

export function updateCurrentRole(role) {
  return {
    type: UPDATE_CURRENT_ROLE,
    role,
  };
}
