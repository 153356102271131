import get from "lodash/get";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { change, submit } from "redux-form";
import { showMessageWithTimeout, showWarning } from "../../actions/app";
import { distribute } from "../../action_managers/distributions";
import {
  MediaFileActionManager,
  ShowCreateActionManager,
  ShowsForUserActionManager,
  ShowUpdateActionManager,
} from "../../action_managers/shows";
import UploadModalActionManager from "../../action_managers/upload_modal";
import { distributionKeyNames } from "../../constants/distribution";
import messageType from "../../constants/message_types";
import ShowFormModal from "./show_form_modal";

function mapStateToProps(state) {
  var props = {
    user: state.user.user,
    userIsLoading: state.user.isLoading,
    tier: state.user.tier,
    mediaFiles: state.mediaFiles,
    isLoading: state.showForm.isLoading || state.mediaFileUpload.isLoading,
    isSuccess: state.showForm.isSuccess,
    shows: state.shows.shows,
    validationErrors: state.showForm.validationErrors,
    errorMessage: state.showForm.errorMessage,
    imageUploader: state.imageUploader,
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    submitForm: (isEdit) => dispatch(submit(isEdit ? "show-edit" : "show-create")),
    fetchMediaFile: (mediaFileUUID, authToken) => {
      dispatch(new MediaFileActionManager({ mediaFileUUID, authToken }).run());
    },
    setError: (errorMessage) => {
      dispatch(showMessageWithTimeout(errorMessage, messageType.Error));
    },
    createShow: (show, user) => {
      return dispatch(new ShowCreateActionManager({ show, user }).run()).then((response) => {
        if (response.status === 200) {
          var showUUID = get(response, "json.uuid");
          if (showUUID) {
            dispatch(
              distribute(showUUID, [
                distributionKeyNames.googlePodcasts,
                distributionKeyNames.radioPublic,
              ])
            );

            return Promise.resolve(showUUID);
          }
        }
      });
    },
    updateShow: (show, user) => {
      dispatch(new ShowUpdateActionManager({ show, user }).run());
    },
    fileUpload: (file, fileName, user) => {
      return dispatch(
        new UploadModalActionManager({
          file,
          fileName,
          authToken: user.authToken,
        }).run()
      );
    },
    setImageFileUUID: (mode, mediaFileUUID) => {
      return dispatch(change("show-" + mode, "imageMediaFileUUID", mediaFileUUID));
    },
    setImageFileData: (mode, file) => {
      return dispatch(change("show-" + mode, "imageFileData", file));
    },
    fetchShowsForUser: (user) => dispatch(new ShowsForUserActionManager({ user }).run()),
    showWarning: (text) => dispatch(showWarning(text, 3000)),
  };
}

const ShowFormModalWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShowFormModal)
);

export default ShowFormModalWrapper;
