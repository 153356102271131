import { EPISODE_DELETE } from "../actions/episode_delete_modal";
import DefaultActionManager from "./default_action_manager";

export const deleteEpisode = (episodeUUID, showUUID) =>
  new DefaultActionManager({
    route: `episodes/${episodeUUID}`,
    method: "delete",
    auth: true,
    actionName: EPISODE_DELETE,
    actionData: {
      episodeUUID,
      showUUID,
    },
  }).run();
