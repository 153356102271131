import { Select, Spin, Table } from "antd";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import reduce from "lodash/reduce";
import take from "lodash/take";
import moment, { Moment } from "moment-timezone";
import numeral from "numeral";
import Papa from "papaparse";
import React, { FunctionComponent, useEffect, useState } from "react";
import { If } from "react-extras";
import { shallowEqual } from "react-redux";
import BlockFeature from "src/components/lib/block_feature";
import { audioBlockTypes } from "src/constants/audio_management";
import { showError, showInfo } from "../../../../actions/app";
import { getAudioBlocksForUser } from "../../../../action_managers/audio_management";
import { InsertionStatsActionManager } from "../../../../action_managers/stats";
import { useDispatchTS, useSelectorTS } from "../../../../hooks/redux-ts";
import BlockSrcImg from "../../../../images/Block_Programmatic_Insertions_Episodes_NoTop5.png";
import { goToPricingPage } from "../../../../lib/config";
import { IEpisode } from "redcircle-types";
import { downloadFile } from "../../../lib/download_file";
import { svgIcon as Icon } from "../../../lib/icon";
import {
  alignment,
  ALL_AUDIO_BLOCKS,
  ALL_CAMPAIGNS,
  ALL_CUSTOM_AUDIO,
  ALL_PODCASTS,
  DataIsLoadingMessage,
  dateFormatStringDiff,
  getRequestHash,
  InsertionGraphTypes,
  insertionTopEpisodesPrefix,
  middleEllipses,
  paginationConfig,
  RequestResponseDataPoint,
  someProgrammaticEnabled,
  sortNameAlphabetical,
  StatsRequestFilter,
  useInitialDataRequest,
  useTierAnalyticsPerm,
  yAxisLabelFormatter,
} from "../analyticsUtility";
import Column from "../RedCircle_graphs/Column";
import { isUUID } from "src/lib/uuid";
import { useGetCustomAudios } from "src/hooks/custom_audio";

interface CPDataPoint {
  key: string;
  episode: string;
  episodeUUID: string;
  showUUID: string;
  showTitle: string;
  insertions: number;
  publishDate: number;
  color: string | null;
  type: string;
}

const columns = [
  {
    title: "",
    dataIndex: "key",
    className: "analyticsPage-table-colorCell",
    align: alignment[1],
    width: 10,
    render: (key: string, episode: any) => {
      return (
        <div
          style={{
            width: "95%",
            height: "16px",
            minWidth: "95%",
            backgroundColor: `${isEmpty(episode?.color) ? "transparent" : episode?.color}`,
          }}>
          {" "}
        </div>
      );
    },
  },
  {
    title: "Episode Name",
    dataIndex: "episode",
    className: "analyticsPage-table-cell",
    align: alignment[0],
    render: (name: string, episode: any) => {
      return (
        <div className="episodeContainer">
          <div className="showName">{episode.showTitle}</div>
          <div className="episodeName line-clamp-1">{name}</div>
        </div>
      );
    },
  },
  {
    title: "Publish Date",
    dataIndex: "publishDate",
    className: "analyticsPage-table-cell",
    width: 150,
    align: alignment[0],
    render: (date: number) => moment(date * 1000).format("MMM D, YYYY"),
  },
  {
    title: "Insertion Type",
    dataIndex: "type",
    className: "analyticsPage-table-cell",
    width: 150,
    align: alignment[0],
  },
  {
    title: "insertions",
    dataIndex: "insertions",
    className: "analyticsPage-table-cell",
    width: 150,
    align: alignment[2],
    render: (insertions: number) => numeral(insertions).format("0,0[.]00"),
  },
];

const totalColumns = columns.filter((col) => col.dataIndex !== "type");

const cpCustomTooltip = (title: any, data: any) => {
  return `<div class="analyticsPage-GraphTooltip">
    <span class="analyticsPage-GraphTooltip--title">Insertions</span>
    <div class="analyticsPage-GraphTooltip--item"> 
      <span style="background-color:${data?.[0]?.color}"> </span>  
      <span>${data?.[0]?.value}</span>
    </div>
    </div>`;
};

const formatLabels = (title: string) => {
  const newTitle = title?.length > 20 ? title.slice(0, 20) + "..." : title;
  return newTitle;
};

const getGraphTitle = (graphType: (typeof InsertionGraphTypes)[number]) => {
  const titleMap = {
    [InsertionGraphTypes[0]]: "Dynamic Insertions",
    [InsertionGraphTypes[1]]: InsertionGraphTypes[1] + " Insertions",
    [InsertionGraphTypes[2]]: InsertionGraphTypes[2] + " Insertions",
    [InsertionGraphTypes[3]]: InsertionGraphTypes[3] + " Insertions",
    [InsertionGraphTypes[4]]: InsertionGraphTypes[4],
  };

  return `Top Episodes by ${titleMap[graphType]}`;
};

const cpColors = ["#577D9E", "#7997b1", "#9ab1c5", "#cdd8e2", "#eef2f5"];

interface IInsertionEpisodes {
  dateRange: [Moment, Moment];
  timeZone: string;
  selectedShow: string;
  graphType: (typeof InsertionGraphTypes)[number];
}

const InsertionEpisodes: FunctionComponent<IInsertionEpisodes> = ({
  dateRange,
  timeZone,
  selectedShow,
  graphType,
}) => {
  const dispatch = useDispatchTS();
  const user = useSelectorTS((state) => state?.user?.user);
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const tierPermission = useTierAnalyticsPerm();
  const campaigns = useSelectorTS((state) => state?.campaigns?.campaigns);

  const episodesByShow = useSelectorTS((state) => state?.episodesByShow);
  const { audioBlocks } = useSelectorTS((state) => state?.audioBlocks);

  const episodesMap = reduce<typeof episodesByShow, Record<string, IEpisode>>(
    episodesByShow,
    (result, value) => {
      return { ...result, ...value.episodes };
    },
    {}
  );

  const [, , initialDataIsReady] = useInitialDataRequest();
  const { customAudios } = useGetCustomAudios();

  const [rawData, setRawData] = useState<RequestResponseDataPoint["json"]>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const [adsLoading, setAdsLoading] = useState<boolean>(true);

  const cacheID = getRequestHash(insertionTopEpisodesPrefix, selectedShow, dateRange);

  const adsCacheID = getRequestHash(`${insertionTopEpisodesPrefix}-Ads`, selectedShow, dateRange);

  const campaignsCacheID = getRequestHash(
    `${insertionTopEpisodesPrefix}-campaigns`,
    selectedShow,
    dateRange
  );

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cacheID],
    shallowEqual
  );

  const statsCacheAdsData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[adsCacheID],
    shallowEqual
  );

  const statsCacheCampaignData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[campaignsCacheID],
    shallowEqual
  );

  const [dropdownVal, setDropdownVal] = useState<string>(ALL_CAMPAIGNS);
  const [audioBlock, setAudioBlock] = useState<string>(ALL_AUDIO_BLOCKS);
  const [customAudio, setCustomAudio] = useState<string>(ALL_CUSTOM_AUDIO);

  // Aggregates insertions by episodeUUID that are of type cross promotion
  const transformCPData = (data: RequestResponseDataPoint["json"]) => {
    const AllMaps: Record<(typeof InsertionGraphTypes)[number], Record<string, number>> = {
      [InsertionGraphTypes[0]]: {},
      [InsertionGraphTypes[1]]: {},
      [InsertionGraphTypes[2]]: {},
      [InsertionGraphTypes[3]]: {},
      [InsertionGraphTypes[4]]: {},
    };

    data.forEach(
      (item) => {
        const [, type, episodeUUID, isProgrammatic, audioBlockUUID, campaignUUID] =
          item?.pathValues;

        if (type === "ad") {
          if (isProgrammatic === "true") {
            AllMaps[InsertionGraphTypes[2]][episodeUUID] =
              typeof AllMaps[InsertionGraphTypes[2]][episodeUUID] === "number"
                ? AllMaps[InsertionGraphTypes[2]][episodeUUID] + item?.count
                : item.count;

            AllMaps[InsertionGraphTypes[0]][episodeUUID] =
              typeof AllMaps[InsertionGraphTypes[0]][episodeUUID] === "number"
                ? AllMaps[InsertionGraphTypes[0]][episodeUUID] + item?.count
                : item.count;
          } else {
            AllMaps[InsertionGraphTypes[1]][episodeUUID] =
              typeof AllMaps[InsertionGraphTypes[1]][episodeUUID] === "number"
                ? AllMaps[InsertionGraphTypes[1]][episodeUUID] + item?.count
                : item.count;
            AllMaps[InsertionGraphTypes[0]][episodeUUID] =
              typeof AllMaps[InsertionGraphTypes[0]][episodeUUID] === "number"
                ? AllMaps[InsertionGraphTypes[0]][episodeUUID] + item?.count
                : item.count;
          }
        } else if (type === "cross_promo") {
          AllMaps[InsertionGraphTypes[3]][episodeUUID] =
            typeof AllMaps[InsertionGraphTypes[3]][episodeUUID] === "number"
              ? AllMaps[InsertionGraphTypes[3]][episodeUUID] + item?.count
              : item.count;
          AllMaps[InsertionGraphTypes[0]][episodeUUID] =
            typeof AllMaps[InsertionGraphTypes[0]][episodeUUID] === "number"
              ? AllMaps[InsertionGraphTypes[0]][episodeUUID] + item?.count
              : item.count;
        } else if (type === "audio_file" || type === "custom_audio") {
          if (
            (audioBlock === ALL_AUDIO_BLOCKS || audioBlock === audioBlockUUID) &&
            (customAudio === ALL_CUSTOM_AUDIO || customAudio === campaignUUID)
          ) {
            AllMaps[InsertionGraphTypes[4]][episodeUUID] =
              typeof AllMaps[InsertionGraphTypes[4]][episodeUUID] === "number"
                ? AllMaps[InsertionGraphTypes[4]][episodeUUID] + item?.count
                : item.count;
          }
          AllMaps[InsertionGraphTypes[0]][episodeUUID] =
            typeof AllMaps[InsertionGraphTypes[0]][episodeUUID] === "number"
              ? AllMaps[InsertionGraphTypes[0]][episodeUUID] + item?.count
              : item.count;
        }
      },
      {} as Record<string, number>
    );

    let currentCPTableData: CPDataPoint[] = Object.entries(AllMaps[graphType]).reduce(
      (accu, [epUUID, insertions]) => {
        if (typeof episodesMap?.[epUUID] !== "undefined") {
          accu.push({
            key: epUUID,
            episodeUUID: epUUID,
            episode: episodesMap?.[epUUID]?.title as string,
            showUUID: episodesMap?.[epUUID]?.showUUID as string,
            publishDate: episodesMap?.[epUUID]?.publishedAt,
            showTitle: shows?.[episodesMap?.[epUUID]?.showUUID as string]?.title as string,
            insertions: insertions,
            type: graphType,
            color: null,
          });
        }

        return accu;
      },
      [] as CPDataPoint[]
    );

    currentCPTableData =
      graphType === InsertionGraphTypes[1] && dropdownVal !== ALL_CAMPAIGNS
        ? currentCPTableData?.filter(
            ({ episodeUUID }) => campaignsByEp[episodeUUID][dropdownVal]?.length > 0
          )
        : currentCPTableData;

    currentCPTableData = orderBy(currentCPTableData, ["insertions"], ["desc"]);

    cpColors.forEach((color, index) => {
      if (index < currentCPTableData?.length) {
        currentCPTableData[index].color = color;
      }
    });

    const cpGraphData =
      currentCPTableData?.length >= 5 ? currentCPTableData.slice(0, 5) : currentCPTableData;

    const cpColorMap: Record<string, CPDataPoint> = keyBy(cpGraphData, "episode");

    return [cpGraphData, currentCPTableData, cpColorMap] as [
      CPDataPoint[],
      CPDataPoint[],
      Record<string, CPDataPoint>,
    ];
  };

  const getAdsData: (
    data: RequestResponseDataPoint["json"]
  ) => [
    Array<{ advertiser: string; insertions: number; percent: number }>,
    Array<{ category: string; insertions: number; percent: number }>,
  ] = (data: RequestResponseDataPoint["json"]) => {
    if (isEmpty(data)) {
      return [[], []];
    }

    const advertiserMap: Record<string, number> = {};
    const categoryMap: Record<string, number> = {};
    let totalAd = 0;
    let totalCat = 0;

    data.forEach((item) => {
      const [, advertiser, category] = item?.pathValues;

      if (advertiser !== "") {
        advertiserMap[advertiser] =
          typeof advertiserMap[advertiser] === "number"
            ? advertiserMap[advertiser] + item.count
            : item.count;
        totalAd += item.count;
      }
      if (category !== "") {
        categoryMap[category] =
          typeof categoryMap[category] === "number"
            ? categoryMap[category] + item.count
            : item.count;

        totalCat += item.count;
      }
    });

    const advertisers = Object.keys(advertiserMap)?.map((advertiser) => ({
      advertiser,
      insertions: advertiserMap[advertiser],
      percent: Math.round((advertiserMap[advertiser] / totalAd) * 100),
    }));

    const categories = Object.keys(categoryMap)?.map((category) => ({
      category,
      insertions: categoryMap[category],
      percent: Math.round((categoryMap[category] / totalCat) * 100),
    }));

    const top5CAds = take(orderBy(advertisers, ["insertions"], ["desc"]), 5);
    const top5CCat = take(orderBy(categories, ["insertions"], ["desc"]), 5);

    return [top5CAds, top5CCat];
  };

  const getHostReadData: (
    data: RequestResponseDataPoint["json"]
  ) => [Array<{ uuid: string; name: string }>, Record<string, Record<string, string>>] = (data) => {
    if (isEmpty(data || isEmpty(campaigns))) {
      return [[], {}];
    }

    const campaignsByEp: Record<string, Record<string, string>> = {};
    const campaignsMap: Record<string, string> = {};

    data.forEach((item) => {
      const [, campaignUUID, episodeUUID] = item.pathValues;

      if (campaignUUID !== "" && episodeUUID != "") {
        const campaignName = campaigns[campaignUUID]?.name;
        if (!campaignsByEp[episodeUUID]) {
          campaignsByEp[episodeUUID] = {};
        }
        campaignsByEp[episodeUUID][campaignUUID] = campaignName;

        campaignsMap[campaignUUID] = campaignName;
      }
    });

    const campaignsList = Object.keys(campaignsMap).map((uuid) => {
      return {
        uuid,
        name: campaignsMap[uuid],
      };
    });

    return [campaignsList, campaignsByEp];
  };

  // Grab campaign Information
  const [campaignsList, campaignsByEp] = getHostReadData(statsCacheCampaignData);

  // Grab Ad information
  const [top5Ads, top5Cat] = getAdsData(statsCacheAdsData);

  const [cpGraphData, cpTableData, cpColorMap] = transformCPData(rawData);

  const columnColorConfig = ({ episode }: any) => {
    return cpColorMap[episode]?.color as string;
  };

  const title = getGraphTitle(graphType);

  const listOfAudioBlocks = map(audioBlocks, (val) => val)
    ?.filter((val) => val?.type === audioBlockTypes.custom)
    ?.sort(sortNameAlphabetical); // Displaying all available audio blocks

  const listOfCustomAudios = Object.values(customAudios).sort(sortNameAlphabetical); // Displaying all available custom audios

  // handle Episodes
  useEffect(() => {
    let id: any;
    if (isEmpty(statsCacheData)) {
      setIsDataLoading(true);
      id = setTimeout(() => {
        let filters: StatsRequestFilter = {
          isUnique: true,
          arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
          timezone: timeZone,
          bucketTerms:
            "insertion.type,insertion.episodeUUID,insertion.isProgrammatic,insertion.audioBlockUUID,insertion.campaignUUID",
          type: "insertions",
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters = { ...filters, showUUID: selectedShow };
        }

        dispatch(
          new InsertionStatsActionManager({
            filters,
            user,
            requestID: cacheID,
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status === 200) {
              setRawData(resp?.json);
            } else {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
            setIsDataLoading(false);
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
            setIsDataLoading(false);
          });
      }, 250);
    } else {
      setRawData(statsCacheData);
      setIsDataLoading(false);
    }

    return () => id && clearTimeout(id);
  }, [
    dateRange?.[0]?.format(dateFormatStringDiff),
    dateRange?.[1]?.format(dateFormatStringDiff),
    selectedShow,
  ]);

  // Handle Advertisers
  useEffect(() => {
    let id: any;
    if (isEmpty(statsCacheAdsData)) {
      setAdsLoading(true);
      id = setTimeout(() => {
        let filters: StatsRequestFilter = {
          isUnique: true,
          isProgrammatic: true,
          arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
          timezone: timeZone,
          bucketTerms:
            "insertion.programmaticData.advertiser,insertion.programmaticData.categoryIDs",
          type: "insertions",
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters = { ...filters, showUUID: selectedShow };
        }

        dispatch(
          new InsertionStatsActionManager({
            filters,
            user,
            requestID: adsCacheID,
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status !== 200) {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
            setAdsLoading(false);
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
            setAdsLoading(false);
          });
      }, 250);
    }

    return () => id && clearTimeout(id);
  }, [
    dateRange?.[0]?.format(dateFormatStringDiff),
    dateRange?.[1]?.format(dateFormatStringDiff),
    selectedShow,
  ]);

  // Handle Campaigns
  useEffect(() => {
    let id: any;
    if (isEmpty(statsCacheCampaignData)) {
      id = setTimeout(() => {
        let filters: StatsRequestFilter = {
          isUnique: true,
          arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
          timezone: timeZone,
          campaignStyle: "hostRead",
          bucketTerms: "insertion.campaignUUID,insertion.episodeUUID",
          type: "insertions",
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters = { ...filters, showUUID: selectedShow };
        }

        dispatch(
          new InsertionStatsActionManager({
            filters,
            user,
            requestID: campaignsCacheID,
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status !== 200) {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
          });
      }, 250);
    }

    return () => id && clearTimeout(id);
  }, [
    dateRange?.[0]?.format(dateFormatStringDiff),
    dateRange?.[1]?.format(dateFormatStringDiff),
    selectedShow,
  ]);

  useEffect(() => {
    dispatch(getAudioBlocksForUser(user)).catch(() => {
      dispatch(showError("An error has occured please reload the page and try again", 5000));
    });
  }, []);

  // Handlers
  const handleCampaignSwitch = (newDropdownValue: any) => {
    setDropdownVal(newDropdownValue);
  };

  const handleAudioBlockSwitch = (newDropdownValue: any) => {
    setAudioBlock(newDropdownValue);
  };

  const handleCustomAudioSwitch = (newCustomAudioVal: string) => {
    setCustomAudio(newCustomAudioVal);
  };

  const handleReportDownload = () => {
    if (initialDataIsReady) {
      const csv = Papa.unparse(
        cpTableData.map((point) => {
          return {
            "Episode Name": point.episode,
            Date: moment(point.publishDate).utc().format("MM/DD/YYYY"),
            "Insertion Type": point.type,
            Insertions: point.insertions,
          };
        })
      );

      downloadFile(
        `DynamicInsertionsReport_${graphType}_Top Episodes_${dateRange?.[0]?.format(
          "YYYY_MM_DD"
        )}_to_${dateRange?.[1]?.format("YYYY_MM_DD")}.csv`,
        csv
      );
    } else {
      dispatch(showInfo(DataIsLoadingMessage, 3000));
    }
  };

  // Permissions
  const showInsertionsEpisodes = tierPermission.Extra.showExtraWidgets;
  const showTop5Eps =
    tierPermission.Extra.showExtraWidgets ||
    (!tierPermission.Extra.showExtraWidgets && someProgrammaticEnabled(shows));

  let total = 0;
  cpTableData.forEach((point: CPDataPoint) => {
    total = total + point.insertions;
  });
  const totalFormatted = numeral(total).format("0,0[.]00");

  return (
    <>
      <BlockFeature
        block={!tierPermission.Insertions.ProgrammaticEpisodes}
        CTA={{
          text: (
            <span className="h3 default-font m-b0 p-hxs text-center">
              <strong>Dynamic Insertions Episodes</strong> is only available starting from Pro Plan.
            </span>
          ),
          btn: {
            text: "Upgrade Your Plan",
            handler: () => goToPricingPage(),
          },
        }}
        blockByImg={{
          src: BlockSrcImg,
          minHeight: "1000px",
        }}>
        <If condition={showInsertionsEpisodes}>
          <div className="flex-row-container justify-center m-bxxs">
            <h3 className="m-a0">{title}</h3>
          </div>
          <div className="flex-row-container justify-center m-bm">
            <h4>Total: {totalFormatted}</h4>
          </div>
          <div className="flex-row-container align-center m-bxxs">
            <h4 className="h4 m-a0 bold capitalize">Insertions</h4>
            <If condition={graphType === InsertionGraphTypes[1] && campaignsList?.length > 0}>
              <span style={{ marginLeft: "auto" }}>
                <Select
                  size="small"
                  className="RC-Antd-Override-Dropdown"
                  value={dropdownVal}
                  virtual={false}
                  onSelect={handleCampaignSwitch}
                  dropdownMatchSelectWidth={false}
                  dropdownAlign={{
                    points: ["tr", "br"],
                    overflow: { adjustX: true, adjustY: true },
                  }}
                  dropdownStyle={{ maxWidth: "250px" }}
                  style={{ width: "160px" }}>
                  <Select.Option key={ALL_CAMPAIGNS} value={ALL_CAMPAIGNS}>
                    {ALL_CAMPAIGNS}
                  </Select.Option>
                  {campaignsList?.map((val) => {
                    return (
                      <Select.Option key={val?.uuid} value={val?.uuid}>
                        {val?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </span>
            </If>
            <If condition={graphType === InsertionGraphTypes[4] && listOfAudioBlocks?.length > 0}>
              <span style={{ marginLeft: "auto" }}>
                <Select
                  size="small"
                  className="RC-Antd-Override-Dropdown"
                  value={audioBlock}
                  virtual={false}
                  onSelect={handleAudioBlockSwitch}
                  dropdownMatchSelectWidth={false}
                  dropdownAlign={{
                    points: ["tr", "br"],
                    overflow: { adjustX: true, adjustY: true },
                  }}
                  dropdownStyle={{ maxWidth: "250px" }}
                  style={{ width: "200px" }}>
                  <Select.Option key={ALL_AUDIO_BLOCKS} value={ALL_AUDIO_BLOCKS}>
                    {ALL_AUDIO_BLOCKS}
                  </Select.Option>
                  {listOfAudioBlocks?.map((val) => {
                    return (
                      <Select.Option
                        className="RC-Antd-Override-Dropdown-Option"
                        key={val?.uuid}
                        value={val?.uuid}>
                        {middleEllipses(val?.name, 30, 8)}
                      </Select.Option>
                    );
                  })}
                </Select>
                <span className="m-lxxs">
                  <Select
                    size="small"
                    className="RC-Antd-Override-Dropdown"
                    value={customAudio}
                    virtual={false}
                    onSelect={handleCustomAudioSwitch}
                    dropdownMatchSelectWidth={false}
                    dropdownAlign={{
                      points: ["tr", "br"],
                      overflow: { adjustX: true, adjustY: true },
                    }}
                    dropdownStyle={{ maxWidth: "250px" }}
                    style={{ width: "160px" }}>
                    <Select.Option key={ALL_CUSTOM_AUDIO} value={ALL_CUSTOM_AUDIO}>
                      {ALL_CUSTOM_AUDIO}
                    </Select.Option>
                    {listOfCustomAudios?.map((val) => {
                      return (
                        <Select.Option
                          className="RC-Antd-Override-Dropdown-Option"
                          key={val?.uuid}
                          value={val?.uuid}>
                          {middleEllipses(val?.name, 30, 8)}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </span>
              </span>
            </If>
          </div>
          <div className="m-bs">
            <Column<CPDataPoint>
              loading={!initialDataIsReady || isDataLoading}
              data={cpGraphData}
              xField="episode"
              yField="insertions"
              tooltip={{
                customContent: cpCustomTooltip,
              }}
              color={columnColorConfig}
              meta={{
                episode: {
                  formatter: formatLabels,
                },
                insertions: {
                  formatter: yAxisLabelFormatter,
                },
              }}
            />
          </div>
          <Table
            className="analyticsPage-table"
            loading={!initialDataIsReady}
            dataSource={cpTableData}
            columns={graphType === InsertionGraphTypes[0] ? totalColumns : columns}
            scroll={{ x: true }}
            pagination={paginationConfig}
          />
          <div className="flex-row-container align-center m-txs">
            <Icon
              name="reportDownload"
              height={16}
              width={16}
              onClick={handleReportDownload}
              style={{
                bottom: ".125em",
                position: "relative",
                cursor: "pointer",
              }}
            />
            <h4 className="h4 m-v0 m-hxxs">Dynamic Insertions Episodes Report</h4>
          </div>
        </If>
      </BlockFeature>
      <If condition={graphType === InsertionGraphTypes[2] && showTop5Eps}>
        <div className="analyticsPage-top5 m-txs">
          <div className="analyticsPage-top5--card">
            <If condition={adsLoading}>
              <Spin />
            </If>
            <If condition={!adsLoading}>
              <>
                <h3>Top 5 Advertisers</h3>
                {top5Ads.map((ad, index) => {
                  return (
                    <div key={ad.advertiser} className="flex-row-container text-subtle">
                      <strong className="black-text">{index + 1}.</strong>
                      <span className="m-hxxs black-text">{ad.advertiser}</span>
                      <span className="m-la black-text">{ad.percent}%</span>
                    </div>
                  );
                })}
              </>
            </If>
          </div>
          <div className="analyticsPage-top5--card">
            <If condition={adsLoading}>
              <Spin />
            </If>
            <If condition={!adsLoading}>
              <>
                <h3>Top 5 Categories</h3>
                {top5Cat.map((cat, index) => {
                  return (
                    <div key={cat.category} className="flex-row-container text-subtle">
                      <strong className="black-text">{index + 1}.</strong>
                      <span className="m-hxxs black-text">{cat.category}</span>
                      <span className="m-la black-text">{cat.percent}%</span>
                    </div>
                  );
                })}
              </>
            </If>
          </div>
        </div>
      </If>
    </>
  );
};

// Optimizing re-rendering from parent.
const areEqual = (prevProps: IInsertionEpisodes, nextProps: IInsertionEpisodes) => {
  const dateRangeIsSame =
    moment(prevProps?.dateRange[0]).format(dateFormatStringDiff) ===
      moment(nextProps?.dateRange[0]).format(dateFormatStringDiff) &&
    moment(prevProps?.dateRange[1]).format(dateFormatStringDiff) ===
      moment(nextProps?.dateRange[1]).format(dateFormatStringDiff);

  const timeZoneIsSame = prevProps?.timeZone === nextProps?.timeZone;
  const selectShowIsSame = prevProps?.selectedShow === nextProps?.selectedShow;
  const graphTypeIsSame = prevProps?.graphType === nextProps?.graphType;

  return dateRangeIsSame && timeZoneIsSame && selectShowIsSame && graphTypeIsSame;
};

const MemoizedInsertionEpisodes = React.memo(InsertionEpisodes, areEqual);

export default MemoizedInsertionEpisodes;
