// generic hooks

import { Form, FormInstance } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

// we have a common pattern where we want to block initial data fetch but not
// block the UI on subsequent data fetches with isLoading - this hook only checks for initial
// data fetch to create the loading state
export const useInitialLoading = (toWatch: boolean) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (toWatch) {
      setLoading(false);
    }
  }, [toWatch]);

  return loading;
};

// debounces anything sent into value
const DEFAULT_DELAY_MS = 500;
export const useDebounce = (value: any, delayMS: number = DEFAULT_DELAY_MS) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => setDebouncedValue(value), delayMS);
      return () => clearTimeout(handler);
    },
    [value, delayMS] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

// A custom hook that builds on useLocation to parse the query string for you.
export const useQuery = () => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const obj: any = {};
  params.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
};

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState<{ width?: number; height?: number }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

/** This hook is used in conjunction with Antd Forms to determine if a form is submittable or not */
export const useFormSubmittable = ({ form }: { form: FormInstance }) => {
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], { form });

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setSubmittable(true);
      })
      .catch(() => {
        setSubmittable(false);
      });
  }, [form, values]);

  return submittable;
};
