import React, { Component } from "react";
import { Col } from "react-bootstrap";
import LoggedOutFrame from "../../lib/logged_out_frame";

export default class PasswordSent extends Component {
  render() {
    return (
      <LoggedOutFrame title="Password Reset link Sent!">
        <Col md={6} mdOffset={3} sm={12}>
          <p className="sign-in-page__welcome">
            Your password link has been sent. Look out for an email from RedCircle, which will guide
            you through the next steps.
          </p>
        </Col>
      </LoggedOutFrame>
    );
  }
}
