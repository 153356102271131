import get from "lodash/get";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { If } from "react-extras";
import HighlightBox from "src/components/lib/highlight_box";
import { permissionTypes } from "src/constants/permission_roles";
import { shouldShowEarnings } from "src/lib/feature_flag";
import { formatMoney, formatMoneyFlexCurrency } from "src/lib/format-money";

export default class BalanceIndicator extends Component {
  componentDidMount() {
    this.fetchBalanceIfConnected(get(this.props, "user.isStripeConnected"));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (get(this.props, "user.isStripeConnected") !== get(nextProps, "user.isStripeConnected")) {
      this.fetchBalanceIfConnected(get(nextProps, "user.isStripeConnected"));
    }
  }

  fetchBalanceIfConnected = (isConnected) => {
    if (isConnected) {
      this.props.getStripeBalance();
    }
  };

  tipsTotal = () => {
    if (!this.props.transactions || !this.props.transactions.length) {
      return 0;
    }

    return this.props.transactions.reduce((agg, value) => {
      return value.ignoreForBalance ? agg : agg + value.amount;
    }, 0);
  };

  renderBalances = ({ amount, currency }) => (
    <div key={currency}>{formatMoneyFlexCurrency({ amount, currency })}</div>
  );

  renderBlank = () => <div>Not Connected</div>;

  render() {
    const { stripeBalancePending = [], stripeBalance = [] } = this.props;
    const canSeeGrayBox = this.props.canAccess(permissionTypes.transferFunds);
    const showEarnings = shouldShowEarnings();
    const md = canSeeGrayBox ? (showEarnings ? 4 : 6) : 12;

    return (
      <div className="balance-indicator">
        <Row>
          {showEarnings && (
            <Col xs={12} md={md}>
              <HighlightBox
                className="bordered"
                title="All Time Earnings"
                value={formatMoney(this.tipsTotal() / 1000)}
                isLoading={!!this.props.isLoading}
              />
            </Col>
          )}
          <If condition={canSeeGrayBox}>
            <Col xs={12} md={md}>
              <HighlightBox
                className="bordered"
                title="Pending Balance"
                value={
                  stripeBalancePending.length
                    ? stripeBalancePending.map(this.renderBalances)
                    : this.renderBlank()
                }
                scalableHeight
                infoText={
                  "Pending amount in your Stripe account waiting to be cleared. This typically takes 7-10 days depending on your bank."
                }
                isLoading={!!this.props.isLoading}
              />
            </Col>

            <Col xs={12} md={md}>
              <HighlightBox
                className="bordered"
                title="Current Balance"
                scalableHeight
                value={
                  stripeBalance.length ? stripeBalance.map(this.renderBalances) : this.renderBlank()
                }
                infoText={`Balance in your Stripe account ready to be transferred to your bank. Click 'Transfer to Bank' to withdraw.`}
                isLoading={!!this.props.isLoading}
              />
            </Col>
          </If>
        </Row>
      </div>
    );
  }
}
