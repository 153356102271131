import React, { Component } from "react";
import BaseModal from "./base_upload_modal";

export default class AudioUploadModal extends Component {
  handleClick = () => {
    if (this.props.setAllUUIDs) {
      var allUUIDs = {};
      for (var file of this.props.mediaFiles) {
        allUUIDs[file.bitrate] = file.uuid;
      }
      this.props.setAllUUIDs(allUUIDs);
      this.props.closeModal();
      return;
    } else {
      for (var mediaFile of this.props.mediaFiles) {
        if (mediaFile.bitrate === this.props.bitrate) {
          this.props.setFileUUID(mediaFile.uuid);
          this.props.closeModal();
          return;
        }
      }
    }

    this.props.setError("You must upload an audio file as the content.");
  };

  bitRateSection = () => {
    return (
      <div>
        <label>Bitrate</label>
        <select
          name="bitrate"
          onChange={(event) => {
            this.props.setBitrate(event.target.value);
          }}>
          <option value={64}>64Kbps -- Lower Quality, Small Size</option>
          <option value={128} selected>
            128Kbps -- Medium Quality, Medium Size
          </option>
          <option value={192}>192Kbps -- High Quality, Larger Size</option>
        </select>
      </div>
    );
  };
  render() {
    return (
      <BaseModal
        handleInput={this.props.doUpload}
        FileTypeName="MediaFile"
        accept=".mp3"
        onClick={this.handleClick}
        optionalSection={this.bitRateSection}
        {...this.props}
      />
    );
  }
}
