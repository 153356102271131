import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import React from "react";
import GraphContainer from "src/components/lib/stats/graph_container";
import StatsPie from "src/components/lib/stats/stats_pie";

const ListenerGender = (props) => {
  const { show, style } = props;

  const listenerDemographics = show?.listenerDemographics;
  let genderData = map(listenerDemographics?.genderBreakdown, (v, k) => ({
    x: k,
    y: v,
    total: 1,
  }));

  let labelByRow = {
    female: "Female",
    male: "Male",
    unknown: "Unknown",
    nonBinary: "Non-Binary",
  };

  if (isEmpty(genderData)) {
    return null;
  }
  genderData = sortBy(genderData, "y");
  genderData.reverse();

  let sortedByName = clone(genderData);
  sortedByName = sortBy(sortedByName, "x");

  return (
    <GraphContainer title={"Listener Gender"} style={style}>
      <StatsPie
        data={genderData}
        labelByRow={labelByRow}
        containerHeight={300}
        containerWidth={368} // 240 - 2 * 28 (parent horizontal padding)) * 2(this multipler is needed since original stat_pie divides the width)
      />
      <div className="no-padding">
        {sortedByName.map((row, index) => {
          return (
            <div className="flex-row-container justify-space-between" key={index}>
              <div>
                <b className="m-rxxs">{labelByRow[row.x]}</b>
              </div>
              <div>{Math.round(row.y * 100) + " %"}</div>
            </div>
          );
        })}
      </div>
    </GraphContainer>
  );
};

export default ListenerGender;
