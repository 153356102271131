import React, { Component } from "react";
import { If } from "react-extras";

class EmailVerificationDetails extends Component {
  render() {
    var emailIsVerified = this.props.user && this.props.user.hasVerifiedEmail;
    return (
      <div className="email-verification-details">
        <If condition={emailIsVerified}>
          <div>
            Your email is <span className="text-success">Verified</span>
          </div>
        </If>
        <If condition={!emailIsVerified}>
          <div>
            Your email is <span className="text-danger">Not Verified</span>
            <button
              className="btn btn-small btn-primary user-form__verify-button"
              onClick={(e) => {
                e.preventDefault();
                this.props.sendVerificationEmail(e);
              }}>
              Verify Now
            </button>
          </div>
        </If>
      </div>
    );
  }
}

export default EmailVerificationDetails;
