import { useEffect, useState } from "react";
import Page from "src/components/lib/page";
import {
  ReportingPageState,
  UTC,
  acceptableCampaignItemStates,
  createDefaultState,
  reportingPageDefaultState,
  validateKeys,
} from "./utilities";
import { useEnsureShowIsAvailable } from "src/hooks/shows";
import { useEnsureCampaignItemsFetched } from "src/hooks/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { AdvertisingReportGraphHeader } from "./advertising_graph_header";
import { AdvertisingReportControls } from "./advertising_report_controls";
import { AdvertisingReportGraph } from "./advertising_report_graph";
import dayjs from "dayjs";
import { showWarning } from "src/actions/app";

const AdvertisingReportingPage = () => {
  const dispatch = useDispatchTS();
  const timeZone = dayjs.tz.guess() ?? UTC; // defaulting to user's current timezone

  /**
   * Holds the state for the reporting page controls, when the user makes changes to teh controls this is updated
   */
  const [reportingState, setReportingState] = useState<ReportingPageState>({
    ...reportingPageDefaultState,
    timeZone,
  });

  /**
   * Separating out graph focus state to its own func, since it is the only change to the state
   * required by the graph component
   */
  const handleChangeGraphFocus = (graphFocus: ReportingPageState["graphFocus"]) => {
    setReportingState((prev) => {
      return { ...prev, graphFocus };
    });
  };

  /**
   * Fetches campaign Items needed based on campaigns user clicked
   */
  useEnsureCampaignItemsFetched(reportingState.campaignUUIDs);

  // shows needed to fetch based on campaign Items
  const showUUIDsNeeded = useSelectorTS((state) => {
    const { campaignItemByCampaignUUID, campaignItems } = state.campaignItems;

    let campaignItemUUIDs: string[] = [];

    for (const campaignUUID of reportingState.campaignUUIDs) {
      campaignItemUUIDs = campaignItemUUIDs.concat(campaignItemByCampaignUUID[campaignUUID] ?? []);
    }

    const showUUIDs = campaignItemUUIDs
      .filter((campaignItemUUID) => {
        return acceptableCampaignItemStates.includes(campaignItems?.[campaignItemUUID]?.state);
      })
      .map((campaignItemUUID) => campaignItems[campaignItemUUID]?.showUUID);

    const uniques = new Set(showUUIDs);

    return [...uniques];
  });
  /**
   * Fetches required public Shows needed.
   */
  useEnsureShowIsAvailable(showUUIDsNeeded);

  /**
   * On page load does the following:
   * - Read state from query params
   */
  useEffect(() => {
    const params = new URL(window.location.href).searchParams;

    if (params.size === 0) return; // No params found, can just skip

    const initialState = createDefaultState(params);

    if (validateKeys(initialState, ["campaignUUIDs"])) {
      setReportingState(initialState);
    } else {
      dispatch(
        showWarning(
          "Invalid url query param. Could not load state from URL. Please check the url has been copied correctly"
        )
      );
    }
  }, []);

  /**
   * Removes podcast selections that are invalid (i.e. podcasts not found in the group of campaign selected).
   */
  useEffect(() => {
    const allAllowedShowsUUIDs = new Set(showUUIDsNeeded);
    const newAllowedShows: string[] = [];
    for (const showUUID of reportingState.showUUIDs) {
      if (allAllowedShowsUUIDs.has(showUUID)) {
        newAllowedShows.push(showUUID);
      }
    }

    if (newAllowedShows.length !== reportingState.showUUIDs.length) {
      setReportingState((prev) => {
        return { ...prev, showUUIDs: newAllowedShows };
      });
    }
  }, [showUUIDsNeeded?.length]);

  return (
    <Page pageTitle="Reporting">
      <Page.Title>Reporting</Page.Title>
      <Page.Section className="m-tm">
        <AdvertisingReportControls
          reportingState={reportingState}
          setReportingState={setReportingState}
          showUUIDsNeeded={showUUIDsNeeded}
        />
      </Page.Section>
      <Page.Section className="m-tm">
        <AdvertisingReportGraphHeader
          reportingState={reportingState}
          showUUIDsNeeded={showUUIDsNeeded}
        />
      </Page.Section>
      <Page.Section className="m-tm">
        <AdvertisingReportGraph
          reportingState={reportingState}
          graphFocus={reportingState.graphFocus}
          handleChangeGraphFocus={handleChangeGraphFocus}
          showUUIDsNeeded={showUUIDsNeeded}
        />
      </Page.Section>
    </Page>
  );
};

export default AdvertisingReportingPage;
