import fetch from "cross-fetch";
import getAPIPrefix from "../lib/api_prefix";

function getEpisodesForShow(showUUID, authToken) {
  return fetch(getAPIPrefix() + "shows/" + showUUID + "/episodes", {
    method: "get",
    headers: {
      Authorization: "Bearer " + authToken,
    },
  });
}

function getEpisode(episodeUUID, authToken) {
  return fetch(getAPIPrefix() + "episodes/" + episodeUUID, {
    method: "get",
    headers: {
      Authorization: "Bearer " + authToken,
    },
  });
}

function createEpisode(episode, authToken) {
  return fetch(getAPIPrefix() + "episodes", {
    method: "post",
    headers: {
      Authorization: "Bearer " + authToken,
    },
    body: JSON.stringify(episode),
  });
}

function validateCreateEpisode(episode, authToken) {
  return fetch(getAPIPrefix() + "episodes/validate", {
    method: "post",
    headers: {
      Authorization: "Bearer " + authToken,
    },
    body: JSON.stringify(episode),
  });
}

function validateUpdateEpisode(episode, authToken) {
  return fetch(getAPIPrefix() + `episodes/${episode.uuid}/validate`, {
    method: "post",
    headers: {
      Authorization: "Bearer " + authToken,
    },
    body: JSON.stringify(episode),
  });
}

function updateEpisode(episode, authToken) {
  return fetch(getAPIPrefix() + "episodes/" + episode.uuid, {
    method: "put",
    headers: {
      Authorization: "Bearer " + authToken,
    },
    body: JSON.stringify(episode),
  });
}

function deleteEpisode(episodeUUID, authToken) {
  return fetch(getAPIPrefix() + "episodes/" + episodeUUID, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + authToken,
    },
  });
}

const episodeAPI = {
  getEpisodesForShow,
  getEpisode,
  createEpisode,
  updateEpisode,
  deleteEpisode,
  validateCreateEpisode,
  validateUpdateEpisode,
};

export default episodeAPI;
