export const updateCropActionName = "UPDATE_CROP";
export const resetCropActionName = "RESET_CROP";

export const updateCrop = (imageUploadData) => ({
  type: updateCropActionName,
  payload: imageUploadData,
});
export const resetCrop = (imageUploadData) => ({
  type: resetCropActionName,
});
