import React, { Component, ReactNode } from "react";

// Either href or to is required
export type IExternalLink = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  (
    | {
        href: string;
        children?: ReactNode;
        to?: string;
      }
    | {
        href?: string;
        children?: ReactNode;
        to: string;
      }
  );

class ExternalLink extends Component<IExternalLink> {
  render() {
    const { href, to, children, ...rest } = this.props;
    return (
      <a
        data-testid="test-externallink"
        href={href || to}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}>
        {children}
      </a>
    );
  }
}

export default ExternalLink;
