import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import { classNames } from "react-extras";
import styles from "./large_header_button.module.scss";

interface ILargeHeaderButtonProps {
  backgroundColor?: string;
  title: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

export default function LargeHeaderButton({
  children,
  backgroundColor = "black",
  title,
  className,
  onClick,
}: ILargeHeaderButtonProps) {
  return (
    <button className={classNames(styles.button, className)} onClick={onClick} aria-label={title}>
      <div className={styles["rotated-background"]} style={{ backgroundColor }} />
      <div className="flex-row-container align-center m-bxxs">
        <h3 className="m-b0 m-t0">{title}</h3>
        <ArrowRightOutlined className={classNames("m-bxxxs m-lxxs", styles.arrow)} />
      </div>
      {children}
    </button>
  );
}
