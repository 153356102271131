import { Form } from "antd";
import { Dayjs } from "dayjs";
import { History } from "history";
import { useEffect, useState } from "react";
import { Link, match } from "react-router-dom";
import { IBrand } from "redcircle-types";
import { Button, MultiModal } from "redcircle-ui";
import { showSuccess, showWarning } from "src/actions/app";
import { createBrand, getBrand, updateBrand } from "src/action_managers/brands";
import { createCampaign, editCampaign, getCampaign } from "src/action_managers/campaigns";
import { useGetCampaign } from "src/hooks/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import {
  CampaignDistributionType,
  CampaignProductExchangeType,
  ICampaign,
  ICampaignTargetingOptions,
} from "redcircle-types";
import { SummaryPage } from "../campaign_editor/summary_page";
import {
  initializeCampaignDeadlines,
  initializeCampaignTimeline,
} from "../campaign_scheduler_modal/campaign_scheduler_utils";
import CampaignFormBrand from "./CampaignFormBrand";
import CampaignFormDetails from "./CampaignFormDetails";
import CampaignFormFlighting from "./CampaignFormFlighting";
import CampaignFormRequirements from "./CampaignFormRequirements";
import CampaignFormTargetAudience from "./CampaignFormTargetAudience";

interface ICampaignEditorModal {
  closeModal: () => void;
  match: match<{ pageNumber: string; campaignUUID?: string }>;
  history: History;
  isEditing?: boolean;
}

interface ICampaignFormValues extends Omit<ICampaign, "targetGender"> {
  redemptionCodeString?: string;
  targetingOptionsArray?: string[];
  targetGender?: string;
}

// some values are not set by the BE (or returned as null, so we set them here)
const DEFAULT_VALUES: Partial<ICampaignFormValues> & {
  brand?: Partial<IBrand>;
  timeline: Dayjs[];
} = {
  productExchangeType: "none" as CampaignProductExchangeType, // not auto set by BE
  requiresEndorsement: false, // not auto set by BE
  targetGender: "all", // not auto set by BE
  targetAges: [],
  distributionType: CampaignDistributionType.DistributionTypeContinuous,
  frequencyConfigs: [], // not auto set by BE
  brand: {} as IBrand,
  timeline: [] as Dayjs[],
};

export default function CampaignEditorModal({
  closeModal,
  match,
  history,
  isEditing,
}: ICampaignEditorModal) {
  const dispatch = useDispatchTS();
  const { params } = match;
  const { pageNumber, campaignUUID: editingCampaignUUID } = params;
  const currentPage = pageNumber ? Number(pageNumber) : 0;
  const [form] = Form.useForm();
  const editingCampaign = useGetCampaign(editingCampaignUUID);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { user } = useSelectorTS((state) => state.user);

  const defaultPixelRequiredValue = editingCampaign?.isV2
    ? user.userAttributes.defaultPixelRequired === "true"
    : undefined;

  useEffect(() => {
    const values = { ...DEFAULT_VALUES, pixelRequired: defaultPixelRequiredValue };
    form.setFieldsValue(values);
  }, []);

  // only when editing a campaign (load campaign data into form)
  useEffect(() => {
    if (editingCampaign) {
      const newFormFields: any = { ...editingCampaign };

      // set custom transformation fields
      newFormFields.redemptionCodeString =
        editingCampaign.redemptionCodes && Object.keys(editingCampaign.redemptionCodes).join(", ");
      newFormFields.targetingOptionsArray =
        editingCampaign.targetingOptions &&
        Object.keys(editingCampaign.targetingOptions).filter(
          (key) => editingCampaign.targetingOptions[key as keyof ICampaignTargetingOptions]
        );
      newFormFields.timeline = initializeCampaignTimeline(editingCampaign);
      newFormFields.deadlines = initializeCampaignDeadlines(editingCampaign);

      // set null fields to defaults
      for (const key in newFormFields) {
        if (newFormFields[key] === null) {
          newFormFields[key] = DEFAULT_VALUES[key as keyof ICampaignFormValues];
        }
      }
      form.setFieldsValue(newFormFields);
    }
  }, [editingCampaign]);

  const handleFormSubmit = async () => {
    // check form fields
    const formFields = form.getFieldsValue(true);
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }

    // page 0 - campaign details
    if (currentPage === 0) {
      const campaign = {
        name: formFields.name,
        productName: formFields.productName,
        referenceNumber: formFields.referenceNumber,
        productExchangeType: formFields.productExchangeType,
        requiresEndorsement: formFields.requiresEndorsement,
        targetGender: formFields.targetGender,
        distributionType: formFields.distributionType,
        isV2: formFields.isV2,
      };
      if (!isEditing) {
        return handleFormSubmitCreate(campaign);
      } else {
        return handleFormSubmitEdit(campaign);
      }
    }

    // page 1 - brand
    if (currentPage === 1) {
      return handleBrandSubmit({
        name: formFields.brand?.name,
        instanceUUID: formFields.brand?.instanceUUID,
        iabCategory: formFields.brand?.iabCategory,
        domain: formFields.brand?.domain,
        description: formFields.brand?.description,
      });
    }

    // page 2 - Requirements
    if (currentPage === 2) {
      return handleFormSubmitEdit({
        productExchangeType: formFields.productExchangeType,
        productExchangeInstructions: formFields.productExchangeInstructions,
        requiresEndorsement: formFields.requiresEndorsement,
        redemptionCodes: formFields.redemptionCodeString
          ? formFields.redemptionCodeString
              .split(",")
              .map((code: string) => code.trim())
              .filter((code: string) => code !== "")
          : [],
        pixelRequired: formFields.pixelRequired,
        isV2: formFields.isV2,
      });
    }

    // page 3 - target audience
    if (currentPage === 3) {
      return handleFormSubmitEdit({
        targetGender: formFields.targetGender,
        targetAges: formFields.targetAges,
        isV2: formFields.isV2,
      });
    }

    // page 4 - flighting (distribution reach)
    if (currentPage === 4) {
      return handleFormSubmitEdit({
        startsAt: formFields.timeline[0]?.unix(),
        ...(formFields.pacing && { endsAt: formFields.timeline[1]?.unix() }),
        assignAudioDeadline: formFields.deadlines.assignAudioDeadline.unix(),
        responseDeadline: formFields.deadlines.responseDeadline.unix(),
        pacing: formFields.pacing,
        frequencyConfigs: formFields.frequencyConfigs,
        hardEndDate: formFields.hardEndDate,
        recentEpisodesOnly: formFields.recentEpisodesOnly,
        targetingOptions: formFields.targetingOptionsArray
          ? formFields.targetingOptionsArray.reduce((acc: any, curr: any) => {
              acc[curr] = true;
              return acc;
            }, {})
          : {},
        isV2: formFields.isV2,
      });
    }
  };

  const handleFormSubmitCreate = async (campaign: Partial<ICampaign>) => {
    setIsSubmitLoading(true);
    const res = await dispatch(createCampaign(campaign));
    setIsSubmitLoading(false);

    if (res && res?.status === 200) {
      dispatch(showSuccess("Campaign created successfully"));
      history.push(`/campaigns/${res.json.uuid}/edit/${currentPage + 1}`);
    }
    return res;
  };

  const handleFormSubmitEdit = async (campaign: Partial<ICampaign>) => {
    if (isEditing && editingCampaign && campaign && !isSubmitLoading) {
      setIsSubmitLoading(true);
      const res = await dispatch(editCampaign({ uuid: editingCampaign.uuid, ...campaign }));
      if (res && res.status === 200) {
        dispatch(getCampaign(editingCampaign.uuid));
      }
      setIsSubmitLoading(false);
      return res;
    }
  };

  // see logic here: https://docs.google.com/document/d/1Qi_IJMVzet95SxlGZ6a-P__hVLFDctCC1ZcZRH-8M8Y/edit
  const handleBrandSubmit = async (brand: Partial<IBrand>) => {
    // if no instanceUUID, fire create brand
    if (!brand.instanceUUID) {
      const res = await dispatch(createBrand(brand));
      if (res && res.status === 200 && res.json.instanceUUID && editingCampaign) {
        // attach instanceUUID to campaign
        const res2 = await dispatch(
          editCampaign({
            uuid: editingCampaign.uuid,
            brandInstanceUUID: res.json.instanceUUID,
            isV2: !!editingCampaign.isV2,
          })
        );
        return res2;
      } else {
        dispatch(showWarning("Error creating brand"));
      }
    } else {
      // brand exists - has it updated?
      try {
        const res = await dispatch(getBrand(brand.instanceUUID));
        const originalBrand = res.json as IBrand;
        if (res.status === 200 && originalBrand) {
          // check if brand has updated
          let hasBrandChanged = false;
          ["iabCategory", "domain", "description"].forEach((field) => {
            if (
              originalBrand[field as keyof typeof originalBrand] !==
              brand[field as keyof typeof brand]
            ) {
              hasBrandChanged = true;
            }
          });

          if (hasBrandChanged) {
            // update brand
            const res2 = await dispatch(
              updateBrand({ ...brand, instanceUUID: originalBrand.instanceUUID })
            );
            // if brand updated, update campaign
            if (res2.status === 200) {
              const res3 = await dispatch(
                editCampaign({
                  uuid: editingCampaignUUID,
                  brandInstanceUUID: brand.instanceUUID,
                  isV2: !!editingCampaign?.isV2,
                })
              );
              return res3;
            } else {
              throw new Error();
            }
          } else {
            // brand has not changed, update campaign
            const res3 = await dispatch(
              editCampaign({
                uuid: editingCampaignUUID,
                brandInstanceUUID: brand.instanceUUID,
                isV2: !!editingCampaign?.isV2,
              })
            );
            return res3;
          }
        }
      } catch (e) {
        dispatch(showWarning("Error updating brand"));
      }
    }
  };

  const handleFormNext = async () => {
    // page 5 - summary page
    if (currentPage === 5) {
      handleClose();
      return;
    }

    const res = await handleFormSubmit();
    if (res && res.status === 200 && res.json) {
      if (!isEditing) {
        history.push(`/campaigns/${res.json.uuid}/edit/${currentPage + 1}`);
      } else {
        history.push(`/campaigns/${editingCampaignUUID}/edit/${currentPage + 1}`);
      }
    }
  };

  const handleFormBack = async () => {
    // page 5 - summary page
    if (currentPage === 5) {
      if (currentPage > 0 && isEditing && editingCampaign) {
        history.push(`/campaigns/${editingCampaign.uuid}/edit/${currentPage - 1}`);
        return;
      }
    }

    const res = await handleFormSubmit();
    if ((res && res.status === 200) || currentPage === 4) {
      if (currentPage > 0 && isEditing && editingCampaign) {
        history.push(`/campaigns/${editingCampaign.uuid}/edit/${currentPage - 1}`);
      }
    }
  };

  const handleClose = () => {
    if (editingCampaignUUID) {
      history.push(`/campaigns/${editingCampaignUUID}`);
    } else {
      history.push("/campaigns");
    }
    closeModal();
  };

  const pages = [
    {
      pretitle: editingCampaign && (
        <span className="redcircle-form-label">{editingCampaign.name}</span>
      ),
      title: isEditing ? "Edit Campaign" : "New Campaign",
      subtitle:
        "Tell us about the product or service you’re promoting. When you invite podcasts to your campaign, this is the information they will see.",
      label: (
        <Link
          to={isEditing ? `/campaigns/${editingCampaignUUID}/edit` : `/campaigns/new`}
          onClick={handleFormSubmit}>
          <Button type="link" className="p-a0" style={{ height: "auto" }}>
            Campaign Details
          </Button>
        </Link>
      ),
      body: <CampaignFormDetails />,
      footer: <FormFooter onSubmit={handleFormNext} isLoading={isSubmitLoading} />,
    },
    {
      pretitle: editingCampaign && (
        <span className="redcircle-form-label">{editingCampaign.name}</span>
      ),
      title: "Brand",
      subtitle: "Tell us about the brand behind the ad.",
      label: (
        <Link to={`/campaigns/${editingCampaignUUID}/edit/1`} onClick={handleFormSubmit}>
          <Button type="link" className="p-a0" style={{ height: "auto" }} disabled={!isEditing}>
            Brand
          </Button>
        </Link>
      ),
      body: <CampaignFormBrand />,
      footer: (
        <FormFooter onSubmit={handleFormNext} onBack={handleFormBack} isLoading={isSubmitLoading} />
      ),
    },
    {
      pretitle: editingCampaign && (
        <span className="redcircle-form-label">{editingCampaign.name}</span>
      ),
      title: "Campaign Requirements",
      label: (
        <Link to={`/campaigns/${editingCampaignUUID}/edit/2`} onClick={handleFormSubmit}>
          <Button type="link" className="p-a0" style={{ height: "auto" }} disabled={!isEditing}>
            Requirements
          </Button>
        </Link>
      ),
      body: <CampaignFormRequirements editingCampaign={editingCampaign} />,
      footer: (
        <FormFooter onSubmit={handleFormNext} onBack={handleFormBack} isLoading={isSubmitLoading} />
      ),
    },
    {
      pretitle: editingCampaign && (
        <span className="redcircle-form-label">{editingCampaign.name}</span>
      ),
      title: "Target Audience",
      subtitle: "Tell us about who you want to reach and we’ll recommend ideal podcasts.",
      label: (
        <Link to={`/campaigns/${editingCampaignUUID}/edit/3`} onClick={handleFormSubmit}>
          <Button type="link" className="p-a0" style={{ height: "auto" }} disabled={!isEditing}>
            Target Audience
          </Button>
        </Link>
      ),
      body: <CampaignFormTargetAudience />,
      footer: (
        <FormFooter onSubmit={handleFormNext} onBack={handleFormBack} isLoading={isSubmitLoading} />
      ),
    },
    {
      pretitle: editingCampaign && (
        <span className="redcircle-form-label">{editingCampaign.name}</span>
      ),
      title: "Flighting",
      subtitle: "Choose what kind of distribution reach you’d like for this promotion.",
      label: (
        <Link to={`/campaigns/${editingCampaignUUID}/edit/4`} onClick={handleFormSubmit}>
          <Button type="link" className="p-a0" style={{ height: "auto" }} disabled={!isEditing}>
            Flighting
          </Button>
        </Link>
      ),
      body: <CampaignFormFlighting />,
      footer: (
        <FormFooter onSubmit={handleFormNext} onBack={handleFormBack} isLoading={isSubmitLoading} />
      ),
    },
    {
      pretitle: editingCampaign && (
        <span className="redcircle-form-label">{editingCampaign.name}</span>
      ),
      title: "Summary",
      subtitle:
        "Here’s a summary of your campaign. If everything looks good, invite the podcasts that you think would be a good fit for your campaign and upload scripts later.",
      label: (
        <Link to={`/campaigns/${editingCampaignUUID}/edit/5`} onClick={handleFormSubmit}>
          <Button type="link" className="p-a0" style={{ height: "auto" }} disabled={!isEditing}>
            Summary
          </Button>
        </Link>
      ),
      body: <SummaryPage campaign={editingCampaign} />,
      footer: (
        <FormFooter
          onSubmit={handleFormNext}
          onBack={handleFormBack}
          submitText={"View Campaign"}
          isLoading={isSubmitLoading}
        />
      ),
    },
  ];

  return (
    <Form form={form}>
      <MultiModal navStyle="steps" currentPage={currentPage} pages={pages} onClose={handleClose} />
    </Form>
  );
}

const FormFooter = ({
  onSubmit,
  onBack,
  submitText = "Save & Continue",
  isLoading,
}: {
  onSubmit: () => void;
  onBack?: () => void;
  submitText?: string;
  isLoading?: boolean;
}) => {
  const form = Form.useFormInstance();
  const fields = Form.useWatch([], form);
  const [formHasError, setFormHasError] = useState(false);

  useEffect(() => {
    validateForm();
  }, [fields]);

  const validateForm = async () => {
    try {
      await form.validateFields();
      setFormHasError(false);
    } catch (e: any) {
      const hasError = e.errorFields?.length > 0;
      setFormHasError(hasError);
    }
  };

  return (
    <>
      {onBack && (
        <Button type="link" size="large" onClick={onBack} loading={isLoading}>
          Save & Back
        </Button>
      )}
      <Button
        type="primary"
        size="large"
        className="m-la"
        loading={isLoading}
        disabled={formHasError}
        onClick={onSubmit}>
        {submitText}
      </Button>
    </>
  );
};
