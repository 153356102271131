import UserStore from "../lib/user_store";
import DefaultActionManager from "./default_action_manager";

export const sendMessageActionName = "SEND_MESSAGE";
export const getMessagesActionName = "GET_MESSAGES";
export const messagesSeenActionName = "MESSAGES_SEEN";

export const sendMessage = (threadUUID, recipientUUID, text, userUUID) =>
  new DefaultActionManager({
    actionName: sendMessageActionName,
    method: "POST",
    route: "messages",
    body: {
      threadUUID,
      recipientUUID,
      text,
      context: "deal",
    },
    actionData: {
      userUUID,
    },
    auth: true,
  }).run();

export const getMessages = (threadUUID) =>
  new DefaultActionManager({
    route: `messages/deal/${threadUUID}`,
    actionName: getMessagesActionName,
    auth: true,
    actionData: {
      threadUUID,
    },
  }).run();

export const messagesSeen = (threadUUID) => {
  if (UserStore.isAdminLogin()) {
    console.log(
      `Would have marked threadUUID ${threadUUID} seen, but aborting since you're logged in as an admin.`
    );
    return { type: "NO_ACTION" };
  }
  return new DefaultActionManager({
    route: `messages/deal/${threadUUID}/seen`,
    actionName: messagesSeenActionName,
    auth: true,
    method: "POST",
  }).run();
};
