import React from "react";
import styles from "./custom_audio.module.scss";

interface IProps {
  impressionCap?: number;
  impressions: number;
}

export default function CustomAudioImpressionProgress({ impressionCap, impressions }: IProps) {
  if (!impressionCap) {
    return <strong>{impressions?.toLocaleString()}</strong>;
  }

  const clampedImpressions = Math.min(impressions, impressionCap);
  const progressPercentage = Math.round((clampedImpressions / impressionCap) * 100);

  return (
    <>
      <div>
        <div className={styles.progressWrapper}>
          <div className={styles.progress} style={{ width: `${progressPercentage}%` }}></div>
        </div>
      </div>
      <strong>{clampedImpressions?.toLocaleString()}</strong>/{impressionCap.toLocaleString()}
    </>
  );
}
