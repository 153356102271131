import React, { lazy, PropsWithChildren, Suspense } from "react";
import Spinner from "../../../lib/spinner";
import { graphFonts } from "../analyticsUtility";
import Message from "./Messages";
import "./redcircle_graphs.scss";

const HeatMap = lazy(() => import("@ant-design/plots/es/components/heatmap"));

// Inferring Graph config type
type graphConfig = Parameters<typeof HeatMap>[number];

// Interface with Generic T of type datapoint to restrict field types
interface IRCHeatMap<T> {
  data: T[];
  xField: string & keyof T;
  yField: string & keyof T;
  colorField: string & keyof T;
  height?: Required<graphConfig>["height"];
  width?: Required<graphConfig>["width"];
  loading?: boolean;
  tooltip?: Required<graphConfig>["tooltip"];
  color?: Required<graphConfig>["color"];
  meta?: Required<graphConfig>["meta"];
  legend?: Required<graphConfig>["legend"];
}

const RCHeatMap = <T extends Record<string, any>>(props: PropsWithChildren<IRCHeatMap<T>>) => {
  const {
    data = [],
    xField,
    yField,
    colorField,
    height = 650,
    width,
    loading = false,
    tooltip,
    color = ["rgb(238,242,245)", "rgb(121,151,177)"],
    meta,
    legend,
  } = props;

  const config: graphConfig = {
    style: {
      minHeight: `${height}px`,
    },
    data,
    height,
    width,
    autoFit: true,
    loading,
    xField,
    yField,
    yAxis: {
      label: {
        style: {
          fontFamily: graphFonts,
          fontSize: 13,
          lineHeight: 24,
          fontWeight: 400,
          letterSpacing: 0.6,
          color: "#000000",
        },
      },
    },
    xAxis: {
      label: {
        style: {
          fontFamily: graphFonts,
          fontSize: 13,
          lineHeight: 24,
          fontWeight: 400,
          letterSpacing: 0.6,
        },
      },
    },
    colorField,
    tooltip,
    color,
    legend,
    meta,
  };

  return (
    <div className="analytics-graphContainer" style={{ minHeight: `${height}px` }}>
      <Suspense fallback={<Spinner />}>
        <HeatMap {...config} />
      </Suspense>
      <Message
        className="m-ll m-rs"
        show={!loading && data?.length === 0}
        type="Insufficient Data"
      />
    </div>
  );
};

export default RCHeatMap;
