import { Component } from "react";
import { Modal, Button } from "redcircle-ui";

class EpisodeDeleteModal extends Component {
  render() {
    const { isLoading, closeModal } = this.props;
    return (
      <Modal open onClose={closeModal} loading={isLoading} size="xs">
        <Modal.Title>Delete Episode</Modal.Title>
        <Modal.Body>
          Are you sure you want to delete this episode? <em>This action cannot be undone.</em>
        </Modal.Body>
        <Modal.Footer noBorder>
          <Modal.CloseButton>Cancel</Modal.CloseButton>
          <Button
            type="primary"
            size="large"
            onClick={() =>
              this.props
                .deleteEpisode(this.props.episodeUUID, this.props.showUUID)
                .then((response) => {
                  if (response.status === 200) {
                    this.props.history.push("/shows/" + this.props.showUUID);
                    this.props.closeModal();
                  }
                })
            }>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EpisodeDeleteModal;
