import fetch from "cross-fetch";
import getAPIPrefix from "../lib/api_prefix";

export const loginWithCredInviteToken = (email: string, credentialInviteToken: string) => {
  return fetch(`${getAPIPrefix()}users/login-with-credential-invite-token`, {
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      email,
      credentialInviteToken,
    }),
  });
};
