import React, { CSSProperties, ReactNode } from "react";
import { classNames } from "react-extras";

export interface IExclusiveTag extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  disabled?: boolean;
  color?: string;
  fontSize?: string;
  backgroundRGB?: [number, number, number];
  backgroundOpacity?: number;
  additionalStyles?: CSSProperties;
}

const ExclusiveTag = ({
  children,
  disabled = false,
  color = "#577D9E",
  fontSize = "10px",
  backgroundRGB = [87, 125, 158],
  backgroundOpacity = 0.1,
  className,
  ...rest
}: IExclusiveTag) => {
  const rgbString = `${backgroundRGB.join(",")}`;

  return (
    <div
      data-testid="test-exclusivetag"
      className={classNames("exclusive-tag", className)}
      style={{
        background: `rgba(${rgbString},${disabled ? 0.05 : backgroundOpacity})`,
        ...(disabled && { opacity: 0.5 }),
        color: color,
        padding: "3px 10px 1px 10px",
        fontSize: fontSize,
        borderRadius: "4px",
        fontFamily: "Gilroy-semibold",
        textTransform: "uppercase",
        letterSpacing: "1px",
        textAlign: "center",
        display: "inline-block",
        lineHeight: "16px",
        ...rest.additionalStyles,
      }}
      {...rest}>
      {children}
    </div>
  );
};
export default ExclusiveTag;
