import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { updateCurrentRole } from "../../../actions/account";
import UserRoles from "../../../constants/roles";

class TipPage extends Component {
  UNSAFE_componentWillMount() {
    this.props.updateCurrentRole(UserRoles.Sponsor);
  }

  getShowUUID = () => {
    return this.props.match.params.showUUID;
  };
  render() {
    return <Redirect to={"/shows/" + this.getShowUUID() + "/sponsor"}></Redirect>;
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, { updateCurrentRole })(TipPage);
