import { useState } from "react";
import { Button, Modal } from "redcircle-ui";
import { showError } from "../../../actions/app";
import { cancelAudioSwap } from "../../../action_managers/campaigns";
import { useDispatchTS } from "../../../hooks/redux-ts";
import "./swap_audio_modal.scss";

interface ICancelAudioSwap {
  visible: boolean;
  setVisible: (newState: boolean) => void;
  campaignItemUUID: string;
  onCancel?: () => void;
}

const CancelAudioSwap = (props: ICancelAudioSwap) => {
  const { visible, setVisible, campaignItemUUID } = props;
  const dispatch = useDispatchTS();
  const [isLoading, setIsLoading] = useState(false);

  // Handlers
  const handleCancelAudioSwap = () => {
    setIsLoading(true);
    return dispatch(cancelAudioSwap(campaignItemUUID))
      .then((resp) => {
        if (resp.status !== 200) {
          dispatch(
            showError(
              "There was an issue cancelling the audio swap request, please try again later",
              5000
            )
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
        handleCancel();
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal open={visible} onClose={handleCancel} size="xs">
      <Modal.Title>Cancel Audio Replacement Request</Modal.Title>
      <Modal.Body>
        <p>
          Canceling this request will unpause the campaign on this podcast and their previous ad
          audio will continue to run.
        </p>
      </Modal.Body>
      <Modal.Footer noBorder>
        <Button
          type="primary"
          size="large"
          className="m-la"
          loading={isLoading}
          onClick={() => handleCancelAudioSwap()}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelAudioSwap;
