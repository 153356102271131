/* globals HTMLDivElement, MouseEvent, Node, document */
import { Calendar, DatePicker, Popover } from "antd";
import { SelectInfo } from "antd/es/calendar/generateCalendar";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import { AiOutlineCalendar, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Button, COLORS } from "redcircle-ui";
import styles from "./SuperDatePicker.module.scss";

interface IAdvancedDatePickerProps {
  value: Dayjs[];
  /**  onSubmit is called when the user submits the date picker - this can only be fired if the footer is rendered with onSubmit */
  onSubmit: (timeline: Dayjs[]) => void;
  onChange?: (timeline: Dayjs[]) => void;
  onClose?: () => void;
  /** onSelectDate is called when the user selects any date - onChange is still fired */
  onSelectDate?: (
    date: Dayjs,
    context?: {
      selectedTimeline?: Dayjs[];
      isValidSelection?: boolean;
      isSelectingStartDate?: boolean;
    }
  ) => void;
  disableDate?: (date: Dayjs) => boolean;
  /**
   * Disables default selection handlers for all dates. This is useful when attaching another selection handler to the calendar,
   * such as a secondary date selector, and prevents the default selection behavior.
   */
  disableNativeSelection?: boolean;
  renderCell?: (date: Dayjs, cell: React.ReactNode, isHovered?: boolean) => React.ReactNode;
  renderFooter: ({
    onSubmit,
    onReset,
    onClose,
    calendarRef,
    componentTimeline,
  }: {
    /** This returns undefined if the dates are illegal */
    onSubmit?: () => void;
    onReset: (value: Dayjs[]) => void;
    onClose: () => void;
    /** SuperDatePicker provides a ref to its calendar component if it is required for click handlers */
    calendarRef: React.RefObject<HTMLDivElement>;
    /** SuperDatePicker provides a reference to internal timeline state if it is required for validation */
    componentTimeline: Dayjs[];
  }) => React.ReactNode;
}

const CalendarHeader = ({
  value,
  onChange,
  calendarSide,
}: {
  value: Dayjs;
  onChange: (value: Dayjs) => void;
  calendarSide: "left" | "right" | false;
}) => {
  return (
    <div className="flex-row-container align-center justify-between p-bxxs">
      {!(calendarSide === "right") ? (
        <Button
          type="icon"
          size="small"
          style={{ width: 20, height: 20 }}
          onClick={() => onChange(value.clone().subtract(1, "month"))}>
          <AiOutlineLeft />
        </Button>
      ) : (
        <div style={{ width: 20, height: 20 }} />
      )}
      <strong>{value.format("MMMM YYYY")}</strong>
      {!(calendarSide === "left") ? (
        <Button
          type="icon"
          size="small"
          style={{ width: 20, height: 20 }}
          onClick={() => onChange(value.clone().add(1, "month"))}>
          <AiOutlineRight />
        </Button>
      ) : (
        <div style={{ width: 20, height: 20 }} />
      )}
    </div>
  );
};

/**
 * A date picker component that allows for selecting a single date or a range of dates.
 * This is designed to supersede the Ant Design date picker component for more advanced use cases.
 *
 * For now, a user is expected to use the renderFooter prop to fire the onSubmit function.
 *
 * @param value Value of the date picker, either a single date or a range of two dates - [Dayjs] or [Dayjs, Dayjs]. A single date will create a single date picker, while two dates will create a range picker.
 * @param onSubmit Function to fire when the user submits the date picker. For now, the submit function can only be passed through the renderFooter prop.
 */
export default function SuperDatePicker({
  value = [dayjs()],
  onSubmit,
  onChange,
  onSelectDate,
  onClose,
  disableDate,
  disableNativeSelection,
  renderCell,
  renderFooter,
}: IAdvancedDatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectingStartDate, setIsSelectingStartDate] = useState(false);
  const [isSelectingEndDate, setIsSelectingEndDate] = useState(false);
  const [isHoveringDate, setIsHoveringDate] = useState<Dayjs | false>(false);
  const [calendarMonth, setCalendarMonth] = useState<Dayjs | undefined>();
  const leftCalendarMonth = calendarMonth;
  const rightCalendarMonth = calendarMonth?.add(1, "month");
  const calendarRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  // close the date picker when clicking outside of the popover or calendar
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(e.target as Node) &&
        calendarRef.current &&
        !calendarRef.current.contains(e.target as Node)
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => document.removeEventListener("mousedown", handleClick);
  }, [onClose]);

  // duplicating state to maintain a temporary copy of the timeline when the datepicker is open
  const [componentTimeline, setComponentTimeline] = useState<Dayjs[]>(value);
  useEffect(() => {
    setCalendarMonth(value?.[0]);
    setComponentTimeline(value);
  }, [value, isOpen]);

  const isRangeSelection = value && value.length === 2;
  const isSubmitValid =
    isRangeSelection && componentTimeline[0]
      ? componentTimeline[0].isBefore(componentTimeline[1], "day")
      : true;

  // lol sorry this is insane but it works
  const renderCalendarCell = (date: Dayjs, calendarMonth?: Dayjs, isHovered?: boolean) => {
    const isDisabled = disableDate && disableDate(date);
    const isToday = date.isSame(dayjs(), "day");
    const isSelecting = isSelectingStartDate || isSelectingEndDate;
    const isOutsideOfMonth = calendarMonth && !date.isSame(calendarMonth, "month");

    const isStartDate = componentTimeline[0] && date.isSame(componentTimeline[0], "day");
    const isEndDate = componentTimeline[1] && date.isSame(componentTimeline[1], "day");
    const isBetweenStartAndEnd =
      date.isBefore(componentTimeline[1]) && date.isAfter(componentTimeline[0]);

    const isBetweenHoveringStart =
      isSelectingStartDate &&
      isHoveringDate &&
      isHoveringDate.isBefore(componentTimeline[1]) &&
      date.isAfter(isHoveringDate) &&
      date.isBefore(componentTimeline[1]);

    const isBetweenHoveringEnd =
      isSelectingEndDate &&
      isHoveringDate &&
      isHoveringDate.isAfter(componentTimeline[0]) &&
      date.isBefore(isHoveringDate) &&
      date.isAfter(componentTimeline[0]);

    const nonInteractingClassname = classNames(
      isBetweenStartAndEnd && styles.between,
      (isStartDate || isEndDate) && styles.active
    );

    const cell = (
      <div
        onMouseOver={() => setIsHoveringDate(date)}
        onMouseOut={() => setIsHoveringDate(false)}
        onFocus={() => setIsHoveringDate(date)}
        onBlur={() => setIsHoveringDate(false)}
        className={classNames(
          styles.cell,
          isToday && styles.today,
          isDisabled && styles.disabled,
          !isDisabled &&
            !isOutsideOfMonth &&
            classNames(
              !isSelecting || !isHoveringDate
                ? nonInteractingClassname
                : classNames(
                    (isBetweenHoveringStart || isBetweenHoveringEnd) && styles.between,
                    isSelectingStartDate && isEndDate && styles.active,
                    isSelectingEndDate && isStartDate && styles.active,
                    styles.hover
                  )
            )
        )}>
        {date.format("D")}
      </div>
    );

    if (isOutsideOfMonth) return null;
    if (renderCell && !isOutsideOfMonth) return renderCell(date, cell, isHovered);
    return cell;
  };

  const handleSelectCell = (date: Dayjs, info: SelectInfo, calendarMonth?: Dayjs) => {
    const isOutsideOfMonth = calendarMonth && !date.isSame(calendarMonth, "month");
    if (isOutsideOfMonth) return;

    if (info.source === "date") {
      const isDisabled = (disableDate && disableDate(date)) || disableNativeSelection;

      if (onSelectDate)
        onSelectDate(date, {
          selectedTimeline: componentTimeline,
          isValidSelection: !isDisabled,
          isSelectingStartDate: !isSelectingEndDate || date.isBefore(componentTimeline[0]),
        });
      if (isDisabled) return;

      let newTimeline = [date];
      if (isSelectingStartDate) {
        newTimeline = isRangeSelection ? [date, componentTimeline[1]] : [date];
        setIsSelectingStartDate(false);
        if (isRangeSelection) setIsSelectingEndDate(true);
      } else if (isSelectingEndDate) {
        // swap dates if end is before start
        newTimeline = [date];
        if (isRangeSelection) {
          newTimeline = date.isBefore(componentTimeline[0])
            ? [date, componentTimeline[0]]
            : [componentTimeline[0], date];
          setIsSelectingEndDate(false);
        }
      } else {
        // nothing is being selected, let's restart date selection
        newTimeline = isRangeSelection ? [date, componentTimeline[1]] : [date];
        if (isRangeSelection) setIsSelectingEndDate(true);
      }

      setComponentTimeline(newTimeline);
      if (onChange) onChange(newTimeline);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsSelectingStartDate(false);
    setIsSelectingEndDate(false);
    setIsHoveringDate(false);
    if (onClose) onClose();
  };

  const handleSubmit = () => {
    if (!isSubmitValid) return;
    onSubmit(componentTimeline);
    setIsSelectingStartDate(false);
    setIsSelectingEndDate(false);
    handleClose();
  };

  const handleReset = (value: Dayjs[]) => {
    setComponentTimeline(value);
    if (onChange) onChange(value);
  };

  const PopoverContent = () => (
    <div
      className="flex-column-container"
      style={{ maxWidth: isRangeSelection ? 600 : 300 }}
      ref={popoverRef}>
      <div className="flex-row-container" ref={calendarRef}>
        <Calendar
          value={leftCalendarMonth}
          fullscreen={false}
          headerRender={(props) => (
            <CalendarHeader {...props} calendarSide={isRangeSelection && "left"} />
          )}
          fullCellRender={(date) => {
            const isHovered = isHoveringDate && date.isSame(isHoveringDate, "day");
            return renderCalendarCell(date, leftCalendarMonth, isHovered);
          }}
          className={styles.calendar}
          onPanelChange={(date) => setCalendarMonth(date)}
          onSelect={(date, info) => handleSelectCell(date, info, leftCalendarMonth)}
          disabledDate={(date) => {
            const isOutsideOfMonth = leftCalendarMonth
              ? !date.isSame(leftCalendarMonth, "month")
              : false;
            return isOutsideOfMonth;
          }}
        />
        {isRangeSelection && (
          <Calendar
            value={rightCalendarMonth}
            fullscreen={false}
            headerRender={(props) => <CalendarHeader {...props} calendarSide="right" />}
            fullCellRender={(date) => {
              const isHovered = isHoveringDate && date.isSame(isHoveringDate, "day");
              return renderCalendarCell(date, rightCalendarMonth, isHovered);
            }}
            className={classNames(styles.calendar, "m-ls")}
            onPanelChange={(date) => setCalendarMonth(date.subtract(1, "month"))}
            onSelect={(date, info) => handleSelectCell(date, info, rightCalendarMonth)}
            disabledDate={(date) => {
              const isOutsideOfMonth = rightCalendarMonth
                ? !date.isSame(rightCalendarMonth, "month")
                : false;
              return isOutsideOfMonth;
            }}
          />
        )}
      </div>

      {renderFooter
        ? renderFooter({
            onSubmit: isSubmitValid ? handleSubmit : undefined,
            onClose: () => handleClose(),
            onReset: handleReset,
            calendarRef: calendarRef,
            componentTimeline,
          })
        : null}
    </div>
  );

  return (
    <Popover trigger="click" open={isOpen} content={PopoverContent}>
      <div
        className={classNames(
          styles.wrapper,
          isOpen && styles.wrapperOpen,
          "flex-row-container align-center"
        )}>
        <Button
          type="link"
          onClick={() => {
            if (!isRangeSelection) setIsSelectingStartDate(true);
            setIsOpen(true);
          }}
          className="p-a0">
          <DatePicker
            variant="borderless"
            className={styles.input}
            value={isHoveringDate && isSelectingStartDate ? isHoveringDate : componentTimeline[0]}
            style={{
              color: isHoveringDate && isSelectingStartDate ? COLORS.GRAY_LIGHT : undefined,
              borderBottom: isSelectingStartDate ? `2px solid ${COLORS.PRIMARY_COLOR}` : undefined,
            }}
            format="MM/DD/YYYY"
            placeholder="Start Date"
            panelRender={() => null}
            showNow={false}
            allowClear={false}
            suffixIcon={null}
            onFocus={() => {
              setIsSelectingEndDate(false);
              setIsSelectingStartDate(true);
            }}
            onBlur={(e) => {
              const calendarNode = calendarRef.current;
              if (calendarNode && !calendarNode.contains(e.relatedTarget)) {
                setIsSelectingStartDate(false);
              }
            }}
            onChange={(date) => handleSelectCell(date, { source: "date" })}
          />

          {isRangeSelection && (
            <>
              <span style={{ color: isOpen ? COLORS.PRIMARY_COLOR : COLORS.GRAY_LIGHT }}>-</span>
              <DatePicker
                variant="borderless"
                className={styles.input}
                value={isHoveringDate && isSelectingEndDate ? isHoveringDate : componentTimeline[1]}
                style={{
                  color: isHoveringDate && isSelectingEndDate ? COLORS.GRAY_LIGHT : undefined,
                  borderBottom: isSelectingEndDate
                    ? `2px solid ${COLORS.PRIMARY_COLOR}`
                    : undefined,
                }}
                format="MM/DD/YYYY"
                panelRender={() => null}
                placeholder="End Date"
                showNow={false}
                allowClear={false}
                suffixIcon={null}
                onFocus={() => {
                  setIsSelectingStartDate(false);
                  setIsSelectingEndDate(true);
                }}
                onBlur={(e) => {
                  if (e.relatedTarget?.className !== "ant-picker-panel") {
                    setIsSelectingEndDate(false);
                  }
                }}
                onChange={(date) => handleSelectCell(date, { source: "date" })}
              />
            </>
          )}
          <AiOutlineCalendar color={COLORS.GRAY_LIGHT} className="m-hxxs" />
        </Button>
      </div>
    </Popover>
  );
}
