import { UnixTimeStamp } from "src/lib/date";
import getAPIPrefix from "../lib/api_prefix";
import { ICampaign, User } from "redcircle-types";
import { store } from "src";
import { showWarning } from "src/actions/app";

type POSITIONS = "PREROLL" | "MIDROLL" | "POSTROLL";

export type ForecastImpressionsRequest = {
  campaignItems: {
    showUUID: string;
    positionsOverride?: Partial<Record<POSITIONS, Record<string, never>>>;
    startDateOverride?: UnixTimeStamp;
    endDateOverride?: UnixTimeStamp;
    recentEpisodesOnlyOverride?: boolean;
    frequencyConfigsOverride?: ICampaign["frequencyConfigs"];
  }[];
  positions: Partial<Record<POSITIONS, Record<string, never>>>;
  startDate: UnixTimeStamp;
  endDate: UnixTimeStamp;
  recentEpisodesOnly: boolean;
  frequencyConfigs: ICampaign["frequencyConfigs"];
};

export type ForecastImpressionResponse = {
  resultsByShowUUID: {
    [showUUID: string]:
      | {
          impressionsByPosition: Partial<Record<POSITIONS, number>>;
          streamulatorErrored: false;
        }
      | {
          impressionsByPosition: null;
          streamulatorErrored: true;
        };
  };
};

export type ForecastImpressionFailureResponse = {
  statusCode: number;
  message: string;
  details: ForecastImpressionResponse;
};

export const getCampaignForecastImpressions = (
  requestInfo: ForecastImpressionsRequest,
  user: User
) => {
  return fetch(`${getAPIPrefix()}campaign-forecast/impressions`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + user.authToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestInfo),
  });
};

export type ForecastEndDateRequest = {
  campaignItems: {
    showUUID: string;
    cpm: Partial<Record<POSITIONS, number>>;
    budget: number;
    positionsOverride?: Partial<Record<POSITIONS, Record<string, never>>>;
    startDateOverride?: UnixTimeStamp;
    recentEpisodesOnlyOverride?: boolean;
    frequencyConfigsOverride?: ICampaign["frequencyConfigs"];
  }[];
  positions: Partial<Record<POSITIONS, Record<string, never>>>;
  startDate: UnixTimeStamp;
  recentEpisodesOnly: boolean;
  frequencyConfigs: ICampaign["frequencyConfigs"];
};

export type ForecastEndDateResponse = {
  resultsByShowUUID: {
    [showUUID: string]:
      | {
          estimatedEndTime: UnixTimeStamp;
          streamulatorErrored: false;
        }
      | {
          estimatedEndTime: null;
          streamulatorErrored: true;
        };
  };
};

export type ForecastEndDateFailureResponse = {
  statusCode: number;
  message: string;
  details: ForecastEndDateResponse;
};

export const getCampaignForecastEndDates = (requestInfo: ForecastEndDateRequest, user: User) => {
  return fetch(`${getAPIPrefix()}campaign-forecast/timeline`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + user.authToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestInfo),
  });
};

export const getCampaignZippedMediaFiles = ({
  campaignUUID,
  user,
}: {
  campaignUUID: string;
  user: User;
}) => {
  return fetch(`${getAPIPrefix()}campaigns/${campaignUUID}/media-files`, {
    headers: {
      Authorization: "Bearer " + user.authToken,
      "Content-Type": "application/zip",
    },
  });
};

/**
 * Creator only endpoints, grabs total creator amount of v2 campaign items
 * @returns {number} Money in cents
 */

export const getCampaignItemTotalCreatorAmount = async (campaignItemUUID: string) => {
  try {
    const { user } = store.getState()?.user;

    const resp = await fetch(`${getAPIPrefix()}campaign-items/${campaignItemUUID}/creator-amount`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.authToken,
        "Content-Type": "application/json",
      },
    });

    if (resp.status === 200) {
      return (await resp.json()) as { totalCreatorAmount: number };
    } else {
      store.dispatch(
        showWarning(
          "Could not calculate campaign item total creator amount, Please contact RedCircle support for more information."
        )
      );
      return { totalCreatorAmount: 0 };
    }
  } catch (err) {
    console.error("Could not get campaign Item total creator Amount", err);
    return { totalCreatorAmount: 0 };
  }
};
