import get from "lodash/get";
import keyBy from "lodash/keyBy";
import { reduxForm } from "redux-form";

export const campaignEditorFormName = "campaign-editor";

export const promotionCodeValueToDisplay = {
  none: "No promotion code or vanity URL",
  podcaster_unique: "Podcasters will provide their own unique code or vanity URL tag",
  standard: "A code or URL is provided in the talking points or script",
};

export const redemptionCodeValueToDisplay = {
  mailing: "Yes, brand will ship the product to the podcaster",
  instructions: "Yes, brand will provide instructions for podcaster to redeem online",
  none: "No samples for this campaign",
};

export const CampaignEditorPageFormsWrapper = reduxForm({
  form: campaignEditorFormName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
});
export const uploadAudio = (props, formData) => {
  return props
    .fileUpload(get(formData, "contentFile.0"), get(formData, "contentFile.0.name"), (e) =>
      props.uploadProgress(e.loaded / e.total)
    )
    .then((response) => {
      return {
        status: response.status,
        mediaFileUUIDs: { ...keyBy(response.json, "bitrate") },
      };
    });
};
