import UserRoles from "../constants/roles";

const defaultRoleOrder = [
  UserRoles.Admin,
  UserRoles.Creator,
  UserRoles.Advertiser,
  UserRoles.Sponsor,
];

export default function getDefaultRole(user) {
  let finalRole = UserRoles.Creator;
  defaultRoleOrder.every((role) => {
    if (user?.roles?.[role]) {
      finalRole = role;
      return false;
    }
    return true;
  });
  return finalRole;
}
