import { Form } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { CampaignStateCompleted, CampaignStateInProgress } from "src/constants/campaigns";
import SchedulerDatePicker from "../campaign_scheduler_modal/scheduler_datepicker";
import CampaignVisualTimeline from "./campaign_visual_timeline";

export default function FlightingDateForm({
  type = "campaign",
  isV2 = true,
  isPostSend = false,
}: {
  type?: "campaign" | "campaignItem";
  isV2?: boolean;
  isPostSend?: boolean;
}) {
  const form = Form.useFormInstance();
  const formValues = Form.useWatch([], { form, preserve: true }) || {};
  const pacing = formValues.pacing as boolean;
  const timeline = formValues.timeline as dayjs.Dayjs[];
  const deadlines = formValues.deadlines;

  const campaignIsModifiable =
    type === "campaign" &&
    (formValues.state === CampaignStateInProgress || formValues.state === CampaignStateCompleted);

  const handleDeadlineChange = (value: {
    assignAudioDeadline?: Dayjs;
    responseDeadline?: Dayjs;
  }) => {
    form.setFieldValue("deadlines", { ...deadlines, ...value });
  };

  return (
    <div>
      <span className="redcircle-form-label">Timeline</span>
      <div className="flex-row-container align-center">
        <Form.Item name="timeline">
          <SchedulerDatePicker
            isRange={pacing}
            deadlineValue={deadlines}
            onDeadlineChange={handleDeadlineChange}
            isV2={isV2}
            isPostSend={campaignIsModifiable || isPostSend}
          />
        </Form.Item>
      </div>
      <CampaignVisualTimeline
        type={type}
        timeline={timeline}
        deadlines={deadlines}
        pacing={pacing}
      />
    </div>
  );
}
