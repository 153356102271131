import DefaultActionManager from "../action_managers/default_action_manager";

export const statsOptionsActionName = "STATS_OPTIONS";

export function fetchStatOptions() {
  return new DefaultActionManager({
    auth: true,
    route: "stats/options",
    actionName: statsOptionsActionName,
  }).run();
}
