import React from "react";
import { UpdateTierInfo } from "./tier_utils";
import UpdateTierPageWrapper from "./update-tier-page-wrapper";
import "./upgrade-tier.scss";

export const UpgradeTierPage = () => {
  return (
    <UpdateTierPageWrapper
      type={"upgrade"}
      ctaText={(levelDisplay) => `Upgrade to ${levelDisplay} Plan`}
      pageDisplay={(props) => (
        <UpdateTierInfo {...props} title={`Upgrade to ${props.levelDisplay} Plan`} />
      )}
    />
  );
};

export const UpdateTierPage = () => {
  return (
    <UpdateTierPageWrapper
      type={"update"}
      ctaText={() => `Update Your Plan`}
      hideNextPayment={true}
      defaultDisableCTA={true}
      pageDisplay={(props) => (
        <UpdateTierInfo
          {...props}
          title={`Update your ${props.levelDisplay} Plan`}
          hideDueToday={true}
          hideNextPayment={true}
        />
      )}
    />
  );
};

export default UpgradeTierPage;
