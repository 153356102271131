import get from "lodash/get";
import { Component } from "react";
import { Button, Col, Grid, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import { If } from "react-extras";
import Breadcrumbs from "src/components/lib/breadcrumbs/breadcrumbs";
import RCButton from "src/components/lib/button/button";
import Page from "src/components/lib/page/page";
import { itunesCategories } from "../../../data/itunes_categories";
import { formatAudienceSize } from "../../../lib/audience_size";
import ExpandableText from "../../lib/expandable_text/expandable_text";
import ExternalLink from "../../lib/external_link";
import HighlightBox from "../../lib/highlight_box";
import LoadingImage from "../../lib/image";
import Spinner from "../../lib/spinner";
import BrowseRow from "../browse_promotion/browse_row";
import PromotionsShowDetailsEpisodeList from "./promotions_show_details_episode_list";
import "./promotions_show_page.scss";

class PromotionsDetails extends Component {
  categoryList = (show) => {
    let categoryUUIDs = get(show, "categoryUUIDs", []);
    return categoryUUIDs
      .map((categoryUUID) =>
        get(
          itunesCategories.find(({ uuid }) => categoryUUID === uuid),
          "name"
        )
      )
      .join(", ");
  };
  render() {
    const show = this.props.show;

    return (
      <Page className="promotions-show-page" pageTitle="Cross Promotions">
        <Breadcrumbs crumbs={[{ name: "Browse", path: "/promotions/browse" }]} />
        <Spinner isLoading={this.props.isLoading} />
        <If condition={!this.props.isLoading && !!show}>
          <Grid fluid>
            <Row>
              <Col xs={12} md={3}>
                <LoadingImage
                  className="promotions-show-page__image"
                  imageURL={get(show, "imageURL")}
                />
              </Col>
              <Col xs={12} md={9}>
                <Row>
                  <Col xs={12}>
                    <h1 className="promotions-show-page__title">{get(show, "title")}</h1>
                    <div className="promotions-show-page__owner-name">{get(show, "ownerName")}</div>
                  </Col>
                </Row>
                <Row className="promotions-show-page__highlight-box-wrapper">
                  <Col xs={12} md={6}>
                    <HighlightBox
                      title="Weekly Audience"
                      value={formatAudienceSize(get(show, "bucketedAudienceSize"))}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <HighlightBox title="Episodes" value={get(show, "episodes", []).length} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="promotions-show-page__cross-promote-button-column">
                    <If condition={this.props.shouldDisableButton}>
                      <OverlayTrigger
                        defaultShow
                        overlay={
                          <Tooltip id="disabled-button-tip">
                            <strong>
                              You need to opt into the promotions marketplace and have at least one
                              podcast to participate in cross-promotion.
                            </strong>
                          </Tooltip>
                        }
                        show={null}
                        placement="bottom">
                        <div className="promotions-show-page__cross-promote-button-tipper" />
                      </OverlayTrigger>
                    </If>
                    <div className="promotions-show-page__cross-promote-button-wrapper">
                      <RCButton
                        type="primary"
                        size="large"
                        className="promotions-show-page__cross-promote-button width-100"
                        disabled={this.props.shouldDisableButton}
                        onClick={this.props.handleClick}>
                        Propose a Cross Promotion
                      </RCButton>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Tabs unmountOnExit mountOnEnter id={"promotions-show-page__tabs"}>
                      <Tab title={"About"} eventKey="about">
                        <ExpandableText dangerousText={get(show, "description", "")} />
                        <div className="promotions-show-details-list-row__info">
                          <If condition={Boolean(get(show, "link"))}>
                            <div className="promotions-show-details-list-row__info-item">
                              <ExternalLink href={get(show, "link")}>Podcast Website</ExternalLink>
                            </div>
                          </If>
                          <div className="promotions-show-details-list-row__info-item">
                            {this.categoryList(show)}
                          </div>
                        </div>
                      </Tab>
                      <Tab title={"Episodes"} eventKey="episodes">
                        <If condition={Boolean(get(show, "episodes.length", 0) !== 0)}>
                          <PromotionsShowDetailsEpisodeList episodes={get(show, "episodes", [])} />
                        </If>
                        <If condition={get(show, "episodes.length", 0) === 0} show={show}>
                          <div className="promotions-show-details__no-episodes-text">
                            No episodes yet.
                          </div>
                        </If>
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div className="promotion-show-details-page__similar-shows">
                      <BrowseRow
                        title={"Similar Podcasts"}
                        shows={get(this.props.categoryShows, get(show, "uuid"), [])}
                        count={4}
                        ignoreUUIDs={[get(show, "uuid")]}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </If>
      </Page>
    );
  }
}

export default PromotionsDetails;
