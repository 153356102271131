import { CheckCircleTwoTone, ExclamationCircleTwoTone } from "@ant-design/icons";
import { Input, Spin, Alert } from "antd";
import RCButton from "src/components/lib/button/button";
import { usePollingShow } from "src/hooks/shows";
import { IShow } from "redcircle-types";

const positionOrder: Record<string, number> = { PREROLL: 0, MIDROLL: 1, POSTROLL: 2 };

export default function VASTURLPollPage({
  showUUID,
  onBack,
}: {
  showUUID?: string;
  onBack: () => void;
}) {
  const { show } = usePollingShow({
    showUUID,
    stopPollAfter: (show: IShow) => {
      if (!show.vastURLs) return false;
      return show.vastURLs?.every((url) => url.verified);
    },
  });

  if (!show || !show.vastURLs || !show.vastURLs.length) return null;
  const allShowsVerified = show.vastURLs.every((url) => url.verified);
  const hasAnyErrors = show.vastURLs.some((url) => url.friendlyError);

  const sortedURLs = show.vastURLs.sort((a, b) => {
    if (positionOrder[a.position] < positionOrder[b.position]) return -1;
    if (positionOrder[a.position] > positionOrder[b.position]) return 1;
    return a.positionIndex - b.positionIndex;
  });

  return (
    <div className="flex-column-container">
      {!allShowsVerified && !hasAnyErrors && (
        <p>
          Hold tight! We're verifying that all of your VAST URLs are configured properly. This may
          take a little bit - you can come back later if you'd like.
        </p>
      )}

      {hasAnyErrors && (
        <p>
          Some of your VAST URLs are not configured properly. Please{" "}
          <RCButton type="link" className="p-a0" onClick={onBack}>
            go back
          </RCButton>
          , check the instructions and try again.
        </p>
      )}

      {sortedURLs.map((url, index) => {
        return (
          <div key={url.url} className="m-bxxs">
            <div className="flex-row-container align-center" key={url.url}>
              <Input
                type="filled"
                value={url.url}
                disabled
                addonBefore={`${url.position} ${url.positionIndex + 1}`}
              />
              <span className="m-lxs">
                {url.friendlyError && <ExclamationCircleTwoTone twoToneColor="#eb2f96" />}
                {!url.friendlyError &&
                  (url.verified ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <Spin />)}
              </span>
            </div>
            {url.friendlyError && (
              <Alert message={url.friendlyError} type="error" className="m-txxs m-bxxs" />
            )}
          </div>
        );
      })}

      {allShowsVerified && (
        <p>
          <strong>All VAST URLs are verified!</strong>
        </p>
      )}
    </div>
  );
}

export const VASTURLPollFooter = ({
  show,
  onBack,
  onFinish,
}: {
  show?: IShow;
  onBack: () => void;
  onFinish: () => void;
}) => {
  const allShowsVerified = show?.vastURLs?.every((url) => url.verified);
  return (
    <>
      <RCButton type="link" size="large" onClick={onBack} className="p-a0">
        Back
      </RCButton>
      {allShowsVerified && (
        <RCButton type="primary" size="large" className="m-la" onClick={onFinish}>
          Done
        </RCButton>
      )}
    </>
  );
};
