export var itunesCategoryGroups = {
  "00917086-bacc-4e01-9f94-eefcff96b8dd": "Arts",
  "96a84fa1-4480-41d6-a3d8-3a740bf49538": "Business",
  "d850044e-48de-45b9-8d7d-bb54dde56fb2": "Comedy",
  "1a4f61a2-db29-4bab-9232-52c19351107b": "Education",
  "7ab8068f-59cb-4587-ba7b-a872a3e5189b": "Fiction",
  "9aa5a11d-7e81-4d21-b231-cc2a8481d751": "Health & Fitness",
  "c274d8e6-a37b-4c0b-826d-18ffb35adb6f": "Kids & Family",
  "b7e2e4df-63d0-4216-bef1-9dc465aba329": "Leisure",
  "50589381-247b-49f4-9c1c-5a991abea1e8": "Music",
  "9280af6c-72b1-486c-b241-41efc0abea3d": "News",
  "d1fdcb80-2559-4b25-a94b-9cf6bcc02142": "Religion & Spirituality",
  "9e7819be-e130-4689-a10b-6e52fa88b315": "Science",
  "429642e8-792a-4516-8ac1-faf0b761294e": "Society & Culture",
  "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0": "Sports",
  "0cbdff14-20dc-41b3-a573-4f1d610cf84d": "TV & Film",
};
