import { Form } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Loading, Modal, MultiModal } from "redcircle-ui";
import { showError, showSuccess } from "src/actions/app";
import { updateShow } from "src/action_managers/shows";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { IGenderBreakdown, IShow } from "redcircle-types";
import {
  getDefaultAdvertisementSettings,
  getDefaultListenerDemographics,
  getDefaultProgrammaticSettings,
} from "./default_settings";
import RAPSettingsFormExclusions from "./rap_settings_form_exclusions";
import RAPSettingsFormHosts from "./rap_settings_form_hosts";
import RAPSettingsFormListeners from "./rap_settings_form_listeners";
import RAPSettingsFormProgrammatic from "./rap_settings_form_programmatic";
import RAPSettingsFormPromoCode from "./rap_settings_form_promo_code";
import RAPSettingsFormRates from "./rap_settings_form_rates";
import RAPSettingsFormSocial from "./rap_settings_form_social";

interface IProps {
  open: boolean;
  onClose: () => void;
  show?: IShow;
}

export const ShowAdvertisingSettingsModalFooter = ({
  onSubmit,
  onFinish,
  nextURL,
  prevURL,
  mode,
}: {
  onSubmit: () => void;
  onFinish?: () => void;
  nextURL?: string;
  prevURL?: string;
  currentPage?: number;
  mode?: "create" | "edit";
}) => {
  const history = useHistory();
  const form = Form.useFormInstance();
  const fields = Form.useWatch([], form); // watch all fields
  const [formHasError, setFormHasError] = useState(false);
  const [isSubmitLoading, setisSubmitLoading] = useState(false);

  useEffect(() => {
    validateForm();
  }, [fields]);

  const validateForm = async () => {
    try {
      await form.validateFields();
      setFormHasError(false);
    } catch (e: any) {
      const hasError = e.errorFields?.length > 0;
      setFormHasError(hasError);
    }
  };

  const handleSubmitAndNav = async (url?: string) => {
    if (mode === "edit") {
      setisSubmitLoading(true);
      await onSubmit();
      setisSubmitLoading(false);
    }

    if (mode === "create" && onFinish) {
      setisSubmitLoading(true);
      await onSubmit();
      setisSubmitLoading(false);
      onFinish();
    }

    if (url) history.push(url);
  };

  return (
    <>
      {mode === "edit" && <Modal.CloseButton />}

      {mode === "create" && prevURL && (
        <Button type="link" onClick={() => history.push(prevURL)} size="large">
          Back
        </Button>
      )}

      <div className="flex-row-container align-center m-la">
        {mode === "edit" && nextURL && (
          <Button
            type="link"
            onClick={() => handleSubmitAndNav()}
            size="large"
            className="m-rxs"
            disabled={formHasError}>
            {isSubmitLoading ? <Loading /> : "Save"}
          </Button>
        )}

        <Modal.SubmitButton
          onClick={() => handleSubmitAndNav(nextURL)}
          isLoading={isSubmitLoading}
          disabled={formHasError}>
          {mode === "create" && !nextURL && "I Agree"}
          {mode === "edit" && !nextURL && "Save"}
          {nextURL && "Save and Continue"}
        </Modal.SubmitButton>
      </div>
    </>
  );
};

export default function ShowAdvertisingSettingsModal({ open, onClose, show }: IProps) {
  const dispatch = useDispatchTS();

  const { showUUID, modifierUUID } = useParams<{ showUUID: string; modifierUUID?: string }>();
  const { user } = useSelectorTS((state) => state.user);
  const [form] = Form.useForm();
  const [mode, setMode] = useState<"create" | "edit">("edit"); // flag for first time joining RAP

  useEffect(() => {
    if (show && open) {
      setMode(!show.advertisementSettings ? "create" : "edit");
      const newShow = cloneDeep(show);

      const advertisementSettings =
        newShow.advertisementSettings || getDefaultAdvertisementSettings(newShow, user);

      advertisementSettings.hostReadPreRollCPM = advertisementSettings.hostReadPreRollCPM / 100;
      advertisementSettings.hostReadMidRollCPM = advertisementSettings.hostReadMidRollCPM / 100;
      advertisementSettings.hostReadPostRollCPM = advertisementSettings.hostReadPostRollCPM / 100;
      if (advertisementSettings.excludedCategories === null) {
        advertisementSettings.excludedCategories = [];
      }

      // Setting default 'excludedBrandInstanceUUIDs'
      advertisementSettings.excludedBrandInstanceUUIDs = Array.isArray(
        newShow?.advertisementSettings?.excludedBrandInstanceUUIDs
      )
        ? [...newShow.advertisementSettings.excludedBrandInstanceUUIDs]
        : [];

      const listenerDemographics = newShow.listenerDemographics || getDefaultListenerDemographics();
      Object.keys(listenerDemographics.genderBreakdown).forEach((key) => {
        listenerDemographics.genderBreakdown[key as keyof IGenderBreakdown] =
          listenerDemographics.genderBreakdown[key as keyof IGenderBreakdown] * 100;
      });
      listenerDemographics.ageBuckets.forEach((bucket) => {
        bucket.percentage = bucket.percentage * 100;
      });

      const programmaticAdSettings =
        newShow.programmaticAdSettings || getDefaultProgrammaticSettings(show);

      // be careful of shallow copies - need to make sure we're not mutating the original object
      form.setFieldsValue({
        advertisementSettings,
        categoryUUIDs: newShow.categoryUUIDs,
        listenerDemographics,
        promoCode: newShow.promoCode,
        programmaticAdSettings,
      });
    }

    if (!open) {
      form.resetFields();
    }
  }, [show, open]);

  const handleSubmit = async () => {
    if (show) {
      const formValues = form.getFieldsValue(true);
      const fixedValues = cloneDeep(formValues);
      let payload: Record<string, any> = {};

      fixedValues.advertisementSettings = {
        ...fixedValues.advertisementSettings,
        hostReadPreRollCPM: fixedValues.advertisementSettings.hostReadPreRollCPM * 100,
        hostReadMidRollCPM: fixedValues.advertisementSettings.hostReadMidRollCPM * 100,
        hostReadPostRollCPM: fixedValues.advertisementSettings.hostReadPostRollCPM * 100,
      };
      Object.keys(fixedValues.listenerDemographics.genderBreakdown).forEach((key) => {
        fixedValues.listenerDemographics.genderBreakdown[key as keyof IGenderBreakdown] =
          fixedValues.listenerDemographics.genderBreakdown[key as keyof IGenderBreakdown] / 100;
      });
      fixedValues.listenerDemographics.ageBuckets.forEach((bucket: any) => {
        bucket.percentage = bucket.percentage / 100;
      });

      // while creating, submit all at once at end
      if (mode === "create") {
        payload = { ...fixedValues };
      }

      // while editing, submitting is by page
      if (mode === "edit") {
        const currentValues = form.getFieldsValue();
        [
          "advertisementSettings",
          "promoCode",
          "listenerDemographics",
          "categoryUUIDs",
          "programmaticAdSettings",
        ].forEach((key) => {
          if (currentValues[key]) payload[key] = fixedValues[key];
        });
      }

      if (Object.keys(payload).length > 0) {
        const res = await dispatch(updateShow({ uuid: show.uuid, ...payload }));
        if (res.status !== 200) {
          dispatch(showError("Something went wrong. Please try again."));
        } else {
          dispatch(showSuccess("Podcast settings saved."));
        }
        return res;
      }
    }
  };

  const pages = [
    {
      id: "rates",
      pretitle: <span className="redcircle-form-label">Host-Read Ads</span>,
      title: `Rates`,
      subtitle: (
        <span>
          Configure your gross rates. For more information, read our{" "}
          <a
            href="https://support.redcircle.com/guide-to-setting-host-read-ad-rates-in-rap"
            target="_blank"
            rel="noreferrer">
            Guide to Setting Advertising Rates.
          </a>
        </span>
      ),
      labelSection: "Host-Read Ads",
      label: (
        <Link to={`/ad-platform/${showUUID}/settings/rates`}>
          <Button
            type={modifierUUID === "rates" ? "primary" : "link"}
            disabled={mode === "create" && modifierUUID !== "rates"}>
            Rates
          </Button>
        </Link>
      ),
      body: show ? <RAPSettingsFormRates show={show} /> : <Loading />,
      footer: (
        <ShowAdvertisingSettingsModalFooter
          onSubmit={handleSubmit}
          mode={mode}
          nextURL={show && `/ad-platform/${show.uuid}/settings/promo-code`}
        />
      ),
    },
    {
      id: "promo-code",
      pretitle: <span className="redcircle-form-label">Host-Read Ads</span>,
      title: `Promo Code`,
      subtitle: `It’s common that brands may want you to incorporate your unique code into your ad read either as a promo code or at the end of a vanity URL. Choose a promo code for your show that is easy for your listeners to remember.`,
      labelSection: "Host-Read Ads",
      label: (
        <Link to={`/ad-platform/${showUUID}/settings/promo-code`}>
          <Button
            type={modifierUUID === "promo-code" ? "primary" : "link"}
            disabled={mode === "create" && modifierUUID !== "promo-code"}>
            Promo Code
          </Button>
        </Link>
      ),
      body: show ? <RAPSettingsFormPromoCode show={show} /> : <Loading />,
      footer: (
        <ShowAdvertisingSettingsModalFooter
          onSubmit={handleSubmit}
          mode={mode}
          nextURL={show && `/ad-platform/${show.uuid}/settings/hosts`}
          prevURL={show && `/ad-platform/${show.uuid}/settings/rates`}
        />
      ),
    },
    {
      id: "hosts",
      pretitle: <span className="redcircle-form-label">Host-Read Ads</span>,
      title: `Hosts`,
      subtitle: `Who are the hosts of your show? Include your mailing address so brands you advertise with can send you free product as part of the campaign.`,
      labelSection: "Host-Read Ads",
      label: (
        <Link to={`/ad-platform/${showUUID}/settings/hosts`}>
          <Button
            type={modifierUUID === "hosts" ? "primary" : "link"}
            disabled={mode === "create" && modifierUUID !== "hosts"}>
            Hosts
          </Button>
        </Link>
      ),
      body: show ? <RAPSettingsFormHosts /> : <Loading />,
      footer: (
        <ShowAdvertisingSettingsModalFooter
          onSubmit={handleSubmit}
          mode={mode}
          nextURL={show && `/ad-platform/${show.uuid}/settings/listeners`}
          prevURL={show && `/ad-platform/${show.uuid}/settings/promo-code`}
        />
      ),
    },
    {
      id: "listeners",
      pretitle: <span className="redcircle-form-label">Host-Read Ads</span>,
      subtitle: (
        <span>
          Who are your listeners? Advertisers use these demographics to select which shows to work
          with. Go to your{" "}
          <strong>
            <a href="https://podcasters.spotify.com/audience" target="blank" rel="noreferrer">
              Spotify for Podcasters account
            </a>
          </strong>{" "}
          → "Audience" page → filter on "All time" to find this breakdown, or give a best guess.
        </span>
      ),
      title: `Listeners`,
      labelSection: "Host-Read Ads",
      label: (
        <Link to={`/ad-platform/${showUUID}/settings/listeners`}>
          <Button
            type={modifierUUID === "listeners" ? "primary" : "link"}
            disabled={mode === "create" && modifierUUID !== "listeners"}>
            Listeners
          </Button>
        </Link>
      ),
      body: show ? <RAPSettingsFormListeners /> : <Loading />,
      footer: (
        <ShowAdvertisingSettingsModalFooter
          onSubmit={handleSubmit}
          mode={mode}
          nextURL={show && `/ad-platform/${show.uuid}/settings/social-media`}
          prevURL={show && `/ad-platform/${show.uuid}/settings/hosts`}
        />
      ),
    },
    {
      id: "social-media",
      pretitle: <span className="redcircle-form-label">Host-Read Ads</span>,
      title: `Social Media`,
      subtitle:
        "Share your social media accounts so advertisers can get to know your show and audience better.",
      labelSection: "Host-Read Ads",
      label: (
        <Link to={`/ad-platform/${showUUID}/settings/social-media`}>
          <Button
            type={modifierUUID === "social-media" ? "primary" : "link"}
            disabled={mode === "create" && modifierUUID !== "social-media"}>
            Social Media
          </Button>
        </Link>
      ),
      body: show ? <RAPSettingsFormSocial /> : <Loading />,
      footer: (
        <ShowAdvertisingSettingsModalFooter
          onSubmit={handleSubmit}
          mode={mode}
          nextURL={show && `/ad-platform/${show.uuid}/settings/programmatic`}
          prevURL={show && `/ad-platform/${show.uuid}/settings/listeners`}
        />
      ),
    },
    {
      id: "programmatic",
      pretitle: <span className="redcircle-form-label">Programmatic Ads</span>,
      title: "Settings",
      labelSection: "Programmatic Ads",
      label: (
        <Link to={`/ad-platform/${showUUID}/settings/programmatic`}>
          <Button
            type={modifierUUID === "programmatic" ? "primary" : "link"}
            disabled={mode === "create" && modifierUUID !== "programmatic"}>
            Settings
          </Button>
        </Link>
      ),
      body: show ? <RAPSettingsFormProgrammatic show={show} /> : <Loading />,
      footer: (
        <ShowAdvertisingSettingsModalFooter
          onSubmit={handleSubmit}
          mode={mode}
          nextURL={show && `/ad-platform/${show.uuid}/settings/exclusions`}
          prevURL={show && `/ad-platform/${show.uuid}/settings/social-media`}
        />
      ),
    },
    {
      id: "exclusions",
      pretitle: <span className="redcircle-form-label">Configuration</span>,
      title: "Exclusions",
      labelSection: "Configuration",
      label: (
        <Link to={`/ad-platform/${showUUID}/settings/exclusions`}>
          <Button
            type={modifierUUID === "exclusions" ? "primary" : "link"}
            disabled={mode === "create" && modifierUUID !== "exclusions"}>
            Exclusions
          </Button>
        </Link>
      ),
      body: show ? <RAPSettingsFormExclusions /> : <Loading />,
      footer: (
        <ShowAdvertisingSettingsModalFooter
          onSubmit={handleSubmit}
          mode={mode}
          nextURL={
            show && mode == "create" ? `/ad-platform/${show.uuid}/settings/review` : undefined
          }
          prevURL={show && `/ad-platform/${show.uuid}/settings/exclusions`}
        />
      ),
    },
  ];

  if (mode === "create") {
    pages.push({
      id: "review",
      pretitle: <span className="redcircle-form-label">Review</span>,
      title: "Finish",
      subtitle:
        "You’re almost there! Please review and agree below to complete your show’s opt-in to RedCircle’s Ad Platform (RAP).",
      labelSection: "Review",
      label: (
        <Link to={`/ad-platform/${showUUID}/settings/review`}>
          <Button
            type={modifierUUID === "review" ? "primary" : "link"}
            disabled={mode === "create" && modifierUUID !== "review"}>
            Review
          </Button>
        </Link>
      ),
      body: (
        <div>
          <strong>By opting into RAP, you’re agreeing that we can:</strong>
          <p>
            Share information about your podcast with advertisers, including your show size,
            category, rates, etc.
          </p>
          <p>
            Automatically create a “Default Ad Block” audio block and assign it to pre-roll and
            post-roll insertion points across your back catalog. Note: these insertion points are
            placeholders for future ads and will be used when you’ve accepted a host read campaign,
            or if you’re opted into programmatic ads.
          </p>
          <p>Add a link in your episode notes so brands know where to contact you for deals.</p>
          <p>Add a link in your episode notes so listeners can access our privacy policy.</p>
        </div>
      ),
      footer: (
        <ShowAdvertisingSettingsModalFooter
          onSubmit={handleSubmit}
          mode={mode}
          prevURL={show && `/ad-platform/${show.uuid}/settings/exclusions`}
          onFinish={() => onClose()}
        />
      ),
    });
  }

  const currentPage = pages.findIndex((page) => page.id === modifierUUID);
  return (
    <Form form={form}>
      <MultiModal
        navStyle="list"
        open={open}
        onClose={onClose}
        currentPage={currentPage}
        pages={pages}
        maskClosable={false}
      />
    </Form>
  );
}
