import { Tag } from "antd";
import classNames from "classnames";
import { COLORS } from "../../theme/colors";
import styles from "./Tag.module.scss";

interface IProps {
  children: React.ReactNode;
  type?: "success" | "warning" | "error" | "default";
  icon?: React.ReactNode;
  color?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const tagTypeToColor = {
  success: "green",
  warning: "gold",
  error: "red",
  default: COLORS.BLUE_LIGHTER,
};

export default function RCTag({
  children,
  type = "default",
  color,
  icon,
  disabled,
  style = {},
  className,
}: IProps) {
  // color will take precedent over type
  const tagColor = color || tagTypeToColor[type];

  return (
    <Tag
      icon={icon}
      color={tagColor}
      bordered={false}
      className={classNames(styles.tag, className)}
      style={{
        ...(type === "default" && !color && { color: COLORS.BLUE_MEDIUM }),
        ...(disabled && { opacity: 0.5 }),
        ...style,
      }}>
      {children}
    </Tag>
  );
}
