import { Select } from "antd";
import { useDispatch } from "react-redux";
import { godmodeUpdateTierLevel } from "../../../action_managers/tiers";
import { tierLevels } from "../../../constants/tiers";
import { useSelectorTS } from "../../../hooks/redux-ts";

const TierControls = () => {
  const userState = useSelectorTS((state) => state.user);
  const dispatch = useDispatch();

  return (
    <>
      <h4>Tier Controls</h4>
      <p>Current Tier: {userState.tier?.level}</p>
      <p>Temporarily view as different tier:</p>
      <Select
        className={"width-100"}
        value={userState.tier?.level}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        onChange={(lvl) => {
          dispatch(godmodeUpdateTierLevel(lvl));
        }}>
        {Object.keys(tierLevels).map((level) => (
          <Select.Option key={level} value={level}>
            {level}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default TierControls;
