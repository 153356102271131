import React, { useState } from "react";
import "./product-tour-popover.scss";

// useProductTourPopover allows multiple popovers on a page by returning current, fn to get to next
export const useProductTourPopover = (currentIndex) => {
  const [index, setCurrentIndex] = useState(currentIndex);

  const next = () => {
    if (!index || index < 0) {
      return;
    }
    setCurrentIndex(index + 1);
  };

  return [index, next];
};

const ProductTourPopover = (props) => {
  const {
    index,
    currentIndex,
    children,
    show = false,
    arrowOffsetLeft,
    arrowOffsetTop,
    positionLeft,
    positionTop,
    ...rest
  } = props;

  if (index !== currentIndex && !show) {
    return null;
  }

  return (
    <div {...rest} key={index}>
      <div className="product-tour-popover">{children}</div>
    </div>
  );
};

export default ProductTourPopover;
