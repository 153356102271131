import { TreeSelect } from "antd";
import { head, sortBy, tail, uniq } from "lodash";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import React, { Component } from "react";
import { Col, ControlLabel, FormGroup, Grid, Row } from "react-bootstrap";
import { classNames, If } from "react-extras";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { showError } from "src/actions/app";
import { languageChoices } from "../../../constants/language-choices";
import { tierLevels } from "../../../constants/tiers";
import { itunesCategories, itunesCategoriesMap } from "../../../data/itunes_categories";
import { itunesCategoryGroups } from "../../../data/itunes_category_groups";
import adsPreviewURL from "../../../images/ads-preview.png";
import { shouldShowTierPerm } from "../../../lib/feature_flag";
import { seedcastForm } from "../../../lib/forms";
import { ACTIONS, CATEGORIES, gtagEvent } from "../../../lib/google_analytics";
import { slugify } from "../../../lib/strings";
import { withTiers } from "../../../lib/tier-utils";
import Checkbox from "../../forms/checkbox";
import FormElement from "../../forms/form_element";
import ImageUploader from "../../forms/image_uploader/image_uploader";
import Divider from "../../lib/divider";
import FormDropDown from "../../lib/form_dropdown";
import UpgradeTag from "../../lib/upgrade-tag";
import "./show-form.scss";
import QuillEditor from "src/components/forms/quill_component";

class ShowForm extends Component {
  constructor(props) {
    const { initialValues } = props;
    super(props);
    this.state = {
      chartableChecked: initialValues.enclosurePrefixSettings?.chartablePrefix || false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isSuccess && nextProps.isSuccess) {
      this.props.close();
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.mode === "create") {
      if (
        nextProps.title &&
        nextProps.title !== this.props.title &&
        isEmpty(get(this.props, ["initialValues", "alias"]))
      ) {
        this.props.changeFieldValue("alias", slugify(nextProps.title));
      }
    }
  }

  componentDidMount() {
    /**
     * Helps initialize React quill controlled component
     */
    if (this.props.mode?.toLowerCase() === "edit") {
      this.props.change("description", this.props.initialValues.description);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { validationErrors, dispatch } = this.props;

    // clear field on uncheck
    if (!this.state.chartableChecked && prevState.chartableChecked) {
      this.props.change("enclosurePrefixSettings.chartablePrefix", null);
    }

    // fire special error message
    if (
      (!prevProps.validationErrors || !prevProps.validationErrors.length) &&
      validationErrors &&
      validationErrors.find((err) => err.fieldName === "chartableUrl")
    ) {
      dispatch(
        showError(validationErrors.find((err) => err.fieldName === "chartableUrl").errorMessage)
      );
    }
  }

  // TODO: this is actually not fired as we are using mysteriously redux handlers to submit with a
  // button outside the form... going to integrate this when we get to refactoring modals.
  // add validation on submit to check if chartableChecked is checked
  onSubmit = (...args) => {
    gtagEvent(ACTIONS.SUBMIT, CATEGORIES.SHOW_FORM);
    this.props.handleSubmit(...args);
  };

  onFieldChange = () => {
    gtagEvent(ACTIONS.FILL, CATEGORIES.SHOW_FORM);
  };

  handleChartableChecked = (e) => {
    const checked = e.target.checked;
    this.setState({ chartableChecked: checked });
  };

  noRelatedShowsAllowed = () => {
    return this.props.hasAllowedTier(tierLevels.growth, false);
  };

  prefixesAllowed = () => {
    return this.props.hasAllowedTier(tierLevels.pro, false);
  };

  render() {
    const { initialValues, showContent, validationErrors } = this.props;
    if (!showContent) return null;
    return (
      <form onSubmit={this.onSubmit} className={"show-form-container"}>
        <Grid fluid>
          <Row>
            <Col md={3} mdOffset={1}>
              <div>
                <Field
                  component={FormElement}
                  InputComponent={ImageUploader}
                  user={this.props.user}
                  label="Image File"
                  name="imageFile"
                  setImageFileData={(data) => this.props.setImageFileData(data)}
                  onUpload={() => gtagEvent(ACTIONS.UPLOAD, CATEGORIES.SHOW_IMAGE)}
                  mediaFileUUID={initialValues && initialValues.imageMediaFileUUID}
                  setImageFileUUID={this.props.setImageFileUUID}
                  setError={this.props.setError}
                  mediaFiles={this.props.mediaFiles}
                  validationErrors={this.props.validationErrors}
                  validationErrorKeyOverride="imageMediaFileUUID"
                />
              </div>
            </Col>
            <Col md={6} mdOffset={1}>
              <div className={"show-form show-" + this.props.mode + "-form"}>
                <div>
                  <Field
                    component={FormElement}
                    onChange={this.onFieldChange}
                    name="title"
                    label="Name"
                    type="text"
                    validationErrors={this.props.validationErrors}
                  />
                </div>
                <div className={"episode-form__quill-container"}>
                  <Field
                    component={FormElement}
                    onChange={this.onFieldChange}
                    InputComponent={QuillEditor}
                    name="description"
                    label="Description"
                    value={this.props.description}
                    validationErrors={this.props.validationErrors}
                    infoText="A description explaining the content of your show."
                  />
                </div>

                <div>
                  <Field
                    component={CategoryTreeField}
                    name={"categoryUUIDs"}
                    type="select-multiple"
                    props={{ validationError: this.props.validationErrors }}
                  />
                </div>
                <div>
                  <Field
                    component={FormElement}
                    onChange={this.onFieldChange}
                    name="ownerEmail"
                    label="Contact Email"
                    type="email"
                    validationErrors={this.props.validationErrors}
                  />
                </div>
                <div>
                  <Field
                    component={FormElement}
                    onChange={this.onFieldChange}
                    name="ownerName"
                    label="Author Name"
                    type="text"
                    infoText="The name of the author of the show."
                    validationErrors={this.props.validationErrors}
                  />
                </div>
                <Divider />
                <FormDropDown
                  validationErrors={this.props.validationErrors}
                  label="Advanced Settings">
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="subtitle"
                      label="Subtitle"
                      type="text"
                      validationErrors={this.props.validationErrors}
                      infoText="A short version of your description."
                    />
                  </div>

                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      componentClass="textarea"
                      name="summary"
                      label="Apple: Summary"
                      type="textarea"
                      rows="10"
                      infoText="If left blank we will default to description. This will be used for apps that don't support an html description like Apple Podcasts."
                      validationErrors={this.props.validationErrors}
                    />
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="episodeDescriptionSuffix"
                      componentClass="textarea"
                      label="Episode Suffix"
                      type="textarea"
                      rows="2"
                      infoText="Message to append to all episodes in this podcast, such as promo codes or reminders for your listeners."
                      validationErrors={this.props.validationErrors}
                    />
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="language"
                      label="Language"
                      type="select"
                      componentClass="select"
                      placeholder="Select a language"
                      validationErrors={this.props.validationErrors}>
                      {languageChoices}
                    </Field>
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="itunesType"
                      label="Episodic vs. Serial"
                      type="select"
                      componentClass="select"
                      infoText="Episodic shows have the most recent episodes listed first, while serial shows start from your oldest episode. Note that only some podcasting apps that support this feature."
                      validationErrors={this.props.validationErrors}>
                      <option value={"episodic"}>Episodic</option>
                      <option value={"serial"}>Serial</option>
                    </Field>
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="copyright"
                      label="Copyright Info"
                      type="text"
                      infoText="Copyright license for your show."
                      validationErrors={this.props.validationErrors}
                    />
                  </div>
                  <div>
                    <Field component="input" name="isComplete" type="hidden" />
                  </div>
                  <div>
                    <Field component="input" name="isVisible" type="hidden" />
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="link"
                      label="External Link"
                      type="text"
                      infoText="Fill in if you have a website for your podcast."
                      validationErrors={this.props.validationErrors}
                    />
                  </div>
                  <div>
                    <Field
                      infoText="Locking may help prevent your podcast from being imported into another hosting setup. You can unlock it at any time."
                      component={FormElement}
                      onChange={this.onFieldChange}
                      InputComponent={Checkbox}
                      name="isImportLocked"
                      checkboxLabel="Podcast is Locked"
                      type="checkbox"
                      validationErrors={this.props.validationErrors}
                    />
                  </div>
                  <div>
                    <Field
                      infoText="Mark true if your show will contain explicit content."
                      component={FormElement}
                      onChange={this.onFieldChange}
                      InputComponent={Checkbox}
                      name="isExplicit"
                      checkboxLabel="Show is Explicit"
                      type="checkbox"
                      validationErrors={this.props.validationErrors}
                    />
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="alias"
                      label="Podcast Webpage Custom Link"
                      infoText="A custom link to share your show with others."
                      helpText={
                        <span
                          className="m-ls"
                          style={{ textTransform: "lowercase", fontSize: "10px" }}>
                          {`redcircle.com/shows/${this.props.alias}`}
                        </span>
                      }
                      validationErrors={this.props.validationErrors}
                    />
                  </div>
                  <If condition={shouldShowTierPerm()}>
                    <div className={"position-relative m-bs"}>
                      <Field
                        disabled={!this.noRelatedShowsAllowed()}
                        component={FormElement}
                        onChange={this.onFieldChange}
                        InputComponent={Checkbox}
                        name="hideRelatedShows"
                        checkboxLabel={"Remove ‘Related Shows’ from Webpage"}
                        type="checkbox"
                        validationErrors={this.props.validationErrors}
                      />
                      <img
                        src={adsPreviewURL}
                        alt="webpage-preview"
                        style={{
                          marginLeft: 20,
                          opacity: this.noRelatedShowsAllowed() ? 1 : 0.3,
                        }}
                      />
                      {!this.noRelatedShowsAllowed() && (
                        <div style={{ position: "absolute", left: 311, top: 0 }}>
                          <UpgradeTag />
                        </div>
                      )}
                    </div>
                  </If>
                  <If condition={shouldShowTierPerm() && this.props.mode === "edit"}>
                    <div className="m-bxxs">
                      <div className="flex-row-container align-center">
                        <label className="redcircle-form-label control-label m-b0">
                          Third-Party Prefixes
                        </label>
                        {!this.prefixesAllowed() && <UpgradeTag className="m-lxxs" />}
                      </div>
                      <span className="fs-11">
                        Adding more than 1 prefix may cause listening delays.
                      </span>
                    </div>
                    <Field
                      disabled={!this.prefixesAllowed()}
                      component={FormElement}
                      onChange={this.onFieldChange}
                      InputComponent={Checkbox}
                      name="enclosurePrefixSettings.podsightsEnabled"
                      checkboxLabel="Spotify Ad Analytics (aka Podsights) Prefix"
                      type="checkbox"
                      validationErrors={this.props.validationErrors}
                    />
                    <Field
                      disabled={!this.prefixesAllowed()}
                      component={FormElement}
                      onChange={this.onFieldChange}
                      InputComponent={Checkbox}
                      name="enclosurePrefixSettings.podtracEnabled"
                      checkboxLabel="Podtrac Prefix"
                      type="checkbox"
                      validationErrors={this.props.validationErrors}
                    />
                    <Field
                      disabled={!this.prefixesAllowed()}
                      component={FormElement}
                      onChange={this.onFieldChange}
                      InputComponent={Checkbox}
                      name="enclosurePrefixSettings.podscribeEnabled"
                      checkboxLabel="Podscribe Prefix"
                      type="checkbox"
                      validationErrors={this.props.validationErrors}
                    />
                    <div className="m-bm">
                      <Checkbox
                        disabled={!this.prefixesAllowed()}
                        checkboxLabel="Chartable Prefix"
                        checked={this.state.chartableChecked}
                        onChange={this.handleChartableChecked}
                      />
                      {this.state.chartableChecked && (
                        <Field
                          component={FormElement}
                          onChange={(value) => {
                            this.onFieldChange();
                            if (value && !this.state.chartableChecked) {
                              this.setState({ chartableChecked: true });
                            }
                          }}
                          name="enclosurePrefixSettings.chartablePrefix"
                          placeholder="https://chtbl.com/track/xxx/"
                          validationErrors={validationErrors}
                          validationErrorKeyOverride="chartablePrefix"
                        />
                      )}
                    </div>
                  </If>
                </FormDropDown>
                <div>
                  <Field
                    component="input"
                    name="enclosurePrefixSettings.gumballPrefix"
                    type="hidden"
                  />
                </div>
                <div>
                  <Field component="input" name="imageMediaFileUUID" type="hidden" />
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </form>
    );
  }

  getCategoryGroups = () => {
    var data = [];
    var categoryGroups = {};
    for (var groupUUID of Object.keys(itunesCategoryGroups)) {
      var group = itunesCategoryGroups[groupUUID];
      categoryGroups[groupUUID] = group;
    }

    for (var category of itunesCategories) {
      data.push({
        name: category.name,
        uuid: category.uuid,
        groupName: categoryGroups[category.group_uuid]
          ? categoryGroups[category.group_uuid]
          : "Other Categories",
      });
    }

    data.sort(function (a, b) {
      var textA = a.groupName.toUpperCase();
      var textB = b.groupName.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    return data;
  };
}
// we put this here because typescript can have a better grip of the types
const itunesCategoriesNodeEdge = groupBy(
  itunesCategories.filter(({ group_uuid }) => group_uuid),
  "group_uuid"
);
const parentWithNoChildren = itunesCategories.filter(
  ({ group_uuid, uuid }) => !group_uuid && !itunesCategoriesNodeEdge[uuid]
);
const treeData = sortBy(
  Object.entries({
    ...itunesCategoriesNodeEdge,
    ...parentWithNoChildren.reduce((agg, { uuid }) => ({ ...agg, [uuid]: [] }), {}),
  }),
  ([parent]) => itunesCategoriesMap[parent].name
).map(([parent, children]) => ({
  value: parent,
  title: itunesCategoriesMap[parent].name,
  children: children?.map((child) => ({ value: child.uuid, title: child.name })),
}));

export const CategoryTreeField = ({ input, meta, validationError }) => {
  let error = validationError?.filter(({ fieldName }) => fieldName === "categoryUUIDs")?.[0]
    ?.errorMessage;
  return (
    <FormGroup>
      <div className={"m-bs"}>
        <ControlLabel className={classNames("redcircle-form-label", error && "text-danger")}>
          Primary Category*
        </ControlLabel>

        <TreeSelect
          data-tree="Primary Categories"
          style={{ width: "100%", marginBottom: 16 }}
          value={!!input.value?.length && { value: head(input.value) }}
          placeholder="Select 1 or more categories"
          treeDefaultExpandAll
          showSearch
          getPopupContainer={(a) => a}
          filterTreeNode={(text, node) => {
            const title = node?.title;
            return title.toLowerCase().includes(text.toLocaleLowerCase());
          }}
          size={"large"}
          treeData={treeData}
          onChange={(a) => input.onChange(uniq([a, ...tail(input.value)]))}
        />
        {error && <div className={"text-danger"}>{error}</div>}
        <ControlLabel className="redcircle-form-label">
          Secondary Categories (Optional)
        </ControlLabel>
        <TreeSelect
          data-tree="Secondary Categories"
          style={{ width: "100%" }}
          value={tail(input.value)?.map((value) => ({ value }))}
          placeholder="Select 1 or more categories"
          treeDefaultExpandAll
          showSearch
          multiple
          treeCheckStrictly
          getPopupContainer={(a) => a}
          filterTreeNode={(text, node) => {
            const title = node?.title;
            return title.toLowerCase().includes(text.toLocaleLowerCase());
          }}
          size={"large"}
          treeData={treeData}
          onChange={(a) =>
            input.onChange(uniq([head(input.value), ...a?.map(({ value }) => value)]))
          }
        />
      </div>
    </FormGroup>
  );
};

export var ShowCreateForm = (props) => <ShowForm {...props} mode="create" />;

export var ShowEditForm = (props) => <ShowForm {...props} mode="edit" />;

ShowCreateForm = reduxForm({
  form: "show-create",
})(ShowCreateForm);
ShowCreateForm = seedcastForm(ShowCreateForm, { whiteList: ["imageFileData"] });

var formValueSelectorCreate = formValueSelector("show-create");
ShowCreateForm = connect(
  (state) => {
    return {
      selectedImageMediaFileUUID: formValueSelectorCreate(state, "imageMediaFileUUID"),
      alias: formValueSelectorCreate(state, "alias"),
      title: formValueSelectorCreate(state, "title"),
      description: formValueSelectorCreate(state, "description"),
    };
  },
  {
    changeFieldValue: (field, value) => change("show-create", field, value),
  }
)(withTiers(ShowCreateForm));

ShowEditForm = reduxForm({
  form: "show-edit",
})(withTiers(ShowEditForm));
ShowEditForm = seedcastForm(ShowEditForm, { whiteList: ["imageFileData"] });

var formValueSelectorUpdate = formValueSelector("show-edit");
ShowEditForm = connect((state) => {
  return {
    selectedImageMediaFileUUID: formValueSelectorUpdate(state, "imageMediaFileUUID"),
    alias: formValueSelectorUpdate(state, "alias"),
    description: formValueSelectorUpdate(state, "description"),
  };
})(withTiers(ShowEditForm));
