import { CheckCircleFilled, ClockCircleOutlined, CloseCircleFilled } from "@ant-design/icons";
import { Checkbox, CheckboxProps, Tooltip } from "antd";
import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { createVettingFormRequest, updateVettingFormRequest } from "src/action_managers/vetting";
import {
  MULTIPLE_CHOICE_TYPE_CHECKBOX,
  MULTIPLE_CHOICE_TYPE_RADIO,
  VETTING_QUESTION_TYPES,
} from "src/constants/vetting";
import { getInvitationStatus, vettingInvitationStatus } from "src/lib/vetting";
import { VettingQuestion } from "src/reducers/vetting";
import { VettingForm, VettingInvitation, VettingQuestionType } from "src/reducers/vetting/types";

export const colConfig = {
  xs: {
    span: 24,
  },
  sm: {
    span: 20,
    offset: 2,
  },
  md: {
    span: 18,
    offset: 3,
  },
};

// Vetting Form values
export interface VettingFormValues {
  brandName: string;
  campaignInfo: string;
  visibility: {
    shareBudget: boolean;
    shareTimeline: boolean;
  };
  question: {
    uuid?: string;
    prompt: VettingQuestion["prompt"];
    type: VettingQuestionType;
    required: boolean;
    allowOtherChoice: boolean;
    allowMultipleChoice: boolean;
    fields: {
      order: number;
      label: string | undefined;
      tooltip?: string;
    }[];
  };
  additionalQuestions: {
    uuid?: string;
    prompt: VettingQuestion["prompt"];
    type: VettingQuestionType;
    required: boolean;
    allowOtherChoice: boolean;
    allowMultipleChoice: boolean;
    fields: {
      order: number;
      label: string;
      tooltip?: string;
    }[];
  }[];
}

/**
 * Transform form values to form creation input
 */
export const transformValuesToRequest: (
  values: VettingFormValues,
  campaignUUID: string
) => createVettingFormRequest = (values, campaignUUID) => {
  const newValues: createVettingFormRequest = {
    campaignUUID,
    campaignInfo: values.campaignInfo,
    brandName: values.brandName,
    visibility: values.visibility,
    questions: [values.question, ...values.additionalQuestions].map((question, index) => {
      return {
        prompt: question.prompt,
        type: question.type,
        order: index,
        validations: {
          required: Boolean(question.required),
        },
        properties: {
          multipleChoiceType:
            question.type === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE
              ? question.allowMultipleChoice
                ? MULTIPLE_CHOICE_TYPE_CHECKBOX
                : MULTIPLE_CHOICE_TYPE_RADIO
              : undefined,
          allowOtherChoice:
            question.type === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE
              ? Boolean(question.allowOtherChoice)
              : undefined,
        },
        fields:
          question.type === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE && Array.isArray(question.fields)
            ? question.fields?.map((field, index) => ({
                order: index,
                label: field.label ?? "",
                tooltip: field.tooltip,
              }))
            : undefined,
      };
    }),
  };

  return newValues;
};

export const transformValuesToUpdateRequest: ({
  values,
  currentValues,
}: {
  values: VettingFormValues;
  currentValues: VettingForm;
}) => updateVettingFormRequest = ({ values, currentValues }) => {
  const newValues: updateVettingFormRequest = {};

  if (currentValues.brandName !== values.brandName) newValues.brandName = values.brandName;
  if (currentValues.campaignInfo !== values.campaignInfo) {
    newValues.campaignInfo = values.campaignInfo;
  }

  if (
    currentValues.visibility.shareBudget !== values.visibility.shareBudget ||
    currentValues.visibility.shareTimeline !== values.visibility.shareTimeline
  ) {
    newValues.visibility = {
      shareBudget: values.visibility.shareBudget,
      shareTimeline: values.visibility.shareTimeline,
    };
  }

  const allQuestions = [values.question, ...values.additionalQuestions];
  newValues.questions = allQuestions.map((question, index) => {
    return {
      // we are not including UUIDs to regenerate questions
      prompt: question.prompt,
      type: question.type,
      order: index,
      validations: {
        required: Boolean(question.required),
      },
      properties: {
        multipleChoiceType:
          question.type === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE
            ? question.allowMultipleChoice
              ? MULTIPLE_CHOICE_TYPE_CHECKBOX
              : MULTIPLE_CHOICE_TYPE_RADIO
            : undefined,
        allowOtherChoice:
          question.type === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE
            ? Boolean(question.allowOtherChoice)
            : undefined,
      },
      fields:
        question.type === VETTING_QUESTION_TYPES.MULTIPLE_CHOICE && Array.isArray(question.fields)
          ? question.fields?.map((field, index) => ({
              order: index,
              label: field.label ?? "",
              tooltip: field.tooltip,
            }))
          : undefined,
    };
  });

  return newValues;
};

// Constants
export const MaxMultipleChoiceFields = 5;
export const MinMultipleChoiceFields = 2;

interface ICheckBoxHeaderProps {
  selectedItems: string[];
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
  allItems: string[];
  isItemUnVetted: (campaignItemUUID: string) => boolean;
  isItemDisabled: (campaignItemUUID: string) => boolean;
}

export const CheckBoxHeaderComponent = (props: ICheckBoxHeaderProps) => {
  const { selectedItems, allItems, setSelectedItems, isItemUnVetted } = props;

  const numberSelected = selectedItems?.length;

  const unVettedItems = allItems.filter((uuid) => isItemUnVetted(uuid));
  const allUnVettedItemsChecked =
    unVettedItems?.length > 0 ? unVettedItems.every((uuid) => selectedItems.includes(uuid)) : false;

  /**
   * Handlers
   */
  const handleSelectAllUnVetted: CheckboxProps["onChange"] = (e) => {
    const all = new Set([...selectedItems, ...unVettedItems]);

    const newItems = e.target.checked
      ? Array.from(all)
      : selectedItems.filter((uuid) => !unVettedItems.includes(uuid));

    setSelectedItems(newItems);
  };

  return (
    <>
      <Checkbox
        className="p-lxxxs p-vxxs"
        checked={allUnVettedItemsChecked}
        onChange={handleSelectAllUnVetted}>
        {numberSelected > 0
          ? `${allUnVettedItemsChecked ? "All" : numberSelected} Unvetted Podcast Selected`
          : "Select All Unvetted"}
      </Checkbox>
    </>
  );
};

interface ICampaignItemVettingStatus {
  vettingInvitations: VettingInvitation[];
  showOnlyIcon?: boolean;
}

export const CampaignItemVettingStatus = ({
  vettingInvitations,
  showOnlyIcon = false,
}: ICampaignItemVettingStatus) => {
  // No Invitations were ever sent
  if (vettingInvitations?.length === 0) {
    return showOnlyIcon ? null : <span className="fs-11">{vettingInvitationStatus.None}</span>;
  }

  const invitationByOrder = [...vettingInvitations]?.sort((a, b) => b.sentAt - a.sentAt);

  const latestInvite = invitationByOrder[0];
  const sentAt = dayjs.unix(latestInvite.sentAt);
  const responseDueAt = dayjs.unix(latestInvite.responseDueAt);

  const status = getInvitationStatus(latestInvite);

  switch (status) {
    case vettingInvitationStatus.Accepted: {
      const response = dayjs.unix(latestInvite?.respondedAt as number);
      return (
        <div className="flex-row-container justify-center align-center fs-11">
          <Tooltip title="Podcaster wants to participate in the campaign.">
            <CheckCircleFilled style={{ color: "#52c41a" }} />
          </Tooltip>
          {showOnlyIcon ? null : (
            <>
              <span className="m-hxxxs">
                <strong>Sent</strong>
                {`: ${sentAt.format("M/D @ hh:mm a")}`}
              </span>
              <span>
                <strong>Responded</strong>
                {`: ${response.format("M/D @ hh:mm a")}`}
              </span>
            </>
          )}
        </div>
      );
    }
    case vettingInvitationStatus.Declined: {
      const response = dayjs.unix(latestInvite?.respondedAt as number);
      return (
        <div className="flex-row-container justify-center align-center fs-11">
          <Tooltip title="Podcaster declined to participate in the campaign.">
            <CloseCircleFilled style={{ color: "#ea404d" }} />
          </Tooltip>
          {showOnlyIcon ? null : (
            <>
              <span className="m-hxxxs">
                <strong>Sent</strong>
                {`: ${sentAt.format("M/D @ hh:mm a")}`}
              </span>
              <span>
                <strong>Responded</strong>
                {`: ${response.format("M/D @ hh:mm a")}`}
              </span>
            </>
          )}
        </div>
      );
    }
    case vettingInvitationStatus.Pending:
      return (
        <div className="flex-row-container justify-center align-center fs-11">
          <Tooltip
            title={`Podcaster has not responded yet. Vetting ends on ${responseDueAt.format(
              "M/D @ hh:mm a"
            )}`}>
            <ClockCircleOutlined style={{ color: "#ea404d" }} />
          </Tooltip>
          {showOnlyIcon ? null : (
            <>
              <span className="m-hxxxs">
                <strong>Sent</strong>
                {`: ${sentAt.format("M/D @ hh:mm a")}`}
              </span>
              <span>
                <strong>Due Date</strong>
                {`: ${responseDueAt.format("M/D @ hh:mm a")}`}
              </span>
            </>
          )}
        </div>
      );
    case vettingInvitationStatus.Expired:
      return (
        <div className="flex-row-container justify-center align-center fs-11">
          <Tooltip title={`Vetting request has expired.`}>
            <CloseCircleFilled style={{ color: "#ea404d" }} />
          </Tooltip>
          {showOnlyIcon ? null : (
            <>
              <span className="m-hxxxs">
                <strong>Sent</strong> {`: ${sentAt.format("M/D @ hh:mm a")}`}
              </span>
              <span>
                <strong>Due Date</strong>
                {`: ${responseDueAt.format("M/D @ hh:mm a")}`}
              </span>
            </>
          )}
        </div>
      );
    case vettingInvitationStatus.None:
      return showOnlyIcon ? null : <span className="fs-11">{vettingInvitationStatus.None}</span>;
  }
};
