import isNil from "lodash/isNil";
import React, { Component } from "react";
import "./spinner.css";

class Spinner extends Component {
  state = {
    spin: false,
    lastStop: 0,
  };

  spinnerTimers = [];

  componentDidMount() {
    if (this.props.isLoading) {
      this.spin();
    } else {
      this.stop();
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.isLoading) {
      this.spin();
    } else {
      this.stop();
    }
  }

  componentWillUnmount() {
    this.stop();
  }

  spin = () => {
    let spinDelay = isNil(this.props.spinDelay) ? 1000 : this.props.spinDelay;
    if (new Date().valueOf() - this.state.lastStop < 100) {
      spinDelay = 0;
    }

    this.spinnerTimers.push(
      setTimeout(() => {
        if (this.props.isLoading) {
          this.setState({ spin: true });
        } else {
          this.stop();
        }
      }, spinDelay)
    );
  };

  stop = () => {
    this.setState({ spin: false, lastStop: new Date().valueOf() });
    this.spinnerTimers.forEach((timer) => {
      clearTimeout(timer);
    });
  };

  render() {
    const { message, disableBackground, isSmall = false } = this.props;

    if (!this.state.spin) {
      return null;
    }
    let outerClasses = "spinner-box";
    if (!disableBackground) {
      outerClasses += " spinner-box--semi-transparent-background";
    }
    let logoClasses = "logo-spin rotate-horizontal";
    if (isSmall) {
      logoClasses += " logo-spin--small";
    }

    return (
      <div className={outerClasses} data-testid="test-spinner">
        <div className={logoClasses} />
        {message && <div className="spinner__text-area">{message}</div>}
      </div>
    );
  }
}

export default Spinner;
