import { Col, Row, Tabs, Tooltip } from "antd";
import parallelLimit from "async/parallelLimit";
import get from "lodash/get";
import some from "lodash/some";
import { Component } from "react";
import RCButton from "src/components/lib/button/button";
import Loading from "src/components/lib/loading/loading";
import Page from "src/components/lib/page/page";
import { shouldShowAnalyticsUpgrade } from "src/lib/feature_flag";
import userAPI from "../../../api/user";
import messageType from "../../../constants/message_types";
import { permissionTypes } from "../../../constants/permission_roles";
import {
  CreatorInsertionCredit,
  PendingInvoiceTransaction,
  RecurringDonationCredit,
  SubscriptionSurchargeCredit,
  TierCreatorDebit,
  TipCredit,
} from "../../../constants/transactions";
import { HAS_SEEN_MONETIZATION_INTRO } from "../../../constants/user_attributes";
import { getPricingPageURL } from "../../../lib/config";
import ContextMenu from "../../lib/context_menu";
import Divider from "../../lib/divider";
import ExternalLink from "../../lib/external_link";
import TransactionTable from "../../lib/transaction_table";
import WarningPanel from "../../lib/warning_panel";
import { TRANSFER_TO_BANK_MODAL } from "../../modals/modal_root";
import BalanceIndicatorWrapper from "./balance_indicator/balance_indicator_wrapper";
import "./monetization.scss";
import StripeConnection from "./stripe_connection";

class MonetizationPage extends Component {
  state = {
    stripeStatus: "",
    introMoreInfo: false,
    isPutting: false,
  };

  componentDidMount() {
    const { getShowCampaigns } = this.props;

    if (!this.hasSeenIntro()) {
      this.props.showIntroModal();
    }
    userAPI.userStripeStatus(this.props.user).then((resp) => {
      if (resp.status === 200) {
        resp.json().then((json) => {
          const status = get(json, "status", "");
          this.setState({ stripeStatus: status });
        });
      }
    });

    this.props.getRecentTransactionsForUser();
    this.props.fetchShowsForUser(this.props.user).then((resp) => {
      if (resp.status === 200) {
        const shows = resp.json;
        shows.forEach((show) => {
          if (show.advertisementSettings) {
            getShowCampaigns(show.uuid);
          }
        });
      }
    });
    this.props.getManualPayoutsFromUser(this.props.user.uuid);
  }

  hasSeenIntro = () => {
    return get(this.props.user, ["userAttributes", HAS_SEEN_MONETIZATION_INTRO]) === "true";
  };

  creatorPayments = () => {
    const { transactions, canAccessStripe } = this.props;
    const { userTransactions } = transactions;

    const userTxnTypes = new Set([
      SubscriptionSurchargeCredit,
      CreatorInsertionCredit,
      TipCredit,
      PendingInvoiceTransaction,
      RecurringDonationCredit,
    ]);

    if (canAccessStripe) userTxnTypes.add(TierCreatorDebit);

    const transformedTransactions = this.combineTransactionData(
      [...userTransactions],
      transactions.transactions
    );
    return transformedTransactions.filter((transaction) => userTxnTypes.has(transaction.type));
  };

  // we need to append data from parent collection objects
  combineTransactionData = (transactions = [], allTransactions) => {
    return transactions.map((t) => {
      // add parent's receipt URLs
      if (t.parentTransactionUUID) {
        const parentT = allTransactions[t.parentTransactionUUID];
        if (parentT && parentT.receiptURL && !t.receiptURL) {
          t.receiptURL = parentT.receiptURL;
        }
      }

      // don't count towards total balance
      if (t.type === TierCreatorDebit) {
        t.ignoreForBalance = true;
      }
      return t;
    });
  };

  enablePayments = () => {
    let updateFuncs = Object.keys(this.props.shows)
      .filter((showUUID) => this.props.shows[showUUID].userUUID === this.props.user.uuid)
      .map((showUUID) => (callback) => {
        this.props.enablePayments(showUUID).then((optInResponse) => {
          callback(optInResponse.status === 200 ? null : "non-200 update response", showUUID);
        });
      });
    this.setState({ isPutting: true });
    parallelLimit(updateFuncs, 5, (err) => {
      this.setState({ isPutting: false });
      if (err) {
        this.props.showMessageWithTimeout(
          "There was a problem turning on payments. Please try again later.",
          messageType.Error
        );
      } else {
        this.props.showMessageWithTimeout("Payments enabled.", messageType.Success);
      }
    });
  };

  renderNotConnectedContent = () => {
    const { introMoreInfo } = this.state;
    return (
      <Row className="monetization-page__not-connected">
        <Col xs={24}>
          <div className="monetization-page__not-connected-content">
            <p>
              You need to first connect your bank account to RedCircle before you're able to use our
              monetization services.
              {!introMoreInfo && (
                <RCButton
                  type="link"
                  size="small"
                  onClick={() => {
                    this.setState({ introMoreInfo: true });
                  }}>
                  More Info
                </RCButton>
              )}
            </p>
            {introMoreInfo && (
              <>
                <p>
                  RedCircle partners with{" "}
                  <ExternalLink href="https://stripe.com">Stripe</ExternalLink> to seamlessly
                  connect your bank account to our platform so you can get paid via direct deposit.
                </p>
                <p>
                  Don't worry, we can't draw money from your account, and Stripe is known for having
                  the best security on the web.
                </p>
                <p>
                  See our{" "}
                  <ExternalLink href="https://support.redcircle.com/connecting-your-bank-account">
                    help article
                  </ExternalLink>{" "}
                  for step-by-step instructions on how to connect to Stripe.
                </p>
              </>
            )}
          </div>
          <div className="monetization-page__not-connected-button">
            <StripeConnection user={this.props.user} disabled={!this.props.canAccessStripe} />
          </div>
          <div className="monetization-page__cut-info">
            By connecting your bank account you agree to the "Monetization Services for Podcast
            Creators" section of&nbsp;
            <ExternalLink href="https://redcircle.com/terms">
              RedCircle's Terms of Service
            </ExternalLink>
            . Stripe collects payment processing fees and RedCircle collects an industry-leading
            4.5% fee on tips and 12% on exclusive content subscriptions made through the platform.
            You can find more info about the fee structures{" "}
            <ExternalLink href={getPricingPageURL()}>here</ExternalLink> and{" "}
            <ExternalLink href="https://support.redcircle.com/exclusive-content">here</ExternalLink>
            .
          </div>
        </Col>
      </Row>
    );
  };

  handleWithdraw = () => {
    this.props.showModal(TRANSFER_TO_BANK_MODAL);
  };

  loginToStripe = () => {
    this.props.getStripeLoginLink(this.props.user).then((response) => {
      if (response.status === 200) {
        window.location.href = response.json.link;
      }
    });
  };

  hasAnyBalance = () => {
    return some(this.props.balance, (currencyInfo) => {
      if (currencyInfo.amount && currencyInfo.amount > 0) {
        return true;
      }
    });
  };

  renderMainContent = () => {
    const allCreatorTransactions = this.creatorPayments();

    const completeTransactions = allCreatorTransactions?.filter(
      (trx) => trx?.type !== PendingInvoiceTransaction
    );
    const awaitingTransactions = allCreatorTransactions?.filter(
      (trx) => trx?.type === PendingInvoiceTransaction
    );

    const allowTabs =
      shouldShowAnalyticsUpgrade() &&
      (awaitingTransactions?.length > 0 ||
        allCreatorTransactions.some((txn) => txn?.invoiceUUID?.length > 0));

    return (
      <>
        <BalanceIndicatorWrapper user={this.props.user} transactions={allCreatorTransactions} />
        <Row className="m-tl">
          <Col xs={24}>
            <h3 className="m-bxxs">Transactions History</h3>
            {allowTabs || <Divider marginTop="0" marginBottom="5px" />}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            {allowTabs ? (
              <Tabs>
                <Tabs.TabPane tab={`All (${allCreatorTransactions?.length})`} key="1">
                  <TransactionTable
                    transactions={allCreatorTransactions}
                    noDataText={"You don't have any transactions yet. Go earn your first payment!"}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={`Complete (${completeTransactions?.length})`} key="2">
                  <TransactionTable
                    transactions={completeTransactions}
                    noDataText={"You do not have any completed transactions."}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={`Awaiting Payments (${awaitingTransactions?.length})`} key="3">
                  <TransactionTable
                    transactions={awaitingTransactions}
                    noDataText={
                      "You do not have any transactions that are awaiting payment from a brand."
                    }
                  />
                </Tabs.TabPane>
              </Tabs>
            ) : (
              <TransactionTable
                transactions={allCreatorTransactions}
                noDataText={"You don't have any transactions yet. Go earn your first payment!"}
              />
            )}
          </Col>
        </Row>
      </>
    );
  };

  renderStripeErrors = () => {
    const { user } = this.props;
    const { stripeStatus } = this.state;
    return (
      <>
        {stripeStatus === "inactive" &&
          get(user, ["userAttributes", "shouldHaveStripeAccess"]) !== "false" && (
            <WarningPanel>
              Stripe requires additional verification for your account. Please{" "}
              <span className="fake-link" onClick={this.loginToStripe}>
                login
              </span>
              .
            </WarningPanel>
          )}
        {stripeStatus === "pending" && (
          <WarningPanel>
            Stripe is still verifying your account. Please check back later to confirm your account
            is set up.
          </WarningPanel>
        )}
      </>
    );
  };

  renderHeader = () => {
    const { user, canAccess } = this.props;
    const hasStripeAccess =
      get(this.props.user, ["userAttributes", "shouldHaveStripeAccess"]) !== "false";
    return (
      <Row>
        <Col xs={24}>
          <div className="flex-row-container align-center monetization-page__balance-header">
            <Page.Title>Money</Page.Title>
            {user.isStripeConnected && (
              <>
                {hasStripeAccess && this.props.canAccess(permissionTypes.transferFunds) && (
                  <ContextMenu
                    className="m-lxs"
                    menuItems={{
                      "Log In to Stripe": this.loginToStripe,
                    }}
                  />
                )}
                <div className="spacer flex-1" />
                {canAccess(permissionTypes.transferFunds) && (
                  <div className="monetization-page__cash-out-button">
                    {!this.hasAnyBalance() ? (
                      <Tooltip title="You must have a current balance to transfer money out.">
                        <div>
                          <RCButton type="primary" size="small" disabled>
                            Transfer to Bank
                          </RCButton>
                        </div>
                      </Tooltip>
                    ) : (
                      <RCButton type="primary" size="small" onClick={this.handleWithdraw}>
                        Transfer to Bank
                      </RCButton>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    const { user, isLoading } = this.props;
    return (
      <Page pageTitle="Money">
        {this.renderHeader()}
        <Page.Divider />
        <Page.Section>
          {isLoading && <Loading />}
          {!isLoading && user.isStripeConnected && this.renderStripeErrors()}
          {!isLoading && !user.isStripeConnected && this.renderNotConnectedContent()}
          {!isLoading && this.renderMainContent()}
        </Page.Section>
      </Page>
    );
  }
}

export default MonetizationPage;
