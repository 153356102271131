import {
  ANDROID,
  iOS,
  MAC_OXS_CATALINA_OR_ABOVE,
  WINDOWS_PHONE,
} from "../constants/operating_systems";

// returns mobile OS based on user-agent: https://stackoverflow.com/a/21742107/706978
export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return WINDOWS_PHONE;
  }
  if (/android/i.test(userAgent)) {
    return ANDROID;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return iOS;
  }
  if (/Mac OS X 10_1[5-9]/.test(userAgent) || /Mac OS X 10_[2-9][0-9]/.test(userAgent)) {
    return MAC_OXS_CATALINA_OR_ABOVE;
  }

  return "";
}
