import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { If } from "react-extras";
import { connect, useSelector } from "react-redux";
import { Field, Fields, reduxForm } from "redux-form";
import { createAdvertiserContact } from "../../../action_managers/advertiser_contact";
import { onCaptchaReady } from "../../../lib/captcha";
import { seedcastForm } from "../../../lib/forms";
import { ACTIONS, CATEGORIES, gtagEvent } from "../../../lib/google_analytics";
import { useComponentDidMount } from "../../../lib/hook";
import FormElement from "../../forms/form_element";
import LoadingButton from "../../forms/loading_button/loading_button";
import { PhoneNumberInputComponent } from "../../forms/phone_input/phone_input";
import Divider from "../../lib/divider";
import LoggedOutFrame from "../../lib/logged_out_frame";
import { withPageTitle } from "../../lib/WithPageTitle";
import "./../../lib/radio-input/radio-input.scss";
import "./advertiser_sign_up.scss";

const unqualifiedBudget = "Less than $5,000";

const budgetChoices = [
  unqualifiedBudget,
  "$5,000 to $10,000",
  "$10,000 to $25,000",
  "$25,000 to $50,000",
  "$50,000 to $100,000",
  "$100,000+",
];

const timelineChoices = [
  "As soon as possible",
  "Starting in the next month or two",
  "I'm exploring / thinking long term",
];

const FORM_NAME = "advertiser-sign-up";

const ContactForm = (props) => {
  const { validationErrors } = props;

  const [leadQualified, setLeadQualified] = useState(false);
  const onBudgetChanged = (event) => {
    setLeadQualified(event.target?.value !== unqualifiedBudget);
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <Row>
        <Col xs={12} md={12}>
          <Field
            component={FormElement}
            name="brandName"
            label="Brand Name"
            type="text"
            validationErrors={validationErrors}
          />
        </Col>
        <Col xs={12} md={12}>
          <Field
            component={FormElement}
            name="website"
            label="Website"
            type="text"
            placeholder="https://yourbrandhere.com"
            validationErrors={validationErrors}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Field
            component={FormElement}
            name="fname"
            label="First Name"
            type="text"
            validationErrors={validationErrors}
            validationErrorKeyOverride="firstName"
          />
        </Col>
        <Col xs={12} md={6}>
          <Field
            component={FormElement}
            name="lname"
            label="Last Name"
            type="text"
            validationErrors={validationErrors}
            validationErrorKeyOverride="lastName"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <Field
            component={FormElement}
            name="role"
            label="Role"
            type="text"
            validationErrors={validationErrors}
          />
        </Col>
        <Col xs={12} md={12}>
          <Field
            component={FormElement}
            name="email"
            label="Email"
            type="text"
            validationErrors={validationErrors}
          />
        </Col>
        <Col xs={12} md={12}>
          <Fields
            names={["phoneNumber", "phoneNumberCountryCode"]}
            component={FormElement}
            InputComponent={PhoneNumberInputComponent}
            label="Phone Number"
            type="text"
            validationErrors={validationErrors}
          />
        </Col>
        <Col xs={12} md={12}>
          <Field
            component={FormElement}
            name="estimatedBudget"
            label="What's your estimated initial campaign budget?"
            type="select"
            as={"select"}
            componentClass="select"
            placeholder="Select a budget"
            onChange={onBudgetChanged}
            helpText={
              leadQualified || (
                <span className={"text-danger"}>
                  RedCircle's Ad Platform is best suited for brands or agencies deploying larger
                  budgets.
                </span>
              )
            }>
            {budgetChoices.map((budget) => (
              <option key={budget} value={budget}>
                {budget}
              </option>
            ))}
          </Field>
        </Col>
        <Col xs={12} md={12}>
          <Field
            component={FormElement}
            name="estimatedTimeline"
            label="What's your estimated timeline?"
            type="select"
            componentClass="select"
            placeholder="Select a timeline">
            {timelineChoices.map((timeline) => (
              <option key={timeline} value={timeline}>
                {timeline}
              </option>
            ))}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Divider />
          <LoadingButton
            type="submit"
            disabled={!props.submitEnabled || !leadQualified}
            className="btn btn-primary width-100"
            isLoading={props.isLoading}>
            Submit
          </LoadingButton>
          <div className="user-form__fine-print">
            By signing up, you agree to our{" "}
            <a href="https://redcircle.com/terms">Terms of Service</a> and{" "}
            <a href="https://redcircle.com/privacy">Privacy Policy</a>. This site is protected by
            reCAPTCHA and the Google{" "}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
            <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
          </div>
        </Col>
      </Row>
    </form>
  );
};

const ContactFormRedux = reduxForm({
  form: FORM_NAME,
  initialValues: {
    phoneNumberCountryCode: "US",
    estimatedBudget: unqualifiedBudget,
    estimatedTimeline: timelineChoices[0],
  },
})(ContactForm);
const ContactFormWrapper = seedcastForm(ContactFormRedux);

let AdvertiserSignUpPage = (props) => {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const validationErrors = useSelector((state) => state.advertiserSignUp.validationErrors);
  const isLoading = useSelector((state) => state.advertiserSignUp.isLoading);

  const [isSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
    onCaptchaReady(() => setSubmitEnabled(true));
  }, []);

  useComponentDidMount(() => {
    gtagEvent(ACTIONS.SCREEN_VIEW, CATEGORIES.ADVERTISER_SIGN_UP);
  });

  const submit = (formData) => {
    formData.firstName = formData.fname;
    formData.lastName = formData.lname;
    props.createAdvertiserContact(formData).then((response) => {
      if (response.status === 200) {
        gtagEvent(ACTIONS.SUBMIT, CATEGORIES.ADVERTISER_SIGN_UP);
        setSubmitted(true);
      }
    });
  };

  const title = isSubmitted ? "Great, we'll be reaching out soon" : "Advertiser Account Sign Up";
  const subtitle = isSubmitted ? (
    "We've received your information and someone from our team will reach out shortly."
  ) : (
    <div>
      Are you an advertiser or brand seeking to advertise? Fill out a few details to request a
      business account, and we’ll reach out to you with next steps. If you're not an advertiser,
      please sign up through the
      <a href="https://app.redcircle.com/sign-up"> Podcaster Sign Up </a> or
      <a href="https://app.redcircle.com/sign-up/sponsor"> Listener Sign Up </a> instead.
    </div>
  );
  return (
    <LoggedOutFrame
      className="advertiser-sign-up-page"
      title={title}
      subtitle={subtitle}
      hideSignIn
      hideSignUp>
      <Row className="sign-up-row">
        <Col xs={12} md={6} mdOffset={3}>
          <If condition={!isSubmitted}>
            <ContactFormWrapper
              validationErrors={validationErrors}
              onSubmit={submit}
              submitEnabled={submitEnabled}
              isLoading={isLoading}
            />
          </If>
        </Col>
      </Row>
    </LoggedOutFrame>
  );
};

AdvertiserSignUpPage = connect(
  () => {
    return {};
  },
  {
    createAdvertiserContact,
  }
)(AdvertiserSignUpPage);

export default withPageTitle(AdvertiserSignUpPage, "Advertiser Contact");
