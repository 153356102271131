import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import uniqBy from "lodash/uniqBy";
import {
  CREATE_PERMISSION,
  GET_MY_PERMISSIONS,
  GET_PERMISSIONS_IN_ORG,
  GET_PERMISSION_PRESETS,
  UPDATE_PERMISSION,
} from "../../action_managers/permissions";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { mergeWithIndex } from "../../lib/reducer_util";
import { IPermissions, PermissionsReduxState } from "./types";

const initialState: PermissionsReduxState = {
  isLoading: false,
  presetIsLoading: false,
  permissionsByCredentialUUID: {},
  permissionsByKey: {},
  orgPermission: {} as IPermissions, // Current user permissions to org;
  presets: {},
  // this is to indicate that the permissions has been fetched, and this being true and the lack of permissions
  // should deny instead of wait
  initialFetched: false,

  // All Permissions within Org, separated out in nested object to not confuse with
  // current user permissions info above
  allPermissions: {
    permissions: [],
    initialFetched: false,
    isLoading: false,
  },
};

const getMyPermissions = httpReducer(GET_MY_PERMISSIONS, initialState, {
  success: (state = initialState, action) => {
    const orgPermission = Array.isArray(action?.payload?.resp)
      ? (action.payload.resp as IPermissions[]).find(
          (permission) => permission.objectType === "org"
        )
      : {};

    const credPermissions = Array.isArray(action?.payload?.resp) ? action.payload.resp : [];

    const allPermissionsUnique = uniqBy(
      [...state.allPermissions.permissions, ...credPermissions],
      "uuid"
    );

    return {
      ...state,
      permissionsByCredentialUUID: mergeWithIndex(
        state.permissionsByCredentialUUID,
        action,
        "credentialUUID",
        "key"
      ),
      permissionsByKey: { ...state.permissionsByKey, ...keyBy(action.payload.resp, "key") },
      orgPermission: { ...state.orgPermission, ...orgPermission },
      initialFetched: true,
      allPermissions: {
        ...state.allPermissions,
        permissions: allPermissionsUnique,
      },
    };
  },
  failure: (state = initialState) => ({
    ...state,
    initialFetched: true,
  }),
});

const getPresets = httpReducer(
  GET_PERMISSION_PRESETS,
  initialState,
  {
    success: (state = initialState, action) => ({ ...state, presets: action.payload.resp }),
  },
  { loadingKeyName: "presetIsLoading" }
);

// Get All Permissions in Org
const getPermissionsInOrg = httpReducer(GET_PERMISSIONS_IN_ORG, initialState, {
  started: (state = initialState) => {
    const permissionsObj = state.allPermissions;
    return {
      ...state,
      allPermissions: {
        ...permissionsObj,
        isLoading: true,
      },
    };
  },
  success: (state = initialState, action) => {
    const permissionsObj = state.allPermissions;
    const newPermissions = Array.isArray(action?.payload?.resp) ? [...action?.payload?.resp] : [];

    const newAllPermissions = uniqBy(
      [...state.allPermissions.permissions, ...newPermissions],
      "uuid"
    );
    return {
      ...state,
      allPermissions: {
        ...permissionsObj,
        permissions: newAllPermissions,
        isLoading: false,
        initialFetched: true,
      },
    };
  },
  failure: (state = initialState) => {
    const permissionsObj = state.allPermissions;
    return {
      ...state,
      allPermissions: {
        ...permissionsObj,
        isLoading: false,
        initialFetched: true,
      },
    };
  },
});

// Update User Permissions
const createPermission = httpReducer(CREATE_PERMISSION, initialState, {
  started: (state = initialState) => {
    const permissionsObj = state.allPermissions;
    return {
      ...state,
      allPermissions: {
        ...permissionsObj,
        isLoading: true,
      },
    };
  },
  success: (state = initialState, action) => {
    const permissionsObj = state.allPermissions;
    const newPermissions: IPermissions[] = Array.isArray(action?.payload?.resp)
      ? [...action?.payload?.resp]
      : [];
    const newPermissionsArray = state.allPermissions.permissions.concat(newPermissions);
    return {
      ...state,
      allPermissions: {
        ...permissionsObj,
        permissions: newPermissionsArray,
        isLoading: false,
        initialFetched: true,
      },
    };
  },
  failure: (state = initialState) => {
    const permissionsObj = state.allPermissions;

    return {
      ...state,
      allPermissions: {
        ...permissionsObj,
        isLoading: false,
        initialFetched: true,
      },
    };
  },
});

// Update User Permissions
const updatePermission = httpReducer(UPDATE_PERMISSION, initialState, {
  started: (state = initialState) => {
    const permissionsObj = state.allPermissions;
    return {
      ...state,
      allPermissions: {
        ...permissionsObj,
        isLoading: true,
      },
    };
  },
  success: (state = initialState, action) => {
    const permissionsObj = state.allPermissions;
    const newUpdatedPermissions: IPermissions[] = Array.isArray(action?.payload?.resp)
      ? [...action?.payload?.resp]
      : [];
    const newUpdatedPermissionsMap = keyBy(newUpdatedPermissions, "uuid");

    const newPermissionsArray = state.allPermissions.permissions
      ?.filter(({ uuid }) => isEmpty(newUpdatedPermissionsMap[uuid]))
      .concat(newUpdatedPermissions);

    return {
      ...state,
      allPermissions: {
        ...permissionsObj,
        permissions: newPermissionsArray,
        isLoading: false,
        initialFetched: true,
      },
    };
  },
  failure: (state = initialState) => {
    const permissionsObj = state.allPermissions;

    return {
      ...state,
      allPermissions: {
        ...permissionsObj,
        isLoading: false,
        initialFetched: true,
      },
    };
  },
});

export default reduceReducers(
  getMyPermissions,
  getPresets,
  getPermissionsInOrg,
  createPermission,
  updatePermission
);
