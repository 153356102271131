/*
 * Be sure to test this page in both signed-in and signed-out. shit's pretty cray cray
 * */

import find from "lodash/find";
import get from "lodash/get";
import { parse } from "query-string";
import React, { Component } from "react";
import { Col, FormControl, FormGroup, Grid, InputGroup, Row } from "react-bootstrap";
import { If } from "react-extras";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Elements } from "react-stripe-elements";
import { showWarning } from "../../actions/app";
import { hideModal, showModal } from "../../actions/modal";
import { fetchPublicShow, fetchPublicShowForSubscriber } from "../../action_managers/public_show";
import {
  addCard,
  fetchSubscription,
  getMyCards,
  getPaymentMethod,
  subscribe,
  updateSubscription,
} from "../../action_managers/subscriptions";
import { formatMoney } from "../../lib/format-money";
import { getShowUUID } from "../../lib/getShowUUID";
import { getSubscriptionUUID } from "../../lib/uuid";
import LoadingButton from "../forms/loading_button/loading_button";
import RCButton from "../lib/button/button";
import ExclusiveTag from "../lib/exclusive_tag";
import PaymentConfirmation from "../lib/payment_confirmation/payment_confirmation";
import { RadioInput } from "../lib/radio-input/radio-input";
import Spinner from "../lib/spinner";
import StripeForm, { options } from "../lib/stripe_form";
import BaseModal from "./base_modal";
import { LISTEN_ON_MODAL } from "./modal_root";
import "./sponsorship_modal/sponsorship_modal.scss";

class PaymentsModal extends Component {
  state = {
    mode: this.props.mode || "subscribe",
    amount: this.props.amount,
    paymentLoading: false,
    currentPage: 0,
    card: {},

    continueBtnLoading: false,
    continueBtnDisabled: false,
  };

  constructor(props) {
    super(props);
    this.customInputRef = React.createRef();
    this.stripeFormRef = React.createRef();
  }

  getSubscriptionUUID = () => getSubscriptionUUID(get(this.props.user, "uuid"), this.getShowUUID());

  componentDidMount() {
    const queryParams = parse(this.props.location.search);
    if (this.props.user && get(queryParams, "amount") && get(queryParams, "mode")) {
      this.setState({
        amount: parseFloat(queryParams.amount),
        mode: queryParams.mode,
        currentPage: 1,
        interval: queryParams.interval,
      });
    }

    // If there's only 1 fixed price, start by selecting it.
    if (
      this.props?.page1Options?.length === 1 &&
      this.props.page1Options[0].type === "fixedPrice"
    ) {
      this.setState({
        amount: this.props.page1Options[0].amount,
        interval: this.props.page1Options[0].interval,
      });
    }

    if (this.props.user) {
      this.props.getMyCards();
      this.props.fetchSubscription(this.getSubscriptionUUID()).then((resp) => {
        if (resp.status !== 200) {
          return;
        }
        const subscription = resp.json;
        this.setState({ paymentLoading: true });
        this.props
          .getPaymentMethod(subscription.uuid)
          .then((resp) => {
            if (resp.status !== 200) {
              return;
            }
            let newState = {
              currentCardID: get(resp.json, "metadata.originalCardID"),
              isEdit: subscription.state === "active",
            };
            // If the exclusive content radio button, need to make sure the min-amount for exclusive content is set
            // otherwise amount will be undefined even though button is selected by default.
            if (this.state.mode === "subscribe") {
              newState.amount = get(subscription, "show.subscriptionDetails.amount");
            }

            // If we're editing, skip past the amount selection and set details to existing sub.
            if (subscription.state === "active") {
              newState.currentPage = 1;
              newState.amount = subscription.amount;
              newState.interval = subscription.interval;
            }
            this.setState(newState);
          })
          .finally(() => {
            this.setState({ paymentLoading: false });
          });
      });
    }
  }

  submit = () => {
    this.props
      .submit({
        email: this.state.email,
        cardID: get(this.state, "card.id"),
        isEdit: this.state.isEdit,
        amount: this.state.amount,
        token: this.state.token,
        interval: this.state.interval || "monthly",
      })
      .then(() => {
        if (this.props.submitCallback) {
          this.props.submitCallback();
        }
      });
  };

  modalContent = () => {
    if (!this.getShow() || this.props.subscriptionIsLoading || this.state.paymentLoading) {
      return (
        <div className={"p-vxxl"}>
          <Spinner isLoading={true} />
        </div>
      );
    }
    return [this.page1Content, this.page2Content, this.page3Content][this.state.currentPage]();
  };

  modalFooter = () => {
    if (!this.getShow() || this.props.subscriptionIsLoading || this.state.paymentLoading) {
      return null;
    }
    return [this.page1Footer, this.page2Footer, this.page3Footer][this.state.currentPage]();
  };

  handleStripeFormStatus = (isReady) => {
    this.setState({ continueBtnDisabled: !isReady });
  };

  handleStripeLoadingStatus = (isLoading) => {
    this.setState({ continueBtnLoading: isLoading });
  };

  page2Content = () => {
    return (
      <>
        <Row>
          <Col xs={10} xsOffset={1}>
            <h3 className="text-center m-bs">Enter Payment Information</h3>
          </Col>
          <Col xs={10} xsOffset={1}>
            <Elements fonts={options.fonts}>
              <StripeForm
                ref={this.stripeFormRef}
                newCardOnly={this.props.newCardOnly}
                token={this.state.token}
                defaultSelectedCardID={
                  get(this.state, "card.id") ||
                  this.state.currentCardID ||
                  get(this.state, "token.card.id")
                }
                amount={this.state.amount}
                onFormStatus={this.handleStripeFormStatus}
                onLoadingStatus={this.handleStripeLoadingStatus}
                submit={this.handleCardSubmit}
              />
            </Elements>
          </Col>
        </Row>
      </>
    );
  };

  page2Footer = () => {
    return (
      <Grid fluid>
        <Row>
          {/* No back button when editing */}
          <If condition={!this.props.isEdit}>
            <Col xs={5} xsOffset={1}>
              <RCButton
                type="secondary"
                size="large"
                onClick={this.previousPage}
                className="width-100">
                Back
              </RCButton>
            </Col>
          </If>
          <Col xs={this.props.isEdit ? 10 : 5} xsOffset={this.props.isEdit ? 1 : 0}>
            <LoadingButton
              onClick={this.handleCardSubmit}
              className="width-100"
              disabled={this.state.continueBtnDisabled}
              isLoading={this.state.continueBtnLoading}>
              Continue
            </LoadingButton>
          </Col>
        </Row>
      </Grid>
    );
  };

  handleCardSubmit = async ({ card, token, email, bypassCardSave }) => {
    if (!card && !bypassCardSave) {
      let formResponse = await this.stripeFormRef.current.submit();
      if (formResponse) {
        let { card, token, email, error } = formResponse;
        if (error) {
          return;
        }
        this.setState({ card, token, email }, this.nextPage);
      }
      return;
    }
    this.setState({ card, token, email }, this.nextPage);
  };

  hideModal = () => {
    if (this.props.closeRoute) {
      this.props.history.push(this.props.closeRoute(window.location));
    }
    this.props.hideModal();
  };

  page3Content = () => {
    const brand = get(this.state, "card.brand");
    const last4 = get(this.state, "card.last4");
    const amount = get(this.state, "amount");
    let chargeTypeText = "Monthly Charge";
    if (this.props.mode === "tip") {
      chargeTypeText = "One-Time Charge";
    } else if (this.state.interval === "annual") {
      chargeTypeText = "Annual Charge";
    } else if (this.state.interval === "weekly") {
      chargeTypeText = "Weekly Charge";
    }

    return (
      <PaymentConfirmation
        {...{
          brand,
          last4,
          amount,
          title: this.props.isEdit ? "Update Your Subscription" : "Review Your Purchase",
          chargeTypeText: chargeTypeText,
          additionalInfo: (
            <If condition={this.props.mode !== "tip"}>
              <div className={"info m-bl"}>
                <If condition={this.props.isEdit}>
                  This card will be charged when your next subscription payment is due.
                </If>{" "}
                You can cancel or edit your payment at any time.
              </div>
            </If>
          ),
        }}
      />
    );
  };

  page3Footer = () => {
    return (
      <Grid fluid>
        <Row>
          <Col xs={5} xsOffset={1}>
            <RCButton
              type="secondary"
              size="large"
              onClick={this.previousPage}
              className="width-100">
              Back
            </RCButton>
          </Col>
          <Col xs={5}>
            <LoadingButton
              onClick={this.submit}
              isLoading={this.props.subscribeIsLoading}
              className="width-100">
              {this.props.isEdit ? "Update" : "Pay Now"}
            </LoadingButton>
          </Col>
        </Row>
      </Grid>
    );
  };

  exclusiveOption = ({ optionKey, amount, exclusiveText }) => {
    return (
      <div className={"highlight-box"}>
        <RadioInput
          id="price-option-2"
          name="radio-group"
          onClick={() => this.setState({ mode: optionKey, amount: amount })}
          checked={this.state.mode === optionKey}
          labelContent={() => (
            <>
              <div className={"flex-row-container align-center"}>
                <span className={"m-rs"}>{formatMoney(amount)}</span>
                <ExclusiveTag>Exclusive Content</ExclusiveTag>
              </div>

              <small className={"support-text"}>{exclusiveText}</small>
            </>
          )}
        />
      </div>
    );
  };

  fixedPriceOption = ({ optionKey, amount, label, interval }) => {
    return (
      <div className="no-highlight-container">
        <RadioInput
          id={`fixed-price-amount-${amount}`}
          name={"radio-group"}
          onClick={() => this.setState({ mode: optionKey, amount: amount, label, interval })}
          checked={this.state.amount === amount}
          labelClass={"width-100"}
          labelContent={() => {
            return (
              <div className={"m-bm"}>
                <span className={"m-rs"}>
                  {formatMoney(amount)} {label}
                </span>
              </div>
            );
          }}
        />
      </div>
    );
  };

  customOption = ({ optionKey, minAmount }) => (
    <div className={"no-highlight-container"}>
      <RadioInput
        id="price-option-custom"
        name="radio-group"
        className={"offset-radio"}
        onClick={() => this.setState({ mode: optionKey })}
        checked={this.state.mode === optionKey}
        labelClass={"width-100"}
        labelContent={() => (
          <>
            <FormGroup className="m-bs">
              <InputGroup>
                <InputGroup.Addon>$</InputGroup.Addon>
                <FormControl
                  placeholder="Custom Amount"
                  type={"number"}
                  bsClass={"form-control width-100"}
                  value={this.state.mode === optionKey ? this.state.amount / 100 : undefined}
                  inputRef={(ref) => {
                    this.customInputRef = ref;
                  }}
                  onChange={(e) => {
                    this.setState({ amount: Math.floor(parseFloat(e.target.value, 10) * 100) });
                  }}
                  onFocus={() => {
                    this.setState({ mode: optionKey });
                    if (this.customInputRef) {
                      this.customInputRef.select();
                    }
                  }}
                />
              </InputGroup>
            </FormGroup>
          </>
        )}
      />
    </div>
  );

  page1Content = () => {
    return (
      <>
        <Row>
          <Col xs={10} xsOffset={1}>
            <div className={"page-1-container"}>
              <img className={"album-art m-bs"} src={get(this.getShow(), "imageURL")} alt="" />
              <h3>{get(this.getShow(), "title")}</h3>
              <If condition={!!this.props.page1Header}>
                <div className={"m-yl"}>
                  <p>{this.props.page1Header}</p>
                </div>
              </If>
              <div className="price-form">
                <form action="#">
                  {this.props.page1Options.map((option) => {
                    return this.typeToOptionMap[option.type](option);
                  })}
                </form>
              </div>
            </div>
          </Col>
        </Row>
        <div className="flex-1" />
      </>
    );
  };

  page1Footer = () => {
    const { page1Options } = this.props;
    const exclusiveContentOption = find(page1Options, ["type", "exclusive"]);
    const minAmount = get(exclusiveContentOption, "amount", 0.0);
    const recurringDonationsEnabled = get(
      this.getShow(),
      ["subscriptionDetails", "recurringDonationsEnabled"],
      false
    );
    const isBtnDisabled = recurringDonationsEnabled
      ? !this.state.amount
      : !this.state.amount || this.state.amount < minAmount;

    return (
      <Grid fluid>
        <Row>
          <Col xs={5} xsOffset={1}>
            <RCButton type="secondary" size="large" onClick={this.hideModal} className="width-100">
              Cancel
            </RCButton>
          </Col>
          <Col xs={5}>
            <LoadingButton disabled={isBtnDisabled} onClick={this.afterStep1} className="width-100">
              Continue
            </LoadingButton>
          </Col>
        </Row>
      </Grid>
    );
  };

  typeToOptionMap = {
    exclusive: this.exclusiveOption,
    custom: this.customOption,
    fixedPrice: this.fixedPriceOption,
  };

  afterStep1 = () => {
    if (this.state.amount && this.state.amount < 99) {
      this.props.showWarning("amount must be greater than $0.99");
      return;
    }
    this.signinOrNextpage();
  };

  signinOrNextpage = () => {
    if (!this.props.user && this.props.requireSignin) {
      this.props.hideModal();
      this.props.history.push({
        pathname: "/sign-up/sponsor",
        search: `?goto=/shows/${this.getShowUUID()}/sponsor/edit&amount=${this.state.amount}&mode=${
          this.state.mode
        }&interval=${this.state.interval || "monthly"}`,
      });
    } else {
      this.nextPage();
    }
  };

  getShowUUID = () => getShowUUID(this.props);
  getShow = () => get(this.props, ["publicShows", this.getShowUUID()]);

  nextPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 });
  };
  previousPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
  };
  render() {
    return (
      <BaseModal
        containerClass={"sponsorship-modal"}
        isLoading={false}
        isMini={true}
        columns={10}
        offset={1}
        close={this.hideModal}
        hideCloseButton={false}
        showContent={true}
        Content={this.modalContent}
        Footer={this.modalFooter}
        noFooterShadow={true}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    publicShows: state.publicShows,
    user: get(state, "user.user"),
    subscriptions: state.subscriptions.subscriptions,
    subscriptionIsLoading: state.subscriptions.isLoading,
    subscribeIsLoading: state.subscriptions.subscribeIsLoading,
  };
}

export default connect(mapStateToProps, {
  fetchSubscription,
  fetchPublicShow,
  fetchPublicShowForSubscriber,
  getMyCards,
  hideModal,
  showWarning,
  updateSubscription,
  getPaymentMethod,
  subscribe,
  addCard,
  launchListenOnModal: (subscriptionUUID) => showModal(LISTEN_ON_MODAL, { subscriptionUUID }),
})(withRouter(PaymentsModal));
