/*
 * action types
 */

import moment from "moment";
import DefaultActionManager, {
  defaultActionManagerRunner,
} from "../action_managers/default_action_manager";

export const RESET_SHOWS = "RESET_SHOWS";

export const SHOW_FETCH_START = "SHOW_FETCH_START";
export const SHOW_FETCH_SUCCESS = "SHOW_FETCH_SUCCESS";
export const SHOW_FETCH_ERROR = "SHOW_FETCH_ERROR";

export const MEDIA_FILE_FETCH_START = "MEDIA_FILE_FETCH_START";
export const MEDIA_FILE_FETCH_END = "MEDIA_FILE_FETCH_END";

export const SHOW_EPISODE_LIST_FETCH = "SHOW_EPISODE_LIST_FETCH";
export const SHOW_EPISODE_LIST_FETCH_START = "SHOW_EPISODE_LIST_FETCH_START";
export const SHOW_EPISODE_LIST_FETCH_SUCCESS = "SHOW_EPISODE_LIST_FETCH_SUCCESS";
export const SHOW_EPISODE_LIST_FETCH_ERROR = "SHOW_EPISODE_LIST_FETCH_ERROR";

export const SHOW_EPISODE_FETCH = "SHOW_EPISODE_FETCH";
export const SHOW_EPISODE_FETCH_START = "SHOW_EPISODE_FETCH_START";
export const SHOW_EPISODE_FETCH_SUCCESS = "SHOW_EPISODE_FETCH_SUCCCESS";
export const SHOW_EPISODE_FETCH_ERROR = "SHOW_EPISODE_FETCH_ERROR";

export const EPISODE_PUT_START = "EPISODE_PUT_START";
export const EPISODE_PUT_SUCCESS = "EPISODE_PUT_SUCCESS";
export const EPISODE_PUT = "EPISODE_PUT";
export const EPISODE_PUT_VALID = "EPISODE_PUT_VALID";
export const EPISODE_PUT_INVALID = "EPISODE_PUT_INVALID";
export const EPISODE_PUT_ERROR = "EPISODE_PUT_ERROR";

export const SHOW_PUT_CLEAR = "SHOW_PUT_CLEAR";
export const SHOW_PUT_START = "SHOW_PUT_START";
export const SHOW_PUT_SUCCESS = "SHOW_PUT_SUCCESS";
export const SHOW_PUT_ERROR = "SHOW_PUT_ERROR";
export const SHOW_PUT_INVALID = "SHOW_PUT_INVALID";

export const SHOW_CHECK_REDIRECT = "SHOW_CHECK_REDIRECT";

export const SHOW_TIP = "SHOW_TIP";
export const SHOW_FETCH_BY_UUIDS = "SHOW_FETCH_BY_UUIDS";

/*
 * action creators
 */
export function fetchShowsStart() {
  return {
    type: SHOW_FETCH_START,
  };
}

export function fetchShowsSuccess(shows) {
  return {
    type: SHOW_FETCH_SUCCESS,
    shows: shows,
  };
}

export function fetchShowsError() {
  return {
    type: SHOW_FETCH_ERROR,
  };
}

export function mediaFileFetchStart(mediaFileUUID) {
  return {
    type: MEDIA_FILE_FETCH_START,
    mediaFileUUID,
  };
}

export function mediaFileFetchEnd(mediaFile) {
  return {
    type: MEDIA_FILE_FETCH_END,
    mediaFile: mediaFile,
  };
}

export function showEpisodeListFetchStart(showUUID) {
  return {
    type: SHOW_EPISODE_LIST_FETCH_START,
    showUUID: showUUID,
  };
}

export function showEpisodeListFetchSucces(showUUID, episodes) {
  return {
    type: SHOW_EPISODE_LIST_FETCH_SUCCESS,
    showUUID: showUUID,
    episodes: episodes,
  };
}

export function showEpisodeListFetchError(showUUID) {
  return {
    type: SHOW_EPISODE_LIST_FETCH_ERROR,
    showUUID: showUUID,
  };
}

export function episodeFetchStart(showUUID, episodeUUID) {
  return {
    type: SHOW_EPISODE_FETCH_START,
    showUUID: showUUID,
  };
}

export function episodeFetchSuccess(episode) {
  return {
    type: SHOW_EPISODE_FETCH_SUCCESS,
    episode: episode,
  };
}

export function episodeFetchError(showUUID, episodeUUID) {
  return {
    type: SHOW_EPISODE_FETCH_ERROR,
    showUUID: showUUID,
  };
}

export function episodePutStart(episode) {
  return {
    type: EPISODE_PUT_START,
    showUUID: episode.showUUID,
  };
}

export function episodePutSuccess(episode) {
  return {
    type: EPISODE_PUT_SUCCESS,
    episode: episode,
  };
}

export function episodePutValid(episode) {
  return {
    type: EPISODE_PUT_VALID,
    episode: episode,
  };
}

export function episodePutInvalid(episode, validationErrors) {
  return {
    type: EPISODE_PUT_INVALID,
    showUUID: episode.showUUID,
    validationErrors: validationErrors,
  };
}

export function episodePutError(episode, errorMessage) {
  return {
    type: EPISODE_PUT_ERROR,
    showUUID: episode.showUUID,
  };
}

export function showPutClear() {
  return {
    type: SHOW_PUT_CLEAR,
  };
}

export function showPutStart(show) {
  return {
    type: SHOW_PUT_START,
    show: show,
  };
}

export function showPutSuccess(show) {
  return {
    type: SHOW_PUT_SUCCESS,
    shows: [show],
  };
}

export function showPutInvalid(validationErrors) {
  return {
    type: SHOW_PUT_INVALID,
    validationErrors: validationErrors,
  };
}

export function showPutError(errorMessage) {
  return {
    type: SHOW_PUT_ERROR,
  };
}

export const showTip = ({ showUUID, stripeToken, email, amount }) =>
  new DefaultActionManager({
    actionName: SHOW_TIP,
    method: "post",
    body: {
      amount,
      stripeToken,
      email,
    },
    withCaptcha: true,
    route: `shows/${showUUID}/tip`,
    successMessage: "Your donation has been processed! Thanks for supporting this podcaster.",
  }).run();

export const getShowsByUUIDs = (showUUIDs) =>
  new DefaultActionManager({
    auth: true,
    body: {
      uuids: showUUIDs,
    },
    method: "post",
    route: "showsByUUIDs",
    actionName: SHOW_FETCH_BY_UUIDS,
  }).run();
