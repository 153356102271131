import { TreeSelect } from "antd";
import { useMemo } from "react";
import { itunesCategoriesNodeEdge } from "src/data/itunes_categories";

interface IProps {
  value?: string | string[];
  onChange?: (categories: string | string[]) => void;
  className?: string;
  multiple?: boolean;
}

export default function CategoryTreeSelect({ value, onChange, className, multiple }: IProps) {
  const treeData = useMemo(() => {
    const parents = itunesCategoriesNodeEdge[undefined as any]; // weird
    return parents.map((parent) => ({
      value: parent.uuid,
      title: parent.name,
      children: itunesCategoriesNodeEdge[parent.uuid]?.map((child) => ({
        value: child.uuid,
        title: child.name,
      })),
    }));
  }, []);

  return (
    <TreeSelect
      value={value}
      placeholder="Select category"
      showSearch
      className={className}
      treeData={treeData}
      multiple={multiple}
      onChange={onChange}
      treeDefaultExpandAll
      filterTreeNode={(input, node) =>
        node && node.title
          ? (node.title as string).toLowerCase().includes(input.toLowerCase())
          : false
      }
      size="large"
    />
  );
}
