import moment from "moment";
import { isCampaignProductExchanging } from "src/lib/campaigns";
import {
  CampaignItemStateAwaitingAudio,
  CampaignItemStateSent,
} from "../../../constants/campaigns";
import activeLineIcon from "../../../icons/campaign_steps/active.svg";
import nonActiveLineIcon from "../../../icons/campaign_steps/non_active.svg";
import receiveOfferIcon from "../../../icons/campaign_steps/receive_offer.svg";
import receiveOfferCompleteIcon from "../../../icons/campaign_steps/receive_offer_complete.svg";
import uploadAdAudioIcon from "../../../icons/campaign_steps/upload_ad_audio.svg";
import uploadAdAudioCompleteIcon from "../../../icons/campaign_steps/upload_ad_audio_complete.svg";
import { localDateAndTime } from "../../../lib/date";

export const AdDetailsStepsRenderProp = ({ campaign, campaignItem, render, horizontalLines }) => {
  const steps = [
    {
      offIcon: receiveOfferIcon,
      onIcon: receiveOfferCompleteIcon,
      copy: "Accept Ad Offer",
      isNext: () => campaignItem.state === CampaignItemStateSent,
      subCopy: () => {
        return (
          <small>
            <b>Due by {localDateAndTime(moment.unix(campaignItem?.respondByAt))}</b>
          </small>
        );
      },
    },
    {
      offIcon: uploadAdAudioIcon,
      onIcon: uploadAdAudioCompleteIcon,
      copy: isCampaignProductExchanging(campaign) ? (
        <span>
          Receive Product; <br /> Record & Upload Ad
        </span>
      ) : (
        "Record & Upload Ad"
      ),
      isNext: () => campaignItem.state === CampaignItemStateAwaitingAudio,
      subCopy: () => {
        const time = moment.unix(campaignItem?.uploadAudioBy);
        return (
          <small>
            <b>Due by {localDateAndTime(time)}</b>
          </small>
        );
      },
    },
  ];

  const currentStep = steps.findIndex(({ isNext }) => isNext());
  const renderProps = steps.map(({ offIcon, onIcon, copy, subCopy }, i) => {
    const shouldShowOnIcon = currentStep === -1 || i < currentStep;
    return {
      copy,
      subCopy: currentStep === i ? subCopy() : "",
      betweenIcon: i < steps.length - 1 && (
        <div>
          <img
            style={{
              ...(horizontalLines && { transform: "rotate(90deg)" }),
              padding: "0 8px",
            }}
            src={shouldShowOnIcon ? activeLineIcon : nonActiveLineIcon}
            alt=""
          />
        </div>
      ),
      icon: shouldShowOnIcon ? onIcon : offIcon,
    };
  });
  return renderProps.map(render);
};

export const AdDetailsSteps = ({ campaign, campaignItem }) => {
  return (
    <AdDetailsStepsRenderProp
      render={({ icon, betweenIcon, copy, subCopy }, i) => {
        return (
          <div key={i}>
            <div className={"m-vxs flex-row-container align-center"}>
              <img src={icon} alt="" />
              <div className={"m-lxxs"}>
                {copy}
                <br />
                {subCopy}
              </div>
            </div>
            {betweenIcon}
          </div>
        );
      }}
      campaign={campaign}
      campaignItem={campaignItem}
    />
  );
};
