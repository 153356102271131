export function snakeCaseToTitleCase(input) {
  var parts = input.split("_");
  var final = "";
  for (var part of parts) {
    var word = part.charAt(0).toUpperCase() + part.substring(1);
    if (final !== "") {
      final = final + " " + word;
    } else {
      final = word;
    }
  }
  return final;
}

export function createTitle(pageName) {
  if (!pageName) {
    return "RedCircle";
  }
  return `RedCircle | ${pageName}`;
}

export function titleCase(sentence) {
  return sentence
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

// Makes a string url friendly. Taken from here: https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
export function slugify(string) {
  const a = "àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;";
  const b = "aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
    .substring(0, 64);
}

export const pluralize = (word, count) => {
  if (count === 1) {
    return word;
  }
  return word + "s";
};
