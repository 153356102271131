import { Link } from "react-router-dom";
import { ShowAlbumArtWithNotification } from "src/components/lib/album_art";
import { IShow } from "redcircle-types";

export default function ShowList({ shows }: { shows: IShow[] }) {
  return (
    <div className="show-list">
      {shows.map((show) => (
        <Link to={`/shows/${show.uuid}`} key={show.uuid} className="m-rxs m-bxl">
          <ShowAlbumArtWithNotification show={show} />
        </Link>
      ))}
    </div>
  );
}
