import clone from "lodash/clone";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import React from "react";
import GraphContainer from "src/components/lib/stats/graph_container";
import StatsPie from "src/components/lib/stats/stats_pie";
import { ageOptions } from "src/components/modals/campaign_editor/target_audience";

const ListenerAge = (props) => {
  const { show, style } = props;

  const listenerDemographics = show?.listenerDemographics;
  let ageData = map(listenerDemographics?.ageBuckets, (v) => ({
    x: v.label,
    y: v.percentage,
    total: 1,
  }));

  let labelByRow = { "0-0": "Unknown" };
  forEach(ageOptions, (v) => {
    labelByRow[v.value] = v.display;
  });

  if (isEmpty(ageData)) {
    return null;
  }
  ageData = sortBy(ageData, "y");
  ageData.reverse();

  let sortedByName = clone(ageData);
  sortedByName = sortBy(sortedByName, "x");
  sortedByName.push(sortedByName.shift()); // We want "Unknown" category to be listed last"

  return (
    <GraphContainer title={"Listener Age"} style={style}>
      <StatsPie
        containerWidth={368} // 240 - 2 * 28 (parent horizontal padding)) * 2(this multipler is needed since original stat_pie divides the width)
        containerHeight={300}
        data={ageData}
        labelByRow={labelByRow}
      />
      <div className="no-padding">
        {sortedByName.map((row, index) => {
          return (
            <div className="flex-row-container justify-space-between" key={index}>
              <div>
                <b className="m-rxxs">{labelByRow[row.x]}</b>
              </div>
              <div>{Math.round(row.y * 100) + " %"}</div>
            </div>
          );
        })}
      </div>
    </GraphContainer>
  );
};

export default ListenerAge;
