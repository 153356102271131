import get from "lodash/get";
import { GET_EPISODE } from "../actions/episodes";
import {
  episodeFetchError,
  episodeFetchStart,
  episodeFetchSuccess,
  episodePutError,
  episodePutInvalid,
  episodePutStart,
  episodePutSuccess,
  fetchShowsError,
  fetchShowsStart,
  fetchShowsSuccess,
  mediaFileFetchEnd,
  mediaFileFetchStart,
  showPutError,
  showPutInvalid,
  showPutStart,
  showPutSuccess,
  SHOW_EPISODE_LIST_FETCH,
} from "../actions/shows";
import episodeAPI from "../api/episodes";
import mediaFileAPI from "../api/mediaFile";
import showAPI from "../api/shows";
import ActionManager from "./base";
import DefaultActionManager, { defaultActionManagerRunner } from "./default_action_manager";
export const ENABLE_PAYMENTS = "ENABLE_PAYMENTS";
export const GET_YOUTUBE_FOR_SHOW = "GET_YOUTUBE_FOR_SHOW";
export const DISCONNECT_YOUTUBE = "DISCONNECT_YOUTUBE";
export const UPLOAD_TO_YOUTUBE = "UPLOAD_TO_YOUTUBE";
export const VALIDATE_EPISODE_CREATE = "VALIDATE_EPISODE_CREATE";
export const VALIDATE_EPISODE_UPDATE = "VALIDATE_EPISODE_UPDATE";
export class ShowsForUserActionManager extends ActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong fetching shows. Please try again later.";
  }

  defaultPreCallActionCreator() {
    return fetchShowsStart;
  }

  defaultSuccessActionCreator() {
    return fetchShowsSuccess;
  }

  default400ActionCreator() {
    return fetchShowsError;
  }

  defaultErrorActionCreator() {
    return fetchShowsError;
  }

  execute(dispatch, getState) {
    return showAPI.fetchShowsForUser(get(this.args, "user") || get(getState(), "user.user"));
  }
}

export const getShowForUser = (user) => new ShowsForUserActionManager({ user }).run();

export class ShowFetchActionManager extends ShowsForUserActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong fetching the show. Please try again later.";
  }

  execute(dispatch, getState) {
    return showAPI.fetchShow(this.args.showUUID, this.args.user || getState().user.user);
  }

  // Overridden to wrap the single show response in an
  // array to work for existing action creators.
  responseOK(dispatch, json) {
    dispatch(this.defaultSuccessActionCreator()([json]));
  }
}

export const getShow = (showUUID) => new ShowFetchActionManager({ showUUID }).run();

export class MediaFileActionManager extends ActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong fetching a media file. Please try again later.";
  }

  preCall(dispatch) {
    return dispatch(mediaFileFetchStart(this.args.mediaFileUUID));
  }

  defaultSuccessActionCreator() {
    return mediaFileFetchEnd;
  }

  // Execute the API call.
  execute(dispatch, getState) {
    // Fill in an API call that returns a promise here.
    return mediaFileAPI.getMediaFile(
      this.args.mediaFileUUID,
      this.args.authToken || getState().user.user.authToken
    );
  }
}

export const getMediaFile = (mediaFileUUID, authToken) =>
  new MediaFileActionManager({
    mediaFileUUID,
    authToken,
  }).run();

export const fetchShow = (showUUID) => new ShowFetchActionManager({ showUUID }).run();

export class EpisodesByShowActionManager extends DefaultActionManager {
  constructor(args) {
    args = {
      ...args,
      actionName: SHOW_EPISODE_LIST_FETCH,
      route: `shows/${args.showUUID}/episodes`,
      auth: true,
      actionData: {
        showUUID: args.showUUID,
      },
    };
    super(args);
  }
}

export const getEpisodeByShow = (showUUID) => new EpisodesByShowActionManager({ showUUID }).run();

export class EpisodeFetchActionManager extends DefaultActionManager {
  constructor(args) {
    args = {
      actionData: {
        showUUID: args.showUUID,
      },
      auth: true,
      ...args,
    };
    super(args);
  }
  defaultErrorMessage() {
    return "Uh oh, something went wrong fetching an episode. Please try again later.";
  }

  defaultSuccessActionCreator() {
    return episodeFetchSuccess;
  }

  preCall(dispatch) {
    dispatch(episodeFetchStart(this.args.showUUID, this.args.episodeUUID));
  }

  execute(placeHolder, getState) {
    return episodeAPI.getEpisode(
      this.args.episodeUUID,
      get(this.args, "user.authToken") || getState().user.user.authToken
    );
  }

  responseError(dispatch, statusCode, json) {
    var errorMessage = json.errorMessage || json.message || this.defaultErrorMessage();
    dispatch(episodeFetchError(this.args.showUUID, this.args.episodeUUID));
    this.showError(dispatch, errorMessage);
  }

  // Handles unknown exceptions.  Override for customizations.
  catchError(dispatch) {
    dispatch(episodeFetchError(this.args.showUUID, this.args.episodeUUID));
    this.showError(dispatch, this.defaultErrorMessage());
  }
}

export const getEpisode = (episodeUUID, showUUID) =>
  new DefaultActionManager({
    actionName: GET_EPISODE,
    route: `episodes/${episodeUUID}`,
    auth: true,
    actionData: {
      showUUID,
    },
  }).run();

export class EpisodeCreateActionManager extends ActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong creating this episode. Please try again later.";
  }

  responseOK(dispatch, json) {
    super.responseOK(dispatch, json);
    if (!this.args.silent) {
      this.showSuccess(dispatch, "Episode saved.");
    }
  }

  defaultSuccessActionCreator() {
    return episodePutSuccess;
  }

  preCall(dispatch) {
    dispatch(episodePutStart(this.args.episode));
  }

  execute() {
    return episodeAPI.createEpisode(this.args.episode, this.args.user.authToken);
  }

  // Same as above, but for 400's.
  response400(dispatch, json) {
    if (json.validationErrors && json.validationErrors.length) {
      return dispatch(episodePutInvalid(this.args.episode, json.validationErrors));
    }
    this.responseError(dispatch, 400, json);
  }

  // Same as above but for non-200, non-400, non-403 responses.
  responseError(dispatch, statusCode, json) {
    // Fill in an action creator here to handle specific 500
    // errors if you want something more specific than this.
    var errorMessage = json.errorMessage || json.message || this.defaultErrorMessage();
    dispatch(episodePutError(this.args.episode));
    this.showError(dispatch, errorMessage);
  }

  // Handles unknown exceptions.  Override for customizations.
  catchError(dispatch) {
    dispatch(episodePutError(this.args.episode));
    this.showError(dispatch, this.defaultErrorMessage());
  }
}

export const validateEpisodeCreate = ({ episode }) =>
  defaultActionManagerRunner({
    route: "episodes/validate",
    auth: true,
    body: episode,
    method: "post",
    actionName: VALIDATE_EPISODE_CREATE,
  });

export const validateEpisodeUpdate = ({ episode }) =>
  defaultActionManagerRunner({
    route: `episodes/${episode.uuid}/validate`,
    auth: true,
    body: episode,
    method: "post",
    actionName: VALIDATE_EPISODE_UPDATE,
  });

export class EpisodeUpdateActionManager extends EpisodeCreateActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong updating this episode. Please try again later.";
  }

  execute() {
    return episodeAPI.updateEpisode(this.args.episode, this.args.user.authToken);
  }
}

export class ShowCreateActionManager extends ActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong creating this show. Please try again later.";
  }

  default400ActionCreator() {
    return showPutInvalid;
  }

  defaultErrorActionCreator() {
    return showPutError;
  }

  responseOK(dispatch, json) {
    dispatch(showPutSuccess(json));
    dispatch(fetchShowsSuccess([json]));
    this.showSuccess(dispatch, this.args.successMessage || "Podcast settings saved.");
  }

  preCall(dispatch) {
    dispatch(showPutStart(this.args.show));
  }

  execute() {
    return showAPI.createShow(this.args.show, this.args.user);
  }
}

export class ShowUpdateActionManager extends ShowCreateActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong updating this show. Please try again later.";
  }

  responseOK(dispatch, json) {
    dispatch(showPutSuccess(json));
    dispatch(fetchShowsSuccess([json]));

    if (!this.args.noMessage) {
      this.showSuccess(
        dispatch,
        this.args.successMessage || "Podcast settings saved.",
        this.args.large
      );
    }
  }

  execute(a, getState) {
    return showAPI.updateShow(this.args.show, this.args.user || getState().user.user);
  }
}

export const updateShow = (show) => new ShowUpdateActionManager({ show }).run();

export class SilentShowUpdateActionManager extends ShowUpdateActionManager {
  defaultErrorMessage() {
    return "";
  }

  // Override to skip displaying message.
  responseOK(dispatch, json) {
    dispatch(showPutSuccess(json));
    dispatch(fetchShowsSuccess([json]));
  }
}

export const enablePayments = (showUUID) =>
  new DefaultActionManager({
    actionName: ENABLE_PAYMENTS,
    auth: true,
    route: `shows/${showUUID}/enable-payments`,
    method: "post",
  }).run();

export const getYoutubeForShow = (showUUID) =>
  new DefaultActionManager({
    auth: true,
    actionName: GET_YOUTUBE_FOR_SHOW,
    route: `shows/${showUUID}/youtube-channels`,
    silent: true,
    actionData: {
      showUUID,
    },
  }).run();

export const disconnectYoutube = (showUUID) =>
  new DefaultActionManager({
    auth: true,
    actionName: DISCONNECT_YOUTUBE,
    route: `shows/${showUUID}/disconnect-youtube`,
    method: "post",
  }).run();

export const uploadToYoutube = (episodeUUID, options) =>
  new DefaultActionManager({
    auth: true,
    actionName: UPLOAD_TO_YOUTUBE,
    route: `episodes/${episodeUUID}/upload-to-youtube`,
    method: "post",
    body: {
      options,
    },
  }).run();
