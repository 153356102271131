import { VettingForm } from "src/reducers/vetting";
import { ARGS, defaultActionManagerRunner } from "./default_action_manager";
import { VettingInvitation } from "src/reducers/vetting/types";
import { UnixTimeStamp } from "src/lib/date";

export const GET_VETTING_FORM = "GET_VETTING_FORM" as const;
export const GET_VETTING_FORMS_FOR_CAMPAIGN = "GET_VETTING_FORMS_FOR_CAMPAIGN" as const;
export const CREATE_VETTING_FORM = "CREATE_VETTING_FORM" as const;
export const DELETE_VETTING_FORM = "DELETE_VETTING_FORM" as const;
export const UPDATE_VETTING_FORM = "UPDATE_VETTING_FORM" as const;

export const GET_VETTING_INVITATION = "GET_VETTING_INVITATION" as const;
export const GET_VETTING_INVITATIONS_FOR_SHOW = "GET_VETTING_INVITATIONS_FOR_SHOW" as const;
export const GET_VETTING_INVITATIONS_FOR_CAMPAIGN_ITEM =
  "GET_VETTING_INVITATIONS_FOR_CAMPAIGN_ITEM" as const;
export const GET_VETTING_INVITATIONS_BY_VETTING_FORM =
  "GET_VETTING_INVITATIONS_BY_VETTING_FORM" as const;
export const GET_VETTING_INVITATIONS_FOR_CAMPAIGN = "GET_VETTING_INVITATIONS_FOR_CAMPAIGN" as const;

export const SEND_VETTING_INVITATIONS = "SEND_VETTING_INVITATIONS" as const;
export const REMIND_VETTING_INVITATION = "REMIND_VETTING_INVITATION" as const;
export const REMIND_VETTING_INVITATIONS_BY_CART = "REMIND_VETTING_INVITATIONS_BY_CART" as const;
export const RESPOND_TO_VETTING_INVITATION = "RESPOND_TO_VETTING_INVITATION" as const;

type OptionalActionManagerArgs = Partial<
  Omit<ARGS, "body" | "actionName" | "method" | "route" | "auth">
>;

export const getVettingForm = (vettingFormUUID: string, options: OptionalActionManagerArgs = {}) =>
  defaultActionManagerRunner<VettingForm>({
    route: `vetting-forms/${vettingFormUUID}`,
    method: "GET",
    actionName: GET_VETTING_FORM,
    auth: true,
    ...options,
  });

export const getVettingInvitation = (
  vettingInvitationUUID: string,
  options: OptionalActionManagerArgs = {}
) =>
  defaultActionManagerRunner<VettingInvitation>({
    route: `vetting-invitations/${vettingInvitationUUID}`,
    method: "GET",
    actionName: GET_VETTING_INVITATION,
    auth: true,
    ...options,
  });

export const getVettingFormsForCampaignUUID = (
  campaignUUID: string,
  options: OptionalActionManagerArgs = {}
) =>
  defaultActionManagerRunner<VettingForm[]>({
    route: `campaigns/${campaignUUID}/vetting-forms`,
    method: "GET",
    actionName: GET_VETTING_FORMS_FOR_CAMPAIGN,
    auth: true,
    ...options,
  });

// Podcaster only
export const getVettingInvitationsForShow = (showUUID: string) =>
  defaultActionManagerRunner<VettingInvitation[]>({
    route: `shows/${showUUID}/vetting-invitations`,
    method: "GET",
    actionName: GET_VETTING_INVITATIONS_FOR_SHOW,
    auth: true,
  });

// Podcaster only
export const getVettingInvitationsForCampaignItem = (
  campaignItemUUID: string,
  options: OptionalActionManagerArgs = {}
) =>
  defaultActionManagerRunner<VettingInvitation[]>({
    route: `campaign-items/${campaignItemUUID}/vetting-invitations`,
    method: "GET",
    actionName: GET_VETTING_INVITATIONS_FOR_CAMPAIGN_ITEM,
    auth: true,
    ...options,
  });

export const getVettingInvitationsForVettingForm = (
  vettingFormUUID: string,
  options: OptionalActionManagerArgs = {}
) =>
  defaultActionManagerRunner<VettingInvitation[]>({
    route: `vetting-forms/${vettingFormUUID}/vetting-invitations`,
    method: "GET",
    actionName: GET_VETTING_INVITATIONS_BY_VETTING_FORM,
    auth: true,
    ...options,
  });

export const getVettingInvitationsForCampaign = (
  campaignUUID: string,
  options: OptionalActionManagerArgs = {}
) =>
  defaultActionManagerRunner<VettingInvitation[]>({
    route: `campaigns/${campaignUUID}/vetting-invitations`,
    method: "GET",
    actionName: GET_VETTING_INVITATIONS_FOR_CAMPAIGN,
    auth: true,
    ...options,
  });

export type createVettingFormRequest = Parameters<typeof createVettingForm>[0];

export const createVettingForm = (
  newVettingForm: Pick<
    VettingForm,
    "campaignUUID" | "brandName" | "visibility" | "questions" | "campaignInfo"
  >,
  options: OptionalActionManagerArgs = {}
) =>
  defaultActionManagerRunner<VettingForm>({
    route: `vetting-forms`,
    method: "POST",
    actionName: CREATE_VETTING_FORM,
    body: newVettingForm,
    auth: true,
    ...options,
  });

export type updateVettingFormRequest = Parameters<typeof updateVettingForm>[1];

export const updateVettingForm = (
  vettingFormUUID: string,
  updates: Partial<Pick<VettingForm, "brandName" | "visibility" | "questions" | "campaignInfo">>,
  options: OptionalActionManagerArgs = {}
) =>
  defaultActionManagerRunner<VettingForm>({
    route: `vetting-forms/${vettingFormUUID}`,
    method: "PUT",
    actionName: UPDATE_VETTING_FORM,
    body: updates,
    auth: true,
    ...options,
  });

export const deleteVettingForm = (
  vettingFormUUID: string,
  options: OptionalActionManagerArgs = {}
) =>
  defaultActionManagerRunner<VettingForm>({
    route: `vetting-forms/${vettingFormUUID}`,
    method: "DELETE",
    actionName: DELETE_VETTING_FORM,
    auth: true,
    ...options,
  });

export const sendVettingInvitations = (
  vettingFormUUID: string,
  sendRequest: {
    campaignItemUUIDs: string[];
    responseDueAt: UnixTimeStamp;
  },
  options: OptionalActionManagerArgs = {}
) =>
  defaultActionManagerRunner<VettingInvitation[]>({
    route: `vetting-forms/${vettingFormUUID}/send`,
    method: "POST",
    actionName: SEND_VETTING_INVITATIONS,
    body: sendRequest,
    auth: true,
    ...options,
  });

export const remindVettingInvitation = (
  vettingInvitationUUID: string,
  message?: string,
  options: OptionalActionManagerArgs = {}
) =>
  defaultActionManagerRunner<VettingInvitation>({
    route: `vetting-invitations/${vettingInvitationUUID}/remind`,
    method: "POST",
    actionName: REMIND_VETTING_INVITATION,
    body: {
      message,
    },
    auth: true,
    ...options,
  });

export const remindVettingInvitationByCart = ({
  campaignUUID,
  message,
  options = {},
}: {
  campaignUUID: string;
  message?: string;
  options?: OptionalActionManagerArgs;
}) =>
  defaultActionManagerRunner<VettingInvitation>({
    route: `campaigns/${campaignUUID}/remind-vetting`,
    method: "POST",
    actionName: REMIND_VETTING_INVITATIONS_BY_CART,
    body: {
      message,
    },
    auth: true,
    ...options,
  });

export const respondToVettingInvitation = (
  vettingInvitationUUID: string,
  responses: VettingInvitation["responses"],
  options: OptionalActionManagerArgs = {}
) =>
  defaultActionManagerRunner({
    route: `vetting-invitations/${vettingInvitationUUID}/respond`,
    method: "POST",
    actionName: RESPOND_TO_VETTING_INVITATION,
    body: {
      responses,
    },
    auth: true,
    ...options,
  });
