import bxLinkExternal from "@iconify-icons/bx/bx-link-external";
import Icon from "@iconify/react";
import moment from "moment";
import { parse } from "query-string";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { showWarning } from "../../../actions/app";
import { getPaymentsForTier } from "../../../action_managers/tiers";
import { permissionTypes } from "../../../constants/permission_roles";
import { tierLevels, TIER_LEVEL_MONTHLY } from "../../../constants/tiers";
import { ITierCopy, tierCopy } from "../../../constants/tier_copy";
import { useDispatchTS, useSelectorTS } from "../../../hooks/redux-ts";
import { useCanAccess } from "../../../lib/permissions";
import AddCardForm from "../../lib/stripe_form/add_card_form";
import StripeElements from "../../lib/stripe_form/stripe_elements";
import { PlanCard } from "../../lib/tier-card";
import { tierOrder } from "./account_plan_utils";
import { UpdateTierHeader, UpdateTierNavBar, useHideSidebar, useUpdateTier } from "./tier_utils";
import { TierLevel } from "redcircle-types";
const useUserHasFreeTrial = () => {
  return !useSelectorTS((state) => state.user.user?.userAttributes?.hasReceivedFreeTrial ?? false);
};

interface IUpdateTierPageProps {
  pageDisplay: (props: {
    isYearly: boolean;
    copyForPlan: ITierCopy;
    userHasFreeTrial: boolean;
    levelDisplay: string;
    destinationLevel: string;
    showAddCard: boolean;
    hideNextPayment?: boolean;
    showLegacyPrice?: boolean;
  }) => ReactElement;
  ctaText: (level: string) => string;
  hideNextPayment?: boolean;
  defaultDisableCTA?: boolean;
  type: "upgrade" | "update" | "downgrade";
}
const UpdateTierPageWrapper = (props: IUpdateTierPageProps) => {
  const { type } = props;
  const { level } = useParams<{ level: Extract<TierLevel, "growth" | "pro"> }>();
  const levelDisplay = level[0].toUpperCase() + level.slice(1);
  const history = useHistory();
  const dispatch = useDispatchTS();
  const { interval } = parse(window.location.search);
  const [isYearly, setIsYearly] = useState(interval !== TIER_LEVEL_MONTHLY);
  const [ctaDisabled, setCtaDisabled] = useState(!!props.defaultDisableCTA);
  const [showAddCard, setShowAddCard] = useState(true);
  const tierState = useSelectorTS((state) => state.user.tier);
  const paymentsIsLoading = useSelectorTS((state) => state.payments.isLoading);
  const currentPaymentCard = useSelectorTS((state) => state.payments.userTierPayment?.card);
  const user = useSelectorTS((state) => state.user.user);
  const permissionState = useSelectorTS((state) => state.permissions);
  const canAccess = useCanAccess(permissionTypes.updateBilling);

  /**
   * If the User is in the update screen
   * User does not change the subscription interval
   * User does not change the subscription tier level
   * then we can show the current legacy pricing
   */
  const showLegacyPrice =
    type === "update" &&
    isYearly === (tierState.interval === "annual") &&
    level === tierState.level;

  useHideSidebar();

  useEffect(() => {
    if (tierState?.uuid) {
      if (tierState) dispatch(getPaymentsForTier());
      let correctType = "";
      if (tierOrder[tierState.level] < tierOrder[level]) {
        correctType = "upgrade";
      } else if (tierOrder[tierState.level] > tierOrder[level]) {
        correctType = "downgrade";
      } else if (tierState.level === level) {
        correctType = "update";
      } else {
        history.replace("/account/payments");
        return;
      }
      if (correctType !== props.type) {
        history.replace(`/account/${correctType}/${level}${window.location.search}`);
        return;
      }
    }
  }, [tierState?.uuid]);

  useEffect(() => {
    if (currentPaymentCard?.id) {
      setShowAddCard(false);
    }
  }, [currentPaymentCard?.id]);
  useEffect(() => {
    if (!permissionState.isLoading && !canAccess) {
      dispatch(showWarning("Contact your Org Owner to change your account plan."));
      history.replace(`/account/payment`);
      return;
    }
  }, [permissionState, canAccess]);

  const hideNextPayment =
    (tierState?.level === tierLevels.growth && level === tierLevels.pro) ||
    (tierState?.level === tierLevels.pro && level === tierLevels.growth) ||
    props.hideNextPayment;
  const updateTier = useUpdateTier();
  const currentPlan = tierCopy[level] ? level : undefined;
  const userHasFreeTrial = useUserHasFreeTrial();
  const cardSubmit = async (card: any) => updateTier(card?.card?.id, isYearly, currentPlan);

  const confirmExistingCard = () => {
    updateTier(currentPaymentCard.id, isYearly, currentPlan);
  };

  const getEndDate = () => {
    const date = moment(0);
    // moment does 0 based counting and stripe uses 1
    date.month(currentPaymentCard?.exp_month - 1);
    date.year(currentPaymentCard?.exp_year);
    return date.format("MM/YY");
  };

  const copyForPlan = tierCopy[currentPlan] ?? {};
  if (!currentPlan) {
    return <Redirect to={"/account/payment"} />;
  }
  return (
    <Grid fluid>
      <UpdateTierNavBar />
      <Row className={"flex-row-container p-tl update-tier-page-wrapper"}>
        <UpdateTierHeader
          title={`Upgrade to ${levelDisplay} Plan`}
          showAddCard={showAddCard}
          className="visible-xs-block"
        />
        <Col xs={12} md={4} mdOffset={1} className="flex-row-container justify-center">
          <PlanCard
            hideNextPayment={hideNextPayment}
            planLevel={currentPlan}
            isYearly={isYearly}
            setIsYearly={(isYearly) => {
              setIsYearly(isYearly);
              setCtaDisabled(false);
            }}
            hasFreeTrial={userHasFreeTrial}
            showLegacyPrice={showLegacyPrice}
          />
        </Col>
        <Col xs={12} md={6} lg={4} className={"flex-column-container"}>
          {props.pageDisplay &&
            props.pageDisplay({
              isYearly,
              copyForPlan,
              userHasFreeTrial,
              levelDisplay,
              showAddCard,
              destinationLevel: currentPlan,
              hideNextPayment,
              showLegacyPrice,
            })}
          {currentPlan !== "core" && !paymentsIsLoading && !showAddCard && (
            <div className={"p-axs m-tm"} style={{ border: "1px solid lightgray" }}>
              <h3>Payment Method</h3>
              <div className={"flex-row-container align-center"}>
                <span className={"m-rs"}>
                  {currentPaymentCard?.brand} **** **** **** {currentPaymentCard?.last4}
                </span>
                <span>{getEndDate()}</span>
                <button className="btn fake-link m-a0 p-a0" onClick={() => setShowAddCard(true)}>
                  Edit <Icon icon={bxLinkExternal} />
                </button>
              </div>
            </div>
          )}

          {currentPlan !== "core" && !paymentsIsLoading && showAddCard && (
            <StripeElements>
              <AddCardForm
                canMakePayment={false}
                onSubmit={cardSubmit}
                user={user}
                ctaText={props.ctaText(levelDisplay)}
              />
            </StripeElements>
          )}
          <div className="flex-1" />
          {!showAddCard && (
            <button
              className="btn btn-primary width-100 m-tm"
              onClick={confirmExistingCard}
              disabled={ctaDisabled}>
              {props.ctaText(levelDisplay)}
            </button>
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default UpdateTierPageWrapper;
