import PLAY_ORDER_INORDER_ICON from "src/icons/play-style-inorder.png";
import PLAY_ORDER_RANDOM_ICON from "src/icons/play-style-random.png";

export const PLAY_ORDER_INORDER = "inorder";
export const PLAY_ORDER_RANDOM = "random";

export const PLAY_ORDER_HUMANIZED = {
  [PLAY_ORDER_INORDER]: "In Order",
  [PLAY_ORDER_RANDOM]: "Random",
};

export const PLAY_ORDER_HUMANIZED_ADVERB = {
  [PLAY_ORDER_INORDER]: "in order",
  [PLAY_ORDER_RANDOM]: "randomly",
};

export const PLAY_ORDER_ICONS = {
  [PLAY_ORDER_INORDER]: PLAY_ORDER_INORDER_ICON,
  [PLAY_ORDER_RANDOM]: PLAY_ORDER_RANDOM_ICON,
};

export const AD_TYPE_DEFAULT = "ad";
export const AD_TYPE_CROSS_PROMO = "crossPromo";
export const AD_TYPE_CUSTOM = "customAudio";
export const AD_TYPE_AUDIO_FILE = "audioFile"; // to be deprecated to custom audio

export const AD_TYPE_HUMANIZED = {
  [AD_TYPE_DEFAULT]: "RedCircle Ad",
  [AD_TYPE_CROSS_PROMO]: "RedCircle Cross Promotion",
};

export interface IAudioBlockFormItem {
  id: string; // we generate temp uuids to track items as we drag them around. removed at call
  type?: string;
  customAudioUUID?: string;
  mediaFileUUID?: string;
  playLimitPerEpisode?: number;
}
