export function getStripeClientID() {
  if (isProduction()) {
    return "ca_CYOd5LJpOIDwViZP6TEdzjlV5Z1nieh2";
  } else {
    return "ca_CYOdhwQpCrQZvS9k5UyrhZ0GTSM2gqkD";
  }
}

export function getStripePublicKey() {
  if (isProduction()) {
    return "pk_live_gO5BO02k2utsNypr7GwPwrON";
  } else {
    return "pk_test_9EJXx15AwZ7cXbT8AXTfg7ZH";
  }
}

export function getGoogleAnalyticsID() {
  if (isProduction()) {
    return "UA-115918131-2";
  } else {
    return "UA-115918131-3";
  }
}

export function getMegapodURL() {
  if (isDev()) {
    return "http://localhost:3000/";
  } else if (isStaging()) {
    return "https://staging.redcircle.com/";
  } else {
    return "https://redcircle.com/";
  }
}

export function getPodcacheURL() {
  if (isDev()) {
    return "http://localhost:8000/";
  } else if (isStaging()) {
    return "https://staging-app.redcircle.com/api/";
  } else {
    return "https://api.podcache.net/";
  }
}

export function getSeedcastURL() {
  if (isDev()) {
    return "http://localhost:8010/";
  } else if (isStaging()) {
    return "https://staging-app.redcircle.com/";
  } else {
    return "https://app.redcircle.com/";
  }
}

export function getStreamURL() {
  return isProduction() ? "https://stream.redcircle.com" : "https://staging-stream.redcircle.com";
}

export function getPricingPageURL() {
  return `${getMegapodURL()}pricing?toApp=1`;
}

export function goToPricingPage(newTab = true) {
  window.open(getPricingPageURL(), newTab ? "_blank" : undefined);
}

export function isDev() {
  return process.env.REACT_APP_ENVIRONMENT === "dev"; // eslint-disable-line no-undef
}

export function isStaging() {
  return process.env.REACT_APP_ENVIRONMENT === "staging"; // eslint-disable-line no-undef
}

export function isProduction() {
  return process.env.REACT_APP_ENVIRONMENT === "prod"; // eslint-disable-line no-undef
}
