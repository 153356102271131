import { Progress, Tooltip } from "antd";
import React from "react";
import "./progress_circle.scss";

interface IProps {
  percent: number;
  text?: string;
  width?: number;
}

export default function ProgressCircle({ percent = 0, text, width = 30 }: IProps) {
  const renderProgress = () => (
    <Progress type="circle" percent={percent} size={width} strokeWidth={10} strokeColor="#ea404d" />
  );
  if (!text) return renderProgress();
  return <Tooltip title={text}>{renderProgress()}</Tooltip>;
}
