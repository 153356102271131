import { Col, Row } from "antd";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { parse } from "query-string";
import { Component } from "react";
import { pixelPageView } from "src/lib/facebook_pixel";
import { GoogleTagManager, GTM_CONSTS } from "src/lib/google_tag_manager";
import { partnerStackSignUp } from "src/lib/partnerstack";
import UserRoles from "../../../constants/roles";
import { onCaptchaReady } from "../../../lib/captcha";
import LoggedOutFrame from "../../lib/logged_out_frame";
import UserForm from "./user_form";

class SignUpPage extends Component {
  state = {
    submitEnabled: false,
  };

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0 });
    onCaptchaReady(() => {
      this.setState({ submitEnabled: true });
    });
    if (!isEmpty(this.props.user)) {
      this.props.history.push("/");
    }

    if (!(this.props.forRole === UserRoles.Sponsor)) {
      pixelPageView();
    }
  }

  signUp = (formData) => {
    formData.firstName = formData.fname;
    formData.lastName = formData.lname;
    const destination = this.getDestination();
    const role = this.props.forRole || UserRoles.Creator;

    this.props.signUp({ ...formData, role }, this.props.forRole).then((resp) => {
      if (resp.status === 200) {
        const { uuid, createdAt } = resp.json;

        // tracking tags
        GoogleTagManager.fireEvent(GTM_CONSTS.SIGN_UP_SUCCESS, { role });

        partnerStackSignUp({ name: `${formData.fname} ${formData.lname}`, email: formData.email });

        this.props.history.push(destination);
      }
    });
  };

  getInitialValues = () => {
    var queryString = window.location.search;
    if (queryString[0] === "?") {
      queryString = queryString.substring(1);
    }
    var query = parse(queryString);

    return {
      email: get(query, "email"),
    };
  };

  getSubtitle = () => {
    if (this.props.forRole === UserRoles.Sponsor) {
      return "Are you a listener looking to sponsor podcasts by donating or subscribing to their exclusive content? Fill out your name, email, \
      and a password to create your listener account. Your name and email will be shared with the podcasts you subscribe to.";
    }

    return (
      <div>
        Are you a Podcaster ready to host and distribute your show on RedCircle? Fill in your name,
        email, and a password to create your account. If you're not a Podcaster, please sign up
        through the
        <a href="https://app.redcircle.com/sign-up/advertiser"> Advertiser Sign Up </a> or
        <a href="https://app.redcircle.com/sign-up/sponsor"> Listener Sign Up </a> instead.
      </div>
    );
  };

  getTitle = () => {
    if (this.props.forRole === UserRoles.Sponsor) {
      return "Listener Account Sign Up";
    }

    return "Podcaster Account Sign Up";
  };

  getDestination = () => {
    const { forRole } = this.props;
    const { interval, tier, goto } = parse(window.location.search);
    const role = forRole || UserRoles.Creator;

    if (interval && tier) {
      return `/sign-up-tiers${window.location.search}`;
    }
    if (role === UserRoles.Sponsor) {
      if (goto) return goto;
      return "/sponsor-show-list";
    }
    return "/shows";
  };

  render() {
    var { user, isLoading, validationErrors } = this.props;
    return (
      <LoggedOutFrame title={this.getTitle()} subtitle={this.getSubtitle()} hideSignIn hideSignUp>
        <Row className="sign-up-row">
          <Col xs={24} md={{ span: 12, offset: 6 }}>
            <UserForm
              onSubmit={this.signUp}
              user={user}
              initialValues={this.getInitialValues()}
              isLoading={isLoading}
              validationErrors={validationErrors}
              mode="create"
              submitEnabled={this.state.submitEnabled}
              password={this.props.password}
            />
            <div className="user-form__fine-print">
              By signing up, you agree to our{" "}
              <a href="https://redcircle.com/terms">Terms of Service</a> and{" "}
              <a href="https://redcircle.com/privacy">Privacy Policy</a>. This site is protected by
              reCAPTCHA and the Google{" "}
              <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
              <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
            </div>
          </Col>
        </Row>
      </LoggedOutFrame>
    );
  }
}

export default SignUpPage;
