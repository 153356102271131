import { GoogleTagManager, GTM_CONSTS } from "src/lib/google_tag_manager";
import getDefaultRole from "src/lib/user_roles";
import {
  signOutEnd,
  signOutStart,
  updateAccountError,
  updateAccountInvalid,
  updateAccountStart,
  updateAccountSuccess,
  updateCurrentRole,
} from "../actions/account";
import {
  userFetchFail,
  userFetchStart,
  userFetchSuccess,
  userLogin,
  userLogout,
  USER_ADD_ROLE,
  USER_ATTRIBUTE,
} from "../actions/user";
import userAPI from "../api/user";
import UserStore, { saveUserRole } from "../lib/user_store";
import ActionManager, { GetStateFunc } from "./base";
import DefaultActionManager from "./default_action_manager";
import { ReduxDispatch } from "src/hooks/redux-ts";
import { User } from "redcircle-types";
export class FetchUserManager extends ActionManager<User, { userUUID: string; authToken: string }> {
  defaultErrorMessage() {
    return "Uh oh, something went wrong fetching your user account. Please try again later.";
  }

  defaultPreCallActionCreator() {
    return userFetchStart;
  }

  defaultSuccessActionCreator() {
    return userFetchSuccess;
  }
  defaultErrorActionCreator() {
    return userFetchFail;
  }
  response400(dispatch: ReduxDispatch, json: any) {
    return this.responseError(dispatch, 400, json);
  }
  execute() {
    return userAPI.fetchUser(this.args.userUUID, this.args.authToken);
  }
}

export class SignOutActionManager extends ActionManager<
  object,
  { token: string; signOutRedirect?: "" }
> {
  preCall(dispatch: ReduxDispatch) {
    dispatch(signOutStart());
  }

  finishCall(dispatch: ReduxDispatch) {
    UserStore.remove();
    dispatch(signOutEnd());
    dispatch(userLogout());
    GoogleTagManager.fireEvent(GTM_CONSTS.LOG_OUT);
    window.location.href = this.args.signOutRedirect || "/";
  }

  responseOK(dispatch: ReduxDispatch, json: any) {
    this.finishCall(dispatch);
  }

  response400(dispatch: ReduxDispatch, json: any) {
    this.finishCall(dispatch);
  }

  response403(dispatch: ReduxDispatch, json: any) {
    this.finishCall(dispatch);
  }

  responseError(dispatch: ReduxDispatch, statusCode: number, json: unknown) {
    this.finishCall(dispatch);
  }

  catchError(dispatch: ReduxDispatch, err: Error) {
    this.finishCall(dispatch);
  }

  defaultErrorMessage() {
    return "Uh oh, something went wrong signing out. Please try again later.";
  }
}

export class AccountUpdateActionManager extends ActionManager {
  execute() {
    return userAPI.update(this.args.userForm, this.args.existingUser);
  }

  responseOK(dispatch: ReduxDispatch, json: any) {
    if (this.args.skipStore) {
      json = { isSuccess: true };
    } else {
      UserStore.save(json);
    }
    dispatch(updateAccountSuccess(json));

    if (this.args.customSuccess) {
      this.showSuccess(dispatch, this.args.customSuccess);
    } else {
      this.showSuccess(dispatch, "Account update saved.");
    }
  }

  defaultErrorMessage() {
    return "Uh oh, something went wrong updating your account. Please try again later.";
  }

  defaultPreCallActionCreator() {
    return updateAccountStart;
  }

  defaultSuccessActionCreator() {
    return updateAccountSuccess;
  }

  default400ActionCreator() {
    return updateAccountInvalid;
  }

  defaultErrorActionCreator() {
    return updateAccountError;
  }
}

/**
 * Special AccountUpdateAction Manager that does not auto reload redux state on a failed
 * attempt to update user account. Updates User redux state when successful.
 * (i.e needs a upper and lower case complexity, etc)
 */
export class CredentialUpdateActionManager extends AccountUpdateActionManager {
  response403() {
    //do nothing
  }

  responseOK(dispatch: ReduxDispatch, json: any) {
    const role = this.args.forRole || getDefaultRole(json);
    dispatch(updateCurrentRole(role));
    saveUserRole(role);
    dispatch(userLogin(json));
    UserStore.save(json);

    this.showSuccess(dispatch, this.args.customSuccess || "Account update saved.");
  }
}

export const updatePassword = (newPassword: any, user: any, skipStore: any) =>
  new AccountUpdateActionManager({
    userForm: { password: newPassword },
    existingUser: user,
    skipStore,
  }).run();

export function setUserAttribute(key: string, value: string) {
  if (UserStore.isAdminLogin()) {
    // eslint-disable-next-line
    console.log("User Attribute Not Set for Admin Login. Would have set:", key, value);
    return { type: "NO_ACTION" };
  }
  return new DefaultActionManager({
    auth: true,
    route: "users/me/set-attribute",
    actionName: USER_ATTRIBUTE,
    method: "POST",
    body: {
      key,
      value,
    },
  }).run();
}

export function addRole(user: any, role: any) {
  return newAddRoleActionManger(user, role).run();
}

// Only use this if you want to chain requests.
export function addRoleFetch(
  user: any,
  role: any,
  dispatch: ReduxDispatch,
  getState: GetStateFunc
) {
  return newAddRoleActionManger(user, role).execute(dispatch, () => {
    return {
      user: { user },
    };
  });
}

function newAddRoleActionManger(user: { uuid: any }, role: any) {
  return new DefaultActionManager({
    auth: true,
    route: `users/${user.uuid}/add-role`,
    actionName: USER_ADD_ROLE,
    method: "POST",
    body: {
      role,
    },
  });
}
