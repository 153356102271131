import React from "react";
import { Link, useHistory } from "react-router-dom";
import Loading from "src/components/lib/loading";
import Page from "src/components/lib/page";
import { ADVERTISING_OPT_IN_THRESHOLD } from "src/constants/misc";
import { permissionTypes } from "src/constants/permission_roles";
import { useGetAllUserShowsWithCampaigns } from "src/hooks/shows";
import { useCanAccessBound, useHasAtLeastOnePermitted } from "src/lib/permissions";
import { isShowRedirected, isShowWaitListed } from "src/lib/show";
import ShowListRAP from "./show_list_rap";

export default function AdPlatformPage() {
  const history = useHistory();
  const canAccess = useCanAccessBound();

  // check perms
  const permittedForAdPlatform = useHasAtLeastOnePermitted(permissionTypes.viewRap);
  if (permittedForAdPlatform === false) history.replace("/shows");

  const { shows = {}, isInitiallyLoading } = useGetAllUserShowsWithCampaigns();

  // redirect if only one show
  const visibleShows = Object.values(shows).filter(
    (show) => show.isVisible && !show.isHardDeleted && canAccess(permissionTypes.viewRap, show.uuid)
  );
  if (visibleShows.length === 1) history.push(`/ad-platform/${visibleShows[0].uuid}`);

  // sort shows by eligibility and filter out shows that have redirected away from red circle
  const showList = Object.values(visibleShows)
    .sort((showA, showB) => {
      let scoreA = 3;
      let scoreB = 3;
      if (showA.estimatedWeeklyDownloads >= ADVERTISING_OPT_IN_THRESHOLD) scoreA = 2;
      if (showB.estimatedWeeklyDownloads >= ADVERTISING_OPT_IN_THRESHOLD) scoreB = 2;
      if (showA.isRemote) scoreA = 1.5;
      if (showB.isRemote) scoreB = 1.5;
      if (showA.advertisementSettings) scoreA = 1;
      if (showB.advertisementSettings) scoreB = 1;
      return scoreA - scoreB;
    })
    .filter(
      (show) =>
        !isShowRedirected(show) &&
        !isShowWaitListed(show) &&
        canAccess(permissionTypes.viewRap, show.uuid)
    );

  return (
    <Page className="ad-platform-page" pageTitle="Ad Platform">
      <Page.Header>
        <Page.Title>RedCircle Ad Platform (RAP)</Page.Title>
      </Page.Header>
      <Page.Divider />

      <Page.Section>
        {isInitiallyLoading && <Loading />}
        {!isInitiallyLoading && (
          <>
            {showList.length ? (
              <ShowListRAP shows={showList} />
            ) : (
              <div className="text-center">
                There are no podcasts on your account.
                <br />
                Go to your{" "}
                <Link to={`/shows/`}>
                  <b>Podcasts Dashboard</b>
                </Link>{" "}
                to get started.
              </div>
            )}
          </>
        )}
      </Page.Section>
    </Page>
  );
}
