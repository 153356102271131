import { useEffect, useState } from "react";
import { Modal } from "redcircle-ui";
import { assignScripts } from "src/action_managers/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { pluralize } from "src/lib/strings";
import {
  AssignScript,
  calcInitialValues,
  calculateReqBody,
  LocalScriptAssignments,
} from "../campaign_scheduler_modal/campaign_script_utils";

interface IAssignScriptModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>> | ((flag: boolean) => void);
  campaignUUID: string;
}

export const AssignScriptModal = ({ campaignUUID, open, setOpen }: IAssignScriptModal) => {
  const dispatch = useDispatchTS();
  const {
    scriptsByCampaignUUID,
    isLoading: isScriptsLoading,
    scriptsByUUID,
  } = useSelectorTS((state) => state.scripts);
  const {
    campaignItemByCampaignUUID,
    campaignItems: campaignItemsByUUID,
    isLoading: isCampaignItemsLoading,
  } = useSelectorTS((state) => state.campaignItems);
  const publicShows = useSelectorTS((state) => state.publicShows);
  const isPublicShowsLoading = publicShows?.isLoading;

  const currentScripts = scriptsByCampaignUUID?.[campaignUUID];

  const itemsForCampaign = campaignItemByCampaignUUID?.[campaignUUID]?.map(
    (campaignItemUUID) => campaignItemsByUUID[campaignItemUUID]
  );

  const newInitialValues = calcInitialValues({
    items: itemsForCampaign,
    publicShows,
    scriptsByUUID,
  });

  const [localScriptAssignments, setLocalScriptAssignments] =
    useState<LocalScriptAssignments>(null);
  const [isLoading, setIsLoading] = useState(false);

  // Handlers

  const handleCancel = () => {
    setOpen(false);
    if (newInitialValues) {
      setLocalScriptAssignments(newInitialValues); // reset local state on cancel
    }
  };

  const handleAssignScripts = async () => {
    if (localScriptAssignments) {
      try {
        const requestBody = calculateReqBody(newInitialValues, localScriptAssignments);
        setIsLoading(true);
        const resp = await dispatch(assignScripts(campaignUUID, requestBody));
        if (resp.status === 200) {
          setOpen(false);
        }
      } catch (err) {
        console.log("error", err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Initially sync assigned script local state,
  useEffect(() => {
    if (!isScriptsLoading && !isCampaignItemsLoading && !isPublicShowsLoading) {
      if (localScriptAssignments === null && newInitialValues !== null) {
        // Only fires once to remove initial state of null
        setLocalScriptAssignments?.(newInitialValues);
      }
    }
  }, [
    newInitialValues,
    localScriptAssignments,
    publicShows,
    isCampaignItemsLoading,
    isScriptsLoading,
    isPublicShowsLoading,
  ]);

  // Update local Assignments after form submission
  useEffect(() => {
    if (!isLoading) {
      const newInitialValues = calcInitialValues({
        items: itemsForCampaign,
        publicShows,
        scriptsByUUID,
      });
      setLocalScriptAssignments?.(newInitialValues);
    }
  }, [isLoading]);

  return (
    <Modal open={open} size="sm" onClose={() => handleCancel()}>
      <Modal.Title>Assign Scripts</Modal.Title>
      <Modal.Body>
        <AssignScript
          campaignItems={itemsForCampaign}
          publicShowState={publicShows}
          scripts={currentScripts}
          localScriptAssignments={localScriptAssignments}
          setLocalScriptAssignments={setLocalScriptAssignments}
        />
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Modal.SubmitButton onClick={handleAssignScripts} disabled={isLoading}>
          {`Assign ${pluralize("Script", currentScripts?.length)}`}
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};
