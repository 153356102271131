import React from "react";
import { classNames } from "react-extras";
import { getImageURL } from "src/lib/get_image_url";

interface AlbumArtProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  className?: string;
  isRedirected?: boolean;
  imageSize?: string;
}

export const AlbumArt = ({ src, className, isRedirected, imageSize, ...rest }: AlbumArtProps) => {
  return (
    <img
      src={getImageURL(src, imageSize)}
      className={classNames("album-art-component", className, { redirected: isRedirected })}
      alt="album-art"
      data-testid="test-album-art"
      {...rest}
    />
  );
};
