export const EMAIL_VERIFICATION_START = "EMAIL_VERIFICATION_START";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_ERROR = "EMAIL_VERIFICATION_ERROR";

export function emailVerificationStart() {
  return {
    type: EMAIL_VERIFICATION_START,
  };
}

export function emailVerificationSuccess() {
  return {
    type: EMAIL_VERIFICATION_SUCCESS,
  };
}

export function emailVerificationError(errorMessage) {
  return {
    type: EMAIL_VERIFICATION_ERROR,
    errorMessage,
  };
}

export const SEND_VERIFICATION_EMAIL_START = "SEND_VERIFICATION_EMAIL_START";
export const SEND_VERIFICATION_EMAIL_SUCCESS = "SEND_VERIFICATION_EMAIL_SUCCESS";
export const SEND_VERIFICATION_EMAIL_ERROR = "SEND_VERIFICATION_EMAIL_ERROR";

export function sendVerificationEmailStart() {
  return {
    type: SEND_VERIFICATION_EMAIL_START,
  };
}

export function sendVerificationEmailSucess() {
  return {
    type: SEND_VERIFICATION_EMAIL_SUCCESS,
  };
}

export function sendVerificationEmailError(errorMessage) {
  return {
    type: SEND_VERIFICATION_EMAIL_ERROR,
    errorMessage,
  };
}
