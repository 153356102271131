import React, { Component } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { hideModal } from "../../../actions/modal";
import { setUserAttribute } from "../../../action_managers/account";
import { HAS_SEEN_MONETIZATION_INTRO } from "../../../constants/user_attributes";
import Divider from "../../lib/divider";
import ExternalLink from "../../lib/external_link";
import BaseModal from "../base_modal";
import "./promotions-intro-modal.scss";

class MonetizationIntroModal extends Component {
  close = () => {
    this.props.markIntroSeen();
    this.props.hideModal();
  };

  render() {
    return (
      <BaseModal
        isLoading={false}
        isMini={true}
        columns={10}
        offset={1}
        title={"Make Money From Your Work"}
        close={this.close}
        hideCloseButton={true}
        showContent={true}
        Content={() => (
          <Grid fluid>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <p>
                  With RedCircle, you can easily accept donations from your listeners. With just a
                  few clicks, you can start earning.
                </p>
                <Divider />
                <p>Here{"'"}s how it works:</p>
                <div className="product-intro-modal-row">
                  <div className="product-intro-modal-row__number">
                    <span>1</span>
                  </div>
                  <div className="product-intro-modal-row__content">
                    <strong>Connect Your Bank Account</strong>
                    <p>
                      RedCircle partners with{" "}
                      <ExternalLink href="https://stripe.com">Stripe</ExternalLink> to seamlessly
                      connect your bank account to the platform so you can get paid via direct
                      deposit.
                    </p>
                    <p>
                      Don't worry, we can't draw money from your account, and Stripe is known for
                      having the best security on the web.
                    </p>
                  </div>
                </div>
                <div className="product-intro-modal-row">
                  <div className="product-intro-modal-row__number">
                    <span>2</span>
                  </div>
                  <div className="product-intro-modal-row__content">
                    <strong>Ask Your Listeners For Support</strong>
                    <p>
                      Once you enable the tipping feature, a link to your tipping page will be added
                      to your show notes and your RedCircle public web page. Your listeners can
                      visit the page and donate whatever amount they like.
                    </p>
                  </div>
                </div>
                <div className="product-intro-modal-row">
                  <div className="product-intro-modal-row__number">
                    <span>3</span>
                  </div>
                  <div className="product-intro-modal-row__content">
                    <strong>Cash Out</strong>
                    <p>
                      Once the tips are processed, you can transfer your earnings directly to your
                      bank account.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10} mdOffset={1} xs={12}>
                <div className="mini-modal-footer">
                  <Button className="btn btn-primary" onClick={this.close}>
                    Check It Out
                  </Button>
                </div>
              </Col>
            </Row>
          </Grid>
        )}
      />
    );
  }
}

export default connect(({ user }) => ({ user }), {
  hideModal,
  markIntroSeen: () => {
    return setUserAttribute(HAS_SEEN_MONETIZATION_INTRO, "true");
  },
})(MonetizationIntroModal);
