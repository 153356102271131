import { Button as AntdButton, ButtonProps, Tooltip } from "antd";
import numeral from "numeral";
import React, { ButtonHTMLAttributes, ReactElement, ReactNode, useRef } from "react";
import "./loading_button.scss";

interface ILoadingButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  Button?: ReactElement | HTMLButtonElement | any;
  isLoading?: boolean;
  version?: "secondary" | "primary"; // No Longer needed, kept for backwards compatibility, was meant to switch loading spinner color type
  disabledMessage?: string | false;
  disabled?: boolean;
  progress?: number;
  children?: ReactNode;
  type?: "submit" | "reset" | "button" | undefined;
  antdBtnProps?: ButtonProps;
}

const LoadingButton = (props: ILoadingButton) => {
  const {
    className,
    isLoading,
    Button = AntdButton,
    disabled,
    progress,
    disabledMessage,
    children,
    antdBtnProps,
    type,
    ...rest
  } = props;

  let baseBtnComponentProps: ButtonProps | ButtonHTMLAttributes<HTMLButtonElement> = {};

  const divRef = useRef<HTMLDivElement>(null); // Used to fix bug where tooltip stays on page when explicitly hovered after modal is closed

  // When component specifies no button base component/ default to antd button
  if (Button === AntdButton) {
    baseBtnComponentProps = {
      type: "primary",
      size: "large",
      htmlType: type,
      ...antdBtnProps,
    } as ButtonProps;
  } else {
    // Backward compatibility for bootstrap btn props when specified to use it in LoadingButton
    baseBtnComponentProps = { type } as ButtonHTMLAttributes<HTMLButtonElement>;
  }

  const showProgress = typeof progress === "number" && Boolean(isLoading);

  const button = (
    <Button
      loading={isLoading}
      disabled={disabled}
      className={`loading-button ${typeof className === "string" ? className : ""}`}
      {...rest}
      {...baseBtnComponentProps}>
      {showProgress ? `${numeral(progress).format("(0 %)")}` : children}
    </Button>
  );

  if (typeof disabledMessage === "string") {
    return (
      <Tooltip
        title={disabledMessage}
        destroyTooltipOnHide={{ keepParent: false }}
        getPopupContainer={() => (divRef?.current ? divRef.current : document.body)}>
        <div ref={divRef} className="p-hxxs">
          {button}
        </div>
      </Tooltip>
    );
  }

  return button;
};

export default LoadingButton;
