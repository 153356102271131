import { useEffect, useRef, useState } from "react";
import { getCampaign, reforecastCampaignItem } from "src/action_managers/campaigns";
import { isUUID } from "src/lib/uuid";
import { useDispatchTS, useSelectorTS } from "./redux-ts";

const MAX_ATTEMPTS = 5;

export const useGetCampaignItems = (campaignUUID?: string) => {
  const dispatch = useDispatchTS();
  const {
    campaignItemByCampaignUUID,
    isLoading: isCampaignItemsLoading,
    campaignItems: campaignItemsByUUID,
  } = useSelectorTS((state) => state.campaignItems);

  const campaignItemsUUIDs = campaignUUID ? campaignItemByCampaignUUID[campaignUUID] : undefined;

  const campaignItems = campaignItemsUUIDs?.map((uuid) => campaignItemsByUUID[uuid]);
  const isUUIDAvailable = typeof campaignUUID === "string" && isUUID(campaignUUID);

  const isCampaignItemsAvailable = !!campaignItems;

  // Capture fail fetch attempts
  const failedAttempts = useRef<number>(1);
  // Capture the first successful fetch
  const success = useRef<boolean>(false);

  // Reset fetch attempts counter on campaignUUID change
  useEffect(() => {
    failedAttempts.current = 1;
    success.current = false;
  }, [campaignUUID]);

  useEffect(() => {
    if (!isCampaignItemsLoading) {
      // Currently no other campaignsItems are being fetched
      if (isUUIDAvailable) {
        // UUID is currently available
        if (!isCampaignItemsAvailable) {
          // Campaign is not found in Redux state
          if (!success.current && failedAttempts.current < MAX_ATTEMPTS) {
            // MAX attempts have not been reached
            dispatch(getCampaign(campaignUUID))
              .then(() => (success.current = true))
              .catch(() => failedAttempts.current++);
          }
        }
      }
    }
  }, [campaignUUID, isCampaignItemsLoading, isUUIDAvailable, isCampaignItemsAvailable]);

  return campaignItems;
};

interface IReforecastData {
  [uuid: string]: {
    impressionsByPosition: Record<string, number>;
  };
}

export const useGetCampaignItemReforecast = (campaignItemUUIDs?: string[]) => {
  const dispatch = useDispatchTS();
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<IReforecastData | null>(null);

  useEffect(() => {
    const run = async () => {
      if (!campaignItemUUIDs || campaignItemUUIDs.length === 0) {
        setResults(null);
        return;
      }

      setIsLoading(true);
      const responseResults = {} as IReforecastData;
      const promises = campaignItemUUIDs.map(async (uuid) => {
        if (!isUUID(uuid)) return;
        const res = await dispatch(reforecastCampaignItem(uuid));
        if (res.status === 200) responseResults[uuid] = res.json as IReforecastData[string];
      });
      await Promise.all(promises);

      setResults(responseResults);
      setIsLoading(false);
    };

    run();
  }, [campaignItemUUIDs]);

  return { data: results, isLoading };
};
