import React, { useEffect } from "react";
import { FormControl } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./phone-input.scss";

const COUNTRY_CODE_DEFAULT = "US";

export const PhoneNumberInput = (props) => {
  const { inputRef, onNumberChange, onCountryChange, nationalNumber, countryCode } = props;

  // no initial country? let's default to US. we need to do this because of how default values work
  // while <PhoneInput /> is unable to be fully controlled
  useEffect(() => {
    if (!countryCode) onCountryChange(COUNTRY_CODE_DEFAULT);
  }, []);

  // this also fires on country change?
  const handlePhoneChange = (value) => {
    onNumberChange(value || "");
  };

  const handleCountryChange = (value) => {
    onCountryChange(value || "");
  };

  return (
    <PhoneInput
      flagUrl={"https://media.redcircle.com/assets/flags/3x2/{XX}.svg"}
      defaultCountry={countryCode}
      value={nationalNumber}
      placeholder={"555-754-3010"}
      onChange={handlePhoneChange}
      onCountryChange={handleCountryChange}
      inputComponent={FormControlWithRef}
      ref={inputRef}
    />
  );
};

/*
  Helper component for Redux Form Fields
 */
export const PhoneNumberInputComponent = (props) => {
  let { phoneNumber, phoneNumberCountryCode, inputRef } = props;
  if (props.accessor) {
    ({ phoneNumber, phoneNumberCountryCode } = props.accessor(props));
  }
  const phoneNumberInput = phoneNumber.input;
  const countryCodeInput = phoneNumberCountryCode.input;
  return (
    <PhoneNumberInput
      onNumberChange={phoneNumberInput.onChange}
      onCountryChange={countryCodeInput.onChange}
      nationalNumber={phoneNumberInput.value}
      countryCode={countryCodeInput.value}
      inputRef={inputRef}
    />
  );
};

const FormControlWithRef = React.forwardRef((props, ref) => {
  const onChange = (e) => props.onChange(e.target.value);
  return <FormControl {...props} inputRef={ref} onChange={onChange} />;
});
