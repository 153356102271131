import { GET_PROGRAMMATIC_EARNINGS } from "../../action_managers/programmatic_earnings";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { ProgrammaticEarningsReduxState } from "./types";

const initialState: ProgrammaticEarningsReduxState = {
  programmaticEarnings: {},
};

const getProgrammaticEarnings = httpReducer(GET_PROGRAMMATIC_EARNINGS, initialState, {
  success: (state, action) => {
    const response = action.payload.resp;
    return {
      ...state,
      programmaticEarnings: response,
    };
  },
});

export default reduceReducers<ProgrammaticEarningsReduxState>(getProgrammaticEarnings);
