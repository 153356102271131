import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  PointerSensor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { RowProps } from "antd";
import { SortOrder } from "antd/es/table/interface";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { classNames } from "react-extras";
import { AiFillFolder, AiFillFolderOpen } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { Table } from "redcircle-ui";
import RCButton from "src/components/lib/button";
import ContextMenu from "src/components/lib/context_menu";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import {
  CampaignStateToFriendly,
  CAMPAIGN_TAG_TYPE__CAMPAIGN,
  CAMPAIGN_TAG_TYPE__TAG,
} from "src/constants/campaigns";
import { permissionPresets, permissionTypes } from "src/constants/permission_roles";
import { useGetMyPermissions } from "src/hooks/org";
import { getCampaignField } from "src/lib/campaign";
import { canAdvertiserAccess } from "src/lib/permissions";
import { isUserPresetAllowed } from "src/lib/permission_roles";
import { CampaignState, ICampaign, ICampaignTag } from "redcircle-types";
import { generateAdvertisingReportCopyLink } from "../../advertising_reporting/advertising_report_controls";
import { downloadCartInfoForCampaign } from "../campaign_page/cart_csv";
import styles from "./campaign_list_page.module.scss";

interface ICampaignListTableProps {
  data: any;
  sort: { columnKey: string; order: SortOrder };
  filters: { archive: boolean };
  totalCampaigns: number;
  onToggleFolder: (uuid: string) => void;
  onEditFolder: (tag: ICampaignTag) => void;
  onDeleteFolder: (tag: ICampaignTag) => void;
  folderState: Partial<Record<string, boolean>>;
  onAssign: (campaign: ICampaign, tag?: ICampaignTag) => void;
  onShowMoveModal: (itemUUID: string) => void;
  onSortColumn: (sorter: any) => void;
  onArchiveToggle: (campaign: ICampaign) => void;
  parentFolderToChildCampaignMap: {
    [parentFolderUUID: string]: ICampaign[];
  };
}

type TRecord = ICampaign & ICampaignTag & { type: string };

const DROPPABLE_ID_ROOT = "root";

export default function CampaignListTable({
  data,
  sort,
  filters,
  totalCampaigns,
  onAssign,
  folderState,
  onToggleFolder,
  onEditFolder,
  onDeleteFolder,
  onShowMoveModal,
  onSortColumn,
  onArchiveToggle,
  parentFolderToChildCampaignMap,
}: ICampaignListTableProps) {
  const history = useHistory();

  const [isDraggingOver, setIsDraggingOver] = useState<string | "root" | false>(false); // tracks group of currently dragging item
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 8 } }));
  const dataToMap = data.reduce((acc: any, curr: any) => ({ [curr.uuid]: curr, ...acc }), {});

  const { orgPermission } = useGetMyPermissions();

  const columns: ColumnsType<TRecord> = [
    {
      key: "name",
      title: "Name",
      sorter: true,
      sortOrder: sort.columnKey === "name" ? sort.order : undefined,
      render: (_: any, record: TRecord) => {
        if (record.type === CAMPAIGN_TAG_TYPE__CAMPAIGN)
          return <CampaignListTableNameCell record={record} />;
        if (record.type === CAMPAIGN_TAG_TYPE__TAG)
          return (
            <CampaignListTableFolderCell
              onToggleFolder={onToggleFolder}
              isCollapsed={!!folderState[record.uuid]}
              record={record}
            />
          );
      },
    },
    {
      key: "state",
      title: "state",
      dataIndex: "state",
      render: (campaignState: string, record: TRecord) => (
        <span>
          {record.type === CAMPAIGN_TAG_TYPE__CAMPAIGN && (
            <ExclusiveTag className="ellipsis">
              {CampaignStateToFriendly[campaignState]}
            </ExclusiveTag>
          )}
        </span>
      ),
      sorter: true,
      sortOrder: sort.columnKey === "state" ? sort.order : undefined,
      filters: Object.keys(CampaignStateToFriendly).map((key) => ({
        text: CampaignStateToFriendly[key as CampaignState],
        value: key,
      })),
      onFilter: (value, record) => {
        if (record.type !== CAMPAIGN_TAG_TYPE__CAMPAIGN) {
          const children = parentFolderToChildCampaignMap[record.uuid];
          return children?.some(
            (child) => child?.state === value && (filters.archive ? true : !child?.archived)
          );
        }

        return record.state === value;
      },
    },
    {
      key: "createdAt",
      title: "Created",
      dataIndex: "createdAt",
      render: (createdAt: string, record: TRecord) => (
        <span className="ellipsis">
          {record.type === CAMPAIGN_TAG_TYPE__CAMPAIGN &&
            dayjs(Number(createdAt) * 1000).format("MMM D, YYYY h:mm A")}
        </span>
      ),
      sorter: true,
      sortOrder: sort.columnKey === "createdAt" ? sort.order : undefined,
    },
    {
      key: "startsAt",
      title: "Starts",
      dataIndex: "startsAt",
      render: (_, record: TRecord) => {
        const startsAt = getCampaignField("startsAt", { campaign: record });
        return (
          <span className="ellipsis">
            {record.type === CAMPAIGN_TAG_TYPE__CAMPAIGN &&
              (startsAt && startsAt > 0
                ? dayjs(Number(startsAt) * 1000).format("MMM D, YYYY h:mm A")
                : "N/A")}
          </span>
        );
      },
      sorter: true,
      sortOrder: sort.columnKey === "startsAt" ? sort.order : undefined,
    },
    {
      key: "menu",
      title: "Actions",
      render: (_: any, record: TRecord) => {
        const campaignUUIDs =
          record.type === CAMPAIGN_TAG_TYPE__TAG
            ? parentFolderToChildCampaignMap[record.uuid]?.map((c) => c.uuid)
            : [record.uuid];

        const reportURL = generateAdvertisingReportCopyLink({ campaignUUIDs: campaignUUIDs });

        const campaignMenuItems: Record<string, () => void> = {
          "View Campaign": () => history.push(`/campaigns/${record.uuid}`),
          "View Reporting": () => history.push(`${reportURL.pathname}${reportURL.search}`),
        };

        if (canAdvertiserAccess(permissionTypes.viewCampaignReporting, record)) {
          campaignMenuItems["Download CSV"] = () => {
            downloadCartInfoForCampaign(record.uuid);
          };
        }

        const tagMenuItems: Record<string, () => void> = {
          "View Reporting": () => history.push(`${reportURL.pathname}${reportURL.search}`),
        };

        if (canAdvertiserAccess(permissionTypes.organizeCampaigns, record)) {
          campaignMenuItems["Move To Folder"] = () => onShowMoveModal(record.uuid);
          tagMenuItems["Rename Folder"] = () => onEditFolder(record);
          tagMenuItems["Delete Folder"] = () => onDeleteFolder(record);

          if (
            isUserPresetAllowed({
              userPreset: orgPermission?.preset,
              limitPreset: permissionPresets.campaignEditor,
            })
          ) {
            campaignMenuItems["Copy Campaign"] = () =>
              history.push(`/campaigns/copy/${record.uuid}`);
          }

          // Add Archive setting
          if (
            record.state === CampaignState.CampaignStateCreated ||
            record.state === CampaignState.CampaignStateCompleted
          ) {
            const archiveText = record.archived ? "Unarchive Campaign" : "Archive Campaign";
            campaignMenuItems[archiveText] = () => onArchiveToggle(record);
          }
        }

        const allMenuItems = {
          ...(record.type === CAMPAIGN_TAG_TYPE__CAMPAIGN && campaignMenuItems),
          ...(record.type === CAMPAIGN_TAG_TYPE__TAG && tagMenuItems),
        };

        if (isEmpty(allMenuItems)) return null;
        return <ContextMenu noCircle menuItems={allMenuItems} />;
      },
    },
  ];

  // tracks currently dragging item over which parent group
  const handleDragOver = useCallback(
    ({ over }: DragOverEvent) => {
      if (over) {
        const overItem = dataToMap[over.id as string];
        // get group of item
        if (overItem) {
          const parentId = overItem.parentCampaignTagUUIDs && overItem.parentCampaignTagUUIDs[0];
          if (parentId) {
            setIsDraggingOver(parentId);
          } else if (overItem.type === CAMPAIGN_TAG_TYPE__TAG) {
            setIsDraggingOver(overItem.uuid);
          } else {
            setIsDraggingOver(DROPPABLE_ID_ROOT);
          }
        }
      } else {
        setIsDraggingOver(false);
      }
    },
    [dataToMap]
  );

  // tracks drop and what to assign on drop
  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (isDraggingOver) setIsDraggingOver(false);
    if (onAssign && over) {
      const activeItem = dataToMap[active.id as string];
      const overItem = dataToMap[over.id as string];

      // if over item is a folder, assign
      if (overItem.type === CAMPAIGN_TAG_TYPE__TAG) {
        // if it already shares tag as parent, do nothing
        if (activeItem.parentCampaignTagUUIDs?.includes(overItem.uuid)) return;
        onAssign(activeItem, overItem);
      }

      // if over item is a campaign, check if it has parents
      if (overItem.type === CAMPAIGN_TAG_TYPE__CAMPAIGN) {
        const parentUUID = overItem.parentCampaignTagUUIDs?.[0];
        if (parentUUID && !activeItem.parentCampaignTagUUIDs?.includes(parentUUID)) {
          // if theres a destination parent and it doesnt share the same parent, assign to that parent
          onAssign(activeItem, dataToMap[parentUUID]);
        } else if (!parentUUID && activeItem.parentCampaignTagUUIDs?.length) {
          // if it has parent but dest has none, assign to root
          if (activeItem.parentCampaignTagUUIDs?.length) onAssign(activeItem);
        }
      }
    }
  };

  const handleDragCancel = () => {
    if (isDraggingOver) setIsDraggingOver(false);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          defaultPageSize: 50,
          hideOnSinglePage: true,
          responsive: true,
          position: ["bottomCenter"],
          showTotal: () => totalCampaigns,
        }}
        onChange={(pagination, filters, sorter: any) => onSortColumn(sorter)}
        rowKey={(record: TRecord) => record.uuid}
        components={{
          body: {
            row: (props: RowProps) => (
              <CampaignListTableRow
                isDraggingOver={isDraggingOver}
                record={dataToMap[props["data-row-key" as keyof typeof props]]}
                {...props}
              />
            ),
          },
        }}
      />
    </DndContext>
  );
}

const CampaignListTableRow = ({ isDraggingOver, record, ...props }: any) => {
  const { setNodeRef } = useDroppable({
    id: record?.uuid,
    disabled: !canAdvertiserAccess(permissionTypes.organizeCampaigns, record),
  });

  if (!record) return null;
  const isFolder = record.type === CAMPAIGN_TAG_TYPE__TAG;
  const parentId = record.parentCampaignTagUUIDs && record.parentCampaignTagUUIDs[0];
  const isDragGroup =
    isDraggingOver && (isDraggingOver === parentId || isDraggingOver === record.uuid);
  const isDragRoot = isDraggingOver === DROPPABLE_ID_ROOT && !parentId && !isFolder;

  return (
    <tr
      {...props}
      ref={setNodeRef}
      className={classNames(
        isFolder && styles.isFolder,
        props.className,
        styles.droppable,
        (isDragGroup || isDragRoot) && styles.isAnyOver
      )}
    />
  );
};

const CampaignListTableNameCell = ({ record }: { record: TRecord }) => {
  const isDisabled = !canAdvertiserAccess(permissionTypes.organizeCampaigns, record);
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: record.uuid,
    disabled: isDisabled,
  });
  const isChild = record.parentCampaignTagUUIDs && record.parentCampaignTagUUIDs.length > 0;
  const isArchived = record?.archived;

  return (
    <div
      className={classNames(
        "flex-row-container align-center",
        styles.nameCell,
        !isDisabled && styles.draggable,
        isDragging && styles.isDragging,
        isChild && styles.isChild,
        {
          [styles.archived]: record.archived,
        }
      )}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={{
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
      }}>
      {isChild && (
        <div className={classNames(styles.childIndicator, "flex-row-container align-center")} />
      )}
      <Link to={`/campaigns/${record.uuid}`} className={styles.linkTitle}>
        <strong className="line-clamp-2">{record.name}</strong>
      </Link>
      {isArchived ? (
        <ExclusiveTag className="m-lxxs" color="rgb(128, 128, 128)" backgroundRGB={[149, 149, 149]}>
          [ARCHIVED]
        </ExclusiveTag>
      ) : undefined}
    </div>
  );
};

const CampaignListTableFolderCell = ({
  onToggleFolder,
  isCollapsed,
  record,
}: {
  onToggleFolder: (uuid: string) => void;
  isCollapsed: boolean;
  record: TRecord;
}) => {
  return (
    <div className={"flex-row-container align-center"} style={{ padding: 12 }}>
      <RCButton
        title={isCollapsed ? "Expand Folder" : "Collapse Folder"}
        type="link"
        className="m-rxxs p-txxxs p-h0"
        onClick={() => onToggleFolder(record.uuid)}>
        {isCollapsed ? (
          <AiFillFolder style={{ height: 18, width: 18 }} />
        ) : (
          <AiFillFolderOpen style={{ height: 18, width: 18 }} />
        )}
      </RCButton>
      <strong>
        {record.title}
        {record.numChildren ? ` (${record.numChildren})` : ""}
      </strong>
    </div>
  );
};
