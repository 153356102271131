import get from "lodash/get";
import React, { Component } from "react";
import { Media, Player } from "react-media-player";
import { connect } from "react-redux";
import { MediaFileActionManager } from "../../../action_managers/shows";
import ContextMenu from "../context_menu";
import { Icon } from "../icon";
import InfoTooltip from "../info";
import "./media_player.scss";
import PlayPause from "./play_pause";
import SeekBar from "./seek_bar";

class MediaPlayer extends Component {
  componentDidMount() {
    const { fetchMediaFile, mediaFile, mediaFileURL, mediaFileUUID } = this.props;
    if (!mediaFile && !mediaFileURL && mediaFileUUID) {
      fetchMediaFile(mediaFileUUID);
    }
  }

  getMediaFile = () => {
    const { mediaFileUUID, mediaFile, mediaFiles } = this.props;
    let file;
    if (mediaFile) {
      file = mediaFile;
    } else if (mediaFiles && mediaFiles[mediaFileUUID]) {
      file = mediaFiles[mediaFileUUID];
    }
    return file;
  };

  getURL = () => {
    const { mediaFileURL } = this.props;
    if (mediaFileURL) return mediaFileURL;
    return get(this.getMediaFile(), "convertedURL");
  };

  renderDisabled = () => <Icon iconName="playBlack" disabled name={"play"} />;

  render() {
    const { setCursor, mini, children, disabled, infoBitText, menuItems } = this.props;
    const URL = this.getURL();
    const file = this.getMediaFile();
    return (
      <div>
        {URL && (
          <Media>
            <div className="media-player-wrapper">
              <div className="media-player">
                <Player
                  src={URL}
                  onTimeUpdate={(status) => setCursor && setCursor(status.currentTime)}
                  onPause={(status) => setCursor && setCursor(status.currentTime)}
                />
              </div>
              <div className={`media-player__media-controls ${mini ? "mini" : ""}`}>
                {children && children(this.props)}
                <div className={"media-player__play-pause_wrapper"}>
                  {disabled ? this.renderDisabled() : <PlayPause />}
                </div>
                {infoBitText && (
                  <div className={"media-player__info-bit-wrapper"}>
                    <InfoTooltip helpText={infoBitText} />
                  </div>
                )}
                {!mini && (
                  <div className="media-player__seek-bar">
                    <SeekBar />
                  </div>
                )}
                {menuItems && (
                  <div className="media-player__context-menu">
                    {/* pass in fetched media data to supplement menu items if needed */}
                    <ContextMenu
                      menuItems={typeof menuItems === "function" ? menuItems(file) : menuItems}
                      noCircle={mini}
                    />
                  </div>
                )}
              </div>
            </div>
          </Media>
        )}
      </div>
    );
  }
}

export default connect((state) => ({ mediaFiles: state.mediaFiles }), {
  fetchMediaFile: (mediaFileUUID, authToken) => {
    if (mediaFileUUID) {
      return new MediaFileActionManager({ mediaFileUUID, authToken }).run();
    }
  },
})(MediaPlayer);
