import { Dropdown, RowProps, Spin, Tooltip } from "antd";
import { Key } from "antd/lib/table/interface";
import dayjs from "dayjs";
import numeral from "numeral";
import { useContext, useMemo, useState } from "react";
import { AiFillCaretDown, AiFillWarning } from "react-icons/ai";
import { Link } from "react-router-dom";
import { pluralize } from "redcircle-lib";
import { Button, COLORS, Modal, Table } from "redcircle-ui";
import { showError, showSuccess } from "src/actions/app";
import {
  editCampaign,
  getCampaign,
  overrideCampaignItems,
  removeCampaignItems,
  updateCampaignItems,
  updateCampaignMeta,
} from "src/action_managers/campaigns";
import { AlbumArt } from "src/components/lib/album_art";
import ContextMenu from "src/components/lib/context_menu/context_menu";
import EmptyStateBlock from "src/components/lib/empty_state_block/EmptyStateBlock";
import OverridePopover from "src/components/pages/campaigns/override_popover";
import { CampaignItemStateDraft } from "src/constants/campaigns";
import { permissionTypes } from "src/constants/permission_roles";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { useGetVettingInvitations } from "src/hooks/vetting";
import { getAverageCPM, getImpressionsFromBudget } from "src/lib/campaigns";
import { getCampaignItemField } from "src/lib/campaign_item";
import { localLongDate } from "src/lib/date";
import { createOverrideResetRequest, getCampaignItemOverrides } from "src/lib/overrides";
import { canAdvertiserAccess } from "src/lib/permissions";
import { ICampaignItem } from "src/reducers/campaign_items";
import ModifyCampaignItemModal from "../modify_campaign_item_modal";
import { CampaignItemVettingStatus } from "../vetting_form_modal/vetting_form_modal_helpers";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";
import { getCampaignTimelineWarnings } from "./campaign_scheduler_utils";
import SchedulerAdjustModal from "./scheduler_adjust_modal";
import SchedulerBudgetCell from "./scheduler_cell_budget";
import SchedulerNegotiateRateCell from "./scheduler_cell_cpm";
import SchedulerDownloadsCell from "./scheduler_cell_downloads";
import SchedulerTimelineCell from "./scheduler_cell_timeline";
import SchedulerDatePicker from "./scheduler_datepicker";
import SchedulerFlighting from "./scheduler_flighting";
import SchedulerMatchBudget from "./scheduler_match_budget";

export default function SchedulerPage() {
  const {
    campaign,
    campaignItems,
    excludedCampaignItems,
    isSchedulerLoading,
    budgets,
    setBudgets,
    timeline,
    setTimeline,
    deadlines,
    setDeadlines,
    minEndDatesState,
    maxImpressionsState,
  } = useContext(CampaignSchedulerContext);
  const dispatch = useDispatchTS();
  const [modal, contextHolder] = Modal.useModal();

  const [showOverrideModalUUIDs, setOverrideModalUUIDs] = useState<string[] | undefined>();
  const [showAdjustModalUUIDs, setShowAdjustModalUUIDs] = useState<string[] | undefined>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const draftItems = campaignItems.filter((item) => item.state === CampaignItemStateDraft);
  const publicShows = useSelectorTS((state) => state.publicShows);
  const isEmpty = !draftItems || draftItems.length === 0;

  const { invitationsByCampaignItemUUID, invitationsByCampaignUUID } = useGetVettingInvitations(
    campaign?.uuid
  );
  const excludedItemUUIDs = useMemo(
    () => excludedCampaignItems.map((item) => item.uuid),
    [excludedCampaignItems]
  );
  const draftItemUUIDs = useMemo(() => draftItems.map((item) => item.uuid), [draftItems]);
  const hasVettingOccured =
    campaign &&
    invitationsByCampaignUUID[campaign?.uuid]?.some((invite) =>
      draftItemUUIDs.includes(invite.campaignItemUUID)
    );

  const timelineWarnings = getCampaignTimelineWarnings({
    startsAt: timeline?.[0],
    endsAt: campaign?.pacing ? timeline?.[1] : undefined,
    responseDeadline: deadlines?.responseDeadline ?? undefined,
    assignAudioDeadline: deadlines?.assignAudioDeadline ?? undefined,
  });

  const tableData = useMemo(
    () =>
      draftItems.map((campaignItem) => {
        const show = publicShows[campaignItem.showUUID];
        return {
          key: campaignItem.uuid,
          show,
          ...campaignItem,
          overrides: getCampaignItemOverrides(campaignItem, { showInternal: false }),
        };
      }),
    [campaignItems]
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: Key[]) => setSelectedRowKeys(selectedRowKeys),
  };

  const handleRemoveItems = async (selectedRowKeys: string[]) => {
    modal.confirm({
      title: `Are you sure you want to remove ${selectedRowKeys.length === 1 ? "this podcast" : "these podcasts"}?`,
      content: (
        <>
          {selectedRowKeys.map((uuid) => {
            const campaignItem = campaignItems.find((item) => item.uuid === uuid);
            if (!campaignItem) return null;
            return (
              <div key={uuid} className="line-clamp-1">
                {publicShows?.[campaignItem.showUUID]?.title}
              </div>
            );
          })}
        </>
      ),
      okText: "Remove",
      onOk: async () => {
        const res = await dispatch(
          removeCampaignItems({ campaign, campaignItemUUIDs: selectedRowKeys })
        );
        if (res && res.status === 200) {
          // remove them from budgets
          const newBudgets = { ...budgets };
          selectedRowKeys.forEach((uuid) => delete newBudgets[uuid]);
          setBudgets(newBudgets);
          setSelectedRowKeys([]);
          dispatch(
            showSuccess(
              `${selectedRowKeys.length} ${pluralize(selectedRowKeys.length, "podcast")} has been removed from the campaign.`
            )
          );
        }
      },
    });
  };

  const handleResetOverride = async (campaignItems: ICampaignItem[]) => {
    const request = campaignItems.reduce(
      (acc, campaignItem) => {
        const overrideRequest = createOverrideResetRequest(campaignItem);
        if (overrideRequest && Object.keys(overrideRequest).length > 0) {
          acc[campaignItem.uuid] = overrideRequest;
        }
        return acc;
      },
      {} as Record<string, any>
    );

    if (request && Object.keys(request).length > 0 && campaign) {
      const res = await dispatch(overrideCampaignItems({ campaignUUID: campaign.uuid, request }));
      if (res.status === 200) {
        dispatch(showSuccess("Overrides have been reset."));
      } else if ((res.json as any).validationErrors) {
        await dispatch(showError((res.json as any).validationErrors[0]?.errorMessage));
      }
      return res;
    }
  };

  const columns = [
    {
      key: "vetting",
      width: 25,
      hidden: !hasVettingOccured,
      render: (_: null, campaignItem: ICampaignItem) => {
        const vettingInvitations = invitationsByCampaignItemUUID[campaignItem.uuid] ?? [];
        return <CampaignItemVettingStatus showOnlyIcon vettingInvitations={vettingInvitations} />;
      },
    },
    {
      title: "Podcast",
      key: "show",
      sorter: (a: ICampaignItem, b: ICampaignItem) => {
        const showA = a.show;
        const showB = b.show;
        if (showA?.title && showB?.title) {
          return showA.title.localeCompare(showB.title);
        }
        return 0;
      },
      render: (_: null, campaignItem: ICampaignItem) => {
        const show = campaignItem.show;
        const isExcluded = excludedItemUUIDs.includes(campaignItem.uuid);
        const { showUUID } = campaignItem;
        const isLoading =
          minEndDatesState.loadingShowUUIDs.has(showUUID) ||
          maxImpressionsState.loadingShowUUIDs.has(showUUID);
        return (
          <div className="flex-row-container align-center">
            <div className={"flex-row-container justify-center m-rxs"}>
              <Spin spinning={isLoading}>
                <AlbumArt
                  imageSize={"48x48"}
                  style={{ width: 48, height: 48 }}
                  className={`medium`}
                  src={show?.imageURL}
                  title={show?.title}
                />
              </Spin>
            </div>
            <div className="flex-column-container">
              <div className="flex-row-container align-center">
                <Link
                  className={isExcluded ? "color-warning" : "color-inherit"}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`/browse/${show?.uuid}`}>
                  <strong className="line-clamp-2">{show?.title}</strong>
                </Link>
                {isExcluded && (
                  <Tooltip title="This podcast has excluded itself from this campaign or the campaign brand.">
                    <AiFillWarning className="m-lxxs" color={COLORS.COLOR_WARNING} />
                  </Tooltip>
                )}
              </div>
              <small>
                Weekly Downloads: {numeral(show?.estimatedWeeklyDownloads ?? 0).format("0.0a")}
              </small>
            </div>
          </div>
        );
      },
    },
    {
      title: "CPM",
      key: "cpm",
      width: 100,
      render: (_: null, campaignItem: ICampaignItem) => {
        const show = campaignItem.show;
        if (!campaignItem || !show) return null;
        return <SchedulerNegotiateRateCell campaignItem={campaignItem} show={show} />;
      },
      filters: [
        { text: "Negotiated", value: "negotiated" },
        { text: "Original", value: "original" },
      ],
      onFilter: (value: boolean | Key, record: ICampaignItem) => {
        const isNegotiated = record.offerRates?.enabled;
        if (value === "negotiated") return isNegotiated;
        if (value === "original") return !isNegotiated;
        return false;
      },
      sorter: (a: ICampaignItem, b: ICampaignItem) => {
        const showA = a.show;
        const showB = b.show;
        const cpmA = getAverageCPM({ show: showA, campaign, campaignItem: a, when: "final" });
        const cpmB = getAverageCPM({ show: showB, campaign, campaignItem: b, when: "final" });
        return cpmA - cpmB;
      },
    },
    {
      title: "Downloads",
      key: "downloads",
      width: 100,
      render: (_: null, campaignItem: ICampaignItem) => {
        const show = campaignItem.show;
        if (!campaignItem || !show) return null;
        return <SchedulerDownloadsCell campaignItem={campaignItem} show={show} />;
      },
      sorter: (a: ICampaignItem, b: ICampaignItem) => {
        const impressionsA = getImpressionsFromBudget({
          cpm: getAverageCPM({ show: a.show, campaign, campaignItem: a, when: "final" }),
          budget: budgets?.[a.uuid] ?? 0,
        });
        const impressionsB = getImpressionsFromBudget({
          cpm: getAverageCPM({ show: b.show, campaign, campaignItem: b, when: "final" }),
          budget: budgets?.[b.uuid] ?? 0,
        });
        return impressionsA - impressionsB;
      },
    },
    {
      title: "Budget",
      key: "budget",
      width: 100,
      render: (_: null, campaignItem: ICampaignItem) => {
        const show = campaignItem.show;
        if (!campaignItem || !show) return null;
        return <SchedulerBudgetCell campaignItem={campaignItem} show={show} />;
      },
      sorter: (a: ICampaignItem, b: ICampaignItem) => {
        const budgetA = budgets?.[a.uuid] ?? 0;
        const budgetB = budgets?.[b.uuid] ?? 0;
        return budgetA - budgetB;
      },
    },
    {
      title: campaign?.isV2 ? "Timeline" : "est. Timeline",
      key: "timeline",
      hidden: draftItems.every((item) =>
        getCampaignItemField("pacing", { campaign, campaignItem: item })
      ),
      width: 180,
      render: (_: null, campaignItem: ICampaignItem & { overrides: any }) => {
        const show = campaignItem.show;
        if (!campaignItem || !show) return null;
        return <SchedulerTimelineCell campaignItem={campaignItem} />;
      },
    },
    {
      title: "Overrides",
      key: "overrides",
      width: 50,
      hidden: !tableData.some((item) => item.overrides),
      render: (_: null, campaignItem: (typeof tableData)[0]) => {
        if (campaignItem.overrides) return <OverridePopover overrides={campaignItem.overrides} />;
        return null;
      },
      sorter: (a: (typeof tableData)[0], b: (typeof tableData)[0]) => {
        const overridesA = a.overrides;
        const overridesB = b.overrides;
        if (!overridesA && !overridesB) return 0;
        if (!overridesA) return -1;
        if (!overridesB) return 1;
        return 0;
      },
    },
    {
      title: "Options",
      key: "options",
      width: 50,
      render: (_: null, campaignItem: ICampaignItem & { overrides: any }) => {
        const show = campaignItem.show;
        if (!campaignItem || !campaign || !show) return null;
        const isExcluded = excludedItemUUIDs.includes(campaignItem.uuid);
        return (
          <div className="flex-row-container justify-center">
            <ContextMenu
              menuItems={{
                ...(campaignItem.isV2 && !isExcluded
                  ? {
                      "Override Item": () => setOverrideModalUUIDs([campaignItem.uuid]),
                    }
                  : {}),
                ...(campaignItem.overrides &&
                Object.keys(campaignItem.overrides).length > 0 &&
                !isExcluded
                  ? { "Remove Overrides": () => handleResetOverride([campaignItem]) }
                  : {}),
                "Remove Item": () => {
                  handleRemoveItems([campaignItem.uuid]);
                },
              }}
            />
          </div>
        );
      },
    },
  ].filter((column) => !column.hidden);

  return (
    <div className="flex-column-container height-100">
      <span className="redcircle-form-label flex-0">
        {campaign?.pacing ? "Evenly paced from" : "When will this promotion start?"}
      </span>
      <div className="flex-row-container justify-space-between m-bxs flex-0 flex-wrap">
        <div className="flex-row-container align-center">
          <SchedulerDatePicker
            isRange={campaign?.pacing}
            value={timeline}
            onChange={setTimeline}
            deadlineValue={deadlines}
            onDeadlineChange={setDeadlines}
            isV2={campaign?.isV2}
          />
          {timelineWarnings.length > 0 && (
            <Tooltip
              title={
                <div>
                  {timelineWarnings.map((w, i) => (
                    <p
                      key={w.message}
                      className={i === timelineWarnings.length - 1 ? "m-b0" : undefined}>
                      {w.message}
                    </p>
                  ))}
                </div>
              }>
              <AiFillWarning className="m-lxxs" color={COLORS.COLOR_WARNING} />
            </Tooltip>
          )}
        </div>
        <div className="flex-row-container align-end m-txxs">
          <SchedulerFlighting />
          <SchedulerMatchBudget className="m-lxs" />
        </div>
      </div>

      {rowSelection.selectedRowKeys.length > 0 && (
        <Table.SelectedBar className="m-bxxs">
          <strong>{rowSelection.selectedRowKeys.length} selected</strong>
          <Dropdown
            menu={{
              items: [
                {
                  key: "override",
                  label: "Override Items",
                  hidden: selectedRowKeys.some((key) => {
                    const item = draftItems.find((i) => i.uuid === key);
                    return item && !item.isV2;
                  }),
                  onClick: () => setOverrideModalUUIDs(selectedRowKeys as string[]),
                },
                {
                  key: "removeModifications",
                  label: "Remove Modifications",
                  hidden: !selectedRowKeys.some((key) => {
                    const item = draftItems.find((i) => i.uuid === key);
                    return item && getCampaignItemOverrides(item, { showInternal: false });
                  }),
                  onClick: () => {
                    if (selectedRowKeys.length > 0) {
                      const campaignItems = selectedRowKeys
                        .map((uuid) => draftItems.find((item) => item.uuid === uuid))
                        .filter(Boolean) as ICampaignItem[];
                      handleResetOverride(campaignItems);
                    }
                  },
                },
                {
                  key: "adjust",
                  label: "Adjust Items",
                  onClick: () => setShowAdjustModalUUIDs(selectedRowKeys as string[]),
                },
                {
                  key: "remove",
                  label: "Remove Items",
                  onClick: () => handleRemoveItems(selectedRowKeys as string[]),
                },
              ].filter((i) => !i.hidden),
            }}>
            <Button type="primary" size="small" className="m-la">
              Actions <AiFillCaretDown />
            </Button>
          </Dropdown>
        </Table.SelectedBar>
      )}

      {!isEmpty && (
        <div className="flex-1" style={{ overflowY: "auto" }}>
          <Table
            dataSource={tableData}
            columns={columns as any}
            pagination={false}
            rowSelection={rowSelection}
          />
          {campaign && (
            <EmptyStateBlock className="m-txs">
              <Link to={`/campaigns/${campaign.uuid}/select`}>+ Add More Podcasts</Link>
            </EmptyStateBlock>
          )}
        </div>
      )}

      {isEmpty && !isSchedulerLoading && (
        <EmptyStateBlock>
          You have no shows added to this campaign.{" "}
          {campaign && <Link to={`/campaigns/${campaign.uuid}/select`}>Add some now.</Link>}
        </EmptyStateBlock>
      )}

      {contextHolder}

      <ModifyCampaignItemModal
        type="override"
        open={!!showOverrideModalUUIDs}
        onClose={() => setOverrideModalUUIDs(undefined)}
        campaign={campaign}
        campaignItemUUIDs={showOverrideModalUUIDs}
      />

      <SchedulerAdjustModal
        open={!!showAdjustModalUUIDs}
        onClose={() => setShowAdjustModalUUIDs(undefined)}
        allCampaignItems={draftItems}
        campaignItemUUIDs={showAdjustModalUUIDs}
      />
    </div>
  );
}

export const SchedulerPageFooter = ({ onSubmitSuccess }: { onSubmitSuccess?: () => void }) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const dispatch = useDispatchTS();
  const {
    campaign,
    campaignItems,
    excludedCampaignItems,
    budgets,
    timeline,
    deadlines,
    minEndDatesState,
    maxImpressionsState,
  } = useContext(CampaignSchedulerContext);
  const draftItems = campaignItems.filter((item) => item.state === CampaignItemStateDraft);
  const publicShows = useSelectorTS((state) => state.publicShows);
  const canAccess = canAdvertiserAccess(permissionTypes.sendCampaign, campaign);

  const startDate = timeline?.[0]?.unix();
  const endDate = timeline?.[1]?.unix();

  const nonPacingLatestEndDate =
    campaign && !campaign.pacing && Object.values(minEndDatesState.minEndDates)?.length > 0
      ? Object.values(minEndDatesState.minEndDates)?.reduce((accu: number, curr: any) => {
          if (!curr.streamulatorErrored) {
            accu = accu >= curr.estimatedEndTime ? accu : curr.estimatedEndTime;
          }
          return accu;
        }, startDate)
      : endDate;
  const aggregateImpressions =
    draftItems?.reduce((acc, campaignItem) => {
      const show = publicShows[campaignItem.showUUID];
      const cpm = getAverageCPM({ show, campaign, campaignItem });
      const budget = budgets?.[campaignItem.uuid] ?? 0;
      const impressions = getImpressionsFromBudget({ cpm, budget });
      acc += Math.round(impressions);
      return acc;
    }, 0) ?? 0;
  const aggregateBudget =
    (Object.values(budgets ?? {}).reduce((accu, curr) => accu + curr, 0) ?? 0) / 100;

  const disabledMessages = [];
  if (!canAccess) disabledMessages.push("You do not have permission to edit this campaign.");
  if (!draftItems || draftItems.length === 0)
    disabledMessages.push("Please add shows to the campaign.");
  const streamulatorIsLoading = maxImpressionsState.isLoading || minEndDatesState.isLoading;
  if (streamulatorIsLoading) disabledMessages.push("Calculating...");
  if (excludedCampaignItems.length > 0) {
    disabledMessages.push(
      `Your campaign has podcasts that have excluded this brand. Please remove them to continue: ${excludedCampaignItems
        .map((item) => publicShows[item.showUUID].title)
        .join(", ")}`
    );
  }

  const validateDates = () => {
    if (!startDate) {
      disabledMessages.push("Please select a start date.");
    }
    if (startDate < dayjs().unix()) {
      disabledMessages.push("Start date cannot be in the past.");
    }
    if (campaign?.pacing && endDate && endDate < startDate) {
      disabledMessages.push("End date cannot be before start date.");
    }
    if (deadlines.assignAudioDeadline) {
      if (deadlines.assignAudioDeadline.unix() > startDate) {
        disabledMessages.push("Audio Due date cannot be after start date.");
      }
      if (deadlines.assignAudioDeadline.unix() < dayjs().unix()) {
        disabledMessages.push("Audio Due date cannot be in the past.");
      }
    }
    if (deadlines.responseDeadline) {
      if (deadlines.responseDeadline.unix() > startDate) {
        disabledMessages.push("Response Due date cannot be after start date.");
      }
      if (deadlines.responseDeadline.unix() < dayjs().unix()) {
        disabledMessages.push("Response Due date cannot be in the past.");
      }
    }
    if (deadlines.responseDeadline && deadlines.assignAudioDeadline) {
      if (deadlines.responseDeadline.unix() > deadlines.assignAudioDeadline.unix()) {
        disabledMessages.push("Response Due date cannot be after Assign Audio Deadline.");
      }
    }
  };

  validateDates();
  const isDisabled = disabledMessages.length > 0;

  // Note: we're doing api call here to avoid messy code in the parent component
  const handleSubmit = async () => {
    if (!campaign || !budgets || Object.keys(budgets).length === 0) return;

    setIsSubmitLoading(true);

    const campaignItemUpdates: Record<string, Partial<ICampaignItem>> = {};
    Object.keys(budgets).forEach((uuid) => {
      campaignItemUpdates[uuid] = {
        totalBudget: Math.round(budgets[uuid]),
        startAt: startDate,
        pacingEstimatedEndAt: campaign?.pacing ? endDate : undefined,
      };
    });

    const res1 = await dispatch(updateCampaignItems({ campaign, items: campaignItemUpdates }));

    const res2 = campaign.isV2
      ? await dispatch(
          editCampaign({
            uuid: campaign.uuid,
            startsAt: startDate,
            ...(campaign.pacing ? { endsAt: endDate } : {}),
            assignAudioDeadline: deadlines.assignAudioDeadline?.unix(),
            responseDeadline: deadlines.responseDeadline?.unix(),
            isV2: campaign.isV2,
          })
        )
      : await dispatch(
          updateCampaignMeta(campaign.uuid, {
            startsAt: startDate,
            estimatedEndsAt: nonPacingLatestEndDate,
          })
        );

    const responses = [res1, res2];
    responses.forEach((res) => {
      if (!res || res.status !== 200) {
        const message = (res.json as any)?.validationErrors?.[0].errorMessage;
        if (message) {
          dispatch(showError(message));
        } else {
          dispatch(showError("Failed to update shows. Please try again later."));
        }
      }
    });

    setIsSubmitLoading(false);
    if (onSubmitSuccess && responses.every((res) => res.status === 200)) {
      onSubmitSuccess();
    }

    await dispatch(getCampaign(campaign.uuid));
  };

  return (
    <div className="flex-column-container width-100">
      <span className="m-la">
        Estimated Downloads: {numeral(aggregateImpressions).format("0,0")}
      </span>
      <span className="m-la m-bxxxs text-end">
        Date Range: {`${localLongDate(startDate)} - ${localLongDate(nonPacingLatestEndDate)}`}
      </span>
      <h5 className="m-la">Total Cost: {numeral(aggregateBudget).format("$0,0.00")}</h5>
      <div className="flex-row-container justify-space-between m-txxs">
        <Modal.CloseButton />
        <Tooltip
          title={
            disabledMessages.length > 0 && (
              <div>
                {disabledMessages.map((m) => (
                  <p key={m} className="m-bxxxs">
                    {m}
                  </p>
                ))}
              </div>
            )
          }>
          <span>
            <Modal.SubmitButton
              disabled={isDisabled}
              onClick={handleSubmit}
              isLoading={isSubmitLoading}>
              Save & Continue
            </Modal.SubmitButton>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
