import { adminLoginAs } from "src/actions/admin_login_as";
import { ReduxDispatch } from "src/hooks/redux-ts";
import { userHasRole } from "src/lib/user";
import { isUUID } from "src/lib/uuid";
import { IRootState } from "src/reducers/types";
import { updateCurrentRole } from "../actions/account";
import getDefaultRole from "../lib/user_roles";
import UserStore, { saveUserRole } from "../lib/user_store";
import ActionManager, { GetStateFunc, HTTPResponses } from "./base";
import { getMyPermissions } from "./permissions";
import { postLoginDataFetch } from "./sign_in";
import { FetchUserManager } from "./account";
import { disableWaitlist } from "src/actions/app";
import { store } from "src";
import { User } from "redcircle-types";

/**
 * Abstracts all auto sign in logic of the app on app load.
 * - Checks if user object is available in cookies
 * - Saves User object in redux state
 * - Loads user permissions before app load
 * - start flow of fetching user specific data (i.e. permission presets, stats options, shows or campaigns,  etc)
 * - Minor URL check (this flow will be skipped for Admin Login)
 */
export class AutoSignInActionManager extends ActionManager<User> implements ActionManager<User> {
  /**
   * Helper func containing logic used to disable/enable auto login flow.
   *
   * Good place to disable auto sign flow for specific routes, check query params, etc.
   */
  enableAutoSignIn = () => {
    /**
     * List of URL routes to block auto sign flow
     */
    const pathBlockList = ["/sign-up/openrap"];

    const pathname = window?.location?.pathname;

    if (pathBlockList.includes(pathname)) {
      return false;
    }

    return true;
  };

  preCall(dispatch: ReduxDispatch): void {
    // No Action
  }

  /**
   * Check if we should skip the login data flow on certain routes,
   */
  isAdminURL() {
    if (!window?.location?.href) return false;

    /**
     * Currently skipping on admin route as they cause race conditions with fetch
     * calls with different user auth tokens since user object is being switched.
     *
     * Admin route handles its owns user login data flow on both page load and form sign in.
     */
    const adminURLs = ["/admin"];

    const url = new URL(window.location.href);

    const isURLAnAdminURL = adminURLs.some((pathToTest) =>
      url.pathname.toLowerCase().startsWith(pathToTest)
    );

    return isURLAnAdminURL;
  }

  /**
   * Auto sign in flow for an admin user,
   * @param {ReduxDispatch} dispatch
   * @returns {Promise<Response>}
   */
  adminLoginFlow(dispatch: ReduxDispatch): Promise<Response> {
    const url = new URL(window.location.href);
    const email = url.searchParams?.get?.("email");
    const adminToken = UserStore.getAdminToken();
    const loginAsEmailExists = typeof email === "string" && email.length > 0;
    const userIsAdmin = typeof adminToken === "string" && adminToken.length > 0;

    if (userIsAdmin && loginAsEmailExists) {
      // going to reuse normal admin login reducer
      dispatch(adminLoginAs({ adminToken, loginAsEmail: email })).then((resp) => {
        const json = resp.json;
        const blob = new Blob([JSON.stringify(json, null, 2)], { type: "application/json" });

        // Basically bubbling the info back up as a response object
        // to be consumed by base.ts run method, used 204 status to skip post success call;
        return new Response(blob, { status: 204 });
      });
    }

    // If the user is in cookies and either the user is not an admin or no login as email is provided
    // do nothing. wait for user to use the admin login as flow, will skip all  post call, since status is not
    // 200
    return Promise.resolve(new Response(null, { status: 204 }));
  }

  /**
   * Auto sign in flow for a normal user
   * @param {ReduxDispatch} dispatch
   * @param  { user: User} param1
   * @returns {Promise<Response>}
   */
  userLoginFlow(dispatch: ReduxDispatch, { user }: { user: User }): Promise<Response> {
    if (isUUID(user.uuid) && user.authToken) {
      let response: HTTPResponses<User>;
      // re-fetch user in case updates are made to User Entity
      return dispatch(
        new FetchUserManager({ userUUID: user.uuid, authToken: user.authToken }).run()
      )
        .then((resp) => {
          if (resp.status === 200) {
            response = resp;
            const latestUser = resp.json;
            const currentRole = UserStore.getUserRole();

            // Handle saving user role
            if (currentRole && userHasRole(latestUser, currentRole)) {
              dispatch(updateCurrentRole(currentRole));
            } else {
              const defaultRole = getDefaultRole(latestUser);
              dispatch(updateCurrentRole(defaultRole));
              saveUserRole(defaultRole);
            }
          }

          // Grabbing permissions first before loading app,
          // this was previous logic, and it seems good to have
          return dispatch(getMyPermissions());
        })
        .then(() => {
          // Pass the response object back to be consumed by the run() method post execute
          const blob = new Blob([JSON.stringify(response.json ?? null, null, 2)], {
            type: "application/json",
          });

          return new Response(blob, { status: response.status });
        });
    } else {
      // returning a Promise with nothing since no user found in cookies
      return Promise.resolve(new Response(null, { status: 204 }));
    }
  }

  execute(dispatch: ReduxDispatch, getState: () => IRootState): Promise<Response> {
    // Grab user if available in cookies
    const user: User = UserStore.get();
    UserStore.saveInviteUUID(); //Save any invite link query params
    UserStore.saveUTMParams();
    const isUserInCookies = !!user;

    // If user exists in cookies, auto login user form cookies
    if (isUserInCookies) {
      if (this.enableAutoSignIn()) {
        // Login flow for Admin URLS is different for saved users

        // If there is a user in cookies this is an admin url, auto login may be different
        if (this.isAdminURL()) {
          return this.adminLoginFlow(dispatch);
        }

        /**
         * Normal flow auto login flow
         */

        return this.userLoginFlow(dispatch, { user });
      } else {
        // On disabled auto sign logic. Will go ahead and remove user entity from cookies
        UserStore.remove();
      }
    }

    // returning a Promise with nothing since no user found in cookies
    return Promise.resolve(new Response(null, { status: 204 }));
  }

  responseOK(dispatch: ReduxDispatch, json: User): void {
    // No Action
  }

  postCall(
    dispatch: ReduxDispatch,
    response: HTTPResponses<User, object>,
    getState: GetStateFunc
  ): Promise<HTTPResponses<User, object>> {
    // If the response did not succeed no point in continuing, this means no user
    // was found in cookies or something else happened
    if (response.status !== 200) return Promise.resolve(response);

    const user = response.json;

    // On Auto sign in check for waitlist disable flag in the URL
    updateWaitlistFlag();

    /**
     * Post login data fetch flow, can skip user perms fetch call because it is already grabbed before app is loaded.
     */
    return postLoginDataFetch(user, { skipUserPerms: true }).then(() => response);
  }
}
export const autoSignInUser = () => {
  return new AutoSignInActionManager({}).run();
};

/**
 * Checks URL for waitlist flag and updates app redux state to restrict user
 */
const updateWaitlistFlag = () => {
  const url = new URL(window.location.href);
  const val = url.searchParams.get("wait");

  if (val === "0") {
    store.dispatch(disableWaitlist());
  }
};
