import { AnyAction } from "redux";
import {
  SIGN_IN_REQUEST_ERROR,
  SIGN_IN_REQUEST_FORBIDDEN,
  SIGN_IN_REQUEST_START,
  SIGN_IN_REQUEST_SUCCESS,
} from "../../actions/sign_in";
import { SignInState } from "./types";

const initialState: SignInState = {
  isLoading: false,
};

function signIn(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SIGN_IN_REQUEST_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case SIGN_IN_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case SIGN_IN_REQUEST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case SIGN_IN_REQUEST_FORBIDDEN:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

export default signIn;
