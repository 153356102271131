import { Alert } from "antd";
import RCButton from "../button/button";
import styles from "./warning_alert.module.scss";

export default function WarningVerifyPhone({
  onClick,
  onClose,
}: {
  onClick: () => void;
  onClose: () => void;
}) {
  const message = (
    <span>
      Your RedCircle account doesn't have a phone number. To receive notifications and reminders
      about ad campaign timelines,{" "}
      <RCButton type="link" onClick={onClick} className="p-a0">
        <strong>add your phone number to your profile</strong>
      </RCButton>
      .
    </span>
  );
  return (
    <Alert
      className={styles.alert}
      message={message}
      type="warning"
      onClose={onClose}
      closable
      banner
    />
  );
}
