import DefaultActionManager from "../action_managers/default_action_manager";

export const BALANCE_FETCH_START = "BALANCE_FETCH_START";
export const BALANCE_FETCH_SUCCESS = "BALANCE_FETCH_SUCCESS";
export const BALANCE_FETCH_ERROR = "BALANCE_FETCH_ERROR";
export const BALANCE_STRIPE_FETCH = "BALANCE_STRIPE_FETCH";

export function balanceFetchStart() {
  return { type: BALANCE_FETCH_START };
}

export function balanceFetchSuccess(balance) {
  return { type: BALANCE_FETCH_SUCCESS, balance: balance.amount };
}

export function balanceFetchError() {
  return { type: BALANCE_FETCH_ERROR };
}

export const getStripeBalance = () =>
  new DefaultActionManager({
    actionName: BALANCE_STRIPE_FETCH,
    route: "transactions/stripe/balance",
    auth: true,
  }).run();
