export const transformStringToWebhookURL = (url: string) => {
  if (!url) return "";
  let newURL = url.trim(); // trim whitespace and newlines
  newURL = newURL.replace(/\n{2,}/g, "\n"); // replace 2 or more newlines with a single newline
  newURL = newURL.replace(/\n|\r/g, "|||"); // replace newline with |||
  return newURL;
};

export const transformWebhookURLToString = (url: string) => {
  if (!url) return "";
  return url.replace(/\|\|\|/g, "\n"); // replace ||| with newline
};

export const getPixelURLValidationMessage = (webhookURL?: string) => {
  if (!webhookURL) return "";

  if (webhookURL != "" && (webhookURL.includes("%7d") || webhookURL.includes("%7b"))) {
    return "Your URL contains encoded curly braces -- RedCircle pixel URLs should look like {{podcastID}} not %7b%7bpodcastID%7d%7d.  Double check your pixel URL.";
  }

  if (webhookURL != "" && !webhookURL.includes("{{")) {
    return "RedCircle pixel URLs typically contain macros like {{ipAddress}} or {{userAgent}}. Double check your pixel URL";
  }

  return "";
};
