import { Checkbox, Form, Tooltip } from "antd";
import { useEffect } from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { FrequencyCapConfig } from "redcircle-types";
import { FrequencyCapOptions } from "./frequency_cap";

/**
 * This form is used to set the flighting options for a campaign.
 * This form is intended to be used in the Campaign Settings and the Scheduler.
 */
export default function FlightingOptionsForm() {
  const form = Form.useFormInstance();
  const pacing = Form.useWatch("pacing", form);
  const frequencyConfigs = Form.useWatch("frequencyConfigs", form);

  const handleFrequencyCapChange = (newFrequencyConfigs: FrequencyCapConfig[]) => {
    form.setFieldValue("frequencyConfigs", newFrequencyConfigs);
  };

  /**
   * Ensure hard dates flag is off when pacing is off
   */
  useEffect(() => {
    const hardEndDate = form.getFieldValue("hardEndDate");
    if (pacing === false && hardEndDate) {
      form.setFieldValue("hardEndDate", false);
    }
  }, [pacing]);

  return (
    <div className="flex-column-container">
      <Form.Item name="frequencyConfigs" className="m-a0 m-bm">
        <FrequencyCapOptions
          frequencyConfigs={frequencyConfigs}
          onFrequencyCapChange={handleFrequencyCapChange}
        />
      </Form.Item>

      <div className="m-bm">
        <span className="redcircle-form-label">Flighting Options</span>
        <div className="flex-row-container align-center">
          <Form.Item name="pacing" className="m-a0" valuePropName="checked">
            <Checkbox>Enable Pacing</Checkbox>
          </Form.Item>
          <Tooltip title="With pacing enabled, ads will be inserted evenly across your campaign timeline">
            <AiFillQuestionCircle />
          </Tooltip>
        </div>

        {pacing && (
          <Form.Item name="hardEndDate" className="m-a0" valuePropName="checked">
            <Checkbox>Set campaign end dates as hard deadlines</Checkbox>
          </Form.Item>
        )}
        <Form.Item name="recentEpisodesOnly" className="m-a0" valuePropName="checked">
          <Checkbox>Recent Episodes Only</Checkbox>
        </Form.Item>
      </div>

      <label className="redcircle-form-label" htmlFor="targetingOptions">
        Where in an episode would you like this audio placed?*
      </label>
      <Form.Item
        name="targetingOptionsArray"
        className="m-a0"
        rules={[{ required: true, message: "Please select a targeting position" }]}>
        <Checkbox.Group>
          <Checkbox value={"preRoll"}>Pre-Roll</Checkbox>
          <Checkbox value={"midRoll"}>Mid-Roll</Checkbox>
          <Checkbox value={"postRoll"}>Post-Roll</Checkbox>
        </Checkbox.Group>
      </Form.Item>
    </div>
  );
}
