import { defaultActionManagerRunner } from "./default_action_manager";

export const GET_PROGRAMMATIC_EARNINGS = "GET_PROGRAMMATIC_EARNINGS";

export const getProgrammaticEarningsForShow = (showUUID: string) =>
  defaultActionManagerRunner({
    actionName: GET_PROGRAMMATIC_EARNINGS,
    method: "get",
    auth: true,
    route: `shows/${showUUID}/programmatic-earnings`,
  });
