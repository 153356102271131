import { useState } from "react";
import { CampaignProductExchangeType } from "redcircle-types";
import { Button, Modal, MultiModal } from "redcircle-ui";
import { showWarning } from "src/actions/app";
import { acceptCampaignOffer } from "src/actions/campaigns";
import { updateShow } from "src/action_managers/shows";
import { useDispatchTS } from "src/hooks/redux-ts";
import { ICampaign } from "redcircle-types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "redcircle-types";
import AcceptCampaignPage from "./accept_campaign_page";
import ConfirmMailingPage from "./confirm_mailing_page";
import NextStepsPage from "./next_steps_page";

interface IProps {
  open: boolean;
  onClose: () => void;
  show: IShow;
  campaign: ICampaign;
  campaignItem: ICampaignItem;
}

export default function AcceptCampaignOfferModal({
  open,
  onClose,
  show,
  campaign,
  campaignItem,
}: IProps) {
  const dispatch = useDispatchTS();
  const [currentPage, setCurrentPage] = useState(0);

  const renderMailingPage =
    campaign &&
    campaign.productExchangeType === CampaignProductExchangeType.ProductExchangeTypeMailing;

  const pages = [
    ...(renderMailingPage
      ? [
          {
            id: "confirm_mailing_address",
            title: "Confirm Mailing Address",
            subtitle:
              "Please confirm your mailing address below. We’ll share this information with the brand so they can send samples of their product.",
            bodyClassName: "p-a0",
            body: (
              <ConfirmMailingPage
                show={show}
                onClose={onClose}
                onSubmit={async (values) => {
                  const advertisementSettings = show.advertisementSettings || {};
                  const res = await dispatch(
                    updateShow({
                      uuid: show.uuid,
                      advertisementSettings: {
                        ...advertisementSettings,
                        hosts: values.advertisementSettings.hosts,
                      },
                    })
                  );
                  if (res.status === 200) {
                    setCurrentPage(currentPage + 1);
                  } else {
                    dispatch(showWarning("Failed to update show"));
                  }
                }}
              />
            ),
          },
        ]
      : []),
    {
      id: "accept_campaign",
      title: "Accept Campaign",
      body: <AcceptCampaignPage campaignItem={campaignItem} />,
      footer: (
        <>
          <Button
            type="link"
            className="p-a0"
            onClick={() => {
              if (currentPage > 0) {
                setCurrentPage(currentPage - 1);
              } else {
                onClose();
              }
            }}>
            {renderMailingPage ? "Back" : "Close"}
          </Button>
          <Modal.SubmitButton
            onClick={async () => {
              const res = await dispatch(acceptCampaignOffer(campaignItem.uuid));
              if (res.status === 200) {
                setCurrentPage(currentPage + 1);
              } else {
                dispatch(showWarning("Failed to accept campaign"));
              }
            }}>
            Accept
          </Modal.SubmitButton>
        </>
      ),
    },
    {
      id: "next_steps",
      title: "Next Steps",
      body: <NextStepsPage campaign={campaign} campaignItem={campaignItem} />,
      footer: (
        <Modal.SubmitButton
          className="m-la"
          onClick={() => {
            onClose();
          }}>
          Ok
        </Modal.SubmitButton>
      ),
    },
  ];

  const handleClose = () => {
    onClose();
    setCurrentPage(0);
  };

  return (
    <MultiModal
      size="sm"
      open={open}
      onClose={handleClose}
      currentPage={currentPage}
      pages={pages}
      maskClosable={false}
    />
  );
}
