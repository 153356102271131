import { AnyAction } from "redux";
import {
  STRIPE_CONNECT_FAIL,
  STRIPE_CONNECT_START,
  STRIPE_CONNECT_SUCCESS,
} from "../../actions/stripe_connect";

const initialState = {
  isLoading: false,
  isSuccess: null,
};

export default function stripeConnect(state = initialState, action: AnyAction) {
  switch (action.type) {
    case STRIPE_CONNECT_START:
      return Object.assign({}, state, {
        isLoading: true,
        isSuccess: false,
      });
    case STRIPE_CONNECT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
      });
    case STRIPE_CONNECT_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}
