import React from "react";
import Loading from "./loading";
import styles from "./loading.module.scss";

export default function LoadingOverlay() {
  return (
    <div className={styles.overlay}>
      <Loading />
    </div>
  );
}
