import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SignInActionManager from "src/action_managers/sign_in";
import { withPageTitle } from "../../lib/WithPageTitle";
import SignInPage from "./sign_in_page";

function mapStateToProps(state) {
  return {
    isLoading: state.signIn.isLoading,
    hasError: state.signIn.hasError,
    errorMessage: state.signIn.errorMessage,
    user: state.user.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitSignIn: (formData, role) => {
      var manager = new SignInActionManager({
        ...formData,
        forRole: role,
      });
      return dispatch(manager.run());
    },
  };
}

const SignInPageWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInPage));

export default withPageTitle(SignInPageWrapper, "Sign In");
