import {
  stripeConnectFail,
  stripeConnectStart,
  stripeConnectSuccess,
} from "../actions/stripe_connect";
import userAPI from "../api/user";
import ActionManager from "./base";
import DefaultActionManager from "./default_action_manager";

export class StripeConnectManager extends ActionManager {
  defaultErrorMessage() {
    return "Uh Oh, something went wrong connecting with Stripe. Please try again later.";
  }

  defaultPreCallActionCreator() {
    return stripeConnectStart;
  }

  defaultSuccessActionCreator() {
    return stripeConnectSuccess;
  }

  default400ActionCreator() {
    return stripeConnectFail;
  }
  defaultErrorActionCreator() {
    return stripeConnectFail;
  }

  responseOK(dispatch, json) {
    super.responseOK(dispatch, json);
    this.showSuccess(dispatch, "Your Stripe account was successfully linked");
  }

  execute() {
    return userAPI.connectStripe(this.args.code, this.args.securityToken, this.args.authToken);
  }
}

export function getStripeLoginLink(user) {
  return new DefaultActionManager({
    auth: true,
    route: "users/" + user.uuid + "/stripe-login-link",
    actionName: "STRIPE_GET_LINK",
    method: "GET",
  }).run();
}
