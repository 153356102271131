export const EPISODE_DELETE_CLEAR = "EPISODE_DELETE_CLEAR";
export const EPISODE_DELETE_START = "EPISODE_DELETE_START";
export const EPISODE_DELETE = "EPISODE_DELETE";
export const EPISODE_DELETE_SUCCESS = "EPISODE_DELETE_SUCCESS";
export const EPISODE_DELETE_ERROR = "EPISODE_DELETE_ERROR";

export function episodeDeleteClear() {
  return {
    type: EPISODE_DELETE_CLEAR,
  };
}

export function episodeDeleteStart(episode) {
  return {
    type: EPISODE_DELETE_START,
    showUUID: episode.showUUID,
    episodeUUID: episode.uuid,
  };
}

export function episodeDeleteSuccess(episode) {
  return {
    type: EPISODE_DELETE_SUCCESS,
    showUUID: episode.showUUID,
    episodeUUID: episode.uuid,
  };
}

export function episodeDeleteError(episode) {
  return {
    type: EPISODE_DELETE_ERROR,
    showUUID: episode.showUUID,
    episodeUUID: episode.uuid,
  };
}
