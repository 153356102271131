import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Image } from "antd";
import React, { ReactNode, useCallback, useMemo } from "react";
import { classNames } from "react-extras";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { updateCurrentRole } from "src/actions/account";
import { SignOutActionManager } from "src/action_managers/account";
import Button from "src/components/lib/button";
import { Dropdown } from "src/components/lib/dropdown";
import ExternalLink from "src/components/lib/external_link/external_link";
import { permissionTypes } from "src/constants/permission_roles";
import UserRoles, { USER_ROLES_TO_FRIENDLY } from "src/constants/roles";
import { useCrossPromoNotifications } from "src/hooks/cross_promo";
import { useSelectorTS } from "src/hooks/redux-ts";
import { useAllShowNotifications } from "src/hooks/shows";
import logo from "src/images/logo-redcircle-dark.svg";
import { hasAtLeastOnePermitted } from "src/lib/permissions";
import { userHasRole } from "src/lib/user";
import { saveUserRole } from "src/lib/user_store";
import styles from "./sidebar.module.scss";
import RCTag from "redcircle-ui/components/Tag/Tag";
import { WAITLIST_MODAL } from "src/components/modals/modal_root";
import { showModal } from "src/actions/modal";
import { COLORS } from "redcircle-ui";
import { isShowRedirected, isShowRemote, isShowRemoved, isShowWaitListed } from "src/lib/show";

interface ISidebarProps {
  hidden?: boolean; // determines whether sidebar should be hidden altogether (for logged out states)
  open?: boolean; // determines whether sidebar should be toggled open (in mobile state)
  toggleSidebar?: () => void; // function to toggle sidebar open/closed
}

export default function Sidebar({ hidden, open, toggleSidebar }: ISidebarProps) {
  const { user, currentRole, tier } = useSelectorTS((state) => state.user);
  const { shows } = useSelectorTS((state) => state.shows);
  const { modalType } = useSelectorTS((state) => state.modal);
  const rapNotifications = useAllShowNotifications();
  const crossPromoNotifications = useCrossPromoNotifications();

  const showList = Object.values(shows);
  const hasActiveRemoteWaitListedShows = showList
    ?.filter((show) => isShowRemote(show) && !isShowRedirected(show) && !isShowRemoved(show))
    .some((show) => isShowWaitListed(show));

  const dispatch = useDispatch();

  const handleRoleChange = (role: string) => {
    dispatch(updateCurrentRole(role));
    saveUserRole(role);
  };

  const handleOpenWaitListModal = useCallback(() => {
    dispatch(showModal(WAITLIST_MODAL));
  }, []);

  const menuItems = user
    ? {
        ...Object.keys(user?.roles ?? {})
          .filter((role) => role != currentRole && user.roles[role as keyof typeof user.roles])
          .reduce((acc: Record<string, () => void>, role) => {
            acc[`Switch to ${USER_ROLES_TO_FRIENDLY[role]}`] = () => handleRoleChange(role);
            return acc;
          }, {}),
        "Sign out": () => dispatch(new SignOutActionManager({ token: user.authToken }).run()),
      }
    : {};

  const rapLabel = `Ad Platform ${rapNotifications.count > 0 ? `(${rapNotifications.count})` : ""}`;
  const promotionsLabel = `Cross Promotions ${
    crossPromoNotifications.count && crossPromoNotifications.count > 0
      ? `(${crossPromoNotifications.count})`
      : ""
  }`;

  const supportLink =
    currentRole === "advertiser"
      ? "https://support.redcircle.com/advertiser-resources"
      : "https://support.redcircle.com";

  return (
    <div className={classNames(styles.main, hidden && styles.hidden, open && styles.active)}>
      <div className={styles.inner}>
        <div className={styles.header}>
          <Button type="link" size="large" className={styles["close-icon"]} onClick={toggleSidebar}>
            <CloseOutlined />
          </Button>
          <Link to="/">
            <Image src={logo} height={25} preview={false} alt={"Sidebar RedCircle Logo"} />
          </Link>
        </div>

        <nav className={styles.nav}>
          <ul>
            <SidebarItem label="Sponsoring" linkTo="/sponsor-show-list" roles={UserRoles.Sponsor} />
            <SidebarItem
              label="Podcasts"
              linkTo="/shows"
              roles={UserRoles.Creator}
              permissions={permissionTypes.viewShow}
              onClick={toggleSidebar}
            />
            <SidebarItem
              label="Campaigns"
              linkTo="/campaigns"
              roles={UserRoles.Advertiser}
              permissions={permissionTypes.viewCampaign}
              onClick={toggleSidebar}
            />
            <SidebarItem
              label="Browse Podcasts"
              linkTo="/browse"
              roles={UserRoles.Advertiser}
              permissions={permissionTypes.browsePodcasts}
              onClick={toggleSidebar}
            />
            <SidebarItem
              label="Reporting"
              linkTo="/reporting"
              roles={UserRoles.Advertiser}
              permissions={permissionTypes.viewCampaignReporting}
              onClick={toggleSidebar}
            />
            <SidebarItem
              label="Analytics"
              linkTo="/stats"
              roles={UserRoles.Creator}
              permissions={permissionTypes.viewStats}
              onClick={toggleSidebar}
            />
            <SidebarItem
              label={promotionsLabel}
              linkTo="/promotions"
              roles={UserRoles.Creator}
              permissions={permissionTypes.viewCrossPromo}
              onClick={toggleSidebar}
            />
            <SidebarItem
              label="Dynamic Insertion"
              linkTo="/dynamic-insertion"
              roles={UserRoles.Creator}
              permissions={permissionTypes.viewDynamicInsertion}
              onClick={toggleSidebar}
            />
            <SidebarItem
              label={rapLabel}
              linkTo="/ad-platform"
              roles={UserRoles.Creator}
              permissions={permissionTypes.viewRap}
              onClick={toggleSidebar}
            />
            <SidebarItem
              label="Money"
              linkTo="/monetization"
              roles={UserRoles.Creator}
              permissions={permissionTypes.viewMoney}
              onClick={toggleSidebar}
            />
          </ul>
          <ul>
            {hasActiveRemoteWaitListedShows && (
              <NonRouteSideBarItem
                active={modalType === WAITLIST_MODAL}
                onClick={handleOpenWaitListModal}>
                <div className="flex-row-container align-center">
                  OpenRAP{" "}
                  <RCTag className="m-lxxs" color={COLORS.PRIMARY_COLOR}>
                    Beta
                  </RCTag>
                </div>
              </NonRouteSideBarItem>
            )}
            <SidebarItem label="Account" linkTo="/account" onClick={toggleSidebar} />
            <SidebarItem label="Support" linkTo={supportLink} external onClick={toggleSidebar} />
          </ul>
        </nav>

        <div className={styles.footer}>
          <hr className={styles.divider} />
          <span className={styles["footer-label"]}>LOGGED IN AS:</span>

          <Dropdown placement="topRight" menuItems={menuItems} overlayClassName={styles.dropdown}>
            <Button type="link" className={styles.dbutton}>
              <div />
              <span className="m-lxs">{user?.firstName}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

const SidebarItem = ({
  label,
  linkTo,
  roles,
  permissions,
  external = false,
  onClick,
}: {
  label?: string;
  linkTo: string;
  roles?: string | string[] /* user may match any role in array */;
  permissions?: string | string[] /* user must match every permission in array */;
  external?: boolean;
  onClick?: () => void;
}) => {
  const { user, currentRole } = useSelectorTS((state) => state.user);
  const permState = useSelectorTS((state) => state.permissions);
  if (!user) return null;

  const hasCorrectRole = () => {
    if (!roles) return true;
    const hasRole = (role: string) =>
      currentRole
        ? currentRole === role || currentRole === UserRoles.Admin
        : userHasRole(user, role as any);

    if (Array.isArray(roles)) return roles.some(hasRole);
    return hasRole(roles);
  };

  const hasCorrectPerms = () => {
    if (!permissions) return true;
    if (Array.isArray(permissions)) {
      return permissions.every((perm) =>
        hasAtLeastOnePermitted(user, permState, perm as keyof typeof permissionTypes)
      );
    }
    return hasAtLeastOnePermitted(user, permState, permissions as keyof typeof permissionTypes);
  };

  if (!hasCorrectRole() || !hasCorrectPerms()) return null;

  if (external) {
    return (
      <li>
        <ExternalLink href={linkTo} className={styles.item}>
          {label}
        </ExternalLink>
      </li>
    );
  }

  return (
    <li>
      <NavLink
        to={linkTo}
        className={styles.item}
        activeClassName={styles.active}
        onClick={() => {
          if (onClick) onClick();
        }}>
        {label}
      </NavLink>
    </li>
  );
};

interface NonRouteSideBarItemProps {
  active: boolean;
  onClick: () => void;
  children: ReactNode;
}

/**
 * Side bar item component that is not specifically tied to a route or a route change
 *
 * Can be used to show side bar items links and connect their behavior to manual flags instead of
 * route changes, great for modals not tied to specific routes or quick behavior
 */
const NonRouteSideBarItem = ({ active, onClick, children }: NonRouteSideBarItemProps) => {
  return (
    <li>
      <div
        className={classNames(styles.item, "pointer", { [styles.active]: active })}
        onClick={onClick}>
        {children}
      </div>
    </li>
  );
};
