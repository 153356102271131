import DefaultActionManager from "../action_managers/default_action_manager";

export const directoryShowsSearchActionName = "DIRECTORY_SHOWS_SEARCH";
export const getDirectoryShowActionName = "GET_DIRECTORY_SHOW";

export function searchDirectoryShows(query) {
  return new DefaultActionManager({
    auth: true,
    route: "directory-shows?query=" + encodeURIComponent(query),
    actionName: directoryShowsSearchActionName,
  }).run();
}

export const getDirectoryShow = (showUUID) =>
  new DefaultActionManager({
    route: `directory-shows/${showUUID}/feed`,
    auth: true,
    actionName: getDirectoryShowActionName,
    actionData: {
      uuid: showUUID,
    },
  }).run();
