import { AnyAction } from "redux";
import {
  EMAIL_VERIFICATION_ERROR,
  EMAIL_VERIFICATION_START,
  EMAIL_VERIFICATION_SUCCESS,
  SEND_VERIFICATION_EMAIL_ERROR,
  SEND_VERIFICATION_EMAIL_START,
  SEND_VERIFICATION_EMAIL_SUCCESS,
} from "../../actions/email_verification";
import { EmailVerificationReduxState, SendVerificationEmailReduxState } from "./types";

const initialState: EmailVerificationReduxState = {
  isLoading: false,
  pushToHome: false,
};

export function emailVerification(state = initialState, action: AnyAction) {
  switch (action.type) {
    case EMAIL_VERIFICATION_START:
      return Object.assign({}, state, {
        isLoading: true,
        pushToHome: false,
      });
    case EMAIL_VERIFICATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        pushToHome: true,
      });
    case EMAIL_VERIFICATION_ERROR:
      return Object.assign({
        isLoading: false,
      });
    default:
      return state;
  }
}

const initialSendState: SendVerificationEmailReduxState = {
  isLoading: false,
};

export function sendVerificationEmail(state = initialSendState, action: AnyAction) {
  switch (action.type) {
    case SEND_VERIFICATION_EMAIL_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case SEND_VERIFICATION_EMAIL_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}
