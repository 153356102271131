export const STRIPE_CONNECT_START = "STRIPE_CONNECT_START";
export const STRIPE_CONNECT_SUCCESS = "STRIPE_CONNECT_SUCCESS";
export const STRIPE_CONNECT_FAIL = "STRIPE_CONNECT_FAIL";

export function stripeConnectStart() {
  return {
    type: STRIPE_CONNECT_START,
  };
}

export function stripeConnectSuccess() {
  return {
    type: STRIPE_CONNECT_SUCCESS,
  };
}

export function stripeConnectFail() {
  return {
    type: STRIPE_CONNECT_FAIL,
  };
}
