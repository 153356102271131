import getFP from "lodash/fp/get";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Component } from "react";
import { If } from "react-extras";
import { connect } from "react-redux";
import Breadcrumbs from "src/components/lib/breadcrumbs/breadcrumbs";
import Page from "src/components/lib/page";
import { ShowsForUserActionManager } from "../../../action_managers/shows";
import { searchSimilarShows } from "../../../action_managers/show_search";
import { getAllAdjacentAudienceBucketSizes, getBiggestShow, getUserShows } from "../../../lib/show";
import Divider from "../../lib/divider";
import Spinner from "../../lib/spinner";
import "./browse_promotion_page.scss";
import Search from "./browse_promotion_search";
import BrowseRow from "./browse_row";
import CategoryList from "./category_list";

class BrowsePromotionPage extends Component {
  state = {
    showSearch: false,
    biggestShowUUID: null,
  };
  componentDidMount() {
    this.props.fetchShowsForUser(this.props.user).then(this.fetchRelatedShows);
  }

  fetchRelatedShows = () => {
    const shows = this.getShowArray();
    this.setState({ biggestShowUUID: getBiggestShow(shows).uuid });
    shows.forEach(({ uuid }) => {
      this.props.searchSimilarShows(uuid);
    });
  };

  getShowArray = () => {
    return Object.values(get(this.props, "shows", {})).filter(getFP("isVisible"));
  };

  renderRows = () => {
    return this.getShowArray().map((show) => (
      <BrowseRow
        key={show.uuid}
        className="browse-promotions-page__browse-row"
        shows={this.props.categoryShows[show.uuid]}
        title={`Podcasts similar to ${show.title}`}
        isLoading={this.props.isLoadingCategoryShows}
      />
    ));
  };

  featuredRow = () => {
    if (isEmpty(this.state.biggestShowUUID)) {
      return null;
    }
    const shows = get(this.props.categoryShows, this.state.biggestShowUUID, []);

    return (
      <BrowseRow
        className="browse-promotions-page__browse-row"
        shows={shows}
        isLoading={this.props.isLoadingCategoryShows}
        count={4}
        displayMetadata={true}
      />
    );
  };

  render() {
    return (
      <Page className="browse-promotions-page" pageTitle="Cross Promotions">
        <Breadcrumbs crumbs={[{ name: "Promotions", path: "/promotions" }]} />
        <Search show={this.state.showSearch} onClose={() => this.setState({ showSearch: false })} />
        <div className="flex-row-container justify-space-between">
          <Page.Title>Browse Partners</Page.Title>
          <span
            className="browse-promotions-page__search-button bold text-primary"
            onClick={() => this.setState({ showSearch: true })}>
            Search
          </span>
        </div>
        <Divider smallMargin />
        <div className="browse-promotions-page__browse-row-highlights">
          <If condition={!this.props.isLoadingShows}>{this.featuredRow()}</If>
        </div>
        <div className="browse-promotions-page__browse-row-wrapper">
          <Spinner isLoading={this.props.isLoadingShows} />
          <If condition={!this.props.isLoadingShows}>{this.renderRows()}</If>
        </div>
        <If condition={!this.props.isLoadingShows}>
          <CategoryList />
        </If>
      </Page>
    );
  }
}

export default connect(
  (state) => {
    const userShows = getUserShows(state.user.user, state.shows.shows);
    const bucketedAudienceSizes = getAllAdjacentAudienceBucketSizes(userShows);
    return {
      shows: state.shows.shows,
      isLoadingShows: state.shows.isLoading,
      isLoadingCategoryShows: state.browsePromotion.categoryShows.loadingCount > 0,
      user: state.user.user,
      categoryShows: state.browsePromotion.categoryShows,
      userAdjacentAudienceSizes: bucketedAudienceSizes,
    };
  },
  {
    searchSimilarShows,
    fetchShowsForUser: (user) => new ShowsForUserActionManager({ user }).run(),
  }
)(BrowsePromotionPage);
