import { Alert } from "antd";
import { useEffect, useState } from "react";
import { classNames } from "react-extras";
import messageTypes from "src/constants/message_types";
import styles from "./message_bar.module.scss";

const messageTypeToAlertType: Record<string, string> = {
  [messageTypes.Success]: "success",
  [messageTypes.Error]: "error",
  [messageTypes.Warning]: "warning",
  [messageTypes.Info]: "info",
};

const MessageBar = ({
  message,
  messageUUID,
  messageType,
  onClose,
}: {
  message?: string | null;
  messageUUID?: string | null;
  messageType?: string | null;
  onClose: () => void;
}) => {
  // prevent the message bar from showing on initial load
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (messageUUID && !isLoaded) setIsLoaded(true);
  }, [messageUUID]);

  const isVisible = !!messageUUID;
  const type = messageType ? messageTypeToAlertType[messageType] : "info";

  return (
    <Alert
      message={message}
      type={type as any}
      className={classNames(
        styles["message-bar"],
        isVisible ? styles.visible : styles.invisible,
        !isLoaded && "d-none"
      )}
      showIcon
      closable
      afterClose={onClose}
    />
  );
};

export default MessageBar;
