import { AutoComplete } from "antd";
import { useEffect, useState } from "react";
import { searchDirectoryShows } from "src/actions/directory_shows";
import { useDebounce } from "src/hooks/lib";
import { useDispatchTS } from "src/hooks/redux-ts";
import { AlbumArt } from "../album_art";

interface IProps {
  onChange?: (value: string | undefined) => void;
  placeholder?: string;
}

export default function DirectorySearchAutocomplete({ onChange, placeholder }: IProps) {
  const dispatch = useDispatchTS();
  const [inputValue, setInputValue] = useState("");
  const debouncedValue = useDebounce(inputValue);
  const [options, setOptions] = useState<Record<string, any>>([]);

  useEffect(() => {
    search(debouncedValue);
  }, [debouncedValue]);

  const handleChange = (value: string) => {
    setInputValue(value);
    if (onChange) onChange(undefined);
    if (!value) setOptions([]);
  };

  const search = async (value: string) => {
    if (value === "") return; // on empty state no need to run, since it will just show an error/warning message

    const res = await dispatch(searchDirectoryShows(value));
    if (res.status === 200 && res.json) {
      setOptions(res.json);
    }
  };

  const renderOptions = (options: any) => {
    if (!options) return [];
    return options.map((o: any) => ({
      label: (
        <div className="flex-row-container align-center p-axxxs" key={o.feedURL}>
          <AlbumArt
            src={o.imageURL}
            style={{ width: 48, height: 48, borderRadius: 4 }}
            className="m-rxxs"
          />
          <strong>{o.trackName}</strong>
        </div>
      ),
      value: o.feedURL,
    }));
  };

  const handleSelect = (feedURL: string) => {
    if (onChange) onChange(feedURL);
  };

  return (
    <AutoComplete
      size="large"
      placeholder={placeholder}
      onChange={handleChange}
      onSelect={handleSelect}
      options={renderOptions(options)}
    />
  );
}
