// removes a key from the query string. Example,
// "www.google.com?utm_term=hi" --> removeQueryString("utm_term") --> "www.google.com?"
export function removeQueryString(keyToRemove: string) {
  let search = document.location.search;
  if (search === "" || search.indexOf(keyToRemove) === -1) {
    return;
  }

  // remove the "?" from the search string
  search = search.substring(1);

  const keyAndValues = search.split("&");
  document.location.search = keyAndValues
    .filter(function (keyAndValue) {
      const key = keyAndValue.split("=")[0];
      return key !== keyToRemove;
    })
    .join("&");
}

export const isValidUrl = (urlString: string) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

/**
 * Helper function needed to encode bracket for URL.
 * @param {string}
 */
export const encodeBracketsOnly = (url?: string) => {
  if (typeof url === "string") {
    return url.replaceAll("[", "%5B").replaceAll("]", "%5D");
  }

  return "";
};
