import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DynamicInsertionPage from "src/components/pages/dynamic_insertion";
import InsertionPointsModal from "src/components/pages/dynamic_insertion/insertion_points_modal";

export default function DynamicInsertionRoutes() {
  return (
    <Switch>
      <Route
        path="/dynamic-insertion/insertion-points/episodes/:episodeUUID"
        exact
        component={InsertionPointsModal}
      />

      <Route path="/dynamic-insertion/insertion-points/episodes" exact>
        <Redirect to="/dynamic-insertion/insertion-points" />
      </Route>

      <Route path="/dynamic-insertion" exact>
        <Redirect to="/dynamic-insertion/custom-audio" />
      </Route>

      <Route
        path="/dynamic-insertion/:tab?/:modifier?/:objectUUID?"
        component={DynamicInsertionPage}
      />
    </Switch>
  );
}
