import dayjs from "dayjs";
import { ICampaignItem } from "src/reducers/campaign_items";
import { VettingForm, VettingInvitation } from "src/reducers/vetting";
import { UnixTimeStamp } from "./date";
import { isUUID } from "./uuid";

/**
 * FE Only mapping of Invitations status, used in conjunction with helper funcs to determine
 * invitation statuses
 */
export const vettingInvitationStatus = {
  None: "None",
  Expired: "Expired",
  Accepted: "Accepted",
  Declined: "Declined",
  Pending: "Pending",
} as const;

export const VETTING_INVITATION_STATUS_ORDER = {
  [vettingInvitationStatus.Accepted]: 0,
  [vettingInvitationStatus.Pending]: 1,
  [vettingInvitationStatus.Declined]: 3,
  [vettingInvitationStatus.Expired]: 4,
  [vettingInvitationStatus.None]: 5,
};

export const sortVettingInvitationsByStatus = (a: VettingInvitation[], b: VettingInvitation[]) => {
  const latestInviteA = a.sort((c, d) => c.sentAt - d.sentAt)[0];
  const latestInviteB = b.sort((c, d) => c.sentAt - d.sentAt)[0];
  const statusA = getInvitationStatus(latestInviteA);
  const statusB = getInvitationStatus(latestInviteB);
  return VETTING_INVITATION_STATUS_ORDER[statusA] - VETTING_INVITATION_STATUS_ORDER[statusB];
};

/**
 * Helper function to determine invitation current status
 * @param invitation
 * @returns {keyof typeof vettingInvitationStatus}
 */
export const getInvitationStatus = (invitation?: VettingInvitation) => {
  if (!invitation) return vettingInvitationStatus.None; // No Invitation Provided/ No invitation available

  if (invitation.isExpired) return vettingInvitationStatus.Expired; // Has been properly marked as expired by BE

  // Responses exists
  if (
    typeof invitation.respondedAt === "number" &&
    Array.isArray(invitation.responses) &&
    invitation.responses?.length > 0
  ) {
    return invitation.responses[0].answer.length === 1 &&
      invitation.responses[0].answer[0]?.toLowerCase() === "yes"
      ? vettingInvitationStatus.Accepted
      : vettingInvitationStatus.Declined;
  }

  const now = dayjs().unix();

  if (now >= invitation.responseDueAt) return vettingInvitationStatus.Expired; // Has been expired due to deadline passing

  if (now < invitation.responseDueAt) return vettingInvitationStatus.Pending;

  return vettingInvitationStatus.None;
};

type PendingVettingInvitation = VettingInvitation;

/**
 * Helper func that iterates through array fo vetting invitations for a campaign Item
 * and determines if there is an ongoing pending invite
 * @param vettingInvitations // The current vetting invitations for a campaign Item
 */
export const isInvitationPendingOnItem: (
  vettingInvitations?: VettingInvitation[]
) => [boolean, PendingVettingInvitation[]] = (vettingInvitations = []) => {
  if (!Array.isArray(vettingInvitations) || vettingInvitations.length === 0) {
    return [false, []];
  }

  const sortedInvites = [...vettingInvitations].sort((a, b) => b.sentAt - a.sentAt); // descending order

  const pendingInvites = sortedInvites.filter(
    (invite) => getInvitationStatus(invite) === vettingInvitationStatus.Pending
  );

  if (pendingInvites.length > 0) {
    return [true, pendingInvites];
  }

  return [false, pendingInvites];
};

/**
 * Helps determine if there is a pending invite in cart. Used to enable send reminder action
 * @param draftItems
 * @param allCampaignVettingInvitations
 * @returns
 */
export const isInvitationsPendingInCart = (
  draftItems: ICampaignItem[] = [],
  allCampaignVettingInvitations: VettingInvitation[] = []
) => {
  const listOfUUIDs = draftItems?.map((item) => item.uuid);

  const invitationsForCart = allCampaignVettingInvitations.filter((invitation) =>
    listOfUUIDs.includes(invitation.campaignItemUUID)
  );

  const pendingInvites = invitationsForCart.filter(
    (invitation) => getInvitationStatus(invitation) === vettingInvitationStatus.Pending
  );

  return pendingInvites.length > 0;
};

/**
 * Helper to determine if a campaignItem should be marked as Brand Inquiry in podcaster campaign item table
 * @param campaignItemInvites
 * @returns
 */
export const isLatestInvitePending = (campaignItemInvites?: VettingInvitation[]) => {
  if (!Array.isArray(campaignItemInvites)) return false;

  const invitationByOrder = [...campaignItemInvites]?.sort((a, b) => b.sentAt - a.sentAt);

  const latestInvite = invitationByOrder[0];

  return getInvitationStatus(latestInvite) === vettingInvitationStatus.Pending;
};

/**
 * Helper func gets latest vetting form from list
 * @param {VettingForms[]} vettingForms
 * @returns {VettingForm | undefined}
 */
export const getLatestVettingForm = (vettingForms?: VettingForm[]) => {
  if (Array.isArray(vettingForms)) {
    if (vettingForms.length === 0) return undefined;
    if (vettingForms.length === 1) return vettingForms[0];
    if (vettingForms.length > 1)
      return vettingForms?.sort((a, b) => b.createdAt - a.createdAt)?.[0];

    return undefined;
  }

  return undefined;
};

/**
 * Helper that checks if vetting form has been sent out in a vetting invitation
 * @param {string | undefined} vettingFormUUID
 * @param {VettingInvitation[] | undefined} vettingInvitationsForCampaign
 * @returns {boolean}
 */
export const formIsInDraft = (
  vettingFormUUID?: string,
  vettingInvitationsForCampaign?: VettingInvitation[]
) => {
  if (isUUID(vettingFormUUID) && Array.isArray(vettingInvitationsForCampaign)) {
    const formInvites =
      vettingInvitationsForCampaign.filter(
        (invite) => invite.vettingFormUUID === vettingFormUUID
      ) ?? [];
    // No invitations have been sent out with this form
    const latestFormIsInDraft = formInvites.length === 0;

    return latestFormIsInDraft;
  }

  return true;
};
