/**
 * REGEX SIMULATES EMAIL VALIDATION OF INPUT TYPE=EMNAIL TO CHECK FOR EMAIL FORMAT,
 * GENERALLY BAD PRACTICE TO VERIFY EMAIL IN FRONTEND ONLY, SHOULD BE USED TO HELP
 * DEBOUNCE CALLS OR SIMPLE FORMAT CHECKS.
 *
 * SOURCE: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email
 * OTHER SOURCES: https://emailregex.com/  , https://emailregex.com/email-validation-summary/
 *
 * @param emailString email string to test
 */

export const isEmail: (emailString: string) => boolean = (emailString) => {
  const emailRegEx =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegEx.test(emailString);
};
