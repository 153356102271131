import { Divider, Popover } from "antd";
import React, { useMemo, useState } from "react";
import { getSampleAdReads } from "src/action_managers/campaigns";
import RCButton from "src/components/lib/button";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import Loading from "src/components/lib/loading";
import MediaPlayer from "src/components/lib/media_player";
import { itunesCategoryUUIDToName } from "src/data/itunes_categories";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { ReactComponent as Magnifier } from "src/icons/magnifier.svg";
import { getShowPrimaryGenderAge } from "src/lib/show";
import { IHost, IShow } from "redcircle-types";

export default function PodcastMoreInfoPopover({ show }: { show: IShow }) {
  const dispatch = useDispatchTS();
  const [adReadFetched, setAdReadFetched] = useState(false);
  const adReadsByShowUUID = useSelectorTS((state) => state.publicShows.adReadsByShowUUID);
  const showAdReads = adReadsByShowUUID?.[show.uuid];

  const details = useMemo(() => {
    const hosts = show.advertisementSettings?.hosts || [];
    const hostString = hosts
      .map((host: IHost) => `${host.fullName?.firstName} ${host.fullName?.lastName}`)
      .join(", ");
    const { primaryGender, genderPercentage, primaryAge, agePercentage } =
      getShowPrimaryGenderAge(show);

    return {
      Hosts: hostString,
      "Episode Frequency": show.estimatedEpisodeFrequency,
      "Primary Gender":
        primaryGender && genderPercentage ? `${primaryGender} (${genderPercentage}%)` : null,
      "Primary Age": primaryAge && agePercentage ? `${primaryAge} (${agePercentage}%)` : null,
      //  TODO: add these back in when incorporating campaign data
      //  Audience in Target Age
      //  Ad Details
    };
  }, [show]);

  const Content = useMemo(() => {
    return (
      <div className="flex-column-container" style={{ width: 300 }}>
        <h5 className="m-bxxs">{show.title}</h5>
        <div className="flex-row-container flex-wrap">
          {show.categoryUUIDs.map((uuid) => (
            <ExclusiveTag key={uuid} className="m-rxxxs m-bxxxs">
              {itunesCategoryUUIDToName(uuid)}
            </ExclusiveTag>
          ))}
        </div>
        <Divider className="m-txxxs m-bxs" />
        {Object.keys(details).map((key: string) => {
          if (!details[key as keyof typeof details]) return null;
          return (
            <small key={key}>
              <strong>{key}: </strong>
              <span style={{ textTransform: "capitalize" }}>
                {details[key as keyof typeof details]}
              </span>
            </small>
          );
        })}

        {!adReadFetched && <Loading />}
        {adReadFetched && showAdReads && showAdReads[0] && (
          <div className="flex-row-container align-center">
            <small className="m-rxxs">
              <strong>Past Host-Read Example Read:</strong>
            </small>
            <MediaPlayer mediaFileUUID={showAdReads[0].mediaFileUUID} mini />
          </div>
        )}
      </div>
    );
  }, [show, showAdReads, adReadFetched]);

  const handleOpenChange = () => {
    if (!adReadFetched) {
      dispatch(getSampleAdReads(show.uuid)).then((res) => {
        if (res.status == 200) setAdReadFetched(true);
      });
    }
  };

  if (!show) return null;

  return (
    <Popover
      content={Content}
      trigger={"hover"}
      placement="right"
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      onOpenChange={handleOpenChange}>
      <RCButton
        style={{ width: "20px", height: "20px", lineHeight: "normal", border: "none" }}
        type="link"
        className="p-a0">
        <Magnifier style={{ width: "20px", height: "20px" }} />
      </RCButton>
    </Popover>
  );
}
