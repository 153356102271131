import isEqual from "lodash/isEqual";
import { getMessagesActionName, sendMessageActionName } from "../../action_managers/messages";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { MessagesReduxState } from "./type";

const initialState: MessagesReduxState = {};
const getMessageReducer = httpReducer(getMessagesActionName, initialState, {
  success: (state = initialState, action) => {
    if (isEqual(action.payload.resp, state[action.data.threadUUID])) {
      return state;
    }
    return {
      ...state,
      [action.data.threadUUID]: action.payload.resp,
    };
  },
  failure: (state, action) => ({ ...state, error: action.payload.error }),
});

const sendMessageReducer = httpReducer(sendMessageActionName, initialState, {
  started: (state = initialState, action) => {
    return {
      ...state,
      [action.body.threadUUID]: state[action.body.threadUUID].concat({
        ...action.body,
        senderUUID: action.data.userUUID,
      }),
    };
  },
  success: (state) => ({ ...state }),
  failure: (state) => ({ ...state }),
});

export default reduceReducers(getMessageReducer, sendMessageReducer);
