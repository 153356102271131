import InfoIcon from "@iconify/icons-mdi/info-circle";
import { Icon, IconifyIcon } from "@iconify/react";
import { Tooltip, TooltipProps } from "antd";
import React, { CSSProperties, ReactNode } from "react";

export interface IInfoTooltip {
  className?: string;
  helpText: ReactNode;
  direction?: TooltipProps["placement"];
  style?: CSSProperties;
  source?: (props: Partial<IconifyIcon> & { "data-testid": string }) => ReactNode;
  baseIcon?: IconifyIcon["icon"];
  height?: IconifyIcon["height"];
  width?: IconifyIcon["width"];
}

const InfoTooltip = (props: IInfoTooltip) => {
  const {
    helpText,
    direction = "right",
    style,
    className,
    source,
    baseIcon = InfoIcon,
    height = 16,
    width,
  } = props;

  if (source) {
    return (
      <Tooltip
        title={helpText}
        placement={direction}
        overlayClassName="bold fs-15 lh-s"
        overlayInnerStyle={{ padding: "12px" }}>
        {source({
          className: `m-lxxxs ${className ? className : ""}`,
          "data-testid": "test-icon",
        })}
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={helpText}
      placement={direction}
      overlayClassName="bold fs-15 lh-s"
      overlayInnerStyle={{ padding: "12px" }}>
      <Icon
        icon={baseIcon}
        height={height}
        width={width}
        className={`m-lxxxs ${className ? className : ""}`}
        style={style}
        data-testid="test-icon"
      />
    </Tooltip>
  );
};

export default InfoTooltip;
