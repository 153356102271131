import { Switch } from "antd";
import { SwitchChangeEventHandler } from "antd/es/switch";
import moment from "moment";
import React from "react";
import { classNames, If } from "react-extras";
import { tierCopy } from "../../../constants/tier_copy";
import Divider from "../divider";
import "./tier-card.scss";
import { useSelectorTS } from "src/hooks/redux-ts";
import { getCurrentSubscriptionAmounts } from "src/lib/tier-utils";
import { formatMoney } from "redcircle-lib";

interface IPlanCardProps {
  isYearly?: boolean;
  freeTrial?: string;
  setIsYearly?: SwitchChangeEventHandler;
  hasFreeTrial?: boolean;
  planLevel: "growth" | "pro" | "enterprise";
  saving?: number;
  hideNextPayment?: boolean;
  showLegacyPrice?: boolean; // In the case that we need to use the user's current subscriptions amount (due to legacy subscriptions)
}

export const PlanCard = ({
  planLevel,
  setIsYearly,
  isYearly = false,
  hasFreeTrial = false,
  hideNextPayment = false,
  showLegacyPrice,
}: IPlanCardProps) => {
  const {
    title,
    desc,
    featuresTitle,
    features,
    monthly,
    hasPopular = false,
    yearlyPrice,
    isFree,
    savingsPercentage,
  } = tierCopy?.[planLevel];

  let { price, monthlyPrice } = tierCopy[planLevel];

  const tier = useSelectorTS((state) => state.user.tier);
  const currentAmounts = getCurrentSubscriptionAmounts(tier);

  if (showLegacyPrice) {
    // we can reuse the same price for both , since this flag is enabled only, when the user
    // does not change any configurations to the current tier (tier plan and tier interval is the same)
    // if the user changes the config then it reverts to the default pricing
    price = formatMoney(currentAmounts.pricePerMonth, { maximumFractionDigits: 0 }).toString();
    monthlyPrice = formatMoney(currentAmounts.pricePerMonth, {
      maximumFractionDigits: 0,
    }).toString();
  }

  const dueToday = () => {
    if (hideNextPayment) {
      return null;
    }
    const dueTodayCopy = () => {
      if (hasFreeTrial) {
        return "$0";
      }
      if (isYearly) {
        return yearlyPrice;
      }
      return monthlyPrice;
    };
    return (
      <div className="text-center">
        <div>Due Today: {dueTodayCopy()}</div>
        {hasFreeTrial && !hideNextPayment && (
          <div>
            Due on {moment().add(7, "d").format("MM/DD/YY")}:{" "}
            {isYearly ? yearlyPrice : monthlyPrice}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classNames("plan-card m-bm m-ha", { popular: hasPopular })}>
      <h5 className={"text-center title fs-s"}>{title}</h5>
      <If condition={!isFree}>
        <div
          className={"flex-row-container justify-space-between position-relative width-80 m-bxxs"}>
          <div className={"bold"}>Monthly</div>
          <Switch checked={isYearly} onChange={setIsYearly} />
          <div className="flex-column-container justify-start align-center">
            <span className="bold m-bxxxs">Yearly</span>
            <If condition={!!savingsPercentage}>
              <div className={classNames("save-pill")}>save {savingsPercentage}%</div>
            </If>
          </div>
        </div>
      </If>
      <div className={"text-center description"}>{desc}</div>
      <Divider smallMargin />
      <div className="bold">{featuresTitle}</div>
      <ul className={"features flex-column-container m-bxxs"}>
        {features.map((feat) => (
          <li key={feat}>{feat}</li>
        ))}
      </ul>
      <div className={"price-container"}>
        <div className={"price"}>
          {isYearly ? price : monthlyPrice}
          {monthly && <span className={"monthly"}>/month</span>}
        </div>
        {!isFree && hasFreeTrial && <div className={"savings text-center"}>Free 7 Day Trial</div>}
      </div>
      <If condition={!isFree}>
        <>
          <Divider marginTop={15} marginBottom={15} />
          {dueToday()}
        </>
      </If>
    </div>
  );
};
