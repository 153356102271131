import React, { Component } from "react";
import Dimensions from "react-dimensions";
import KnownAgentFriendlyNames from "../../../lib/known_agent_friendly";
import StatsPie from "./stats_pie";
import { statsToVictoryFormat } from "./utils";

class AppGraph extends Component {
  state = {
    currentLabel: "",
    currentPercent: "",
  };

  componentDidMount() {
    this.props.fetchStats(
      {
        isUnique: true,
        [this.props.uuidType]: this.props.uuid,
        timeRange: "allTime",
        bucketTerms: "download.metadata.app",
      },
      this.props.user,
      this.statsRequestID()
    );
  }

  statsRequestID = () => {
    return this.props.uuid + "app-graph";
  };

  render() {
    const agents = KnownAgentFriendlyNames.getMap("app");
    if (!agents) {
      return null;
    }

    // If the app is not known we mark it as other on the pie chart
    agents.Other = "Other";

    return (
      <div className="flex-row-container">
        <div className="stats-widget-app-graph__list">
          <ol>
            {this.getDataWithOther().map((row, index) => {
              return (
                <li key={index}>
                  <span className="app-list-label">{index + 1}.</span>
                  {KnownAgentFriendlyNames.get("app", row.x)}
                </li>
              );
            })}
          </ol>
        </div>
        <div>
          <StatsPie
            data={this.getDataWithOther()}
            labelByRow={agents}
            containerHeight={this.props.containerHeight}
            containerWidth={this.props.containerWidth}
          />
        </div>
      </div>
    );
  }

  getDataWithOther = () => {
    return statsToVictoryFormat(this.props.stats, this.statsRequestID(), {
      limit: 5,
      withOther: true,
    });
  };
}

export default Dimensions()(AppGraph);
