import { AnyAction } from "redux";
import {
  ADS_FETCH_ERROR,
  ADS_FETCH_START,
  ADS_FETCH_SUCCESS,
  AD_PUT_CLEAR,
  AD_PUT_ERROR,
  AD_PUT_INVALID,
  AD_PUT_START,
  AD_PUT_SUCCESS,
} from "../../actions/ads";
import { PutAdsReduxState } from "./types";

const initialState = {
  isLoading: false,
  ads: {} as any,
};

export function ads(state = initialState, action: AnyAction) {
  switch (action.type) {
    case ADS_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case ADS_FETCH_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newAds = Object.assign({}, state.ads);

      for (const ad of action.ads || []) {
        newAds[ad.uuid] = ad;
      }
      return Object.assign({}, state, {
        isLoading: false,
        ads: newAds,
      });
    case ADS_FETCH_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

const initialPutState: PutAdsReduxState = {
  validationErrors: [],
  isLoading: false,
  isSuccess: false,
};

export function putAds(state = initialPutState, action: AnyAction) {
  switch (action.type) {
    case AD_PUT_CLEAR:
      return Object.assign({}, initialPutState);
    case AD_PUT_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case AD_PUT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
      });
    case AD_PUT_INVALID:
      return Object.assign({}, state, {
        isLoading: false,
        validationErrors: action.validationErrors,
      });
    case AD_PUT_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}
