import getFP from "lodash/fp/get";
import groupByFP from "lodash/fp/groupBy";
import map from "lodash/fp/map";
import mapValuesFP from "lodash/fp/mapValues";
import flow from "lodash/fp/pipe";
import mergeWith from "lodash/mergeWith";
import uniq from "lodash/uniq";

/*
given an array of the same backend entity, this will return a "merge with concatenation" version of an index
with the key being the specific field on the entity
e.g.
input
[{uuid: "show1", userUUID: "user1"}, {uuid: "show2", userUUID: "user1"},{uuid: "show3", userUUID: "user2"}]
 =>
 {
    user1: ["show1", "show2"],
    user2: ["show3"]
 }

 the primaryKey optional argument is useful when the main part of the state is not keyed by uuid, like
 inventory.ID and permissions.key
 */
const extractUUID = (primaryKey: string) => map(getFP(primaryKey));
const mapValuesToIndexKey = (primaryKey: string) => mapValuesFP(extractUUID(primaryKey));
const indexByIndexKey = (key: string, primaryKey: string) =>
  flow(groupByFP(key), mapValuesToIndexKey(primaryKey));
export const mergeWithIndex = (
  partialReduxState: Record<string, string[]>,
  action: any,
  key: string,
  primaryKey = "uuid"
) => {
  return mergeWith(
    partialReduxState,
    indexByIndexKey(key, primaryKey)(action.payload.resp),
    (current: string[], src: string[]) => {
      if (Array.isArray(current)) {
        return uniq(src.concat(current));
      }
    }
  ) as Record<string, string[]>;
};
