/**
 * Formats money
 *
 * ***EXPECTS DOLLAR***. Does not auto divide for cents or milli cents.
 *
 * @param dollarValue - value to format
 * @param options -optional {Intl.NumberFormatOptions}
 * @returns
 */
export const formatMoney = (dollarValue: number, options: Intl.NumberFormatOptions = {}) => {
  if (typeof dollarValue !== "number") return 0;

  // Create our number formatter.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    ...options,

    // These options can be added to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(dollarValue);
};
