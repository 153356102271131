import { Alert } from "antd";
import RCButton from "../button/button";
import styles from "./warning_alert.module.scss";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { isShowRemote } from "src/lib/show";
import { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { IShow } from "redcircle-types";
import { StatsRequestFilterWBody } from "src/api/stats";
import { DownloadsStatsWBodyActionManager } from "src/action_managers/stats";
import ExternalLink from "../external_link";
import objectHash from "object-hash";

export const WarningVerifyVast = ({ podcasts }: { podcasts: IShow[] }) => {
  const message = (
    <span>
      Your podcast's VAST connection is no longer working{" "}
      {podcasts.length > 0
        ? `for the following podcasts: ${podcasts.map((show) => show.title).join(", ")}`
        : ""}
      . Review the{" "}
      <ExternalLink href="https://support.redcircle.com/how-to-set-up-vast">
        {`Vast Setup instructions`}
      </ExternalLink>{" "}
      and confirm that your VAST setup is still running. If you need help,{" "}
      <ExternalLink href="https://support.redcircle.com/kb-tickets/new">
        {`contact support`}
      </ExternalLink>
      .
    </span>
  );
  return <Alert className={styles.alert} message={message} type="warning" closable banner />;
};

const isOptedIn = (show: IShow) =>
  Boolean(show?.advertisementSettings?.isHostReadEnabled) ||
  Boolean(show?.programmaticAdSettings?.enabled);

export const useShouldVerifyVast = () => {
  const cacheID = "checkVastIssues";
  const { user } = useSelectorTS((state) => state.user);
  const { shows } = useSelectorTS((state) => state.shows);
  const { isLoading: isStatsLoading, stats } = useSelectorTS((state) => state.stats);
  const downloads = stats?.[cacheID];

  const dispatch = useDispatchTS();

  const verifiedRemoteRAPShows = Object.values(shows ?? {})
    .filter((show) => isShowRemote(show))
    .filter((show) => isOptedIn(show))
    .filter((show) => Array.isArray(show?.vastURLs) && show?.vastURLs?.length > 0);

  /**
   * Fetch downloads for remote RAP enabled podcasts
   */
  useEffect(() => {
    if (verifiedRemoteRAPShows.length > 0 && !isStatsLoading && !Array.isArray(downloads)) {
      const now = dayjs();

      const filters: StatsRequestFilterWBody = {
        creatorUUID: user.uuid,
        arbitraryTimeRange: [now.subtract(1, "d").unix(), now.unix()].join(","),
        permittedShowUUIDs: verifiedRemoteRAPShows.map((show) => show.uuid),
        bucketTerms: ["download.showUUID"],
        campaignUUIDs: [],
        interval: "1d",
        isUnique: true,
      };

      dispatch(
        new DownloadsStatsWBodyActionManager({
          filters,
          requestID: cacheID,
          silent: true,
        }).run()
      );
    }
  }, [verifiedRemoteRAPShows.length, isStatsLoading, downloads]);

  /**
   * Creating a unique string hash key based on dependent objects/arrays to calculate podcasts with zero downloads
   */
  const cacheKeyHash = objectHash(
    { downloads, verifiedRemoteRAPShows, shows },
    { algorithm: "sha1" }
  );

  const podcastsWithZeroDownloads = useMemo(() => {
    const podcastDownloadsMap = verifiedRemoteRAPShows.reduce(
      (accu, curr) => {
        if (typeof accu[curr.uuid] !== "number") accu[curr.uuid] = 0;

        return accu;
      },
      {} as { [showUUID: string]: number }
    );

    downloads?.forEach((download) => {
      const { count, pathValues } = download;
      const [_, showUUID] = pathValues;

      if (typeof podcastDownloadsMap[showUUID] === "number") {
        podcastDownloadsMap[showUUID] += count;
      }
    });

    return Array.isArray(downloads) && downloads.length > 0
      ? Object.entries(podcastDownloadsMap).reduce((accu, curr) => {
          const [key, val] = curr;
          if (val === 0) {
            accu.push(structuredClone(shows[key]));
          }
          return accu;
        }, [] as IShow[])
      : [];
  }, [cacheKeyHash]);

  return {
    shouldVerifyVast: podcastsWithZeroDownloads.length > 0,
    affectedPodcasts: podcastsWithZeroDownloads,
  };
};
