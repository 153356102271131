import { Select, Table } from "antd";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import take from "lodash/take";
import { Moment } from "moment-timezone";
import numeral from "numeral";
import Papa from "papaparse";
import React, { FunctionComponent, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import BlockFeature from "src/components/lib/block_feature";
import { showError, showInfo } from "../../../../actions/app";
import { DownloadStatsActionManager } from "../../../../action_managers/stats";
import { useDispatchTS, useSelectorTS } from "../../../../hooks/redux-ts";
import BlockImgSrc from "../../../../images/Block_Podcast_Performance.png";
import { goToPricingPage } from "../../../../lib/config";
import { downloadFile } from "../../../lib/download_file";
import {
  alignment,
  ALL_PODCASTS,
  customTooltip,
  DataIsLoadingMessage,
  dateFormatStringDiff,
  DropdownIntervalValues,
  equalityFunc,
  getDropdownDefaultValueFromDates,
  getDropdownOptionsFromDates,
  getIntervalFromDropdown,
  getMaskFromDropdown,
  getRequestHash,
  paginationConfig,
  PodcastPerformanceGraphDataPoint,
  PodcastPerformanceTableDataPoint,
  RequestResponseDataPoint,
  StatsRequestFilter,
  tooltipTitleFactory,
  useInitialDataRequest,
  useTierAnalyticsPerm,
  yAxisLabelFormatter,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";
import Line from "../RedCircle_graphs/Line";

// UTILITY FUNCTIONS

const lineColors = ["#EA404D", "#8B0D38", "#2A76DE", "#577D9E", "#C6C6C6"];

const podcastRequestPrefix = "PodcastsPerformance";

interface IPodcastPerformance {
  dateRange: [Moment, Moment];
  timeZone: string;
  selectedShow: string;
}

const PodcastPerformance: FunctionComponent<IPodcastPerformance> = ({
  dateRange,
  timeZone,
  selectedShow,
}) => {
  const dispatch = useDispatchTS();
  const user = useSelectorTS((state) => state?.user?.user);
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const tierPermission = useTierAnalyticsPerm();

  const [, , initialDataIsReady] = useInitialDataRequest();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const dropdownOptions = getDropdownOptionsFromDates(dateRange);
  const dropdownDefaultValue = getDropdownDefaultValueFromDates(dateRange);

  const [dropdownVal, setDropdownVal] =
    useState<(typeof DropdownIntervalValues)[number]>(dropdownDefaultValue);

  const cacheID = getRequestHash(podcastRequestPrefix, selectedShow, dateRange, dropdownVal);

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cacheID],
    shallowEqual
  );

  // Takes in data from the request response and maps each data point to the correct value/name to be consumed by the line graph
  const transformAndAggregateData: (
    data: RequestResponseDataPoint["json"]
  ) => [PodcastPerformanceGraphDataPoint[], PodcastPerformanceTableDataPoint[]] = (data) => {
    let total = 0;

    if (isEmpty(data)) {
      return [[], []];
    }

    const downloadsByShow = data?.reduce(
      (accu, curr) => {
        const showUUID = curr?.pathValues?.[1];

        if (isEmpty(shows[showUUID])) {
          return accu;
        }

        if (accu[showUUID]) {
          accu[showUUID].downloads += curr?.count;
        } else {
          accu[showUUID] = {
            downloads: curr?.count,
          };
        }
        total += curr?.count;
        return accu;
      },
      {} as Record<string, { downloads: number }>
    );

    const tableData: PodcastPerformanceTableDataPoint[] = orderBy(
      map(downloadsByShow, (val, key) => {
        return {
          key,
          showUUID: key,
          showTitle: shows?.[key]?.title as string,
          downloads: val?.downloads,
          percent: ((val?.downloads / total) * 100).toFixed(0),
          total,
        };
      }),
      "downloads",
      "desc"
    );

    // Adding color value to top 5 downalods items
    const length = tableData?.length;

    lineColors.forEach((color, index) => {
      if (index < length) {
        tableData[index] = { ...tableData[index], color };
      }
    });

    const mapByShow = keyBy(take(tableData, 5), "showUUID");

    const graphData: PodcastPerformanceGraphDataPoint[] = data
      .map(({ count, date, pathValues }) => {
        const showUUID = pathValues?.[1];
        return {
          count,
          date: date * 1000,
          show: shows?.[showUUID]?.title as string,
          showUUID,
          color: mapByShow[showUUID] ? mapByShow[showUUID].color : undefined,
        };
      })
      .filter((item) => item.color);

    return [graphData, tableData];
  };

  const [graphData, tableData] = transformAndAggregateData(statsCacheData);

  const columns = [
    {
      title: "",
      dataIndex: "key" as keyof PodcastPerformanceTableDataPoint,
      className: "analyticsPage-table-colorCell",
      align: alignment[1],
      width: 10,
      render: (key: string, show: any) => {
        return (
          <div
            style={{
              width: "95%",
              height: "16px",
              minWidth: "95%",
              backgroundColor: `${show?.color ? show.color : "transparent"}`,
            }}>
            {" "}
          </div>
        );
      },
    },
    {
      title: "Podcast Name",
      dataIndex: "showTitle" as keyof PodcastPerformanceTableDataPoint,
      className: "analyticsPage-table-cell",
      align: alignment[0],
    },
    {
      title: "Downloads",
      dataIndex: "downloads" as keyof PodcastPerformanceTableDataPoint,
      className: "analyticsPage-table-cell",
      align: alignment[2],
      render: (num: number) => numeral(num).format("0,0"),
    },
    {
      title: "% of Total",
      dataIndex: "percent" as keyof PodcastPerformanceTableDataPoint,
      className: "analyticsPage-table-cell",
      align: alignment[2],
      render: (percent: string) => {
        const number = parseFloat(percent);
        if (number < 1) {
          return `< 1%`;
        }

        return `${percent}%`;
      },
    },
  ];

  const colorByTitle = keyBy(take(tableData, 5), "showTitle");

  // map color to specific line in graph depending on switches' state;
  const colorConfig = ({ show }: Record<string, any>) => {
    return colorByTitle?.[show]?.color as string;
  };

  // map point style
  const pointConfig = {
    shape: "circle",
    size: 3,
    style: (datum: any) => {
      const color = colorByTitle[datum?.show]?.color;
      return {
        fill: "white",
        stroke: color,
        lineWidth: 0.5,
      };
    },
  };

  const interval = getIntervalFromDropdown(dropdownVal);

  //   Handle data fetching for line graph on date range/interval change
  useEffect(() => {
    let id: any;
    if (initialDataIsReady) {
      if (isEmpty(statsCacheData)) {
        setIsDataLoading(true);
        id = setTimeout(() => {
          let filters: StatsRequestFilter = {
            isUnique: true,
            arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
            timezone: timeZone,
            interval: getIntervalFromDropdown(dropdownVal),
            bucketTerms: "download.showUUID",
          };

          if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
            filters = { ...filters, showUUID: selectedShow };
          }

          dispatch(
            new DownloadStatsActionManager({
              filters,
              user,
              requestID: cacheID,
            }).run()
          )
            .then((resp: RequestResponseDataPoint) => {
              if (resp?.status !== 200) {
                dispatch(
                  showInfo("Please wait a few seconds and try the data request again", 5000)
                );
              }
              setIsDataLoading(false);
            })
            .catch(() => {
              dispatch(
                showError("An error has occured please reload the page and try again", 5000)
              );
              setIsDataLoading(false);
            });
        }, 250);
      } else {
        setIsDataLoading(false);
      }
    }

    return () => id && clearTimeout(id);
  }, [
    dateRange?.[0]?.format(dateFormatStringDiff),
    dateRange?.[1]?.format(dateFormatStringDiff),
    selectedShow,
    dropdownVal,
    initialDataIsReady,
  ]);

  // handles update to dropdownd efault value absed on parent date range
  useEffect(() => {
    setDropdownVal(dropdownDefaultValue);
  }, [dropdownDefaultValue]);

  // Handlers
  const handleIntervalChange = (newDropdownValue: (typeof DropdownIntervalValues)[number]) => {
    setDropdownVal(newDropdownValue);
  };

  const handleReportDownload = () => {
    if (!isDataLoading) {
      const sortedData = orderBy(tableData, "downloads", "desc");
      const csv = Papa.unparse(
        sortedData.map((point) => {
          return {
            PodcastName: point.showTitle,
            Downloads: point.downloads,
            Percent_of_Total: point.percent,
          };
        })
      );
      downloadFile(
        `PodcastPerformanceReport_${dateRange?.[0]?.format(
          "YYYY_MM_DD"
        )}_to_${dateRange?.[1]?.format("YYYY_MM_DD")}.csv`,
        csv
      );
    } else {
      dispatch(showInfo(DataIsLoadingMessage, 3000));
    }
  };

  const downloadSettings = tierPermission.Downloads.PodcastPerformance
    ? {
        allow: tierPermission.General.ExportDataWidgetLevel,
        text: "Podcast Performance Report",
        hanlder: handleReportDownload,
      }
    : undefined;

  return (
    <InfoCard
      title={{
        text: "Podcast Performance",
      }}
      download={downloadSettings}>
      <BlockFeature
        block={!tierPermission.Downloads.PodcastPerformance}
        CTA={{
          text: (
            <span className="h3 default-font m-b0 p-hxs text-center">
              <strong>Podcast Performance</strong> available starting from Pro Plan.
            </span>
          ),
          btn: {
            text: "Upgrade Your Plan",
            handler: () => goToPricingPage(),
          },
        }}
        blockByImg={{
          src: BlockImgSrc,
          minHeight: "700px",
        }}>
        <>
          <div className="flex-row-container align-center m-bxxs">
            <h4 className="h4 m-a0 bold capitalize">Downloads</h4>

            <span style={{ marginLeft: "auto" }}>
              <Select
                size="small"
                className="RC-Antd-Override-Dropdown"
                value={dropdownVal}
                onSelect={handleIntervalChange}
                style={{ width: "100px" }}>
                {dropdownOptions.map((val) => {
                  return (
                    <Select.Option key={val} value={val}>
                      {val}
                    </Select.Option>
                  );
                })}
              </Select>
            </span>
          </div>
          <Line<PodcastPerformanceGraphDataPoint>
            data={graphData}
            loading={isDataLoading}
            xField="date"
            yField="count"
            seriesField="show"
            legend={false}
            color={colorConfig}
            point={pointConfig}
            tooltip={{
              customContent: customTooltip,
              title: tooltipTitleFactory(interval),
            }}
            meta={{
              date: {
                type: "timeCat",
                mask: getMaskFromDropdown(dropdownVal),
              },
              count: {
                formatter: yAxisLabelFormatter,
              },
            }}
          />
          <div className="m-bs" />
          <Table
            className="analyticsPage-table"
            loading={isDataLoading}
            dataSource={tableData}
            columns={columns}
            scroll={{ x: true }}
            pagination={{
              ...paginationConfig,
              defaultPageSize: 8,
            }}
          />
        </>
      </BlockFeature>
    </InfoCard>
  );
};

const MemoizedPodcastPerformance = React.memo(PodcastPerformance, equalityFunc);

export default MemoizedPodcastPerformance;
