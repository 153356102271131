import Geohash from "latlon-geohash";
import { AnyAction } from "redux";
import {
  STATS_REQUEST_ERROR,
  STATS_REQUEST_RESET,
  STATS_REQUEST_SHOW_SELECT,
  STATS_REQUEST_START,
  STATS_REQUEST_SUCCESS,
} from "../../actions/stats";
import { fetchGeoStatsActionName } from "../../action_managers/stats";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { removeItem } from "./../index";
import { StatsReduxState } from "./types";

const initialState: StatsReduxState = {
  isLoading: false,
  loadingUUIDs: [],
  stats: {},
  requestID: null,
  selectedShowUUID: null,
};

function stats(state = initialState, action: AnyAction) {
  switch (action.type) {
    case STATS_REQUEST_RESET:
      return Object.assign({}, initialState);
    case STATS_REQUEST_START:
      return Object.assign({}, state, {
        isLoading: true,
        loadingUUIDs: state.loadingUUIDs.concat(action.requestID),
      });
    case STATS_REQUEST_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const nextState = Object.assign({}, state, {
        isLoading: state.isLoading,
        stats: {
          ...state.stats,
          [action.requestID]: action.stats,
        },
        loadingUUIDs: removeItem(state.loadingUUIDs, action.requestID),
      });
      if (nextState.loadingUUIDs.length === 0) {
        nextState.isLoading = false;
      }
      return nextState;
    case STATS_REQUEST_ERROR:
      // eslint-disable-next-line no-case-declarations
      const newState = Object.assign({}, state, {
        isLoading: !state.loadingUUIDs,
        stats: {
          ...state.stats,
          [action.requestID]: [],
        },
        loadingUUIDs: removeItem(state.loadingUUIDs, action.requestID),
      });
      if (newState.loadingUUIDs.length === 0) {
        newState.isLoading = false;
      }
      return newState;
    case STATS_REQUEST_SHOW_SELECT:
      return Object.assign({}, state, {
        selectedShowUUID: action.showUUID,
      });
    default:
      return state;
  }
}

const geoStats = httpReducer<StatsReduxState>(fetchGeoStatsActionName, initialState, {
  success: (state, action) => {
    const buckets = action.payload.resp.buckets || [];
    return {
      ...state,
      stats: {
        ...state.stats,
        [action.requestID]: buckets.map(({ geohash, count }: any) => {
          return {
            location: Geohash.decode(geohash),
            count,
          };
        }),
      },
    };
  },
  failure: (state, action) => ({
    ...state,
    loadingUUIDs: removeItem(state.loadingUUIDs, action.requestID),
    [action.requestID]: [],
  }),
});

export default reduceReducers(stats, geoStats as any);
