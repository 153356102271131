export const ADS_FETCH_START = "ADS_FOR_USER_FETCH_START";
export const ADS_FETCH_SUCCESS = "ADS_FOR_USER_FETCH_SUCCESS";
export const ADS_FETCH_ERROR = "ADS_FOR_USER_FETCH_SUCCESS";

export const AD_PUT_START = "AD_PUT_START";
export const AD_PUT_SUCCESS = "AD_PUT_SUCCESS";
export const AD_PUT_ERROR = "AD_PUT_ERROR";
export const AD_PUT_INVALID = "AD_PUT_INVALID";
export const AD_PUT_CLEAR = "AD_PUT_CLEAR";

export function adsFetchStart() {
  return { type: ADS_FETCH_START };
}

export function adsFetchSuccess(ads) {
  return { type: ADS_FETCH_SUCCESS, ads };
}

export function adsFetchError() {
  return { type: ADS_FETCH_ERROR };
}

export function adPutClear() {
  return { type: AD_PUT_CLEAR };
}

export function adPutStart() {
  return { type: AD_PUT_START };
}

export function adPutSuccess(ad) {
  return { type: AD_PUT_SUCCESS, ad };
}

export function adPutError() {
  return { type: AD_PUT_ERROR };
}

export function adPutInvalid(validationErrors) {
  return { type: AD_PUT_INVALID, validationErrors };
}
