import DefaultActionManager from "./default_action_manager";

class RedirectHelpModalActionManager extends DefaultActionManager {
  responseOK(dispatch, json) {
    if (json === true) {
      this.showSuccess(dispatch, `Successfully redirected ${this.args.showTitle}`);
    } else if (json === false) {
      this.showWarning(
        dispatch,
        `Redirect not completed. Please contact your hosting service to confirm redirect is set.`
      );
    }
    return dispatch(this.defaultSuccessActionCreator(json));
  }
}

export default RedirectHelpModalActionManager;
