import DefaultActionManager from "./default_action_manager";

export const GET_MARKERS_BY_SHOW_UUID = "GET_MARKERS_BY_SHOW_UUID";
export const getMarkersByShowUUID = (showUUID) => {
  return new DefaultActionManager({
    actionName: GET_MARKERS_BY_SHOW_UUID,
    route: `shows/${showUUID}/markers`,
    auth: true,
    actionData: {
      showUUID,
    },
  }).run();
};
