import get from "lodash/get";

const unknownKey = "unknown";

const innerGraphColors = [
  "rgba(87, 125, 158, 1)",
  "rgba(87, 125, 158, 0.65)",
  "rgba(87, 125, 158, 0.43)",
  "rgba(87, 125, 158, 0.32)",
];

export const graphColors = [];
for (var i = 0; i < 5; i++) {
  innerGraphColors.forEach((item) => {
    graphColors.push(item);
  });
}

export function aggregateStats(stats, config = {}) {
  if (!stats) {
    return [];
  }

  var totalCount = 0;
  var totals = stats.reduce((counts, current) => {
    var key = current.pathValues[1];
    if (key === unknownKey && config.removeUnknown) {
      return counts;
    }
    counts[key] = counts[key] ? counts[key] + current.count : current.count;
    totalCount += current.count;
    return counts;
  }, {});

  var result = Object.keys(totals).reduce((agg, key) => {
    agg[key] = {
      count: totals[key],
      total: totalCount,
    };
    return agg;
  }, {});

  return result;
}

export function statsToVictoryFormat(inputStats, requestID, config = {}) {
  var stats = aggregateStats(get(inputStats, requestID), config);
  var data = [];
  Object.keys(stats).forEach((key) => {
    data.push({
      x: key,
      y: stats[key].count,
      total: stats[key].total,
    });
  });

  if (data.length > 0 && config.withOther) {
    const totalCount = data[0].total;
    const otherTotal = totalCount - data.reduce((agg, current) => agg + current.y, 0);
    if (otherTotal > 0) {
      data.push({
        x: "Other",
        y: otherTotal,
        total: totalCount,
      });
    }
  }

  data.sort((a, b) => b.y - a.y);

  if (config.limit && data.length >= config.limit) {
    data = data.slice(0, config.limit);
  }
  return data;
}
