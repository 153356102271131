import { CopyOutlined, LinkOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { showSuccess } from "src/actions/app";
import { showModal } from "src/actions/modal";
import { getScheduledJob } from "src/action_managers/scheduled_job";
import { uploadToYoutube } from "src/action_managers/shows";
import Breadcrumbs from "src/components/lib/breadcrumbs/breadcrumbs";
import RCButton from "src/components/lib/button/button";
import ClipboardAction from "src/components/lib/clipboard-action";
import ContextMenu from "src/components/lib/context_menu/context_menu";
import ExpandableText from "src/components/lib/expandable_text";
import ExternalLink from "src/components/lib/external_link/external_link";
import Loading from "src/components/lib/loading/loading";
import MediaPlayer from "src/components/lib/media_player";
import Page from "src/components/lib/page/page";
import ShowPageNav from "src/components/lib/show_page_nav/show_page_nav";
import StatsWidgetContainerWrapper from "src/components/lib/stats/stats_widget_container_wrapper";
import WarningPanelv2 from "src/components/lib/warning_panel/warning_panel_v2";
import { EPISODE_DELETE_MODAL } from "src/components/modals/modal_root";
import { contactOwnerCopy, permissionTypes } from "src/constants/permission_roles";
import { tierLevels } from "src/constants/tiers";
import { useEpisode } from "src/hooks/episodes";
import { usePollingMediaFile } from "src/hooks/mediaFiles";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { useShow } from "src/hooks/shows";
import { getMegapodURL, getStreamURL } from "src/lib/config";
import { formatDuration } from "src/lib/duration";
import { useCanAccessBound } from "src/lib/permissions";
import { formatPublishDate } from "src/lib/publish_date";
import { newUUID } from "src/lib/uuid";
import { IEpisode } from "redcircle-types";
import { IShow } from "redcircle-types";
import ShowPageWrapper from "../shows/show_page_wrapper";
import EpisodePageMediaProgress from "./episode_page_media_progress";
import { EpisodePublishModal, EpisodeRegenModal } from "./episode_page_modals";
import { isShowRemote } from "src/lib/show";

export default function EpisodePage() {
  const dispatch = useDispatchTS();
  const history = useHistory();
  const location = useLocation();
  const { showUUID, episodeUUID } = useParams<{ showUUID: string; episodeUUID: string }>();
  const { show, isLoading: isShowLoading } = useShow({ showUUID });
  const { episode, isLoading: isEpisodeLoading } = useEpisode({ episodeUUID });
  const { mediaFile } = usePollingMediaFile({
    mediaFileUUID: episode?.contentMediaFileUUID,
    shouldPoll: false,
  });

  const canAccess = useCanAccessBound();
  const userCanEmbed = show && canAccess(permissionTypes.embedPlayer, show.uuid);
  const userCanEditInsertionPoints = show && canAccess(permissionTypes.editMarkers, show.uuid);
  const userCanSeeAnalytics = show && canAccess(permissionTypes.viewStats, show.uuid);
  const userCanEdit = show && canAccess(permissionTypes.editEpisodesDraft, show.uuid);
  const userCanDelete = show && canAccess(permissionTypes.deleteEpisodes, show.uuid);
  const showIsRemote = isShowRemote(show);

  const [refreshToken, setRefreshToken] = useState<string>(newUUID());
  const [showRegenModal, setRegenModal] = useState<"youtube" | "video" | false>(false);
  const [showPublishModal, setPublishModal] = useState<"youtube" | "video" | false>(false);

  const isLoading = isShowLoading || isEpisodeLoading;
  const publicURL = `${getMegapodURL()}shows/${showUUID}/episodes/${episodeUUID}`;
  const audioStreamURL = `${getStreamURL()}/episodes/${episodeUUID}/stream.mp3`;
  const episodeIsPublic = show && (isEmpty(show.importedURL) || show.redirectVerified === true);
  const episodeNotPublishedYet = episode && dayjs.unix(episode.publishedAt).isAfter(dayjs());

  const copyToClipboard = useCallback((url: string, successMessage: string) => {
    new ClipboardAction({ text: url, container: document.body });
    dispatch(showSuccess(successMessage));
  }, []);

  const handleRefresh = () => {
    setRefreshToken(newUUID());
    dispatch(showSuccess("Episode media file refreshed!"));
  };

  return (
    <ShowPageWrapper show={show} episode={episode} isLoading={isLoading}>
      <Breadcrumbs
        crumbs={[
          { path: `/shows`, name: "All Podcasts" },
          { path: `/shows/${showUUID}`, name: show?.title },
          { path: `/shows/${showUUID}/ep`, name: "Episodes" },
          { path: `/shows/${showUUID}/ep/${episodeUUID}`, name: episode?.title, active: true },
        ]}
      />
      {isLoading && <Loading />}

      {episode && !isLoading && (
        <Row className="m-bs">
          <Col span={24}>
            <EpisodePageMediaProgress episode={episode} />
          </Col>
        </Row>
      )}

      {show && episode && (
        <Row>
          <ShowPageWrapper.Sidebar />
          <ShowPageWrapper.Body>
            <ShowPageWrapper.Header title={episode.title}>
              {!showIsRemote && (
                <ContextMenu
                  noCircle={true}
                  menuItems={{
                    "Edit Episode": {
                      onSelect: () =>
                        history.push(
                          `/shows/${showUUID}/ep/${episodeUUID}/edit${location?.search}`
                        ),
                      hide: !userCanEdit,
                    },
                    "Copy Episode Media URL": {
                      onSelect: () => {
                        new ClipboardAction({ text: audioStreamURL, container: document.body });
                        dispatch(showSuccess("Copied to clipboard!"));
                      },
                    },
                    "Regenerate Video": {
                      onSelect: () =>
                        setRegenModal(show.canAutoDistributeToYouTube ? "youtube" : "video"),
                      hide: !episode.youTubeInfo?.videoURL && !episode.youTubeInfo?.videoID,
                    },
                    "Delete Episode": {
                      onSelect: () =>
                        dispatch(showModal(EPISODE_DELETE_MODAL, { episodeUUID, showUUID })),
                      disabled: !userCanDelete,
                      ...(!userCanDelete && { toolTipText: contactOwnerCopy }),
                    },
                  }}
                />
              )}
            </ShowPageWrapper.Header>

            <Page.Section className="m-bxs">
              {episode.description && (
                <ExpandableText dangerousText={episode.description} heightToWidth={4} />
              )}

              <div className="flex-column-container">
                {episodeIsPublic && !showIsRemote && (
                  <small className="flex-row-container align-center">
                    Public Episode Page{" "}
                    <RCButton
                      type="link"
                      className="p-a0 svg-button m-hxxs"
                      onClick={() => copyToClipboard(publicURL, "Copied to clipboard!")}
                      disabled={episodeNotPublishedYet}>
                      <CopyOutlined style={{ color: "#577D9E" }} />
                    </RCButton>
                    <a href={publicURL} target="_blank" rel="noreferrer">
                      <RCButton
                        type="link"
                        className="p-a0 svg-button"
                        disabled={episodeNotPublishedYet}>
                        <LinkOutlined style={{ color: "#577D9E" }} />
                      </RCButton>
                    </a>
                    {episodeNotPublishedYet && (
                      <Tooltip title="Public page info will be visible after episode is published">
                        <AiFillInfoCircle className="m-lxxs" style={{ color: "#577D9E" }} />
                      </Tooltip>
                    )}
                  </small>
                )}
                {mediaFile?.duration && <small>{formatDuration(mediaFile.duration)}</small>}
                <small>{formatPublishDate(episode.publishedAt, episode.isVisible)}</small>
              </div>

              {!!episode.contentMediaFileUUID && (
                <div className="flex-row-container align-center">
                  <div className="flex-1">
                    <MediaPlayer
                      mediaFileURL={`${episode.streamURL}?forceRefreshToken=${refreshToken}`}
                    />
                  </div>
                  <Tooltip
                    title="Click refresh to listen to the latest version that has any updates to insertion
                    points and audio blocks">
                    <RCButton
                      type="secondary"
                      size="small"
                      className="m-lxs"
                      onClick={handleRefresh}>
                      Refresh
                    </RCButton>
                  </Tooltip>
                </div>
              )}
            </Page.Section>

            <Page.Section className="m-bxs">
              <Row>
                {userCanEmbed && !showIsRemote && (
                  <Col span={24}>
                    <ShowPageNav
                      title="Embed This Episode"
                      linkTo={`/shows/${showUUID}/ep/${episodeUUID}/embed`}
                    />
                  </Col>
                )}
                {userCanEditInsertionPoints && !showIsRemote && (
                  <Col span={24}>
                    <ShowPageNav
                      title="Insertion Points"
                      linkTo={`/shows/${showUUID}/ep/${episodeUUID}/insertion-points`}
                    />
                  </Col>
                )}
                <Col span={24}>
                  <ShowPageNav
                    title={"Analytics"}
                    linkTo={show.isRemote ? `/stats/earnings` : `/stats/downloads`}
                    linkState={{
                      defaultStatsShowUUID: showUUID,
                      defaultStatsEpisodeUUID: episodeUUID,
                    }}
                  />
                </Col>
                {userCanEdit && !showIsRemote && (
                  <ShowPageNavYoutube show={show} episode={episode} onPublish={setPublishModal} />
                )}
              </Row>
            </Page.Section>

            {userCanSeeAnalytics && (
              <StatsWidgetContainerWrapper uuid={episodeUUID} uuidType="episodeUUID" />
            )}
          </ShowPageWrapper.Body>
        </Row>
      )}

      <EpisodeRegenModal
        visible={!!showRegenModal}
        onClose={() => setRegenModal(false)}
        onSubmit={() => {
          dispatch(uploadToYoutube(episodeUUID));
          setRegenModal(false);
        }}
        type={showRegenModal}
      />

      <EpisodePublishModal
        episode={episode}
        visible={!!showPublishModal}
        onClose={() => setPublishModal(false)}
        onSubmit={(values) => {
          dispatch(uploadToYoutube(episodeUUID, values));
          setPublishModal(false);
        }}
        type={showPublishModal}
      />
    </ShowPageWrapper>
  );
}

const ShowPageNavYoutube = ({
  show,
  episode,
  onPublish,
}: {
  show: IShow;
  episode: IEpisode;
  onPublish: (type: "youtube" | "video" | false) => void;
}) => {
  const dispatch = useDispatchTS();
  const { tier } = useSelectorTS((state) => state.user);
  const { scheduledJobs } = useSelectorTS((state) => state.scheduledJobs);

  useEffect(() => {
    if (episode) {
      if (episode.youTubeInfo?.scheduledJobUUID) {
        dispatch(getScheduledJob(episode.youTubeInfo?.scheduledJobUUID));
      }
    }
  }, [episode]);

  if (!episode || !episode.isVisible) return null;

  const youtubeInfo = episode.youTubeInfo;
  const scheduledJob = scheduledJobs[youtubeInfo?.scheduledJobUUID as string];
  const scheduledJobisOld =
    scheduledJob && dayjs().diff(dayjs(scheduledJob.scheduledDate), "hour") > 6;
  const scheduledJobErrors = scheduledJob && scheduledJob.errors;

  const isEpisodePublishingLater = dayjs.unix(episode.publishedAt).isAfter(dayjs());
  const connectedToYoutube = !!show.youtubeAuthToken;

  const jobRan = !!youtubeInfo;
  const jobInProcess =
    jobRan && youtubeInfo.scheduledJobUUID && !youtubeInfo?.videoURL && !youtubeInfo?.videoID;
  const jobSucceededWithPublish = jobRan && !!youtubeInfo?.videoID;
  const jobSucceededWithVideo = jobRan && youtubeInfo?.videoURL;

  const renderYTPublishComplete = () => (
    <Col span={24}>
      <ShowPageNav
        title={"YouTube Video"}
        linkTo={`https://www.youtube.com/watch?v=${youtubeInfo?.videoID}`}
      />
    </Col>
  );

  const renderVideoComplete = () => (
    <Col span={24}>
      <ShowPageNav
        title={"Download Video File"}
        linkTo={youtubeInfo?.videoURL}
        tooltip={"Your video file has been generated. Click to download it, then upload to YouTube"}
      />
    </Col>
  );

  const renderVideoProcessing = () => (
    <Col span={24}>
      <ShowPageNav
        title={isEpisodePublishingLater ? "Video Scheduled" : "Video Processing"}
        tooltip={
          isEpisodePublishingLater
            ? "Your video is scheduled. Your video will begin to generate when the episode is released."
            : "Your video is processing. Please check back in a few hours."
        }
      />
    </Col>
  );

  const renderManualJobSchedule = (
    publishToYoutube: boolean,
    withErrors: boolean,
    withDownload: boolean
  ) => (
    <Col span={24}>
      <ShowPageNav
        title={publishToYoutube ? "Publish To Youtube" : "Generate Video"}
        onClick={() => onPublish(publishToYoutube ? "youtube" : "video")}
      />
      {withErrors && !!scheduledJobErrors && (
        <WarningPanelv2>
          There was an error uploading your video. Try generating the video again, and if you're
          still having trouble, contact our support team.
          {withDownload && jobSucceededWithVideo && youtubeInfo.videoURL && (
            <>
              {" "}
              You can also{" "}
              <ExternalLink href={youtubeInfo.videoURL}>download your video</ExternalLink> and
              upload it to YouTube manually.
            </>
          )}
        </WarningPanelv2>
      )}
    </Col>
  );

  switch (tier.level) {
    case tierLevels.og:
      if (jobSucceededWithPublish) return renderYTPublishComplete();
      if (jobSucceededWithVideo) return renderVideoComplete();
      if (jobInProcess && !scheduledJobErrors) {
        // Weird case where job is scheduled and in process for a long time, maybe to a BE error that wasnt captured, allowing user to restart it.
        if (scheduledJobisOld)
          return renderManualJobSchedule(show.canAutoDistributeToYouTube, false, false);
        return renderVideoProcessing();
      }
      return renderManualJobSchedule(
        show.canAutoDistributeToYouTube,
        true,
        show.canAutoDistributeToYouTube
      );
    case tierLevels.core:
      // Not allowing generate video or publish to youtube ShowPageNav for core
      return null;
    case tierLevels.growth:
      if (jobSucceededWithVideo) return renderVideoComplete();
      if (jobInProcess && !scheduledJobErrors) {
        // Weird case where job is scheduled and in process for a long time, maybe to a BE error that wasnt captured, allowing user to restart it.
        if (scheduledJobisOld) return renderManualJobSchedule(connectedToYoutube, false, false);
        return renderVideoProcessing();
      }
      return renderManualJobSchedule(false, true, false);
    case tierLevels.pro:
    case tierLevels.enterprise:
      if (jobSucceededWithPublish) return renderYTPublishComplete();
      if (jobSucceededWithVideo) return renderVideoComplete();
      if (jobInProcess && !scheduledJobErrors) {
        // Weird case where job is scheduled and in process for a long time, maybe to a BE error that wasnt captured, allowing user to restart it.
        if (scheduledJobisOld) return renderManualJobSchedule(connectedToYoutube, false, false);
        return renderVideoProcessing();
      }
      return renderManualJobSchedule(connectedToYoutube, true, true);
    default:
      return null;
  }
};
