import Cookies from "js-cookie";
import get from "lodash/get";
import { parse } from "query-string";
import * as url from "./url";

const userCookieName = "user";
const userAttributesCookieName = "userAttributes";
const adminLogInCookieName = "adminLogIn";
const adminLogInValue = "true";
const adminLogInTokenCookieName = "adminLogInToken";
export const utmCookieName = "utmParamsV2";
export const utmSourceCookieKey = "utmSource";
export const utmMediumCookieKey = "utmMedium";
export const utmCampaignCookieKey = "utmCampaign";
export const utmTermCookieKey = "utmTerm";
export const utmContentCookieKey = "utmContent";
export const utmSourceURLKey = "utm_source";
export const utmMediumURLKey = "utm_medium";
export const utmCampaignURLKey = "utm_campaign";
export const utmTermURLKey = "utm_term";
export const utmContentURLKey = "utm_content";

export const inviteUUIDCookieName = "invite_uuid";
const attributeNameCurrentRole = "current_role";

/**
 * TEMPORARY FIX
 *
 * There are too many flags being used within user object that do not scale
 * with account that have too many shows (i.e. large Enterprise accounts). When User object
 * gets to big it fails to save the cookie and caused authentication issues.
 */
const limitUserSize = (user) => {
  let userString = JSON.stringify(user);
  const size = new Blob([userString])?.size ?? 0;

  // total space limit for cookies per domain is 4KB, going below this to make sapce for other cookies
  if (size > 3000 && user?.userAttributes?.hasSeenAdvertisingPage) {
    const newUser = { ...user };
    newUser.userAttributes = { ...user.userAttributes };
    newUser.userAttributes.hasSeenAdvertisingPage = "";
    return newUser;
  }

  return user;
};

function saveUser(user, config = {}) {
  user = limitUserSize(user);

  Cookies.set(userCookieName, JSON.stringify(user), { expires: 7, sameSite: "strict" });
  if (config.admin) {
    Cookies.set(adminLogInCookieName, adminLogInValue, { expires: 7, sameSite: "strict" });
  }
  if (config.adminToken) {
    Cookies.set(adminLogInTokenCookieName, config.adminToken, { expires: 7, sameSite: "strict" });
  }
}

function getAdminToken() {
  return Cookies.get(adminLogInTokenCookieName);
}

function saveUserAttributes(attributes, config = {}) {
  if (!config.admin) {
    const attributeString = Cookies.get(userAttributesCookieName);
    const currentAttributes = attributeString ? JSON.parse(attributeString) : {};
    Cookies.set(userAttributesCookieName, JSON.stringify({ ...currentAttributes, ...attributes }));
  }
}

export function saveUserRole(role, config) {
  saveUserAttributes({ [attributeNameCurrentRole]: role }, config);
}

function getUserRole() {
  const attributeString = Cookies.get(userAttributesCookieName);
  if (!attributeString) {
    return attributeString;
  }
  return get(JSON.parse(attributeString), attributeNameCurrentRole);
}

function getUser() {
  var userString = Cookies.get(userCookieName);
  if (userString) {
    return JSON.parse(userString);
  } else {
    return null;
  }
}

function deleteUser() {
  Cookies.remove(userCookieName);
  Cookies.remove(adminLogInCookieName);
  Cookies.remove(userAttributesCookieName);
}

function isAdminLogin() {
  return Cookies.get(adminLogInCookieName) === adminLogInValue;
}

// If there are any UTM params in the query string, then save them to the cookies.
function saveUTMParams() {
  let queryString = window.location.search;
  if (queryString[0] === "?") {
    queryString = queryString.substring(1);
  }
  const urlParams = parse(queryString);
  const source = get(urlParams, utmSourceURLKey);
  const medium = get(urlParams, utmMediumURLKey);
  const campaign = get(urlParams, utmCampaignURLKey);
  const term = get(urlParams, utmTermURLKey);
  const content = get(urlParams, utmContentURLKey);

  if (source || medium || campaign || term || content) {
    Cookies.set(
      utmCookieName,
      JSON.stringify({
        [utmSourceCookieKey]: source,
        [utmMediumCookieKey]: medium,
        [utmCampaignCookieKey]: campaign,
        [utmTermCookieKey]: term,
        [utmContentCookieKey]: content,
      }),
      { expires: 7 }
    );
  }
}

// If the user clicked on a link with an invite uuid save that as a cookie so we can use the invite for sign-up
function saveInviteUUID() {
  let queryString = window.location.search;
  if (queryString[0] === "?") {
    queryString = queryString.substring(1);
  }
  const urlParams = parse(queryString);
  const inviteUUID = get(urlParams, "iu");
  if (inviteUUID) {
    url.removeQueryString("iu");
    Cookies.set(inviteUUIDCookieName, inviteUUID, { expires: 7 });
  }
}

var UserStore = {
  save: saveUser,
  get: getUser,
  remove: deleteUser,
  isAdminLogin,
  getAdminToken,
  saveUTMParams: saveUTMParams,
  saveInviteUUID: saveInviteUUID,
  getUserRole,
};

export default UserStore;
