import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { change, submit } from "redux-form";
import { showMessageWithTimeout } from "../../actions/app";
import { showModal } from "../../actions/modal";
import { uploadModalProgress } from "../../actions/upload_modal";
import {
  EpisodeCreateActionManager,
  EpisodeUpdateActionManager,
  getEpisode,
  getYoutubeForShow,
  MediaFileActionManager,
  ShowFetchActionManager,
  validateEpisodeCreate,
  validateEpisodeUpdate,
} from "../../action_managers/shows";
import UploadModalActionManager from "../../action_managers/upload_modal";
import messageType from "../../constants/message_types";
import { withPermissions } from "../../lib/permissions-js";
import EpisodeFormModal from "./episode_form_modal";
import { UPLOAD_INTERIM } from "./modal_root";

function mapStateToProps(state) {
  var props = {
    user: state.user.user,
    episodesByShow: state.episodesByShow,
    progress: state.mediaFileUpload.progress,
    mediaFiles: state.mediaFiles,
    shows: state.shows,
    isLoading: state.mediaFileUpload.isLoading,
    imageUploader: state.imageUploader,
    campaignItems: state.campaignItems,
    tier: state?.user?.tier,
  };
  return props;
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    submitForm: (isEdit) => dispatch(submit(isEdit ? "episode-edit" : "episode-create")),
    fetchEpisode: (showUUID, episodeUUID, user) => {
      return dispatch(getEpisode(episodeUUID, showUUID));
    },
    updateIsVisible: (isVisible, mode) =>
      dispatch(change("episode-" + ownProps.mode, "isVisible", isVisible)),
    fetchMediaFile: (mediaFileUUID, authToken) => {
      if (mediaFileUUID) {
        return dispatch(new MediaFileActionManager({ mediaFileUUID, authToken }).run());
      }
    },
    fileUpload: (file, fileName, user, progressCallback) => {
      return dispatch(
        new UploadModalActionManager({
          file,
          fileName,
          authToken: user.authToken,
          progressCallback,
        }).run()
      );
    },
    uploadProgress: (progress) => {
      return dispatch(uploadModalProgress(progress));
    },
    setError: (errorMessage) => {
      dispatch(showMessageWithTimeout(errorMessage, messageType.Error));
    },
    fetchShow: (showUUID, user) => {
      return dispatch(new ShowFetchActionManager({ showUUID, user }).run());
    },
    updateEpisode: (episode, user, silent) => {
      return dispatch(new EpisodeUpdateActionManager({ episode, user, silent }).run());
    },
    createEpisode: (episode, user, silent) => {
      return dispatch(new EpisodeCreateActionManager({ episode, user, silent }).run());
    },
    validateEpisodeCreate: (episode) => {
      return dispatch(validateEpisodeCreate({ episode }));
    },
    validateEpisodeUpdate: (episode, user) => {
      return dispatch(validateEpisodeUpdate({ episode }));
    },
    setImageFileUUID: (mediaFileUUID, mode) => {
      return dispatch(change("episode-" + mode, "imageMediaFileUUID", mediaFileUUID));
    },
    setContentFileUUID: (mediaFileUUID, mode) => {
      return dispatch(change("episode-" + mode, "contentMediaFileUUID", mediaFileUUID));
    },
    setImageFileData: (mode, file) => {
      return dispatch(change("episode-" + mode, "imageFileData", file));
    },
    setAudioFileData: (mode, file) => {
      return dispatch(change("episode-" + mode, "audioFileData", file));
    },
    showMessageWithTimeout: (message, type) => {
      return dispatch(showMessageWithTimeout(message, type));
    },
    openLoadingModal: (props) => dispatch(showModal(UPLOAD_INTERIM, props)),
    getYoutubeForShow: (showUUID) => dispatch(getYoutubeForShow(showUUID)),
  };
}

const EpisodeFormModalWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withPermissions(EpisodeFormModal))
);

export default EpisodeFormModalWrapper;
