import get from "lodash/get";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import isEqualWith from "lodash/isEqualWith";
import negate from "lodash/negate";
import * as moment from "moment";
import { Component } from "react";
import { Button, ButtonGroup, Col, FormControl, FormGroup, Glyphicon, Row } from "react-bootstrap";
import { classNames, If } from "react-extras";
import { Redirect } from "react-router-dom";
import Breadcrumbs from "src/components/lib/breadcrumbs/breadcrumbs";
import RCButton from "src/components/lib/button/button";
import ExternalLink from "src/components/lib/external_link/external_link";
import Page from "src/components/lib/page/page";
import { dealState } from "../../../constants/deals";
import { permissionTypes } from "../../../constants/permission_roles";
import Divider from "../../lib/divider";
import DealBox from "./deal_box";
import "./deal_page.scss";

export default class DealPage extends Component {
  state = {
    currentMessage: "",
    smallScreenToggle: "messages",
  };

  componentDidMount() {
    this.props.getDealsForUser(this.props.user).then((response) => {
      if (response.status === 200) {
        this.fetchMessages();
      }
    });
    this.interval = setInterval(this.fetchMessages, 5000);
  }

  fetchMessages = () => {
    this.props
      .getMessages(this.getDealUUID())
      .then(() => this.props.messagesSeen(this.getDealUUID()));
  };

  getMessages = (props = this.props) => {
    return get(props.messages, this.getDealUUID(), []);
  };

  hasMessages = (props = this.props) => {
    return Array.isArray(this.getMessages(props)) && this.getMessages(props).length;
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const hasNewMessages = !isEqualWith(this.props, nextProps, (a, b, key) =>
      key === "isLoading" ? true : undefined
    );
    return hasNewMessages || this.state !== nextState;
  }

  hasBudgetsForDeals = (props) => {
    return [this.getCurrentUserDealRequest(), this.getOtherUserDealRequest()]
      .filter(negate(isEmpty))
      .every((dealRequest) =>
        dealRequest.dealContents.every((content) => get(props, ["budgets", content.budgetUUID]))
      );
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (get(this.getMessages(), "length") !== get(this.getMessages(nextProps), "length")) {
      //do it after render finishes
      setTimeout(this.scrollToBottom, 0);
    }
    if (
      this.getDeal(nextProps).state !== dealState.requested &&
      !get(nextProps, "budgets.isLoading") &&
      !this.hasBudgetsForDeals(nextProps) &&
      !get(nextProps, "budgets.error")
    ) {
      this.props.getBudgetByDealUUID(this.getDealUUID());
    }

    if (this.getMessages().length !== this.getMessages(nextProps).length) {
      if (get(this.getMessages(), "length") !== get(this.getMessages(nextProps), "length")) {
        //do it after render finishes
        setTimeout(this.scrollToBottom, 0);
      }
    }

    const newMessageHasSystemMessage = this.getMessages(nextProps)
      .slice(this.getMessages().length)
      .some((message) => message.type === "action");
    if (newMessageHasSystemMessage) {
      this.props.fetchDeal(this.getDealUUID(), this.props.user);
    }
  };

  scrollToBottom = () => {
    if (!this.messageList) {
      return;
    }
    const scrollHeight = this.messageList.scrollHeight;
    const height = this.messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  getDealUUID = () => {
    return this.props.match.params.dealUUID;
  };

  getDeal = (props = this.props) => {
    return get(props.deals, this.getDealUUID(), {});
  };

  getDealRequestByUserUUID = (userUUID) => {
    return get(this.getDeal(), ["dealRequests", userUUID], {});
  };

  getCurrentUserDealRequest = () => {
    return this.getDealRequestByUserUUID(get(this.props, "user.uuid", {}));
  };

  getOtherUserUUID = () => {
    let deal = this.getDeal();
    if (!deal) {
      return null;
    }

    if (deal.initiatorUUID === get(this.props, "user.uuid")) {
      return deal.recipientUUID;
    } else {
      return deal.initiatorUUID;
    }
  };

  getOtherUserDealRequest = () => {
    return this.getDealRequestByUserUUID(this.getOtherUserUUID());
  };

  groupByDay = (messages) => {
    return groupBy(messages, (message) => {
      // when new messages are sent on the client the createdAt isn't there right away
      // so we just use the current time
      const createdAt = message.createdAt || moment().unix();
      return moment.unix(createdAt).format("dddd, MMMM D");
    });
  };

  renderMessagesOnSingleDay = (messages, dealRequest) => {
    return messages.map(({ text, senderUUID, uuid, type, createdAt }) => {
      if (type === "action") {
        return (
          <div className={"system-message"} key={uuid}>
            <div className={"line"} />
            <div className={"message"}>{text}</div>
            <div className={"line"} />
          </div>
        );
      }
      const isSent = this.props.user.uuid === senderUUID;
      const displayTime = moment.unix(createdAt || moment().unix()).format("LT");

      return (
        <div className={`deal-page__message ${isSent ? "sent" : "received"}`} key={uuid}>
          <p>
            <span className="deal-page__sender-name">
              {isSent ? getFullName(this.props.user) : dealRequest.recipientName}
            </span>
            <span className="deal-page__time">{displayTime}</span>
          </p>
          {text}
        </div>
      );
    });
  };

  canEdit = () => {
    return this.props.canAccess(permissionTypes.editCrossPromo, this.getDeal()?.userShow?.uuid);
  };

  renderDayBreakLine = (day) => {
    const currentTime = moment();
    const currentDay = currentTime.format("dddd, MMMM D");
    const displayDay = currentDay === day ? "Today" : day;
    return (
      <div className={"system-message"} key={day}>
        <div className={"line"} />
        <div className={"message"}>{displayDay}</div>
        <div className={"line"} />
      </div>
    );
  };

  renderMessages = () => {
    const messages = this.getMessages();
    const dealRequest = this.getCurrentUserDealRequest();

    if (!this.hasMessages()) {
      return null;
    }

    const messagesByDay = this.groupByDay(messages);

    return Object.keys(messagesByDay).map((day) => {
      return (
        <div key={day}>
          {this.renderDayBreakLine(day)}
          {this.renderMessagesOnSingleDay(messagesByDay[day], dealRequest)}
        </div>
      );
    });
  };

  changeDealState = (newState) => () => {
    this.props.updateDeal(this.getDealUUID(), {
      ownerUUID: this.props.user.uuid,
      state: newState,
    });
  };

  acceptIsDisabled = () => {
    if (!get(this.getOtherUserDealRequest(), "dealContents.length")) {
      return true;
    }
    return !this.getOtherUserDealRequest().dealContents.every(
      (content) => content.mediaFileUUIDs && content.showUUID && content.airsAt
    );
  };

  sendMessage = (e) => {
    e.preventDefault();
    this.props
      .sendMessage(
        this.getDealUUID(),
        this.getCurrentUserDealRequest().recipientUUID,
        this.state.currentMessage,
        this.props.user.uuid
      )
      .then(this.fetchMessages);
    this.setState({ currentMessage: "" });
  };

  showRequestModal = () => {
    this.props.showRequestModal({
      deal: this.getDeal(),
      currentUserDealRequest: this.getCurrentUserDealRequest(),
      otherUserDealRequest: this.getOtherUserDealRequest(),
    });
  };

  deleteRequest = () => {
    const request = this.getCurrentUserDealRequest();
    const dealContent = request.dealContents[0];
    this.props.updateDeal(this.getDealUUID(), {
      ownerUUID: this.props.user.uuid,
      dealContents: [{ ...dealContent, type: "cross_promo", mediaFileUUIDs: null, airsAt: null }],
    });
  };

  getUserShowUUID = () => {
    return this.getOtherUserDealRequest()?.dealContents?.[0]?.showUUID;
  };

  render() {
    const { deals } = this.props;

    let currentUserDealRequest = this.getCurrentUserDealRequest();
    let otherUserDealRequest = this.getOtherUserDealRequest();
    let mobileVisible = "visible-xs visible-sm visible-md visible-lg visible-xl";
    let mobileHidden = "hidden-xs hidden-sm visible-md visible-lg visible-xl";
    let messagesActive = this.state.smallScreenToggle === "messages";
    let offersActive = this.state.smallScreenToggle === "promotions";
    if (
      !this.props.permissionIsLoading &&
      this.getUserShowUUID() &&
      !this.props.canAccess(permissionTypes.editCrossPromo, this.getUserShowUUID())
    ) {
      return <Redirect to={"/"} />;
    }
    const dealsLoading = deals && deals.isLoading;

    return (
      <Page>
        <Breadcrumbs crumbs={[{ name: "Back", path: "/promotions" }]} />
        <Page.Title>{get(currentUserDealRequest, "title")}</Page.Title>
        <Page.Section>
          <Row>
            <Col xs={12} className="flex-row-container align-center justify-center">
              <ButtonGroup
                className={`deal-page__mobile-toggle visible-xs visible-sm hidden-md hidden-lg hidden-xl`}>
                <Button
                  className={classNames(
                    "btn",
                    "deal-page__mobile-toggle-button",
                    messagesActive ? "btn-primary" : "btn-secondary"
                  )}
                  onClick={() => this.setState({ smallScreenToggle: "messages" })}>
                  Messages
                </Button>
                <Button
                  className={classNames(
                    "btn",
                    "deal-page__mobile-toggle-button",
                    this.state.smallScreenToggle === "promotions" && "current",
                    offersActive ? "btn-primary" : "btn-secondary"
                  )}
                  onClick={() => this.setState({ smallScreenToggle: "promotions" })}>
                  Promotions
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col md={7} xs={12}>
              <div
                className={classNames(
                  "deal-page__message-container",
                  messagesActive ? mobileVisible : mobileHidden
                )}>
                <div className={"messages-wrapper"} ref={(ref) => (this.messageList = ref)}>
                  <div className={"messages"}>{this.renderMessages()}</div>
                </div>
                <div className="deal-page__new-message-form-wrapper">
                  <If condition={this.canEdit()}>
                    <div className="deal-page__new-message-form">
                      <Divider />
                      <form onSubmit={this.sendMessage} className="p-bxxs">
                        <FormGroup controlId={"send-message"}>
                          <Row>
                            <Col xs={9}>
                              <FormControl
                                onChange={(e) => this.setState({ currentMessage: e.target.value })}
                                value={this.state.currentMessage}
                                type="text"
                                className={"field-control flex-1"}
                              />
                            </Col>
                            <Col xs={3}>
                              <RCButton
                                type="primary"
                                size="large"
                                className="deal-page__send-button"
                                style={{ height: 48 }}
                                disabled={dealsLoading}
                                htmlType="submit">
                                <Glyphicon glyph="send" className="hidden-lg hidden-xl hidden-md" />
                                <span className="hidden-xs hidden-sm">Send</span>
                              </RCButton>
                            </Col>
                          </Row>
                        </FormGroup>
                      </form>
                    </div>
                  </If>
                </div>
              </div>
            </Col>
            <Col md={5} xs={12}>
              <div
                className={classNames(
                  "deal-page__offers-wrapper",
                  offersActive ? mobileVisible : mobileHidden
                )}>
                <DealBox
                  dealRequest={otherUserDealRequest}
                  state={currentUserDealRequest.state}
                  title={`Promotion from ${get(currentUserDealRequest, "recipientName", "other")}`}
                />
                <DealBox
                  showEdit={this.getDeal().state === dealState.requested}
                  state={otherUserDealRequest.state}
                  showRequestModal={this.showRequestModal}
                  deleteRequest={this.deleteRequest}
                  title={"Your Promotion"}
                  dealRequest={currentUserDealRequest}
                />
                <div className="deal-page__accept-button-wrapper">
                  <If condition={this.getDeal().state !== "accepted"}>
                    <If condition={currentUserDealRequest.state === "requested"}>
                      <button
                        className={"btn btn-secondary m-tl deal-page__accept-button"}
                        onClick={this.changeDealState("accepted")}
                        disabled={this.acceptIsDisabled()}>
                        Accept
                      </button>
                      <If condition={this.acceptIsDisabled()}>
                        <div className="text-center disabled-explainer">
                          You need a complete promotion from {currentUserDealRequest.recipientName}{" "}
                          before you can accept
                        </div>
                      </If>
                    </If>
                    <If condition={currentUserDealRequest.state === "accepted"}>
                      <button
                        className={"btn btn-secondary deal-page__accept-button m-tl"}
                        onClick={this.changeDealState("requested")}>
                        Cancel
                      </button>
                    </If>
                  </If>
                  <If condition={this.getDeal().state === "accepted"}>
                    <h4 className={"semi-bold"}>Promotion Accepted!</h4>
                    This promo will run until completion. If something happens and you need to
                    cancel it,{" "}
                    <ExternalLink href={"https://support.redcircle.com/"}>
                      contact support
                    </ExternalLink>
                  </If>
                </div>
              </div>
            </Col>
          </Row>
        </Page.Section>
      </Page>
    );
  }
}

const getFullName = ({ firstName, lastName }) => `${firstName} ${lastName}`;
