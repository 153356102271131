import get from "lodash/get";
import { v4 as uuid } from "uuid";
import { uploadModalError, uploadModalStart, uploadModalSuccess } from "../actions/upload_modal";
import mediaFileAPI from "../api/mediaFile";
import ActionManager from "./base";

class UploadModalActionManager extends ActionManager {
  actionUUID = uuid();

  defaultErrorMessage() {
    return "Uh oh, something went wrong during the upload. Please try again later.";
  }

  responseOK(dispatch, json) {
    return dispatch(uploadModalSuccess(json, this.actionUUID));
  }

  responseError(dispatch, statusCode, json) {
    let errorMessage = json.errorMessage || json.message || this.defaultErrorMessage();

    // Use the first validation error if there is one for a clearer error message to the user.
    // Instead of "invalid request" they will now see something like "Image is too small, need
    // 1400x1400 instead of WxH".
    if (get(json, "validationErrors")) {
      errorMessage = get(json.validationErrors[0], "errorMessage", errorMessage);
    }
    dispatch(uploadModalError(errorMessage, this.actionUUID));
    this.showError(dispatch, errorMessage);
  }

  preCall(dispatch) {
    dispatch(uploadModalStart(this.actionUUID));
  }

  execute(dispatch, getState) {
    return mediaFileAPI.uploadFile(
      this.args.file,
      this.args.fileName,
      this.args.authToken || getState().user.user.authToken,
      this.args.progressCallback
    );
  }

  response400(dispatch, json) {
    this.responseError(dispatch, 400, json);
  }
}

export default UploadModalActionManager;
