import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { If } from "react-extras";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showWarning } from "src/actions/app";
import { inactiveCampaignItemsList } from "src/constants/campaigns";
import { showModal } from "../../../actions/modal";
import { getMyCards, removeCard } from "../../../action_managers/subscriptions";
import { permissionTypes } from "../../../constants/permission_roles";
import UserRoles from "../../../constants/roles";
import { shouldShowUpdatePlan } from "../../../lib/feature_flag";
import { withPermissions } from "../../../lib/permissions-js";
import ContextMenu from "../../lib/context_menu";
import Spinner from "../../lib/spinner";
import { CONFIRMATION_MODAL } from "../../modals/modal_root";
import AccountPlan from "./account_plan";
import "./payment-method.scss";

class PaymentMethods extends Component {
  componentDidMount() {
    this.props.getMyCards();
  }

  getCards = () => {
    return get(this.props.payments, "cards");
  };

  removeCard = (cardID) => {
    this.props.removeCardModal(cardID, () => {
      return this.props.removeCard(cardID).then(() => {
        return this.props.getMyCards();
      });
    });
  };

  getTier = () => {
    return this.props.tier?.level;
  };

  renderAccountPlan = () => {
    const { currentRole } = this.props.user;
    if (![UserRoles.Creator, UserRoles.Admin].includes(currentRole)) return null;
    if (!shouldShowUpdatePlan()) return null;
    return (
      <Row>
        <Col xs={12}>
          <h3>Account Plan</h3>
          <AccountPlan currentTier={this.getTier()} />
        </Col>
      </Row>
    );
  };

  renderCards = () => {
    if (isEmpty(this.getCards())) {
      return null;
    }

    const activeCampaignItems =
      Object.values(this.props.campaignItems?.campaignItems ?? {})?.filter(
        (item) => !inactiveCampaignItemsList.has(item.state)
      ) ?? [];

    const cardKeys = Object.keys(this.getCards());

    return (
      <>
        <h3 className="m-ts m-bxs">Payment Methods</h3>

        {cardKeys.map((key) => {
          const card = this.getCards()[key];
          return (
            <div className={"payment-methods-row p-as"} key={card.id}>
              <h4 className={"card-name"}>{card.brand}</h4>
              <div className={"last-4 bold"}>**** **** **** {card.last4}</div>
              <div className="exp bold">
                {moment()
                  /* moment months are zero indexed */
                  .month(card.exp_month - 1)
                  .year(card.exp_year)
                  .format("MM/YY")}
              </div>
              <div className="flex-1" />
              <If condition={this.props.payments?.userTierPayment?.card?.id !== card.id}>
                <ContextMenu
                  menuItems={{
                    "Remove Card": () => {
                      if (
                        this.props.role === UserRoles.Advertiser &&
                        cardKeys.length === 1 &&
                        activeCampaignItems?.length > 0
                      ) {
                        this.props.showWarning(
                          "Account must have at least 1 card on file while campaigns are active"
                        );
                      } else {
                        this.removeCard(card.id);
                      }
                    },
                  }}
                />
              </If>
            </div>
          );
        })}
      </>
    );
  };
  renderPaymentMethods = () => {
    const { payments } = this.props;
    const { isLoading } = payments;
    if (!this.props.canAccess(permissionTypes.updateBilling)) {
      return null;
    }
    return (
      <Row>
        <Col xs={12}>
          {isLoading ? (
            <div className="position-relative payment-methods__spinner-container">
              <Spinner isLoading={true} />
            </div>
          ) : (
            <div className={"payment-methods"}>{this.renderCards()}</div>
          )}
        </Col>
      </Row>
    );
  };
  render() {
    return (
      <Grid fluid>
        {this.renderAccountPlan()}

        {this.renderPaymentMethods()}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    payments: state.payments,
    tier: state.user.tier,
    user: state.user,
    campaignItems: state.campaignItems,
    role: state.user.currentRole,
  };
}

export default connect(mapStateToProps, {
  showWarning,
  getMyCards,
  removeCard,
  removeCardModal: (cardID, callback) => {
    return showModal(CONFIRMATION_MODAL, {
      modalTitle: "Remove Card",
      confirmationMessage: "You can add this card again later by editing a subscription",
      callback,
      actionButtonName: "Remove Card",
    });
  },
})(withRouter(withPermissions(PaymentMethods)));
