import React from "react";
import { ICampaign } from "redcircle-types";
import { ICampaignItem } from "src/reducers/campaign_items";
import {
  CampaignItemStateAwaitingAudio,
  ProductExchangeTypeInstructions,
  ProductExchangeTypeMailing,
} from "../../../constants/campaigns";
import WarningPanelv2 from "../../lib/warning_panel/warning_panel_v2";

interface IProductInstructionsInfo {
  campaign: ICampaign;
  campaignItem: ICampaignItem;
  additionalContent?: React.ReactNode;
  forceOn?: boolean;
}

const ProductInstructionsInfo = ({
  campaign,
  campaignItem,
  additionalContent,
  forceOn = false,
}: IProductInstructionsInfo) => {
  const itemAccepted = campaignItem.state === CampaignItemStateAwaitingAudio || forceOn;

  const isProductInstruction = campaign.productExchangeType === ProductExchangeTypeInstructions;
  const isProductMailing = campaign.productExchangeType === ProductExchangeTypeMailing;

  if (!itemAccepted || (!isProductInstruction && !isProductMailing)) {
    return null;
  }
  return (
    <WarningPanelv2 withShadow className={"m-bs"}>
      {!forceOn && <h3 className="m-bxs">Next Steps</h3>}
      <div className="redcircle-form-label">instructions for receiving product samples</div>
      {(isProductInstruction || isProductMailing) && (
        <>
          {isProductMailing && (
            <p>Brand will ship the product sample to the mailing address provided.</p>
          )}

          {campaign.productExchangeInstructions && (
            <div
              dangerouslySetInnerHTML={{ __html: campaign.productExchangeInstructions }}
              className="text-block-expanded"
            />
          )}

          {campaignItem.redemptionCodes?.length && (
            <>
              <div className="redcircle-form-label m-tm">Redemption Codes</div>
              <div>
                <p>
                  Enter this code at checkout (good for one use) to receive your product sample:
                </p>
                <p className="text-block-expanded">
                  {campaignItem.redemptionCodes.map((code, i) => (
                    <div className={"bold"} key={i}>
                      {code}
                    </div>
                  ))}
                </p>
              </div>
            </>
          )}
        </>
      )}
      {additionalContent && additionalContent}
    </WarningPanelv2>
  );
};

export default ProductInstructionsInfo;
