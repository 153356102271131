import classNames from "classnames";
import styles from "./AudioPlayer.module.scss";
import AudioPlayerDownload from "./components/Download";
import AudioPlayerImage from "./components/Image";
import { BackwardButton, ForwardButton, PlayButton, StopButton } from "./components/PlayButtons";
import AudioPlayerPlayRate from "./components/PlayRate";
import SeekBar from "./components/SeekBar";
import AudioPlayerTime from "./components/Time";
import AudioPlayerTitle from "./components/Title";
import AudioPlayerVolume from "./components/Volume";
import { AudioPlayerContext, AudioPlayerWrapper } from "./components/Wrapper";

interface IAudioPlayer {
  visible: boolean;
  src?: string;
  imageURL?: string;
  title?: string;
  subtitle?: string;
  onPrev?: () => void;
  onNext?: () => void;
  onStop?: () => void;
  style?: React.CSSProperties;
  autoplay?: boolean;
}

// Fully built AudioPlayer component
export default function AudioPlayer({
  visible,
  src,
  imageURL,
  title,
  subtitle,
  onPrev,
  onNext,
  onStop,
  autoplay = true,
  style = {},
}: IAudioPlayer) {
  return (
    <AudioPlayerWrapper src={src} title={title} autoplay={autoplay}>
      <div
        className={classNames(styles.audioPlayerWrapper, "rc-audio-player")}
        style={{ bottom: visible ? "0px" : "-200px", ...style }}>
        <div className={styles.audioPlayer}>
          <AudioPlayerImage className="m-rxs" imageURL={imageURL} alt={title} />
          <div className={styles.flex}>
            <AudioPlayerTitle className="m-rxs" subtitle={subtitle} />
            {/* CONTROL GRID */}
            <div className={styles.controlGrid}>
              <div className="flex-row-container align-center justify-center">
                <BackwardButton className="m-rs" onClick={onPrev} disabled={!onPrev} />
                <PlayButton className="m-rs" />
                <ForwardButton onClick={onNext} disabled={!onNext} />
                {onStop && <StopButton className="m-ls" onClick={onStop} />}
              </div>
              <div className="flex-row-container align-center justify-between">
                <AudioPlayerPlayRate />
                <AudioPlayerVolume />
                <AudioPlayerDownload />
              </div>
              <div className="flex-row-container align-end justify-center">
                <SeekBar className="width-100" />
              </div>
              <div className="flex-row-container align-end justify-center">
                <AudioPlayerTime />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AudioPlayerWrapper>
  );
}

AudioPlayer.Wrapper = AudioPlayerWrapper;
AudioPlayer.Consumer = AudioPlayerContext.Consumer; // use when creating custom audio components
AudioPlayer.Image = AudioPlayerImage;
AudioPlayer.Title = AudioPlayerTitle;
AudioPlayer.BackwardButton = BackwardButton;
AudioPlayer.PlayButton = PlayButton;
AudioPlayer.ForwardButton = ForwardButton;
AudioPlayer.SeekBar = SeekBar;
AudioPlayer.Time = AudioPlayerTime;
AudioPlayer.PlayRate = AudioPlayerPlayRate;
AudioPlayer.Volume = AudioPlayerVolume;
AudioPlayer.Download = AudioPlayerDownload;
