import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import HighlightBox from "src/components/lib/highlight_box";
import { getCampaignItemTotalAmount } from "src/lib/campaigns";
import { formatMoney } from "src/lib/format-money";
import { ICampaign } from "redcircle-types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { getCampaignItemTotalCreatorAmount } from "src/api/campaigns";
import { isCampaignItem } from "redcircle-types";

export default function ShowCampaignMetadata({
  campaign,
  campaignItem,
  className,
  isLoading,
}: {
  campaign: ICampaign;
  campaignItem: ICampaignItem;
  className?: string;
  isLoading: boolean;
}) {
  const [totalAmount, setTotalAmount] = useState<number | null>(null);
  const isValidCampaignItem = isCampaignItem(campaignItem);

  useEffect(() => {
    if (totalAmount === null && isValidCampaignItem) {
      if (campaignItem?.isV2) {
        getCampaignItemTotalCreatorAmount(campaignItem?.uuid).then((val) => {
          setTotalAmount(val.totalCreatorAmount);
        });
      } else {
        setTotalAmount(getCampaignItemTotalAmount(campaignItem));
      }
    }
  }, [totalAmount, isValidCampaignItem]);

  return (
    <Row gutter={[16, 16]} className={className}>
      <Col xs={24} md={24}>
        <HighlightBox
          className="bordered"
          title="total amount"
          value={formatMoney(totalAmount ?? 0)}
          isLoading={isLoading || !campaignItem || totalAmount === null}
        />
      </Col>
    </Row>
  );
}
