/* eslint-disable no-case-declarations */
import { isEmpty } from "lodash";
import { permissionObjectTypes } from "src/constants/permissions";
import {
  permissionPresets,
  permissionsConfigMap,
  permissionTypes,
} from "src/constants/permission_roles";
import UserRoles from "src/constants/roles";
import { isShowRedirected } from "src/lib/show";
import {
  ICampaignOrCampaignTag,
  isArrayOfCampaignsOrCampaignTags,
  isShow,
  isArrayOfShows,
  IShow,
  UserRole,
} from "redcircle-types";
import { IPermissions, ObjectType, Presets } from "src/reducers/permissions/types";

/**
 *
 * Helper func to calculate a credential's current role for a given entity
 *
 * @param credentialUUID
 * @param orgUUID
 * @param permissionMapByKey
 * @param entityUUID // UUID for entity i.e. ShowUUID, CampaignUUID, CampaignTagUUID
 * @returns
 */
export const calculateRole = (
  credentialUUID: string,
  orgUUID: string,
  permissionMapByKey: Record<string, IPermissions>,
  entityUUID?: string
) => {
  const orgKey = `${credentialUUID}-${orgUUID}`;
  const entityKey = `${credentialUUID}-${entityUUID}`;
  const defaultRole = permissionMapByKey?.[orgKey]?.preset || permissionPresets.noAccess;
  if (defaultRole === permissionPresets.orgAdmin) {
    return defaultRole;
  }

  return permissionMapByKey?.[entityKey]?.preset || defaultRole;
};

/**
 * Used to organize and sort roles within the interface, also decide
 * hierarchy (i.e if one non admin role can edit another non admin role)
 */
export const presetRoleRanking: Record<Presets, number> = {
  // Org Admin
  orgAdmin: 6,

  // Show Presets
  showAdmin: 5,
  showContributor: 4,
  showEditor: 3,
  showViewer: 2,
  noAccess: 0,

  // Campaign presets
  campaignAdmin: 5,
  campaignEditor: 3,
  campaignViewer: 2,

  //Other
  audioBlockOwner: 0,
};

const specificEntityType = {
  show: "show",
  campaign: "campaign",
  tag: "campaignTag",
} as const;

export type AccessFormState = {
  entities: {
    uuid: string;
    type: Extract<ObjectType, "show" | "campaignOrCampaignTag">;
    specificType: (typeof specificEntityType)[keyof typeof specificEntityType];
    checked: boolean;
    currentRole: Presets;
    name: string;
    imgUrl: null | string;
  }[];
  defaultRole: Presets;
};

export type AccessFormAction =
  | {
      type: "updateEntityRole";
      entityUUID: string;
      newRole: Presets;
    }
  | {
      type: "updateEntityCheck";
      entityUUID: string;
      checked: boolean;
    }
  | {
      type: "updateDefaultRole";
      newRole: Presets;
    }
  | {
      type: "reInitialize";
      formState: AccessFormState;
    };

export const AccessInitialState: AccessFormState = {
  entities: [],
  defaultRole: "noAccess",
};

export const AccessFormReducer = (state: AccessFormState, action: AccessFormAction) => {
  const stateCopy: AccessFormState = structuredClone(state);
  let entity: AccessFormState["entities"][number] | undefined;

  switch (action.type) {
    case "updateEntityCheck":
      entity = stateCopy.entities.find((entity) => entity.uuid === action.entityUUID);

      if (entity && entity.checked !== action.checked) {
        entity.checked = action.checked;
      }
      return stateCopy;

    case "updateEntityRole":
      entity = stateCopy.entities.find((entity) => entity.uuid === action.entityUUID);

      if (entity && entity.currentRole !== action.newRole) {
        entity.currentRole = action.newRole;
      }
      return stateCopy;

    case "updateDefaultRole":
      if (stateCopy.defaultRole !== action.newRole) {
        stateCopy.defaultRole = action.newRole;
      }
      return stateCopy;

    case "reInitialize":
      return structuredClone(action.formState);

    default:
      return stateCopy;
  }
};

/**
 * Calculate Initial values for Access form State
 */
export const calculateInitialValues = (
  entities: IShow[] | ICampaignOrCampaignTag[],
  selectedCredUUID: string,
  orgUUID: string,
  permissionsByKey: Record<string, IPermissions>,
  searchText: string
) => {
  const accessForm: AccessFormState = {
    entities: [],
    defaultRole: permissionPresets.noAccess,
  };

  const isSelectedUserOrgOwner =
    permissionsByKey[`${selectedCredUUID}-${orgUUID}`]?.preset === permissionPresets.orgAdmin;

  const selectedUserOrgPermission = permissionsByKey[`${selectedCredUUID}-${orgUUID}`];

  if (
    !isEmpty(selectedUserOrgPermission) &&
    selectedUserOrgPermission?.preset !== permissionPresets.orgAdmin
  ) {
    accessForm.defaultRole = selectedUserOrgPermission.preset;
  }

  let filteredEntities = entities;

  // Filters for Shows
  if (entities.length > 0 && isArrayOfShows(filteredEntities)) {
    // Filter out redirected shows
    filteredEntities = filteredEntities.filter((entity) => !isShowRedirected(entity));

    // Filter out based on search text
    filteredEntities = filteredEntities.filter((entity) =>
      entity?.title?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  }

  // Filters for Campaigns
  if (entities.length > 0 && isArrayOfCampaignsOrCampaignTags(filteredEntities)) {
    // Filter out based on search text
    filteredEntities = filteredEntities.filter((entity) => {
      if ("name" in entity) {
        return entity?.name?.toLowerCase()?.includes(searchText?.toLowerCase());
      }

      if ("title" in entity) {
        return entity?.title?.toLowerCase()?.includes(searchText?.toLowerCase());
      }

      return true;
    });
  }

  for (const entity of filteredEntities) {
    // Selected Credential is Org Owner, has all Admin Access
    if (isSelectedUserOrgOwner) {
      accessForm.entities.push({
        uuid: entity.uuid,
        checked: false,
        type: isShow(entity)
          ? permissionObjectTypes.show
          : permissionObjectTypes.campaignOrCampaignTag,
        specificType: (entity as any).permissionType,
        currentRole: isShow(entity) ? permissionPresets.showAdmin : permissionPresets.campaignAdmin,
        name: isShow(entity) ? (entity.title ?? "") : "name" in entity ? entity.name : entity.title,
        imgUrl: isShow(entity) ? entity.imageURL : null,
      });
    }
    // Selected credential has an explicit permission set for this entity
    else if (!isEmpty(permissionsByKey[`${selectedCredUUID}-${entity.uuid}`])) {
      const permission = permissionsByKey[`${selectedCredUUID}-${entity.uuid}`];

      accessForm.entities.push({
        uuid: entity.uuid,
        checked: false,
        type: isShow(entity)
          ? permissionObjectTypes.show
          : permissionObjectTypes.campaignOrCampaignTag,
        specificType: (entity as any).permissionType,
        currentRole: permission.preset,
        name: isShow(entity) ? (entity.title ?? "") : "name" in entity ? entity.name : entity.title,
        imgUrl: isShow(entity) ? entity.imageURL : null,
      });
    }
    // Selected credential has no explicit permission set of the entity, will default to org perm preset
    else {
      accessForm.entities.push({
        uuid: entity.uuid,
        checked: false,
        type: isShow(entity)
          ? permissionObjectTypes.show
          : permissionObjectTypes.campaignOrCampaignTag,
        specificType: (entity as any).permissionType,
        currentRole: isShow(entity) ? accessForm.defaultRole : "notSet", // use default role for shows, notSet for campaigns
        name: isShow(entity) ? (entity.title ?? "") : "name" in entity ? entity.name : entity.title,
        imgUrl: isShow(entity) ? entity.imageURL : null,
      });
    }
  }
  return accessForm;
};

/**
 * Scans all entity permissions (show or campaignOrCampaignTag) for a credential and determines
 * customized override settings
 */
export const determineOverrides: (props: {
  credentialUUID: string;
  currentRole: Presets;
  allOrgPermissions: IPermissions[];
}) => IPermissions["overrides"] = ({ credentialUUID, currentRole, allOrgPermissions }) => {
  const orgPermission = allOrgPermissions.find(
    (perm) =>
      perm.credentialUUID === credentialUUID && perm.objectType === permissionObjectTypes.org
  );
  const relevantPermissions = allOrgPermissions.filter(
    (perm) =>
      (perm.objectType === permissionObjectTypes.campaignOrCampaignTag ||
        perm.objectType === permissionObjectTypes.show) &&
      perm.preset === currentRole &&
      perm.credentialUUID === credentialUUID
  );

  let overrides: Partial<Record<keyof typeof permissionTypes, boolean>> =
    relevantPermissions.reduce((accu, curr) => {
      if (curr?.overrides) {
        accu = { ...accu, ...curr?.overrides };
      }

      return accu;
    }, {});

  if (orgPermission && orgPermission.preset === currentRole && orgPermission.overrides) {
    overrides = { ...overrides, ...orgPermission.overrides };
  }

  if (Object.keys(overrides).length === 0) return null;

  return overrides;
};

/**
 * Helper, compares override map with default map for a specific preset,
 * will return permission types changed or null if no change detected
 */
export const cleanFinalOverrides: (
  overrides: IPermissions["overrides"],
  presetRole: Presets
) => IPermissions["overrides"] = (overrides, presetRole) => {
  const defaultMap = Object.entries(permissionsConfigMap).reduce(
    (accu, curr) => {
      const [permissionName, metaData] = curr;
      if (metaData && metaData?.[presetRole]) {
        accu[permissionName as keyof typeof permissionTypes] = Boolean(
          metaData[presetRole]?.defaultState
        );
      }

      return accu;
    },
    {} as Record<keyof typeof permissionTypes, boolean>
  );

  let finalOverrides: IPermissions["overrides"] = null;

  if (overrides) {
    for (const permissionType in defaultMap) {
      if (
        typeof overrides?.[permissionType as keyof typeof overrides] === "boolean" &&
        overrides[permissionType as keyof typeof overrides] !==
          defaultMap[permissionType as keyof typeof defaultMap]
      ) {
        if (finalOverrides === null) {
          finalOverrides = {};
        }

        finalOverrides[permissionType as keyof typeof finalOverrides] =
          overrides[permissionType as keyof typeof overrides];
      }
    }
  }

  return finalOverrides;
};

export const getPermPresetsFromUserRole = (userRole: UserRole) => {
  switch (userRole) {
    case UserRoles.Advertiser:
      return [
        permissionPresets.campaignAdmin,
        permissionPresets.campaignEditor,
        permissionPresets.campaignViewer,
        permissionPresets.noAccess,
      ];

    case UserRoles.Admin:
    case UserRoles.Creator:
    default:
      return [
        permissionPresets.showAdmin,
        permissionPresets.showContributor,
        permissionPresets.showEditor,
        permissionPresets.showViewer,
        permissionPresets.noAccess,
      ];
  }
};
