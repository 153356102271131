import includes from "lodash/includes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change, Field } from "redux-form";
import usePrevious from "../../../hooks/use_previous";
import { CheckboxField } from "../../forms/checkbox";
import FormElement from "../../forms/form_element";
import { RadioInputField } from "../../lib/radio-input/radio-input";
import { campaignEditorFormName, CampaignEditorPageFormsWrapper } from "./campaign_editor_helpers";

export const ageOptions = [
  { value: "any", display: "Any Age" },
  { value: "0-17", display: "<18" },
  { value: "18-22", display: "18-22" },
  { value: "23-27", display: "23-27" },
  { value: "28-34", display: "28-34" },
  { value: "35-44", display: "35-44" },
  { value: "45-59", display: "45-59" },
  { value: "60-150", display: "60+" },
];

export const genderOptions = [
  {
    value: "all",
    display: "All/No Preference",
  },
  {
    value: "female",
    display: "Primarily Female",
  },
  {
    value: "male",
    display: "Primarily Male",
  },
];

const TargetAudience = (props) => {
  const { handleSubmit } = props;
  const formValues = useSelector((state) => state.form[campaignEditorFormName]?.values);

  const ages = formValues?.targetAges ?? [];
  const prevAges = usePrevious(ages);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!includes(prevAges, "any") && includes(ages, "any")) {
      dispatch(change(campaignEditorFormName, "targetAges", ["any"]));
    }
    if (includes(prevAges, "any") && ages.length > 1) {
      dispatch(
        change(
          campaignEditorFormName,
          "targetAges",
          ages.filter((v) => v !== "any")
        )
      );
    }
    if (ages.length === ageOptions.length - 1 || ages.length === 0) {
      dispatch(change(campaignEditorFormName, "targetAges", ["any"]));
    }
  }, [ages]);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        component={FormElement}
        name={"targetGender"}
        label={"primary gender*"}
        InputComponent={RadioInputField}
        defaultValue={"all"}
        options={genderOptions}
        className={"m-bxxxs"}
      />
      <Field
        component={FormElement}
        name="targetAges"
        label={"primary age*"}
        InputComponent={CheckboxField}
        options={ageOptions}
      />
    </form>
  );
};

export default CampaignEditorPageFormsWrapper(TargetAudience);
