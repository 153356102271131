import keyBy from "lodash/keyBy";
import omit from "lodash/omit";
import {
  CUSTOM_AUDIO_CREATE,
  CUSTOM_AUDIO_DELETE,
  CUSTOM_AUDIO_GET_AUDIO_BLOCKS,
  CUSTOM_AUDIO_GET_FOR_USER,
  CUSTOM_AUDIO_GET_INSERTIONS,
  CUSTOM_AUDIO_GET_MEDIA_FILE,
  CUSTOM_AUDIO_GET_MEDIA_FILES,
  CUSTOM_AUDIO_UPDATE,
} from "src/action_managers/audio_management";
import { httpReducer, reduceReducers } from "src/lib/create-action";
import { CustomAudio, CustomAudiosReduxState } from "./types";
import { isUUID } from "src/lib/uuid";

const initialState: CustomAudiosReduxState = {
  customAudios: {},
  isLoading: false,
  initialFetched: false,
};

const getCustomAudioForUser = httpReducer(CUSTOM_AUDIO_GET_FOR_USER, initialState, {
  success: (state: any, action: any) => ({
    ...state,
    customAudios: { ...state?.customAudios, ...keyBy(action.payload.resp, "uuid") },
    initialFetched: true,
  }),
});

const createCustomAudio = httpReducer(CUSTOM_AUDIO_CREATE, initialState, {
  success: (state: any, action: any) => {
    const mediaFile = action.data.mediaFile;
    const newCustomAudio = {
      ...action.payload.resp,
      mediaFile,
      insertions: 0,
      audioBlocks: [],
    };

    return {
      ...state,
      customAudios: { ...state.customAudios, [newCustomAudio.uuid]: newCustomAudio },
    };
  },
});

const updateCustomAudio = httpReducer(CUSTOM_AUDIO_UPDATE, initialState, {
  success: (state: any, action: any) => {
    const mediaFile = action.data.mediaFile;

    const updatedCustomAudio = {
      ...action.payload.resp,
      mediaFile,
      insertions: action.data.insertions,
      audioBlocks: action.data.audioBlocks,
    };

    return {
      ...state,
      customAudios: { ...state.customAudios, [updatedCustomAudio.uuid]: updatedCustomAudio },
    };
  },
});

const deleteCustomAudio = httpReducer(CUSTOM_AUDIO_DELETE, initialState, {
  success: (state: any, action: any) => ({
    ...state,
    customAudios: omit(state.customAudios, action.data.uuid),
  }),
});

const getAudioBlocksForCustomAudios = httpReducer(CUSTOM_AUDIO_GET_AUDIO_BLOCKS, initialState, {
  success: (state: any, action: any) => {
    const updatedCustomAudios: Record<string, CustomAudio> = {};
    const audioBlocks = action.payload.resp.audioBlocksByCustomAudioUUID;

    Object.keys(audioBlocks).forEach((key) => {
      updatedCustomAudios[key] = { ...state.customAudios[key], audioBlocks: audioBlocks[key] };
    });

    return { ...state, customAudios: { ...state.customAudios, ...updatedCustomAudios } };
  },
});

const getInsertionsForCustomAudios = httpReducer(CUSTOM_AUDIO_GET_INSERTIONS, initialState, {
  success: (state: any, action: any) => {
    const updatedCustomAudios: Record<string, CustomAudio> = {};
    const insertions = action.payload.resp.insertionsByCustomAudioUUID;

    Object.keys(insertions).forEach((key) => {
      updatedCustomAudios[key] = { ...state.customAudios[key], insertions: insertions[key] };
    });

    return { ...state, customAudios: { ...state.customAudios, ...updatedCustomAudios } };
  },
});

const getMediaFilesForCustomAudios = httpReducer(CUSTOM_AUDIO_GET_MEDIA_FILES, initialState, {
  success: (state: any, action: any) => {
    const updatedCustomAudios: Record<string, CustomAudio> = {};
    const allUUIDs = Object.keys(state.customAudios);
    const mediaFiles = action.payload.resp.mediaFilesByUUID;

    allUUIDs.forEach((customAudioUUID) => {
      const mediaFileUUID = state.customAudios[customAudioUUID]?.mediaFileUUID;

      if (isUUID(mediaFileUUID) && !!mediaFiles[mediaFileUUID]) {
        updatedCustomAudios[customAudioUUID] = {
          ...state.customAudios[customAudioUUID],
          mediaFile: mediaFiles[mediaFileUUID],
        };
      }
    });

    return { ...state, customAudios: { ...state.customAudios, ...updatedCustomAudios } };
  },
});

const getCustomAudioMediaFile = httpReducer(CUSTOM_AUDIO_GET_MEDIA_FILE, initialState, {
  success: (state: any, action: any) => {
    const updatedCustomAudios = { ...state.customAudios };

    updatedCustomAudios[action.data.customAudio.uuid] = {
      ...updatedCustomAudios[action.data.customAudio.uuid],
      mediaFile: action.payload.resp,
    };

    return { ...state, customAudios: updatedCustomAudios };
  },
});

export default reduceReducers(
  getCustomAudioForUser,
  createCustomAudio,
  updateCustomAudio,
  deleteCustomAudio,
  getAudioBlocksForCustomAudios,
  getInsertionsForCustomAudios,
  getMediaFilesForCustomAudios,
  getCustomAudioMediaFile
);
