import React, { Component } from "react";
import { If } from "react-extras";
import { reduxForm } from "redux-form";
import { seedcastForm } from "../../../lib/forms";
import PasswordField from "../../lib/password_field";
import Spinner from "../../lib/spinner";
class NewPasswordForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Spinner isLoading={this.props.isLoading} />
        <div className="text-start">
          <PasswordField
            validationErrors={this.props.validationErrors}
            password={this.props.password}
          />
        </div>
        <If condition={!this.props.hideSubmit}>
          <hr className="redcircle-divider" />
          <button type="submit" className={"btn btn-primary btn-block"}>
            Set New Password
          </button>
        </If>
      </form>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isSuccess) {
      this.props.resetAndLogout();
    }
  }
}

NewPasswordForm = reduxForm({
  form: "new-password",
})(NewPasswordForm);
NewPasswordForm = seedcastForm(NewPasswordForm);

export default NewPasswordForm;
