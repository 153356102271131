export type MarkersReduxState = {
  isLoading: boolean;
  markers: { [key: string]: Marker };
  markersByEpisodeUUID: { [episodeUUID: string]: string[] };
  recentMarkerUUIDs: string[];
  markersByShowUUID: { [showUUID: string]: string[] };
  markersPageUUIDs: string[];
};

export type Marker = {
  uuid: string;
  episodeUUID: string;
  showUUID: string;
  userUUID: string;
  offsetMilliSeconds: number;
  offsetBytes: number;
  position: MarkerPosition;
  createdAt: number;
  updatedAt: number;

  audioBlockUUID?: string; // undefined if a marker has no audioBlockUUID attached
};

export enum MarkerPosition {
  PREROLL = "PREROLL",
  MIDROLL = "MIDROLL",
  POSTROLL = "POSTROLL",
}
