import isEmpty from "lodash/isEmpty";
import { parse, stringify } from "query-string";
import React, { useEffect } from "react";
import { classNames } from "react-extras";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useWindowSize } from "redcircle-lib";
import { MEDIA_QUERY_BREAKPOINT } from "redcircle-ui";
import { dismissMessageBar, toggleSidebarOpen } from "src/actions/app";
import AdminPanel from "src/components/base/admin_panel";
import MessageBar from "src/components/base/message_bar";
import { ModalLauncher } from "src/components/lib/modal_launcher";
import { oneLevelUp } from "src/components/lib/routing";
import { SPONSORSHIP_MODAL } from "src/components/modals/modal_root";
import RemoteSignUpPage from "src/components/pages/sign_up/remote_sign_up_page";
import TipPage from "src/components/pages/tip/tip_page";
import TipSuccess from "src/components/pages/tip/tip_success";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { GoogleTagManager } from "src/lib/google_tag_manager";
import { isUUID } from "src/lib/uuid";
import AdPlatformRoutes from "src/routes/ad_platform_routes";
import CampaignRoutes from "src/routes/campaign_routes";
import DynamicInsertionRoutes from "src/routes/dynamic_insertion_routes";
import ShowsRoutes from "src/routes/shows_routes";
import { isMobile } from "../constants/css";
import { permissionTypes } from "../constants/permission_roles";
import UserRoles from "../constants/roles";
import { isDev, isStaging } from "../lib/config";
import { isUserWaitListRestricted, userHasRole, userRoleExists } from "../lib/user";
import HomeRedirectWrapper from "./base/home_redirect/home_redirect_wrapper";
import Sidebar from "./base/sidebar";
import SidebarMenuButton from "./base/sidebar/sidebar_menu_button";
import HelmetPageTitle from "./lib/helmet_page_title";
import { PrivateRouteAtLeastOne, RCRoute } from "./lib/private_route";
import RedirectExternal from "./lib/redirect_route";
import WarningAlert from "./lib/warning_alert";
import ModalRoot from "./modals/modal_root";
import AccountPage from "./pages/account/account_page";
import DowngradeTier from "./pages/account/downgrade_tier";
import { TierPaymentFailed } from "./pages/account/tier_payment_failed";
import { UpdateTierPayment } from "./pages/account/update_tier_payment";
import UpgradeTier, { UpdateTierPage } from "./pages/account/upgrade-tier";
import AdminSignInAsPageWrapper from "./pages/admin/admin_sign_in_as_page_wrapper";
import AdvertiserBrowsePage from "./pages/advertiser_browse/advertiser_browse_page";
import AdvertiserBrowseShowPage from "./pages/advertiser_browse/advertiser_browse_show_page";
import AdvertisingReportingPage from "./pages/advertising_reporting";
import AnalyticsPage from "./pages/analytics";
import BrowsePromotionPage from "./pages/browse_promotion/browse_promotion_page";
import AdvertiserWelcomeModal from "./modals/advertiser_welcome_modal";
import CampaignListPage from "./pages/campaigns/campaign_list_page";
import DealPageWrapper from "./pages/deals/deal_page_wrapper";
import EmailVerificationPageWrapper from "./pages/email_verification/email_verification_page_wrapper";
import ForgotPasswordPageWrapper from "./pages/forgot_password/forgot_password_page_wrapper";
import ForgotPasswordSent from "./pages/forgot_password/forgot_password_sent";
import RecoverPasswordPageWrapper from "./pages/forgot_password/recover_password_page_wrapper";
import MonetizationPageWrapper from "./pages/monetization/monetization_page_wrapper";
import OAuthRedirectPageWrapper from "./pages/monetization/oauth_redirect_page_wrapper";
import PromotionsDashboardWrapper from "./pages/promotions/promotions_dashboard_wrapper";
import PromotionsShowPage from "./pages/promotions/promotions_show_details";
import PromotionCategoryPage from "./pages/promotions/promotion_category";
import SignInPageWrapper from "./pages/sign_in/sign_in_page_wrapper";
import AdvertiserSignUpPage from "./pages/sign_up/advertiser_sign_up";
import SignUpWrapper from "./pages/sign_up/sign_up_page_wrapper";
import SignUpTiered from "./pages/sign_up/sign_up_tiered";
import TeamMemberSignUp from "./pages/sign_up/teamMember_sign_up";
import SponsorShowList from "./pages/sponsor/sponsor_show_list";
import SponsorShowPage from "./pages/sponsor/sponsor_show_page";
import SponsorTransaction from "./pages/sponsor_transactions/sponsor_transaction_page";
import { WaitlistPage } from "./pages/waitlist";
import YoutubeDummyPage from "./pages/youtube/youtube_dummy_page";

import "redcircle-brand-css/main.scss";
import "./app.scss";

// keeps track of logged-in user
export const UserContext = React.createContext({});

export default function App() {
  const location = useLocation();
  const history = useHistory();
  const windowSize = useWindowSize();
  const dispatch = useDispatchTS();

  const app = useSelectorTS((state) => state.app);
  const { isSidebarOpen, hideSidebar } = useSelectorTS((state) => state.app);
  const { message, messageUUID, messageType } = useSelectorTS((state) => state.app);
  const { user, tier } = useSelectorTS((state) => state.user);
  const showState = useSelectorTS((state) => state.shows);

  const roleExists = user && userRoleExists(user);
  const isUserAdmin = userHasRole(user, UserRoles.Admin);

  const shouldHideSidebar = !roleExists || hideSidebar || false;
  const showAdminPanel = isUserAdmin || isStaging() || isDev();
  const currentLocation = !roleExists ? encodeURIComponent(window.location.pathname) : "";

  useEffect(() => {
    if (user) GoogleTagManager.configUser(user);
  }, []);

  useEffect(() => {
    const { width } = windowSize;
    if (width) {
      if (width > MEDIA_QUERY_BREAKPOINT.MD) {
        if (!isSidebarOpen) dispatch(toggleSidebarOpen());
      } else {
        if (isSidebarOpen) dispatch(toggleSidebarOpen());
      }
    }
  }, [windowSize]);

  // Checking for Account tier payment state
  const tierInfoExists = isUUID(tier?.uuid);
  const path = location?.pathname;
  if (tierInfoExists && userHasRole(user, [UserRoles.Creator, UserRoles.Admin])) {
    if (tier?.state === "incomplete" && path !== "/account/update-tier-payment") {
      history.push("/account/update-tier-payment");
    }
    if (tier?.state === "payment_failed" && path !== "/account/tier-payment-failed") {
      history.push("/account/tier-payment-failed");
    }
  }

  // Auto re-routes waitlisted users to waitlist page
  if (
    isUserWaitListRestricted({ user, tier, app, showState }) &&
    location.pathname !== "/waitlist"
  ) {
    history.push("/waitlist");
  }

  // If a user is not signed in but is trying to access a loggedInContent page
  // we ensure they arrive to that page after /sign-in
  const urlParams = parse(window.location.search);
  const { goto, ...rest } = urlParams;
  if (roleExists && !isEmpty(goto)) {
    return <Redirect push to={{ pathname: goto, search: stringify(rest) }} />;
  }

  const loggedOutContent = (
    <Switch>
      <Route
        path="/advertiser-welcome/:email/:advertiserContactToken"
        render={() => [
          <CampaignListPage key={"campaign-page"} />,
          <AdvertiserWelcomeModal key={"ad-welcome-modal"} />,
        ]}
      />
      <Route path="/admin/sign-in-as" component={AdminSignInAsPageWrapper} />
      <Route
        path="/sign-in/creator"
        render={(props) => <SignInPageWrapper {...props} forRole="creator" />}
      />
      <Route
        path="/sign-in/sponsor"
        render={(props) => <SignInPageWrapper {...props} forRole="sponsor" />}
      />
      <Route path="/new-member/:encodedEmail/:inviteToken" component={TeamMemberSignUp} />
      <Route path="/sign-in" component={SignInPageWrapper} />
      <Route path="/forgot-password" component={ForgotPasswordPageWrapper} />
      <Route path="/forgot-password-sent" component={ForgotPasswordSent} />
      <Route
        path="/recover-password/:email/:passwordToken"
        component={RecoverPasswordPageWrapper}
      />
      <Route path="/sign-up/advertiser" component={AdvertiserSignUpPage} />
      <Route path="/sign-up/openrap" component={RemoteSignUpPage} />
      <Route
        path="/sign-up/sponsor"
        render={(props) => <SignUpWrapper {...props} forRole={"sponsor"} />}
      />
      <Route
        path="/sign-up/creator"
        render={(props) => <SignUpWrapper {...props} forRole={"creator"} />}
      />
      {/* Redirecting these routes */}
      <Route
        path="/ad-marketplace/:showUUID"
        render={({ match }) => <Redirect to={`/ad-platform/${match?.params?.showUUID}`} />}
      />
      <Route path="/ad-marketplace" render={() => <Redirect to={`/ad-platform`} />} />

      {/* SPONSOR ROUTES */}
      <Route path="/shows/:showUUID/tip" component={TipPage} />
      <Route path="/shows/:showUUID/tip-success" component={TipSuccess} />
      <Route
        path={[
          "/shows/:showUUID/sponsor",
          "/shows/:showUUID/exclusive-content",
          "/shows/:showUUID/donations",
        ]}>
        <Route
          path={[
            "/shows/:showUUID/exclusive-content/edit",
            "/shows/:showUUID/donations/edit",
            "/shows/:showUUID/sponsor/edit",
          ]}
          render={(props) => [
            <ModalLauncher
              key={"modal-launcher"}
              modalType={SPONSORSHIP_MODAL}
              modalProps={{
                closeRoute: oneLevelUp,
                ...props,
              }}
            />,
          ]}
        />
        <SponsorShowPage />
      </Route>

      <Route
        path="/shows/:showUUID/resubscribe"
        render={(props) => <SponsorShowPage {...props} resubscribe />}
      />

      {!roleExists && (
        <Redirect
          to={{
            pathname: "/sign-in",
            search: `goto=${currentLocation}&${window.location.search.substring(1)}`,
          }}
        />
      )}
    </Switch>
  );

  const loggedInContent = (
    <>
      <Route path="/support">
        <RedirectExternal newPath="https://support.redcircle.com/" />
      </Route>
      <Route path="/oauth-redirect" component={OAuthRedirectPageWrapper} />
      <PrivateRouteAtLeastOne path="/monetization" exact permissionType={permissionTypes.viewMoney}>
        <MonetizationPageWrapper />
      </PrivateRouteAtLeastOne>
      <Route path="/sponsor-transactions" component={SponsorTransaction} />
      <Route path="/sponsor-show-list" component={SponsorShowList} />
      <Route path="/subscriptions/:subscriptionUUID" component={SponsorShowPage} />
      <Switch>
        <Route path="/account/upgrade/:level" component={UpgradeTier} />
        <Route path="/account/update/:level" component={UpdateTierPage} />
        <Route path="/account/downgrade/:level" component={DowngradeTier} />
        <Route path="/account/update-tier-payment" component={UpdateTierPayment} />
        <Route path="/account/tier-payment-failed" component={TierPaymentFailed} />
        <Route path="/account/:tab?" component={AccountPage} />
      </Switch>
      <Route path="/verify-email/:verificationToken" component={EmailVerificationPageWrapper} />
      <Route path="/youtube-oauth" component={YoutubeDummyPage} />
      <Route path="/analytics" component={AnalyticsPage} />
      <Route path="/sign-up-tiers" component={SignUpTiered} />
      <RCRoute requireLogIn={true} roles={[UserRoles.Creator]} path="/waitlist">
        <WaitlistPage />
      </RCRoute>
      <RCRoute path="/reporting" requireLogIn={true} roles={[UserRoles.Advertiser]}>
        <AdvertisingReportingPage />
      </RCRoute>

      <Switch>
        <RCRoute path="/browse" roles={[UserRoles.Advertiser]} exact>
          <AdvertiserBrowsePage />
        </RCRoute>
        <RCRoute
          path="/browse/:showUUID"
          paramKey="showUUID"
          roles={[UserRoles.Creator, UserRoles.Advertiser]}
          permissionType={permissionTypes.browsePodcasts}
          useHasAtLeastOne
          exact>
          <AdvertiserBrowseShowPage />
        </RCRoute>
      </Switch>

      <Route path="/stats/:tab?" component={AnalyticsPage} />
      <Switch>
        <Route path="/promotions/categories/:categoryUUID" component={PromotionCategoryPage} />
        <PrivateRouteAtLeastOne
          path="/promotions/shows/:showUUID"
          exact
          permissionType={permissionTypes.viewCrossPromo}>
          <PromotionsShowPage />
        </PrivateRouteAtLeastOne>
        <PrivateRouteAtLeastOne
          path="/promotions/browse"
          exact
          permissionType={permissionTypes.viewCrossPromo}>
          <BrowsePromotionPage />
        </PrivateRouteAtLeastOne>
        <Route path="/promotions/deals/:dealUUID" component={DealPageWrapper} />
        <PrivateRouteAtLeastOne
          path="/promotions"
          exact
          permissionType={permissionTypes.viewCrossPromo}>
          <PromotionsDashboardWrapper />
        </PrivateRouteAtLeastOne>
      </Switch>

      {/* ROUTE MODULES */}
      <CampaignRoutes />
      <AdPlatformRoutes />
      <ShowsRoutes />
      <DynamicInsertionRoutes />

      <Route exact path="/" component={HomeRedirectWrapper} />
      {loggedOutContent}
    </>
  );

  return (
    <UserContext.Provider value={user}>
      <div className={classNames("main-wrapper", roleExists && "main-wrapper--logged-in")}>
        <HelmetPageTitle />
        <MessageBar
          key={messageUUID}
          message={message}
          messageUUID={messageUUID}
          messageType={messageType}
          onClose={() => dispatch(dismissMessageBar(messageUUID))}
        />
        <Sidebar
          toggleSidebar={() => {
            if (isMobile()) dispatch(toggleSidebarOpen());
          }}
          open={isSidebarOpen}
          hidden={shouldHideSidebar}
        />
        <ModalRoot />
        {showAdminPanel && <AdminPanel />}

        <div
          className={classNames(
            "main-content",
            (!isSidebarOpen || shouldHideSidebar) && "main-content--no-sidebar",
            shouldHideSidebar && "main-content--hidden-sidebar"
          )}>
          <WarningAlert />
          {!shouldHideSidebar && (
            <div className="mobile-top-bar">
              <SidebarMenuButton onClick={() => dispatch(toggleSidebarOpen())} />
            </div>
          )}

          <Switch>
            {/*this is done this way because we want to redirect to update tiers page after signup instead of directly to shows if there are params*/}
            <Route path="/sign-up" exact>
              <SignUpWrapper forRole={UserRoles.Creator} />
            </Route>
            {roleExists ? loggedInContent : loggedOutContent}
          </Switch>
        </div>
      </div>
    </UserContext.Provider>
  );
}
