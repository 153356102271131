import { Base64 } from "js-base64";
import get from "lodash/get";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Clipboard from "react-clipboard.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showSuccess } from "../../actions/app";
import { hideModal } from "../../actions/modal";
import { fetchSubscription } from "../../action_managers/subscriptions";
import { ANDROID, iOS, MAC_OXS_CATALINA_OR_ABOVE } from "../../constants/operating_systems";
import {
  AndroidPodcastPlayers,
  CatalinaPlayers,
  iOSPodcastPlayers,
  OSXPodcastPlayers,
} from "../../constants/podcast_players";
import { getMobileOperatingSystem } from "../../lib/user-agent";
import ExternalLink from "../lib/external_link";
import Spinner from "../lib/spinner";
import BaseModal from "./base_modal";
import "./listen_on_modal/listen_on_modal.scss";

class ListenOnModal extends Component {
  componentDidMount() {
    const subscriptionUUID = this.getSubscriptionUUID();
    if (subscriptionUUID && !this.getSubscription()) {
      this.props.fetchSubscription(subscriptionUUID);
    }
  }

  getSubscription() {
    return get(this.props.subscriptions, this.props.subscriptionUUID);
  }

  getSubscriptionUUID() {
    return this.props.subscriptionUUID;
  }

  getPlayerOptions() {
    const os = getMobileOperatingSystem();
    switch (os) {
      case iOS:
        return iOSPodcastPlayers;
      case ANDROID:
        return AndroidPodcastPlayers;
      case MAC_OXS_CATALINA_OR_ABOVE:
        return CatalinaPlayers;
      default:
        return OSXPodcastPlayers;
    }
  }

  createPlayerButtons(playerOptions) {
    const rssURL = get(this.getSubscription(), "rssURL");
    if (!rssURL) {
      return null;
    }
    return playerOptions.map((option) => {
      let modifiedRSSURL = rssURL;

      if (option.escape) {
        modifiedRSSURL = encodeURIComponent(modifiedRSSURL);
      }
      if (option.stripProtocol) {
        modifiedRSSURL = rssURL.replace(/^(http|https):\/\//, "");
      }
      if (option.b64encode) {
        modifiedRSSURL = Base64.encode(modifiedRSSURL);
      }
      const playerDeepLink = option.uri + modifiedRSSURL;
      return (
        <Col xs={6} s={4} key={option.name}>
          <ExternalLink key={option.name} href={playerDeepLink} className={"listen-link bold"}>
            <div className={"listen-on-button"}>
              <img src={option.icon} alt={`${option.name}-button`} className={"icon"} />
              <div className={"title"}>{option.name}</div>
            </div>
          </ExternalLink>
        </Col>
      );
    });
  }

  notify = () => {
    this.props.showSuccess("RSS Feed copied to clipboard.");
  };
  getRSSFeed = () => {
    return get(this.getSubscription(), "rssURL");
  };
  render() {
    if (!this.getSubscription()) {
      return <Spinner isLoading={true} />;
    }
    const playerButtons = this.createPlayerButtons(this.getPlayerOptions());
    return (
      <BaseModal
        ref={this.getDatRef}
        modalProps={{ enforceFocus: false, autoFocus: false, restoreFocus: false }}
        isLoading={false}
        isMini={true}
        offset={1}
        title={"Thanks for supporting this podcast!"}
        close={this.props.hideModal}
        hideCloseButton={false}
        showContent={true}
        Content={() => (
          <Row>
            <Col mdOffset={1} md={10}>
              <div className={"listen-on-modal"}>
                <p>
                  We've made a special exclusive feed, just for you. If you’ve previously subscribed
                  to this exclusive feed, you can skip this step.
                </p>
                <p className="bold m-yl">
                  To listen to this podcast's exclusive content on your device, select your podcast
                  client below, and subscribe to the feed.
                </p>
                <Row>{playerButtons}</Row>
                <div className={"rss"}>
                  <Clipboard
                    option-container={document.getElementsByClassName("modal")[0]}
                    onSuccess={this.notify}
                    option-text={this.getRSSFeed}
                    className="m-tl btn btn-primary">
                    Copy RSS Feed To Clipboard
                  </Clipboard>
                  <p className={"m-tl"}>You can also subscribe with this link:</p>
                  <b>{get(this.getSubscription(), "rssURL")}</b>
                </div>
              </div>
            </Col>
            <Col xs={10} xsOffset={1}>
              <button
                onClick={this.props.hideModal}
                className="btn btn-primary listen-on-modal__done-button">
                Done
              </button>
            </Col>
          </Row>
        )}
      />
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      return {
        user: state.user.user,
        subscriptions: state.subscriptions.subscriptions,
      };
    },
    {
      showSuccess,
      fetchSubscription,
      hideModal,
    }
  )(ListenOnModal)
);
