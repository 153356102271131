import getFP from "lodash/fp/get";
import pickFP from "lodash/fp/pick";
import pipe from "lodash/fp/pipe";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import range from "lodash/range";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { classNames, If } from "react-extras";

import "./form-steps.scss";

export class FormStep extends Component {
  render() {
    const { step, currentStep, children } = this.props;

    let isVisible = false;
    if (Array.isArray(step)) {
      isVisible = step.includes(currentStep);
    } else {
      isVisible = step === currentStep;
    }

    return (
      <div className={classNames("form-step", "width-100", !isVisible && "form-step--invisible")}>
        {children}
      </div>
    );
  }
}

// Same as MultiStepWithNavContent but using the FormStep component instead
export const FormStepWithNav = (props) => {
  const { children, setStep, currentStep, disableClickNav = false, hideMenuXS = false } = props;
  const navLinks = groupBy(
    React.Children.map(
      children,
      pipe(getFP("props"), pickFP(["step", "navName", "parent"]))
    ).filter(getFP("navName")),
    "parent"
  );

  return (
    <div className="width-100">
      <Grid fluid className="multistep-nav">
        <Row>
          <Col xsHidden={hideMenuXS} xs={12} sm={3} lg={2}>
            <div className={"m-bl"}>
              {Object.entries(navLinks).map(([parent, children]) => {
                return (
                  <div key={parent}>
                    <If condition={parent !== "undefined"}>
                      <div className={"parent-label"}>{parent}</div>
                    </If>
                    {children.map((child) => {
                      if (!child) {
                        return null;
                      }
                      const step = get(child, ["step"]);
                      const navName = get(child, ["navName"]);
                      return (
                        <div
                          key={step}
                          className={classNames(
                            "multistep-nav-item",
                            currentStep === step && "active",
                            currentStep !== step &&
                              disableClickNav &&
                              "cursor-disabled inactive-dim"
                          )}
                          onClick={() => {
                            if (!disableClickNav) {
                              setStep(step);
                            }
                          }}>
                          {navName}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </Col>
          <Col xs={12} sm={9} lgOffset={1} lg={8}>
            {React.Children.map(children, (child) => {
              if (!child) {
                return null;
              }
              return React.cloneElement(child);
            })}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export class FormStepDots extends Component {
  getStyle = (index, total) => {
    switch (index) {
      case total:
        return { right: "0px" };
      case 1:
        return { left: "0px" };
      default:
        return { left: `calc(${Math.round(((index - 1) / (total - 1)) * 100)}% - 10px)` };
    }
  };
  render() {
    let { numSteps, currentStep } = this.props;
    let stepNumbers = range(1, numSteps + 1);

    return (
      <div className="form-step-dots">
        <div className="form-step-dots__line" />
        {stepNumbers.map((index) => (
          <div
            className={classNames(
              "form-step-dots__dot",
              currentStep === index && "form-step-dots__dot--selected"
            )}
            style={this.getStyle(index, numSteps)}
            key={index}
          />
        ))}
      </div>
    );
  }
}
