import DefaultActionManager from "./default_action_manager";

export const CREATE_EPISODE_WAVEFORM = "CREATE_EPISODE_WAVEFORM";

export const createEpisodeWaveform = (episodeUUID) => {
  return new DefaultActionManager({
    auth: true,
    actionName: CREATE_EPISODE_WAVEFORM,
    route: `episodes/${episodeUUID}/enqueue-waveform`,
    method: "post",
    silent: true,
  }).run();
};
