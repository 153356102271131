import Forward15 from "@iconify/icons-mdi/fast-forward-15";
import MagnifyPlus from "@iconify/icons-mdi/magnify-add-outline";
import MagnifyMinus from "@iconify/icons-mdi/magnify-minus-outline";
import PauseIcon from "@iconify/icons-mdi/pause";
import PlayIcon from "@iconify/icons-mdi/play";
import Rewind15 from "@iconify/icons-mdi/rewind-15";
import { Icon } from "@iconify/react";
import { Empty, Spin } from "antd";
import { clamp } from "lodash";
import numeral from "numeral";
import Peaks from "peaks.js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { transformTimeNumToString } from "src/lib/duration";
import TimeFormatter from "../time_formatter";
import CustomPointMarkerFunc, { RedCircleHandlers } from "./CustomPointMarker";
import classes from "./waveform.module.scss";

//Helper

/**
 * Issue with component drawing post roll at the every end of waveform, need
 * to draw post rolla few milliseconds before end to be captured by the
 * internal update function
 *
 */
const softCelingAudioDuration = (durationInSeconds: number) => {
  const minDiffInMilliseconds = 30;
  const durationInMilliseconds = durationInSeconds * 1000;

  return (durationInMilliseconds - minDiffInMilliseconds) / 1000;
};

const createPeaksConfig: (props: {
  zoomContainer: React.MutableRefObject<HTMLDivElement | null>;
  overViewContainer: React.MutableRefObject<HTMLDivElement | null>;
  audioContainer: React.MutableRefObject<HTMLAudioElement | null>;
  datFile: string;
}) => Peaks.PeaksOptions = (props) => {
  const { zoomContainer, overViewContainer, audioContainer, datFile } = props;

  const options: Peaks.PeaksOptions = {
    zoomview: {
      container: zoomContainer?.current as HTMLDivElement,
      wheelMode: "scroll",
      waveformColor: "#939B9F", //"rgba(1,8,49,.5)",
      playedWaveformColor: "#010831",
      timeLabelPrecision: 3,
    },
    overview: {
      container: overViewContainer?.current as HTMLDivElement,
      highlightColor: "rgba(190,224,241,1)",
      highlightOffset: 1,
      waveformColor: "#939B9F",
      playedWaveformColor: "#010831",
      timeLabelPrecision: 3,
    },

    mediaElement: audioContainer?.current as HTMLAudioElement,
    dataUri: {
      arraybuffer: datFile,
    },
    waveformCache: true,

    // PlayHead
    playheadColor: "#000000",
    showPlayheadTime: false,
    formatAxisTime: formatAxisTime,
    timeLabelPrecision: 3,

    //Segments

    createPointMarker: CustomPointMarkerFunc,

    segmentColor: "rgba(255, 161, 39, .5)",
    // create

    // Fonts
    fontSize: 11,
    fontFamily: "Gilroy-bold",
  };

  return options;
};

export type WaveFormMarker = {
  id: string;
  time: number;
  labelText?: string;
  editable?: boolean;
  color?: string;
  zIndex?: number;
  [key: string]: unknown;
};

export type WaveFormProps = {
  datFile?: string;
  convertedURL?: string;
  markers: WaveFormMarker[];
  handleMarkerTimeUpdate: (markerID: string, newTimeInSeconds: number) => void;
  handleMarkerDelete: (markerID: string) => void;
  onPlayHeadTimeUpdate: (newTime: number) => void;
  onReady?: (PeaksInstance: Peaks.PeaksInstance | null, durationInSeconds: number) => void;
  isLoading?: boolean;
  loadingMessage?: string;
};

const WaveForm = ({
  datFile,
  convertedURL,
  markers,
  handleMarkerTimeUpdate,
  handleMarkerDelete,
  onPlayHeadTimeUpdate,
  onReady,
  isLoading = false,
  loadingMessage = "",
}: WaveFormProps) => {
  const zoomContainer = useRef<HTMLDivElement | null>(null);
  const overViewContainer = useRef<HTMLDivElement | null>(null);
  const audioContainer = useRef<HTMLAudioElement | null>(null);
  const componentContainer = useRef<HTMLDivElement | null>(null);

  const PeaksInstance = useRef<(Peaks.PeaksInstance & RedCircleHandlers) | null>(null);

  /** Controls State */
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);

  const initialization = (peaks: Peaks.PeaksInstance) => {
    PeaksInstance.current = peaks as Peaks.PeaksInstance & RedCircleHandlers;

    const zoomView = PeaksInstance.current?.views?.getView("zoomview");
    const overView = PeaksInstance.current?.views?.getView("overview");
    const duration = PeaksInstance.current?.player.getDuration() || 0;

    const zoomSeconds = Math.floor(duration * 0.2);
    // @ts-expect-error using exposed zoomview layer api
    const timeScale = zoomView?._getScale(zoomSeconds);
    // Zoomview config
    zoomView?.enableSeek(true);
    zoomView?.setZoom({
      scale: Math.max(timeScale, MIN_ZOOM_SCALE),
    });
    zoomView?.setAmplitudeScale(0.4);
    zoomView?.setWheelMode("scroll");
    zoomView?.enableAutoScroll(false);

    // Overview Config

    overView?.enableSeek(true);
    overView?.setAmplitudeScale(0.4);
    overView?.enableMarkerEditing(true);

    setAudioDuration(softCelingAudioDuration(duration));
    setIsReady(true);
  };

  const onPeaksInitFunc: Peaks.PeaksInitCallback = (err, peaks) => {
    if (err) {
      console.log(err);
    } else {
      if (peaks) {
        initialization(peaks);
      }
    }
  };

  // Constructor: intialize Peaks instance
  useEffect(() => {
    if (datFile && !isReady) {
      const options = createPeaksConfig({
        zoomContainer,
        overViewContainer,
        audioContainer,
        datFile,
      });

      if (PeaksInstance.current) {
        PeaksInstance.current?.destroy();
      }

      PeaksInstance.current = Peaks.init(options, onPeaksInitFunc) as Peaks.PeaksInstance &
        RedCircleHandlers;
    }
  }, [datFile, isReady]);

  // On Source Change
  useEffect(() => {
    if (isReady) {
      PeaksInstance.current?.setSource?.(
        {
          mediaUrl: convertedURL,
          dataUri: {
            arraybuffer: datFile,
          },
        },
        (err) => {
          initialization(PeaksInstance.current as Peaks.PeaksInstance);
        }
      );
    }
  }, [datFile, convertedURL, isReady]);

  // Marker Effect
  useEffect(() => {
    if (isReady) {
      markers
        .sort((a, b) =>
          typeof a.zIndex == "number" && typeof b.zIndex === "number" ? a.zIndex - b.zIndex : 0
        )
        .forEach((marker) => {
          PeaksInstance.current?.points.add({
            ...marker,
          });
        });
    }
    return () => {
      isReady && PeaksInstance?.current?.points?.removeAll?.();
    };
  }, [isReady, markers]);

  // Audio play event handlers
  useEffect(() => {
    /** Peals Instance Event handlers */
    const handleInstancePlaying = () => setIsPlaying(true);
    const handleInstancePause = () => setIsPlaying(false);

    const handleInstanceSeeked = (newSeekedTime: number) => {
      const zoomView = PeaksInstance.current?.views?.getView("zoomview");

      // @ts-expect-error using exposed zoomview layer api
      const startTime = zoomView.getStartTime();
      // @ts-expect-error using exposed zoomview layer api
      const endTime = Math.ceil(zoomView.getEndTime());

      if (newSeekedTime > endTime || newSeekedTime < startTime) {
        const newStartTime = Math.max(newSeekedTime - Math.round((endTime - startTime) / 2), 0);
        zoomView?.setStartTime(newStartTime);
      }
    };

    if (PeaksInstance.current) {
      // Adding all instance event handlers
      // @ts-expect-error "player.playing" event is incorrectly typed in library
      PeaksInstance.current?.on("player.playing", handleInstancePlaying);
      PeaksInstance.current?.on("player.pause", handleInstancePause);
      PeaksInstance.current?.on("player.seeked", handleInstanceSeeked);
    }

    return () => {
      // Taking off all event handlers
      // @ts-expect-error "player.playing" event is incorrectly typed in library
      PeaksInstance.current?.off("player.playing", handleInstancePlaying);
      PeaksInstance.current?.off("player.pause", handleInstancePause);
      PeaksInstance.current?.off("player.seeked", handleInstanceSeeked);
    };
  }, [isReady, setIsPlaying]);

  // Add Marker Drag event handlers
  useEffect(() => {
    const handleMarkerDragEnd = (event: any) => {
      const point: {
        _id: string;
        _time: number;
        _labelText?: string;
        _editable?: boolean;
        _color?: string;
      } = event?.point;

      const percentage = (point._time / audioDuration) * 100;

      let newTimeInMilliseconds = point._time * 1000;

      if (event?.evet?.target?.tagName !== "CANVAS" && percentage >= 99.7) {
        newTimeInMilliseconds = audioDuration * 1000;
      }

      handleMarkerTimeUpdate?.(point._id, newTimeInMilliseconds);
    };

    if (PeaksInstance.current) {
      PeaksInstance.current?.on("points.dragend", handleMarkerDragEnd);
    }

    return () => {
      if (PeaksInstance.current) {
        PeaksInstance.current?.off("points.dragend", handleMarkerDragEnd);
      }
    };
  }, [isReady, audioDuration, handleMarkerTimeUpdate]);

  // Add Marker onTime Update
  useEffect(() => {
    const handleInstanceTimeUpdate = (timeInSeconds: number) => {
      onPlayHeadTimeUpdate(timeInSeconds);
      setCurrentTime(timeInSeconds);
    };

    if (PeaksInstance.current) {
      PeaksInstance.current?.on("player.timeupdate", handleInstanceTimeUpdate);
    }

    return () => {
      if (PeaksInstance.current) {
        PeaksInstance.current?.off("player.timeupdate", handleInstanceTimeUpdate);
      }
    };
  }, [isReady, onPlayHeadTimeUpdate]);

  // Pass down handlers to Peak instance for Marker api usage
  useEffect(() => {
    if (isReady) {
      if (PeaksInstance.current) {
        PeaksInstance.current.RedCircleHandlers = {
          handleMarkerDelete,
        };
      }
    }
  }, [isReady, handleMarkerDelete]);

  // Add Zoom wheel Events to Zoom overview
  useEffect(() => {
    const handleZoomViewWheelScroll = (event: WheelEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const zoomview = PeaksInstance.current?.views?.getView("zoomview");
      if (!zoomview) return;

      const player = PeaksInstance.current?.player;
      const minScaleClamp = MIN_ZOOM_SCALE;
      // @ts-expect-error using exposed zoomview layer api
      const maxScaleClamp = zoomview?._getScale(player.getDuration());

      if (event.shiftKey) {
        zoomview.setStartTime(
          // @ts-expect-error using exposed zoomview layer api
          zoomview.pixelsToTime(zoomview._frameOffset) + event.deltaX * (maxScaleClamp / 80000)
        );
      } else {
        zoomview.setZoom({
          scale: clamp(
            // @ts-expect-error using exposed zoomview layer api
            Math.round((zoomview._scale + event.deltaY * (maxScaleClamp / 2000)) / 256) * 256,
            minScaleClamp,
            maxScaleClamp
          ),
        });
      }
    };

    zoomContainer?.current?.addEventListener("wheel", handleZoomViewWheelScroll);

    return () => {
      zoomContainer?.current?.removeEventListener("wheel", handleZoomViewWheelScroll);
    };
  }, [isReady]);

  // Add keyboard controls - i.e space bar
  useEffect(() => {
    const handleEvent = (e: KeyboardEvent) => {
      const target = e.target as any;
      if (e.key === " " && e.code === "Space" && target?.id !== "zoom_play_time_formatter") {
        // @ts-expect-error using exposed player API isPlaying()
        PeaksInstance?.current?.player.isPlaying()
          ? PeaksInstance?.current?.player.pause()
          : PeaksInstance?.current?.player.play();
      }
    };

    componentContainer?.current?.addEventListener("keyup", handleEvent);

    return () => {
      componentContainer?.current?.removeEventListener("keyup", handleEvent);
    };
  }, [isReady]);

  // Sync onReady func
  useEffect(() => {
    if (isReady) {
      onReady?.(PeaksInstance?.current, audioDuration);
    }
  }, [isReady, onReady, audioDuration]);

  // Destructor: release resources
  useEffect(() => {
    return () => {
      if (PeaksInstance?.current) {
        //Remove markers
        PeaksInstance.current?.points?.removeAll();

        // Removing Segment
        PeaksInstance.current?.segments?.removeAll();

        //Destroying instance
        PeaksInstance.current?.destroy();
        isReady && setIsReady(false);
      }
    };
  }, []);

  // Fixes issue with overview container going out of bounds of aprent due to delayed resize func initialization
  if (overViewContainer?.current && PeaksInstance?.current && isReady) {
    const overView = PeaksInstance.current?.views?.getView("overview");
    // @ts-expect-error using exposed overview layer api not typed
    const overviewWidwth = overView?.getWidth?.();
    if (overviewWidwth !== overViewContainer?.current?.clientWidth) {
      overView?.fitToContainer();
    }
  }

  /**
   * Helper Functions
   */

  const hanldeRewind = useCallback((rewindSeconds: number) => {
    const currentTime = PeaksInstance?.current?.player.getCurrentTime() || 0;
    const newTime = Math.max(currentTime - rewindSeconds, 0);
    PeaksInstance?.current?.player.seek(newTime);
  }, []);

  const handleForward = useCallback(
    (forwardSeconds: number) => {
      const totalDuration = audioDuration;
      const currentTime = PeaksInstance?.current?.player.getCurrentTime() || 0;
      const newTime = Math.min(currentTime + forwardSeconds, totalDuration);
      PeaksInstance?.current?.player.seek(newTime);
    },
    [audioDuration]
  );

  /**
   * Component Event Handlers
   */

  const handlePlay = useCallback(() => {
    // @ts-expect-error using exposed player API isPlaying()
    PeaksInstance?.current?.player.isPlaying() || PeaksInstance?.current?.player.play();
  }, []);

  const handlePause = useCallback(() => {
    // @ts-expect-error using exposed player API isPlaying()
    PeaksInstance?.current?.player.isPlaying() && PeaksInstance?.current?.player.pause();
  }, []);
  const handleRewind15 = useCallback(() => hanldeRewind(15), [hanldeRewind]);
  const handleforward15 = useCallback(() => handleForward(15), [handleForward]);
  const handleZoomIn = useCallback(() => {
    const zoomview = PeaksInstance.current?.views?.getView("zoomview");
    if (!zoomview) return;

    const player = PeaksInstance.current?.player;
    const minScaleClamp = MIN_ZOOM_SCALE;
    // @ts-expect-error using exposed zoomview layer api
    const maxScaleClamp = zoomview?._getScale(player.getDuration());
    const percent10 = maxScaleClamp / 10;

    zoomview.setZoom({
      // @ts-expect-error using exposed zoomview layer api
      scale: clamp(zoomview._scale - percent10, minScaleClamp, maxScaleClamp),
    });
  }, []);

  const handleZoomOut = useCallback(() => {
    const zoomview = PeaksInstance.current?.views?.getView("zoomview");
    if (!zoomview) return;

    const player = PeaksInstance.current?.player;
    const minScaleClamp = MIN_ZOOM_SCALE;
    // @ts-expect-error using exposed zoomview layer api
    const maxScaleClamp = zoomview?._getScale(player.getDuration());
    const percent10 = maxScaleClamp / 10;

    zoomview.setZoom({
      // @ts-expect-error using exposed zoomview layer api
      scale: clamp(zoomview._scale + percent10, minScaleClamp, maxScaleClamp),
    });
  }, []);

  let message = "";
  if (!isReady) {
    message = "Loading Audio Waveform";
  } else if (isLoading) {
    message = loadingMessage;
  }

  if (!datFile && !convertedURL) {
    return <Empty description="No episode audio detected" />;
  }

  return (
    <Spin spinning={!isReady || isLoading} size="large" tip={message}>
      <div
        ref={componentContainer}
        data-testid="waveform-container"
        className={`width-100 ${isReady ? "" : "semi-transparent"}`}
        tabIndex={-1}>
        <div ref={overViewContainer} className={classes.overview} tabIndex={0}></div>
        <div className={classes.zoomview}>
          {isReady && (
            <div className={classes.zoomview_controls}>
              <span
                className="flex-row-container justify-center align-center"
                onClick={handleRewind15}>
                <Icon
                  icon={Rewind15}
                  color="#EA404D"
                  className={classes.zoomview_controls__icon}
                  height={25}
                />
              </span>

              {isPlaying ? (
                <span
                  className="flex-row-container justify-center align-center"
                  onClick={handlePause}>
                  <Icon
                    icon={PauseIcon}
                    color="#EA404D"
                    className={classes.zoomview_controls__icon}
                    height={22}
                  />
                </span>
              ) : (
                <span
                  className="flex-row-container justify-center align-center"
                  onClick={handlePlay}>
                  <Icon
                    icon={PlayIcon}
                    color="#EA404D"
                    className={classes.zoomview_controls__icon}
                    height={22}
                  />
                </span>
              )}
              <span
                className="flex-row-container justify-center align-center"
                onClick={handleforward15}>
                <Icon
                  icon={Forward15}
                  color="#EA404D"
                  className={classes.zoomview_controls__icon}
                  height={22}
                />
              </span>

              <TimeFormatter
                id="zoom_play_time_formatter"
                timeInMilliseconds={currentTime * 1000}
                max={Math.floor(audioDuration * 1000)}
                onClick={() => handlePause()}
                onKeyDown={(event) => {
                  if (event.key === " ") {
                    // @ts-expect-error using exposed player API isPlaying()
                    PeaksInstance?.current?.player.isPlaying()
                      ? PeaksInstance?.current?.player.pause()
                      : PeaksInstance?.current?.player.play();
                  } else {
                    handlePause();
                  }
                }}
                onManualInputUpdate={({ isCorrectFormat, timeInMilliseconds }) => {
                  if (isCorrectFormat && typeof timeInMilliseconds === "number" && !isPlaying) {
                    PeaksInstance?.current?.player.seek(timeInMilliseconds / 1000);
                  }
                }}
              />

              <div className={`fs-xs ${classes.zoomview_controls__duration}`}>
                {transformTimeNumToString(Math.floor(audioDuration * 1000))}
              </div>
              <div className={`flex-row-container justify-center align-center m-la`}>
                <span
                  className="flex-row-container justify-center align-center"
                  onClick={handleZoomOut}>
                  <Icon
                    icon={MagnifyMinus}
                    color="#000000"
                    className={classes.zoomview_controls__icon}
                    height={22}
                  />
                </span>
                <span
                  className="flex-row-container justify-center align-center"
                  onClick={handleZoomIn}>
                  <Icon
                    icon={MagnifyPlus}
                    color="#000000"
                    className={classes.zoomview_controls__icon}
                    height={22}
                  />
                </span>
              </div>
            </div>
          )}
          <div ref={zoomContainer} className={classes.zoomview_waveform}></div>
        </div>

        <audio ref={audioContainer}>
          <source src={convertedURL} type="audio/mp3" />
        </audio>
      </div>
    </Spin>
  );
};

const MIN_ZOOM_SCALE = 256;

const formatAxisTime = (seconds: number) => numeral(seconds).format("00:00:00");

const areWaveFormPropsEqual = (prevProps: WaveFormProps, nextProps: WaveFormProps) => {
  const convertedURLIsEqual = prevProps.convertedURL === nextProps.convertedURL;
  const datFileIsEqual = prevProps.datFile === nextProps.datFile;
  const isHandleUpdateEqual = prevProps.handleMarkerTimeUpdate === nextProps.handleMarkerTimeUpdate;
  const isHandleMarkerDeleteEqual = prevProps.handleMarkerDelete === nextProps.handleMarkerDelete;
  const isHandleOnPlayHeadTimeUpdateEqual =
    prevProps.onPlayHeadTimeUpdate === nextProps.onPlayHeadTimeUpdate;
  const isLoadingFlagEqual = prevProps.isLoading === nextProps.isLoading;
  const isLoadingMessageEqual = prevProps.loadingMessage === nextProps.loadingMessage;
  const isOnReadyEqual = prevProps.onReady === nextProps.onReady;

  if (
    !convertedURLIsEqual ||
    !datFileIsEqual ||
    !isHandleUpdateEqual ||
    !isHandleMarkerDeleteEqual ||
    !isHandleOnPlayHeadTimeUpdateEqual ||
    !isLoadingFlagEqual ||
    !isLoadingMessageEqual ||
    !isOnReadyEqual
  )
    return false;

  /** Helper Funcs */

  const areMarkersEqual = (prevMarker: WaveFormMarker, nextMarker: WaveFormMarker) => {
    const keys: (keyof WaveFormMarker)[] = [
      "id",
      "editable",
      "color",
      "labelText",
      "time",
      "zIndex",
    ];

    return keys.every((key) => {
      switch (key) {
        case "id":
        case "editable":
        case "labelText":
        case "color":
        case "time":
        case "zIndex":
          return prevMarker[key] === nextMarker[key];
          break;

        default:
          return true;
      }
    });
  };

  const sortMarker = (a: WaveFormProps["markers"][number], b: WaveFormProps["markers"][number]) =>
    a.id?.localeCompare(b.id);

  const sortedPrevMarkers = prevProps.markers?.sort(sortMarker);
  const sortedNextMarkers = nextProps.markers?.sort(sortMarker);
  const indeces = Array.from({ length: sortedNextMarkers.length }, (item, index) => index);

  /**
   * The Wave form will rerender when a change in markers is detected
   */

  const markersAreEqual =
    prevProps.markers?.length === nextProps.markers?.length &&
    indeces.every((index) => {
      return areMarkersEqual(sortedPrevMarkers[index], sortedNextMarkers[index]);
    });

  return markersAreEqual;
};

const MemoizedWaveForm = React.memo(WaveForm, areWaveFormPropsEqual);

export default MemoizedWaveForm;
