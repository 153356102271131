import get from "lodash/get";
import keyBy from "lodash/keyBy";
import { AnyAction } from "redux";
import {
  MARKER_ADD,
  MARKER_ADDED_TO_WAVEFORM,
  MARKER_EDIT_DELETE,
  MARKER_EDIT_OFFSET_AUDIOBLOCK,
  MARKER_RESET_STATE,
} from "../../actions/marker";
import {
  FETCH_RECENT_MARKERS,
  GET_MARKERS_BY_EPISODE,
  GET_MARKERS_PAGE,
  MARKERS_UPDATE,
} from "../../action_managers/audio_management";
import { GET_MARKERS_BY_SHOW_UUID } from "../../action_managers/markers";
import { httpMultipleActionsReducer, httpReducer, reduceReducers } from "../../lib/create-action";
import { newUUID } from "../../lib/uuid";
import { MarkersReduxState } from "./types";

const markersInitialState: MarkersReduxState = {
  isLoading: false,
  markers: {},
  markersByEpisodeUUID: {},
  recentMarkerUUIDs: [],
  markersByShowUUID: {},
  markersPageUUIDs: [],
};

const getMarkersByShow = httpReducer(GET_MARKERS_BY_SHOW_UUID, markersInitialState, {
  success: (state = markersInitialState, action) => {
    return {
      ...state,
      markersByShowUUID: {
        ...state?.markersByShowUUID,
        [action.data.showUUID]: action.payload.resp.map(({ uuid }: { uuid: string }) => uuid),
      },
    };
  },
});

const getUserMarkersPage = httpReducer(GET_MARKERS_PAGE, markersInitialState, {
  success: (state = markersInitialState, action) => {
    return {
      ...state,
      markersPageUUIDs: action.payload.resp.map(({ uuid }: { uuid: string }) => uuid),
    };
  },
});

const getMarkersByEpisode = httpReducer(GET_MARKERS_BY_EPISODE, markersInitialState, {
  success: (state = markersInitialState, action) => {
    return {
      ...state,
      markersByEpisodeUUID: {
        ...state?.markersByEpisodeUUID,
        [action.data.episodeUUID]: action.payload.resp.map(({ uuid }: any) => uuid),
      },
    };
  },
});

const getRecentMarkers = httpReducer(FETCH_RECENT_MARKERS, markersInitialState, {
  success: (state = markersInitialState, action) => {
    return { ...state, recentMarkerUUIDs: action.payload.resp.map((marker: any) => marker.uuid) };
  },
});

const getMarkers = httpMultipleActionsReducer(
  [
    FETCH_RECENT_MARKERS,
    GET_MARKERS_BY_SHOW_UUID,
    GET_MARKERS_BY_EPISODE,
    MARKERS_UPDATE,
    GET_MARKERS_PAGE,
  ],
  markersInitialState,
  {
    success: (state = markersInitialState, action) => {
      const newState = keyBy(action.payload.resp, "uuid");
      return { ...state, markers: { ...state?.markers, ...newState } };
    },
  }
);

const editMarkerOffsetMs = (state = markersInitialState, action: AnyAction) => {
  switch (action.type) {
    case MARKER_EDIT_OFFSET_AUDIOBLOCK: {
      const markerToUpdate = Object.assign({} as any, get(state.markers, action.markerUUID, {}));
      markerToUpdate.offsetMilliSeconds = action.offsetMs;
      markerToUpdate.audioBlockUUID = action.audioBlockUUID;

      return {
        ...state,
        markers: {
          ...state.markers,
          [action.markerUUID]: markerToUpdate,
        },
      };
    }
    case MARKER_EDIT_DELETE: {
      const markerToDelete = state.markers[action.markerUUID];
      markerToDelete.delete = true;

      return {
        ...state,
        markers: {
          ...state.markers,
          [action.markerUUID]: markerToDelete,
        },
      };
    }
    default:
      return state;
  }
};

const addMarker = (state = markersInitialState, action: AnyAction) => {
  switch (action.type) {
    case MARKER_ADD: {
      const newMarkerUUID: string = newUUID();
      const newEpisodeMarkers = get(
        state.markersByEpisodeUUID,
        action.episodeUUID,
        [] as string[]
      ).concat([newMarkerUUID as string]);
      return {
        ...state,
        markers: {
          ...state.markers,
          [newMarkerUUID]: {
            uuid: newMarkerUUID,
            userUUID: action.userUUID,
            episodeUUID: action.episodeUUID,
            offsetMilliSeconds: action.offsetMs,
            // isNewMarker is only used on the frontend to determine which markers to create
            isNewMarker: true,
            // bulkAdd is only used ont he frontend to designate that we're adding multiple at once.
            bulkAdd: action.bulkAdd,
            audioBlockUUID: action.audioBlockUUID,
          },
        },
        markersByEpisodeUUID: {
          ...state.markersByEpisodeUUID,
          [action.episodeUUID]: newEpisodeMarkers,
        },
      } as MarkersReduxState;
    }
    default:
      return state;
  }
};

const resetMarkerState = (state = markersInitialState, action: AnyAction) => {
  switch (action.type) {
    case MARKER_RESET_STATE: {
      return markersInitialState;
    }
    default:
      return state;
  }
};

const newMarkerAddedToWaveform = (state = markersInitialState, action: AnyAction) => {
  switch (action.type) {
    case MARKER_ADDED_TO_WAVEFORM: {
      const marker = get(state.markers, action.markerUUID);
      marker.addedToWaveform = true;
      return {
        ...state,
        markers: {
          ...state.markers,
          [marker.uuid]: marker,
        },
      };
    }
    default:
      return state;
  }
};

export default reduceReducers(
  getMarkersByShow,
  getRecentMarkers,
  getMarkers,
  getMarkersByEpisode,
  editMarkerOffsetMs,
  addMarker,
  resetMarkerState,
  getUserMarkersPage,
  newMarkerAddedToWaveform
);
