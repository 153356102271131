import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchPublicShowForSubscriber } from "../../../action_managers/public_show";
import { getTransactionsForUser } from "../../../action_managers/transactions";
import TransactionTable from "../../lib/transaction_table";
import "./sponsorship_transaction_page.scss";

class SponsorTransaction extends Component {
  componentDidMount() {
    this.props.getTransactionsForUser().then((response) => {
      if (response.status === 200) {
        const showUUIDs = response.json.reduce((agg, transaction) => {
          if (transaction.showUUID) {
            agg.add(transaction.showUUID);
          }
          return agg;
        }, new Set());
        if (showUUIDs.size) {
          showUUIDs.forEach((showUUID) => {
            this.props.fetchPublicShowForSubscriber(showUUID);
          });
        }
      }
    });
  }

  tipTransactions = () => {
    return this.props.transactions.userTransactions.filter(
      (transaction) => transaction.type === "TipCollection"
    );
  };

  render() {
    return (
      <div className={"sponsor-transaction"}>
        <Grid fluid>
          <Row>
            <Col xs={12} md={8} mdOffset={2}>
              <h1>Payment History</h1>
              <TransactionTable
                shows={this.props.shows}
                transactions={this.tipTransactions()}
                noDataText={"You don't have any payments yet!"}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { transactions: state.transactions, shows: state.publicShows };
}

export default connect(mapStateToProps, {
  getTransactionsForUser,
  fetchPublicShowForSubscriber,
})(SponsorTransaction);
