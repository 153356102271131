import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { hideModal, showModal } from "src/actions/modal";
import { ModalType } from "src/components/modals/modal_root";
import { oneLevelUp } from "../routing";

interface IModalLauncherProps {
  modalType: ModalType;
  modalProps?: any;
}

export default function ModalLauncher({ modalType, modalProps }: IModalLauncherProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const params = match.params;

  // ModalLauncher provides some default props (and router context) to children
  const combinedModalProps = {
    closeRoute: () => history.push(oneLevelUp(location)),
    closeModal: () => dispatch(hideModal()),
    // onClose by default will navigate one level up, override this if you want to do something else
    onClose: () => {
      dispatch(hideModal());
      history.push(oneLevelUp(location));
    },
    location,
    history,
    match,
    params,
    ...modalProps,
  };

  // on mount, fire modal launch
  useEffect(() => {
    dispatch(showModal(modalType, combinedModalProps));
    return () => {
      dispatch(hideModal());
    };
  }, [location]);
  // we only reload modal on path changes within modals
  // this will allow you to launch a new modal from a modal without changing the route

  return null;
}
