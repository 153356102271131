import { VettingQuestionType } from "src/reducers/vetting/types";

export const VETTING_QUESTION_TYPES: Record<
  "YES_NO" | "SHORT_TEXT" | "LONG_TEXT" | "MULTIPLE_CHOICE",
  VettingQuestionType
> = {
  YES_NO: "yes_no",
  SHORT_TEXT: "short_text",
  LONG_TEXT: "long_text",
  MULTIPLE_CHOICE: "multiple_choice",
};

export const MULTIPLE_CHOICE_TYPE_RADIO = "radio";
export const MULTIPLE_CHOICE_TYPE_CHECKBOX = "checkbox";

export const OTHER_OPTION_VALUE = "other_option_value";
