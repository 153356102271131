import { Form, InputNumber, Popover, Tooltip } from "antd";
import numeral from "numeral";
import { ForwardedRef, forwardRef, useContext, useEffect, useRef, useState } from "react";
import { AiFillExclamationCircle, AiFillWarning } from "react-icons/ai";
import { Button, COLORS, Modal } from "redcircle-ui";
import {
  getAverageCPM,
  getDailyImpressions,
  getImpressionsFromBudget,
  getImpressionsWOBudget,
} from "src/lib/campaigns";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "redcircle-types";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";
import { getCampaignItemField } from "src/lib/campaign_item";
import { IconContext } from "react-icons";

export default function SchedulerDownloadsCell({
  campaignItem,
  show,
}: {
  campaignItem: ICampaignItem;
  show: IShow;
}) {
  const [showPopover, setShowPopover] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // focus on input
  useEffect(() => {
    if (showPopover) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [showPopover]);

  const { budgets, setBudgets, campaign, excludedCampaignItems, timeline, maxImpressionsState } =
    useContext(CampaignSchedulerContext);
  const isExcluded =
    campaignItem && excludedCampaignItems.map((item) => item.uuid).includes(campaignItem.uuid);

  const cpm = getAverageCPM({ show, campaign, campaignItem });

  const impressions =
    campaignItem && typeof budgets?.[campaignItem.uuid] === "number"
      ? getImpressionsFromBudget({ cpm, budget: budgets[campaignItem.uuid] })
      : 0;

  const max = maxImpressionsState.maxImpressions[campaignItem.showUUID]?.impressions;
  const showMaxLimit =
    getCampaignItemField("pacing", { campaign, campaignItem }) &&
    typeof max === "number" &&
    max >= 0;

  const handleSubmit = (value: number) => {
    const newBudget = (value / 1000) * cpm;
    setBudgets({ ...budgets, [campaignItem.uuid]: newBudget });
  };

  return (
    <Popover
      open={showPopover}
      content={
        <PopoverContent
          ref={inputRef}
          downloads={impressions}
          onClose={() => setShowPopover(false)}
          onSubmit={handleSubmit}
          max={showMaxLimit ? max : undefined}
        />
      }
      trigger="click"
      onOpenChange={(open) => setShowPopover(open)}
      overlayInnerStyle={{ padding: 0 }}>
      <Button type="secondary" size="small" disabled={isExcluded} style={{ minWidth: "90px" }}>
        {numeral(impressions).format("0,0")}
      </Button>
    </Popover>
  );
}

const PopoverContent = forwardRef(function PopoverContent(
  {
    onClose,
    onSubmit,
    downloads,
    max,
  }: {
    onClose: () => void;
    onSubmit: (value: number) => void;
    downloads: number;
    max?: number;
  },
  ref: ForwardedRef<HTMLInputElement>
) {
  const [value, setValue] = useState<number | null>(null);
  const isTooHigh =
    typeof max === "number" && max >= 0 && typeof value === "number" && value >= 0
      ? value > max
      : false;

  useEffect(() => {
    if (typeof downloads === "number") {
      setValue(Math.round(downloads));
    }
  }, [downloads]);

  const handleSubmit = () => {
    if (value === null) return;
    onSubmit(Math.round(value));
    onClose();
  };

  return (
    <Form>
      <div className="p-axs flex-column-container">
        <span>
          <h5>Downloads</h5>
          {typeof max === "number" && <span> Estimated Maximum: {numeral(max).format("0,0")}</span>}
        </span>
        <div className="flex-row-container align-center m-txxxs">
          <InputNumber
            ref={ref}
            value={value}
            onChange={(value) => setValue(value)}
            precision={0}
            size="large"
            min={0}
            style={{ width: "100%" }}
            status={isTooHigh ? "warning" : undefined}
          />
          {isTooHigh && (
            <IconContext.Provider value={{ size: "16px" }}>
              <AiFillWarning className="m-lxxxs" color={COLORS.COLOR_WARNING} />
            </IconContext.Provider>
          )}
        </div>
      </div>
      <Modal.Footer className="p-axs">
        <Button type="link" className="p-a0" size="small" onClick={onClose}>
          Cancel
        </Button>
        <div className="flex-row-container align-center">
          <Tooltip
            title={
              isTooHigh
                ? "This count exceeds the estimated maximum downloads for your timeline. It is unlikely this downloads count will complete for dates you've selected."
                : undefined
            }
            placement="bottom">
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              className="m-lxxs"
              disabled={value === null}
              onClick={handleSubmit}>
              Save
            </Button>
          </Tooltip>
        </div>
      </Modal.Footer>
    </Form>
  );
});
