import { ADD_CARD, GET_MY_CARDS } from "../../action_managers/subscriptions";
import { GET_PAYMENT_FOR_TIER } from "../../action_managers/tiers";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { Card, PaymentReduxState } from "./types";

const initialProps: PaymentReduxState = {
  cards: {},
  userTierPayment: {},
};

const getMyCards = httpReducer<PaymentReduxState>(GET_MY_CARDS, initialProps, {
  success: (state, action) => ({
    ...state,
    cards: action.payload.resp.reduce((agg: PaymentReduxState["cards"], card: Card) => {
      agg[card.id] = card;
      return agg;
    }, {}),
  }),
});

const addCard = httpReducer(
  ADD_CARD,
  initialProps,
  {
    success: (state, action) => ({
      ...state,
      cards: {
        ...state.cards,
        [action.payload.resp.id]: action.payload.resp,
      },
    }),
  },
  { loadingKeyName: "addCardIsLoading" }
);
const getPaymentForTier = httpReducer(GET_PAYMENT_FOR_TIER, initialProps, {
  success: (state, action) => ({
    ...state,
    userTierPayment: action.payload.resp,
  }),
  failure: (state, action) => ({
    ...state,
    userTierPayment: { error: action.payload.error },
  }),
});

export default reduceReducers(getMyCards, addCard, getPaymentForTier);
