import QuestionIcon from "@iconify/icons-mdi/question-mark-circle";
import isEmpty from "lodash/isEmpty";
import React, { FunctionComponent, ReactNode } from "react";
import { classNames } from "react-extras";
import InfoTooltip from "src/components/lib/info";
import Divider from "../../lib/divider";
import { svgIcon as Icon } from "../../lib/icon";
import UpgradeTag from "../../lib/upgrade-tag";
import "./InfoCard.scss";

interface IInfoCard {
  title?: {
    text: string;
    tooltipText?: string;
    divider?: boolean;
    eyebrow?: ReactNode;
    subHeader?: ReactNode;
  };
  download?: {
    text?: string;
    hanlder: (event: React.MouseEvent<SVGAElement, MouseEvent>) => void | undefined;
    allow?: boolean;
  };
}

const InfoCard: FunctionComponent<IInfoCard> = ({ title, download, children }) => {
  return (
    <div className="InfoCard">
      {!isEmpty(title) && (
        <>
          <div className="InfoCard-responsiveRow">
            <h3 className="InfoCard-title">
              {title?.text}
              {title?.tooltipText && (
                <>
                  <InfoTooltip
                    direction="top"
                    helpText={`${title?.tooltipText}`}
                    baseIcon={QuestionIcon}
                    height={20}
                    style={{
                      color: "#C6C6C6",
                      bottom: ".125em",
                      position: "relative",
                      cursor: "pointer",
                    }}
                  />
                </>
              )}
            </h3>
            {title?.eyebrow && <div>{title?.eyebrow}</div>}
          </div>
          {title?.subHeader && <div>{title?.subHeader}</div>}
          {title?.divider ? (
            <Divider marginTop={"24px"} marginBottom={"24px"} />
          ) : (
            <div className="m-txs" />
          )}
        </>
      )}
      <div className="position-relative show-page-spacer">{children}</div>
      <div style={{ marginBottom: "auto" }} />

      {!isEmpty(download) && (
        <div className={classNames("flex-row-container align-center m-txs")}>
          <Icon
            name="reportDownload"
            className={classNames({
              ["pointer-events-none"]: !download?.allow,
              ["semi-transparent"]: !download?.allow,
            })}
            height={16}
            width={16}
            style={{
              bottom: ".125em",
              position: "relative",
              cursor: "pointer",
            }}
            onClick={download?.allow ? download?.hanlder : undefined}
          />
          {download?.text && (
            <h4
              className={classNames("h4 m-v0 m-hxxs", {
                ["pointer-events-none"]: !download?.allow,
                ["semi-transparent"]: !download?.allow,
              })}>
              {download?.text}
            </h4>
          )}
          {download?.allow || <UpgradeTag />}
        </div>
      )}
    </div>
  );
};

export default InfoCard;
