import { Reducer } from "redux";
import {
  DISMISS_MESSAGE_BAR,
  SET_HIDE_WARNING_BAR,
  SET_SIDE_BAR_OPEN,
  SHOW_MESSAGE_BAR,
  TOGGLE_SIDEBAR_OPEN,
  DISABLE_WAITLIST,
  SET_HIDE_SIDEBAR,
} from "../../actions/app";
import { AppReduxState } from "./types";

const initialState: AppReduxState = {
  isSidebarOpen: true, // used to toggle open/close state of sidebar
  hideSidebar: false, // used to hide sidebar and nav on all states
  detectSideBarOnResize: true,

  message: null,
  messageUUID: null,
  messageType: null,
  hideWarningBar: false,
  bypassWaitListRestriction: false, // flag used to temporary disable wait list restriction on wait listed user, based on specific flows
};

const app: Reducer<AppReduxState> = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_OPEN:
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    case SET_SIDE_BAR_OPEN:
      return {
        ...state,
        isSidebarOpen: action.payload.open,
        detectSideBarOnResize: action.payload.auto,
      };
    case SET_HIDE_SIDEBAR:
      return { ...state, hideSidebar: action.payload };

    case SHOW_MESSAGE_BAR:
      return Object.assign({}, state, {
        message: action.message,
        messageUUID: action.messageUUID,
        messageType: action.messageType,
      });
    case DISMISS_MESSAGE_BAR:
      if (state.messageUUID !== action.messageUUID) {
        return state;
      }
      return Object.assign({}, state, {
        message: null,
        messageUUID: null,
        messageType: null,
      });
    case SET_HIDE_WARNING_BAR:
      return {
        ...state,
        hideWarningBar: action.payload,
      };

    case DISABLE_WAITLIST:
      return {
        ...state,
        bypassWaitListRestriction: true,
      };
    default:
      return state;
  }
};

export default app;
