import fetch from "cross-fetch";
import { RECAPTCHA_SITE_TOKEN } from "../constants/recaptcha";
import getAPIPrefix from "../lib/api_prefix";
import { User } from "redcircle-types";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const checkIfEmailExists: (email: string) => Promise<any> = (email) => {
  return new Promise((resolve, reject) => {
    return window.grecaptcha
      .execute(RECAPTCHA_SITE_TOKEN, { action: "verify_email_exists" })
      .then((token: string) => {
        return fetch(`${getAPIPrefix()}users/email-exists`, {
          method: "post",
          headers: {
            Recaptcha: token,
          },
          body: JSON.stringify({ email }),
        })
          .then(resolve)
          .catch(reject);
      });
  });
};

export const resendCredentialEmailInvite = (credentialUUID: string, user: User) => {
  return fetch(`${getAPIPrefix()}credentials/${credentialUUID}/resend-email`, {
    method: "post",
    headers: {
      Authorization: "Bearer " + user.authToken,
      "content-type": "application/json",
    },
  });
};
