import DefaultActionManager from "./default_action_manager";

export const createDealActionName = "CREATE_DEAL";
export const fetchDealActionName = "FETCH_DEAL";
export const updateDealActionName = "UPDATE_DEAL";
export const getDealForUserActionName = "GET_DEAL_FOR_USER";
export const getUnreadDealMessagesActionName = "GET_UNREAD_DEAL_MESSAGES";
export const dealClearValidationActionName = createDealActionName + "_CLEAR_VALIDATION";

export function clearDealValidationErrors() {
  return {
    type: dealClearValidationActionName,
  };
}

export function createDeal(recipientUUID, message, showUUID, myShowUUID) {
  let dealContents = [{ type: "cross_promo" }];
  if (showUUID) {
    dealContents[0].showUUID = showUUID;
  }

  return new DefaultActionManager({
    route: `deals`,
    method: "POST",
    actionName: createDealActionName,
    body: {
      recipientUUID,
      messageText: message,
      dealContents,
      recipientDealContents: [{ type: "cross_promo", showUUID: myShowUUID }],
    },
    auth: true,
  }).run();
}

export function fetchDeal(dealUUID) {
  return new DefaultActionManager({
    route: `deals/${dealUUID}`,
    method: "GET",
    actionName: fetchDealActionName,
    auth: true,
  }).run();
}

export function updateDeal(dealUUID, dealUpdateRequest) {
  return new DefaultActionManager({
    route: `deals/${dealUUID}`,
    method: "PUT",
    actionName: updateDealActionName,
    body: dealUpdateRequest,
    auth: true,
  }).run();
}

export function updateDealMeta(dealUUID, dealUpdateRequest) {
  return new DefaultActionManager({
    route: `deals/${dealUUID}/meta`,
    method: "PUT",
    actionName: updateDealActionName,
    body: dealUpdateRequest,
    auth: true,
  }).run();
}

export const getDealsForUser = () =>
  new DefaultActionManager({
    route: (state) => `users/${state.user.user.uuid}/deals`,
    actionName: getDealForUserActionName,
    auth: true,
  }).run();

export const getUnreadDealMessages = (user) =>
  new DefaultActionManager({
    route: `users/${user.uuid}/deals/unread`,
    actionName: getUnreadDealMessagesActionName,
    auth: true,
  }).run();
