import { isEmpty } from "lodash";
import get from "lodash/get";
import Moment from "moment-timezone";
import { Component } from "react";
import { Col, Grid, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { If } from "react-extras";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { Field, formValueSelector, reduxForm } from "redux-form";
import QuillEditor from "src/components/forms/quill_component";
import { getPricingPageURL } from "../../../lib/config";
import { shouldShowTierPerm } from "../../../lib/feature_flag";
import { seedcastForm } from "../../../lib/forms";
import { ACTIONS, CATEGORIES, gtagEvent } from "../../../lib/google_analytics";
import { showHasExclusiveContent } from "../../../lib/show";
import { tierLevel } from "../../../reducers/types";
import Checkbox from "../../forms/checkbox";
import FileUploadV2 from "../../forms/file_upload/file_upload_v2";
import FormElement from "../../forms/form_element";
import ImageUploader from "../../forms/image_uploader/image_uploader";
import FormDropDown from "../../lib/form_dropdown";
import InfoTooltip from "../../lib/info";
import UpgradeTag from "../../lib/upgrade-tag";
import "../shows/show-form.scss";

const EpisodeCreate = "episode-create";
const EpisodeEdit = "episode-edit";

const fileSizeMaxDefault = 400;

const fileSizeLimitMap = {
  [tierLevel.og]: 200,
  [tierLevel.core]: 200,
  [tierLevel.growth]: 200,
  [tierLevel.pro]: 250,
  [tierLevel.enterprise]: fileSizeMaxDefault,
};
class EpisodeForm extends Component {
  onFieldChange = () => {
    gtagEvent(ACTIONS.FILL, CATEGORIES.EPISODE_FORM);
  };

  onSubmit = (...args) => {
    gtagEvent(ACTIONS.SUBMIT, CATEGORIES.EPISODE_FORM);

    this.props.handleSubmit(...args);
  };

  // pre tiers Logic
  videoCheckboxLabel = () => {
    if (get(this.props, "show.canAutoDistributeToYouTube")) {
      return "Publish to YouTube";
    }

    return (
      <span>
        Generate Video File{" "}
        <InfoTooltip
          helpText={"Generate a video file that you can download and manually upload to YouTube"}
        />
      </span>
    );
  };

  tierVideoCheckboxLabel = () => {
    const canAutoDistributeToYouTube = get(this.props, "show.canAutoDistributeToYouTube");
    const level = this.props.tier?.level;
    const PublishToYoutube = "Publish to YouTube";
    const GenerateVideoFile = "Generate Video File";

    // Original checkbox label logic pre tiers
    if (!shouldShowTierPerm()) {
      return this.videoCheckboxLabel();
    }

    switch (level) {
      case tierLevel.og:
        return (
          <span>
            {canAutoDistributeToYouTube ? PublishToYoutube : GenerateVideoFile}{" "}
            {this.connectedToYouTube() || (
              <InfoTooltip
                helpText={"Go to the Distribution page and connect your YouTube account"}
              />
            )}
          </span>
        );
      case tierLevel.core:
        return (
          <span>
            Add Video Feature <UpgradeTag />
          </span>
        );
      case tierLevel.growth:
        return (
          <div className="flex-column-container">
            <span className="m-txs">
              Generate Video File{" "}
              <InfoTooltip
                helpText={
                  "Generate a video file that you can download and manually upload to YouTube"
                }
              />
            </span>
            <div className="flex-row-container align-center">
              <input type="checkbox" disabled className="m-rxxs" />
              <span className="text-disabled m-rxxxs">{PublishToYoutube}</span>
              <UpgradeTag />
            </div>
          </div>
        );
      case tierLevel.pro:
      case tierLevel.enterprise:
        return (
          <div className="flex-row-container">
            {PublishToYoutube}{" "}
            {this.disableVideoGenerate() && (
              <InfoTooltip
                helpText={"Go to the Distribution page and connect your YouTube account"}
              />
            )}
          </div>
        );
      default:
        return this.videoCheckboxLabel();
    }
  };

  validateMp3Size = (field, allValues, props) => {
    const megabyte = 10 ** 6;

    const { fileByteLength } = props;

    const file = field?.[0] || {
      size: fileByteLength,
    };

    if (isEmpty(file)) {
      return "Audio file cannot be empty";
    }

    if (!shouldShowTierPerm()) {
      // Original validation message logic pre tiers
      // The OG file zise limit is the current pre-tier limit
      if (file?.size > fileSizeLimitMap.og * megabyte) {
        return `Your audio file is too large. RedCircle's max audio file size is ${fileSizeLimitMap.og} MB.`;
      }
    } else {
      // New post tier validation

      const fileSizeLimit = fileSizeLimitMap?.[this.props?.tier?.level] ?? fileSizeMaxDefault;

      if (file?.size > fileSizeLimit * megabyte) {
        let errorMessage = <p>{`File size exceeds ${fileSizeLimit}mb.`}</p>;

        if (this.props?.tier !== tierLevel.enterprise) {
          errorMessage = (
            <p>
              {`File size exceeds ${fileSizeLimit}mb. To increase your file size limit please`}{" "}
              <strong>
                <a
                  href={getPricingPageURL()}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "inherit" }}>
                  Upgrade Your Plan
                </a>
              </strong>
              {"."}
            </p>
          );
        }

        return errorMessage;
      }
    }
  };

  validateTitle = (title) => {
    if (typeof title !== "string" || isEmpty(title)) {
      return "Title cannot be empty";
    }
  };

  disableVideoGenerate = () => {
    if (!shouldShowTierPerm()) return false;

    switch (this.props.tier.level) {
      case tierLevel.core: // always disable, video generate not allowed in core plan
        return true;
      case tierLevel.og:
      case tierLevel.pro:
      case tierLevel.enterprise: // video generate also adds auto publish to youtube, enabled based on connected youtube account
        return !this.connectedToYouTube();
      default:
        return false; // for growth do not disable
    }
  };

  connectedToYouTube = () => !!get(this.props.show, ["youtubeAuthToken"]);

  render() {
    const {
      mode,
      user,
      show,
      episode,
      setImageFileData,
      setImageFileUUID,
      initialValues,
      setError,
      mediaFiles,
      validationErrors,
      setAudioFileData,
      selectedContentMediaFileUUID,
      close,
      isFormLoading,
      progress,
      shouldPublishToYoutube,
    } = this.props;

    var isCreateForm = mode === "create";

    return (
      <form onSubmit={this.onSubmit} className={"show-form-container"}>
        <div className={"episode-form episode-" + mode + "-form"}>
          <Grid fluid>
            <Row>
              <Col md={3} mdOffset={1}>
                <div>
                  <Field
                    component={FormElement}
                    InputComponent={ImageUploader}
                    label="Episode Artwork"
                    name="imageFile"
                    onUpload={() => gtagEvent(ACTIONS.UPLOAD, CATEGORIES.EPISODE_IMAGE)}
                    setImageFileData={(mode, file) => setImageFileData(mode, file)}
                    setImageFileUUID={setImageFileUUID}
                    mediaFileUUID={initialValues?.imageMediaFileUUID}
                    setError={setError}
                    mediaFiles={mediaFiles}
                    validationErrors={validationErrors}
                    validationErrorKeyOverride={"imageMediaFileUUID"}
                  />
                </div>
              </Col>
              <Col md={6} mdOffset={1}>
                <div>
                  <Field component="input" name="showUUID" type="hidden" />
                  <Field component="input" name="isVisible" type="hidden" />
                </div>

                <div className={"m-bl"}>
                  <Field
                    validate={this.validateTitle}
                    component={FormElement}
                    onChange={this.onFieldChange}
                    name="title"
                    label="Title"
                    type="text"
                    validationErrors={validationErrors}
                  />
                </div>
                <div className={"episode-form__quill-container m-bl"}>
                  <Field
                    component={FormElement}
                    onChange={this.onFieldChange}
                    InputComponent={QuillEditor}
                    name="description"
                    label="Description"
                    validationErrors={validationErrors}
                  />
                </div>
                <div className={"m-bl"}>
                  <Field
                    validate={this.validateMp3Size}
                    component={FormElement}
                    InputComponent={FileUploadV2}
                    label="Upload MP3"
                    name="contentFile"
                    onChange={(...args) => {
                      gtagEvent(ACTIONS.UPLOAD, CATEGORIES.EPISODE_AUDIO);
                      setAudioFileData(...args);
                    }}
                    setAudioFileData={setAudioFileData}
                    mediaFiles={mediaFiles}
                    mediaFileUUID={
                      selectedContentMediaFileUUID || initialValues?.contentMediaFileUUID
                    }
                    validationErrors={validationErrors}
                    validationErrorKeyOverride={"contentMediaFileUUID"}
                    max={fileSizeLimitMap[this.props?.tier?.level]}
                  />
                </div>
                <div className={"m-bl"}>
                  <Field
                    name="publishedAt"
                    component={FormElement}
                    onChange={this.onFieldChange}
                    InputComponent={renderDateTimePicker}
                    valueField="value"
                    label="Publish Date"
                  />
                </div>
                <If
                  condition={
                    shouldShowTierPerm()
                      ? isCreateForm
                      : !!get(show, ["youtubeAuthToken"]) && isCreateForm
                  }>
                  <Field
                    component={FormElement}
                    onChange={this.onFieldChange}
                    InputComponent={Checkbox}
                    name="shouldPublishToYoutube"
                    label="Video"
                    checkboxLabel={this.tierVideoCheckboxLabel()}
                    disabled={this.disableVideoGenerate()}
                    type="checkbox"
                    validationErrors={validationErrors}
                    groupClassName="flex-column-container"
                  />
                  {shouldPublishToYoutube && (
                    <div className="p-ls">
                      <Field
                        component={FormElement}
                        onChange={this.onFieldChange}
                        name="youtubeOptions.title"
                        label="YouTube Title"
                        validationErrors={validationErrors}
                      />
                      <Field
                        component={FormElement}
                        onChange={this.onFieldChange}
                        name="youtubeOptions.description"
                        label="YouTube Description"
                        validationErrors={validationErrors}
                      />
                      <Field
                        component={FormElement}
                        onChange={() => {
                          this.onFieldChange();
                        }}
                        name="youtubeOptions.privacyStatus"
                        label="Privacy Status"
                        type="select"
                        componentClass="select"
                        placeholder="Select a type"
                        validationErrors={validationErrors}>
                        <>
                          <option key="text/html" value="public">
                            Public
                          </option>
                          <option key="text/plain" value="unlisted">
                            Unlisted
                          </option>
                          <option key="text/vtt" value="private">
                            Private
                          </option>
                        </>
                      </Field>
                    </div>
                  )}
                </If>
                <If condition={!!showHasExclusiveContent(show)}>
                  <div className={"m-bl"}>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      InputComponent={Checkbox}
                      name="shouldPublishToSubscribers"
                      label="Exclusive Access"
                      checkboxLabel="Publish to Exclusive Feed"
                      type="checkbox"
                      validationErrors={validationErrors}
                      validationErrorKeyOverride={"shouldPublishToNonSubscribers"}
                      validationErrorMessageOverride=""
                      groupClassName="flex-column-container"
                    />
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      InputComponent={Checkbox}
                      name="shouldPublishToNonSubscribers"
                      checkboxLabel="Publish to Public Feed"
                      type="checkbox"
                      validationErrors={validationErrors}
                    />
                  </div>
                </If>
                <FormDropDown validationErrors={validationErrors}>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="subtitle"
                      label="Subtitle"
                      type="text"
                      validationErrors={validationErrors}
                    />
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="link"
                      label="External Website"
                      type="text"
                      validationErrors={validationErrors}
                      infoText="A link to the episode on your external webpage. Defaults to your RedCircle podcast page."
                    />
                  </div>
                  <If condition={user.userAttributes.shouldSeeSeparateITunesTitle === "true"}>
                    <div>
                      <Field
                        component={FormElement}
                        onChange={this.onFieldChange}
                        name="iTunesTitle"
                        label="Apple: Title"
                        type="text"
                        infoText="If you want to use a separate title within Apple Podcasts, you can specify an alternative title here."
                        validationErrors={validationErrors}
                      />
                    </div>
                  </If>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      componentClass="textarea"
                      name="summary"
                      infoText="If left blank we will default to description. This will be used for apps that don't support an html description like Apple Podcasts."
                      label="Apple: Summary"
                      type="textarea"
                      rows="10"
                      validationErrors={validationErrors}
                    />
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="episodeNumber"
                      label="Episode Number"
                      type="number"
                      validationErrors={validationErrors}
                    />
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="season"
                      label="Season"
                      type="number"
                      validationErrors={validationErrors}
                    />
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      name="transcriptInfo.url"
                      label="Transcript URL"
                      type="text"
                      validationErrors={validationErrors}
                      infoText="A URL for a transcript file."
                    />
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={() => {
                        this.onFieldChange();
                      }}
                      name="transcriptInfo.type"
                      label="Transcript Type"
                      type="select"
                      componentClass="select"
                      placeholder="Select a type"
                      validationErrors={validationErrors}>
                      <>
                        <option key="" value="">
                          -- None --
                        </option>
                        <option key="text/html" value="text/html">
                          HTML
                        </option>
                        <option key="text/plain" value="text/plain">
                          Plain Text
                        </option>
                        <option key="text/vtt" value="text/vtt">
                          VTT
                        </option>
                        <option key="application/json" value="application/json">
                          JSON
                        </option>
                      </>
                    </Field>
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={() => {
                        this.onFieldChange();
                      }}
                      name="episodeType"
                      label="Episode Type"
                      type="select"
                      componentClass="select"
                      placeholder="Select a type"
                      validationErrors={validationErrors}>
                      <>
                        <option key="full" value="full">
                          Full
                        </option>
                        ,
                        <option key="trailer" value="trailer">
                          Trailer
                        </option>
                        ,
                        <option key="bonus" value="bonus">
                          Bonus
                        </option>
                      </>
                    </Field>
                  </div>
                  <div>
                    <Field
                      component={FormElement}
                      onChange={this.onFieldChange}
                      InputComponent={Checkbox}
                      name="isExplicit"
                      checkboxLabel="Episode is Explicit"
                      type="checkbox"
                      validationErrors={validationErrors}
                    />
                  </div>
                  <div>
                    <input name="isClosedCaptioned" type="hidden" value={true} />
                  </div>
                  <div>
                    <Field component="input" type="hidden" name="imageMediaFileUUID" />
                  </div>
                  <div>
                    <Field component="input" type="hidden" name="contentMediaFileUUID" />
                  </div>
                  {isCreateForm && (
                    <div>
                      <Field component="input" name="adPlacements[0]" type="hidden" value={0} />
                      <Field component="input" name="adPlacements[1]" type="hidden" value={0} />
                    </div>
                  )}
                </FormDropDown>
              </Col>
            </Row>
          </Grid>
        </div>
      </form>
    );
  }
}

const renderDateTimePicker = ({ onChange, value, disabled, infoBitText }) => {
  const picker = (
    <DateTimePicker
      onChange={onChange}
      time={true}
      value={!value ? new Date() : new Date(value)}
      step={5}
      disabled={disabled}
      format={(value) => {
        return Moment.tz(value, Moment.tz.guess()).format("lll z");
      }}
      timeFormat={(value, a, b) => {
        return Moment.tz(value, Moment.tz.guess()).format(`LT z`);
      }}
      parse={(value) => {
        const noTZValue = value.replace(/.+\s[AP]M(.+)/, (match, p1) => match.replace(p1, ""));
        return new Date(noTZValue);
      }}
    />
  );
  if (!infoBitText) {
    return <div>{picker}</div>;
  }
  return (
    <OverlayTrigger overlay={<Tooltip id="tooltip">{infoBitText}</Tooltip>}>
      {picker}
    </OverlayTrigger>
  );
};

export var EpisodeCreateForm = (props) => <EpisodeForm {...props} mode="create" />;

export var EpisodeEditForm = (props) => <EpisodeForm {...props} mode="edit" />;

var formValueSelectorCreate = formValueSelector(EpisodeCreate);
EpisodeCreateForm = connect((state) => {
  return {
    selectedImageMediaFileUUID: formValueSelectorCreate(state, "imageMediaFileUUID"),
    selectedContentMediaFileUUID: formValueSelectorCreate(state, "contentMediaFileUUID"),
    isVisible: formValueSelectorCreate(state, "isVisible"),
    shouldPublishToYoutube: formValueSelectorCreate(state, "shouldPublishToYoutube"),
  };
})(EpisodeCreateForm);

// formWhiteList stops the seedcastForm from stripping the value on submit
const formWhiteList = ["imageFileData", "audioFileData"];

EpisodeCreateForm = reduxForm({
  form: EpisodeCreate,
})(EpisodeCreateForm);
EpisodeCreateForm = seedcastForm(EpisodeCreateForm, {
  whiteList: formWhiteList,
});

var formValueSelectorEdit = formValueSelector(EpisodeEdit);
EpisodeEditForm = connect((state) => {
  return {
    selectedImageMediaFileUUID: formValueSelectorEdit(state, "imageMediaFileUUID"),
    selectedContentMediaFileUUID: formValueSelectorEdit(state, "contentMediaFileUUID"),
    imageUploader: state.imageUploader,
    isVisible: formValueSelectorCreate(state, "isVisible"),
    shouldPublishToYoutube: formValueSelectorEdit(state, "shouldPublishToYoutube"),
  };
})(EpisodeEditForm);
EpisodeEditForm = reduxForm({
  form: EpisodeEdit,
})(EpisodeEditForm);
EpisodeEditForm = seedcastForm(EpisodeEditForm, {
  whiteList: formWhiteList,
});
