import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { classNames, If } from "react-extras";
import { Link } from "react-router-dom";
import { getImageURL } from "../../../lib/get_image_url";
import "./album_art.scss";

const AlbumArt = (props) => {
  const { src } = props;
  const { className, imageSize, isRedirected = false, ...rest } = props;

  return (
    <img
      alt="album-art"
      data-testid="test-album-art"
      className={classNames("album-art-component", {
        [className]: typeof className === "string",
        redirected: isRedirected,
      })}
      {...rest}
      src={getImageURL(src, imageSize)}
    />
  );
};

AlbumArt.displayName = "AlbumArt";

const AlbumDisplay = (props) => {
  const {
    title,
    subTitle,
    uuid,
    isDirectoryShow,
    toURL,
    episodeCount,
    bucketSize,
    linkExternally,
    ...otherProps
  } = props;

  let linkURL = isDirectoryShow
    ? `/promotions/directory-shows/${uuid}`
    : "/promotions/shows/" + uuid;

  let linkProps = {};
  if (linkExternally) {
    linkProps = {
      target: "_blank",
      rel: "noopener noreferrer",
    };
  }

  return (
    <div className="album-art-wrapper">
      <Link to={toURL || linkURL} {...linkProps}>
        <AlbumArt {...otherProps} />
      </Link>
      <div className={"album-art-title bold text-start"} style={{ textAlign: "left" }}>
        <Link className="no-style-link" to={toURL || linkURL} {...linkProps}>
          {title}
        </Link>
      </div>
      <If condition={!episodeCount && !bucketSize}>
        <div className="album-art-subtitle">
          <Link to={toURL || linkURL} className="no-style-link" {...linkProps}>
            {subTitle}
          </Link>
        </div>
      </If>
      <If condition={!!bucketSize}>
        <span className="album-art-subtitle">Weekly Downloads: {bucketSize}</span>
        <br />
      </If>
      <If condition={!!episodeCount}>
        <span className="album-art-subtitle">Episodes: {episodeCount}</span>
      </If>
    </div>
  );
};

const AlbumGrid = (props) => {
  const { shows, noGrid, customSizes, ...otherProps } = props;
  const renderSizes = {
    md: 2,
    sm: 3,
    xs: 6,
    ...customSizes,
  };
  const content = () => {
    return (
      <Row>
        {shows.map(({ title, imageURL, ownerName, uuid, isDirectoryShow, subtitle, toURL }, i) => (
          <div key={uuid}>
            {i % (12 / renderSizes.xs) === 0 && i !== 0 && (
              <div className="clearfix visible-xs-block" />
            )}
            {i % (12 / renderSizes.sm) === 0 && i !== 0 && (
              <div className="clearfix visible-sm-block" />
            )}
            {i % (12 / renderSizes.md) === 0 && i !== 0 && (
              <div className="clearfix visible-md-block visible-lg-block visible-xl-block album-grid-clearer" />
            )}
            <Col {...renderSizes} key={uuid}>
              <AlbumDisplay
                src={imageURL}
                uuid={uuid}
                alt={`${title.replace(" ", "-")}-${i}`}
                title={title}
                subTitle={subtitle || ownerName}
                isDirectoryShow={isDirectoryShow}
                toURL={toURL}
                {...otherProps}
              />
            </Col>
          </div>
        ))}
      </Row>
    );
  };
  return noGrid ? content() : <Grid fluid>{content()}</Grid>;
};

export { AlbumArt, AlbumDisplay, AlbumGrid };
