import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";

export var itunesCategories = [
  {
    group_uuid: "0cbdff14-20dc-41b3-a573-4f1d610cf84d",
    name: "After Shows",
    uuid: "d9f24614-0f81-4aca-879d-245c88a3953b",
  },
  {
    group_uuid: "9aa5a11d-7e81-4d21-b231-cc2a8481d751",
    name: "Alternative Health",
    uuid: "dbac5e12-3769-40a7-aecc-29e27d13c687",
  },
  {
    group_uuid: "b7e2e4df-63d0-4216-bef1-9dc465aba329",
    name: "Animation & Manga",
    uuid: "318dc527-7ee7-48c3-b1bc-3da67cece4d9",
  },
  { name: "Arts", uuid: "00917086-bacc-4e01-9f94-eefcff96b8dd" },
  {
    group_uuid: "9e7819be-e130-4689-a10b-6e52fa88b315",
    name: "Astronomy",
    uuid: "51a62b03-ff94-45ca-85ca-af5d6882bcb7",
  },
  {
    group_uuid: "b7e2e4df-63d0-4216-bef1-9dc465aba329",
    name: "Automotive",
    uuid: "d8aac8ea-fb36-431e-a09c-cc7a5bf099ab",
  },
  {
    group_uuid: "b7e2e4df-63d0-4216-bef1-9dc465aba329",
    name: "Aviation",
    uuid: "12b0a4a7-d9b9-474a-96c9-6a742f22b028",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Baseball",
    uuid: "c4124612-da92-4bbf-81be-8b916d6787c6",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Basketball",
    uuid: "8fe226a9-97ed-4a51-9663-0f11ace7cab3",
  },
  {
    group_uuid: "00917086-bacc-4e01-9f94-eefcff96b8dd",
    name: "Books",
    uuid: "a1e0cc0c-4eec-4d95-aead-480bfa12197b",
  },
  {
    group_uuid: "d1fdcb80-2559-4b25-a94b-9cf6bcc02142",
    name: "Buddhism",
    uuid: "0b93dc0d-2145-4bd6-a75e-948d3cdd9d46",
  },
  { name: "Business", uuid: "96a84fa1-4480-41d6-a3d8-3a740bf49538" },
  {
    group_uuid: "9280af6c-72b1-486c-b241-41efc0abea3d",
    name: "Business News",
    uuid: "97207571-55f6-4184-824c-436eae69dae3",
  },
  {
    group_uuid: "96a84fa1-4480-41d6-a3d8-3a740bf49538",
    name: "Careers",
    uuid: "8aeca566-2a8d-40be-854b-12c919067bae",
  },
  {
    group_uuid: "9e7819be-e130-4689-a10b-6e52fa88b315",
    name: "Chemistry",
    uuid: "c92c3872-ce59-4d6c-bb50-ad9efb4afe1e",
  },
  {
    group_uuid: "d1fdcb80-2559-4b25-a94b-9cf6bcc02142",
    name: "Christianity",
    uuid: "4bdbcd1b-ad9b-47e2-aaa3-578bfa0732c9",
  },
  { name: "Comedy", uuid: "d850044e-48de-45b9-8d7d-bb54dde56fb2" },
  {
    group_uuid: "7ab8068f-59cb-4587-ba7b-a872a3e5189b",
    name: "Comedy Fiction",
    uuid: "5f0fe8d8-b774-4e72-9555-47461557e265",
  },
  {
    group_uuid: "d850044e-48de-45b9-8d7d-bb54dde56fb2",
    name: "Comedy Interviews",
    uuid: "cbbc5498-09a4-46a9-875a-efff0555c5dc",
  },
  {
    group_uuid: "1a4f61a2-db29-4bab-9232-52c19351107b",
    name: "Courses",
    uuid: "f7d526b1-6331-4b38-9bb4-5469880df2d9",
  },
  {
    group_uuid: "b7e2e4df-63d0-4216-bef1-9dc465aba329",
    name: "Crafts",
    uuid: "08485b50-1601-451c-b0a7-b849476c5c9a",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Cricket",
    uuid: "3530e6f1-37b1-414d-90c5-09ddb4887870",
  },
  {
    group_uuid: "9280af6c-72b1-486c-b241-41efc0abea3d",
    name: "Daily News",
    uuid: "caec7508-4928-4d1c-8fc5-a227920e58eb",
  },
  {
    group_uuid: "00917086-bacc-4e01-9f94-eefcff96b8dd",
    name: "Design",
    uuid: "2b21beeb-3589-4d22-930b-7a0025025ddb",
  },
  {
    group_uuid: "429642e8-792a-4516-8ac1-faf0b761294e",
    name: "Documentary",
    uuid: "a8ee86fc-4e94-4d20-bc24-19f9893c4bac",
  },
  {
    group_uuid: "7ab8068f-59cb-4587-ba7b-a872a3e5189b",
    name: "Drama",
    uuid: "f66f4385-81fc-4b35-9bc2-957b99815326",
  },
  {
    group_uuid: "9e7819be-e130-4689-a10b-6e52fa88b315",
    name: "Earth Sciences",
    uuid: "76e6ff1d-5f14-488d-94d2-691dd85bdcc6",
  },
  { name: "Education", uuid: "1a4f61a2-db29-4bab-9232-52c19351107b" },
  {
    group_uuid: "c274d8e6-a37b-4c0b-826d-18ffb35adb6f",
    name: "Education for Kids",
    uuid: "51bbae99-6556-4cf5-818a-3ef3309efa20",
  },
  {
    group_uuid: "9280af6c-72b1-486c-b241-41efc0abea3d",
    name: "Entertainment News",
    uuid: "8413b268-289c-4133-8c0d-37921ac3692f",
  },
  {
    group_uuid: "96a84fa1-4480-41d6-a3d8-3a740bf49538",
    name: "Entrepreneurship",
    uuid: "74b56908-cd04-4669-8f5f-e545c184e7c6",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Fantasy Sports",
    uuid: "ce819faa-c746-44c3-ad52-769b66c1f207",
  },
  {
    group_uuid: "00917086-bacc-4e01-9f94-eefcff96b8dd",
    name: "Fashion & Beauty",
    uuid: "75a6b217-e982-4af1-ad4f-05607e69064c",
  },
  { name: "Fiction", uuid: "7ab8068f-59cb-4587-ba7b-a872a3e5189b" },
  {
    group_uuid: "0cbdff14-20dc-41b3-a573-4f1d610cf84d",
    name: "Film History",
    uuid: "d9e1640a-bfa0-4cd6-bcf9-46e00e11969a",
  },
  {
    group_uuid: "0cbdff14-20dc-41b3-a573-4f1d610cf84d",
    name: "Film Interviews",
    uuid: "ec893eef-c450-4edc-9709-b89d6fca2729",
  },
  {
    group_uuid: "0cbdff14-20dc-41b3-a573-4f1d610cf84d",
    name: "Film Reviews",
    uuid: "8670194a-cba3-4e68-a51c-ab13f597fa0c",
  },
  {
    group_uuid: "9aa5a11d-7e81-4d21-b231-cc2a8481d751",
    name: "Fitness",
    uuid: "5191b55c-6677-48d1-9266-9cbc5c751035",
  },
  {
    group_uuid: "00917086-bacc-4e01-9f94-eefcff96b8dd",
    name: "Food",
    uuid: "665b403f-ddc5-406c-9cb5-34407b5f048a",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Football",
    uuid: "e5804030-50d4-47c7-aa44-a6f39bd04866",
  },
  {
    group_uuid: "b7e2e4df-63d0-4216-bef1-9dc465aba329",
    name: "Games",
    uuid: "0f467fa6-c369-491d-9e5c-765107d86104",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Golf",
    uuid: "9a406dda-593a-4ee6-b19b-780689ab7d68",
  },
  { name: "Government", uuid: "69ee34cb-16cc-4ae8-a93f-66f4af4bd49e" },
  { name: "Health & Fitness", uuid: "9aa5a11d-7e81-4d21-b231-cc2a8481d751" },
  {
    group_uuid: "d1fdcb80-2559-4b25-a94b-9cf6bcc02142",
    name: "Hinduism",
    uuid: "25260558-74b8-475f-a910-6a32d2cc0df3",
  },
  { name: "History", uuid: "03bfd975-6a29-4e1d-a9f1-a8d5dcd65f83" },
  {
    group_uuid: "b7e2e4df-63d0-4216-bef1-9dc465aba329",
    name: "Hobbies",
    uuid: "8b53d744-ee66-4b2d-b9e2-af27e827ec38",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Hockey",
    uuid: "9220b22c-01d6-4682-ab62-6f531eab8b06",
  },
  {
    group_uuid: "b7e2e4df-63d0-4216-bef1-9dc465aba329",
    name: "Home & Garden",
    uuid: "2b442aac-4c17-41a0-8908-538e249bbc73",
  },
  {
    group_uuid: "1a4f61a2-db29-4bab-9232-52c19351107b",
    name: "How To",
    uuid: "5a5e95d8-d3a2-4fb0-ae63-9c95025271e1",
  },
  {
    group_uuid: "d850044e-48de-45b9-8d7d-bb54dde56fb2",
    name: "Improv",
    uuid: "ad3c63ed-be58-42bb-8d11-c0f5a2ed51f2",
  },
  {
    group_uuid: "96a84fa1-4480-41d6-a3d8-3a740bf49538",
    name: "Investing",
    uuid: "c82935ac-65bb-4731-a270-56c8ead8e099",
  },
  {
    group_uuid: "d1fdcb80-2559-4b25-a94b-9cf6bcc02142",
    name: "Islam",
    uuid: "16d95119-b20d-43ce-bb68-22223d8b7afa",
  },
  {
    group_uuid: "d1fdcb80-2559-4b25-a94b-9cf6bcc02142",
    name: "Judaism",
    uuid: "f35bc474-de3d-4b5c-9a9b-6507ec8ea5f6",
  },
  { name: "Kids & Family", uuid: "c274d8e6-a37b-4c0b-826d-18ffb35adb6f" },
  {
    group_uuid: "1a4f61a2-db29-4bab-9232-52c19351107b",
    name: "Language Learning",
    uuid: "510c7f3d-fb42-4853-b0a3-335a35de77e7",
  },
  { name: "Leisure", uuid: "b7e2e4df-63d0-4216-bef1-9dc465aba329" },
  {
    group_uuid: "9e7819be-e130-4689-a10b-6e52fa88b315",
    name: "Life Sciences",
    uuid: "bd0e8abd-13af-4553-8366-8f9beacc851a",
  },
  {
    group_uuid: "96a84fa1-4480-41d6-a3d8-3a740bf49538",
    name: "Management",
    uuid: "b6903f39-5bfc-419c-bcfb-4c88b412a4bb",
  },
  {
    group_uuid: "96a84fa1-4480-41d6-a3d8-3a740bf49538",
    name: "Marketing",
    uuid: "b6903f39-5bfc-419c-bcfb-4c88b413a4bb",
  },
  {
    group_uuid: "9e7819be-e130-4689-a10b-6e52fa88b315",
    name: "Mathematics",
    uuid: "c6a72357-8914-4426-9c5a-cb60b2e26926",
  },
  {
    group_uuid: "9aa5a11d-7e81-4d21-b231-cc2a8481d751",
    name: "Medicine",
    uuid: "528fb1ab-94df-4be2-8b01-be0b5c799ec7",
  },
  {
    group_uuid: "9aa5a11d-7e81-4d21-b231-cc2a8481d751",
    name: "Mental Health",
    uuid: "8f351a75-4e73-41cc-98e2-b5534f6ffa3f",
  },
  { name: "Music", uuid: "50589381-247b-49f4-9c1c-5a991abea1e8" },
  {
    group_uuid: "50589381-247b-49f4-9c1c-5a991abea1e8",
    name: "Music Commentary",
    uuid: "6b3c7d1c-a8df-47f0-9184-4885f03ea985",
  },
  {
    group_uuid: "50589381-247b-49f4-9c1c-5a991abea1e8",
    name: "Music History",
    uuid: "b4c8982c-a35f-4ada-87c3-d7f27b8350a9",
  },
  {
    group_uuid: "50589381-247b-49f4-9c1c-5a991abea1e8",
    name: "Music Interviews",
    uuid: "98eb3753-f938-432d-a0d7-8ce248dcbfca",
  },
  {
    group_uuid: "9e7819be-e130-4689-a10b-6e52fa88b315",
    name: "Natural Sciences",
    uuid: "63a6c494-db59-4eec-9581-2960c4dec0d0",
  },
  {
    group_uuid: "9e7819be-e130-4689-a10b-6e52fa88b315",
    name: "Nature",
    uuid: "2ccb84e8-50db-4ec0-8088-3df8b838e57d",
  },
  { name: "News", uuid: "9280af6c-72b1-486c-b241-41efc0abea3d" },
  {
    group_uuid: "9280af6c-72b1-486c-b241-41efc0abea3d",
    name: "News Commentary",
    uuid: "5b4ade93-2b55-44dc-b003-1f93de2966c2",
  },
  {
    group_uuid: "96a84fa1-4480-41d6-a3d8-3a740bf49538",
    name: "Non-Profit",
    uuid: "7fedcc58-8627-4e26-bf46-b9c606cbb164",
  },
  {
    group_uuid: "9aa5a11d-7e81-4d21-b231-cc2a8481d751",
    name: "Nutrition",
    uuid: "458ccc09-3323-41eb-a952-2b83e6e51ccd",
  },
  {
    group_uuid: "c274d8e6-a37b-4c0b-826d-18ffb35adb6f",
    name: "Parenting",
    uuid: "80352c23-9d7a-4a44-b7cf-9bb05cc645f4",
  },
  {
    group_uuid: "00917086-bacc-4e01-9f94-eefcff96b8dd",
    name: "Performing Arts",
    uuid: "0bee8a4e-627a-469b-af35-e99038d41c5e",
  },
  {
    group_uuid: "429642e8-792a-4516-8ac1-faf0b761294e",
    name: "Personal Journals",
    uuid: "ab4e214d-fdbb-41f7-bba2-00fa4efd689e",
  },
  {
    group_uuid: "c274d8e6-a37b-4c0b-826d-18ffb35adb6f",
    name: "Pets & Animals",
    uuid: "4c118293-04fb-4a26-807e-b333bb7abd49",
  },
  {
    group_uuid: "429642e8-792a-4516-8ac1-faf0b761294e",
    name: "Philosophy",
    uuid: "570c8706-f0b2-4422-be3b-0b88f416de4b",
  },
  {
    group_uuid: "9e7819be-e130-4689-a10b-6e52fa88b315",
    name: "Physics",
    uuid: "0048a0a7-6176-4706-873b-c3a51991789e",
  },
  {
    group_uuid: "429642e8-792a-4516-8ac1-faf0b761294e",
    name: "Places & Travel",
    uuid: "430f8ee0-f83a-41f4-a6ff-a6b28dd405b3",
  },
  {
    group_uuid: "9280af6c-72b1-486c-b241-41efc0abea3d",
    name: "Politics",
    uuid: "817a4025-cf26-4f31-b973-5c58bcacdd46",
  },
  {
    group_uuid: "429642e8-792a-4516-8ac1-faf0b761294e",
    name: "Relationships",
    uuid: "a832a15c-3117-406d-9c53-3a5dcba132db",
  },
  {
    group_uuid: "d1fdcb80-2559-4b25-a94b-9cf6bcc02142",
    name: "Religion",
    uuid: "8f0abc7d-d133-4cdf-8b27-d0325f71bd44",
  },
  { name: "Religion & Spirituality", uuid: "d1fdcb80-2559-4b25-a94b-9cf6bcc02142" },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Rugby",
    uuid: "b20928f9-9120-41b6-9c5a-49c5bbaea8fd",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Running",
    uuid: "fbb72d33-fd1b-4264-a6fd-eedc40366ac0",
  },
  { name: "Science", uuid: "9e7819be-e130-4689-a10b-6e52fa88b315" },
  {
    group_uuid: "7ab8068f-59cb-4587-ba7b-a872a3e5189b",
    name: "Science Fiction",
    uuid: "24c2b4b9-5125-4a2c-bf9d-26b5f0f8a2cb",
  },
  {
    group_uuid: "1a4f61a2-db29-4bab-9232-52c19351107b",
    name: "Self-Improvement",
    uuid: "f93d4511-a95b-4971-89d2-bf6d0c57d148",
  },
  {
    group_uuid: "9aa5a11d-7e81-4d21-b231-cc2a8481d751",
    name: "Sexuality",
    uuid: "e59927a1-f8a6-4dbf-8910-5df811b2bb93",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Soccer",
    uuid: "819467d9-ed48-403b-a019-381b7e71b18a",
  },
  {
    group_uuid: "9e7819be-e130-4689-a10b-6e52fa88b315",
    name: "Social Sciences",
    uuid: "9c327131-8196-4059-a66d-3969c91a16b5",
  },
  { name: "Society & Culture", uuid: "429642e8-792a-4516-8ac1-faf0b761294e" },
  {
    group_uuid: "d1fdcb80-2559-4b25-a94b-9cf6bcc02142",
    name: "Spirituality",
    uuid: "5ae70478-57c5-4b87-8d7f-7b48e1f6e748",
  },
  { name: "Sports", uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0" },
  {
    group_uuid: "9280af6c-72b1-486c-b241-41efc0abea3d",
    name: "Sports News",
    uuid: "e216b097-65ef-4280-8af7-fe4a494b9ec4",
  },
  {
    group_uuid: "d850044e-48de-45b9-8d7d-bb54dde56fb2",
    name: "Stand-Up",
    uuid: "f8018dce-893c-4040-aece-b4a432a815d2",
  },
  {
    group_uuid: "c274d8e6-a37b-4c0b-826d-18ffb35adb6f",
    name: "Stories for Kids",
    uuid: "cf1ac366-9b59-4627-9b9a-84445658857e",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Swimming",
    uuid: "f8a4f3db-0ee9-4a4c-976e-31ea1cc74632",
  },
  { name: "TV & Film", uuid: "0cbdff14-20dc-41b3-a573-4f1d610cf84d" },
  {
    group_uuid: "0cbdff14-20dc-41b3-a573-4f1d610cf84d",
    name: "TV Reviews",
    uuid: "68aed84c-3be6-4827-b19e-edbe5b94609b",
  },
  {
    group_uuid: "9280af6c-72b1-486c-b241-41efc0abea3d",
    name: "Tech News",
    uuid: "c1fd1f46-ee03-40cb-af9e-a52167aa4b55",
  },
  { name: "Technology", uuid: "4e1cb02a-aad6-43b7-b6e9-ce503deff121" },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Tennis",
    uuid: "3c7b83c8-17ce-42de-8c5c-28f1113f3e1d",
  },
  { name: "True Crime", uuid: "d9fd16e9-3322-4e6d-8718-6eef2aead639" },
  {
    group_uuid: "b7e2e4df-63d0-4216-bef1-9dc465aba329",
    name: "Video Games",
    uuid: "f2f47117-7ba2-4823-816f-f6654badc14c",
  },
  {
    group_uuid: "00917086-bacc-4e01-9f94-eefcff96b8dd",
    name: "Visual Arts",
    uuid: "c5807d75-16c9-423e-878f-2821452d5c88",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Volleyball",
    uuid: "6e1c4b5d-dcc1-4886-914d-369862c8e79c",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Wilderness",
    uuid: "281a2ecb-0954-449c-baa9-f8fc055f870f",
  },
  {
    group_uuid: "b13b68be-0f4c-4a3b-b0a7-f9efc72f90a0",
    name: "Wrestling",
    uuid: "b7d22cee-38af-46a1-98ed-4979da150c55",
  },
];
export const itunesCategoriesMap = keyBy(itunesCategories, "uuid");
export const itunesCategoriesNodeEdge = groupBy(itunesCategories, "group_uuid");
export const itunesCategoriesSorted = itunesCategories
  .slice()
  .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB));

export const itunesCategoryUUIDToName = (uuid) => itunesCategoriesMap[uuid]?.name;
