import dayjs from "dayjs";
import { useState } from "react";
import RCButton from "src/components/lib/button";
import Loading from "src/components/lib/loading";
import MediaPlayer from "src/components/lib/media_player";
import { formatDuration } from "src/lib/duration";
import { IEpisode } from "redcircle-types";

export default function AdvertiserBrowseShowEpisodes({
  episodes,
  isLoading,
}: {
  episodes: IEpisode[];
  isLoading?: boolean;
}) {
  const [showAll, setShowAll] = useState(false);
  if (isLoading || !episodes) return <Loading />;

  const episodesToShow = showAll ? episodes : episodes.slice(0, 5);
  return (
    <div>
      {episodesToShow.map((episode) => (
        <div
          className={"flex-row-container p-txxs align-center"}
          key={episode.uuid}
          style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
          <div className="flex-1">
            <h5>{episode.title}</h5>
            <small className="subtitle">
              {dayjs(episode.publishedAt * 1000).format("ll")} - {formatDuration(episode.duration)}
            </small>
          </div>

          <div className={"flex-1"}>
            <MediaPlayer mediaFileURL={episode.streamURL} />
          </div>
        </div>
      ))}
      {!showAll && (
        <div className={"text-center m-txs"}>
          <RCButton type="link" onClick={() => setShowAll(true)}>
            View All ({episodes.length})
          </RCButton>
        </div>
      )}
    </div>
  );
}
