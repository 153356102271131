import dayjs from "dayjs";
import {
  SummaryPageInfo,
  SummaryPageSectionWrapper,
} from "src/components/lib/summary-page-sections/summary-page-sections";
import { getAverageCPM, getImpressionsFromBudget } from "src/lib/campaigns";
import { getCampaignItemField } from "src/lib/campaign_item";
import { ICampaign } from "redcircle-types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "redcircle-types";
import { formatStartAndEndDates } from "../../campaigns/campaign_page/campaign_page_utils";

export default function ShowCampaignTimeline({
  campaign,
  campaignItem,
  show,
}: {
  campaign: ICampaign;
  campaignItem?: ICampaignItem;
  show: IShow;
}) {
  if (!campaignItem) return null;

  const cpm = getAverageCPM({ show, campaign, campaignItem, when: "final" });
  const impressions = getImpressionsFromBudget({ cpm, budget: campaignItem.totalBudget });

  const estimatedWeeklyDownloads = show?.estimatedWeeklyDownloads ?? 1;
  const estimatedWeeks = impressions / estimatedWeeklyDownloads;
  const estimatedWeeksHumanized = dayjs.duration(estimatedWeeks, "weeks").humanize();

  const isPacing = getCampaignItemField("pacing", { campaignItem, campaign });
  const hardEndDate = getCampaignItemField("hardEndDate", { campaignItem, campaign });

  return (
    <SummaryPageSectionWrapper title={"Campaign Timeline"}>
      <>
        <div className="fs-13 flex-column-container m-bxs">
          <span className="m-bxxxs">
            <span className="bold">Pacing:</span> {isPacing ? "Even pacing" : "No pacing"}
          </span>
        </div>
        <div className="fs-13 flex-column-container m-bxs">
          <span className="m-bxxxs">
            <span className="bold">Estimated Timeline:</span>{" "}
            {formatStartAndEndDates(campaignItem, campaign, show, "LL")}
          </span>
          {!isPacing && (
            <span>
              We estimate that this campaign will run for about {estimatedWeeksHumanized} based on
              your CPM rates and show size.
            </span>
          )}
        </div>
        <SummaryPageInfo
          title="End Dates"
          text={hardEndDate ? "Hard" : "Soft"}
          tooltipText={
            hardEndDate
              ? "A campaign with a hard end date will end on that date whether it has fully delivered its downloads or not. If your show does less downloads than expected during the campaign or you're overbooked with host-reads, you may end up making less than the expected total for this campaign."
              : "A campaign with a soft end date will continue past the expected end date until the budget is spent.  If a campaign is running significantly over its timeline, it may still require ending early."
          }
        />
      </>
    </SummaryPageSectionWrapper>
  );
}
