import filter from "lodash/filter";
import { AnyAction, Reducer } from "redux";

const SUCCESS_TOKEN = "_SUCCESS";
const FAILURE_BAD_REQUEST_TOKEN = "_FAILURE_BADREQUEST";
const FAILURE_UNAUTHORIZED_TOKEN = "_FAILURE_UNAUTHORIZED";
const FAILURE_TOKEN = "_FAILURE";
const STARTED_TOKEN = "_STARTED";

const getActionNames = (actionName: string) => ({
  failure: actionName + FAILURE_TOKEN,
  failureBadRequest: actionName + FAILURE_BAD_REQUEST_TOKEN,
  failureUnauthorized: actionName + FAILURE_UNAUTHORIZED_TOKEN,
  started: actionName + STARTED_TOKEN,
  success: actionName + SUCCESS_TOKEN,
});

export interface HTTPAction<ACTION_NAME extends string> extends AnyAction {
  payload: { resp: any; body?: any; validationErrors: any[] };
  body: any;
  data?: any;
  type: ACTION_NAME;
  requestID?: string;
}

function defaultReducer<T>(state: T): T {
  return state;
}

export interface IReducersMap<T, ACTION_NAME extends string> {
  failure?: Reducer<T>;
  failureBadRequest?: Reducer<T>;
  started?: Reducer<T>;
  success?: Reducer<T, HTTPAction<ACTION_NAME>>;
  failureUnauthorized?: Reducer<T>;
}

const defaultReducers = {
  failure: defaultReducer,
  failureBadRequest: (state: any, action: AnyAction) => ({
    ...state,
    validationErrors: action.payload?.validationErrors,
  }),
  started: defaultReducer,
  success: defaultReducer,
};

function httpMultipleActionsReducer<T, ACTION_NAME extends string>(
  actionNames: string[] = [],
  initialState: T,
  reducers: IReducersMap<T, ACTION_NAME> = {},
  config = {}
) {
  return (state: T = initialState, action: HTTPAction<ACTION_NAME>) => {
    const matchingActions = filter(actionNames, (actionName: ACTION_NAME) =>
      action.type.startsWith(actionName)
    ) as string[];
    if (matchingActions.length > 0) {
      return httpReducer(
        matchingActions[0] as ACTION_NAME,
        initialState,
        reducers,
        config
      )(state, action);
    }
    return state || initialState;
  };
}

function httpReducer<T, ACTION_NAME extends string>(
  actionName: ACTION_NAME,
  initialState: T,
  reducers: IReducersMap<T, ACTION_NAME>,
  config: { loadingKeyName?: string } = {}
): Reducer<T, HTTPAction<ACTION_NAME>> {
  return (state: T = initialState, action) => {
    const httpReducers = { ...defaultReducers, ...reducers };
    state = state || initialState;
    const loadingKeyName = config.loadingKeyName || "isLoading";
    const actionNames = getActionNames(actionName);
    try {
      switch (action.type) {
        case actionNames.success:
          return httpReducers.success({ ...state, [loadingKeyName]: false }, action);
        case actionNames.failure:
          return httpReducers.failure({ ...state, [loadingKeyName]: false }, action);
        case actionNames.started:
          return httpReducers.started({ ...state, [loadingKeyName]: true }, action);
        case actionNames.failureBadRequest: {
          const badRequestReducer = httpReducers.failureBadRequest || httpReducers.failure;
          return badRequestReducer({ ...state, [loadingKeyName]: false }, action);
        }
        case actionNames.failureUnauthorized: {
          const unauthorizedReducer = httpReducers.failureUnauthorized || httpReducers.failure;
          return unauthorizedReducer({ ...state, [loadingKeyName]: false }, action);
        }
        default:
          return state;
      }
    } catch (e) {
      /*eslint-disable */
      if (process.env.NODE_ENV !== "production") {
        /*eslint-enable */
        // for some reason, javascript errors that occur in reducers gets swallowed, making it really hard to debug
        // tslint:disable-next-line:no-console
        console.error(e);
      }
      throw e;
    }
  };
}

// type ReduceReducers <T> = (reducers: IReducerFN<T>[]) => IReducerFN<T>
function reduceReducers<T>(...reducers: Array<Reducer<T>>): Reducer<T> {
  return (state: T, action: any) => {
    // tslint:disable-next-line:no-shadowed-variable
    return reducers.reduce((state, reducer) => reducer(state, action), state);
  };
}
export { httpReducer, httpMultipleActionsReducer, reduceReducers, getActionNames };
