const getAPIPrefix = () => {
  if (
    process.env.REACT_APP_ENVIRONMENT === "prod" ||
    process.env.REACT_APP_ENVIRONMENT === "staging"
  ) {
    return "/api/";
  } else {
    return "/";
  }
};

export default getAPIPrefix;
