import { Alert } from "antd";
import { useContext, useMemo, useState } from "react";
import { Button, Modal } from "redcircle-ui";
import { showWarning } from "src/actions/app";
import { assignScripts } from "src/action_managers/campaigns";
import { CampaignItemStateDraft } from "src/constants/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { isScriptRequired } from "src/lib/campaigns";
import { AssignScript, calcInitialValues, calculateReqBody } from "./campaign_script_utils";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

export default function SchedulerAssignScriptsPage() {
  const { campaign, campaignItems, scriptAssignmentState, setScriptAssignmentState } =
    useContext(CampaignSchedulerContext);
  const { scriptsByCampaignUUID } = useSelectorTS((state) => state?.scripts);
  const scripts = (campaign && scriptsByCampaignUUID[campaign.uuid]) || [];

  const scriptRequired = useMemo(() => {
    if (campaign) return isScriptRequired(campaign);
  }, [campaign?.startsAt]);

  const publicShows = useSelectorTS((state) => state.publicShows);
  const draftItems = campaignItems.filter((item) => item.state === CampaignItemStateDraft);

  return (
    <>
      {campaign?.isV2 && scriptRequired && (
        <Alert
          message="The podcaster's audio due deadline is less than 7 days out - please assign all scripts soon."
          type="warning"
          className="m-bxxs"
        />
      )}
      <AssignScript
        publicShowState={publicShows}
        campaignItems={draftItems}
        scripts={scripts}
        localScriptAssignments={scriptAssignmentState}
        setLocalScriptAssignments={setScriptAssignmentState}
      />
    </>
  );
}

export const SchedulerAssignScriptsPageFooter = ({
  onBack,
  onSubmitSuccess,
}: {
  onBack: () => void;
  onSubmitSuccess: () => void;
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showCheckModal, setShowCheckModal] = useState(false);

  const dispatch = useDispatchTS();
  const { campaign, campaignItems, scripts, scriptAssignmentState } =
    useContext(CampaignSchedulerContext);
  const draftItems = campaignItems.filter((item) => item.state === CampaignItemStateDraft);
  const publicShows = useSelectorTS((state) => state.publicShows);
  const { scriptsByUUID } = useSelectorTS((state) => state?.scripts);

  // SCRIPT FLAGS
  const scriptRequired = useMemo(() => {
    if (campaign) return isScriptRequired(campaign);
  }, [campaign?.startsAt]);

  const assignedScriptsFromPrevCarts =
    campaignItems?.filter((item) => !!item.scriptUUID)?.map((item) => item.scriptUUID) ?? [];
  const assignedShows = Object.keys(scriptAssignmentState ?? {}).filter((campaignItemUUID) => {
    const assignedScriptUUID = scriptAssignmentState[campaignItemUUID]?.scriptUUID;
    // if script exists, then it's assigned
    return scriptsByUUID?.[assignedScriptUUID];
  });
  const unassignedShows = draftItems?.filter((item) => !assignedShows.includes(item?.uuid)) ?? [];
  const assignedScripts = Object.values(scriptAssignmentState ?? {})
    .map((item: any) => item.scriptUUID)
    .filter((uuid) => !!scriptsByUUID?.[uuid])
    .concat(assignedScriptsFromPrevCarts);
  const unassignedScripts =
    scripts?.filter((script) => !assignedScripts.includes(script.uuid)) ?? [];

  const initialAssignedState = calcInitialValues({
    items: draftItems,
    publicShows,
    scriptsByUUID,
  });

  // validate script assignments first
  const handleSubmit = async () => {
    if (campaign) {
      if (!campaign.isV2 && (unassignedScripts.length > 0 || unassignedShows.length > 0)) {
        setShowCheckModal(true);
      } else {
        await submit();
      }
    }
  };

  const submit = async () => {
    if (campaign) {
      setIsSubmitLoading(true);
      try {
        const requestBody = calculateReqBody(initialAssignedState, scriptAssignmentState);
        await dispatch(assignScripts(campaign.uuid, requestBody));
        onSubmitSuccess();
      } catch (err) {
        dispatch(showWarning("There was an issue assigning the script(s) for this campaign"));
      }
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Button type="link" className="p-a0" size="large" onClick={onBack}>
        Back
      </Button>
      <Button type="primary" size="large" onClick={handleSubmit} loading={isSubmitLoading}>
        Continue
      </Button>

      <Modal size="xs" open={showCheckModal} onClose={() => setShowCheckModal(false)}>
        <Modal.Title>Unassigned Items</Modal.Title>
        <Modal.Body>
          {unassignedScripts.length > 0 && (
            <>
              <p>The following scripts have not been assigned to any podcasts:</p>
              <ul>
                {unassignedScripts.map((script) => (
                  <li key={script.uuid}>
                    <strong>{script.name}</strong>
                  </li>
                ))}
              </ul>
            </>
          )}
          {unassignedShows.length > 0 && (
            <>
              <p>The following podcasts have not been assigned a script:</p>
              <ul>
                {unassignedShows.map((campaignItem) => {
                  const show = publicShows?.[campaignItem?.showUUID];
                  return (
                    <li key={show?.title}>
                      <strong>{show?.title}</strong>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          <p>Do you want to continue?</p>
          {unassignedShows.length > 0 && (
            <strong>
              {scriptRequired
                ? "Because your campaign is less than 15 days out, a script is required for all podcasts for you to send the campaign."
                : "You will be able to assign a script later."}
            </strong>
          )}
        </Modal.Body>
        <Modal.Footer noBorder>
          <Button
            type="link"
            className="p-a0"
            size="large"
            onClick={() => setShowCheckModal(false)}>
            Reassign Scripts
          </Button>
          <Button
            type="primary"
            size="large"
            disabled={scriptRequired && unassignedShows.length > 0}
            onClick={() => {
              setShowCheckModal(false);
              submit();
            }}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
