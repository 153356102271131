import React from "react";
import { classNames } from "react-extras";
import styles from "./EmptyStateBlock.module.scss";

export default function EmptyStateBlock({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={classNames(className, styles.block, "text-subtle p-ts p-bs p-rs p-ls")}>
      {children}
    </div>
  );
}
