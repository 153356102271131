import get from "lodash/get";
import React, { Component } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { If } from "react-extras";
import { connect } from "react-redux";
import { showModal } from "src/actions/modal";
import RCButton from "src/components/lib/button/button";
import { CONFIRMATION_MODAL } from "src/components/modals/modal_root";
import { isShowRedirected } from "src/lib/show";
import {
  fetchPublicShow,
  fetchPublicShowForSubscriber,
} from "../../../action_managers/public_show";
import {
  cancelSubscription,
  fetchSubscription,
  fetchSubscriptionForUser,
} from "../../../action_managers/subscriptions";
import { AlbumDisplay } from "../../lib/album_art";
import Divider from "../../lib/divider";
import Spinner from "../../lib/spinner";
import "./sponsor_show_list.scss";

const popularShows = [
  {
    title: "CzabeCast Premium",
    uuid: "2a60ac83-f8ca-48fd-92dd-6fc24ba3b094",
  },
  {
    title: "The Jocko Podcast",
    uuid: "64a89f88-a245-4098-8d8d-496325ec4f74",
  },
  {
    title: "Doctrine and Devotion",
    uuid: "d9afaeae-f348-4994-9bfa-8e2a8786be06",
  },
];

class SponsorShowList extends Component {
  state = {
    fetchingShows: false,
  };

  componentDidMount() {
    this.setState({ fetchingShows: true });
    Promise.all(
      popularShows.map((popularShow) => this.props.fetchPublicShowForSubscriber(popularShow.uuid))
    ).finally(() => this.setState({ fetchingShows: false }));

    this.props.fetchSubscriptionForUser(this.props.user.uuid);
  }

  getActiveSubscriptionIDs = () => {
    return this.getSubscriptionIDs().filter(
      (subID) => this.props.subscriptions[subID].state === "active"
    );
  };

  getInactiveSubscriptionIDs = () => {
    return this.getSubscriptionIDs().filter(
      (subID) => this.props.subscriptions[subID].state !== "active"
    );
  };

  getSubscriptionIDs = () => {
    return Object.keys(this.props.subscriptions).filter(
      (subscriptionID) => this.props.subscriptions[subscriptionID].userUUID === this.props.user.uuid
    );
  };

  subscriptionsByShowUUID = () => {
    return Object.entries(this.props.subscriptions).reduce((accu, curr) => {
      const [key, val] = curr;

      if (!accu[val?.showUUID]) {
        accu[val.showUUID] = [];
      }

      accu[val?.showUUID].push(key);

      return accu;
    }, {});
  };

  checkifShowHasSubscription = (show) => {
    const { uuid: showUUID } = show;

    const subs =
      this.subscriptionsByShowUUID()[showUUID]?.map(
        (subsUUID) => this.props.subscriptions[subsUUID]
      ) ?? [];

    return subs.some((sub) => sub.state === "active");
  };

  cancelSubscription = (subUUID) => {
    return this.props.cancelSubscriptionModal(subUUID, (uuid) => {
      return this.props.cancelSubscription(uuid).then(() => {
        return this.props.fetchSubscription(subUUID);
      });
    });
  };

  renderAlbumArts = ({ shows }) => {
    if (!get(shows, "length")) {
      return <div>You're not sponsoring any shows yet.</div>;
    }

    return (
      <Row>
        {shows.map((show) => {
          const isRedirected = isShowRedirected(show);

          const subscriptionActive = this.checkifShowHasSubscription(show);
          const subUUID = this.subscriptionsByShowUUID()[show.uuid]?.[0];

          return (
            <Col xs={6} sm={4} lg={3} key={show.uuid}>
              <AlbumDisplay
                src={show.imageURL}
                title={show.title}
                toURL={isRedirected ? "/sponsor-show-list" : `/shows/${show.uuid}/sponsor`}
                isRedirected={isRedirected}
              />
              <If condition={!subscriptionActive && !isRedirected}>
                <RCButton
                  type="primary"
                  size="large"
                  className="width-100 m-txxxs"
                  onClick={this.gotoURL(`/shows/${show.uuid}/resubscribe`)}>
                  Resubscribe
                </RCButton>
              </If>
              {/**
               * Quick cancel button for podcasts that redirected away from Redcircle and user still has active
               * subscription for said podcasts.
               */}
              <If condition={subscriptionActive && isRedirected}>
                <Button
                  className={"btn btn-primary width-100 m-ts"}
                  onClick={() => this.cancelSubscription(subUUID)}>
                  Cancel Subscription
                </Button>
              </If>
            </Col>
          );
        })}
      </Row>
    );
  };

  gotoURL = (url) => {
    return () => {
      this.props.history.push(url);
      window.scrollTo(0, 0);
    };
  };

  render() {
    const activeSubIDs = this.getActiveSubscriptionIDs() || [];
    const activeShows = activeSubIDs.map((uuid) => this.props.subscriptions[uuid].show);

    const inactiveSubIDs = this.getInactiveSubscriptionIDs() || [];
    const inactiveShows = inactiveSubIDs.map((uuid) => this.props.subscriptions[uuid].show);
    const isLoading = this.props.isLoading || this.state.fetchingShows > 0;

    // Don't render a popular show if already subscribed.
    const allShowUUIDs = inactiveShows.concat(activeShows).map((show) => show.uuid);
    const popularShowsToRender = popularShows
      .map((show) => this.props.publicShows[show.uuid])
      .filter((show) => show)
      .filter((show) => !allShowUUIDs.includes(show.uuid));

    if (isLoading) {
      return (
        <div className={"sponsor-show-list p-vxxl"}>
          <Spinner isLoading={true} />
        </div>
      );
    }
    return (
      <div className={"sponsor-show-list"}>
        <Grid fluid>
          <Row>
            <Col md={8} mdOffset={2}>
              <h2>Podcasts you're sponsoring</h2>
              <Divider smallMargin />
              {this.renderAlbumArts({ shows: activeShows })}
            </Col>
          </Row>
          <If condition={inactiveSubIDs.length > 0}>
            <Row>
              <Col md={8} mdOffset={2}>
                <h2 className={"m-txl"}>Podcasts you're no longer sponsoring</h2>
                <Divider smallMargin />
                {this.renderAlbumArts({ shows: inactiveShows })}
              </Col>
            </Row>
          </If>
          <Row>
            <Col md={8} mdOffset={2}>
              <h2 className={"m-txl"}>Popular podcasts to sponsor</h2>
              <Divider smallMargin />
              {this.renderAlbumArts({ shows: popularShowsToRender })}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    subscriptions: state.subscriptions.subscriptions,
    publicShows: state.publicShows,
    isLoading: state.subscriptions.isLoading || state.publicShows.isLoading,
  };
}

export default connect(mapStateToProps, {
  cancelSubscriptionModal: (uuid, callback) => {
    return showModal(CONFIRMATION_MODAL, {
      modalTitle: "Are you sure?",
      confirmationMessage:
        "If you cancel your sponsorship, you'll no longer be supporting this podcaster's work. You'll also lose access to their exclusive content at the end of your current billing cycle.",
      callback: () => callback(uuid),
      actionButtonName: "Stop Sponsoring",
    });
  },
  cancelSubscription,
  fetchPublicShow,
  fetchPublicShowForSubscriber,
  fetchSubscriptionForUser,
  fetchSubscription,
})(SponsorShowList);
