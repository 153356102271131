import get from "lodash/get";
import React, { Component } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { If } from "react-extras";
import { connect } from "react-redux";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { seedcastForm } from "../../../lib/forms";
import FileUploadV2 from "../../forms/file_upload/file_upload_v2";
import FormElement from "../../forms/form_element";
import { FormStep, FormStepDots } from "../../forms/form_steps/form_steps";
import LoadingButton from "../../forms/loading_button/loading_button";
import Divider from "../../lib/divider";
import ExternalLink from "../../lib/external_link";
import "./deal_request_form.scss";

class DealRequestForm extends Component {
  state = {
    step: 1,
    validationErrors: [],
  };

  render() {
    return (
      <div>
        <form onSubmit={this.props.handleSubmit}>
          <Grid fluid>
            <div className="deal-request-form-content">
              <Row>
                <Col xs={12} md={10} mdOffset={1}>
                  <FormStep step={1} currentStep={this.state.step}>
                    <DealRequestStep
                      title="What promo will you run?"
                      description="Upload an mp3 of the ad for your show.  It will be played on the other podcast."
                      FormItem={this.mp3Step}
                    />
                  </FormStep>
                  <FormStep step={2} currentStep={this.state.step}>
                    <DealRequestStep
                      title="When will the promo start?"
                      description="The ad will run from the start date until the campaign completes"
                      FormItem={this.dateStep}
                    />
                  </FormStep>
                </Col>
              </Row>
            </div>
            <div className="deal-request-form__dots">
              <Row>
                <Col xs={12} md={10} mdOffset={1}>
                  <FormStepDots numSteps={2} currentStep={this.state.step} />
                </Col>
              </Row>
            </div>
            <Row className="deal-reaquest-form__buttons">{this.buttons()}</Row>
          </Grid>
        </form>
      </div>
    );
  }

  mp3Step = () => {
    return (
      <div>
        <Field
          component={FormElement}
          InputComponent={FileUploadV2}
          label="Upload MP3"
          name="contentFile"
          mediaFiles={this.props.mediaFiles}
          mediaFileUUID={get(this.props, "initialValues.mediaFileUUIDs.128")}
          validationErrors={this.validationErrors()}
          validationErrorKeyOverride={"mediaFileUUIDs"}
          infoText={
            "Upload a 30-60 second audio clip promoting your podcast. <br/>This clip will be played as an intro on the recipients podcast."
          }
        />
        <Field component={FormElement} type="hidden" name="mediaFileUUIDs" />
        <div className="mp3-upload-step__help-text">
          Check out{" "}
          <ExternalLink href="https://support.redcircle.com/creating-a-great-promotional-ad">
            this article
          </ExternalLink>{" "}
          for tips on producing great ad audio.
        </div>
      </div>
    );
  };

  dateStep = () => {
    return (
      <Field
        name="airsAt"
        component={FormElement}
        InputComponent={RenderDateTimePicker}
        valueField="value"
        label="Start Date"
        validationErrors={this.validationErrors()}
        infoText={
          "This is the date and time the promotions will start playing on each others episodes."
        }
      />
    );
  };

  buttons = () => (
    <div className="deal-request-form__buttons">
      <Col xs={6} md={5} mdOffset={1}>
        <If condition={this.state.step === 1}>
          <Button
            className="btn-secondary"
            onClick={(e) => {
              this.props.close();
            }}>
            Cancel
          </Button>
        </If>
        <If condition={this.state.step !== 1}>
          <Button
            className="btn-secondary"
            onClick={() => {
              this.setState({ step: this.state.step - 1 });
            }}>
            Back
          </Button>
        </If>
      </Col>
      <Col xs={6} md={5}>
        <If condition={this.state.step !== 2}>
          <Button
            className="btn-primary"
            onClick={() => {
              this.incrementStepAndValidate();
            }}>
            Continue
          </Button>
        </If>
        <If condition={this.state.step === 2}>
          <LoadingButton isLoading={this.props.isPutting} type="submit" className="btn btn-primary">
            Finish
            {Boolean(this.props.progress) && " (" + Math.round(this.props.progress * 95) + "%)"}
          </LoadingButton>
        </If>
      </Col>
    </div>
  );

  incrementStepAndValidate = () => {
    switch (this.state.step) {
      case 1:
        if (!this.props.formValues.mediaFileUUIDs && !this.props.formValues.contentFile) {
          this.setState({
            validationErrors: [
              {
                fieldName: "mediaFileUUIDs",
                errorMessage: "You must upload an audio file.",
              },
            ],
          });
          return;
        } else {
          this.setState({
            validationErrors: [],
          });
          break;
        }
      default:
        if (!this.props.formValues.airsAt) {
          this.setState({
            validationErrors: [
              {
                fieldName: "airsAt",
                errorMessage: "You must selct a start date for the ad.",
              },
            ],
          });
          return;
        } else {
          this.setState({
            validationErrors: [],
          });
        }
    }
    this.setState({ step: this.state.step + 1 });
  };

  validationErrors = () => {
    return this.state.validationErrors.concat(this.props.validationErrors || []);
  };
}

class DealRequestStep extends Component {
  render() {
    let { FormItem } = this.props;
    return (
      <div className="deal-request-step">
        <h1 className="deal-request-step__title">{this.props.title}</h1>
        <div className="deal-request-step__description">{this.props.description}</div>
        <Divider />
        <FormItem />
      </div>
    );
  }
}

const RenderDateTimePicker = ({ onChange, value }) => {
  return (
    <DateTimePicker
      onChange={onChange}
      time={true}
      value={!value ? new Date() : new Date(value)}
      step={5}
    />
  );
};

DealRequestForm = seedcastForm(
  reduxForm({
    form: "deal-request",
  })(DealRequestForm)
);

DealRequestForm = connect((state) => {
  return {
    formValues: formValueSelector("deal-request")(
      state,
      "airsAt",
      "mediaFileUUIDs",
      "contentFile",
      "showUUID"
    ),
  };
})(DealRequestForm);

export default DealRequestForm;
