/*
 * Be sure to test this page in both signed-in and signed-out. this feature has been a wild ride, folks.
 * */

import get from "lodash/get";
import partial from "lodash/partial";
import moment from "moment";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { Choose, classNames, If } from "react-extras";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { showWarning } from "../../../actions/app";
import { showModal } from "../../../actions/modal";
import { showTip } from "../../../actions/shows";
import { addRole } from "../../../action_managers/account";
import {
  fetchPublicShow,
  fetchPublicShowForSubscriber,
} from "../../../action_managers/public_show";
import {
  cancelSubscription,
  fetchSubscription,
  getPaymentMethod,
  undoCancelSubscription,
} from "../../../action_managers/subscriptions";
import UserRoles from "../../../constants/roles";
import { formatDuration } from "../../../lib/duration";
import { formatMoney } from "../../../lib/format-money";
import { userHasRole } from "../../../lib/user";
import { getSubscriptionUUID } from "../../../lib/uuid";
import { AlbumArt } from "../../lib/album_art";
import Divider from "../../lib/divider";
import ExclusiveTag from "../../lib/exclusive_tag";
import ExpandableText from "../../lib/expandable_text/expandable_text";
import HighlightBox from "../../lib/highlight_box";
import LoggedOutFrame from "../../lib/logged_out_frame";
import MediaPlayer from "../../lib/media_player/media_player";
import { withPageTitle } from "../../lib/WithPageTitle";
import {
  CONFIRMATION_MODAL,
  LISTEN_ON_MODAL,
  SPONSORSHIP_MODAL,
  TIP_MODAL,
} from "../../modals/modal_root";
import "./sponsor_show_page.scss";

class SponsorShowPage extends Component {
  state = {
    expandedEpisode: "",
    subscription: {},
    currentCardID: "",
  };

  componentDidMount() {
    if (this.props.user && !userHasRole(this.props.user, UserRoles.Sponsor)) {
      this.props.addRole(this.props.user, UserRoles.Sponsor);
    }
    this.getShowAndSubscription().then(() => {
      const sub = this.getSubscription();
      const show = this.getShow();

      // prop flag obtained from redirect origin, used to immediately resubscribe user
      if (this.props.resubscribe) {
        if (sub && sub.type === "exclusive") {
          // Check if the cancelled sub is currently pending to be cancelled or fully cancelled;
          if (this.subscriptionIsPendingCancelled()) {
            this.undoCancelSubscription();
          } else {
            this.showExclusiveModal(show);
          }
        } else {
          this.showDonationModal(show, sub.amount);
        }
      }
    });
  }

  getSubscriptionUUID = () => getSubscriptionUUID(get(this.props.user, "uuid"), this.getShowUUID());

  getShowAndSubscription = async () => {
    let sub;
    if (this.props.user) {
      sub = this.props.fetchSubscription(this.getSubscriptionUUID());
      await this.props.fetchPublicShowForSubscriber(this.getShowUUID());
    } else {
      await this.props.fetchPublicShow(this.getShowUUID());
    }

    return sub;
  };

  getSubscription = () => {
    return get(this.props.subscriptions, this.getSubscriptionUUID());
  };

  getShowUUID = () => {
    return get(this.props, "match.params.showUUID");
  };

  getShow = () => get(this.props, ["publicShows", this.getShowUUID()]);
  renderShowTitle = (opts) => {
    let className = "hidden-xs visible-sm visible-md visible-lg visible-xl";
    if (opts && opts.forMobile) {
      className = "visible-xs hidden-sm hidden-md hidden-lg hidden-xl text-center";
    }
    const show = this.getShow();
    if (!show) {
      return null;
    }
    return (
      <div className={className}>
        <h1>{show.title}</h1>
        <Divider smallMargin />
        <If condition={!opts || !opts.forMobile}>
          <ExpandableText dangerousText={show.description} heightToWidth={4} />
        </If>
      </div>
    );
  };

  cancelSubscription = () => {
    return this.props.cancelSubscriptionModal(get(this.getSubscription(), "uuid"), (uuid) => {
      return this.props.cancelSubscription(uuid).then(() => {
        return this.getShowAndSubscription();
      });
    });
  };

  undoCancelSubscription = () => {
    const uuid = get(this.getSubscription(), "uuid");
    return this.props.undoCancelSubscriptionModal(uuid, (uuid) => {
      return this.props.undoCancelSubscription(uuid).then((resp) => {
        return this.getShowAndSubscription();
      });
    });
  };

  goToSignin = () => {
    this.props.history.push({
      pathname: "/sign-up/sponsor",
      search: `?goto=/shows/${this.getShowUUID()}/sponsor`,
    });
  };

  renderEpisodeList = () => {
    const show = this.getShow();
    if (!show) {
      return null;
    }

    return (
      <div className={"episode-list"}>
        {show.episodes.map((episode) => {
          const expanded = this.state.expandedEpisode === episode.uuid;
          const cannotAccess = !episode.streamURL;
          return (
            <div
              key={episode.uuid}
              className={classNames("episode-list-item", { "episode-list-collapsed": !expanded })}
              onClick={() => {
                if (cannotAccess) {
                  return;
                }
                expanded
                  ? this.setState({ expandedEpisode: "" })
                  : this.setState({ expandedEpisode: episode.uuid });
              }}>
              <div
                className={classNames(
                  "episode-title flex-row-container align-center",
                  "bold",
                  cannotAccess && "cannot-access"
                )}>
                <span className={"episode-title-text"}>{episode.title}</span>
                {this.isExclusive(episode) && (
                  <ExclusiveTag disabled={cannotAccess}>exclusive</ExclusiveTag>
                )}
              </div>
              <div className={classNames("episode-published-at", cannotAccess && "cannot-access")}>
                {moment.unix(episode.publishedAt).format("MMM DD, YYYY")} -{" "}
                {formatDuration(episode.duration)}
              </div>
              <CSSTransition
                classNames={"episode-list"}
                mountOnEnter={true}
                unmountOnExit={true}
                in={expanded}
                timeout={300}>
                <div onClick={(e) => e.stopPropagation()}>
                  <div
                    className="episode-description"
                    dangerouslySetInnerHTML={{
                      __html: episode.description,
                    }}
                  />
                  {episode.streamURL && (
                    <MediaPlayer
                      mediaFileURL={episode.streamURL}
                      menuItems={{
                        Download: () => this.download(episode),
                      }}
                    />
                  )}
                </div>
              </CSSTransition>
            </div>
          );
        })}
      </div>
    );
  };

  download = (episode) => {
    window.open(episode.streamURL, "_blank");
  };

  isExclusive = (episode) => {
    return !episode.shouldPublishToNonSubscribers && episode.shouldPublishToSubscribers;
  };

  subscriptionIsActive = () => {
    return get(this.getSubscription(), "state") === "active";
  };

  subscriptionIsCancelled = () => get(this.getSubscription(), "state") === "canceled";

  // Due to stripe process issues, when a sub is cancelled its state goes to "canceled_at_period_end", which is pending cancellation,
  // once the period of time pending is done, it becomes fully "cancelled"
  subscriptionIsPendingCancelled = () =>
    get(this.getSubscription(), "state") === "canceled_at_period_end";

  subscriptionIsExclusive = () => {
    return get(this.getSubscription(), "type") === "exclusive";
  };

  onSubscriptionDone = () => {
    this.getShowAndSubscription();
  };

  showModal = (props) => {
    this.props.showModal(props);
  };

  isExclusiveEnabled = () => {
    return get(this.getShow(), "subscriptionDetails.exclusiveContentEnabled", false);
  };

  isDonationsEnabled = () => {
    return get(this.getShow(), "subscriptionDetails.recurringDonationsEnabled", false);
  };

  launchTipModal = () => {
    return this.props.launchTipModal({
      mode: "tip",
    });
  };

  perTextFromInterval = (interval) => {
    if (!interval) {
      return "";
    }

    if (interval === "weekly") {
      return "per week";
    }
    if (interval === "annual") {
      return "per year";
    }

    return "per month";
  };

  renderLeft = () => {
    const show = this.getShow();
    if (!show) {
      return null;
    }

    return (
      <div className={"left-column"}>
        <div className="hidden-xs visible-sm visible-md visible-lg visible-xl">
          <AlbumArt src={show.imageURL} alt="album-art" />
        </div>
        <div className="visible-xs hidden-sm hidden-md hidden-lg hidden-xl">
          <Row>
            <Col xs={6} xsOffset={3}>
              <AlbumArt src={show.imageURL} alt="album-art" />
            </Col>
          </Row>
        </div>

        {this.renderShowTitle({ forMobile: true })}
        <div className="link-row bold">
          <If
            condition={
              this.subscriptionIsExclusive() &&
              (this.subscriptionIsActive() || this.subscriptionIsPendingCancelled())
            }>
            <button
              onClick={() =>
                this.props.launchListenOnModal({ subscriptionUUID: this.getSubscriptionUUID() })
              }
              className={"btn btn-primary width-100"}>
              Listen In App
            </button>
          </If>
        </div>

        <Choose>
          <Choose.When condition={this.subscriptionIsActive()}>
            <div className="sponsor-show-info">
              <HighlightBox
                title={"You're sponsoring this podcast for"}
                value={`${formatMoney(
                  get(this.getSubscription(), "amount")
                )} ${this.perTextFromInterval(get(this.getSubscription(), "interval"))}`}
              />
              <HighlightBox
                title={"Your next payment will be on"}
                value={moment
                  .unix(get(this.getSubscription(), "nextPaymentAt", 0))
                  .format("MMMM Do YYYY")}
              />
            </div>
            <div className={"bold bottom-links"}>
              <button className={"fake-link"} onClick={this.showEditModal}>
                Update Payment
              </button>
            </div>
            <div className={"bold bottom-links"}>
              <button className={"fake-link"} onClick={this.cancelSubscription}>
                Cancel Sponsorship
              </button>
            </div>
          </Choose.When>
          <Choose.When condition={this.subscriptionIsPendingCancelled()}>
            <div className="sponsor-show-info">
              <HighlightBox
                title={"You're sponsoring this podcast for"}
                value={`${formatMoney(
                  get(this.getSubscription(), "amount")
                )} ${this.perTextFromInterval(get(this.getSubscription(), "interval"))}`}
              />
              <HighlightBox
                title={"Access to subscription will end on"}
                value={moment
                  .unix(get(this.getSubscription(), "nextPaymentAt", 0))
                  .format("MMMM Do YYYY")}
              />
            </div>

            <div className={"bold bottom-links"}>
              <button className={"fake-link"} onClick={this.undoCancelSubscription}>
                Reactivate Sponsorship
              </button>
            </div>
          </Choose.When>
          <Choose.Otherwise>
            <div className="sponsor-show-info">
              <If condition={this.isExclusiveEnabled()}>
                <div className="header bold">EXCLUSIVE CONTENT</div>
                <div className="content-section">
                  <p>Get access to exclusive content from this podcast.</p>
                  <div className="fake-link" onClick={(show) => this.showExclusiveModal(show)}>
                    Unlock Exclusive Content
                  </div>
                </div>
              </If>
            </div>
            <If condition={this.isDonationsEnabled()}>
              <div className="sponsor-show-info">
                <div className="header bold">DONATIONS</div>
                <div className="content-section">
                  <p>Set your own one-time or monthly donation amount.</p>
                  <div className="fake-link" onClick={(show) => this.showDonationModal(show)}>
                    Recurring Donation
                  </div>
                  <div className="fake-link m-ts" onClick={this.launchTipModal}>
                    One-Time Donation
                  </div>
                </div>
              </div>
            </If>
          </Choose.Otherwise>
        </Choose>
      </div>
    );
  };

  showExclusiveModal = (show) => {
    this.showModal({
      mode: "subscribe",
      show,
      onDone: this.onSubscriptionDone,
    });
  };

  showDonationModal = (show, amount = 0) => {
    this.showModal({
      mode: "custom",
      show,
      amount,
      onDone: this.onSubscriptionDone,
      interval: "monthly",
    });
  };

  showEditModal = () => {
    this.showModal({
      mode: this.getSubscription().type === "donation" ? "custom" : "subscribe",
      amount: this.getSubscription().amount,
      onDone: this.onSubscriptionDone,
      currentCardID: this.state.currentCardID,
      isEdit: true,
      submitCallback: this.getShowAndSubscription,
    });
  };

  render() {
    return (
      <LoggedOutFrame
        noBar={true}
        hideHeader={!!this.props.user}
        signInLink={`/sign-in/sponsor?goto=${encodeURIComponent(
          `/shows/${this.getShowUUID()}/sponsor`
        )}`}
        signUpLink={`/sign-up/sponsor?goto=${encodeURIComponent(
          `/shows/${this.getShowUUID()}/sponsor`
        )}`}>
        <div className={"sponsor-show-page"}>
          <Grid>
            <Row>
              <Col sm={3}>{this.renderLeft()}</Col>
              <Col sm={8} smOffset={1}>
                {this.renderShowTitle()}
                {this.renderEpisodeList()}
              </Col>
            </Row>
          </Grid>
        </div>
      </LoggedOutFrame>
    );
  }
}

function mapStateToProps(state) {
  return {
    publicShows: state.publicShows,
    subscriptions: state.subscriptions.subscriptions,
    user: state.user.user,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    showModal: (props) => showModal(SPONSORSHIP_MODAL, props),
    fetchPublicShow,
    cancelSubscriptionModal: (uuid, callback) => {
      return showModal(CONFIRMATION_MODAL, {
        modalTitle: "Are you sure?",
        confirmationMessage:
          "If you cancel your sponsorship, you'll no longer be supporting this podcaster's work. You'll also lose access to their exclusive content at the end of your current billing cycle.",
        callback: () => callback(uuid),
        actionButtonName: "Stop Sponsoring",
      });
    },
    undoCancelSubscriptionModal: (uuid, callback) => {
      return showModal(CONFIRMATION_MODAL, {
        modalTitle: "Reactivate Sponsorship",
        confirmationMessage:
          "By reactivating your sponsorship to this podcast you will continue to have access to their exclusive content.",
        callback: () => callback(uuid),
        actionButtonName: "Reactivate",
      });
    },
    cancelSubscription,
    undoCancelSubscription,
    fetchPublicShowForSubscriber,
    fetchSubscription,
    getPaymentMethod,
    showWarning,
    addRole,
    showTip,
    launchListenOnModal: partial(showModal, LISTEN_ON_MODAL),
    launchTipModal: partial(showModal, TIP_MODAL),
  })(withPageTitle(SponsorShowPage, "Sponsor Show"))
);
