import * as Sentry from "@sentry/browser";
import React from "react";
import { Col, Row } from "react-bootstrap";
import LoggedOutFrame from "./lib/logged_out_frame";

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // for now we won't do anything here
    Sentry.withScope((scope) => {
      scope.setExtra("errorInfo", errorInfo);

      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <LoggedOutFrame title="Whoops! Looks like something went wrong.">
          <Row>
            <Col md={6} mdOffset={3} xs={12}>
              <div>
                An error has occurred and we're working to fix the problem! Please try again later.
              </div>
              <div className="m-ts">
                If you need immediate help don't hesitate to contact our support team at{" "}
                <a href="https://support.redcircle.com">https://support.redcircle.com</a>
              </div>
            </Col>
          </Row>
        </LoggedOutFrame>
      );
    }

    return this.props.children;
  }
}

export default AppErrorBoundary;
