import React, { Component } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { hideModal, showModal } from "../../actions/modal";
import { setUserAttribute } from "../../action_managers/account";
import { HAS_SEEN_PROMOTIONS_INTRO } from "../../constants/user_attributes";
import promo1 from "../../images/promo_intro_1.png";
import promo2 from "../../images/promo_intro_2.png";
import promo3 from "../../images/promo_intro_3.png";
import promo4 from "../../images/promo_intro_4.png";
import { FormStep, FormStepDots } from "../forms/form_steps/form_steps";
import BaseModal from "./base_modal";
import { PROMOTIONS_OPT_IN_MODAL } from "./modal_root";
import "./monetization_intro_modal/promotions-intro-modal.scss";

class PromotionsIntroModal extends Component {
  state = {
    step: 1,
  };

  close = () => {
    this.props.markIntroSeen();
    this.props.hideModal();
  };

  continue = () => {
    if (this.state.step === 4) {
      this.close();
      this.props.showOptInModal();
      return;
    }
    this.setState({ step: this.state.step + 1 });
  };

  render() {
    return (
      <BaseModal
        isLoading={false}
        isMini={true}
        columns={10}
        offset={1}
        close={this.close}
        hideCloseButton={false}
        showContent={true}
        Content={() => (
          <Grid fluid>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <FormStep step={1} currentStep={this.state.step}>
                  <PromoIntroStep
                    title="What are cross promotions?"
                    imageSrc={promo1}
                    description={`Cross promotions allow you to spread the word about your podcast
                    by connecting with similiar podcasters. You can get in contact, and run promos
                    for eachother’s shows.`}
                  />
                </FormStep>

                <FormStep step={2} currentStep={this.state.step}>
                  <PromoIntroStep
                    title="Browse to find similar podcasts"
                    imageSrc={promo2}
                    description={`You can browse and search through our listings of RedCircle Podcasters.
                     You can use this to find podcasts of similar audience size, similar audience
                     demographics, or similar subjects.`}
                  />
                </FormStep>

                <FormStep step={3} currentStep={this.state.step}>
                  <PromoIntroStep
                    title="Contact a partner"
                    imageSrc={promo3}
                    description={`Once you’ve found a podcast that seems like a similar match to yours, you can contact
                     the podcaster and explain that you’d like to run a cross promotion.`}
                  />
                </FormStep>

                <FormStep step={4} currentStep={this.state.step}>
                  <PromoIntroStep
                    title="Propose a promotion"
                    imageSrc={promo4}
                    description={`You both can chat to work out your promo. Choose which podcast you’d like your
                    promotion to run on, an audio clip of your promotion, and the start date. Once you both agree, your
                    promotions will automatically be added as a pre-roll to each other’s podcast.`}
                  />
                </FormStep>
              </Col>
              <Col xs={12} md={10} mdOffset={1} className="promo-intro-step__form_step_dots">
                <FormStepDots numSteps={4} currentStep={this.state.step} />
              </Col>
              <Col xs={12} md={10} mdOffset={1}>
                <Button
                  className="btn btn-primary promo-intro-step__continue"
                  onClick={this.continue}>
                  Continue
                </Button>
              </Col>
            </Row>
          </Grid>
        )}
      />
    );
  }
}

class PromoIntroStep extends Component {
  render() {
    return (
      <div>
        <img height="302px" width="100%" alt="" src={this.props.imageSrc} />
        <h1 className="promo-intro-step__title">{this.props.title}</h1>
        <div className="promo-intro-step__description">{this.props.description}</div>
      </div>
    );
  }
}

export default connect(({ user }) => ({ user }), {
  hideModal,
  showOptInModal: () => {
    return showModal(PROMOTIONS_OPT_IN_MODAL);
  },
  markIntroSeen: () => {
    return setUserAttribute(HAS_SEEN_PROMOTIONS_INTRO, "true");
  },
})(PromotionsIntroModal);
