import { Divider } from "antd";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { getPaymentsForTier } from "../../../action_managers/tiers";
import { useDispatchTS, useSelectorTS } from "../../../hooks/redux-ts";
import AccountPlanCurrentPlan from "./account_plan_current_plan";
import { AccountPlanItem } from "./account_plan_item";
import { accountPlanCopy } from "./account_plan_utils";

interface IAccountPlanProps {
  currentTier: string;
}

const AccountPlan = ({ currentTier }: IAccountPlanProps) => {
  const dispatch = useDispatchTS();
  const user = useSelectorTS((state) => state.user.user);
  const tier = useSelectorTS((state) => state.user.tier);
  const paymentForTier = useSelectorTS((state) => state.payments.userTierPayment);

  useEffect(() => {
    if (tier?.uuid && isEmpty(paymentForTier)) {
      dispatch(getPaymentsForTier());
    }
  }, [tier?.uuid]);

  const RenderAccountPlans = () => {
    return accountPlanCopy
      .filter(({ level, shouldHide }) => {
        if (shouldHide?.(user)) {
          return false;
        }
        return level !== currentTier;
      })
      .map((setting) => (
        <AccountPlanItem key={setting.level} {...setting} currentTier={tier.level} />
      ));
  };

  return (
    <div className={"m-bm"}>
      <Divider className="m-txs m-bs" />
      <AccountPlanCurrentPlan />

      {RenderAccountPlans()}
    </div>
  );
};

export default AccountPlan;
