import React, { Component } from "react";
import { If } from "react-extras";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import "./progress_bar.scss";

class ProgressBar extends Component {
  render() {
    let percent = 100;
    if (this.props.current < this.props.total) {
      percent = Math.min(99, Math.ceil((this.props.current / this.props.total) * 100));
    }

    return (
      <div className="seedcast-progress-bar" data-testid="progress-bar">
        <If condition={Boolean(this.props.title)}>
          <div className="seedcast-progress-bar__title">{this.props.title}</div>
        </If>
        <Progress
          percent={percent}
          status={this.props.status}
          theme={{
            default: { color: "#ea404d" },
            active: { color: "#ea404d" },
          }}
        />
        <If condition={Boolean(this.props.subtitle)}>
          <div className="seedcast-progress-bar__subtitle" data-testid="subtitle">
            {this.props.subtitle}
          </div>
        </If>
      </div>
    );
  }
}

export default ProgressBar;
