export default {
  CONVERSION_STATE_KEY_NEEDS_PROCESSING: "Needs Processing",
  CONVERSION_STATE_KEY_PROCESSING: "Processing",
  CONVERSION_STATE_KEY_DONE: "Done",
  CONVERSION_STATE_KEY_FAILED: "Failed - Please check your file and upload again",
  CONVERSION_STATE_KEY_NOT_APPLICABLE: "No conversion needed",
};

export const CONVERSION_STATE_KEY_NEEDS_PROCESSING = "needs_processing";
export const CONVERSION_STATE_KEY_PROCESSING = "processing";
export const CONVERSION_STATE_KEY_DONE = "done";
export const CONVERSION_STATE_KEY_FAILED = "failed";
export const CONVERSION_STATE_KEY_NOT_APPLICABLE = "not_applicable";
