export const AD_PLACEMENTS_MODAL_CLEAR = "AD_PLACEMENTS_MODAL_CLEAR";
export const AD_PLACEMENTS_MODAL_SET_PLACEMENT = "AD_PLACEMENTS_MODAL_SET_PLACEMENT";
export const AD_PLACEMENTS_MODAL_SET_CURSOR = "AD_PLACEMENTS_MODAL_SET_CURSOR";
export const AD_PLACEMENTS_MODAL_DELETE_PLACEMENT = "AD_PLACEMENTS_MODAL_DELETE_PLACEMENT";
export const AD_PLACEMENTS_MODAL_SAVE_START = "AD_PLACEMENTS_MODAL_SAVE_START";
export const AD_PLACEMENTS_MODAL_SAVE_ERROR = "AD_PLACEMENTS_MODAL_SAVE_ERROR";
export const AD_PLACEMENTS_MODAL_SAVE_SUCCESS = "AD_PLACEMENTS_MODAL_SAVE_SUCCESS";

export function adPlacementsModalClear(episode) {
  return {
    type: AD_PLACEMENTS_MODAL_CLEAR,
    episode: episode,
  };
}

export function adPlacementsModalSetCursor(cursor) {
  return {
    type: AD_PLACEMENTS_MODAL_SET_CURSOR,
    cursor: cursor,
  };
}

export function adPlacementsModalSetPlacement(placement) {
  return {
    type: AD_PLACEMENTS_MODAL_SET_PLACEMENT,
    placement: placement,
  };
}

export function adPlacementsModalDeletePlacement(index) {
  return {
    type: AD_PLACEMENTS_MODAL_DELETE_PLACEMENT,
    index: index,
  };
}

export function adPlacementsModalSaveStart() {
  return {
    type: AD_PLACEMENTS_MODAL_SAVE_START,
  };
}

export function adPlacementsModalSaveSuccess(episode) {
  return {
    type: AD_PLACEMENTS_MODAL_SAVE_SUCCESS,
    episode: episode,
  };
}

export function adPlacementsModalSaveError(errorMessage) {
  return {
    type: AD_PLACEMENTS_MODAL_SAVE_ERROR,
    errorMessage: errorMessage,
  };
}
