import { Tabs } from "antd";
import { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import Page from "src/components/lib/page";
import { permissionTypes } from "src/constants/permission_roles";
import { useQuery } from "src/hooks/lib";
import { useGetUserCards } from "src/hooks/user";
import { useCanAccessBound } from "src/lib/permissions";
import UserRoles from "../../../../constants/roles";
import { useSelectorTS } from "../../../../hooks/redux-ts";
import PaymentMethod from "../payment_methods";
import ProfileSettings from "../profile_settings_wrapper";
import TeamSeats from "../team_seats";

type Tabs = "profile" | "payment" | "team";

const AccountPage = () => {
  const history = useHistory();
  const { tab } = useParams<{ tab: Tabs }>();
  const { phone } = useQuery();
  const { currentRole } = useSelectorTS((state) => state.user);
  const { isLoading } = useGetUserCards();
  const canAccess = useCanAccessBound();
  const phoneInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (phone === "true" && phoneInputRef?.current) {
      phoneInputRef.current?.scrollIntoView({ behavior: "smooth" });
      phoneInputRef.current?.focus();
    }
  }, [phone, phoneInputRef]);

  const showPaymentTab =
    !isLoading &&
    ([UserRoles.Creator, UserRoles.Admin].includes(currentRole) || // admins and creators
      canAccess(permissionTypes.updateBilling)); // advertisers
  const showTeamSeatsTab = [UserRoles.Creator, UserRoles.Admin, UserRoles.Advertiser].includes(
    currentRole
  );

  const handleTabChange = (key: string) => {
    history.push(`/account/${key}`);
  };

  return (
    <Page pageTitle="Account">
      <Page.Header className="m-bxxs">
        <Page.Title>Account</Page.Title>
      </Page.Header>
      <Page.Section>
        <Tabs activeKey={tab || "profile"} onChange={handleTabChange}>
          <Tabs.TabPane tab="Profile" key="profile" className="m-txs">
            <ProfileSettings phoneInputRef={phoneInputRef} />
          </Tabs.TabPane>
          {showPaymentTab && (
            <Tabs.TabPane tab="Payment" key="payment" className="m-txs">
              <PaymentMethod />
            </Tabs.TabPane>
          )}
          {showTeamSeatsTab && (
            <Tabs.TabPane tab="Team Seats" key="team" className="m-txs">
              <TeamSeats />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Page.Section>
    </Page>
  );
};

export default AccountPage;
