import get from "lodash/get";
import { v4 as uuid } from "uuid";
import { userLogout } from "../actions/user";
import messageType, { MessageTypes } from "../constants/message_types";
import UserStore from "../lib/user_store";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import { IRootState, ValidationError } from "src/reducers/types";
import { dismissMessageBar, showMessageBar } from "src/actions/app";
import { ReduxDispatch } from "src/hooks/redux-ts";

export type GetStateFunc = () => IRootState;

export type HTTPResponses<SuccessResponse, ErrorResponse = object> =
  | { status: 200; json: SuccessResponse }
  | { status: 204; json: object }
  | { status: 400; json: ErrorResponse }
  | { status: 403; json: ErrorResponse }
  | { status: 500; json: ErrorResponse };

export interface IActionManager<SuccessResponse, ARGS, ErrorResponse> {
  args: ARGS;
  uuid: string;

  defaultErrorMessage(): string;
  defaultPreCallActionCreator(): ActionCreator<AnyAction>;
  defaultSuccessActionCreator(): ActionCreator<AnyAction>;
  default400ActionCreator(): ActionCreator<AnyAction>;
  defaultErrorActionCreator(): ActionCreator<AnyAction>;

  preCall(dispatch: ReduxDispatch): void;
  postCall(
    dispatch: ReduxDispatch,
    response: HTTPResponses<SuccessResponse, ErrorResponse>,
    getState?: GetStateFunc
  ): Promise<HTTPResponses<SuccessResponse, ErrorResponse>>;

  responseOK(dispatch: ReduxDispatch, json: any): void | any;
  response400(dispatch: ReduxDispatch, json: any): void | any;
  responseError(dispatch: ReduxDispatch, statusCode: number, json: any): void;
  response403(dispatch: ReduxDispatch, json?: any): void;
  catchError(dispatch: ReduxDispatch, err?: Error): void;

  execute(dispatch: ReduxDispatch, getState: GetStateFunc): Promise<Response>;

  run(): (dispatch: ReduxDispatch, getState: GetStateFunc) => Promise<any>;

  showMessageWithTimeout(
    dispatch: ReduxDispatch,
    message: string,
    selectedMessageType: MessageTypes
  ): void;
  showError(dispatch: ReduxDispatch, message: string): void;
  showWarning(dispatch: ReduxDispatch, message: string): void;
  showInfo(dispatch: ReduxDispatch, message: string): void;
  showSuccess(dispatch: ReduxDispatch, message: string): void;
}
class ActionManager<
  SuccessResponse extends object = Record<string, any>,
  TArgs extends object = Record<string, any>,
  ErrorResponse extends object = Record<string, any>,
> implements IActionManager<SuccessResponse, TArgs, ErrorResponse>
{
  args: TArgs;
  uuid: string;

  // When constructing an action manager, you add
  // you pass all necessary data as an 'args' object.
  // These are stored as this.args and accessible for all
  // other calls.
  constructor(args: TArgs) {
    this.args = args;
    this.uuid = uuid();
  }

  // Override with an error message specific to the action
  // domain this class manages.
  defaultErrorMessage() {
    return "Uh oh, something went wrong. Please try again later.";
  }

  // Override with a function that takes dispatch as an argument
  // and dispatches a start action. If you need to pass
  // arguments to the action creator, override preCall instead.
  defaultPreCallActionCreator() {
    return function () {
      return { type: "NO_ACTION" };
    };
  }

  // Override with a function that takes dispatch as an argument
  // and dispatches a success action. By default, the success
  // action is passed the json object from the response.
  // If you need to pass other stuff from the constructor args,
  // override responseOK().  If you need to do something fancier,
  // you can override run(), but that should be avoided.
  defaultSuccessActionCreator() {
    return function (response: SuccessResponse) {
      return { type: "NO_ACTION" };
    };
  }

  // Same as above. Override response400 for customization.
  default400ActionCreator() {
    return function (validationErrors: ValidationError) {
      return { type: "NO_ACTION" };
    };
  }

  // Same as above. Override responseError for customization.
  defaultErrorActionCreator() {
    return (errorMessage: string) => {
      return { type: "NO_ACTION" };
    };
  }

  // preCall allows hooks for emitting actions before the call
  // begins
  preCall(dispatch: ReduxDispatch) {
    dispatch(this.defaultPreCallActionCreator()());
  }

  /**
   *  Post call function for the purpose of cleanup or set up
   *  data flow after the main actions have completed
   * @param {ReduxDispatch} dispatch
   * @param {HTTPResponses<SuccessResponse, ErrorResponse>} response
   * @param {GetStateFunc} getState
   * @returns {Promise<HTTPResponses<SuccessResponse, ErrorResponse>>}
   */
  postCall(
    dispatch: ReduxDispatch,
    response: HTTPResponses<SuccessResponse, ErrorResponse>,
    getState: GetStateFunc
  ) {
    return Promise.resolve(response);
  }

  // Execute the API call.
  execute(dispatch: ReduxDispatch, getState: () => IRootState) {
    // Fill in an API call that returns a promise here.
    return new Promise((resolve) => {
      resolve({ status: 200 } as Response);
    }) as Promise<Response>;
  }

  // responseOK dispatches actions when the call is successful.
  // Override defaultSuccessActionCreator() where possible instead
  // of this. Sometimes you'll need to override this though.
  responseOK(dispatch: ReduxDispatch, json: SuccessResponse): void | any {
    return dispatch(this.defaultSuccessActionCreator()(json));
  }

  // Same as above, but for 400's.
  response400(dispatch: ReduxDispatch, json: any) {
    if (json.validationErrors && json.validationErrors.length) {
      dispatch(this.default400ActionCreator()(json.validationErrors));
      return;
    }
    this.responseError(dispatch, 400, json);
  }

  // Same as above but for non-200, non-400, non-403 responses.
  responseError(dispatch: ReduxDispatch, statusCode: number, json: any) {
    // Fill in an action creator here to handle specific 500
    // errors if you want something more specific than this.
    const errorMessage = json.errorMessage || json.message || this.defaultErrorMessage();
    dispatch(this.defaultErrorActionCreator()(errorMessage));
    this.showError(dispatch, errorMessage);
  }

  // Handles 403's by logging the user out and emitting a warning.
  response403(dispatch: ReduxDispatch, json?: any) {
    UserStore.remove();
    dispatch(userLogout());
    this.showWarning(dispatch, "You've been logged out. Please sign back in.");
  }

  // Handles unknown exceptions.  Override for customizations.
  catchError(dispatch: ReduxDispatch, err?: Error) {
    dispatch(this.defaultErrorActionCreator()(this.defaultErrorMessage()));
    this.showError(dispatch, this.defaultErrorMessage());
  }

  // Run takes an input object with all necessary
  // data / context for the 3rd party call. It returns
  // a function expecting dispatch and running through
  // the 3rd party call.
  run() {
    const that = this;

    return (dispatch: ReduxDispatch, getState: GetStateFunc) => {
      let responseData: HTTPResponses<SuccessResponse, ErrorResponse>;
      that.preCall(dispatch);
      return that
        .execute(dispatch, getState)
        .then((response) => {
          responseData = {
            status: response.status,
            json: {},
          } as HTTPResponses<SuccessResponse, ErrorResponse>;

          if (response.status === 204) {
            that.responseOK(dispatch, {} as SuccessResponse);
            return responseData;
          }

          return response
            .json()
            .then((json) => {
              responseData.json = json;
              switch (response.status) {
                case 200:
                  that.responseOK(dispatch, json as SuccessResponse);
                  break;
                case 400:
                  that.response400(dispatch, json as ErrorResponse);
                  break;
                case 403:
                  that.response403(dispatch, json as ErrorResponse);
                  break;
                default:
                  that.responseError(dispatch, response.status, json);
              }

              return responseData as HTTPResponses<SuccessResponse, ErrorResponse>;
            })
            .then((resp) => {
              return that.postCall(dispatch, resp, getState);
            })
            .catch((err: Error) => {
              that.catchError(dispatch, err);
              return responseData;
            });
        })
        .catch((err) => {
          that.catchError(dispatch, err);
          // TODO need to build out error types and responses
          // The issue is, looks like even in error cases
          // the promise is set to return in the .then statement
          // of the caller.
          // For now re-use SuccessResponse shape
          return {
            status: 500,
            json: {},
          } as HTTPResponses<SuccessResponse, ErrorResponse>;
        });
    };
  }

  showMessageWithTimeout(
    dispatch: ReduxDispatch,
    message: string,
    selectedMessageType: MessageTypes
  ) {
    if (message && !get(this.args, "silent")) {
      const messageUUID = uuid();
      dispatch(showMessageBar(messageUUID, message, selectedMessageType));
      setTimeout(function () {
        dispatch(dismissMessageBar(messageUUID));
      }, 5000);
    }
  }
  showError(dispatch: ReduxDispatch, message: string) {
    this.showMessageWithTimeout(dispatch, message, messageType.Error);
  }
  showWarning(dispatch: ReduxDispatch, message: string) {
    this.showMessageWithTimeout(dispatch, message, messageType.Warning);
  }
  showInfo(dispatch: ReduxDispatch, message: string) {
    this.showMessageWithTimeout(dispatch, message, messageType.Info);
  }
  showSuccess(dispatch: ReduxDispatch, message: string) {
    this.showMessageWithTimeout(dispatch, message, messageType.Success);
  }
}

export default ActionManager;
