import minusIcon from "@iconify-icons/mdi/minus";
import plusIcon from "@iconify-icons/mdi/plus";
import QuestionIcon from "@iconify/icons-mdi/question-mark-circle";
import { Icon } from "@iconify/react";
import { Checkbox, Select } from "antd";
import { isEmpty } from "lodash";
import truncate from "lodash/truncate";
import React, { useState } from "react";
import { classNames, If } from "react-extras";
import { useHistory } from "react-router-dom";
import { permissionObjectTypes } from "src/constants/permissions";
import { getPermCategoriesFromUserRole } from "src/lib/permission_roles";
import { Presets } from "src/reducers/permissions/types";
import { showError, showSuccess } from "../../actions/app";
import {
  CreatePermission,
  createPermission,
  IUpdatePermission,
  updatePermission,
} from "../../action_managers/permissions";
import {
  CreatorAllowedRoles,
  creatorRoleMapping,
  permissionPresets,
  permissionsConfigMap,
  permissionTypes,
  roleToFriendly,
} from "../../constants/permission_roles";
import { useCredentialsInOrg, usePermissionsInOrg } from "../../hooks/org";
import { useDispatchTS, useSelectorTS } from "../../hooks/redux-ts";
import { credential } from "../../reducers/credentials";
import { IPermissions } from "../../reducers/permissions";
import LoadingButton from "../forms/loading_button/loading_button";
import Divider from "../lib/divider";
import InfoTooltip from "../lib/info";
import { Modal } from "redcircle-ui";
import { presetRoleRanking } from "../pages/account/team_seats_utilities";
import "./modal.scss";

// Helper function;

const extractFromKey: (key: string) => [string, string] = (key) => {
  const [credentialUUID, permissionsUUID] = key?.split("__");
  return [credentialUUID, permissionsUUID];
};

// Helper Function , abstracts logic of what can be shows based on userRole vs credentialRole
const getViewLogic = (userRole: Presets, credentialRole: Presets) => {
  const access = {
    canExpand: false, // Can Expand to see permissions
    canUpdateRole: false, // Can update the role of credential
    canUpdatePermissions: false, // Can update permissions of credential
  };

  // If User is org owner and looking at himself
  if (userRole === permissionPresets.orgAdmin && credentialRole === permissionPresets.orgAdmin) {
    return access;
  }

  // if user is orgOwner
  if (userRole === permissionPresets.orgAdmin) {
    return {
      canExpand: true,
      canUpdateRole: true,
      canUpdatePermissions: false,
    };
  }

  // If credential is org Owner
  if (credentialRole === permissionPresets.orgAdmin) {
    return {
      canExpand: false,
      canUpdateRole: false,
      canUpdatePermissions: false,
    };
  }

  return {
    canExpand: presetRoleRanking[userRole] >= presetRoleRanking.showContributor,
    canUpdateRole:
      presetRoleRanking[userRole] >= presetRoleRanking.showContributor &&
      presetRoleRanking[userRole] > presetRoleRanking[credentialRole],
    canUpdatePermissions: false,
  };
};

// TYPES
interface IAddTeamSeatToPodcast {
  visible: boolean; // Parent controlled modal visibility
  setVisible: React.Dispatch<React.SetStateAction<boolean>>; // Parent change function form visibility
  showUUID: string;
  onCancel?: () => void;
}

// MODAL COMPONENT
const AddTeamSeatToPodcast = (props: IAddTeamSeatToPodcast) => {
  const dispatch = useDispatchTS();
  const history = useHistory();
  // Local State
  const { visible = false, setVisible, showUUID, onCancel } = props;

  // changes for new team seat additions
  const [newCredChanges, setNewCredChanges] = useState<string[]>([]);
  const [newCredRole, setNewCredRole] = useState<keyof typeof permissionPresets>(
    permissionPresets.showViewer
  );
  // Changes for list of credentials already in show
  const [updates, setUpdates] = useState<
    Record<
      string,
      {
        role: keyof typeof permissionPresets;
        expanded: boolean;
      }
    >
  >({});
  const [isLoading, setIsLoading] = useState(false);

  // Redux State
  const user = useSelectorTS((state) => state?.user?.user);
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const { credentials, credentialsMapByUUID } = useCredentialsInOrg();

  const { permissions, permissionsMapByCred, permissionsMapByUUID, permissionMapByKey } =
    usePermissionsInOrg();

  // Get User Role
  const permissionKey = `${user?.credentialUUID}-${showUUID}`;
  const userRole = isEmpty(permissionMapByKey?.[permissionKey])
    ? permissionsMapByCred?.[user?.credentialUUID]?.find(
        (permission) => permission?.objectType === permissionObjectTypes.org
      )?.preset || permissionPresets.noAccess
    : permissionMapByKey?.[permissionKey]?.preset;

  // Build list of credentials that have or dont have permissions for this show
  const credentialsInSearch: Array<credential & { permission: IPermissions }> = [];
  const credentialsWithShowPerm: Array<credential & { permission: IPermissions }> = [];

  credentials.forEach((cred) => {
    const key = `${cred?.uuid}-${showUUID}`;
    const orgKey = `${cred?.uuid}-${user?.uuid}`;

    if (permissionMapByKey[orgKey]?.preset === permissionPresets.orgAdmin) {
      credentialsWithShowPerm.push({ ...cred, permission: permissionMapByKey[orgKey] });
    } else if (!isEmpty(permissionMapByKey[key])) {
      // Explicit Permissions Exists
      if (permissionMapByKey[key]?.preset === permissionPresets.noAccess) {
        credentialsInSearch.push({ ...cred, permission: permissionMapByKey[key] });
      } else {
        credentialsWithShowPerm.push({ ...cred, permission: permissionMapByKey[key] });
      }
    } else {
      // Explicit permissions does exists
      if (permissionMapByKey[orgKey]?.preset === permissionPresets.noAccess) {
        credentialsInSearch.push({ ...cred, permission: permissionMapByKey[orgKey] });
      } else {
        credentialsWithShowPerm.push({ ...cred, permission: permissionMapByKey[orgKey] });
      }
    }
  });

  // Sort list
  credentialsWithShowPerm.sort((a, b) => {
    // Org Owner is always first
    if (a?.permission?.preset === permissionPresets.orgAdmin) {
      return -1;
    }

    if (b?.permission?.preset === permissionPresets.orgAdmin) {
      return 1;
    }

    // Current User is second
    if (a?.uuid === user?.credentialUUID) {
      return -1;
    }

    if (b?.uuid === user?.credentialUUID) {
      return 1;
    }

    // Sort by roles
    if (a?.permission?.preset !== b?.permission?.preset) {
      return presetRoleRanking[b?.permission?.preset] - presetRoleRanking[a?.permission?.preset];
    }

    // Sort by first name alphabetical
    return a?.firstName?.localeCompare(b?.firstName);
  });

  // Handlers
  const handleCancel = () => {
    // Resetting state
    setUpdates({});
    setNewCredChanges([]);
    setNewCredRole(permissionPresets.showViewer);
    setVisible(false);

    // Incase there is explicit onCancel
    onCancel && onCancel();
  };

  const handleFilterOptions = (inputVal: any, option: any) => {
    const cred = credentialsMapByUUID[option?.key];
    const searchString = `${cred?.firstName || ""}${cred?.lastName || ""}${cred?.email || ""}`;
    return searchString.indexOf(inputVal) >= 0;
  };
  const handleSave = async () => {
    const updatesList: IUpdatePermission[] = [];
    const additionsList: CreatePermission[] = [];

    const overridesMap = permissions?.reduce(
      (accu, curr) => {
        const role = curr?.preset;
        accu[role] = { ...accu[role], ...curr?.overrides };
        return accu;
      },
      {} as Record<keyof typeof permissionPresets, Record<keyof typeof permissionTypes, boolean>>
    );

    // Adding new Team members to this podcast
    if (!isEmpty(newCredChanges)) {
      newCredChanges.forEach((credUUID) => {
        const permission = permissionMapByKey[`${credUUID}-${showUUID}`];
        if (isEmpty(permission)) {
          // No Permissions exists
          additionsList.push({
            credentialUUID: credUUID,
            objectUUID: showUUID,
            objectType: permissionObjectTypes.show,
            overrides: !isEmpty(overridesMap[newCredRole]) ? overridesMap[newCredRole] : undefined,
            preset: newCredRole,
          });
        } else {
          updatesList.push({
            uuid: permission?.uuid,
            credentialUUID: permission.credentialUUID,
            objectUUID: permission.objectUUID,
            objectType: permission.objectType,
            overrides: !isEmpty(overridesMap[newCredRole]) ? overridesMap[newCredRole] : undefined,
            preset: newCredRole,
          });
        }
      });
    }

    if (!isEmpty(updates)) {
      Object.entries(updates).forEach(([key, value]) => {
        const [credUUID, permissionUUID] = extractFromKey(key);

        const currentPermission = permissionsMapByUUID[permissionUUID];
        const newRole = isEmpty(value?.role) ? currentPermission.preset : value?.role;
        const newOverrides = !isEmpty(overridesMap[newRole]) ? overridesMap[newRole] : undefined;

        if (currentPermission?.objectType === permissionObjectTypes.org) {
          // show permissions does not exist
          additionsList.push({
            credentialUUID: credUUID,
            objectType: permissionObjectTypes.show,
            objectUUID: showUUID,
            preset: newRole,
            overrides: newOverrides,
          });
        } else {
          // Show Permissions exists, will update it
          updatesList.push({
            uuid: permissionUUID,
            credentialUUID: credUUID,
            objectUUID: currentPermission.objectUUID,
            objectType: currentPermission.objectType,
            preset: newRole,
            overrides: currentPermission?.overrides,
          });
        }
      });
    }

    try {
      setIsLoading(true);
      if (updatesList?.length > 0) {
        await dispatch(updatePermission(updatesList));
      }

      if (additionsList?.length > 0) {
        await dispatch(createPermission(additionsList));
      }

      const PodcastName = shows[showUUID]?.title;
      dispatch(showSuccess(`Podcast Team Members for ${PodcastName} have been updated.`));

      return Promise.resolve();
    } catch (err) {
      dispatch(
        showError(
          `There was an error updating the team member permissions for this podcast. Please try again at a later time or contact your Organization Owner`,
          5000
        )
      );
      return Promise.reject(err);
    } finally {
      // Resetting state
      setUpdates({});
      setNewCredChanges([]);
      setNewCredRole(permissionPresets.showViewer);
      setIsLoading(false);
      setVisible(false);
    }
  };

  // Logic Breakdown
  const canSearchCredentials = presetRoleRanking[userRole] > presetRoleRanking.showContributor;

  let search = null; // Holds search section
  const footer = null; // Holds footer section

  // Holds Expandable Section
  const getExpandableSection = (
    role: Presets,
    viewLogic: ReturnType<typeof getViewLogic>,
    uuid: string, // Credential UUID
    permission: IPermissions
  ) => {
    const sections = getPermCategoriesFromUserRole("creator");
    return (
      <div className="m-hxs p-hxxs p-vxxxs">
        {sections?.map((section) => {
          const permissionsList = Object.keys(permissionsConfigMap).filter((permName) => {
            const metaData = permissionsConfigMap[permName as keyof typeof permissionsConfigMap];

            if (metaData.category === section && metaData[role] !== null) return true;

            return false;
          }) as (keyof typeof permissionsConfigMap)[];

          const sectionDisabled = permissionsList.every(
            (key) =>
              typeof permissionsConfigMap?.[key]?.[role]?.defaultState === "boolean" &&
              !permissionsConfigMap?.[key]?.[role]?.defaultState
          );

          return (
            <div key={section} className="m-vxxs">
              <div className="m-bxxs flex-row-container align-center">
                <span
                  className="bold title--1 uppercase"
                  style={{ color: sectionDisabled ? "gray" : "black" }}>
                  {section}
                </span>
                {sectionDisabled && (
                  <>
                    <span className="title--7 m-hxxxs flex-row-container align-center">
                      Unavailable for this role
                    </span>
                    <InfoTooltip
                      direction="top"
                      helpText="You can choose another role that includes these permissioms"
                      baseIcon={QuestionIcon}
                      height={20}
                      style={{
                        color: "#C6C6C6",
                        cursor: "pointer",
                      }}
                    />
                  </>
                )}
              </div>
              {permissionsList.map((subKey) => {
                const { name } = permissionsConfigMap[subKey];
                const permissionTypeConfig = permissionsConfigMap[subKey]?.[role];

                const { defaultState: defaultCheckedState } = permissionTypeConfig ?? {};

                return (
                  <div
                    key={subKey}
                    className="flex-row-container align-center justify-space-between">
                    <Checkbox
                      className="m-bxxs title--5 justify-space-between check-box-separator"
                      disabled={true}
                      checked={
                        permission?.overrides?.[subKey as keyof typeof permissionTypes] ??
                        defaultCheckedState
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        width: "100%",
                      }}>
                      {name}
                    </Checkbox>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const notFound = (userRole: Presets) => {
    if (userRole === permissionPresets.orgAdmin) {
      return (
        <p className="title--8 m-vxxxs">
          No results found. Add{" "}
          <span
            className="fake-link capitalize"
            onClick={() => {
              handleCancel();
              history.push("/account/team");
            }}>
            New Team Member {">"}
          </span>
        </p>
      );
    }
    return undefined;
  };

  // Show Search section and save button
  if (canSearchCredentials) {
    search = (
      <>
        <div className="flex-row-container align-center justify-space-between">
          <Select
            mode="multiple"
            maxTagCount={6}
            notFoundContent={notFound(userRole)}
            onChange={(values) => {
              if (Array.isArray(values)) {
                setNewCredChanges([...(values as string[])]);
              }
            }}
            placeholder="Search Team Members to add to this podcast"
            filterOption={handleFilterOptions}
            optionFilterProp={"value"}
            value={newCredChanges}
            style={{ width: "100%" }}>
            {credentialsInSearch.map(({ uuid, firstName, lastName, email }) => (
              <Select.Option key={uuid} value={uuid}>
                <div className="flex-column-container align-start p-axxxs">
                  <span className="m-bxxxs">
                    {firstName} {lastName}
                  </span>
                  <span>{email}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
          {newCredChanges?.length > 0 && (
            <span className="m-lxxs">
              <Select
                size="small"
                style={{ width: "120px" }}
                virtual={false}
                value={newCredRole}
                placeholder="Select Role"
                onChange={(value) => setNewCredRole(value)}>
                {Object.keys(creatorRoleMapping).map((key) => {
                  return (
                    <Select.Option key={key} value={key as CreatorAllowedRoles}>
                      {creatorRoleMapping[key as CreatorAllowedRoles]}
                    </Select.Option>
                  );
                })}
              </Select>
            </span>
          )}
        </div>

        <Divider marginTop={10} marginBottom={10} />
      </>
    );
  }

  return (
    <Modal open={visible} size="xs" onClose={handleCancel}>
      <Modal.Title>Podcast Team Members</Modal.Title>
      <Modal.Body className="addTeamSeatToPodcastModal">
        <div>
          {search}
          {credentialsWithShowPerm?.map(({ uuid, firstName, lastName, email, permission }) => {
            const isUser = uuid === user.credentialUUID;
            const role = permission?.preset;

            const viewLogic = getViewLogic(userRole, role);

            return (
              <div
                key={uuid}
                className="m-bxxs"
                style={{
                  width: "100%",
                  background: "#FFFFFF",
                  border: "1px solid #C6C6C6",
                  borderRadius: "4px",
                }}>
                <div className="p-hxxs p-vxxxs flex-row-container align-center justify-space-between">
                  <div className="info flex-column-container align-start m-lxxs">
                    <div className="info-fullname">
                      <If condition={isUser}>
                        <>
                          <span className="title--8">
                            <strong>
                              {truncate(firstName, {
                                length: 15,
                              })}
                            </strong>
                          </span>
                          <span className="title--8">
                            <strong>{truncate(lastName, { length: 15 })}</strong>
                          </span>
                          <span className="title--5 m-lxxxs">(You)</span>
                        </>
                      </If>
                      <If condition={!isUser}>
                        <>
                          <span className="title--8">
                            {truncate(firstName, {
                              length: 20,
                            })}
                          </span>
                          <span className="title--8">{truncate(lastName, { length: 20 })}</span>
                        </>
                      </If>
                    </div>

                    <span className="info-email title--5">{email}</span>
                  </div>

                  <If condition={viewLogic.canUpdateRole}>
                    <div className="m-la">
                      <Select
                        size="small"
                        className="m-la"
                        style={{ width: "120px" }}
                        virtual={false}
                        defaultValue={role}
                        value={updates?.[`${uuid}__${permission?.uuid}`]?.role || role}
                        placeholder="Select Role"
                        onChange={(value) =>
                          setUpdates((prev) => {
                            return {
                              ...prev,
                              [`${uuid}__${permission?.uuid}`]: {
                                ...prev?.[`${uuid}__${permission?.uuid}`],
                                role: value,
                              },
                            };
                          })
                        }>
                        {Object.keys(creatorRoleMapping).map((key) => {
                          return (
                            <Select.Option key={key} value={key as CreatorAllowedRoles}>
                              {creatorRoleMapping[key as CreatorAllowedRoles]}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </div>
                  </If>

                  <If condition={!viewLogic.canUpdateRole}>
                    <span className="m-rxs m-la title--8">
                      <strong>{roleToFriendly[role]}</strong>
                    </span>
                  </If>

                  <If condition={viewLogic.canExpand}>
                    <span
                      className="pointer flex-row-container align-center justify-center"
                      onClick={() => {
                        setUpdates((prev) => {
                          return {
                            ...prev,
                            [`${uuid}__${permission?.uuid}`]: {
                              ...prev?.[`${uuid}__${permission?.uuid}`],
                              expanded: !prev?.[`${uuid}__${permission?.uuid}`]?.expanded,
                            },
                          };
                        });
                      }}>
                      {updates[`${uuid}__${permission?.uuid}`]?.expanded ? (
                        <Icon icon={minusIcon} className="m-hxxs" color="#EA404D" />
                      ) : (
                        <Icon icon={plusIcon} className="m-hxxs" color="#EA404D" />
                      )}
                    </span>
                  </If>
                </div>
                <div
                  className={classNames("expandable p-h0", {
                    expanded: updates[`${uuid}__${permission?.uuid}`]?.expanded,
                  })}>
                  {getExpandableSection(role, viewLogic, uuid, permission)}
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Modal.SubmitButton isLoading={isLoading} onClick={handleSave}>
          Save
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTeamSeatToPodcast;
