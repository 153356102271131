import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { If } from "react-extras";
import { matchPath } from "react-router-dom";
import LoggedOutFrame from "../../lib/logged_out_frame";
import Spinner from "../../lib/spinner";
import NewPasswordForm from "./new_password_form";

class RecoverPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    var result = matchPath(this.props.location.pathname, "/recover-password/:email/:passwordToken");

    if (result) {
      this.props.recoverPassword(atob(result.params.email), result.params.passwordToken);
    }
  }

  onSubmit(values) {
    this.props.updatePassword(values.password, this.props.user);
  }

  render() {
    return (
      <LoggedOutFrame
        title={"Choose a Password"}
        subtitle={"Great, we've confirmed you're you! Time to create a new password."}>
        <Row className="password-recovery-row">
          <Col xs={12} md={6} mdOffset={3} className="text-center">
            <Spinner isLoading={this.props.isLoading} disableBackground={true} />
            <If condition={Boolean(this.props.user)}>
              <NewPasswordForm
                user={this.props.user}
                onSubmit={this.onSubmit}
                validationErrors={this.props.validationErrors}
                isSuccess={this.props.isSuccess}
                password={this.props.password}
                resetAndLogout={() => {
                  this.props.resetRecoveryState();
                  this.props.redirectToHome(this.props.history.push);
                  this.props.logout();
                }}
              />
            </If>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            {this.props.errorMessage && <p>{this.props.errorMessage} </p>}
          </Col>
        </Row>
      </LoggedOutFrame>
    );
  }
}

export default RecoverPasswordPage;
