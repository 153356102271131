import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { permissionTypes } from "../../../constants/permission_roles";
import { TIER_LEVEL_ANNUAL } from "../../../constants/tiers";
import { useSelectorTS } from "../../../hooks/redux-ts";
import { useCanAccess } from "../../../lib/permissions";
import Divider from "../../lib/divider";
import AddCardForm from "../../lib/stripe_form/add_card_form";
import StripeElements from "../../lib/stripe_form/stripe_elements";
import {
  AccountOnHold,
  UpdateTierNavBar,
  useHideSidebar,
  useHideWarningbar,
  useUpdateTier,
} from "./tier_utils";

export const TierPaymentFailed = () => {
  useHideSidebar();
  useHideWarningbar();
  const user = useSelectorTS((state) => state.user.user);
  const canAccess = useCanAccess(permissionTypes.updateBilling, user.uuid);
  const updateTier = useUpdateTier();
  const userTier = useSelectorTS((state) => state.user.tier);
  const cardSubmit = async (card: any) => {
    updateTier(
      card?.card?.id,
      userTier?.interval === TIER_LEVEL_ANNUAL,
      userTier.level,
      "Payment failed. Please update your payment information and try again."
    );
  };
  if (!canAccess) {
    return (
      <AccountOnHold
        subTitle={
          "Please reach out to your Org Owner to have them update the account’s billing information."
        }
      />
    );
  }
  return (
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <UpdateTierNavBar signOutOnly={true} />
        </Col>
      </Row>
      <Row className={"m-txl"}>
        <Col xs={8} xsOffset={2} mdOffset={3} md={6} lg={4} lgOffset={4}>
          <h2>Account On Hold</h2>
          <div>
            The payment method on file could not be processed. Please update your payment method to
            continue your plan.{" "}
          </div>
          <Divider />
          <StripeElements>
            <AddCardForm
              canMakePayment={false}
              onSubmit={cardSubmit}
              user={user}
              ctaText={"Update Payment Method"}
            />
          </StripeElements>
        </Col>
      </Row>
    </Grid>
  );
};
