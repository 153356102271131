import get from "lodash/get";
import isNil from "lodash/isNil";
import React, { Component } from "react";
import { ControlLabel, FormControl, FormGroup, HelpBlock, InputGroup } from "react-bootstrap";
import InfoTooltip from "../lib/info";

class FormElement extends Component {
  render() {
    var {
      input,
      label,
      type,
      prefix,
      postfix,
      InputComponent = FormControl,
      RenderHandler,
      validationErrorMessageOverride,
      validationErrorKeyOverride,
      noValidationMessage,
      meta,
      validationErrors,
      helpText,
      infoText,
      infoDirection,
      groupClassName,
      validateAfterTouch = false,
      ...props
    } = this.props;
    var validationState = null;
    var helpClass = "";
    if (validationErrors && validationErrors.length > 0) {
      for (var valError of validationErrors) {
        if (
          valError.fieldName === get(input, "name") ||
          valError.fieldName === get(props, "name") ||
          (valError.fieldName === validationErrorKeyOverride && validationErrorKeyOverride) ||
          get(this.props, "names", []).some((name) => valError.fieldName === name)
        ) {
          validationState = "error";
          helpClass = "text-danger";
          if (noValidationMessage) {
            helpText = "";
          } else {
            helpText = isNil(validationErrorMessageOverride)
              ? valError.errorMessage
              : validationErrorMessageOverride;
          }
        }
      }
    }

    if ((validateAfterTouch ? meta?.touched || meta?.dirty : meta?.dirty) && meta?.error) {
      helpText = meta.error;
      validationState = "error";
      helpClass = "text-danger";
    }
    let InputElementWrapper = prefix || postfix ? InputGroup : React.Fragment;
    return (
      <FormGroup
        controlId={this.props.name}
        validationState={validationState}
        className={groupClassName}>
        {this.props.label && (
          <>
            <ControlLabel className="redcircle-form-label">{this.props.label}</ControlLabel>
            {infoText && <InfoTooltip helpText={infoText} direction={infoDirection} />}
          </>
        )}
        <InputElementWrapper>
          {prefix && <InputGroup.Addon>{prefix}</InputGroup.Addon>}
          {RenderHandler && <RenderHandler {...this.props} />}
          {!RenderHandler && (
            <InputComponent
              {...input}
              type={type}
              meta={meta}
              {...props}
              {...(this.props.InputComponent && infoText && { infoText, infoDirection })}
            />
          )}
          {postfix && <InputGroup.Addon>{postfix}</InputGroup.Addon>}
        </InputElementWrapper>
        <FormControl.Feedback />
        {helpText && <HelpBlock className={helpClass}>{helpText}</HelpBlock>}
      </FormGroup>
    );
  }
}

export default FormElement;
