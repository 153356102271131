import { Popover, Slider } from "antd";
import { useContext, useState } from "react";
import { RiVolumeDownLine, RiVolumeMuteLine, RiVolumeUpLine } from "react-icons/ri";
import { Button } from "../../Button";
import { AudioPlayerContext } from "./Wrapper";

export default function AudioPlayerVolume({ className }: { className?: string }) {
  const { audioData, setVolume } = useContext(AudioPlayerContext);
  const { volume } = audioData;
  const modifiedVolume = volume * 10;

  const [seekVolume, setSeekVolume] = useState<number | null>(null);
  const [prevVolume, setPrevVolume] = useState<number | null>(null);

  const handleToggleMute = () => {
    if (!prevVolume) {
      setPrevVolume(volume);
      setVolume(0);
    } else {
      setVolume(prevVolume);
      setPrevVolume(null);
    }
  };

  const content = (
    <div className="flex-column-container align-center" style={{ height: 150, width: 50 }}>
      <Slider
        vertical
        min={0}
        max={10}
        value={seekVolume || modifiedVolume}
        onChange={(value: number) => setSeekVolume(value)}
        onAfterChange={(value: number) => {
          setSeekVolume(null);
          setPrevVolume(value === 0 ? volume : null);
          setVolume(value / 10);
        }}
        tooltip={{ formatter: null }}
      />
      <Button type="link" size="small" onClick={handleToggleMute}>
        {prevVolume ? "Unmute" : "Mute"}
      </Button>
    </div>
  );

  const renderIcon = () => {
    if (volume === 0) return <RiVolumeMuteLine />;
    if (volume > 0 && volume <= 0.5) return <RiVolumeDownLine />;
    return <RiVolumeUpLine />;
  };

  return (
    <Popover content={content} trigger="click">
      <Button type="icon" className={className} size="small">
        {renderIcon()}
      </Button>
    </Popover>
  );
}
