import React, { Component } from "react";
import { If } from "react-extras";
import "./warning_panel.scss";

// TODO: deprecate this. updated component in WarningPanelV2
class WarningPanel extends Component {
  state = { visible: true };
  render() {
    const defaultClass = "alert-warning";
    const alertClass = this.props.alertClass ? this.props.alertClass : defaultClass;
    const typeClass = this.props.isError ? "alert-danger" : alertClass;
    const className = this.props.className;
    return (
      <div
        className={`warning-panel alert alert-dismissible ${typeClass} ${className}`}
        role="alert"
        style={{ display: this.state.visible ? "block" : "none" }}>
        <If condition={!this.props.hideClose}>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => {
              this.setState({ visible: false });
            }}>
            <span aria-hidden="true">&times;</span>
          </button>
        </If>
        {this.props.children}
      </div>
    );
  }
}

export default WarningPanel;
