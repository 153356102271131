export const HAS_SEEN_WELCOME_MODAL = "hasSeenWelcomeModal";
export const HAS_SEEN_PROMOTIONS_INTRO = "hasSeenPromotionsIntro";
export const HAS_SEEN_PROMOTIONS_OPT_IN = "hasSeenPromotionsOptIn";
export const HAS_SEEN_MONETIZATION_INTRO = "hasSeenMonetizationIntro";
export const HAS_SIGNED_UP_WITH_INVITE = "hasSignedUpWithInvite";
export const HAS_SIGNED_UP_AS_SPONSOR = "hasSignedUpAsSponsor";
export const SHOULD_HAVE_STRIPE_ACCESS = "shouldHaveStripeAccess";
export const HAS_SEEN_ADVERTISING_PAGE = "hasSeenAdvertisingPage";
export const SHOULD_GET_PRIORITY_SUPPORT = "shouldGetPrioritySupport";
export const SEND_CAMPAIGN_BLOCKED = "sendCampaignBlocked";
export const CAN_IMPORT_REMOTE = "canImportRemote";
