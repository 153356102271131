import { Col, Image, Row } from "antd";
import dayjs from "dayjs";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import React, { ReactNode } from "react";
import { classNames, If } from "react-extras";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { breakpoint } from "src/constants/css";
import { useWindowSize } from "src/hooks/lib";
import logo from "src/images/logo-redcircle-dark.svg";
import { getMegapodURL } from "../../../lib/config";
import "./logged_out_frame.scss";

interface ILoggedOutFrame {
  title?: string;
  subtitle?: string | ReactNode;
  headerColProps?: any;
  className?: string;
  style?: React.CSSProperties;
  noBar?: boolean;
  hideSignIn?: boolean;
  hideSignUp?: boolean;
  hideHeader?: boolean;
  signInLink?: string;
  signUpLink?: string;
  signInText?: string;
  manualLink?: React.JSX.Element;
  children?: ReactNode;
  withShadow?: boolean; // Adds bottom border shadow to header
}

const LoggedOutFrame = (props: ILoggedOutFrame) => {
  const location = useLocation();
  const currentYear = dayjs().year();

  const { width = 0 } = useWindowSize();

  const imageHeight = width <= breakpoint ? 15 : 25;
  const imageWidth = width <= breakpoint ? 150 : 250;

  const getRolePathName = () => {
    const result = matchPath(location.pathname, "/:topLevelRoute/:role");
    return get(result, "params.role");
  };

  const getLink = (topLevelRoute: string) => {
    const role = getRolePathName();
    let path = "/" + topLevelRoute;
    if (role) {
      path = path + "/" + role;
    }
    return path + window.location.search;
  };

  // Making an updated version of LoggedOutframe with dynamic header props. Using this helper
  // function for flexibility incase different version are needed in the future
  const getHeaderColProps = () => {
    if (isEmpty(props?.headerColProps) || typeof props?.headerColProps === "undefined") {
      return { xs: 24, md: { span: 12, offset: 6 } };
    }
    return props.headerColProps;
  };

  const { title, subtitle } = props;
  return (
    <div
      className={classNames("logged-out-frame", props.className, { withShadow: props.withShadow })}
      style={props.style}>
      <If condition={!props.hideHeader}>
        <div className="logged-out-frame__topbar flex-row-container align-center justify-space-between">
          <a href="https://redcircle.com">
            <Image
              src={logo}
              alt={"RedCircle Logo"}
              placeholder="RedCircle Logo"
              preview={false}
              height={imageHeight}
              width={imageWidth}
            />
          </a>
          <div className="logged-out-frame__nav">
            <If condition={!props.hideSignIn}>
              <NavLink
                className="redcircle-big-link"
                to={props.signInLink || getLink("sign-in")}
                activeClassName="logged-out-frame__nav-link--active">
                Sign In
              </NavLink>
            </If>
            <If condition={!props.hideSignUp}>
              <a
                className="redcircle-big-link"
                href={props.signUpLink || getMegapodURL() + "account-create"}>
                {props.signInText || "Sign Up"}
              </a>
            </If>
            {!!props.manualLink && React.isValidElement(props.manualLink) && props.manualLink}
          </div>
        </div>
      </If>

      <div className="logged-out-frame__content container">
        <Row>
          <Col {...getHeaderColProps()}>
            {title && <h1 className="logged-out-frame__header m-bxs">{title}</h1>}
            {subtitle && <div className="logged-out-frame__welcome">{subtitle}</div>}
            <If condition={!props.noBar}>
              <hr className="redcircle-divider logged-out-frame__first-divider" />
            </If>
          </Col>
        </Row>
        {props.children}
      </div>
      <div className="logged-out-frame__footer">
        <hr className="redcircle-divider" />
        <div className="logged-out-frame__footer__copyright text-small">
          <small>© {currentYear} RedCircle Technologies Inc. All Rights Reserved.</small>
        </div>
        <div className="logged-out-frame__footer__links">
          <a className="redcircle-big-link" href="https://redcircle.com/privacy">
            <small>Privacy Policy</small>
          </a>
          <a className="redcircle-big-link" href="https://redcircle.com/terms">
            <small>Terms of Service</small>
          </a>
          <a className="redcircle-big-link" href="mailto:support@getredcircle.com">
            <small>Contact Us</small>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoggedOutFrame;
