import React, { useState } from "react";
import { If } from "react-extras";
import InfoTooltip from "../info";
import "./radio-input.scss";

export const RadioInput = ({ labelContent, labelClass, onClick, ...passThroughProps }) => (
  <div className={"radio-input"} onClick={onClick}>
    <input type="radio" {...passThroughProps} readOnly />
    <label htmlFor={passThroughProps.id} className={labelClass} onClick={onClick}>
      {labelContent && labelContent()}
    </label>
  </div>
);

export const RadioInputField = ({ options, value, onChange, defaultValue, className }) => {
  const optionValues = new Set(options.filter(({ isOther }) => !isOther).map(({ value }) => value));
  const [temporarilyClickedOther, updateOtherState] = useState(false);
  if ((value === undefined || value === "") && defaultValue !== undefined) {
    onChange(defaultValue);
  }
  const optionsToRender = options.map(
    ({
      isOther,
      display,
      value: optionValue,
      optionalFields,
      infoText,
      infoDirection = "right",
    }) => {
      const isOtherSelected = isOther && !optionValues.has(value);
      return (
        <div key={optionValue} className={className ? className : "m-vxs"}>
          <RadioInput
            onClick={() => {
              if (isOther) {
                updateOtherState(true);
              } else {
                onChange(optionValue);
                updateOtherState(false);
              }
            }}
            checked={
              !!(
                (value === optionValue && !temporarilyClickedOther) ||
                (isOther && isOtherSelected) ||
                (isOther && temporarilyClickedOther)
              )
            }
            labelContent={() => (
              <div className={"radio-input-label"}>
                {display}
                {infoText && <InfoTooltip helpText={infoText} direction={infoDirection} />}
              </div>
            )}
          />
          <If
            condition={
              !!(
                optionalFields &&
                (isOtherSelected || temporarilyClickedOther || value === optionValue)
              )
            }>
            {optionalFields && optionalFields({ onChange: (val) => onChange(val), value: value })}
          </If>
        </div>
      );
    }
  );
  return <div>{optionsToRender}</div>;
};
