import {
  VettingForm,
  VettingInvitation,
  VettingReduxState,
  isArrayOfVettingForms,
  isArrayOfVettingInvitations,
  isVettingForm,
  isVettingInvitation,
} from "./types";
import { httpReducer, reduceReducers } from "src/lib/create-action";
import {
  CREATE_VETTING_FORM,
  DELETE_VETTING_FORM,
  GET_VETTING_FORM,
  GET_VETTING_FORMS_FOR_CAMPAIGN,
  GET_VETTING_INVITATION,
  GET_VETTING_INVITATIONS_BY_VETTING_FORM,
  GET_VETTING_INVITATIONS_FOR_SHOW,
  GET_VETTING_INVITATIONS_FOR_CAMPAIGN_ITEM,
  REMIND_VETTING_INVITATION,
  RESPOND_TO_VETTING_INVITATION,
  SEND_VETTING_INVITATIONS,
  UPDATE_VETTING_FORM,
  REMIND_VETTING_INVITATIONS_BY_CART,
  GET_VETTING_INVITATIONS_FOR_CAMPAIGN,
} from "src/action_managers/vetting";

export const initialState: VettingReduxState = {
  forms: [],
  formsByUUID: {},
  formsByCampaignUUID: {},

  invitations: [],
  invitationsByUUID: {},
  invitationsByCampaignUUID: {},
  invitationsByCampaignItemUUID: {},

  isLoading: false,
  initialFetched: false,
};

/**
 * Helper func to update changes in Vetting forms across all vetting form sub
 * state slices.
 */
const getSlicesForVettingForm = ({
  initialState,
  vettingForms = [],
}: {
  initialState: VettingReduxState;
  vettingForms: VettingForm[];
}) => {
  // Start with vetting forms map by UUID
  const newFormsByUUID = { ...initialState.formsByUUID };

  for (const vettingForm of vettingForms) {
    newFormsByUUID[vettingForm.uuid] = { ...newFormsByUUID[vettingForm.uuid], ...vettingForm };
  }

  // Build vetting forms array
  const newVettingForms = Object.values(newFormsByUUID);

  // Build Vetting forms map by campaign UUID
  const newFormsByCampaignUUID = newVettingForms.reduce((accu, curr) => {
    if (!Array.isArray(accu[curr.campaignUUID])) accu[curr.campaignUUID] = [];

    accu[curr.campaignUUID].push(curr);

    return accu;
  }, {} as VettingReduxState["formsByCampaignUUID"]);

  return {
    newVettingForms,
    newFormsByUUID,
    newFormsByCampaignUUID,
  };
};
/**
 * Helper func to update changes in Vetting invitations across all vetting form sub
 * state slices.
 */
const getSlicesForVettingInvitation = ({
  initialState,
  vettingInvitations = [],
}: {
  initialState: VettingReduxState;
  vettingInvitations: VettingInvitation[];
}) => {
  // Start with vetting invitations map by UUID
  const newInvitationsByUUID = { ...initialState.invitationsByUUID };
  for (const vettingInvitation of vettingInvitations) {
    newInvitationsByUUID[vettingInvitation.uuid] = {
      ...newInvitationsByUUID[vettingInvitation.uuid],
      ...vettingInvitation,
    };
  }

  // Build Vetting Invitations array
  const newInvitations = Object.values(newInvitationsByUUID);

  // Build Vetting Invitations map by campaign UUID
  const newInvitationsByCampaignUUID = newInvitations.reduce((accu, curr) => {
    if (!Array.isArray(accu[curr.campaignUUID])) accu[curr.campaignUUID] = [];

    accu[curr.campaignUUID].push(curr);

    return accu;
  }, {} as VettingReduxState["invitationsByCampaignUUID"]);

  // Build vetting invitations map by campaign Item UUID
  const newInvitationsByCampaignItemUUID = newInvitations.reduce((accu, curr) => {
    if (!Array.isArray(accu[curr.campaignItemUUID])) accu[curr.campaignItemUUID] = [];

    accu[curr.campaignItemUUID].push(curr);

    return accu;
  }, {} as VettingReduxState["invitationsByCampaignItemUUID"]);

  return {
    newInvitations,
    newInvitationsByUUID,
    newInvitationsByCampaignUUID,
    newInvitationsByCampaignItemUUID,
  };
};

/**
 * Helper func to update changes in Vetting forms across all vetting form sub slices when
 * deleting vetting forms
 */

const getSlicesForVettingFormOnDelete = ({
  initialState,
  toBeDeleted = [],
}: {
  initialState: VettingReduxState;
  toBeDeleted: VettingForm[];
}) => {
  const uuidsToDelete = toBeDeleted.map((form) => form.uuid);

  const newVettingForms = initialState.forms.filter((form) => !uuidsToDelete.includes(form.uuid));

  const newFormsByUUID: VettingReduxState["formsByUUID"] = {};
  const newFormsByCampaignUUID: VettingReduxState["formsByCampaignUUID"] = {};

  for (const vettingForm of newVettingForms) {
    newFormsByUUID[vettingForm.uuid] = { ...vettingForm };

    if (!Array.isArray(newFormsByCampaignUUID[vettingForm.campaignUUID])) {
      newFormsByCampaignUUID[vettingForm.campaignUUID] = [];
    }

    newFormsByCampaignUUID[vettingForm.campaignUUID].push(vettingForm);
  }

  return {
    newVettingForms,
    newFormsByUUID,
    newFormsByCampaignUUID,
  };
};

/**
 * Reducers
 */

const getVettingForm = httpReducer(GET_VETTING_FORM, initialState, {
  success: (state = initialState, action) => {
    const vettingForms = isVettingForm(action.payload.resp) ? [action.payload.resp] : [];

    const { newVettingForms, newFormsByUUID, newFormsByCampaignUUID } = getSlicesForVettingForm({
      initialState: state,
      vettingForms: vettingForms,
    });

    return {
      ...state,
      forms: newVettingForms,
      formsByUUID: newFormsByUUID,
      formsByCampaignUUID: newFormsByCampaignUUID,
    };
  },
});

const getVettingInvitation = httpReducer(GET_VETTING_INVITATION, initialState, {
  success: (state = initialState, action) => {
    const newVettingInvitations = isVettingInvitation(action.payload.resp)
      ? [action.payload.resp]
      : [];

    const {
      newInvitations,
      newInvitationsByUUID,
      newInvitationsByCampaignUUID,
      newInvitationsByCampaignItemUUID,
    } = getSlicesForVettingInvitation({
      initialState: state,
      vettingInvitations: newVettingInvitations,
    });

    return {
      ...state,
      invitations: newInvitations,
      invitationsByUUID: newInvitationsByUUID,
      invitationsByCampaignUUID: newInvitationsByCampaignUUID,
      invitationsByCampaignItemUUID: newInvitationsByCampaignItemUUID,
    };
  },
});

const getVettingFormsForCampaign = httpReducer(GET_VETTING_FORMS_FOR_CAMPAIGN, initialState, {
  success: (state = initialState, action) => {
    const resp = isArrayOfVettingForms(action.payload.resp) ? action.payload.resp : [];

    const { newVettingForms, newFormsByUUID, newFormsByCampaignUUID } = getSlicesForVettingForm({
      initialState: state,
      vettingForms: resp,
    });

    return {
      ...state,
      forms: newVettingForms,
      formsByUUID: newFormsByUUID,
      formsByCampaignUUID: newFormsByCampaignUUID,
    };
  },
});

const getVettingInvitationsForShow = httpReducer(GET_VETTING_INVITATIONS_FOR_SHOW, initialState, {
  success: (state = initialState, action) => {
    const resp = isArrayOfVettingInvitations(action.payload.resp) ? action.payload.resp : [];
    const {
      newInvitations,
      newInvitationsByUUID,
      newInvitationsByCampaignUUID,
      newInvitationsByCampaignItemUUID,
    } = getSlicesForVettingInvitation({
      initialState,
      vettingInvitations: resp,
    });

    return {
      ...state,
      invitations: newInvitations,
      invitationsByUUID: newInvitationsByUUID,
      invitationsByCampaignUUID: newInvitationsByCampaignUUID,
      invitationsByCampaignItemUUID: newInvitationsByCampaignItemUUID,
    };
  },
});

const getVettingInvitationsForCampaignItem = httpReducer(
  GET_VETTING_INVITATIONS_FOR_CAMPAIGN_ITEM,
  initialState,
  {
    success: (state = initialState, action) => {
      const resp = isArrayOfVettingInvitations(action?.payload?.resp) ? action.payload.resp : [];

      const {
        newInvitations,
        newInvitationsByUUID,
        newInvitationsByCampaignUUID,
        newInvitationsByCampaignItemUUID,
      } = getSlicesForVettingInvitation({
        initialState: state,
        vettingInvitations: resp,
      });

      return {
        ...state,
        invitations: newInvitations,
        invitationsByUUID: newInvitationsByUUID,
        invitationsByCampaignUUID: newInvitationsByCampaignUUID,
        invitationsByCampaignItemUUID: newInvitationsByCampaignItemUUID,
      };
    },
  }
);

const getVettingInvitationsForVettingForm = httpReducer(
  GET_VETTING_INVITATIONS_BY_VETTING_FORM,
  initialState,
  {
    success: (state = initialState, action) => {
      const newVettingInvitations = isArrayOfVettingInvitations(action?.payload?.resp)
        ? action?.payload?.resp
        : [];

      const {
        newInvitations,
        newInvitationsByUUID,
        newInvitationsByCampaignUUID,
        newInvitationsByCampaignItemUUID,
      } = getSlicesForVettingInvitation({
        initialState: state,
        vettingInvitations: newVettingInvitations,
      });

      return {
        ...state,
        invitations: newInvitations,
        invitationsByUUID: newInvitationsByUUID,
        invitationsByCampaignUUID: newInvitationsByCampaignUUID,
        invitationsByCampaignItemUUID: newInvitationsByCampaignItemUUID,
      };
    },
  }
);
const getVettingInvitationsForCampaign = httpReducer(
  GET_VETTING_INVITATIONS_FOR_CAMPAIGN,
  initialState,
  {
    success: (state = initialState, action) => {
      const newVettingInvitations = isArrayOfVettingInvitations(action?.payload?.resp)
        ? action?.payload?.resp
        : [];

      const {
        newInvitations,
        newInvitationsByUUID,
        newInvitationsByCampaignUUID,
        newInvitationsByCampaignItemUUID,
      } = getSlicesForVettingInvitation({
        initialState: state,
        vettingInvitations: newVettingInvitations,
      });

      return {
        ...state,
        invitations: newInvitations,
        invitationsByUUID: newInvitationsByUUID,
        invitationsByCampaignUUID: newInvitationsByCampaignUUID,
        invitationsByCampaignItemUUID: newInvitationsByCampaignItemUUID,
      };
    },
  }
);

const createVettingForm = httpReducer(CREATE_VETTING_FORM, initialState, {
  success: (state = initialState, action) => {
    const newVettingForm = isVettingForm(action.payload.resp) ? [action.payload.resp] : [];

    const { newVettingForms, newFormsByUUID, newFormsByCampaignUUID } = getSlicesForVettingForm({
      initialState: state,
      vettingForms: newVettingForm,
    });

    return {
      ...state,
      forms: newVettingForms,
      formsByUUID: newFormsByUUID,
      formsByCampaignUUID: newFormsByCampaignUUID,
    };
  },
});

const updateVettingForm = httpReducer(UPDATE_VETTING_FORM, initialState, {
  success: (state = initialState, action) => {
    const updatedVettingForm = isVettingForm(action.payload.resp) ? [action.payload.resp] : [];

    const { newVettingForms, newFormsByUUID, newFormsByCampaignUUID } = getSlicesForVettingForm({
      initialState: state,
      vettingForms: updatedVettingForm,
    });

    return {
      ...state,
      forms: newVettingForms,
      formsByUUID: newFormsByUUID,
      formsByCampaignUUID: newFormsByCampaignUUID,
    };
  },
});

const deleteVettingForm = httpReducer(DELETE_VETTING_FORM, initialState, {
  success: (state = initialState, action) => {
    const deletedVettingForms = isVettingForm(action.payload.resp) ? [action.payload.resp] : [];

    const { newVettingForms, newFormsByUUID, newFormsByCampaignUUID } =
      getSlicesForVettingFormOnDelete({ initialState: state, toBeDeleted: deletedVettingForms });

    return {
      ...state,
      forms: newVettingForms,
      formsByUUID: newFormsByUUID,
      formsByCampaignUUID: newFormsByCampaignUUID,
    };
  },
});

const sendVettingInvitations = httpReducer(SEND_VETTING_INVITATIONS, initialState, {
  success: (state = initialState, action) => {
    const resp = isArrayOfVettingInvitations(action.payload.resp) ? action.payload.resp : [];

    const {
      newInvitations,
      newInvitationsByUUID,
      newInvitationsByCampaignUUID,
      newInvitationsByCampaignItemUUID,
    } = getSlicesForVettingInvitation({
      initialState: state,
      vettingInvitations: resp,
    });

    return {
      ...state,
      invitations: newInvitations,
      invitationsByUUID: newInvitationsByUUID,
      invitationsByCampaignUUID: newInvitationsByCampaignUUID,
      invitationsByCampaignItemUUID: newInvitationsByCampaignItemUUID,
    };
  },
});

const remindVettingInvitation = httpReducer(REMIND_VETTING_INVITATION, initialState, {
  success: (state = initialState, action) => {
    const resp = isVettingInvitation(action.payload.resp) ? [action.payload.resp] : [];

    const {
      newInvitations,
      newInvitationsByUUID,
      newInvitationsByCampaignUUID,
      newInvitationsByCampaignItemUUID,
    } = getSlicesForVettingInvitation({
      initialState: state,
      vettingInvitations: resp,
    });

    return {
      ...state,
      invitations: newInvitations,
      invitationsByUUID: newInvitationsByUUID,
      invitationsByCampaignUUID: newInvitationsByCampaignUUID,
      invitationsByCampaignItemUUID: newInvitationsByCampaignItemUUID,
    };
  },
});

/**
 * Does not change state for now. Might change in the future
 */
const remindVettingInvitationsInCart = httpReducer(
  REMIND_VETTING_INVITATIONS_BY_CART,
  initialState,
  {
    success: (state = initialState, action) => {
      return { ...state };
    },
  }
);

const respondToVettingInvitation = httpReducer(RESPOND_TO_VETTING_INVITATION, initialState, {
  success: (state = initialState, action) => {
    const respondedInvitations = isVettingInvitation(action.payload.resp)
      ? [action.payload.resp]
      : [];

    const {
      newInvitations,
      newInvitationsByUUID,
      newInvitationsByCampaignUUID,
      newInvitationsByCampaignItemUUID,
    } = getSlicesForVettingInvitation({
      initialState: state,
      vettingInvitations: respondedInvitations,
    });

    return {
      ...state,
      invitations: newInvitations,
      invitationsByUUID: newInvitationsByUUID,
      invitationsByCampaignUUID: newInvitationsByCampaignUUID,
      invitationsByCampaignItemUUID: newInvitationsByCampaignItemUUID,
    };
  },
});

export default reduceReducers(
  getVettingForm,
  getVettingInvitation,
  getVettingFormsForCampaign,
  getVettingInvitationsForShow,
  getVettingInvitationsForCampaignItem,
  getVettingInvitationsForVettingForm,
  getVettingInvitationsForCampaign,
  createVettingForm,
  updateVettingForm,
  deleteVettingForm,
  sendVettingInvitations,
  remindVettingInvitation,
  remindVettingInvitationsInCart,
  respondToVettingInvitation
);
