import { useEffect, useMemo, useRef, useState } from "react";
import { getCampaign } from "src/action_managers/campaigns";
import {
  getVettingForm,
  getVettingFormsForCampaignUUID,
  getVettingInvitationsForCampaign,
  getVettingInvitationsForShow,
} from "src/action_managers/vetting";
import { VettingStateAwaiting } from "src/constants/campaigns";
import { isUUID } from "src/lib/uuid";
import {
  getInvitationStatus,
  isLatestInvitePending,
  vettingInvitationStatus,
} from "src/lib/vetting";
import { ICampaignItem } from "src/reducers/campaign_items";
import { VettingForm, VettingInvitation, VettingQuestion } from "src/reducers/vetting";
import { getShowCampaign } from "../actions/campaigns";
import { useDispatchTS, useSelectorTS } from "./redux-ts";

// VETTING - PODCASTERS ============================================================================
interface IShowVettingInfo {
  vettingInvitationsByCampaignItemUUID: Record<string, VettingInvitation[]>;
  vettingForms: Record<string, VettingForm>;
  vettingCampaignItems: Record<string, ICampaignItem & { visible: boolean }>;
  isLoading: boolean;
}

/**
 * PODCASTERS: gets pending vetting invitations, campaignItems and forms for a show
 */
export const useGetShowVettingInfo = (showUUID: string): IShowVettingInfo => {
  const dispatch = useDispatchTS();
  const [isLoading, setIsLoading] = useState(true);

  const { invitationsByCampaignItemUUID, invitations, formsByUUID } = useSelectorTS(
    (state) => state.vetting
  );
  const { campaignItemByCampaignUUID, campaignItems } = useSelectorTS(
    (state) => state.campaignItems
  );

  // only get pending vetting invitations for show
  const showInvitations = useMemo(
    () =>
      invitations
        .filter((i) => i.showUUID === showUUID)
        .filter((i) => getInvitationStatus(i) === vettingInvitationStatus.Pending),
    [invitations]
  );
  const showFormUUIDs = useMemo(() => {
    const formUUIDs = showInvitations.map((i) => i.vettingFormUUID);
    return [...new Set(formUUIDs)];
  }, [showInvitations]);
  const showInvitationCampaignUUIDs = useMemo(() => {
    const campaignUUIDs = showInvitations.map((i) => i.campaignUUID);
    return [...new Set(campaignUUIDs)];
  }, [showInvitations]);
  const showInvitationCampaignItemUUIDs = useMemo(() => {
    const campaignItemUUIDs = showInvitations.map((i) => i.campaignItemUUID);
    return [...new Set(campaignItemUUIDs)];
  }, [showInvitations]);

  // get vetting invitations for show
  useEffect(() => {
    if (showUUID) {
      dispatch(getVettingInvitationsForShow(showUUID)).then(() => {
        setIsLoading(false);
      });
    }
  }, [showUUID]);

  // ensure vetting forms for show invitations
  useEffect(() => {
    if (showFormUUIDs.length > 0) {
      showFormUUIDs.forEach((formUUID) => {
        if (!formsByUUID[formUUID]) {
          dispatch(getVettingForm(formUUID));
        }
      });
    }
  }, [showFormUUIDs]);

  // ensure campaigns for show invitations
  useEffect(() => {
    if (showInvitationCampaignUUIDs.length > 0) {
      showInvitationCampaignUUIDs.forEach((campaignUUID) => {
        if (!campaignItemByCampaignUUID[campaignUUID] || !campaignItems[campaignUUID]) {
          dispatch(getShowCampaign(showUUID, campaignUUID, { silent: true }));
        }
      });
    }
  }, [showInvitationCampaignUUIDs]);

  // get all the data out!
  const vettingCampaignItems = showInvitationCampaignItemUUIDs.reduce((acc, ciUUID) => {
    const campaignItem = campaignItems[ciUUID];
    if (!campaignItem) return acc; // skip if campaign item is broken or not available
    return {
      ...acc,
      [ciUUID]: { ...campaignItem, state: VettingStateAwaiting },
    };
  }, {});

  return {
    vettingInvitationsByCampaignItemUUID: invitationsByCampaignItemUUID,
    vettingForms: formsByUUID,
    vettingCampaignItems, // campaignItems with "visible" flag
    isLoading,
  };
};

export const useShowHasVettingNotification = (showUUID: string) => {
  const dispatch = useDispatchTS();
  const [hasNotification, setHasNotification] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      dispatch(getVettingInvitationsForShow(showUUID)).then((res) => {
        if (res.status === 200 && res.json) {
          const isPending = isLatestInvitePending(res.json);
          if (isPending) setHasNotification(true);
        }
      });
    }

    return () => {
      ignore = true;
    };
  }, []);

  return hasNotification;
};

export const useGetVettingInfoForCampaignItem = (campaignItemUUID?: string) => {
  const dispatch = useDispatchTS();
  const { invitationsByCampaignItemUUID, formsByUUID, isLoading } = useSelectorTS(
    (state) => state.vetting
  );
  const invitations =
    campaignItemUUID && invitationsByCampaignItemUUID[campaignItemUUID]
      ? invitationsByCampaignItemUUID[campaignItemUUID].sort((a, b) => b.sentAt - a.sentAt)
      : [];
  const invitation = invitations[0];
  const form = invitation ? formsByUUID[invitation.vettingFormUUID] : undefined;

  const { campaignItemByCampaignUUID, campaignItems } = useSelectorTS(
    (state) => state.campaignItems
  );
  const campaignItem = campaignItemUUID ? campaignItems[campaignItemUUID] : undefined;

  // ensure vetting forms for show invitations
  useEffect(() => {
    if (invitation && invitation.vettingFormUUID) {
      if (!formsByUUID[invitation.vettingFormUUID]) {
        dispatch(getVettingForm(invitation.vettingFormUUID));
      }
    }
  }, [invitation]);

  // ensure campaigns for show invitations
  useEffect(() => {
    const campaignUUID = invitation?.campaignUUID;
    if (campaignUUID) {
      if (!campaignItemByCampaignUUID[campaignUUID] || !campaignItems[campaignUUID]) {
        dispatch(getShowCampaign(invitation.showUUID, campaignUUID, { silent: true }));
      }
    }
  }, [invitation]);

  return {
    invitation,
    form,
    campaignItem,
    isLoading,
  };
};

// VETTING - ADVERTISERS ===========================================================================

const MAX_ATTEMPTS = 5;

export const useGetVettingForm = (vettingFormUUID?: string) => {
  const { formsByUUID, isLoading: isVettingFormsLoading } = useSelectorTS((state) => state.vetting);

  const isUUIDAvailable = typeof vettingFormUUID === "string" && isUUID(vettingFormUUID);

  const vettingForm = isUUIDAvailable ? formsByUUID[vettingFormUUID] : undefined;

  const isVettingFormAvailable = !!vettingForm;

  const dispatch = useDispatchTS();

  // Capture fail fetch attempts
  const failedAttempts = useRef<number>(1);
  // Capture the first successful fetch
  const success = useRef<boolean>(false);

  // Reset fetch attempts counter on vettingFormUUID change
  useEffect(() => {
    failedAttempts.current = 1;
    success.current = false;
  }, [vettingFormUUID]);

  // Fetch Vetting form
  useEffect(() => {
    if (!isVettingFormsLoading) {
      // No other requests are pending
      if (isUUIDAvailable) {
        // UUID is provided to the hook
        if (!isVettingFormAvailable) {
          // Vetting is not currently in the redux state
          if (!success.current && failedAttempts.current < MAX_ATTEMPTS) {
            dispatch(getVettingForm(vettingFormUUID)).then((resp) => {
              if (resp.status === 200) {
                success.current = true;
              } else {
                failedAttempts.current++;
              }
            });
          }
        }
      }
    }
  }, [isVettingFormsLoading, isUUIDAvailable, isVettingFormAvailable, vettingFormUUID]);

  return vettingForm;
};

/**
 * Advertiser handle fetch all vetting invitations for a campaign
 */
export const useGetVettingInvitations = (campaignUUID?: string) => {
  const dispatch = useDispatchTS();
  const { formsByCampaignUUID } = useGetVettingFormsForCampaign(campaignUUID);

  const forms = formsByCampaignUUID?.[campaignUUID ?? ""] ?? [];

  const {
    invitationsByCampaignUUID,
    invitationsByCampaignItemUUID,
    isLoading: isInvitationsLoading,
    invitations,
    invitationsByUUID,
  } = useSelectorTS((state) => state.vetting);
  const isUUIDAvailable = typeof campaignUUID === "string" && isUUID(campaignUUID);
  const vettingFormsAvailable = forms?.length > 0;

  const formsUUIDs = forms?.map(({ uuid }) => uuid);
  const invitationsAlreadyInState =
    invitations?.filter(({ vettingFormUUID }) => formsUUIDs.includes(vettingFormUUID))?.length > 0;

  // Capture fail fetch attempts
  const failedAttempts = useRef<number>(1);
  // Capture the first successful fetch
  const success = useRef<boolean>(false);
  // Capture in progress state in parallelized fetch calls
  const inprogress = useRef<boolean>(false);

  // Reset fetch attempts counter on campaignUUID change
  useEffect(() => {
    failedAttempts.current = 1;
    inprogress.current = false;
    success.current = false;
  }, [campaignUUID]);

  // Fetch Vetting form
  useEffect(() => {
    if (!isInvitationsLoading) {
      // No other requests are pending
      if (isUUIDAvailable) {
        // Campaign UUID is provided to the hook
        if (vettingFormsAvailable) {
          // Campaign has Vetting forms available
          if (!invitationsAlreadyInState) {
            // invitations are not already available in state (i.e. its empty)
            if (!success.current && !inprogress.current && failedAttempts.current < MAX_ATTEMPTS) {
              // MAX attempts have not been reached
              dispatch(getVettingInvitationsForCampaign(campaignUUID)).then((resp) => {
                if (resp.status === 200) {
                  success.current = true;
                } else {
                  failedAttempts.current++;
                }
              });
              inprogress.current = true;
            }
          }
        }
      }
    }
  }, [isInvitationsLoading, isUUIDAvailable, vettingFormsAvailable, invitationsAlreadyInState]);

  return {
    invitations,
    invitationsByCampaignUUID,
    invitationsByUUID,
    invitationsByCampaignItemUUID,
    isInvitationsLoading,
  };
};

export const useGetVettingFormsForCampaign = (campaignUUID?: string) => {
  const dispatch = useDispatchTS();
  const { forms, formsByCampaignUUID, formsByUUID, isLoading, initialFetched } = useSelectorTS(
    (state) => state.vetting
  );

  const isUUIDAvailable = typeof campaignUUID === "string" && isUUID(campaignUUID);

  const formsAlreadyInState = formsByCampaignUUID[campaignUUID ?? ""]?.length > 0;

  // Capture fail fetch attempts
  const failedAttempts = useRef<number>(0);
  // Capture the first successful fetch
  const success = useRef<boolean>(false);
  // Captures progress of calls on reloading hook
  const inprogress = useRef<boolean>(false);

  // Reset fetch attempts counter on campaignUUID change
  useEffect(() => {
    failedAttempts.current = 0;
    inprogress.current = false;
    success.current = false;
  }, [campaignUUID]);

  // Fetch vetting forms for campaign
  useEffect(() => {
    if (!isLoading) {
      // No other requests are pending
      if (isUUIDAvailable) {
        // UUID is provided to the hook
        if (!formsAlreadyInState) {
          // Forms not in state, need to fetch
          if (!success.current && !inprogress.current && failedAttempts.current < MAX_ATTEMPTS) {
            // First success has not been reached, and below max amount of failed attempts
            dispatch(getVettingFormsForCampaignUUID(campaignUUID)).then((resp) => {
              if (resp.status === 200) {
                success.current = true;
              } else {
                failedAttempts.current++;
              }
              inprogress.current = false;
            });
            inprogress.current = true;
          }
        }
      }
    }
  }, [campaignUUID, isLoading, isUUIDAvailable, formsAlreadyInState]);

  return { forms, formsByCampaignUUID, formsByUUID, isLoading, initialFetched };
};

// ADVERTISER ONLY - gets response matrix for a campaign
export interface VettingResponseMatrix {
  [campaignItemUUID: string]: {
    [questionUUID: string]: {
      question: string;
      response: string;
    };
  };
}

export const useGetVettingResponseMatrix = (campaignUUID?: string): VettingResponseMatrix => {
  const { formsByCampaignUUID } = useGetVettingFormsForCampaign(campaignUUID);
  const { invitationsByCampaignUUID } = useGetVettingInvitations(campaignUUID);

  return getVettingResponseMatrix({ campaignUUID, formsByCampaignUUID, invitationsByCampaignUUID });
};

/**
 * Helper function to create vetting response matrix to be used in other functions
 * (i.e. hooks, csv downloads, etc)
 */
export const getVettingResponseMatrix = ({
  campaignUUID,
  invitationsByCampaignUUID,
  formsByCampaignUUID,
}: {
  campaignUUID?: string;
  invitationsByCampaignUUID: {
    [campaignUUID: string]: VettingInvitation[];
  };
  formsByCampaignUUID: {
    [campaignUUID: string]: VettingForm[];
  };
}) => {
  const campaignInvitations = campaignUUID && invitationsByCampaignUUID[campaignUUID];
  const campaignForms = campaignUUID && formsByCampaignUUID[campaignUUID];
  const allQuestions: Record<string, VettingQuestion> | undefined = campaignForms
    ? campaignForms
        .map((form) => form.questions)
        .flat()
        .reduce((acc, q) => ({ ...acc, [q.uuid]: q }), {})
    : undefined;

  const responseMatrix: VettingResponseMatrix = {};
  if (campaignInvitations && allQuestions) {
    campaignInvitations.forEach((invitation) => {
      const campaignItemUUID = invitation.campaignItemUUID;
      const responses = invitation.responses;
      if (responses) {
        responses.forEach((response) => {
          const questionUUID = response.questionUUID;
          const question = allQuestions[questionUUID];
          if (question && response.answer) {
            let answerString = response.answer.join(", ");
            if (response.other) answerString = answerString.concat(` (${response.other})`);
            if (!responseMatrix[campaignItemUUID]) responseMatrix[campaignItemUUID] = {};
            responseMatrix[campaignItemUUID][questionUUID] = {
              question: question.prompt,
              response: answerString,
            };
          }
        });
      }
    });
  }

  return responseMatrix;
};
