import React, { Component } from "react";
import { classNames } from "react-extras";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./table.scss";

class Table extends Component {
  render() {
    return (
      <div
        className={classNames(
          this.props.className,
          "standard-table",
          this.props.noBottomLine && "no-bottom-line",
          this.props.addTopLine && "add-top-line"
        )}>
        <ReactTable
          showPagination={false}
          showPageSizeOptions={false}
          sortable={this.props.sortable === undefined ? true : this.props.sortable}
          defaultPageSize={9999999}
          minRows={0}
          noDataText={"No Data Available Yet"}
          {...this.props}
        />
      </div>
    );
  }
}

export default Table;
