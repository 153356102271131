import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getStripeBalance } from "src/actions/balance";
import { withPermissions } from "src/lib/permissions-js";
import BalanceIndicator from "./balance_indicator";

function mapStateToProps(state) {
  return {
    user: state.user.user,
    isLoading: state.balance.isLoading,
    balance: state.balance.balance,
    stripeBalance: state.balance.stripeBalance,
    stripeBalancePending: state.balance.stripeBalancePending,
  };
}

const mapDispatchToProps = {
  getStripeBalance,
};

const BalanceIndicatorWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withPermissions(BalanceIndicator))
);

export default BalanceIndicatorWrapper;
