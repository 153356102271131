import { Dropdown, DropDownProps, Tooltip } from "antd";
import React from "react";

type onSelect = () => void;

type TMenuItem = {
  onSelect: onSelect;
  title?: string;
  disabled?: boolean;
  hide?: boolean;
  toolTipText?: string;
};

export type MenuItems = Record<string, TMenuItem | onSelect>;

export type IProps =
  | DropDownProps & {
      children?: React.ReactNode;
      className?: string;
      menuItems: MenuItems;
    };

export default function RCDropdown({ children, className, menuItems, ...rest }: IProps) {
  const renderMenuItem = ({
    onSelect,
    title = "",
    disabled = false,
    hide = false,
    toolTipText,
  }: TMenuItem) => {
    if (hide) return null;

    const item = (
      <a
        data-testid="tooltip-wrapped-text"
        href=""
        onClick={(e: any) => {
          e.preventDefault();
          onSelect();
        }}>
        {title}
      </a>
    );

    return {
      key: title,
      disabled,
      label: toolTipText ? <Tooltip title={toolTipText}>{item}</Tooltip> : item,
    };
  };

  const items = Object?.entries(menuItems)
    ?.map(([title, menuItem]) => {
      // cast setting to object if function
      let setting = menuItem;
      if (typeof setting === "function") setting = { onSelect: setting };
      return renderMenuItem({ ...setting, title });
    })
    ?.filter((item) => !!item);

  return (
    <Dropdown menu={{ items }} className={className} trigger={["click"]} {...rest}>
      {children}
    </Dropdown>
  );
}
