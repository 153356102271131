/*
this component is meant to be used as a read-through render prop
e.g.
       <WithPublicShow uuid={get(dealContent, "showUUID")}>
          {show => <div className="deal-box__content__show-value">{show.title}</div>}
        </WithPublicShow>
 */
// eslint-disable-next-line
import { Component } from "react";
import { connect } from "react-redux";
import { fetchPublicShow } from "../../action_managers/public_show";

class WithPublicShow extends Component {
  componentDidMount() {
    if (!this.props.publicShows[this.props.uuid]) {
      this.props.fetchPublicShow(this.props.uuid);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.uuid !== nextProps.uuid && !nextProps.publicShows[nextProps.uuid]) {
      nextProps.fetchPublicShow(nextProps.uuid);
    }
  }

  render() {
    const show = this.props.publicShows[this.props.uuid];
    return show ? this.props.children(show) : null;
  }
}

export default connect(({ publicShows }) => ({ publicShows }), { fetchPublicShow })(WithPublicShow);
