import get from "lodash/get";
import React, { Component } from "react";
import Dimensions from "react-dimensions";
import { classNames, If } from "react-extras";
import "./expandable_text.scss";

class ExpandableText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      examined: false,
    };
    this.ref = React.createRef();
  }

  maxHeight = () => {
    if (this.props.maxHeight) {
      return this.props.maxHeight;
    }
    if (this.props.heightToWidth) {
      return this.props.containerWidth / this.props.heightToWidth;
    }
    return 250; // default
  };

  componentDidMount() {
    setTimeout(this.checkHeight, 0);
  }

  componentDidUpdate() {
    setTimeout(this.checkHeight, 0);
  }

  checkHeight = () => {
    const getBoundingClientRect = get(this.ref, "current.getBoundingClientRect");
    if (!getBoundingClientRect) {
      return;
    }
    const height = this.ref.current.getBoundingClientRect().height;
    if (height && height !== 0 && !this.state.examined) {
      if (height < this.maxHeight()) {
        this.setState({ examined: true });
        return;
      }
      this.setState({ examined: true, collapsed: true });
    }
  };

  render() {
    var shouldCollapse = this.state.collapsed || !this.state.examined;
    var containerStyle = {
      maxHeight: shouldCollapse ? this.maxHeight() : "none",
      overflow: shouldCollapse ? "hidden" : "auto",
    };

    return (
      <div className="expandable-text" ref={this.ref}>
        <div
          className={classNames("expandable-text__visible", shouldCollapse && "collapsed")}
          style={containerStyle}>
          <div
            className="expandable-text__text"
            dangerouslySetInnerHTML={{
              __html: `${this.props.dangerousText}`,
            }}
          />
          <If condition={shouldCollapse}>
            <div className="expandable-text__blocker" />
          </If>
        </div>
        <If condition={shouldCollapse}>
          <div className="expandable-text__more-button-wrapper">
            <span
              className="expandable-text__more-button"
              onClick={() => {
                this.setState({ collapsed: false, examined: true });
              }}>
              {this.props.moreText || "More..."}
            </span>
          </div>
        </If>
      </div>
    );
  }
}

export default Dimensions()(ExpandableText);
