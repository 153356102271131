import { Link, useHistory } from "react-router-dom";
import RCButton from "src/components/lib/button/button";
import ExternalLink from "src/components/lib/external_link/external_link";
import { permissionTypes } from "src/constants/permission_roles";
import { useSelectorTS } from "src/hooks/redux-ts";
import { useCanAccess } from "src/lib/permissions";
import { IShow } from "redcircle-types";

export default function RAPGettingStarted({ show }: { show?: IShow }) {
  const history = useHistory();
  const { user } = useSelectorTS((state) => state.user);
  const canEditRap = useCanAccess(permissionTypes.editRap, show?.uuid);

  if (!user) return null;
  return (
    <div className="flex-column-container align-center">
      {!user.isStripeConnected && (
        <p>
          Before getting started with advertising, you need to{" "}
          <Link to={"/monetization"}>connect your banking info with RedCircle.</Link>
        </p>
      )}

      {user.isStripeConnected && (
        <>
          <p>
            Welcome to the RedCircle Ad Platform! Set up your RAP profile now to start generating
            revenue with host read{!show?.isRemote ? " and programmatic" : ""} ads that excite your
            listeners.
          </p>
          {canEditRap && (
            <>
              <RCButton
                type="primary"
                size="large"
                className="m-bxs m-txs p-xxl"
                onClick={() => history.push(`/ad-platform/${show?.uuid}/settings/rates`)}>
                Get Started
              </RCButton>
              <ExternalLink
                to={
                  "https://support.redcircle.com/setting-up-advertising-in-the-redcircle-ad-platform-rap"
                }>
                <strong>Learn More</strong>
              </ExternalLink>
            </>
          )}
        </>
      )}
    </div>
  );
}
