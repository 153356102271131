import get from "lodash/get";
import moment from "moment-timezone";
import React from "react";
import { If } from "react-extras";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import ListViewRow from "src/components/lib/list_view_row";
import Loading from "src/components/lib/loading";
import { permissionTypes } from "src/constants/permission_roles";
import { useCanAccess } from "src/lib/permissions";

const ShowDetailsEpisodeList = (props) => {
  const canEdit = useCanAccess(permissionTypes.editEpisodesDraft, props.showUUID);

  const getSubtext = (publishedAt, isVisible) => {
    var publishedInFuture = publishedAt * 1000 > new Date().valueOf();
    var formattedDate = moment
      .tz(publishedAt * 1000, moment.tz.guess())
      .format("MMMM D, YYYY [at] h:mm a z");
    if (!isVisible) {
      return `Unpublished Draft`;
    }
    if (publishedInFuture) {
      return `Draft, To Publish on ${formattedDate}`;
    }
    return `Published on ${formattedDate}`;
  };

  const getColumnInfo = (totalRows) => {
    return [
      {
        Cell: (cellProps) => {
          return (
            <ListViewRow
              key={cellProps.row.uuid}
              linkTo={`/shows/${cellProps.row.showUUID}/ep/${cellProps.row.uuid}`}
              mainText={cellProps.row.title}
              dropUp={cellProps.index > totalRows - 3}
              subText={getSubtext(cellProps.row.publishedAt, cellProps.row.isVisible)}
              menuItems={{
                "Episode Details": () => {
                  props.historyPush(`/shows/${cellProps.row.showUUID}/ep/${cellProps.row.uuid}`);
                },
                "Edit Episode": {
                  onSelect: () =>
                    props.historyPush(
                      `/shows/${cellProps.row.showUUID}/ep/${cellProps.row.uuid}/edit`
                    ),
                  hide: !canEdit,
                },
              }}
              historyPush={props.historyPush}
            />
          );
        },

        filterable: true,
        filterMethod: (filter, row) =>
          row.title.toLowerCase().includes(get(filter, "value", "").toLowerCase()),

        // This is overridden just to specify the placeholder.
        Filter: ({ filter, onChange }) => {
          return (
            <div className="m-bxs">
              <input
                className="form-control"
                onChange={(event) => onChange(event.target.value)}
                value={filter ? filter.value : ""}
                placeholder="Search..."
              />
            </div>
          );
        },
      },

      // These are included because they are referenced in the 'Cell' for episode title
      // And we need them to be in props.row.*
      {
        accessor: "uuid",
        show: false,
      },
      {
        accessor: "showUUID",
        show: false,
      },
      {
        accessor: "publishedAt",
        show: false,
      },
      {
        accessor: "isVisible",
        show: false,
      },
      {
        accessor: "title",
        show: false,
      },
    ];
  };

  var allEpisodes = [];
  if (props.episodes) {
    for (var uuid of Object.keys(props.episodes || {})) {
      allEpisodes.push(props.episodes[uuid]);
    }
    allEpisodes.reverse();
  }
  // we want to overflow the containers when there are less than 5 items so that the dropdown menu
  // on each row does not get cut off by the container.
  const additionalStyle = (state) => {
    if (state.sortedData.length <= 5) {
      return { style: { overflow: "visible" } };
    }
    return {};
  };
  var isLoading = !props.episodes || props.episodes.isLoading;

  // Well...if a show has 1000+ episodes the episode page really starts to struggle in terms of performance
  // So we turn on the default paginator to make the page usable...even if its a little ugly...it's only for like 5
  // users.
  const isGiantShow = allEpisodes.length >= 1000;
  const canAddNewEpisode = useCanAccess(permissionTypes.editEpisodesDraft, props.showUUID);

  return (
    <div className="show-details-episode-list">
      {isLoading && <Loading />}
      <If condition={Boolean(allEpisodes.length) && !isLoading}>
        <ReactTable
          getTbodyProps={additionalStyle}
          getTableProps={additionalStyle}
          data={allEpisodes}
          columns={getColumnInfo(allEpisodes.length)}
          showPagination={isGiantShow}
          showPageSizeOptions={false}
          defaultPageSize={isGiantShow ? 100 : 9999999}
          minRows={0}
        />
      </If>
      <If condition={!allEpisodes.length && !isLoading && canAddNewEpisode}>
        <div className="show-details-page__no-episode-text">
          No Episodes{" "}
          {!props.show?.isRemote && (
            <span>
              - <Link to={"/shows/" + props.showUUID + "/ep/create"}>Create One</Link>
            </span>
          )}
        </div>
      </If>
    </div>
  );
};

export default ShowDetailsEpisodeList;
