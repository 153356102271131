/** Constants for useShowSearch and /shows?... call */
export const SHOW_SEARCH_FILTER_TYPE_ADS = "ads";

export const SHOW_SEARCH_SORT_OPTIONS = {
  relevance: {
    label: "Relevance",
    value: "_score",
    direction: "desc",
  },
  downloadsDesc: {
    label: "Most Downloads",
    value: "estimatedWeeklyDownloads",
    direction: "desc",
  },
  // downloadsAsc: {
  //   label: "Least Downloads",
  //   value: "estimatedWeeklyDownloads",
  //   direction: "asc",
  // },
  updatedAtDesc: { label: "Last Updated", value: "updatedAt", direction: "desc" },
  alphaAsc: { label: "A-Z", value: "title.keyword", direction: "asc" },
  // createdAtDesc: { label: "Newest", value: "createdAt", direction: "desc" },
  // createdAtAsc: { label: "Oldest", value: "createdAt", direction: "asc" },
};

export const SHOW_SEARCH_MIN_DOWNLOADS = 0;
export const SHOW_SEARCH_MAX_DOWNLOADS = 300000;
const SHOW_SEARCH_MARKS_DOWNLOADS_COUNT = 5;
export const SHOW_SEARCH_MARKS_DOWNLOADS = [
  ...Array(SHOW_SEARCH_MARKS_DOWNLOADS_COUNT + 1).keys(),
].reduce((acc: any, curr) => {
  const SPACING = SHOW_SEARCH_MAX_DOWNLOADS / SHOW_SEARCH_MARKS_DOWNLOADS_COUNT;
  acc[curr * SPACING] = `${(curr * SPACING) / 1000}k`;
  if (curr === SHOW_SEARCH_MARKS_DOWNLOADS_COUNT)
    acc[curr * SPACING] = `${(curr * SPACING) / 1000}k+`;
  return acc;
}, {});

export const SHOW_SEARCH_MIN_CPM = 1;
export const SHOW_SEARCH_MAX_CPM = 30; // in dollars
export const SHOW_SEARCH_MARKS_CPM = {
  1: "$1",
  5: "$5",
  10: "$10",
  15: "$15",
  20: "$20",
  25: "$25",
  30: "$30+",
};

export const SHOW_SEARCH_FILTER_CREATED = [
  // { label: "Last 24 hours", value: 1 },
  // { label: "Last 7 days", value: 7 },
  // { label: "Last 30 days", value: 30 },
  { label: "Last 90 days", value: 90 },
  { label: "Last 6 months", value: 180 },
  { label: "Last year", value: 365 },
  { label: "All time", value: 0 },
];

export const SHOW_SEARCH_FILTER_EPISODE_FREQUENCY = {
  daily: { label: "Daily", value: "daily", range: [1, 1] },
  // everyFewDaily: { label: "Every Few Days", value: "everyFewDaily", range: [2, 6] },
  weekly: { label: "Weekly", value: "weekly", range: [2, 10] },
  // everyFewWeekly: { label: "Every Few Weeks", value: "everyFewWeekly", range: [11, 28] },
  monthly: { label: "Monthly", value: "monthly", range: [11, 31] },
  sporadically: { label: "Sporadically", value: "sporadically", range: [32, null] },
  all: { label: "All", value: "all", range: [null, null] },
};

// the number of "recommended" shows to show at the top of a campaign search
// before showing the rest
export const SHOW_SEARCH_NUM_RECOMMENDED_TO_SHOW = 15;

export const SHOW_SEARCH_FILTER_PRIMARY_GENDER = [
  { label: "All", value: "all" },
  { label: "Female", value: "female" },
  { label: "Male", value: "male" },
];
