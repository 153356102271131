import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { VictoryContainer, VictoryPie } from "victory";
import { graphColors } from "./utils";

const StatsPie = (props) => {
  const {
    containerHeight,
    containerWidth,
    borderWidth = 20,
    // labelByRow data[0].x --> string
    labelByRow,
    // data is of the form {total: number x: label y: number}
    data = [],
  } = props;

  const [label, setLabel] = useState("");
  const [percent, setPercent] = useState("");
  if (isEmpty(data)) {
    return null;
  }

  const outerRadius = Math.min(containerHeight, containerWidth / 2) / 2;
  const innerRadius = outerRadius - borderWidth;

  return (
    <div className="stats-widget-app-graph">
      <div className="stats-widget-app-graph__pie">
        <div
          className="stats-widget-app-graph__pielabel"
          style={{
            width: innerRadius * 2,
            height: innerRadius * 2,
            marginLeft: -innerRadius,
            marginTop: -innerRadius,
            borderRadius: innerRadius,
          }}>
          <div
            style={{ borderRadius: innerRadius }}
            className="stats-widget-app-graph__pielabel__inner redcircle-label">
            {label}
            <br />
            {percent}
          </div>
        </div>
        <VictoryPie
          data={data}
          containerComponent={
            <VictoryContainer
              resposive={true}
              style={{
                height: containerHeight,
                width: containerWidth / 2,
              }}
            />
          }
          radius={outerRadius}
          innerRadius={innerRadius}
          height={containerHeight}
          width={containerWidth / 2}
          standalone={true}
          padding={0}
          labels={() => ""}
          colorScale={graphColors}
          events={[
            {
              target: "data",
              eventHandlers: {
                onMouseOver: () => {
                  return [
                    {
                      target: "data",
                      mutation: ({ datum }) => {
                        const total = datum.total;
                        setLabel(labelByRow[datum.x]);
                        setPercent(Math.round((datum.y / total) * 100) + "%");
                      },
                    },
                  ];
                },
                onMouseOut: () => {
                  return [
                    {
                      target: "data",
                      mutation: () => {
                        setLabel("");
                        setPercent("");
                      },
                    },
                  ];
                },
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default StatsPie;
