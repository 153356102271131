import { Form } from "antd";
import { useEffect } from "react";
import { Modal } from "redcircle-ui";
import RAPSettingsFormHosts from "src/components/modals/show_advertising_settings_modal/rap_settings_form_hosts";
import { IShow } from "redcircle-types";

interface IProps {
  show?: IShow;
  onSubmit: (values: any) => void;
  onClose: () => void;
}

export default function ConfirmMailingPage({ show, onSubmit, onClose }: IProps) {
  const [form] = Form.useForm();
  const advertisementSettings = Form.useWatch("advertisementSettings", form);
  const hasAddress = advertisementSettings?.hosts?.[0]?.postalAddress?.StreetAddress?.length > 0;

  useEffect(() => {
    if (show) {
      form.setFieldsValue({ advertisementSettings: show.advertisementSettings });
    }
  }, [show]);

  const handleSubmit = () => {
    const fields = form.getFieldsValue();
    onSubmit(fields);
  };

  return (
    <Form form={form}>
      <div className="p-xxl p-bs">
        <RAPSettingsFormHosts required />
      </div>

      <Modal.Footer>
        <Modal.CloseButton onClick={onClose} />
        <Modal.SubmitButton onClick={handleSubmit} disabled={!hasAddress}>
          Continue
        </Modal.SubmitButton>
      </Modal.Footer>
    </Form>
  );
}
