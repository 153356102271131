import { InfoCircleFilled } from "@ant-design/icons";
import { Divider, Form, Input, Select, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import LoadingButton from "src/components/forms/loading_button/loading_button";
import Button from "src/components/lib/button";
import Drawer from "src/components/lib/drawer";
import { pluralize } from "src/lib/strings";
import { AudioBlock } from "src/reducers/audio_blocks";
import { CustomAudio } from "src/reducers/custom_audio";
import { MediaFile } from "redcircle-types";
import { v4 as uuid } from "uuid";
import styles from "./audio_blocks.module.scss";
import { UnsavedChangesModal } from "./audio_blocks_modals";
import AudioSelector from "./audio_selector";
import { IAudioBlockFormItem, PLAY_ORDER_INORDER, PLAY_ORDER_RANDOM } from "./consts";

const DROPPABLE_ID_ACTIVE = "active-audio";
const DROPPABLE_ID_FALLBACK = "fallback-audio";
interface IProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  isEditingBlock?: AudioBlock; // if editing, pass block and initialize with values
  readOnly: boolean;
  isRAPEnabled?: boolean;
  isCrossPromoEnabled?: boolean;
  customAudios?: Record<string, CustomAudio>;
  mediaFiles?: Record<string, MediaFile>;
}

// reorder item in one list
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// move item from one list to another
const move = (
  sourceList: any[],
  destList: any[],
  droppableSource: any,
  droppableDestination: any
) => {
  const source = [...sourceList];
  const dest = [...destList];
  const [removed] = source.splice(droppableSource.index, 1);
  dest.splice(droppableDestination.index, 0, removed);
  return {
    [droppableSource.droppableId]: source,
    [droppableDestination.droppableId]: dest,
  };
};

export default function AudioBlocksDrawer({
  visible,
  onClose,
  onSubmit,
  isEditingBlock,
  readOnly,
  isRAPEnabled,
  isCrossPromoEnabled,
  customAudios,
  mediaFiles,
}: IProps) {
  const [form] = Form.useForm<{ name: string; playStyle: string; playCount: number }>();
  const [playCount, setPlayCount] = useState(0);
  const playStyle = Form.useWatch("playStyle", form);
  const [audioFiles, setAudioFiles] = useState<IAudioBlockFormItem[]>([]);
  const splitIndex = playStyle !== PLAY_ORDER_RANDOM ? playCount : audioFiles.length;
  const activeFiles = audioFiles.slice(0, splitIndex);
  const fallbackFiles = audioFiles.slice(splitIndex);
  const totalAudioFiles = audioFiles.length;

  const [isDragging, setDragging] = useState(false);
  const [showUnsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const [formError, setFormError] = useState<string>();
  const nameInputRef = useRef<any>();

  useEffect(() => {
    if (visible) {
      nameInputRef?.current?.focus();
    }

    // RESET DATA
    if (!visible) {
      form.resetFields();
      setAudioFiles([]);
      setPlayCount(0);
      setFormError(undefined);
    }
  }, [visible]);

  useEffect(() => {
    if (isEditingBlock) {
      const editingPlayCount = isEditingBlock.limit || isEditingBlock.items.length;
      form.setFieldsValue({ name: isEditingBlock.name, playStyle: isEditingBlock.playStyle });
      setPlayCount(editingPlayCount);
      if (isEditingBlock.items) {
        // generate random id for react keys, will be removed at submit call
        setAudioFiles(isEditingBlock.items.map((item: any) => ({ id: uuid(), ...item })));
      }
    }
  }, [isEditingBlock]);

  const onClickAddAudio = (fallback?: boolean) => {
    if (!fallback) {
      const newActiveFiles = [...activeFiles, { id: uuid() }];
      setAudioFiles([...newActiveFiles, ...fallbackFiles]);
    } else {
      const newFallbackFiles = [...fallbackFiles, { id: uuid() }];
      setAudioFiles([...activeFiles, ...newFallbackFiles]);
    }
    if (!fallback) setPlayCount((p) => p + 1);
  };

  const handleSelectAudio = (index: number, value: IAudioBlockFormItem) => {
    const newAudioFiles = [...audioFiles];
    newAudioFiles[index] = { ...newAudioFiles[index], ...value };
    setAudioFiles(newAudioFiles);
    if (formError) setFormError(undefined);
  };

  const handleRemoveAudio = (index: number) => {
    const newAudioFiles = [...audioFiles];
    newAudioFiles.splice(index, 1);
    setAudioFiles(newAudioFiles);
    if (index < playCount) setPlayCount((p) => p - 1);
    if (formError) setFormError(undefined);
  };

  const handleDragEnd = ({ source, destination }: any) => {
    setDragging(false);
    if (destination) {
      const srcAudioFiles =
        source.droppableId === DROPPABLE_ID_ACTIVE ? [...activeFiles] : [...fallbackFiles];
      const destAudioFiles =
        destination.droppableId === DROPPABLE_ID_ACTIVE ? [...activeFiles] : [...fallbackFiles];

      if (source.droppableId === destination.droppableId) {
        // drop to same list
        const newFiles = reorder(srcAudioFiles, source.index, destination.index);
        setAudioFiles(
          source.droppableId === DROPPABLE_ID_ACTIVE
            ? [...newFiles, ...fallbackFiles]
            : [...activeFiles, ...newFiles]
        );
      } else {
        // drop to different list
        const {
          [source.droppableId]: srcNewAudioFiles,
          [destination.droppableId]: destNewAudioFiles,
        } = move(srcAudioFiles, destAudioFiles, source, destination);
        setAudioFiles(
          source.droppableId === DROPPABLE_ID_ACTIVE
            ? [...srcNewAudioFiles, ...destNewAudioFiles]
            : [...destNewAudioFiles, ...srcNewAudioFiles]
        );
        setPlayCount(
          source.droppableId === DROPPABLE_ID_ACTIVE
            ? srcNewAudioFiles.length
            : destNewAudioFiles.length
        );
      }
    }
  };

  const validate = (values?: any) => {
    let hasError = false;
    if (audioFiles.some((audioFile) => !audioFile.type)) {
      setFormError("Please select an audio file");
      hasError = true;
    }
    return !hasError;
  };

  const handleSubmit = (values: any) => {
    if (validate(values) && onSubmit) {
      const scrubbedAudioFiles = audioFiles.map((audioFile) => ({
        type: audioFile.type,
        customAudioUUID: audioFile.customAudioUUID,
        mediaFileUUID: audioFile.mediaFileUUID,
        playLimitPerEpisode: audioFile.playLimitPerEpisode,
      }));

      onSubmit({
        name: values.name,
        playCount,
        playStyle: values.playStyle,
        audioFiles: scrubbedAudioFiles,
      });
    }
  };

  const checkForUnsavedChanges = () => {
    if (readOnly) return false;
    let hasUnsavedChanges = false;
    if (!isEditingBlock) {
      hasUnsavedChanges = audioFiles.length > 0 && audioFiles.some((audioFile) => audioFile.type);
    } else {
      const selectedAudioFiles = audioFiles.filter((audioFile) => audioFile.type);
      const editLimit =
        isEditingBlock.limit === null ? isEditingBlock.items.length : isEditingBlock.limit;
      hasUnsavedChanges =
        isEditingBlock.playStyle !== playStyle ||
        editLimit !== playCount ||
        isEditingBlock.items.length !== selectedAudioFiles.length;
    }
    return hasUnsavedChanges;
  };

  const handleClose = () => {
    const hasUnsavedChanges = checkForUnsavedChanges();
    if (hasUnsavedChanges) {
      setUnsavedChangesModal(true);
    } else {
      onClose();
    }
  };

  const renderTitle = () => {
    if (isEditingBlock && readOnly) return "View Audio Block";
    if (isEditingBlock) return "Edit Audio Block";
    return "New Audio Block";
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    backgroundColor: isDraggingOver ? "#E8F4FA" : "transparent",
    border: isDragging ? "1px dashed #c6c6c6" : "1px solid transparent",
  });

  return (
    <Drawer open={visible} onClose={handleClose} maskClosable={false}>
      {visible && (
        <Form
          form={form}
          initialValues={{ name: "", playStyle: PLAY_ORDER_INORDER }}
          name="audio-block-form"
          className="flex-column-container height-100"
          onFinish={handleSubmit}>
          <Drawer.Header>
            <h3 className="m-b0">{renderTitle()}</h3>
          </Drawer.Header>

          <Drawer.Body>
            <strong>
              <small>NAME</small>
            </strong>
            {!readOnly && (
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please enter audio block name" }]}>
                <Input
                  ref={nameInputRef}
                  size="large"
                  placeholder="Enter Audio Block Name"
                  maxLength={100}
                />
              </Form.Item>
            )}
            {readOnly && isEditingBlock && <h3>{isEditingBlock.name}</h3>}

            <div className="flex-row-container align-center m-bm">
              <span className="m-rxxxs">Play</span>
              <Select
                className="highlighted m-rxxxs"
                disabled={!totalAudioFiles || readOnly}
                value={playCount}
                onChange={(v) => setPlayCount(v)}>
                {[...Array(totalAudioFiles + 1)].map((_, index) => (
                  <Select.Option key={index} value={index} disabled={readOnly}>
                    {index}
                  </Select.Option>
                ))}
              </Select>
              <span className="m-rxxxs">out of</span>
              <span className="m-rxxxs">
                {totalAudioFiles} {pluralize("audio", totalAudioFiles)}
              </span>
              <Form.Item name="playStyle" className="m-b0">
                <Select className="highlighted" disabled={readOnly}>
                  <Select.Option key={PLAY_ORDER_INORDER} value={PLAY_ORDER_INORDER}>
                    in order
                  </Select.Option>
                  <Select.Option key={PLAY_ORDER_RANDOM} value={PLAY_ORDER_RANDOM}>
                    randomly
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>

            {/* SELECTORS */}
            {formError && <span className="text-danger">{formError}</span>}
            <DragDropContext onDragEnd={handleDragEnd} onDragStart={() => setDragging(true)}>
              <Droppable droppableId={DROPPABLE_ID_ACTIVE}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    className={styles.list}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}>
                    {activeFiles.map((audioFile, index) => (
                      <AudioSelector
                        key={audioFile.id}
                        index={index}
                        labelIndex={index}
                        audioFile={audioFile}
                        playStyle={playStyle}
                        customAudios={customAudios}
                        mediaFiles={mediaFiles}
                        onSelect={(value) => handleSelectAudio(index, value)}
                        onRemove={() => handleRemoveAudio(index)}
                        disabled={readOnly}
                        isRAPEnabled={isRAPEnabled}
                        isCrossPromoEnabled={isCrossPromoEnabled}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              {!readOnly && (
                <div>
                  <Button type="secondary" onClick={() => onClickAddAudio()}>
                    Add Audio
                  </Button>
                </div>
              )}

              {playStyle !== PLAY_ORDER_RANDOM && (
                <>
                  {!(readOnly && !fallbackFiles.length) && (
                    <>
                      <Divider className="m-txl m-bxs" />
                      <span className="preheader m-bxxs">
                        FALLBACK AUDIO
                        <Tooltip
                          className="m-lxxs"
                          title="This audio will be inserted if any of the above audio cannot be. The max number of allowed audios still applies."
                          placement="bottom">
                          <InfoCircleFilled className={styles.info} />
                        </Tooltip>
                      </span>
                    </>
                  )}

                  <Droppable droppableId={DROPPABLE_ID_FALLBACK}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        className={styles.list}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}>
                        {fallbackFiles.map((audioFile, index) => (
                          <AudioSelector
                            key={audioFile.id}
                            index={index}
                            labelIndex={index + activeFiles.length}
                            audioFile={audioFile}
                            customAudios={customAudios}
                            mediaFiles={mediaFiles}
                            playStyle={playStyle}
                            onSelect={(value) =>
                              handleSelectAudio(index + activeFiles.length, value)
                            }
                            onRemove={() => handleRemoveAudio(index + activeFiles.length)}
                            disabled={readOnly}
                            isRAPEnabled={isRAPEnabled}
                            isCrossPromoEnabled={isCrossPromoEnabled}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>

                  {!readOnly && (
                    <div>
                      <Button type="link" className="p-l0" onClick={() => onClickAddAudio(true)}>
                        + Add Fallback Audio
                      </Button>
                    </div>
                  )}
                </>
              )}
            </DragDropContext>

            {!readOnly && isEditingBlock && (
              <div className="flex-row-container justify-center m-tl">
                <Link to={`/dynamic-insertion/audio-blocks/delete/${isEditingBlock.uuid}`}>
                  <strong>Delete Audio Block</strong>
                </Link>
              </div>
            )}
          </Drawer.Body>

          {!readOnly && (
            <Drawer.Footer className="flex-row-container justify-space-between">
              <Button type="link" size="large" className="p-l0" onClick={handleClose}>
                Cancel
              </Button>

              <LoadingButton type="submit" disabled={!audioFiles.length && !fallbackFiles.length}>
                Save
              </LoadingButton>
            </Drawer.Footer>
          )}

          <UnsavedChangesModal
            visible={showUnsavedChangesModal}
            onClose={() => setUnsavedChangesModal(false)}
            onSubmit={() => onClose()}
          />
        </Form>
      )}
    </Drawer>
  );
}
