import { AnyAction } from "redux";
import { HIDE_MODAL, SHOW_MODAL } from "../../actions/modal";
import { ModalReduxState } from "./types";

const initialState: ModalReduxState = {
  modalType: null,
  modalProps: {},
};

function modal(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
}

export default modal;
