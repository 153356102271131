import React from "react";
import { Col, Row } from "react-bootstrap";
import { If } from "react-extras";
import "react-phone-number-input/style.css";
import { useHistory } from "react-router-dom";
import { Field, Fields, reduxForm } from "redux-form";
import RCButton from "src/components/lib/button/button";
import { seedcastForm } from "../../../lib/forms";
import { useComponentDidMount } from "../../../lib/hook";
import FormElement from "../../forms/form_element";
import LoadingButton from "../../forms/loading_button/loading_button";
import { PhoneNumberInputComponent } from "../../forms/phone_input/phone_input";
import PasswordField from "../../lib/password_field";
import EmailVerificationDetails from "../../pages/account/email_verification_details";

let UserForm = (props) => {
  const history = useHistory();
  useComponentDidMount(() => {
    if (typeof props.user !== "undefined" && props.user !== null && props.mode === "create") {
      history.push("/");
    }
  });
  var isEditForm = props.mode === "edit";

  // set defaults
  props = {
    ...props,
    ...{
      submitEnabled: props?.submitEnabled ?? true,
    },
  };
  return (
    <form onSubmit={props.handleSubmit} data-testid="form">
      <Row>
        <Col xs={12} md={6}>
          <Field
            component={FormElement}
            name="fname"
            label="First Name"
            type="text"
            validationErrors={props.validationErrors}
            validationErrorKeyOverride="firstName"
          />
        </Col>
        <Col xs={12} md={6}>
          <Field
            component={FormElement}
            name="lname"
            label="Last Name"
            type="text"
            validationErrors={props.validationErrors}
            validationErrorKeyOverride="lastName"
          />
        </Col>
      </Row>

      <If condition={isEditForm}>
        <div>
          <Field
            component={FormElement}
            name="companyName"
            label="Organization Name"
            type="text"
            validationErrors={props.validationErrors}
          />
        </div>
      </If>
      <div>
        <Field
          component={FormElement}
          name="email"
          label="Email Address"
          type="email"
          validationErrors={props.validationErrors}
          helpText={
            isEditForm && (
              <EmailVerificationDetails
                user={props.user}
                sendVerificationEmail={function (e) {
                  e.stopPropagation();
                  props.sendVerificationEmail(props.user);
                }}
              />
            )
          }
        />
      </div>
      <If condition={isEditForm}>
        <div>
          <Fields
            names={["phoneNumber", "phoneNumberCountryCode"]}
            component={FormElement}
            InputComponent={PhoneNumberInputComponent}
            label="Phone Number"
            type="text"
            validationErrors={props.validationErrors}
            validationErrorKeyOverride={"phoneNumber"}
            props={{ inputRef: props.phoneInputRef }}
          />
        </div>
      </If>
      <div>
        <PasswordField validationErrors={props.validationErrors} password={props.password} />
      </div>
      <Row>
        <Col md={props.logOut ? 6 : 12} xs={12}>
          <LoadingButton
            isLoading={props.isLoading}
            disabled={!props.submitEnabled}
            type="submit"
            className="sign-in-form__submit-button width-100 m-bxxs">
            {isEditForm ? "Save" : "Create Account"}
          </LoadingButton>
        </Col>
        <If condition={Boolean(props.logOut)}>
          <Col md={6} xs={12}>
            <RCButton
              type="secondary"
              size="large"
              className="sign-in-form__submit-button width-100"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.logOut();
              }}>
              Log Out
            </RCButton>
          </Col>
        </If>
      </Row>
    </form>
  );
};

UserForm = reduxForm({
  form: "sign-up",
})(UserForm);
UserForm = seedcastForm(UserForm);

export default UserForm;
