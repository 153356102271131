import { Form, Tooltip, TreeSelect } from "antd";
import UpgradeTag from "src/components/lib/upgrade-tag";
import daastAdCategories from "src/data/daast-ad-categories";
import { useSelectorTS } from "src/hooks/redux-ts";
import { minimumAllowedTier } from "src/lib/tier-utils";
import { tierLevel } from "src/reducers/types";
import { BrandsExcludeAutoComplete } from "./brands_exclude_autocomplete";

export default function RAPSettingsFormExclusions() {
  const { tier } = useSelectorTS((state) => state?.user);
  const userCanExclude = minimumAllowedTier(tierLevel.growth, tier, true);

  return (
    <div className="flex-column-container align-start">
      <div>
        <div className="flex-row-container align-center">
          <h5 className="m-rxxs">Select Advertising Categories to Exclude</h5>
          {!userCanExclude && <UpgradeTag />}
        </div>
        <p>
          Expand and review the entire list of subcategories below, and select any you'd like to opt
          out of. Make sure to save your category exclusions so no unwanted ads are inserted into
          your show.
        </p>

        <Tooltip title={userCanExclude ? "" : "Upgrade to Growth to exclude categories"}>
          <Form.Item name={["advertisementSettings", "excludedCategories"]}>
            <TreeSelect
              treeData={daastAdCategories}
              className="width-100"
              size="large"
              treeCheckable
              multiple
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              placeholder="Select advertising categories to exclude"
              disabled={!userCanExclude}
              filterTreeNode={(input, node) =>
                node && node.title
                  ? (node.title as string).toLowerCase().includes(input.toLowerCase())
                  : false
              }
            />
          </Form.Item>
        </Tooltip>
      </div>
      <div className="width-100">
        <Form.Item
          label={<h5 className="m-rxxs">Select Brands to Exclude</h5>}
          labelCol={{ span: 24 }}
          colon={false}
          name={["advertisementSettings", "excludedBrandInstanceUUIDs"]}>
          <BrandsExcludeAutoComplete />
        </Form.Item>
      </div>
    </div>
  );
}
