import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { DEFAULT_URL_BY_ROLES } from "src/constants/roles";
import getDefaultRole from "src/lib/user_roles";
import LoggedOutFrame from "../../lib/logged_out_frame";
import AdminSignInAsForm from "./admin_sign_in_as_form";

class AdminSignInAsPage extends Component {
  loginAs = (...args) => {
    this.props.loginAs(...args).then((resp) => {
      const defaultRole = getDefaultRole(resp.json);
      const defaultURL = DEFAULT_URL_BY_ROLES[defaultRole] ?? "/";
      this.props.history.push(defaultURL);
    });
  };

  render() {
    return (
      <LoggedOutFrame Title="Admin Sign In As..." subtitle="Sign in as another user">
        <Row>
          <Col xs={12} md={6} mdOffset={3}>
            <AdminSignInAsForm
              isLoading={this.props.isLoading}
              onSubmit={this.loginAs}
              user={this.props.user}
              logout={this.props.logout}
            />
          </Col>
        </Row>
      </LoggedOutFrame>
    );
  }
}

export default AdminSignInAsPage;
