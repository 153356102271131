import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Component } from "react";
import { matchPath } from "react-router-dom";
import { Button, Modal } from "redcircle-ui";
import { formValueSelector, getFormSyncErrors } from "redux-form";
import messageTypes from "../../constants/message_types";
import { contactOwnerCopy, permissionTypes } from "../../constants/permission_roles";
import { useSelectorTS } from "../../hooks/redux-ts";
import { getCroppedImg } from "../../lib/image-uploader";
import { showHasExclusiveContent } from "../../lib/show";
import { EpisodeCreateForm, EpisodeEditForm } from "../pages/episodes/episode_form";
class EpisodeFormModal extends Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    var showUUID = this.getShowUUID();
    if (showUUID) {
      this.props.fetchShow(showUUID, this.props.user);

      this.props.getYoutubeForShow(showUUID);
    }

    var episodeUUID = this.getEpisodeUUID();
    if (showUUID && episodeUUID) {
      this.props.fetchEpisode(showUUID, episodeUUID, this.props.user).then(() => {
        var episode = this.getEpisode();
        if (episode) {
          if (episode.contentMediaFileUUID) {
            this.props.fetchMediaFile(episode.contentMediaFileUUID, this.props.user.authToken);
          }
          if (episode.imageMediaFileUUID) {
            this.props.fetchMediaFile(episode.imageMediaFileUUID, this.props.user.authToken);
          }
        }
      });
    }
  }

  isLoading = () => {
    var episodeInfo = get(this.props.episodesByShow, [this.getShowUUID()]);
    return (
      this.props.isLoading ||
      get(episodeInfo, "isPutting") ||
      this.state.isLoading ||
      this.props.episodesByShow?.isLoading
    );
  };

  close = (newEpisode) => {
    this.props.history.push(this.props.closeRoute(this.props.location));
    this.props.closeModal();
  };

  getShowUUID = () => {
    var result = matchPath(this.props.location.pathname, "/shows/:showUUID/ep/:episodeUUID");
    return get(result, "params.showUUID");
  };

  getEpisodeUUID = () => {
    var result = matchPath(this.props.location.pathname, "/shows/:showUUID/ep/:episodeUUID");
    var episodeUUID = get(result, "params.episodeUUID");
    // on the create page, the path is such that the episode UUID will appear to be create.

    return episodeUUID === "create" ? null : episodeUUID;
  };

  getEpisodeInfo = () => {
    var showUUID = this.getShowUUID();
    return get(this.props.episodesByShow, showUUID);
  };

  getEpisode = () => {
    var episodeInfo = this.getEpisodeInfo();
    var episodeUUID = this.getEpisodeUUID();
    return get(episodeInfo, ["episodes", episodeUUID]);
  };

  getShow = () => {
    var showUUID = this.getShowUUID();
    return get(this.props.shows.shows, showUUID);
  };

  onSubmit = async (episodeData, _, props) => {
    const { episode = {} } = props;
    let newEpisode = { ...episodeData };
    if (!showHasExclusiveContent(this.getShow())) {
      newEpisode.shouldPublishToNonSubscribers = true;
    }
    let needsToInsertMarkers = false;

    if (newEpisode.publishedAt) {
      // adjust episode time for epoch
      const newPublishedAt = Math.round(newEpisode.publishedAt.valueOf() / 1000);
      newEpisode.publishedAt = newPublishedAt;
    }

    // Make sure these are treated as numbers.
    if (newEpisode.season) {
      newEpisode.season = parseInt(newEpisode.season, 10);
    }
    if (newEpisode.episodeNumber) {
      newEpisode.episodeNumber = parseInt(newEpisode.episodeNumber, 10);
    }

    // Make sure there is an audio file uploaded on create.
    if (!newEpisode.audioFileData && !this.isEditMode() && newEpisode.isVisible) {
      this.props.showMessageWithTimeout("An audio file is required.", messageTypes.Error);
      return;
    }

    this.setState({ isLoading: true });
    var validator = this.isEditMode()
      ? this.props.validateEpisodeUpdate
      : this.props.validateEpisodeCreate;
    return validator(newEpisode, this.props.user).then((result) => {
      const sendToInterim =
        newEpisode.isVisible &&
        this.shouldInsertMarkers(needsToInsertMarkers) &&
        (newEpisode.audioFileData || needsToInsertMarkers);
      if (result?.status !== 200) {
        this.setState({ isLoading: false });
        if (sendToInterim) {
          return Promise.reject();
        }
        return;
      }
      const uploadMediaFiles = [
        this.uploadImagePromise(newEpisode),
        this.uploadAudioPromise(newEpisode),
      ];
      if (sendToInterim) {
        this.props.openLoadingModal({
          uploadAndEditEpisode: () => this.uploadAndEditEpisode(uploadMediaFiles, newEpisode, true),
          show: this.getShow(),
          needsToInsertMarkers: needsToInsertMarkers,
        });
        return;
      }
      return this.uploadAndEditEpisode(uploadMediaFiles, newEpisode, false)
        .then((submitResult) => this.postEdit(submitResult, needsToInsertMarkers))
        .catch((e) => {
          this.props.setError(e.message);
        });
    });
  };

  uploadAndEditEpisode = (uploadMediaFiles, newEpisode, silent) => {
    const auidoFileAvailable = !isEmpty(newEpisode?.audioFileData);
    const imageFileAvailable = this.props.imageUploader.touched;
    if (auidoFileAvailable || imageFileAvailable) {
      return Promise.all(uploadMediaFiles).then((results) => {
        const [imageUUID, audioUUID] = results;

        if (imageUUID) {
          delete newEpisode.imageFileData;
          newEpisode.imageMediaFileUUID = imageUUID;
        }

        if (audioUUID) {
          delete newEpisode.audioFileData;
          newEpisode.contentMediaFileUUID = audioUUID;
        }

        // Bail and rely on the audio upload error to display an error.
        // Update: Bail only when a specific media file(audio or image) is available and it fails
        if (
          !this.isEditMode() &&
          ((auidoFileAvailable && !audioUUID) || (imageFileAvailable && !imageUUID))
        ) {
          return;
        }

        return this.submitHandler(newEpisode, this.props.user, silent);
      });
    } else {
      return this.submitHandler(newEpisode, this.props.user, silent);
    }
  };
  shouldInsertMarkers = (needsToInsertMarkers) => {
    const show = this.getShow();
    return (
      show?.canShareAudienceSize ||
      show?.advertisementSettings?.isHostReadEnabled ||
      show?.advertisementSettings?.isPreRecordedEnabled ||
      needsToInsertMarkers
    );
  };

  postEdit = (submitResult, needsToInsertMarkers) => {
    if (submitResult.status === 200) {
      this.setState({
        isLoading: false,
      });
      if (
        submitResult?.json?.isVisible &&
        submitResult?.json?.contentMediaFileUUID &&
        this.shouldInsertMarkers(needsToInsertMarkers)
      ) {
        this.props.history.push(
          `/shows/${submitResult.json.showUUID}/ep/${submitResult.json.uuid}/insertion-points?postUpload=true`
        );
      } else {
        this.props.closeModal();
        this.props.history.push(
          `/shows/${submitResult.json.showUUID}/ep/${submitResult.json.uuid}`
        );
      }
    }
  };

  uploadImagePromise = (episodeData) => {
    if (this.props.imageUploader.touched) {
      if (!this.props.imageUploader.image) {
        return Promise.resolve(null);
      }
      return getCroppedImg(this.props.imageUploader).then((image) => {
        var fileName = get(this.props.imageUploader, "fileName");
        var existingURL = get(this.props, [
          "mediaFiles",
          get(this.getEpisode(), "imageMediaFileUUID"),
          "url",
        ]);
        if (existingURL) {
          fileName = existingURL.split("/").pop();
        }

        return this.props.fileUpload(image, fileName, this.props.user).then((result) => {
          if (result?.status === 200) {
            const imageUUID = get(result, "json[0].uuid");
            return imageUUID;
          }
        });
      });
    }

    return Promise.resolve(episodeData.imageMediaFileUUID);
  };

  uploadAudioPromise = (episodeData) => {
    const fileTooLargeErorrMessage =
      "Your audio file is too large. RedCircle's max audio file size is 400 MB. Try re-exporting your audio at a lower bitrate.";
    if (episodeData.audioFileData) {
      if (episodeData.audioFileData[0].size > 4 * 10 ** 8) {
        return Promise.reject(new Error(fileTooLargeErorrMessage));
      }
      return this.props
        .fileUpload(
          episodeData.audioFileData[0],
          episodeData.audioFileData[0].name,
          this.props.user,
          (e) => this.props.uploadProgress(e.loaded / e.total)
        )
        .then((result) => {
          if (get(result, "status") === 413) {
            return Promise.reject(new Error(fileTooLargeErorrMessage));
          }
          if (get(result, "json.length")) {
            return get(
              result.json.find(({ bitrate }) => bitrate === 128),
              "uuid",
              get(result, "json[0].uuid")
            );
          }
        });
    }
    return Promise.resolve(episodeData.contentMediaFileUUID);
  };

  submitHandler = (data, user, silent) => {
    return this.isEditMode()
      ? this.props.updateEpisode(data, user, silent)
      : this.props.createEpisode(data, user, silent);
  };

  isEditMode = () => {
    return this.props.mode === "edit";
  };

  getInitialValues = () => {
    let show = this.getShow();
    let subscriptionsEnabled = !!get(show, ["subscriptionDetails", "amount"]);

    if (this.isEditMode()) {
      var initialValues = { ...this.getEpisode() };
      initialValues.publishedAt *= 1000;
      initialValues.isVisible = !get(this.getEpisode(), "isVisible");
      return initialValues;
    }

    const mindDate = Date.now() + 10 * 60 * 1000; // Add 10 minutes to current time in order to provide enough leeway for podcaster to set insertion point.
    return {
      showUUID: this.getShowUUID(),
      isClosedCaptioned: false,
      shouldPublishToNonSubscribers: true,
      shouldPublishToSubscribers: subscriptionsEnabled,
      isExplicit: false,
      isVisible: false,
      episodeType: "full",
      adPlacements: [0, 0],
      publishedAt: new Date(mindDate),
    };
  };

  saveButtonText = () => {
    if (!this.isEditMode() || !this.getEpisode()?.isVisible) {
      return "Publish";
    }
    return "Update";
  };

  canPublishEpisode = () => {
    return this.props.canAccess(permissionTypes.editEpisodes, this.getShowUUID());
  };

  handleSubmitClicked = (isVisible) => {
    this.props.updateIsVisible(isVisible);
    setTimeout(() => {
      this.props.submitForm(this.isEditMode());
    }, 0);
  };

  render() {
    var Form = this.isEditMode() ? EpisodeEditForm : EpisodeCreateForm;

    return (
      <Modal open loading={this.isEditMode() && !this.getEpisode()} onClose={this.close}>
        <Modal.Title>{this.isEditMode() ? "Edit Episode" : "Create Episode"}</Modal.Title>
        <Modal.Body>
          <Form
            initialValues={this.getInitialValues()}
            isFormLoading={this.isLoading()}
            progress={this.props.progress}
            setError={this.props.setError}
            mediaFiles={this.props.mediaFiles}
            onSubmit={this.onSubmit}
            show={this.getShow()}
            validationErrors={get(this.getEpisodeInfo(), "validationErrors")}
            fileUpload={this.props.fileUpload}
            setImageFileUUID={(uuid) => this.props.setImageFileUUID(uuid, this.props.mode)}
            setContentFileUUID={(uuid) => this.props.setContentFileUUID(uuid, this.props.mode)}
            setImageFileData={(data) => {
              this.props.setImageFileData(this.props.mode, data);
            }}
            setAudioFileData={(data) => {
              this.props.setAudioFileData(this.props.mode, data);
            }}
            user={this.props.user}
            tier={this.props.tier}
            episode={this.getEpisode()}
          />
        </Modal.Body>
        <ModalFooter
          publishPermitted={this.getEpisode()?.isVisible || this.canPublishEpisode()} // this get episode is only avaiable in edit mode
          isLoading={this.isLoading()}
          handleSubmitClicked={this.handleSubmitClicked}
          progress={this.props.progress}
          saveButtonText={this.saveButtonText()}
          isEditMode={this.isEditMode()}
        />
      </Modal>
    );
  }
}

const shouldDisablePublish = (formvalues, formSyncErrors) => {
  const {
    shouldPublishToSubscribers,
    shouldPublishToNonSubscribers,
    audioFileData,
    contentMediaFileUUID,
    description,
  } = formvalues;
  /**
   * Validate:
   * Title is not empty
   * Audio is appropriate size for user tier
   */
  if (!isEmpty(formSyncErrors)) return true;

  // check for description
  if (typeof description !== "string" || isEmpty(description) || description === "<p><br></p>") {
    return true;
  }

  // Ensures audio file is not empty, the sync error validation
  // only runs after file has been added
  if (!audioFileData && !contentMediaFileUUID) return true;

  // check episode is being published to at least 1 group (subscribers or non subscribers, or both)
  return !shouldPublishToSubscribers && !shouldPublishToNonSubscribers;
};

const shouldDisableDraft = ({ title }) => {
  return !title;
};

const ModalFooter = ({
  isLoading,
  handleSubmitClicked,
  progress,
  saveButtonText,
  isEditMode,
  publishPermitted,
}) => {
  const formName = isEditMode ? "episode-edit" : "episode-create";
  const formValues = useSelectorTS((state) =>
    formValueSelector(formName)(
      state,
      "title",
      "description",
      "shouldPublishToSubscribers",
      "shouldPublishToNonSubscribers",
      "audioFileData",
      "contentMediaFileUUID"
    )
  );

  const formSyncErrors = useSelectorTS((state) => getFormSyncErrors(formName)(state));
  return (
    <Modal.Footer>
      <Button
        loading={isLoading}
        onClick={() => handleSubmitClicked(false)}
        type={"secondary"}
        size="large"
        disabled={shouldDisableDraft(formValues)}>
        Save as Draft
      </Button>
      <Button
        loading={isLoading}
        size="large"
        type="primary"
        disabled={shouldDisablePublish(formValues, formSyncErrors) || !publishPermitted}
        disabledMessage={!publishPermitted && contactOwnerCopy}
        onClick={() => handleSubmitClicked(true)}
        progress={progress}>
        {saveButtonText}
      </Button>
    </Modal.Footer>
  );
};

export default EpisodeFormModal;
