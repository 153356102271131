import React, { lazy, PropsWithChildren, Suspense } from "react";
import Spinner from "../../../lib/spinner";
import { graphFonts } from "../analyticsUtility";
import Message from "./Messages";
import "./redcircle_graphs.scss";

const Column = lazy(() => import("@ant-design/plots/es/components/column"));

// Inferring Graph config type
type graphConfig = Parameters<typeof Column>[number];

// Interface with Generic T of type datapoint to restrict field types
interface IRCLolumn<T extends Record<string, any>> extends graphConfig {
  data: T[];
  xField: string & keyof T;
  yField: string & keyof T;
  seriesField?: string & keyof T;
  loading?: boolean;
  noDataMessage?: string;
}

const RCColumn = <T extends Record<string, any>>(props: PropsWithChildren<IRCLolumn<T>>) => {
  const {
    data = [],
    loading = false,
    height = 260,
    color = ["#1979C9", "#D62A0D", "#FAA219"],
    noDataMessage,
    ...rest
  } = props;

  const config: graphConfig = {
    data,
    autoFit: true,
    style: {
      minHeight: `${height}px`,
    },
    height,
    loading,
    xAxis: {
      label: {
        style: {
          fontFamily: graphFonts,
          fontSize: 11,
          lineHeight: 16,
          fontWeight: 400,
          letterSpacing: 0.6,
          color: "#000000",
        },
      },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineDash: [15, 15],
            strokeOpacity: 0.1,
          },
        },
      },
    },
    yAxis: {
      label: {
        style: {
          fontFamily: graphFonts,
          fontSize: 11,
          lineHeight: 16,
          fontWeight: 400,
          letterSpacing: 0.6,
          color: "#000000",
        },
      },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineDash: [15, 15],
            strokeOpacity: 0.1,
          },
        },
      },
    },
    color,
    ...rest,
  };

  return (
    <div className="analytics-graphContainer" style={{ minHeight: `${height}px` }}>
      <Suspense fallback={<Spinner />}>
        <Column {...config} />
      </Suspense>
      <Message
        show={!loading && data?.length === 0}
        text={noDataMessage}
        type="Insufficient Data"
      />
    </div>
  );
};

export default RCColumn;
