import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showMessageWithTimeout } from "../../actions/app";
import { hideModal } from "../../actions/modal";
import { uploadModalBitrateSet } from "../../actions/upload_modal";
import UploadModalActionManager from "../../action_managers/upload_modal";
import messageType from "../../constants/message_types";
import AudioUploadModal from "./audio_upload_modal";

function mapStateToProps(state) {
  return {
    user: state.user.user,
    isLoading: state.mediaFileUpload.isLoading,
    mediaFiles: state.mediaFileUpload.mediaFiles,
    bitrate: state.mediaFileUpload.bitrate,
    errorMessage: state.mediaFileUpload.errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(hideModal());
    },
    doUpload: (file, fileName, user) => {
      return dispatch(
        new UploadModalActionManager({
          file,
          fileName,
          authToken: user.authToken,
        }).run()
      );
    },
    setBitrate: (bitrate) => {
      dispatch(uploadModalBitrateSet(parseInt(bitrate, 10)));
    },
    setError: (errorMessage) => {
      dispatch(showMessageWithTimeout(errorMessage, messageType.Error));
    },
  };
}

const AudioUploadModalWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AudioUploadModal)
);

export { AudioUploadModalWrapper };
