import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { showWarning } from "src/actions/app";
import {
  audioBlockCreate,
  audioBlockUpdate,
  deleteAudioBlock,
  getAudioBlock,
  getAudioBlocksForUser,
  getAudioBlockStatsForUser,
  getCustomAudioForUser,
  updateMarkers,
} from "src/action_managers/audio_management";
import { getMyPermissions } from "src/action_managers/permissions";
import BlockFeature from "src/components/lib/block_feature";
import Button from "src/components/lib/button";
import EmptyStateBlock from "src/components/lib/empty_state_block";
import Loading from "src/components/lib/loading";
import { permissionTypes } from "src/constants/permission_roles";
import { useGetAudioBlockMediaFiles, useUserRAPEnabled } from "src/hooks/audio_blocks";
import { useUserCrossPromoEnabled } from "src/hooks/cross_promo";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { goToPricingPage } from "src/lib/config";
import { useCanAccessBound } from "src/lib/permissions";
import { minimumAllowedTier } from "src/lib/tier-utils";
import { tierLevel } from "redcircle-types";
import "./audio_blocks.scss";
import AudioBlocksDrawer from "./audio_blocks_drawer";
import { BulkAssignBlockModal, DeletePromptModal } from "./audio_blocks_modals";
import AudioBlocksTable from "./audio_blocks_table";

export default function AudioBlocksSection() {
  const { tab, modifier, objectUUID } = useParams<{
    tab?: string;
    modifier?: string;
    objectUUID?: string;
  }>();
  const history = useHistory();
  const dispatch = useDispatchTS();
  const { user, tier } = useSelectorTS((state) => state.user);
  const isUserLoading = useSelectorTS((state) => state.user.isLoading);
  const [isStatsCalled, setStatsCalled] = useState(false);
  const [isStatsLoading, setStatsLoading] = useState(true);
  const { isLoading: isAudioBlocksLoading, audioBlocks } = useSelectorTS(
    (state) => state.audioBlocks
  );
  const { isLoading: isCustomAudioLoading, customAudios } = useSelectorTS(
    (state) => state.customAudios
  );

  // TODO: remove media files
  const { mediaFiles } = useGetAudioBlockMediaFiles();

  const hasAudioBlocks = audioBlocks && Object.keys(audioBlocks).length > 0;
  const isDrawerOpen =
    tab === "audio-blocks" && (modifier === "edit" || modifier === "create" || modifier === "view");

  // PERMS
  const canAccess = useCanAccessBound();
  const userHasPerms = minimumAllowedTier(tierLevel.growth, tier, true);
  const userIsOptedInRAP = useUserRAPEnabled();
  const userIsOptedInCrossPromo = useUserCrossPromoEnabled();
  const userCanCreate = canAccess(permissionTypes.createAudioBlock);

  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    if (!isAudioBlocksLoading && isStatsLoading && !isStatsCalled) {
      setStatsCalled(true);
      dispatch(getAudioBlockStatsForUser(user.uuid)).then(() => setStatsLoading(false));
    }
  }, [isAudioBlocksLoading, isStatsLoading, isStatsCalled]);

  useEffect(() => {
    if (tab === "audio-blocks") {
      dispatch(getAudioBlocksForUser(user));
      dispatch(getCustomAudioForUser(user));
    }
  }, [tab]);

  useEffect(() => {
    if (isInitialLoading && !isUserLoading && !isAudioBlocksLoading) {
      setIsInitialLoading(false);
    }
  }, [isUserLoading, isAudioBlocksLoading]);

  const handleSubmitAudioBlock = (values: any) => {
    const isEditing = modifier === "edit";
    if (!isEditing) {
      const { name, playStyle, playCount, audioFiles } = values;
      dispatch(audioBlockCreate(name, playStyle, playCount, Object.values(audioFiles))).then(
        (res: any) => {
          if (res.status === 200) {
            dispatch(getMyPermissions());
            history.push(`/dynamic-insertion/audio-blocks`);
          } else {
            dispatch(showWarning(res.json?.message || "Error creating audio block"));
          }
        }
      );
    } else if (objectUUID) {
      const { name, playStyle, playCount, audioFiles } = values;
      dispatch(
        audioBlockUpdate(objectUUID, name, playStyle, playCount, Object.values(audioFiles))
      ).then((res: any) => {
        if (res.status === 200) {
          dispatch(getAudioBlock(objectUUID)).then(() => {
            history.push(`/dynamic-insertion/audio-blocks`);
          });
        } else {
          dispatch(showWarning(res.json?.message || "Error updating audio block"));
        }
      });
    }
  };

  const handleAssignMarkers = async (
    markersToUpdate: Record<string, boolean>,
    audioBlockUUID: string
  ) => {
    const updates = Object.entries(markersToUpdate).map(([markerUUID, toAssign]) => ({
      audioBlockUUID: toAssign ? audioBlockUUID : null, // clear audioBlockUUID when setting false
      uuid: markerUUID,
    }));
    if (updates.length) {
      return dispatch(updateMarkers(updates)).then((res) => {
        if (res.status === 200) {
          dispatch(getAudioBlockStatsForUser(user.uuid));
        } else {
          dispatch(showWarning("Error updating markers"));
        }
        return res;
      });
    } else {
      const error = "No markers selected";
      dispatch(showWarning(error));
      return Promise.reject(error);
    }
  };

  const handleDeleteBlock = async () => {
    dispatch(deleteAudioBlock(objectUUID));
  };

  return (
    <div className="flex-column-container">
      <div className="flex-row-container justify-space-between align-center m-bxs">
        <h3 className="m-b0">Audio Blocks</h3>
        {!isInitialLoading && (userHasPerms || userIsOptedInRAP) && userCanCreate && (
          <Button
            type="secondary"
            onClick={() => history.push("/dynamic-insertion/audio-blocks/create")}>
            Add New Audio Block
          </Button>
        )}
      </div>

      {isInitialLoading && <Loading />}
      {!isInitialLoading && (
        <BlockFeature
          block={!isUserLoading && !(userHasPerms || userIsOptedInRAP)}
          blockbyBlur
          minHeight={500}
          CTA={{
            text: "Dynamic Insertion of Audio Blocks is available starting from the Growth Plan or upon joining the RedCircle Ad Platform.",
            btn: { text: "Upgrade Your Plan", handler: () => goToPricingPage() },
          }}>
          {hasAudioBlocks && (
            <AudioBlocksTable
              audioBlocks={audioBlocks}
              customAudios={customAudios}
              mediaFiles={mediaFiles}
              isStatsLoading={isStatsLoading}
              onEditBlock={(block) => {
                history.push("/dynamic-insertion/audio-blocks/edit/" + block.uuid);
              }}
              onDeleteBlock={(block) =>
                history.push("/dynamic-insertion/audio-blocks/delete/" + block.uuid)
              }
              onAssignBlock={(block) =>
                history.push("/dynamic-insertion/audio-blocks/assign/" + block.uuid)
              }
              onViewBlock={(block) =>
                history.push("/dynamic-insertion/audio-blocks/view/" + block.uuid)
              }
            />
          )}
          {!hasAudioBlocks && !isAudioBlocksLoading && (
            <EmptyStateBlock>
              <span>
                Create an audio block and assign it to insertion points to start dynamically
                inserting audio into your episodes.{" "}
                <a
                  href="https://support.redcircle.com/audio-blocks"
                  target="_blank"
                  rel="noreferrer">
                  Learn More
                </a>
              </span>
            </EmptyStateBlock>
          )}
        </BlockFeature>
      )}

      {/* MODALS */}
      <AudioBlocksDrawer
        visible={isDrawerOpen}
        onClose={() => history.push("/dynamic-insertion/audio-blocks")}
        onSubmit={handleSubmitAudioBlock}
        isEditingBlock={isDrawerOpen && objectUUID ? audioBlocks[objectUUID] : undefined}
        readOnly={modifier === "view"}
        isRAPEnabled={userIsOptedInRAP}
        isCrossPromoEnabled={userIsOptedInCrossPromo}
        customAudios={customAudios}
        mediaFiles={mediaFiles}
      />

      <DeletePromptModal
        visible={modifier === "delete"}
        onClose={() => history.push("/dynamic-insertion/audio-blocks")}
        onSubmit={handleDeleteBlock}
      />

      <BulkAssignBlockModal
        visible={modifier === "assign"}
        onClose={() => history.push("/dynamic-insertion/audio-blocks")}
        onSubmit={handleAssignMarkers}
        audioBlock={objectUUID ? audioBlocks[objectUUID] : undefined}
      />
    </div>
  );
}
