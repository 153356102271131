import Konva from "konva";
import { Group } from "konva/lib/Group";
import { Line } from "konva/lib/shapes/Line";
import { Rect } from "konva/lib/shapes/Rect";
import { Text } from "konva/lib/shapes/Text";
import { CreatePointMarkerOptions, PeaksInstance, PointMarker } from "peaks.js";

type views = "zoomview" | "overview";

export type RedCircleHandlers = {
  RedCircleHandlers: {
    handleMarkerDelete: (markerID: string) => void;
  };
};

const themeColor = "#577D9E";

/**
 * Helper Funcs
 */

const scaleXLinePath = (sideLength: number, scale: number) => {
  if (Math.abs(scale) > 1) {
    throw new Error("scale can only be between 0 and 1");
  }

  const padding = Math.floor((sideLength - sideLength * scale) / 2);
  const start = padding;
  const end = sideLength - padding;

  return `M ${start} ${start} L ${end} ${end} M ${start} ${end} L ${end} ${start}`;
};

class CustomPointMarker implements PointMarker {
  /**
   * PROPERTIES
   */
  private _options: CreatePointMarkerOptions;

  // Marker Tag
  private _markerTagContainer!: Konva.Group;

  // Marker Text
  private _textContainer!: Konva.Rect;
  private _text!: Konva.Text;

  // Marker  X Button
  private _buttonContainer!: Konva.Group;
  private _buttonBorder!: Konva.Rect;
  private _buttonSymbol!: Konva.Path;

  // Marker Line
  private _line!: Konva.Line;

  // Entire Marker (line + tag)
  private _markerGroup!: Konva.Group;

  // Complete Container Layer
  private _MarkerLayer!: Konva.Layer;
  private _waveFormlayer!: Konva.Layer;
  private _timeAxisLayer!: Konva.Layer;
  private _peaksInstance!: PeaksInstance & RedCircleHandlers;
  private _totalDuration!: number;
  private _stage!: Konva.Stage;

  static _initialExecution: Record<views, boolean> = {
    overview: false,
    zoomview: false,
  };

  constructor(options: CreatePointMarkerOptions) {
    this._options = options;

    /**
     * A bit Hacky , I found a way to get container layer (i.e. zoomview or overview layer canvas)
     * I can use this to get thier attributes and set their configuration using Konva Api.
     */
    const layer = options.layer as any;
    this._MarkerLayer = layer._layer as Konva.Layer;
    this._peaksInstance = layer._peaks;
    this._totalDuration = this._peaksInstance.player.getDuration() ?? 1;

    this._MarkerLayer.getNativeCanvasElement().setAttribute("id", "Marker Layer");

    const stage = this._MarkerLayer.getParent();
    const viewName = this._options.view as views;
    const timeAxisLayer =
      viewName === "overview"
        ? (stage?.getChildren()[4] as Konva.Layer)
        : (stage?.getChildren()[3] as Konva.Layer);
    const waveFormlayer = stage?.getChildren()?.[0] as Konva.Layer;

    waveFormlayer.getNativeCanvasElement().setAttribute("id", "Waveform Layer");
    timeAxisLayer.getNativeCanvasElement().setAttribute("id", "TimeAxis Layer");

    this._waveFormlayer = waveFormlayer;
    this._timeAxisLayer = timeAxisLayer;
  }

  init = (group: Konva.Group | any) => {
    // Adding Konva group to class prop;
    this._markerGroup = group;

    /** Creating Marker title Tag */

    this._text = new Text({
      text: " " + (this._options.point.labelText?.toUpperCase() ?? "") + " ",
      fontFamily: "Gilroy-bold",
      fontSize: 11,
      lineHeight: 1,
      letterSpacing: 1,
      padding: 2,
      verticalAlign: "middle",
      fill: themeColor,
      align: "left",
    });

    const XButtonWidth = this._text.height();

    this._text.x(XButtonWidth);

    this._textContainer = new Rect({
      x: XButtonWidth,
      y: 0,
      stroke: themeColor,
      strokeWidth: 1,
      height: this._text.height(),
      width: this._text.width(),
      cornerRadius: 1,
      fill: "#FFFFFF",
    });

    this._buttonContainer = new Group({
      x: this._text.width() + XButtonWidth,
      y: 0,
      width: XButtonWidth,
      height: this._textContainer.height(),
      visible: false,
    });

    this._buttonBorder = new Konva.Rect({
      id: "ButtonBorder",
      x: 0,
      y: 0,
      height: XButtonWidth,
      width: XButtonWidth,
      stroke: themeColor,
      fill: themeColor,
      strokeWidth: 1,
    });

    this._buttonSymbol = new Konva.Path({
      data: scaleXLinePath(XButtonWidth, 0.6),
      stroke: "white",
      strokeWidth: 1,
    });

    this._buttonContainer.add(this._buttonBorder);
    this._buttonContainer.add(this._buttonSymbol);

    this._markerTagContainer = new Group({
      id: "markerTagContainer",
      x: 0,
      y: 0,
      offsetX: Math.floor((this._textContainer.width() + this._buttonContainer.width() * 2) / 2),
      height: this._textContainer.height(),
      width: this._textContainer.width() + this._buttonContainer.width() * 2,
    });

    this._markerTagContainer.add(this._textContainer);
    this._markerTagContainer.add(this._text);
    this._markerTagContainer.add(this._buttonContainer);

    // Vertical Line - create with default y and points, the real values
    // are set in fitToView().
    this._line = new Line({
      x: 0,
      y: 0,
      stroke: this._options.color,
      strokeWidth: 3,
    });

    group.add(this._markerTagContainer);
    group.add(this._line);

    // Set marker group ID
    const labelText = this._options.point?.labelText;
    if (typeof labelText === "string") {
      group?.id(labelText);
    }

    this.fitToView();

    this._bindEventHandlers();
  };

  fitToView = () => {
    const height = this._options.layer.getHeight();

    const labelHeight = this._markerTagContainer.height();
    const offsetHeight = height - labelHeight;
    // Positioning marker line and marker tag container in the appropriate positions
    this._markerTagContainer.y(offsetHeight - 1);
    this._line.points([0, 0, 0, offsetHeight]);

    // Scaling the other Layers in order to make space for the new marker tags
    const scale = 1 - this._textContainer.height() / this._MarkerLayer.getHeight();

    this._timeAxisLayer?.getChildren().forEach((child) => {
      child.scaleY(scale);
    });
    this._waveFormlayer?.getChildren().forEach((child) => {
      child.scaleY(scale);
    });

    this._updateTagPositions();
  };

  timeUpdated = (time: number) => {
    this.fitToView();
  };

  destroy = () => {
    this._cleanEventHandlers();
  };

  /**
   * Helper
   */

  private _bindEventHandlers = () => {
    this._line.on("mouseenter", this._lineMouseEnter);
    this._line.on("mouseleave", this._lineMouseLeave);

    this._markerTagContainer.on("mouseenter", this._markerTagEnter);
    this._markerTagContainer.on("mouseleave", this._markerTagLeave);

    this._buttonContainer.on("mouseenter", this._buttonContainerEnter);
    this._buttonContainer.on("mouseleave", this._buttonContainerLeave);

    this._buttonContainer.on("click", this._buttonClick);
  };

  private _cleanEventHandlers = () => {
    this._line.off("mouseenter", this._lineMouseEnter);
    this._line.off("mouseleave", this._lineMouseLeave);

    this._markerTagContainer.off("mouseenter", this._markerTagEnter);
    this._markerTagContainer.off("mouseleave", this._markerTagLeave);

    this._buttonContainer.off("mouseenter", this._buttonContainerEnter);
    this._buttonContainer.off("mouseleave", this._buttonContainerLeave);

    this._buttonContainer.off("click", this._buttonClick);
  };

  private _updateTagPositions = () => {
    const fullLayerWidth = this._MarkerLayer.width();
    const currentPositionViaTime =
      (this._options?.point?.time / this._totalDuration) * fullLayerWidth;

    const currentPositionX =
      this._markerGroup.getAttrs()?.x || this._markerGroup?.x?.() || currentPositionViaTime;

    const markerContainerWidth = this._markerTagContainer.width();
    const halfTheMarkerTagWidth = markerContainerWidth / 2;
    const textWidth = this._textContainer.width();
    const buttonWidth = this._buttonContainer.width();

    if (currentPositionX < halfTheMarkerTagWidth) {
      // Move X button to right side of tag text
      this._buttonContainer.x() !== buttonWidth + textWidth &&
        this._buttonContainer.x(buttonWidth + textWidth);

      // Slide entire tag container to not clip text from view
      if (currentPositionX < textWidth / 2) {
        this._markerTagContainer.offsetX(currentPositionX + buttonWidth);
      }
    } else if (fullLayerWidth - currentPositionX < halfTheMarkerTagWidth) {
      // Move X button to left side of tag text
      this._buttonContainer.x() !== 0 && this._buttonContainer.x(0);

      if (fullLayerWidth - currentPositionX < textWidth / 2) {
        this._markerTagContainer.offsetX(
          halfTheMarkerTagWidth + (textWidth / 2 - (fullLayerWidth - currentPositionX))
        );
      }
    } else {
      // Return X button to default right side of Text
      this._buttonContainer.x() !== buttonWidth + textWidth &&
        this._buttonContainer.x(buttonWidth + textWidth);

      this._markerTagContainer.offsetX() !== halfTheMarkerTagWidth &&
        this._markerTagContainer.offsetX(halfTheMarkerTagWidth);
    }

    // Set Canvas order of markers
    const zIndex = this._options?.point?.zIndex;
    if (typeof zIndex === "number") {
      this._markerGroup.setZIndex(zIndex);
    }
  };

  /**
   * HANDLERS
   */

  private _lineMouseEnter = () => {
    document.body.style.cursor = "col-resize";
  };

  private _lineMouseLeave = () => {
    document.body.style.cursor = "default";
  };

  private _markerTagEnter = () => {
    this._buttonContainer?.show();
  };

  private _markerTagLeave = () => {
    this._buttonContainer?.hide();
  };

  private _buttonContainerEnter = () => {
    document.body.style.cursor = "pointer";
  };

  private _buttonContainerLeave = () => {
    document.body.style.cursor = "default";
  };

  private _buttonClick = (event: any) => {
    const handler = this._peaksInstance?.RedCircleHandlers?.handleMarkerDelete;
    const markerID = this._options.point._id as string;

    markerID && handler && handler(markerID);
  };
}

const CustomPointMarkerFunc = (options: CreatePointMarkerOptions) => {
  return new CustomPointMarker(options) as PointMarker;
};

export default CustomPointMarkerFunc;
