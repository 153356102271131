import { Col, Row } from "antd";
import React, { useContext } from "react";
import { classNames } from "react-extras";
import { AlbumArt } from "src/components/lib/album_art/album_art";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import Page from "src/components/lib/page";
import { isShowRedirected } from "src/lib/show";
import { IEpisode } from "redcircle-types";
import { IShow } from "redcircle-types";

interface IShowPageWrapperContext {
  show?: IShow;
  episode?: IEpisode;
  isLoading?: boolean;
}
type IShowPageWrapperProps = IShowPageWrapperContext & {
  children: React.ReactNode;
  className?: string;
};

const ShowContext = React.createContext<IShowPageWrapperContext>({});

// Extends Page Component
export default function ShowPageWrapper({
  show,
  episode,
  children,
  className,
  isLoading,
}: IShowPageWrapperProps) {
  return (
    <Page className={className} pageTitle={show?.title}>
      <ShowContext.Provider value={{ show, episode, isLoading }}>{children}</ShowContext.Provider>
    </Page>
  );
}

const ShowPageWrapperHeader = ({
  title,
  children,
  className,
}: {
  title?: string;
  children?: React.ReactNode;
  className?: string;
}) => {
  const { show, episode } = useContext(ShowContext);
  return (
    <>
      <Page.Header
        className={classNames("flex-row-container justify-space-between align-center", className)}>
        {title && (
          <Page.Title className="flex-row-container align-center">
            {title}{" "}
            {episode && !episode.isVisible && <ExclusiveTag className="m-lxs">DRAFT</ExclusiveTag>}
            {isShowRedirected(show) && <ExclusiveTag className="m-lxs">Redirected</ExclusiveTag>}
          </Page.Title>
        )}
        {children}
      </Page.Header>
      <Page.Divider />
    </>
  );
};
ShowPageWrapper.Header = ShowPageWrapperHeader;

const ShowPageWrapperSidebar = ({ children }: { children?: React.ReactNode }) => {
  const { show, episode } = useContext(ShowContext);
  if (!show) return null;
  const imageURL = episode?.imageURL || show.imageURL;
  return (
    <Col xs={12} md={6} className="m-bxs">
      <div className="flex-row-container">
        {imageURL && <AlbumArt src={imageURL} isRedirected={isShowRedirected(show)} />}
      </div>
      {children}
    </Col>
  );
};
ShowPageWrapper.Sidebar = ShowPageWrapperSidebar;

const ShowPageWrapperBody = ({ children }: { children: React.ReactNode }) => {
  const { show } = useContext(ShowContext);
  if (!show) return null;
  return (
    <Col xs={24} md={{ span: 17, offset: 1 }}>
      <Page.Section className={classNames(isShowRedirected(show) ? "semi-transparent" : "")}>
        {children}
      </Page.Section>
    </Col>
  );
};
ShowPageWrapper.Body = ShowPageWrapperBody;

const ShowPageWrapperFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <Row>
      <Col xs={24}>{children}</Col>
    </Row>
  );
};
ShowPageWrapper.Footer = ShowPageWrapperFooter;
