import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { saveAs } from "file-saver";
import { useState } from "react";
import { Button, Modal } from "redcircle-ui";
import { getCampaignZippedMediaFiles } from "src/api/campaigns";
import Loading from "src/components/lib/loading";
import CancelAudioSwap from "src/components/modals/campaign_audio_swap/cancel_audio_swap";
import SwapAudioModal from "src/components/modals/campaign_audio_swap/swap_audio_modal";
import ModifyCampaignItemModal from "src/components/modals/modify_campaign_item_modal";
import VettingFormModal from "src/components/modals/vetting_form_modal";
import {
  CampaignItemStateAudioSwapRequested,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateDraft,
  CampaignItemStateNeedsScript,
} from "src/constants/campaigns";
import { permissionTypes } from "src/constants/permission_roles";
import { useSelectorTS } from "src/hooks/redux-ts";
import { canAdvertiserAccess } from "src/lib/permissions";
import { BudgetsReduxState } from "src/reducers/budgets";
import { ICampaign, IShowMap, Script, ICampaignItem } from "redcircle-types";
import { PublicShowsReduxState } from "src/reducers/public_show";
import { DownloadInsertionInfoButton, downloadPodcastInfo } from "./campaign_csv";
import CampaignDashboardCart from "./campaign_dashboard_cart";
import CampaignDashboardGraph from "./campaign_dashboard_graph";
import CampaignDashboardTable from "./campaign_dashboard_table";
import CampaignMetaData from "./campaign_metadata";

export type AudioSwapModalTableState = {
  currentCampaignItemUUID: string;
  audioSwapModal: boolean;
  cancelAudioSwapModal: boolean;
};

interface IProps {
  campaign?: ICampaign;
  campaignStats?: any;
  campaignItemsByState?: any;
  getDistribution?: any;
  scriptsForCampaign?: Script[];
  isLoading: boolean;
  campaignItems: ICampaignItem[];
  shows: IShowMap | PublicShowsReduxState;
  budgets: BudgetsReduxState;
}

export default function CampaignPageDashboard({
  campaign,
  campaignStats,
  campaignItemsByState,
  getDistribution,
  scriptsForCampaign,
  isLoading,
  campaignItems,
  shows,
  budgets,
}: IProps) {
  const {
    draft,
    sent,
    accepted,
    running,
    paused,
    completed,
    expired,
    declined,
    [CampaignItemStateNeedsScript]: needsScript,
    [CampaignItemStateAwaitingAudio]: awaitingAudio,
    [CampaignItemStateAudioSwapRequested]: audioSwapRequested,
  } = campaignItemsByState;

  const acceptedItems = [
    ...accepted,
    ...needsScript,
    ...audioSwapRequested,
    ...awaitingAudio,
    ...running,
    ...paused,
    ...completed,
  ];
  const csvDownloadCategories = [
    ...accepted,
    ...needsScript,
    ...audioSwapRequested,
    ...awaitingAudio,
    ...running,
    ...paused,
    ...completed,
  ];

  const { user } = useSelectorTS((state) => state.user);

  const hasSentAnyItems = Object.keys(campaignItemsByState).some(
    (state) => state !== CampaignItemStateDraft && campaignItemsByState[state].length > 0
  );

  const [openVettingModal, setOpenVettingModal] = useState(false);
  const [openDownloadAudioModal, setOpenDownloadAudioModal] = useState(false);
  const [showModifyModalUUIDs, setModifyModalUUIDs] = useState<string[] | undefined>();

  const [audioSwapModalState, setAudioSwapModalState] = useState<AudioSwapModalTableState>({
    currentCampaignItemUUID: "",
    audioSwapModal: false,
    cancelAudioSwapModal: false,
  });

  const canShowCampaignReports = canAdvertiserAccess(
    permissionTypes.viewCampaignReporting,
    campaign
  );
  const campaignItemsHaveMediaFiles = campaignItems.some((item) => item.mediaFileUUID);

  const downloadDropdownItems: MenuProps["items"] = [];
  if (canShowCampaignReports && csvDownloadCategories.length) {
    const downloadCSV = [
      {
        key: "download_csv",
        label: (
          <Button
            type="link"
            className="p-a0"
            onClick={() =>
              downloadPodcastInfo({
                campaign,
                campaignItems: campaignItems as any,
                scriptsForCampaign,
              })
            }>
            Download Podcast Info (CSV)
          </Button>
        ),
      },
    ];
    downloadDropdownItems.push(...downloadCSV);
  }

  if (campaignItemsHaveMediaFiles) {
    const downloadAdReads = [
      {
        key: "download_ad_reads",
        label: (
          <Button
            type="link"
            className="p-a0"
            onClick={async (e) => {
              e.preventDefault();
              if (campaign?.uuid) {
                setOpenDownloadAudioModal(true);
                const res = await getCampaignZippedMediaFiles({
                  campaignUUID: campaign.uuid,
                  user,
                });
                const body = await res.blob();
                saveAs(body, `${campaign.name} Ad Reads.zip`);
                setOpenDownloadAudioModal(false);
              }
            }}>
            Download Ad Reads (ZIP)
          </Button>
        ),
      },
    ];
    downloadDropdownItems.push(...downloadAdReads);
  }

  return (
    <div>
      <CampaignMetaData
        campaign={campaign}
        campaignStats={campaignStats}
        allAcceptedItems={acceptedItems}
        renderDownloadLink={() => (
          <DownloadInsertionInfoButton className="m-lxxxs" campaign={campaign} />
        )}
        className="m-bm"
      />
      {!!campaign && campaignItems.length > 0 && !!shows && !!budgets && (
        <CampaignDashboardGraph
          campaign={campaign}
          campaignItems={campaignItems}
          shows={shows}
          budgets={budgets}
        />
      )}

      {!isLoading && (
        <CampaignDashboardCart
          campaign={campaign}
          className="m-bm"
          hasSentAnyItems={hasSentAnyItems}
          getDistribution={getDistribution}
          setOpenVettingModal={setOpenVettingModal}
        />
      )}
      {isLoading && <Loading />}
      {!isLoading && hasSentAnyItems && (
        <CampaignDashboardTable
          campaignItemsByState={campaignItemsByState}
          campaign={campaign}
          campaignStats={campaignStats}
          audioSwapModalState={audioSwapModalState}
          setAudioSwapModalState={setAudioSwapModalState}
          onModifyLineItems={setModifyModalUUIDs}
          tabBarExtraContent={
            downloadDropdownItems.length > 0 && (
              <Dropdown menu={{ items: downloadDropdownItems }}>
                <Button type="link">Download</Button>
              </Dropdown>
            )
          }
        />
      )}

      <SwapAudioModal
        visible={audioSwapModalState.audioSwapModal}
        setVisible={(newBool) =>
          setAudioSwapModalState((prev) => ({ ...prev, audioSwapModal: newBool }))
        }
        campaignItemUUID={audioSwapModalState.currentCampaignItemUUID}
      />

      <CancelAudioSwap
        visible={audioSwapModalState.cancelAudioSwapModal}
        setVisible={(newBool) =>
          setAudioSwapModalState((prev) => ({ ...prev, cancelAudioSwapModal: newBool }))
        }
        campaignItemUUID={audioSwapModalState.currentCampaignItemUUID}
      />

      <VettingFormModal
        campaign={campaign}
        draftItems={draft}
        open={openVettingModal}
        setOpen={setOpenVettingModal}
      />

      <ModifyCampaignItemModal
        open={!!showModifyModalUUIDs}
        onClose={() => setModifyModalUUIDs(undefined)}
        campaign={campaign}
        campaignItemUUIDs={showModifyModalUUIDs}
        type="modify"
      />

      <Modal
        size="xs"
        open={openDownloadAudioModal}
        onClose={() => setOpenDownloadAudioModal(false)}
        className="p-bs">
        <Modal.Title>Downloading ad reads...</Modal.Title>
        <Modal.Body>
          <Loading className="m-bs" />
        </Modal.Body>
      </Modal>
    </div>
  );
}
