import dayjs from "dayjs";
import { formatMoney } from "redcircle-lib";
import { ICampaign } from "redcircle-types";
import { ICampaignItem } from "src/reducers/campaign_items";
import ProductInstructionsInfo from "../product_instructions_info";

export default function NextStepsPage({
  campaign,
  campaignItem,
}: {
  campaign: ICampaign;
  campaignItem: ICampaignItem;
}) {
  const campaignItemHasScript =
    typeof campaignItem?.scriptUUID === "string" && campaignItem?.scriptUUID?.length > 0;

  const totalAmountInDollars =
    typeof campaignItem?.totalCreatorAmount === "number"
      ? campaignItem.totalCreatorAmount / 100 // Dividing since value is in cents
      : 0; // Should not get here but setting to zero to fail gracefully

  return (
    <>
      <p>
        This offer has been accepted for <strong>{formatMoney(totalAmountInDollars)}</strong>
      </p>

      {!campaignItemHasScript && (
        <p>The brand will upload the script at least 5 days before your ad audio is due.</p>
      )}

      <p>
        To view the script and upload your audio, return to your RAP Dashboard and click{" "}
        <strong>"View & Upload."</strong>
      </p>

      {campaignItem.uploadAudioBy && (
        <p>
          <strong>
            Your ad audio upload is due by{" "}
            {dayjs.unix(campaignItem.uploadAudioBy).local().format("M/D/YY h:mm A z")}.
          </strong>
        </p>
      )}

      <ProductInstructionsInfo campaign={campaign} campaignItem={campaignItem} forceOn />
    </>
  );
}
