const messageTypes = {
  Success: "SUCCESS",
  Error: "ERROR",
  Warning: "WARNING",
  Info: "INFO",
} as const;

export type MessageTypes = (typeof messageTypes)[keyof typeof messageTypes];

export default messageTypes;
