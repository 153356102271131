import React from "react";
import { Icon } from "src/components/lib/icon";
import styles from "./custom_audio.module.scss";

interface IProps {
  audioRef: HTMLAudioElement | null;
  withoutBorder?: boolean;
}

export default function PlayPause({ audioRef, withoutBorder }: IProps) {
  let iconName = "play";
  if (audioRef) {
    iconName = audioRef.paused ? "play" : "pause";
  }

  const styleName = withoutBorder ? styles.playPauseMini : styles.playPause;

  return (
    <div className={styleName}>
      <Icon iconName={iconName} />
    </div>
  );
}
