import { Checkbox } from "antd";
import React from "react";
import { classNames } from "react-extras";
import "./TriCheckbox.scss";

export type TTriCheckboxState = "checked" | "unchecked" | "indeterminate";

interface IProps {
  className?: string;
  state: TTriCheckboxState;
  onChange: (e: any) => void;
  disabled?: boolean;
}

export function TriCheckbox({ className, state, onChange, disabled }: IProps) {
  return (
    <Checkbox
      className={classNames("tri-checkbox", className)}
      checked={state === "checked"}
      /* tslint:disable-next-line */
      indeterminate={state === "indeterminate"}
      onChange={onChange}
      disabled={disabled}
    />
  );
}
