import get from "lodash/get";
import head from "lodash/head";
import moment from "moment";
// this is 5/1/2020. prior to this date, we time out import jobs after 2 days and just hide it.
// this has caused some people thinking that their import is finished when it's not.
// since we used to rely on the timeout, there are many dangling import jobs that we fixed either by hand
// or otherwise. we drop everything before the date to avoid confusion
const cutoffOfUnfinishedImportJobTimeout = 1588352724;

export const importJobIsUnfinished = (importJob, show) => {
  const startedAt = get(importJob, "startedAt", 0);
  return (
    importJob.state === "started" &&
    getNumEpisodesImported(importJob) < getTotalJobs(importJob) &&
    !show.redirectVerified &&
    startedAt > cutoffOfUnfinishedImportJobTimeout
  );
};

export const getImportJobDetail = (importJob, show = {}) => {
  let result = {
    totalJobs: 0,
    numEpisodesImported: 0,
    shouldShowProgressBar: false,
    hasJobStarted: false,
    isTimedOut: false,
    isUnfinished: false,
  };
  if (!importJob) {
    return result;
  }

  const startedAt = get(importJob, "startedAt", 0);

  // It takes a couple seconds for the import job to actually start processing on the queue, so to let the user
  // know that the import is processing we check if the job as started and set the loading bar to 0% until
  // the total episode count is processed.
  if (
    getTotalJobs(importJob) === 0 &&
    moment.unix(startedAt).isSameOrAfter(moment().subtract(10, "seconds"))
  ) {
    result.shouldShowProgressBar = true;
    result.totalJobs = 1;
    return result;
  }

  const isUnfinished = importJobIsUnfinished(importJob, show);

  const isTimedOut = moment.unix(startedAt).isBefore(moment().subtract(2, "days"));

  result.totalJobs = getTotalJobs(importJob);
  result.numEpisodesImported = getNumEpisodesImported(importJob);
  result.shouldShowProgressBar = isUnfinished;
  result.hasJobStarted = true;
  result.isTimedOut = isTimedOut;
  result.isUnfinished = isUnfinished;
  return result;
};

// total number jobs we expect to run before the show import is complete.
const getTotalJobs = (importJob) => {
  return get(importJob, "totalEpisodes", 0) + get(importJob, "expectedMediaFilesImported", 0);
};

const getNumEpisodesImported = (importJob) => {
  return get(importJob, "episodesImported", 0) + get(importJob, "mediaFilesImported", 0);
};

// gets a show specific import job based on show UUID
export const getImportJob = (importJobs, show) => {
  if (!importJobs || !show) {
    return undefined;
  }
  return head(importJobs.filter(({ showUUID }) => showUUID === get(show, "uuid")));
};
