import get from "lodash/get";
import React, { Component } from "react";
import DropZone from "react-dropzone";
import loadingSpinner from "../../../icons/loading-spinner-white.svg";
import uploadIcon from "../../../icons/upload-icon-white.svg";
import { formatDuration } from "../../../lib/duration";
import "./file_upload.scss";

class FileUploadV2 extends Component {
  onDrop = (files) => {
    this.props.onChange(files);
  };

  fileIndicator = () => {
    var { mediaFiles, mediaFileUUID } = this.props;
    const duration = "Duration: " + formatDuration(get(mediaFiles, [mediaFileUUID, "duration"]));
    const fileName = get(this.props, "value[0].name");
    const existingFileName = get(mediaFiles, [mediaFileUUID, "fileName"], "");
    const durationIsSet = get(mediaFiles, [mediaFileUUID, "durationIsSet"], false);

    var fileString = fileName;
    if (!fileName && existingFileName && duration) {
      if (durationIsSet) {
        fileString = existingFileName + " - " + duration;
      } else {
        fileString = existingFileName;
      }
    }
    if (fileName || get(mediaFiles, mediaFileUUID)) {
      return <p className={"episode-form__file-indicator"}>{fileString}</p>;
    }
  };

  buttonText = () => {
    if (this.props.isLoading) {
      return (
        <div className="audio-drop-zone-text">
          <img src={loadingSpinner} alt="loading" className="loading-button__spinner" />
        </div>
      );
    }

    if (this.props.mediaFileUUID) {
      return <p className={"audio-drop-zone-text"}>Switch to another file</p>;
    } else {
      return (
        <p className={"audio-drop-zone-text"}>
          <span>
            <img src={uploadIcon} className={"media-file-upload-button__icon"} alt="upload" />
            Choose a file to upload
          </span>
        </p>
      );
    }
  };

  render() {
    const { showMaxFileSize = true, acceptedFileExtensions = ".mp3,.mpeg,.m4a" } = this.props;
    return (
      <>
        <DropZone onDrop={this.onDrop} accept={acceptedFileExtensions}>
          {({ getRootProps, getInputProps }) => {
            return (
              <div {...getRootProps()} className={"audio-drop-zone"} data-cy="file-drag-and-drop">
                <div className={"audio-drop-zone-content"}>
                  <input {...getInputProps()} />
                  {this.buttonText()}
                  {this.fileIndicator()}
                </div>
              </div>
            );
          }}
        </DropZone>
        {showMaxFileSize && (
          <div className="audio-drop-zone__help-text">
            Max Size: {this.props?.max || "200"} Megabytes
          </div>
        )}
      </>
    );
  }
}

export default FileUploadV2;
