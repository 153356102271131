import breaker from "../images/breaker.png";
import downcastIcon from "../images/downcast.png";
import googleIcon from "../images/google-podcasts.png";
import overcastIcon from "../images/overcast.png";
import playerFMIcon from "../images/player-fm.png";
import pocketcastsIcon from "../images/pocketcasts.png";
import podcastIcon from "../images/podcast-generic.png";

export const APPLE_PODCASTS = "Apple Podcasts";
export const ITUNES = "iTunes";
export const OVERCAST = "Overcast";
export const POCKET_CASTS = "Pocket Casts";
export const BREAKER = "Breaker";
export const DOWNCAST = " Downcast";
export const PLAYERFM = "Player FM";
export const GOOGLE_PODCASTS = "Google Podcasts";

export const iOSPodcastPlayers = [
  {
    name: APPLE_PODCASTS,
    uri: "podcast://",
    stripProtocol: true,
    icon: podcastIcon,
  },
  {
    name: OVERCAST,
    uri: "overcast://x-callback-url/add?url=",
    escape: true,
    icon: overcastIcon,
  },
  {
    name: GOOGLE_PODCASTS,
    uri: "https://podcasts.google.com/subscribe-by-rss-feed?feed=",
    stripProtocol: false,
    b64encode: true,
    icon: googleIcon,
  },
  {
    name: BREAKER,
    uri: "breaker://subscribe/feed/",
    stripProtocol: false,
    icon: breaker,
  },
  {
    name: POCKET_CASTS,
    uri: "pktc://subscribe/",
    stripProtocol: true,
    icon: pocketcastsIcon,
  },
  {
    name: PLAYERFM,
    uri: "https://player.fm/subscribe?id=",
    escape: true,
    icon: playerFMIcon,
  },
  {
    name: DOWNCAST,
    uri: "downcast://",
    stripProtocol: true,
    icon: downcastIcon,
  },
];

// On android the intent will popup with players you hae installed
export const AndroidPodcastPlayers = [
  {
    name: "Choose Player",
    uri: "pcast://",
    icon: podcastIcon,
    stripProtocol: true,
  },
  {
    name: GOOGLE_PODCASTS,
    uri: "https://podcasts.google.com/subscribe-by-rss-feed?feed=",
    stripProtocol: false,
    b64encode: true,
    icon: googleIcon,
  },
];

export const OSXPodcastPlayers = [
  {
    name: ITUNES,
    uri: "pcast://",
    icon: podcastIcon,
    stripProtocol: true,
  },
  {
    name: GOOGLE_PODCASTS,
    uri: "https://podcasts.google.com/subscribe-by-rss-feed?feed=",
    stripProtocol: false,
    b64encode: true,
    icon: googleIcon,
  },
];

export const CatalinaPlayers = [
  {
    name: APPLE_PODCASTS,
    uri: "podcast://",
    icon: podcastIcon,
    stripProtocol: true,
  },
  {
    name: GOOGLE_PODCASTS,
    uri: "https://podcasts.google.com/subscribe-by-rss-feed?feed=",
    stripProtocol: false,
    b64encode: true,
    icon: googleIcon,
  },
];
