import { Modal } from "redcircle-ui";
import { AudioBlock } from "src/reducers/audio_blocks";
import BulkAssignForm from "./bulk_assign_form";

export const UnsavedChangesModal = ({
  visible,
  onClose,
  onSubmit,
}: {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  return (
    <Modal
      open={visible}
      size="xs"
      onClose={onClose}
      onSubmit={() => {
        onSubmit();
        onClose();
      }}>
      <Modal.Title>Unsaved Changes</Modal.Title>
      <Modal.Body>
        You have made changes to this audio block. If you close without saving, these changes will
        be discarded.
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton>Continue Editing</Modal.CloseButton>
        <Modal.SubmitButton>Discard Changes</Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export const DeletePromptModal = ({
  visible,
  onClose,
  onSubmit,
}: {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  return (
    <Modal
      open={visible}
      size="xs"
      onClose={onClose}
      onSubmit={() => {
        onSubmit();
        onClose();
      }}>
      <Modal.Title>Delete Audio Block</Modal.Title>
      <Modal.Body>
        This will permanently delete this audio block.
        {/* TODO: add episode data */}
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Modal.SubmitButton>Delete Audio Block</Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export const BulkAssignBlockModal = ({
  visible,
  onClose,
  onSubmit,
  audioBlock,
}: {
  visible: boolean;
  onClose: () => void;
  onSubmit: any;
  audioBlock?: AudioBlock | false;
}) => {
  const handleSubmit = async (markersToUpdate: Record<string, boolean>) => {
    if (audioBlock) {
      return onSubmit(markersToUpdate, audioBlock.uuid).then((res: any) => {
        if (res.status === 200) onClose();
      });
    }
  };

  return (
    <Modal open={visible} size="md" onClose={onClose}>
      <Modal.Title>Assign Insertion Points for {audioBlock && audioBlock.name}</Modal.Title>
      {audioBlock && (
        <BulkAssignForm
          key={visible ? audioBlock.uuid : "1"}
          audioBlock={audioBlock}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};
