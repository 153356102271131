import React from "react";
import { Link } from "react-router-dom";
import WarningPanelv2 from "src/components/lib/warning_panel/warning_panel_v2";

export const AudioSwapWarning = ({ audioSwapRequested }: { audioSwapRequested: any }) => (
  <WarningPanelv2 className="m-bxs">
    <h3 className="m-bxxs">Campaign Paused on {audioSwapRequested[0]?.campaignName}</h3>
    <p className="subtitle uppercase m-b0 lh-xs">Replace ad audio now</p>
    <p className="text m-b0 lh-s">
      The brand found an issue with your ad audio and paused this campaign until your audio is
      replaced.
    </p>

    <Link
      className="bold"
      to={`/ad-platform/${audioSwapRequested[0]?.showUUID}/campaigns/${audioSwapRequested[0].campaignUUID}`}>
      {"View & Replace Audio >"}
    </Link>
  </WarningPanelv2>
);
