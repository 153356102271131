import { Alert, Card, Col, Radio, RadioGroupProps, Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, COLORS, MultiModal, Tag } from "redcircle-ui";
import { change, Form, formValueSelector, reduxForm, submit } from "redux-form";
import { showSuccess, showWarning } from "src/actions/app";
import {
  submitEpisodesFeedImport,
  submitShowFeedImport,
  validateFeedURLForRemote,
} from "src/actions/feed_import";
import { USER_IMPORT_JOB } from "src/actions/user";
import DefaultActionManager from "src/action_managers/default_action_manager";
import { distribute } from "src/action_managers/distributions";
import { getShowForUser } from "src/action_managers/shows";
import { distributionKeyNames } from "src/constants/distribution";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { seedcastForm } from "src/lib/forms";
import { ACTIONS, CATEGORIES, gtagEvent } from "src/lib/google_analytics";
import { IShow } from "redcircle-types";
import { User } from "redcircle-types";
import { showMessages } from "../../constants/notificationMessages";
import { tierLevels } from "../../constants/tiers";
import { getActiveHostedPodcasts } from "../../lib/show";
import ExternalLink from "../lib/external_link/external_link";
import ImportForm, { IMPORT_FORM_NAME } from "../pages/shows/import_form";
import "./modal.scss";

export const importActionTypes = {
  Migrate: "Migrate",
  Remote: "Remote",
} as const;

const BIT_RATE = 128;

export type ImportType = keyof typeof importActionTypes | undefined;

const isRemoteHostValid = (remoteHost: string | false) => {
  const validHosts = ["megaphone", "art19", "omny", "simplecast"];

  if (typeof remoteHost === "string") {
    return validHosts.some((host) => remoteHost.toLowerCase().includes(host));
  }

  return false;
};

interface IImportModal {
  handleSubmit: any;
  validationErrors: any;
}

const ImportModal = ({ handleSubmit, validationErrors }: IImportModal) => {
  /** Local State */
  const [confirmed, setConfirmed] = useState(); // user confirms the podcast is theirs
  const [page, setPage] = useState<number>(0);
  const [open, setOpen] = useState(true);
  const [isValidHost, setIsValidHost] = useState(false); // captured flag for valid remote hosts

  const dispatch = useDispatchTS();
  const location = useLocation();
  const history = useHistory();

  const userState = useSelectorTS((state) => state.user);
  const isLoading = useSelectorTS((state) => {
    return state.showFeedImport.isLoading || state.episodeFeedImport.isLoading;
  });
  const rssFeedURL = useSelectorTS(
    (state) => formValueSelector(IMPORT_FORM_NAME)(state, "feedURL") as string
  );
  const importType = useSelectorTS(
    (state) => formValueSelector(IMPORT_FORM_NAME)(state, "importType") as ImportType
  );

  const isUserPromoted = Boolean(userState?.user?.openRAPWaitlistInfo?.promoted);
  const importBtnText =
    importType === importActionTypes.Remote && !isUserPromoted ? "Join The Waitlist" : "Import";

  // Ensure on mount page is set to 0;
  useEffect(() => {
    if (page !== 0) setPage(0);
  }, []);

  useEffect(() => {
    const { isLoading, user, tier } = userState;
    if (!isLoading && user && tier.level === tierLevels.core) {
      dispatch(getShowForUser(user)).then((resp) => {
        if (resp?.status === 200) {
          const activeShows = getActiveHostedPodcasts(resp.json);
          if (activeShows?.length >= 1) {
            dispatch(showWarning(showMessages.Warning.TierARedirectHack, 3000));
            handleClose();
          }
        }
      });
    }
  }, []);

  /**
   * Helpers
   */

  const isSubmitButtonDisabled = () => {
    switch (page) {
      case 0:
        return !confirmed;
      case 1:
        if (importType === importActionTypes.Remote) {
          return !isValidHost;
        }
        return typeof importType !== "string";
      default:
        return false;
    }
  };

  const isDisabled = isSubmitButtonDisabled();

  const feedImport: (
    url: string,
    bitrate: number
  ) => Promise<
    | {
        showUUID: string;
        status: 200;
        json: IShow;
      }
    | Record<string, never>
  > = async (url, bitrate) => {
    try {
      const isRemote = importType === importActionTypes.Remote;
      const isWaitlisted = isRemote && !isUserPromoted;
      const response = await dispatch(submitShowFeedImport({ feedURL: url, isRemote }));

      if (response.status === 200) {
        if (isWaitlisted) {
          await dispatch(
            showSuccess(
              "Great! We've got you on the waitlist for OpenRAP, we'll be sure to reach out as soon as we're ready to onboard your podcast."
            )
          );
        }

        const showUUID = response?.json?.uuid;
        if (!isWaitlisted) {
          await dispatch(submitEpisodesFeedImport(showUUID, url, bitrate));
          await dispatch(
            distribute(showUUID, [
              distributionKeyNames.googlePodcasts,
              distributionKeyNames.radioPublic,
            ])
          );
        }

        return { ...response, showUUID };
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };

  const validateFeedURL = async (url: string) => {
    const encoded = encodeURIComponent(url);
    const res = await dispatch(validateFeedURLForRemote({ feedURL: encoded }));
    if (res.status === 200) {
      if (res.json.remoteHost === null || !res.json.remoteHost) return false;
      return res.json.remoteHost as string;
    }
    return false;
  };

  const fetchJobsForUser = (user: User) => {
    dispatch(
      new DefaultActionManager({
        auth: true,
        route: `users/${user.uuid}/import-jobs`,
        actionName: USER_IMPORT_JOB,
      }).run()
    );
  };

  const onSubmit = (values: { feedURL: string }) => {
    gtagEvent(ACTIONS.SUBMIT, CATEGORIES.IMPORT_FORM);
    feedImport(values.feedURL, BIT_RATE).then((responseData) => {
      if ("status" in responseData && responseData?.status === 200) {
        const isRemote = importType === importActionTypes.Remote;
        const isWaitlisted = isRemote && !isUserPromoted;

        handleClose();

        if (isWaitlisted) {
          history.go(0);
        } else {
          history.push(`/shows/${responseData.showUUID}${appendTestQuery()}`, {
            newShowUUID: responseData.showUUID,
          });
          const { user } = userState;
          fetchJobsForUser(user);
        }
      }
    });
  };

  const appendTestQuery = () => String(location?.search);

  /**
   * handlers
   */
  const handleClose = () => {
    setOpen(false);
    setPage(0);
    history.push(`/shows${location?.search}`);
  };

  const handleBack = () => {
    switch (page) {
      case 0:
        handleClose();
        break;
      case 1:
        setPage(0);
        break;
    }
  };

  const handleImportClick = async () => {
    let remoteHost: string | false;
    switch (page) {
      case 0:
        remoteHost = await validateFeedURL(rssFeedURL);
        setIsValidHost(isRemoteHostValid(remoteHost));
        setPage(1);
        break;
      case 1:
        dispatch(submit(IMPORT_FORM_NAME));
        break;
    }
  };

  const handleChangeImportType: RadioGroupProps["onChange"] = (e) => {
    dispatch(change(IMPORT_FORM_NAME, "importType", e.target.value));
  };

  const pages = [
    {
      title: "Podcast Import",
      body: (
        <Row gutter={24}>
          <Col md={24} xs={24}>
            <p>
              Importing your podcast into RedCircle creates a copy of your episodes on RedCircle's
              Platform. Nothing will change for your listeners and your existing hosting provider
              will work as it does today.
            </p>
          </Col>
          <Col md={24} xs={24}>
            <ImportForm
              onUpdateCertification={(e: any) => {
                setConfirmed(e.target.checked);
              }}
              close={handleClose}
              validationErrors={validationErrors}
            />
          </Col>
        </Row>
      ),
      footer: (
        <div className="width-100">
          <Row gutter={24}>
            <Col md={24} xs={24} className="flex-row-container">
              <Button type="link" size="large" onClick={handleBack}>
                Back
              </Button>
              <div className="flex-row-container align-center m-la">
                <Button
                  type="primary"
                  size="large"
                  loading={isLoading}
                  disabled={isDisabled}
                  className="m-lm"
                  onClick={handleImportClick}>
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
    {
      title: "Podcast Import",
      body: (
        <Row gutter={24}>
          <Col md={24} xs={24}>
            <p>
              Are you transferring your podcast to RedCircle's hosting, or do you want to enable
              RedCircle's ads on your current platform?
            </p>
          </Col>
          <Col md={24} xs={24}>
            <Card className="m-bxs">
              <Radio
                checked={importType === importActionTypes.Migrate}
                value={importActionTypes.Migrate}
                onChange={handleChangeImportType}>
                <p className="m-b0">I plan to migrate my podcast to RedCircle</p>
              </Radio>
            </Card>

            <Card>
              <div className="flex-column-container align-start">
                <Radio
                  checked={importType === importActionTypes.Remote}
                  value={importActionTypes.Remote}
                  disabled={!isValidHost}
                  onChange={handleChangeImportType}>
                  <div className="flex-row-container align-center">
                    <p className="m-b0">
                      I want RedCircle's ads, but I want to stay on my existing hosting platform{" "}
                      <Tag className="m-lxxs" color={COLORS.PRIMARY_COLOR}>
                        Beta
                      </Tag>
                    </p>
                  </div>
                </Radio>
                {!isValidHost && (
                  <Alert
                    message={
                      <span>
                        Your current podcast host doesn't connect to RedCircle over VAST. Consider{" "}
                        <ExternalLink
                          href={"https://support.redcircle.com/redcircle-migration-guide"}>
                          switching to RedCircle's hosting.
                        </ExternalLink>
                      </span>
                    }
                    className="m-txs"
                    type="warning"
                    showIcon
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      ),
      footer: (
        <div className="width-100">
          <Row gutter={24}>
            <Col md={24} xs={24} className="flex-row-container">
              <Button type="link" size="large" onClick={handleBack}>
                Back
              </Button>
              <div className="flex-row-container align-center m-la">
                <Button
                  type="primary"
                  size="large"
                  loading={isLoading}
                  disabled={isDisabled}
                  className="m-lm"
                  onClick={handleImportClick}>
                  {importBtnText}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <MultiModal onClose={handleClose} open={open} pages={pages} currentPage={page} size="md" />
    </Form>
  );
};

const ImportModalWrapper = seedcastForm(
  reduxForm({
    form: IMPORT_FORM_NAME,
  })(ImportModal as any),
  {
    submissionFilter: (values: any) => values,
  }
);

export default ImportModalWrapper;
