import { TierState } from "./user";
export type Tier = {
  uuid: string;
  orgUUID: string;
  level: TierLevel;
  interval: "monthly" | "annual";
  amount: number; // In cents
  stripeSubscriptionID: string;
  createdAt: number;
  updatedAt: number;
  state: TierState;
};

export const tierLevel = {
  og: "og",
  core: "core",
  growth: "growth",
  pro: "pro",
  enterprise: "enterprise",
} as const;

export const TIER_LEVEL_ANNUAL = "annual";
export const TIER_LEVEL_MONTHLY = "monthly";

export type TierLevel = keyof typeof tierLevel;
