import React from "react";
import { Link } from "react-router-dom";

export interface IBackButton {
  to: string;
  text: string;
}

function BackButton(props: IBackButton) {
  return (
    <div data-testid="test-backbutton" className="show-details-page__back-link seedcast-back-link">
      <Link to={props.to} className="redcircle-big-link">
        <span className="glyphicon glyphicon-chevron-left back-link-chevron" />{" "}
        {props.text || "Back"}
      </Link>
    </div>
  );
}

export default BackButton;
