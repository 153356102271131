import React, { useEffect } from "react";

const RedirectExternal = ({ newPath }: { newPath: string }) => {
  useEffect(() => {
    window.location.href = newPath;
  }, []);

  return <></>;
};

export default RedirectExternal;
