import debounce from "lodash/debounce";
import filter from "lodash/filter";
import { Component } from "react";
import { Button, Col, FormControl, Row } from "react-bootstrap";
import { If } from "react-extras";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import {
  clearShows,
  searchDirectoryShows,
  searchShows,
} from "../../../action_managers/show_search";
import { searchNames } from "../../../constants/bucketed_audience_size";
import { itunesCategories } from "../../../data/itunes_categories";
import missingAlbumArt from "../../../images/missing.png";
import { getAllAdjacentAudienceBucketSizes, getUserShows } from "../../../lib/show";
import Divider from "../../lib/divider";
import Page from "../../lib/page/page";
import Spinner from "../../lib/spinner";
import "./browse_promotion_page.scss";

class BrowsePromotionSearch extends Component {
  state = {
    searchValue: "",
    showExtraFilters: false,
    bucketedAudienceSize: undefined,
    categoryUUID: undefined,
    debouncing: false,
  };
  componentDidMount() {
    this.props.clearShows();
  }

  updateSearchText = (e) => {
    this.setState({ searchValue: e.target.value });
    e.target.value ? this.searchShows() : this.props.clearShows();
  };

  debouncedSearch = debounce(() => {
    this.setState({ debouncing: false });
    let searchTerms = {
      ...(this.state.searchValue && { query: this.state.searchValue }),
      ...(this.state.categoryUUID && { categoryUUIDs: [this.state.categoryUUID] }),
      userUUID: this.props.user.uuid,
      showSearchType: "crossPromos",
    };

    // Two cases.
    // 1) All is selected, only search shows of similar size. Defined as shows of same size and one category up/below.
    // 2) Specific size is selected then search `bucketedAudienceSize` to specify single size
    if (!this.state.bucketedAudienceSize) {
      this.props.searchDirectoryShows({
        ...(this.state.searchValue && { query: this.state.searchValue }),
        ...(this.state.categoryUUID && { categoryUUIDs: [this.state.categoryUUID] }),
      });
      searchTerms.bucketedAudienceSizes = this.props.bucketedAudienceSizes;
    } else {
      searchTerms.bucketedAudienceSize = this.state.bucketedAudienceSize;
    }

    this.props.searchShows(searchTerms);
  }, 500);

  searchShows = () => {
    this.setState({ debouncing: true });
    this.debouncedSearch();
  };

  renderSearchResult = (show) => {
    const linkTo = show.isDirectoryShow
      ? `/promotions/directory-shows/${show.uuid}`
      : `/promotions/shows/${show.uuid}`;
    return (
      <Link to={linkTo} className="no-style-link" key={show.uuid}>
        <div className={"browse-promotion-search__search-result"}>
          <div
            className="browse-promotion-search__search-picture"
            style={{
              backgroundImage: "url(" + (show.imageURL || missingAlbumArt) + ")",
            }}
          />
          <span className="browse-promotion-search__title">{show.title}</span>
          <span className="browse-promotion-search__spacer" />
          <span className="browse-promotions-search__arrow glyphicon glyphicon-chevron-right" />
        </div>
      </Link>
    );
  };

  handleDetailSelect = (filterKey) => (e) => {
    const value = e.target.value === "all" ? undefined : e.target.value;
    this.setState({ [filterKey]: value });
    this.searchShows();
  };

  detailedSearch = () => {
    if (!this.state.showExtraFilters) {
      return null;
    }
    const audienceSizes = filter(searchNames, (o) => {
      return this.props.bucketedAudienceSizes.includes(o.value);
    });

    return (
      <Row className="detailed-search-row">
        <Col md={6}>
          <FormControl
            componentClass="select"
            className="form-control--small"
            placeholder="Weekly Downloads"
            value={this.state.bucketedAudienceSize}
            onChange={this.handleDetailSelect("bucketedAudienceSize")}>
            {[{ name: "All Valid Weekly Downloads", value: "" }, ...audienceSizes].map(
              ({ name, value }) => {
                return (
                  <option key={name} value={value}>
                    {name}
                  </option>
                );
              }
            )}
          </FormControl>
        </Col>
        <Col md={6}>
          <FormControl
            componentClass="select"
            placeholder="Category"
            className="form-control--small"
            onChange={this.handleDetailSelect("categoryUUID")}>
            {[{ name: "All Categories", uuid: "all" }, ...itunesCategories].map(
              ({ name, uuid }) => {
                return (
                  <option key={name} value={uuid}>
                    {name}
                  </option>
                );
              }
            )}
          </FormControl>
        </Col>
      </Row>
    );
  };
  getResults = () => {
    const results = this.props.search.concat(this.props.directoryShowsSearchResults);
    const nonRemoteShows = results.filter((show) => !show.isRemote);
    return nonRemoteShows;
  };
  render() {
    return (
      <CSSTransition in={this.props.show} classNames={"browse-search"} timeout={500} unmountOnExit>
        <div className="browse-promotion-search__wrapper">
          <Page>
            <Row>
              <Col xs={10}>
                <h1 className="browse-promotion-search__title">Search Partners</h1>
              </Col>
              <Col xs={2} className="browse-promotion-search__close-wrapper">
                <span className="browse-promotion-search__close bold" onClick={this.props.onClose}>
                  Close
                </span>
              </Col>
              <Col xs={12}>
                <Divider smallMargin />
              </Col>
            </Row>
            <Row>
              <Col xs={11}>
                <FormControl
                  type="text"
                  onChange={this.updateSearchText}
                  value={this.state.searchValue}
                  className="browse-promotion-search__search-box"
                  placeholder="Search"
                />
              </Col>
              <Col xs={1}>
                <Button
                  bsStyle="default"
                  className="browse-promotion-search__filter-button"
                  active={this.state.showExtraFilters}
                  onClick={() => {
                    this.setState({ showExtraFilters: !this.state.showExtraFilters });
                  }}
                />
              </Col>
            </Row>
            {this.detailedSearch()}
            <If condition={!!this.getResults().length && !this.props.isLoading}>
              {this.getResults().map(this.renderSearchResult)}
            </If>
            <Spinner isLoading={this.props.isLoading} />
            <If
              condition={
                !this.props.search.length &&
                !!this.state.searchValue &&
                !this.props.isLoading &&
                !this.state.debouncing
              }>
              <div className="text-center browse-promotion-search__no-results">
                We couldn
                {"'"}t find any podcasts that match your search.
              </div>
            </If>
            <If
              condition={
                !this.props.search.length &&
                !this.state.searchValue &&
                !this.props.isLoading &&
                !this.state.debouncing
              }>
              <div className="text-center browse-promotion-search__no-results">
                Start your search for a partner podcast.
              </div>
            </If>
          </Page>
        </div>
      </CSSTransition>
    );
  }
}

export default connect(
  (state) => {
    const userShows = getUserShows(state.user.user, state.shows.shows);
    const bucketedAudienceSizes = getAllAdjacentAudienceBucketSizes(userShows);
    return {
      search: state.browsePromotion.search.results,
      directoryShowsSearchResults: state.browsePromotion.searchDirectoryShows.results,
      isLoading: state.browsePromotion.search.isLoading,
      user: state.user.user,
      bucketedAudienceSizes: bucketedAudienceSizes,
    };
  },
  {
    searchShows,
    searchDirectoryShows,
    clearShows,
  }
)(BrowsePromotionSearch);
