import { isNull, negate } from "lodash";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import { default as moment } from "moment";
import papa from "papaparse";
import {
  isBudget,
  isCampaign,
  isCampaignItem,
  isShow,
  ICampaignItem,
  ICampaign,
} from "redcircle-types";
import { store } from "src";
import { showInfo, showWarning } from "src/actions/app";
import { getBudgetsByCampaignUUID } from "src/action_managers/budgets";
import { getCampaign } from "src/action_managers/campaigns";
import { fetchDistributions } from "src/action_managers/distributions";
import { fetchPublicShowsByUUIDs } from "src/action_managers/public_show";
import {
  getVettingFormsForCampaignUUID,
  getVettingInvitationsForCampaign,
} from "src/action_managers/vetting";
import { downloadFile } from "src/components/lib/download_file";
import { distributionKeyNames } from "src/constants/distribution";
import { itunesCategories } from "src/data/itunes_categories";
import { getVettingResponseMatrix, VettingResponseMatrix } from "src/hooks/vetting";
import { getAverageCPM, getImpressionsFromBudget } from "src/lib/campaigns";
import { getCampaignItemField } from "src/lib/campaign_item";
import { clearHTMLTags } from "src/lib/dom";
import { formatDuration } from "src/lib/duration";
import { formatMoney } from "src/lib/format-money";
import { isUUID } from "src/lib/uuid";
import { isMediaFile } from "redcircle-types";
import { PublicShowsReduxState } from "src/reducers/public_show";
import { formatStartAndEndDates } from "./campaign_page_utils";

const NOT_SET = "Not Set";
/**
 * Outputs CSV with relevant campaign data including vetting responses.
 *
 * Can also use async wrapper version @see {@link downloadCartInfoForCampaign} with no dependencies from parameters
 *
 */
export const downloadCartInfo = async ({
  campaign,
  publicShows,
  campaignItems,
  getDistribution,
  campaignVettingResponses,
}: {
  campaign: ICampaign;
  publicShows: PublicShowsReduxState;
  campaignItems: ICampaignItem[];
  getDistribution?: any;
  campaignVettingResponses?: VettingResponseMatrix;
}) => {
  if (isEmpty(campaign) || isEmpty(campaignItems) || isEmpty(publicShows)) {
    return;
  }

  campaignItems = sortBy(campaignItems, "respondedAt");

  // extract columns from matrix: { questionUUID: question }
  const vettingColumns = campaignVettingResponses
    ? Object.values(campaignVettingResponses).reduce(
        (acc, showResponse) => {
          const showResponseKeys: Record<string, string> = Object.keys(showResponse).reduce(
            (acc, key) => {
              return { ...acc, [key]: `Vetting: ${showResponse[key].question}` };
            },
            {}
          );
          return { ...acc, ...showResponseKeys };
        },
        {} as Record<string, string>
      )
    : {};

  const shows = campaignItems
    .map(async (campaignItem) => {
      const show = publicShows[campaignItem.showUUID];
      if (!show) {
        return null;
      }

      const adSettings = show.advertisementSettings;

      // for each gender category > 5% viewership, form a comma-separated string describing the podcast's gender demographics
      const genderBreakdown: any = {
        Female: show.listenerDemographics?.genderBreakdown?.female,
        Male: show.listenerDemographics?.genderBreakdown?.male,
        "Non-binary": show.listenerDemographics?.genderBreakdown?.nonBinary,
      };
      const genderSummary = Object.keys(genderBreakdown)
        .filter((gender: string) => genderBreakdown[gender] && genderBreakdown[gender] >= 0.05)
        .map((gender) => `${gender}: ${Math.round(genderBreakdown[gender] * 100)}%`)
        .join(", ");

      let runTime: string = NOT_SET;
      if (campaignItem.startAt > 0) {
        // won't be set until the user sets the schedule
        const dateFormat = "MM/DD/YYYY";
        runTime = formatStartAndEndDates(campaignItem, campaign, show, dateFormat);
      }

      const distribution = await getDistribution(show.uuid);
      const applePodcastsLink =
        distribution.status === 200
          ? distribution.json.details[distributionKeyNames.applePodcasts]?.url
          : undefined;

      const cpm = getAverageCPM({ show, campaign, campaignItem });

      const showResponse = campaignVettingResponses?.[campaignItem.uuid] || {};
      const vettingValues = Object.keys(vettingColumns).reduce((acc, questionUUID) => {
        const question = vettingColumns[questionUUID];
        const response = showResponse[questionUUID]?.response || "";
        return { ...acc, [question]: response };
      }, {});
      const campaignDownloads = Math.round(
        getImpressionsFromBudget({ cpm, budget: campaignItem.totalBudget })
      );

      const targetingOptions = getCampaignItemField("targetingOptions", { campaignItem, campaign });

      return {
        "Show ID": show.uuid,
        "Show Name": show.title,
        // form a comma-separated string of each host's full name
        Hosts: (adSettings?.hosts ?? [])
          .map(({ fullName }: any) => {
            return `${fullName.firstName} ${fullName.lastName}`;
          })
          .join(", "),
        "Promo Code": show.promoCode,
        // find each category UUID in the UUID -> name mapping and join into a comma-separated string
        Categories: show.categoryUUIDs
          .map((categoryUUID) => {
            return itunesCategories.find((category) => category.uuid == categoryUUID);
          })
          .filter(Boolean)
          .map((category) => category!.name)
          .join(", "),
        "Weekly Downloads": show.estimatedWeeklyDownloads,
        CPM: formatMoney(cpm),
        "Campaign Downloads": campaignDownloads > 0 ? campaignDownloads : NOT_SET,
        "Estimated Run Time": runTime,
        "Total Budget": formatMoney(campaignItem.totalBudget),
        // based on the targeting options for the campaign, create a comma-separated string of positions that the campaign will insert into
        "Ad Placement": [
          targetingOptions.preRoll ? "Pre" : null,
          targetingOptions.midRoll ? "Mid" : null,
          targetingOptions.postRoll ? "Post" : null,
        ]
          .filter(negate(isNull))
          .join(", "),
        "Episode Frequency": show.estimatedEpisodeFrequency,
        "Avg Episode Downloads": show.averageEpisodeDownloads,
        "Avg Episode Length": formatDuration(show.averageEpisodeDuration),
        Gender: genderSummary,
        // for each age bucket > 5%, create a comma-separated string of the age demographics for the podcast
        Age: (show.listenerDemographics?.ageBuckets ?? [])
          .filter((ageBucket) => ageBucket.percentage >= 0.05)
          .filter((ageBucket) => ageBucket.label !== "0-0") // remove the "unknown category"
          .map((ageBucket) => {
            return `${ageBucket.minAge}-${ageBucket.maxAge}: ${Math.round(
              ageBucket.percentage * 100
            )}%`;
          })
          .join(", "),
        Facebook: adSettings?.facebookLink,
        Instagram: adSettings?.instagramLink,
        TikTok: adSettings?.tikTokLink,
        Twitter: adSettings?.twitterLink,
        Youtube: adSettings?.youtubeLink,
        "Apple Podcasts": applePodcastsLink,
        "Podcast Description": clearHTMLTags(show.description),
        ...vettingValues,
      };
    })
    .filter(Boolean);

  const csv = papa.unparse(await Promise.all(shows));
  const now = moment().unix();
  downloadFile(`${campaign.name}_${now}.csv`, csv);
};

/**
 * Async function that checks and fetches all required data for the csv output of the
 * `downloadCartInfo` function.
 *
 * Checks `campaign`, `campaignItems`, `Budget`, `MediaFile`, `VettingForms`, and `VettingResponses` are available in
 * redux state, and will fetch any missing data.
 *
 */
export const downloadCartInfoForCampaign = async (campaignUUID: string) => {
  // Initially check onr redux state
  const { campaigns } = store.getState()?.campaigns;
  const { campaignItemByCampaignUUID, campaignItems: campaignItemsMap } =
    store.getState()?.campaignItems;

  let mediaFilesMap = store.getState()?.mediaFiles;

  let budgetsMap = store.getState()?.budgets;
  let publicShows = store.getState()?.publicShows;

  let { formsByCampaignUUID, invitationsByCampaignUUID } = store.getState()?.vetting;

  let campaign = campaigns?.[campaignUUID];
  let campaignItems = campaignItemByCampaignUUID?.[campaignUUID]
    ?.map((campaignItemUUID) => campaignItemsMap?.[campaignItemUUID])
    ?.filter((campaignItem) => isCampaignItem(campaignItem));

  const isMediaFilesMissing =
    campaignItems
      ?.map((item) => item.mediaFileUUID)
      ?.filter((uuid) => isUUID(uuid))
      ?.some((mediaFileUUID) => !isMediaFile(mediaFilesMap[mediaFileUUID as string])) ?? true;

  try {
    // Check if campaign, campaignItems, and media files are available in redux state
    // the getCampaign reducer fetches all three entities
    if (
      !isCampaign(campaign) ||
      !Array.isArray(campaignItems) ||
      campaignItems?.length === 0 ||
      isMediaFilesMissing
    ) {
      await store.dispatch(getCampaign(campaignUUID));
      campaign = store.getState()?.campaigns?.campaigns?.[campaignUUID];
      const { campaignItemByCampaignUUID, campaignItems: campaignItemsMap } =
        store.getState()?.campaignItems;

      campaignItems = campaignItemByCampaignUUID?.[campaignUUID]?.map(
        (campaignItemUUID) => campaignItemsMap?.[campaignItemUUID]
      );

      mediaFilesMap = store.getState()?.mediaFiles;
    }

    // If campaign has no campaign Items. Can stop right here
    if (!Array.isArray(campaignItems) || campaignItems?.length === 0) {
      store.dispatch(showInfo(`"${campaign.name}" does not have any podcasts.`));
      return;
    }

    const missingPublicShowsList = campaignItems
      ?.map((item) => item.showUUID)
      ?.filter((publicShowUUID) => !isShow(publicShows?.[publicShowUUID]));
    const isPublicShowsMissing = missingPublicShowsList?.length > 0;

    // Fetch public shows that are missing in redux state
    if (isPublicShowsMissing) {
      await store.dispatch(fetchPublicShowsByUUIDs(missingPublicShowsList));
      publicShows = store.getState()?.publicShows;
    }

    const isBudgetsMissing = campaignItems
      ?.map((item) => item?.budgetUUID)
      .filter((budgetUUID) => isUUID(budgetUUID))
      ?.some((budgetUUID) => !isBudget(budgetsMap[budgetUUID as string]));

    // Fetch budgets if they are missing
    if (isBudgetsMissing) {
      await store.dispatch(getBudgetsByCampaignUUID(campaignUUID));
      budgetsMap = store.getState()?.budgets;
    }

    // Fetch Vetting responses
    const isVettingFormsMissing =
      !Array.isArray(formsByCampaignUUID?.[campaignUUID]) ||
      formsByCampaignUUID?.[campaignUUID]?.length === 0;

    if (isVettingFormsMissing) {
      await store.dispatch(getVettingFormsForCampaignUUID(campaignUUID));
      formsByCampaignUUID = store.getState()?.vetting.formsByCampaignUUID;
    }

    const isVettingInvitationsMissing =
      !Array.isArray(invitationsByCampaignUUID?.[campaignUUID]) ||
      invitationsByCampaignUUID?.[campaignUUID]?.length === 0;

    if (isVettingInvitationsMissing) {
      await store.dispatch(getVettingInvitationsForCampaign(campaignUUID));
      invitationsByCampaignUUID = store.getState()?.vetting.invitationsByCampaignUUID;
    }

    /**
     * Load requirements for Download CSV function
     */

    const vettingResponseMatrix = getVettingResponseMatrix({
      campaignUUID,
      formsByCampaignUUID,
      invitationsByCampaignUUID,
    });

    downloadCartInfo({
      campaign,
      publicShows,
      campaignItems,
      getDistribution: async (showUUID: string) => {
        return store.dispatch(fetchDistributions(showUUID));
      },
      campaignVettingResponses: vettingResponseMatrix,
    });
  } catch (err) {
    store.dispatch(showWarning("Could not download CSV"));
  }
};
