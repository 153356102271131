import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import React, { Component } from "react";
import Dimensions from "react-dimensions";
import KnownAgentFriendlyNames from "../../../lib/known_agent_friendly";
import { aggregateStats, graphColors } from "./utils";

const statsKeyMobile = "mobile";
const statsKeyDesktop = "desktop";
const statsKeyWearable = "wearable";
const statsKeySmartSpeaker = "smartSpeaker";

const allStatsKeys = [statsKeyMobile, statsKeyDesktop, statsKeyWearable, statsKeySmartSpeaker];

class DeviceGraph extends Component {
  componentDidMount() {
    this.props.fetchStats(
      {
        isUnique: true,
        [this.props.uuidType]: this.props.uuid,
        timeRange: "allTime",
        bucketTerms: "download.metadata.device",
      },
      this.props.user,
      this.statsRequestID()
    );
  }

  statsRequestID = () => {
    return this.props.uuid + "device-graph";
  };

  getData = () => {
    return aggregateStats(get(this.props.stats, this.statsRequestID()), {
      removeUnknown: true,
    });
  };

  maxRadius = () => {
    return Math.min(this.props.containerWidth / 4, this.props.containerHeight / 2);
  };

  render() {
    var data = this.getData();
    if (isEmpty(data) || isNil(data)) {
      return null;
    }
    return (
      <div className="device-graph">
        <div className="device-graph__circles_row">
          {allStatsKeys.map((item, index) => {
            var radiusFactor = Math.max(
              0.02,
              get(data, [item, "count"], 0.04) / get(data, [item, "total"], 1)
            );
            return (
              <div key={index} className="device-graph__circles-column">
                <div
                  className="device-graph__circles-column__circle"
                  style={{
                    width: this.maxRadius() * radiusFactor,
                    height: this.maxRadius() * radiusFactor,
                    borderRadius: "50%",
                    background: graphColors[index],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginLeft: (this.maxRadius() * radiusFactor) / -2,
                    marginTop: (this.maxRadius() * radiusFactor) / -2,
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="device-graph__labels-row">
          {allStatsKeys.map((item, index) => {
            var percent = Math.round(
              (get(data, [item, "count"], 0) / get(data, [item, "total"], 1)) * 100
            );
            return (
              <div key={index} className="device-graph__labels-column">
                <div className="device-graph__labels-column__percent">{percent}%</div>
                <div className="device-graph__labels-column__label redcircle-label">
                  {KnownAgentFriendlyNames.get("device", item)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Dimensions()(DeviceGraph);
