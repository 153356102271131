import get from "lodash/get";
import { AnyAction } from "redux";
import {
  SHOW_PUT_CLEAR,
  SHOW_PUT_ERROR,
  SHOW_PUT_INVALID,
  SHOW_PUT_START,
  SHOW_PUT_SUCCESS,
} from "../../actions/shows";
import { ENABLE_EXCLUSIVE_CONTENT } from "../../action_managers/subscriptions";
import { getActionNames } from "../../lib/create-action";

const initialState = {
  isLoading: false,
  isSuccess: false,
  validationErrors: [],
  submittedShow: null,
};

function showForm(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SHOW_PUT_START:
    case getActionNames(ENABLE_EXCLUSIVE_CONTENT).started:
      return Object.assign({}, initialState, {
        isLoading: true,
      });
    case SHOW_PUT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
        submittedShow: action.shows[0],
      });
    case getActionNames(ENABLE_EXCLUSIVE_CONTENT).success:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
      });
    case SHOW_PUT_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case SHOW_PUT_INVALID:
      return Object.assign({}, state, {
        isLoading: false,
        validationErrors: action.validationErrors,
      });
    case getActionNames(ENABLE_EXCLUSIVE_CONTENT).failureBadRequest:
    case getActionNames(ENABLE_EXCLUSIVE_CONTENT).failure:
      return Object.assign({}, state, {
        isLoading: false,
        validationErrors: get(action, "payload.validationErrors", []),
      });
    case SHOW_PUT_CLEAR:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}

export default showForm;
