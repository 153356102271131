import get from "lodash/get";
import React, { Component } from "react";
import { Checkbox, Col, Grid, Row } from "react-bootstrap";
import { If } from "react-extras";
import { connect } from "react-redux";
import { showSuccess, showWarning } from "../../../actions/app";
import { hideModal } from "../../../actions/modal";
import FormElement from "../../forms/form_element";
import LoadingButton from "../../forms/loading_button/loading_button";
import InfoTooltip from "../../lib/info";
import BaseModal from "../base_modal";
import "../modal.scss";
import "./enable_subscriptions_modal.scss";

class EnableSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amountMonthly: props.amountMonthly / 100 || 5,
      amountWeekly: props.amountWeekly / 100 || 1,
      amountAnnual: props.amountAnnual / 100 || 50,

      monthlyChecked: !!props.amountMonthly,
      weeklyChecked: !!props.amountWeekly,
      annualChecked: !!props.amountAnnual,

      description: props.description || "",
    };
  }

  modalContent = () => {
    let subscriptionErrors = get(this.props, "validationErrors", []).filter(
      (item) => item.fieldName === "subscriptionDetails"
    );
    return (
      <div className={"body"}>
        <Grid fluid>
          <Row>
            <Col xs={10} xsOffset={1}>
              <p className="description">
                When a listener pledges to sponsor you a certain monthly amount, you can give them
                access to the exclusive content on this podcast.
              </p>
              <form action="#" onSubmit={this.submit}>
                <p>
                  <h3>
                    Sponsorship Intervals & Pricing
                    <InfoTooltip
                      helpText={
                        "Allow users to subscribe on different payment intervals and set the price they must contribute each weekly to gain access to the exclusive content."
                      }></InfoTooltip>
                  </h3>
                  <If condition={subscriptionErrors && subscriptionErrors.length !== 0}>
                    <p className={"text-danger"}>{get(subscriptionErrors, [0, "errorMessage"])}</p>
                  </If>
                </p>
                <Checkbox
                  className={"m-bs"}
                  defaultChecked={this.state.weeklyChecked}
                  onChange={(e) => {
                    this.setState({ weeklyChecked: e.target.checked });
                  }}>
                  <span className={"redcircle-form-label"}>Weekly Subscriptions</span>
                </Checkbox>
                <FormElement
                  value={this.state.amountWeekly}
                  type="number"
                  input={{ name: "amountWeekly" }}
                  placeholder={"$1"}
                  prefix={"$"}
                  onChange={(e) => this.setState({ amountWeekly: parseFloat(e.target.value) })}
                  validationErrors={this.props.validationErrors}
                  disabled={!this.state.weeklyChecked}
                />

                <Checkbox
                  className={"m-bs"}
                  defaultChecked={this.state.monthlyChecked}
                  onChange={(e) => {
                    this.setState({ monthlyChecked: e.target.checked });
                  }}>
                  <span className={"redcircle-form-label"}>Monthly Subscriptions</span>
                </Checkbox>
                <FormElement
                  value={this.state.amountMonthly}
                  type="number"
                  input={{ name: "amount" }}
                  placeholder={"$5"}
                  prefix={"$"}
                  infoText="Listeners must contribute this price each month to gain access to the exclusive content."
                  onChange={(e) => this.setState({ amountMonthly: parseFloat(e.target.value) })}
                  validationErrors={this.props.validationErrors}
                  disabled={!this.state.monthlyChecked}
                />

                <Checkbox
                  className={"m-bs"}
                  defaultChecked={this.state.annualChecked}
                  onChange={(e) => {
                    this.setState({ annualChecked: e.target.checked });
                  }}>
                  <span className={"redcircle-form-label"}>Annual Subscriptions</span>
                </Checkbox>
                <FormElement
                  value={this.state.amountAnnual}
                  type="number"
                  input={{ name: "amountAnnual" }}
                  placeholder={"$50"}
                  prefix={"$"}
                  onChange={(e) => this.setState({ amountAnnual: parseFloat(e.target.value) })}
                  validationErrors={this.props.validationErrors}
                  disabled={!this.state.annualChecked}
                />
                <FormElement
                  label="Description"
                  type="textarea"
                  rows="3"
                  input={{ name: "description" }}
                  componentClass="textarea"
                  value={this.state.description}
                  placeholder={"Subscribers get bonus episodes and extended interviews."}
                  infoText="A short description of what exclusive content listeners get when they subscribe."
                  onChange={(e) => this.setState({ description: e.target.value })}
                  validationErrors={this.props.validationErrors}
                />
              </form>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };

  submit = () => {
    if (!this.state.amountMonthly && !this.state.amountWeekly && !this.state.amountAnnual) {
      return this.props.showWarning(
        "You must specify at least one subscription interval and price."
      );
    }

    // When enabling subscriptions, 0 is not allowed, but when editing, 0 is used to indicate it's off.
    const defaultValue = this.props.isEdit ? 0 : null;

    let formData = {
      description: this.state.description,
      amount: this.state.monthlyChecked ? this.state.amountMonthly * 100 : defaultValue,
      amountWeekly: this.state.weeklyChecked ? this.state.amountWeekly * 100 : defaultValue,
      amountAnnual: this.state.annualChecked ? this.state.amountAnnual * 100 : defaultValue,
    };

    this.props.submit(formData).then((resp) => {
      if (resp.status === 200) {
        this.props.hideModal();
      }
    });
  };
  render() {
    return (
      <BaseModal
        containerClass={"enable-subscriptions"}
        Footer={() => (
          <div className={"footer-container"}>
            <Row>
              <Col xs={5} xsOffset={1}>
                <button onClick={this.props.hideModal} className="btn btn-secondary width-100">
                  Cancel
                </button>
              </Col>
              <Col xs={5}>
                <LoadingButton
                  isLoading={this.props.isLoading}
                  onClick={this.submit}
                  className="btn btn-primary width-100">
                  OK
                </LoadingButton>
              </Col>
            </Row>
          </div>
        )}
        isLoading={false}
        isMini={true}
        columns={10}
        offset={1}
        title={"Exclusive Content Settings"}
        close={this.props.hideModal}
        hideCloseButton={false}
        showContent={true}
        Content={this.modalContent}
      />
    );
  }
}

export default connect(
  ({ showForm }) => ({
    validationErrors: showForm.validationErrors,
    isLoading: showForm.isLoading,
  }),
  {
    hideModal,
    showWarning,
    showSuccess,
  }
)(EnableSubscriptions);
