import { AnyAction } from "redux";
import {
  EPISODE_DELETE_CLEAR,
  EPISODE_DELETE_ERROR,
  EPISODE_DELETE_START,
  EPISODE_DELETE_SUCCESS,
} from "../../actions/episode_delete_modal";
import { EpisodeDeleteReduxState } from "./types";

const initialState: EpisodeDeleteReduxState = {
  isLoading: false,
  isSuccess: false,
};

function episodeDelete(state = initialState, action: AnyAction) {
  switch (action.type) {
    case EPISODE_DELETE_CLEAR:
      return Object.assign({}, initialState);
    case EPISODE_DELETE_START:
      return {
        isLoading: true,
      };
    case EPISODE_DELETE_SUCCESS:
      return {
        isLoading: false,
        isSuccess: true,
      };
    case EPISODE_DELETE_ERROR:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
}

export default episodeDelete;
