import { useContext } from "react";
import { AiFillBackward, AiFillForward, AiFillPauseCircle, AiFillPlayCircle } from "react-icons/ai";
import { RiStopFill } from "react-icons/ri";
import { Button } from "../../Button";
import { AudioPlayerContext } from "./Wrapper";

export const PlayButton = ({ className }: { className?: string }) => {
  const { audioData, pause, play } = useContext(AudioPlayerContext);
  const { isPlaying } = audioData;

  return (
    <Button
      type="icon"
      size="large"
      className={className}
      onClick={() => (isPlaying ? pause() : play())}>
      {isPlaying ? <AiFillPauseCircle /> : <AiFillPlayCircle />}
    </Button>
  );
};

export const BackwardButton = ({
  className,
  onClick,
  disabled,
}: {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button type="icon" className={className} onClick={onClick} disabled={disabled}>
      <AiFillBackward />
    </Button>
  );
};

export const ForwardButton = ({
  className,
  onClick,
  disabled,
}: {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button type="icon" className={className} onClick={onClick} disabled={disabled}>
      <AiFillForward />
    </Button>
  );
};

export const StopButton = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) => {
  const { stop } = useContext(AudioPlayerContext);

  const handleClick = () => {
    stop();
    if (onClick) onClick();
  };

  return (
    <Button type="icon" className={className} onClick={handleClick}>
      <RiStopFill />
    </Button>
  );
};
