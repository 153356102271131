import { Spin } from "antd";
import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, RouteProps, useRouteMatch } from "react-router-dom";
import UserRoles from "src/constants/roles";
import { userRoleExists } from "src/lib/user";
import { UserRole } from "redcircle-types";
import { showWarning } from "../../actions/app";
import { hideModal } from "../../actions/modal";
import { AdvertiserPermissionTypes, permissionTypes } from "../../constants/permission_roles";
import { useSelectorTS } from "../../hooks/redux-ts";
import {
  canAccess,
  canAdvertiserAccess,
  hasAtLeastOnePermitted,
  useCanAccessBound,
  useHasAtLeastOnePermitted,
  usePermissionIsLoading,
} from "../../lib/permissions";
import { isEqual } from "lodash";
import { CampaignState } from "src/reducers/campaigns/campaigns";

/**
 * Helps ensure Route Permissions check component does not constantly re render on every dispatch to campaigns.
 */
const campaignSelectorEqualityFunc = (prev: CampaignState, next: CampaignState) => {
  const campaignsLoadingIsEqual = prev.campaignsForUserIsLoading === next.campaignsForUserIsLoading;
  const initialFetchedIsEqual =
    prev.campaignsForUserInitialFetched === next.campaignsForUserInitialFetched;
  const campaignsIsEqual = isEqual(prev?.campaigns, next.campaigns);

  return initialFetchedIsEqual && campaignsIsEqual && campaignsLoadingIsEqual;
};

const defaultUserRoleRedirectURL: Record<UserRole, string> = {
  admin: "/account",
  advertiser: "/campaigns",
  creator: "/shows",
  sponsor: "/account",
};

interface IPrivateRoute extends RouteProps {
  permissionType: keyof typeof permissionTypes;
  paramKey?: string;
}

export const PrivateRoute = ({ permissionType, children, paramKey, ...props }: IPrivateRoute) => {
  const { user, isLoading } = useSelectorTS((state) => state.user);
  const permissionState = useSelectorTS((state) => state.permissions);
  const dispatch = useDispatch();
  return (
    <Route
      {...props}
      render={({ match }) => {
        const objectUUID = paramKey ? match.params[paramKey] : user?.uuid;
        if (isLoading || permissionState.isLoading || permissionState.presetIsLoading) {
          return null;
        }
        const shouldShow = canAccess(user, permissionState, permissionType, objectUUID);
        if (!shouldShow) {
          dispatch(hideModal());
          dispatch(showWarning("You are not authorized for this action"));
        }
        return shouldShow ? children : <Redirect to={"/"} />;
      }}
    />
  );
};

export const PrivateRouteAtLeastOne = ({ permissionType, children, ...props }: IPrivateRoute) => {
  const permissionIsLoading = usePermissionIsLoading();
  const dispatch = useDispatch();
  const shouldShow = useHasAtLeastOnePermitted(permissionType);
  return (
    <Route
      {...props}
      render={() => {
        if (permissionIsLoading) {
          return null;
        }
        if (!shouldShow) {
          dispatch(hideModal());
          dispatch(showWarning("You are not authorized for this action"));
        }
        return shouldShow ? children : <Redirect to={"/"} />;
      }}
    />
  );
};

const Loader = () => (
  <div
    style={{ height: "100vh" }}
    className="width-100 flex-row-container align-center justify-center">
    <Spin spinning={true} size="large" />
  </div>
);

interface IRouteLogInCheck {
  requireLogIn?: boolean; // enables component checking if the user is logged in, in order to access this route
  children?: ReactNode;
}

/**
 * Route Check for User redux object to determine if user is logged in.
 * Can check if route required user to be logged in or not
 */
const RouteLogInCheck = React.memo((props: IRouteLogInCheck) => {
  const { requireLogIn = true, children } = props;
  const { user, isLoading: isUserLoading } = useSelectorTS((state) => state.user);
  const userHasRole = userRoleExists(user);

  //  Route requires log in check
  if (requireLogIn) {
    // User object is available
    if (user) {
      // copying logic to check if user is logged in (i.e. user role exists)
      if (userHasRole) {
        return <>{children}</>;
      }
    }
    // user object is not available
    else {
      // user object is still loading for the first time
      if (isUserLoading) {
        return <Loader />;
      }
      // user object is not available and there is no loading, user is not logged in
      else {
        return <Redirect to={"/sign-in"} />;
      }
    }
  }

  return <>{children}</>;
});

RouteLogInCheck.displayName = "RouteLogInCheck";

interface IRouteUserRoleCheck {
  roles?: UserRole[];
  children?: ReactNode;
}
/**
 * Route check, isolates all logic for checking correct UserRole (i.e podcaster, advertiser, sponsor)
 */
const RouteUserRoleCheck = React.memo((props: IRouteUserRoleCheck) => {
  const { roles, children } = props;
  const { isLoading: userIsLoading, currentRole } = useSelectorTS((state) => state.user);

  /**
   * Data is loading, show spinner
   */
  if (userIsLoading) {
    return <Loader />;
  }

  const userRolesProvided = Array.isArray(roles) && roles.length > 0;

  // Check user role matches role restriction
  if (userRolesProvided) {
    const hasAllowedRole =
      roles.some((role) => role === currentRole) || currentRole === UserRoles.Admin;

    if (!hasAllowedRole) {
      return <Redirect to={"/"} />;
    }
  }

  return <>{children}</>;
});

RouteUserRoleCheck.displayName = "RouteUserRoleCheck";

interface IRoutePermissionCheck extends Pick<RouteProps, "path"> {
  permissionType?: keyof typeof permissionTypes;
  useHasAtLeastOne?: boolean; // check permission type is found in at least 1 of the credentials permission
  paramKey?: "campaignUUID" | "showUUID" | "audioBlockUUID";
  skipLoad?: boolean; // incase loading states are handled in route components
  redirectURL?: string; // can provide a redirect URL if permission check failed
  children?: ReactNode;
}

/**
 * Route check, isolates all logic for checking user correct route permissions
 */
const RoutePermissionCheck = React.memo((props: IRoutePermissionCheck) => {
  const {
    permissionType,
    paramKey,
    useHasAtLeastOne = false,
    skipLoad = false,
    children,
    redirectURL,
    path = "",
  } = props;

  const match = useRouteMatch<Record<NonNullable<typeof paramKey>, string>>({ path });
  const { campaignUUID, showUUID, audioBlockUUID } = match?.params ?? {};

  const { user, currentRole } = useSelectorTS((state) => state.user);
  const showsInitialFetched = useSelectorTS((state) => state.shows.initialFetched);
  const permissionsReduxState = useSelectorTS((state) => state.permissions);
  const { campaigns, campaignsForUserInitialFetched, campaignsForUserIsLoading } = useSelectorTS(
    (state) => state.campaigns,
    campaignSelectorEqualityFunc
  );

  const arrayOfCampaigns = Object.values(campaigns ?? {}).map((campaign) => campaign);
  const { isLoading: permissionsIsLoading, presetIsLoading } = permissionsReduxState;

  /**
   * Checking if user provided a paramKey for the slug and grabbing it from the URL, if it was not provided
   * or it does not exist then going down the line of possible slugs before defaulting to org entity
   */
  const entityUUID =
    (typeof paramKey === "string" && match !== null ? match?.params[paramKey] : undefined) ??
    showUUID ??
    campaignUUID ??
    audioBlockUUID ??
    user.uuid;

  const canAccess = useCanAccessBound();

  /**
   * Determining if the route needs to load preliminary information in order
   * to check for correct permissions
   */
  let isLoading = false;

  switch (currentRole) {
    case UserRoles.Advertiser:
      isLoading =
        permissionsIsLoading ||
        presetIsLoading ||
        campaignsForUserIsLoading ||
        !campaignsForUserInitialFetched;
      break;

    case UserRoles.Creator:
      isLoading = permissionsIsLoading || presetIsLoading || !showsInitialFetched;
      break;
    case UserRoles.Sponsor:
      // Sponsor routes currently don't rely on permissions so no need to wait and can skip loading phase
      isLoading = false;
      break;
    case UserRoles.Admin:
      // Will be deprecated eventually but basically encapsulates "advertiser" and "Creator" requirements for isLoading.
      isLoading =
        permissionsIsLoading ||
        presetIsLoading ||
        campaignsForUserIsLoading ||
        !campaignsForUserInitialFetched ||
        !showsInitialFetched;
      break;
  }

  /**
   * Data is loading, show spinner
   */
  if (!skipLoad && isLoading) {
    return <Loader />;
  }

  const permissionTypesProvided = typeof permissionType == "string";

  // CHeck user permission matches the permissionType restriction
  if (permissionTypesProvided) {
    let hasAllowedPermission = false;
    switch (currentRole) {
      case "advertiser":
        hasAllowedPermission = useHasAtLeastOne
          ? arrayOfCampaigns.some((campaign) =>
              canAdvertiserAccess(permissionType as AdvertiserPermissionTypes, campaign)
            )
          : canAdvertiserAccess(
              permissionType as AdvertiserPermissionTypes,
              campaigns?.[entityUUID]
            );
        break;
      case "creator":
        hasAllowedPermission = useHasAtLeastOne
          ? hasAtLeastOnePermitted(user, permissionsReduxState, permissionType)
          : canAccess(permissionType, entityUUID);
        break;
      case "admin":
      case "sponsor":
        hasAllowedPermission = true;
        break;
    }

    if (!hasAllowedPermission) {
      const defaultRedirectURL = defaultUserRoleRedirectURL[currentRole];
      return <Redirect to={redirectURL ?? defaultRedirectURL} />;
    }
  }

  return <>{children}</>;
});

RoutePermissionCheck.displayName = "RoutePermissionCheck";

interface IRCRoute
  extends IRouteLogInCheck,
    IRouteUserRoleCheck,
    IRoutePermissionCheck,
    Omit<RouteProps, "children"> {}

/**
 * TODO Replace all Route/PrivateRoute/RoleRoute/PrivateRouteAtLeastOne with RCRoute component,
 * need to do this post campaign permission in order to not increase scope too much.
 *
 * Consolidates all permission and user role check logic into one route component
 */
export const RCRoute = (props: IRCRoute) => {
  const {
    requireLogIn,
    permissionType,
    paramKey,
    roles,
    useHasAtLeastOne,
    skipLoad,
    children,
    redirectURL,
    ...restRouterProps
  } = props;

  const routeLogInProps: IRouteLogInCheck = {
    requireLogIn,
  };

  const routeUserRoleProps: IRouteUserRoleCheck = {
    roles,
  };

  const routePermissionProps: IRoutePermissionCheck = {
    permissionType,
    useHasAtLeastOne,
    paramKey,
    skipLoad,
    redirectURL,
    path: restRouterProps.path,
  };

  return (
    <Route {...restRouterProps}>
      <RouteLogInCheck {...routeLogInProps}>
        <RouteUserRoleCheck {...routeUserRoleProps}>
          <RoutePermissionCheck {...routePermissionProps}>{children}</RoutePermissionCheck>
        </RouteUserRoleCheck>
      </RouteLogInCheck>
    </Route>
  );
};
