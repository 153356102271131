import { AnyAction } from "redux";
import {
  SIGN_OUT_REQUEST_END,
  SIGN_OUT_REQUEST_START,
  UPDATE_ACCOUNT_CLEAR,
  UPDATE_ACCOUNT_ERROR,
  UPDATE_ACCOUNT_INVALID,
  UPDATE_ACCOUNT_START,
  UPDATE_ACCOUNT_SUCCESS,
} from "../../actions/account";
import { SignOutState, UpdateAccountReduxState } from "./types";

const initialState: SignOutState = {
  isLoading: false,
};

export function signOut(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SIGN_OUT_REQUEST_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case SIGN_OUT_REQUEST_END:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}

const initialUpdateState: UpdateAccountReduxState = {
  isLoading: false,
  hasError: false,
  validationErrors: [],
};

export function updateAccount(state = initialUpdateState, action: AnyAction) {
  switch (action.type) {
    case UPDATE_ACCOUNT_CLEAR:
      return Object.assign({}, initialUpdateState);
    case UPDATE_ACCOUNT_START:
      return Object.assign({}, state, {
        isLoading: true,
        hasError: false,
        validationErrors: [],
      });
    case UPDATE_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
      });
    case UPDATE_ACCOUNT_INVALID:
      return Object.assign({}, state, {
        isLoading: false,
        hasError: true,
        validationErrors: action.validationErrors,
      });
    case UPDATE_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        hasError: true,
        validationErrors: [],
      });
    default:
      return state;
  }
}
