const daastAdCategories = [
  {
    title: "Arts & Entertainment",
    value: "IAB1",
    children: [
      { title: "Books & Literature", value: "IAB1-1" },
      { title: "Celebrity Fan/Gossip", value: "IAB1-2" },
      { title: "Fine Art", value: "IAB1-3" },
      { title: "Humor", value: "IAB1-4" },
      { title: "Movies", value: "IAB1-5" },
      { title: "Music", value: "IAB1-6" },
      { title: "Television", value: "IAB1-7" },
      { title: "Gambling/Casino", value: "IAB1-8" },
      { title: "Lottery", value: "IAB1-9" },
      { title: "Magazines", value: "IAB1-10" },
      { title: "Concerts", value: "IAB1-11" },
      { title: "Museums", value: "IAB1-12" },
    ],
  },
  {
    title: "Automotive",
    value: "IAB2",
    children: [
      { title: "Auto Parts", value: "IAB2-1" },
      { title: "Auto Repair", value: "IAB2-2" },
      { title: "Buying/Selling Cars", value: "IAB2-3" },
      { title: "Car Culture", value: "IAB2-4" },
      { title: "Certified Pre-Owned", value: "IAB2-5" },
      { title: "Convertible", value: "IAB2-6" },
      { title: "Coupe", value: "IAB2-7" },
      { title: "Crossover", value: "IAB2-8" },
      { title: "Diesel", value: "IAB2-9" },
      { title: "Electric Vehicle", value: "IAB2-10" },
      { title: "Hatchback", value: "IAB2-11" },
      { title: "Hybrid", value: "IAB2-12" },
      { title: "Luxury", value: "IAB2-13" },
      { title: "MiniVan", value: "IAB2-14" },
      { title: "Mortorcycles", value: "IAB2-15" },
      { title: "Off-Road Vehicles", value: "IAB2-16" },
      { title: "Performance Vehicles", value: "IAB2-17" },
      { title: "Pickup", value: "IAB2-18" },
      { title: "Road-Side Assistance", value: "IAB2-19" },
      { title: "Sedan", value: "IAB2-20" },
      { title: "Trucks & Accessories", value: "IAB2-21" },
      { title: "Vintage Cars", value: "IAB2-22" },
      { title: "Wagon", value: "IAB2-23" },
      { title: "Recreational Vehicles", value: "IAB2-24" },
      { title: "Car Wash", value: "IAB2-25" },
    ],
  },
  {
    title: "Business",
    value: "IAB3",
    children: [
      { title: "Advertising", value: "IAB3-1" },
      { title: "Agriculture", value: "IAB3-2" },
      { title: "Biotech/Biomedical", value: "IAB3-3" },
      { title: "Business Software", value: "IAB3-4" },
      { title: "Construction", value: "IAB3-5" },
      { title: "Forestry", value: "IAB3-6" },
      { title: "Government", value: "IAB3-7" },
      { title: "Green Solutions", value: "IAB3-8" },
      { title: "Human Resources", value: "IAB3-9" },
      { title: "Logistics", value: "IAB3-10" },
      { title: "Marketing", value: "IAB3-11" },
      { title: "Metals", value: "IAB3-12" },
      { title: "Cable Companies", value: "IAB3-13" },
      { title: "Internet/Broadband", value: "IAB3-14" },
      { title: "Phone/Services", value: "IAB3-15" },
      { title: "Storage Facilities", value: "IAB3-16" },
      { title: "Transportation", value: "IAB3-17" },
      { title: "Conference/Events/Seminars", value: "IAB3-18" },
      { title: "Convenience / Gas", value: "IAB3-19" },
      { title: "Laundry / dry cleaning", value: "IAB3-20" },
      { title: "Mortuaries", value: "IAB3-21" },
      { title: "Utility Companies", value: "IAB3-22" },
      { title: "Equipment Rentals", value: "IAB3-23" },
      { title: "Record Labels", value: "IAB3-24" },
      { title: "Attorney", value: "IAB3-25" },
    ],
  },
  {
    title: "Careers",
    value: "IAB4",
    children: [
      { title: "Career Planning", value: "IAB4-1" },
      { title: "College", value: "IAB4-2" },
      { title: "Financial Aid", value: "IAB4-3" },
      { title: "Job Fairs", value: "IAB4-4" },
      { title: "Job Search", value: "IAB4-5" },
      { title: "Resume Writing/Advice", value: "IAB4-6" },
      { title: "Nursing", value: "IAB4-7" },
      { title: "Scholarships", value: "IAB4-8" },
      { title: "Telecommuting", value: "IAB4-9" },
      { title: "U.S. Military", value: "IAB4-10" },
      { title: "Career Advice", value: "IAB4-11" },
      { title: "Employment Agencies", value: "IAB4-12" },
    ],
  },
  {
    title: "Education",
    value: "IAB5",
    children: [
      { title: "7-12 Education", value: "IAB5-1" },
      { title: "Adult Education", value: "IAB5-2" },
      { title: "Art History", value: "IAB5-3" },
      { title: "Colledge Administration", value: "IAB5-4" },
      { title: "College Life", value: "IAB5-5" },
      { title: "Distance Learning", value: "IAB5-6" },
      { title: "English as a 2nd Language", value: "IAB5-7" },
      { title: "Language Learning", value: "IAB5-8" },
      { title: "Graduate School", value: "IAB5-9" },
      { title: "Homeschooling", value: "IAB5-10" },
      { title: "Homework/Study Tips", value: "IAB5-11" },
      { title: "K-6 Educators", value: "IAB5-12" },
      { title: "Private School", value: "IAB5-13" },
      { title: "Special Education", value: "IAB5-14" },
      { title: "Studying Business", value: "IAB5-15" },
    ],
  },
  {
    title: "Family & Parenting",
    value: "IAB6",
    children: [
      { title: "Adoption", value: "IAB6-1" },
      { title: "Babies & Toddlers", value: "IAB6-2" },
      { title: "Daycare/Pre School", value: "IAB6-3" },
      { title: "Family Internet", value: "IAB6-4" },
      { title: "Parenting - K-6 Kids", value: "IAB6-5" },
      { title: "Parenting teens", value: "IAB6-6" },
      { title: "Pregnancy", value: "IAB6-7" },
      { title: "Special Needs Kids", value: "IAB6-8" },
      { title: "Eldercare", value: "IAB6-9" },
    ],
  },
  {
    title: "Health & Fitness",
    value: "IAB7",
    children: [
      { title: "Exercise", value: "IAB7-1" },
      { title: "A.D.D.", value: "IAB7-2" },
      { title: "AIDS/HIV", value: "IAB7-3" },
      { title: "Allergies", value: "IAB7-4" },
      { title: "Alternative Medicine", value: "IAB7-5" },
      { title: "Arthritis", value: "IAB7-6" },
      { title: "Asthma", value: "IAB7-7" },
      { title: "Autism/PDD", value: "IAB7-8" },
      { title: "Bipolar Disorder", value: "IAB7-9" },
      { title: "Brain Tumor", value: "IAB7-10" },
      { title: "Cancer", value: "IAB7-11" },
      { title: "Cholesterol", value: "IAB7-12" },
      { title: "Chronic Fatigue Syndrome", value: "IAB7-13" },
      { title: "Chronic Pain", value: "IAB7-14" },
      { title: "Cold & Flu", value: "IAB7-15" },
      { title: "Deafness", value: "IAB7-16" },
      { title: "Dental Care", value: "IAB7-17" },
      { title: "Depression", value: "IAB7-18" },
      { title: "Dermatology", value: "IAB7-19" },
      { title: "Diabetes", value: "IAB7-20" },
      { title: "Epilepsy", value: "IAB7-21" },
      { title: "GERD/Acid Reflux", value: "IAB7-22" },
      { title: "Headaches/Migraines", value: "IAB7-23" },
      { title: "Heart Disease", value: "IAB7-24" },
      { title: "Herbs for Health", value: "IAB7-25" },
      { title: "Holistic Healing", value: "IAB7-26" },
      { title: "IBS/Crohn's Disease", value: "IAB7-27" },
      { title: "Incest/Abuse Support", value: "IAB7-28" },
      { title: "Incontinence", value: "IAB7-29" },
      { title: "Infertility", value: "IAB7-30" },
      { title: "Men's Health", value: "IAB7-31" },
      { title: "Nutrition", value: "IAB7-32" },
      { title: "Orthopedics", value: "IAB7-33" },
      { title: "Panic/Anxiety Disorders", value: "IAB7-34" },
      { title: "Pediatrics", value: "IAB7-35" },
      { title: "Physical Therapy", value: "IAB7-36" },
      { title: "Psychology/Psychiatry", value: "IAB7-37" },
      { title: "Senior Health", value: "IAB7-38" },
      { title: "Sexuality", value: "IAB7-39" },
      { title: "Sleep Disorders", value: "IAB7-40" },
      { title: "Smoking Cessation", value: "IAB7-41" },
      { title: "Substance Abuse", value: "IAB7-42" },
      { title: "Thyroid Disease", value: "IAB7-43" },
      { title: "Weight Loss", value: "IAB7-44" },
      { title: "Women's Health", value: "IAB7-45" },
      { title: "Cosmetic Surgery", value: "IAB7-46" },
      { title: "Laser Eye Care", value: "IAB7-47" },
      { title: "Optical/Eye Care", value: "IAB7-48" },
      { title: "Physicians / Physician Services", value: "IAB7-49" },
      { title: "Vitamins / Supplements", value: "IAB7-50" },
      { title: "Pharmacy", value: "IAB7-51" },
      { title: "Hospitals", value: "IAB7-52" },
      { title: "Clinical Research", value: "IAB7-53" },
      { title: "Chiropractic", value: "IAB7-54" },
    ],
  },

  {
    title: "Food & Drink",
    value: "IAB8",
    children: [
      { title: "American Cuisine", value: "IAB8-1" },
      { title: "Barbecues & Grilling", value: "IAB8-2" },
      { title: "Cajun/Creole", value: "IAB8-3" },
      { title: "Chinese Cuisine", value: "IAB8-4" },
      { title: "Cocktails/Beer", value: "IAB8-5" },
      { title: "Coffee/Tea", value: "IAB8-6" },
      { title: "Cuisine-Specific", value: "IAB8-7" },
      { title: "Desserts & Baking", value: "IAB8-8" },
      { title: "Dining Out", value: "IAB8-9" },
      { title: "Food Allergies", value: "IAB8-10" },
      { title: "French Cuisine", value: "IAB8-11" },
      { title: "Health/Lowfat Cooking", value: "IAB8-12" },
      { title: "Italian Cuisine", value: "IAB8-13" },
      { title: "Japanese Cuisine", value: "IAB8-14" },
      { title: "Mexican Cuisine", value: "IAB8-15" },
      { title: "Vegan", value: "IAB8-16" },
      { title: "Vegetarian", value: "IAB8-17" },
      { title: "Wine", value: "IAB8-18" },
      { title: "Beverages", value: "IAB8-19" },
      { title: "Catering", value: "IAB8-20" },
      { title: "Fast Food", value: "IAB8-21" },
      { title: "Pizza", value: "IAB8-22" },
      { title: "Night Clubs/Bars", value: "IAB8-23" },
    ],
  },
  {
    title: "Hobbies & Interests",
    value: "IAB9",
    children: [
      { title: "Art/Technology", value: "IAB9-1" },
      { title: "Arts & Crafts", value: "IAB9-2" },
      { title: "Beadwork", value: "IAB9-3" },
      { title: "Birdwatching", value: "IAB9-4" },
      { title: "Board Games/Puzzles", value: "IAB9-5" },
      { title: "Candle & Soap Making", value: "IAB9-6" },
      { title: "Card Games", value: "IAB9-7" },
      { title: "Chess", value: "IAB9-8" },
      { title: "Cigars", value: "IAB9-9" },
      { title: "Collecting", value: "IAB9-10" },
      { title: "Comic Books", value: "IAB9-11" },
      { title: "Drawing/Sketching", value: "IAB9-12" },
      { title: "Freelance Writing", value: "IAB9-13" },
      { title: "Genealogy", value: "IAB9-14" },
      { title: "Getting Published", value: "IAB9-15" },
      { title: "Guitar", value: "IAB9-16" },
      { title: "Home Recording", value: "IAB9-17" },
      { title: "Investors & Patents", value: "IAB9-18" },
      { title: "Jewelry Making", value: "IAB9-19" },
      { title: "Magic & Illusion", value: "IAB9-20" },
      { title: "Needlework", value: "IAB9-21" },
      { title: "Painting", value: "IAB9-22" },
      { title: "Photography", value: "IAB9-23" },
      { title: "Radio", value: "IAB9-24" },
      { title: "Roleplaying Games", value: "IAB9-25" },
      { title: "Sci-Fi & Fantasy", value: "IAB9-26" },
      { title: "Scrapbooking", value: "IAB9-27" },
      { title: "Screenwriting", value: "IAB9-28" },
      { title: "Stamps & Coins", value: "IAB9-29" },
      { title: "Video & Computer Games", value: "IAB9-30" },
      { title: "Woodworking", value: "IAB9-31" },
      { title: "Fire Arms/Gun Shows", value: "IAB9-32" },
    ],
  },
  {
    title: "Home & Garden",
    value: "IAB10",
    children: [
      { title: "Appliances", value: "IAB10-1" },
      { title: "Entertaining", value: "IAB10-2" },
      { title: "Environmental Safety", value: "IAB10-3" },
      { title: "Gardening", value: "IAB10-4" },
      { title: "Home Repair", value: "IAB10-5" },
      { title: "Home Theater", value: "IAB10-6" },
      { title: "Interior Decorating", value: "IAB10-7" },
      { title: "Landscaping", value: "IAB10-8" },
      { title: "Remodeling & Construction", value: "IAB10-9" },
      { title: "Furniture Outdoor", value: "IAB10-10" },
      { title: "Pool Supplies", value: "IAB10-11" },
      { title: "Security Systems", value: "IAB10-12" },
      { title: "Windows", value: "IAB10-13" },
      { title: "HVAC", value: "IAB10-14" },
      { title: "Pest Control/Bug Extermination", value: "IAB10-15" },
      { title: "Hot Tubs / Spas ", value: "IAB10-16" },
      { title: "Home Utilities", value: "IAB10-17" },
      { title: "Flooring / Carpet / Rug", value: "IAB10-18" },
      { title: "Cleaning Services", value: "IAB10-19" },
      { title: "Household Cleaners", value: "IAB10-20" },
      { title: "Appliance Repair", value: "IAB10-21" },
      { title: "Plumbing", value: "IAB10-22" },
      { title: "Matress", value: "IAB10-23" },
    ],
  },
  {
    title: "Law, Gov't & Politics",
    value: "IAB11",
    children: [
      { title: "Immigration", value: "IAB11-1" },
      { title: "Legal Issues", value: "IAB11-2" },
      { title: "U.S. Government Resources", value: "IAB11-3" },
      { title: "Politics", value: "IAB11-4" },
      { title: "Commentary", value: "IAB11-5" },
      { title: "Civic Organizations", value: "IAB11-6" },
      { title: "Non Profit", value: "IAB11-7" },
      { title: "Ecology", value: "IAB11-8" },
    ],
  },
  {
    title: "News",
    value: "IAB12",
    children: [
      { title: "International News", value: "IAB12-1" },
      { title: "National News", value: "IAB12-2" },
      { title: "Local News", value: "IAB12-3" },
      { title: "Newspapers", value: "IAB12-4" },
    ],
  },
  {
    title: "Personal Finance",
    value: "IAB13",
    children: [
      { title: "Beginning Investing", value: "IAB13-1" },
      { title: "Credit/Debt & Loans", value: "IAB13-2" },
      { title: "Financial News", value: "IAB13-3" },
      { title: "Financial Planning", value: "IAB13-4" },
      { title: "Hedge Fund", value: "IAB13-5" },
      { title: "Insurance", value: "IAB13-6" },
      { title: "Investing", value: "IAB13-7" },
      { title: "Mutual Funds", value: "IAB13-8" },
      { title: "Options", value: "IAB13-9" },
      { title: "Retirement Planning", value: "IAB13-10" },
      { title: "Stocks", value: "IAB13-11" },
      { title: "Tax Planning", value: "IAB13-12" },
      { title: "Banks", value: "IAB13-13" },
    ],
  },
  {
    title: "Society",
    value: "IAB14",
    children: [
      { title: "Dating", value: "IAB14-1" },
      { title: "Divorce Support", value: "IAB14-2" },
      { title: "Gay Life", value: "IAB14-3" },
      { title: "Marriage", value: "IAB14-4" },
      { title: "Senior Living", value: "IAB14-5" },
      { title: "Teens", value: "IAB14-6" },
      { title: "Weddings", value: "IAB14-7" },
      { title: "Ethnic Specific", value: "IAB14-8" },
      { title: "Cemetary/Memorial", value: "IAB14-9" },
      { title: "Donation/Charity", value: "IAB14-10" },
    ],
  },
  {
    title: "Science",
    value: "IAB15",
    children: [
      { title: "Astrology", value: "IAB15-1" },
      { title: "Biology", value: "IAB15-2" },
      { title: "Chemistry", value: "IAB15-3" },
      { title: "Geology", value: "IAB15-4" },
      { title: "Paranormal Phenomena", value: "IAB15-5" },
      { title: "Physics", value: "IAB15-6" },
      { title: "Space/Astronomy", value: "IAB15-7" },
      { title: "Geography", value: "IAB15-8" },
      { title: "Botany", value: "IAB15-9" },
      { title: "Weather", value: "IAB15-10" },
    ],
  },
  {
    title: "Pets",
    value: "IAB16",
    children: [
      { title: "Aquariums", value: "IAB16-1" },
      { title: "Birds", value: "IAB16-2" },
      { title: "Cats", value: "IAB16-3" },
      { title: "Dogs", value: "IAB16-4" },
      { title: "Large Animals", value: "IAB16-5" },
      { title: "Reptiles", value: "IAB16-6" },
      { title: "Veterinary Medicine", value: "IAB16-7" },
    ],
  },
  {
    title: "Sports",
    value: "IAB17",
    children: [
      { title: "Auto Racing", value: "IAB17-1" },
      { title: "Baseball", value: "IAB17-2" },
      { title: "Bicycling", value: "IAB17-3" },
      { title: "Bodybuilding", value: "IAB17-4" },
      { title: "Boxing", value: "IAB17-5" },
      { title: "Canoeing/Kayaking", value: "IAB17-6" },
      { title: "Cheerleading", value: "IAB17-7" },
      { title: "Climbing", value: "IAB17-8" },
      { title: "Cricket", value: "IAB17-9" },
      { title: "Figure Skating", value: "IAB17-10" },
      { title: "Fly Fishing", value: "IAB17-11" },
      { title: "Football", value: "IAB17-12" },
      { title: "Freshwater Fishing", value: "IAB17-13" },
      { title: "Game & Fish", value: "IAB17-14" },
      { title: "Golf", value: "IAB17-15" },
      { title: "Horse Racing", value: "IAB17-16" },
      { title: "Horses", value: "IAB17-17" },
      { title: "Hunting/Shooting", value: "IAB17-18" },
      { title: "Inline Skating", value: "IAB17-19" },
      { title: "Martial Arts", value: "IAB17-20" },
      { title: "Mountain Biking", value: "IAB17-21" },
      { title: "NASCAR Racing", value: "IAB17-22" },
      { title: "Olympics", value: "IAB17-23" },
      { title: "Paintball", value: "IAB17-24" },
      { title: "Power & Motorcycles", value: "IAB17-25" },
      { title: "Pro Basketball", value: "IAB17-26" },
      { title: "Pro Ice Hockey", value: "IAB17-27" },
      { title: "Rodeo", value: "IAB17-28" },
      { title: "Rugby", value: "IAB17-29" },
      { title: "Running/Jogging", value: "IAB17-30" },
      { title: "Sailing", value: "IAB17-31" },
      { title: "Saltwater Fishing", value: "IAB17-32" },
      { title: "Scuba Diving", value: "IAB17-33" },
      { title: "Skateboarding", value: "IAB17-34" },
      { title: "Skiing", value: "IAB17-35" },
      { title: "Snowboarding", value: "IAB17-36" },
      { title: "Surfing/Bodyboarding", value: "IAB17-37" },
      { title: "Swimming", value: "IAB17-38" },
      { title: "Table Tennis/Ping-Pong", value: "IAB17-39" },
      { title: "Tennis", value: "IAB17-40" },
      { title: "Volleyball", value: "IAB17-41" },
      { title: "Walking", value: "IAB17-42" },
      { title: "Waterski/Wakeboard", value: "IAB17-43" },
      { title: "World Soccer", value: "IAB17-44" },
      { title: "Fitness", value: "IAB17-45" },
    ],
  },
  {
    title: "Style & Fashion",
    value: "IAB18",
    children: [
      { title: "Beauty", value: "IAB18-1" },
      { title: "Body Art", value: "IAB18-2" },
      { title: "Fashion", value: "IAB18-3" },
      { title: "Jewelry", value: "IAB18-4" },
      { title: "Clothing", value: "IAB18-5" },
      { title: "Accessories", value: "IAB18-6" },
      { title: "Shoes/Boots", value: "IAB18-7" },
      { title: "Hair Loss", value: "IAB18-8" },
      { title: "Tanning", value: "IAB18-9" },
    ],
  },
  {
    title: "Technology & Computing",
    value: "IAB19",
    children: [
      { title: "3-D Graphics", value: "IAB19-1" },
      { title: "Animation", value: "IAB19-2" },
      { title: "Antivirus Software", value: "IAB19-3" },
      { title: "C/C++", value: "IAB19-4" },
      { title: "Cameras & Camcorders", value: "IAB19-5" },
      { title: "Cell Phones", value: "IAB19-6" },
      { title: "Computer Certification", value: "IAB19-7" },
      { title: "Computer Networking", value: "IAB19-8" },
      { title: "Computer Peripherals", value: "IAB19-9" },
      { title: "Computer Reviews", value: "IAB19-10" },
      { title: "Data Centers", value: "IAB19-11" },
      { title: "Databases", value: "IAB19-12" },
      { title: "Desktop Publishing", value: "IAB19-13" },
      { title: "Desktop Video", value: "IAB19-14" },
      { title: "Email", value: "IAB19-15" },
      { title: "Graphics Software", value: "IAB19-16" },
      { title: "Home Video/DVD", value: "IAB19-17" },
      { title: "Internet Technology", value: "IAB19-18" },
      { title: "Java", value: "IAB19-19" },
      { title: "JavaScript", value: "IAB19-20" },
      { title: "Mac Support", value: "IAB19-21" },
      { title: "MP3/MIDI", value: "IAB19-22" },
      { title: "Net Conferencing", value: "IAB19-23" },
      { title: "Net for Beginners", value: "IAB19-24" },
      { title: "Network Security", value: "IAB19-25" },
      { title: "Palmtops/PDAs", value: "IAB19-26" },
      { title: "PC Support", value: "IAB19-27" },
      { title: "Portable", value: "IAB19-28" },
      { title: "Entertainment", value: "IAB19-29" },
      { title: "Shareware/Freeware", value: "IAB19-30" },
      { title: "Unix", value: "IAB19-31" },
      { title: "Visual Basic", value: "IAB19-32" },
      { title: "Web Clip Art", value: "IAB19-33" },
      { title: "Web Design/HTML", value: "IAB19-34" },
      { title: "Web Search", value: "IAB19-35" },
      { title: "Windows", value: "IAB19-36" },
      { title: "Computers", value: "IAB19-37" },
      { title: "Stereo/TV", value: "IAB19-38" },
      { title: "Other Software", value: "IAB19-39" },
    ],
  },
  {
    title: "Travel",
    value: "IAB20",
    children: [
      { title: "Adventure Travel", value: "IAB20-1" },
      { title: "Africa", value: "IAB20-2" },
      { title: "Air Travel", value: "IAB20-3" },
      { title: "Australia & New Zealand", value: "IAB20-4" },
      { title: "Bed & Breakfasts", value: "IAB20-5" },
      { title: "Budget Travel", value: "IAB20-6" },
      { title: "Business Travel", value: "IAB20-7" },
      { title: "By US Locale", value: "IAB20-8" },
      { title: "Camping", value: "IAB20-9" },
      { title: "Canada", value: "IAB20-10" },
      { title: "Caribbean", value: "IAB20-11" },
      { title: "Cruises", value: "IAB20-12" },
      { title: "Eastern Europe", value: "IAB20-13" },
      { title: "Europe", value: "IAB20-14" },
      { title: "France", value: "IAB20-15" },
      { title: "Greece", value: "IAB20-16" },
      { title: "Honeymoons/Getaways", value: "IAB20-17" },
      { title: "Hotels", value: "IAB20-18" },
      { title: "Italy", value: "IAB20-19" },
      { title: "Japan", value: "IAB20-20" },
      { title: "Mexico & Central America", value: "IAB20-21" },
      { title: "National Parks", value: "IAB20-22" },
      { title: "South America", value: "IAB20-23" },
      { title: "Spas", value: "IAB20-24" },
      { title: "Theme Parks", value: "IAB20-25" },
      { title: "Traveling with Kids", value: "IAB20-26" },
      { title: "United Kingdom", value: "IAB20-27" },
    ],
  },
  {
    title: "Real Estate",
    value: "IAB21",
    children: [
      { title: "Apartments", value: "IAB21-1" },
      { title: "Architects", value: "IAB21-2" },
      { title: "Buying/Selling Homes", value: "IAB21-3" },
      { title: "Timeshare", value: "IAB21-4" },
    ],
  },
  {
    title: "Shopping",
    value: "IAB22",
    children: [
      { title: "Contests & Freebies", value: "IAB22-1" },
      { title: "Couponing", value: "IAB22-2" },
      { title: "Comparison", value: "IAB22-3" },
      { title: "Engines", value: "IAB22-4" },
      { title: "Auctions", value: "IAB22-5" },
      { title: "Cellular Stores", value: "IAB22-6" },
      { title: "Grocery Stores", value: "IAB22-7" },
      { title: "Malls", value: "IAB22-8" },
      { title: "Department Store", value: "IAB22-9" },
      { title: "Fairs/Farmers Markets", value: "IAB22-10" },
      { title: "Antiques", value: "IAB22-11" },
      { title: "Office supplies", value: "IAB22-12" },
      { title: "Pawnshop", value: "IAB22-13" },
      { title: "Music Stores", value: "IAB22-14" },
      { title: "Toys", value: "IAB22-15" },
      { title: "Fireworks", value: "IAB22-16" },
      { title: "Florist/Balloons", value: "IAB22-17" },
    ],
  },
  {
    title: "Religion & Spirituality",
    value: "IAB23",
    children: [
      { title: "Alternative Religions", value: "IAB23-1" },
      { title: "Atheism/Agnosticism", value: "IAB23-2" },
      { title: "Buddhism", value: "IAB23-3" },
      { title: "Catholicism", value: "IAB23-4" },
      { title: "Christianity", value: "IAB23-5" },
      { title: "Hinduism", value: "IAB23-6" },
      { title: "Islam", value: "IAB23-7" },
      { title: "Judaism", value: "IAB23-8" },
      { title: "Latter-Day Saints", value: "IAB23-9" },
      { title: "Pagan/Wiccan", value: "IAB23-10" },
      { title: "Psychics", value: "IAB23-11" },
    ],
  },
  {
    title: "Uncategorized",
    value: "IAB24",
    children: [],
  },
  {
    title: "Non-Standard Content",
    value: "IAB25",
    children: [
      { title: "Unmoderated UGC", value: "IAB25-1" },
      { title: "Extreme Graphic/Explicit Violence", value: "IAB25-2" },
      { title: "Pornography", value: "IAB25-3" },
      { title: "Profane Content", value: "IAB25-4" },
      { title: "Hate Content", value: "IAB25-5" },
      { title: "Under Construction", value: "IAB25-6" },
      { title: "Incentivized", value: "IAB25-7" },
    ],
  },
  {
    title: "Illegal Content",
    value: "IAB26",
    children: [
      { title: "Illegal Content", value: "IAB26-1" },
      { title: "Warez", value: "IAB26-2" },
      { title: "Spyware/Malware", value: "IAB26-3" },
      { title: "Copyright Infringement", value: "IAB26-4" },
    ],
  },
];

export default daastAdCategories;
