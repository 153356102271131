import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "src/components/lib/private_route";
import AdPlatformPage from "src/components/pages/ad_platform/ad_platform_page";
import ShowAdvertisingPage from "src/components/pages/ad_platform/show_advertising_page";
import ShowCampaignPage from "src/components/pages/ad_platform/show_campaign_page";
import { permissionTypes } from "src/constants/permission_roles";

export default function AdPlatformRoutes() {
  return (
    <Switch>
      <PrivateRoute
        path="/ad-platform/:showUUID/campaigns/:campaignUUID"
        permissionType={permissionTypes.viewRap}
        paramKey={"showUUID"}
        exact>
        <ShowCampaignPage />
      </PrivateRoute>

      <PrivateRoute
        path={["/ad-platform/:showUUID", "/ad-platform/:showUUID/:modifier/:modifierUUID"]}
        permissionType={permissionTypes.viewRap}
        paramKey={"showUUID"}
        exact>
        <ShowAdvertisingPage />
      </PrivateRoute>

      <Route path="/ad-platform" exact component={AdPlatformPage} />
    </Switch>
  );
}
