import get from "lodash/get";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchPublicShow } from "../../../action_managers/public_show";
import { AlbumArt } from "../../lib/album_art";
import Divider from "../../lib/divider";
import ExternalLink from "../../lib/external_link";
import LoggedOutFrame from "../../lib/logged_out_frame";

class TipSuccess extends Component {
  componentDidMount() {
    this.props.fetchPublicShow(this.getShowUUID());
  }

  getShowUUID = () => {
    return this.props.match.params.showUUID;
  };

  getShow = () => {
    return get(this.props.publicShows, this.getShowUUID(), {});
  };

  getShareComponents = () => {
    return {
      pageURL: encodeURIComponent("https://redcircle.com/shows/" + this.getShowUUID()),
      homepage: encodeURIComponent("https://redcircle.com"),
      message: encodeURIComponent(
        `I just supported the podcast "${this.getShow().title}" by donating on RedCircle!`
      ),
      hashtag: encodeURIComponent("BeHeard"),
    };
  };

  getFacebookLink = () => {
    let components = this.getShareComponents();
    return `https://www.facebook.com/dialog/share?app_id=381472019069731&display=page&href=${components.pageURL}&redirect_uri=${components.homepage}&quote=${components.message}&hashtag=%23${components.hashtag}`;
  };

  getTwitterLink = () => {
    let components = this.getShareComponents();
    return `https://twitter.com/intent/tweet?text=${components.message}&url=${components.pageURL}&hashtags=${components.hashtag}&via=getredcircle`;
  };

  render() {
    return (
      <LoggedOutFrame
        hideSignIn={true}
        hideSignUp={true}
        className="tip-page-frame text-center"
        noBar={true}>
        <Row>
          <Col xs={12} md={2}>
            <AlbumArt src={this.getShow().imageURL} />
          </Col>
          <Col xs={12} mdOffset={1} md={9} className="text-center">
            <h2>Thanks For Tipping</h2>
            <div className={"m-ym"}>
              Your support is appreciated by {get(this.getShow(), "ownerName")}! You'll receive a
              receipt via email shortly.
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} mdOffset={3} md={9}>
            <Row>
              <Col xs={12} md={6} mdOffset={3}>
                <h4 className="m-yl">Share the Love</h4>
                <Divider smallMargin />
              </Col>
              <Col xsOffset={3} xs={3} className="text-center">
                <ExternalLink href={this.getFacebookLink()}>Facebook</ExternalLink>
              </Col>
              <Col xs={3} className="text-center">
                <ExternalLink href={this.getTwitterLink()}>Twitter</ExternalLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </LoggedOutFrame>
    );
  }
}

function mapStateToProps(state) {
  return { publicShows: state.publicShows, user: get(state, "user.user") };
}

export default connect(mapStateToProps, { fetchPublicShow })(TipSuccess);
