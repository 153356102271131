import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { TIER_LEVEL_ANNUAL } from "../../../constants/tiers";
import { useSelectorTS } from "../../../hooks/redux-ts";
import { formatMoney } from "../../../lib/format-money";
import Divider from "../../lib/divider";
import AddCardForm from "../../lib/stripe_form/add_card_form";
import StripeElements from "../../lib/stripe_form/stripe_elements";
import { UpdateTierNavBar, useHideSidebar, useHideWarningbar, useUpdateTier } from "./tier_utils";

export const UpdateTierPayment = () => {
  useHideSidebar();
  useHideWarningbar();
  const user = useSelectorTS((state) => state.user.user);
  const updateTier = useUpdateTier();
  const userTier = useSelectorTS((state) => state.user.tier);
  const cardSubmit = async (card: any) => {
    updateTier(card?.card?.id, userTier?.interval === TIER_LEVEL_ANNUAL, userTier.level);
  };
  return (
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <UpdateTierNavBar signOutOnly={true} />
        </Col>
      </Row>
      <Row className={"m-txl"}>
        <Col xs={8} xsOffset={2} mdOffset={3} md={6} lg={4} lgOffset={4}>
          <h2>Card Information</h2>
          <div>Add your payment info for your RedCircle Enterprise Plan</div>
          <Divider />
          <div className={"bg-offwhite p-as"}>
            <p>Below are the details of your new plan which is effective immediately:</p>
            <p>
              <span className={"bold m-bxs"}>Account Plan: </span>RedCircle Enterprise Plan
            </p>
            <p>
              <span className={"bold"}>Plan Details: </span>
              {formatMoney(userTier?.amount)}/{userTier?.interval === "annual" ? "year" : "month"}
            </p>
          </div>

          <StripeElements>
            <AddCardForm canMakePayment={false} onSubmit={cardSubmit} user={user} />
          </StripeElements>
        </Col>
      </Row>
    </Grid>
  );
};
