import { v4 as uuid4 } from "uuid";
import { v5 as uuid } from "uuid";
import { seededUUIDNamespace } from "../constants/uuid";

export const getSubscriptionUUID = (userUUID, showUUID) =>
  uuid(userUUID + showUUID, seededUUIDNamespace);

export const isUUID = (string) =>
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(string);

export const newUUID = () => uuid4();
