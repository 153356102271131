import {
  SummaryPageInfo,
  SummaryPageSectionWrapper,
} from "src/components/lib/summary-page-sections/summary-page-sections";
import { redemptionCodeValueToDisplay } from "src/components/modals/campaign_editor/campaign_editor_helpers";
import {
  ProductExchangeTypeInstructions,
  ProductExchangeTypeMailing,
} from "src/constants/campaigns";
import { ICampaign } from "redcircle-types";

export default function ShowCampaignProductSamples({ campaign }: { campaign: ICampaign }) {
  return (
    <SummaryPageSectionWrapper title={"Product Samples"}>
      <>
        <SummaryPageInfo
          noColon
          title={"Free product samples?"}
          text={
            redemptionCodeValueToDisplay[
              campaign.productExchangeType as keyof typeof redemptionCodeValueToDisplay
            ]
          }
          newline
        />
        {(campaign.productExchangeType === ProductExchangeTypeMailing ||
          campaign.productExchangeType === ProductExchangeTypeInstructions) && (
          <>
            <SummaryPageInfo
              title={"Redemption Instructions*"}
              dangerouslySetInnerHTML={campaign.productExchangeInstructions}
              newline
            />
            {campaign.redemptionCodes && (
              <SummaryPageInfo
                title={"Number of Redemption Codes Uploaded*"}
                text={`${Object.keys(campaign.redemptionCodes).length}`}
              />
            )}
          </>
        )}
      </>
    </SummaryPageSectionWrapper>
  );
}
