import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import UserRoles from "../../../constants/roles";

class HomeRedirect extends Component {
  render() {
    return (
      <div>
        {this.props.currentRole === UserRoles.Creator && <Redirect to="/shows" />}
        {this.props.currentRole === UserRoles.Advertiser && <Redirect to="/campaigns" />}
        {this.props.currentRole === UserRoles.Sponsor && <Redirect to="/sponsor-show-list" />}
      </div>
    );
  }
}

export default HomeRedirect;
