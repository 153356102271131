import DefaultActionManager from "../action_managers/default_action_manager";

export const GET_EPISODE = "GET_EPISODE";

export const getEpisode = (episodeUUID) => {
  return new DefaultActionManager({
    auth: true,
    method: "get",
    route: `episodes/${episodeUUID}`,
    actionName: GET_EPISODE,
  }).run();
};
