import moment from "moment";
import React from "react";
import { If } from "react-extras";
import { useHistory } from "react-router-dom";
import { gray1 } from "../../../constants/css";
import { permissionTypes } from "../../../constants/permission_roles";
import { useSelectorTS } from "../../../hooks/redux-ts";
import InitialsCircle from "../../../lib/initials_circle";
import { useCanAccess } from "../../../lib/permissions";
import ContextMenu from "../../lib/context_menu";
import ExclusiveTag from "../../lib/exclusive_tag";
import { accountPlanCopy } from "./account_plan_utils";

const AccountPlanCurrentPlan = () => {
  const tier = useSelectorTS((state) => state.user.tier);
  const paymentForTier = useSelectorTS((state) => state.payments.userTierPayment);
  const history = useHistory();
  const isOwner = useCanAccess(permissionTypes.updateBilling);
  return (
    accountPlanCopy
      .filter(({ level }) => level === tier.level)
      .map(({ title, level, desc, showUpdatePlan }) => {
        return (
          <div
            key={level}
            className={"p-as m-bs flex-row-container align-center"}
            style={{ background: gray1, border: `1px solid #ebe8e8` }}>
            <InitialsCircle />
            <div className={"flex-1"}>
              <div className="flex-row-container align-center">
                <h4>{title}</h4>
                <ExclusiveTag className="m-lxs">Current Plan</ExclusiveTag>
              </div>
              <p className={"m-bxxs"}>{desc(tier)}</p>
              <If condition={Number(paymentForTier?.nextPaymentAt) > 0 && isOwner}>
                <small>
                  Your next payment is due on{" "}
                  {moment.unix(paymentForTier?.nextPaymentAt ?? 0).format("M/D/YY")}
                </small>
              </If>
            </div>
            <div>
              <If condition={!!showUpdatePlan && isOwner}>
                <ContextMenu
                  noCircle={true}
                  menuItems={{
                    "Update Plan": {
                      onSelect: () => {
                        history.push(`/account/update/${level}?interval=${tier.interval}`);
                      },
                    },
                  }}
                />
              </If>
            </div>
          </div>
        );
      })[0] ?? null
  );
};

export default AccountPlanCurrentPlan;
