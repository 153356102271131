import { Form, InputNumber, Popover } from "antd";
import { ForwardedRef, forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import { formatMoney } from "redcircle-lib";
import { Button, COLORS, Modal } from "redcircle-ui";
import { CampaignItemStateDraft } from "src/constants/campaigns";
import { useSelectorTS } from "src/hooks/redux-ts";
import { getAverageCPM, getBudget } from "src/lib/campaigns";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

export default function SchedulerMatchBudget({ className }: { className?: string }) {
  const [showPopover, setShowPopover] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { campaign, campaignItems, budgets, setBudgets, maxImpressionsState } =
    useContext(CampaignSchedulerContext);
  const publicShows = useSelectorTS((state) => state.publicShows);
  const totalBudget = Object.values(budgets ?? {}).reduce((accu, curr) => accu + curr, 0) ?? 0;
  const draftItems = campaignItems.filter((item) => item.state === CampaignItemStateDraft);
  const isDisabled = !draftItems || draftItems.length === 0;

  const maxImpressionsByCampaignItemUUID = useMemo(() => {
    const maxImpressions: { [campaignItemUUID: string]: number } = {};
    for (const campaignItem of campaignItems) {
      const show = publicShows[campaignItem.showUUID];
      const cpm = getAverageCPM({ show, campaign, campaignItem });
      const maxImpressionsForShow = maxImpressionsState.maxImpressions?.[campaignItem.showUUID];
      const max =
        maxImpressionsForShow?.impressions > 0
          ? getBudget({ impressions: maxImpressionsForShow.impressions, cpm })
          : 0;
      maxImpressions[campaignItem.uuid] = max;
    }
    return maxImpressions;
  }, [campaignItems, campaign, publicShows, maxImpressionsState]);

  const totalMaxImpressions = Object.values(maxImpressionsByCampaignItemUUID).reduce(
    (accu, curr) => accu + curr,
    0
  );
  const showMaxLimit = campaign?.pacing && !!totalMaxImpressions && totalMaxImpressions > 0;

  // focus on input
  useEffect(() => {
    if (showPopover) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [showPopover]);

  const handleSubmitBudget = (newTotalBudget: number) => {
    setBudgets((prevBudgets) => {
      const newBudgets = { ...prevBudgets };
      const count = Object.keys(newBudgets).length;
      const evenlyDividedBudget = newTotalBudget / count;

      if (campaign?.pacing) {
        for (const uuid in newBudgets) {
          const maxImpressions = maxImpressionsByCampaignItemUUID[uuid];
          const weight = maxImpressions >= 0 ? maxImpressions / totalMaxImpressions : 1 / count;
          newBudgets[uuid] = Math.trunc(weight * newTotalBudget);
        }
      } else {
        Object.keys(newBudgets).forEach((key) => {
          newBudgets[key] = evenlyDividedBudget;
        });
      }

      return newBudgets;
    });
  };

  return (
    <Popover
      open={showPopover}
      content={
        <PopoverContent
          onClose={() => setShowPopover(false)}
          ref={inputRef}
          budget={totalBudget}
          onSubmit={handleSubmitBudget}
          max={showMaxLimit ? totalMaxImpressions : undefined}
        />
      }
      trigger="click"
      onOpenChange={(open) => setShowPopover(open)}
      overlayInnerStyle={{ padding: 0 }}>
      <Button type="secondary-red" size="small" className={className} disabled={isDisabled}>
        Match Budget
      </Button>
    </Popover>
  );
}

const PopoverContent = forwardRef(function PopoverContent(
  {
    onClose,
    onSubmit,
    budget,
    max,
  }: {
    onClose: () => void;
    onSubmit: (value: number) => void;
    budget: number;
    max?: number;
  },
  ref: ForwardedRef<HTMLInputElement>
) {
  const [value, setValue] = useState<number | null>(null);
  const isTooHigh = max && value ? value > max / 100 : false;

  useEffect(() => {
    if (typeof budget === "number") {
      setValue(budget / 100);
    }
  }, [budget]);

  const handleSubmit = () => {
    if (value === null) return;
    onSubmit(Math.round(value * 100));
    onClose();
  };

  return (
    <Form>
      <div className="p-axs flex-column-container">
        <span>
          <h5>Total Budget</h5>
          {max && <span> Estimated Maximum: {formatMoney(max / 100)}</span>}
        </span>
        <div className="flex-row-container align-center m-txxxs">
          <InputNumber
            className="m-txxxs"
            ref={ref}
            value={value}
            onChange={(value) => setValue(value)}
            prefix="$"
            size="large"
            precision={2}
            min={0}
            style={{ width: "100%" }}
            status={isTooHigh ? "warning" : undefined}
          />
          {isTooHigh && (
            <AiFillExclamationCircle className="m-lxxxs" color={COLORS.COLOR_WARNING} />
          )}
        </div>
      </div>
      <Modal.Footer className="p-axs">
        <Button type="link" className="p-a0" size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          size="small"
          className="m-lxxs"
          disabled={value === null}
          onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Form>
  );
});
