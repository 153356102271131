import { Alert, Tooltip } from "antd";
import { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "redcircle-ui";
import { CampaignItemStateDraft } from "src/constants/campaigns";
import { validateCampaign } from "src/lib/validate-campaign";
import { ICampaign } from "redcircle-types";
import { SummaryPage } from "../campaign_editor/summary_page";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

export default function SchedulerSummaryPage() {
  const history = useHistory();
  const { campaign, campaignItems } = useContext(CampaignSchedulerContext);
  const canEditCampaign = campaignItems?.every(
    (campaignItem) => campaignItem?.state === CampaignItemStateDraft
  );

  const handleCampaignEdit = (pageNumber?: number) =>
    campaign ? history.push(`/campaigns/${campaign.uuid}/edit/${pageNumber}`) : undefined;

  return (
    <div className="p-bxs">
      <SummaryPageValidationErrors campaign={campaign} />
      <SummaryPage
        campaign={campaign}
        updatePage={canEditCampaign ? handleCampaignEdit : undefined}
      />
    </div>
  );
}

const SummaryPageValidationErrors = ({ campaign }: { campaign?: ICampaign }) => {
  const [isCampaignComplete, missingFields, fieldNames] = useMemo(() => {
    return validateCampaign(campaign);
  }, [campaign, campaign?.uuid]);

  if (isCampaignComplete) return null;
  return (
    <Alert
      className="m-bxs"
      type="error"
      message={
        <div className="flex-column-container">
          <strong>Your campaign is incomplete. Please fill out the missing fields:</strong>
          <ul className="m-b0">
            {missingFields.map((field) => (
              <li key={field}>{field}</li>
            ))}
          </ul>
        </div>
      }
    />
  );
};

export const SchedulerSummaryPageFooter = ({
  onBack,
  onSubmit,
}: {
  onBack: () => void;
  onSubmit: () => void;
}) => {
  const { campaign } = useContext(CampaignSchedulerContext);
  const [isCampaignComplete] = useMemo(() => {
    return validateCampaign(campaign);
  }, [campaign, campaign?.uuid]);

  return (
    <>
      <Button type="link" size="large" className="p-a0" onClick={onBack}>
        Back
      </Button>
      <Tooltip
        title={
          !isCampaignComplete ? "Please fill out the missing fields on your campaign" : undefined
        }>
        <Button type="primary" size="large" disabled={!isCampaignComplete} onClick={onSubmit}>
          Continue
        </Button>
      </Tooltip>
    </>
  );
};
