import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import { useEffect, useMemo } from "react";
import { shallowEqual } from "react-redux";
import { getCredentialsInOrg } from "../action_managers/credentials";
import { getMyPermissions, getPermissionsInOrg } from "../action_managers/permissions";
import { permissionPresets } from "../constants/permission_roles";
import { useDispatchTS, useSelectorTS } from "./redux-ts";

let initialCredentialFetch = false;

// Hook for grabbing credentials in org (Grabbing all users within an Org),
// Automatically fetches data if not available and exposes a dispatchFunction
export const useCredentialsInOrg = () => {
  const { isLoading, initialFetched, credentials } = useSelectorTS(
    (state) => state?.credentials,
    shallowEqual
  ); // shallowEqual will ensure equality func comapres prop values within Object instead of just strict equality on onjects

  const dispatch = useDispatchTS();
  const getCredentials = () => dispatch(getCredentialsInOrg());

  const credentialsMapByUUID = useMemo(() => {
    return keyBy(credentials, "uuid");
  }, [credentials?.length, credentials]);

  useEffect(() => {
    if (!initialCredentialFetch && !initialFetched && !isLoading) {
      initialCredentialFetch = true;
      getCredentials().catch((err) => {
        // Catch any Errors
        console.log(err);
      });
    }
  }, []);

  return { isLoading, credentials, credentialsMapByUUID, getCredentials };
};

let initialPermissionsFetch = false;

// Hook for grabbing permissions in org (Grabbing all permissions of all users within an Org),
// Automatically fetches data if not available and exposes a dispatchFunction
export const usePermissionsInOrg = () => {
  const { isLoading, initialFetched, permissions } = useSelectorTS(
    (state) => state?.permissions?.allPermissions,
    shallowEqual
  ); // shallowEqual will ensure equality func compares prop values within Object instead of just strict equality on objects

  const dispatch = useDispatchTS();
  const getPermissions = () => dispatch(getPermissionsInOrg());

  const permissionsMapByCred = useMemo(() => {
    return groupBy(permissions, "credentialUUID");
  }, [permissions?.length, permissions]);

  const permissionsMapByUUID = useMemo(() => {
    return keyBy(permissions, "uuid");
  }, [permissions?.length, permissions]);

  const orgOwnerPermission = useMemo(() => {
    return permissions.find(
      (permission) =>
        permission.preset === permissionPresets.orgAdmin && permission.objectType === "org"
    );
  }, [permissions?.length, permissions]);

  const permissionMapByKey = useMemo(() => {
    return keyBy(permissions, "key");
  }, [permissions?.length, permissions]);

  useEffect(() => {
    if (!initialPermissionsFetch && !initialFetched && !isLoading) {
      initialPermissionsFetch = true;
      getPermissions().catch((err) => {
        // Catch any Errors
        console.log(err);
      });
    }
  }, []);

  return {
    isLoading,
    initialFetched,
    permissions,
    permissionsMapByCred,
    permissionsMapByUUID,
    orgOwnerPermission,
    permissionMapByKey,
    getPermissions,
  };
};

let initialMyPermsFetched = false;

/**
 * Only get Current User permissions
 */
export const useGetMyPermissions = () => {
  const { user } = useSelectorTS((state) => state.user);
  const {
    isLoading: isMyPermsLoading,
    initialFetched,
    orgPermission,
    permissionsByCredentialUUID,
    permissionsByKey,
  } = useSelectorTS((state) => state?.permissions, shallowEqual); // shallowEqual will ensure equality func compares prop values within Object instead of just strict equality on objects

  const dispatch = useDispatchTS();

  const userPermissionKeys = permissionsByCredentialUUID?.[orgPermission?.credentialUUID] || [];
  const userPermissions = userPermissionKeys.map((key) => permissionsByKey[key]) ?? [];

  useEffect(() => {
    if (!initialMyPermsFetched && !initialFetched && !isMyPermsLoading && !!user) {
      initialMyPermsFetched = true;
      dispatch(getMyPermissions()).catch((err) => {
        // Catch any Errors
        console.log(err);
      });
    }
  }, [user]);

  return {
    orgPermission,
    userPermissions,
  };
};
