import { Form, Input, Tooltip } from "antd";
import { AiFillInfoCircle } from "react-icons/ai";

export default function CampaignFormDetails() {
  return (
    <div className="flex-column-container">
      <label className="redcircle-form-label fs-11" htmlFor="name">
        Campaign Name*
      </label>
      <Form.Item
        name="name"
        className="flex-column-container"
        rules={[{ required: true, message: "Campaign name is required" }]}>
        <Input placeholder="example: Nike's Q1 Campaign" size="large" />
      </Form.Item>

      <label className="redcircle-form-label fs-11" htmlFor="productName">
        Product or Service Name
      </label>
      <Form.Item name="productName" className="flex-column-container">
        <Input placeholder="example: Nike Air Max" size="large" />
      </Form.Item>

      <div className="flex-row-container align-center">
        <label className="redcircle-form-label fs-11 m-rxxs" htmlFor="referenceNumber">
          Reference Number
        </label>
        <Tooltip title="A reference number can be any text, and will appear on a campaign's invoice to help accounting teams match the invoice to this campaign.">
          <AiFillInfoCircle className="m-bxxs" />
        </Tooltip>
      </div>
      <Form.Item name="referenceNumber" className="flex-column-container">
        <Input placeholder="example: IO #12345" size="large" />
      </Form.Item>
    </div>
  );
}
