import React from "react";
import { Modal } from "redcircle-ui";
import { goToPricingPage } from "../../lib/config";

// TYPES
interface IDeleteTeamSeat {
  text: string;
  visible: boolean; // Parent controlled modal visibility
  setVisible: React.Dispatch<React.SetStateAction<boolean>>; // Parent change function form visibility
}

const UpgradePlanModal = (props: IDeleteTeamSeat) => {
  const { text, visible, setVisible } = props;

  return (
    <Modal
      size="sm"
      open={visible}
      onClose={() => setVisible(false)}
      onSubmit={() => goToPricingPage()}>
      <Modal.Title>Upgrade Your Plan</Modal.Title>

      <Modal.Body>{text}</Modal.Body>

      <Modal.Footer noBorder>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Modal.SubmitButton>Upgrade Plan</Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpgradePlanModal;
