import React, { Component } from "react";
import { If } from "react-extras";
import { Link } from "react-router-dom";
import ContextMenu from "../context_menu";
import "./list_view_row.scss";

class ListViewRow extends Component {
  render() {
    return (
      <div className="list-view-row">
        <Link
          to={this.props.linkTo}
          className="list-view-row__text-wrapper flex-column-container justify-start no-style-link">
          <span className="list-view-row__main-text m-txxs redcircle-big-link bold">
            {this.props.mainText}
          </span>
          <If condition={Boolean(this.props.subText)}>
            <span className="subtext">{this.props.subText}</span>
          </If>
        </Link>
        <If condition={Boolean(this.props.menuItems)}>
          <div>
            <ContextMenu menuItems={this.props.menuItems} noCircle={true} />
          </div>
        </If>
      </div>
    );
  }
}

export default ListViewRow;
