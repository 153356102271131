import { Tooltip } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { CreditCardPaymentMethod, InvoicingPaymentMethod } from "../../../constants/campaigns";
import { formatMoney } from "../../../lib/format-money";
import { svgIcon as Icon } from "../../lib/icon";
import "./payment_confirmation.scss";

const PaymentConfirmation = (props) => {
  const {
    title,
    last4,
    brand,
    amount,
    chargeTypeText,
    additionalInfo,
    paymentMethodType = CreditCardPaymentMethod,
    isRateNegotiated,
  } = props;

  let payment = (
    <>
      <div className="brand">{brand}</div>
      <div className="last4">{last4}</div>
    </>
  );
  if (paymentMethodType === InvoicingPaymentMethod) {
    payment = <div className="brand">Invoice</div>;
  }

  return (
    <>
      <Row>
        <div className={"page-3-container"}>
          <Col xs={10} xsOffset={1}>
            <h3 className={"title-left"}>{title || "Review your purchase"}</h3>
          </Col>
          <Col xs={10} xsOffset={1}>
            <div className="table">
              <div className="table-row flex-row-container">
                <div className={"left-column"}>Payment</div>
                <div className="spacer flex-1" />
                <div className={"right-column bold"}>{payment}</div>
              </div>
              <div className="table-row flex-row-container">
                <div className={"left-column"}>{chargeTypeText}</div>
                {isRateNegotiated && (
                  <div>
                    <Tooltip placement="top" title="You have updated the rate on one or more shows">
                      <Icon
                        name="money-tag"
                        style={{
                          top: ".125em",
                          position: "relative",
                          fill: "#577d9e",
                          height: "16px",
                          width: "16px",
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
                <div className="spacer flex-1" />
                <div className={"right-column amount bold"}>{formatMoney(amount)}</div>
              </div>
            </div>
            {additionalInfo ?? null}
          </Col>
        </div>
      </Row>
    </>
  );
};

export default PaymentConfirmation;
