import openInNew from "@iconify-icons/mdi/open-in-new";
import Icon from "@iconify/react";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import moment from "moment";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { classNames, If } from "react-extras";
import { useSelector } from "react-redux";
import { shouldShowAnalyticsUpgrade } from "src/lib/feature_flag";
import {
  CreatorInsertionCredit,
  PendingInvoiceTransaction,
  PendingInvoiceTransactionState,
  RecurringDonationCredit,
  SubscriptionSurchargeCredit,
  TierCreatorDebit,
  TipCredit,
} from "../../../constants/transactions";
import RedCircleIcon from "../../../icons/redcircle-icon.svg";
import { formatMoney } from "../../../lib/format-money";
import ExclusiveTag from "../exclusive_tag";
import ExternalLink from "../external_link";
import Table from "../tables";
import WarningPanelv2 from "../warning_panel/warning_panel_v2";
import "./transaction_table.scss";

const columnInfo = [
  {
    accessor: "transactionDisplay",
    Cell: function Cell(a) {
      const isPending = a.original?.isPending ?? false;
      const hasInvestigationNotes =
        a.original?.investigationNotes && a.original?.isBeingInvestigated;

      let pendingClasses = classNames({
        "pending-opacity": isPending,
      });

      return (
        <div className="flex-row-container transaction-row">
          <div className="flex-row-container transaction-info">
            <If condition={!!get(a.value, "imageURL")}>
              <img
                src={`${get(a.value, "imageURL")}?d=100x100`}
                className={"album-art " + pendingClasses}
                alt="album-art"
              />
            </If>
            <div className="transaction-display">
              <If condition={!!get(a.value, "title")}>
                <h4 className={"transaction-title ellipsis " + pendingClasses}>
                  {get(a.value, "title", "No Title")}
                </h4>
              </If>
              <p className={"m-a0 ellipsis " + pendingClasses}>
                {a.value.datePrefix}
                {moment(a.value.createdAt, "X").format("MMMM D, YYYY")}
              </p>
              {isPending && shouldShowAnalyticsUpgrade() && (
                <p className={"m-a0 ellipsis " + pendingClasses}>
                  {"Estimated payment by: "}
                  {moment(a.value.createdAt, "X").add(3, "months").format("MMMM D, YYYY")}
                </p>
              )}
              <If condition={hasInvestigationNotes}>
                <WarningPanelv2 className={"investigation-notes"}>
                  <strong>Investigation Details:&nbsp;</strong>
                  <div dangerouslySetInnerHTML={{ __html: a.original?.investigationNotes }}></div>
                </WarningPanelv2>
              </If>
            </div>
          </div>
          {get(a.value, "receiptURL") && (
            <ExternalLink to={a.value.receiptURL} className="transaction-receipt">
              View Receipt <Icon icon={openInNew} />
            </ExternalLink>
          )}
        </div>
      );
    },
  },
  {
    accessor: "amount",
    Cell: (a) => {
      const isPending = a.original?.isPending ?? false;
      let amountClasses = classNames("transaction-amount-column", {
        "pending-opacity": isPending,
      });

      let pendingTagClasses = classNames("m-rs hidden-sm hidden-xs", {
        "tag-under-investigation": a.original.isBeingInvestigated,
      });

      const pendingText = a.original.isBeingInvestigated ? "UNDER INVESTIGATION" : "AWAITING BRAND";

      let pendingHelpText = (
        <>
          Awaiting invoice payment <br /> from the brand
        </>
      );
      if (a.original.isBeingInvestigated && a.original.investigationNotes) {
        pendingHelpText = (
          <div dangerouslySetInnerHTML={{ __html: a.original.investigationNotes }}></div>
        );
      }

      return (
        <div className="flex-row-container">
          <If condition={isPending}>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={
                <Popover id="helper-text">
                  <div className="text-center">{pendingHelpText}</div>
                </Popover>
              }>
              <ExclusiveTag className={pendingTagClasses}>{pendingText}</ExclusiveTag>
            </OverlayTrigger>
          </If>
          <div className={amountClasses}>{formatMoney(a.value / 1000)}</div>
        </div>
      );
    },
  },
];

const TransactionTable = (props) => {
  const { transactions, noDataText } = props;
  const shows = useSelector((state) => state.shows.shows);
  const campaigns = useSelector((state) => state.campaigns.campaigns);
  const manualPayoutsArray = useSelector((state) => state.manualPayouts.manualPayouts);
  const manualPayouts = keyBy(manualPayoutsArray, "uuid");

  if (!transactions) return null;

  const getTitle = (transaction) => {
    let titlePrefix = "";
    switch (transaction.type) {
      case SubscriptionSurchargeCredit:
        titlePrefix = `Subscription Payment`;
        break;
      case TipCredit:
      case RecurringDonationCredit:
        titlePrefix = `Donation Payment`;
        break;
      case PendingInvoiceTransaction:
      case CreatorInsertionCredit: {
        const { campaignUUID, manualPayoutUUID, offlineCampaignName } = transaction;
        const campaign =
          typeof campaignUUID === "string" && campaignUUID?.length > 1
            ? campaigns?.[campaignUUID]
            : null;
        const manualPayout =
          typeof manualPayoutUUID === "string" && manualPayoutUUID?.length > 1
            ? manualPayouts?.[manualPayoutUUID]
            : null;

        if (manualPayout && manualPayout?.type === "programmatic") {
          const payoutEndDate = Number.isInteger(manualPayout?.periodEndsAt)
            ? moment(manualPayout.periodEndsAt, "X")
            : null;
          const payoutStartDate = Number.isInteger(manualPayout?.periodBeginsAt)
            ? moment(manualPayout.periodBeginsAt, "X")
            : null;
          titlePrefix = `RAP Payment from Programmatic Ads ${
            payoutEndDate && payoutStartDate
              ? "(" +
                payoutStartDate.format("MM/DD/YY") +
                " - " +
                payoutEndDate.format("MM/DD/YY") +
                ")"
              : ""
          }`;
        } else if (manualPayout && manualPayout?.type === "offline-campaign") {
          titlePrefix = manualPayout?.description;
        } else if (campaign && campaign?.name) {
          titlePrefix = `RAP Payment from ${campaign.name}`;
        } else {
          titlePrefix = `RAP Payment for ${
            typeof offlineCampaignName === "string" && offlineCampaignName?.length > 0
              ? offlineCampaignName
              : "Off Platform Ads"
          }`;
        }

        break;
      }
      case TierCreatorDebit:
        titlePrefix = transaction.description || "Account Transaction for RedCircle";
        break;
      default:
    }

    return titlePrefix;
  };

  const getTransactionImage = (transaction) => {
    if (transaction.type === TierCreatorDebit) {
      return RedCircleIcon;
    }

    return `${get(shows, [transaction.showUUID, "imageURL"], "")}`;
  };
  const transactionsMaxItems = 5000;
  const defaultPageSize = 100;
  const showPagination = transactions?.length > defaultPageSize;

  const data = transactions
    .slice(Math.max(transactions?.length - transactionsMaxItems, 0)) // User transactions laready organized from earliest to latest, take arbitrary amount from end
    .reverse()
    .filter((transaction) => {
      // Filters out transactions from ManualPayouts that are not from programmatic ads or offline campaigns,
      // in the future manualPayouts will be used to send out special promotions,
      // until designs are provided for those cases, choosing to not show it in the UI
      return transaction?.manualPayoutUUID && transaction?.manualPayoutUUID?.length > 1
        ? manualPayouts?.[transaction.manualPayoutUUID]?.type === "programmatic" ||
            manualPayouts?.[transaction.manualPayoutUUID]?.type === "offline-campaign"
        : true;
    })
    .map((transaction) => {
      return {
        ...transaction,
        isPending: transaction.state === PendingInvoiceTransactionState,
        transactionDisplay: {
          title: getTitle(transaction),
          imageURL: getTransactionImage(transaction),
          receiptURL: transaction.receiptURL,
          createdAt: transaction.createdAt,
          isBeingInvestigated: transaction.isBeingInvestigated,
          investigationNotes: transaction.investigationNotes,
          datePrefix:
            transaction.state === PendingInvoiceTransactionState ? "Brand invoiced on: " : "",
        },
      };
    });

  if (data.length) {
    return (
      <div className="transaction-table">
        <Table
          data={data}
          columns={columnInfo}
          className="transaction-table"
          showPagination={showPagination}
          defaultPageSize={defaultPageSize}
          showPaginationTop={true}
          getTheadProps={() => ({
            style: {
              display: "none",
            },
          })}
        />
      </div>
    );
  }
  return <div className="no-transactions text-center m-yl">{noDataText}</div>;
};

export default TransactionTable;
