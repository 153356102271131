import { InfoCircleFilled } from "@ant-design/icons";
import { Form, Input, InputRef, Switch, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { createCustomAudio, updateCustomAudio } from "src/action_managers/audio_management";
import UploadModalActionManager from "src/action_managers/upload_modal";
import FileUpload from "src/components/forms/file_upload/file_upload_v2";
import LoadingButton from "src/components/forms/loading_button/loading_button";
import BlockFeature from "src/components/lib/block_feature";
import Button from "src/components/lib/button";
import Drawer from "src/components/lib/drawer";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { goToPricingPage } from "src/lib/config";
import {
  getPixelURLValidationMessage,
  transformStringToWebhookURL,
  transformWebhookURLToString,
} from "src/lib/pixel_urls";
import { minimumAllowedTier } from "src/lib/tier-utils";
import { CustomAudio } from "src/reducers/custom_audio";
import { MediaFile, tierLevel } from "redcircle-types";
import AudioMacrosCollapse from "../../../modals/pixel_modal/audio_macros_collapse";
import styles from "./custom_audio.module.scss";
import DeletionModal from "./custom_audio_deletion_modal";

interface IProps {
  record: CustomAudio;
  tier: any;
  visible: boolean;
  onClose: () => void;
  onDelete: (record: any) => Promise<void>;
}

const MAX_FILE_SIZE_MB = 50;

export default function CustomAudioDrawer({ record, tier, visible, onClose, onDelete }: IProps) {
  const [form] = Form.useForm();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatchTS();

  const { user } = useSelectorTS((state) => state.user);
  const showExtraFields = minimumAllowedTier(tierLevel.pro, tier, false);
  const nameInputRef = useRef<InputRef | null>(null);

  useEffect(() => {
    if (visible) {
      nameInputRef?.current?.focus();
    }

    // RESET DATA
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record.name,
        isActive: !record.isPaused,
        startAt: record.startAt ? moment.unix(record.startAt).toDate() : undefined,
        endAt: record.endAt ? moment.unix(record.endAt).toDate() : undefined,
        trackingURL: record.trackingURL ? transformWebhookURLToString(record.trackingURL) : "",
        maxInsertions: record.maxInsertions,
      });
    } else {
      form.resetFields();
    }
  }, [record]);

  const validate = (values: any) => {
    const newErrors: any = {};

    if (values.maxInsertions || values.maxInsertions === 0) {
      if (values.maxInsertions > 1000000000 || values.maxInsertions < 1) {
        newErrors.maxInsertions = "Please enter a cap between 1 and 1,000,000,000";
      }
    }

    if (values.trackingURL) {
      try {
        new URL(values.trackingURL);
      } catch {
        newErrors.trackingURL = "Please enter a valid URL.";
      }
    }

    if (values.audioFile) {
      const file = values.audioFile[0];
      const maxSizeInBytes = MAX_FILE_SIZE_MB * 1024 * 1024;

      if (file.size > maxSizeInBytes) {
        newErrors.audioFile = `Please upload a file smaller than ${MAX_FILE_SIZE_MB} MB.`;
      }

      const acceptedFileTypes = ["audio/mpeg", "audio/x-m4a", "audio/mp3", "audio/m4a"];
      if (!acceptedFileTypes.includes(file.type)) {
        newErrors.audioFile = "Please upload a .mp3 or .m4a/.mp4 file.";
      }
    } else if (!record) {
      newErrors.audioFile = "Please upload an audio file.";
    }

    if (values.startAt && values.endAt && values.startAt > values.endAt) {
      newErrors.startAt = "Please choose a start date prior to the end date.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      return true;
    }

    return false;
  };

  const updateRecord = (values: any, mediaFile: MediaFile | undefined) => {
    dispatch(
      updateCustomAudio(
        record,
        values.name,
        mediaFile,
        values.trackingURL,
        values.maxInsertions,
        values.startAt,
        values.endAt,
        !values.isActive
      )
    ).then((response) => {
      if (response.status === 200) {
        onClose();
      }
    });
  };

  const createRecord = (values: any, mediaFile: MediaFile) => {
    dispatch(
      createCustomAudio(
        values.name,
        mediaFile,
        values.trackingURL,
        values.maxInsertions,
        values.startAt,
        values.endAt,
        !values.isActive
      )
    ).then((response) => {
      if (response.status === 200) {
        onClose();
      }
    });
  };

  const handleSubmit = (values: any) => {
    // use null here, not undefined - if the value in the form is blank, we want
    // to remove it off the entity (undefined would cause no change)
    values.maxInsertions = values.maxInsertions ? parseInt(values.maxInsertions) : null;
    values.startAt = values.startAt ? moment(values.startAt).unix() : null;
    values.endAt = values.endAt ? moment(values.endAt).unix() : null;
    values.trackingURL = values.trackingURL
      ? transformStringToWebhookURL(values.trackingURL)
      : null;

    // ignore this if user is growth/og
    if (!showExtraFields) {
      delete values.maxInsertions;
      delete values.startAt;
      delete values.endAt;
      delete values.trackingURL;
    }

    if (validate(values)) {
      if (values.audioFile) {
        const file = values.audioFile[0];

        setIsSubmitting(true);
        dispatch(
          new UploadModalActionManager({
            file,
            fileName: file.name,
            authToken: user.authToken,
          }).run()
        ).then((response) => {
          if (response.status === 200) {
            const mediaFile = response.json[0];

            if (record) {
              updateRecord(values, mediaFile);
            } else {
              createRecord(values, mediaFile);
            }
          }
          setIsSubmitting(false);
        });
      } else {
        updateRecord(values, record.mediaFile);
      }
    }
  };

  const handleDeletion = () => {
    onDelete(record).then(onClose);
  };

  if (!visible) return null;

  return (
    <Drawer open={visible} onClose={onClose} maskClosable={false}>
      {visible && (
        <>
          <Form
            form={form}
            name="custom-audio-form"
            className="flex-column-container height-100"
            initialValues={{ isActive: true }}
            onFinish={handleSubmit}>
            <Drawer.Header>
              <h3 className="m-b0">{record ? "Edit" : "New"} Custom Audio</h3>
            </Drawer.Header>

            <Drawer.Body>
              <strong>
                <small>NAME</small>
              </strong>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please enter custom audio name." }]}>
                <Input placeholder="Enter Custom Audio Name" size="large" ref={nameInputRef} />
              </Form.Item>

              <strong>
                <small>AUDIO FILE</small>
              </strong>
              <Form.Item
                name="audioFile"
                validateStatus={errors.audioFile && "error"}
                help={errors.audioFile}>
                <FileUpload
                  acceptedFileExtensions=".mp3,.m4a"
                  mediaFileUUID={record?.mediaFileUUID}
                  max={MAX_FILE_SIZE_MB}
                />
              </Form.Item>

              <BlockFeature
                block={!showExtraFields}
                blockByBorder
                CTA={{
                  text: "Advanced Targeting available with Pro Plan",
                  btn: { text: "Upgrade Your Plan", handler: () => goToPricingPage() },
                }}>
                <>
                  <span>
                    <strong>
                      <small>TRACKING URL</small>
                    </strong>
                    <small className={styles.formHelpText}>(optional)</small>
                    <Tooltip
                      title={
                        <span>
                          To include multiple tracking URLs, separate each URL with a new line or{" "}
                          <code>|||</code>.
                        </span>
                      }>
                      <small className="m-lxxxs">
                        <InfoCircleFilled />
                      </small>
                    </Tooltip>
                  </span>
                  <Form.Item
                    className="m-bxxs"
                    name="trackingURL"
                    validateStatus={errors.trackingURL && "error"}
                    help={errors.trackingURL}
                    rules={[
                      {
                        validator: async (_, trackingURL) => {
                          const err = getPixelURLValidationMessage(trackingURL);
                          if (err) {
                            return Promise.reject(new Error(err));
                          }
                        },
                        warningOnly: true,
                      },
                    ]}>
                    <Input.TextArea
                      disabled={!showExtraFields}
                      placeholder="Enter Tracking URL"
                      size="large"
                      rows={1}
                      autoSize
                    />
                  </Form.Item>
                  <AudioMacrosCollapse />

                  <span>
                    <strong>
                      <small>START DATE</small>
                    </strong>
                    <small className={styles.formHelpText}>(optional)</small>
                  </span>
                  <Form.Item
                    name="startAt"
                    validateStatus={errors.startAt && "error"}
                    help={errors.startAt}>
                    <DateTimePicker
                      disabled={!showExtraFields}
                      placeholder={moment().format("lll")}
                    />
                  </Form.Item>

                  <span>
                    <strong>
                      <small>END DATE</small>
                    </strong>
                    <small className={styles.formHelpText}>(optional)</small>
                  </span>
                  <Form.Item
                    name="endAt"
                    validateStatus={errors.endAt && "error"}
                    help={errors.endAt}>
                    <DateTimePicker
                      disabled={!showExtraFields}
                      placeholder={moment().format("lll")}
                    />
                  </Form.Item>

                  <span>
                    <strong>
                      <small>IMPRESSION CAP</small>
                    </strong>
                    <small className={styles.formHelpText}>(optional)</small>
                  </span>
                  <Form.Item
                    validateStatus={errors.maxInsertions && "error"}
                    help={errors.maxInsertions}
                    name="maxInsertions">
                    <Input
                      disabled={!showExtraFields}
                      placeholder="20,000"
                      type="number"
                      size="large"
                    />
                  </Form.Item>
                </>
              </BlockFeature>

              <strong>
                <small>STATUS</small>
              </strong>
              <Form.Item name="isActive" valuePropName="checked">
                <Switch checkedChildren="ACTIVE" unCheckedChildren="PAUSED" />
              </Form.Item>

              {record && (
                <Button type="link" className="m-tl" onClick={() => setShowDeleteModal(true)}>
                  <strong>Delete Audio</strong>
                </Button>
              )}
            </Drawer.Body>

            <Drawer.Footer className="flex-row-container justify-space-between">
              <Button type="link" size="large" onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton type="submit" isLoading={isSubmitting}>
                Save
              </LoadingButton>
            </Drawer.Footer>
          </Form>

          {record && (
            <DeletionModal
              audioBlocks={record.audioBlocks}
              show={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              onOk={handleDeletion}
            />
          )}
        </>
      )}
    </Drawer>
  );
}
