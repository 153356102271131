import { ADVERTISER_SIGN_UP } from "../../action_managers/advertiser_contact";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { AdvertiserContactsReduxState } from "./types";

const initialState: AdvertiserContactsReduxState = {
  isLoading: false,
  validationErrors: [],
};

const advertiserSignUp = httpReducer(ADVERTISER_SIGN_UP, initialState, {
  failureBadRequest: (state, action) => {
    return {
      ...state,
      validationErrors: action.payload?.validationErrors,
    };
  },
});

export default reduceReducers(advertiserSignUp);
