import React from "react";
import cancelIcon from "src/icons/modal-close-icon.svg";
import { secondsToString } from "src/lib/numbers";
import styles from "./custom_audio.module.scss";
import PlayPause from "./custom_audio_play_pause";

interface IProps {
  audioRef: HTMLAudioElement | null;
  progress: number;
  recordKey: string;
  onClose: (key: string) => void;
  onPlayPause: (key: string) => void;
}

export default function MediaPlayer({
  audioRef,
  progress,
  recordKey,
  onClose,
  onPlayPause,
}: IProps) {
  if (!audioRef) {
    return null;
  }

  const handleClose = () => {
    onClose(recordKey);
  };

  const handlePlayPause = () => {
    onPlayPause(recordKey);
  };

  const progressPercentage = (progress / audioRef.duration) * 100;

  return (
    <div className="flex-row-container justify-end">
      <div className={styles.mediaBar}>
        <div onClick={handlePlayPause}>
          <PlayPause audioRef={audioRef} withoutBorder />
        </div>
        <div className={styles.mediaBarProgressWrapper}>
          <div
            className={styles.mediaBarProgress}
            style={{ width: `${progressPercentage}%` }}></div>
        </div>
        <div className={styles.mediaBarDuration}>{secondsToString(progress)}</div>
        <div className={styles.mediaBarClose} onClick={handleClose}>
          <img src={cancelIcon} />
        </div>
      </div>
    </div>
  );
}
