/* eslint-disable no-case-declarations */
import { AnyAction } from "redux";
import {
  UPLOAD_MODAL_BITRATE_SET,
  UPLOAD_MODAL_CLEAR,
  UPLOAD_MODAL_ERROR,
  UPLOAD_MODAL_PROGRESS,
  UPLOAD_MODAL_START,
  UPLOAD_MODAL_SUCCESS,
} from "../../actions/upload_modal";
import { removeItem } from "./../index";
import { MediaFileUploadReduxState } from "./types";

const initialState: MediaFileUploadReduxState = {
  isLoading: false,
  mediaFiles: null,
  bitrate: 128, // default bitrate.
  loadingUUIDs: [],
  progress: 0,
};

function mediaFileUpload(state = initialState, action: AnyAction) {
  switch (action.type) {
    case UPLOAD_MODAL_CLEAR:
      return Object.assign({}, initialState);
    case UPLOAD_MODAL_START:
      return {
        isLoading: true,
        mediaFiles: null,
        bitrate: state.bitrate,
        progress: 0,
        loadingUUIDs: [...state.loadingUUIDs, action.uuid],
      };
    case UPLOAD_MODAL_SUCCESS:
      const newState: MediaFileUploadReduxState = {
        isLoading: state.isLoading,
        mediaFiles: action.mediaFiles,
        bitrate: state.bitrate,
        progress: 0,
        loadingUUIDs: removeItem<string>(state.loadingUUIDs, action.uuid),
      };

      if (newState.loadingUUIDs.length === 0) {
        newState.isLoading = false;
      }
      return newState;
    case UPLOAD_MODAL_ERROR:
      const nextState: MediaFileUploadReduxState = {
        ...state,
        isLoading: state.isLoading,
        bitrate: state.bitrate,
        progress: 0,
        loadingUUIDs: removeItem<string>(state.loadingUUIDs, action.uuid),
      };
      if (nextState.loadingUUIDs.length === 0) {
        nextState.isLoading = false;
      }
      return nextState;
    case UPLOAD_MODAL_BITRATE_SET:
      return Object.assign({}, state, {
        bitrate: action.bitrate,
      });
    case UPLOAD_MODAL_PROGRESS:
      return Object.assign({}, state, {
        progress: action.progress,
      });
    default:
      return state;
  }
}

export default mediaFileUpload;
