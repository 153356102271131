import { GET_YOUTUBE_FOR_SHOW } from "../../action_managers/shows";
import { httpReducer } from "../../lib/create-action";
import { YoutubeReduxState } from "./types";

const initialState: YoutubeReduxState = {
  channelsByShow: {},
  isLoading: false,
};

const youtubeChannels = httpReducer(GET_YOUTUBE_FOR_SHOW, initialState, {
  success: (state, action) => ({
    ...state,
    channelsByShow: {
      ...state.channelsByShow,
      [action.data.showUUID]: action.payload.resp,
    },
  }),
});

export default youtubeChannels;
