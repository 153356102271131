export const UPLOAD_MODAL_CLEAR = "UPLOAD_MODAL_CLEAR";
export const UPLOAD_MODAL_START = "UPLOAD_MODAL_START";
export const UPLOAD_MODAL_ERROR = "UPLOAD_MODAL_ERROR";
export const UPLOAD_MODAL_SUCCESS = "UPLOAD_MODAL_SUCCESS";
export const UPLOAD_MODAL_BITRATE_SET = "UPLOAD_MODAL_BITRATE_SET";
export const UPLOAD_MODAL_PROGRESS = "UPLOAD_MODAL_PROGRESS";

export function uploadModalClear() {
  return {
    type: UPLOAD_MODAL_CLEAR,
  };
}

export function uploadModalBitrateSet(bitrate) {
  return {
    type: UPLOAD_MODAL_BITRATE_SET,
    bitrate: bitrate,
  };
}

export function uploadModalStart(uuid) {
  return {
    type: UPLOAD_MODAL_START,
    uuid: uuid,
  };
}

export function uploadModalSuccess(mediaFiles, uuid) {
  return {
    type: UPLOAD_MODAL_SUCCESS,
    mediaFiles: mediaFiles,
    uuid: uuid,
  };
}

export function uploadModalError(message, uuid) {
  return {
    type: UPLOAD_MODAL_ERROR,
    uuid: uuid,
  };
}

export function uploadModalProgress(progress, uuid) {
  return {
    type: UPLOAD_MODAL_PROGRESS,
    progress: progress,
  };
}
