/*
 * action types
 */

export const SIGN_UP_REQUEST_START = "SIGN_UP_REQUEST_START";
export const SIGN_UP_REQUEST_SUCCESS = "SIGN_UP_REQUEST_SUCCESS";
export const SIGN_UP_REQUEST_ERROR = "SIGN_UP_REQUEST_ERROR";
export const SIGN_UP_REQUEST_INVALID = "SIGN_UP_REQUEST_INVALID";

/*
 * action creators
 */
export function signUpStart() {
  return {
    type: SIGN_UP_REQUEST_START,
  };
}

export function signUpSuccess(user) {
  return {
    type: SIGN_UP_REQUEST_SUCCESS,
  };
}

export function signUpError() {
  return {
    type: SIGN_UP_REQUEST_ERROR,
  };
}

export function signUpInvalid(validationErrors) {
  return {
    type: SIGN_UP_REQUEST_INVALID,
    validationErrors: validationErrors,
  };
}
