import isNil from "lodash/isNil";
import React, { CSSProperties } from "react";
import { classNames } from "react-extras";

/**
 * TODO: Replace to use Antd divider component, Use Antd Divider component
 * for future use instead of this component for consistency.
 */
interface IDivider {
  smallMargin?: boolean;
  className?: string;
  marginTop?: CSSProperties["marginTop"];
  marginBottom?: CSSProperties["marginBottom"];
  style?: CSSProperties;
}

const Divider = (props: IDivider) => {
  const { smallMargin, marginTop, marginBottom, className, style } = props;
  let defaultMargin = "28px";
  if (!isNil(smallMargin)) {
    defaultMargin = "12px";
  }
  return (
    <hr
      className={classNames("redcircle-divider", className)}
      style={{
        marginTop: marginTop ?? defaultMargin,
        marginBottom: marginBottom ?? defaultMargin,
        ...style,
      }}
    />
  );
};

export default Divider;
