import get from "lodash/get";
import React, { Component } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { matchPath } from "react-router-dom";
import DistributionForm from "../pages/shows/distribution_form";
import BaseModal from "./base_modal";

class DistributionsModal extends Component {
  componentDidMount() {
    this.props.fetchDistributions(this.showUUID());
  }

  showUUID = () => {
    var result = matchPath(this.props.location.pathname, "/shows/:showUUID/distribution");
    return get(result, "params.showUUID");
  };

  close = () => {
    this.props.history.push(this.props.closeRoute(this.props.location));
    this.props.closeModal();
  };

  render() {
    return (
      <BaseModal
        isLoading={this.props.isLoading}
        title={"Distribution"}
        showContent={!this.props.isLoading}
        close={this.close}
        Content={this.modalContent}
        Footer={this.modalFooter}
      />
    );
  }

  modalFooter = () => {
    return (
      <Grid fluid>
        <Row>
          <Col mdOffset={3} md={6} xs={12}>
            <Button
              className="btn-secondary btn-large distributions-modal__submit-button modal-button"
              onClick={this.close}>
              Close
            </Button>
          </Col>
        </Row>
      </Grid>
    );
  };

  modalContent = () => {
    var distribution = this.props.distributions[this.showUUID()];
    return (
      <div className="distributions-modal-content">
        <DistributionForm
          validationErrors={this.props.validationErrors}
          initialValues={distribution}
          onSubmit={(values) => {
            this.props.putDistributions(this.showUUID(), values);
          }}
          getDistributionFormValue={this.props.getDistributionFormValue}
          isPutting={this.props.isPutting}
        />
      </div>
    );
  };

  getDistributionRows = () => {
    const data = this.props.distributions[this.showUUID()] || {};
    return Object.keys(data).map((key) => {
      return { key, link: data[key] };
    });
  };
}

export default DistributionsModal;
