import React, { Component } from "react";
import { withMediaProps } from "react-media-player";
import { secondsToString } from "../../../lib/numbers";

class SeekBar extends Component {
  constructor(props) {
    super(props);
    this.seekbar = React.createRef();
  }

  _onChangeUsed = false;

  shouldComponentUpdate({ media }) {
    return (
      this.props.media.currentTime !== media.currentTime ||
      this.props.media.duration !== media.duration
    );
  }

  _handleChange = (e, duration) => {
    const domRect = this.seekbar.current.getBoundingClientRect();
    const start = domRect.x;
    const width = domRect.width;
    const clickPosition = e.clientX;
    const value = ((clickPosition - start) / width) * duration;
    this.props.media.seekTo(+value);
    this._onChangeUsed = true;
  };

  render() {
    const { style, media } = this.props;
    const { duration, currentTime } = media;
    const percentagePlayed = (currentTime * 100) / duration;

    return (
      <>
        <div
          ref={this.seekbar}
          onClick={(e) => this._handleChange(e, duration)}
          className="seekbar-wrapper">
          <div className="seekbar" style={{ width: `${percentagePlayed}%`, ...style }} />
        </div>
        <div className="duration-text">{secondsToString(currentTime)}</div>
      </>
    );
  }
}

export default withMediaProps(SeekBar);
