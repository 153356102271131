import { Collapse, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { ICampaign, ICampaignItem } from "redcircle-types";
import { Modal } from "redcircle-ui";
import { showWarning } from "src/actions/app";
import { updateCampaignOfferShowNotes } from "src/actions/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { getPromoCode } from "src/lib/campaigns";
import { ScriptItem } from "../campaign_audio_swap/swap_audio_modal";

interface IProps {
  open: boolean;
  onClose: () => void;
  campaignItem: ICampaignItem;
}

export default function UpdateShowNotesModal({ open, onClose, campaignItem }: IProps) {
  const [form] = Form.useForm();
  const dispatch = useDispatchTS();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { scriptsByUUID } = useSelectorTS((state) => state?.scripts);
  const script = scriptsByUUID[campaignItem?.scriptUUID ?? ""];
  const show = useSelectorTS(
    (state) =>
      state.shows?.shows?.[campaignItem?.showUUID] || state?.publicShows?.[campaignItem?.showUUID]
  );
  const campaign = useSelectorTS(
    (state) => state?.campaigns?.campaigns?.[campaignItem?.campaignUUID]
  );

  useEffect(() => {
    form.setFieldsValue({ feedCTA: campaignItem.feedCTA });
  }, [campaignItem]);

  const handleSubmit = async ({ feedCTA }: { feedCTA: string }) => {
    setIsSubmitting(true);
    const res = await dispatch(
      updateCampaignOfferShowNotes({
        campaign: { uuid: campaign.uuid, isV2: campaignItem.isV2 } as ICampaign,
        campaignItemUUID: campaignItem.uuid,
        feedCTA,
      })
    );
    setIsSubmitting(false);
    if (res.status === 200) {
      onClose();
    } else {
      dispatch(showWarning("Failed to update show notes"));
    }
  };

  return (
    <Modal open={open} onClose={onClose} size="sm">
      <Form form={form} onFinish={handleSubmit}>
        <Modal.Title>Update Show Notes</Modal.Title>
        <Modal.Body>
          <Collapse ghost>
            <Collapse.Panel
              header={<strong className="color-primary">Script Details</strong>}
              key="1">
              <div className="flex-column-container align-start">
                <ScriptItem
                  title="campaign promotion code"
                  text={getPromoCode({ campaign, campaignItem, show, script })}
                />
                <ScriptItem title="Script or talking points" text={script?.content} isHTML={true} />
                <ScriptItem
                  title="Requirements from brand"
                  text={script?.readerGuidance}
                  isHTML={true}
                />
              </div>
            </Collapse.Panel>
          </Collapse>

          <label className="redcircle-form-label" htmlFor="feedCTA">
            Add Code To Show Notes
          </label>
          <Form.Item name="feedCTA">
            <Input.TextArea autoSize={{ minRows: 3 }} />
          </Form.Item>
        </Modal.Body>
        <Modal.Footer>
          <Modal.CloseButton />
          <Modal.SubmitButton disabled={isSubmitting}>Save</Modal.SubmitButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
