import React, { lazy, PropsWithChildren, Suspense } from "react";
import Spinner from "../../../lib/spinner";
import { graphFonts } from "../analyticsUtility";
import Message from "./Messages";
import "./redcircle_graphs.scss";

const Line = lazy(() => import("@ant-design/plots/es/components/line"));

// Inferring Graph config type
type graphConfig = Parameters<typeof Line>[number];

// Interface with Generic T of type datapoint to restrict field types
interface IRCLine<T> {
  data: T[];
  xField: string & keyof T;
  yField: string & keyof T;
  seriesField?: string & keyof T;
  height?: Required<graphConfig>["height"];
  width?: Required<graphConfig>["width"];
  loading?: boolean;
  point?: Required<graphConfig>["point"];
  tooltip?: Required<graphConfig>["tooltip"];
  color?: Required<graphConfig>["color"];
  meta?: Required<graphConfig>["meta"];
  legend?: Required<graphConfig>["legend"];
}

const RCLine = <T extends Record<string, any>>(props: PropsWithChildren<IRCLine<T>>) => {
  const {
    data = [],
    xField,
    yField,
    height = 260,
    width,
    seriesField,
    loading = false,
    point,
    tooltip,
    color = ["#1979C9", "#D62A0D", "#FAA219"],
    meta,
    legend,
  } = props;

  const config: graphConfig = {
    data,
    autoFit: true,
    style: {
      minHeight: `${height}px`,
    },
    height,
    width,
    loading,
    xField,
    yField,
    legend,
    seriesField,
    point,
    tooltip,
    xAxis: {
      label: {
        style: {
          fontFamily: graphFonts,
          fontSize: 11,
          lineHeight: 16,
          fontWeight: 400,
          letterSpacing: 0.6,
          color: "#000000",
        },
      },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineDash: [15, 15],
            strokeOpacity: 0.1,
          },
        },
      },
    },
    yAxis: {
      label: {
        style: {
          fontFamily: graphFonts,
          fontSize: 11,
          lineHeight: 16,
          fontWeight: 400,
          letterSpacing: 0.6,
          color: "#000000",
        },
      },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineDash: [15, 15],
            strokeOpacity: 0.1,
          },
        },
      },
    },
    color,
    meta,
  };

  return (
    <div className="analytics-graphContainer" style={{ minHeight: `${height}px` }}>
      <Suspense fallback={<Spinner />}>
        <Line {...config} />
      </Suspense>
      <Message show={!loading && data?.length === 0} type="Insufficient Data" />
    </div>
  );
};

export default RCLine;
