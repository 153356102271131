import { Link } from "react-router-dom";
import WarningPanel from "src/components/lib/warning_panel";
import { ACTIONS, CATEGORIES, gtagEvent } from "src/lib/google_analytics";
import { IShow } from "redcircle-types";

export const RedirectWarningPanel = ({ show }: { show: IShow }) => {
  return (
    <WarningPanel>
      Your import was successful. To complete the switch,{" "}
      <Link
        to={`/shows/${show.uuid}/redirect-help`}
        className="btn-link"
        onClick={() => gtagEvent(ACTIONS.CLICK, CATEGORIES.VERIFY_REDIRECT_LINK)}>
        redirect your podcast
      </Link>
    </WarningPanel>
  );
};

export const DistributionWarningPanel = ({ show }: { show: IShow }) => {
  return (
    <WarningPanel>
      There are major platforms that you have not confirmed your distribution on yet.{" "}
      <Link to={`/shows/${show.uuid}/distribution`}>Complete Your Distribution {">"}</Link>
    </WarningPanel>
  );
};

export const VastUnverifiedWarningPanel = ({ show }: { show: IShow }) => {
  return (
    <WarningPanel>
      Your podcast is not yet fully connected to RedCircle through VAST.{" "}
      <Link to={`/shows/${show.uuid}/vast-setup`}>Setup and verify VAST {">"}</Link>
    </WarningPanel>
  );
};
