import { ModalType } from "src/components/modals/modal_root";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export function showModal(modalType: ModalType, modalProps: object = {}) {
  return {
    type: SHOW_MODAL,
    modalType: modalType,
    modalProps: modalProps,
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}
