import DefaultActionManager from "./default_action_manager";

export const GET_SCHEDULED_JOB = "GET_SCHEDULED_JOB";

export const getScheduledJob = (jobUUID) =>
  new DefaultActionManager({
    actionName: GET_SCHEDULED_JOB,
    route: `scheduled-jobs/${jobUUID}`,
    auth: true,
  }).run();
