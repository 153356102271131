import keyBy from "lodash/keyBy";
import { AnyAction } from "redux";
import { showFeedImportActionName } from "../../actions/feed_import";
import {
  RESET_SHOWS,
  SHOW_CHECK_REDIRECT,
  SHOW_FETCH_BY_UUIDS,
  SHOW_FETCH_ERROR,
  SHOW_FETCH_START,
  SHOW_FETCH_SUCCESS,
  SHOW_PUT_SUCCESS,
} from "../../actions/shows";
import { ENABLE_PAYMENTS } from "../../action_managers/shows";
import { ENABLE_EXCLUSIVE_CONTENT } from "../../action_managers/subscriptions";
import { getActionNames, httpReducer, reduceReducers } from "../../lib/create-action";
import { IShow, IShowMap } from "redcircle-types";

const initialState = {
  isLoading: false,
  initialFetched: false,
  shows: {} as IShowMap,
};

export type showState = typeof initialState;

const resetShows = (state = initialState, action: AnyAction) => {
  if (action.type === RESET_SHOWS) {
    return {
      ...initialState,
      isLoading: state.isLoading,
    };
  }

  return state;
};

function getShow(state: showState = initialState, action: AnyAction): showState {
  switch (action.type) {
    case SHOW_FETCH_START:
    case getActionNames(ENABLE_PAYMENTS).started:
    case getActionNames(ENABLE_EXCLUSIVE_CONTENT).started:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case getActionNames(showFeedImportActionName).success: {
      const show = action.payload.resp;
      const newShow = { [show.uuid]: show };
      // tslint:disable-next-line:no-shadowed-variable
      const shows = Object.assign({}, state.shows, newShow);
      return {
        ...state,
        isLoading: false,
        shows,
      };
    }
    case getActionNames(ENABLE_EXCLUSIVE_CONTENT).success:
    case getActionNames(ENABLE_PAYMENTS).success: {
      const show = action.payload.resp;
      return { ...state, shows: { ...state.shows, [show.uuid]: show }, isLoading: false };
    }
    case SHOW_FETCH_SUCCESS:
    case SHOW_PUT_SUCCESS: {
      const allShows = { ...state.shows };

      for (const fetchedShow of action.shows) {
        if (!fetchedShow.isHardDeleted && fetchedShow.isVisible !== false) {
          allShows[fetchedShow.uuid] = fetchedShow;
        } else {
          delete allShows[fetchedShow.uuid];
        }
      }
      return {
        isLoading: false,
        shows: allShows,
        initialFetched: true,
      };
    }
    case getActionNames(ENABLE_EXCLUSIVE_CONTENT).failure:
    case getActionNames(ENABLE_EXCLUSIVE_CONTENT).failureBadRequest:
    case SHOW_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        initialFetched: true,
      };
    case getActionNames(SHOW_CHECK_REDIRECT).success: {
      const show = { ...action.data.show, redirectVerified: action.payload.resp };
      return {
        ...state,
        isLoading: false,
        shows: Object.assign({}, state.shows, { [show.uuid]: show }),
      };
    }
    default:
      return state;
  }
}

const getShowsByUUIDs = httpReducer(SHOW_FETCH_BY_UUIDS, initialState, {
  success: (state, action) => {
    const showResp = action.payload.resp as IShow[];
    return {
      ...state,
      shows: {
        ...state.shows,
        ...keyBy(showResp, "uuid"),
      },
    };
  },
});

export const showReducer = reduceReducers(resetShows, getShow, getShowsByUUIDs);
