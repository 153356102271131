import get from "lodash/get";
import { Reducer } from "redux";
import {
  createDealActionName,
  dealClearValidationActionName,
  fetchDealActionName,
  getDealForUserActionName,
  getUnreadDealMessagesActionName,
  updateDealActionName,
} from "../../action_managers/deals";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { Deal, DealsReduxState } from "./types";

const initialState: DealsReduxState = { isLoading: false, userDealUUIDs: [], isPutting: false };

const dealCreateReducer = httpReducer(createDealActionName, initialState, {
  success: (state, action) => ({
    ...state,
    [action.payload.resp.uuid]: action.payload.resp,
  }),
  failureBadRequest: (state, action) => ({
    ...state,
    validationErrors: get(action, "payload.validationErrors", []),
  }),
});

const dealFetchReducer = httpReducer(fetchDealActionName, initialState, {
  success: (state, action) => ({ ...state, [action.payload.resp.uuid]: action.payload.resp }),
});

const dealClearValidationReducer: Reducer<DealsReduxState> = (state, action) => {
  if (action.type === dealClearValidationActionName) {
    state = {
      ...state,
      validationErrors: null,
    };
  }
  return state;
};

const dealUpdateRequestReducer = httpReducer(
  updateDealActionName,
  initialState,
  {
    success: (state, action) => {
      return {
        ...state,
        [action.payload.resp.uuid]: action.payload.resp,
      };
    },
  },
  { loadingKeyName: "isPutting" }
);

const createDealForUserReducer: (
  actionName: string,
  showLoader: boolean
) => Reducer<DealsReduxState> = (actionName, showLoader) => {
  let config: { loadingKeyName?: string } = { loadingKeyName: "no_loader" };
  if (showLoader) {
    config = {};
  }

  return httpReducer(
    actionName,
    initialState,
    {
      success: (state, action) => {
        const newDeals: Record<string, Deal> = {};
        const userDealUUIDs: string[] = [];
        action.payload.resp.forEach((deal: Deal) => {
          userDealUUIDs.push(deal.uuid);
          newDeals[deal.uuid] = deal;
        });
        return {
          ...state,
          ...newDeals,
          userDealUUIDs,
        };
      },
    },
    config
  );
};

const dealUnreadMessagesReducer = httpReducer(
  getUnreadDealMessagesActionName,
  initialState,
  {
    success: (state, action) => {
      const unreadMessages = get(action.payload.resp, "unread", 0);
      return {
        ...state,
        unread: unreadMessages,
      };
    },
  },
  { loadingKeyName: "no_loader" }
);

export default reduceReducers(
  dealCreateReducer,
  dealFetchReducer,
  dealClearValidationReducer,
  dealUpdateRequestReducer,
  createDealForUserReducer(getDealForUserActionName, true),
  dealUnreadMessagesReducer
);
