import { Divider, Form, Input } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "redcircle-ui";
import GoogleMapAutocomplete from "src/components/lib/google_map_autocomplete";

export default function RAPSettingsFormHosts({ required = false }: { required?: boolean }) {
  return (
    <Form.List name={["advertisementSettings", "hosts"]}>
      {(fields, { add, remove }, { errors }) => {
        return (
          <>
            {fields.map((field, index) => (
              <div key={field.key} className="flex-row-container">
                <div className="flex-column-container flex-1 m-rxs">
                  <div className="flex-row-container">
                    <div className="flex-1 m-rxs">
                      <label
                        className="redcircle-form-label"
                        htmlFor={`advertisementSettings_hosts_${index}_fullName_firstName`}>
                        First Name
                      </label>
                      <Form.Item name={[field.name, "fullName", "firstName"]} className="m-bxxs">
                        <Input size="large" />
                      </Form.Item>
                    </div>
                    <div className="flex-1">
                      <label
                        className="redcircle-form-label"
                        htmlFor={`advertisementSettings_hosts_${index}_fullName_lastName`}>
                        Last Name
                      </label>
                      <Form.Item name={[field.name, "fullName", "lastName"]} className="m-bxxs">
                        <Input size="large" />
                      </Form.Item>
                    </div>
                  </div>

                  <label
                    className="redcircle-form-label"
                    htmlFor={`advertisementSettings_hosts_${index}_postalAddress`}>
                    Street Address
                  </label>

                  <Form.Item
                    name={[field.name, "postalAddress"]}
                    className="m-bxxs"
                    rules={[{ required, message: "Please enter an address" }]}>
                    <GoogleMapAutocomplete
                      key={`advertisementSettings_hosts_${index}_postalAddresss`}
                    />
                  </Form.Item>

                  <Divider />
                </div>
                <Button type="icon" onClick={() => remove(index)} size="small">
                  <AiOutlineClose />
                </Button>
              </div>
            ))}
            <Button onClick={() => add()}>Add Host</Button>
          </>
        );
      }}
    </Form.List>
  );
}
