import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showMessageWithTimeout } from "../../../actions/app";
import { getStripeBalance } from "../../../actions/balance";
import { getShowCampaigns } from "../../../actions/campaigns";
import { showModal } from "../../../actions/modal";
import { FetchUserManager } from "../../../action_managers/account";
import { getManualPayoutsFromUser } from "../../../action_managers/manual_payouts";
import {
  enablePayments,
  ShowFetchActionManager,
  ShowsForUserActionManager,
} from "../../../action_managers/shows";
import { getStripeLoginLink } from "../../../action_managers/stripe_connect";
import {
  getRecentTransactionsForUser,
  getTransactionsForUser,
  TransactionWithdrawActionManager,
} from "../../../action_managers/transactions";
import { isOrgOwner } from "../../../lib/permissions";
import { withPermissions } from "../../../lib/permissions-js";
import { CONFIRMATION_MODAL, MONETIZATION_INTRO_MODAL } from "../../modals/modal_root";
import MonetizationPage from "./monetization_page";

function mapStateToProps(state) {
  return {
    user: state.user.user,
    isLoading: state.user.isLoading || state.shows.isLoading || state.transactions.isLoading,
    isPuttingShow: state.showForm.isLoading,
    transactions: state.transactions,
    shows: state.shows.shows,
    balance: state.balance.stripeBalance,
    canAccessStripe: isOrgOwner(state.user.user, state.permissions),
  };
}

const mapDispatchToProps = {
  fetchUser: (userUUID, authToken) =>
    new FetchUserManager({
      userUUID,
      authToken,
    }).run(),
  showIntroModal: () => showModal(MONETIZATION_INTRO_MODAL),
  fetchShow: (showUUID, user) => new ShowFetchActionManager({ showUUID, user }).run(),
  getTransactionsForUser,
  getRecentTransactionsForUser,

  withdrawFunds: (user) => new TransactionWithdrawActionManager({ user }).run(),

  fetchShowsForUser: (user) => new ShowsForUserActionManager({ user }).run(),
  enablePayments,
  showMessageWithTimeout: (message, type) => {
    return showMessageWithTimeout(message, type);
  },
  getShow: getStripeBalance,
  getStripeLoginLink,
  showWithdrawConfirmation: (callback) =>
    showModal(CONFIRMATION_MODAL, {
      modalTitle: "Transfer to Bank?",
      confirmationMessage:
        "Your current balance in Stripe will be transferred to your bank account.",
      callback,
    }),
  getStripeBalance,
  getShowCampaigns,
  getManualPayoutsFromUser,
  showModal,
};

const MonetizationPageWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withPermissions(MonetizationPage))
);

export default MonetizationPageWrapper;
