import { Popover } from "antd";
import React, { useMemo } from "react";
import { PLAY_ORDER_RANDOM } from "src/components/pages/dynamic_insertion/audio_blocks_section/consts";
import PLAY_ORDER_RANDOM_ICON from "src/icons/play-style-random.png";
import { pluralize } from "src/lib/strings";
import { PlayStyle } from "src/reducers/audio_blocks/types";
import { CustomAudio } from "src/reducers/custom_audio";

interface IProps {
  customAudio: CustomAudio;
}

interface AudioBlockPopoverItem {
  key: string;
  name: string;
  playStyle: PlayStyle;
  positionInfo: string;
}

export default function CustomAudioBlocks({ customAudio }: IProps) {
  if (!customAudio.audioBlocks) return null;

  const content = useMemo(() => {
    const blockCount = <span>{customAudio.audioBlocks.length}</span>;
    let content = blockCount;

    if (customAudio.audioBlocks.length > 0) {
      const audioBlockItems: AudioBlockPopoverItem[] = [];

      customAudio.audioBlocks.forEach((audioBlock) => {
        if (audioBlock.playStyle === PLAY_ORDER_RANDOM) {
          let spots = 0;
          audioBlock.items.forEach((audioBlockItem) => {
            if (audioBlockItem.customAudioUUID === customAudio.uuid) spots += 1;
          });

          audioBlockItems.push({
            key: audioBlock.uuid,
            name: audioBlock.name,
            playStyle: audioBlock.playStyle,
            positionInfo: `${spots} ${pluralize("Spot", spots)}`,
          });
        } else {
          audioBlock.items.forEach((audioBlockItem, index) => {
            if (audioBlockItem.customAudioUUID === customAudio.uuid) {
              audioBlockItems.push({
                key: `${audioBlock.uuid}-${index}`,
                name: audioBlock.name,
                playStyle: audioBlock.playStyle,
                positionInfo: `Position ${index + 1} of ${audioBlock.limit}`,
              });
            }
          });
        }
      });

      const popoverContent = (
        <div>
          {audioBlockItems.map((audioBlockItem) => (
            <div className="p-vxxs" key={audioBlockItem.key}>
              <strong>{audioBlockItem.name}</strong>
              {" | "}
              <span>
                {audioBlockItem.positionInfo}
                {audioBlockItem.playStyle === PLAY_ORDER_RANDOM && (
                  <img src={PLAY_ORDER_RANDOM_ICON} />
                )}
              </span>
            </div>
          ))}
        </div>
      );

      content = <Popover content={popoverContent}>{blockCount}</Popover>;
    }

    return content;
  }, [customAudio]);

  return <div className="text-end">{content}</div>;
}
