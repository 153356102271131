import { defaultActionManagerRunner } from "./default_action_manager";

export const GET_MANUAL_PAYOUTS = "GET_MANUAL_PAYOUTS";

export const getManualPayoutsFromUser = (userUUID: string) =>
  defaultActionManagerRunner({
    actionName: GET_MANUAL_PAYOUTS,
    method: "get",
    auth: true,
    route: `users/${userUUID}/manual-payouts`,
  });
