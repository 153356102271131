import { groupBy, keyBy, mergeWith } from "lodash";
import {
  CREATE_MULTIPLE_SCRIPTS,
  CREATE_SCRIPT,
  CreateMultipleScriptsResponse,
  DELETE_SCRIPT,
  GET_SCRIPT,
  GET_SCRIPTS_FOR_CAMPAIGN,
  UPDATE_MULTIPLE_SCRIPTS,
  UPDATE_SCRIPT,
  UpdateMultipleScriptsResponse,
} from "src/action_managers/script";
import { httpReducer, reduceReducers } from "src/lib/create-action";
import { Script } from "redcircle-types";
import { ScriptReduxState } from "./types";

const initialState: ScriptReduxState = {
  scripts: [],
  scriptsByUUID: {},
  scriptsByCampaignUUID: {},
  isLoading: false,
};

const getScript = httpReducer(GET_SCRIPT, initialState, {
  success: (state = initialState, action) => {
    const response: Script = action.payload.resp;

    const newScriptByCampaignUUID = groupBy([response], "campaignUUID");
    const newScriptsByUUID = keyBy([response], "uuid");
    const newScripts = [...state.scripts];

    const index = newScripts.findIndex((item) => item.uuid === response.uuid);
    if (index >= 0) {
      newScripts[index] = { ...response };
    } else {
      newScripts.push({ ...response });
    }

    return {
      ...state,
      scripts: newScripts,
      scriptsByCampaignUUID: { ...state.scriptsByCampaignUUID, ...newScriptByCampaignUUID },
      scriptsByUUID: { ...state.scriptsByUUID, ...newScriptsByUUID },
    };
  },
});

const createScript = httpReducer(CREATE_SCRIPT, initialState, {
  success: (state = initialState, action) => {
    const response: Script = action.payload.resp;

    const newScriptByCampaignUUID = groupBy([response], "campaignUUID");
    const newScriptsByUUID = keyBy([response], "uuid");
    const newScripts = [...state.scripts, response];

    return {
      ...state,
      scripts: newScripts,
      scriptsByCampaignUUID: { ...state.scriptsByCampaignUUID, ...newScriptByCampaignUUID },
      scriptsByUUID: { ...state.scriptsByUUID, ...newScriptsByUUID },
    };
  },
});

const updateScript = httpReducer(UPDATE_SCRIPT, initialState, {
  success: (state = initialState, action) => {
    const response: Script = action.payload.resp;

    const newScripts = [...state.scripts];

    const index = newScripts.findIndex((script) => script.uuid === response.uuid);
    if (index > 0) {
      newScripts[index] = { ...response };
    }

    const newScriptsByCampaignUUID = { ...state?.scriptsByCampaignUUID };

    const newScriptsList = newScriptsByCampaignUUID[response?.campaignUUID]?.map((scriptItem) => {
      if (scriptItem?.uuid === response?.uuid) {
        return {
          ...scriptItem,
          ...response,
        };
      }

      return { ...scriptItem };
    });

    newScriptsByCampaignUUID[response?.campaignUUID] = newScriptsList;

    return {
      ...state,
      scripts: newScripts,
      scriptsByCampaignUUID: newScriptsByCampaignUUID,
      scriptsByUUID: {
        ...state.scriptsByUUID,
        [response?.uuid]: {
          ...response,
        },
      },
    };
  },
});

const deleteScript = httpReducer(DELETE_SCRIPT, initialState, {
  success: (state = initialState, action) => {
    const response = action.payload.resp as { deletedScriptUUID: string };
    const { deletedScriptUUID } = response;

    const newScripts = [...state.scripts];

    const index = newScripts.findIndex((script) => script.uuid === deletedScriptUUID);
    if (index >= 0) {
      newScripts.splice(index, 1);
    }

    const newScriptsByUUID = keyBy(newScripts, "uuid");
    const newScriptByCampaignUUID = groupBy(newScripts, "campaignUUID");

    return {
      ...state,
      scripts: newScripts,
      scriptsByUUID: newScriptsByUUID,
      scriptsByCampaignUUID: newScriptByCampaignUUID,
    };
  },
});

const createMultipleScripts = httpReducer(CREATE_MULTIPLE_SCRIPTS, initialState, {
  success: (state = initialState, action) => {
    const response: CreateMultipleScriptsResponse = action.payload.resp;
    const { successes } = response;

    const newScriptsByUUID = { ...state.scriptsByUUID };

    // Making new Script map and replace new script update instead of merging
    if (Array.isArray(successes) && successes?.length > 0) {
      successes.forEach((script) => {
        newScriptsByUUID[script.uuid] = { ...script };
      });
    }

    const newScripts = Object.values(newScriptsByUUID);
    const newScriptByCampaignUUID = groupBy(newScripts, "campaignUUID");

    return {
      ...state,
      scripts: newScripts,
      scriptsByCampaignUUID: newScriptByCampaignUUID,
      scriptsByUUID: newScriptsByUUID,
      isLoading: false,
    };
  },
});

const updateMultipleScripts = httpReducer(UPDATE_MULTIPLE_SCRIPTS, initialState, {
  success: (state = initialState, action) => {
    const response: UpdateMultipleScriptsResponse = action.payload.resp;
    const { successes } = response;

    const newScriptsByUUID = { ...state.scriptsByUUID };

    // Making new Script map and replace new script update instead of merging
    if (Array.isArray(successes) && successes?.length > 0) {
      successes.forEach((script) => {
        newScriptsByUUID[script.uuid] = { ...script };
      });
    }

    const newScripts = Object.values(newScriptsByUUID);
    const newScriptByCampaignUUID = groupBy(newScripts, "campaignUUID");

    return {
      ...state,
      scripts: newScripts,
      scriptsByCampaignUUID: newScriptByCampaignUUID,
      scriptsByUUID: newScriptsByUUID,
      isLoading: false,
    };
  },
});

const getScriptsForCampaign = httpReducer(GET_SCRIPTS_FOR_CAMPAIGN, initialState, {
  success: (state = initialState, action) => {
    const response: Script[] = action.payload.resp;

    const newScriptsByUUID = { ...state.scriptsByUUID };

    // Add new scripts or replace older ones
    for (const script of response) {
      newScriptsByUUID[script.uuid] = { ...script };
    }

    // Remove any scripts for this particular campaign not from response
    if (response.length > 0) {
      const campaignUUID = response[0].campaignUUID;
      const scriptUUIDsInCampaign = response.map((resp) => resp.uuid);
      const removeIDs = Object.keys(newScriptsByUUID)
        .filter((key) => newScriptsByUUID[key]?.campaignUUID === campaignUUID)
        .filter((key) => !scriptUUIDsInCampaign.includes(key));
      for (const key of removeIDs) {
        delete newScriptsByUUID?.[key];
      }
    }

    const newScripts = Object.values(newScriptsByUUID);

    const newScriptByCampaignUUID = groupBy(newScripts, "campaignUUID");

    return {
      ...state,
      scripts: newScripts,
      scriptsByCampaignUUID: newScriptByCampaignUUID,
      scriptsByUUID: newScriptsByUUID,
      isLoading: false,
    };
  },
});

export default reduceReducers<ScriptReduxState>(
  getScript,
  createScript,
  updateScript,
  deleteScript,
  createMultipleScripts,
  updateMultipleScripts,
  getScriptsForCampaign
);
