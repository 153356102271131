import React from "react";
import { classNames } from "react-extras";
import styles from "./red_dot.module.scss";

const SIZE_DEFAULT = 12;

interface IProps {
  size: number;
  style?: React.CSSProperties;
  className?: string;
}

export default function RedDot({ size = SIZE_DEFAULT, style = {}, className }: IProps) {
  return (
    <div
      data-testid="test-red-dot"
      className={classNames(styles.notification, className)}
      style={{ height: `${size}px`, width: `${size}px`, ...style }}
    />
  );
}
