import React, { Component } from "react";
import { classNames, If } from "react-extras";
import { formatDuration } from "../../../lib/duration";
import { formatPublishDate } from "../../../lib/publish_date";
import MediaPlayer from "../../lib/media_player/media_player";

class PromotionsShowDetailsEpisodeList extends Component {
  render() {
    let { episodes } = this.props;
    return (episodes || []).map((episode) => (
      <PromotionsShowDetailsEpisodeListRow
        episode={episode}
        key={episode.uuid || episode.publishedAt}
      />
    ));
  }
}

class PromotionsShowDetailsEpisodeListRow extends Component {
  state = {
    closed: true,
    hideMedia: true,
  };
  render() {
    let { episode } = this.props;
    return (
      <div className="promotions-show-details-list-row-wrapper">
        <div
          className="promotions-show-details-list-row"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.setState({ closed: !this.state.closed });
            if (this.state.hideMedia) {
              this.setState({ hideMedia: false });
            } else {
              // Give the CSS transition 500 ms to finish before hiding.
              // We unmount the media player to make it stop playing
              // after closing the panel.
              setTimeout(() => this.setState({ hideMedia: true }), 500);
            }
          }}>
          <div className="promotions-show-details-list-row__header">
            <span className="promotions-show-details-list-row__title">{episode.title}</span>
          </div>
          <div className="promotions-show-details-list-row__details">
            <span>{this.state.closed ? "Show" : "Hide"} Details</span>
          </div>
        </div>
        <div
          className={classNames(
            "promotions-show-details-list-row__content",
            !this.state.closed && "promotions-show-details-list-row__content--open"
          )}>
          <If condition={!this.state.hideMedia}>
            <MediaPlayer mediaFileURL={episode.contentURL} />
          </If>
          <div
            dangerouslySetInnerHTML={{
              __html: `${episode.description}`,
            }}
          />
          <div className="promotions-show-details-list-row__info">
            <div className="promotions-show-details-list-row__info-item">
              {formatDuration(episode.duration)}
            </div>
            <div className="promotions-show-details-list-row__info-item">
              {formatPublishDate(episode.publishedAt, true)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PromotionsShowDetailsEpisodeList;
