import React from "react";
import ExternalLink from "src/components/lib/external_link/external_link";
import ProgressBar from "src/components/lib/progress_bar";
import { getImportJobDetail } from "src/lib/import_jobs_helper";
import { IImportJob, IShow } from "redcircle-types";

export default function ImportProgressBar({
  importJobs,
  shows,
}: {
  importJobs: IImportJob[];
  shows: Record<string, IShow>;
}) {
  if (!importJobs) return null;

  const visibleJobs = importJobs.filter(
    (job) => shows[job.showUUID] && shows[job.showUUID].isVisible
  );
  const pendingJobs = visibleJobs.filter(
    (job) => getImportJobDetail(job).shouldShowProgressBar === true
  );
  const initialValue = {
    total: 0,
    current: 0,
    shouldShowProgressBar: false,
    shouldShowContact: false,
  };

  const accumulateJobDetails = (
    accumulator: {
      total: number;
      current: number;
      shouldShowProgressBar: boolean;
      shouldShowContact: boolean;
    },
    job: IImportJob
  ) => {
    const { totalJobs, numEpisodesImported, shouldShowProgressBar, isTimedOut, isUnfinished } =
      getImportJobDetail(job, shows[job.showUUID]);
    if (!isUnfinished) return accumulator;

    accumulator.total += totalJobs;
    accumulator.current += numEpisodesImported;
    accumulator.shouldShowProgressBar = accumulator.shouldShowProgressBar || shouldShowProgressBar;
    accumulator.shouldShowContact = accumulator.shouldShowContact || (isTimedOut && isUnfinished);
    return accumulator;
  };

  const jobDetails = visibleJobs.reduce(accumulateJobDetails, initialValue);
  if (!jobDetails.shouldShowProgressBar) return null;

  return (
    <div className="width-100 m-bm">
      <ProgressBar
        title={getProgressBarTitle(pendingJobs, shows)}
        current={jobDetails.current}
        total={jobDetails.total}
        {...(jobDetails.shouldShowContact && {
          subtitle: (
            <span>
              Import taking too long? contact{" "}
              <ExternalLink href={"https://support.redcircle.com/"}>support</ExternalLink>
            </span>
          ),
        })}
      />
    </div>
  );
}

const getProgressBarTitle = (importJobs: IImportJob[], shows: Record<string, IShow>) => {
  const titles = importJobs.map((job) => shows[job.showUUID]?.title).filter((a) => a);
  return formatTitleString(titles as string[]);
};

const formatTitleString = (showTitles: string[]) => {
  if (!showTitles.length) return null;

  let titles: (React.JSX.Element | string)[] = showTitles.map((title) => (
    <span key={title} className="progress-bar-title">
      {title}
    </span>
  ));
  switch (titles.length) {
    case 1:
      return finalizeTitleString(titles);
    case 2:
      titles.splice(1, 0, ", and ");
      return finalizeTitleString(titles);
    case 3:
      titles.splice(-1, 0, ", and ");
      titles.splice(1, 0, ", ");
      return finalizeTitleString(titles);
    default:
      titles.splice(1, 0, ", ");
      titles.splice(3, 0, ", ");
      titles.splice(5, 0, ", ");
      titles = titles.slice(0, 5);
      titles.push(
        `, and ${showTitles.length - 3} other show${showTitles.length > 4 ? "s" : ""}...`
      );
      return finalizeTitleString(titles);
  }
};

const finalizeTitleString = (titles: (React.JSX.Element | string)[]) => ["Importing ", ...titles];
