import DefaultActionManager from "./default_action_manager";
export const SUBSCRIPTION_FETCH_FOR_USER = "SUBSCRIPTION_FETCH_FOR_USER";
export const SUBSCRIPTION_FETCH = "SUBSCRIPTION_FETCH";
export const ADD_CARD = "ADD_CARD";
export const SUBSCRIBE = "SUBSCRIBE";
export const ENABLE_EXCLUSIVE_CONTENT = "ENABLE_EXCLUSIVE_CONTENT";
export const FETCH_SUBSCRIPTIONS_BY_SHOWUUID = "FETCH_SUBSCRIPTIONS_BY_SHOWUUID";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const UNDO_CANCEL_SUBSCRIPTION = "UNDO_CANCEL_SUBSCRIPTION";
export const GET_MY_CARDS = "GET_MY_CARDS";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const GET_PAYMENT_INFO = "GET_PAYMENT_INFO";
export const REMOVE_CARD = "REMOVE_CARD";

export const fetchSubscriptionForUser = (userUUID) =>
  new DefaultActionManager({
    route: `users/${userUUID}/subscriptions`,
    auth: true,
    actionName: SUBSCRIPTION_FETCH_FOR_USER,
    silent: true,
  }).run();

export const cancelSubscription = (subscriptionUUID) =>
  new DefaultActionManager({
    route: `subscriptions/${subscriptionUUID}`,
    auth: true,
    method: "DELETE",
    actionName: CANCEL_SUBSCRIPTION,
    successMessage: "Subscription successfully cancelled!",
  }).run();

export const undoCancelSubscription = (subscriptionUUID) =>
  new DefaultActionManager({
    route: `subscriptions/${subscriptionUUID}/undo-cancel`,
    auth: true,
    method: "POST",
    actionName: UNDO_CANCEL_SUBSCRIPTION,
    successMessage: "You've successfully resubscribed to this podcast.",
  }).run();

export const fetchSubscription = (subscriptionUUID) =>
  new DefaultActionManager({
    auth: true,
    route: `subscriptions/${subscriptionUUID}`,
    actionName: SUBSCRIPTION_FETCH,
    method: "GET",
    silent: true,
  }).run();

export const addCard = (stripeToken, makeDefault) =>
  new DefaultActionManager({
    auth: true,
    route: `stripe/cards`,
    actionName: ADD_CARD,
    method: "POST",
    body: {
      stripeToken,
      makeDefault,
    },
  }).run();

export const subscribe = (cardIDOnPlatformAccount, amount, interval, showUUID) =>
  new DefaultActionManager({
    auth: true,
    route: `shows/${showUUID}/subscribe`,
    actionName: SUBSCRIBE,
    method: "POST",
    body: {
      cardIDOnPlatformAccount,
      amount,
      interval,
    },
    successMessage: "You are subscribed!",
    withCaptcha: true,
  }).run();

export const enableExclusiveContent = ({
  amount,
  amountWeekly,
  amountAnnual,
  showUUID,
  description,
}) =>
  new DefaultActionManager({
    auth: true,
    route: `shows/${showUUID}/enable-exclusive-content`,
    actionName: ENABLE_EXCLUSIVE_CONTENT,
    method: "POST",
    body: {
      amount,
      amountWeekly,
      amountAnnual,
      interval: "monthly",
      description,
    },
    successMessage: "Subscriptions successfully enabled",
  }).run();

export const fetchSubscriptionsByShowUUID = ({ showUUID }) =>
  new DefaultActionManager({
    silent: true,
    auth: true,
    route: `shows/${showUUID}/subscriptions`,
    actionName: FETCH_SUBSCRIPTIONS_BY_SHOWUUID,
    actionData: {
      showUUID,
    },
  }).run();

export const getMyCards = () =>
  new DefaultActionManager({
    auth: true,
    route: `stripe/cards`,
    actionName: GET_MY_CARDS,
    silent: true,
  }).run();

export const updateSubscription = (cardIDOnPlatformAccount, amount, showUUID) =>
  new DefaultActionManager({
    auth: true,
    route: `subscriptions/${showUUID}`,
    actionName: UPDATE_SUBSCRIPTION,
    body: {
      cardIDOnPlatformAccount,
      amount,
    },
    method: "PATCH",
    withCaptcha: true,
  }).run();

export const getPaymentMethod = (subscriptionUUID) =>
  new DefaultActionManager({
    route: `subscriptions/${subscriptionUUID}/payment-info`,
    actionName: GET_PAYMENT_INFO,
    auth: true,
  }).run();

export const removeCard = (cardID) =>
  new DefaultActionManager({
    route: `stripe/cards/${cardID}`,
    actionName: REMOVE_CARD,
    auth: true,
    method: "DELETE",
  }).run();
