import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { hideModal } from "../../actions/modal";
import ConfirmationModal from "./confirmation_modal";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(hideModal());
    },
  };
}

const ConfirmationModalWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal)
);

export default ConfirmationModalWrapper;
