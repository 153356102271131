import DefaultActionManager from "./default_action_manager";

export const publicShowActionName = "PUBLIC_SHOW_FETCH";
export const getPublicShowsByUUIDs = "GET_PUBLIC_SHOWS_BY_UUIDS";
export const getPublicShowsForSubscriberActionName = "GET_PUBLIC_SHOW_FOR_SUBSCRIBER";

export function fetchPublicShow(showUUID) {
  return new DefaultActionManager({
    route: `public/shows/${showUUID}`,
    actionName: publicShowActionName,
  }).run();
}

export function fetchPublicShowsForUser(userUUID) {
  return new DefaultActionManager({
    auth: true,
    route: `users/${userUUID}/public/shows`,
    actionName: publicShowActionName,
    actionData: {
      userUUID,
    },
  }).run();
}

export function fetchPublicShowForSubscriber(showUUID) {
  return new DefaultActionManager({
    auth: true,
    route: `public/shows/${showUUID}/subscribed`,
    actionName: getPublicShowsForSubscriberActionName,
  }).run();
}

export function fetchPublicShowsByUUIDs(showUUIDs) {
  return new DefaultActionManager({
    auth: true,
    route: `public/shows-by-uuids`,
    body: {
      uuids: showUUIDs,
    },
    actionName: getPublicShowsByUUIDs,
    method: "post",
  }).run();
}
