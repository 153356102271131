import {
  forgotPasswordError,
  forgotPasswordStart,
  forgotPasswordSuccess,
  recoverPasswordError,
  recoverPasswordStart,
  recoverPasswordSuccess,
} from "../actions/forgot_password";
import userAPI from "../api/user";
import ActionManager from "./base";
import DefaultActionManager from "./default_action_manager";

export const LOGIN_BY_ADVERTISER_CONTACT_TOKEN = "LOGIN_BY_ADVERTISER_CONTACT_TOKEN";

export class ForgotPasswordActionManager extends ActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong resetting your password. Please try again later.";
  }

  defaultPreCallActionCreator() {
    return forgotPasswordStart;
  }

  defaultSuccessActionCreator() {
    return forgotPasswordSuccess;
  }

  // Same as above. Override response400 for customization.
  default400ActionCreator() {
    return forgotPasswordError;
  }

  // Same as anbove. Override responseError for customization.
  defaultErrorActionCreator() {
    return forgotPasswordError;
  }

  // Execute the API call.
  execute() {
    // Fill in an API call that returns a promise here.
    return userAPI.forgotPassword(this.args.email);
  }
}

export class RecoverPasswordActionManager extends ActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong recovering your password. Please try again later.";
  }
  defaultPreCallActionCreator() {
    return recoverPasswordStart;
  }

  defaultSuccessActionCreator() {
    return recoverPasswordSuccess;
  }

  // Same as above. Override response400 for customization.
  default400ActionCreator() {
    return recoverPasswordError;
  }

  // Same as anbove. Override responseError for customization.
  defaultErrorActionCreator() {
    return recoverPasswordError;
  }

  execute() {
    return userAPI.recoverPassword(this.args.email, this.args.passwordToken);
  }
}

export const recoverPassword = (email, passwordToken) =>
  new RecoverPasswordActionManager({
    email,
    passwordToken,
  }).run();

export const loginByAdvertiserContactToken = (email, advertiserContactToken) =>
  new DefaultActionManager({
    route: "users/login-with-advertiser-contact-token",
    body: {
      email,
      advertiserContactToken,
    },
    method: "post",
    actionName: LOGIN_BY_ADVERTISER_CONTACT_TOKEN,
  }).run();
