import classNames from "classnames";
import style from "../AudioPlayer.module.scss";
import { useAudioPlayerContext } from "./Wrapper";

export default function AudioPlayerTitle({
  className,
  title,
  subtitle,
}: {
  className?: string;
  title?: string;
  subtitle?: string;
}) {
  const { audioData } = useAudioPlayerContext();
  const { title: audioTitle } = audioData;

  return (
    <div className={classNames("flex-column-container", style.title, className)}>
      <strong className="line-clamp-1" title={title || audioTitle}>
        {title || audioTitle}
      </strong>
      <span className="line-clamp-1 color-primary subtitle3 font-size-sm" title={subtitle}>
        {subtitle}
      </span>
    </div>
  );
}
