import { AnyAction, combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { USER_LOGOUT } from "../actions/user";
import { signOut, updateAccount } from "./account";
import advertiserSignUp from "./advertiser_contacts";
import adPlacementsModal from "./ad_placements_modal";
import app from "./app";
import audioBlocks from "./audio_blocks";
import balance from "./balance";
import brands from "./brands";
import browsePromotion from "./browse_promotion";
import budgets from "./budgets";
import campaigns from "./campaigns/campaigns";
import campaignItems from "./campaign_items/campaign-items";
import credentials from "./credentials";
import customAudios from "./custom_audio";
import deals from "./deals";
import directoryShows from "./directory_shows";
import distributions from "./distributions";
import { emailVerification, sendVerificationEmail } from "./email_verification";
import episodes from "./episodes";
import episodesByShow from "./episode_by_show/episode_by_show";
import episodeDelete from "./episode_delete";
import { episodeFeedImport, showFeedImport } from "./feed_import";
import { forgotPassword, recoverPassword } from "./forgot_password";
import imageUploader from "./image-uploader";
import manualPayouts from "./manual_payouts/manual_payouts";
import markers from "./markers";
import mediaFiles from "./media_file";
import mediaFileUpload from "./media_file_upload";
import messages from "./messages";
import modal from "./modal";
import payments from "./payment";
import permissions from "./permissions";
import programmaticEarnings from "./programmatic_earnings";
import publicShows from "./public_show";
import { putAds } from "./put_ads";
import scheduledJobs from "./scheduled_jobs";
import scripts from "./scripts";
import { showReducer } from "./shows/showReducer";
import showForm from "./show_form";
import signIn from "./sign_in";
import signUp from "./sign_up";
import stats from "./stats";
import statsOptions from "./stats_options";
import stripeConnect from "./stripe_connect";
import subscriptions from "./subscriptions";
import transactions from "./transactions";
import { IRootState } from "./types";
import { user } from "./user";
import vetting from "./vetting";
import youtube from "./youtube";

export function removeItem<T>(array: T[], item: T) {
  const index = array.indexOf(item);
  const copy = [...array];
  if (index !== -1) {
    copy.splice(index, 1);
  }
  return copy;
}

const mainReducer = combineReducers({
  // The redux chrome extension lists these reducers in the
  // order they are defined here, so let's keep them in alpha order.
  adPlacementsModal,
  advertiserSignUp,
  app,
  audioBlocks,
  balance,
  brands,
  browsePromotion,
  budgets,
  campaignItems,
  campaigns,
  credentials,
  customAudios,
  deals,
  directoryShows,
  distributions,
  emailVerification,
  episodeDelete,
  episodeFeedImport,
  episodes,
  episodesByShow,
  forgotPassword,
  form: formReducer,
  imageUploader,
  manualPayouts,
  markers,
  mediaFileUpload,
  mediaFiles,
  messages,
  modal,
  payments,
  permissions,
  programmaticEarnings,
  publicShows,
  putAds,
  recoverPassword,
  scheduledJobs,
  scripts,
  sendVerificationEmail,
  showFeedImport,
  showForm,
  shows: showReducer,
  signIn,
  signOut,
  signUp,
  stats,
  statsOptions,
  stripeConnect,
  subscriptions,
  transactions,
  youtube,
  updateAccount,
  user,
  vetting,
});

// Handles clearing all state on logout.
const rootReducer = (state: IRootState, action: AnyAction) => {
  if (action.type === USER_LOGOUT) {
    return mainReducer(undefined, action);
  }
  return mainReducer(state, action);
};

export default rootReducer;
