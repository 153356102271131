import { Select, Switch, Table } from "antd";
import flatMap from "lodash/flatMap";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";
import reduce from "lodash/reduce";
import take from "lodash/take";
import moment, { Moment } from "moment-timezone";
import numeral from "numeral";
import Papa from "papaparse";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { shallowEqual } from "react-redux";
import BlockFeature from "src/components/lib/block_feature";
import { showError, showInfo } from "../../../../actions/app";
import { DownloadStatsActionManager } from "../../../../action_managers/stats";
import { useDispatchTS, useSelectorTS } from "../../../../hooks/redux-ts";
import BlockSrcImg from "../../../../images/Block_Episode_Performance.png";
import { goToPricingPage } from "../../../../lib/config";
import { IEpisode, IShowMap } from "redcircle-types";
import { downloadFile } from "../../../lib/download_file";
import {
  alignment,
  allowedTimeIntervals,
  ALL_PODCASTS,
  blankIfZero,
  Breakpoint,
  customTooltip,
  DataIsLoadingMessage,
  dateFormatStringDiff,
  DropdownIntervalValues,
  EpisodeSectionAggregateTimeIntervals,
  EpisodeSectionTableDataPoint,
  equalityFunc,
  getDropdownDefaultValueFromDates,
  getDropdownOptionsFromDates,
  getIntervalFromDropdown,
  getMaskFromDropdown,
  getRequestHash,
  paginationConfig,
  RequestResponseDataPoint,
  StatsRequestFilter,
  tooltipTitleFactory,
  useInitialDataRequest,
  useTierAnalyticsPerm,
  yAxisLabelFormatter,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";
import Line from "../RedCircle_graphs/Line";
import TextSwitch from "../TextSwitch";
// TYPES

const switchOptions = ["Select Date Range", "Since Published"] as const;
const orderOptions = ["Top Episodes", "Most Recent"] as const;

interface tableCol {
  title: string;
  dataIndex: keyof EpisodeSectionTableDataPoint;
  className: string;
  align?: (typeof alignment)[number];
  width?: string | number;
  responsive?: Breakpoint[];
  render: typeof renderNames | typeof renderDates | typeof renderDownloads;
  sorter?: (a: EpisodeSectionTableDataPoint, b: EpisodeSectionTableDataPoint) => number;
}

// Generic datapoint for line graph
interface DataPoint {
  date: number | string;
  count: number;
  episode: string;
}

// Takes in a record where each prop is the episodeUUID and the value is the data point,
// loops through all episodeUUIDs values and maps them to an array where each item is the
// noramlized downloads value for 1day, 2day, 3day ... 7day, etc.
const normalizeByDownload = (data: Record<string, EpisodeSectionTableDataPoint>) => {
  const graphData = flatMap(data, (episode) => {
    const result: Array<{
      count: number;
      date: string;
      episode: string;
      episodeTitle: string;
      color?: string;
      showRedirectedAt?: number;
    }> = [];

    Object.keys(episode).forEach((key) => {
      if (key?.endsWith("_day")) {
        const number = parseInt(key.split("_")[0]);
        const title = number > 1 ? `${number} Days` : `${number} Day`;

        if (episode[key as EpisodeSectionAggregateTimeIntervals] > 0) {
          result.push({
            count: episode[key as EpisodeSectionAggregateTimeIntervals],
            date: title,
            episode: episode.episodeUUID,
            color: episode.color,
            episodeTitle: episode.episodeTitle,
            showRedirectedAt: episode.showRedirectedAt,
          });
        }
      }
    });

    return result;
  });

  graphData.sort((a, b) => {
    const day1 = parseInt(a?.date?.split(" ")?.[0]);
    const day2 = parseInt(b?.date?.split(" ")?.[0]);

    if (isNaN(day1) || isNaN(day2)) {
      return 0;
    }

    return day1 - day2;
  });

  return graphData;
};

// Table data calculations
const transformDataToTabledata = (
  data: RequestResponseDataPoint["json"],
  shows: IShowMap,
  episodesMap: Record<string, IEpisode>,
  initialDataIsReady: boolean
) => {
  if (!initialDataIsReady) {
    return [];
  }

  const today = moment().endOf("day").valueOf() + 1;

  const durationMap = allowedTimeIntervals.reduce<
    Record<(typeof allowedTimeIntervals)[number], number>
  >(
    (accu, key) => {
      const numDays = parseInt(key.split("_")?.[0]);
      accu[key] = moment.duration(numDays, "days").valueOf() as number;

      return accu;
    },
    {} as Record<(typeof allowedTimeIntervals)[number], number>
  );

  const aggregatedDataByEpisode = data?.reduce(
    (accu, curr) => {
      const episodeUUID = curr?.pathValues[1];
      if (isEmpty(episodesMap?.[episodeUUID])) {
        return accu;
      }

      if (!isEmpty(accu[episodeUUID])) {
        accu[episodeUUID].downloads += curr?.count;

        allowedTimeIntervals.forEach((key) => {
          const dateLimit = accu[episodeUUID]?.publishedDate * 1000 + durationMap[key];
          const dateCutOff = moment(dateLimit).startOf("day").valueOf();

          accu[episodeUUID][key] =
            curr?.date * 1000 < dateCutOff && dateCutOff <= today
              ? accu[episodeUUID][key] + curr?.count
              : accu[episodeUUID][key];
        });
      } else {
        const episodeTitle = episodesMap?.[episodeUUID].title as string;
        const showUUID = episodesMap?.[episodeUUID].showUUID as string;
        const showTitle = shows?.[showUUID].title as string;
        const publishedDate = episodesMap?.[episodeUUID].publishedAt;

        accu[episodeUUID] = {
          key: episodeUUID,
          episodeUUID,
          episodeTitle,
          showTitle,
          showUUID,
          publishedDate,
          downloads: curr?.count,
          "1_day": 0,
          "2_day": 0,
          "3_day": 0,
          "4_day": 0,
          "5_day": 0,
          "6_day": 0,
          "7_day": 0,
          "30_day": 0,
          "60_day": 0,
          "90_day": 0,
        };

        allowedTimeIntervals.forEach((key) => {
          const dateLimit = accu[episodeUUID]?.publishedDate * 1000 + durationMap[key];
          const dateCutOff = moment(dateLimit).startOf("day").valueOf();

          accu[episodeUUID][key] =
            curr?.date * 1000 < dateCutOff && dateCutOff <= today
              ? curr?.count
              : accu[episodeUUID][key];
        });
      }

      return accu;
    },
    {} as Record<string, EpisodeSectionTableDataPoint>
  );

  const tableData = orderBy(aggregatedDataByEpisode, ["downloads"], ["desc"]);

  // Appending color value to most recent 5 items (length matching line colors array)
  lineColors.forEach((color, index) => {
    if (index < tableData.length) {
      tableData[index] = { ...tableData[index], color };
    }
  });

  return tableData;
};

const getDownloadsByEpMap = (data: RequestResponseDataPoint["json"]) => {
  if (isEmpty(data)) {
    return {};
  }

  const map: Record<string, number> = {};

  return data.reduce((accu, curr) => {
    const [, episodeUUID] = curr?.pathValues;

    accu[episodeUUID] =
      typeof accu[episodeUUID] === "number" ? accu[episodeUUID] + curr?.count : curr?.count;

    return accu;
  }, map);
};

const transformToSincePubData = (
  episodesMap: Record<string, IEpisode>,
  shows: IShowMap,
  selectedShowUUID: string,
  totalDownloadsByEp: Record<string, number>,
  initialDataIsReady: boolean
) => {
  if (isEmpty(episodesMap) || !initialDataIsReady) {
    return [];
  }

  const result = Object.keys(episodesMap).map((episodeUUID) => {
    const { title, showUUID, publishedAt } = episodesMap[episodeUUID];
    let showRedirectedAt = shows?.[showUUID as string]?.redirectedAt;
    let { downloadStats } = episodesMap[episodeUUID];

    if (typeof showRedirectedAt !== "number") {
      showRedirectedAt = 0;
    }

    if (isEmpty(downloadStats) || typeof downloadStats === "undefined") {
      downloadStats = {
        1: -1,
        2: -1,
        3: -1,
        4: -1,
        5: -1,
        6: -1,
        7: -1,
        30: -1,
        60: -1,
        90: -1,
      } as Required<typeof downloadStats>;
    }

    const datapoint: EpisodeSectionTableDataPoint = {
      key: episodeUUID,
      episodeUUID,
      episodeTitle: title as string,
      showTitle: shows[showUUID as string]?.title as string,
      showUUID: showUUID as string,
      publishedDate: publishedAt,
      downloads:
        typeof totalDownloadsByEp[episodeUUID] === "number" ? totalDownloadsByEp[episodeUUID] : 0,
      color: undefined,
      showRedirectedAt,
      "1_day": -1,
      "2_day": -1,
      "3_day": -1,
      "4_day": -1,
      "5_day": -1,
      "6_day": -1,
      "7_day": -1,
      "30_day": -1,
      "60_day": -1,
      "90_day": -1,
    };

    const allowedTimeIntervals: EpisodeSectionAggregateTimeIntervals[] = [
      "1_day",
      "2_day",
      "3_day",
      "4_day",
      "5_day",
      "6_day",
      "7_day",
      "30_day",
      "60_day",
      "90_day",
    ];

    allowedTimeIntervals.forEach((prop) => {
      const dayString = prop.split("_")[0];
      const day = parseInt(dayString) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 30 | 60 | 90;

      if (typeof downloadStats?.[day] === "number") {
        datapoint[prop] = downloadStats[day] as number;
      }
    });

    return datapoint;
  });

  const today = moment().valueOf();
  const filterEpisodes = result?.filter((episode) => {
    const { episodeUUID, showUUID, publishedDate } = episode;

    if (selectedShowUUID !== ALL_PODCASTS) {
      return (
        showUUID === selectedShowUUID &&
        publishedDate * 1000 <= today &&
        episodesMap[episodeUUID]?.isVisible
      );
    }

    return publishedDate * 1000 <= today && episodesMap[episodeUUID]?.isVisible;
  });

  return orderBy(filterEpisodes, ["downloads"], ["desc"]);
};

// Table Column render functions
const renderNames = (name: string, episode: any) => {
  return (
    <div className="episodeContainer">
      <div className="showName">{episode.showTitle}</div>
      <div className="episodeName line-clamp-1">{name}</div>
    </div>
  );
};
const renderDownloads = (num: number, datum: EpisodeSectionTableDataPoint) => {
  if (num >= 0) {
    return numeral(num).format("0,0");
  } else {
    if (datum.publishedDate > (datum.showRedirectedAt as number)) {
      return "TBD";
    } else {
      return "*";
    }
  }
};
const renderDates = (date: number) => moment(date * 1000).format("MMM D, YYYY");

const lineColors = ["#EA404D", "#8B0D38", "#2A76DE", "#577D9E", "#C6C6C6"];

const selectDateRangeCols: tableCol[] = [
  {
    title: "",
    dataIndex: "key",
    className: "analyticsPage-table-colorCell",
    align: alignment[1],
    width: 10,
    render: (key: string, episode: any) => {
      return (
        <div
          style={{
            width: "95%",
            height: "16px",
            minWidth: "95%",
            backgroundColor: `${isEmpty(episode?.color) ? "transparent" : episode?.color}`,
          }}>
          {" "}
        </div>
      );
    },
  },
  {
    title: "Episode Name",
    dataIndex: "episodeTitle",
    className: "analyticsPage-table-cell",
    align: alignment[0],
    render: renderNames,
  },
  {
    title: "Publish Date",
    dataIndex: "publishedDate",
    className: "analyticsPage-table-cell",
    align: alignment[0],
    responsive: ["md", "lg", "xl", "xxl"],
    render: renderDates,
    sorter: (a: EpisodeSectionTableDataPoint, b: EpisodeSectionTableDataPoint) =>
      a.publishedDate - b.publishedDate,
  },
  {
    title: "Downloads",
    dataIndex: "downloads",
    className: "analyticsPage-table-cell",
    align: alignment[2],
    render: renderDownloads,
    sorter: (a: EpisodeSectionTableDataPoint, b: EpisodeSectionTableDataPoint) =>
      a.downloads - b.downloads,
  },
];

const episodesRequestPrefix = "Episode-Performance";

interface IEpisodePerformance {
  dateRange: [Moment, Moment];
  timeZone: string;
  selectedShow: string;
}

const EpisodePerformance: FunctionComponent<IEpisodePerformance> = ({
  dateRange,
  timeZone,
  selectedShow,
}) => {
  const dispatch = useDispatchTS();
  const user = useSelectorTS((state) => state?.user?.user);
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const tierPermission = useTierAnalyticsPerm();

  const episodesByShow = useSelectorTS((state) => state?.episodesByShow);
  const episodesMap = reduce<typeof episodesByShow, Record<string, IEpisode>>(
    episodesByShow,
    (result, value) => {
      return { ...result, ...value.episodes };
    },
    {}
  );

  const [, , initialDataIsReady] = useInitialDataRequest();

  const [isRawDataLoading, setIsRawDataLoading] = useState<boolean>(true);
  const [isSincePubDataLoading, setIsSincePubDataLoading] = useState<boolean>(true);

  const [graphSwitch, setGraphSwitch] = useState<(typeof switchOptions)[number]>(switchOptions[0]);
  const [orderSwitch, setOrderSwitch] = useState<(typeof orderOptions)[number]>(orderOptions[0]);

  const dropdownOptions = getDropdownOptionsFromDates(dateRange);
  const dropdownDefaultValue = getDropdownDefaultValueFromDates(dateRange);

  const [dropdownVal, setDropdownVal] =
    useState<(typeof DropdownIntervalValues)[number]>(dropdownDefaultValue);

  const requestID = getRequestHash(episodesRequestPrefix, selectedShow, dateRange, dropdownVal);
  const sincePubRequestID = `${episodesRequestPrefix}-Since-Piblished-${selectedShow}`;

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[requestID],
    shallowEqual
  );

  const sincePubCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[sincePubRequestID],
    shallowEqual
  );

  // LOGIC STARTING

  // Get select date range table data
  const tableData = useMemo(
    () => transformDataToTabledata(statsCacheData, shows, episodesMap, initialDataIsReady),
    [isRawDataLoading, initialDataIsReady, statsCacheData, shows, episodesMap]
  );

  // Get Since Published total downloads by episode
  const totalDownloadsByEp = getDownloadsByEpMap(sincePubCacheData);

  // Get Since Published table data
  const sincePubTableData = useMemo(
    () =>
      transformToSincePubData(
        episodesMap,
        shows,
        selectedShow,
        totalDownloadsByEp,
        initialDataIsReady
      ),
    [
      initialDataIsReady,
      isSincePubDataLoading,
      selectedShow,
      episodesMap,
      shows,
      totalDownloadsByEp,
    ]
  );

  // Get top 5 by downloads with color prop added, default tabledata calculation orders data by downloads, and adds color prop
  const top5Episodes = take(tableData, 5);
  const top5SincePubEpisodes = take(orderBy(sincePubTableData, ["downloads"], ["desc"]), 5).map(
    (ep, index) => ({
      ...ep,
      color: index < lineColors.length ? lineColors[index] : undefined,
    })
  );

  // Get most recent 5 episodes by published date with color prop added
  const recent5SincePubEpisodes = take(
    orderBy(sincePubTableData, ["publishedDate"], ["desc"]),
    5
  ).map((ep, index) => ({
    ...ep,
    color: index < lineColors.length ? lineColors[index] : undefined,
  }));

  // utility map by episodeUUID
  const top5SincePubEpisodesMap = keyBy(top5SincePubEpisodes, "episodeUUID");
  const recent5SincePubEpisodesMap = keyBy(recent5SincePubEpisodes, "episodeUUID");
  const top5EpisodesMap = keyBy(top5Episodes, "episodeUUID");

  // initial graph data, top 5 episodes by downloads over the time range provided
  const graphData = statsCacheData
    ?.filter((item) => !isEmpty(top5EpisodesMap?.[item?.pathValues?.[1]]))
    ?.map(({ count, date, pathValues }) => {
      const episodeUUID = pathValues[1];
      return {
        count,
        date: date * 1000,
        episode: episodeUUID,
        episodeTitle: top5EpisodesMap?.[episodeUUID].episodeTitle,
        color: top5EpisodesMap?.[pathValues[1]].color,
      };
    });

  // Determine Line graph data based on switches state
  const lineDataProvidedToGraph =
    graphSwitch === switchOptions[0]
      ? graphData
      : orderSwitch === orderOptions[0]
        ? normalizeByDownload(top5SincePubEpisodesMap)
        : normalizeByDownload(recent5SincePubEpisodesMap);

  const dataProvidedTottable =
    graphSwitch === switchOptions[0]
      ? tableData
      : orderSwitch === orderOptions[0]
        ? sincePubTableData
        : orderBy(sincePubTableData, ["publishedDate"], ["desc"]);

  const interval = getIntervalFromDropdown(dropdownVal);

  const selectDateLoading = isRawDataLoading;
  const sincePubLoading = isSincePubDataLoading || !initialDataIsReady;
  const dataIsLoading = selectDateLoading || sincePubLoading;

  // TABLE CONFIG

  // Table Column for Since Publish
  const sincePublishedCols: tableCol[] = useMemo(() => {
    const colorMappingByEpisode =
      graphSwitch === switchOptions[0]
        ? top5EpisodesMap
        : orderSwitch === orderOptions[0]
          ? top5SincePubEpisodesMap
          : recent5SincePubEpisodesMap;
    return [
      {
        title: "",
        dataIndex: "key",
        className: "analyticsPage-table-colorCell",
        align: alignment[1],
        width: 10,
        render: (key: string, episode: any) => {
          return (
            <div
              style={{
                width: "95%",
                height: "16px",
                minWidth: "95%",
                backgroundColor: `${
                  isEmpty(colorMappingByEpisode[episode?.episodeUUID])
                    ? "transparent"
                    : colorMappingByEpisode[episode?.episodeUUID]?.color
                }`,
              }}>
              {" "}
            </div>
          );
        },
      },
      {
        title: "Episode Name",
        dataIndex: "episodeTitle",
        className: "analyticsPage-table-cell",
        align: alignment[0],
        render: renderNames,
      },
      {
        title: "Publish Date",
        dataIndex: "publishedDate",
        className: "analyticsPage-table-cell",
        align: alignment[0],
        responsive: ["md", "lg", "xl", "xxl"],
        width: 120,
        render: renderDates,
      },
      {
        title: "1 Day",
        dataIndex: "1_day",
        className: "analyticsPage-table-cell",
        align: alignment[2],
        responsive: ["md", "lg", "xl", "xxl"],
        width: 92,
        render: renderDownloads,
        sorter: (a: EpisodeSectionTableDataPoint, b: EpisodeSectionTableDataPoint) =>
          a?.["1_day"] - b?.["1_day"],
      },
      {
        title: "7 Days",
        dataIndex: "7_day",
        className: "analyticsPage-table-cell",
        align: alignment[2],
        width: 92,
        render: renderDownloads,
        sorter: (a: EpisodeSectionTableDataPoint, b: EpisodeSectionTableDataPoint) =>
          a?.["7_day"] - b?.["7_day"],
      },
      {
        title: "30 Days",
        dataIndex: "30_day",
        className: "analyticsPage-table-cell",
        align: alignment[2],
        responsive: ["md", "lg", "xl", "xxl"],
        width: 92,
        render: renderDownloads,
        sorter: (a: EpisodeSectionTableDataPoint, b: EpisodeSectionTableDataPoint) =>
          a?.["30_day"] - b?.["30_day"],
      },
      {
        title: "60 Days",
        dataIndex: "60_day",
        className: "analyticsPage-table-cell",
        align: alignment[2],
        responsive: ["md", "lg", "xl", "xxl"],
        width: 92,
        render: renderDownloads,
        sorter: (a: EpisodeSectionTableDataPoint, b: EpisodeSectionTableDataPoint) =>
          a?.["60_day"] - b?.["60_day"],
      },
      {
        title: "Total",
        dataIndex: "downloads",
        className: "analyticsPage-table-cell",
        align: alignment[2],
        responsive: ["md", "lg", "xl", "xxl"],
        width: 92,
        render: renderDownloads,
        sorter: (a: EpisodeSectionTableDataPoint, b: EpisodeSectionTableDataPoint) =>
          a.downloads - b.downloads,
      },
    ];
  }, [
    initialDataIsReady,
    isSincePubDataLoading,
    orderSwitch,
    graphSwitch,
    isEmpty(top5EpisodesMap),
    isEmpty(top5SincePubEpisodesMap),
    isEmpty(recent5SincePubEpisodesMap),
  ]);

  // change table columns depending on Switches State
  const columns = graphSwitch === switchOptions[0] ? selectDateRangeCols : sincePublishedCols;

  // map color to specific line in graph depending on switches' state;
  const colorConfig = ({ episode }: Record<string, any>) => {
    let color: string;

    if (graphSwitch === switchOptions[0]) {
      color = top5EpisodesMap[episode]?.color as string;
    } else {
      if (orderSwitch === orderOptions[0]) {
        color = top5SincePubEpisodesMap[episode]?.color as string;
      } else {
        color = recent5SincePubEpisodesMap[episode]?.color as string;
      }
    }

    return color;
  };

  // map point style
  const pointConfig = {
    shape: "circle",
    size: 3,
    style: (datum: any) => {
      let color;

      if (graphSwitch === switchOptions[0]) {
        color = top5EpisodesMap[datum?.episode]?.color;
      } else {
        if (orderSwitch === orderOptions[0]) {
          color = top5SincePubEpisodesMap[datum?.episode]?.color;
        } else {
          color = recent5SincePubEpisodesMap[datum?.episode]?.color as string;
        }
      }

      return {
        fill: "white",
        stroke: color,
        lineWidth: 0.5,
      };
    },
  };

  // Fetch data within time range selected
  useEffect(() => {
    let id: any;
    if (initialDataIsReady) {
      if (isEmpty(statsCacheData)) {
        setIsRawDataLoading(true);
        id = setTimeout(() => {
          let filters: StatsRequestFilter = {
            isUnique: true,
            arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
            timezone: timeZone,
            interval: getIntervalFromDropdown(dropdownVal),
            bucketTerms: "download.episodeUUID",
          };

          if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
            filters = { ...filters, showUUID: selectedShow };
          }

          dispatch(
            new DownloadStatsActionManager({
              filters,
              user,
              requestID,
            }).run()
          )
            .then((resp: RequestResponseDataPoint) => {
              if (resp?.status === 200) {
                setIsRawDataLoading(false);
              } else {
                dispatch(
                  showInfo("Please wait a few seconds and try the data request again", 5000)
                );
              }
            })
            .catch(() => {
              dispatch(
                showError("An error has occurred please reload the page and try again", 5000)
              );
            });
        }, 250);
      } else {
        setIsRawDataLoading(false);
      }
    }

    return () => id && clearTimeout(id);
  }, [
    dateRange?.[0]?.format(dateFormatStringDiff),
    dateRange?.[1]?.format(dateFormatStringDiff),
    selectedShow,
    dropdownVal,
    initialDataIsReady,
  ]);

  // Fetch All time data for since published section total downloads
  useEffect(() => {
    if (isEmpty(sincePubCacheData)) {
      setIsSincePubDataLoading(true);

      let filters: StatsRequestFilter = {
        isUnique: true,
        timeRange: "allTime",
        timezone: timeZone,
        bucketTerms: "download.episodeUUID",
      };

      if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
        filters = { ...filters, showUUID: selectedShow };
      }

      dispatch(
        new DownloadStatsActionManager({
          filters,
          user,
          requestID: sincePubRequestID,
        }).run()
      )
        .then((resp: RequestResponseDataPoint) => {
          if (resp?.status !== 200) {
            dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
          }
          setIsSincePubDataLoading(false);
        })
        .catch(() => {
          dispatch(showError("An error has occurred please reload the page and try again", 5000));
        });
    } else {
      setIsSincePubDataLoading(false);
    }
  }, [selectedShow]);

  // Handles update to dropdownd default value based on parent date range
  useEffect(() => {
    setDropdownVal(dropdownDefaultValue);
  }, [dropdownDefaultValue]);

  // Handlers
  const handleIntervalChange = (newDropdownValue: (typeof DropdownIntervalValues)[number]) => {
    setDropdownVal(newDropdownValue);
  };

  const handleGraphSwitchChange = (option: any) => setGraphSwitch(option?.value);

  const handleOrderSwitchChange = (val: boolean) =>
    val ? setOrderSwitch(orderOptions[1]) : setOrderSwitch(orderOptions[0]);

  const handleReportDownload = () => {
    if (!dataIsLoading) {
      const sortedData =
        graphSwitch === switchOptions[0]
          ? tableData
          : orderSwitch === orderOptions[0]
            ? sincePubTableData
            : orderBy(sincePubTableData, ["publishedDate"], ["desc"]);

      const csv = Papa.unparse(
        sortedData.map((point) => {
          if (graphSwitch === switchOptions[0]) {
            return {
              PodcastName: point.showTitle,
              EpisodeName: point.episodeTitle,
              PublishDate: moment(point.publishedDate * 1000)
                .utc()
                .format("MM/DD/YYYY hh:mm:ss [UTC]"),
              Downloads: point.downloads,
            };
          } else {
            return {
              PodcastName: point.showTitle,
              EpisodeName: point.episodeTitle,
              PublishDate: moment(point.publishedDate * 1000)
                .utc()
                .format("MM/DD/YYYY hh:mm:ss [UTC]"),
              "1Day_SincePublished": blankIfZero(point["1_day"]),
              "2Day_SincePublished": blankIfZero(point["2_day"]),
              "3Day_SincePublished": blankIfZero(point["3_day"]),
              "4Day_SincePublished": blankIfZero(point["4_day"]),
              "5Day_SincePublished": blankIfZero(point["5_day"]),
              "6Day_SincePublished": blankIfZero(point["6_day"]),
              "7Day_SincePublished": blankIfZero(point["7_day"]),
              "30Day_SincePublished": blankIfZero(point["30_day"]),
              "60Day_SincePublished": blankIfZero(point["60_day"]),
              "90Day_SincePublished": blankIfZero(point["90_day"]),
              Total_Downloads: point.downloads,
            };
          }
        })
      );

      const csvTitle =
        graphSwitch === switchOptions[0]
          ? `EpisodePerformanceReport_${dateRange?.[0]?.format(
              "YYYY_MM_DD"
            )}_to_${dateRange?.[1]?.format("YYYY_MM_DD")}.csv`
          : "EpisodePerformanceReport_SincePublished.csv";

      downloadFile(csvTitle, csv);
    } else {
      dispatch(showInfo(DataIsLoadingMessage, 5000));
    }
  };

  // Tier Permissions
  const showGraph =
    tierPermission.Downloads.EpisodePerformance ||
    (!tierPermission.Downloads.EpisodePerformance && graphSwitch === switchOptions[0]);

  const downloadSettings = showGraph
    ? {
        allow: tierPermission.General.ExportDataWidgetLevel,
        text: "Episode Performance Report",
        hanlder: handleReportDownload,
      }
    : undefined;

  // Sub compoenents
  const renderSelect = () => {
    return (
      <Select
        size="small"
        className="RC-Antd-Override-Dropdown"
        value={dropdownVal}
        onSelect={handleIntervalChange}
        style={{ width: "100px" }}>
        {dropdownOptions.map((val) => {
          return (
            <Select.Option key={val} value={val}>
              {val}
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  const renderSwitch = () => {
    return (
      <div className="flex-row-container justify-center align-center">
        <span className="analyticsPage-text m-a0">{orderOptions[0]}</span>
        <Switch
          className="RC-Antd-Override-Switch"
          checked={orderSwitch === orderOptions[1]}
          onClick={handleOrderSwitchChange}
        />
        <span className="analyticsPage-text m-a0">{orderOptions[1]}</span>
      </div>
    );
  };

  return (
    <InfoCard
      title={{
        text: "Episode Performance",
        eyebrow: (
          <TextSwitch
            onSelect={handleGraphSwitchChange}
            options={[
              { value: "Select Date Range", key: "Select Date Range" },
              { value: "Since Published", key: "Since Published" },
            ]}
          />
        ),
      }}
      download={downloadSettings}>
      <BlockFeature
        block={!showGraph}
        CTA={{
          text: (
            <span className="h3 default-font m-b0 p-hxs text-center">
              <strong>Since Published</strong> available starting from Growth Plan.
            </span>
          ),
          btn: {
            text: "Upgrade Your Plan",
            handler: () => goToPricingPage(),
          },
        }}
        blockByImg={{
          src: BlockSrcImg,
          minHeight: "800px",
        }}>
        <>
          <div className="flex-row-container align-center m-bxxs ">
            <h4 className="h4 m-a0 bold capitalize">Downloads</h4>
            <span style={{ marginLeft: "auto" }}>
              {graphSwitch === switchOptions[0] ? renderSelect() : renderSwitch()}
            </span>
          </div>
          <Line<DataPoint>
            data={lineDataProvidedToGraph}
            loading={graphSwitch === switchOptions[0] ? selectDateLoading : sincePubLoading}
            xField="date"
            yField="count"
            seriesField="episode"
            legend={false}
            point={pointConfig}
            tooltip={{ customContent: customTooltip, title: tooltipTitleFactory(interval) }}
            color={colorConfig}
            meta={{
              date: {
                type: graphSwitch === switchOptions[0] ? "timeCat" : "cat",
                mask: getMaskFromDropdown(dropdownVal),
                range: [0, 1],
              },
              count: {
                formatter: yAxisLabelFormatter,
              },
            }}
          />
          <div className="m-bs" />

          <h4 className="h4 m-a0 m-bxs">
            {graphSwitch === switchOptions[1]
              ? "*Day-specific data unavailable for any episodes not originally published on RedCircle"
              : ""}
          </h4>

          <Table
            className="analyticsPage-table"
            loading={graphSwitch === switchOptions[0] ? selectDateLoading : sincePubLoading}
            dataSource={dataProvidedTottable}
            columns={columns}
            scroll={{ x: true }}
            pagination={paginationConfig}
          />
        </>
      </BlockFeature>
    </InfoCard>
  );
};

const MemoizedEpisodePerformance = React.memo(EpisodePerformance, equalityFunc);

export default MemoizedEpisodePerformance;
