import { Select, Table, Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";
import moment, { Moment } from "moment";
import numeral from "numeral";
import Papa from "papaparse";
import React, { FunctionComponent, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { showError, showInfo } from "../../../../actions/app";
import { DownloadStatsActionManager } from "../../../../action_managers/stats";
import { useDispatchTS, useSelectorTS } from "../../../../hooks/redux-ts";
import { downloadFile } from "../../../lib/download_file";
import {
  alignment,
  ALL_EPISODES,
  ALL_PODCASTS,
  DataIsLoadingMessage,
  dateFormatStringDiff,
  getEpisodeTitleSuffix,
  getListOfEpisodes,
  getRequestHash,
  ListeningCardGraphData,
  middleEllipses,
  RequestResponseDataPoint,
  StatsRequestFilter,
  useSharedFetchStatsOption,
  useTierAnalyticsPerm,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";
import Donut from "../RedCircle_graphs/Donut";

const listeningTypeMap = {
  App: {
    title: "App",
    bucketTerm: "download.metadata.app",
    optionsKey: "app",
    csvTitle: "ListeningAppReport",
    csvColumnTitle: "App",
  },
  Device: {
    title: "Device",
    bucketTerm: "download.metadata.device",
    optionsKey: "device",
    csvTitle: "ListeningDeviceReport",
    csvColumnTitle: "DeviceType",
  },
  Category: {
    title: "OS",
    bucketTerm: "download.metadata.os",
    optionsKey: "os",
    csvTitle: "ListeningOSReport",
    csvColumnTitle: "OS",
  },
};

const transformData = (data: RequestResponseDataPoint["json"]) => {
  if (isEmpty(data)) {
    return [];
  }

  let total = 0;

  const aggregateByApp = data.reduce(
    (accu, curr) => {
      const app = curr?.pathValues?.[1];

      if (accu?.[app]) {
        accu[app] += curr?.count;
      } else {
        accu[app] = curr?.count;
      }

      total += curr?.count;

      return accu;
    },
    {} as Record<string, number>
  );

  return map(aggregateByApp, (value, key) => {
    return {
      key,
      type: key,
      value,
      percent: ((value / total) * 100).toFixed(0),
      total,
    };
  }).sort((a, b) => b.value - a.value);
};

interface IListening {
  dateRange: [Moment, Moment];
  timeZone: string;
  selectedShow: string;
  type: keyof typeof listeningTypeMap;
}

const ListeningCard: FunctionComponent<IListening> = ({
  dateRange,
  timeZone,
  selectedShow,
  type,
}) => {
  const dispatch = useDispatchTS();
  const user = useSelectorTS((state) => state.user.user);
  const tierPermission = useTierAnalyticsPerm();
  const episodesByShow = useSelectorTS((state) => state?.episodesByShow, shallowEqual);
  const statsOptions = useSelectorTS((state) => state?.statsOptions);

  const listeningTypeNameMap = statsOptions[listeningTypeMap[type].optionsKey]?.reduce(
    (accu: Record<string, string>, curr: { key: string; name: string }) => {
      accu[curr.key] = curr?.name;
      return accu;
    },
    {}
  );

  const listOfEpisodes = getListOfEpisodes(selectedShow, episodesByShow);

  const [selectedEpisode, setSelectedEpisode] = useState<string>(ALL_EPISODES);

  const requestPrefix = `Listening-${listeningTypeMap[type].title}-${selectedEpisode}`;
  const cacheID = getRequestHash(requestPrefix, selectedShow, dateRange);

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cacheID],
    shallowEqual
  );

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const [stastOptionsIsLoaded] = useSharedFetchStatsOption();

  const donutGraphIsLoading = isDataLoading || !stastOptionsIsLoaded || isUndefined(statsCacheData);

  const dataProvidedToGraph = transformData(statsCacheData);

  const dataProvidedToTable = dataProvidedToGraph?.slice(0, 5);

  // Table Columns
  const columns = [
    {
      title: listeningTypeMap[type].title,
      dataIndex: "type",
      className: "analyticsPage-table-cell",
      align: alignment[0],
      width: 135,
      render: (type: string, data: any, index: number) => {
        return `${index + 1}. ${listeningTypeNameMap?.[type]}`;
      },
    },
    {
      title: "Downloads",
      dataIndex: "value",
      className: "analyticsPage-table-cell",
      align: alignment[2],
      render: (downloads: number) => numeral(downloads).format("0,0"),
    },
    {
      title: "% of total",
      dataIndex: "percent",
      className: "analyticsPage-table-cell",
      align: alignment[2],
      render: (percent: string) => {
        const number = parseInt(percent);
        if (number < 1) {
          return `< 1%`;
        }
        return `${percent}%`;
      },
    },
  ];

  // Pie Statistic formating

  // Pie chart center text config
  const statistic = {
    title: {
      style: {
        fontFamily: 'Gilroy-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "15px",
        lineHeight: "24px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "150px",
        textAlign: "center",
      },
      formatter: (datum: any, data: any) => {
        if (datum) {
          return listeningTypeNameMap?.[datum?.type];
        }

        if (data?.length > 0) {
          // get the max datum by the value of downloads
          const max = data.reduce((max: any, curr: any) =>
            max?.value >= curr?.value ? max : curr
          );
          return listeningTypeNameMap?.[max?.type];
        }

        return "";
      },
    },
    content: {
      style: {
        fontFamily: 'Gilroy-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "15px",
        lineHeight: "24px",
        fontWeight: "400",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "150px",
        textAlign: "center",
      },
      formatter: (datum: any, data: any) => {
        if (datum) {
          return `${datum?.percent}%`;
        }

        if (data?.length > 0) {
          const max = data.reduce((max: any, curr: any) =>
            max?.value >= curr?.value ? max : curr
          );
          return `${max?.percent}%`;
        }
        return "";
      },
    },
  };

  // Handle data fetching
  useEffect(() => {
    let id: any;

    if (isEmpty(statsCacheData)) {
      setIsDataLoading(true);
      id = setTimeout(() => {
        const filters: StatsRequestFilter = {
          isUnique: true,
          arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
          timezone: timeZone,
          bucketTerms: listeningTypeMap[type].bucketTerm,
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters.showUUID = selectedShow;
        }

        if (selectedShow !== ALL_PODCASTS && selectedEpisode !== ALL_EPISODES) {
          filters.episodeUUID = selectedEpisode;
        }

        dispatch(
          new DownloadStatsActionManager({
            filters,
            user,
            requestID: cacheID,
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status !== 200) {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
            setIsDataLoading(false);
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
          });
      }, 250);
    } else {
      setIsDataLoading(false);
    }

    return () => id && clearTimeout(id);
  }, [
    dateRange?.[0]?.format(dateFormatStringDiff),
    dateRange?.[1]?.format(dateFormatStringDiff),
    selectedShow,
    selectedEpisode,
  ]);

  useEffect(() => {
    setSelectedEpisode(ALL_EPISODES);
  }, [selectedShow]);

  // Handlers
  const handleEpisodeChange = (episode: string) => setSelectedEpisode(episode);
  const handleReportDownload = () => {
    if (!donutGraphIsLoading) {
      const episodeTitle = getEpisodeTitleSuffix(selectedShow, selectedEpisode, episodesByShow);
      const csv = Papa.unparse(
        dataProvidedToGraph.map((item) => {
          return {
            [listeningTypeMap[type].csvColumnTitle]: listeningTypeNameMap?.[item?.type],
            Downloads: item?.value,
            Percent_of_Total: item?.percent,
          };
        })
      );

      downloadFile(
        `${listeningTypeMap[type].csvTitle}_${dateRange?.[0]?.format(
          "YYYY_MM_DD"
        )}_to_${dateRange?.[1]?.format("YYYY_MM_DD")}${episodeTitle}.csv`,
        csv
      );
    } else {
      dispatch(showInfo(DataIsLoadingMessage, 3000));
    }
  };

  return (
    <InfoCard
      title={{
        text: `Listening ${listeningTypeMap[type].title}`,
        eyebrow: (
          <>
            <Tooltip
              title="Select a podcast to filter by episode"
              trigger={selectedShow !== ALL_PODCASTS ? [] : ["hover"]}>
              <Select
                size="small"
                className="RC-Antd-Override-Dropdown"
                value={selectedEpisode}
                onSelect={handleEpisodeChange}
                disabled={selectedShow === ALL_PODCASTS}
                virtual={false}
                dropdownMatchSelectWidth={false}
                dropdownAlign={{
                  points: ["tr", "br"],
                  overflow: { adjustX: true, adjustY: true },
                }}
                dropdownStyle={{ maxWidth: "320px" }}
                style={{ width: "150px" }}>
                {listOfEpisodes.map(({ uuid, title }) => {
                  return (
                    <Select.Option
                      className="RC-Antd-Override-Dropdown-Option"
                      key={uuid}
                      value={uuid}>
                      {middleEllipses(title, 35, 8)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Tooltip>
          </>
        ),
      }}
      download={{
        allow: tierPermission.General.ExportDataWidgetLevel,
        text: `Listening ${listeningTypeMap[type].title} Report`,
        hanlder: handleReportDownload,
      }}>
      <div className="analyticsPage-graphContainer--Donut">
        <Donut<ListeningCardGraphData>
          data={dataProvidedToGraph}
          loading={donutGraphIsLoading}
          angleField="value"
          colorField="type"
          legend={false}
          statistic={statistic}
        />
      </div>
      <Table
        className="analyticsPage-table"
        loading={donutGraphIsLoading}
        dataSource={dataProvidedToTable}
        columns={columns}
        scroll={{ x: true }}
        pagination={false}
      />
    </InfoCard>
  );
};

// Need to optimize and lower re-renders due to multiple request calls both in the current
// component and parent component, moving this to later priority;

const areEqual = (prevProps: IListening, nextProps: IListening) => {
  const dateRangeIsSame =
    moment(prevProps?.dateRange[0]).format(dateFormatStringDiff) ===
      moment(nextProps?.dateRange[0]).format(dateFormatStringDiff) &&
    moment(prevProps?.dateRange[1]).format(dateFormatStringDiff) ===
      moment(nextProps?.dateRange[1]).format(dateFormatStringDiff);

  const timeZoneIsSame = prevProps?.timeZone === nextProps?.timeZone;
  const selectShowIsSame = prevProps?.selectedShow === nextProps?.selectedShow;

  const typeIsSame = prevProps?.type === nextProps?.type;

  return dateRangeIsSame && timeZoneIsSame && selectShowIsSame && typeIsSame;
};

const MemoizedListeningCard = React.memo(ListeningCard, areEqual);

export default MemoizedListeningCard;
