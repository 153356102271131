import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { onCaptchaReady } from "../../../lib/captcha";
import LoggedOutFrame from "../../lib/logged_out_frame";
import ForgotPasswordForm from "./forgot_password_form";

class ForgotPasswordPage extends Component {
  state = {
    submitEnabled: false,
  };

  componentDidMount() {
    onCaptchaReady(() => {
      this.setState({ submitEnabled: true });
    });
  }

  componentDidUpdate() {
    if (this.props.isSuccess) {
      this.props.history.push("/forgot-password-sent");
    }
  }

  render() {
    return (
      <LoggedOutFrame>
        <Row className="forgot-password-row">
          <Col xs={12} md={6} mdOffset={3}>
            <h1 className="sign-in-page__header m-bxs">Forgot Password?</h1>
            <p className="sign-in-page__welcome">
              Enter your email, and we'll send you a link that can reset your password.
            </p>
            <ForgotPasswordForm
              isLoading={this.props.isLoading}
              hasMessage={this.props.hasMessage}
              isError={this.props.isError}
              message={this.props.message}
              onSubmit={this.props.forgotPassword}
              submitEnabled={this.state.submitEnabled}
            />
          </Col>
        </Row>
      </LoggedOutFrame>
    );
  }
}

export default ForgotPasswordPage;
