/*
 * Be sure to test this page in both signed-in and signed-out. shit's pretty cray cray
 * */

import get from "lodash/get";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showError } from "../../../actions/app";
import { hideModal, showModal } from "../../../actions/modal";
import { fetchPublicShowForSubscriber } from "../../../action_managers/public_show";
import { subscribe, updateSubscription } from "../../../action_managers/subscriptions";
import { getShowUUID } from "../../../lib/getShowUUID";
import { getSubscriptionUUID } from "../../../lib/uuid";
import { LISTEN_ON_MODAL } from "../modal_root";
import PaymentModal from "../payments-modal";

class SponsorshipModal extends Component {
  getShow = () => get(this.props, ["publicShows", this.getShowUUID()]);
  getShowUUID = () => getShowUUID(this.props);

  getSubscriptionUUID = () => getSubscriptionUUID(get(this.props.user, "uuid"), this.getShowUUID());

  submitSubscription = async ({ cardID, isEdit, amount, interval }) => {
    const stripeCardID = cardID;
    let resp;
    if (isEdit) {
      resp = await this.props.updateSubscription(stripeCardID, amount, this.getSubscriptionUUID());
    } else {
      resp = await this.props.subscribe(stripeCardID, amount, interval, this.getShowUUID());
    }

    if (resp.status !== 200) {
      resp?.json?.validationErrors?.length > 0 &&
        this.props.showError(resp?.json?.validationErrors?.[0]?.errorMessage);
      return;
    }
    this.props.fetchPublicShowForSubscriber(this.getShowUUID());
    if (!isEdit && resp.json.type === "exclusive") {
      setTimeout(() => this.props.launchListenOnModal(this.getSubscriptionUUID()), 0);
    }
    this.hideModal();
  };
  hideModal = () => {
    if (this.props.closeRoute) {
      this.props.history.push(`/shows/${this.getShowUUID()}/donations`);
    }
    this.props.hideModal();
  };

  isExclusiveEnabled = () => {
    return get(this.getShow(), "subscriptionDetails.exclusiveContentEnabled", false);
  };

  getAmount = () => {
    if (!this.isExclusiveEnabled()) {
      return 0;
    }
    return get(this.getShow(), "subscriptionDetails.amount");
  };
  render() {
    const amount = this.getAmount();
    const options = [];
    const subscriptionDetails = get(this.getShow(), "subscriptionDetails");
    let page1Header = "Select an amount below.";
    if (this.props.mode === "subscribe") {
      page1Header = (
        <span>
          This subscription fee will unlock access to <strong>Exclusive Content</strong>. Enjoy
          immediately, and cancel anytime.
        </span>
      );
      if (subscriptionDetails?.amountWeekly) {
        options.push({
          type: "fixedPrice",
          optionKey: "weeklyAmount",
          amount: subscriptionDetails.amountWeekly,
          interval: "weekly",
          label: "per week",
        });
      }
      // monthly is called `amount`
      if (subscriptionDetails?.amount) {
        options.push({
          type: "fixedPrice",
          optionKey: "monthlyAmount",
          amount: subscriptionDetails.amount,
          interval: "monthly",
          label: "per month",
        });
      }
      if (subscriptionDetails?.amountAnnual) {
        options.push({
          type: "fixedPrice",
          optionKey: "annualAmount",
          amount: subscriptionDetails.amountAnnual,
          interval: "annual",
          label: "per year",
        });
      }
    }
    if (this.props.mode === "custom") {
      page1Header = (
        <span>
          Contribute to this podcast through a monthly <strong>Donation</strong>. Select the amount
          you feel comfortable contributing, and cancel anytime.
        </span>
      );
      options.push({
        type: "custom",
        optionKey: "custom",
        minAmount: amount,
        interval: "monthly",
        label: "per month",
      });
    }

    return (
      <PaymentModal
        {...this.props}
        requireSignin
        submit={this.submitSubscription}
        page1Options={options}
        page1Header={page1Header}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    publicShows: state.publicShows,
    user: state.user.user,
  };
}

export default connect(mapStateToProps, {
  hideModal,
  subscribe,
  showError,
  fetchPublicShowForSubscriber,
  updateSubscription,
  launchListenOnModal: (subscriptionUUID) => showModal(LISTEN_ON_MODAL, { subscriptionUUID }),
})(withRouter(SponsorshipModal));
