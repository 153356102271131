import {
  CREDENTIAL_CREATE,
  CREDENTIAL_DELETE,
  GET_CREDENTIALS_IN_ORG,
} from "../../action_managers/credentials";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { CredentialsReduxState } from "./types";

const initialState: CredentialsReduxState = {
  isLoading: false,
  initialFetched: false,
  credentials: [],
};

export interface credential {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  orgUUID: string;
  claimed: boolean;
  inviteExpiredAt: number;
}

// Credentials are users within the Org
const getCredentialsInOrg = httpReducer(GET_CREDENTIALS_IN_ORG, initialState, {
  success: (state, action) => {
    const mewCredentials = Array.isArray(action?.payload?.resp)
      ? ([...action?.payload?.resp] as credential[])
      : state.credentials;
    return {
      ...state,
      initialFetched: true,
      credentials: mewCredentials,
    };
  },
  failure: (state) => {
    return {
      ...state,
      initialFetched: true,
    };
  },
});

// Credentials are users within the Org
const AddCredential = httpReducer(CREDENTIAL_CREATE, initialState, {
  success: (state, action) => {
    const newCredential = action?.payload?.resp;
    return {
      ...state,
      credentials: [...state.credentials, newCredential],
    };
  },
});

// Credentials are users within the Org
const deleteCredential = httpReducer(CREDENTIAL_DELETE, initialState, {
  success: (state, action) => {
    const deletedCredentialUUID = action?.data?.credentialUUID;
    const newCredentialsList = state?.credentials?.filter(
      (cred) => cred.uuid !== deletedCredentialUUID
    );

    return {
      ...state,
      credentials: newCredentialsList,
    };
  },
});

export default reduceReducers(getCredentialsInOrg, AddCredential, deleteCredential);
