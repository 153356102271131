import { updateCampaignItems } from "src/action_managers/campaigns";
import { ICampaign } from "redcircle-types";
import DefaultActionManager from "../action_managers/default_action_manager";

export const RESET_CAMPAIGNS = "RESET_CAMPAIGNS";
export const FETCH_SHOW_CAMPAIGNS_ACTION = "FETCH_SHOW_CAMPAIGNS";
export const FETCH_SHOW_CAMPAIGN_ACTION = "FETCH_SHOW_CAMPAIGN";
export const RESPOND_TO_CAMPAIGN_OFFER_ACTION = "RESPOND_TO_CAMPAIGN_OFFER";
export const UPDATE_V2_ITEM_CTA = "UPDATE_V2_ITEM_CTA";

export const getShowCampaigns = (showUUID: string) =>
  new DefaultActionManager({
    route: `shows/${showUUID}/campaigns`,
    auth: true,
    actionName: FETCH_SHOW_CAMPAIGNS_ACTION,
    actionData: {
      showUUID,
    },
  }).run();

export const getShowCampaign = (
  showUUID: string,
  campaignUUID: string,
  options: { silent?: boolean } = {}
) => {
  return new DefaultActionManager({
    route: `shows/${showUUID}/campaigns/${campaignUUID}`,
    auth: true,
    actionName: FETCH_SHOW_CAMPAIGN_ACTION,
    actionData: {
      showUUID,
      campaignUUID,
    },
    silent: options.silent,
  }).run();
};

export const acceptCampaignOffer = (campaignItemUUID: string) => {
  return new DefaultActionManager({
    route: `campaign-items/${campaignItemUUID}/respond-to-offer`,
    auth: true,
    actionName: RESPOND_TO_CAMPAIGN_OFFER_ACTION,
    actionData: { campaignItemUUID },
    body: { state: "accepted" },
    method: "post",
  }).run();
};

export const updateCampaignOfferShowNotes = ({
  campaign,
  campaignItemUUID,
  feedCTA,
}: {
  campaign: ICampaign;
  campaignItemUUID: string;
  feedCTA: string;
}) => {
  if (campaign?.isV2) {
    return new DefaultActionManager({
      route: `campaign-items/${campaignItemUUID}/update-cta`,
      auth: true,
      actionName: UPDATE_V2_ITEM_CTA,
      actionData: { campaignItemUUID },
      body: { feedCTA },
      method: "post",
    }).run();
  } else {
    return new DefaultActionManager({
      route: `campaign-items/${campaignItemUUID}/respond-to-offer`,
      auth: true,
      actionName: RESPOND_TO_CAMPAIGN_OFFER_ACTION,
      actionData: { campaignItemUUID },
      body: { state: "accepted", feedCTA },
      method: "post",
    }).run();
  }
};

export const updateCampaignOfferUploadAudio = ({
  campaignItemUUID,
  mediaFileUUID,
}: {
  campaignItemUUID: string;
  mediaFileUUID: string;
}) => {
  return new DefaultActionManager({
    route: `campaign-items/${campaignItemUUID}/respond-to-offer`,
    auth: true,
    actionName: RESPOND_TO_CAMPAIGN_OFFER_ACTION,
    actionData: { campaignItemUUID },
    body: { state: "accepted", mediaFileUUID },
    method: "post",
  }).run();
};

export const declineCampaignOffer = (campaignItemUUID: string, reason?: string) => {
  const body = { state: "declined", declinedReason: reason };
  return new DefaultActionManager({
    route: `campaign-items/${campaignItemUUID}/respond-to-offer`,
    auth: true,
    actionName: RESPOND_TO_CAMPAIGN_OFFER_ACTION,
    actionData: {
      campaignItemUUID,
    },
    body,
    method: "post",
  }).run();
};
