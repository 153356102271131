import { parse } from "query-string";
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { seedcastForm } from "../../../lib/forms";
import FormElement from "../../forms/form_element";
import Spinner from "../../lib/spinner";

class AdminSignInAsFormComponent extends Component {
  componentDidMount() {
    const { email } = parse(window.location.search);

    if (email) {
      this.props.change("loginAsEmail", email);
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Spinner isLoading={this.props.isLoading} />
        <div>
          <Field component={FormElement} name="adminEmail" label="Admin Email" type="email" />
        </div>
        <div>
          <Field
            component={FormElement}
            label="Admin Password"
            name="adminPassword"
            type="password"
          />
        </div>
        <div>
          <Field component={FormElement} name="loginAsEmail" label="Login As Email" type="text" />
        </div>
        <hr className="redcircle-divider" />
        <button type="submit" className="btn btn-primary btn-block sign-in-form__submit-button">
          Sign In
        </button>
      </form>
    );
  }
}

let AdminSignInAsForm = reduxForm({
  form: "admin-sign-in-as",
})(AdminSignInAsFormComponent);
AdminSignInAsForm = seedcastForm(AdminSignInAsForm);

export default AdminSignInAsForm;
