import { Location } from "history";

export const oneLevelUp = nLevelsUp(1);

export function nLevelsUp(n: number) {
  return function moveNLevelsUp(location: Location<unknown>) {
    const parts = location.pathname.split("/");
    return parts.slice(0, parts.length - n).join("/");
  };
}
