import get from "lodash/get";
import React, { Component } from "react";
import { If } from "react-extras";
import { connect } from "react-redux";
import { matchPath, withRouter } from "react-router-dom";
import { showSuccess } from "../../actions/app";
import {
  disconnectYoutube,
  getShow,
  getYoutubeForShow,
  updateShow,
} from "../../action_managers/shows";
import { getSeedcastURL } from "../../lib/config";
import RCButton from "./button/button";
import ExternalLink from "./external_link";
import WarningPanel from "./warning_panel";

class YoutubeIntegration extends Component {
  state = {
    errorMessage: "",
  };
  getShowUUID = () => {
    const paths = matchPath(this.props.location.pathname, {
      path: "/shows/:showUUID",
    });
    return paths.params.showUUID;
  };

  componentDidMount = () => {
    this.getShowReadThrough(this.getShowUUID());
    this.props.getYoutubeForShow(this.getShowUUID()).then((resp) => {
      if (resp.status >= 400 && resp.status < 500) {
        this.setState({
          errorMessage:
            "Uh oh, RedCircle is having trouble fetching your YouTube info. Check back again later.",
        });
      }
    });
  };

  getShowReadThrough = async (showUUID = this.getShowUUID()) => {
    if (this.props.shows[showUUID]) {
      return this.props.shows[showUUID];
    } else {
      return get(await this.props.getShow(this.getShowUUID()), "json");
    }
  };

  getOAuthURL = () => {
    return `https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&scope=https://www.googleapis.com/auth/youtube.upload+https://www.googleapis.com/auth/youtube.readonly&access_type=offline&include_granted_scopes=true&state=state_parameter_passthrough_value&redirect_uri=${getSeedcastURL()}youtube-oauth&response_type=code&client_id=491614943860-a8g1pnihbbl0navttvv4au797ciov7od.apps.googleusercontent.com`;
  };

  getShow = () => {
    return this.props.shows[this.getShowUUID()];
  };

  getSignedInStatus = () => {
    return !!get(this.getShow(), "youtubeAuthToken");
  };

  startOAuth = () => {
    sessionStorage.setItem("oauthShowUUID", this.getShowUUID());
    window.open(this.getOAuthURL(), "_blank");
    this.startPoll();
  };
  startPoll = () => {
    this.poll = setInterval(() => {
      this.props.getShow(this.getShowUUID()).then((resp) => {
        if (resp.status === 200 && get(resp, "json.youtubeAuthToken")) {
          this.setState({
            errorMessage: null,
          });
          this.props.getYoutubeForShow(this.getShowUUID()).then((resp) => {
            if (resp.status >= 200 && resp.status < 300) {
              this.props.showSuccess("You’ve successfully connected your YouTube channel.");
            }
          });
          clearTimeout(this.poll);
        }
      });
    }, 1000);
  };
  renderChannelLinks = () => {
    const channels = get(this.props.channelsByShow, this.getShowUUID(), []);

    if (this.state.errorMessage) {
      return <WarningPanel hideClose>{this.state.errorMessage}</WarningPanel>;
    }

    if (!channels.length) {
      return (
        <WarningPanel hideClose>
          Your Google account does not have a YouTube channel. Click{" "}
          <ExternalLink to={"http://www.youtube.com/create_channel"}>here</ExternalLink> to create
          one
        </WarningPanel>
      );
    }

    if (channels.some((channel) => get(channel, "status.longUploadsStatus") !== "allowed")) {
      return (
        <WarningPanel hideClose>
          Your YouTube channel is not verified. Click{" "}
          <ExternalLink to={"http://www.youtube.com/verify"}>here</ExternalLink> to verify your
          channel
        </WarningPanel>
      );
    }

    return channels.map((channel) => (
      <div key={channel.id}>
        <span className={"bold"}>Connected Channel: </span>
        <ExternalLink
          key={channel.id}
          href={`https://www.youtube.com/channel/${get(channel, "id")}`}>
          {get(channel, "snippet.title")}
        </ExternalLink>
      </div>
    ));
  };

  componentWillUnmount() {
    if (this.poll) {
      clearTimeout(this.poll);
    }
  }

  render() {
    const isSignedIn = this.getSignedInStatus();
    return (
      <div>
        <If condition={!isSignedIn}>
          <RCButton type="primary" onClick={this.startOAuth}>
            Connect Your YouTube
          </RCButton>
        </If>
        <If condition={isSignedIn}>
          <>
            {this.renderChannelLinks()}
            <div
              className="fake-link m-ts"
              onClick={() => {
                this.props.disconnectYoutube(this.getShowUUID()).then(() => {
                  this.props.getShow(this.getShowUUID());
                });
              }}>
              Disconnect
            </div>
          </>
        </If>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    shows: state.shows.shows,
    channelsByShow: state.youtube.channelsByShow,
  }),
  {
    getShow,
    updateShow,
    getYoutubeForShow,
    disconnectYoutube,
    showSuccess,
  }
)(withRouter(YoutubeIntegration));
