import get from "lodash/get";
import React, { Component } from "react";
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import { Choose, If } from "react-extras";
import { matchPath, withRouter } from "react-router-dom";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { distributionKeyNames, distributionKeys } from "../../../constants/distribution";
import { permissionTypes } from "../../../constants/permission_roles";
import { seedcastForm } from "../../../lib/forms";
import { withPermissions } from "../../../lib/permissions-js";
import FormElement from "../../forms/form_element";
import LoadingButton from "../../forms/loading_button/loading_button";
import Divider from "../../lib/divider";
import ExternalLink from "../../lib/external_link";
import FormDropdown from "../../lib/form_dropdown";
import "./distribution_form.scss";

class DistributionForm extends Component {
  state = {
    lastFieldTouched: undefined,
  };

  getShowUUID = () => {
    const paths = matchPath(this.props.location.pathname, {
      path: "/shows/:showUUID",
    });
    return paths.params.showUUID;
  };
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="distributions-modal-intro">
              If you haven’t already, distribute your show to the various platforms so you can be
              heard. To help listeners find your podcast, confirm your distribution on these
              platforms below.
            </div>
          </Col>
        </Row>
        {distributionKeys.map((item) => this.formRow(item))}
        <Row>
          <Col xs={12}>
            <p className="distributions-modal-outtro">
              There's a few other places you can distribute your podcast to, check out{" "}
              <ExternalLink
                href="https://support.redcircle.com/distributing-your-podcast-to-other-directories"
                target="_blank"
                rel="noopener noreferrer">
                this article
              </ExternalLink>{" "}
              for more ways to get your podcast out to the world.
            </p>
          </Col>
        </Row>
      </form>
    );
  }

  // Warning function used by input fields. Making a simple check that input value is not
  // a complete garbage value, reusing this warning flag to disable button component
  warnFunc(value, allvalues, props) {
    const isURL = /^\s*(https:\/\/|http:\/\/|www)/;
    const isShortString = value?.trim().length <= 18;

    if (!isURL.test(value) || isShortString) {
      // set warning to true for this field
      return true;
    }
    // no warnings
    return undefined;
  }

  formRow(row) {
    const { key, name, image, tabs, placeholder, forceComplete, CompletedInstruction } = row;
    const link = get(this.props.distributions[this.getShowUUID()], key);
    const rssFeed = get(this.props.shows[this.getShowUUID()], "rssURL");
    const hasWarning = !!this.props.formSyncWarnings?.[key];
    const hasNotChangedFromInit =
      this.props.formInitialValues?.[key] === this.props.getDistributionFormValue(key);

    let completed = !!get(this.props.distributions[this.getShowUUID()], key);
    let completedText = "Distribution Confirmed";

    if (key === distributionKeyNames.youtube) {
      completed = !!get(this.props.shows[this.getShowUUID()], "youtubeAuthToken");
      completedText = "Connected";
    }
    const canDistribute = this.props.canAccess(
      permissionTypes.editDistribution,
      this.getShowUUID()
    );
    completed = completed || !!forceComplete;
    const shouldDisable = !completed && !canDistribute;
    const disabledText = shouldDisable ? "Contact Org Owner to distribute" : "";
    return (
      <div key={key}>
        <Row className="distribution-form-row" key={"distribution-form-row-" + key}>
          <Col md={1} className="hidden-xs hidden-sm distribution-form-row__image-wrapper">
            <img alt="service logo" src={image} className={"distribution-form-row__image"} />
          </Col>
          <Col md={11} xs={12}>
            <If condition={Array.isArray(tabs) && tabs.length > 0}>
              <FormDropdown
                label={name}
                completed={completed}
                completedText={completedText}
                disabledText={disabledText}
                disabled={shouldDisable}>
                <Choose>
                  <Choose.When condition={completed}>
                    <CompletedInstruction
                      showSuccess={this.props.showSuccess}
                      rssFeed={rssFeed}
                      distributionName={name}
                      distributionLink={link}
                    />
                  </Choose.When>

                  <Choose.When condition={tabs.length > 1 && !completed}>
                    <Tabs id={`tab-${key}`} defaultActiveKey="tab0">
                      {tabs.map((tab, index) => {
                        const { formatTabTitle, Instruction } = tab;
                        return (
                          <Tab
                            key={index}
                            eventKey={`tab${index}`}
                            tabClassName="distribution-form-row--tabs"
                            title={formatTabTitle(name, index)}>
                            <Instruction
                              showUUID={this.getShowUUID()}
                              rssFeed={rssFeed}
                              showSuccess={this.props.showSuccess}
                              completed={completed}>
                              <div className="distribution-form-row__input-container">
                                <Field
                                  component={FormElement}
                                  name={key}
                                  type="text"
                                  validationErrors={this.props.validationErrors}
                                  onFocus={(e) => e.target.select()}
                                  placeholder={placeholder}
                                  warn={this.warnFunc}
                                  validationErrorKeyOverride={"links." + key}
                                />
                                <LoadingButton
                                  Button={Button}
                                  className="btn-primary"
                                  isLoading={this.props.isPutting}
                                  disabled={hasWarning || hasNotChangedFromInit}
                                  type="submit">
                                  Confirm
                                </LoadingButton>
                              </div>
                            </Instruction>
                          </Tab>
                        );
                      })}
                    </Tabs>
                  </Choose.When>

                  <Choose.When condition={tabs.length === 1 && !completed}>
                    <div className="distribution-form-instructions">
                      {tabs.map((tab, index) => {
                        const { Instruction } = tab;
                        // there will only be 1 Instruciton in this array
                        return (
                          <Instruction
                            key={index}
                            showUUID={this.getShowUUID()}
                            rssFeed={rssFeed}
                            howSuccess={this.props.showSuccess}>
                            <div className="distribution-form-row__input-container">
                              <Field
                                component={FormElement}
                                name={key}
                                type="text"
                                validationErrors={this.props.validationErrors}
                                onFocus={(e) => e.target.select()}
                                placeholder={placeholder}
                                warn={this.warnFunc}
                                validationErrorKeyOverride={"links." + key}
                              />
                              <LoadingButton
                                Button={Button}
                                className="btn-primary"
                                isLoading={this.props.isPutting}
                                disabled={hasWarning || hasNotChangedFromInit}
                                type="submit">
                                Confirm
                              </LoadingButton>
                            </div>
                          </Instruction>
                        );
                      })}
                    </div>
                  </Choose.When>
                </Choose>
              </FormDropdown>
            </If>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={11} mdOffset={1}>
            <Divider smallMargin />
          </Col>
        </Row>
      </div>
    );
  }
}

// Helper function
const mapErrorsToReduxForm = (res) => {
  // any responses not 200 ok will be considered an error to redux form , and will integrate/passs erros to redux form
  if (res.status !== 200) {
    let errorObj = "undefined Error occured";
    if (res?.json?.validationErrors) {
      errorObj = res.json.validationErrors.reduce((accu, curr) => {
        const key = curr.fieldName.split(".")[1];
        if (accu?.[key]) {
          accu[key] = curr.errorMessage;
        }
        return accu;
      }, {});
    }
    throw new SubmissionError(errorObj);
  } else {
    return Promise.resolve();
  }
};

let DistributionReduxForm = reduxForm({
  form: "distribution-form",
  onSubmit: (values, dispatch, props) => {
    const paths = matchPath(props.location.pathname, {
      path: "/shows/:showUUID",
    });
    const showUUID = paths.params.showUUID;

    let counter = 0;
    let name = "";
    // Grabbing all values that have changed, if only a single value has changed, will
    // customize success message to appropriate value name;
    const filteredValues = Object.keys(values).reduce((accu, currentKey) => {
      if (
        values[currentKey] !== props.initialValues[currentKey] &&
        !props.syncWarnings[currentKey]
      ) {
        accu[currentKey] = values[currentKey];
        counter++;
        name = distributionKeys.find((item) => item.key === currentKey)?.name;
      }

      return accu;
    }, {});
    const successMessage =
      counter === 1
        ? `You’ve successfully confirmed your distribution to ${name}.`
        : "Distribution settings saved.";
    return counter === 0
      ? undefined
      : props.putDistributions(showUUID, filteredValues, successMessage).then(mapErrorsToReduxForm);
  },
  onSubmitFail: (errors, dispatch, submitError, props) => {
    // On submission fail, reinitialize init values for field inputs and reset touched flag, etc
    const newInitialValues = { ...props.values };
    props.initialize(newInitialValues);
  },
})(withRouter(withPermissions(DistributionForm)));
DistributionReduxForm = seedcastForm(DistributionReduxForm);

export default DistributionReduxForm;
