import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { UPDATE_ACCOUNT_SUCCESS, UPDATE_CURRENT_ROLE } from "../../actions/account";
import { EMAIL_VERIFICATION_SUCCESS } from "../../actions/email_verification";
import {
  RESET_USER,
  USER_ATTRIBUTE,
  USER_FETCH_FAIL,
  USER_FETCH_START,
  USER_FETCH_SUCCESS,
  USER_IMPORT_JOB,
  USER_LOGIN,
  USER_LOGOUT,
} from "../../actions/user";
import { LOGIN_BY_ADVERTISER_CONTACT_TOKEN } from "../../action_managers/forgot_password";
import {
  FETCH_TIER_FOR_ORG,
  GODMODE_UPDATE_TIER_LEVEL,
  UPDATE_TIER,
} from "../../action_managers/tiers";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { userHasRole } from "../../lib/user";
import getDefaultRole from "../../lib/user_roles";
import UserStore from "../../lib/user_store";
import adminLoginReducer from "./admin_log_in_as";
import { initialState } from "./initialState";

const resetUser = (state = initialState, action) => {
  if (action.type === RESET_USER) {
    return {
      ...initialState,
    };
  }

  return state;
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case USER_FETCH_SUCCESS:
    case USER_LOGIN:
    case UPDATE_ACCOUNT_SUCCESS:
      return setCurrentUserOnState(state, action);
    case USER_FETCH_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case EMAIL_VERIFICATION_SUCCESS:
      var newState = cloneDeep(state);
      set(newState, "user.hasVerifiedEmail", true);
      return newState;
    case USER_LOGOUT:
      return {
        isLoading: false,
        user: null,
      };
    case GODMODE_UPDATE_TIER_LEVEL:
      return {
        ...state,
        tier: {
          ...state.tier,
          level: action.payload,
        },
      };
    default:
      return state;
  }
};

const loginWithAdvertiserContactToken = httpReducer(
  LOGIN_BY_ADVERTISER_CONTACT_TOKEN,
  initialState,
  {
    success: (state = initialState, action) =>
      setCurrentUserOnState(state, { user: action.payload.resp }),
  }
);

function userRole(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_ROLE:
      return { ...state, currentRole: action.role };
    default:
      return state;
  }
}

export function setCurrentUserOnState(state = initialState, action) {
  const currentRole = UserStore.getUserRole();
  let roleToSet;
  if (currentRole && userHasRole(action.user, currentRole)) {
    roleToSet = currentRole;
  } else if (action?.user?.roles) {
    roleToSet = getDefaultRole(action.user);
  }
  return {
    ...state,
    isLoading: false,
    user: action.user,
    currentRole: roleToSet,
  };
}

const importJobReducer = httpReducer(
  USER_IMPORT_JOB,
  initialState,
  {
    success: (state = initialState, action) => ({ ...state, importJobs: action.payload.resp }),
    failure: (state = initialState, action) => ({ ...state, error: action.payload.error }),
  },
  { loadingKeyName: "importJobIsLoading" }
);

const AttributeReducer = httpReducer(USER_ATTRIBUTE, initialState, {
  success: (state = initialState, action) => ({ ...state, user: action.payload.resp }),
});

const getTierReducer = httpReducer(FETCH_TIER_FOR_ORG, initialState, {
  success: (state = initialState, action) => ({ ...state, tier: action.payload.resp }),
});

const updateTierReducer = httpReducer(UPDATE_TIER, initialState, {
  success: (state = initialState, action) => ({ ...state, tier: action.payload.resp }),
});

export default reduceReducers(
  resetUser,
  adminLoginReducer,
  user,
  importJobReducer,
  AttributeReducer,
  userRole,
  loginWithAdvertiserContactToken,
  getTierReducer,
  updateTierReducer
);
