import { AnyAction } from "redux";
import {
  FORGOT_PASSWORD_REQUEST_ERROR,
  FORGOT_PASSWORD_REQUEST_START,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  RECOVER_PASSWORD_REQUEST_ERROR,
  RECOVER_PASSWORD_REQUEST_START,
  RECOVER_PASSWORD_REQUEST_SUCCESS,
  RECOVER_PASSWORD_RESET,
} from "../../actions/forgot_password";
import { ForgotPasswordReduxState, RecoverPasswordReduxState } from "./types";

const initialState: ForgotPasswordReduxState = {
  isLoading: false,
  isSuccess: false,
};

export function forgotPassword(state = initialState, action: AnyAction) {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST_START:
      return Object.assign({}, state, {
        isLoading: true,
        isSuccess: false,
      });
    case FORGOT_PASSWORD_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
      });
    case FORGOT_PASSWORD_REQUEST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: false,
      });
    default:
      return state;
  }
}

const initalRecoverState: RecoverPasswordReduxState = {
  isLoading: false,
  errorMessage: "",
  user: null,
  isSuccess: false,
};

export function recoverPassword(state = initalRecoverState, action: AnyAction) {
  switch (action.type) {
    case RECOVER_PASSWORD_REQUEST_START:
      return Object.assign({}, state, {
        isLoading: true,
        isSuccess: false,
      });
    case RECOVER_PASSWORD_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        user: action.user,
        isSuccess: true,
      });
    case RECOVER_PASSWORD_REQUEST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: action.errorMessage,
      });
    case RECOVER_PASSWORD_RESET:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
