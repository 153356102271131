declare global {
  interface Window {
    growsumo: any;
  }
}

export const partnerStackSignUp = ({ name, email }: { name: string; email: string }) => {
  if (window.growsumo) {
    window.growsumo.data.name = name;
    window.growsumo.data.email = email;
    window.growsumo.data.customer_key = email;

    // Register the signup with PartnerStack
    window.growsumo.createSignup();
  }
};
