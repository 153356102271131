import get from "lodash/get";
import { store } from "src";
import { fetchStatOptions } from "src/actions/stats_options";
import UserRoles from "src/constants/roles";
import { ReduxDispatch } from "src/hooks/redux-ts";
import { GoogleTagManager, GTM_CONSTS } from "src/lib/google_tag_manager";
import known_agent_friendly from "src/lib/known_agent_friendly";
import { userHasRole } from "src/lib/user";
import { updateCurrentRole } from "../actions/account";
import { signInError, signInForbidden, signInStart, signInSuccess } from "../actions/sign_in";
import { userLogin } from "../actions/user";
import userAPI from "../api/user";
import getDefaultRole from "../lib/user_roles";
import UserStore, { saveUserRole } from "../lib/user_store";
import { addRoleFetch } from "./account";
import ActionManager, { GetStateFunc, HTTPResponses } from "./base";
import { getCampaignsForUser } from "./campaigns";
import { getMyPermissions, getPermissionPresets } from "./permissions";
import { getShowForUser } from "./shows";
import { fetchTierForOrg } from "./tiers";
import { User, UserRole } from "redcircle-types";

class SignInActionManager extends ActionManager<User> {
  preCall(dispatch: ReduxDispatch) {
    dispatch(signInStart());
  }

  saveRole = (dispatch: ReduxDispatch, role: UserRole) => {
    dispatch(updateCurrentRole(role));
    saveUserRole(role);
  };

  /**
   *
   * TODO - Refactor Heavily (currently its too much scope creep)
   */
  // This is pretty ugly - we're trying to make sure that the role gets added before
  // the sign in result is added to the redux store, and handling some
  // ugly role precedence stuff.
  execute = (dispatch: ReduxDispatch, getState: GetStateFunc) => {
    // Get the user's stored cookie about what role they've selcted
    const currentRole = UserStore.getUserRole();

    return userAPI.signIn(this.args.email, this.args.password).then((response) => {
      if (Math.floor(response.status / 100) !== 2) {
        return response;
      }
      // If the user is signing in as a particular role, set that.
      if (this.args.forRole) {
        this.saveRole(dispatch, this.args.forRole);
      }

      return response.json().then((storedJSON: any) => {
        response.json = () => Promise.resolve(storedJSON);
        if (!this.args.forRole || get(storedJSON, ["roles", this.args.forRole])) {
          // If the user doesn't have a saved role and isn't logging into a specific role, set their role
          // to a reasonable default depending on the roles on their account.
          if (!currentRole && !this.args.forRole) {
            this.saveRole(dispatch, getDefaultRole(storedJSON));
          }
          return response;
        }

        return addRoleFetch(storedJSON, this.args.forRole, dispatch, getState).then(
          (addRoleResponse) => {
            if (addRoleResponse.status === 200 || addRoleResponse.status === 204) {
              storedJSON.roles[this.args.forRole] = true;
            }
            return response;
          }
        );
      });
    });
  };

  responseOK(dispatch: ReduxDispatch, json: User): any {
    dispatch(userLogin(json));
    UserStore.save(json);
    dispatch(signInSuccess());
    GoogleTagManager.configUser(json);
    GoogleTagManager.fireEvent(GTM_CONSTS.LOG_IN_SUCCESS);
  }

  response400(dispatch: ReduxDispatch, json: any): any {
    this.responseError(dispatch, 400, json);
  }

  response403(dispatch: ReduxDispatch, json: any) {
    dispatch(signInForbidden());
    this.showError(dispatch, json.message);
  }

  responseError(dispatch: ReduxDispatch, statusCode: number, json: any) {
    dispatch(signInError());
    this.showError(dispatch, json.message || this.defaultErrorMessage());
  }

  catchError(dispatch: ReduxDispatch) {
    dispatch(signInError());
    this.showError(dispatch, this.defaultErrorMessage());
  }

  postCall(
    dispatch: ReduxDispatch,
    response: HTTPResponses<User, object>,
    getState: GetStateFunc
  ): Promise<HTTPResponses<User, object>> {
    // If the response did not succeed no point in continuing
    if (response.status !== 200) return Promise.resolve(response);

    const user = response.json;

    /**
     * Post login need to reset redux state with new user specific data
     */

    return postLoginDataFetch(user).then(() => response);
  }

  defaultErrorMessage() {
    return "Uh oh, something went wrong signing in. Please try again later.";
  }
}

export default SignInActionManager;

/**
 * Helper function, encapsulates all data fetching required once a user is logged in
 *
 * @param {User} user
 * @param {Partial<{ skipUserPerms: boolean }> } flags
 */
export const postLoginDataFetch = async (
  user: User,
  flags: Partial<{ skipUserPerms: boolean }> = {}
) => {
  const { skipUserPerms = false } = flags;
  try {
    if (!skipUserPerms) {
      await store.dispatch(getMyPermissions());
    }

    await store.dispatch(getPermissionPresets());

    if (userHasRole(user, [UserRoles.Creator, UserRoles.Admin])) {
      const resp = await store.dispatch(fetchStatOptions());
      if (resp.json) {
        // Old local state for user agent info used by widgets in show/episode details pages
        known_agent_friendly.set(resp.json);
      }
    }

    if (userHasRole(user, [UserRoles.Creator, UserRoles.Admin])) {
      await store.dispatch(fetchTierForOrg());
    }

    if (userHasRole(user, [UserRoles.Creator, UserRoles.Admin])) {
      await store.dispatch(getShowForUser(user));
    }

    // Based on user roles, load campaigns
    // this is needed since campaign permissions are
    // stored inside campaigns object
    if (userHasRole(user, [UserRoles.Advertiser, UserRoles.Admin])) {
      await store.dispatch(getCampaignsForUser(user.uuid));
    }
  } catch (err) {
    console.log("error happened", err);
  }
};
