import querystring from "query-string";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Spinner from "../../lib/spinner";

class OAuthRedirectPage extends Component {
  componentDidMount() {
    var queryParams = querystring.parse(window.location.search.substring(1));
    if (queryParams.code && queryParams.state && this.props.user) {
      this.props.connectStripeAccount(
        queryParams.code,
        queryParams.state,
        this.props.user && this.props.user.authToken
      );
    }
  }
  render() {
    return (
      <div style={{ minHeight: "300px" }} className="text-center">
        <h1>Sit Tight, Connecting With Stripe</h1>
        <Spinner isLoading={this.props.isLoading} />
        {this.props.isSuccess && <Redirect to="/monetization" />}
      </div>
    );
  }
}

export default OAuthRedirectPage;
