import { Input } from "antd";
import isString from "lodash/isString";
import truncate from "lodash/truncate";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { classNames, If } from "react-extras";
import { getCampaignsForUser } from "src/action_managers/campaigns";
import BlockFeature from "src/components/lib/block_feature";
import RCButton from "src/components/lib/button/button";
import { showError, showSuccess } from "../../../actions/app";
import { resendCredentialEmailInvite } from "../../../api/org";
import { permissionPresets } from "../../../constants/permission_roles";
import { useCredentialsInOrg, usePermissionsInOrg } from "../../../hooks/org";
import { useDispatchTS, useSelectorTS } from "../../../hooks/redux-ts";
import BlockSrcImg from "../../../images/block_team_seats.png";
import { getPricingPageURL, goToPricingPage } from "../../../lib/config";
import { minimumAllowedTier } from "../../../lib/tier-utils";
import { credential } from "../../../reducers/credentials";
import { IPermissions } from "../../../reducers/permissions";
import { tierLevel } from "../../../reducers/types";
import Divider from "../../lib/divider";
import ExternalLink from "../../lib/external_link";
import AddNewTeamSeatModal from "../../modals/add_new_teamSeats";
import DeleteTeamSeatModal from "../../modals/delete_teamSeats";
import AccessViewer from "./AccessViewer";
import "./team_seats.scss";

// Helpers
const proPlanMaxSeats = 5;
const advertiserMaxSeats = 100;

const sortCredentials = (
  permissionMapByKey: Record<string, IPermissions>,
  userCredUUID: string
) => {
  return (a: credential, b: credential) => {
    if (permissionMapByKey[`${a.uuid}-${a.orgUUID}`]?.preset === permissionPresets.orgAdmin) {
      return -1;
    }

    if (permissionMapByKey[`${b.uuid}-${b.orgUUID}`]?.preset === permissionPresets.orgAdmin) {
      return 1;
    }

    if (a.uuid === userCredUUID) {
      return -1;
    }

    if (b.uuid === userCredUUID) {
      return 1;
    }

    if (a.claimed && !b.claimed) {
      return -1;
    }

    if (b.claimed && !a.claimed) {
      return 1;
    }

    if (!a?.claimed && !b?.claimed) {
      return a?.inviteExpiredAt - b?.inviteExpiredAt;
    }

    return `${a.firstName}${a.lastName}`.localeCompare(`${b.firstName}${b.lastName}`);
  };
};

const UpSellCopy = (tier: keyof typeof tierLevel, numOfSeats: number, isUserOrgOwner: boolean) => {
  switch (tier) {
    case tierLevel.pro:
      return (
        <>
          Your Org currently has {numOfSeats} out of {proPlanMaxSeats} available Team Seats.{" "}
          {isUserOrgOwner && (
            <ExternalLink href={getPricingPageURL()}>Upgrade Your Plan {">"}</ExternalLink>
          )}{" "}
        </>
      );

    case tierLevel.enterprise:
      return (
        <>{`Your Org currently has ${numOfSeats} Team Seat${numOfSeats === 1 ? "" : "s"}.`} </>
      );

    default:
      return null;
  }
};

const TeamSeats = () => {
  const dispatch = useDispatchTS();

  // REDUX STATE
  const { isLoading: userIsLoading, user, tier } = useSelectorTS((state) => state?.user);

  const { credentials, getCredentials, credentialsMapByUUID } = useCredentialsInOrg();
  const { permissionMapByKey, orgOwnerPermission } = usePermissionsInOrg();
  const userOrgPermission = useSelectorTS((state) => state?.permissions?.orgPermission);

  // LOCAL STATE
  const [selectedMember, setSelectedMember] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteTeamseat, setShowDeleteTeamseat] = useState(false);
  const [searchText, setSearchText] = useState("");

  // Handlers
  const handleMemberClick = (uuid: string) => () => setSelectedMember(uuid);
  const handleOpenModal = (showModal: boolean) => () => setShowModal(showModal);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    isString(event?.target?.value) && setSearchText(event?.target?.value);

  const handleResendEmail = (credentialUUID: string) => () => {
    resendCredentialEmailInvite(credentialUUID, user)
      .then((response) => {
        if (!response?.ok) {
          throw new Error("Something went wrong");
        }

        dispatch(showSuccess("Successfully resent invite email", 2000));
        getCredentials();
      })
      .catch(() => {
        dispatch(showError("We could not resend the email, Please try again at a later time"));
      });
  };

  const isCredentialRecent = (credentialUUID: string) => {
    const now = moment();
    const cred = credentialsMapByUUID[credentialUUID];
    const createdAt = moment(cred.inviteExpiredAt * 1000).subtract(14, "days");
    const difference = now.diff(createdAt, "seconds");
    return difference <= 15;
  };

  // Logic
  const isUserOrgOwner = userOrgPermission?.preset === permissionPresets.orgAdmin;

  const filteredCredentials = credentials
    .filter((cred) => {
      if (searchText === "") {
        return true;
      }

      return Boolean(
        cred?.firstName?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          cred?.lastName?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          cred?.email?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
    })
    .sort(sortCredentials(permissionMapByKey, user.credentialUUID));

  // On page load, select first credential
  useEffect(() => {
    const recentNewCred = credentials.find((cred) => isCredentialRecent(cred.uuid));
    let timeOutUUID: NodeJS.Timeout;
    if (recentNewCred) {
      setSelectedMember(recentNewCred?.uuid);
      if (credentials?.length > 5) {
        timeOutUUID = setTimeout(() => {
          const selected = document.getElementsByClassName("teamMember selected")[0];
          selected?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
        }, 500);
      }
    } else {
      setSelectedMember(credentials?.[0]?.uuid);
    }

    return () => {
      timeOutUUID && clearTimeout(timeOutUUID);
    };
  }, [credentials?.length, credentials]);

  useEffect(() => {
    // Will refetch credentials on mount/tab switch
    getCredentials();
  }, []);

  useEffect(() => {
    if (!userIsLoading && user.roles.advertiser) {
      dispatch(getCampaignsForUser(user.uuid));
    }
  }, [userIsLoading, user]);

  const showPage =
    (user.roles?.creator && minimumAllowedTier(tierLevel.pro, tier, false)) ||
    user.roles?.advertiser;

  const showAddUserBtn =
    (!!user.roles.creator &&
      ((tier.level === tierLevel.pro && credentials?.length < proPlanMaxSeats) ||
        tier.level === tierLevel.enterprise)) ||
    (!!user.roles.advertiser && credentials.length < advertiserMaxSeats);

  const showUpgradePlanBtn =
    !!user.roles.creator && tier.level === tierLevel.pro && credentials?.length >= proPlanMaxSeats;
  return (
    <>
      <Grid className="teamSeatsPage" fluid>
        <Row>
          <Col xs={12}>
            <h3 className="m-bxxs">Team Seats</h3>
            <p className="m-a0">Add, manage, and view team members and their permissions.</p>
          </Col>
        </Row>
        <Divider marginTop={16} marginBottom={16} />
        <BlockFeature
          block={!showPage}
          blockByImg={{
            src: BlockSrcImg,
            minHeight: 630,
          }}
          CTA={{
            text: "Team Seats available starting from Pro Plan.",
            btn: {
              text: "Upgrade Your Plan",
              handler: () => goToPricingPage(),
            },
          }}>
          <Row>
            <Col xs={12}>
              <p className="teamSeatsPage-text--3 m-bm m-txs">
                {UpSellCopy(tier?.level, credentials?.length, isUserOrgOwner)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="teamSeatsPage-panel">
                <div className="TeamMembers flex-column-container">
                  <h3 className="m-ts m-lxs">Team Members</h3>
                  <Input.Search
                    id="teamMembers"
                    className="m-bxs m-hxs"
                    style={{ width: "auto" }}
                    placeholder="Search Users"
                    onChange={handleSearchChange}
                    allowClear={true}
                  />
                  <div className="TeamMembers-list">
                    {filteredCredentials?.map((cred) => {
                      return (
                        <TeamMember
                          key={cred?.uuid}
                          isUserOrgOwner={isUserOrgOwner}
                          isCredOrgOwner={cred.uuid === orgOwnerPermission?.credentialUUID}
                          isCredUser={cred?.uuid === user.credentialUUID}
                          {...cred}
                          resendEmail={handleResendEmail}
                          handleClick={handleMemberClick}
                          selected={selectedMember === cred?.uuid}
                        />
                      );
                    })}
                  </div>

                  <If condition={isUserOrgOwner && showAddUserBtn}>
                    <div className="TeamMembers-footer p-as">
                      <RCButton
                        type="primary"
                        size="large"
                        className="width-100"
                        onClick={handleOpenModal(true)}>
                        Add Team Member
                      </RCButton>
                    </div>
                  </If>
                  <If condition={isUserOrgOwner && showUpgradePlanBtn}>
                    <div className="TeamMembers-footer p-as">
                      <RCButton
                        type="primary"
                        size="large"
                        className="width-100"
                        onClick={() => goToPricingPage()}>
                        Upgrade Plan
                      </RCButton>
                    </div>
                  </If>
                </div>
                <AccessViewer
                  credentialUUID={selectedMember}
                  setShowDeleteTeamseat={setShowDeleteTeamseat}
                />
              </div>
            </Col>
          </Row>
        </BlockFeature>
      </Grid>
      <AddNewTeamSeatModal visible={showModal} setVisible={setShowModal} />
      <DeleteTeamSeatModal
        visible={showDeleteTeamseat}
        setVisible={setShowDeleteTeamseat}
        credentialUUID={selectedMember}
      />
    </>
  );
};

interface ITeamMember extends credential {
  selected: boolean;
  isUserOrgOwner: boolean;
  isCredOrgOwner: boolean;
  isCredUser: boolean;
  handleClick: (uuid: string) => () => void;
  resendEmail: (credentialUUID: string) => () => void;
}

// Team Member
const TeamMember: FunctionComponent<ITeamMember> = ({
  uuid,
  firstName,
  lastName,
  email,
  claimed,
  inviteExpiredAt,
  selected,
  resendEmail,
  handleClick,
  isUserOrgOwner,
  isCredOrgOwner,
  isCredUser,
}) => {
  const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
  const textLimit = isCredUser || isCredOrgOwner ? 11 : 12;

  let renderInfo = <p className="teamMember-right--email m-a0">{email}</p>;

  if (!claimed) {
    const today = moment();
    const expire = moment(inviteExpiredAt * 1000);
    const isExpired = today.isAfter(expire);
    const text = `Invite Pending - expire${isExpired ? "d" : "s"} ${expire.format("M/D/YY")}`;

    renderInfo = (
      <>
        <p className={`teamMember-right--invitebtntext m-a0 ${!claimed && "pending"}`}>{text}</p>
        <If condition={isUserOrgOwner}>
          <p className="teamMember-right--invitebtn m-a0 fake-link" onClick={resendEmail(uuid)}>
            Resend Invite
          </p>
        </If>
      </>
    );
  }

  return (
    <div
      className={classNames("teamMember", "m-a0 p-axs pointer flex-row-container", {
        selected,
      })}
      onClick={handleClick(uuid)}>
      <div className="teamMember-left flex-row-container align-center justify-center">
        <div className="teamMember-left--logo">
          <span className="teamMember-left--logo--text uppercase">{initials}</span>
        </div>
      </div>
      <div className="teamMember-right">
        <p className={`teamMember-right--fullname m-a0 ${!claimed && "pending"}`}>
          <span>
            {truncate(firstName, {
              length: textLimit,
            })}
          </span>
          <span>
            {truncate(lastName, {
              length: textLimit,
            })}
          </span>

          <If condition={!isCredUser && isCredOrgOwner}>
            <span className="teamMember-right--invitetext"> (Org Owner)</span>
          </If>

          <If condition={isCredUser}>
            <span className="teamMember-right--invitetext"> (You)</span>
          </If>
        </p>
        {renderInfo}
      </div>
    </div>
  );
};

const MemoizedTeamSeats = React.memo(TeamSeats);

export default MemoizedTeamSeats;
