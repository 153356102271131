import { AnyAction } from "redux";
import { resetCropActionName, updateCropActionName } from "../../actions/image-uploader";
import { ImageUploaderReduxState } from "./types";

const imageCrop = {
  aspect: 1,
};
const initialState: ImageUploaderReduxState = {
  showCrop: false,
  crop: imageCrop,
  fetchedInitial: false,
  fetchingInitial: false,
  initialOnLoads: 0,
  touched: false,
};
const imageUploader = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case updateCropActionName:
      return { ...state, ...action.payload };
    case resetCropActionName:
      return initialState;
    default:
      return state;
  }
};
export default imageUploader;
