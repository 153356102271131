import React, { Component } from "react";
import { classNames } from "react-extras";

class GraphContainer extends Component {
  render() {
    const { className, style } = this.props;

    return (
      <div className={className ? className : "stats-widget-graph-container"} style={style}>
        <div className={classNames("stats-widget-graph-title", this.props.titleClassName)}>
          <h3>{this.props.title}</h3>
        </div>
        <div className="stats-widget-graph">{this.props.children}</div>
      </div>
    );
  }
}

export default GraphContainer;
