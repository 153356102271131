import {
  emailVerificationError,
  emailVerificationStart,
  emailVerificationSuccess,
  sendVerificationEmailError,
  sendVerificationEmailStart,
  sendVerificationEmailSucess,
} from "../actions/email_verification";
import userAPI from "../api/user";
import UserStore from "../lib/user_store";
import ActionManager from "./base";

export class EmailVerificationManager extends ActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong verifying your email. Please try again later.";
  }

  defaultPreCallActionCreator() {
    return emailVerificationStart;
  }

  defaultSuccessActionCreator() {
    return emailVerificationSuccess;
  }

  // Same as above. Override response400 for customization.
  default400ActionCreator() {
    return emailVerificationError;
  }

  // Same as anbove. Override responseError for customization.
  defaultErrorActionCreator() {
    return emailVerificationError;
  }

  responseOK(dispatch, json) {
    super.responseOK(dispatch, json);
    var user = UserStore.get();
    user.hasVerifiedEmail = true;
    UserStore.save(user);
    this.showSuccess(dispatch, "Your email has been verified!");
  }

  // Execute the API call.
  execute() {
    // Fill in an API call that returns a promise here.
    return userAPI.verifyEmail(this.args.verificationToken, this.args.authToken);
  }
}

export class SendVerificationEmailManager extends ActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong sending your verification email. Please try again later.";
  }
  defaultPreCallActionCreator() {
    return sendVerificationEmailStart;
  }

  defaultSuccessActionCreator() {
    return sendVerificationEmailSucess;
  }

  // Same as above. Override response400 for customization.
  default400ActionCreator() {
    return sendVerificationEmailError;
  }

  // Same as anbove. Override responseError for customization.
  defaultErrorActionCreator() {
    return sendVerificationEmailError;
  }

  execute() {
    return userAPI.sendVerificationEmail(this.args.user.authToken);
  }

  responseOK(dispatch, json) {
    super.responseOK(dispatch, json);
    this.showSuccess(
      dispatch,
      "An email has been sent to " +
        this.args.user.email +
        ". Check your inbox to complete verification."
    );
  }
}
