import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FetchUserManager } from "../../../action_managers/account";
import { StripeConnectManager } from "../../../action_managers/stripe_connect";
import OAuthRedirectPage from "./oauth_redirect_page";

function mapStateToProps(state) {
  var props = {
    user: state.user.user,
    isLoading: state.stripeConnect.isLoading || state.user.isLoading,
    isSuccess: state.stripeConnect.isSuccess,
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUser: (userUUID, authToken) => {
      dispatch(
        new FetchUserManager({
          userUUID,
          authToken,
        }).run()
      );
    },
    connectStripeAccount: (code, state, authToken) => {
      dispatch(
        new StripeConnectManager({
          code,
          securityToken: state,
          authToken,
        }).run()
      );
    },
  };
}

const OAuthRedirectPageWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OAuthRedirectPage)
);

export default OAuthRedirectPageWrapper;
