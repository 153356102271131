import { getPodcacheURL } from "./config";

export const getEmbeddableHTML = (showUUID: string, episodeUUID: string) => {
  return `<script async defer onload="redcircleIframe();" src="${getEmbedSrc(
    showUUID,
    episodeUUID
  )}"></script>
    <div class="${getEmbeddableDivClassName(episodeUUID)}"></div>
    <style>
    .redcircle-link:link {
        color: #ea404d;
        text-decoration: none;
    }
    .redcircle-link:hover {
        color: #ea404d;
    }
    .redcircle-link:active {
        color: #ea404d;
    }
    .redcircle-link:visited {
        color: #ea404d;
    }
</style>
<p style="margin-top:3px;margin-left:11px;font-family: sans-serif;font-size: 10px; color: gray;">Powered by <a class="redcircle-link" href="https://redcircle.com?utm_source=rc_embedded_player&utm_medium=web&utm_campaign=embedded_v1">RedCircle</a></p>`;
};

export const getEmbedSrc = (showUUID: string, episodeUUID: string) =>
  `${getPodcacheURL()}embedded-player/sh/${showUUID}/ep/${episodeUUID}`;

export const getEmbeddableDivClassName = (episodeUUID: string) => `redcirclePlayer-${episodeUUID}`;
