import React, { Component } from "react";
import { Button, Col, Grid, Modal, Row } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Spinner from "../lib/spinner";
import "./modal.scss";

class ConfirmationModal extends Component {
  render() {
    if (this.props.isSuccess) {
      return <Redirect to={this.props.nextURL} />;
    }
    return (
      <div className="static-modal">
        <Modal className="confirmation-modal" show={true}>
          <div className="confirmation-modal__body">
            <Grid fluid>
              <Row>
                <Col xs={12}>
                  <Spinner isLoading={this.props.isLoading} />
                  <div className="confirmation-modal__title m-bl">
                    {this.props.modalTitle}
                  </div>
                  <div className="confirmation-modal__message">
                    {this.props.confirmationMessage}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Button className="btn-secondary width-100" onClick={this.props.closeModal}>
                    Cancel
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className="btn-primary width-100"
                    onClick={() => {
                      this.props.closeModal();
                      this.props.callback();
                    }}>
                    {this.props.actionButtonName || "Confirm"}
                  </Button>
                </Col>
              </Row>
            </Grid>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ConfirmationModal;
