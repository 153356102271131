import { useSelectorTS } from "src/hooks/redux-ts";
import { getPromoCode } from "src/lib/campaigns";
import { quillIsEmpty } from "src/lib/forms";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "redcircle-types";

export default function ScriptDetails({
  campaignItem,
  show,
}: {
  campaignItem: ICampaignItem;
  show: IShow;
}) {
  const { scriptsByUUID } = useSelectorTS((state) => state.scripts);
  const script = scriptsByUUID[campaignItem?.scriptUUID ?? ""] ?? {};
  const promoCode = getPromoCode({ campaignItem, show, script }) ?? "";
  const hasReaderGuidance = script?.readerGuidance && !quillIsEmpty(script.readerGuidance);

  return (
    <>
      <p className="redcircle-form-label">Campaign Promo Code</p>
      <p>{promoCode}</p>

      <p className="redcircle-form-label m-tm">Script or Talking Points</p>
      {script?.content ? (
        <div dangerouslySetInnerHTML={{ __html: script.content }} className="text-block-expanded" />
      ) : (
        <p>No script or talking points provided</p>
      )}

      {hasReaderGuidance && script.readerGuidance !== null && (
        <>
          <p className="redcircle-form-label m-tm">Requirements from Brand</p>
          <div
            dangerouslySetInnerHTML={{ __html: script.readerGuidance }}
            className="text-block-expanded"
          />
        </>
      )}
    </>
  );
}
