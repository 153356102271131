import { Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import LargeHeaderButton from "./large_header_button";

export default function ShowListOnboarding() {
  const history = useHistory();

  return (
    <Row>
      <Col xs={24} md={{ offset: 2, span: 20 }}>
        <h2 className="m-tm m-bxs">Welcome to RedCircle, let's get started!</h2>

        <LargeHeaderButton
          backgroundColor={"#EA404D"}
          title="Import Podcast"
          className="m-bxs"
          onClick={() => history.push("/shows/import")}>
          <span>I have an existing podcast that I want to import</span>
          <a
            href="https://support.redcircle.com/migrating-your-existing-podcast"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}>
            <strong>Learn More</strong>
          </a>
        </LargeHeaderButton>

        <LargeHeaderButton
          backgroundColor={"#9FB6C8"}
          title="Create New Podcast"
          onClick={() => history.push("/shows/create")}>
          <span>I’m new to podcasting and want to create a brand new podcast</span>
          <a
            href="https://support.redcircle.com/starting-your-first-podcast"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}>
            <strong>Learn More</strong>
          </a>
        </LargeHeaderButton>
      </Col>
    </Row>
  );
}
