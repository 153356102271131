import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showModal } from "../../../actions/modal";
import { getDealsForUser } from "../../../action_managers/deals";
import { ShowsForUserActionManager } from "../../../action_managers/shows";
import { searchSimilarShows } from "../../../action_managers/show_search";
import { withPermissions } from "../../../lib/permissions-js";
import { getAllAdjacentAudienceBucketSizes, getUserShows } from "../../../lib/show";
import { PROMOTIONS_INTRO_MODAL, PROMOTIONS_OPT_IN_MODAL } from "../../modals/modal_root";
import PromotionsDashboard from "./promotions_dashboard";

const mapStateToProps = (state, ownProps) => {
  const userShows = getUserShows(state.shows.shows);
  const bucketedAudienceSizes = getAllAdjacentAudienceBucketSizes(userShows);
  return {
    isLoading: state.shows.isLoading,
    isLoadingBrowse: state.browsePromotion.categoryShows.isLoading,
    shows: state.shows.shows,
    user: state.user.user,
    categoryShows: state.browsePromotion.categoryShows,
    categoryDirectoryShows: state.browsePromotion.categoryDirectoryShows,
    deals: state.deals,
    userAdjacentAudienceSizes: bucketedAudienceSizes,
    tier: state?.user?.tier,
  };
};

const mapDispatchToProps = {
  searchSimilarShows,
  getDealsForUser,
  fetchShowsForUser: (user) => new ShowsForUserActionManager({ user }).run(),
  showIntroModal: () => showModal(PROMOTIONS_INTRO_MODAL),
  showOptInModal: () => showModal(PROMOTIONS_OPT_IN_MODAL),
};

const PromotionsDashboardWrapper = withPermissions(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PromotionsDashboard))
);

export default PromotionsDashboardWrapper;
