/*
 * action types
 */

export const STATS_REQUEST_RESET = "STATS_REQUEST_RESET";
export const STATS_REQUEST_START = "STATS_REQUEST_START";
export const STATS_REQUEST_SUCCESS = "STATS_REQUEST_SUCCESS";
export const STATS_REQUEST_ERROR = "STATS_REQUEST_ERROR";
export const STATS_REQUEST_SHOW_SELECT = "STATS_REQUEST_SHOW_SELECT";
/*
 * action creators
 */

export function statsRequestStart(requestID) {
  return {
    type: STATS_REQUEST_START,
    requestID: requestID,
  };
}

export function statsRequestReset() {
  return {
    type: STATS_REQUEST_RESET,
  };
}

export function statsRequestSuccess(requestID, stats) {
  return {
    type: STATS_REQUEST_SUCCESS,
    stats: stats,
    requestID: requestID,
  };
}

export function statsRequestError(requestID) {
  return {
    requestID: requestID,
    type: STATS_REQUEST_ERROR,
  };
}

export function statsRequestShowSelect(showUUID) {
  return {
    type: STATS_REQUEST_SHOW_SELECT,
    showUUID: showUUID,
  };
}
