import React, { CSSProperties, ReactNode } from "react";
import { classNames } from "react-extras";
import "./warning_panel.scss";

interface IWarningPanelV2 {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  withShadow?: boolean;
  outline?: boolean;
  style?: CSSProperties;
}

// WarningPanelv2 is a redesign of our warning panel
const WarningPanelv2 = ({
  children,
  className = "",
  disabled = false,
  withShadow = false,
  outline = true,
  style,
}: IWarningPanelV2) => {
  return (
    <div
      className={classNames(
        "warning-panel-v2",
        disabled && "disabled",
        withShadow && "shadow",
        outline && "outline",
        className
      )}
      style={style}>
      {children}
    </div>
  );
};
export default WarningPanelv2;
