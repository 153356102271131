import classNames from "classnames";
import styles from "../AudioPlayer.module.scss";

export default function AudioPlayerImage({
  className,
  imageURL,
  alt,
}: {
  className?: string;
  imageURL?: string;
  alt?: string;
}) {
  if (!imageURL) return null;
  return (
    <img
      src={imageURL}
      alt={alt || "Audio Player Image"}
      width={60}
      height={60}
      className={classNames("box-shadow-3", styles.image, className)}
    />
  );
}
