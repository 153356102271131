import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IRootState } from "../reducers/types";

export const useSelectorTS: TypedUseSelectorHook<IRootState> = useSelector;

export type ReduxDispatch = ThunkDispatch<IRootState, any, Action>;
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}
export const useDispatchTS = useReduxDispatch;
