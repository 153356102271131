import get from "lodash/get";
import { matchPath, RouteComponentProps } from "react-router-dom";

export const getShowUUID = (props: RouteComponentProps) => {
  const a = matchPath(props.location.pathname, { path: "/shows/:showUUID/sponsor" });
  const b = matchPath(props.location.pathname, { path: "/shows/:showUUID/donations" });
  const c = matchPath(props.location.pathname, { path: "/shows/:showUUID/resubscribe" });
  const d = matchPath(props.location.pathname, {
    path: "/shows/:showUUID/exclusive-content",
  });
  return get(a || b || c || d, "params.showUUID");
};
