import DefaultActionManager from "./default_action_manager";

export const distributionsFetchActionName = "DISTRIBUTIONS_FETCH";
export const distributionsPutActionName = "DISTRIBUTIONS_PUT";
export const distributionsDistributeActionName = "DISTRIBUTIONS_DISTRIBUTE";

class DistributionFetchActionManager extends DefaultActionManager {
  responseError(dispatch, status, json) {
    // If the user hasn't created a distribution yet, the back end 404's
    // but we should be chill with this up front.
    if (status !== 404) {
      return super.responseError(dispatch, status, json);
    }
    return dispatch(this.defaultErrorActionCreator()());
  }
}

export function fetchDistributions(showUUID) {
  return new DistributionFetchActionManager({
    auth: true,
    route: `distributions/${showUUID}`,
    actionName: distributionsFetchActionName,
  }).run();
}

export function putDistributions(showUUID, links, successMessage = "Distribution settings saved.") {
  return new DefaultActionManager({
    auth: true,
    route: "distributions",
    actionName: distributionsPutActionName,
    body: {
      showUUID,
      links: links,
    },
    method: "put",
    successMessage,
    messageSize: "large",
  }).run();
}

export function distribute(showUUID, distributionKeys = []) {
  return new DefaultActionManager({
    auth: true,
    route: `distributions/${showUUID}/distribute`,
    actionName: distributionsDistributeActionName,
    method: "post",
    body: {
      showUUID,
      distributionKeys,
    },
  }).run();
}
