import { Select, Switch, Tooltip } from "antd";
import { isUndefined, orderBy } from "lodash";
import isEmpty from "lodash/isEmpty";
import { Moment } from "moment-timezone";
import Papa from "papaparse";
import React, { FunctionComponent, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { showError, showInfo } from "../../../../actions/app";
import { DownloadStatsActionManager } from "../../../../action_managers/stats";
import { useDispatchTS, useSelectorTS } from "../../../../hooks/redux-ts";
import { downloadFile } from "../../../lib/download_file";
import {
  ALL_EPISODES,
  ALL_PODCASTS,
  companyStartDate,
  customTooltip,
  DataIsLoadingMessage,
  dateFormatStringDiff,
  DownloadSectionGraphDataPoint,
  downloadsSectionRequestPrefix,
  DropdownIntervalValues,
  equalityFunc,
  getDropdownDefaultValueFromDates,
  getDropdownOptionsFromDates,
  getDurationTextFromDates,
  getEpisodeTitleSuffix,
  getIntervalFromDropdown,
  getListOfEpisodes,
  getMaskFromDropdown,
  getRequestHash,
  getTotalFromData,
  middleEllipses,
  reportFormatDateFromInterval,
  RequestResponseDataPoint,
  StatsRequestFilter,
  tooltipTitleFactory,
  useTierAnalyticsPerm,
  yAxisLabelFormatter,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";
import Area from "../RedCircle_graphs/Area";

// Utility Functions
const getTitle = (
  total: number,
  dateRange: [Moment, Moment],
  isCumulative: boolean,
  isLoading: boolean
) => {
  if (total === 0 || isLoading) {
    return "";
  }

  return `${yAxisLabelFormatter(total)} ${isCumulative ? "Cumulative " : ""}Downloads ${
    isCumulative ? "" : getDurationTextFromDates(dateRange)
  }`;
};

// Gets original over time data and transforms it to cumulative
const transformDataToCommulative = (data: DownloadSectionGraphDataPoint[], startValue = 0) => {
  const result: DownloadSectionGraphDataPoint[] = [];
  data?.reduce((accu, curr) => {
    result.push({
      date: curr.date,
      count: accu + curr.count,
    });
    return accu + curr.count;
  }, startValue);

  return result;
};

// Time Data from backend is unix timestamp in seconds, transforms to milliseconds
const transformDataToMilliseconds = (data: RequestResponseDataPoint["json"]) => {
  return data?.map((item) => {
    return { date: item.date * 1000, count: item.count };
  });
};

interface IDownloadsSection {
  dateRange: [Moment, Moment];
  timeZone: string;
  selectedShow: string;
}

const DownloadsSection: FunctionComponent<IDownloadsSection> = ({
  dateRange,
  timeZone,
  selectedShow,
}) => {
  const dispatch = useDispatchTS();
  const user = useSelectorTS((state) => state?.user?.user);
  const tierPermission = useTierAnalyticsPerm();
  const episodesByShow = useSelectorTS((state) => state?.episodesByShow, shallowEqual);

  const dropdownOptions = getDropdownOptionsFromDates(dateRange);
  const dropdownDefaultValue = getDropdownDefaultValueFromDates(dateRange);

  const [dropdownVal, setDropdownVal] =
    useState<(typeof DropdownIntervalValues)[number]>(dropdownDefaultValue);

  const listOfEpisodes = getListOfEpisodes(selectedShow, episodesByShow);

  const [selectedEpisode, setSelectedEpisode] = useState<string>(ALL_EPISODES);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCumulative, setIsCumulative] = useState<boolean>(false);

  const [cumulativeStart, setCumulativeStart] = useState<number>(0);
  const [isCumulativeStartLoading, setIsCumulativeStartLoading] = useState<boolean>(true);

  const cacheID = getRequestHash(
    downloadsSectionRequestPrefix,
    `${selectedShow}-${selectedEpisode}`,
    dateRange,
    dropdownVal
  );

  const cumulativeCacheID = `${downloadsSectionRequestPrefix}-${selectedShow}-${selectedEpisode}-${dateRange?.[0]?.format(
    dateFormatStringDiff
  )}`;

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cacheID],
    shallowEqual
  );

  const statsCacheCumulativeData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cumulativeCacheID],
    shallowEqual
  );

  const isGraphLoading = isCumulative
    ? isCumulativeStartLoading &&
      isUndefined(statsCacheCumulativeData) &&
      isUndefined(statsCacheData)
    : isLoading && isUndefined(statsCacheData);

  const data = transformDataToMilliseconds(statsCacheData);

  const cumulativeData = transformDataToCommulative(data, cumulativeStart);

  const startVal = isCumulative ? cumulativeStart : 0;
  const total = getTotalFromData<DownloadSectionGraphDataPoint>(data, startVal);

  const title = getTitle(total, dateRange, isCumulative, isGraphLoading);

  const areaGradient = {
    steps: [
      {
        percent: 0,
        color: "rgba(87, 125, 158, 0.04)",
      },
      {
        percent: 0.5,
        color: "rgba(87, 125, 158, 0.6)",
      },
      {
        percent: 1,
        color: "rgba(87, 125, 158, 1)",
      },
    ],
    direction: 270,
  };

  const interval = getIntervalFromDropdown(dropdownVal);

  // handles data fetching within time Range
  useEffect(() => {
    let id: any;
    if (isEmpty(statsCacheData)) {
      setIsLoading(true);
      id = setTimeout(() => {
        const filters: StatsRequestFilter = {
          isUnique: true,
          arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
          timezone: timeZone,
          interval,
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters.showUUID = selectedShow;
        }

        if (selectedShow !== ALL_PODCASTS && selectedEpisode !== ALL_EPISODES) {
          filters.episodeUUID = selectedEpisode;
        }

        dispatch(
          new DownloadStatsActionManager({
            filters,
            user,
            requestID: cacheID,
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status !== 200) {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
            setIsLoading(false);
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
            setIsLoading(false);
          });
      }, 250);
    } else {
      setIsLoading(false);
    }

    return () => id && clearTimeout(id);
  }, [
    dateRange?.[0]?.format(dateFormatStringDiff),
    dateRange?.[1]?.format(dateFormatStringDiff),
    selectedShow,
    selectedEpisode,
    dropdownVal,
  ]);

  // Hanldes fetching data before startdate in order to get cumulative  data
  useEffect(() => {
    let id: any;
    if (isEmpty(statsCacheCumulativeData)) {
      setIsCumulativeStartLoading(true);
      id = setTimeout(() => {
        const filters: StatsRequestFilter = {
          isUnique: true,
          arbitraryTimeRange: [companyStartDate.unix(), dateRange?.[0]?.unix()].join(","),
          timezone: timeZone,
        };

        if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
          filters.showUUID = selectedShow;
        }

        if (selectedShow !== ALL_PODCASTS && selectedEpisode !== ALL_EPISODES) {
          filters.episodeUUID = selectedEpisode;
        }

        dispatch(
          new DownloadStatsActionManager({
            filters,
            user,
            requestID: cumulativeCacheID,
          }).run()
        )
          .then((resp: RequestResponseDataPoint) => {
            if (resp?.status === 200) {
              const amount = getTotalFromData(resp.json);
              setCumulativeStart(amount);
            } else {
              dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
            }
            setIsCumulativeStartLoading(false);
          })
          .catch(() => {
            dispatch(showError("An error has occured please reload the page and try again", 5000));
            setIsCumulativeStartLoading(false);
          });
      }, 250);
    } else {
      const amount = getTotalFromData(statsCacheCumulativeData);
      setCumulativeStart(amount);
      setIsCumulativeStartLoading(false);
    }

    return () => id && clearTimeout(id);
  }, [dateRange?.[0]?.format(dateFormatStringDiff), selectedShow, selectedEpisode]);

  useEffect(() => {
    setDropdownVal(dropdownDefaultValue);
  }, [dropdownDefaultValue]);

  useEffect(() => {
    setSelectedEpisode(ALL_EPISODES);
  }, [selectedShow]);

  // Handlers
  const handleIntervalChange = (newDropdownValue: (typeof DropdownIntervalValues)[number]) =>
    setDropdownVal(newDropdownValue);

  const handleEpisodeChange = (episode: string) => setSelectedEpisode(episode);

  const handleSwitchChange = (checked: boolean) => setIsCumulative(checked);

  const handleDownload = () => {
    if (!isLoading) {
      const currentData = isCumulative ? cumulativeData : data;
      const orderedData = orderBy(currentData, ["date"], ["desc"]);
      const episodeTitle = getEpisodeTitleSuffix(selectedShow, selectedEpisode, episodesByShow);

      const csv = Papa.unparse(
        orderedData.map((point) => {
          return {
            date: reportFormatDateFromInterval(interval, point?.date),
            downloads: point.count,
          };
        })
      );
      downloadFile(
        `DownloadsReport_${isCumulative ? "Cumulative" : "OverTime"}_${dateRange?.[0]?.format(
          "YYYY_MM_DD"
        )}_to_${dateRange?.[1]?.format("YYYY_MM_DD")}${episodeTitle}.csv`,
        csv
      );
    } else {
      dispatch(showInfo(DataIsLoadingMessage, 3000));
    }
  };

  return (
    <InfoCard
      title={{
        text: "Downloads",
        tooltipText:
          "A user who either downloaded your podcast episode or streamed at least a portion of it within a 24 hour period.",
        divider: true,
        eyebrow: (
          <>
            <Tooltip
              title="Select a podcast to filter by episode"
              trigger={selectedShow !== ALL_PODCASTS ? [] : ["hover"]}>
              <Select
                size="small"
                className="RC-Antd-Override-Dropdown"
                value={selectedEpisode}
                onSelect={handleEpisodeChange}
                disabled={selectedShow === ALL_PODCASTS}
                virtual={false}
                dropdownMatchSelectWidth={false}
                dropdownAlign={{
                  points: ["tr", "br"],
                  overflow: { adjustX: true, adjustY: true },
                }}
                dropdownStyle={{ maxWidth: "320px" }}
                style={{ width: "150px" }}>
                {listOfEpisodes.map(({ uuid, title }) => {
                  return (
                    <Select.Option
                      className="RC-Antd-Override-Dropdown-Option"
                      key={uuid}
                      value={uuid}>
                      {middleEllipses(title, 35, 8)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Tooltip>
          </>
        ),
      }}
      download={{
        allow: tierPermission.General.ExportDataWidgetLevel,
        text: "Downloads Report",
        hanlder: handleDownload,
      }}>
      <div style={{ width: "100%", height: "100%" }}>
        <h3 className="analyticsPage-title">{title}</h3>

        <div className="flex-row-container align-center m-bxxs">
          <h4 className="h4 m-a0 bold capitalize">Downloads</h4>
          <span
            style={{
              height: "6px",
              width: "16px",
              borderRadius: "4px",
              margin: "0px 10px",
              position: "relative",
              bottom: ".125em",
              backgroundColor: `${areaGradient?.steps?.[areaGradient?.steps?.length - 1]?.color}`,
            }}
          />
          <span style={{ marginLeft: "auto" }}>
            <Select
              size="small"
              className="RC-Antd-Override-Dropdown"
              value={dropdownVal}
              onSelect={handleIntervalChange}
              style={{ width: "100px" }}>
              {dropdownOptions.map((val) => {
                return (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                );
              })}
            </Select>
          </span>
        </div>
        <Area<DownloadSectionGraphDataPoint>
          data={isCumulative ? cumulativeData : data}
          loading={isGraphLoading}
          xField="date"
          yField="count"
          tooltip={{
            customContent: customTooltip,
            title: tooltipTitleFactory(interval),
          }}
          meta={{
            date: {
              type: "timeCat",
              mask: getMaskFromDropdown(dropdownVal),
              range: [0, 1],
              nice: true,
            },
            count: {
              formatter: yAxisLabelFormatter,
            },
          }}
          animation={false}
        />
        <div className="flex-row-container justify-center align-center m-vxs">
          <span className="area-switch-text h3 m-a0">Over Time</span>
          <Switch
            className="RC-Antd-Override-Switch m-hs m-yauto"
            checked={isCumulative}
            onClick={handleSwitchChange}
          />
          <span className="area-switch-text h3 m-a0">Cumulative</span>
        </div>
      </div>
    </InfoCard>
  );
};

// Optimizing re-rendering from parent.
const MemoizedDownLoadSection = React.memo(DownloadsSection, equalityFunc);

export default MemoizedDownLoadSection;
