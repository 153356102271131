import { Popover } from "antd";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { Button } from "../../Button";
import { useAudioPlayerContext } from "./Wrapper";

const MAX_RATE = 3;
const MIN_RATE = 0.25;
const RATE_STEP = 0.25;

export default function AudioPlayerPlayRate({ className }: { className?: string }) {
  const { audioData, setPlayRate } = useAudioPlayerContext();
  const { playRate } = audioData;

  const handlePlayRateIncrease = () => {
    if (playRate < MAX_RATE) setPlayRate(playRate + RATE_STEP);
  };

  const handlePlayRateDecrease = () => {
    if (playRate > MIN_RATE) setPlayRate(playRate - RATE_STEP);
  };

  const content = (
    <div className="flex-column-container align-center">
      <Button
        type="link"
        size="small"
        onClick={handlePlayRateIncrease}
        disabled={playRate === MAX_RATE}>
        <AiOutlinePlus />
      </Button>
      <strong className="font-size-lg color-primary">{playRate}x</strong>
      <Button
        type="link"
        size="small"
        onClick={handlePlayRateDecrease}
        disabled={playRate === MIN_RATE}>
        <AiOutlineMinus />
      </Button>
    </div>
  );

  return (
    <Popover content={content} trigger="click">
      <Button type="icon" className={className} size="small">
        <strong className="font-size-lg">{playRate}x</strong>
      </Button>
    </Popover>
  );
}
