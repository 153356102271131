import React, { Component } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { onCaptchaReady } from "../../../lib/captcha";
import { getMegapodURL } from "../../../lib/config";
import LoggedOutFrame from "../../lib/logged_out_frame";
import SignInForm from "./sign_in_form";

class SignInPage extends Component {
  state = {
    submitEnabled: false,
  };

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0 });
    onCaptchaReady(() => {
      this.setState({ submitEnabled: true });
    });
  }

  signIn = (data) => {
    this.props.submitSignIn(data, this.props.forRole);
  };

  render() {
    return (
      <LoggedOutFrame title="Sign In" subtitle="Welcome Back! It's good to see you again.">
        <Row className="sign-up-row">
          <Col xs={24} md={{ span: 12, offset: 6 }}>
            <SignInForm
              onSubmit={this.signIn}
              isLoading={this.props.isLoading}
              hasError={this.props.hasError}
              errorMessage={this.props.errorMessage}
              user={this.props.user}
              submitEnabled={this.state.submitEnabled}
            />
          </Col>
        </Row>

        <Row>
          <Col
            xs={24}
            md={{ span: 12, offset: 6 }}
            className="flex-row-container justify-space-between">
            <a
              className="redcircle-big-link"
              href={getMegapodURL() + "account-create" + window.location.search}>
              Create Account
            </a>
            <Link className="text-end redcircle-big-link" to="/forgot-password">
              Forgot your password?
            </Link>
          </Col>
        </Row>
      </LoggedOutFrame>
    );
  }
}

export default SignInPage;
