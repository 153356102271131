/**
 * There is a common reusable pattern where based on the route a specific show UUID or episode UUID is reqiured
 * so in that route logic we need to check if that show is in state, and if it is not ins state we need to fetch it.
 * then grab that show info. This hook encapsulates that logic
 */

import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { getShowCampaigns } from "src/actions/campaigns";
import { getSampleAdReads } from "src/action_managers/campaigns";
import { fetchDistributions } from "src/action_managers/distributions";
import { getProgrammaticEarningsForShow } from "src/action_managers/programmatic_earnings";
import { fetchPublicShow, fetchPublicShowsByUUIDs } from "src/action_managers/public_show";
import {
  EpisodesByShowActionManager,
  getEpisodeByShow,
  getShow,
  getShowForUser,
} from "src/action_managers/shows";
import { searchShows } from "src/action_managers/show_search";
import { ADVERTISING_OPT_IN_THRESHOLD } from "src/constants/misc";
import { permissionTypes } from "src/constants/permission_roles";
import UserRoles from "src/constants/roles";
import {
  SHOW_SEARCH_FILTER_EPISODE_FREQUENCY,
  SHOW_SEARCH_FILTER_TYPE_ADS,
  SHOW_SEARCH_SORT_OPTIONS,
} from "src/constants/search";
import { HAS_SEEN_ADVERTISING_PAGE } from "src/constants/user_attributes";
import { useShowHasVettingNotification } from "src/hooks/vetting";
import { isCampaignItemPendingAction } from "src/lib/campaigns";
import { useCanAccessBound } from "src/lib/permissions";
import { userHasRole } from "src/lib/user";
import { isUUID } from "src/lib/uuid";
import { IShow, User, IEpisode, ICampaignItem } from "redcircle-types";
import { useQuery } from "./lib";
import { useDispatchTS, useSelectorTS } from "./redux-ts";
import { debounce } from "lodash";

type useShowConfig = {
  showUUID?: string;
};
const MAX_ATTEMPTS = 5;

export const useShow = ({ showUUID }: useShowConfig) => {
  const { isLoading, initialFetched, shows } = useSelectorTS((state) => state.shows);
  const dispatch = useDispatchTS();
  const isUUIDString = isUUID(showUUID);
  const fetchAttempts = useRef<number>(1);

  const currentShow = typeof showUUID === "string" && isUUIDString ? shows?.[showUUID] : undefined;

  // Reset fetch attempts counter on episodeUUID change
  useEffect(() => {
    fetchAttempts.current = 1;
  }, [showUUID]);

  useEffect(() => {
    if (showUUID && isUUIDString && fetchAttempts.current < MAX_ATTEMPTS) {
      if (isLoading) {
        // Wait till it finishes
      } else {
        // Currently not loading and has already fetched shows once
        if (initialFetched) {
          if (!currentShow) {
            fetchAttempts.current++;
            dispatch(getShow(showUUID));
          }
        } else {
          // Currently not loading and has not fetched shows at all
          fetchAttempts.current++;
          dispatch(getShow(showUUID));
        }
      }
    }
  }, [isLoading, showUUID]);

  return { show: currentShow, isLoading: !currentShow };
};

export const usePublicShow = (showUUID: string) => {
  const dispatch = useDispatchTS();

  // check if user is creator, if not shortcut
  const { user } = useSelectorTS((state) => state.user);
  const { shows: userShows } = useSelectorTS((state) => state.shows);
  const userIsAdvertiser = userHasRole(user, [UserRoles.Advertiser, UserRoles.Admin]);
  const creatorCanSearchShow =
    userHasRole(user, [UserRoles.Creator, UserRoles.Admin]) && userShows[showUUID];

  // get whatever data we have from browse to speed loading
  const { search } = useSelectorTS((state) => state.browsePromotion);
  const { results } = search;
  const showInSearch = results?.find((show) => show.uuid === showUUID);

  const publicShows = useSelectorTS((state) => state.publicShows);
  const publicShow = publicShows?.[showUUID];
  const [isInitialLoading, setIsInitialLoading] = useState(!publicShow);

  useEffect(() => {
    if (showUUID) {
      if (!publicShow && (userIsAdvertiser || creatorCanSearchShow)) {
        dispatch(fetchPublicShow(showUUID)).then(() => {
          setIsInitialLoading(false);
        });
      }
      dispatch(getSampleAdReads(showUUID));
    }
  }, [showUUID, creatorCanSearchShow]);

  return { show: (publicShow || showInSearch) as IShow, isLoading: isInitialLoading };
};

// heavyweight hook that will fetch the show, episodes for show, campaignItems for show
type TGetShowDetailOptions = {
  withEpisodes?: boolean;
  withCampaigns?: boolean;
  withDistributions?: boolean;
  withProgrammaticEarnings?: boolean;
};
export const useGetShowDetail = (showUUID: string, options = {}) => {
  const {
    withEpisodes,
    withCampaigns,
    withDistributions,
    withProgrammaticEarnings,
  }: TGetShowDetailOptions = options;

  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);
  const { show, isLoading: isShowLoading } = useShow({ showUUID });
  const { [showUUID]: episodes } = useSelectorTS((state) => state.episodesByShow);
  const { distributions: allDistributions } = useSelectorTS((state) => state.distributions);
  const { campaigns } = useSelectorTS((state) => state.campaigns);
  const { campaignItems } = useSelectorTS((state) => state.campaignItems);
  const distributions = withDistributions ? allDistributions?.[showUUID] : undefined;

  useEffect(() => {
    if (showUUID) {
      if (withEpisodes) dispatch(new EpisodesByShowActionManager({ showUUID, user }).run());
      if (withCampaigns) dispatch(getShowCampaigns(showUUID));
      if (withDistributions) dispatch(fetchDistributions(showUUID));
      if (withProgrammaticEarnings) dispatch(getProgrammaticEarningsForShow(showUUID));
    }
  }, [showUUID]);

  return {
    show,
    episodes: episodes?.episodes,
    isLoading: isShowLoading,
    campaigns,
    campaignItems,
    distributions,
  };
};

export const useGetAllEpisodes = () => {
  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);
  const { shows } = useSelectorTS((state) => state.shows);
  const { isLoading, ...episodesByShow } = useSelectorTS((state) => state.episodesByShow);
  const numShows = Object.keys(shows).length;

  const episodes: Record<string, IEpisode & { show: IShow }> = Object.keys(episodesByShow).reduce(
    (acc, showUUID) => {
      // append show info to episodes
      const showEpisodes: any = episodesByShow[showUUID].episodes;
      Object.keys(showEpisodes).forEach((episodeUUID) => {
        showEpisodes[episodeUUID].show = shows[showUUID];
      });
      return { ...acc, ...showEpisodes };
    },
    {}
  );

  useEffect(() => {
    dispatch(getShowForUser(user));
  }, []);

  useEffect(() => {
    if (numShows > 0) {
      // check if shows is missing episodes
      Object.keys(shows).forEach((showUUID) => {
        if (!episodesByShow[showUUID]) dispatch(getEpisodeByShow(showUUID));
      });
    }
  }, [numShows]);

  return { isLoading, episodes };
};

// fetches all user shows
export const useGetAllUserShows = () => {
  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);
  const { isLoading, shows } = useSelectorTS((state) => state.shows);

  useEffect(() => {
    dispatch(getShowForUser(user));
  }, []);

  const userShows: Record<string, IShow> = Object.values(shows)
    .filter((show: IShow) => show.userUUID === user.uuid)
    .reduce((acc, show) => ({ ...acc, [show.uuid]: show }), {});

  return { isLoading, shows: userShows };
};

// fetches ALL USER SHOWS with ALL CAMPAIGNS
export const useGetAllUserShowsWithCampaigns = (callback?: (response: any) => void) => {
  const dispatch = useDispatchTS();
  const canAccess = useCanAccessBound();
  const { user } = useSelectorTS((state) => state.user);
  const { isLoading, shows } = useSelectorTS((state) => state.shows);
  const { campaigns } = useSelectorTS((state) => state.campaigns);
  const { campaignItems } = useSelectorTS((state) => state.campaignItems);

  const doWeHaveCachedShows = Object.keys(shows).length > 0;
  const [isInitiallyLoading, setInitiallyLoading] = useState(!doWeHaveCachedShows);

  useEffect(() => {
    dispatch(getShowForUser(user))
      .then((res) => {
        if (res.status === 200) {
          res.json
            .filter(
              (show: IShow) =>
                show.isVisible &&
                show.advertisementSettings &&
                canAccess(permissionTypes.editRap, show.uuid)
            )
            .forEach((show: IShow) => {
              dispatch(getShowCampaigns(show.uuid));
            });
        }
        return res;
      })
      .then((res) => {
        if (isInitiallyLoading) setInitiallyLoading(false);
        if (res.status === 200 && callback) {
          callback(res.json);
        }
      });
  }, []);

  const userShows: Record<string, IShow> = Object.values(shows)
    .filter((show: IShow) => show.userUUID === user.uuid)
    .reduce((acc, show) => ({ ...acc, [show.uuid]: show }), {});
  return { isLoading, isInitiallyLoading, shows: userShows, campaigns, campaignItems };
};

const countShowNotifications = (user: User, show: IShow, showCampaignItems: ICampaignItem[]) => {
  const hasEnoughDownloads = show.estimatedWeeklyDownloads > ADVERTISING_OPT_IN_THRESHOLD;
  const hasNotSetupAdvertisement = !show.advertisementSettings;
  const hasSeenAdvertisementForShow = (user?.userAttributes?.[HAS_SEEN_ADVERTISING_PAGE] ?? "")
    .split(",")
    .includes(show.uuid);

  const campaignItemsPendingAction = showCampaignItems.filter((item) =>
    isCampaignItemPendingAction(item)
  );
  const pendingActionCount = campaignItemsPendingAction.length;

  const showNotification =
    (!hasSeenAdvertisementForShow && hasEnoughDownloads && hasNotSetupAdvertisement) ||
    !!pendingActionCount;

  return { showNotification, count: pendingActionCount };
};

// determines whether show has an advertising notification available, and how many pending actions
export const useShowNotification = (showUUID: string) => {
  const { user } = useSelectorTS((state) => state.user);
  const { show } = useShow({ showUUID });
  const { campaignItems, showCampaignItemUUIDs } = useSelectorTS((state) => state.campaignItems);
  const hasVettingNotification = useShowHasVettingNotification(showUUID);

  if (!show) return { showNotification: false, count: 0 };

  const showCampaignItems = (showCampaignItemUUIDs[showUUID] ?? []).map(
    (itemUUID) => campaignItems[itemUUID]
  );
  const showNotification = countShowNotifications(user, show, showCampaignItems);

  return {
    showNotification: showNotification.showNotification || hasVettingNotification,
    count: showNotification.count,
  };
};

// same but for all shows
export const useAllShowNotifications = () => {
  const canAccess = useCanAccessBound();
  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);
  const { shows } = useSelectorTS((state) => state.shows);
  const { campaignItems, showCampaignItemUUIDs } = useSelectorTS((state) => state.campaignItems);

  // if missing shows fetch
  useEffect(() => {
    if (user && userHasRole(user, UserRoles.Creator) && !Object.keys(shows).length)
      dispatch(getShowForUser(user));
  }, [user]);

  // check shows for any missing campaigns
  useEffect(() => {
    Object.values(shows).forEach((show) => {
      if (
        !showCampaignItemUUIDs[show.uuid] &&
        show.isVisible &&
        show.advertisementSettings &&
        canAccess(permissionTypes.editRap, show.uuid)
      ) {
        dispatch(getShowCampaigns(show.uuid));
      }
    });
  }, [shows]);

  const allNotifications = Object.values(shows).map((show) => {
    const showCampaignItems = (showCampaignItemUUIDs[show.uuid] ?? []).map(
      (itemUUID) => campaignItems[itemUUID]
    );
    return countShowNotifications(user, show, showCampaignItems);
  });

  return allNotifications.reduce(
    (acc, value) => {
      if (value.showNotification && !acc.showNotification) acc.showNotification = true;
      if (value.count) acc.count += value.count;
      return acc;
    },
    { showNotification: false, count: 0 }
  );
};

const DEFAULT_SORT_KEY = "downloadsDesc";
const DEFAULT_PAGE_SIZE = 100;
interface IShowSearchOptions {
  sort?: keyof typeof SHOW_SEARCH_SORT_OPTIONS;
  page?: number;
  categoryUUID?: string;
  minEstimatedWeeklyDownloads?: number;
  maxEstimatedWeeklyDownloads?: number;
  minAverageCPM?: number;
  maxAverageCPM?: number;
  episodeFrequency?: keyof typeof SHOW_SEARCH_FILTER_EPISODE_FREQUENCY;
  createdAtSince?: number;
  primaryGender?: string;
  includeNSFW?: boolean;
}
export const useShowSearch = (query: string, options: IShowSearchOptions = {}) => {
  const dispatch = useDispatchTS();
  const [isInitiallyLoading, setInitiallyLoading] = useState(true);
  const { search } = useSelectorTS((state) => state.browsePromotion);
  const { isLoading, results } = search;
  const { user } = useSelectorTS((state) => state.user);

  const {
    sort = DEFAULT_SORT_KEY,
    page = 1,
    categoryUUID,
    minEstimatedWeeklyDownloads,
    maxEstimatedWeeklyDownloads,
    minAverageCPM = 1,
    maxAverageCPM,
    episodeFrequency,
    createdAtSince,
    primaryGender,
    includeNSFW,
  } = options;

  useEffect(() => {
    if (isInitiallyLoading && results.length > 0) setInitiallyLoading(false);
  }, [results]);

  // can we cache api responses?
  useEffect(() => {
    dispatch(
      searchShows({
        userUUID: user.uuid,
        showSearchType: SHOW_SEARCH_FILTER_TYPE_ADS,
        query,
        sortField: SHOW_SEARCH_SORT_OPTIONS[sort as keyof typeof SHOW_SEARCH_SORT_OPTIONS]?.value,
        sortDirection:
          SHOW_SEARCH_SORT_OPTIONS[sort as keyof typeof SHOW_SEARCH_SORT_OPTIONS]?.direction,
        from: (page - 1) * DEFAULT_PAGE_SIZE,
        categoryUUID,
        minEstimatedWeeklyDownloads,
        maxEstimatedWeeklyDownloads,
        minAverageCPM: minAverageCPM ? minAverageCPM * 100 : undefined,
        maxAverageCPM: maxAverageCPM ? maxAverageCPM * 100 : undefined,
        minEstimatedEpisodeFrequency: episodeFrequency
          ? SHOW_SEARCH_FILTER_EPISODE_FREQUENCY[episodeFrequency].range[0]
          : undefined,
        maxEstimatedEpisodeFrequency: episodeFrequency
          ? SHOW_SEARCH_FILTER_EPISODE_FREQUENCY[episodeFrequency].range[1]
          : undefined,
        createdAtSince: createdAtSince ? dayjs().subtract(createdAtSince, "day").unix() : undefined,
        primaryGender: primaryGender === "all" ? undefined : primaryGender,
        includeNSFW,
      } as any)
    );
  }, [
    query,
    sort,
    page,
    categoryUUID,
    minEstimatedWeeklyDownloads,
    maxEstimatedWeeklyDownloads,
    minAverageCPM,
    maxAverageCPM,
    episodeFrequency,
    createdAtSince,
    primaryGender,
    includeNSFW,
  ]);

  return { isLoading: isInitiallyLoading || isLoading, results };
};

// derive show query from url changes
export const useShowSearchFromURL = () => {
  const queryParams = useQuery();
  const {
    query,
    sort = DEFAULT_SORT_KEY,
    page = 1,
    categoryUUID,
    minEstimatedWeeklyDownloads,
    maxEstimatedWeeklyDownloads,
    minAverageCPM = 1,
    maxAverageCPM,
    episodeFrequency,
    createdAtSince,
    primaryGender,
    includeNSFW,
  } = queryParams;

  const parseOrUndefined = (value: string | undefined) => {
    if (typeof value === "undefined") return undefined;
    if (typeof value === "string") return parseInt(value);
    return value;
  };

  const { results, isLoading } = useShowSearch(query, {
    sort,
    page: parseOrUndefined(page),
    categoryUUID,
    minEstimatedWeeklyDownloads: parseOrUndefined(minEstimatedWeeklyDownloads),
    maxEstimatedWeeklyDownloads: parseOrUndefined(maxEstimatedWeeklyDownloads),
    minAverageCPM: parseOrUndefined(minAverageCPM),
    maxAverageCPM: parseOrUndefined(maxAverageCPM),
    episodeFrequency,
    createdAtSince: parseOrUndefined(createdAtSince),
    primaryGender,
    includeNSFW: includeNSFW === "true",
  });

  return { results, isLoading };
};

// poll a show until a condition is met
export const usePollingShow = ({
  showUUID,
  interval = 5000,
  stopPollAfter,
}: {
  showUUID?: string;
  interval?: number;
  stopPollAfter: (show: IShow) => boolean;
}) => {
  const dispatch = useDispatchTS();
  const poll = useRef<NodeJS.Timer | undefined>();
  const { isLoading, shows } = useSelectorTS((state) => state.shows);
  const show = showUUID ? shows?.[showUUID] : undefined;

  useEffect(() => {
    if (showUUID) {
      dispatch(getShow(showUUID)).then((res) => {
        const show = res.json;
        if (res.status === 200 && !stopPollAfter(show as IShow)) {
          startPoll();
        }
      });
    }

    return () => endPoll();
  }, [showUUID]);

  const startPoll = () => {
    poll.current = setInterval(() => {
      dispatch(getShow(showUUID)).then((res) => {
        const show = res.json;
        if (stopPollAfter(show as IShow)) {
          endPoll();
        }
      });
    }, interval);
  };

  const endPoll = () => {
    if (poll.current) clearInterval(poll.current);
    poll.current = undefined;
  };

  return { show, isLoading };
};

/**
 *  Advertiser account only;
 *
 * Ensures that array of showUUIDs is available in redux state, sends fetches if needed.
 *
 * Used in conjunctions with campaign fetch requests. when the show information is needed for
 * campaignItems in a particular campaign/ or multiple campaigns. instead of continually fetching public shows, just
 * provided a array of all needed publicShows.
 *
 * Can be composable in other hooks
 * @param {string[]}
 */
export const useEnsureShowIsAvailable = (showUUIDs: string[] = []) => {
  const publicShows = useSelectorTS((state) => state.publicShows);
  const { isLoading: isCampaignItemsLoading } = useSelectorTS((state) => state.campaignItems);
  const [isLoading, setIsLoading] = useState(false);
  const [attempts, setAttempts] = useState<{ [showUUID: string]: number }>({});
  const dispatch = useDispatchTS();
  const MAX_ALLOWED_ATTEMPTS = 3;
  const FETCH_DEBOUNCE_WAIT_MILLISECONDS = 1500;

  const missingShows = showUUIDs
    .filter((showUUID) => isUUID(showUUID))
    .filter((showUUID) => {
      return !publicShows[showUUID];
    })
    .filter((showUUID) => {
      // incase failure of specific showUUID fetches occurs, will limit the fetch for those shows
      const count = attempts[showUUID] ?? 0;
      return count < MAX_ALLOWED_ATTEMPTS;
    });

  /**
   * Debounced public shows fetch func fetch function. Ensuring minimal calls
   */
  const debouncedFetchPublicShows = useCallback(
    debounce((missingShows: string[]) => {
      dispatch(fetchPublicShowsByUUIDs(missingShows))
        .then((resp) => {
          missingShows.forEach((showUUID) => {
            setAttempts((prev) => {
              return { ...prev, [showUUID]: (prev[showUUID] ?? 0) + 1 };
            });
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, FETCH_DEBOUNCE_WAIT_MILLISECONDS),
    []
  );

  /**
   * Ensure the following on showUUIDsNeeded change
   * - Checks no ongoing public shown fetches
   * - Checks no ongoing campaignItems fetches (when a campaignItem fetch is finished more showUUIDs will be needed to be fetched, will wait for all to finish)
   * - Debounces the fetch call incase user makes random changes and updates in the dropdown, helps minimizing amount of calls
   */
  useEffect(() => {
    if (missingShows.length > 0 && !isLoading && !isCampaignItemsLoading) {
      setIsLoading(true);
      debouncedFetchPublicShows(missingShows);
    }
  }, [missingShows?.length, isLoading, isCampaignItemsLoading]);
};
