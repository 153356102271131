import { Checkbox, Collapse, Radio, Slider } from "antd";
import CategorySelector from "src/components/lib/category_selector/category_selector";
import {
  SHOW_SEARCH_MARKS_DOWNLOADS,
  SHOW_SEARCH_MAX_DOWNLOADS,
  SHOW_SEARCH_MIN_DOWNLOADS,
  SHOW_SEARCH_MIN_CPM,
  SHOW_SEARCH_MAX_CPM,
  SHOW_SEARCH_MARKS_CPM,
  SHOW_SEARCH_FILTER_EPISODE_FREQUENCY,
  SHOW_SEARCH_FILTER_CREATED,
  SHOW_SEARCH_FILTER_PRIMARY_GENDER,
} from "src/constants/search";
import styles from "./PodcastSelector.module.scss";

interface IProps {
  onChange: (key: string, value: any) => void;
  values: {
    categories: string[];
    downloads: [number, number];
    cpm: [number, number];
    episodeFrequency: string;
    createdAtSince: number;
    primaryGender: string;
    includeNSFW: boolean;
  };
}

export default function PodcastSelectorFilterPopover({ values, onChange }: IProps) {
  const weeklyDownloadsSuffix = values.downloads[1] === SHOW_SEARCH_MAX_DOWNLOADS ? "+" : "";
  const cpmSuffix = values.cpm[1] === SHOW_SEARCH_MAX_CPM ? "+" : "";

  const items = [
    {
      key: "categories",
      label: `Content Category ${
        values.categories.length ? `(${values.categories.length} selected)` : ""
      }`,
      children: (
        <CategorySelector
          className={styles.categories}
          values={values.categories}
          onChange={(categories) => onChange("categories", categories)}
        />
      ),
    },
    {
      key: "downloads",
      label: `Weekly Downloads: ${values.downloads?.[0] / 1000}k - ${
        values.downloads?.[1] / 1000
      }k${weeklyDownloadsSuffix}`,
      children: (
        <Slider
          range
          min={SHOW_SEARCH_MIN_DOWNLOADS}
          max={SHOW_SEARCH_MAX_DOWNLOADS}
          marks={SHOW_SEARCH_MARKS_DOWNLOADS}
          style={{ maxWidth: "250px" }}
          value={values.downloads}
          onChange={(downloads) => onChange("downloads", downloads)}
        />
      ),
    },
    {
      key: "cpm",
      label: `CPM: $${values.cpm?.[0]} - $${values.cpm?.[1]}${cpmSuffix}`,
      children: (
        <Slider
          range
          min={SHOW_SEARCH_MIN_CPM}
          max={SHOW_SEARCH_MAX_CPM}
          marks={SHOW_SEARCH_MARKS_CPM}
          style={{ maxWidth: "250px" }}
          value={values.cpm}
          onChange={(cpm) => onChange("cpm", cpm)}
        />
      ),
    },
    {
      key: "episodeFrequency",
      label: `Episode Frequency: ${values.episodeFrequency}`,
      children: (
        <Radio.Group
          value={values.episodeFrequency}
          onChange={(e) => onChange("episodeFrequency", e.target.value)}>
          <div className="flex-column-container">
            {Object.values(SHOW_SEARCH_FILTER_EPISODE_FREQUENCY).map((item) => (
              <Radio className="m-l0" value={item.value} key={item.value}>
                {item.label}
              </Radio>
            ))}
          </div>
        </Radio.Group>
      ),
    },
    {
      key: "recentlyAdded",
      label: `Recently Added: ${
        values.createdAtSince === 0 ? "all" : values.createdAtSince + " days ago"
      }`,
      children: (
        <Radio.Group
          value={values.createdAtSince}
          onChange={(e) => onChange("createdAtSince", e.target.value)}>
          <div className="flex-column-container">
            {SHOW_SEARCH_FILTER_CREATED.map((item) => (
              <Radio value={item.value} key={item.value}>
                {item.label}
              </Radio>
            ))}
          </div>
        </Radio.Group>
      ),
    },
    {
      key: "primaryGender",
      label: `Primary Gender: ${values.primaryGender}`,
      children: (
        <Radio.Group
          value={values.primaryGender}
          onChange={(e) => onChange("primaryGender", e.target.value)}>
          <div className="flex-column-container">
            {SHOW_SEARCH_FILTER_PRIMARY_GENDER.map((item) => (
              <Radio value={item.value} key={item.value}>
                {item.label}
              </Radio>
            ))}
          </div>
        </Radio.Group>
      ),
    },
    {
      key: "moreFilters",
      label: "More Filters",
      children: (
        <Checkbox
          checked={values.includeNSFW}
          onClick={(e: any) => onChange("includeNSFW", e.target.checked)}>
          Include NSFW Content
        </Checkbox>
      ),
    },
  ];

  return (
    <div className="flex-column-container" style={{ minWidth: 300 }}>
      <Collapse ghost accordion expandIconPosition="end" items={items} />
    </div>
  );
}
