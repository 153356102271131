const colors = {
  white: "#FFFFFF",
  black: "#000000",
  primaryColor: "#ea404d",
  primaryColor10: "#fcebed",
  burgundy: "#8b0D38",
  lightBlue: "#9Fb6c8",
  mediumBlue: "#577D9E",
  deepBlue: "#010831",
  gray: "#e5e5e5",
  darkGray: "#979797",
};

const fonts = {
  bold: `Gilroy-Bold, Gilroy-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif`,
};

const blues = ["#8CCAFF", "#87C2F5", "#7AB0DE", "#73A5D1", "#6591B8"];

export { colors, fonts, blues };
