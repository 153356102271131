/**
 * Might be good to have a file for Success, Error, Warning, and Info messages
 */

/**
 * Show messages (Error, Warning, Info)
 */

export const showMessages = {
  Success: {},
  Info: {},
  Warning: {
    TierARedirectHack: "You can only have 1 active show in your account",
  },
  Error: {
    PleaseTryAgain:
      "There was an issue removing the redirect, please reload the page and try again. For any urgent issues, please contact support",
  },
};

export const addScriptModalMessages = {
  Success: {
    UpdatedCampaign: "Successfully updated campaign",
    CreatedScript: "Script successfully added to campaign",
    UpdatedScript: "Script successfully updated in campaign",
  },
  Info: {},
  Warning: {},
  Error: {
    CouldNotUpdate:
      "There was an issue adding the script(s). Please try again later. For more immediate response please contact RedCircle support.",
  },
};

/**
 * Schedule and Send Campaign Messages
 */

export const CampaignSendMessages = {
  Success: {},
  Info: {},
  Warning: {},
  Error: {
    CouldNotLoadForecastEndDates:
      "There was an issue loading forecast campaign end dates for a show. Please reload the page, if the problem persists contact us at RedCircle support.",
    CouldNotLoadForecastImpressions:
      "There was an issue loading forecast impression data for a show. Please reload the page, if the problem persists contact us at RedCircle support.",
    CouldNotSendCampaign:
      "There was an issue sending the campaign, please try again later, or contact us at RedCircle support.",
  },
} as const;
