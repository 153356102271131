import { Collapse } from "antd";

export default function AudioMacrosCollapse() {
  const items = [
    {
      key: "1",
      label: "URL Macros",
      children: (
        <ul className="p-lxs">
          <li>
            <small>
              <strong>{"{{podcastID}}"}:</strong> The podcast's ID
            </small>
          </li>
          <li>
            <small>
              <strong>{"{{ip}}"}:</strong> The listener's IP
            </small>
          </li>
          <li>
            <small>
              <strong>{"{{ipHash}}"}:</strong> The listener's encrypted IP address
            </small>
          </li>
          <li>
            <small>
              <strong>{"{{episodeID}}"}:</strong> The ID of the episode
            </small>
          </li>
          <li>
            <small>
              <strong>{"{{timestamp}}"}:</strong> The timestamp when the download occurred in ISO
              8601 format (e.g., 1977-07-16T19:20+01:00)
            </small>
          </li>
          <li>
            <small>
              <strong>{"{{epoch}}"}:</strong> The timestamp when the download occurred in epoch
              format (e.g., 1594165941)
            </small>
          </li>
          <li>
            <small>
              <strong>{"{{uniqueHash}}"}:</strong> A hash of the user agent, IP address, and episode
              ID
            </small>
          </li>
          <li>
            <small>
              <strong>{"{{userAgent}}"}:</strong> The browser user agent
            </small>
          </li>
          <li>
            <small>
              <strong>{"{{random}}"}:</strong> A random piece of text
            </small>
          </li>
          <li>
            <small>
              <strong>{"{{isUnique}}"}:</strong> Whether or not RedCircle treats this download as
              unique (e.g. “true” or “false”)
            </small>
          </li>
        </ul>
      ),
    },
  ];
  return <Collapse bordered={false} className="m-bs" items={items} />;
}
