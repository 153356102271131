import get from "lodash/get";
import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import downChevron from "../../../icons/down-chevron.svg";
import upChevron from "../../../icons/up-chevron.svg";
import { colors } from "../../../lib/design";
import "./form_dropdown.scss";

class FormDropdown extends Component {
  state = {
    show: this.props.initialShow ?? false,
    showChildren: this.props.initialShow ?? false,
  };

  toggleState = () => {
    if (this.props.disabled) {
      return;
    }
    if (this.props.setShow) {
      this.props.setShow(!this.state.show);
    } else {
      this.setState({ show: !this.state.show, initialHide: false });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (get(nextProps, "validationErrors", []).length) {
      this.setState({ show: true });
    }
    if (nextProps.show !== undefined && nextProps.show !== this.state.show) {
      this.setState({ show: nextProps.show });
    }
  }

  getButton = () => {
    const closedStateChevron = this.props.closedStateChevron || upChevron;
    const iconSrc = this.state.show ? closedStateChevron : downChevron;
    const Chevron = (
      <span onClick={this.toggleState} className="form-dropdown-wrapper__chevron pointer">
        <img src={iconSrc} alt={"dropdown-icon"} />
      </span>
    );
    if (this.props.Component) {
      const Component = this.props.Component;
      return <Component onClick={this.toggleState} Chevron={Chevron} />;
    }
    if (this.props.renderer) {
      return this.props.renderer({ onClick: this.toggleState, Chevron });
    }
    return (
      <div className="form-dropdown-wrapper m-bm" onClick={this.toggleState}>
        <div className={"flex-1"}>{this.props.label || "More Options"}</div>
        {this.props.disabledText && (
          <span style={{ color: colors.darkGray, fontSize: 12 }} className={"default-font"}>
            {this.props.disabledText}
          </span>
        )}
        {!this.props.disabled && (
          <>
            {this.props.completed && (
              <div className="form-dropdown-badge">
                <svg
                  className="form-dropdown-badge-icon"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="50" cy="50" r="50" />
                </svg>
                <span className="form-dropdown-badge-text">{this.props.completedText}</span>
              </div>
            )}
            {Chevron}
          </>
        )}
      </div>
    );
  };
  render() {
    return (
      <div>
        {this.getButton()}
        <CSSTransition
          classNames={"form-dropdown"}
          in={this.state.show}
          timeout={300}
          onEnter={() => {
            this.setState({ showChildren: true });
          }}
          onExited={() => {
            this.setState({ showChildren: false });
          }}>
          <div style={{ display: this.state.showChildren ? "block" : "none" }}>
            {this.props.children}
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default FormDropdown;
