import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import objectSupport from "dayjs/plugin/objectSupport";
import utc from "dayjs/plugin/utc";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(objectSupport);

export const formatDurationHumanized = (durationInSeconds: number) => {
  const d = dayjs.duration(durationInSeconds, "milliseconds");
  const numHours = d.hours();
  return d.format(`${numHours ? "H[h] " : ""}m[m]`);
};

export const formatDurationPlayback = (durationInSeconds: number) => {
  return dayjs.duration(durationInSeconds, "seconds").format("HH:mm:ss");
};

export const formatDateHumanized = (unixDate: number) => {
  return dayjs(unixDate).format("MMM D, YYYY");
};

/** This function finds UTC=0 within the day of this user's timezone.
 * This means that if the user is in a timezone that is ahead of UTC, the UTC=0 time will be the next day.
 * @param date - the date to find the UTC=0 time within the current day of the user's timezone. If not provided, the current time and date is used.
 * @returns Dayjs - the UTC=0 time within the current day of the user's timezone
 */
export const getUTCZeroForDay = (date: Dayjs = dayjs()) => {
  const utcZeroDay = date.utc(true).startOf("day");
  const daySpread = [-1, 0, 1];

  for (const spread of daySpread) {
    const localDay = utcZeroDay.add(spread, "day").local();
    if (localDay.isSame(date, "day")) {
      return localDay;
    }
  }

  return date;
};

export const getEasternEODForDay = (date: Dayjs = dayjs()) => {
  const secondSundayMarch = dayjs({ year: date.year(), month: 2, day: 1 })
    .startOf("month")
    .day(0)
    .add(14, "day");
  const firstSundayNovember = dayjs({ year: date.year(), month: 10, day: 1 })
    .startOf("month")
    .day(0);
  const isDST = date.isAfter(secondSundayMarch) && date.isBefore(firstSundayNovember);
  const offset = isDST ? -4 : -5;

  const easternEOD = date
    .utcOffset(offset, true)
    .startOf("day")
    .add(1, "day")
    .subtract(1, "second");
  const daySpread = [-1, 0, 1];

  for (const spread of daySpread) {
    const localDay = easternEOD.add(spread, "day").local();
    if (localDay.isSame(date, "day")) {
      return localDay;
    }
  }

  return date;
};
