import { SortOrder } from "antd/es/table/interface";
import {
  CampaignStateCompleted,
  CampaignStateCreated,
  CampaignStateInProgress,
  CAMPAIGN_TAG_TYPE__CAMPAIGN,
  CAMPAIGN_TAG_TYPE__TAG,
} from "src/constants/campaigns";
import { ICampaign, ICampaignTag } from "redcircle-types";
import { getCampaignField } from "src/lib/campaign";

const CAMPAGN_STATE_ORDER = {
  [CampaignStateCreated]: 0,
  [CampaignStateInProgress]: 1,
  [CampaignStateCompleted]: 2,
} as Record<string, number>;

/** Sorts campaigns and tags by column key and order, floating folders to top */

export const sortCampaigns = (
  campaigns: ICampaign[],
  { columnKey, order }: { columnKey: keyof ICampaign; order: SortOrder }
) => {
  // map type
  const newCampaigns = campaigns.map((campaign: ICampaign) => ({
    ...campaign,
    type: CAMPAIGN_TAG_TYPE__CAMPAIGN,
  }));

  // sort
  if (columnKey && order) {
    // special sorting order for campaign state - fallback to createdAt
    if (columnKey === "state") {
      newCampaigns.sort((a: ICampaign, b: ICampaign) => {
        const keyField = a.state;
        const keyField2 = b.state;
        const orderA = CAMPAGN_STATE_ORDER[keyField as keyof typeof CAMPAGN_STATE_ORDER];
        const orderB = CAMPAGN_STATE_ORDER[keyField2 as keyof typeof CAMPAGN_STATE_ORDER];
        if (orderA !== orderB) {
          return order === "ascend" ? orderA - orderB : orderB - orderA;
        }
        return b.createdAt - a.createdAt;
      });
    } else {
      newCampaigns.sort((a: ICampaign, b: ICampaign) => {
        const keyField = getCampaignField(columnKey, { campaign: a });
        const keyField2 = getCampaignField(columnKey, { campaign: b });
        // sort by string
        if (typeof keyField === "string" && typeof keyField2 === "string") {
          const lowercaseA = keyField.toLowerCase();
          const lowercaseB = keyField2.toLowerCase();
          return order === "ascend"
            ? lowercaseA.localeCompare(lowercaseB)
            : lowercaseB.localeCompare(lowercaseA);
        }

        // sort by number
        if (typeof keyField === "number" && typeof keyField2 === "number") {
          return order === "ascend" ? keyField - keyField2 : keyField2 - keyField;
        }

        return 0;
      });
    }
  }

  return newCampaigns;
};

export const sortTags = (
  tags: ICampaignTag[],
  { columnKey, order }: { columnKey: string; order: SortOrder }
) => {
  // map type
  const newTags = tags.map((tag: ICampaignTag) => ({
    ...tag,
    type: CAMPAIGN_TAG_TYPE__TAG,
  }));

  // sort by name only
  if (columnKey === "name") {
    newTags.sort((a: ICampaignTag, b: ICampaignTag) => {
      const lowercaseA = a.title.toLowerCase();
      const lowercaseB = b.title.toLowerCase();
      return order === "ascend"
        ? lowercaseA.localeCompare(lowercaseB)
        : lowercaseB.localeCompare(lowercaseA);
    });
  }

  return newTags;
};

export const joinTagsWithCampaigns = (campaigns: ICampaign[], campaignTags: ICampaignTag[]) => {
  const allItems = [...campaignTags, ...campaigns];
  const allItemsByUUID = allItems.reduce(
    (acc: Record<string, ICampaign | ICampaignTag>, item: ICampaign | ICampaignTag) => ({
      [item.uuid]: item,
      ...acc,
    }),
    {}
  );
  const parentUUIDs = allItems.reduce(
    (acc: Record<string, string[]>, item: ICampaign | ICampaignTag) => {
      if (item.parentCampaignTagUUIDs) {
        const parentTag = item.parentCampaignTagUUIDs[0]; // ignore all other tags but the first for now
        if (!acc[parentTag]) acc[parentTag] = [];
        acc[parentTag].push(item.uuid);
      }
      return acc;
    },
    {}
  );

  const sortedList: (ICampaignTag | ICampaign)[] = [];
  // push in tags - float to top of list
  campaignTags.forEach((tag: ICampaignTag) => {
    // get number of children in tag
    tag.numChildren = parentUUIDs[tag.uuid]?.length || 0;
    if (!tag.parentCampaignTagUUIDs) sortedList.push(tag);
    // push in children - this is only one level deep for now (no nested folders)
    // if we want to nest folders, we'll need to do a recursive function
    if (parentUUIDs[tag.uuid]) {
      parentUUIDs[tag.uuid].forEach((uuid: string) => sortedList.push(allItemsByUUID[uuid]));
    }
  });

  // push in campaigns with no parents
  campaigns.forEach((campaign: ICampaign) => {
    if (!campaign.parentCampaignTagUUIDs) sortedList.push(campaign);
  });

  return sortedList;
};

export const filterByCollapsedFolders = (
  list: (ICampaign | ICampaignTag)[] = [],
  collapsedFolders: Record<string, boolean> = {}
) => {
  return list.filter(
    (row: any) =>
      row.type === CAMPAIGN_TAG_TYPE__TAG ||
      (row.type === CAMPAIGN_TAG_TYPE__CAMPAIGN &&
        !row.parentCampaignTagUUIDs?.some((uuid: string) => collapsedFolders[uuid]))
  );
};

export const filterBySearch = (list: (ICampaign | ICampaignTag)[] = [], searchQuery: string) => {
  if (!searchQuery) return list;

  const isValidCampaign = (row: any) =>
    row.type === CAMPAIGN_TAG_TYPE__CAMPAIGN &&
    (row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.referenceNumber.includes(searchQuery));

  const isValidFolder = (row: any) =>
    row.type === CAMPAIGN_TAG_TYPE__TAG &&
    row.title.toLowerCase().includes(searchQuery.toLowerCase());

  // get all tags and campaigns that match search query
  // include parents of tags that match search query
  const campaignsFiltered = list.filter(isValidCampaign);
  const parentsTagsOfFilteredCampaigns = campaignsFiltered.reduce(
    (acc: Record<string, boolean>, row: any) => {
      if (row.parentCampaignTagUUIDs) {
        row.parentCampaignTagUUIDs.forEach((uuid: string) => {
          acc[uuid] = true;
        });
      }
      return acc;
    },
    {}
  );

  // filter all things
  const filteredList = list.filter((row: any) => {
    switch (row.type) {
      case CAMPAIGN_TAG_TYPE__CAMPAIGN:
        return isValidCampaign(row);
      case CAMPAIGN_TAG_TYPE__TAG:
        return parentsTagsOfFilteredCampaigns[row.uuid] || isValidFolder(row);
      default:
        return false;
    }
  });

  return filteredList;
};
