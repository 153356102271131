import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { classNames } from "react-extras";

interface IProps {
  className?: string;
  currentPage: number;
  pageSize: number;
  currentItems: any[];
  onPageChange: (page: number) => void;
}

// Sometimes we don't know the total count - create a paginator that infers
export default function Paginator({
  className,
  currentPage = 1,
  pageSize,
  currentItems,
  onPageChange,
}: IProps) {
  return (
    <nav className={classNames("flex-row-container align-center", className)}>
      <Button
        type="link"
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}>
        <LeftOutlined />
      </Button>

      {currentPage > 2 && (
        <Button type="link" onClick={() => onPageChange(currentPage - 2)}>
          {currentPage - 2}
        </Button>
      )}

      {currentPage > 1 && (
        <Button type="link" onClick={() => onPageChange(currentPage - 1)}>
          {currentPage - 1}
        </Button>
      )}

      <Button type="primary">{currentPage}</Button>

      <Button
        type="link"
        disabled={currentItems.length !== pageSize}
        onClick={() => onPageChange(currentPage + 1)}>
        <RightOutlined />
      </Button>
    </nav>
  );
}
