import { get } from "lodash";
import { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, MultiModal } from "redcircle-ui";
import { formValueSelector, submit } from "redux-form";
import UserRoles from "src/constants/roles";
import { useComponentDidMount } from "src/lib/hook";
import { saveUserRole } from "src/lib/user_store";
import { updateCurrentRole } from "../../actions/account";
import { updatePassword } from "../../action_managers/account";
import { loginByAdvertiserContactToken } from "../../action_managers/forgot_password";
import NewPasswordForm from "../pages/forgot_password/new_password_form";

const AdvertiserWelcomeModal = (props: any) => {
  const [currentPage, setCurrentPage] = useState(0);

  useComponentDidMount(() => {
    const { email, advertiserContactToken } = get(props, "match.params", {});
    props.loginByAdvertiserContactToken(atob(email), advertiserContactToken).then((resp: any) => {
      if (resp.status !== 200) {
        props.history.push("/");
      } else {
        props.updateCurrentRole(UserRoles.Advertiser);
        saveUserRole(UserRoles.Advertiser);
      }
    });
  });

  const onSubmit = (values: any) => {
    props.updatePassword(values.password, props.user).then((resp: any) => {
      if (resp.status === 200) {
        props.history.push("/campaigns");
      }
    });
  };

  const pages = [
    {
      title: "Welcome to RedCircle!",
      body: (
        <p>
          This is the advertising portal. Here you can browse the entire catalog of RedCircle
          podcasts that are available for advertising, as well as their rates, and other
          information.
        </p>
      ),
      footer: (
        <Modal.SubmitButton onClick={() => setCurrentPage(currentPage + 1)} className="m-la">
          Next
        </Modal.SubmitButton>
      ),
    },
    {
      title: "Setup Password",
      subtitle: "Before we get started, we need you to setup a password.",
      body: (
        <NewPasswordForm
          hideSubmit
          password={props.password}
          onSubmit={onSubmit}
          validationErrors={props.validationErrors}
        />
      ),
      footer: (
        <Modal.SubmitButton onClick={props.submitNewPassword} className="m-la">
          Save
        </Modal.SubmitButton>
      ),
    },
  ];

  return (
    <MultiModal
      size="sm"
      currentPage={currentPage}
      pages={pages}
      onSubmit={props.submitNewPassword}
    />
  );
};

export default connect(
  (state) => ({
    user: state.user.user,
    isLoading: state.updateAccount.isLoading,
    validationErrors: state.updateAccount.validationErrors,
    password: formValueSelector("new-password")(state, "password"),
  }),
  {
    updatePassword,
    updateCurrentRole,
    loginByAdvertiserContactToken,
    submitNewPassword: () => submit("new-password"),
  }
)(withRouter(AdvertiserWelcomeModal));
