import {
  SHOW_SEARCH_FILTER_EPISODE_FREQUENCY,
  SHOW_SEARCH_MAX_CPM,
  SHOW_SEARCH_MAX_DOWNLOADS,
  SHOW_SEARCH_MIN_CPM,
  SHOW_SEARCH_MIN_DOWNLOADS,
} from "src/constants/search";
import { isShowExcludingCampaign } from "src/lib/show";
import { ICampaign } from "redcircle-types";
import { IShow } from "redcircle-types";

export const DEFAULT_SORT_KEY = "downloadsDesc";
export const DEFAULT_FILTERS = {
  categories: [],
  downloads: [SHOW_SEARCH_MIN_DOWNLOADS, SHOW_SEARCH_MAX_DOWNLOADS] as [number, number],
  cpm: [SHOW_SEARCH_MIN_CPM, SHOW_SEARCH_MAX_CPM] as [number, number],
  episodeFrequency: "all" as keyof typeof SHOW_SEARCH_FILTER_EPISODE_FREQUENCY,
  createdAtSince: 0,
  primaryGender: "all",
  includeNSFW: false,
};

export const shouldShowRecommendedShows = (currentFilters: typeof DEFAULT_FILTERS) => {
  return (
    currentFilters === DEFAULT_FILTERS ||
    (!currentFilters.categories.length &&
      currentFilters.downloads[0] === SHOW_SEARCH_MIN_DOWNLOADS &&
      currentFilters.downloads[1] === SHOW_SEARCH_MAX_DOWNLOADS &&
      currentFilters.cpm[0] === SHOW_SEARCH_MIN_CPM &&
      currentFilters.cpm[1] === SHOW_SEARCH_MAX_CPM &&
      currentFilters.episodeFrequency === "all" &&
      currentFilters.createdAtSince === 0 &&
      currentFilters.primaryGender === "all")
  );
};

export const sortRecommendedShows = (shows?: IShow[], sortKey = DEFAULT_SORT_KEY) => {
  if (!shows) return [];

  switch (sortKey) {
    case "relevance":
      return shows; // we assume shows are sorted already due to relevance
    case "downloadsDesc":
      return shows.sort((a, b) => b.estimatedWeeklyDownloads - a.estimatedWeeklyDownloads);
    case "updatedAtDesc":
      return shows.sort((a, b) => (a.updatedAt && b.updatedAt ? b.updatedAt - a.updatedAt : 0));
    case "alphaAsc":
      return shows.sort((a, b) => (a.title && b.title ? a.title.localeCompare(b.title) : 0));
    default:
      return shows;
  }
};

// shows have their own exclusion rules - search endpoint should not return excluded shows
// but just in case
export const markExcludedShows = (shows: IShow[], campaign?: ICampaign) => {
  if (!campaign || !shows) return shows;
  return shows.map((show) => ({ ...show, isExcluded: isShowExcludingCampaign(show, campaign) }));
};
