import langMap from "langmap";
import sortBy from "lodash/sortBy";
import React from "react";

export var languages = [];
var english = {};
Object.keys(langMap).forEach((localeName) => {
  var option = {
    localeName,
    friendlyName: langMap[localeName].englishName,
  };
  if (localeName === "en-US") {
    english = option;
  } else {
    languages.push(option);
  }
});
languages = sortBy(languages, "friendlyName");
languages.unshift(english);

export const languageChoices = languages.map(({ localeName, friendlyName }) => (
  <option key={localeName} value={localeName}>
    {friendlyName}
  </option>
));
