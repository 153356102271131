import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { getAudioBlocksForUser } from "src/action_managers/audio_management";
import { getMediaFile } from "src/action_managers/shows";
import { AD_TYPE_AUDIO_FILE } from "src/components/pages/dynamic_insertion/audio_blocks_section/consts";
import { User } from "redcircle-types";
import { useDispatchTS, useSelectorTS } from "./redux-ts";

export const useGetAudioBlocks = () => {
  const dispatch = useDispatchTS();
  const { user, isLoading: isUserLoading } = useSelectorTS((state) => state.user);
  const { isLoading: isAudioBlocksLoading, audioBlocks } = useSelectorTS(
    (state) => state.audioBlocks
  );

  const [calledOnce, setCalledOnce] = useState(false);

  useEffect(() => {
    if (isUserLoading) {
      // User is Loading
    } else {
      // User is not loading

      if (!isEmpty(user)) {
        if (!isAudioBlocksLoading && !calledOnce) {
          dispatch(getAudioBlocksForUser(user));
          setCalledOnce(true);
        }
      }
    }
  }, [isUserLoading, isEmpty(user), calledOnce]);

  return { audioBlocks, isLoading: isUserLoading || isAudioBlocksLoading };
};

export const useUserRAPEnabled = (userFetched?: User) => {
  const user = userFetched || useSelectorTS((state) => state.user.user);
  const { shows, isLoading: isShowsLoading } = useSelectorTS((state) => state.shows);
  const isLoading = !user || isShowsLoading;

  if (isLoading || !shows) return false;
  return Object.values(shows).some((show) => show.advertisementSettings);
};

// TODO: deprecate this when we remove audio files
export const useGetAudioBlockMediaFiles = () => {
  const dispatch = useDispatchTS();

  const { audioBlocks } = useSelectorTS((state) => state.audioBlocks);
  const mediaFiles = useSelectorTS((state) => state.mediaFiles);

  useEffect(() => {
    if (!audioBlocks) return;
    const allMediaFiles = Object.keys(audioBlocks).reduce((acc: any, audioBlockUUID: string) => {
      const audioBlock = audioBlocks[audioBlockUUID];
      if (!audioBlock.items) return acc;
      const audioBlockMediaFiles = audioBlock.items
        .filter((item: any) => item.type === AD_TYPE_AUDIO_FILE && item.mediaFileUUID)
        .map((item: any) => item.mediaFileUUID);
      return [...acc, ...audioBlockMediaFiles];
    }, []);

    allMediaFiles.forEach((mediaFileUUID: string) => {
      if (!mediaFiles[mediaFileUUID]) {
        dispatch(getMediaFile(mediaFileUUID));
      }
    });
  }, [audioBlocks]);

  return { mediaFiles };
};
