import Geohash from "latlon-geohash";
import isFunction from "lodash";
import debounce from "lodash/debounce";
import mapboxgl from "mapbox-gl";
import moment from "moment-timezone";
import React, { useEffect, useRef } from "react";
import { classNames, If } from "react-extras";
import { v4 as uuid } from "uuid";
import { useDispatchTS } from "../../../hooks/redux-ts";
import { ALL_EPISODES, ALL_PODCASTS } from "../../pages/analytics/analyticsUtility";
import Message from "../../pages/analytics/RedCircle_graphs/Messages";
import { fetchGeoStats } from "./../../../action_managers/stats";
import { mapboxToken } from "./../../../constants/mapbox_token";
import "./mapbox.scss";

mapboxgl.accessToken = mapboxToken;
const startingPoint = {
  location: [-95.7129, 37.0902],
  zoom: 2.5,
};

const transformRawDataToSourceData = (buckets) => {
  return {
    type: "FeatureCollection",
    features: buckets.map(({ geohash, count }) => {
      const { lon, lat } = Geohash.decode(geohash);
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [lon, lat],
        },
        properties: {
          count: count,
        },
      };
    }),
  };
};

const AnalyticsMapBox = (props) => {
  const { dateRange, selectedShow, selectedEpisode, timeZone, minHeight = 300, className } = props;
  let { showMessage = false } = props;

  const dispatch = useDispatchTS();
  // Holds ref for Map Div element
  const mapContainer = useRef();

  // Holds ref for Map Instance, will keep the same instance on consequent re renders
  let mapInstance = useRef();

  // Previous Deboucned function

  let debouncedMoveEnd = useRef();

  useEffect(() => {
    // Initiate map instance
    if (mapboxgl.supported()) {
      mapInstance.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/light-v10",
        center: startingPoint.location,
        zoom: startingPoint.zoom,
      });

      mapInstance.current.addControl(
        new mapboxgl.NavigationControl({ showCompass: false, showZoom: true }),
        "top-right"
      );

      // Initialize Data for first time
      mapInstance.current.on("load", () => {
        fetchAndLoadData();
      });
    }

    return () => mapInstance.current?.remove();
  }, []);

  useEffect(() => {
    if (mapboxgl.supported()) {
      // Debounce callback to ensure only the last action is used to fetch the data

      if (isFunction(debouncedMoveEnd.current)) {
        mapInstance.current.off("move", debouncedMoveEnd.current);
      }

      debouncedMoveEnd.current = debounce(() => {
        fetchAndLoadData();
      }, 700);

      // On the last map movement will fetch data by grabbing current coordinate boundaries
      mapInstance.current.on("move", debouncedMoveEnd.current);

      fetchAndLoadData();
    }
  }, [selectedShow, selectedEpisode]);

  const fetchAndLoadData = () => {
    const bounds = mapInstance.current.getBounds();
    const { lng: NWLng, lat: NWLat } = bounds.getNorthWest();
    const { lng: SELng, lat: SELat } = bounds.getSouthEast();

    const query = `${NWLat},${NWLng},${SELat},${SELng}`;
    let filters = {
      isUnique: true,
      arbitraryTimeRange: [dateRange?.[0]?.unix(), dateRange?.[1]?.unix()].join(","),
      timezone: timeZone,
      statsType: "downloads",
      geoBoundingBox: query,
    };

    if (selectedShow !== ALL_PODCASTS) {
      filters.showUUID = selectedShow;
    }

    if (selectedEpisode !== ALL_EPISODES) {
      filters.episodeUUID = selectedEpisode;
    }

    const requestHash = uuid();

    dispatch(fetchGeoStats(filters, requestHash))
      .then((resp) => {
        addOrUpdateSources(transformRawDataToSourceData(resp?.json?.buckets));
      })
      .catch((err) => null);
  };

  const addOrUpdateSources = (sourceData) => {
    const clusterSource = mapInstance.current.getSource("downloads-cluster");
    if (clusterSource) {
      clusterSource.setData(sourceData);
    } else {
      mapInstance.current.addSource("downloads-cluster", {
        type: "geojson",
        data: sourceData,
        cluster: true,
        clusterRadius: 60,
        clusterProperties: {
          total_count: ["+", ["get", "count"]],
        },
      });

      addLayers();
    }
  };

  const addLayers = () => {
    mapInstance.current.addLayer({
      id: "cluster-circle",
      type: "circle",
      source: "downloads-cluster",
      filter: ["!", ["has", "total_count"]],
      paint: {
        "circle-color": [
          "step",
          ["get", "count"],
          ["rgba", 255, 156, 160, 1],
          100,
          ["rgba", 255, 156, 160, 0.8],
          1000,
          ["rgba", 255, 156, 160, 0.6],
          10000,
          ["rgba", 255, 156, 160, 0.4],
        ],
        "circle-radius": [
          "interpolate",
          ["linear", 50],
          ["get", "count"],
          1,
          1,
          100,
          20,
          1000,
          30,
          10000,
          40,
        ],
      },
    });

    mapInstance.current.addLayer({
      id: "cluster-total-circle",
      type: "circle",
      source: "downloads-cluster",
      filter: ["has", "total_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "total_count"],
          ["rgba", 255, 156, 160, 1],
          100,
          ["rgba", 255, 156, 160, 0.8],
          1000,
          ["rgba", 255, 156, 160, 0.6],
          10000,
          ["rgba", 255, 156, 160, 0.4],
        ],
        "circle-radius": [
          "interpolate",
          ["linear", 50],
          ["get", "total_count"],
          1,
          10,
          100,
          20,
          1000,
          30,
          10000,
          40,
        ],
      },
    });

    // Text field example kept from previous component
    // might need to add text in future

    // mapInstance.current.addLayer({
    //   id: "cluster-total-count",
    //   type: "symbol",
    //   source: "downloads-cluster",
    //   filter: ["has", "total_count"],
    //   layout: {
    //     "text-field": ["number-format", ["get", "total_count"], {}],
    //     "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    //     "text-size": 12,
    //   },
    // });

    // mapInstance.current.addLayer({
    //   id: "cluster-count",
    //   type: "symbol",
    //   source: "downloads-cluster",
    //   filter: ["has", "count"],
    //   layout: {
    //     "text-field": ["number-format", ["get", "count"], {}],
    //     "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    //     "text-size": 12,
    //   },
    // });
  };
  let messageType = "Insufficient Data";
  let showNotSupported = false;

  if (!mapboxgl.supported()) {
    showNotSupported = true;
    messageType = "Browser Not Supported";
  }

  const enabledMessage = showMessage || showNotSupported;

  return (
    <div
      id="analytics-mapbox-container"
      className={className}
      style={{ minHeight: enabledMessage ? 200 : minHeight }}>
      <If condition={Boolean(enabledMessage)}>
        <Message show={true} type={messageType} />
      </If>

      <div
        className={classNames({
          hidden: showMessage,
        })}
        style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}
        ref={mapContainer}
      />
    </div>
  );
};

// Need to optimize and lower re-renders due to multiple request calls both in the current
// component and parent component, moving this to later priority;

const areEqual = (prevProps, nextProps) => {
  const dateRangeIsSame =
    moment(prevProps?.dateRange[0]).format("MM/DD/YYYY") ===
      moment(nextProps?.dateRange[0]).format("MM/DD/YYYY") &&
    moment(prevProps?.dateRange[1]).format("MM/DD/YYYY") ===
      moment(nextProps?.dateRange[1]).format("MM/DD/YYYY");

  const timeZoneIsSame = prevProps?.timeZone === nextProps?.timeZone;
  const selectShowIsSame = prevProps?.selectedShow === nextProps?.selectedShow;
  const minHeightIsSame = prevProps?.minHeight === nextProps?.minHeight;
  const showMessageIsSane = prevProps?.showMessage === nextProps?.showMessage;

  return (
    dateRangeIsSame && showMessageIsSane && timeZoneIsSame && selectShowIsSame && minHeightIsSame
  );
};

export default AnalyticsMapBox;
