import { Row } from "antd";
import React from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Breadcrumbs from "src/components/lib/breadcrumbs";
import Button from "src/components/lib/button";
import Loading from "src/components/lib/loading";
import Page from "src/components/lib/page";
import { permissionTypes } from "src/constants/permission_roles";
import { useSelectorTS } from "src/hooks/redux-ts";
import { useGetShowDetail } from "src/hooks/shows";
import { useCanAccessBound } from "src/lib/permissions";
import ShowPageWrapper from "../show_page_wrapper";
import ShowDetailsEpisodeList from "./show_details_episode_list";

export default function ShowEpisodesPage() {
  const { showUUID } = useParams<{ showUUID: string }>();
  const location = useLocation();
  const canAccess = useCanAccessBound();
  const history = useHistory();
  const { show, episodes, isLoading } = useGetShowDetail(showUUID, { withEpisodes: true });
  const { user } = useSelectorTS((state) => state.user);

  return (
    <ShowPageWrapper className="show-episodes-page" show={show} isLoading={isLoading}>
      <Breadcrumbs
        crumbs={[
          { path: "/shows", name: "All Podcasts" },
          { path: `/shows/${showUUID}`, name: show?.title },
          { path: `/shows/${showUUID}/ep`, name: "Episodes", active: true },
        ]}
      />
      {isLoading && <Loading />}
      {show && (
        <Row>
          <ShowPageWrapper.Sidebar />
          <ShowPageWrapper.Body>
            <ShowPageWrapper.Header title="Episodes">
              {canAccess(permissionTypes.editEpisodesDraft, showUUID) && !show.isRemote && (
                <Link data-cy="new-episode" to={`/shows/${showUUID}/ep/create${location?.search}`}>
                  <Button type="secondary" size="small">
                    New Episode
                  </Button>
                </Link>
              )}
            </ShowPageWrapper.Header>

            <Page.Section>
              <ShowDetailsEpisodeList
                show={show}
                showUUID={showUUID}
                user={user}
                historyPush={history.push}
                episodes={episodes}
              />
            </Page.Section>
          </ShowPageWrapper.Body>
        </Row>
      )}
    </ShowPageWrapper>
  );
}
