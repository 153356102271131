import { Modal } from "redcircle-ui";

interface IProps {
  open: boolean;
  onClose: () => void;
}

export default function PostVettingModal({ open, onClose }: IProps) {
  return (
    <Modal open={open} onClose={onClose} size="sm">
      <Modal.Title>Thanks For Submitting This Brand Inquiry!</Modal.Title>
      <Modal.Body>
        <strong>What happens next?</strong>
        <p className="m-bs">
          We'll take it from here! Our team will share your responses with the advertiser as we
          pitch your show for an upcoming campaign.
        </p>
        <strong>Am I going to get this deal?</strong>
        <p className="m-bs">
          We hope so! Our team will try our best to score the deal for you, but in the end, it's up
          to the advertiser to select which podcasts are the best fit for their campaign.
        </p>
        <strong>I've filled a lot of these out, but I don't get many deals. What's wrong?</strong>
        <p className="m-b0">
          Bummer! If you're getting brand inquiries, our team is putting your show in front of
          brands, and that's half the battle. Check out{" "}
          <a
            href="https://support.redcircle.com/how-to-get-more-ad-deals"
            target="_blank"
            rel="noopen noreferrer">
            this article
          </a>{" "}
          for some tips on how to improve your show's likelihood of getting advertising in RAP.
        </p>
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.SubmitButton onClick={onClose} className="m-la">
          Close
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
}
