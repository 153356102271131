import { BarsOutlined, BorderOutlined, FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { classNames } from "react-extras";
import { Link, useHistory } from "react-router-dom";
import Button from "src/components/lib/button";
import LoadingOverlay from "src/components/lib/loading/loading_overlay";
import Page from "src/components/lib/page";
import { permissionPresets } from "src/constants/permission_roles";
import {
  SHOW_SEARCH_FILTER_EPISODE_FREQUENCY,
  SHOW_SEARCH_MAX_CPM,
  SHOW_SEARCH_MAX_DOWNLOADS,
  SHOW_SEARCH_MIN_CPM,
  SHOW_SEARCH_MIN_DOWNLOADS,
} from "src/constants/search";
import { useDebounce, useQuery } from "src/hooks/lib";
import { useGetMyPermissions } from "src/hooks/org";
import { useShowSearchFromURL } from "src/hooks/shows";
import { isUserPresetAllowed } from "src/lib/permission_roles";
import { IShow } from "redcircle-types";
import AddToCampaignModal from "../add_to_campaign_modal/add_to_campaign_modal";
import styles from "./advertiser_browse_page.module.scss";
import AdvertiserBrowseResults from "./advertiser_browse_results";
import BrowseFilterSidebarForm from "./browse_filter_sidebar_form";

const DEFAULT_SORT_KEY = "downloadsDesc";
const VIEW_GRID = "grid";
const VIEW_LIST = "list";
export default function AdvertiserBrowsePage() {
  const searchResultsRef = useRef<HTMLDivElement>(null);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [showAddModalShow, setAddModalShow] = useState<IShow | null>(null);

  // maintain overall state in search params
  const history = useHistory();
  const queryParams = useQuery();
  const {
    query = "",
    sort = DEFAULT_SORT_KEY,
    categoryUUID,
    minEstimatedWeeklyDownloads,
    maxEstimatedWeeklyDownloads,
    minAverageCPM,
    maxAverageCPM,
    episodeFrequency,
    createdAtSince,
    page,
    primaryGender,
    includeNSFW,
    view = VIEW_GRID,
  } = queryParams;

  // results
  const { results, isLoading } = useShowSearchFromURL();

  // split query into its own state to rerender faster
  const [searchQuery, setSearchQuery] = useState(query);
  const debouncedQuery = useDebounce(searchQuery);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const { orgPermission } = useGetMyPermissions();
  const canAddNewCampaign = isUserPresetAllowed({
    userPreset: orgPermission.preset,
    limitPreset: permissionPresets.campaignEditor,
  });

  // if query changes due to any other reason (nav, etc) then resync search query
  useEffect(() => {
    if (query !== searchQuery) {
      setSearchQuery(query);
    }
  }, [query]);

  // update url state when query changes
  useEffect(() => {
    const newParams = { ...queryParams, query: debouncedQuery };
    // if there are currently results lets reset page, only if data has already been loaded
    if (!isInitialLoading) {
      if (results.length) delete newParams.page;
    }
    history.replace(`/browse?${paramsToQueryString(newParams)}`);
    if (isInitialLoading) setIsInitialLoading(false);
    searchResultsRef?.current?.scroll({ top: 0, behavior: "smooth" });
  }, [debouncedQuery]);

  // update url state when filter changes
  const handleFormSubmit = (formValues: any) => {
    if (isMobileSidebarOpen) setIsMobileSidebarOpen(false);
    const filters: any = {};
    if (formValues.sort) filters.sort = formValues.sort;
    if (formValues.category) filters.categoryUUID = formValues.category.join(",");
    if (formValues.downloads) {
      if (formValues.downloads[0] !== SHOW_SEARCH_MIN_DOWNLOADS) {
        filters.minEstimatedWeeklyDownloads = formValues.downloads[0];
      }
      if (formValues.downloads[1] !== SHOW_SEARCH_MAX_DOWNLOADS) {
        filters.maxEstimatedWeeklyDownloads = formValues.downloads[1];
      }
    }
    if (formValues.cpm) {
      if (formValues.cpm[0] > SHOW_SEARCH_MIN_CPM) {
        filters.minAverageCPM = formValues.cpm[0];
      }
      if (formValues.cpm[1] !== SHOW_SEARCH_MAX_CPM) {
        filters.maxAverageCPM = formValues.cpm[1];
      }
    }
    if (formValues.episodeFrequency) filters.episodeFrequency = formValues.episodeFrequency;
    if (formValues.createdAtSince) filters.createdAtSince = formValues.createdAtSince;
    if (formValues.primaryGender) filters.primaryGender = formValues.primaryGender;
    if (formValues.includeNSFW) filters.includeNSFW = formValues.includeNSFW;
    const newParams = { query, ...filters, view };
    history.replace(`/browse?${paramsToQueryString(newParams)}`);
    searchResultsRef?.current?.scroll({ top: 0, behavior: "smooth" });
  };

  const handleFormClear = () => {
    const newParams = { query, view };
    history.replace(`/browse?${paramsToQueryString(newParams)}`);
    searchResultsRef?.current?.scroll({ top: 0, behavior: "smooth" });
  };

  const handlePageChange = (page: number) => {
    const newParams = { ...queryParams, page };
    history.replace(`/browse?${paramsToQueryString(newParams)}`);
    searchResultsRef?.current?.scroll({ top: 0, behavior: "smooth" });
  };

  const handleToggleView = (newView: string) => {
    const newParams = { ...queryParams, view: newView };
    history.replace(`/browse?${paramsToQueryString(newParams)}`);
    searchResultsRef?.current?.scroll({ top: 0, behavior: "smooth" });
  };

  const handleClickAddResult = (show: IShow) => {
    setAddModalShow(show);
  };

  const paramsToQueryString = (params: any) =>
    Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== "")
      .map((key) => `${key}=${params[key]}`)
      .join("&");

  const formState = {
    sort: sort || DEFAULT_SORT_KEY,
    category: categoryUUID ? categoryUUID.split(",") : undefined,
    downloads: [
      minEstimatedWeeklyDownloads || SHOW_SEARCH_MIN_DOWNLOADS,
      maxEstimatedWeeklyDownloads || SHOW_SEARCH_MAX_DOWNLOADS,
    ],
    cpm: [minAverageCPM || SHOW_SEARCH_MIN_CPM, maxAverageCPM || SHOW_SEARCH_MAX_CPM],
    episodeFrequency: episodeFrequency || SHOW_SEARCH_FILTER_EPISODE_FREQUENCY.all.value,
    createdAtSince: parseInt(createdAtSince, 10) || 0,
    primaryGender: primaryGender || "all",
    includeNSFW: includeNSFW || false,
  };

  return (
    <>
      <Row>
        <Col xs={24} md={15} lg={17} xl={18}>
          <Page
            className={classNames("browse-page p-r0 p-b0", styles.page)}
            pageTitle="Browse Podcasts">
            <Page.Header className="p-rs flex-row-container align-center">
              <Page.Title>Search Podcasts</Page.Title>
              <div className={classNames(styles["browse-shows-buttons"], "m-lxs")}>
                <div className="flex-row-container flex-nowrap align-center">
                  <strong className="m-rxxxs">View: </strong>
                  <Button
                    type="link"
                    className="p-a0 m-rxxs"
                    onClick={() => handleToggleView(VIEW_LIST)}>
                    <BarsOutlined />
                  </Button>
                  <Button type="link" className="p-a0" onClick={() => handleToggleView(VIEW_GRID)}>
                    <BorderOutlined />
                  </Button>
                </div>
                {canAddNewCampaign && (
                  <Link to="/campaigns/new">
                    <Button type="link" size="small" className="p-a0">
                      New Campaign
                    </Button>
                  </Link>
                )}
              </div>
            </Page.Header>
            <Page.Divider />

            <Page.Section className="p-rs m-bxs flex-row-container align-center">
              <Input
                type="search"
                size="large"
                placeholder="Search podcasts..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                allowClear
                prefix={<SearchOutlined />}
              />
              <Button
                type="link"
                className={styles["filter-button"]}
                onClick={() => setIsMobileSidebarOpen(true)}>
                <FilterOutlined />
              </Button>
            </Page.Section>

            <Page.Section
              className="p-r0 flex-column-container"
              style={{ overflow: "hidden", position: "relative" }}>
              {isLoading && <LoadingOverlay />}
              <AdvertiserBrowseResults
                view={view}
                results={results}
                isLoading={isLoading}
                currentPage={page}
                onPageChange={handlePageChange}
                onClickAdd={handleClickAddResult}
                ref={searchResultsRef}
              />
            </Page.Section>
          </Page>
        </Col>

        <Col
          xs={12}
          md={9}
          lg={7}
          xl={6}
          className={classNames(styles.sidebar, isMobileSidebarOpen && styles["mobile-sidebar"])}>
          <Page.Sidebar className={styles["browse-shows-sidebar"]}>
            <BrowseFilterSidebarForm
              values={formState as any}
              onSubmit={handleFormSubmit}
              onClear={handleFormClear}
              onClose={() => setIsMobileSidebarOpen(false)}
            />
          </Page.Sidebar>
        </Col>
      </Row>

      {/* MODALS */}
      <AddToCampaignModal
        visible={!!showAddModalShow}
        onClose={() => setAddModalShow(null)}
        show={showAddModalShow}
      />
    </>
  );
}
