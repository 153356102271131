import fetch from "cross-fetch";
import getAPIPrefix from "../lib/api_prefix";

function addFunds(user, amount, stripeToken) {
  return fetch(getAPIPrefix() + "transactions/add-funds", {
    method: "post",
    headers: {
      Authorization: "Bearer " + user.authToken,
    },
    body: JSON.stringify({
      stripeToken,
      amount,
    }),
  });
}

function withdrawFunds(user, instant = false) {
  return fetch(getAPIPrefix() + "transactions/withdraw", {
    method: "post",
    headers: {
      Authorization: "Bearer " + user.authToken,
    },
    body: JSON.stringify({ instantPayout: instant }),
  });
}

const transactionsAPI = {
  addFunds,
  withdrawFunds,
};

export default transactionsAPI;
