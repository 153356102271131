import React from "react";
import ExternalLink from "../components/lib/external_link";

export const HELP_GUIDE_BY_HOST = {
  Acast: acast,
  Anchor: anchor,
  Audioboom: audioboom,
  Blubrry: blubrry,
  Buzzsprout: buzzsprout,
  Libsyn: libsyn,
  Megaphone: megaphone,
  Podbean: podbean,
  Spreaker: spreaker,
  Simplecast: simplecast,
  Soundcloud: soundcloud,
  Other: other,
};

function acast(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>
          Email <a href="mailto:support@acast.com">support@acast.com</a> and let them know you would
          like to redirect your Acast RSS feed to your RedCircle RSS feed URL:
          {podcacheRSSURL}
        </li>
        <li>{clickVerifyLinkText}</li>
      </ol>
    </div>
  );
}

function anchor(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>
          Log into your{" "}
          <a href="https://anchor.fm" target="_blank" rel="noopener noreferrer">
            Anchor account
          </a>{" "}
          on the web.
        </li>
        <li>
          Click <b>Settings</b> in the top right of your screen, then click <b>Distribution</b>.
        </li>
        <li>
          Scroll down to the advanced settings section of the distribution page and paste your new
          RedCircle RSS feed URL in the box provided for <b>Redirect URL</b>: {podcacheRSSURL}
        </li>
        <li>
          Click <b>Redirect my podcast</b>
        </li>
      </ol>
    </div>
  );
}

function audioboom(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>
          Email <a href={"support@audioboom.com"}>support@audioboom.com</a> and let them know you
          would like to redirect your Audioboom RSS feed to your RedCircle RSS Feed URL:{" "}
          {podcacheRSSURL}
        </li>
        <li>{clickVerifyLinkText}</li>
      </ol>
    </div>
  );
}

function blubrry(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>
          Contact{" "}
          <a
            href="https://create.blubrry.com/blubrry-support/"
            target="_blank"
            rel="noopener noreferrer">
            Blubrry support{" "}
          </a>
          and let them know you would like to redirect your RSS feed to your RedCircle RSS feed URL:
          {podcacheRSSURL}
        </li>
        <li>
          For more information:{" "}
          <a
            href="https://create.blubrry.com/manual/syndicating-your-podcast-rss-feeds/changing-your-podcast-rss-feed-address-url/"
            target="_blank"
            rel="noopener noreferrer">
            Blubrry support page.
          </a>
        </li>
        <li>{renderVerifyLinkHelpText("Blubrry")}</li>
      </ol>
    </div>
  );
}

function buzzsprout(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>
          Within your{" "}
          <a href="https://www.buzzsprout.com/login" target="_blank" rel="noopener noreferrer">
            Buzzsprout
          </a>{" "}
          account, go to <b>Podcast Settings</b> and click on the <b>Cancel/Redirect Podcast</b>{" "}
          tab.
        </li>
        <li>
          Select the center option to <b>Redirect Your Podcast Feed</b>.
        </li>
        <li>
          From there you will be taken to an informational page with the full process of placing
          your 301 redirect. Follow each step to make sure you place the redirect successfully.
        </li>
        <li>When prompted enter your RedCircle RSS feed URL: {podcacheRSSURL}</li>
        <li>
          For more information see:{" "}
          <a
            href="https://www.buzzsprout.com/help/9-transfer-away-from-buzzsprout"
            target="_blank"
            rel="noopener noreferrer">
            Buzzsprout Help FAQ
          </a>
        </li>
        <li>{clickVerifyLinkText}</li>
      </ol>
    </div>
  );
}

function megaphone(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>
          Contact{" "}
          <a href="https://megaphone.fm/contact" target="_blank" rel="noopener noreferrer">
            Megaphone support{" "}
          </a>
          via email or live chat, and let them know you would like to redirect your Megaphone RSS
          feed to your RedCircle RSS feed URL:
          {podcacheRSSURL}
        </li>
        <li>
          For more information:{" "}
          <a
            href="http://support.megaphone.fm/megaphone-faqs/how-do-i-setup-a-301-redirect-from-megaphone-to-a-new-hosting-provider"
            target="_blank"
            rel="noopener noreferrer">
            Megaphone support page.
          </a>
        </li>
        <li>{renderVerifyLinkHelpText("Megaphone")}</li>
      </ol>
    </div>
  );
}

function libsyn(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>
          Login to your{" "}
          <a href="https://login.libsyn.com/" target="_blank" rel="noopener noreferrer">
            Libsyn
          </a>{" "}
          dashboard.
        </li>
        <li>
          Click on <b>Settings</b> and then <b>Edit Show Settings</b> from the menu.
        </li>
        <li>
          Select <b>Redirects</b> from the sidebar.
        </li>
        <li>
          Set the{" "}
          <b>
            <u>Feed</u> Redirect URL
          </b>{" "}
          to your RedCircle RSS URL:
          {podcacheRSSURL}
        </li>
        <li>
          Click the green <b>Save</b> button at the bottom of the page.
        </li>
      </ol>
      <div>
        <small>
          NOTE: Later on, you may want to return to this page, and set the Page Redirect URL to your
          RedCircle public page.
        </small>
      </div>
    </div>
  );
}

function podbean(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>
          Login to your{" "}
          <a href="https://www.podbean.com/login" target="_blank" rel="noopener noreferrer">
            Podbean account
          </a>
          .
        </li>
        <li>
          Navigate to the <b>Settings</b> page.
        </li>
        <li>
          Click the <b>Feed</b> tab. Scroll down to the bottom, and click into{" "}
          <b>Advanced Feed Settings</b>.
        </li>
        <li>
          In the <b>Redirect to a New Feed URL</b> section, paste your RedCircle RSS Feed URL:{" "}
          {podcacheRSSURL}
        </li>
        <li>{clickVerifyLinkText}</li>
      </ol>
    </div>
  );
}

function spreaker(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>
          Login to your
          <ExternalLink> Spreaker account and visit your show's page.</ExternalLink>
        </li>
        <li>
          Click on <b>RSS Customization</b>.
        </li>
        <li>
          Select <b>Redirect Feed</b> from the options at the top.
        </li>
        <li>
          In the <b>New Feed URL</b> section, paste your RedCircle RSS feed URL: {podcacheRSSURL}
        </li>
        <li>
          Click <b>Save</b> at the bottom.
        </li>
        <li>{clickVerifyLinkText}</li>
      </ol>
    </div>
  );
}
function simplecast(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>
          <ExternalLink
            href="https://auth.simplecast.com/"
            target="_blank"
            rel="noopener noreferrer">
            Log in{" "}
          </ExternalLink>
          to your Simplecast dashboard
        </li>
        <li>
          Click drop-down menu in the upper left and select <b>'Show Distribution'</b>
        </li>
        <li>
          Scroll to the very bottom and click on arrow to expand <b>'Advanced Settings'</b>.
        </li>
        <li>
          Locate the <b>RSS Feed Redirect</b>. field.
        </li>
        <li>
          Paste your new RSS feed URL to properly redirect to RedCircle. RSS URL: {podcacheRSSURL}
        </li>
        <li>
          Press <b>Save</b> in the upper right corner of your dashboard.
        </li>
        <li>{clickVerifyLinkText}</li>
      </ol>
    </div>
  );
}

function soundcloud(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>
          Follow instructions{" "}
          <ExternalLink
            target="_blank"
            rel="noopener noreferrer"
            to={
              "https://help.soundcloud.com/hc/en-us/articles/115003564088-Redirecting-your-SoundCloud-RSS-feed-to-another-host"
            }>
            here
          </ExternalLink>
        </li>
        <li>In the "Subscriber redirect" textbox, paste the following link: {podcacheRSSURL}</li>
        <li>{clickVerifyLinkText}</li>
      </ol>
    </div>
  );
}

function other(podcacheRSSURL) {
  return (
    <div>
      <ol>
        <li>Check to see if your hosting service is listed in the dropdown above.</li>
        <li>
          If you cannot find your hosting provider in our help guide, then contact your podcast
          hosting service's support and ask to redirect your RSS feed URL to RedCircle's:
          {podcacheRSSURL}
        </li>
        <li>{clickVerifyLinkText}</li>
      </ol>
    </div>
  );
}

function renderVerifyLinkHelpText(hostName) {
  return (
    <span>
      Once {hostName} support confirms that a redirect is set to point to your RedCircle RSS feed,
      click the <b>Verify Redirect</b> button below to ensure the redirect went through.
    </span>
  );
}

const clickVerifyLinkText = (
  <span>
    Click the <b>Verify Redirect</b> button below to ensure the redirect went through.
  </span>
);
