import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import { If } from "react-extras";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { showMessageWithTimeout } from "../../../actions/app";
import { hideModal } from "../../../actions/modal";
import { getMediaFile } from "../../../action_managers/shows";
import {
  CONVERSION_STATE_KEY_DONE,
  CONVERSION_STATE_KEY_FAILED,
} from "../../../constants/conversion_states";
import messageType from "../../../constants/message_types";
import insertionPointsStatic from "../../../images/insertion-points-static.png";
import insertionPoints from "../../../images/insertion-points.gif";
import processAudioStatic from "../../../images/process-audio-static.png";
import processAudio from "../../../images/process-audio.gif";
import uploadFileStatic from "../../../images/upload-file-static.png";
import uploadFile from "../../../images/upload-file.gif";
import BaseModalV2 from "../base_modal_v2";

const marginAmount = 64;
const translateAmount = (margin) => margin + 228;
const offsets = [translateAmount(marginAmount), 0, -1 * translateAmount(marginAmount)];
const ModalContent = ({
  uploadAndEditEpisode,
  setError,
  getMediaFile,
  hideModal,
  progress,
  needsToInsertMarkers = false,
}) => {
  const [currentStep, setCurrentStep] = useState(needsToInsertMarkers ? 2 : 0);
  const [updatedEpisode, setUpdatedEpisode] = useState({});
  const timeoutRef = useRef(null);
  const history = useHistory();
  const steps = [
    {
      loadingImage: uploadFile,
      notLoadingImage: uploadFileStatic,
      bottomCopy: `Uploading your episode's audio file (${Math.ceil(progress * 100)}%)`,
    },
    {
      loadingImage: processAudio,
      notLoadingImage: processAudioStatic,
      bottomCopy: "Processing your episode's audio file",
    },
    {
      loadingImage: insertionPoints,
      notLoadingImage: insertionPointsStatic,
      bottomCopy: "Generating your episode's waveform and Insertion Points",
    },
  ];

  const onNext = (updatedEpisode) => {
    history.push(
      `/shows/${updatedEpisode.showUUID}/ep/${updatedEpisode.uuid}/insertion-points?postUpload=true`
    );
  };

  const startEpisodePoll = (updatedEpisode) => {
    timeoutRef.current = setInterval(() => {
      getMediaFile(updatedEpisode.contentMediaFileUUID).then(handleMediaFileResponse);
    }, 3000);
  };

  const handleMediaFileResponse = (resp) => {
    if (resp.status !== 200) {
      hideModal();
      return;
    }
    if (currentStep === 1) {
      const { conversionState } = resp.json;

      if (
        conversionState === CONVERSION_STATE_KEY_DONE ||
        conversionState === CONVERSION_STATE_KEY_FAILED
      ) {
        setCurrentStep(2);
      }
    } else if (currentStep === 2) {
      const { waveformURL, markersAdded } = resp.json;
      if (waveformURL && markersAdded) {
        onNext(updatedEpisode);
      }
    }
  };

  useEffect(() => {
    if (currentStep !== 0) {
      timeoutRef.current && clearInterval(timeoutRef.current);
      startEpisodePoll(updatedEpisode);
    }
  }, [currentStep]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (uploadAndEditEpisode) {
      uploadAndEditEpisode()
        .then((resp) => {
          if (resp.status !== 200) {
            hideModal();
          }
          setUpdatedEpisode(resp.json);
          setCurrentStep(1);
        })
        .catch((e) => setError(e.message));
    }
  }, []);

  return (
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <div className={"flex-row-container justify-center m-txxl"}>
            {steps.map((step, i) => {
              const isCurrentStep = i === currentStep;
              return (
                <motion.div
                  key={i}
                  animate={{
                    x: offsets[currentStep],
                    opacity: isCurrentStep ? 1 : 0.2,
                  }}
                  transition={{ ease: "easeInOut", type: "tween", duration: 0.7 }}
                  style={{
                    marginRight: i < steps.length - 1 ? marginAmount : 0,
                  }}>
                  <img
                    style={{
                      width: 228,
                      height: 228,
                    }}
                    src={isCurrentStep ? step.loadingImage : step.notLoadingImage}
                    alt=""
                  />
                </motion.div>
              );
            })}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={10} xsOffset={1}>
          <h3 className={"text-center m-vxxl"}>
            Step {currentStep + 1} of {steps.length}: {steps[currentStep].bottomCopy}
          </h3>
        </Col>
      </Row>
    </Grid>
  );
};

const UploadInterimModal = (props) => {
  let subCopy = (
    <span>
      Hold tight while we process your episode! This may take several minutes for larger episode
      files. Once complete, you’ll be prompted to configure your Insertion Points for this episode.
      <If condition={!!props.show.advertisementSettings}>
        <>
          <br />
          <br />
          <span className="bold">Important: </span>
          We insert the mid-roll at your 50% timestamp, so we recommend you drag this point to a
          timestamp that sounds natural for an ad break.
        </>
      </If>
    </span>
  );

  return (
    <BaseModalV2
      containerClass={"modal-hide-overflow"}
      title={"Episode Progress"}
      subTitle={subCopy}
      hideTitleDivider
      hideCloseButton={true}
      showContent={true}
      hideFooter
      Content={<ModalContent {...props} />}
    />
  );
};

export default connect(
  (state) => ({
    progress: state.mediaFileUpload.progress,
  }),
  {
    getMediaFile,
    setError: (errorMessage) => showMessageWithTimeout(errorMessage, messageType.Error),
    hideModal,
  }
)(UploadInterimModal);
