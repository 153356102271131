import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ForgotPasswordActionManager } from "../../../action_managers/forgot_password";
import { withPageTitle } from "../../lib/WithPageTitle";
import ForgotPasswordPage from "./forgot_password_page";

function mapStateToProps(state) {
  return {
    isLoading: state.forgotPassword.isLoading,
    hasMessage: state.forgotPassword.hasMessage,
    isError: state.forgotPassword.isError,
    message: state.forgotPassword.message,
    isSuccess: state.forgotPassword.isSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    forgotPassword: (formJSON) => {
      dispatch(new ForgotPasswordActionManager({ email: formJSON.email }).run());
    },
  };
}

const ForgotPasswordPageWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage)
);

export default withPageTitle(ForgotPasswordPageWrapper, "Forgot Password");
