import get from "lodash/get";
import React, { Component } from "react";
import { If } from "react-extras";
import { connect } from "react-redux";
import { showSuccess } from "../../actions/app";
import { distribute, putDistributions } from "../../action_managers/distributions";
import { distributionKeyNames } from "../../constants/distribution";
import RCButton from "./button";
import ExternalLink from "./external_link";

class SpotifyDistribution extends Component {
  state = {
    showError: false,
    distributionUrl: "",
    showURL: false,
  };

  componentDidMount() {
    const url = this.getURL();
    if (url) {
      this.setState({
        distributionUrl: url,
        showURL: true,
      });
    }
  }

  getShowUUID = () => this.props.showUUID;

  getURL = () => {
    return get(this.props.distributions, [this.getShowUUID(), distributionKeyNames.spotify]);
  };

  distribute = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.distribute(this.getShowUUID(), [distributionKeyNames.spotify]).then((resp) => {
      if (resp.status === 200) {
        if (get(resp, "json.spotify.errCode") === 409) {
          this.setState({ showError: true });
          return;
        }
        this.props.showSuccess(
          "Successfully distributed to Spotify. It may take up to 24 hours for your show to appear."
        );
      }
    });
  };
  submitSpotifyLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.putDistributions(this.getShowUUID(), {
      [distributionKeyNames.spotify]: this.state.distributionUrl,
    });
  };
  render() {
    const showURL = this.getURL();
    return (
      <div>
        <If condition={this.state.showError}>
          <div className={"spotify-distribution-error"}>
            Looks like your podcast has already been registered. Click{" "}
            <ExternalLink href="https://support.redcircle.com/distributing-your-podcast-to-spotify">
              here
            </ExternalLink>{" "}
            for help.
          </div>
        </If>
        <RCButton type="primary" onClick={this.distribute}>
          Confirm
        </RCButton>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    distributions: state.distributions.distributions,
    validationErrors: state.distributions.validationErrors,
  };
}

export default connect(mapStateToProps, {
  showSuccess,
  distribute,
  putDistributions,
})(SpotifyDistribution);
