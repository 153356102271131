import { permissionPresets, permissionTypes } from "../constants/permission_roles";
import { ObjectType } from "../reducers/permissions";
import { defaultActionManagerRunner } from "./default_action_manager";

export const GET_CREDENTIALS_IN_ORG = "GET_CREDENTIALS_IN_ORG";
export const CREDENTIAL_CREATE = "CREDENTIAL_CREATE";
export const CREDENTIAL_DELETE = "CREDENTIAL_DELETE";

export interface CreateCredential {
  userUUID: string;
  email: string;
  firstName: string;
  lastName: string;
  permissions: Array<{
    credentialUUID?: string;
    objectUUID: string;
    objectType: ObjectType;
    overrides?: Partial<Record<typeof permissionTypes[keyof typeof permissionTypes], boolean>>;
    preset: typeof permissionPresets[keyof typeof permissionPresets];
  }>;
}

export const getCredentialsInOrg = () => {
  return defaultActionManagerRunner({
    actionName: GET_CREDENTIALS_IN_ORG,
    auth: true,
    route: "users/my-org-credentials",
  });
};

export const addCredential = (credentialConfig: CreateCredential) => {
  return defaultActionManagerRunner({
    method: "post",
    actionName: CREDENTIAL_CREATE,
    auth: true,
    route: "credentials",
    body: credentialConfig,
    successMessage: "Invite Email Sent",
    messageSize: "large",
  });
};

export const deleteCredential = (credentialUUID: string) => {
  return defaultActionManagerRunner({
    method: "DELETE",
    actionName: CREDENTIAL_DELETE,
    actionData: {
      credentialUUID,
    },
    auth: true,
    route: `credentials/${credentialUUID}`,
    successMessage: "User successfully deleted",
    messageSize: "large",
  });
};
