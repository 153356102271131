export type MediaFile = {
  uuid: string;
  fileType: FileType;
  fileName: string;
  userUUID: string;
  url: string;
  convertedURL: string;
  createdAt: number;
  updatedAt: number;
  contentSize: number;
  sourceBitrate: number;
  duration: number;
  durationIsSet: boolean;
  conversionState: MediaFileConversionState;
  bitrate: number;
  id3DataSize: number;
  waveformURL: string;
  markersAdded: boolean;
  convertError: string | null;
  isLoading: boolean;
};

export enum MediaFileConversionState {
  "needs_processing" = "needs_processing",
  "processing" = "processing",
  "done" = "done",
  "failed" = "failed",
  "not_applicable" = "not_applicable",
}

type FileType =
  | "image/jpeg"
  | "image/png"
  | "image/jpg"
  | "audio/mpeg"
  | "audio/mp3"
  | "audio/x-m4a"
  | "audio/m4a"
  | "binary/octet-stream";

/**
 *  Type guard to check if an unknown value is a valid MediaFile object,
 * @param value
 * @returns {Boolean}
 */
export function isMediaFile(value?: unknown): value is MediaFile {
  if (
    typeof value === "object" &&
    value !== null &&
    "uuid" in value &&
    "userUUID" in value &&
    "fileName" in value &&
    "url" in value &&
    "createdAt" in value &&
    "updatedAt" in value &&
    typeof value.uuid === "string" &&
    typeof value.userUUID === "string" &&
    typeof value.fileName === "string" &&
    typeof value.url === "string" &&
    typeof value.createdAt === "number" &&
    typeof value.updatedAt === "number"
  ) {
    return true;
  }

  return false;
}
