import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { hideModal } from "../../actions/modal";

class PostActionModal extends Component {
  componentDidMount() {
    setTimeout(this.props.hideModal, this.props.timeout || 3000);
  }

  render() {
    return (
      <div className="static-modal">
        <Modal dialogClassName={"post-action-modal modal-dialog-centered"} show={true}>
          <Modal.Body>
            <div className="post-action-modal__check-mark">
              <span className="post-action-modal__check-mark-inner" />
            </div>
            <div className="post-action-modal__message">
              {this.props.message || "Proposal Sent"}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(() => ({}), {
  hideModal,
})(PostActionModal);
