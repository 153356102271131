import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showError } from "../../actions/app";
import { hideModal } from "../../actions/modal";
import { showTip } from "../../actions/shows";
import { getShowUUID } from "../../lib/getShowUUID";
import { centsToMillicents } from "../../lib/money";
import PaymentModal from "./payments-modal";

class TipModal extends Component {
  submitTip = async (options) => {
    let args = {
      amount: centsToMillicents(options.amount),
      showUUID: this.getShowUUID(),
    };
    if (this.props.user) {
      args = {
        ...args,
        email: this.props.user.email,
        stripeToken: options.cardID,
      };
    } else {
      args = {
        ...args,
        email: options.email,
        stripeToken: options.token.id,
      };
    }
    let resp = await this.props.showTip(args);
    if (resp.status !== 200) {
      resp?.json?.validationErrors?.length > 0 &&
        this.props.showError(resp?.json?.validationErrors?.[0]?.errorMessage);
      return;
    }

    this.props.hideModal();
  };
  getShowUUID = () => getShowUUID(this.props);
  render() {
    const options = [
      {
        type: "fixedPrice",
        optionKey: "4.99",
        amount: 499,
      },
      { type: "custom", optionKey: "custom" },
    ];
    const amount = options[0].amount;
    return (
      <PaymentModal
        {...this.props}
        newCardOnly
        amount={amount}
        submit={this.submitTip}
        page1Options={options}
        mode={"tip"}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    publicShows: state.publicShows,
    user: state.user.user,
  };
}

export default connect(mapStateToProps, {
  hideModal,
  showTip,
  showError,
})(withRouter(TipModal));
