import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { If } from "react-extras";
import Spinner from "../lib/spinner";
import "./modal.scss";

class BaseModal extends Component {
  componentDidMount() {
    this.props.setBitrate(128); // default
  }

  handleInput = (event) => {
    event.preventDefault();
    event.persist();
    this.props.handleInput(event.target.files[0], this.props.user);
  };

  render() {
    return (
      <div className="static-modal">
        <Modal.Dialog className="seedcast-modal">
          <Modal.Header>
            <Modal.Title>Upload {this.props.FileTypeName}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Spinner isLoading={this.props.isLoading} />
            <If condition={Boolean(this.props.errorMessage)}>
              <div className="alert alert-danger" role="alert">
                {this.props.errorMessage}
              </div>
            </If>
            <label>
              Select File
              <input
                onChange={this.handleInput}
                name="file"
                type="file"
                accept={this.props.accept}
              />
            </label>
            {this.props.optionalSection && this.props.optionalSection()}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.closeModal}>Cancel</Button>
            <If condition={Boolean(this.props.mediaFiles) && Boolean(this.props.onClick)}>
              <Button onClick={this.props.onClick} bsStyle="primary">
                Select
              </Button>
            </If>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    );
  }
}

export default BaseModal;
