import get from "lodash/get";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SHOW_CHECK_REDIRECT } from "../../actions/shows";
import RedirectHelpModalActionManager from "../../action_managers/redirect_help_modal";
import { ShowsForUserActionManager } from "../../action_managers/shows";
import ShowRedirectHelpModal from "./show_redirect_help_modal";

function mapStateToProps(state) {
  return {
    shows: state.shows.shows,
    user: state.user.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchShowsForUser: (user) => {
      return dispatch(new ShowsForUserActionManager({ user }).run());
    },

    fetchVerifyRedirect: (show) => {
      return dispatch(
        new RedirectHelpModalActionManager({
          auth: true,
          route: `shows/${show.uuid}/check-redirect`,
          actionName: SHOW_CHECK_REDIRECT,
          method: "POST",
          actionData: { show: show },
          showTitle: get(show, "title"),
        }).run()
      );
    },
  };
}

const ShowRedirectHelpModalWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShowRedirectHelpModal)
);

export default ShowRedirectHelpModalWrapper;
