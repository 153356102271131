import get from "lodash/get";
import indexOf from "lodash/indexOf";
import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { If } from "react-extras";
import { connect } from "react-redux";
import Loading from "src/components/lib/loading/loading";
import { AlbumDisplay } from "../../lib/album_art";
import Divider from "../../lib/divider";
import "./browse_row.scss";

class BrowseRow extends Component {
  renderAlbumArts = (show) => {
    const { displayMetadata = false } = this.props;
    let episodeCount = undefined;
    let bucketSize = undefined;
    if (displayMetadata) {
      episodeCount = get(show, "episodeCount", 0);
      bucketSize = get(show, "bucketedAudienceSize", "calculating");
    }

    return (
      <Col md={12 / (this.props.count || 6)} xs={6} key={show.uuid}>
        <AlbumDisplay
          uuid={show.uuid}
          subTitle={show.ownerName}
          src={show.imageURL}
          alt={`${show.title}`}
          title={show.title}
          episodeCount={episodeCount}
          bucketSize={bucketSize}
          linkExternally={true}
        />
      </Col>
    );
  };

  getShowList = () => {
    let ignoreUUIDs = get(this.props, "ignoreUUIDs", []);
    let showlist = get(this.props, "shows", [])
      .filter(({ userUUID }) => userUUID !== this.props.user.uuid)
      .filter(({ isRemote }) => !isRemote)
      .filter(({ uuid }) => {
        return indexOf(ignoreUUIDs, uuid) === -1;
      });
    return showlist;
  };

  getEmptyContent = () => {
    if (!this.props.noResultsText) {
      return null;
    }

    return <div className="browse-row__no-results-text">{this.props.noResultsText}</div>;
  };

  render() {
    if (!this.getShowList().length && !this.props.isLoading) {
      return this.getEmptyContent();
    }
    return (
      <div className={this.props.className}>
        <Row>
          <Col xs={12}>
            <If condition={!!this.props.title}>
              <h3 className="browse-row__title">{this.props.title}</h3>
              <Divider smallMargin className="browse-row-divider" />
            </If>
          </Col>
        </Row>
        <Row className="browse-row__row">
          {this.props.isLoading && <Loading />}
          <If condition={!this.props.isLoading}>
            {this.getShowList()
              .slice(0, this.props.count || 6)
              .map(this.renderAlbumArts)}
          </If>
        </Row>
      </div>
    );
  }
}

export default connect((state) => ({ user: state.user.user }), {})(BrowseRow);
