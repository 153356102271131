import { AiOutlineCopy } from "react-icons/ai";
import { copyToClipboard } from "redcircle-lib";
import { Button } from "../Button";
import styles from "./CopyText.module.scss";

export default function CopyText({
  text,
  afterCopy,
}: {
  text: string;
  afterCopy?: (text?: string) => void;
}) {
  const handleCopy = () => {
    copyToClipboard(text);
    if (afterCopy) afterCopy(text);
  };

  return (
    <div className="flex-row-container align-center width-100">
      <div className={styles.container}>{text}</div>
      <Button type="primary" className="m-lxxs" onClick={handleCopy}>
        <AiOutlineCopy />
      </Button>
    </div>
  );
}
