import chunk from "lodash/chunk";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { itunesCategories } from "../../../data/itunes_categories";
import Divider from "../../lib/divider";

const getRootLevels = () => itunesCategories.filter(({ group_uuid }) => !group_uuid);

class CategoryList extends Component {
  rootLevels = getRootLevels();

  render() {
    return (
      <div className={"browse-promotions-page__category-wrapper"}>
        <Row>
          <Col xs={12}>
            <h3 className="browse-promotions-page__categories-title">Categories</h3>
          </Col>
          <Col xs={12}>
            <Divider smallMargin />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {chunk(this.rootLevels, 2).map((pair, i) => (
              <Row className="category-row" key={i}>
                {pair.map(({ name, uuid }) => (
                  <Col xs={12} md={6} key={uuid} className="category-column">
                    <Link
                      to={`/promotions/categories/${uuid}`}
                      className={"browse-promotions-page__category-item no-style-link"}>
                      <span className="browse-promotions-page__category-name">{name}</span>
                      <span className="browse-promotions-page__category-arrow chevron--right" />
                    </Link>
                  </Col>
                ))}
              </Row>
            ))}
          </Col>
        </Row>
      </div>
    );
  }
}

export default CategoryList;
