import parallelLimit from "async/parallelLimit";
import React, { Component } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showMessageWithTimeout } from "../../actions/app";
import { hideModal } from "../../actions/modal";
import { AccountUpdateActionManager, setUserAttribute } from "../../action_managers/account";
import {
  ShowsForUserActionManager,
  SilentShowUpdateActionManager,
} from "../../action_managers/shows";
import messageType from "../../constants/message_types";
import { HAS_SEEN_PROMOTIONS_OPT_IN } from "../../constants/user_attributes";
import { getPricingPageURL } from "../../lib/config";
import { shouldShowTierPerm } from "../../lib/feature_flag";
import { minimumAllowedTier } from "../../lib/tier-utils";
import { tierLevel } from "../../reducers/types";
import LoadingButton from "../forms/loading_button/loading_button";
import Divider from "../lib/divider";
import BaseModal from "./base_modal";
import "./monetization_intro_modal/promotions-intro-modal.scss";
class PromotionsOptInModal extends Component {
  state = {
    isPuting: false,
  };

  join = () => {
    this.props.optInUser(this.props.user);
    this.props.fetchAllShows(this.props.user).then((response) => {
      if (response.status === 200) {
        let updateFuncs = response.json.map((show) => (callback) => {
          this.props
            .optInShow(show.uuid, this.props.user)
            .then((optInResponse) =>
              callback(optInResponse.status === 200 ? null : "non-200 update response", show.uuid)
            );
        });
        this.setState({ isPutting: true });
        parallelLimit(updateFuncs, 5, (err) => {
          this.setState({ isPutting: false });
          if (err) {
            this.props.showMessageWithTimeout(
              "There was a problem opting into cross promotions. Please try again later.",
              messageType.Error
            );
          } else {
            this.props.history.push("/promotions/browse");
            this.close();
          }
        });
      }
    });
  };

  close = () => {
    this.props.markSeen();
    this.props.hideModal();
  };

  render() {
    const showUpgrade = shouldShowTierPerm()
      ? !minimumAllowedTier(tierLevel.growth, this.props.tier, true)
      : false;

    return (
      <BaseModal
        isLoading={false}
        isMini={true}
        columns={10}
        offset={1}
        title={"Join the Community"}
        close={this.props.hideModal}
        hideCloseButton={true}
        showContent={true}
        Content={() => (
          <Grid fluid>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <p>
                  Before we get going, here's a few things to know about joining the cross-promotion
                  community.
                </p>
                <Divider />
                <div className="cross-promotion-opt-in-row">
                  <div className="cross-promotion-opt-in-row__title bold">
                    Participate in the Marketplace
                  </div>
                  <div className="cross-promotion-opt-in-row__description">
                    Participating in the cross promotion marketplace means your podcasts will be
                    searchable by other podcasters who are interested in finding cross promotion
                    partners.
                  </div>
                </div>
                <div className="cross-promotion-opt-in-row">
                  <div className="cross-promotion-opt-in-row__title bold">
                    Let Partners Find You
                  </div>
                  <div className="cross-promotion-opt-in-row__description">
                    Once you join the marketplace, other RedCircle podcasters may reach out to
                    inquire about cross promotions. You'll be notified when anyone makes a request.
                  </div>
                </div>
                <div className="cross-promotion-opt-in-row">
                  <div className="cross-promotion-opt-in-row__title bold">Find Perfect Matches</div>
                  <div className="cross-promotion-opt-in-row__description m-bl">
                    In order for RedCircle to facilitate good matches between cross-promoting shows,
                    we'll show other RedCircle podcasters the approximate size of your audience.
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <div className="mini-modal-footer">
                  {showUpgrade ? (
                    <LoadingButton
                      className="btn btn-primary m-tl"
                      onClick={() => window.open(getPricingPageURL(), "_blank")}>
                      Upgrade Your Plan
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      className="btn btn-primary m-tl"
                      onClick={this.join}
                      isLoading={this.props.isLoading || this.state.isPutting}>
                      Join the Community
                    </LoadingButton>
                  )}
                  <Button onClick={this.close} className="btn btn-link seedcast-btn-link">
                    Not Right Now
                  </Button>
                </div>
              </Col>
            </Row>
          </Grid>
        )}
      />
    );
  }
}

export default withRouter(
  connect(
    ({ user, shows }) => {
      return {
        user: user.user,
        isLoading: shows.isLoading,
        tier: user?.tier,
      };
    },
    {
      hideModal,
      fetchAllShows: (user) => {
        return new ShowsForUserActionManager({
          user,
        }).run();
      },
      optInShow: (showUUID, user) => {
        return new SilentShowUpdateActionManager({
          show: {
            uuid: showUUID,
            canShareAudienceSize: true,
          },
          user,
        }).run();
      },
      markSeen: () => {
        return setUserAttribute(HAS_SEEN_PROMOTIONS_OPT_IN, "true");
      },
      showMessageWithTimeout: (message, type) => {
        return showMessageWithTimeout(message, type);
      },
      optInUser: (user) =>
        new AccountUpdateActionManager({
          userForm: { hasOptedInSharingAudienceSize: true },
          existingUser: user,
          customSuccess: "",
        }).run(),
    }
  )(PromotionsOptInModal)
);
