import { Input, Tree } from "antd";
import { DataNode } from "antd/lib/tree";
import React, { useEffect, useState } from "react";
import { itunesCategories } from "src/data/itunes_categories";
import RCButton from "../button";

interface ICheckedKeys {
  checked: React.Key[];
  halfChecked: React.Key[];
}

interface ICategory {
  uuid: string;
  name: string;
  group_uuid?: string;
}

interface IProps {
  values: string[]; // allow this component to be controlled
  onChange?: (categories: string[]) => void;
  className?: string;
  style?: React.CSSProperties;
}

export default function CategorySelector({ values = [], onChange, className, style }: IProps) {
  const [categoryQuery, setCategoryQuery] = useState("");
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<ICheckedKeys>({ checked: [], halfChecked: [] });
  const majorCategories = itunesCategories.filter((c) => !c.group_uuid);

  // component will be controlled by values
  useEffect(() => {
    // check if values are different from checkedKeys, update checkedKeys accordingly
    if (values.length === checkedKeys.checked.length) return;
    setCheckedKeys({ checked: values, halfChecked: [] });

    // check if parents are not selected, expand them accordingly
    const newExpandedKeys = [...expandedKeys];
    values.forEach((nodeUUID) => {
      const parent = itunesCategories.find((c) => c.uuid === nodeUUID)?.group_uuid;
      if (parent && !values.includes(parent) && !newExpandedKeys.includes(parent))
        newExpandedKeys.push(parent);
    });
    setExpandedKeys(newExpandedKeys);
  }, [values]);

  useEffect(() => {
    if (onChange) onChange(checkedKeys.checked as string[]);
  }, [checkedKeys]);

  const isCategoryInSearch = (category: ICategory) => {
    if (categoryQuery) return category.name.toLowerCase().includes(categoryQuery.toLowerCase());
    return true;
  };

  const handleExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
  };

  const handleCheck = ({ checked, halfChecked }: ICheckedKeys, event: any) => {
    // selecting/deselecting a parent propagates to children
    if (majorCategories.find((c) => c.uuid === event.node.key)) {
      const children = event.node.children.map((c: any) => c.key);
      if (event.checked) {
        checked = [...checked, ...children];
      } else {
        checked = checked.filter((c) => !children.includes(c));
      }
    }
    setCheckedKeys({ checked, halfChecked });
  };

  const handleReset = () => {
    setCheckedKeys({ checked: [], halfChecked: [] });
    setCategoryQuery("");
  };

  const treeData = majorCategories
    .map((c) => {
      const parentNode: DataNode = {
        title: c.name,
        key: c.uuid,
      };
      parentNode.children = itunesCategories
        .filter((c2) => c2.group_uuid === c.uuid)
        .filter(isCategoryInSearch)
        .map((c2) => ({ title: c2.name, key: c2.uuid }));
      return parentNode;
    })
    .filter((node) => {
      const category = itunesCategories.find((c) => c.uuid === node.key);
      if (!category) return true;
      if (categoryQuery && isCategoryInSearch(category)) return true;
      if (categoryQuery && node.children) return node.children.length > 0;
      return true;
    });

  return (
    <div className={className} style={style}>
      <div className="flex-row-container align-center">
        <Input
          type="search"
          placeholder="Search Category"
          className="m-bxxs"
          size="small"
          value={categoryQuery}
          onChange={(e) => setCategoryQuery(e.target.value)}
          allowClear
        />
        {checkedKeys.checked.length > 0 && (
          <RCButton type="link" size="small" className="m-bxxxs" onClick={handleReset}>
            Reset
          </RCButton>
        )}
      </div>
      <Tree
        checkable
        checkStrictly
        onExpand={handleExpand}
        expandedKeys={expandedKeys}
        onCheck={handleCheck as any}
        checkedKeys={checkedKeys}
        treeData={treeData}
      />
    </div>
  );
}
