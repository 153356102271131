const backUpCleanHTMLTags = (strToSanitize: string) => {
  return strToSanitize.replace(/(<([^>]+)>)/gi, "");
};

/**
 * Removes html tags from html text strings, does not add any formatting, returns content text
 * @param strToSanitize - html string to sanitize
 * @returns new text string with html tags removed (No formatting)
 */
export const clearHTMLTags = (strToSanitize?: string) => {
  if (typeof strToSanitize !== "string") return "";

  if ("DOMParser" in window && window.DOMParser) {
    /**
     * Normalize UTF-16 characters
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize#specifications
     **/
    const normalizedString = strToSanitize.normalize("NFKD");
    const myHTML = new DOMParser().parseFromString(normalizedString, "text/html");
    return myHTML?.body?.textContent || backUpCleanHTMLTags(normalizedString);
  }

  return backUpCleanHTMLTags(strToSanitize);
};
