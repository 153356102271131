import { Row as AntdRow } from "antd";
import get from "lodash/get";
import React, { Component } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import Clipboard from "react-clipboard.js";
import { classNames, If } from "react-extras";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "src/components/lib/breadcrumbs";
import { showSuccess } from "../../../actions/app";
import { showModal } from "../../../actions/modal";
import {
  enablePayments,
  ShowFetchActionManager,
  ShowUpdateActionManager,
} from "../../../action_managers/shows";
import {
  enableExclusiveContent,
  fetchSubscriptionsByShowUUID,
} from "../../../action_managers/subscriptions";
import { permissionTypes } from "../../../constants/permission_roles";
import { getSeedcastURL } from "../../../lib/config";
import { formatMoney } from "../../../lib/format-money";
import { withPermissions } from "../../../lib/permissions-js";
import LoadingButton from "../../forms/loading_button";
import ContextMenu from "../../lib/context_menu";
import Divider from "../../lib/divider";
import ExternalLink from "../../lib/external_link";
import HighlightBox from "../../lib/highlight_box";
import Spinner from "../../lib/spinner";
import { CONFIRMATION_MODAL, ENABLE_SUBSCRIPTION_MODAL } from "../../modals/modal_root";
import ShowPageWrapper from "../shows/show_page_wrapper";
import "./enable-subscriptions.scss";

class ShowSubscriptionPage extends Component {
  componentDidMount() {
    this.props.fetchShowByUUID(this.getShowUUID(), this.props.user).then(() => {
      if (this.hasPaymentEnabled()) {
        this.props.fetchSubscriptionsByShowUUID({ showUUID: this.getShowUUID() });
      }
    });
  }

  getShowUUID = () => {
    return get(this.props, "match.params.showUUID");
  };
  getShow = () => {
    return get(this.props, ["shows", this.getShowUUID()], {});
  };

  getSubscriptionDetails = () => {
    return get(this.getShow(), "subscriptionDetails", {});
  };

  isRecurringDonationsEnabled = () => {
    return get(this.getShow(), ["subscriptionDetails", "recurringDonationsEnabled"], false);
  };

  hasPaymentEnabled = () => {
    // GROSS
    return get(this.getShow(), ["subscriptionDetails", "recurringDonationsEnabled"]) !== undefined;
  };

  canEditDonation = () => {
    return this.props.canAccess(permissionTypes.editDonations, this.getShowUUID());
  };

  renderDisabledContent = () => {
    return (
      <div className="flex-column-container align-center">
        {this.props.canAccess(permissionTypes.connectBank, this.getShowUUID()) && (
          <LoadingButton
            onClick={() => this.props.enablePayments(this.getShowUUID())}
            className="m-tl m-bxs"
            isLoading={this.props.isLoading}>
            Enable Payments
          </LoadingButton>
        )}
        <strong>
          <ExternalLink href="https://support.redcircle.com/earning-money">Learn More</ExternalLink>
        </strong>
      </div>
    );
  };

  updateRecurringDonationStatus = (shouldEnable) => {
    const show = this.getShow();
    this.props.updateShow(
      {
        uuid: show.uuid,
        subscriptionDetails: {
          ...show.subscriptionDetails,
          recurringDonationsEnabled: shouldEnable,
        },
      },
      this.props.user
    );
  };

  renderEnableDonationButton = () => {
    return (
      <LoadingButton
        onClick={(e) => {
          e.preventDefault();
          this.updateRecurringDonationStatus(true);
        }}
        className="m-txs m-bxs">
        Enable Donations
      </LoadingButton>
    );
  };

  renderStripeLink = () => {
    return (
      <div>
        <p>Before configuring exclusive content and donations you must enable monetization.</p>
        <Button
          className="m-tl btn-primary"
          onClick={() => this.props.history.push("/monetization")}>
          Enable Monetization
        </Button>
      </div>
    );
  };

  disableSubscription = () => {
    return this.updateRecurringDonationStatus(false);
  };

  renderEnabledContent = () => {
    return (
      <>
        <div className="exclusive-content-intro-text">
          <p>Manage donations to power monetization directly from your listeners</p>
        </div>
        <div
          className={classNames(
            "exclusive-content-row",
            this.isRecurringDonationsEnabled() && "m-y0"
          )}>
          <div>
            <h4 className={"exclusive-access-header"}>
              Donations:
              <If condition={this.isRecurringDonationsEnabled()}>
                <span className="redcircle-badge">Enabled</span>
              </If>
              <If condition={!this.isRecurringDonationsEnabled()}>
                <span className="text-danger m-ls">Disabled</span>
              </If>
            </h4>
            <If condition={!this.isRecurringDonationsEnabled()}>
              <div>
                <Divider smallMargin />
                Turn on donations to accept donations from your listeners
              </div>
            </If>
          </div>
          <If condition={this.isRecurringDonationsEnabled() && this.canEditDonation()}>
            <div className={"context-menu"}>
              <ContextMenu
                menuItems={{
                  "Disable New Donations": () => {
                    this.props.showDeleteConfirmation(this.disableSubscription);
                  },
                }}
              />
            </div>
          </If>
          <If condition={!this.isRecurringDonationsEnabled() && this.canEditDonation()}>
            {this.renderEnableDonationButton()}
          </If>
        </div>
        <If condition={!this.isRecurringDonationsEnabled()}>
          <h3>Donations</h3>
          <Divider smallMargin />
        </If>
        <If condition={this.getSubscriptions().length}>{this.renderHighlightBoxes()}</If>
        <If condition={!this.getSubscriptions().length}>
          <p className="m-ts">
            You do not have any donors yet. Remember to tell your listeners about it!
          </p>
        </If>
      </>
    );
  };

  renderHighlightBoxes = () => {
    const subscriptions = this.getSubscriptions();
    if (!subscriptions.length) {
      return null;
    }
    const income = subscriptions.reduce((agg, { amount }) => agg + amount, 0);
    return (
      <>
        <Row>
          <Col xs={12} md={6}>
            <HighlightBox title={"Total Sponsors"} value={subscriptions.length} />
          </Col>
          <Col xs={12} md={6}>
            <HighlightBox title={"Income Per Month"} value={formatMoney(income)} />
          </Col>
        </Row>
      </>
    );
  };

  getSubscriptions = () => {
    return get(this.props, ["subscriptionsByShowUUIDs", this.getShowUUID()], []).filter(
      (subscription) => subscription.state === "active" && subscription.type === "donation"
    );
  };

  render() {
    const sponsorURL = `${getSeedcastURL()}shows/${this.getShowUUID()}/donations`;

    return (
      <ShowPageWrapper className="show-donations-page" show={this.getShow()}>
        <Breadcrumbs
          crumbs={[
            { path: "/shows", name: "All Podcasts" },
            { path: `/shows/${this.getShowUUID()}`, name: this.getShow()?.title },
            { path: `/shows/${this.getShowUUID()}/i/donations`, name: "Donations", active: true },
          ]}
        />

        <AntdRow>
          <ShowPageWrapper.Sidebar>
            <If condition={this.isRecurringDonationsEnabled()}>
              <Clipboard
                onSuccess={this.props.showSuccess.bind(
                  this,
                  "Successfully copied donations link to clipboard.",
                  5000
                )}
                option-text={() => sponsorURL}
                className="m-l0 m-yl btn btn-link">
                Copy Donations Link
              </Clipboard>
            </If>
          </ShowPageWrapper.Sidebar>

          <ShowPageWrapper.Body>
            <ShowPageWrapper.Header title="Donations" />

            <div className={"enable-subscriptions"}>
              <div className="enable-subscriptions-main-content">
                <Grid fluid>
                  <Row>
                    <Spinner isLoading={this.props.isLoading} />
                    <If condition={!this.props.isLoading}>
                      <If condition={!this.hasPaymentEnabled()}>
                        <div className={"disabled-content"}>
                          <If condition={!get(this.props.user, "isStripeConnected")}>
                            {this.renderStripeLink()}
                          </If>
                          <If condition={get(this.props.user, "isStripeConnected")}>
                            <p>
                              Get rewarded for your work by collecting one-time and monthly
                              donations.
                            </p>
                            {this.renderDisabledContent()}
                          </If>
                        </div>
                      </If>
                      <If condition={this.hasPaymentEnabled()}>{this.renderEnabledContent()}</If>
                    </If>
                  </Row>
                </Grid>
              </div>
            </div>
          </ShowPageWrapper.Body>
        </AntdRow>
      </ShowPageWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    shows: state.shows.shows,
    isLoading: state.showForm.isLoading || state.subscriptions.isLoading || state.shows.isLoading,
    user: state.user.user,
    subscriptionsByShowUUIDs: state.subscriptions.subscriptionsByShowUUIDs,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updateShow: (show, user) => new ShowUpdateActionManager({ show, user }).run(),
    enableExclusiveContent,
    enablePayments,
    fetchShowByUUID: (showUUID, user) => new ShowFetchActionManager({ showUUID, user }).run(),
    showEnableSubscriptionModal: (props) => showModal(ENABLE_SUBSCRIPTION_MODAL, props),
    fetchSubscriptionsByShowUUID,
    showDeleteConfirmation: (callback) =>
      showModal(CONFIRMATION_MODAL, {
        modalTitle: "Disable Donations?",
        confirmationMessage:
          "Disabling this feature will prevent new listeners from subscribing to your exclusive content.  Existing listeners will remain subscribed and still have access.",
        callback,
      }),
    showSuccess,
  })(withPermissions(ShowSubscriptionPage))
);
