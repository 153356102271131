import get from "lodash/get";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formValueSelector } from "redux-form";
import { updateAccountClear } from "../../../actions/account";
import {
  AccountUpdateActionManager,
  FetchUserManager,
  SignOutActionManager,
} from "../../../action_managers/account";
import { SendVerificationEmailManager } from "../../../action_managers/email_verification";
import { withPageTitle } from "../../lib/WithPageTitle";
import ProfileSettings from "./profile_settings";

function mapStateToProps(state) {
  var formValueSelectorPassword = formValueSelector("sign-up");

  const user = {
    ...state.user.user,
    fname: get(state, "user.user.firstName"),
    lname: get(state, "user.user.lastName"),
  };
  const props = {
    user: user,
    isLoading: state.signOut.isLoading || state.user.isLoading,
    isLoadingForm: state.updateAccount.isLoading,
    isLoadingVerification: state.sendVerificationEmail.isLoading,
    validationErrors: state.updateAccount.validationErrors,
    errorMessage: state.updateAccount.errorMessage,
    isSuccess: state.updateAccount.isSuccess,
    password: formValueSelectorPassword(state, "password"),
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: (token) => {
      dispatch(new SignOutActionManager({ token }).run());
    },
    updateUser: (userForm, user) => {
      // Ignore any empty fields.
      if (userForm.ccEmails?.length) {
        userForm.ccEmails = userForm.ccEmails
          .filter((item) => item)
          .map((item) => item.trim())
          .filter((item) => item);
      }
      userForm = {
        ...userForm,
        // backend expects firstName/lastName instead of fname,lname
        firstName: userForm.fname,
        lastName: userForm.lname,
      };

      const args = {
        userForm: userForm,
        existingUser: user,
      };
      dispatch(new AccountUpdateActionManager(args).run());
    },
    updateUserClear: () => {
      dispatch(updateAccountClear());
    },
    fetchUser: (userUUID, authToken) => {
      dispatch(
        new FetchUserManager({
          userUUID,
          authToken,
        }).run()
      );
    },
    sendVerificationEmail: (user) => {
      dispatch(
        new SendVerificationEmailManager({
          user,
        }).run()
      );
    },
  };
}

const ProfileSettingsWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileSettings)
);

export default ProfileSettingsWrapper;
