import { Moment } from "moment-timezone";
import React, { FunctionComponent, useState } from "react";
import BlockFeature from "src/components/lib/block_feature";
import { useSelectorTS } from "../../../../hooks/redux-ts";
import HostReadImgSrc from "../../../../images/Block_HostRead_Insertions.png";
import OtherImgSrc from "../../../../images/Block_Other_Insertions.png";
import ProgrammaticImgSrc from "../../../../images/Block_Programmatic_Insertions.png";
import PromoImgSrc from "../../../../images/Block_Promo_Insertions.png";
import TotalImgSrc from "../../../../images/Block_Total_Insertions.png";
import { goToPricingPage } from "../../../../lib/config";
import Divider from "../../../lib/divider";
import {
  equalityFunc,
  InsertionGraphTypes,
  someProgrammaticEnabled,
  useShowRequest,
  useTierAnalyticsPerm,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";
import TextSwitch from "../TextSwitch";
import InsertionData from "./insertion_data";
import InsertionEpisodes from "./insertion_episodes";

const tabOptions = InsertionGraphTypes.map((tab) => {
  return {
    value: tab,
    key: tab,
  };
});

interface IInsertionsSection {
  dateRange: [Moment, Moment];
  timeZone: string;
  selectedShow: string;
}

const InsertionsSection: FunctionComponent<IInsertionsSection> = (props) => {
  const shows = useSelectorTS((state) => state?.shows?.shows);
  useShowRequest();
  const tierPermission = useTierAnalyticsPerm();

  const defaultSelectedTab =
    !tierPermission.Extra.showExtraWidgets && someProgrammaticEnabled(shows)
      ? InsertionGraphTypes[2]
      : InsertionGraphTypes[0];

  const [graphType, setGraphType] =
    useState<typeof InsertionGraphTypes[number]>(defaultSelectedTab);

  const handleGraphSwitch = (option: any) => setGraphType(option?.value);

  const permissionsByType = {
    [InsertionGraphTypes[0]]: tierPermission.Insertions.totalInsertions,
    [InsertionGraphTypes[1]]: tierPermission.Insertions.HostRead,
    [InsertionGraphTypes[2]]: tierPermission.Insertions.Programmatic,
    [InsertionGraphTypes[3]]: tierPermission.Insertions.CrossPromotions,
    [InsertionGraphTypes[4]]: tierPermission.Insertions.Other,
  };

  const imgMaps = {
    [InsertionGraphTypes[0]]: TotalImgSrc,
    [InsertionGraphTypes[1]]: HostReadImgSrc,
    [InsertionGraphTypes[2]]: ProgrammaticImgSrc,
    [InsertionGraphTypes[3]]: PromoImgSrc,
    [InsertionGraphTypes[4]]: OtherImgSrc,
  };

  const filteredtabOptions =
    !tierPermission.Extra.showExtraWidgets && someProgrammaticEnabled(shows)
      ? tabOptions.map((item) =>
          item.value === InsertionGraphTypes[2] ? item : { ...item, hidden: true }
        )
      : tabOptions;

  return (
    <InfoCard
      title={{
        text: "Dynamic Insertions",
        tooltipText: "Number of times your Audio Blocks were dynamically inserted",
        subHeader: (
          <div className="m-vs">
            <TextSwitch
              onSelect={handleGraphSwitch}
              type="tab"
              value={graphType}
              options={filteredtabOptions}
            />
          </div>
        ),
      }}>
      <BlockFeature
        block={!permissionsByType[graphType]}
        CTA={{
          text: (
            <span className="h3 default-font m-b0 p-hxs text-center">
              <strong>{`${graphType}`}</strong>
              {` is only available starting from ${
                graphType === InsertionGraphTypes[4] ? "Enterprise" : "Pro"
              } Plan.`}
            </span>
          ),
          btn: {
            text: "Upgrade Your Plan",
            handler: () => goToPricingPage(),
          },
        }}
        blockByImg={{
          src: imgMaps[graphType],
          minHeight: "1800px",
        }}>
        <>
          <InsertionData {...props} graphType={graphType} />
          <Divider marginTop={"24px"} marginBottom={"24px"} />
          <InsertionEpisodes {...props} graphType={graphType} />
        </>
      </BlockFeature>
    </InfoCard>
  );
};

const MemoizedInsertionsSection = React.memo(InsertionsSection, equalityFunc);

export default MemoizedInsertionsSection;
