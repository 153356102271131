export const WITHDRAW_START = "WITHDRAW_START";
export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_ERROR = "WITHDRAW_ERROR";

export function withdrawStart() {
  return { type: WITHDRAW_START };
}

export function withdrawSuccess() {
  return { type: WITHDRAW_SUCCESS };
}

export function withdrawError() {
  return { type: WITHDRAW_ERROR };
}
