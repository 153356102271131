import { Image } from "antd";
import React, { Component } from "react";
import logo from "src/images/logo-redcircle-dark.svg";
class SidebarMenuButton extends Component {
  render() {
    return (
      <div className="position-relative height-100 flex-row-container align-center justify-start">
        <div className="hamburger-wrapper" onClick={this.props.onClick}>
          <span className="glyphicon glyphicon-menu-hamburger" />
        </div>
        <Image src={logo} alt={"RedCircle Logo"} height={25} preview={false} />
      </div>
    );
  }
}

export default SidebarMenuButton;
