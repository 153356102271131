import { GET_MANUAL_PAYOUTS } from "../../action_managers/manual_payouts";
import { httpReducer, reduceReducers } from "../../lib/create-action";

export interface IPayouts {
  uuid: string;
  userUUID: string;
  showUUID: string;
  amount: number;
  type: string;
  state: string;
  periodBeginsAt: number;
  periodEndsAt: number;
  createdAt: number;
  updatedAt: number;
}

const initialState = {
  isLoading: false,
  manualPayouts: [] as IPayouts[],
};

export type ManualPayoutsState = typeof initialState;

const getManualPayouts = httpReducer(GET_MANUAL_PAYOUTS, initialState, {
  success: (state, action) => {
    const response = action.payload.resp;

    return {
      ...state,
      manualPayouts: [...response],
    };
  },
});

export default reduceReducers<ManualPayoutsState>(getManualPayouts);
