import { UserRole } from "redcircle-types";

const UserRoles: Record<"Advertiser" | "Creator" | "Admin" | "Sponsor", UserRole> = {
  Advertiser: "advertiser",
  Creator: "creator",
  Admin: "admin",
  Sponsor: "sponsor",
} as const;

export const DEFAULT_URL_BY_ROLES: Record<UserRole, string> = {
  advertiser: "/campaigns",
  creator: "/shows",
  sponsor: "/",
  admin: "/",
};

export const USER_ROLES_TO_FRIENDLY: Record<string, string> = {
  advertiser: "Advertiser",
  creator: "Podcaster",
  sponsor: "Listener",
  admin: "Admin",
};

export default UserRoles;
