import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formValueSelector, submit } from "redux-form";
import { hideModal } from "../../actions/modal";
import { fetchDistributions, putDistributions } from "../../action_managers/distributions";
import { ShowFetchActionManager } from "../../action_managers/shows";
import DistributionsModal from "./distributions_modal";
function mapStateToProps(state) {
  return {
    user: state.user.user,
    distributions: state.distributions.distributions,
    isLoading: state.distributions.isLoading,
    isPutting: state.distributions.isPutting,
    validationErrors: state.distributions.validationErrors,
    getDistributionFormValue: (key) => {
      return formValueSelector("distribution-form")(state, key);
    },
  };
}

const mapDispatchToProps = {
  closeModal: hideModal,
  fetchDistributions,
  putDistributions,
  submitForm: () => {
    return submit("distribution-form");
  },
  getShow: (showUUID, user) => new ShowFetchActionManager({ showUUID, user }).run(),
};

const DistributionsModalWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DistributionsModal)
);

export default DistributionsModalWrapper;
