import { Alert } from "antd";
import RCButton from "../button/button";
import styles from "./warning_alert.module.scss";

export default function WarningVerifyEmail({ onClick }: { onClick: () => void }) {
  const message = (
    <span>
      Your email is unverified!{" "}
      <RCButton type="link" onClick={onClick} className="p-a0">
        <strong>Send Verification Email</strong>
      </RCButton>
    </span>
  );
  return <Alert className={styles.alert} message={message} type="warning" banner />;
}
