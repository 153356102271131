import React, { lazy, PropsWithChildren, Suspense } from "react";
import Spinner from "../../../lib/spinner";
import { graphFonts } from "../analyticsUtility";
import Message from "./Messages";
import "./redcircle_graphs.scss";

const Area = lazy(() => import("@ant-design/plots/es/components/area"));

// Default Graph Configs
const defaultGradient = {
  steps: [
    {
      percent: 0,
      color: "rgba(38, 115, 221, 0.04)",
    },
    {
      percent: 0.2,
      color: "rgba(38, 115, 221, 0.5)",
    },
    {
      percent: 0.6,
      color: "rgba(38, 115, 221, 0.8)",
    },
    {
      percent: 1,
      color: "rgba(38, 115, 221, 1)",
    },
  ],
  direction: 270,
};

const defaultPointConfig = {
  size: 3,
  shape: "circle",
  style: {
    fill: "white",
    stroke: "rgba(38, 115, 221, .5)",
    lineWidth: 0.5,
  },
};

const defaultLineConfig = {
  style: {
    stroke: `${defaultGradient.steps[0].color}`,
  },
};

const defaultAreaStyle = () => {
  return {
    fill: `l(${defaultGradient?.direction}) ${defaultGradient?.steps?.map(
      ({ percent, color }) => `${percent}:${color} `
    )}`,
  };
};

// Inferring Graph config type
type graphConfig = Parameters<typeof Area>[number];

// Creating wrapper component with type Generic T for datapoint type in order to constraint field values.
interface IRCArea<T> {
  data: T[];
  xField: string & keyof T;
  yField: string & keyof T;
  seriesField?: string & keyof T;
  height?: Required<graphConfig>["height"];
  width?: Required<graphConfig>["width"];
  loading?: boolean;
  legend?: Required<graphConfig>["legend"];
  isStack?: Required<graphConfig>["isStack"];
  line?: Required<graphConfig>["line"];
  tooltip?: Required<graphConfig>["tooltip"];
  color?: Required<graphConfig>["color"];
  point?: Required<graphConfig>["point"];
  areaStyle?: Required<graphConfig>["areaStyle"];
  meta?: Required<graphConfig>["meta"];
  animation?: Required<graphConfig>["animation"];
  graphMessage?: string;
}

const RCArea = <T extends Record<string, any>>(props: PropsWithChildren<IRCArea<T>>) => {
  const {
    data = [],
    xField,
    yField,
    seriesField,
    height = 260,
    width,
    loading,
    legend,
    isStack,
    line = defaultLineConfig,
    tooltip,
    color = "#577D9E",
    point = defaultPointConfig,
    areaStyle = defaultAreaStyle,
    meta,
    animation,
    graphMessage = "",
  } = props;

  const config: graphConfig = {
    style: {
      minHeight: `${height}px`,
    },
    height,
    animation,
    width,
    autoFit: true,
    loading,
    data,
    tooltip,
    xField,
    yField,
    seriesField,
    legend,
    isStack,
    color,
    point,
    xAxis: {
      label: {
        style: {
          fontFamily: graphFonts,
          fontSize: 11,
          lineHeight: 16,
          fontWeight: 400,
          letterSpacing: 0.6,
          color: "#000000",
        },
      },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineDash: [15, 15],
            strokeOpacity: 0.1,
          },
        },
      },
    },
    yAxis: {
      label: {
        style: {
          fontFamily: graphFonts,
          fontSize: 11,
          lineHeight: 16,
          fontWeight: 400,
          letterSpacing: 0.6,
          color: "#000000",
        },
      },
      grid: {
        line: {
          style: {
            stroke: "black",
            lineDash: [15, 15],
            strokeOpacity: 0.1,
          },
        },
      },
    },
    line,
    areaStyle,
    meta,
  };

  const dataAllZero = data?.every((item: T) => (item[yField] as unknown) === 0);
  const displayMessage = !loading && (data?.length === 0 || dataAllZero || graphMessage.length > 0);

  return (
    <div className="analytics-graphContainer" style={{ minHeight: `${height}px` }}>
      <Suspense fallback={<Spinner />}>
        <Area {...config} />
      </Suspense>
      <Message show={displayMessage} type="Insufficient Data" text={graphMessage} />
    </div>
  );
};

export default RCArea;
