import { episodeFeedImportActionName, showFeedImportActionName } from "../../actions/feed_import";
import { httpReducer } from "../../lib/create-action";
import { EpisodeFeedImportReduxState, ShowFeedImportReduxState } from "./types";

const showFeedImportState: ShowFeedImportReduxState = {
  isLoading: false,
};

const EpisodeFeedImportState: EpisodeFeedImportReduxState = {
  isLoading: false,
};

const showFeedImport = httpReducer(showFeedImportActionName, showFeedImportState, {
  success: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
});

const episodeFeedImport = httpReducer(episodeFeedImportActionName, EpisodeFeedImportState, {
  success: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },
});

export { showFeedImport, episodeFeedImport };
