import { parse } from "query-string";
import React, { useState } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { fetchTierForOrg } from "../../../action_managers/tiers";
import { useDispatchTS, useSelectorTS } from "../../../hooks/redux-ts";
import { useComponentDidMount } from "../../../lib/hook";
import Divider from "../../lib/divider";
import AddCardForm from "../../lib/stripe_form/add_card_form";
import StripeElements from "../../lib/stripe_form/stripe_elements";
import { PlanCard } from "../../lib/tier-card";
import {
  UpdateTierNavBar,
  useHideSidebar,
  useHideWarningbar,
  useUpdateTier,
} from "../account/tier_utils";
import "./sign_up_tiered.scss";

const SignUpTiered = () => {
  useHideSidebar();
  useHideWarningbar();
  const dispatch = useDispatchTS();
  useComponentDidMount(() => {
    dispatch(fetchTierForOrg());
  });
  const updateTier = useUpdateTier("/shows");

  const { tier, interval } = parse(window.location.search) as {
    tier: "growth" | "pro" | "enterprise";
    interval: "yearly" | "monthly";
  };
  const currentPlan = tier;
  const [isYearly, setIsYearly] = useState(interval === "yearly");
  const cardSubmit = (card: any) => updateTier(card?.card?.id, isYearly, currentPlan);

  const user = useSelectorTS((state) => state.user.user);
  return (
    <div>
      <UpdateTierNavBar />

      <Grid className={"p-tl"}>
        <Row>
          <Col xs={12} md={5} mdOffset={1}>
            <PlanCard
              planLevel={currentPlan}
              isYearly={isYearly}
              setIsYearly={setIsYearly}
              hasFreeTrial={true}
            />
          </Col>
          <Col xs={12} md={5}>
            <h1>Card Information</h1>
            <p>You won't be charged until your 7-day free trial has ended.</p>
            <Divider marginTop={24} />
            <StripeElements>
              <AddCardForm canMakePayment={false} onSubmit={cardSubmit} user={user} />
            </StripeElements>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default SignUpTiered;
