import { useEffect, useRef } from "react";
import { getUnreadDealMessages } from "src/action_managers/deals";
import { useDispatchTS, useSelectorTS } from "./redux-ts";
import { userHasRole } from "src/lib/user";
import UserRoles from "src/constants/roles";

export const useUserCrossPromoEnabled = () => {
  const user = useSelectorTS((state) => state.user?.user);
  return Boolean(user?.hasOptedInSharingAudienceSize);
};

const UNREAD_FETCH_INTERVAL_MS = 30000;

export const useCrossPromoNotifications = () => {
  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);
  const deals = useSelectorTS((state) => state.deals);
  const poll = useRef<NodeJS.Timer>();
  const isCreator = userHasRole(user, [UserRoles.Creator]);
  // poll for unread messages
  useEffect(() => {
    if (user && !poll.current && isCreator) {
      dispatch(getUnreadDealMessages(user));
      poll.current = setInterval(() => {
        dispatch(getUnreadDealMessages(user));
      }, UNREAD_FETCH_INTERVAL_MS);
    }

    return () => {
      if (poll.current) {
        clearInterval(poll.current);
      }
    };
  }, [user]);

  return { count: deals.unread };
};
