import identity from "lodash/identity";
import { combineReducers, Reducer } from "redux";
import {
  browseSearchDirectoryShowActionName,
  browseSearchShowActionName,
  clearShowsActionName,
  fetchCategoryActionName,
  fetchDirectoryCategoryActionName,
  getCampaignRecommendedShowsActionName,
  getCategoriesActionName,
} from "../../action_managers/show_search";
import missingAlbumArt from "../../images/missing.png";
import { httpReducer, IReducersMap, reduceReducers } from "../../lib/create-action";
import { IShow } from "redcircle-types";
import { BrowsePromotionsReduxState } from "./types";

const initialState: BrowsePromotionsReduxState = {
  categoryShows: {
    loadingCount: 0,
  },
  categoryDirectoryShows: {
    loadingCount: 0,
  },
  search: { latestRequestID: "", results: [] },
  listenerDemoRecommendedShows: { shows: [] },
  searchDirectoryShows: { latestRequestID: "", results: [] },
  activeAdCategories: { categories: [] },
};

const directoryShowMapper = (resp: any) => {
  return resp.map((directoryShow: any) => ({
    ...directoryShow,
    title: directoryShow.collectionName,
    isDirectoryShow: true,
    imageURL: directoryShow.imageURL || missingAlbumArt,
  }));
};

type categoryState =
  | BrowsePromotionsReduxState["categoryShows"]
  | BrowsePromotionsReduxState["categoryDirectoryShows"];

const categoryReducers: (func?: (args: any) => any) => IReducersMap<categoryState> = (
  mapper = identity
) => {
  return {
    started: (state) => ({
      ...state,
      loadingCount: (state.loadingCount as number) + 1,
    }),
    success: (state, action) => ({
      ...state,
      [action.data.rootCategoryUUID]: mapper(action.payload.resp),
      loadingCount: (state.loadingCount as number) - 1,
    }),
    failure: (state, action) => ({
      ...state,
      error: action.payload.error,
      loadingCount: (state.loadingCount as number) - 1,
    }),
  };
};
const fetchCategory = httpReducer(
  fetchCategoryActionName,
  initialState.categoryShows,
  categoryReducers()
);
const fetchDirectoryCategory = httpReducer(
  fetchDirectoryCategoryActionName,
  initialState.categoryShows,
  categoryReducers(directoryShowMapper)
);

type searchState = BrowsePromotionsReduxState["search"];

const searchReducers: (func?: (args: any) => IShow[]) => IReducersMap<searchState> = (
  mapper = identity
) => ({
  started: (state, action) => ({ ...state, latestRequestID: action.requestID }),
  success: (state, action) => {
    if (action.requestID !== state.latestRequestID) {
      return state;
    }
    return {
      ...state,
      results: mapper(action.payload.resp),
    };
  },
  failure: (state, action) => ({
    ...state,
    error: action.payload.error,
  }),
});

const searchShows = httpReducer(browseSearchShowActionName, initialState.search, searchReducers());

const searchDirectoryShows = httpReducer(
  browseSearchDirectoryShowActionName,
  initialState.search,
  searchReducers(directoryShowMapper)
);

const clearResults: Reducer<searchState> = (state, action) => {
  switch (action.type) {
    case clearShowsActionName:
      return {
        ...state,
        latestRequestID: "",
        results: [],
        searchDirectoryShows: [],
      };
    default:
      return state;
  }
};

const activeAdCategories = httpReducer(getCategoriesActionName, initialState.activeAdCategories, {
  success: (state, action) => ({ categories: action.payload.resp }),
});

const listenerDemoRecommendedShows = httpReducer(getCampaignRecommendedShowsActionName, [], {
  success: (state, action) => ({
    ...state,
    shows: action.payload.resp,
  }),
});

export default combineReducers({
  categoryShows: fetchCategory,
  categoryDirectoryShows: fetchDirectoryCategory,
  search: reduceReducers(searchShows, clearResults),
  searchDirectoryShows: reduceReducers(searchDirectoryShows, clearResults),
  activeAdCategories,
  listenerDemoRecommendedShows,
});
