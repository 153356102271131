/* global google */
import { AutoComplete, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import { classNames } from "react-extras";
import PlacesAutocomplete from "react-places-autocomplete";
import { Button } from "redcircle-ui";
import { addressToString, placeToAddress } from "src/lib/postal_address";

/**
 * GoogleMapsAutocomplete is a fully controlled form component that uses the Google Maps Places API to provide
 * address suggestions as a user types. It also provides a dropdown of suggestions that can be selected.
 */
export default function GoogleMapAutocomplete({
  id,
  value,
  className,
  onChange,
}: {
  id?: string;
  value?: Record<string, string | string[]>;
  className?: string;
  onChange?: (address: Record<string, string | string[] | undefined> | null) => void;
}) {
  const address = value;
  const addressLine2 = address?.StreetAddress?.[1] || "";

  const [addressString, setAddressString] = useState(addressToString(value));
  const [addressString2, setAddressString2] = useState(addressLine2);
  const [showAddressLine2, setShowAddressLine2] = useState(addressLine2 !== "");

  const ref = useRef(null);
  const googleMapsService = useRef<google.maps.places.PlacesService | null>(null);

  // set up google places service
  useEffect(() => {
    const loadPlacesService = async () => {
      if (ref.current) {
        const lib = await google.maps.importLibrary("places");
        const { PlacesService } = lib as google.maps.PlacesLibrary;
        googleMapsService.current = new PlacesService(ref.current);
      }
    };
    loadPlacesService();
  }, []);

  const handleChange = (addressString: string) => {
    setAddressString(addressString);
    if (addressString === "") {
      if (onChange) onChange(null);
    }
  };

  const handleChangeLine2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAddressString2(value);
    if (onChange && address) {
      if (value === "") {
        onChange({
          ...address,
          StreetAddress: address.StreetAddress ? [address.StreetAddress?.[0]] : undefined,
        });
      } else {
        onChange({
          ...address,
          StreetAddress: [address.StreetAddress?.[0], value],
        });
      }
    }
  };

  const isNeighborhoodCity = (place: google.maps.places.PlaceResult, suggestedCity: string) => {
    if (!suggestedCity) return false;
    if (place.address_components) {
      // address components can vary by response, so these should be iterated over
      for (const component of place.address_components) {
        if (component.long_name == suggestedCity && component.types.includes("neighborhood")) {
          return true;
        }
      }
    }
  };

  const handleSelect = async (options: string) => {
    if (!googleMapsService.current) return;
    const splitOptions = options.split(",");
    const placeId = splitOptions[0];
    const suggestedCity = splitOptions.length == 2 ? splitOptions[1] : "";
    const request = { placeId, fields: ["address_components"] };

    googleMapsService.current.getDetails(request, (place) => {
      if (place) {
        const neighborhoodIsCity = isNeighborhoodCity(place, suggestedCity);
        const verifiedPlace = place;
        if (neighborhoodIsCity && verifiedPlace.address_components) {
          for (const component of verifiedPlace.address_components) {
            if (component.types.includes("locality")) {
              component.long_name = suggestedCity;
              component.short_name = suggestedCity;
            }
          }
        }

        const address = placeToAddress(verifiedPlace);
        const addressString = addressToString(address);
        setAddressString(addressString);
        if (onChange && address) {
          if (address.StreetAddress) {
            onChange({
              ...address,
              StreetAddress: addressString2
                ? [address.StreetAddress[0], addressString2]
                : [address.StreetAddress[0]],
            });
          } else {
            onChange(address);
          }
        }
      }
    });
  };

  return (
    <PlacesAutocomplete value={addressString} onChange={handleChange}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const inputProps = getInputProps();

        const parseCityFromAddressSuggestion = (description: string) => {
          // Parse the city from the suggested addresss for comparing to the Google Places response
          const firstCommaIdx = description.indexOf(",");
          const secondCommaIdx = description.indexOf(",", firstCommaIdx + 1);
          if (firstCommaIdx == -1 || secondCommaIdx == -1) {
            return "";
          }

          return description.slice(firstCommaIdx + 2, secondCommaIdx);
        };

        const options = suggestions
          ? suggestions.map((suggestion) => {
              const suggestedCity = parseCityFromAddressSuggestion(suggestion.description);
              return {
                value: suggestedCity
                  ? `${suggestion.placeId},${suggestedCity}`
                  : suggestion.placeId,
                label: suggestion.description,
              };
            })
          : [];

        return (
          <>
            <div ref={ref} />
            <AutoComplete
              id={id}
              value={addressString}
              size="large"
              onSearch={(value) => inputProps.onChange({ target: { value } })}
              onSelect={handleSelect}
              options={options}
              className={classNames("width-100", className)}
              placeholder="Search for a location..."
            />
            {!showAddressLine2 && address && (
              <Button type="link" onClick={() => setShowAddressLine2(true)} className="p-a0">
                + Add Address Line 2
              </Button>
            )}
            {showAddressLine2 && address && (
              <>
                <label className="redcircle-form-label m-txxs" htmlFor="addressLine2">
                  Address Line 2 (Optional)
                </label>
                <Input
                  size="large"
                  value={addressLine2}
                  onChange={handleChangeLine2}
                  placeholder="Apt, Suite, etc."
                />
              </>
            )}
          </>
        );
      }}
    </PlacesAutocomplete>
  );
}
