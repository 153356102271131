import get from "lodash/get";
import { Component } from "react";
import { matchPath } from "react-router-dom";
import { Modal } from "redcircle-ui";
import { showMessages } from "../../constants/notificationMessages";
import { tierLevels } from "../../constants/tiers";
import { getCroppedImg } from "../../lib/image-uploader";
import { getActiveHostedPodcasts } from "../../lib/show";
import { ShowCreateForm, ShowEditForm } from "../pages/shows/show_form";
import "./modal.scss";

class ShowFormModal extends Component {
  componentDidMount() {
    const { userIsLoading, user, tier } = this.props;
    if (!userIsLoading && user && tier.level === tierLevels.core && !this.isEditMode()) {
      this.props.fetchShowsForUser(user).then((resp) => {
        if (resp?.status === 200) {
          const activeShows = getActiveHostedPodcasts(resp.json);
          if (activeShows?.length >= 1) {
            this.props.showWarning(showMessages.Warning.TierARedirectHack);
            this.close();
          }
        }
      });
    }
  }

  close = () => {
    const { history, closeModal, closeRoute, location } = this.props;
    history.push(closeRoute(location));
    closeModal();
  };

  getShowUUID = () => {
    const { location } = this.props;
    const result =
      matchPath(location.pathname, "/shows/:showUUID/edit") ||
      matchPath(location.pathname, "/browse/:showUUID");
    return result && result.params && result.params.showUUID;
  };

  // TODO: this logic should be in the form. let's migrate when we refactor this modal
  validateSubmit = (values) => {
    const { setError } = this.props;
    if (values.enclosurePrefixSettings?.chartablePrefix === "") {
      setError("Chartable URL is required.");
      return false;
    }
    return true;
  };

  onSubmit = (formValues) => {
    const { imageUploader, user, setError, fileUpload } = this.props;
    if (!this.validateSubmit(formValues)) return;

    var finalResult = Object.assign({}, formValues);

    if (get(finalResult, "categoryUUIDs[0].name")) {
      finalResult.categoryUUIDs = finalResult.categoryUUIDs.map(({ uuid }) => uuid);
    }

    var fileName = get(imageUploader, "fileName");
    if (this.isEditMode()) {
      var url = get(this.props, ["mediaFiles", this.getShow().imageMediaFileUUID, "url"]);
      fileName = url.split("/").pop();
    }

    // If we haven't set any image file data on update, then
    // we can skip the upload part.
    if (this.isEditMode() && !imageUploader.touched) {
      return this.submitHandler(finalResult, user);
    }
    if (!get(imageUploader, "image")) {
      setError("An image is required");
      return;
    }
    getCroppedImg(imageUploader).then((imageFileData) => {
      fileUpload(imageFileData, fileName, user).then((result) => {
        if (result.status === 200) {
          const imageUUID = get(result, "json[0].uuid");
          finalResult.imageMediaFileUUID = imageUUID;
          this.submitHandler(finalResult, user);
        }
      });
    });
  };

  submitHandler = (data, user) => {
    const { updateShow, createShow, history, location } = this.props;
    return this.isEditMode()
      ? updateShow(data, user)
      : createShow(data, user)?.then((showUUID) => {
          history.replace(`${history?.location?.pathname}${history?.location?.search}`, {
            newShowUUID: showUUID,
          });
        });
  };

  isEditMode = () => {
    return this.props.mode === "edit";
  };

  getShow = () => {
    return get(this.props, ["shows", this.getShowUUID()]);
  };

  getRenderInfo = () => {
    const { user } = this.props;
    var show = this.getShow();
    var renderInfo = {
      title: "Create Podcast",
      initialValues: {
        alias: "",
        isComplete: false,
        isVisible: true,
        isExplicit: false,
        language: "en-US",
        ownerEmail: get(this.props, "user.email"),
        ownerName: get(this.props, "user.companyName", `${user.firstName} ${user.lastName}`),
        copyright: "All rights reserved.",
      },
      Form: ShowCreateForm,
      show: show,
    };
    if (this.isEditMode()) {
      renderInfo.title = "Podcast Settings";
      renderInfo.initialValues = show;
      renderInfo.Form = ShowEditForm;
    }
    return renderInfo;
  };

  render() {
    const { title, initialValues, Form, show } = this.getRenderInfo();
    const {
      mode,
      mediaFiles,
      setError,
      isSuccess,
      errorMessage,
      validationErrors,
      fileUpload,
      user,
      isLoading,
      submitForm,
      setImageFileData,
      setImageFileUUID,
    } = this.props;

    return (
      <Modal open onClose={this.close} loading={!(!this.isEditMode() || show)}>
        <Modal.Title>{title}</Modal.Title>
        <Modal.Body>
          <Form
            showContent={Boolean(!this.isEditMode() || show)}
            close={this.close}
            mediaFiles={mediaFiles}
            onSubmit={this.onSubmit}
            setError={setError}
            isSuccess={isSuccess}
            initialValues={initialValues}
            errorMessage={errorMessage}
            validationErrors={validationErrors}
            fileUpload={fileUpload}
            user={user}
            setImageFileData={(data) => setImageFileData(mode, data)}
            setImageFileUUID={(uuid) => setImageFileUUID(mode, uuid)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Modal.CloseButton>Cancel</Modal.CloseButton>
          <Modal.SubmitButton isLoading={isLoading} onClick={() => submitForm(this.isEditMode())}>
            {mode === "create" ? "Create" : "Save"}
          </Modal.SubmitButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ShowFormModal;
