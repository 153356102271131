import { useEffect, useRef } from "react";
import { createEpisodeWaveform } from "src/action_managers/episodes";
import { getMediaFile } from "src/action_managers/shows";
import {
  CONVERSION_STATE_KEY_DONE,
  CONVERSION_STATE_KEY_FAILED,
} from "src/constants/conversion_states";
import { MediaFileConversionState } from "redcircle-types";
import { useDispatchTS, useSelectorTS } from "./redux-ts";

type useMediaFileConfig = {
  mediaFileUUID?: string;
  episodeUUID?: string;
  enablePolling?: boolean;
  pollingDelay?: number;
};

const useMediaFile = ({
  mediaFileUUID,
  episodeUUID,
  enablePolling = true,
  pollingDelay = 3500,
}: useMediaFileConfig) => {
  const mediaFiles = useSelectorTS((state) => state.mediaFiles);
  const {
    isLoading: userIsLoading,
    user: { authToken },
  } = useSelectorTS((state) => state.user);
  const currentMediaFile =
    typeof mediaFileUUID === "string" ? mediaFiles?.[mediaFileUUID] : undefined;

  const dispatch = useDispatchTS();

  let timeOutID: NodeJS.Timeout;

  useEffect(() => {
    if (currentMediaFile && !currentMediaFile.isLoading) {
      // media file object in State
      switch (currentMediaFile.conversionState) {
        case MediaFileConversionState.done:
          /**
           *  Conversion is Done but no waveform URL found, possiblity this is old audio that was converted
           *  before waveformURL was added as a feature. Need to enqueue the waveform logic
           *  */

          if (!currentMediaFile?.waveformURL) {
            typeof episodeUUID === "string" && dispatch(createEpisodeWaveform(episodeUUID));
          }

          // File is marked as done but no waveform URL due to audio being converted before waveformURL was implemented
          // or we have a stale state when converstate was updated, continue to poll for new media file data until we get
          // waveformURL
          if (enablePolling) {
            if (!currentMediaFile?.waveformURL) {
              timeOutID = setTimeout(() => {
                typeof mediaFileUUID === "string" &&
                  dispatch(getMediaFile(mediaFileUUID, authToken));
              }, pollingDelay);
            } else {
              timeOutID && clearTimeout(timeOutID);
            }
          }
          break;
        case MediaFileConversionState.processing:
          // File conversion is in process/processing need to refetch new media file data till done.
          if (enablePolling) {
            if (!currentMediaFile?.waveformURL) {
              timeOutID = setTimeout(() => {
                typeof mediaFileUUID === "string" &&
                  dispatch(getMediaFile(mediaFileUUID, authToken));
              }, pollingDelay);
            } else {
              timeOutID && clearTimeout(timeOutID);
            }
          }

          break;
        case MediaFileConversionState.failed:
          // Somethign went wrong. Should re enqueue if necesary

          if (!currentMediaFile?.waveformURL) {
            typeof episodeUUID === "string" && dispatch(createEpisodeWaveform(episodeUUID));
          }

          break;
        case MediaFileConversionState.not_applicable:
        case MediaFileConversionState.needs_processing:
          // Do nothing, needs_processing means process has already been enqueued for conversion but not started yet
          // and not_applicable is self explanatory

          break;
      }
    } else {
      // Media file object not in state must fetch it
      typeof mediaFileUUID === "string" && dispatch(getMediaFile(mediaFileUUID, authToken));
    }

    return () => {
      timeOutID && clearTimeout(timeOutID);
    };
  }, [userIsLoading, mediaFileUUID, episodeUUID, currentMediaFile]);

  if (!mediaFileUUID) return { mediaFile: undefined, isLoading: true };

  return {
    mediaFile: currentMediaFile ? currentMediaFile : undefined,
    isLoading: currentMediaFile ? currentMediaFile.isLoading : true,
  };
};

// polling logic for media file through all conversion states, not just processing
const usePollingMediaFile = ({
  mediaFileUUID,
  shouldPoll = true,
  interval = 3000,
}: {
  mediaFileUUID?: string | undefined;
  shouldPoll?: boolean;
  interval?: number;
}) => {
  const dispatch = useDispatchTS();
  const mediaFiles = useSelectorTS((state) => state.mediaFiles);
  const poll = useRef<NodeJS.Timer | undefined>();

  useEffect(() => {
    if (mediaFileUUID) {
      dispatch(getMediaFile(mediaFileUUID)).then((res) => {
        if (
          res.status === 200 &&
          shouldPoll &&
          res.json.conversionState !== CONVERSION_STATE_KEY_DONE &&
          res.json.conversionState !== CONVERSION_STATE_KEY_FAILED
        ) {
          startPoll();
        }
      });
    }

    return () => endPoll();
  }, [mediaFileUUID]);

  const startPoll = () => {
    poll.current = setInterval(() => {
      dispatch(getMediaFile(mediaFileUUID)).then((res) => {
        if (
          res.json.conversionState === CONVERSION_STATE_KEY_DONE ||
          res.json.conversionState === CONVERSION_STATE_KEY_FAILED
        ) {
          endPoll();
        }
      });
    }, interval);
  };

  const endPoll = () => {
    if (poll.current) clearInterval(poll.current);
    poll.current = undefined;
  };

  const mediaFile = mediaFileUUID ? mediaFiles[mediaFileUUID] : null;
  const isLoading = mediaFile ? mediaFile.isLoading : true;
  return { mediaFile, isLoading };
};

export { useMediaFile, usePollingMediaFile };
