/*
 * action types
 */

export const FORGOT_PASSWORD_REQUEST_START = "FORGOT_PASSWORD_REQUEST_START";
export const FORGOT_PASSWORD_REQUEST_SUCCESS = "FORGOT_PASSWORD_REQUEST_SUCCESS";
export const FORGOT_PASSWORD_REQUEST_ERROR = "FORGOT_PASSWORD_REQUEST_ERROR";
export const RECOVER_PASSWORD_REQUEST_START = "RECOVER_PASSWORD_REQUEST_START";
export const RECOVER_PASSWORD_REQUEST_SUCCESS = "RECOVER_PASSWORD_REQUEST_SUCCESS";
export const RECOVER_PASSWORD_REQUEST_ERROR = "RECOVER_PASSWORD_REQUEST_ERROR";
export const RECOVER_PASSWORD_RESET = "RECOVER_PASSWORD_REQUEST_RESET";

/*
 * action creators
 */

export function forgotPasswordStart() {
  return {
    type: FORGOT_PASSWORD_REQUEST_START,
  };
}

export function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD_REQUEST_SUCCESS,
  };
}

export function forgotPasswordError() {
  return {
    type: FORGOT_PASSWORD_REQUEST_ERROR,
  };
}

export function recoverPasswordStart() {
  return {
    type: RECOVER_PASSWORD_REQUEST_START,
  };
}

export function recoverPasswordSuccess(user) {
  return {
    type: RECOVER_PASSWORD_REQUEST_SUCCESS,
    user: user,
  };
}

export function recoverPasswordError() {
  return {
    type: RECOVER_PASSWORD_REQUEST_ERROR,
  };
}

export function recoverPasswordReset() {
  return {
    type: RECOVER_PASSWORD_RESET,
  };
}
