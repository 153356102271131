import { Tooltip } from "antd";
import get from "lodash/get";
import { stringifyUrl } from "query-string";
import React, { Component } from "react";
import { classNames } from "react-extras";
import { getSeedcastURL, getStripeClientID } from "../../../lib/config";
import "./connect_button.scss";
import { connect } from "react-redux";
import { encodeBracketsOnly } from "src/lib/url";

class StripeConnection extends Component {
  wrapWithTooltip = (content) => {
    if (this.props.disabled) {
      return <Tooltip title={"You do not have permissions to connect payments"}>{content}</Tooltip>;
    }
    return content;
  };
  render() {
    const stripeURL = getStripeURL(this.props.user, this.props.app);
    return (
      <div className="stripe-connection-wrapper">
        <div className={classNames("stripe-connection__button flex-row-container")}>
          {this.wrapWithTooltip(
            <p>
              <a
                disabled={this.props.disabled}
                href={stripeURL}
                className={classNames("connect-button", this.props.disabled && "disabled")}>
                <span>Connect with Stripe</span>
              </a>
            </p>
          )}
          <p className="stripe-connection__button-description" />
        </div>
      </div>
    );
  }
}

export const getStripeURL = (user, app) => {
  /**
   * "bypassWaitListRestriction" will always be true, if it is manually set to false,
   * it means the user has been allowed to bypass wait list
   */
  const addWaitListQueryParam = app.bypassWaitListRestriction;

  var stripeClientID = getStripeClientID();
  var params = {
    response_type: "code",
    client_id: stripeClientID,
    scope: "read_write",
    state: get(user, "securityToken"),
    suggested_capabilities: ["card_payments", "transfers", "tax_reporting_us_1099_misc"],
    /**
     *  query-string intentionally does not flatten nested objects
     **/
    "stripe_user[email]": get(user, "email"),
    "stripe_user[first_name]": get(user, "firstName"),
    "stripe_user[last_name]": get(user, "lastName"),
    /**
     *  Adding a redirect uri manually to pass in optional wait listed flag,
     *  Redirect URI must match URI's in platform allowed list
     */
    redirect_uri: `${getSeedcastURL()}oauth-redirect${addWaitListQueryParam ? "?wait=0" : ""}`,
  };

  if (get(user, "phoneNumber")) {
    params["stripe_user[phone_number]"] = get(user, "phoneNumber");
  }

  const url = `${encodeBracketsOnly(stringifyUrl({ url: "https://connect.stripe.com/express/oauth/authorize", query: params }, { arrayFormat: "bracket" }))}`;

  return url;
};
const StripeConnectionWrapper = connect((state) => {
  return { app: state?.app };
}, {})(StripeConnection);

export default StripeConnectionWrapper;
