import debounce from "lodash/debounce";
import get from "lodash/get";
import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import Spinner from "../../lib/spinner";
import "./autocomplete.scss";

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      isLoading: false,
    };
    this.fetchSuggestionsDebounced = debounce(
      (value) => {
        this.setState({ isLoading: true });
        return this.props.fetchSuggestions(value).then(() => this.setState({ isLoading: false }));
      },
      250,
      { maxWait: 1000 }
    );
  }

  UNSAFE_componentWillMount() {
    this.props.clearSuggestions();
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.fetchSuggestionsDebounced(value);
  };

  onSuggestionsClearRequested = () => {
    this.props.clearSuggestions();
  };

  onSelect = (event, { suggestion }) => {
    if (this.props.onSelect) {
      this.props.onSelect(suggestion);
    }
  };

  render() {
    const inputProps = {
      ...this.props.input,
      value: this.state.value,
      onChange: this.onChange,
      className: get(this.props, "input.className", "") + " form-control",
      placeholder: get(this.props, "placeholder"),
      isLoading: get(this.props, "isLoading", false),
    };

    let suggestions = this.props.suggestions;
    if (this.props.limit) {
      suggestions = suggestions.slice(0, this.props.limit);
    }

    // Finally, render it!
    return (
      <>
        <div className="flex-row-container align-center">
          <div className="width-100">
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={this.onSelect}
              getSuggestionValue={this.props.getItemValue}
              renderSuggestion={this.props.renderItem}
              inputProps={inputProps}
            />
          </div>
          <div className="react-autosuggest__loader">
            <Spinner
              isSmall={true}
              isLoading={this.state.isLoading}
              spinDelay={1}
              disableBackground={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Autocomplete;
