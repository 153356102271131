import { ReduxDispatch } from "src/hooks/redux-ts";
import { updateCurrentRole } from "../actions/account";
import { signUpError, signUpInvalid, signUpStart, signUpSuccess } from "../actions/sign_up";
import { userLogin } from "../actions/user";
import userAPI from "../api/user";
import getDefaultRole from "../lib/user_roles";
import UserStore, { saveUserRole } from "../lib/user_store";
import ActionManager, { GetStateFunc, HTTPResponses } from "./base";
import { postLoginDataFetch } from "./sign_in";
import { User, UserRole } from "redcircle-types";

type SignUpRequest = {
  email: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  password: string;
  role: Exclude<UserRole, "admin">;
  languageCode?: string;
  phoneNumber?: number;
  phoneNumberCountryCode?: string;
  inviteUUID?: string;
  signupSource?: "openRAPWaitlist" | "";
};

class SignUpActionManager extends ActionManager<
  User,
  { formJSON: SignUpRequest; forRole: UserRole }
> {
  defaultErrorMessage() {
    return "Uh oh, something went wrong signing up. Please try again later.";
  }

  defaultPreCallActionCreator() {
    return signUpStart;
  }
  defaultSuccessActionCreator() {
    return signUpSuccess;
  }

  default400ActionCreator() {
    return signUpInvalid;
  }

  defaultErrorActionCreator() {
    return signUpError;
  }

  execute() {
    return userAPI.signUp(this.args.formJSON);
  }

  responseOK(dispatch: ReduxDispatch, json: User) {
    const role = this.args.forRole || getDefaultRole(json);
    dispatch(updateCurrentRole(role));
    saveUserRole(role);
    dispatch(userLogin(json));
    UserStore.save(json);
    super.responseOK(dispatch, json);
  }

  postCall(
    dispatch: ReduxDispatch,
    response: HTTPResponses<User, object>,
    getState: GetStateFunc
  ): Promise<HTTPResponses<User, object>> {
    // If the response did not succeed no point in continuing
    if (response.status !== 200) return Promise.resolve(response);

    const user = response.json;

    /**
     * Post login need to reset redux state with new user specific data
     */
    return postLoginDataFetch(user).then(() => response);
  }
}

export default SignUpActionManager;
