import set from "lodash/set";
import { AnyAction } from "redux";
import { MEDIA_FILE_FETCH_END, MEDIA_FILE_FETCH_START } from "../../actions/shows";
import { UPLOAD_MODAL_SUCCESS } from "../../actions/upload_modal";
import { MediaFileReduxState } from "./types";
import { httpReducer, reduceReducers } from "src/lib/create-action";
import { GET_CAMPAIGN } from "src/action_managers/campaigns";
import { keyBy } from "lodash";
import { MediaFile } from "redcircle-types";
import { isUUID } from "src/lib/uuid";

const initialMediaState: MediaFileReduxState = {};

function mediaFiles(state = initialMediaState, action: AnyAction) {
  switch (action.type) {
    case MEDIA_FILE_FETCH_START: {
      const newState = { ...state };
      if (action.mediaFileUUID) {
        set(newState, action.mediaFileUUID + ".isLoading", true);
      }
      return newState;
    }
    case MEDIA_FILE_FETCH_END: {
      const newState = { ...state };
      if (action.mediaFile && action.mediaFile.uuid) {
        newState[action.mediaFile.uuid] = action.mediaFile;
        newState[action.mediaFile.uuid].isLoading = false;
      }
      return newState;
    }
    case UPLOAD_MODAL_SUCCESS: {
      const newState = { ...state };
      if (action.mediaFiles) {
        for (const f of action.mediaFiles) {
          newState[f.uuid] = f;
          newState[f.uuid].isLoading = false;
        }
      }
      return newState;
    }
    default:
      return state;
  }
}

const getCampaignMediaFiles = httpReducer(GET_CAMPAIGN, initialMediaState, {
  success: (state, action) => {
    const mediaFiles = action.payload.resp?.items
      ?.map(({ mediaFile }: { mediaFile: MediaFile }) => mediaFile)
      ?.filter((mediaFile: MediaFile) => isUUID(mediaFile?.uuid)) as MediaFile[];

    return {
      ...state,
      ...keyBy(mediaFiles, "uuid"),
    };
  },
});

export default reduceReducers(mediaFiles, getCampaignMediaFiles);
