import get from "lodash/get";
import { GET_EPISODE } from "../../actions/episodes";
import { EPISODE_PUT, SHOW_EPISODE_FETCH_SUCCESS } from "../../actions/shows";
import { UPLOAD_TO_YOUTUBE } from "../../action_managers/shows";
import { httpMultipleActionsReducer, httpReducer, reduceReducers } from "../../lib/create-action";
import { EpisodesReduxState } from "./types";
import { IEpisode } from "redcircle-types";

const initialState: EpisodesReduxState = {
  episodeByUUID: {},
};

const getEpisode = httpMultipleActionsReducer(
  [GET_EPISODE, SHOW_EPISODE_FETCH_SUCCESS, UPLOAD_TO_YOUTUBE],
  initialState,
  {
    success: (state, action) => {
      const currentEpisodes = get(state, "episodeByUUID", {});
      const episode = action.payload.resp;
      const newEpisodes = { ...currentEpisodes, [episode.uuid]: episode };
      return {
        ...state,
        episodeByUUID: newEpisodes,
      };
    },
  }
);

const updateEpisode = httpReducer(EPISODE_PUT, initialState, {
  success: (state, action) => {
    const updatedEpisode: IEpisode = action?.episode;

    const currentEpisodes = get(state, "episodeByUUID", {});

    const newEpisodes = { ...currentEpisodes, [updatedEpisode.uuid]: updatedEpisode };
    return {
      ...state,
      episodeByUUID: newEpisodes,
    };
  },
});

export default reduceReducers(getEpisode, updateEpisode);
