import dayjs from "dayjs";
import Moment from "moment";
import {
  Budget,
  CampaignItemState,
  ICampaign,
  ICampaignTargetingOptions,
  isBudget,
  isCampaign,
  ICampaignItem,
  IOfferRates,
  isCampaignItem,
  PublicShow,
  IShow,
  IAdvertisementSettings,
  Script,
  cents,
} from "redcircle-types";
import { IAppendedCampaignItem } from "src/components/pages/campaigns/campaign_page/campaign_page";
import { getCampaignProgress } from "src/components/pages/campaigns/campaign_page/campaign_progress";
import {
  CampaignItemStateAwaitingAudio,
  CampaignItemStateCompleted,
  CampaignItemStatePaused,
  CampaignItemStateRunning,
  CampaignItemStateSent,
  CampaignStyleHostRead,
  ProductExchangeTypeInstructions,
  ProductExchangeTypeMailing,
} from "src/constants/campaigns";
import {
  CampaignDistributionReachDiscrete,
  CampaignItemStateAudioSwapRequested,
  PromoCodeTypeNone,
  PromoCodeTypePodcasterUnique,
  PromoCodeTypeStandard,
} from "../constants/campaigns";
import { getCampaignField } from "./campaign";
import { getCampaignItemField } from "./campaign_item";
import { differenceBetween2Dates, UnixTimeStamp } from "./date";
import { isValidUrl } from "./url";

/**
 * ************* MUST REFACTOR START  **************
 */

export const isNegotiatedRateInCampaign = (campaign: ICampaign, checkOnlyDraft = false) => {
  if (checkOnlyDraft) {
    return Boolean(
      campaign?.items?.some(({ item }) => item?.offerRates?.enabled && item?.state === "draft")
    );
  }
  return Boolean(campaign?.items?.some(({ item }) => item?.offerRates?.enabled));
};

export const campaignIsDiscrete = (campaign: ICampaign) => {
  return campaign?.distributionType === CampaignDistributionReachDiscrete;
};

export const campaignItemIsDiscrete = (campaignItem: ICampaignItem) => {
  return !!campaignItem?.flightConfigs?.length;
};
export const getStartOfWeek = (date: Moment.MomentInput) => {
  const firstMonday = 363600;
  const currentDateUnix = Moment(date).unix();
  const remainder = (Moment(date).unix() - firstMonday) % (60 * 60 * 24 * 7);
  return Moment.unix(currentDateUnix - remainder);
};

export const getEndOfWeek = (date: Moment.MomentInput) => {
  return Moment.unix(getStartOfWeek(Moment(date).add(1, "w")).subtract(1, "m").unix());
};

/**
 * ************* MUST REFACTOR END  **************
 */

const isCPMRenegotiated = (campaignItem: ICampaignItem) => {
  return Boolean(campaignItem?.offerRates?.enabled);
};

/**
 * Get Average CPM is defined as an overloaded helper function in order to
 * consolidate all of the logic in one place and decrease chance of confusion/tech debt
 * from splitting to multiple helper funcs. Depending on the context the CPM
 * can required grabbing multiple different redux slices.
 */
interface IGetAverageCPM<
  S extends IShow | PublicShow | undefined = IShow | PublicShow | undefined,
  C extends ICampaign | undefined = ICampaign | undefined,
  CI extends ICampaignItem | undefined = ICampaignItem | undefined,
  B extends Budget | undefined = Budget | undefined,
> {
  /**
   * Calculate Average CPM from show rates information. Simple division, adding pre, mid, post rates and
   * divide by 3. Does not take into account any rate configuration from a campaign or negotiated
   * rate information from campaignItem.
   *
   * #### THIS OVERLOAD SHOULD ONLY BE USED FOR PODCASTS THAT ARE NOT PART OF A CAMPAIGN ####
   *
   * @param props.show
   * @returns {number} cost per thousand downloads or 0 if there is an error
   *
   */
  (props: { show: S }): number;

  /**
   * Calculate Average CPM from show rate information, taking into account campaign configuration
   * (i.e. campaign is configured to pre and mid rolls, this will average only the rates for pre and mid rolls)
   *
   * Primarily used to provide possible CPM rates in advertiser podcast browse modal. Where a campaign exists with
   * rate configuration (pre, mid, post roll etc) but no podcast has been added to the campaign hence no
   * campaignItem to grab possible rate renegotiated information from.
   *
   * @param props.show
   * @param props.campaign
   * @returns {number} cost per thousand downloads or 0 if there is an error
   *
   */
  (props: { show: S; campaign: C }): number;

  /**
   * Calculate Average CPM from show rate information, taking into account both campaign rate configuration
   * and possible re-negotiated rate information from campaignItem. Will also take into account if  budget prop
   * has been added to campaignItem (i.e. the Budget for a campaign Item has the recorded CPMs of that show at the
   * moment of invite ).
   *
   * @param props.show
   * @param props.campaign
   * @param props.campaignItem
   * @param when - "final" or "original" CPM - defaults to final CPM (i.e. if the CPM was re negotiated choose to get original CPM or final CPM)
   * @returns {number} cost per thousand downloads or 0 if there is an error
   */
  (props: {
    show: S;
    campaign: C;
    campaignItem: CI;
    when?: "final" | "original" | undefined;
  }): number;

  /**
   * Calculate Average CPM from show rate information, taking into account both campaign rate configuration
   * and possible re-negotiated rate information from campaignItem. Also takes into account Budget object
   * for the campaign item.
   *
   * @param props.show
   * @param props.campaign
   * @param props.campaignItem
   * @param props.budget
   * @param when - "final" or "original" CPM - defaults to final CPM (i.e. if the CPM was re negotiated choose to get original CPM or final CPM)
   * @returns {number} cost per thousand downloads or 0 if there is an error
   */
  (props: {
    show: S;
    campaign: C;
    campaignItem: CI;
    budget: B;
    when?: "final" | "original" | undefined;
  }): number;
}

export const getAverageCPM: IGetAverageCPM = (props) => {
  const { show } = props;

  if (!!show && !("campaign" in props) && !("campaignItem" in props) && !("budget" in props)) {
    return (
      (show.advertisementSettings.hostReadPreRollCPM +
        show.advertisementSettings.hostReadMidRollCPM +
        show.advertisementSettings.hostReadPostRollCPM) /
      3
    );
  }

  if (!!show && "campaign" in props && isCampaign(props.campaign)) {
    const { campaign, campaignItem } = props;
    const targetingOptions: ICampaignTargetingOptions = campaignItem
      ? getCampaignItemField("targetingOptions", { campaignItem, campaign })
      : campaign.targetingOptions;
    const numOfAllowedCPMs = Object.values(targetingOptions).reduce(
      (accu: number, enabled: boolean) => (accu += enabled ? 1 : 0),
      0
    ) as number;

    const totalCPM = Object.entries(targetingOptions).reduce((accu, curr) => {
      const [CPMType, enabled] = curr as [
        keyof ICampaignTargetingOptions,
        ICampaignTargetingOptions[keyof ICampaignTargetingOptions],
      ];

      // If not enabled in campaign config then skip from cumulative addition
      if (!enabled) return accu;

      if ("campaignItem" in props && isCampaignItem(props.campaignItem)) {
        const { campaignItem, when = "final" } = props;

        // Prioritize grabbing the final CPM info from offer rates object when CPM is negotiated
        if (isCPMRenegotiated(campaignItem) && when === "final") {
          accu +=
            campaignItem.offerRates?.[
              `finalAdjusted${CPMType.charAt(0).toUpperCase()}${CPMType.substring(
                1
              )}CPM` as Exclude<keyof IOfferRates, "enabled">
            ];
        }
        // Prioritize grabbing the original CPM info from offer rates object when CPM is negotiated
        else if (isCPMRenegotiated(campaignItem) && when === "original") {
          accu +=
            campaignItem.offerRates?.[
              `original${CPMType.charAt(0).toUpperCase()}${CPMType.substring(1)}CPM` as Exclude<
                keyof IOfferRates,
                "enabled"
              >
            ];
        }
        // Second priority is grabbing CPM info from budget object, if provided
        else if ("budget" in props && isBudget(props.budget)) {
          accu +=
            props.budget?.advertisementSettings?.[
              `hostRead${CPMType.charAt(0).toUpperCase()}${CPMType.substring(1)}CPM` as keyof Pick<
                IAdvertisementSettings,
                "hostReadPreRollCPM" | "hostReadMidRollCPM" | "hostReadPostRollCPM"
              >
            ];
        }
        // Check if budget is provided combined in the campaignItems object in order to calcualte CPM
        else if ("budget" in campaignItem && isBudget(campaignItem.budget)) {
          accu +=
            campaignItem?.budget?.advertisementSettings?.[
              `hostRead${CPMType.charAt(0).toUpperCase()}${CPMType.substring(1)}CPM` as keyof Pick<
                IAdvertisementSettings,
                "hostReadPreRollCPM" | "hostReadMidRollCPM" | "hostReadPostRollCPM"
              >
            ];
        } else {
          // Default grabbing CPM info from show object
          accu +=
            show.advertisementSettings?.[
              `hostRead${CPMType.charAt(0).toUpperCase()}${CPMType.substring(1)}CPM` as keyof Pick<
                IAdvertisementSettings,
                "hostReadPreRollCPM" | "hostReadMidRollCPM" | "hostReadPostRollCPM"
              >
            ];
        }
      } else {
        accu +=
          show.advertisementSettings?.[
            `hostRead${CPMType.charAt(0).toUpperCase()}${CPMType.substring(1)}CPM` as keyof Pick<
              IAdvertisementSettings,
              "hostReadPreRollCPM" | "hostReadMidRollCPM" | "hostReadPostRollCPM"
            >
          ];
      }

      return accu;
    }, 0);

    return numOfAllowedCPMs !== 0 ? Math.floor(totalCPM / numOfAllowedCPMs) : 0;
  }

  return 0;
};

/**
 * Return the unix time stamp of the estimated End Date of the campaign item. Takes into account
 * Pacing enabled campaigns
 * @param {props} props - required details to calculate campaign item estimated end date
 * @param {ICampaign} props.campaign
 * @param {ICampaignItem} props.campaignItem
 * @param {IShow | PublicShow} props.show
 * @param {Budget} props.budget
 * @returns {number} Unix time Stamp - Estimated End Date
 */
export const getEstimatedEndDateForCampaignItem = ({
  campaign,
  campaignItem,
  show,
  budget,
}: {
  campaign?: ICampaign;
  campaignItem?: ICampaignItem;
  show?: IShow | PublicShow;
  budget?: Budget;
}) => {
  if (!campaign || !campaignItem || !show) return 0;

  const today = dayjs().unix();

  // If campaignItem already finished just return the end date provided by BE
  if (typeof campaignItem.completedAt === "number") {
    return campaignItem.completedAt;
  }

  // If campaignItem is a paced campaign, check that the paced End date has not passed, and return that
  const pacing = getCampaignItemField("pacing", { campaignItem, campaign });
  if (pacing) {
    if (campaignItem.isV2) return campaignItem?.lifecycleSettings?.endAt?.value;
    return campaignItem.pacingEstimatedEndAt;
  }

  /**
   * If campaignItem is ongoing calculate days left from remaining budget and add it to
   * startAt date if it is in the future (use current date if starts at date has already passed)
   */

  const cpm = getAverageCPM({ show, campaign, campaignItem, budget, when: "final" });
  const dailyImpressions = getDailyImpressions(show);
  const budgetLeft =
    typeof campaignItem?.budget?.total === "number" &&
    typeof campaignItem?.budget?.current === "number"
      ? (campaignItem?.budget?.total - campaignItem?.budget?.current) / 1000
      : campaignItem.totalBudget;
  const impressions = (budgetLeft / cpm) * 1000;

  const daysLeft = Math.round(impressions / dailyImpressions); // Older helper funcs round days, keeping this for consistency

  const startAt = getCampaignItemField("startAt", { campaignItem, campaign });
  const startDate = typeof startAt === "number" ? startAt : today;

  const estimatedEndDate = dayjs.unix(startDate).add(daysLeft, "day").unix();
  return estimatedEndDate;
};

export const getCampaignCPMsForShow = ({
  campaign,
  campaignItem,
  show,
  when = "final",
}: {
  campaign?: ICampaign;
  campaignItem?: ICampaignItem;
  show?: IShow | PublicShow;
  when?: "final" | "original";
}) => {
  if (!campaign || !campaignItem || !show) return {};
  const targetingOptions: ICampaignTargetingOptions = getCampaignItemField("targetingOptions", {
    campaignItem,
    campaign,
  });

  const CPM_breakdown = Object.entries(targetingOptions).reduce(
    (accu, curr) => {
      const [CPMType, enabled] = curr as [
        keyof ICampaignTargetingOptions,
        ICampaignTargetingOptions[keyof ICampaignTargetingOptions],
      ];

      // If not enabled then skip from cumulative addition
      if (!enabled) return accu;

      if (isCPMRenegotiated(campaignItem) && when === "final") {
        accu[CPMType] =
          campaignItem.offerRates?.[
            `finalAdjusted${CPMType.charAt(0).toUpperCase()}${CPMType.substring(1)}CPM` as Exclude<
              keyof IOfferRates,
              "enabled"
            >
          ];
      } else if (isBudget(campaignItem?.budget)) {
        accu[CPMType] =
          campaignItem?.budget?.advertisementSettings?.[
            `hostRead${CPMType.charAt(0).toUpperCase()}${CPMType.substring(1)}CPM` as keyof Pick<
              IAdvertisementSettings,
              "hostReadPreRollCPM" | "hostReadMidRollCPM" | "hostReadPostRollCPM"
            >
          ];
      } else {
        accu[CPMType] =
          show.advertisementSettings?.[
            `hostRead${CPMType.charAt(0).toUpperCase()}${CPMType.substring(1)}CPM` as keyof Pick<
              IAdvertisementSettings,
              "hostReadPreRollCPM" | "hostReadMidRollCPM" | "hostReadPostRollCPM"
            >
          ];
      }

      return accu;
    },
    {} as Partial<Record<keyof ICampaignTargetingOptions, number>>
  );

  return CPM_breakdown;
};

/**
 * Calculate From Budget
 */

/**
 * @typedef CampaignItemDetails
 * @type {object}
 */

/**
 * Calculate campaign item Impressions for NON PACING campaign, using CPM and budget
 * @param {CampaignItemDetails} props - required details to calculate budget
 * @param  {number} props.cpm - Cost per thousand Downloads
 * @param {number} props.budget - budget for campaign item
 * @returns {number} - calculated impressions for campaign item, returns 0 if there is an error
 */
export const getImpressionsFromBudget: (props: { cpm: number; budget: number }) => number = ({
  cpm,
  budget,
}) => {
  if (typeof cpm === "number" && typeof budget === "number") {
    return (budget / cpm) * 1000;
  }
  return 0;
};

/**
 * Calculate campaign item Impressions for NON PACING campaigns using CPM, estimated daily impressions and start/end date,
 * @param {CampaignItemDetails} props - required details to calculate impressions
 * @param  {number} props.cpm - Cost per thousand Downloads
 * @param {number} props.dailyImpressions - estimated daily impressions for campaign item podcast
 * @param {number} props.startDate - unix time stamp of start date for campaign item
 * @param {number} props.endDate - unix time stamp of end date for campaign item
 * @returns {number} - calculated impressions for campaign item, returns -1 if there is an error
 */
export const getImpressionsWOBudget: (props: {
  dailyImpressions: number;
  startDate: UnixTimeStamp;
  endDate: UnixTimeStamp;
}) => number = ({ dailyImpressions, startDate, endDate }) => {
  let impressions = -1;
  if (
    typeof startDate === "number" &&
    typeof endDate === "number" &&
    typeof dailyImpressions === "number"
  ) {
    impressions =
      Math.ceil(
        differenceBetween2Dates({
          startDate: startDate,
          endDate: endDate,
          interval: "day",
        })
      ) * dailyImpressions;
  }

  return impressions;
};

/**
 * Calculate campaign item Budget for NON PACING campaigns using CPM, estimated daily impressions and start/end date,
 * @param {CampaignItemDetails} props - required details to calculate budget
 * @param  {number} props.cpm - Cost per thousand Downloads
 * @param {number} props.dailyImpressions - estimated daily impressions for campaign item podcast
 * @param {number} props.startDate - unix time stamp of start date for campaign item
 * @param {number} props.endDate - unix time stamp of end date for campaign item
 * @returns {number} - calculated budget for campaign item in cents returns -1 if there is an error
 */
export const getBudget: (
  props:
    | {
        cpm: number;
        dailyImpressions: number;
        startDate: UnixTimeStamp;
        endDate: UnixTimeStamp;
      }
    | { cpm: number; impressions: number }
) => cents = (props) => {
  if (
    "startDate" in props &&
    "endDate" in props &&
    typeof props?.cpm === "number" &&
    typeof props?.startDate === "number" &&
    typeof props?.endDate === "number" &&
    typeof props?.dailyImpressions === "number"
  ) {
    const { cpm, startDate, endDate, dailyImpressions } = props;
    const impressions = getImpressionsWOBudget({ dailyImpressions, startDate, endDate });

    return Math.trunc((impressions / 1000) * cpm); //returning whole number cents;
  } else if (
    "impressions" in props &&
    typeof props?.cpm === "number" &&
    typeof props?.impressions === "number"
  ) {
    const { cpm, impressions } = props;
    return Math.trunc((impressions / 1000) * cpm); // returning whole number cents
  }

  return -1;
};
/**
 * Calculated estimated daily impressions for show
 * @param publicShow - public show info
 * @returns {number} - calculated estimated daily impressions, returns 0 if there is an error
 */
export const getDailyImpressions = (publicShow?: PublicShow | IShow) =>
  (publicShow?.estimatedWeeklyDownloads ?? 0) / 7;

// Audio Swap
export const isAudioSwapRequested = (campaignItem?: ICampaignItem) => {
  const audioReqAfterLastUpdate =
    typeof campaignItem?.swapAudioInfo !== "undefined" &&
    Number(campaignItem?.swapAudioInfo?.requestedAt) >= Number(campaignItem?.audioLastUploadedAt);

  /**
   * AudioSwap is active when campaignItem state === "audio=swap-requested" which is implicitly brand initiated swap request,
   * or if Podcaster initiated swap request, campaignItem.state does not change but the swap request timestamp with be after or
   * equal the timestamp of last audio updated
   */
  return {
    isAudioSwapActive:
      campaignItem?.state === CampaignItemStateAudioSwapRequested ||
      (campaignItem?.isV2 && campaignItem.lifecycleSettings.swapAudioPending) ||
      audioReqAfterLastUpdate,
    isPodcasterInitiated:
      campaignItem?.swapAudioInfo?.initiatorUserUUID === campaignItem?.creatorUUID ||
      (campaignItem?.isV2 &&
        campaignItem.lifecycleSettings.swapAudioInfo?.initiatorUserUUID ===
          campaignItem?.creatorUUID),
  };
};

// GET PROMO CODE

export const getPromoCode = ({
  campaign,
  campaignItem,
  show,
  script,
}: {
  campaign?: ICampaign; // @deprecated
  campaignItem: ICampaignItem;
  show: IShow | PublicShow;
  script: Script;
}) => {
  switch (script?.promoCodeType || campaign?.promoCodeType) {
    case PromoCodeTypePodcasterUnique:
      return campaignItem?.promoCode || show?.promoCode || "";
    case PromoCodeTypeStandard:
      return script?.promoCode || "";
    case PromoCodeTypeNone:
      return "No promotion code/URL";
    default:
      return undefined;
  }
};

interface IGenerateDefaultCTAProps {
  promoCode: string;
  brandName?: string;
  website?: string;
}
export const generateDefaultCTA = ({ promoCode, brandName, website }: IGenerateDefaultCTAProps) => {
  const trimmedPromoCode = promoCode.trim();
  if (isValidUrl(trimmedPromoCode)) {
    const promoCodePrefix = trimmedPromoCode.startsWith("http") ? "" : "https://";
    return `Check out ${brandName}: ${promoCodePrefix}${promoCode}`;
  }

  if (website && isValidUrl(website)) {
    const websitePrefix = website.trim().startsWith("http") ? "" : "https://";
    website = `${websitePrefix}${website}`;
  }

  if (trimmedPromoCode.length > 0) {
    return `Check out ${brandName} and use my code ${promoCode} for a great deal: ${website}`;
  }

  return `Check out ${brandName}: ${website}`;
};

interface IGenerateDefaultCTAWithCampaignProps extends IGenerateDefaultCTAProps {
  campaignItem: ICampaignItem;
}
export const generateDefaultCTAWithCampaign = (props: IGenerateDefaultCTAWithCampaignProps) => {
  const { campaignItem, ...rest } = props;

  if (campaignItem.feedCTA) return campaignItem.feedCTA;

  return generateDefaultCTA(rest);
};

// Check if Script is required for Shopping Cart Campaign Sent flow
export const isScriptRequired = (campaign: ICampaign) => {
  const MinDaysNeededForScriptRequired = 15;
  const MinDaysNeededForScriptRequiredV2 = 7;

  if (campaign.isV2) {
    const assignAudioDeadline = campaign.assignAudioDeadline;
    return (
      !!assignAudioDeadline &&
      dayjs.unix(assignAudioDeadline).diff(dayjs(), "days") < MinDaysNeededForScriptRequiredV2
    );
  }

  // round campaign start date up to EOD in EST
  const startsAt = getCampaignField("startsAt", { campaign });
  const campaignStartDate = startsAt && dayjs.unix(startsAt).tz("America/New_York").endOf("day");
  const today = dayjs();
  return (
    !!campaignStartDate && campaignStartDate.diff(today, "days") < MinDaysNeededForScriptRequired
  );
};

export const isPastDueDate = (unixDueDate: number) => {
  const dueDate = Moment.unix(unixDueDate);

  if (!dueDate.isValid()) return true;

  const now = Moment();

  return dueDate.isBefore(now);
};

// MONEY MONEY MONEY MONEY MONEY MONEY MONEY MONEY MONEY MONEY MONEY MONEY MONEY MONEY MONEY MONEY

export const getCampaignItemTotalAmount = (campaignItem: ICampaignItem) => {
  return campaignItem.totalCreatorAmount;
};

// Currently only shows amount in USD.
// We make sure to subtract the RedCircle cut that we took.
export const getCampaignItemPaidAmount = (campaignItem: ICampaignItem) => {
  const totalCents = campaignItem.paidUpToAmount || 0.0;
  const paidOutCents =
    totalCents - Math.floor((totalCents * campaignItem.advertisingCutBasisPoints) / 10000);
  return paidOutCents;
};

export const getCampaignItemUnpaidAmount = (campaignItem: ICampaignItem) => {
  const amount = getCampaignItemTotalAmount(campaignItem) - getCampaignItemPaidAmount(campaignItem);
  return amount < 0 ? 0 : amount; // sometimes we overpaid people
};

// Processing Campaign Items
// this is used to override campaign states in the frontend for running campaigns with
// progress at full completion
export const processCampaignItemState = (
  campaignItem: IAppendedCampaignItem
): IAppendedCampaignItem => {
  if (campaignItem && campaignItem.state === CampaignItemStateRunning) {
    const progress = getCampaignProgress(campaignItem)?.percent;
    if (progress === 100) {
      return { ...campaignItem, state: CampaignItemStateCompleted };
    }
  }

  return campaignItem;
};

export const isCampaignItemPendingAction = (campaignItem: ICampaignItem) => {
  return (
    campaignItem?.state === CampaignItemStatePaused ||
    campaignItem?.state === CampaignItemStateAudioSwapRequested ||
    campaignItem?.state === CampaignItemStateAwaitingAudio ||
    campaignItem?.state === CampaignItemStateSent
  );
};

/**
 * Accepted items are campaign items with the following states.
 *  "accepted",
    "completed",
    "paused",
    "running",
    "awaiting-script",
    "audio-swap-requested",
    "awaiting-audio-upload",

    Is useful for separating campaign items and to perform calculations
 */
export const isCampaignItemAccepted = (campaignItem: ICampaignItem) => {
  const acceptedItemStates: CampaignItemState[] = [
    "accepted",
    "completed",
    "paused",
    "running",
    "awaiting-script",
    "audio-swap-requested",
    "awaiting-audio-upload",
  ];

  return acceptedItemStates.includes(campaignItem?.state);
};

export const isCampaignArchived = (campaign: ICampaign) => campaign?.archived;

export const isCampaignProductExchanging = (campaign: ICampaign) => {
  return (
    campaign?.style === CampaignStyleHostRead &&
    (campaign?.productExchangeType === ProductExchangeTypeInstructions ||
      campaign?.productExchangeType === ProductExchangeTypeMailing)
  );
};
