import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formValueSelector } from "redux-form";
import { showMessageWithTimeout } from "../../../actions/app";
import { recoverPasswordReset } from "../../../actions/forgot_password";
import { userLogout } from "../../../actions/user";
import { AccountUpdateActionManager } from "../../../action_managers/account";
import { RecoverPasswordActionManager } from "../../../action_managers/forgot_password";
import messageType from "../../../constants/message_types";
import UserStore from "../../../lib/user_store";
import { withPageTitle } from "../../lib/WithPageTitle";
import RecoverPasswordPage from "./recover_password_page";

function mapStateToProps(state) {
  var formValueSelectorPassword = formValueSelector("new-password");
  return {
    isLoading: state.recoverPassword.isLoading,
    errorMessage: state.recoverPassword.errorMessage,
    user: state.recoverPassword.user,
    validationErrors: state.updateAccount.validationErrors,
    isSuccess: state.updateAccount.isSuccess,
    password: formValueSelectorPassword(state, "password"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    recoverPassword: (email, passwordToken) => {
      dispatch(
        new RecoverPasswordActionManager({
          email,
          passwordToken,
        }).run()
      );
    },
    updatePassword: (newPassword, user) => {
      return dispatch(
        new AccountUpdateActionManager({
          userForm: { password: newPassword },
          existingUser: user,
          customSuccess: "Password update successful. Please log back in.",
          skipStore: true,
        }).run()
      );
    },
    resetRecoveryState: () => {
      return dispatch(recoverPasswordReset());
    },
    logout: () => {
      UserStore.remove();
      dispatch(userLogout());
    },
    redirectToHome: (push) => {
      dispatch(
        showMessageWithTimeout("Succes! Please login with your new password.", messageType.Info)
      );
      push("/");
    },
  };
}

const RecoverPasswordPageWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordPage)
);

export default withPageTitle(RecoverPasswordPageWrapper, "Password Recovery");
