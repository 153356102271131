import filter from "lodash/filter";
import get from "lodash/get";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../actions/modal";
import { uploadModalProgress } from "../../actions/upload_modal";
import { updateDeal } from "../../action_managers/deals";
import { fetchPublicShowsForUser } from "../../action_managers/public_show";
import { MediaFileActionManager } from "../../action_managers/shows";
import UploadModalActionManager from "../../action_managers/upload_modal";
import DealRequestForm from "../pages/deals/deal_request_form";
import BaseModal from "./base_modal";

class DealRequestModal extends Component {
  componentDidMount() {
    let otherUserUUID = this.props.otherUserDealRequest.ownerUUID;
    this.props.fetchPublicShowsForUser(otherUserUUID);
    let mediaFileUUID = this.getExampleMediaFileUUID();
    if (mediaFileUUID) {
      this.props.fetchMediaFile(mediaFileUUID, this.props.user.user.authToken);
    }
  }

  getExampleMediaFileUUID = () => {
    return get(this.props, "currentUserDealRequest.dealContents[0].mediaFileUUIDs.128");
  };

  getInitialValues = () => {
    let dealContent = this.props.currentUserDealRequest.dealContents[0];
    let airsAt = new Date();
    let existingDate = get(dealContent, "airsAt");
    if (existingDate) {
      airsAt = new Date(existingDate * 1000);
    }

    return {
      airsAt: airsAt,
      showUUID: get(dealContent, "showUUID"),
      mediaFileUUIDs: get(dealContent, "mediaFileUUIDs"),
    };
  };

  isLoading = () => {
    if (this.props.isLoadingPublicShows) {
      return true;
    }

    if (this.getExampleMediaFileUUID()) {
      return get(this.props.mediaFiles, [this.getExampleMediaFileUUID(), "isLoading"]);
    }

    return false;
  };

  render() {
    return (
      <BaseModal
        isLoading={this.isLoading()}
        isMini={true}
        title={false}
        close={this.props.hideModal}
        showContent={!this.props.isLoadingPublicShows}
        Content={DealRequestForm}
        initialValues={this.getInitialValues()}
        onSubmit={(formData, dispatch, props) => {
          if (props.pristine) {
            this.props.hideModal();
            return;
          }
          this.submitForm(formData);
        }}
        isPutting={this.props.isPutting}
        progress={this.props.progress}
        publicShows={this.getOtherUserPublicShows()}
        mediaFiles={this.props.mediaFiles}
      />
    );
  }

  getOtherUserPublicShows = () => {
    let otherUserUUID = this.props.otherUserDealRequest.ownerUUID;
    let otherUserShowUUIDs = get(this.props.publicShowUUIDsByUser, otherUserUUID, []);
    return filter(otherUserShowUUIDs.map((uuid) => get(this.props.publicShows, uuid)));
  };

  audioUploadPromise = (formData) => {
    if (get(formData, "contentFile.0")) {
      return this.props
        .fileUpload(
          get(formData, "contentFile.0"),
          get(formData, "contentFile.0.name"),
          this.props.user.user.authToken,
          (e) => this.props.uploadProgress(e.loaded / e.total)
        )
        .then((response) => {
          let mediaFileUUIDs = {};
          if (response.status === 200) {
            response.json.forEach((item) => {
              mediaFileUUIDs[String(item.bitrate)] = item.uuid;
            });
          }
          return {
            status: response.status,
            mediaFileUUIDs,
          };
        });
    }
    return Promise.resolve({ status: 200, mediaFileUUIDs: formData.mediaFileUUIDs });
  };

  submitForm = (formData, props) => {
    let dealContent = this.props.currentUserDealRequest.dealContents[0];
    this.audioUploadPromise(formData).then((response) => {
      if (response.status !== 200) {
        return;
      }
      let payload = {
        ownerUUID: this.props.user.user.uuid,
        dealContents: [
          {
            ...dealContent,
            type: "cross_promo",
            mediaFileUUIDs: response.mediaFileUUIDs,
            airsAt: Math.round(formData.airsAt.valueOf() / 1000),
          },
        ],
      };
      this.props.updateDeal(this.props.deal.uuid, payload).then((response) => {
        if (response.status === 200) {
          this.props.hideModal();
        }
      });
    });
  };
}

export default connect(
  ({ mediaFiles, user, mediaFileUpload, publicShows }) => ({
    user,
    mediaFiles,
    progress: mediaFileUpload.progress,
    isPutting: mediaFileUpload.isLoading,
    publicShowUUIDsByUser: publicShows.userIndexes,
    publicShows: publicShows,
    isLoadingPublicShows: publicShows.isLoading,
  }),
  {
    hideModal,
    fileUpload: (file, fileName, authToken, progressCallback) =>
      new UploadModalActionManager({
        file,
        fileName,
        authToken,
        progressCallback,
      }).run(),
    uploadProgress: (progress) => uploadModalProgress(progress),
    fetchPublicShowsForUser,
    updateDeal,
    fetchMediaFile: (mediaFileUUID, authToken) => {
      if (mediaFileUUID) {
        return new MediaFileActionManager({ mediaFileUUID, authToken }).run();
      }
    },
  }
)(DealRequestModal);
