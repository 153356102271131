import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { EmailVerificationManager } from "../../../action_managers/email_verification";
import { withPageTitle } from "../../lib/WithPageTitle";
import EmailVerificationPage from "./email_verification_page";

function mapStateToProps(state) {
  return {
    user: state.user.user,
    isLoading: state.emailVerification.isLoading,
    pushToHome: state.emailVerification.pushToHome,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyEmail: (verificationToken, authToken) => {
      dispatch(
        new EmailVerificationManager({
          verificationToken,
          authToken,
        }).run()
      );
    },
  };
}

const EmailVerificationPageWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmailVerificationPage)
);

export default withPageTitle(EmailVerificationPageWrapper, "Email Verification");
