import getAPIPrefix from "../lib/api_prefix";

function getMediaFile(uuid, authToken) {
  return fetch(getAPIPrefix() + "media-files/" + uuid, {
    method: "get",
    headers: {
      Authorization: "Bearer " + authToken,
    },
  });
}

function uploadFile(file, fileName, authToken, progressCallback) {
  // We create a fetch API-like promise using an XHR in order to be able to
  // use the progress api.
  return new Promise((resolve, reject) => {
    var data = new FormData();
    data.append("file", file, fileName);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", getAPIPrefix() + "media-files");
    xhr.setRequestHeader("Authorization", "Bearer " + authToken);
    if (progressCallback) {
      xhr.upload.onprogress = progressCallback;
    }

    xhr.onload = () => {
      resolve({
        status: xhr.status,
        json: () =>
          new Promise((jsonResolve, jsonReject) => {
            try {
              jsonResolve(JSON.parse(xhr.response));
            } catch {
              jsonReject("unable to parse JSON: " + xhr.response);
            }
          }),
      });
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(data);
  });
}

const mediaFileAPI = {
  getMediaFile,
  uploadFile,
};

export default mediaFileAPI;
