import React from "react";
import { Link } from "react-router-dom";
import { ShowAlbumArtWithNotification } from "src/components/lib/album_art";
import ExclusiveTag from "src/components/lib/exclusive_tag";
import { ADVERTISING_OPT_IN_THRESHOLD } from "src/constants/misc";
import { IShow } from "redcircle-types";

export default function ShowListRAP({ shows }: { shows: IShow[] }) {
  return (
    <div className="show-list">
      {shows.map((show) => {
        let tag;

        const optedIn =
          Boolean(show?.advertisementSettings?.isHostReadEnabled) ||
          Boolean(show?.programmaticAdSettings?.enabled);

        if (optedIn) {
          tag = (
            <ExclusiveTag
              disabled={false}
              className="album-art-tag"
              color="#417505"
              fontSize="8px"
              backgroundRGB={[255, 255, 255]}
              backgroundOpacity={0.9}>
              <b>OPTED IN</b>
            </ExclusiveTag>
          );
        } else if (show.estimatedWeeklyDownloads >= ADVERTISING_OPT_IN_THRESHOLD || show.isRemote) {
          tag = (
            <ExclusiveTag
              disabled={false}
              className="album-art-tag"
              fontSize="8px"
              backgroundRGB={[255, 255, 255]}
              backgroundOpacity={0.9}>
              <b>ELIGIBLE TO OPT IN</b>
            </ExclusiveTag>
          );
        } else {
          tag = (
            <ExclusiveTag
              disabled={false}
              className="album-art-tag"
              fontSize="8px"
              backgroundRGB={[255, 255, 255]}
              backgroundOpacity={0.9}>
              <b>NOT ELIGIBLE TO OPT IN</b>
            </ExclusiveTag>
          );
        }

        return (
          <Link to={`/shows/${show.uuid}/advertising`} key={show.uuid} className="m-rxs m-bxl">
            <ShowAlbumArtWithNotification show={show} tag={tag} />
          </Link>
        );
      })}
    </div>
  );
}
