import { Presets } from "src/reducers/permissions/types";

export const permissionTypes = {
  // CreateShow means a user can view object
  createShow: "createShow",
  // EditShow means a user can edit object
  editShow: "editShow",
  // ViewShow means a user can view a show
  viewShow: "viewShow",
  // RedirectShow means a user can redirect a show
  redirectShow: "redirectShow",
  // ViewEpisodes means a user can see a show's episodes
  viewEpisodes: "viewEpisodes",
  // EditEpisodesDraft means a user can edit episodes for a show
  editEpisodesDraft: "editEpisodesDraft",
  // EditEpisodes means a user can edit episodes for a show
  editEpisodes: "editEpisodes",
  // DeleteEpisodes means a user can edit episodes for a show
  deleteEpisodes: "deleteEpisodes",
  // EditMarkers means a user can edit markers
  editMarkers: "editMarkers",
  // EmbedPlayer means a user can see embeddable player code
  embedPlayer: "embedPlayer",
  // EditDistribution means a user can edit distributions for a show
  editDistribution: "editDistribution",
  // ViewDistribution means a user can view distribuition for a show
  viewDistribution: "viewDistribution",
  /*
Monetization Capabilities
*/

  // EditExclusiveContent means a user can Enable/Edit/Disable Exclusive Content
  editExclusiveContent: "editExclusiveContent",
  // ViewExclusiveContent means a user can View Exclusive Content
  viewExclusiveContent: "viewExclusiveContent",
  // ExportSubscribers means a user can Export Exclusive Content Subscribers
  exportSubscribers: "exportSubscribers",
  // EditDonations means a user can Enable/Edit/Disable Donations
  editDonations: "editDonations",
  // ViewDonations means a user can View Donations
  viewDonations: "viewDonations",
  // EditRAP means a user can Enable/Edit/Disable/Respond/Upload RAP
  editRap: "editRap",
  // ViewRap means a user can View RAP
  viewRap: "viewRap",
  /*
Cross Promo
*/

  // EditCrossPromo means a user can Enable/Request/Respond Cross Promotions
  editCrossPromo: "editCrossPromo",
  // ViewCrossPromo means a user can View Cross Promotions
  viewCrossPromo: "viewCrossPromo",
  /*
Dynamic Insertion Capabilities
*/

  // CreateAudioBlock means a user can Create Audio Blocks
  createAudioBlock: "createAudioBlock",
  // EditAudioBlock means a user can Edit/Delete Audio Blocks*
  editAudioBlock: "editAudioBlock",
  // BulkAssignAudioBlock means a user can Bulk Assign Audio Blocks across Podcast
  bulkAssignAudioBlock: "bulkAssignAudioBlock",
  // ViewDynamicInsertion means a user can View Dynamic Insertion
  viewDynamicInsertion: "viewDynamicInsertion",
  /*Reporting Capabilities*/

  // ViewStats means a user can View Downloads Analytics
  viewStats: "viewStats",
  // ViewRevenueStats means a user can View Revenue Analytics
  viewRevenueStats: "viewRevenueStats",
  // ViewDynamicInsertionStats means a user can View Dynamic Insertion Analytics
  viewDynamicInsertionStats: "viewDynamicInsertionStats",
  // ExportStats means a user can Export Analytics
  exportStats: "exportStats",
  /*
Account Capabilities
*/

  // UpdateBilling means a user can
  updateBilling: "updateBilling",
  // ConnectBank means a user can
  connectBank: "connectBank",
  // TransferFunds means a user can
  transferFunds: "transferFunds",
  // ViewMoney means a user can
  viewMoney: "viewMoney",

  //View permissions
  viewPermissions: "viewPermissions",
  // Edit permissions
  editPermissions: "editPermissions",

  /**
   * Campaign permissions types
   */

  browsePodcasts: "browsePodcasts",
  viewCampaign: "viewCampaign",
  editCampaign: "editCampaign",
  viewCampaignReporting: "viewCampaignReporting",
  sendCampaign: "sendCampaign",
  negotiateRates: "negotiateRates",
  organizeCampaigns: "organizeCampaigns",
} as const;

export type AdvertiserPermissionTypes = Extract<
  keyof typeof permissionTypes,
  | "browsePodcasts"
  | "viewCampaign"
  | "editCampaign"
  | "viewCampaignReporting"
  | "sendCampaign"
  | "negotiateRates"
  | "organizeCampaigns"
  | "viewPermissions"
  | "editPermissions"
>;

export const permissionPresets: Record<
  Exclude<Presets, "audioBlockOwner" | "notSet">,
  Exclude<Presets, "audioBlockOwner" | "notSet">
> = {
  // PermissionPresetOrgAdmin indicates that the user has all permissions for this object
  orgAdmin: "orgAdmin",

  // PermissionPresetShowAdmin indicates that the user has all permissions for this object
  showAdmin: "showAdmin",

  // PermissionPresetShowContributor indicates that the user has all permissions for this object
  showContributor: "showContributor",

  // PermissionPresetShowEditor indicates that the user has all permissions for this object
  showEditor: "showEditor",

  // PermissionPresetShowViewer indicates that the user has all permissions for this object
  showViewer: "showViewer",

  // PermissionPresetNoAccess indicates that the user should not have access
  noAccess: "noAccess",
  // PermissionPresetShowReporting indicates that the user has all permissions for this object
  // showReporting: "showReporting",

  // Campaign permissions
  campaignAdmin: "campaignAdmin",
  campaignEditor: "campaignEditor",
  campaignViewer: "campaignViewer",
} as const;

export const contactOwnerCopy =
  "Please contact your Org Owner to update your access to this feature.";

export type FakeTypesForUI = "inviteTeamSeat" | "editTeamSeatPerm";
export type UIPermissionsTypes = keyof typeof permissionTypes | FakeTypesForUI;

export const creatorRoleMapping = {
  [permissionPresets.showAdmin]: "Admin",
  [permissionPresets.showContributor]: "Contributor",
  [permissionPresets.showEditor]: "Editor",
  [permissionPresets.showViewer]: "Viewer",
  [permissionPresets.noAccess]: "No Access",
} as const;

export const roleToFriendly: Record<Presets, string> = {
  showAdmin: "Admin",
  showContributor: "Contributor",
  showEditor: "Editor",
  showViewer: "Viewer",
  noAccess: "No Access",
  campaignAdmin: "Admin",
  campaignEditor: "Editor",
  campaignViewer: "Viewer",
  audioBlockOwner: "",
  orgAdmin: "",
  notSet: "Permission Not Set",
};

export const advertiserRoleMapping = {
  [permissionPresets.campaignAdmin]: "Admin",
  [permissionPresets.campaignEditor]: "Editor",
  [permissionPresets.campaignViewer]: "Viewer",
  [permissionPresets.noAccess]: "No Access",
} as const;

export type CreatorAllowedRoles = keyof typeof creatorRoleMapping;
export type AdvertiserAllowedRoles = keyof typeof advertiserRoleMapping;

/**
 * Permissions Config Data
 */

export const creatorPermissionCategories = [
  "Podcast",
  "Monetization",
  "Cross Promotions",
  "Dynamic Insertion",
  "Reporting",
  "Account",
] as const;

export const advertiserPermissionCategories = ["Campaign"] as const;

export type PermissionSections =
  | (typeof creatorPermissionCategories)[number]
  | (typeof advertiserPermissionCategories)[number];

/**
 * TODO - Need to transfer this logic to BE, backend currently only
 * provides verification if a permission type exists within preset role,
 * it needs to provide on default state and ability to be modified
 */
export const permissionsConfigMap: Record<
  UIPermissionsTypes,
  { name: string; category: PermissionSections } & Record<
    Presets,
    { defaultState: boolean; canBeModified: boolean; hide?: boolean } | null
  >
> = {
  // CreateShow means a user can view object
  createShow: {
    name: "Create/Import Podcasts",
    category: "Account",
    orgAdmin: null,
    showAdmin: { defaultState: false, canBeModified: false },
    showContributor: { defaultState: false, canBeModified: false },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // ViewShow means a user can view a show
  viewShow: {
    name: "View Podcast",
    category: "Podcast",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: true, canBeModified: false },
    showViewer: { defaultState: true, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // EditShow means a user can edit object
  editShow: {
    name: "Edit Podcast",
    category: "Podcast",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: true, canBeModified: true },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // RedirectShow means a user can redirect or delete a show
  redirectShow: {
    name: "Redirect/Delete Podcast",
    category: "Podcast",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: true },
    showContributor: { defaultState: false, canBeModified: false },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // ViewEpisodes means a user can see a show's episodes
  viewEpisodes: {
    name: "View Episodes",
    category: "Podcast",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: true, canBeModified: false },
    showViewer: { defaultState: true, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // EditEpisodesDraft means a user can edit episodes for a show
  editEpisodesDraft: {
    name: "Create/Edit Episode Drafts",
    category: "Podcast",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: true, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // EditEpisodes means a user can edit episodes for a show
  editEpisodes: {
    name: "Publish Episodes",
    category: "Podcast",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: true, canBeModified: true },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // DeleteEpisodes means a user can edit episodes for a show
  deleteEpisodes: {
    name: "Delete Episodes",
    category: "Podcast",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: true },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // EditMarkers means a user can edit markers
  editMarkers: {
    name: "Configure Episode Insertion Points",
    category: "Podcast",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: true, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // EmbedPlayer means a user can see embeddable player code
  embedPlayer: {
    name: "Embeddable Player",
    category: "Podcast",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: true, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // EditDistribution means a user can edit distributions for a show
  editDistribution: {
    name: "Edit Distribution",
    category: "Podcast",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: true },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // ViewDistribution means a user can view distribution for a show
  viewDistribution: {
    name: "View Distribution",
    category: "Podcast",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  /*
Monetization Capabilities
*/

  // ViewExclusiveContent means a user can View Exclusive Content
  viewExclusiveContent: {
    name: "View Exclusive Content",
    category: "Monetization",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: true, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // EditExclusiveContent means a user can Enable/Edit/Disable Exclusive Content
  editExclusiveContent: {
    name: "Enable/Edit/Disable Exclusive Content",
    category: "Monetization",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: true },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },

  // ExportSubscribers means a user can Export Exclusive Content Subscribers
  exportSubscribers: {
    name: "Export Exclusive Content Subscribers",
    category: "Monetization",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: true },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // ViewDonations means a user can View Donations
  viewDonations: {
    name: "View Donations",
    category: "Monetization",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: true, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // EditDonations means a user can Enable/Edit/Disable Donations
  editDonations: {
    name: "Enable/Edit/Disable Donations",
    category: "Monetization",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: true },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // ViewRap means a user can View RAP
  viewRap: {
    name: "View RedCircle Ad Platform (RAP)",
    category: "Monetization",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: true, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // EditRAP means a user can Enable/Edit/Disable/Respond/Upload RAP
  editRap: {
    name: "Edit RedCircle Ad Platform (RAP)",
    category: "Monetization",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: true },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },

  /*
Cross Promo
*/

  // EditCrossPromo means a user can Enable/Request/Respond Cross Promotions
  editCrossPromo: {
    name: "View Cross Promotions",
    category: "Cross Promotions",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: true, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // ViewCrossPromo means a user can View Cross Promotions
  viewCrossPromo: {
    name: "Edit Cross Promotions",
    category: "Cross Promotions",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: true },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  /*
Dynamic Insertion Capabilities
*/

  // ViewDynamicInsertion means a user can View Dynamic Insertion
  viewDynamicInsertion: {
    name: "View Dynamic Insertion",
    category: "Dynamic Insertion",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: true, canBeModified: false },
    showViewer: { defaultState: true, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // CreateAudioBlock means a user can Create Audio Blocks
  createAudioBlock: {
    name: "Create Audio Files & Audio Blocks",
    category: "Dynamic Insertion",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: true },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // EditAudioBlock means a user can Edit/Delete Audio Blocks*
  editAudioBlock: {
    name: "Edit/Delete Audio Files & Audio Blocks",
    category: "Dynamic Insertion",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: true },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // BulkAssignAudioBlock means a user can Bulk Assign Audio Blocks across Podcast
  bulkAssignAudioBlock: {
    name: "Bulk Assign Audio Blocks",
    category: "Dynamic Insertion",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: true },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  /*Reporting Capabilities*/

  // ViewStats means a user can View Downloads Analytics
  viewStats: {
    name: "View Download Analytics",
    category: "Reporting",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: true, canBeModified: true },
    showViewer: { defaultState: true, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // ViewRevenueStats means a user can View Revenue Analytics
  viewRevenueStats: {
    name: "View Revenue Analytics",
    category: "Reporting",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // ViewMoney means a user can
  viewMoney: {
    name: "View Revenue Analytics & Money",
    category: "Reporting",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: true },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: true, canBeModified: true },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // ViewDynamicInsertionStats means a user can View Dynamic Insertion Analytics
  viewDynamicInsertionStats: {
    name: "View Dynamic Insertion Analytics",
    category: "Reporting",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: { defaultState: true, canBeModified: false },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: true, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // ExportStats means a user can Export Analytics
  exportStats: {
    name: "Export Analytics",
    category: "Reporting",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  /*
Account Capabilities
*/

  // UpdateBilling means a user can
  updateBilling: {
    name: "Update Account Plan/Billing",
    category: "Account",
    orgAdmin: null,
    showAdmin: { defaultState: false, canBeModified: false },
    showContributor: { defaultState: false, canBeModified: false },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // ConnectBank means a user can
  connectBank: {
    name: "Connect Bank Account",
    category: "Account",
    orgAdmin: null,
    showAdmin: { defaultState: false, canBeModified: false },
    showContributor: { defaultState: false, canBeModified: false },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // TransferFunds means a user can
  transferFunds: {
    name: "Transfer Funds to Bank",
    category: "Account",
    orgAdmin: null,
    showAdmin: { defaultState: false, canBeModified: false },
    showContributor: { defaultState: false, canBeModified: false },
    showEditor: { defaultState: false, canBeModified: false },
    showViewer: { defaultState: false, canBeModified: false },
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  //View permissions
  viewPermissions: {
    name: "View Permissions",
    category: "Account",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },
  // Edit permissions
  editPermissions: {
    name: "Edit Permissions",
    category: "Account",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },

  editTeamSeatPerm: {
    name: "Edit Permissions for a Team Member",
    category: "Account",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },

  inviteTeamSeat: {
    name: "Invite/Delete Team Member from Org",
    category: "Account",
    orgAdmin: null,
    showAdmin: { defaultState: true, canBeModified: false },
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: null,
    campaignEditor: null,
    campaignViewer: null,
    audioBlockOwner: null,
  },

  /**
   * Campaign permissions types
   */

  browsePodcasts: {
    name: "Browse Podcasts",
    category: "Campaign",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: { defaultState: true, canBeModified: false },
    campaignEditor: { defaultState: true, canBeModified: false },
    campaignViewer: { defaultState: false, canBeModified: false },
    audioBlockOwner: null,
  },
  viewCampaign: {
    name: "View Campaign",
    category: "Campaign",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: { defaultState: true, canBeModified: false },
    campaignEditor: { defaultState: true, canBeModified: false },
    campaignViewer: { defaultState: true, canBeModified: false },
    audioBlockOwner: null,
  },
  editCampaign: {
    name: "Edit Campaign",
    category: "Campaign",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: { defaultState: true, canBeModified: false },
    campaignEditor: { defaultState: true, canBeModified: false },
    campaignViewer: { defaultState: false, canBeModified: false },
    audioBlockOwner: null,
  },
  viewCampaignReporting: {
    name: "View Campaign Reporting",
    category: "Campaign",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: { defaultState: true, canBeModified: false },
    campaignEditor: { defaultState: true, canBeModified: false },
    campaignViewer: { defaultState: true, canBeModified: true },
    audioBlockOwner: null,
  },
  sendCampaign: {
    name: "Send Campaign",
    category: "Campaign",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: { defaultState: true, canBeModified: false },
    campaignEditor: { defaultState: false, canBeModified: false },
    campaignViewer: { defaultState: false, canBeModified: false },
    audioBlockOwner: null,
  },
  negotiateRates: {
    name: "Negotiate Rates",
    category: "Campaign",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: { defaultState: true, canBeModified: false },
    campaignEditor: { defaultState: true, canBeModified: true },
    campaignViewer: { defaultState: false, canBeModified: false },
    audioBlockOwner: null,
  },
  organizeCampaigns: {
    name: "Organize Campaigns",
    category: "Campaign",
    orgAdmin: null,
    showAdmin: null,
    showContributor: null,
    showEditor: null,
    showViewer: null,
    noAccess: null,
    campaignAdmin: { defaultState: true, canBeModified: false },
    campaignEditor: { defaultState: false, canBeModified: false },
    campaignViewer: { defaultState: false, canBeModified: false },
    audioBlockOwner: null,
  },
};
