import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import { GET_SAMPLE_AD_READ } from "../../action_managers/campaigns";
import {
  getPublicShowsByUUIDs,
  getPublicShowsForSubscriberActionName,
  publicShowActionName,
} from "../../action_managers/public_show";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { AdRead, PublicShow } from "redcircle-types";
import { PublicShowsReduxState } from "./types";

const initialState: PublicShowsReduxState = {
  isLoading: false,
  userIndexes: {},
  adReadsByShowUUID: {},
};

const publicShowReducer = httpReducer<PublicShowsReduxState>(publicShowActionName, initialState, {
  success: (state, action) => {
    const newState = cloneDeep(state);
    if (get(action, "data.userUUID")) {
      newState.userIndexes[action.data.userUUID as string] = action.payload.resp.map(
        (item: PublicShow) => item.uuid
      ) as string[];

      action.payload.resp.forEach((show: PublicShow) => {
        newState[show.uuid] = show;
      });
    } else {
      newState[action.payload.resp.uuid] = action.payload.resp;
    }
    return newState;
  },
});

const subscribedShowReducer = httpReducer<PublicShowsReduxState>(
  getPublicShowsForSubscriberActionName,
  initialState,
  {
    success: (state, action) => ({
      ...state,
      [action.payload.resp.uuid as string]: action.payload.resp,
    }),
  }
);

const fetchPublicShowsByUUIDs = httpReducer(getPublicShowsByUUIDs, initialState, {
  success: (state, action) => ({
    ...state,
    ...keyBy(action.payload.resp, "uuid"),
  }),
});

const getSampleAdReads = httpReducer<PublicShowsReduxState>(GET_SAMPLE_AD_READ, initialState, {
  success: (state, action) => ({
    ...state,
    adReadsByShowUUID: {
      ...state.adReadsByShowUUID,
      [action.data.showUUID as string]: action.payload.resp as AdRead[],
    },
  }),
});

export default reduceReducers(
  publicShowReducer,
  subscribedShowReducer,
  fetchPublicShowsByUUIDs,
  getSampleAdReads
);
