import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { matchPath, Redirect } from "react-router-dom";
import Spinner from "../../lib/spinner";

class EmailVerificationPage extends Component {
  componentDidMount() {
    var result = matchPath(this.props.location.pathname, "/verify-email/:verificationToken");

    if (result) {
      this.props.verifyEmail(result.params.verificationToken, this.props.user.authToken);
    }
  }

  render() {
    if (this.props.pushToHome) {
      return <Redirect to="/" />;
    }

    return (
      <Grid fluid>
        <Row className="email-verification-row">
          <Col xs={12} md={6} mdOffset={3} className="text-center text-danger">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <Spinner isLoading={this.props.isLoading} disableBackground={true} />
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            {this.props.errorMessage && <p>{this.props.errorMessage} </p>}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default EmailVerificationPage;
