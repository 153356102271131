import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  formatAsYouType,
  isCorrectTimeFormat,
  transformTimeNumToString,
  transformTimeStringToNum,
  zeroTimeInSeconds,
} from "src/lib/duration";
import classes from "./time_formatter.module.scss";

export interface ITimeFormatter
  extends Omit<React.HTMLProps<HTMLInputElement>, "onTimeUpdate" | "onChange"> {
  timeInMilliseconds: number;
  showMilliseconds?: boolean;
  allowInPlaceDeletion?: boolean;
  separator?: string;
  allowOverflow?: boolean; // allow the user to overflow a time slot, the logic will recalculate this format it correctly to the next time  slot (i.e 90 miuntes -> 1 hour 30 minutes)
  max?: number;
  editable?: boolean;
  disabled?: boolean;
  className?: string;
  onTimeUpdate?: (timeInMilliseconds: number) => void;
  onManualInputUpdate?: (props: {
    inputText: string;
    timeInMilliseconds: number | null;
    isCorrectFormat: boolean;
  }) => void;
}

const TimeFormatter = ({
  timeInMilliseconds = 0,
  showMilliseconds = true,
  allowInPlaceDeletion,
  separator = ":",
  allowOverflow = false,
  max = Number.POSITIVE_INFINITY,
  editable = true,
  disabled,
  className = "",
  onTimeUpdate,
  onManualInputUpdate,
  onKeyDown,
  ...rest
}: ITimeFormatter) => {
  /**
   * Separating value state in 2 phases. time phase -> text phase. 2 flows
   *
   * 1rst Flow:
   *
   * - Input is changed as string from user
   * - correct time string format check PASSED
   * - transform time string to millisecond and update currentTime
   * - event handlers on time update fire
   * - transform currentTime to TimeString and update inputText
   * - rerender component
   *
   * 2nd Flow:
   *
   * - Input is changed as string from user
   * - correct time string format check FAILED
   * - No update to currentTime local time state
   * - update input val with incorrect format to allow user to finish typing
   * - rerender component
   *
   *
   */
  const [currentTime, setCurrentTime] = useState(timeInMilliseconds);
  const [inputText, setInputText] = useState(
    transformTimeNumToString(timeInMilliseconds, showMilliseconds)
  );

  // Manual flag to kick of useLayoutEffect on inputtext update
  const [flag, setFlag] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const cursorStart = useRef<number>(0);
  const cursorEnd = useRef<number>(0);

  /**
   * Ensures cursor/selection position is maintained throughout text value rerender updates
   */

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.selectionStart = cursorStart.current;
      inputRef.current.selectionEnd = cursorEnd.current;
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.selectionStart = cursorStart.current;
        inputRef.current.selectionEnd = cursorEnd.current;
      }
    };
  }, [currentTime, setCurrentTime, inputText, setInputText, timeInMilliseconds, flag]);

  /**
   * Syncs time with parent prop. making this a controlled component
   */
  useEffect(() => {
    if (timeInMilliseconds !== currentTime || timeInMilliseconds > max) {
      setCurrentTime(Math.min(timeInMilliseconds, max));
    }
    setFlag((prev) => !prev);
  }, [timeInMilliseconds, max]);

  // Syncs current time with shown text value
  useEffect(() => {
    setInputText(transformTimeNumToString(currentTime, showMilliseconds));
  }, [currentTime, timeInMilliseconds]);

  /**
   * Syncs parent onTimeUpdate handlers with local state
   */
  useEffect(() => {
    onTimeUpdate && onTimeUpdate(currentTime);
  }, [currentTime]);

  /**
   *  Syncs parent onManualInputUpdate handler with local text state
   */
  useEffect(() => {
    onManualInputUpdate?.({
      inputText,
      timeInMilliseconds: isCorrectTimeFormat(inputText, showMilliseconds)
        ? transformTimeStringToNum(inputText, showMilliseconds, allowOverflow)
        : null,
      isCorrectFormat: isCorrectTimeFormat(inputText, showMilliseconds),
    });
  }, [inputText]);

  const placeHolder = showMilliseconds
    ? zeroTimeInSeconds.join(separator) + ".000"
    : zeroTimeInSeconds.join(separator);

  /**
   * Handlers
   */

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const timeString = event.target.value;

    const target = event.target as any;

    if (typeof target.selectionStart === "number") cursorStart.current = target.selectionStart;
    if (typeof target.selectionEnd === "number") cursorEnd.current = target.selectionEnd;

    if (isCorrectTimeFormat(timeString, showMilliseconds) && editable) {
      const newTime = Math.min(
        transformTimeStringToNum(timeString, showMilliseconds, allowOverflow),
        max
      );

      if (newTime !== currentTime) {
        setCurrentTime(newTime);
      } else {
        setInputText(transformTimeNumToString(newTime, showMilliseconds));
      }
    } else {
      // If initial input is incorrect format apply checks and allow user to update local statetext but not actualy time state
      const { newTimeString: newVal, newCursorPosition } = formatAsYouType({
        timeString,
        separator,
        isMilliseconds: showMilliseconds,
        cursorPosition: cursorStart.current,
        allowInPlaceDeletion,
      });

      cursorStart.current = newCursorPosition;
      cursorEnd.current = newCursorPosition;

      // User input data post format passes check to update state
      if (isCorrectTimeFormat(newVal, showMilliseconds)) {
        const newTime = Math.min(
          transformTimeStringToNum(newVal, showMilliseconds, allowOverflow),
          max
        );

        if (newTime !== currentTime) {
          setCurrentTime(newTime);
        } else {
          setInputText(newVal);
        }
      } else {
        setInputText(newVal);
      }
    }

    setFlag((prev) => !prev);
  };

  const HandlerKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as any;
    if (typeof target.selectionStart === "number") cursorStart.current = target.selectionStart;
    if (typeof target.selectionEnd === "number") cursorEnd.current = target.selectionEnd;

    setFlag((prev) => !prev);
    onKeyDown?.(event);

    if (!editable) return;

    const interval = getAmountFromCursorPos(cursorStart.current);
    switch (event?.key) {
      case "ArrowDown":
        event.preventDefault();
        setCurrentTime((prev) => Math.max(prev - interval, 0));

        break;
      case "ArrowUp":
        event.preventDefault();
        setCurrentTime((prev) => Math.min(prev + interval, max));

        break;

      default:
    }
  };

  const pattern = showMilliseconds
    ? `^[0-9]{2}${separator}[0-9]{2}${separator}[0-9]{2}.[0-9]{3}$`
    : `^[0-9]{2}${separator}[0-9]{2}${separator}[0-9]{2}$`;

  return (
    <input
      {...rest}
      ref={inputRef}
      data-format={`${showMilliseconds ? "milliseconds" : "seconds"}`}
      className={`${classes.input} ${className}`}
      type="text"
      disabled={disabled}
      placeholder={placeHolder}
      value={inputText}
      onChange={handleValueChange}
      onKeyDown={HandlerKeyDown}
      pattern={pattern}
      data-testid="timeFormatter"
    />
  );
};

const getAmountFromCursorPos = (pos: number) => {
  const OneSecond = 1000;
  const OneMinute = 1000 * 60;
  const oneHour = 1000 * 60 * 60;

  if (pos <= 2) {
    return oneHour;
  } else if (pos >= 3 && pos <= 5) {
    return OneMinute;
  } else if (pos >= 6 && pos <= 8) {
    return OneSecond;
  } else {
    return 1;
  }
};

export default TimeFormatter;
