import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { adminLoginAs } from "../../../actions/admin_login_as";
import { SignOutActionManager } from "../../../action_managers/account";
import AdminSignInAs from "./admin_sign_in_as";

function mapStateToProps(state) {
  return {
    isLoading: state.user.isLoading,
    user: state.user.user,
  };
}

const mapDispatchToProps = {
  loginAs: adminLoginAs,
  logout: (token, signOutRedirect) => {
    return new SignOutActionManager({ token, signOutRedirect }).run();
  },
};

const AdminSignInAsWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminSignInAs)
);

export default AdminSignInAsWrapper;
