import get from "lodash/get";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { If } from "react-extras";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { connect } from "react-redux";
import { resetCrop, updateCrop } from "../../../actions/image-uploader";
import { MediaFileActionManager } from "../../../action_managers/shows";
import uploadIcon from "../../../icons/upload-icon-large.svg";
import { getCroppedImg, isMobile } from "../../../lib/image-uploader";
import "./image-uploader.scss";

const minImagesSide = 1400;
const maxImageSide = 3000;

class ImageUploader extends Component {
  state = {
    fetchedInitial: false,
    imagePreview: null,
  };

  componentDidMount() {
    this.props.resetCrop();
    this.setExistingImage(this.props);
    if (
      this.props.mediaFileUUID &&
      !get(this.props, ["mediaFiles", this.props.mediaFileUUID, "uuid"])
    ) {
      this.props.fetchMediaFile(this.props.mediaFileUUID);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setExistingImage(nextProps);
  }

  setExistingImage = (props) => {
    if (props.mediaFileUUID && !this.state.fetchedInitial) {
      this.setState({ fetchingInitial: true });
    }
    if (
      props.mediaFileUUID &&
      get(props, ["mediaFiles", props.mediaFileUUID, "uuid"]) &&
      !this.state.fetchedInitial
    ) {
      this.fetchingInitial(props);
    }
  };

  fetchingInitial = (props) => {
    this.setState({ fetchedInitial: true });
    fetch(props.mediaFiles[props.mediaFileUUID].url + "?random=thing").then((response) => {
      response.blob().then((data) => {
        this.setState({ initialOnLoads: 2 });
        this.handleInput([data], { skipValidation: true });
      });
    });
  };
  getImageSize = (imageURL) => {
    return new Promise((resolve, reject) => {
      var image = new Image();
      image.onload = () => {
        resolve({
          width: image.width,
          height: image.height,
        });
      };
      image.src = imageURL;
    });
  };

  handleInput = async (file, opts) => {
    // onUpload() should only be called when an image is actually uploaded. handleInput is called when a page
    // is refreshed and an image is loaded. We decrement initialLoads starts at 2 when we load the page, and decrements
    // by 2 when we upload an image.
    if (this.state.initialOnLoads <= 0 && this.props.onUpload) {
      this.props.updateCrop({ touched: true });
      this.props.onUpload();
    }

    const { skipValidation } = { skipValidation: false, ...opts };
    const imageURL = URL.createObjectURL(file[0]);
    const { width, height } = await this.getImageSize(imageURL);
    if (!skipValidation && (width < minImagesSide || height < minImagesSide)) {
      this.props.setError(
        `Your image needs to be at least 1400 x 1400. Your image is ${width} x ${height}`
      );
      return;
    }

    const maxDimension = Math.min(maxImageSide, width, height);
    const minHeight = (minImagesSide / height) * 100;
    const minWidth = (minImagesSide / width) * 100;
    const crop = {
      ...this.props.imageUploader.crop,
      x: 0,
      y: 0,
      width: Math.min(100, (maxDimension / width) * 100),
      height: Math.min(100, (maxDimension / height) * 100),
    };
    const pixelCrop = {
      x: 0,
      y: 0,
      width: maxDimension,
      height: maxDimension,
    };
    const fileName = get(file, [0, "name"]);
    this.props.updateCrop({
      crop,
      fileName,
      pixelCrop,
      minHeight: minHeight,
      minWidth: minWidth,
      maxHeight: (maxImageSide / height) * 100,
      maxWidth: (maxImageSide / width) * 100,
      imageURL,
      originalDimentions: {
        width,
        height,
      },
      showCrop: true,
      touched: true,
    });
  };

  reset = () => {
    this.props.resetCrop();
    this.props.updateCrop({ touched: true });
    this.props.setImageFileUUID(null);
  };

  onImageLoad = (image) => {
    this.props.updateCrop({ image });
    this.handleCropComplete(this.props.imageUploader.crop, this.props.imageUploader.pixelCrop);
    this.setState({ fetchingInitial: false });
  };

  handleCropComplete = (crop, pixelCrop) => {
    if (this.state.initialOnLoads <= 0) {
      // We want to prevent setting this data if the image
      // wasn't touched. Due to https://github.com/DominicTobias/react-image-crop/issues/107
      // the onLoad handler is called 2 times, so we wait until
      // that happens twice before allowing new data to be set
      // after we set an existing image up.
      this.props.updateCrop({ touched: true });
    }
    this.setState({
      initialOnLoads: this.state.initialOnLoads - 1,
    });
    this.props.updateCrop({
      pixelCrop,
    });
  };

  handleChange = (crop) => {
    if (crop.width < this.props.imageUploader.minWidth) {
      crop.width = this.props.imageUploader.minWidth;
      if (this.props.imageUploader.originalDimentions.width < this.props.imageUploader.minWidth) {
        crop.x = 100 - crop.width;
      }
    }

    if (crop.height < this.props.imageUploader.minHeight) {
      crop.height = this.props.imageUploader.minHeight;
      if (this.props.imageUploader.originalDimentions.height < this.props.imageUploader.minHeight) {
        crop.y = 100 - crop.height;
      }
    }

    this.props.updateCrop({ crop: { ...this.props.imageUploader.crop, ...crop } });
  };
  render() {
    return (
      <div>
        <If condition={!this.props.imageUploader.imageURL}>
          <Dropzone onDrop={this.handleInput} accept=".jpg,.jpeg,.png">
            {({ getRootProps, getInputProps }) => {
              return (
                <div {...getRootProps()} className={"drop-zone-box"} data-cy="image-drag-and-drop">
                  <div className={"drop-zone-content"}>
                    <input {...getInputProps()} className={"drop-zone-input"} />
                    <If condition={!this.state.fetchingInitial}>
                      <img src={uploadIcon} alt="uploadIcon" className={"image-upload-icon"} />
                      <h3 className={"drop-zone-title m-ts m-b0"}>
                        Drag & Drop
                      </h3>
                      <small>or Browse</small>
                    </If>
                    <If condition={!!this.state.fetchingInitial}>Loading...</If>
                  </div>
                </div>
              );
            }}
          </Dropzone>
          <div className="image-upload__size-info">
            Images must be larger than 1400 x 1400 pixels.
          </div>
        </If>
        <If condition={!!this.props.imageUploader.imageURL}>
          <ImagePreview {...this.props.imageUploader} />
          <ReactCrop
            src={this.props.imageUploader.imageURL || ""}
            onChange={this.handleChange}
            minHeight={this.props.imageUploader.minHeight}
            maxHeight={this.props.imageUploader.maxHeight}
            minWidth={this.props.imageUploader.minWidth}
            maxWidth={this.props.imageUploader.maxWidth}
            onComplete={this.handleCropComplete}
            crop={this.props.imageUploader.crop}
            onImageLoaded={this.onImageLoad}
            imageStyle={{
              width: "100%",
              maxWidth: "308px",
            }}
          />

          <button type="button" onClick={this.reset} className="bold btn btn-link">
            <small>Delete Image</small>
          </button>
        </If>
      </div>
    );
  }
}

class ImagePreview extends Component {
  state = {
    preview: undefined,
  };

  hasNewProps = (nextProps) => {
    if (isMobile()) {
      return false;
    }
    return (
      (get(this.props, "image") !== get(nextProps, "image") && nextProps.image) ||
      this.props.pixelCrop !== nextProps.pixelCrop
    );
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.hasNewProps(nextProps) || this.state.preview !== nextState.preview;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.hasNewProps(nextProps)) {
      getCroppedImg(nextProps).then((image) => {
        this.setState({ preview: image });
      });
    }
  }
  render() {
    if (!this.state.preview || isMobile()) {
      return null;
    }
    return (
      <img
        className={"drop-zone-preview hidden-sm hidden-xs"}
        alt=""
        src={window.URL.createObjectURL(this.state.preview)}
      />
    );
  }
}

export default connect((state) => ({ imageUploader: state.imageUploader }), {
  updateCrop,
  resetCrop,
  fetchMediaFile: (mediaFileUUID) => new MediaFileActionManager({ mediaFileUUID }).run(),
})(ImageUploader);
