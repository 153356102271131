import get from "lodash/get";
import mapValues from "lodash/mapValues";
import {
  distributionsDistributeActionName,
  distributionsFetchActionName,
  distributionsPutActionName,
} from "../../action_managers/distributions";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { DistributionsReduxState } from "./types";

const initialState: DistributionsReduxState = {
  isLoading: false,
  isPutting: false,
  distributions: {},
  validationErrors: [],
};

const distributionsFetchReducer = httpReducer(distributionsFetchActionName, initialState, {
  success: (state, action) => {
    const distributions = get(action, "payload.resp.links");
    const showUUID = get(action, "payload.resp.showUUID");
    if (distributions && showUUID) {
      state = Object.assign({}, state, {
        distributions: { ...state.distributions, [showUUID]: distributions },
      });
    }
    return state;
  },
});

const distributionsPutReducer = httpReducer(
  distributionsPutActionName,
  initialState,
  {
    success: (state, action) => {
      const distributions = get(action, "payload.resp.links");
      const showUUID = get(action, "payload.resp.showUUID");
      if (distributions && showUUID) {
        state = Object.assign({}, state, {
          distributions: { ...state.distributions, [showUUID]: distributions },
          validationErrors: [],
        });
      }
      return state;
    },
    failureBadRequest: (state, action) => {
      return Object.assign({}, state, {
        validationErrors: get(action, "payload.validationErrors"),
      });
    },
  },
  {
    loadingKeyName: "isPutting",
  }
);

const distributionDistributeReducer = httpReducer(distributionsDistributeActionName, initialState, {
  success: (state, action) => {
    const showUUID = action.payload.body.showUUID;
    return {
      ...state,
      distributions: {
        ...state.distributions,
        [showUUID]: {
          ...state.distributions[showUUID],
          ...mapValues(action.payload.resp, (val) => {
            return val.deepLink;
          }),
        },
      },
    };
  },
});

export default reduceReducers(
  distributionsFetchReducer,
  distributionsPutReducer,
  distributionDistributeReducer
);
