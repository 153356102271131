import get from "lodash/get";
import isNil from "lodash/isNil";
import React, { Component } from "react";
import HighlightBox from "../highlight_box";

class KeyStatBox extends Component {
  componentDidMount() {
    this.props.fetchStats(this.props.requestID);
  }

  getStats = () => {
    var stats = get(this.props.stats, this.props.requestID);
    if (isNil(stats)) {
      return null;
    }

    return this.props.dataAccessor(stats || []);
  };

  render() {
    var stats = this.getStats();
    return <HighlightBox className="bordered" title={this.props.title} value={stats} />;
  }
}

export default KeyStatBox;
