import DefaultActionManager from "./default_action_manager";

export const GET_MARKERS_BY_EPISODE = "GET_MARKERS_BY_EPISODE";
export const FETCH_RECENT_MARKERS = "FETCH_RECENT_MARKERS";
export const FETCH_RECENT_AUDIO_BLOCKS = "FETCH_RECENT_AUDIO_BLOCKS";
export const AUDIO_BLOCK_CREATE = "AUDIO_BLOCK_CREATE";
export const AUDIO_BLOCK_UPDATE = "AUDIO_BLOCK_UPDATE";
export const AUDIO_BLOCK_GET_FOR_USER = "AUDIO_BLOCK_GET_FOR_USER";
export const AUDIO_BLOCK_GET_STATS_FOR_USER = "AUDIO_BLOCK_GET_STATS_FOR_USER";
export const AUDIO_BLOCK_GET = "AUDIO_BLOCK_GET";
export const AUDIO_BLOCK_DELETE = "AUDIO_BLOCK_DELETE";
export const MARKERS_UPDATE = "MARKERS_UPDATE";
export const GET_MARKERS_PAGE = "GET_MARKERS_PAGE";
export const CUSTOM_AUDIO_GET_FOR_USER = "CUSTOM_AUDIO_GET_FOR_USER";
export const CUSTOM_AUDIO_CREATE = "CUSTOM_AUDIO_CREATE";
export const CUSTOM_AUDIO_UPDATE = "CUSTOM_AUDIO_UPDATE";
export const CUSTOM_AUDIO_DELETE = "CUSTOM_AUDIO_DELETE";
export const CUSTOM_AUDIO_GET_AUDIO_BLOCKS = "CUSTOM_AUDIO_GET_AUDIO_BLOCKS";
export const CUSTOM_AUDIO_GET_INSERTIONS = "CUSTOM_AUDIO_GET_INSERTIONS";
export const CUSTOM_AUDIO_GET_MEDIA_FILES = "CUSTOM_AUDIO_GET_MEDIA_FILES";
export const CUSTOM_AUDIO_GET_MEDIA_FILE = "CUSTOM_AUDIO_GET_MEDIA_FILE";

export const audioBlockCreate = (name, playStyle, limit, items) =>
  new DefaultActionManager({
    method: "POST",
    actionName: AUDIO_BLOCK_CREATE,
    auth: true,
    route: "audio-blocks",
    body: {
      name,
      playStyle,
      limit,
      items,
    },
    successMessage: "Audio Block Created",
  }).run();

export const audioBlockUpdate = (uuid, name, playStyle, limit, items) =>
  new DefaultActionManager({
    method: "POST",
    actionName: AUDIO_BLOCK_UPDATE,
    auth: true,
    route: `audio-blocks/${uuid}`,
    body: {
      name,
      playStyle,
      limit,
      items,
    },
    successMessage: "Audio Block Updated",
  }).run();

export const getAudioBlocksForUser = (user) =>
  new DefaultActionManager({
    actionName: AUDIO_BLOCK_GET_FOR_USER,
    auth: true,
    route: `users/${user.uuid}/audio-blocks`,
  }).run();

export const getAudioBlockStatsForUser = (userUUID) =>
  new DefaultActionManager({
    actionName: AUDIO_BLOCK_GET_STATS_FOR_USER,
    auth: true,
    route: `users/${userUUID}/audio-blocks-stats`,
  }).run();

export const getAudioBlock = (uuid) =>
  new DefaultActionManager({
    actionName: AUDIO_BLOCK_GET,
    auth: true,
    route: `audio-blocks/${uuid}`,
    actionData: {
      uuid,
    },
  }).run();

export const fetchRecentMarkers = () =>
  new DefaultActionManager({
    actionName: FETCH_RECENT_MARKERS,
    auth: true,
    route: `markers/recent?limit=10`,
  }).run();

export const getMarkersByEpisodeUUID = (episodeUUID) => {
  return new DefaultActionManager({
    actionName: GET_MARKERS_BY_EPISODE,
    auth: true,
    route: `episodes/${episodeUUID}/markers`,
    method: "get",
    actionData: { episodeUUID },
  }).run();
};

export const updateMarkers = (markers) =>
  new DefaultActionManager({
    actionName: MARKERS_UPDATE,
    auth: true,
    route: `markers`,
    method: "POST",
    body: markers,
    successMessage: "Insertion points updated",
  }).run();

export const fetchRecentAudioBlocks = (userUUID) =>
  new DefaultActionManager({
    actionName: FETCH_RECENT_AUDIO_BLOCKS,
    auth: true,
    route: `users/${userUUID}/audio-blocks?limit=10`,
    method: "get",
  }).run();

export const deleteAudioBlock = (uuid) =>
  new DefaultActionManager({
    actionName: AUDIO_BLOCK_DELETE,
    auth: true,
    route: `audio-blocks/${uuid}`,
    method: "DELETE",
    actionData: { uuid },
    successMessage: "Audio block deleted",
  }).run();

export const getMarkersPage = (limit = 50) =>
  new DefaultActionManager({
    actionName: GET_MARKERS_PAGE,
    auth: true,
    route: `markers/recent?limit=${limit}`,
  }).run();

export const getCustomAudioForUser = (user) =>
  new DefaultActionManager({
    actionName: CUSTOM_AUDIO_GET_FOR_USER,
    auth: true,
    route: `users/${user.uuid}/custom-audio`,
  }).run();

export const createCustomAudio = (
  name,
  mediaFile,
  trackingURL,
  maxInsertions,
  startAt,
  endAt,
  isPaused
) =>
  new DefaultActionManager({
    method: "POST",
    actionName: CUSTOM_AUDIO_CREATE,
    auth: true,
    route: "custom-audio",
    body: {
      name,
      mediaFileUUID: mediaFile.uuid,
      trackingURL,
      maxInsertions,
      startAt,
      endAt,
      isPaused,
    },
    successMessage: "Custom Audio Created",
    actionData: { mediaFile },
  }).run();

export const updateCustomAudio = (
  record,
  name,
  mediaFile,
  trackingURL,
  maxInsertions,
  startAt,
  endAt,
  isPaused
) =>
  new DefaultActionManager({
    method: "PUT",
    actionName: CUSTOM_AUDIO_UPDATE,
    auth: true,
    route: `custom-audio/${record.uuid}`,
    body: {
      name,
      mediaFileUUID: mediaFile.uuid,
      trackingURL,
      maxInsertions,
      startAt,
      endAt,
      isPaused,
    },
    successMessage: "Custom Audio Updated",
    actionData: { mediaFile, insertions: record.insertions, audioBlocks: record.audioBlocks },
  }).run();

export const deleteCustomAudio = (uuid) =>
  new DefaultActionManager({
    method: "DELETE",
    actionName: CUSTOM_AUDIO_DELETE,
    auth: true,
    route: `custom-audio/${uuid}`,
    actionData: { uuid },
    successMessage: "Custom Audio Deleted",
  }).run();

export const getAudioBlocksForCustomAudios = (user, customAudios) =>
  new DefaultActionManager({
    method: "GET",
    actionName: CUSTOM_AUDIO_GET_AUDIO_BLOCKS,
    auth: true,
    route: `users/${user.uuid}/audio-blocks-by-custom-audio?customAudioUUIDs=${customAudios.map(
      (customAudio) => customAudio.uuid
    )}`,
  }).run();

export const getInsertionsForCustomAudios = (customAudios) =>
  new DefaultActionManager({
    method: "GET",
    actionName: CUSTOM_AUDIO_GET_INSERTIONS,
    auth: true,
    route: `custom-audio/insertions?customAudioUUIDs=${customAudios.map(
      (customAudio) => customAudio.uuid
    )}`,
  }).run();

export const getMediaFilesForCustomAudios = (customAudios) =>
  new DefaultActionManager({
    method: "GET",
    actionName: CUSTOM_AUDIO_GET_MEDIA_FILES,
    auth: true,
    route: `media-files/batch-get?mediaFileUUIDs=${customAudios.map(
      (customAudio) => customAudio.mediaFileUUID
    )}`,
  }).run();

export const getCustomAudioMediaFile = (customAudio) =>
  new DefaultActionManager({
    method: "GET",
    actionName: CUSTOM_AUDIO_GET_MEDIA_FILE,
    auth: true,
    route: `media-files/${customAudio.mediaFileUUID}`,
    actionData: { customAudio },
  }).run();
