export const MARKER_EDIT_OFFSET_AUDIOBLOCK = "MARKER_EDIT_OFFSET_AUDIOBLOCK";
export const MARKER_EDIT_DELETE = "MARKER_EDIT_DELETE";
export const MARKER_ADD = "MARKER_ADD";
export const MARKER_RESET_STATE = "MARKER_RESET_STATE";
export const MARKER_ADDED_TO_WAVEFORM = "MARKER_ADDED_TO_WAVEFORM";

export const editMarkerOffsetAndAudioBlock = (markerUUID, offsetMs, audioBlockUUID) => {
  return (dispatch) =>
    Promise.resolve().then(
      dispatch({
        type: MARKER_EDIT_OFFSET_AUDIOBLOCK,
        markerUUID,
        offsetMs,
        audioBlockUUID,
      })
    );
};

export const resetMarkerState = () => {
  return (dispatch) => Promise.resolve(dispatch({ type: MARKER_RESET_STATE }));
};

export const deleteMarker = (markerUUID) => {
  return {
    type: MARKER_EDIT_DELETE,
    markerUUID,
  };
};

export const addMarker = (episodeUUID, offsetMs, userUUID, bulkAdd, audioBlockUUID) => {
  // offsetMs should always be an integer
  offsetMs = Math.floor(offsetMs);

  return {
    type: MARKER_ADD,
    episodeUUID,
    offsetMs,
    userUUID,
    bulkAdd,
    audioBlockUUID,
  };
};

export const newMarkerAddedToWaveform = (markerUUID) => {
  return {
    type: MARKER_ADDED_TO_WAVEFORM,
    markerUUID,
  };
};
