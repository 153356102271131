import { ReportingPageState, createCacheKeyFromState, getGraphTotalFromData } from "./utilities";
import { useSelectorTS } from "src/hooks/redux-ts";
import { Col, Row } from "antd";
import HighlightBox from "src/components/lib/highlight_box/highlight_box";
import { formatMoney } from "redcircle-lib";
import numeral from "numeral";

export const AdvertisingReportGraphHeader = ({
  reportingState,
  showUUIDsNeeded,
}: {
  reportingState: ReportingPageState;
  showUUIDsNeeded: string[];
}) => {
  const cacheGraphInsertionDataKey = createCacheKeyFromState({
    prefix: "ARGI", // Advertising Report Graph
    state: reportingState,
    keys: ["dateRange", "campaignUUIDs", "interval", "timeZone"],
  });

  const cacheGraphSpendDataKey = createCacheKeyFromState({
    prefix: "ARGS", // Advertising Report Graph
    state: reportingState,
    keys: ["dateRange", "campaignUUIDs", "interval", "timeZone"],
  });

  const cacheData = useSelectorTS((state) => state.stats.stats);

  const isGraphSpendLoading = useSelectorTS((state) =>
    state.stats.loadingUUIDs.includes(cacheGraphSpendDataKey)
  );
  const isGraphInsertionsLoading = useSelectorTS((state) =>
    state.stats.loadingUUIDs.includes(cacheGraphInsertionDataKey)
  );

  const isTotalPodcastsLoading = useSelectorTS((state) => state.campaignItems.isLoading);

  /**
   * Helper Functions
   */

  const graphSpend = getGraphTotalFromData({
    state: reportingState,
    data: cacheData[cacheGraphSpendDataKey],
    type: "spend",
  });

  const graphInsertions = getGraphTotalFromData({
    state: reportingState,
    data: cacheData[cacheGraphInsertionDataKey],
    type: "insertion",
  });

  const totalPodcasts = showUUIDsNeeded.length;
  return (
    <>
      <Row gutter={[16, 16]} justify={"space-evenly"}>
        <Col xs={12} md={12} lg={8} xl={8}>
          <HighlightBox
            className="bordered"
            title="Graph spend"
            value={formatMoney(graphSpend)}
            isLoading={isGraphSpendLoading}
          />
        </Col>
        <Col xs={12} md={12} lg={8} xl={8}>
          <HighlightBox
            className="bordered"
            title="Graph Insertions"
            value={numeral(graphInsertions).format("0,0")}
            isLoading={isGraphInsertionsLoading}
          />
        </Col>
        <Col xs={12} md={12} lg={8} xl={8}>
          <HighlightBox
            className="bordered"
            title="Total # Podcasts"
            value={numeral(totalPodcasts).format("0,0")}
            isLoading={isTotalPodcastsLoading}
          />
        </Col>
      </Row>
    </>
  );
};
