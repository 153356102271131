import concat from "lodash/concat";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import zipWith from "lodash/zipWith";
import { default as Moment } from "moment";
import papa from "papaparse";
import { useEffect, useState } from "react";
import { classNames } from "react-extras";
import { getCampaign, getCampaignWeeklyStats } from "src/action_managers/campaigns";
import { downloadFile } from "src/components/lib/download_file";
import {
  ProductExchangeTypeInstructions,
  ProductExchangeTypeMailing,
  PromoCodeTypePodcasterUnique,
} from "src/constants/campaigns";
import { useDispatchTS } from "src/hooks/redux-ts";
import { ReactComponent as DownloadIcon } from "src/icons/download.svg";
import { getAverageCPM, getImpressionsFromBudget } from "src/lib/campaigns";
import { formatMoney } from "src/lib/format-money";
import { addressToString } from "src/lib/postal_address";
import { store } from "src";
import {
  Budget,
  CampaignItemState,
  MediaFile,
  isBudget,
  isCampaign,
  isCampaignItem,
  isShow,
  isMediaFile,
  ICampaign,
  IShow,
  ICampaignItem,
  PublicShow,
  Script,
  isScript,
} from "redcircle-types";
import { isUUID } from "src/lib/uuid";
import { fetchPublicShowsByUUIDs } from "src/action_managers/public_show";
import { getBudgetsByCampaignUUID } from "src/action_managers/budgets";
import { getScriptsForCampaign } from "src/action_managers/script";
import { showInfo, showWarning } from "src/actions/app";

/*
  CSV should like like the following,

  Show Title,          2020-04-27, 2019-05-22
  In the Lobby,        10,         0
  Emily's Whacky Show, 0,          200
 */
const downloadCSV = (campaign: ICampaign, showStatsByWeek: any) => {
  if (!campaign) {
    return;
  }
  if (isEmpty(showStatsByWeek)) {
    return;
  }

  const weeks = Object.keys(showStatsByWeek);
  weeks.sort();
  // fields are the header columns of the csv
  // ex. Show Title, 2019-04-02, 2019-04-16, ...
  const fields = ["Show Title"].concat(weeks);
  const showTitles = uniq(
    Object.values(showStatsByWeek).flatMap((shows: any) => shows.map((show: any) => show.showTitle))
  );
  showTitles.sort();

  let data = showTitles.map((showTitle) => [showTitle]);
  weeks.forEach((week) => {
    const nextRow = new Array(showTitles.length).fill(0);

    const showStats = showStatsByWeek[week];
    showStats.forEach((show: any) => {
      const index = showTitles.findIndex((showTitle) => showTitle === show.showTitle);
      nextRow[index] = show.downloads;
    });

    data = zipWith(data, nextRow, concat);
  });

  const csv = papa.unparse({
    fields: fields,
    data: data,
  });

  downloadFile(`${campaign.name}_AdInsertionsPerShowByWeek.csv`, csv);
};

export const DownloadInsertionInfoButton = ({
  campaign,
  className,
}: {
  campaign?: ICampaign;
  className?: string;
}) => {
  const [showStatsByWeek, setShowStatsByWeek] = useState({});
  const dispatch = useDispatchTS();

  useEffect(() => {
    if (campaign && !isEmpty(campaign)) {
      dispatch(getCampaignWeeklyStats(campaign.uuid)).then((resp) => {
        if (resp.status === 200) {
          setShowStatsByWeek(resp.json);
        }
      });
    }
  }, [campaign]);

  if (!campaign || isEmpty(showStatsByWeek)) return null;
  return (
    <button
      className={classNames("svg-button", className)}
      onClick={() => downloadCSV(campaign, showStatsByWeek)}>
      <DownloadIcon />
    </button>
  );
};

/**
 * Outputs CSV with relevant campaign data.
 *
 * Can also use async wrapper version @see {@link downloadCSVForCampaign}
 */
export const downloadPodcastInfo = ({
  campaign,
  campaignItems,
  scriptsForCampaign,
}: {
  campaign?: ICampaign;
  /* any[] contains budget and show as well on the item (e.g. item.budget, item.show)  */
  campaignItems?: (ICampaignItem & {
    budget: Budget;
    show: IShow | PublicShow;
    mediaFile: MediaFile;
  })[];
  scriptsForCampaign?: Script[];
}) => {
  if (isEmpty(campaign) || !Array.isArray(campaignItems)) {
    return;
  }

  const promoCodeType = scriptsForCampaign?.[0]?.promoCodeType ?? campaign.promoCodeType;

  const removedItemsStates: CampaignItemState[] = ["declined", "expired"];

  const items = sortBy(
    campaignItems.filter((item) => !removedItemsStates.includes(item.state)),
    "respondedAt"
  );

  const shows = flatten(
    items.map((campaignItem) => {
      const { show, totalBudget, respondedAt, redemptionCodes, promoCode, webhookURL } =
        campaignItem;

      const hosts = show.advertisementSettings.hosts;
      const rows = hosts?.map(({ fullName, postalAddress }, i: number) => {
        if (i > 0) {
          // We don't want duplicate rows when we aren't explicitly showing per host information.
          // Ex. No host name => every row is the same since host name was the only difference
          if (campaign.productExchangeType !== ProductExchangeTypeMailing) {
            return null;
          }

          // No need to include per host info if their is no mailing address to send product to
          if (
            campaign.productExchangeType === ProductExchangeTypeMailing &&
            isEmpty(postalAddress)
          ) {
            return null;
          }
        }

        const cpm = getAverageCPM({ show, campaign, campaignItem });

        const mediaFileInfo =
          typeof campaignItem?.mediaFile?.url === "string"
            ? {
                Audio: campaignItem.mediaFile.url,
              }
            : {};

        return {
          "Accepted Date": Moment.unix(respondedAt ?? 0).format("lll"),
          "Show Name": show.title,
          ...(i === 0 && {
            Amount: formatMoney(totalBudget),
            "Average CPM": formatMoney(cpm),
            "Estimated Impressions": Math.round(
              getImpressionsFromBudget({ cpm, budget: totalBudget })
            ),
          }),
          ...(i === 0 &&
            promoCodeType === PromoCodeTypePodcasterUnique && {
              "Unique Code or Tag": promoCode || show.promoCode,
            }),
          ...(campaign.productExchangeType === ProductExchangeTypeMailing && {
            "Host Name": `${fullName.firstName} ${fullName.lastName}`,
            "Mailing Address": addressToString(postalAddress, true),
          }),
          ...(i === 0 &&
            campaign.productExchangeType === ProductExchangeTypeInstructions && {
              "Redemption Codes": redemptionCodes,
            }),
          "Pixel URL": webhookURL,
          ...mediaFileInfo,
        };
      });

      return rows?.filter((row: any) => row !== null);
    })
  ).filter((item: any) => !!item);

  const csv = papa.unparse(shows);
  const currentMs = Moment.now();
  downloadFile(`${campaign.name}_${currentMs}.csv`, csv);
};

/**
 * Async function that checks and fetches all required data for the csv output of the
 * `downloadPodcastInfo` function.
 *
 * Checks `campaign`, `campaignItems`, `Budget`, `MediaFile`, and `scripts` are available in
 * redux state, and will fetch any missing data.
 */
export const downloadCSVForCampaign = async (campaignUUID: string) => {
  // Initially check onr redux state
  const { campaigns } = store.getState()?.campaigns;
  const { campaignItemByCampaignUUID, campaignItems: campaignItemsMap } =
    store.getState()?.campaignItems;

  let mediaFilesMap = store.getState()?.mediaFiles;
  let scriptsByUUID = store.getState()?.scripts?.scriptsByUUID;

  let budgetsMap = store.getState()?.budgets;
  let publicShows = store.getState()?.publicShows;

  let campaign = campaigns?.[campaignUUID];
  let campaignItems = campaignItemByCampaignUUID?.[campaignUUID]
    ?.map((campaignItemUUID) => campaignItemsMap?.[campaignItemUUID])
    ?.filter((campaignItem) => isCampaignItem(campaignItem));

  const isMediaFilesMissing =
    campaignItems
      ?.map((item) => item.mediaFileUUID)
      ?.filter((uuid) => isUUID(uuid))
      ?.some((mediaFileUUID) => !isMediaFile(mediaFilesMap[mediaFileUUID as string])) ?? true;

  try {
    // Check if campaign, campaignItems, and media files are available in redux state
    // the getCampaign reducer fetches all three entities
    if (
      !isCampaign(campaign) ||
      !Array.isArray(campaignItems) ||
      campaignItems?.length === 0 ||
      isMediaFilesMissing
    ) {
      await store.dispatch(getCampaign(campaignUUID));
      campaign = store.getState()?.campaigns?.campaigns?.[campaignUUID];
      const { campaignItemByCampaignUUID, campaignItems: campaignItemsMap } =
        store.getState()?.campaignItems;

      campaignItems = campaignItemByCampaignUUID?.[campaignUUID]?.map(
        (campaignItemUUID) => campaignItemsMap?.[campaignItemUUID]
      );

      mediaFilesMap = store.getState()?.mediaFiles;
    }

    // If campaign has no campaign Items. Can stop right here
    if (!Array.isArray(campaignItems) || campaignItems?.length === 0) {
      store.dispatch(showInfo(`"${campaign.name}" does not have any podcasts.`));
      return;
    }

    const missingPublicShowsList = campaignItems
      ?.map((item) => item.showUUID)
      ?.filter((publicShowUUID) => !isShow(publicShows?.[publicShowUUID]));
    const isPublicShowsMissing = missingPublicShowsList?.length > 0;

    // Fetch public shows that are missing in redux state
    if (isPublicShowsMissing) {
      await store.dispatch(fetchPublicShowsByUUIDs(missingPublicShowsList));
      publicShows = store.getState()?.publicShows;
    }

    const isBudgetsMissing = campaignItems
      ?.map((item) => item?.budgetUUID)
      .filter((budgetUUID) => isUUID(budgetUUID))
      ?.some((budgetUUID) => !isBudget(budgetsMap[budgetUUID as string]));

    // Fetch budgets if they are missing
    if (isBudgetsMissing) {
      await store.dispatch(getBudgetsByCampaignUUID(campaignUUID));
      budgetsMap = store.getState()?.budgets;
    }

    const isScriptsMissing = campaignItems
      ?.map((item) => item?.scriptUUID)
      .filter((scriptUUID) => isUUID(scriptUUID))
      .some((scriptUUID) => !isScript(scriptsByUUID[scriptUUID as string]));

    // Fetch missing scripts
    if (isScriptsMissing) {
      await store.dispatch(getScriptsForCampaign(campaignUUID));
      scriptsByUUID = store.getState()?.scripts?.scriptsByUUID;
    }

    /**
     * Load requirements for Download CSV function
     */

    const loadedCampaignItems = campaignItems?.map((item) => {
      const { budgetUUID = "", showUUID = "", mediaFileUUID = "" } = item;
      return {
        ...item,
        budget: budgetsMap[budgetUUID],
        show: publicShows[showUUID],
        mediaFile: mediaFilesMap[mediaFileUUID],
      } as ICampaignItem & { budget: Budget; show: PublicShow; mediaFile: MediaFile };
    });

    const scriptsForCampaign = campaignItems
      ?.map((item) => item.scriptUUID)
      .filter((scriptUUID) => isUUID(scriptUUID))
      .map((scriptUUID) => scriptsByUUID[scriptUUID as string]);

    downloadPodcastInfo({
      campaign,
      campaignItems: loadedCampaignItems,
      scriptsForCampaign,
    });
  } catch (err) {
    store.dispatch(showWarning("Could not download CSV"));
  }
};
