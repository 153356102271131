import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HomeRedirect from "./home_redirect";

function mapStateToProps(state) {
  return {
    user: state.user.user,
    currentRole: state.user.currentRole,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const HomeRedirectWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeRedirect));

export default HomeRedirectWrapper;
