import { Spin, Tooltip } from "antd";
import React, { ReactNode } from "react";
import { classNames, If } from "react-extras";
import { svgIcon as Icon } from "../icon";
import "./highlight_box.scss";

type IHighLightBox = {
  title: ReactNode;
  infoText?: string | false;
  scalableHeight?: boolean;
  isLoading?: boolean;
  analytics?: boolean;
  className?: string;
} & ({ value: ReactNode } | { children: ReactNode });

const HighlightBox = (props: IHighLightBox) => {
  const { title, infoText, scalableHeight, isLoading, analytics, className } = props;
  return (
    <div
      className={classNames("highlight-box", className, {
        "scalable-height": scalableHeight,
        analytics: analytics,
      })}>
      <div className="highlight-box__inner">
        <div className="highlight-box__title redcircle-form-label" data-testid="title">
          {title}
          <If condition={!!infoText}>
            <Tooltip title={infoText} className="highlight-box-tooltip">
              <Icon name="info" className="highlight-box-icon" />
            </Tooltip>
          </If>
        </div>
        <If condition={!!isLoading}>
          <Spin size="small" />
        </If>
        <If condition={!isLoading}>
          {"value" in props && typeof props?.value !== "undefined" && (
            <h3 data-testid="value" className="highlight-box__stat m-a0">
              {props?.value}
            </h3>
          )}
          {"children" in props && React.Children.count(props.children) > 0 && props?.children}
        </If>
      </div>
    </div>
  );
};

export default HighlightBox;
