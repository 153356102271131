import { Form, Input, Select } from "antd";
import { IYoutubeOptions } from "redcircle-types";
import { Modal } from "redcircle-ui";
import { clearHTMLTags } from "src/lib/dom";
import { IEpisode } from "redcircle-types";

export const EpisodeRegenModal = ({
  visible,
  onClose,
  onSubmit,
  type,
}: {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  type: string | boolean;
}) => (
  <Modal open={visible} size="xs" onClose={onClose} onSubmit={onSubmit}>
    <Modal.Title>
      {type === "youtube" && "Regenerate YouTube Video?"}
      {type === "video" && "Regenerate Video File?"}
    </Modal.Title>
    <Modal.Body>
      {type === "youtube" &&
        "Regenerating will cause another video to be published to YouTube using your latest podcast audio. You may want to delete your previous YouTube video first. The process can take up to 2 hours."}
      {type === "video" &&
        "Regenerating will create another video file using your latest podcast audio. You will no longer be able to access the existing video file. The process can take up to 2 hours."}
    </Modal.Body>
    <Modal.Footer noBorder>
      <Modal.CloseButton />
      <Modal.SubmitButton />
    </Modal.Footer>
  </Modal>
);

export const EpisodePublishModal = ({
  visible,
  episode,
  onClose,
  onSubmit,
  type,
}: {
  episode?: IEpisode;
  visible: boolean;
  onClose: () => void;
  onSubmit: (values?: IYoutubeOptions) => void;
  type: string | boolean;
}) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    if (type === "video") onSubmit();
    if (type === "youtube") {
      form
        .validateFields()
        .then((values) => {
          onSubmit(values);
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  return (
    <Modal open={visible} size="xs" onClose={onClose} onSubmit={handleSubmit}>
      <Modal.Title>
        {type === "youtube" && "Publish to YouTube?"}
        {type === "video" && "Generate Video File?"}
      </Modal.Title>
      <Modal.Body>
        {type === "youtube" && (
          <>
            Your video will be uploaded to your YouTube within 1-2 hours. If your episode is not
            published yet, the process will begin when the episode is published.
            <Form
              className="m-ts"
              form={form}
              initialValues={{
                privacyStatus: "public",
                title: episode?.title,
                description: episode?.description
                  ? clearHTMLTags(episode.description.replaceAll("<br>", "\n\n"))
                  : "",
              }}>
              <label htmlFor="title" className="redcircle-form-label">
                <small>Title</small>
              </label>
              <Form.Item name="title" rules={[{ required: true, message: "Title is required" }]}>
                <Input placeholder="Title" />
              </Form.Item>
              <label htmlFor="description" className="redcircle-form-label">
                <small>Description</small>
              </label>
              <Form.Item name="description">
                <Input.TextArea placeholder="Description" autoSize={{ minRows: 2, maxRows: 10 }} />
              </Form.Item>
              <label htmlFor="privacyStatus" className="redcircle-form-label">
                <small>Privacy Status</small>
              </label>
              <Form.Item
                name="privacyStatus"
                rules={[{ required: true, message: "Privacy Status is required" }]}>
                <Select>
                  <Select.Option value="public">Public</Select.Option>
                  <Select.Option value="unlisted">Unlisted</Select.Option>
                  <Select.Option value="private">Private</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </>
        )}
        {type === "video" &&
          "This will generate a video file that you can download and manually upload to YouTube. The process could take up to 2 hours.  If your episode is not published yet, the video will start generating when your episode is released."}
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton />
        <Modal.SubmitButton />
      </Modal.Footer>
    </Modal>
  );
};
