import dayjs from "dayjs";
import { ICampaignItem } from "src/reducers/campaign_items";

export default function AcceptCampaignPage({ campaignItem }: { campaignItem: ICampaignItem }) {
  return (
    <>
      <p>
        The brand will provide the script at least 5 days before you need to upload your ad audio.
      </p>
      <p>
        Your ad audio will be due by{" "}
        {campaignItem?.uploadAudioBy && (
          <strong>{dayjs.unix(campaignItem.uploadAudioBy).format(`M/D/YY`)}</strong>
        )}
        .
      </p>
    </>
  );
}
