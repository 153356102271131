import { Spin } from "antd";
import React from "react";
import { classNames } from "react-extras";

export interface ILoading {
  size?: "small" | "default" | "large" | undefined;
  className?: string;
}

export default function Loading({ size = "default", className }: ILoading) {
  return (
    <div
      data-testid="test-loading"
      className={classNames("width-100 flex-row-container justify-center", className)}>
      <Spin size={size} />
    </div>
  );
}
