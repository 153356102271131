import { Row as AntdRow } from "antd";
import get from "lodash/get";
import pick from "lodash/pick";
import moment from "moment";
import numeral from "numeral";
import Papa from "papaparse";
import React, { Component } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import Clipboard from "react-clipboard.js";
import { If } from "react-extras";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "src/components/lib/breadcrumbs";
import { showSuccess } from "../../../actions/app";
import { showModal } from "../../../actions/modal";
import {
  enablePayments,
  ShowFetchActionManager,
  ShowUpdateActionManager,
} from "../../../action_managers/shows";
import {
  enableExclusiveContent,
  fetchSubscriptionsByShowUUID,
} from "../../../action_managers/subscriptions";
import { permissionTypes } from "../../../constants/permission_roles";
import { getSeedcastURL } from "../../../lib/config";
import { formatMoney } from "../../../lib/format-money";
import { withPermissions } from "../../../lib/permissions-js";
import LoadingButton from "../../forms/loading_button/loading_button";
import ContextMenu from "../../lib/context_menu";
import Divider from "../../lib/divider";
import { downloadFile } from "../../lib/download_file";
import ExternalLink from "../../lib/external_link";
import HighlightBox from "../../lib/highlight_box";
import Spinner from "../../lib/spinner";
import { CONFIRMATION_MODAL, ENABLE_SUBSCRIPTION_MODAL } from "../../modals/modal_root";
import ShowPageWrapper from "../shows/show_page_wrapper";
import "./enable-subscriptions.scss";

class ShowSubscriptionPage extends Component {
  componentDidMount() {
    this.props.fetchShowByUUID(this.getShowUUID(), this.props.user).then(() => {
      if (this.hasPaymentEnabled()) {
        this.props.fetchSubscriptionsByShowUUID({ showUUID: this.getShowUUID() });
      }
    });
  }

  getShowUUID = () => {
    return get(this.props, "match.params.showUUID");
  };
  getShow = () => {
    return get(this.props, ["shows", this.getShowUUID()], {});
  };

  getSubscriptionDetails = () => {
    return get(this.getShow(), "subscriptionDetails", {});
  };

  isExclusiveContentEnabled = () => {
    return get(this.getShow(), ["subscriptionDetails", "exclusiveContentEnabled"], false);
  };

  hasPaymentEnabled = () => {
    // GROSS
    return get(this.getShow(), ["subscriptionDetails", "recurringDonationsEnabled"]) !== undefined;
  };

  renderDisabledContent = () => {
    if (!this.canEditExclusive()) {
      return null;
    }
    return (
      <div className="flex-column-container align-center">
        <LoadingButton
          onClick={() => this.props.enablePayments(this.getShowUUID())}
          className="m-tl m-bxs"
          isLoading={this.props.isLoading}>
          Enable Payments
        </LoadingButton>
        <strong>
          <ExternalLink href="https://support.redcircle.com/earning-money">Learn More</ExternalLink>
        </strong>
      </div>
    );
  };

  renderEnableExclusiveContentButton = () => {
    return (
      <LoadingButton
        onClick={() =>
          this.props.showEnableSubscriptionModal({
            submit: this.submitEnableSubscription,
            isEdit: false,
          })
        }
        className="m-txs m-bxs">
        Enable Exclusive Content
      </LoadingButton>
    );
  };

  renderStripeLink = () => {
    return (
      <div>
        <p>Before configuring exclusive content and donations you must enable monetization.</p>
        <Button
          className="m-tl btn-primary"
          onClick={() => this.props.history.push("/monetization")}>
          Enable Monetization
        </Button>
      </div>
    );
  };

  editSubscription = ({ amount, amountWeekly, amountAnnual, description }) => {
    return this.props.updateShow(
      {
        uuid: this.getShowUUID(),
        subscriptionDetails: { amount, amountWeekly, amountAnnual, description },
      },
      this.props.user
    );
  };

  disableSubscription = () => {
    return this.props.updateShow(
      {
        uuid: this.getShowUUID(),
        subscriptionDetails: {
          exclusiveContentEnabled: false,
          amount: 0,
          amountMonthly: 0,
          amountAnnual: 0,
        },
      },
      this.props.user
    );
  };

  canEditExclusive = () => {
    return this.props.canAccess(permissionTypes.editExclusiveContent, this.getShowUUID());
  };

  canExportExclusive = () => {
    return this.props.canAccess(permissionTypes.exportSubscribers, this.getShowUUID());
  };

  submitEnableSubscription = ({ amount, amountWeekly, amountAnnual, description }) => {
    return this.props.enableExclusiveContent({
      amount,
      amountWeekly,
      amountAnnual,
      description,
      showUUID: this.getShowUUID(),
    });
  };

  renderEnabledContent = () => {
    return (
      <>
        <div className="exclusive-content-intro-text">
          <p>Manage exclusive content to power monetization directly from your listeners</p>
        </div>
        <div className={"exclusive-content-row"}>
          <div>
            <h4 className={"exclusive-access-header"}>
              Exclusive Content:
              <If condition={this.isExclusiveContentEnabled()}>
                <span className="redcircle-badge">Enabled</span>
              </If>
              <If condition={!this.isExclusiveContentEnabled()}>
                <span className="text-danger m-ls">Disabled</span>
              </If>
            </h4>
            <Divider smallMargin />
            <div className="m-bs">
              <If condition={this.isExclusiveContentEnabled()}>
                Minimum monthly subscription for exclusive content:{" "}
                {formatMoney(this.getSubscriptionDetails().amount)}
              </If>
              <If condition={!this.isExclusiveContentEnabled()}>
                Turn on exclusive content to require users to pay to get access to some of your
                episodes.
              </If>
            </div>
          </div>
          <If condition={this.isExclusiveContentEnabled() && this.canEditExclusive()}>
            <div className={"context-menu"}>
              <ContextMenu
                menuItems={{
                  "Edit Price & Description": () => {
                    this.props.showEnableSubscriptionModal({
                      submit: this.editSubscription,
                      description: this.getSubscriptionDetails().description,
                      amountMonthly: this.getSubscriptionDetails().amount,
                      amountAnnual: this.getSubscriptionDetails().amountAnnual,
                      amountWeekly: this.getSubscriptionDetails().amountWeekly,
                      isEdit: true,
                    });
                  },
                  "Disable New Subscriptions": () => {
                    this.props.showDeleteConfirmation(this.disableSubscription);
                  },
                }}
              />
            </div>
          </If>
          <If condition={!this.isExclusiveContentEnabled() && this.canEditExclusive()}>
            {this.renderEnableExclusiveContentButton()}
          </If>
        </div>

        <div className={"flex-row-container justify-space-between align-baseline"}>
          <h3 className={"m-b0"}>Subscription Details</h3>
          <If condition={this.canExportExclusive()}>
            <span className={"btn btn-link m-a0 no-padding"} onClick={this.exportSubscribers}>
              <span className={"hidden-xs hidden-sm"}>Export Subscribers</span>
              <span
                className={
                  "hidden-md hidden-lg hidden-xl visible-sm visible-xs glyphicon glyphicon-download-alt"
                }
              />
            </span>
          </If>
        </div>
        <Divider smallMargin />
        <If condition={this.getSubscriptions().length}>{this.renderHighlightBoxes()}</If>
        <If condition={!this.getSubscriptions().length}>
          <p className="m-ts">
            You do not have any subscribers yet. Remember to tell your listeners about it!
          </p>
        </If>
      </>
    );
  };

  renderHighlightBoxes = () => {
    const subscriptions = this.getSubscriptions();
    if (!subscriptions.length) {
      return null;
    }

    const divisors = {
      monthly: 1,
      weekly: 0.25,
      annual: 12,
    };
    // times .78 for net income
    const income =
      subscriptions.reduce((agg, { amount, interval }) => agg + amount / divisors[interval], 0) *
      0.88;
    return (
      <>
        <Row>
          <Col xs={12} md={6}>
            <HighlightBox title={"Total Sponsors"} value={subscriptions.length} />
          </Col>
          <Col xs={12} md={6}>
            <HighlightBox title={"Net Income per Month"} value={formatMoney(income)} />
          </Col>
        </Row>
      </>
    );
  };

  exportSubscribers = () => {
    const subscriptions = this.getAllSubscriptions();
    let csvData = subscriptions.map((sub) =>
      pick(sub, [
        "amount",
        "interval",
        "state",
        "createdAt",
        "type",
        "firstName",
        "lastName",
        "email",
      ])
    );
    // Formatting Data before final parse/download
    csvData = csvData
      .filter((sub) => sub.state.toLowerCase() === "active")
      .sort((a, b) => a.createdAt - b.createdAt)
      .map((sub) => {
        sub.createdAt = moment.unix(sub.createdAt).format("dddd, MMMM Do, YYYY h:mm:ss A");
        sub.amount = numeral((typeof sub.amount === "number" ? sub.amount : 0) / 100).format(
          "$0,0.00"
        );
        return sub;
      });
    const csv = Papa.unparse(csvData);
    downloadFile(`redcircle-subscribers.csv`, csv);
  };

  getSubscriptions = () => {
    return this.getAllSubscriptions().filter(
      (subscription) => subscription.state === "active" && subscription.type === "exclusive"
    );
  };

  getAllSubscriptions = () => {
    return get(this.props, ["subscriptionsByShowUUIDs", this.getShowUUID()], []);
  };

  getRecurringDonation = () => {
    return get(this.props, ["subscriptionsByShowUUIDs", this.getShowUUID()], []).filter(
      (subscription) => subscription.state === "active" && subscription.type === "exclusive"
    );
  };

  render() {
    const sponsorURL = `${getSeedcastURL()}shows/${this.getShowUUID()}/exclusive-content`;

    return (
      <ShowPageWrapper className="show-exclusive-content-page" show={this.getShow()}>
        <Breadcrumbs
          crumbs={[
            { path: "/shows", name: "All Podcasts" },
            { path: `/shows/${this.getShowUUID()}`, name: this.getShow()?.title },
            {
              path: `/shows/${this.getShowUUID()}/i/exclusive-content`,
              name: "Exclusive Content",
              active: true,
            },
          ]}
        />

        <AntdRow>
          <ShowPageWrapper.Sidebar>
            <If condition={this.isExclusiveContentEnabled()}>
              <Clipboard
                onSuccess={this.props.showSuccess.bind(
                  this,
                  "Successfully copied exclusive content link to clipboard.",
                  5000
                )}
                option-text={() => sponsorURL}
                className="m-l0 m-yl btn btn-link">
                Copy Exclusive Content Link
              </Clipboard>
            </If>
          </ShowPageWrapper.Sidebar>

          <ShowPageWrapper.Body>
            <ShowPageWrapper.Header title="Exclusive Content" />

            <div className={"enable-subscriptions"}>
              <div className="enable-subscriptions-main-content">
                <Grid fluid>
                  <Row>
                    <Spinner isLoading={this.props.isLoading} />
                    <If condition={!this.props.isLoading}>
                      <If condition={!this.hasPaymentEnabled()}>
                        <div className={"disabled-content"}>
                          <If condition={!get(this.props.user, "isStripeConnected")}>
                            {this.renderStripeLink()}
                          </If>
                          <If condition={get(this.props.user, "isStripeConnected")}>
                            <p>
                              Build monthly subscription revenue from your most loyal listeners with
                              exclusive content.
                            </p>
                            {this.renderDisabledContent()}
                          </If>
                        </div>
                      </If>
                      <If condition={this.hasPaymentEnabled()}>{this.renderEnabledContent()}</If>
                    </If>
                  </Row>
                </Grid>
              </div>
            </div>
          </ShowPageWrapper.Body>
        </AntdRow>
      </ShowPageWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    shows: state.shows.shows,
    isLoading: state.showForm.isLoading || state.subscriptions.isLoading || state.shows.isLoading,
    user: state.user.user,
    subscriptionsByShowUUIDs: state.subscriptions.subscriptionsByShowUUIDs,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updateShow: (show, user) => new ShowUpdateActionManager({ show, user }).run(),
    enableExclusiveContent,
    enablePayments,
    fetchShowByUUID: (showUUID, user) => new ShowFetchActionManager({ showUUID, user }).run(),
    showEnableSubscriptionModal: (props) => showModal(ENABLE_SUBSCRIPTION_MODAL, props),
    fetchSubscriptionsByShowUUID,
    showDeleteConfirmation: (callback) =>
      showModal(CONFIRMATION_MODAL, {
        modalTitle: "Stop offering exclusive content?",
        confirmationMessage:
          "Disabling this feature will prevent new listeners from subscribing to your exclusive content.  Existing listeners will remain subscribed and still have access.",
        callback,
      }),
    showSuccess,
  })(withPermissions(ShowSubscriptionPage))
);
