import { Helmet } from "react-helmet-async";
import UserRoles from "src/constants/roles";
import { useSelectorTS } from "src/hooks/redux-ts";
import { userHasRole } from "src/lib/user";

export default function HelmetPageTitle({ title }: { title?: string }) {
  const { user } = useSelectorTS((state) => state.user);
  const isUserAdmin = userHasRole(user, UserRoles.Admin);

  return (
    <Helmet>
      <title>
        {isUserAdmin ? "[Admin] " : ""}
        {title ? `RedCircle | ${title}` : "RedCircle"}
      </title>
    </Helmet>
  );
}
