import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formValueSelector } from "redux-form";
import SignUpActionManager from "../../../action_managers/sign_up";
import { withPageTitle } from "../../lib/WithPageTitle";
import SignUpPage from "./sign_up_page";

function mapStateToProps(state) {
  var formValueSelectorPassword = formValueSelector("sign-up");
  return {
    user: state.user.user,
    isLoading: state.signUp.isLoading,
    validationErrors: state.signUp.validationErrors,
    password: formValueSelectorPassword(state, "password"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signUp: (formJSON, forRole) => dispatch(new SignUpActionManager({ formJSON, forRole }).run()),
  };
}

const SignUpPageWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpPage));

export default withPageTitle(SignUpPageWrapper, "Sign Up");
