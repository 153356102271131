import get from "lodash/get";

export const formatMoney = (amount: number) =>
  (amount / 100).toLocaleString(undefined, {
    style: "currency",
    currency: "USD",
  });

export const formatMoneyShort = (amount: number) =>
  (amount / 100)
    .toLocaleString(undefined, {
      style: "currency",
      currency: "USD",
    })
    .slice(0, -3);

export const formatMoneyFlexCurrency = ({
  amount,
  currency,
}: {
  amount: number;
  currency: string;
}) => {
  // https://stripe.com/docs/currencies#zero-decimal don't divide by 100 for these.
  const zeroDecimalCurrencies = {
    BIF: 1,
    CLP: 1,
    DJF: 1,
    GNF: 1,
    JPY: 1,
    KMF: 1,
    KRW: 1,
    MGA: 1,
    PYG: 1,
    RWF: 1,
    UGX: 1,
    VND: 1,
    VUV: 1,
    XAF: 1,
    XOF: 1,
    XPF: 1,
  };

  const divisor = get(zeroDecimalCurrencies, currency.toUpperCase(), 100);
  return (amount / divisor).toLocaleString(undefined, {
    style: "currency",
    currency: currency.toUpperCase(),
  });
};
