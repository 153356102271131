import isNil from "lodash/isNil";
import React, { Component } from "react";
import { Col, Grid, Modal, Row } from "react-bootstrap";
import { classNames, If } from "react-extras";
import closeIcon from "../../icons/modal-close-icon.svg";
import Divider from "../lib/divider";
import Spinner from "../lib/spinner";
import "./modal.scss";

class BaseModal extends Component {
  render() {
    var {
      isLoading,
      isMini,
      Content,
      close,
      hideCloseButton,
      title,
      subTitle,
      showContent,
      Footer,
      modalProps,
      containerClass,
      noFooterShadow,
      hideFooter = false,
      hideTitleDivider = false,
      ref,
      hideOverflow = false,
      ...contentProps
    } = this.props;
    var mainClasses = classNames(
      "seedcast-modal",
      "modal-dialog-centered",
      isMini && "seedcast-modal--mini",
      containerClass
    );

    return (
      <div className={"static-modal"}>
        <Modal
          dialogClassName={classNames(mainClasses, this.props.isLarge && "large")}
          show={true}
          {...modalProps}
          ref={(r) => {
            ref && ref(r);
          }}>
          <If condition={!hideCloseButton}>
            <button onClick={close} className={"btn btn-link seedcast-modal__close-button"}>
              <img src={closeIcon} alt={"close-modal"} />
            </button>
          </If>
          <div className={classNames("seedcast-modal__content", this.props.isLarge && "large")}>
            <Grid fluid>
              <Row>
                <Col
                  xs={this.props.columns || 10}
                  xsOffset={isNil(this.props.offset) ? 1 : this.props.offset}
                  md={this.props.columns || 10}
                  mdOffset={isNil(this.props.offset) ? 1 : this.props.offset}>
                  <div className={"seedcast-modal__header"}>
                    <If condition={Boolean(title)}>
                      <h2>{title}</h2>
                    </If>
                    <If condition={Boolean(subTitle)}>
                      <p>{subTitle}</p>
                    </If>
                    <If condition={!isMini && !hideTitleDivider}>
                      <Divider smallMargin className="seedcast-modal__title-divider" />
                    </If>
                  </div>
                </Col>
              </Row>
            </Grid>
            <Modal.Body>
              <Spinner isLoading={isLoading} />
              <If condition={showContent}>
                <Content {...contentProps} close={close} />
              </If>
            </Modal.Body>
          </div>
          <div className="flex-1" />
          <If condition={!hideFooter}>
            <div
              className={classNames(
                "seedcast-modal__footer",
                Footer && "show-footer",
                this.props.noFooterShadow && "seedcast-modal__footer--no-shadow"
              )}>
              <div className={"seedcast-modal__footer-inner"}>{Footer && <Footer />}</div>
            </div>
          </If>
        </Modal>
      </div>
    );
  }
}

export default BaseModal;
