export const breakpoint = 768;

export function isMobile() {
  return window.innerWidth <= breakpoint;
}

export const primaryColor = "#ea404d";
export const primaryColor20 = "#fad5d8";
export const primaryColor25 = "#f6b2b7";
export const primaryColor75 = "#f07982";
export const primaryColor50 = "#f49fa6";

export const blue = "#577D9E";
export const gray1 = "#FAFAFA";
