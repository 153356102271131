import { Redirect, Route, Switch } from "react-router-dom";
import { ModalLauncher } from "src/components/lib/modal_launcher";
import { PrivateRoute } from "src/components/lib/private_route";
import { oneLevelUp } from "src/components/lib/routing";
import ImportModal from "src/components/modals/import_modal";
import {
  EPISODE_FORM_MODAL,
  SHOW_FORM_MODAL,
  SHOW_REDIRECT_HELP_MODAL,
  SPONSORSHIP_MODAL,
  VAST_SETUP_MODAL,
} from "src/components/modals/modal_root";
import InsertionPointsModal from "src/components/pages/dynamic_insertion/insertion_points_modal";
import EpisodeEmbedPage from "src/components/pages/episodes/episode_embed_page";
import EpisodePage from "src/components/pages/episodes/episode_page";
import ShowDetailsPage from "src/components/pages/shows/show_details_page";
import ShowDistributionPage from "src/components/pages/shows/show_distribution_page";
import ShowEmbedPage from "src/components/pages/shows/show_embed_page";
import ShowEpisodesPage from "src/components/pages/shows/show_episodes_page";
import ShowListPage from "src/components/pages/shows/show_list_page";
import ShowRemovePage from "src/components/pages/shows/show_remove_page";
import SponsorShowPage from "src/components/pages/sponsor/sponsor_show_page";
import ShowExclusiveContentPage from "src/components/pages/subscriptions/show_exclusive_content";
import ShowDonationsPage from "src/components/pages/subscriptions/show_monthly_donations";
import { permissionTypes } from "src/constants/permission_roles";

export default function ShowsRoutes() {
  return (
    <Switch>
      <Route
        path="/shows/:showUUID/advertising"
        render={({ match }) => <Redirect to={`/ad-platform/${match?.params?.showUUID}`} />}
      />
      <Route
        path="/shows/:showUUID/campaigns/:campaignUUID"
        render={({ match }) => (
          <Redirect
            to={`/ad-platform/${match.params.showUUID}/campaigns/${match.params.campaignUUID}`}
          />
        )}
      />
      <PrivateRoute
        path="/shows/:showUUID/embed-show"
        permissionType={permissionTypes.embedPlayer}
        paramKey={"showUUID"}>
        <ShowEmbedPage />
      </PrivateRoute>
      <PrivateRoute
        path="/shows/:showUUID/remove"
        paramKey={"showUUID"}
        permissionType={permissionTypes.redirectShow}>
        <ShowRemovePage />
      </PrivateRoute>
      <PrivateRoute
        path="/shows/:showUUID/i/exclusive-content"
        permissionType={permissionTypes.viewExclusiveContent}
        paramKey={"showUUID"}>
        <ShowExclusiveContentPage />
      </PrivateRoute>
      <PrivateRoute
        paramKey={"showUUID"}
        path="/shows/:showUUID/i/donations"
        permissionType={permissionTypes.viewDonations}>
        <ShowDonationsPage />
      </PrivateRoute>

      <Route path="/shows/:showUUID/distribution" component={ShowDistributionPage} />

      <Route
        path="/shows/:showUUID/sponsor/create"
        render={(props) => [
          <SponsorShowPage key={"sponsor-show-page"} />,
          <ModalLauncher
            key={"sponsor-show-page-modal"}
            modalType={SPONSORSHIP_MODAL}
            modalProps={{
              ...props,
              closeRoute: oneLevelUp,
            }}
          />,
        ]}
      />

      {/* EPISODE LIST */}
      <Route path={["/shows/:showUUID/ep", "/shows/:showUUID/ep/create"]} exact>
        <Switch>
          <Route path="/shows/:showUUID/ep/create">
            <ModalLauncher
              modalType={EPISODE_FORM_MODAL}
              modalProps={{ mode: "create", closeRoute: oneLevelUp }}
            />
          </Route>
        </Switch>

        <Route path="/shows/:showUUID/ep" component={ShowEpisodesPage} />
      </Route>

      {/* EPISODES */}
      <PrivateRoute
        path="/shows/:showUUID/ep/:episodeUUID/embed"
        permissionType={permissionTypes.embedPlayer}
        paramKey={"showUUID"}>
        <EpisodeEmbedPage />
      </PrivateRoute>

      <Route path="/shows/:showUUID/ep/:episodeUUID">
        <Switch>
          <PrivateRoute
            path="/shows/:showUUID/ep/:episodeUUID/edit"
            permissionType={permissionTypes.editEpisodesDraft}
            paramKey={"showUUID"}>
            <ModalLauncher
              modalType={EPISODE_FORM_MODAL}
              modalProps={{ mode: "edit", closeRoute: oneLevelUp }}
            />
          </PrivateRoute>

          <PrivateRoute
            path="/shows/:showUUID/ep/:episodeUUID/insertion-points"
            paramKey={"showUUID"}
            permissionType={permissionTypes.editMarkers}>
            <InsertionPointsModal />
          </PrivateRoute>
        </Switch>

        <Route path="/shows/:showUUID/ep/:episodeUUID" component={EpisodePage} />
      </Route>

      {/* SHOW LIST */}
      <Route path={["/shows", "/shows/create", "/shows/import"]} exact>
        <Switch>
          <PrivateRoute path="/shows/create" permissionType={permissionTypes.createShow}>
            <ModalLauncher
              modalType={SHOW_FORM_MODAL}
              modalProps={{
                mode: "create",
                closeRoute: (location: string) => `/shows${location?.search}`,
              }}
            />
          </PrivateRoute>
          <PrivateRoute path="/shows/import" permissionType={permissionTypes.createShow}>
            <ImportModal />
          </PrivateRoute>
        </Switch>

        <ShowListPage key={"wrapper"} />
      </Route>

      {/* SHOWS */}
      <Route path="/shows/:showUUID">
        <Switch>
          <PrivateRoute
            path="/shows/:showUUID/edit"
            permissionType={permissionTypes.editShow}
            paramKey={"showUUID"}>
            <ModalLauncher
              modalType={SHOW_FORM_MODAL}
              modalProps={{ mode: "edit", closeRoute: oneLevelUp }}
            />
          </PrivateRoute>
          <Route
            path="/shows/:showUUID/redirect-help"
            render={(props) => (
              <>
                <ModalLauncher
                  modalType={SHOW_REDIRECT_HELP_MODAL}
                  modalProps={{ ...props, mode: "edit", closeRoute: oneLevelUp }}
                />
              </>
            )}
          />
          <PrivateRoute
            path="/shows/:showUUID/vast-setup"
            permissionType={permissionTypes.editShow}
            paramKey="showUUID">
            <ModalLauncher modalType={VAST_SETUP_MODAL} />
          </PrivateRoute>
        </Switch>
        {/**
         * Needs to be exact so it does not overlap with other routes, i.e. :showUUID/sponsor
         */}
        <Route
          path={[
            "/shows/:showUUID",
            "/shows/:showUUID/redirect-help",
            "/shows/:showUUID/vast-setup",
            "/shows/:showUUID/edit",
          ]}
          exact>
          <ShowDetailsPage />
        </Route>
      </Route>
    </Switch>
  );
}
