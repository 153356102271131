import DefaultActionManager from "./default_action_manager";

export const getBudgetByDealUUIDActionName = "GET_BUDGET_BY_DEAL_UUID";

export const getBudgetByDealUUID = (dealUUID) =>
  new DefaultActionManager({
    route: `deals/${dealUUID}/budgets`,
    auth: true,
    silent: true,
    actionName: getBudgetByDealUUIDActionName,
  }).run();

export const getBudgetByCampaignUUIDActionName = "GET_BUDGET_BY_CAMPAIGN_UUID";

export const getBudgetsByCampaignUUID = (campaignUUID) =>
  new DefaultActionManager({
    route: `campaigns/${campaignUUID}/budgets`,
    auth: true,
    silent: true,
    actionName: getBudgetByCampaignUUIDActionName,
  }).run();
