import get from "lodash/get";
import {
  directoryShowsSearchActionName,
  getDirectoryShowActionName,
} from "../../actions/directory_shows";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { DirectoryShowResponse, DirectoryShowsReduxState } from "./types";

const initialState: DirectoryShowsReduxState = {
  isLoading: false,
  results: [],
};
const searchDirectoryShows = httpReducer(directoryShowsSearchActionName, initialState, {
  success: (state, action) => {
    return Object.assign({}, state, {
      results: get(action, "payload.resp", []),
    });
  },
});

const getDirectoryShows = httpReducer(getDirectoryShowActionName, initialState, {
  success: (state, action) => ({
    ...state,
    [action.data.uuid]: directoryShowToShow(action.payload.resp),
  }),
});

export default reduceReducers(searchDirectoryShows, getDirectoryShows);

export const directoryShowToShow = (directoryShow: DirectoryShowResponse) => {
  return {
    ...directoryShow.ShowRequest,
    imageURL: directoryShow.ImageURL,
    episodes: directoryShow.Episodes.map(({ EpisodeRequest, ContentURL }) => {
      return {
        contentURL: ContentURL,
        ...EpisodeRequest,
      };
    }),
    invite: directoryShow.invite,
    inviteLink: directoryShow.inviteLink,
  };
};
