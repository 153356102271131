import { presetRoleRanking } from "src/components/pages/account/team_seats_utilities";
import {
  advertiserPermissionCategories,
  creatorPermissionCategories,
  permissionsConfigMap,
  UIPermissionsTypes,
} from "src/constants/permission_roles";
import UserRoles from "src/constants/roles";
import { Presets } from "src/reducers/permissions/types";
import { UserRole } from "redcircle-types";

/**
 * Get the permissions categories based on user role
 */

export const getPermCategoriesFromUserRole = (userRole: UserRole) => {
  switch (userRole) {
    case UserRoles.Advertiser:
      return advertiserPermissionCategories;

    case UserRoles.Admin:
    case UserRoles.Creator:
    default:
      return creatorPermissionCategories;
  }
};

/**
 * Get map of default permissions for a specific preset role
 */
export const getDefaultPermissionsForRole = (presetRole: Presets) => {
  const permissionsMap: Partial<Record<UIPermissionsTypes, boolean>> = {};

  for (const permissionType in permissionsConfigMap) {
    const metaData =
      permissionsConfigMap[permissionType as keyof typeof permissionsConfigMap][presetRole];
    if (metaData) {
      permissionsMap[permissionType as keyof typeof permissionsConfigMap] = metaData.defaultState;
    }
  }

  return permissionsMap;
};

/**
 * Compare user permission preset to a minimum allowed preset
 */
export const isUserPresetAllowed = ({
  userPreset,
  limitPreset,
  inclusive = true,
}: {
  userPreset: Presets;
  limitPreset: Presets;
  inclusive?: boolean;
}) => {
  return inclusive
    ? presetRoleRanking[userPreset] >= presetRoleRanking[limitPreset]
    : presetRoleRanking[userPreset] > presetRoleRanking[limitPreset];
};
