import keyBy from "lodash/keyBy";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import Button from "src/components/lib/button";
import { shouldShowAnalyticsUpgrade } from "src/lib/feature_flag";
import {
  CreatorInsertionCredit,
  PendingInvoiceTransaction,
  RecurringDonationCredit,
  SubscriptionSurchargeCredit,
  TipCredit,
} from "../../../../constants/transactions";
import { useSelectorTS } from "../../../../hooks/redux-ts";
import { formatMoney } from "../../../../lib/format-money";
import { ITransaction } from "../../../../reducers/transactions";
import HighlightBox from "../../../lib/highlight_box";
import {
  ALL_PODCASTS,
  canOptInToRAP,
  isDonationsEnabled,
  isExclusiveContentEnabled,
  isHostReadEnabled,
  isProgrammaticEnabled,
  RevenueGraphTypes,
  useSharedRevenueRequests,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";

interface IRevenueOverview {
  selectedShow: string;
}

const RevenueOverview: FunctionComponent<IRevenueOverview> = ({ selectedShow }) => {
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const history = useHistory();
  const { manualPayouts: manualPayoutsArray } = useSelectorTS((state) => state?.manualPayouts);
  const userTransactions = useSelectorTS((state) => state?.transactions?.userTransactions);
  const manualPayouts = keyBy(manualPayoutsArray, "uuid");

  const enableOtherRevenue =
    shouldShowAnalyticsUpgrade() && userTransactions.some((txn) => txn?.invoiceUUID?.length > 0);

  // Hanldes Fetching Data
  const [, , initialDataIsLoaded] = useSharedRevenueRequests();

  const transformTransactionData: (data: ITransaction[]) => number[] = (data) => {
    if (!initialDataIsLoaded) {
      return [];
    }
    // Aggregate revenue for each type in monthly intervals
    let RAPHostReadTotal = 0;
    let RAPProgrammaticTotal = 0;
    let ExclusiveSubscriptionsTotal = 0;
    let DonationsTotal = 0;
    let Other = 0;

    data.forEach((transaction) => {
      if (selectedShow === ALL_PODCASTS || selectedShow === transaction.showUUID) {
        switch (transaction.type) {
          case SubscriptionSurchargeCredit:
            ExclusiveSubscriptionsTotal += transaction?.amount;
            break;
          case TipCredit:
          case RecurringDonationCredit:
            DonationsTotal += transaction?.amount;
            break;
          case CreatorInsertionCredit: {
            const { campaignUUID, manualPayoutUUID } = transaction;
            if (campaignUUID) {
              RAPHostReadTotal += transaction?.amount;
            } else {
              // other type of transactions
              if (manualPayouts?.[manualPayoutUUID]?.type === "programmatic") {
                RAPProgrammaticTotal += transaction?.amount;
              }

              if (manualPayouts?.[manualPayoutUUID]?.type === "offline-campaign") {
                Other += transaction?.amount;
              }
            }

            break;
          }

          case PendingInvoiceTransaction: {
            const { campaignUUID } = transaction;

            if (campaignUUID) {
              RAPHostReadTotal += transaction?.amount;
            } else {
              Other += transaction?.amount;
            }

            break;
          }

          // Dont need these types
          case "SubscriptionPayout":
          case "TipPayout":
          case "RecurringDonationPayout":
          case "SubscriptionDebit":
          case "SubscriptionCollection":
            break;
          default:
        }
      }
    });

    const total =
      RAPHostReadTotal +
      RAPProgrammaticTotal +
      ExclusiveSubscriptionsTotal +
      DonationsTotal +
      Other;

    return [
      total,
      RAPHostReadTotal,
      RAPProgrammaticTotal,
      ExclusiveSubscriptionsTotal,
      DonationsTotal,
      Other,
    ];
  };

  const [
    total,
    RAPHostReadTotal,
    RAPProgrammaticTotal,
    ExclusiveSubscriptionsTotal,
    DonationsTotal,
    OtherTotal,
  ] = transformTransactionData(userTransactions);

  // Show Flags

  const showHostReadOptIn =
    selectedShow &&
    selectedShow !== ALL_PODCASTS &&
    RAPHostReadTotal == 0 &&
    canOptInToRAP(shows[selectedShow]) &&
    !isHostReadEnabled(shows[selectedShow]);

  const showProgrammaticOptIn =
    selectedShow &&
    selectedShow !== ALL_PODCASTS &&
    RAPProgrammaticTotal == 0 &&
    canOptInToRAP(shows[selectedShow]) &&
    !isProgrammaticEnabled(shows[selectedShow]);

  const showExclusiveOptIn =
    selectedShow &&
    selectedShow !== ALL_PODCASTS &&
    ExclusiveSubscriptionsTotal === 0 &&
    !isExclusiveContentEnabled(shows?.[selectedShow]);

  const showDoantionsOptIn =
    selectedShow &&
    selectedShow !== ALL_PODCASTS &&
    DonationsTotal === 0 &&
    !isDonationsEnabled(shows?.[selectedShow]);

  const revenueMap: Record<
    (typeof RevenueGraphTypes)[number],
    { value: number; link: string; showOptIn: boolean }
  > = {
    [RevenueGraphTypes[0]]: {
      value: total,
      link: "",
      showOptIn: false,
    },
    [RevenueGraphTypes[1]]: {
      value: RAPHostReadTotal,
      link: `/ad-platform/${selectedShow}`,
      showOptIn: showHostReadOptIn,
    },
    [RevenueGraphTypes[2]]: {
      value: RAPProgrammaticTotal,
      link: `/ad-platform/${selectedShow}`,
      showOptIn: showProgrammaticOptIn,
    },
    [RevenueGraphTypes[3]]: {
      value: ExclusiveSubscriptionsTotal,
      link: `/shows/${selectedShow}/i/exclusive-content`,
      showOptIn: showExclusiveOptIn,
    },
    [RevenueGraphTypes[4]]: {
      value: DonationsTotal,
      link: `/shows/${selectedShow}/i/donations`,
      showOptIn: showDoantionsOptIn,
    },
    [RevenueGraphTypes[5]]: {
      value: OtherTotal,
      link: `/ad-platform/${selectedShow}`,
      showOptIn: false,
    },
  };

  const KPIs: (typeof RevenueGraphTypes)[number][] = enableOtherRevenue
    ? [...RevenueGraphTypes]
    : RevenueGraphTypes.slice(0, RevenueGraphTypes?.length - 1);

  return (
    <InfoCard title={{ text: "Earnings Overview" }}>
      <div className="analyticsPage-RevenueOverview">
        {KPIs.map((type) => {
          let title;

          if (type === RevenueGraphTypes[0]) {
            const split = type.split(" ");
            split.pop();

            title = (
              <>
                {`${split.join(" ")}`} <br /> Earnings
              </>
            );
          } else if (type === RevenueGraphTypes[3]) {
            title = (
              <>
                {`Subscriptions`} <br /> Earnings
              </>
            );
          } else {
            title = (
              <>
                {`${type}`} <br /> Earnings
              </>
            );
          }

          const content = revenueMap[type].showOptIn ? (
            <Button
              type="secondary"
              size="small"
              onClick={() => history.push(revenueMap[type].link)}>
              Opt In
            </Button>
          ) : (
            <h3 className="highlight-box__stat m-a0">
              {formatMoney(revenueMap[type].value / 1000)}
            </h3>
          );

          return (
            <HighlightBox
              key={type}
              analytics={true}
              title={title}
              infoText={
                type === RevenueGraphTypes[5] &&
                "Other earnings from RedCircle (e.g. off platform, manual ads)."
              }
              isLoading={!initialDataIsLoaded}>
              {content}
            </HighlightBox>
          );
        })}
      </div>
    </InfoCard>
  );
};

// Optimizing re-rendering from parent.
const areEqual = (prevProps: IRevenueOverview, nextProps: IRevenueOverview) => {
  const selectShowIsSame = prevProps?.selectedShow === nextProps?.selectedShow;
  return selectShowIsSame;
};

const MemoizedRevenueOverview = React.memo(RevenueOverview, areEqual);

export default MemoizedRevenueOverview;
