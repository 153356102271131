import { connect } from "react-redux";
import { showModal } from "../../../actions/modal";
import { getBudgetByDealUUID } from "../../../action_managers/budgets";
import { fetchDeal, getDealsForUser, updateDeal } from "../../../action_managers/deals";
import { getMessages, messagesSeen, sendMessage } from "../../../action_managers/messages";
import { withPermissions } from "../../../lib/permissions-js";
import { DEAL_REQUEST_MODAL } from "../../modals/modal_root";
import DealPage from "./deal_page";

const showRequestModal = (props) => showModal(DEAL_REQUEST_MODAL, props);
export default connect(
  ({ deals, messages, user, budgets }) => ({
    messages,
    user: user.user,
    deals,
    budgets,
  }),
  {
    getMessages,
    sendMessage,
    fetchDeal,
    updateDeal,
    showRequestModal,
    getBudgetByDealUUID,
    messagesSeen,
    getDealsForUser,
  }
)(withPermissions(DealPage));
