import React, { Component } from "react";
import { withMediaProps } from "react-media-player";
import { Icon } from "../icon";

class PlayPause extends Component {
  shouldComponentUpdate({ media }) {
    return (
      this.props.media.isPlaying !== media.isPlaying ||
      this.props.media.isLoading !== media.isLoading
    );
  }

  _handlePlayPause = () => {
    this.props.media.playPause();
  };

  getButtonSymbol = () => {
    if (this.props.media.isPlaying) {
      if (this.props.media.isLoading) {
        return <span className="media-player__loading-button" />;
      } else {
        return <Icon iconName="pause" />;
      }
    } else {
      return <Icon iconName="play" />;
    }
  };

  render() {
    const { className, style } = this.props;
    return (
      <button type="button" className={className} style={style} onClick={this._handlePlayPause}>
        {this.getButtonSymbol()}
      </button>
    );
  }
}

export default withMediaProps(PlayPause);
