import { mergeWith } from "lodash";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import union from "lodash/union";
import uniq from "lodash/uniq";
import { AnyAction, Reducer } from "redux";
import {
  FETCH_SHOW_CAMPAIGNS_ACTION,
  FETCH_SHOW_CAMPAIGN_ACTION,
  RESPOND_TO_CAMPAIGN_OFFER_ACTION,
  UPDATE_V2_ITEM_CTA,
} from "../../actions/campaigns";
import {
  ASSIGN_SCRIPT_TO_ITEM,
  CAMPAIGN_ITEM_OVERRIDE,
  CAMPAIGN_ITEMS_OVERRIDE,
  CANCEL_AUDIO_SWAP,
  CREATE_CAMPAIGN_ITEM,
  GET_CAMPAIGN,
  INITATE_AUDIO_SWAP,
  PAUSE_CAMPAIGN_ITEM,
  REMOVE_CAMPAIGN_ITEMS,
  RESET_CAMPAIGN_ITEMS,
  UNPAUSE_CAMPAIGN_ITEM,
  UPDATE_CAMPAIGN_ITEM,
  UPDATE_CAMPAIGN_ITEM_V2,
  UPDATE_CAMPAIGN_ITEMS,
  UPDATE_CAMPAIGN_ITEMS_V2,
} from "../../action_managers/campaigns";
import { HTTPAction, httpReducer, reduceReducers } from "../../lib/create-action";
import { CampaignItemsReduxState, ICampaignItem } from "redcircle-types";
import { campaignItemIsDiscrete } from "src/lib/campaigns";

export const initialState: CampaignItemsReduxState = {
  // TODO: merge these two in the components
  campaignItemByCampaignUUID: {},
  campaignItems: {},
  showCampaignItemUUIDs: {},
  isLoading: false,
  audioSwapReqIsLoading: false,
};

/**
 * Removes discreet campaign Items
 * @param {ICampaignItem[]}
 * @returns {ICampaignItem[]}
 */
const removeDiscreetCampaignItems = (campaignItems?: ICampaignItem[]) => {
  if (!Array.isArray(campaignItems)) return [];

  return campaignItems.filter((campaign) => !campaignItemIsDiscrete(campaign));
};

const resetCampaignItems: Reducer<CampaignItemsReduxState, HTTPAction<"RESET_CAMPAIGN_ITEMS">> = (
  state = initialState,
  action
) => {
  if (action.type === RESET_CAMPAIGN_ITEMS) {
    return {
      ...initialState,
    };
  }

  return state;
};

const getCampaigns = httpReducer(GET_CAMPAIGN, initialState, {
  success: (state = initialState, action) => {
    return {
      ...state,
      campaignItemByCampaignUUID: {
        ...state.campaignItemByCampaignUUID,
        [action.payload.resp.uuid]: action.payload.resp.items?.map(
          ({ item }: { item: ICampaignItem }) => item.uuid
        ),
      },
      campaignItems: {
        ...state.campaignItems,
        ...keyBy(
          action.payload.resp.items?.map(({ item }: { item: ICampaignItem }) => item),
          "uuid"
        ),
      },
    };
  },
});

const createCampaignItem = httpReducer(CREATE_CAMPAIGN_ITEM, initialState, {
  success: (state = initialState, action) => {
    const byCampaignUUID = mapValues(
      groupBy(action.payload.resp, "campaignUUID"),
      (updatedItems, campaignUUID) =>
        uniq(
          updatedItems
            .map(({ uuid }) => uuid)
            .concat(state.campaignItemByCampaignUUID[campaignUUID] ?? [])
        )
    );
    return {
      ...state,
      campaignItemByCampaignUUID: {
        ...state.campaignItemByCampaignUUID,
        ...byCampaignUUID,
      },
      campaignItems: { ...state.campaignItems, ...keyBy(action.payload.resp, "uuid") },
    };
  },
});

const getShowCampaignItem = httpReducer(FETCH_SHOW_CAMPAIGN_ACTION, initialState, {
  success: (state = initialState, action) => {
    const resp = action.payload.resp;
    const campaignItem = resp.campaignItem;
    const showUUID = get(action, ["data", "showUUID"]);

    const campaignItems = {
      ...state.campaignItems,
      [campaignItem.uuid]: campaignItem,
    };

    const showCampaignItemUUIDs = {
      ...state.showCampaignItemUUIDs,
      [showUUID]: union([campaignItem.uuid], get(state.showCampaignItemUUIDs, showUUID, [])),
    };

    return {
      ...state,
      campaignItems,
      showCampaignItemUUIDs,
      campaignItemByCampaignUUID: mergeWith(
        state.campaignItemByCampaignUUID,
        { [campaignItem?.campaignUUID]: [campaignItem?.uuid] },
        (entryValues: string[] = [], newValues: string[] = []) => {
          return uniq([...entryValues, ...newValues]);
        }
      ),
    };
  },
});

const updateFeedCTACampaignItemV2 = httpReducer(UPDATE_V2_ITEM_CTA, initialState, {
  success: (state = initialState, action) => {
    const campaignItem = action.payload.resp;

    const campaignItems = {
      ...state.campaignItems,
      [campaignItem.uuid]: {
        ...(state.campaignItems?.[campaignItem.uuid] ?? {}),
        ...campaignItem,
        // fresh state is used to tell the rest of the app that the state just updated.
        // has implications for podcaster campaign page
        freshState: true,
      },
    };

    const showCampaignItemUUIDs = {
      ...state.showCampaignItemUUIDs,
      [campaignItem.showUUID]: union(
        [campaignItem.uuid],
        state.showCampaignItemUUIDs?.[campaignItem.showUUID] ?? []
      ),
    };

    return {
      ...state,
      campaignItems,
      showCampaignItemUUIDs,
    };
  },
});

const respondToCampaignOffer = httpReducer(RESPOND_TO_CAMPAIGN_OFFER_ACTION, initialState, {
  success: (state = initialState, action) => {
    const campaignItem = action.payload.resp;

    const campaignItems = {
      ...state.campaignItems,
      [campaignItem.uuid]: {
        ...(state.campaignItems?.[campaignItem.uuid] ?? {}),
        ...campaignItem,
        // fresh state is used to tell the rest of the app that the state just updated.
        // has implications for podcaster campaign page
        freshState: true,
      },
    };

    const showCampaignItemUUIDs = {
      ...state.showCampaignItemUUIDs,
      [campaignItem.showUUID]: union(
        [campaignItem.uuid],
        state.showCampaignItemUUIDs?.[campaignItem.showUUID] ?? []
      ),
    };

    return {
      ...state,
      campaignItems,
      showCampaignItemUUIDs,
    };
  },
});

const getShowCampaignItems = httpReducer(FETCH_SHOW_CAMPAIGNS_ACTION, initialState, {
  success: (state = initialState, action) => {
    const resp = action.payload.resp;
    const campaignItems = removeDiscreetCampaignItems(
      get(resp, "campaignItems", []) as ICampaignItem[]
    );
    const showUUID = get(action, ["data", "showUUID"]);

    const campaignItemByUUID = {
      ...state.campaignItems,
      ...keyBy(campaignItems, "uuid"),
    };
    const showCampaignItemUUIDs = {
      ...state.showCampaignItemUUIDs,
      [showUUID]: campaignItems.map(({ uuid }) => uuid),
    };

    const newCampaignItemByCampaignUUID = campaignItems?.reduce<
      CampaignItemsReduxState["campaignItemByCampaignUUID"]
    >((accu, campaignItem) => {
      const { campaignUUID, uuid } = campaignItem;

      if (!Array.isArray(accu[campaignUUID])) accu[campaignUUID] = [];

      accu[campaignUUID].push(uuid);

      return accu;
    }, {});

    return {
      ...state,
      campaignItems: campaignItemByUUID,
      showCampaignItemUUIDs,
      campaignItemByCampaignUUID: mergeWith(
        state.campaignItemByCampaignUUID,
        newCampaignItemByCampaignUUID,
        (entryValues: string[] = [], newValues: string[] = []) => {
          return uniq([...entryValues, ...newValues]);
        }
      ),
    };
  },
});

const updateCampaignItemsV2 = httpReducer(UPDATE_CAMPAIGN_ITEMS_V2, initialState, {
  success: (state = initialState, action) => {
    const initialItems = action.payload.body.requestsByCampaignItemUUID as {
      [key: string]: ICampaignItem;
    };
    const response = action.payload.resp as ICampaignItem[];
    const updatesByCampaignUUID = mapValues(groupBy(response, "campaignUUID"), (items) =>
      items.map(({ uuid }) => uuid)
    );

    const newState = {
      ...state,
      campaignItemByCampaignUUID: {
        ...mapValues(state.campaignItemByCampaignUUID, (uuids, campaignUUID) =>
          uniq([...uuids, ...(updatesByCampaignUUID?.[campaignUUID] ?? [])])
        ),
      },
      campaignItems: {
        ...state.campaignItems,
        ...keyBy(
          /**
           * No longer need to infer state/ BE calculates campaignItem.state for V2 items
           */
          response.map((ci) => ({ ...ci })),
          "uuid"
        ),
      },
      validationErrors: [],
    };

    return newState;
  },
});

const updateCampaignItemV2 = httpReducer(UPDATE_CAMPAIGN_ITEM_V2, initialState, {
  success: (state = initialState, action) => {
    const response = [action.payload.resp as ICampaignItem];
    const updatesByCampaignUUID = mapValues(groupBy(response, "campaignUUID"), (items) =>
      items.map(({ uuid }) => uuid)
    );

    const newState = {
      ...state,
      campaignItemByCampaignUUID: {
        ...mapValues(state.campaignItemByCampaignUUID, (uuids, campaignUUID) =>
          uniq([...uuids, ...(updatesByCampaignUUID?.[campaignUUID] ?? [])])
        ),
      },
      campaignItems: {
        ...state.campaignItems,
        ...keyBy(
          /**
           * No longer need to infer state/ BE calculates campaignItem.state for V2 items
           */
          response.map((ci) => ({ ...ci })),
          "uuid"
        ),
      },
      validationErrors: [],
    };

    return newState;
  },
});

const overrideCampaignItem = httpReducer(CAMPAIGN_ITEM_OVERRIDE, initialState, {
  success: (state = initialState, action) => {
    const updatedItem = action.payload.resp as ICampaignItem;
    return {
      ...state,
      campaignItemByCampaignUUID: {
        ...mapValues(state.campaignItemByCampaignUUID, (uuids, campaignUUID) => {
          if (campaignUUID === updatedItem?.campaignUUID) {
            return uniq([...uuids, updatedItem.uuid]);
          }

          return uuids;
        }),
      },
      campaignItems: {
        ...state.campaignItems,
        [updatedItem.uuid]: {
          ...state.campaignItems[updatedItem.uuid],
          ...updatedItem,
        },
      },
    };
  },
});

const overrideCampaignItems = httpReducer(CAMPAIGN_ITEMS_OVERRIDE, initialState, {
  success: (state = initialState, action) => {
    const updatedItems = action.payload.resp as ICampaignItem[];

    const mappedUpdatedItemsByCampaign = updatedItems.reduce(
      (accu, curr) => {
        if (!Array.isArray(accu[curr.campaignUUID])) accu[curr.campaignUUID] = []; // default add an array for zero state

        accu[curr.campaignUUID].push(curr.uuid);

        return accu;
      },
      {} as { [campaignUUID: string]: string[] }
    );

    return {
      ...state,
      campaignItemByCampaignUUID: {
        ...mapValues(state.campaignItemByCampaignUUID, (uuids, campaignUUID) => {
          return uniq([...uuids, ...(mappedUpdatedItemsByCampaign[campaignUUID] ?? [])]);
        }),
      },
      campaignItems: {
        ...state.campaignItems,
        ...keyBy(
          updatedItems.map((item) => ({ ...state.campaignItems[item.uuid], ...item })),
          "uuid"
        ),
      },
    };
  },
});

// TODO: replace with updateCampaignItemsV2 after all active campaigns are in v2
const updateCampaignItems = httpReducer(UPDATE_CAMPAIGN_ITEMS, initialState, {
  success: (state = initialState, action) => {
    const updateRequests = action.payload.body.updateRequestByCampaignItemUUID as {
      [key: string]: { delete: boolean };
    };
    const resp = action.payload.resp as ICampaignItem[];
    const deletedUUIDs = Object.entries(updateRequests)
      .filter(([, updateBody]) => updateBody.delete)
      .map(([uuid]) => uuid);
    const updatesByCampaignUUID = mapValues(groupBy(resp, "campaignUUID"), (items) =>
      items.map(({ uuid }) => uuid)
    );
    return {
      ...state,
      campaignItemByCampaignUUID: {
        ...mapValues(state.campaignItemByCampaignUUID, (uuids, campaignUUID) =>
          uniq([...uuids, ...(updatesByCampaignUUID?.[campaignUUID] ?? [])]).filter(
            (uuid: string) => uuid && !deletedUUIDs.includes(uuid)
          )
        ),
      },
      campaignItems: {
        ...omit(state.campaignItems, deletedUUIDs),
        ...keyBy(action.payload.resp, "uuid"),
      },
      validationErrors: [],
    };
  },
});

// TODO: replace with deleteCampaignItems after all active campaigns are in v2
const removeCampaignItems = httpReducer(REMOVE_CAMPAIGN_ITEMS, initialState, {
  success: (state = initialState, action) => {
    const itemUUIDsToRemove = (action?.data ?? []) as string[];

    const allItems = Object.values(state.campaignItems);
    const newCampaignItems: Record<string, ICampaignItem> = {};
    const newCampaignItemByCampaignUUID: Record<string, string[]> = {};
    const newShowCampaignItemUUIDs: Record<string, string[]> = {};

    for (const item of allItems) {
      const { uuid, campaignUUID, showUUID } = item;
      if (!itemUUIDsToRemove.includes(uuid)) {
        // Add campaign item to map of campaign Items
        newCampaignItems[uuid] = { ...item };

        // Add campaign items to campaign map
        if (!Array.isArray(newCampaignItemByCampaignUUID[campaignUUID])) {
          newCampaignItemByCampaignUUID[campaignUUID] = [];
        }
        newCampaignItemByCampaignUUID[campaignUUID].push(uuid);

        // Add campaign items to show map
        if (!Array.isArray(newShowCampaignItemUUIDs[showUUID])) {
          newShowCampaignItemUUIDs[showUUID] = [];
        }
        newShowCampaignItemUUIDs[showUUID].push(uuid);
      }
    }

    return {
      ...state,
      campaignItems: newCampaignItems,
      campaignItemByCampaignUUID: newCampaignItemByCampaignUUID,
      showCampaignItemUUIDs: newShowCampaignItemUUIDs,
    };
  },
});

// we don't need anything beyond the default functionality of tracking success/error.
// TODO: add real reducer
// TODO delete and replace with updateCampaignItemV2 post Editable Rap Deploy
const updateCampaignItem = httpReducer(UPDATE_CAMPAIGN_ITEM, initialState, {
  success: (state = initialState) => ({ ...state, validationErrors: undefined }),
});

const initiateAudioSwap = httpReducer(
  INITATE_AUDIO_SWAP,
  initialState,
  {
    success: (state = initialState, action) => {
      const updatedCampaignItem: ICampaignItem = action.payload.resp;
      const { uuid: updatedUUID } = updatedCampaignItem;
      return {
        ...state,
        campaignItems: {
          ...state.campaignItems,
          [updatedUUID]: {
            ...state.campaignItems[updatedUUID],
            ...updatedCampaignItem,
          },
        },
      };
    },
  },
  {
    loadingKeyName: "audioSwapReqIsLoading",
  }
);

const cancelAudioSwap = httpReducer(
  CANCEL_AUDIO_SWAP,
  initialState,
  {
    success: (state = initialState, action) => {
      const updatedCampaignItem: ICampaignItem = action.payload.resp;
      const { uuid: updatedUUID } = updatedCampaignItem;
      return {
        ...state,
        campaignItems: {
          ...state.campaignItems,
          [updatedUUID]: {
            ...updatedCampaignItem,
          },
        },
      };
    },
  },
  {
    loadingKeyName: "audioSwapReqIsLoading",
  }
);

const assignScriptsToItem = httpReducer(ASSIGN_SCRIPT_TO_ITEM, initialState, {
  success: (state = initialState, action) => {
    const resp: ICampaignItem[] = action.payload.resp;

    const newCampaignItems = { ...state.campaignItems };

    for (const item of resp) {
      const { uuid } = item;
      newCampaignItems[uuid] = { ...state.campaignItems[uuid], ...item };
      if (typeof item?.scriptUUID === "string" && item.scriptUUID.length > 0) {
        newCampaignItems[uuid].scriptUUID = item.scriptUUID;
      } else {
        delete newCampaignItems[uuid]?.scriptUUID;
      }
    }

    // Map by campaign UUID
    const newCampaignItemByCampaignUUID: CampaignItemsReduxState["campaignItemByCampaignUUID"] = {};
    const items = Object.values(newCampaignItems);
    for (const item of items) {
      const { campaignUUID, uuid } = item;
      if (Array.isArray(newCampaignItemByCampaignUUID[campaignUUID])) {
        newCampaignItemByCampaignUUID[campaignUUID].push(uuid);
      } else {
        newCampaignItemByCampaignUUID[campaignUUID] = [uuid];
      }
    }

    return {
      ...state,
      campaignItems: newCampaignItems,
      campaignItemByCampaignUUID: newCampaignItemByCampaignUUID,
      isLoading: false,
    };
  },
});

const pauseCampaignItem = httpReducer(PAUSE_CAMPAIGN_ITEM, initialState, {
  success: (state = initialState, action) => {
    const pausedCampaignItem = action.payload.resp as ICampaignItem;

    const newCampaignItem = {
      ...state.campaignItems?.[pausedCampaignItem?.uuid],
      ...pausedCampaignItem,
    };

    return {
      ...state,
      campaignItems: { ...state.campaignItems, [pausedCampaignItem.uuid]: newCampaignItem },
    };
  },
});

const unpauseCampaignItem = httpReducer(UNPAUSE_CAMPAIGN_ITEM, initialState, {
  success: (state = initialState, action) => {
    const pausedCampaignItem = action.payload.resp as ICampaignItem;

    const newCampaignItem = {
      ...state.campaignItems?.[pausedCampaignItem?.uuid],
      ...pausedCampaignItem,
    };

    return {
      ...state,
      campaignItems: { ...state.campaignItems, [pausedCampaignItem.uuid]: newCampaignItem },
    };
  },
});

export default reduceReducers<CampaignItemsReduxState>(
  updateFeedCTACampaignItemV2,
  resetCampaignItems,
  getCampaigns,
  overrideCampaignItem,
  overrideCampaignItems,
  createCampaignItem,
  getShowCampaignItem,
  getShowCampaignItems,
  removeCampaignItems,
  updateCampaignItemsV2,
  updateCampaignItems,
  updateCampaignItemV2,
  updateCampaignItem,
  initiateAudioSwap,
  cancelAudioSwap,
  respondToCampaignOffer,
  assignScriptsToItem,
  pauseCampaignItem,
  unpauseCampaignItem
);
