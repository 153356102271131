import { Button, Drawer } from "antd";
import React, { useContext } from "react";
import { classNames } from "react-extras";
import { ReactComponent as ModalCloseIcon } from "src/icons/modal-close-icon.svg";
import styles from "./Drawer.module.scss";

interface IDrawerContext {
  onClose?: () => void;
  onSubmit?: () => void;
}

// We use this context to pass props down to composed components
const DrawerContext = React.createContext<IDrawerContext>({});

const RCDrawer = ({ children, onClose, contextMenuItems, title, loading, ...props }: any) => {
  return (
    <Drawer
      className={classNames(styles.drawer)}
      placement="right"
      closable={false}
      onClose={onClose}
      styles={{
        body: { padding: 0, overflow: "hidden", display: "flex", flexDirection: "column" },
      }}
      {...props}>
      <DrawerContext.Provider value={{ onClose }}>{children}</DrawerContext.Provider>
    </Drawer>
  );
};

const Header = ({ children, className }: any) => {
  const { onClose } = useContext(DrawerContext);
  return (
    <div className={classNames(className, styles.header)}>
      <div>{children}</div>
      <Button type="link" onClick={onClose}>
        <ModalCloseIcon className={styles.close} width={32} height={32} />
      </Button>
    </div>
  );
};
RCDrawer.Header = Header;

const Body = ({ children, className }: any) => {
  return <div className={classNames(styles.body, className)}>{children}</div>;
};
RCDrawer.Body = Body;

const Footer = ({ children, className }: any) => {
  return <div className={classNames(styles.footer, className)}>{children}</div>;
};
RCDrawer.Footer = Footer;

export default RCDrawer;
