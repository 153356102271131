import { Form, Input } from "antd";
import { useState } from "react";
import { validatePromoCode } from "src/lib/rap";
import { IShow } from "redcircle-types";

const MAX_LENGTH = 50;

export default function RAPSettingsFormPromoCode({ show }: { show?: IShow }) {
  const [validateStatus, setValidateStatus] = useState<string>("");

  return (
    <div>
      <label className="redcircle-form-label" htmlFor="promoCode">
        Promo Code
      </label>
      <Form.Item
        name={"promoCode"}
        className="m-bxxs"
        rules={[
          {
            validator: async (_, value) => {
              if (!show || !value) {
                setValidateStatus("");
                return Promise.reject();
              }
              setValidateStatus("warning");
              const [hasError, message] = await validatePromoCode(show?.uuid, value);
              if (hasError) {
                setValidateStatus("error");
                return Promise.reject(message);
              }
              setValidateStatus("success");
              return Promise.resolve("Success!");
            },
          },
          { required: true, message: "Please enter a promo code." },
          { max: MAX_LENGTH, message: `Promo code must be ${MAX_LENGTH} characters or less.` },
        ]}
        validateStatus={validateStatus as any}
        hasFeedback>
        <Input
          placeholder="example: REDCIRCLE1"
          size="large"
          maxLength={MAX_LENGTH}
          onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
        />
      </Form.Item>
      {validateStatus === "success" && (
        <span className="color-success-dark">Your promo code is available!</span>
      )}
    </div>
  );
}
