/*
this page is not used for now, as we don't have completed designs, but we will reactivate at a later date
when we move the distribution modal into its own page
 */

import { Row } from "antd";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formValueSelector, getFormInitialValues, getFormSyncWarnings } from "redux-form";
import Breadcrumbs from "src/components/lib/breadcrumbs";
import { showSuccess } from "../../../actions/app";
import { fetchDistributions, putDistributions } from "../../../action_managers/distributions";
import { getShow, getYoutubeForShow } from "../../../action_managers/shows";
import { useComponentDidMount } from "../../../lib/hook";
import DistributionForm from "./distribution_form";
import ShowPageWrapper from "./show_page_wrapper";

const ShowDistributionPage = (props) => {
  const showUUID = props.match.params.showUUID;
  const distribution = props.distributions[showUUID];
  const show = props.shows[showUUID];

  useComponentDidMount(() => {
    props.fetchDistributions(showUUID);
    props.getShow(showUUID);
  });

  return (
    <ShowPageWrapper className="show-distribution-page" show={show}>
      <Breadcrumbs
        crumbs={[
          { path: "/shows", name: "All Podcasts" },
          { path: `/shows/${showUUID}`, name: show?.title },
          { path: `/shows/${showUUID}/distribution`, name: "Distribution", active: true },
        ]}
      />

      <Row>
        <ShowPageWrapper.Sidebar />
        <ShowPageWrapper.Body>
          <ShowPageWrapper.Header title="Distribution" />
          <div className="distributions-modal-content">
            <DistributionForm
              validationErrors={props.validationErrors}
              initialValues={distribution}
              getDistributionFormValue={props.getDistributionFormValue}
              isPutting={props.isPutting}
              {...props}
            />
          </div>
        </ShowPageWrapper.Body>
      </Row>
    </ShowPageWrapper>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.user,
    distributions: state.distributions.distributions,
    isLoading: state.distributions.isLoading,
    isPutting: state.distributions.isPutting,
    validationErrors: state.distributions.validationErrors,
    shows: state.shows.shows,
    formSyncWarnings: getFormSyncWarnings("distribution-form")(state),
    formInitialValues: getFormInitialValues("distribution-form")(state),
    getDistributionFormValue: (key) => formValueSelector("distribution-form")(state, key),
    permissions: state.permissions,
  };
}

const mapDispatchToProps = {
  fetchDistributions,
  putDistributions,
  showSuccess,
  getShow,
  getYoutubeForShow,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowDistributionPage));
