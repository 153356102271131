import { Timeline } from "antd";
import dayjs from "dayjs";
import { pluralize } from "redcircle-lib";

export default function CampaignVisualTimeline({
  type = "campaign",
  timeline,
  deadlines,
  pacing,
}: {
  type?: "campaign" | "campaignItem";
  timeline: dayjs.Dayjs[];
  deadlines: any;
  pacing: boolean;
}) {
  if (!timeline || !deadlines) return null;

  const typeToFriendly = type === "campaign" ? "Campaign" : "Campaign Item";
  const dateFormat = "LL LT z";

  const items = [
    {
      children: (
        <span>
          Podcaster response due: <strong>{deadlines?.responseDeadline?.format(dateFormat)}</strong>
        </span>
      ),
    },
    {
      children: (
        <span>
          Podcaster audio due: <strong>{deadlines?.assignAudioDeadline?.format(dateFormat)}</strong>
        </span>
      ),
    },
    {
      children: (
        <span>
          {typeToFriendly} will start: <strong>{timeline?.[0]?.format(dateFormat)}</strong>
        </span>
      ),
    },
  ];

  if (timeline && timeline[1] && pacing) {
    const diff = timeline[1].add(1, "s").diff(timeline[0], "day");
    items.push({
      children: (
        <span>
          {typeToFriendly} will run for:{" "}
          <strong>
            {diff} {pluralize(diff, "day")}
          </strong>
        </span>
      ),
    });
    items.push({
      children: (
        <span>
          {typeToFriendly} will end: <strong>{timeline[1].format(dateFormat)}</strong>
        </span>
      ),
    });
  }

  return <Timeline items={items} className="m-txs" />;
}
