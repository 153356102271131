import fetch from "cross-fetch";
import Cookies from "js-cookie";
import isEmpty from "lodash/isEmpty";
import { RECAPTCHA_SITE_TOKEN } from "../constants/recaptcha";
import getAPIPrefix from "../lib/api_prefix";
import { inviteUUIDCookieName, utmCookieName } from "../lib/user_store";

function fetchUser(userUUID, authToken) {
  return fetch(getAPIPrefix() + "users/me", {
    method: "get",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  });
}

function signIn(email, password) {
  return new Promise((resolve, reject) => {
    window.grecaptcha.execute(RECAPTCHA_SITE_TOKEN, { action: "sign_in" }).then((token) =>
      fetch(getAPIPrefix() + "users/login", {
        method: "post",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
        headers: {
          "content-type": "application/json",
          Recaptcha: token,
        },
      })
        .then(resolve)
        .catch(reject)
    );
  });
}

function signUp(formJSON) {
  let body = { ...formJSON };
  const utmParams = Cookies.get(utmCookieName);
  if (!isEmpty(utmParams)) {
    try {
      body.utmParams = JSON.parse(utmParams);
    } finally {
      // hopefully we don't get here.
    }
  }
  const inviteUUID = Cookies.get(inviteUUIDCookieName);
  if (!isEmpty(inviteUUID)) {
    body.inviteUUID = inviteUUID;
  }

  return new Promise((resolve, reject) => {
    window.grecaptcha.execute(RECAPTCHA_SITE_TOKEN, { action: "sign_up" }).then((token) =>
      fetch(getAPIPrefix() + "users", {
        method: "post",
        headers: {
          "content-type": "application/json",
          Recaptcha: token,
        },
        body: JSON.stringify(body),
      })
        .then(resolve)
        .catch(reject)
    );
  });
}

function update(formJSON, existingUser) {
  return fetch(getAPIPrefix() + "users/" + existingUser.uuid, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + existingUser.authToken,
    },
    body: JSON.stringify(formJSON),
  });
}

function forgotPassword(email) {
  return new Promise((resolve, reject) => {
    window.grecaptcha.execute(RECAPTCHA_SITE_TOKEN, { action: "password_reset" }).then((token) =>
      fetch(getAPIPrefix() + "users/password-reset", {
        method: "post",
        body: JSON.stringify({
          email: email,
        }),
        headers: {
          "content-type": "application/json",
          Recaptcha: token,
        },
      })
        .then(resolve)
        .catch(reject)
    );
  });
}

function recoverPassword(email, passwordToken) {
  return fetch(getAPIPrefix() + "users/login-with-password-reset-token", {
    method: "post",
    body: JSON.stringify({
      email: email,
      passwordResetToken: passwordToken,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
}

function connectStripe(code, securityToken, authToken) {
  return fetch(getAPIPrefix() + "users/connect-stripe", {
    method: "POST",
    body: JSON.stringify({
      accessCode: code,
      securityToken: securityToken,
    }),
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  });
}

function sendVerificationEmail(authToken) {
  return fetch(getAPIPrefix() + "users/send-verification-email", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  });
}

function verifyEmail(verificationToken, authToken) {
  return fetch(getAPIPrefix() + "users/verify-email", {
    method: "POST",
    body: JSON.stringify({
      emailVerificationToken: verificationToken,
    }),
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + authToken,
    },
  });
}

function userStripeStatus(user) {
  return fetch(getAPIPrefix() + `users/${user.uuid}/stripe-status`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + user.authToken,
    },
  });
}

let userAPI = {
  fetchUser,
  signIn,
  signUp,
  forgotPassword,
  recoverPassword,
  update,
  connectStripe,
  sendVerificationEmail,
  verifyEmail,
  userStripeStatus,
};

export default userAPI;
