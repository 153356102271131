import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import numeral from "numeral";
import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { If } from "react-extras";
import { connect } from "react-redux";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import RCButton from "src/components/lib/button/button";
import { MediaFileActionManager } from "../../../action_managers/shows";
import { budgetTypeToFriendlyNames } from "../../../constants/budgets";
import { dealState } from "../../../constants/deals";
import { colors } from "../../../lib/design";
import MediaPlayer from "../../lib/media_player/media_player";
import WithPublicShow from "../../lib/with-public-show";

class DealBox extends Component {
  crossPromoDeal = (dealContent, i) => {
    if (!dealContent.mediaFileUUIDs) {
      return null;
    }
    return (
      <div key={i}>
        <div className="deal-box__content__show-label-row flex-row-container">
          <span className="deal-box__content__show-label redcircle-form-label">Podcast:</span>
          {this.props.showEdit && (
            <span>
              <span
                className="bold pointer text-primary m-rs"
                onClick={this.props.showRequestModal}>
                Edit
              </span>
              <span className="bold pointer text-primary" onClick={this.props.deleteRequest}>
                Remove
              </span>
            </span>
          )}
        </div>

        <WithPublicShow uuid={get(dealContent, "showUUID")}>
          {(show) => (
            <div className="deal-box__content__show-value deal-box__value">{show.title}</div>
          )}
        </WithPublicShow>
        <div className="deal-box__content__audio-label redcircle-form-label">Audio:</div>
        <MediaPlayer
          key={dealContent.mediaFileUUIDs[128] || i.toString()}
          user={this.props.user}
          fetchMediaFile={this.props.fetchMediaFile}
          mediaFiles={this.props.mediaFiles}
          mediaFileUUID={dealContent.mediaFileUUIDs[128]}
        />
        <If condition={!!dealContent.airsAt}>
          <div className="deal-box__content__date-label redcircle-form-label">Campaign:</div>
          <div className="deal-box__content__air-at deal-box__value">
            {this.getBudgetLine(dealContent)}
            Beginning {moment.unix(get(dealContent, "airsAt", 0)).format("LLL")}
          </div>
          <If condition={!!dealContent.budgetUUID && !!this.props.budgets[dealContent.budgetUUID]}>
            {this.renderProgressBar(dealContent, `tooltip-${i}`)}
          </If>
        </If>
      </div>
    );
  };

  getBudgetLine = (dealContent) => {
    let type = get(dealContent, "budgetType");
    let amount = get(dealContent, "budgetAmount");
    if (!type || !amount) {
      return null;
    }

    let text = "";
    if (type === "insertions") {
      text = amount.toLocaleString() + " Pre-Roll Ad Insertions";
    } else {
      text = "$" + Math.round(amount / 100000).toFixed(2) + "in Ads";
    }
    if (dealContent.estimatedDurationDays) {
      text = text + ` (about ${dealContent.estimatedDurationDays} days)`;
    }
    return [text, <br key={"2"} />];
  };

  renderProgressBar = (dealContent, id) => {
    const current = get(this.props.budgets, [dealContent.budgetUUID, "current"]);
    const total = get(this.props.budgets, [dealContent.budgetUUID, "total"]);
    const type = get(this.props.budgets, [dealContent.budgetUUID, "type"]);
    const percent = Math.min(Math.ceil((current / total) * 100), 100);
    return (
      <OverlayTrigger overlay={this.progressTooltip(current, total, type, id)} placement="top">
        <div>
          <Progress
            percent={percent}
            strokeWidth={1}
            status={"active"}
            theme={{
              active: {
                color: colors.mediumBlue,
                symbol: " ",
              },
            }}
          />
        </div>
      </OverlayTrigger>
    );
  };
  progressTooltip = (current, total, type, id) => {
    const divisor = type === "insertions" ? 1 : 100000;
    return (
      <Tooltip id={id}>
        <If condition={type === "funds"} />
        {numeral(current / divisor).format("0,0.[00]")}/
        {numeral(total / divisor).format("0,0.[00]")} {budgetTypeToFriendlyNames[type]}
      </Tooltip>
    );
  };

  renderRequestButton = () => {
    const dealContents = get(this.props.dealRequest, "dealContents", []);
    const hasDealContent =
      dealContents.length && dealContents.every(({ mediaFileUUIDs }) => mediaFileUUIDs);
    if (hasDealContent) {
      return null;
    }

    if (this.props.showRequestModal) {
      return (
        <div className="deal-box__add-button">
          <RCButton type="primary" size="small" onClick={this.props.showRequestModal}>
            Add Your Promotion
          </RCButton>
        </div>
      );
    }

    // Their deal request
    return <div className="deal-box__no-request-text">No Promotion Yet</div>;
  };

  render() {
    if (!this.props.dealRequest || isEmpty(this.props.dealRequest)) {
      return null;
    }

    let dealContents = get(this.props.dealRequest, "dealContents", []);
    return (
      <div className={`deal-box ${this.props.state}`}>
        <div className="deal-box__title">
          <span className="title">{get(this.props, "title")}</span>
          <If condition={this.props.state === dealState.accepted}>
            <span className="m-rl">Accepted</span>
          </If>
        </div>
        <div className="deal-box__content">
          {dealContents.map(this.crossPromoDeal)}
          {this.renderRequestButton()}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({ mediaFiles: state.mediaFiles, user: state.user.user, budgets: state.budgets }),
  {
    fetchMediaFile: (mediaFileUUID, authToken) => {
      if (mediaFileUUID) {
        return new MediaFileActionManager({ mediaFileUUID, authToken }).run();
      }
    },
  }
)(DealBox);
