import { AnyAction } from "redux";
import {
  SIGN_UP_REQUEST_ERROR,
  SIGN_UP_REQUEST_INVALID,
  SIGN_UP_REQUEST_START,
  SIGN_UP_REQUEST_SUCCESS,
} from "../../actions/sign_up";

const initialState = {
  isLoading: false,
  validationErrors: [],
};

export default function signUp(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SIGN_UP_REQUEST_START:
      return Object.assign({}, state, {
        isLoading: true,
        validationErrors: [],
      });
    case SIGN_UP_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case SIGN_UP_REQUEST_INVALID:
      return Object.assign({}, state, {
        isLoading: false,
        validationErrors: action.validationErrors,
      });
    case SIGN_UP_REQUEST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
}
