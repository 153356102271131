import keyBy from "lodash/keyBy";
import {
  getBudgetByCampaignUUIDActionName,
  getBudgetByDealUUIDActionName,
} from "../../action_managers/budgets";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { BudgetsReduxState } from "./types";

const initialState: BudgetsReduxState = {};
const getBudgetByDealUUID = httpReducer(getBudgetByDealUUIDActionName, initialState, {
  success: (state, action) => ({ ...state, ...action.payload.resp }),
  failure: (state, action) => ({ ...state, error: action.payload.error }),
});

const getBudgetByCampaignUUID = httpReducer(getBudgetByCampaignUUIDActionName, initialState, {
  success: (state, action) => ({ ...state, ...keyBy(action.payload.resp, "uuid") }),
  failure: (state, action) => ({ ...state, error: action.payload.error }),
});

export default reduceReducers(getBudgetByDealUUID, getBudgetByCampaignUUID);
