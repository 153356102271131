import { Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { ReactNode } from "react";
import { If } from "react-extras";
import { ReactComponent as InfoIcon } from "src/icons/info.svg";
import { ICampaign } from "../../../reducers/campaigns/types";
import RCButton from "../button/button";
import ContextMenu from "../context_menu";
import type { MenuItems } from "../context_menu/context_menu";
import Divider from "../divider";
import ExternalLink from "../external_link";
import "./summary-page-sections.scss";

interface ISummaryPageSectionWrapper {
  title: string;
  children?: React.ReactNode;
  pageNumber?: number;
  updatePage?: (pageNumber?: number) => void;
  menuItems?: MenuItems;
  noDivider?: boolean;
}

export const SummaryPageSectionWrapper = ({
  title,
  children,
  pageNumber,
  updatePage,
  menuItems,
  noDivider,
}: ISummaryPageSectionWrapper) => {
  return (
    <div className={"summary-page-section-wrapper m-bxs"}>
      <div className={"summary-page-section-wrapper--header flex-row-container align-center"}>
        <h6 className="capitalize fs-15 lh-s m-a0">{title}</h6>
        <div className="flex-1" />
        <If condition={!!updatePage}>
          <RCButton
            type="link"
            onClick={() => updatePage && updatePage(pageNumber)}
            aria-label={`Edit ${title}`}>
            Edit
          </RCButton>
        </If>
        {menuItems && <ContextMenu menuItems={menuItems} noCircle />}
      </div>
      {!noDivider && <Divider marginTop={24} marginBottom={24} />}
      <div className="summary-page-section-wrapper--body">{children}</div>
    </div>
  );
};

interface ISummaryPageInfo {
  unbold?: boolean;
  title: string;
  text?: string | React.JSX.Element;
  newline?: boolean;
  noColon?: boolean;
  tooltipText?: string;
  subCopy?: string | React.JSX.Element;
  dangerouslySetInnerHTML?: string;
}

export const SummaryPageInfo = ({
  unbold,
  title,
  text,
  newline,
  noColon,
  tooltipText,
  subCopy,
  dangerouslySetInnerHTML,
}: ISummaryPageInfo) => (
  <div className="m-bxs fs-13 lh-s">
    <span className={`${!unbold && "bold"}`}>
      {title}
      {!noColon ? ":" : ""}{" "}
    </span>
    {tooltipText && (
      <Tooltip title={tooltipText} className="m-rxxxs">
        <InfoIcon style={{ marginBottom: "-3px" }} />
      </Tooltip>
    )}
    {newline && <br />}
    {text}
    {dangerouslySetInnerHTML && (
      <div
        dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }}
        className="text-block-expanded"
      />
    )}
    <If condition={!!subCopy}>
      <div className={"small m-txxs"}>{subCopy}</div>
    </If>
  </div>
);

interface ISummaryPageInfoSecondTier {
  title: ReactNode;
  text: string | React.JSX.Element;
}

export const SummaryPageInfoSecondTier = ({ title, text }: ISummaryPageInfoSecondTier) => (
  <div className="m-bxs flex-row-container">
    <div className={"flex-1"}>{title}</div>
    <div>{text}</div>
  </div>
);

interface ICampaignDetailsSummaryPageInfo {
  campaign: ICampaign;
}

export const CampaignDetailsSummaryPageInfo = ({ campaign }: ICampaignDetailsSummaryPageInfo) => {
  if (!campaign || isEmpty(campaign)) return null;
  return (
    <>
      <SummaryPageInfo title={"Product or Service"} text={campaign.productName} />
      <SummaryPageInfo title={"Brand Name"} text={campaign.brand?.name} />
      <SummaryPageInfo title={"Brand Description"} text={campaign.brand?.description} newline />
      <SummaryPageInfo
        title={"Brand Website"}
        text={
          campaign.brand?.domain && (
            <ExternalLink to={campaign.brand.domain}>
              <strong>{campaign.brand.domain}</strong>
            </ExternalLink>
          )
        }
      />
      {campaign.referenceNumber && (
        <SummaryPageInfo title="Reference Number" text={campaign.referenceNumber} />
      )}
    </>
  );
};
