import { ICampaign } from "redcircle-types";

type CampaignFieldsToCheck =
  | "name"
  | "productName"
  | "goal"
  | "productExchangeInstructions"
  | "requiresEndorsement"
  | "script"
  | "contentMediaFileUUID"
  | "brand.name"
  | "brand.domain"
  | "brand.description";

const fieldToFriendlyName: Record<CampaignFieldsToCheck, string> = {
  name: "Campaign Name",
  productName: "Product or Service Name",
  goal: "What do you want to accomplish?",
  "brand.name": "Brand Name",
  "brand.domain": "Website",
  "brand.description": "Brand Description",
  productExchangeInstructions: "Instructions for podcasters to redeem product online",
  script: "Talking points or script",
  contentMediaFileUUID: "Upload MP3",
  requiresEndorsement: "Personal Endorsement from the Podcaster?",
};

/* validateCampaign returns [bool, Array[strings]]

  returns [true, []] if the form is complete otherwise [false, [list of fields that are missing]]
 */
export const validateCampaign: (campaign?: ICampaign) => [boolean, string[], string[]] = (
  campaign
) => {
  if (!campaign) {
    return [false, [], []];
  }

  const noEmptyFields: CampaignFieldsToCheck[] = [
    "name",
    "brand.name",
    "brand.domain",
    "brand.description",
    "productName",
    "requiresEndorsement",
  ];
  const emptyFields = noEmptyFields.filter((field) => {
    // check if field is split
    let value;
    if (field.includes(".")) {
      const [parent, child] = field.split(".");
      value = campaign?.[parent]?.[child];
    } else {
      value = campaign?.[field as keyof typeof campaign];
    }

    switch (typeof value) {
      case "string":
        return value?.length === 0;
        break;
      case "boolean":
        return false;
      case "undefined":
        return true;
      default:
        return true;
    }
  });

  if (campaign.productExchangeType === "instructions") {
    if ((campaign?.productExchangeInstructions?.length ?? 0) <= 0) {
      emptyFields.push("productExchangeInstructions");
    }
  }

  // if (!campaign.contentMediaFileUUID) {
  //   emptyFields.push("contentMediaFileUUID");
  // }

  const friendlyNames = emptyFields.map((field) => fieldToFriendlyName[field]);

  return [emptyFields.length === 0, friendlyNames, emptyFields];
};
