import { CheckOutlined, DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { classNames } from "react-extras";
import Button from "src/components/lib/button";
import styles from "./select_dropdown.module.scss";

interface IProps {
  onSelect: (option: string) => any;
  value: string;
  options: string[];
}

// this isn't really a <select /> component - it opens up into a form dropdown
export default function FormDropdownSelect({ onSelect, value, options = [] }: IProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>();

  const handleVisibleChange = (flag: boolean) => setShowDropdown(flag);

  const handleClickOption = (e: React.MouseEvent<HTMLAnchorElement>, value: string) => {
    e.preventDefault();
    setSelectedOption(value);
  };

  const handleClickSubmit = () => {
    if (selectedOption) {
      onSelect(selectedOption);
      setSelectedOption(undefined);
      setShowDropdown(false);
    }
  };

  const renderDropdown = () => {
    return (
      <Menu className={styles["dropdown-menu"]}>
        <div className={styles["dropdown-menu-body"]}>
          {options.map((option) => (
            <div key={option}>
              {selectedOption === option && <CheckOutlined className={styles.check} />}
              <a
                href=""
                className={classNames(
                  styles["dropdown-menu-option"],
                  selectedOption !== option && styles.spacer
                )}
                onClick={(e) => handleClickOption(e, option)}>
                {option}
              </a>
            </div>
          ))}
        </div>
        <div className={styles["dropdown-menu-footer"]}>
          <Button type="primary" disabled={!selectedOption} onClick={handleClickSubmit}>
            Ok
          </Button>
        </div>
      </Menu>
    );
  };

  return (
    <Dropdown
      visible={showDropdown}
      onVisibleChange={handleVisibleChange}
      overlay={renderDropdown()}
      trigger={["click"]}>
      <a
        className={classNames("ant-dropdown-link", styles["dropdown-link"])}
        onClick={(e) => e.preventDefault()}>
        <span>{value}</span>
        <DownOutlined />
      </a>
    </Dropdown>
  );
}
