import { store } from "src";
import getAPIPrefix from "src/lib/api_prefix";

export type CancelInvitationsInCampaignRequest = {
  campaignUUID: string;
};

/**
 *
 * Cancels all pending vetting invitations in campaign (in cart)
 *
 * This end point does not return values to update the redux state with so
 * it will be a stand alone api
 * @param param0
 * @returns
 */
export const cancelInvitationsInCampaign = ({
  campaignUUID,
}: CancelInvitationsInCampaignRequest) => {
  const { user: userState } = store.getState();
  const { user } = userState;

  return fetch(`${getAPIPrefix()}campaigns/${campaignUUID}/cancel-vetting`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + user.authToken,
    },
  });
};
