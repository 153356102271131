import React, { useEffect } from "react";
import ReactQuill, { Quill, ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";

const Link = Quill.import("formats/link");

// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = ["http", "https", "mailto", "tel", "radar", "rdar", "smb", "sms"];

/**
 * Sanitizes link urls to append 'http://' when user adds a url.
 *
 * Source:
 * https://stackoverflow.com/questions/72349562/angular-how-can-i-append-http-or-https-to-hyperlink-in-a-quill-editor
 */
class CustomLinkSanitizer extends Link {
  static sanitize(url: string) {
    // Run default sanitize method from Quill
    const sanitizedUrl: string = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === "about:blank") return sanitizedUrl;

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (protocol: string) {
      return sanitizedUrl.startsWith(protocol);
    });

    if (hasWhitelistedProtocol) return sanitizedUrl;

    // if not, then append only 'http' to not to be a relative URL
    return `http://${sanitizedUrl}`;
  }
}

Quill.register(CustomLinkSanitizer, true);

interface IQuillEditor extends Pick<ReactQuillProps, "onChange" | "value" | "placeholder"> {}

const QuillEditor = (props: IQuillEditor) => {
  const { value, onChange, placeholder = "" } = props;

  // quillRef is required to dynamically update placeholders
  const quillRef = React.useRef<ReactQuill>(null);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.root.dataset.placeholder = placeholder;
    }
  }, [placeholder]);

  /**
   * Manually setting default toolbar options
   */
  const toolbarOptions = [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "link", { list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ];

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="quill-editor"
      modules={{
        toolbar: toolbarOptions,
        clipboard: {
          matchVisual: false,
        },
      }}
    />
  );
};

export default QuillEditor;
