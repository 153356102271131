import React from "react";
import { Helmet } from "react-helmet-async";
import { createTitle } from "../../lib/strings";

export function withPageTitle(WrappedComponent, pageTitle) {
  return class extends React.Component {
    render() {
      return (
        <React.Fragment>
          <Helmet>
            <title>{createTitle(pageTitle)}</title>
          </Helmet>
          <WrappedComponent {...this.props} />
        </React.Fragment>
      );
    }
  };
}
