import get from "lodash/get";
import sortBy from "lodash/sortBy";
import { Component } from "react";
import { If } from "react-extras";
import { connect } from "react-redux";
import Breadcrumbs from "src/components/lib/breadcrumbs/breadcrumbs";
import Loading from "src/components/lib/loading/loading";
import Page from "src/components/lib/page";
import { ShowsForUserActionManager } from "../../../action_managers/shows";
import { fetchCategoryShows, fetchCategoryShowsV2 } from "../../../action_managers/show_search";
import { itunesCategories } from "../../../data/itunes_categories";
import { getAllAdjacentAudienceBucketSizes, getBiggestShow, getUserShows } from "../../../lib/show";
import { AlbumGrid } from "../../lib/album_art";
import Spinner from "../../lib/spinner";
import CategoryList from "../browse_promotion/category_list";

class BrowseCategory extends Component {
  componentDidMount() {
    this.props
      .fetchShowsForUser(this.props.user)
      .then(() => this.fetchShows(this.props.match.params.categoryUUID));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.getCategoryUUID(this.props) !== this.getCategoryUUID(nextProps) &&
      this.getCategoryUUID()
    ) {
      this.fetchShows(nextProps.match.params.categoryUUID);
    }
  }

  getCategoryUUID(props = this.props) {
    return props.match.params.categoryUUID;
  }

  fetchShows = (categoryUUID) => {
    this.props.fetchCategoryShowsV2({
      showUUIDs: [this.props.biggestShowUUID],
      rootCategoryUUID: categoryUUID,
    });
  };

  renderShows = () => {
    let shows = this.getShows();
    return <AlbumGrid shows={shows} />;
  };

  getShows = () => {
    const shows = get(this.props.browsePromotion.categoryShows, this.props.biggestShowUUID, []);
    const rankedShows = sortBy(shows, "rankScore").reverse();
    const directoryShows = get(
      this.props.browsePromotion.categoryDirectoryShows,
      [this.getCategoryUUID()],
      []
    );
    const allShows = rankedShows.concat(directoryShows);
    const allNonRemoteShows = allShows.filter((show) => !show.isRemote);
    return allNonRemoteShows;
  };

  getTitle = () => {
    return itunesCategories.find(({ uuid }) => this.getCategoryUUID() === uuid).name;
  };

  render() {
    let isLoading = this.props.browsePromotion.categoryShows.isLoading;
    const shows = this.getShows();
    return (
      <Page>
        <Breadcrumbs crumbs={[{ name: "Back", path: "/promotions/browse" }]} />
        <Page.Title>{this.getTitle()}</Page.Title>
        <Page.Divider />
        <Page.Section>
          {isLoading && <Loading />}
          <If condition={!isLoading}>{this.renderShows()}</If>
          <If condition={!isLoading && !shows.length}>
            <div className="browse-category-page__no-shows-content">
              There are no podcasts in this category that are similar in size to yours. Check back
              again soon.
            </div>
          </If>
          <If condition={!isLoading}>
            <CategoryList />
          </If>
        </Page.Section>
      </Page>
    );
  }
}

BrowseCategory.propTypes = {};

export default connect(
  (state) => {
    const userShows = getUserShows(state.user.user, state.shows.shows);
    const biggestShowUUID = getBiggestShow(userShows).uuid;
    const bucketedAudienceSizes = getAllAdjacentAudienceBucketSizes(userShows);
    return {
      browsePromotion: state.browsePromotion,
      userAdjacentAudienceSizes: bucketedAudienceSizes,
      biggestShowUUID: biggestShowUUID,
      user: state.user.user,
    };
  },
  {
    fetchCategoryShows,
    fetchCategoryShowsV2,
    fetchShowsForUser: (user) => new ShowsForUserActionManager({ user }).run(),
  }
)(BrowseCategory);
