import React from "react";
import { If } from "react-extras";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import RCButton from "src/components/lib/button/button";
import { showSuccess } from "../../../actions/app";
import { showModal } from "../../../actions/modal";
import { permissionTypes } from "../../../constants/permission_roles";
import { tierLevel, tierLevels } from "../../../constants/tiers";
import { useSelectorTS } from "../../../hooks/redux-ts";
import { getPricingPageURL } from "../../../lib/config";
import { useCanAccess } from "../../../lib/permissions";
import { CONFIRMATION_MODAL, DOWNGRADE_BLOCK_MODAL } from "../../modals/modal_root";
import { getMailtoLink, IPlanCopy, tierOrder } from "./account_plan_utils";

export interface IAccountPlanItemProps extends IPlanCopy {
  currentTier: tierLevel;
}

export const AccountPlanItem = (props: IAccountPlanItemProps) => {
  const {
    title,
    level,
    desc,
    ctaText = "Change Plan",
    downgradeCheck,
    onSuccess,
    canTrial = false,
    currentTier,
  } = props;
  const dispatch = useDispatch();
  const user = useSelectorTS((state) => state.user.user);
  const history = useHistory();
  const isOwner = useCanAccess(permissionTypes.updateBilling);

  const handleCTAClick = () => {
    if (tierOrder[currentTier] < tierOrder[level]) {
      history.push(`/account/upgrade/${level}`);
    } else {
      if (downgradeCheck) {
        downgradeCheck(dispatch).then((result: any) => {
          if (result === false) {
            return;
          }
          if (result?.length === 0) {
            onSuccess ? onSuccess(dispatch) : history.push(`/account/downgrade/${level}`);
          } else {
            dispatch(
              showModal(DOWNGRADE_BLOCK_MODAL, {
                level,
                blockers: result,
                title,
              })
            );
          }
        });
      }
    }
  };
  return (
    <div
      key={level}
      className={"p-as flex-row-container align-center"}
      style={{ border: `1px solid #ebe8e8` }}>
      <div className={"flex-1"}>
        <h4>{title}</h4>
        <p className={"m-bxxs"}>
          {/*pin this to the cheaper price*/}
          {desc({ interval: "annual" })}
        </p>
        <div className={"small"}>
          {!user.userAttributes.hasReceivedFreeTrial && canTrial && "Free 7 Day Trial"}
        </div>
      </div>
      <div className={"m-lxs text-end flex-row-container align-center"}>
        <RCButton
          type="secondary"
          size="small"
          onClick={() => {
            window.location.href = getPricingPageURL();
          }}>
          Learn More
        </RCButton>
        <If condition={!isOwner}>
          <div className={"small m-lxxs"}>Contact your Org Owner to change your plan</div>
        </If>
        <If condition={isOwner}>
          <RCButton type="primary" size="small" className="m-lxs" onClick={handleCTAClick}>
            {ctaText}
          </RCButton>
        </If>
      </div>
    </div>
  );
};
