import { createContext, useContext, useEffect } from "react";
import { useAudioPlayer } from "../hooks/useAudioPlayer";

export const AudioPlayerContext = createContext({} as ReturnType<typeof useAudioPlayer>);
export const useAudioPlayerContext = () => useContext(AudioPlayerContext);

export const AudioPlayerWrapper = ({
  children,
  src,
  title,
  autoplay = false,
}: {
  children: React.ReactNode;
  src?: string;
  title?: string;
  autoplay: boolean;
}) => {
  const values = useAudioPlayer({ autoplay });
  const { setAudio, setTitle } = values;

  useEffect(() => {
    if (src) setAudio(src);
  }, [src]);

  useEffect(() => {
    if (title) setTitle(title);
  }, [title]);

  return <AudioPlayerContext.Provider value={values}>{children}</AudioPlayerContext.Provider>;
};
