import { Divider, Modal, ModalProps } from "antd";
import classNames from "classnames";
import React, { useCallback, useContext } from "react";
import { Button } from "../Button";
import { Loading } from "../Loading";
import styles from "./Modal.module.scss";

// MODAL COMPONENTS
type TRCModal = React.FC<IModalProps> & {
  TitleSection: React.FC<IBodyProps>; // for more complex title sections
  Title: React.FC<IBodyProps>; // use this for simple title sections
  Body: React.FC<IBodyProps>;
  Sidebar: React.FC<IBodyProps>;
  Footer: React.FC<IBodyProps & { noBorder?: boolean }>;
  CloseButton: React.FC<IButtonProps>;
  SubmitButton: React.FC<IButtonProps>;

  // passthrough for antd modal functions
  useModal: typeof Modal.useModal;
};

// MODAL PROPS
export interface IModalProps {
  open: boolean;
  className?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  children: React.ReactNode;
  loading?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  maskClosable?: boolean;
  isViewportHieght?: boolean; // Set to true to make the modal height the size of the window
  afterClose?: ModalProps["afterClose"]; // Run func after modal close animation is completed.
  destroyOnClose?: boolean;
}

interface IBodyProps {
  children?: React.ReactNode;
  className?: string;
  noDivider?: boolean;
}

interface IButtonProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

// We use this context to pass Modal props down to composed components
const ModalContext = React.createContext<Partial<IModalProps>>({});

const MODAL_STYLE = { top: 15 };
const MODAL_WIDTHS: Record<string, number> = {
  xs: 500,
  sm: 800,
  md: 1200,
  lg: 1600,
  xl: 2000,
};

// This is BaseModalV3 - looking to reduce boolean flag props and make it composable
const RCModal: TRCModal = ({
  open,
  className,
  onClose,
  onSubmit,
  children,
  loading = false,
  size = "md",
  maskClosable = true,
  isViewportHieght = false,
  afterClose,
  destroyOnClose,
}: IModalProps) => {
  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Modal
      open={open}
      className={classNames(styles.modal, isViewportHieght && styles["viewport-height"], className)}
      style={MODAL_STYLE}
      width={MODAL_WIDTHS[size]}
      onCancel={handleClose}
      closable={onClose ? true : false}
      footer={null}
      afterClose={afterClose}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}>
      <ModalContext.Provider value={{ onClose: handleClose, onSubmit, size, loading }}>
        {loading && (
          <Body className="flex-column-container justify-center p-am">
            <Loading />
          </Body>
        )}
        {!loading && children}
      </ModalContext.Provider>
    </Modal>
  );
};

const TitleSection: React.FC<IBodyProps> = ({ children, className, noDivider }) => {
  const { size } = useContext(ModalContext);
  return (
    <div className={classNames(className, styles.title, size && styles[size])}>
      <div className={styles["title-inner"]}>
        {children}
        {!noDivider && <Divider className="m-txs m-bxs" />}
      </div>
    </div>
  );
};

const Title: React.FC<IBodyProps> = ({ children, className, noDivider }) => (
  <TitleSection noDivider={noDivider} className={className}>
    <h2>{children}</h2>
  </TitleSection>
);

const Body: React.FC<IBodyProps> = ({ children, className }) => {
  const { size } = useContext(ModalContext);
  return (
    <div className={classNames(className, styles.body, size && styles[size])}>
      <div className={classNames(className, styles["body-inner"])}>{children}</div>
    </div>
  );
};

const Sidebar: React.FC<IBodyProps> = ({ children, className }) => (
  <div className={classNames(className, styles.sidebar)}>
    <div className={styles["sidebar-inner"]}>{children}</div>
  </div>
);

const Footer: React.FC<IBodyProps & { noBorder?: boolean }> = ({
  children,
  className,
  noBorder,
}) => {
  const { size } = useContext(ModalContext);
  return (
    <div
      className={classNames(
        className,
        styles.footer,
        !noBorder && styles["footer-border"],
        size && styles[size]
      )}>
      <div className={styles["footer-inner"]}>{children}</div>
    </div>
  );
};

const CloseButton: React.FC<IButtonProps> = ({ children = "Close", className }) => {
  const { onClose } = useContext(ModalContext);
  return (
    <Button type="link" size="large" className={classNames("p-l0", className)} onClick={onClose}>
      {children}
    </Button>
  );
};

const SubmitButton: React.FC<IButtonProps> = ({
  children = "Ok",
  className,
  onClick,
  disabled,
  isLoading,
}) => {
  const { onSubmit } = useContext(ModalContext);

  const handleSubmit = useCallback(() => {
    if (onClick) onClick();
    if (onSubmit) onSubmit();
  }, [onClick, onSubmit]);

  return (
    <Button
      type="primary"
      size="large"
      htmlType="submit"
      className={className}
      onClick={handleSubmit}
      disabled={disabled}
      loading={isLoading}>
      {children}
    </Button>
  );
};

RCModal.TitleSection = TitleSection;
RCModal.Title = Title;
RCModal.Sidebar = Sidebar;
RCModal.Body = Body;
RCModal.Footer = Footer;
RCModal.CloseButton = CloseButton;
RCModal.SubmitButton = SubmitButton;
RCModal.useModal = Modal.useModal;
export default RCModal;
