import * as Sentry from "@sentry/browser";
import * as Moment from "moment";
import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
import momentLocalizer from "react-widgets-moment";
import { ConfigProvider } from "redcircle-ui";
import { autoSignInUser } from "src/action_managers/auto_sign_in";
import App from "./components/app";
import AppErrorBoundary from "./components/app_error_boundary";
import { getStripePublicKey } from "./lib/config";
import { unregister } from "./registerServiceWorker";
import configureStore from "./store";

import "src/styles/global.scss";
import "./dayjs";
import "./wdyr.ts";

// TODO - real localization later.
Moment.locale("en");
momentLocalizer();

// uncomment to log places where we can save unnecessary re-renders.
// if (process.env.NODE_ENV === "development") {
//   // tslint:disable-next-line:no-var-requires
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

// Initialize sentry
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://9f96d8d5096749079e69434d938e2b4b@sentry.io/1432611",
  });
}

// unregister service worker
unregister();

export const store = configureStore();

const render = (Component: React.ComponentType) => {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(
    <HelmetProvider>
      <Provider store={store}>
        <StripeProvider apiKey={getStripePublicKey()}>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  borderRadius: 4,
                  borderRadiusLG: 4,
                  borderRadiusSM: 4,
                },
              },
            }}>
            <Router>
              <AppErrorBoundary>
                <Component />
              </AppErrorBoundary>
            </Router>
          </ConfigProvider>
        </StripeProvider>
      </Provider>
    </HelmetProvider>
  );
};

store.dispatch(autoSignInUser()).then(() => {
  render(App);
});
