import { Form, Input } from "antd";
import { FaFacebook, FaInstagram, FaTiktok, FaTwitter, FaYoutube } from "react-icons/fa";

const SOCIAL_LINKS: Record<string, any> = {
  twitterLink: {
    label: "Twitter",
    placeholder: "https://twitter.com/getredcircle",
    icon: <FaTwitter style={{ height: 48, width: 48 }} />,
    validationRegex: /^https?:\/\/(www\.)?twitter\.com\/.*$/,
    errorMessage: "Link must start with https:// and include twitter.com",
  },
  instagramLink: {
    label: "Instagram",
    placeholder: "https://instagram.com/getredcircle",
    icon: <FaInstagram style={{ height: 48, width: 48 }} />,
    validationRegex: /^https?:\/\/(www\.)?instagram\.com\/.*$/,
    errorMessage: "Link must start with https:// and include instagram.com",
  },
  youtubeLink: {
    label: "YouTube",
    placeholder: "https://youtube.com/@getredcircle",
    icon: <FaYoutube style={{ height: 48, width: 48 }} />,
    validationRegex: /^https?:\/\/(www\.)?youtube\.com\/.*$/,
    errorMessage: "Link must start with https:// and include youtube.com",
  },
  tikTokLink: {
    label: "TikTok",
    placeholder: "https://tiktok.com/@getredcircle",
    icon: <FaTiktok style={{ height: 48, width: 48 }} />,
    validationRegex: /^https?:\/\/(www\.)?tiktok\.com\/.*$/,
    errorMessage: "Link must start with https:// and include tiktok.com",
  },
  facebookLink: {
    label: "Facebook",
    placeholder: "https://facebook.com/GetRedCircle/",
    icon: <FaFacebook style={{ height: 48, width: 48 }} />,
    validationRegex: /^https?:\/\/(www\.)?facebook\.com\/.*$/,
    errorMessage: "Link must start with https:// and include facebook.com",
  },
};

export default function RAPSettingsFormSocial() {
  return (
    <div>
      {Object.keys(SOCIAL_LINKS).map((key) => {
        return (
          <div className="flex-row-container align-center width-100" key={key}>
            {SOCIAL_LINKS[key].icon}
            <div className="flex-1 m-lxs">
              <label className="redcircle-form-label" htmlFor={`advertisementSettings_${key}`}>
                {SOCIAL_LINKS[key].label}
              </label>
              <Form.Item
                name={["advertisementSettings", key]}
                className="m-bs"
                rules={[
                  {
                    pattern: SOCIAL_LINKS[key].validationRegex,
                    message: SOCIAL_LINKS[key].errorMessage,
                  },
                ]}>
                <Input
                  placeholder={SOCIAL_LINKS[key].placeholder}
                  size="large"
                  className="width-100"
                />
              </Form.Item>
            </div>
          </div>
        );
      })}
    </div>
  );
}
