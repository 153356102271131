import React from "react";
import { Modal } from "redcircle-ui";
import { AudioBlock } from "src/reducers/audio_blocks";

interface IProps {
  audioBlocks?: AudioBlock[];
  show: boolean;
  onClose: () => void;
  onOk: () => void;
}

export default function CustomAudioDeletionModal({ audioBlocks, show, onClose, onOk }: IProps) {
  let message =
    "This will permanently delete this audio. It is not being used in any audio blocks.";

  if (audioBlocks && audioBlocks.length > 0) {
    message =
      "This will permanently delete this audio and remove it from the following audio blocks:";
  }

  const handleOk = () => {
    onOk();
    onClose();
  };

  return (
    <Modal open={show} size="xs" onSubmit={handleOk} onClose={onClose}>
      <Modal.Title>Delete Audio</Modal.Title>
      <Modal.Body>
        <>
          <p>{message}</p>
          {audioBlocks && (
            <ul>
              {audioBlocks.map((block, index) => (
                <li key={index}>{block.name}</li>
              ))}
            </ul>
          )}
        </>
      </Modal.Body>
      <Modal.Footer noBorder>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Modal.SubmitButton>Delete Audio</Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
}
