import { Tooltip } from "antd";
import { useContext } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { Button } from "redcircle-ui";
import RadioSelectFrame from "src/components/lib/radio-select-frame/radio-select-frame";
import {
  CampaignInvoicingStyleFull,
  CreditCardPaymentMethod,
  InvoicingPaymentMethod,
} from "src/constants/campaigns";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

export default function SchedulerPaymentTypePage() {
  const { campaign, paymentMethodType, setPaymentMethodType } =
    useContext(CampaignSchedulerContext);

  const isCreditCardDisabled = campaign?.invoicingStyle === CampaignInvoicingStyleFull;

  return (
    <div>
      <RadioSelectFrame
        isSelected={paymentMethodType === CreditCardPaymentMethod}
        onClick={() => {
          if (!isCreditCardDisabled) {
            setPaymentMethodType(CreditCardPaymentMethod);
          }
        }}
        title={
          <div className="flex-row-container align-center">
            <span>Pay By Credit Card</span>
            {isCreditCardDisabled && (
              <Tooltip title="Credit card payments are only available to monthly billing intervals.">
                <AiFillInfoCircle className="m-lxxs" />
              </Tooltip>
            )}
          </div>
        }
        description="Your credit card will be charged automatically each month."
        disabled={isCreditCardDisabled}
      />
      <RadioSelectFrame
        isSelected={paymentMethodType === InvoicingPaymentMethod}
        onClick={() => setPaymentMethodType(InvoicingPaymentMethod)}
        title="Pay By Invoice"
        description="You'll be invoiced by RedCircle according to the payment timeline you just selected."
      />
    </div>
  );
}

export const SchedulerPaymentTypeFooter = ({
  onBack,
  onSubmit,
}: {
  onBack: () => void;
  onSubmit: () => void;
}) => {
  const { paymentMethodType } = useContext(CampaignSchedulerContext);

  return (
    <>
      <Button type="link" size="large" className="p-a0" onClick={onBack}>
        Back
      </Button>
      <Button type="primary" size="large" onClick={onSubmit} disabled={!paymentMethodType}>
        Continue
      </Button>
    </>
  );
};
