import React from "react";
import { Col, Grid, Modal, Row } from "react-bootstrap";
import { classNames, If } from "react-extras";
import closeIcon from "../../icons/modal-close-icon.svg";
import Divider from "../lib/divider";
import Spinner from "../lib/spinner";
import WarningPanelv2 from "../lib/warning_panel/warning_panel_v2";
import "./modal.scss";

export interface IBaseModalV2Props {
  isLoading?: boolean;
  isMini?: boolean;
  Content: React.ReactNode;
  close: React.MouseEventHandler;
  hideCloseButton?: boolean;
  title?: string;
  subTitle?: string;
  showContent?: boolean;
  Footer?: React.ReactNode;
  modalProps: any;
  topWarning?: { title: string; text: string };
  containerClass?: string;
  noFooterShadow?: boolean;
  hideFooter?: boolean;
  hideTitleDivider?: boolean;
  header?: React.ReactNode;
  /* included for backwards compatability */
  hideMiniTitleDivider?: boolean;
  isLarge?: boolean;
  fillScreen?: boolean;
  ref?: any;
  columns: number;
  offset: number;
}

/*
  Passes in Content and Footer as components rather than functions
 */
const BaseModalV2 = (props: IBaseModalV2Props) => {
  const {
    isLoading,
    isMini,
    Content = null,
    close,
    hideCloseButton,
    title,
    subTitle,
    showContent = true,
    Footer,
    modalProps,
    topWarning,
    containerClass,
    noFooterShadow = false,
    hideFooter = false,
    hideTitleDivider = false,
    header,
    /* included for backwards compatability */
    hideMiniTitleDivider = true,
    isLarge = false,
    fillScreen,
    ref,
    columns = 10,
    offset = 1,
  } = props;
  const mainClasses = classNames(
    "seedcast-modal",
    "modal-dialog-centered",
    isMini && "seedcast-modal--mini",
    containerClass
  );

  return (
    <div className={"static-modal"}>
      <Modal
        dialogClassName={classNames(mainClasses, isLarge && "large", fillScreen && "fill-screen")}
        show={true}
        {...modalProps}
        ref={(r) => {
          if (ref) {
            ref(r);
          }
        }}>
        <If condition={!hideCloseButton}>
          <button onClick={close} className={"btn btn-link seedcast-modal__close-button"}>
            <img src={closeIcon} alt={"close-modal"} />
          </button>
        </If>
        <div className={classNames("seedcast-modal__content", isLarge && "large")}>
          {header ? (
            header
          ) : (
            <Grid fluid>
              <Row>
                <Col xs={columns} xsOffset={offset} md={columns} mdOffset={offset}>
                  <If condition={Boolean(title) || !hideTitleDivider}>
                    <div className={"seedcast-modal__header"}>
                      <If condition={!!topWarning}>
                        <WarningPanelv2 withShadow className={"m-bl"}>
                          <h4 className={"bold"}>{topWarning?.title || "Warning"}</h4>
                          <p>{topWarning?.text || "text"}</p>
                        </WarningPanelv2>
                      </If>
                      <If condition={Boolean(title)}>
                        <h2 className="line-clamp-2">{title}</h2>
                      </If>
                      <If condition={Boolean(subTitle)}>
                        <p>{subTitle}</p>
                      </If>
                      <If condition={!(isMini && hideMiniTitleDivider) && !hideTitleDivider}>
                        <Divider smallMargin className="seedcast-modal__title-divider" />
                      </If>
                    </div>
                  </If>
                </Col>
              </Row>
            </Grid>
          )}

          <div className={fillScreen ? "seedcast-modal__scroll-content" : ""}>
            <Modal.Body>
              <Spinner isLoading={isLoading} />
              <If condition={showContent}>{Content}</If>
            </Modal.Body>
          </div>
        </div>
        <If condition={!fillScreen}>
          <div className="flex-1" />
        </If>
        <If condition={!hideFooter}>
          <div
            className={classNames(
              "seedcast-modal__footer",
              Footer && "show-footer",
              noFooterShadow && "seedcast-modal__footer--no-shadow"
            )}>
            <div className={"seedcast-modal__footer-inner"}>{Footer}</div>
          </div>
        </If>
      </Modal>
    </div>
  );
};

export default BaseModalV2;
