import { CloseOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Form, Radio, Slider } from "antd";
import React, { useEffect } from "react";
import { classNames } from "react-extras";
import Button from "src/components/lib/button";
import CategorySelector from "src/components/lib/category_selector";
import Page from "src/components/lib/page";
import {
  SHOW_SEARCH_FILTER_CREATED,
  SHOW_SEARCH_FILTER_EPISODE_FREQUENCY,
  SHOW_SEARCH_FILTER_PRIMARY_GENDER,
  SHOW_SEARCH_MARKS_CPM,
  SHOW_SEARCH_MARKS_DOWNLOADS,
  SHOW_SEARCH_MAX_CPM,
  SHOW_SEARCH_MAX_DOWNLOADS,
  SHOW_SEARCH_MIN_CPM,
  SHOW_SEARCH_MIN_DOWNLOADS,
  SHOW_SEARCH_SORT_OPTIONS,
} from "src/constants/search";
import styles from "./advertiser_browse_page.module.scss";

interface IProps {
  values?: {
    sort?: string;
    category?: string[];
    downloads?: [number, number];
    cpm?: [number, number];
    createdAtSince?: number;
  };
  onSubmit: (values: any) => void;
  onClear: () => void;
  onClose: () => void;
}

// Fully controlled component
export default function BrowseFilterSidebarForm({ values, onSubmit, onClear, onClose }: IProps) {
  const [form] = Form.useForm();
  const categoriesSelected = Form.useWatch("category", form) || [];
  const numCategoriesSelected = categoriesSelected.length;

  useEffect(() => {
    form.setFieldsValue({ ...values });
  }, [values]);

  return (
    <Form
      form={form}
      name="browse-filter"
      initialValues={values}
      className="flex-column-container height-100"
      onFinish={onSubmit}>
      <div className="flex-row-container justify-space-between align-center">
        <h3 className="m-hxs m-txs">Filter</h3>
        <Button
          type="link"
          onClick={onClose}
          className={classNames(styles["filter-button"], "m-txxs")}>
          <CloseOutlined />
        </Button>
      </div>
      <Page.Divider className="m-b0" />
      <div
        className="filter-containers flex-1 width-100"
        style={{ overflowY: "auto", overflowX: "hidden" }}>
        <Collapse
          className={styles.filters}
          defaultActiveKey={[
            "sort",
            "category",
            "downloads",
            "cpm",
            "recent",
            "episodeFrequency",
            "primaryGender",
          ]}
          ghost
          expandIconPosition="end">
          <Collapse.Panel header={<small>SORT BY</small>} key="sort">
            <Form.Item name="sort" className="m-b0">
              <Radio.Group className={styles.sort}>
                {Object.keys(SHOW_SEARCH_SORT_OPTIONS).map((key) => (
                  <Radio key={key} value={key}>
                    {SHOW_SEARCH_SORT_OPTIONS[key as keyof typeof SHOW_SEARCH_SORT_OPTIONS].label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <small>
                Content Category {numCategoriesSelected ? `(${numCategoriesSelected})` : ""}
              </small>
            }
            key="category">
            <Form.Item name="category" className="m-b0">
              <CategorySelector
                values={categoriesSelected}
                className={styles.categories}
                style={{ marginRight: "-16px", paddingRight: "16px" }}
              />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel header={<small>Weekly Downloads</small>} key="downloads">
            <Form.Item name="downloads" className="m-b0">
              <Slider
                range
                min={SHOW_SEARCH_MIN_DOWNLOADS}
                max={SHOW_SEARCH_MAX_DOWNLOADS}
                marks={SHOW_SEARCH_MARKS_DOWNLOADS}
                style={{ maxWidth: "250px" }}
              />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel header={<small>CPM</small>} key="cpm">
            <Form.Item name="cpm" className="m-b0">
              <Slider
                range
                min={SHOW_SEARCH_MIN_CPM}
                max={SHOW_SEARCH_MAX_CPM}
                marks={SHOW_SEARCH_MARKS_CPM}
                style={{ maxWidth: "250px" }}
              />
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel header={<small>Episode Frequency</small>} key="episodeFrequency">
            <Form.Item name="episodeFrequency" className="m-b0 flex-column-container">
              <Radio.Group>
                <div className="flex-column-container">
                  {Object.values(SHOW_SEARCH_FILTER_EPISODE_FREQUENCY).map((item) => (
                    <Radio className="m-l0" value={item.value} key={item.value}>
                      {item.label}
                    </Radio>
                  ))}
                </div>
              </Radio.Group>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel header={<small>Recently Added</small>} key="recent">
            <Form.Item name="createdAtSince" className="m-b0">
              <Radio.Group className={styles.sort}>
                {SHOW_SEARCH_FILTER_CREATED.map((item) => (
                  <Radio value={item.value} key={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel header={<small>Primary Gender</small>} key="primaryGender">
            <Form.Item name="primaryGender" className="m-b0">
              <Radio.Group className={styles.sort}>
                {SHOW_SEARCH_FILTER_PRIMARY_GENDER.map((item) => (
                  <Radio value={item.value} key={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel header={<small>More Filters</small>} key="other">
            <Form.Item name="includeNSFW" className="m-b0 m-l0" valuePropName="checked">
              <Checkbox>Include NSFW Content</Checkbox>
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
      </div>

      <div className="footer flex-row-container justify-space-between align-center p-axs">
        <Button type="link" size="small" onClick={onClear}>
          Clear Filters
        </Button>
        <Form.Item className="m-b0">
          <Button type="primary" size="small" htmlType="submit">
            Show Results
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}
