import { connect, ConnectedProps } from "react-redux";
import { ReduxDispatch } from "../hooks/redux-ts";
import { IRootState, tierLevel } from "../reducers/types";
import { Tier } from "redcircle-types";

const tierOrder = {
  core: 0,
  growth: 1,
  pro: 2,
  enterprise: 3,
};

export const minimumAllowedTier = (
  minLevel: keyof typeof tierOrder,
  tierState: IRootState["user"]["tier"],
  ogAllowed = true
) => {
  const level = tierState?.level;

  if (level === tierLevel.og) {
    return ogAllowed;
  }
  const minLevelNum = tierOrder[minLevel];
  const currentMinLevel = tierOrder[level] ?? 0;
  return currentMinLevel >= minLevelNum;
};

export const withTiers = connect(
  (state: IRootState) => ({
    tier: state.user?.tier,
  }),
  {
    hasAllowedTier:
      (minLevel: keyof typeof tierOrder, ogAllowed: boolean) =>
      (dispatch: ReduxDispatch, getState: () => IRootState) => {
        const tierState = getState().user.tier;
        return minimumAllowedTier(minLevel, tierState, ogAllowed);
      },
  }
);

/**
 * Calculates current subscription amounts for the purpose of displaying legacy
 * subscriptions pricing
 * @param tier
 */
export const getCurrentSubscriptionAmounts: (tier?: Tier) => {
  isYearly: boolean;
  pricePerMonth: number;
} = (tier) => {
  if (!tier) return { isYearly: false, pricePerMonth: 0 };

  const isYearly = tier.interval === "annual";
  const amount = tier.amount / 100;

  return {
    isYearly,
    pricePerMonth: isYearly ? amount / 12 : amount,
  };
};

export type WithTierProps = ConnectedProps<typeof withTiers>;
