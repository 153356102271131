import React from "react";
import { useSelectorTS } from "../hooks/redux-ts";

const InitialsCircle = () => {
  const user = useSelectorTS((state) => state.user.user);
  return (
    <div
      style={{
        width: 80,
        height: 80,
        background: "#EBF0F1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 28,
        lineHeight: "1em",
        borderRadius: "50%",
        textTransform: "uppercase",
      }}
      className={"bold m-rxs"}>
      <div>
        {user.firstName[0]}
        {user.lastName[0]}
      </div>
    </div>
  );
};

export default InitialsCircle;
