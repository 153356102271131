import { connect } from "react-redux";
import { getMyPermissions } from "../action_managers/permissions";
import { canAccess, hasAtLeastOnePermitted, permissionIsLoading } from "./permissions";

// this one is put here because i've yet to figure out how to get connect to work with TS and redux thunk
export const withPermissions = (component) =>
  connect(
    (state) => ({
      presetIsLoading: state.permissions.presetIsLoading,
      //these are put here so that when things are loaded it triggers a render
      permissionState: state.permissions,
      showsState: state.shows,
      permissionsFetched: state.permissions.initialFetched,
      userIsLoading: state.user.isLoading,
      permissionIsLoading: permissionIsLoading(state),
    }),
    {
      canAccess: (permissionType, objectUUID) => (dispatch, getState) => {
        const state = getState();
        const user = state.user.user;
        const permissionState = state.permissions;
        return canAccess(user, permissionState, permissionType, objectUUID);
      },
      hasAtLeastOnePermission: (permissionType) => (dispatch, getState) => {
        const state = getState();
        const permissionState = state.permissions;
        if (permissionIsLoading(state)) {
          return undefined;
        }
        const user = state.user.user;
        return hasAtLeastOnePermitted(user, permissionState, permissionType);
      },
      getMyPermissions,
    }
  )(component);
