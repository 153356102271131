import showAPI from "src/api/shows";
import { IShow } from "redcircle-types";

export const validateCPM = (
  cpm: number,
  defaultCPM: number
): [isValid: boolean, message: string] => {
  if (cpm < 200) {
    return [true, "$2 is the minimum CPM"];
  }
  if (cpm > 10000) {
    return [true, "$100 is the maximum CPM"];
  }

  const low = Math.round((defaultCPM * 0.66) / 100) * 100;
  const high = Math.round((defaultCPM * 1.33) / 100) * 100;
  const recommended = `Recommended Range: $${low / 100}–$${high / 100}`;

  if (cpm < low) {
    return [false, `This CPM is low for your show size based on industry data. ${recommended}`];
  }
  if (cpm > high) {
    return [false, `This CPM is high for your show size based on industry data. ${recommended}`];
  }

  return [false, ""];
};

const audienceSizeToSuggestedCPMs: Record<number, number[]> = {
  0: [1000, 1200],
  100: [1000, 1200],
  1000: [1200, 1500],
  5000: [1500, 2000],
  10000: [2000, 2500],
  25000: [2500, 3000],
};

export const calculateDefaultCPMsForShow = (show?: IShow) => {
  const audienceSize = show && show.estimatedWeeklyDownloads ? show.estimatedWeeklyDownloads : 0;
  let [defaultPreRecordedCPM, defaultHostReadCPM] = [0, 0];
  Object.keys(audienceSizeToSuggestedCPMs).forEach((key) => {
    if (audienceSize >= parseInt(key)) {
      [defaultPreRecordedCPM, defaultHostReadCPM] = audienceSizeToSuggestedCPMs[key as any];
    }
  });

  const roundedDiscount = (price: number) => Math.round((price * 0.75) / 100) * 100;
  const defaultHostReadCPMWithDiscount = roundedDiscount(defaultHostReadCPM);
  const defaultPreRecordedCPMWithDiscount = roundedDiscount(defaultPreRecordedCPM);

  const roundToNearest10 = (num: number) => Math.round(num / 1000) * 1000;
  const defaultSpotRate = roundToNearest10(
    (defaultHostReadCPM * (show?.averageEpisodeDownloads || show?.estimatedWeeklyDownloads || 1)) /
      1000
  );
  const defaultSpotRateWithDiscount = roundToNearest10(
    (defaultHostReadCPMWithDiscount *
      (show?.averageEpisodeDownloads || show?.estimatedWeeklyDownloads || 1)) /
      1000
  );

  return {
    preRecorded: { default: defaultPreRecordedCPM, discount: defaultPreRecordedCPMWithDiscount },
    hostRead: { default: defaultHostReadCPM, discount: defaultHostReadCPMWithDiscount },
    spotRate: { default: defaultSpotRate, discount: defaultSpotRateWithDiscount },
  };
};

export const validatePromoCode = async (
  showUUID: string,
  code: string
): Promise<[hasError: boolean, message: string]> => {
  const isAlphaNumeric = /^[a-z0-9]+$/i.test(code);
  if (!isAlphaNumeric) {
    return [true, "Promo code should be alphanumeric and must not include spaces."];
  }

  const resp = await showAPI.validatePromoCode(showUUID, code);
  if (resp.status !== 200) {
    return [true, "Promo code is already in use, please try another one."];
  }

  return [false, ""];
};
