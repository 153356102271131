import get from "lodash/get";
import mapValues from "lodash/mapValues";

class KnownAgentFriendlyNames {
  set = (knownAgents) => {
    this.knownAgents = mapValues(knownAgents, (val) =>
      val.reduce((agg, { name, key }) => Object.assign(agg, { [key]: name }), {})
    );
  };
  get = (type, key) => {
    return get(this.knownAgents, [type, key], "Unknown");
  };

  getMap = (type) => {
    return get(this.knownAgents, [type]);
  };
}

export default new KnownAgentFriendlyNames();
