import { Presets } from "src/reducers/permissions/types";
import { permissionTypes } from "../constants/permission_roles";
import { ObjectType } from "../reducers/permissions";
import { defaultActionManagerRunner } from "./default_action_manager";

export const GET_MY_PERMISSIONS = "GET_MY_PERMISSIONS";
export const GET_PERMISSION_PRESETS = "GET_PERMISSION_PRESETS";
export const GET_PERMISSIONS_IN_ORG = "GET_PERMISSIONS_IN_ORG";
export const CREATE_PERMISSION = "CREATE_PERMISSION";
export const UPDATE_PERMISSION = "UPDATE_PERMISSION";
export type CreatePermission = Omit<IUpdatePermission, "uuid">;

export interface IUpdatePermission {
  uuid: string;
  credentialUUID: string;
  objectUUID: string;
  objectType: ObjectType;
  overrides?: Partial<Record<typeof permissionTypes[keyof typeof permissionTypes], boolean>> | null;
  preset: Presets;
}

export const getMyPermissions = () =>
  defaultActionManagerRunner({
    actionName: GET_MY_PERMISSIONS,
    auth: true,
    route: "permissions/me",
  });

export const getPermissionPresets = () =>
  defaultActionManagerRunner({
    actionName: GET_PERMISSION_PRESETS,
    route: "permissions/presets",
  });

export const getPermissionsInOrg = () =>
  defaultActionManagerRunner({
    auth: true,
    actionName: GET_PERMISSIONS_IN_ORG,
    route: "users/org-permissions",
  });

export const createPermission = (permissionRequests: CreatePermission[]) => {
  return defaultActionManagerRunner({
    actionName: CREATE_PERMISSION,
    auth: true,
    method: "POST",
    route: `permissions/create`,
    body: {
      request: permissionRequests,
    },
  });
};

export const updatePermission = (permissionUpdates: IUpdatePermission[]) => {
  return defaultActionManagerRunner({
    actionName: UPDATE_PERMISSION,
    auth: true,
    method: "POST",
    route: `permissions/update`,
    body: {
      updates: permissionUpdates,
    },
    successMessage: "Successfully Updated Role",
    messageSize: "large",
  });
};
