import { Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { AiFillDollarCircle } from "react-icons/ai";
import { formatMoney } from "redcircle-lib";
import { Button, COLORS, Loading } from "redcircle-ui";
import { campaignOneWeek } from "src/action_managers/campaigns";
import {
  CampaignInvoicingMonthTypeBroadcast,
  CampaignInvoicingMonthTypeCalendar,
  CampaignInvoicingStyleFull,
  CampaignInvoicingStyleMonthly,
  CreditCardPaymentMethod,
  InvoicingPaymentMethod,
} from "src/constants/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";
import { StripeCard } from "./stripe_components";

export default function SchedulerPaymentConfirmPage() {
  const dispatch = useDispatchTS();
  const {
    campaign,
    paymentCardId,
    invoicingStyle,
    invoicingMonthType,
    paymentMethodType,
    budgets,
  } = useContext(CampaignSchedulerContext);
  const { user } = useSelectorTS((state) => state.user);
  const { cards } = useSelectorTS((state) => state.payments);
  const card = paymentCardId ? cards[paymentCardId] : undefined;

  const [oneWeekAmount, setOneWeekAmount] = useState(0);
  const [isOneWeekAmountLoading, setIsOneWeekAmountLoading] = useState(false);
  const totalCost = Object.keys(budgets).reduce((agg, uuid) => agg + budgets?.[uuid], 0);
  const isCampaignRateNegotiated = campaign?.items?.some(
    ({ item }) => item?.offerRates?.enabled && item?.state === "draft"
  );

  useEffect(() => {
    const getOneWeekAmount = async () => {
      if (campaign && paymentMethodType === CreditCardPaymentMethod) {
        setIsOneWeekAmountLoading(true);
        const res = await dispatch(campaignOneWeek(campaign.uuid));
        if (res.status === 200) {
          setOneWeekAmount(res.json.amount);
          setIsOneWeekAmountLoading(false);
        }
      }
    };
    getOneWeekAmount();
  }, [campaign, paymentMethodType]);

  return (
    <div>
      <div className="redcircle-form-label">Payment Method</div>
      {card && paymentMethodType === CreditCardPaymentMethod && <StripeCard card={card} />}
      {paymentMethodType === InvoicingPaymentMethod && <strong>Invoice</strong>}

      {user.invoicingPaymentEnabled && (
        <>
          <div className="redcircle-form-label m-ts">Payment Timeline</div>
          <strong>{invoicingStyle === CampaignInvoicingStyleFull && "End of Campaign"}</strong>
          <strong>{invoicingStyle === CampaignInvoicingStyleMonthly && "Monthly"}</strong>
        </>
      )}

      <div className="redcircle-form-label m-ts">Payment Type</div>
      <strong>
        {paymentMethodType === CreditCardPaymentMethod ? "One-Time Authorization" : "Total Budget"}
      </strong>

      <div className="redcircle-form-label m-ts">Amount</div>
      <div className="flex-row-container">
        {isOneWeekAmountLoading && <Loading style={{ width: "auto" }} />}
        {!isOneWeekAmountLoading && (
          <>
            <strong>
              {paymentMethodType === CreditCardPaymentMethod
                ? formatMoney(oneWeekAmount / 100)
                : formatMoney(totalCost / 100)}
            </strong>
            {isCampaignRateNegotiated && (
              <span>
                <Tooltip title="You have updated the rate on one or more shows">
                  <AiFillDollarCircle className="m-lxxxs" color={COLORS.BLUE_MEDIUM} />
                </Tooltip>
              </span>
            )}
          </>
        )}
      </div>

      <div className="redcircle-form-label m-ts">Info</div>
      {paymentMethodType === CreditCardPaymentMethod && (
        <div>
          <p>
            We will hold these funds until the podcasters accept your offer and the campaign starts.
          </p>
          <p>
            After the campaign starts, you will be billed{" "}
            <strong>
              {invoicingStyle === CampaignInvoicingStyleMonthly &&
                invoicingMonthType === CampaignInvoicingMonthTypeCalendar &&
                "at end of each calendar month"}
              {invoicingStyle === CampaignInvoicingStyleMonthly &&
                invoicingMonthType === CampaignInvoicingMonthTypeBroadcast &&
                "at end of each broadcast month"}
            </strong>{" "}
            as the campaign runs, up to your maximum budget amount of{" "}
            <strong>{formatMoney(totalCost / 100)}</strong>.
          </p>
        </div>
      )}
      {paymentMethodType === InvoicingPaymentMethod && (
        <div>
          {!campaign?.paymentType && (
            <p>
              You've selected <strong>Pay by Invoice</strong> for the entirety of this campaign.
            </p>
          )}
          <p>
            RedCircle will invoice you as the campaign runs, at the{" "}
            <strong>
              {invoicingStyle === CampaignInvoicingStyleFull && "end of the campaign"}
              {invoicingStyle === CampaignInvoicingStyleMonthly &&
                invoicingMonthType === CampaignInvoicingMonthTypeCalendar &&
                "end of each calendar month"}
              {invoicingStyle === CampaignInvoicingStyleMonthly &&
                invoicingMonthType === CampaignInvoicingMonthTypeBroadcast &&
                "end of each broadcast month"}
            </strong>
            .
          </p>
          <p>Payment terms will be indicated on each invoice.</p>
        </div>
      )}
    </div>
  );
}

export const SchedulerPaymentConfirmFooter = ({
  onBack,
  onSubmit,
}: {
  onBack: () => void;
  onSubmit: () => void;
}) => {
  const { user } = useSelectorTS((state) => state.user);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    await onSubmit();
    setIsSubmitLoading(false);
  };

  return (
    <>
      <Button type="link" size="large" className="p-a0" onClick={onBack}>
        Back
      </Button>
      <Button
        size="large"
        type="primary"
        onClick={handleSubmit}
        loading={isSubmitLoading}
        disabled={user?.userAttributes?.sendCampaignBlocked === "true"}>
        Submit
      </Button>
    </>
  );
};
