import { Slider } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isNumber from "lodash/isNumber";
import { useContext, useState } from "react";
import { formatDurationPlayback } from "redcircle-lib";
import { AudioPlayerContext } from "./Wrapper";

dayjs.extend(duration);

export default function SeekBar({ className }: { className?: string }) {
  const { audioData, seek } = useContext(AudioPlayerContext);
  const { currentTime, duration } = audioData;

  const [seekValue, setSeekValue] = useState<number | null>(null);

  const handleChange = (value: number) => {
    setSeekValue(value);
  };

  const handleAfterChange = (value: number) => {
    setSeekValue(null);
    seek(value);
  };

  return (
    <Slider
      className={classNames("m-a0", className)}
      min={0}
      max={duration}
      value={seekValue || currentTime}
      onChange={handleChange}
      onAfterChange={handleAfterChange}
      tooltip={{
        formatter: (value?: number) => (isNumber(value) ? formatDurationPlayback(value) : null),
      }}
    />
  );
}
