import { UnixTimeStamp } from "src/lib/date";
import { isUUID } from "src/lib/uuid";

export type VettingReduxState = {
  forms: VettingForm[];
  formsByUUID: { [formsUUID: string]: VettingForm };
  formsByCampaignUUID: { [campaignUUID: string]: VettingForm[] };

  invitations: VettingInvitation[];
  invitationsByUUID: { [requestUUID: string]: VettingInvitation };

  invitationsByCampaignUUID: { [campaignUUID: string]: VettingInvitation[] };
  invitationsByCampaignItemUUID: { [campaignItemUUID: string]: VettingInvitation[] };

  isLoading: false;
  /**
   * We used this flag as a way to differentiate between zero state
   * (loaded and no forms available) and initial empty state (nothing loaded yet).
   * Could also add null state on forms
   */
  initialFetched: boolean;
};

export type VettingForm = {
  uuid: string;
  userUUID: string;
  campaignUUID: string;
  brandName: string; // field included in the design of form. can be different than campaign brand name;
  campaignInfo: string;
  visibility: {
    shareBudget: boolean;
    shareTimeline: boolean;
  };
  questions: VettingQuestion[];

  createdAt: UnixTimeStamp;
  updatedAt: UnixTimeStamp;
};

export type VettingQuestionType = "yes_no" | "short_text" | "long_text" | "multiple_choice";

export type VettingQuestion = {
  uuid: string; // Question uuid
  prompt: string;
  tooltip?: string;
  type: VettingQuestionType; // Can always add more types

  // Enforces question order
  order: number;

  /**
   * Contains validation rules that are specific based on question type, can add more options later
   * (i.e for short/long text can ad max characters, )
   */
  validations: {
    required: boolean;
  };

  /**
   * Contains question type specific properties for how the question is viewed by the user
   */
  properties: {
    /**
     *  When type === "multiple_choice" , this field specifies what type of multiple choice, and
     *  implicitly signals if multiple answers are permitted (checkbox) instead of single answer (radio)
     */
    multipleChoiceType?: "checkbox" | "radio";
    allowOtherChoice?: boolean; // allow generic short text other option in question;
  };

  /**
   * When question type is multiple choice, specifies the possible field values to choose
   * from.
   */
  fields?: VettingQuestionField[];
};

export type VettingQuestionField = {
  /**
   * Number from 1-4,5 (as many options available), will be used to uniquely
   * identify the field , as well as the order of the fields.
   */
  order: number;
  label: string;
  tooltip?: string;
};

export type VettingInvitation = {
  uuid: string;
  vettingFormUUID: string;

  showUUID: string;
  campaignUUID: string;
  campaignItemUUID: string;

  sentAt: UnixTimeStamp;
  responseDueAt: UnixTimeStamp;

  /**
   * The minimum amount of information needed from the Podcaster
   * to identify and answer the questions.
   */
  responses?: {
    questionUUID: string; // used to connect to the question meta data

    /**
     * type === "yes_no" -> answer: ["yes"] | ["no"]
     * type === "short_text" -> answer: string[1]
     * type === "long_text" -> answer: string[1]
     * type === "multiple_choice" -> answer: string[1-5] array of the label used as response
     */
    answer: ("yes" | "no" | string)[];

    other?: string;
  }[];

  respondedAt?: UnixTimeStamp; // existence marks completion

  lastManualReminder?: UnixTimeStamp;
  isExpired?: boolean;
};

/**
 * Type guard to check if an unknown value is a valid VettingForm object,
 * re enforces the VettingForm type post check
 *
 * @param value
 * @returns Boolean
 */
export function isVettingForm(value?: unknown): value is VettingForm {
  if (
    typeof value === "object" &&
    value !== null &&
    "uuid" in value &&
    "userUUID" in value &&
    "campaignUUID" in value &&
    "questions" in value &&
    isUUID(value["uuid"]) &&
    isUUID(value["userUUID"]) &&
    isUUID(value["campaignUUID"]) &&
    Array.isArray(value["questions"])
  ) {
    return true;
  }

  return false;
}

/**
 * Type guard to check if an unknown value is a valid VettingInvitation object,
 * re enforces the Vetting Invitation type post check
 *
 * @param value
 * @returns Boolean
 */
export function isVettingInvitation(value?: unknown): value is VettingInvitation {
  if (
    typeof value === "object" &&
    value !== null &&
    "uuid" in value &&
    "vettingFormUUID" in value &&
    "campaignUUID" in value &&
    "campaignItemUUID" in value &&
    "sentAt" in value &&
    isUUID(value["uuid"]) &&
    isUUID(value["vettingFormUUID"]) &&
    isUUID(value["campaignUUID"]) &&
    isUUID(value["campaignItemUUID"]) &&
    typeof value["sentAt"] === "number"
  ) {
    return true;
  }

  return false;
}

/**
 * Type guard to check if an unknown value is a valid VettingForm array object,
 * re enforces the VettingForm type post check
 *
 * @param value
 * @returns Boolean
 */
export function isArrayOfVettingForms(value?: unknown | unknown[]): value is VettingForm[] {
  if (Array.isArray(value)) {
    return value.every((item) => isVettingForm(item));
  }

  return false;
}

/**
 * Type guard to check if an unknown value is a valid Vetting invitation array object,
 * re enforces the Vetting Invitation type post check
 *
 * @param value
 * @returns Boolean
 */
export function isArrayOfVettingInvitations(
  value?: unknown | unknown[]
): value is VettingInvitation[] {
  if (Array.isArray(value)) {
    return value.every((item) => isVettingInvitation(item));
  }

  return false;
}
