import { Button, Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { ButtonProps, ButtonType } from "antd/lib/button";
import { useRef } from "react";
import { classNames } from "react-extras";
import styles from "./button.module.scss";
import React from "react";

/**
 * Extending all props from antd button props while adding specific RCButton props (i.e tooltipMessage)
 * or overriding props (i.e. type)
 */
export interface TButton extends Omit<ButtonProps, "type"> {
  tooltipMessage?: string;
  tooltipPlacement?: TooltipPlacement;
  flex?: boolean; // allows button text to wrap and increase height
  type?: ButtonType | undefined | "secondary" | "icon";
}

export default function RCButton({
  children,
  className = "",
  type = "default",
  size,
  shape,
  flex,
  tooltipMessage,
  tooltipPlacement,
  ...props
}: TButton) {
  let antdType = "default";
  const allStyles = { [styles.button]: true, [className]: true };
  const divRef = useRef<HTMLDivElement>(null); // Used to fix bug where tooltip stays on page when explicitly hovered after modal is closed

  switch (type) {
    case "secondary":
      allStyles[styles.secondary] = true;
      break;
    case "icon":
      allStyles[styles.icon] = true;
      antdType = "link";
      break;
    default:
      antdType = type;
  }

  if (size === "small") {
    allStyles[styles.mini] = true;
  }

  if (flex) {
    allStyles[styles.flex] = true;
  }

  const renderButtonComponent = () => (
    <Button
      role="button"
      type={antdType as ButtonType}
      size={size || "middle"}
      shape={shape}
      className={classNames(allStyles)}
      {...props}>
      {children}
    </Button>
  );

  if (typeof tooltipMessage === "string") {
    return (
      <Tooltip
        title={tooltipMessage}
        destroyTooltipOnHide={{ keepParent: false }}
        placement={tooltipPlacement}
        getPopupContainer={() => (divRef?.current ? divRef.current : document.body)}>
        <div ref={divRef} className="flex-row-container justify-stretch">
          {renderButtonComponent()}
        </div>
      </Tooltip>
    );
  }

  return renderButtonComponent();
}
