import { useEffect, useRef, useState } from "react";
import { getShowCampaigns } from "src/actions/campaigns";
import { getBudgetsByCampaignUUID } from "src/action_managers/budgets";
import {
  getCampaign,
  getCampaignsForUser,
  getCampaignStats,
  getCampaignTags,
} from "src/action_managers/campaigns";
import { getProgrammaticEarningsForShow } from "src/action_managers/programmatic_earnings";
import { fetchPublicShowsByUUIDs } from "src/action_managers/public_show";
import { getScriptsForCampaign } from "src/action_managers/script";
import UserRoles from "src/constants/roles";
import { processCampaignItemState } from "src/lib/campaigns";
import { userHasRole } from "src/lib/user";
import { isUUID } from "src/lib/uuid";
import { ICampaign } from "redcircle-types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "redcircle-types";
import { VettingForm, VettingInvitation } from "src/reducers/vetting";
import { useDispatchTS, useSelectorTS } from "./redux-ts";
import { parallelLimit } from "async";
import { Budget } from "redcircle-types";

const MAX_ATTEMPTS = 5;

export const useGetCampaign = (campaignUUID?: string) => {
  const { campaigns, isLoading: isCampaignsLoading } = useSelectorTS((state) => state.campaigns);
  const campaign = campaignUUID ? campaigns[campaignUUID] : undefined;

  const isUUIDAvailable = typeof campaignUUID === "string" && isUUID(campaignUUID);

  const isCampaignAvailable = !!campaign;

  const fetchAttempts = useRef<number>(1);

  // Reset fetch attempts counter on episodeUUID change
  useEffect(() => {
    fetchAttempts.current = 1;
  }, [campaignUUID]);

  useEffect(() => {
    if (!isCampaignsLoading) {
      // Currently no other campaigns are being fetched

      if (isUUIDAvailable) {
        // UUID is currently available
        if (!isCampaignAvailable) {
          // Campaign is not found in Redux state
          if (fetchAttempts.current < MAX_ATTEMPTS) {
            // MAX attempts have not been reached
            fetchAttempts.current++;
            getCampaign(campaignUUID);
          }
        }
      }
    }
  }, [campaignUUID, isCampaignsLoading, isUUIDAvailable, isCampaignAvailable]);

  return campaign;
};

export const useGetCampaignJuiced = (campaignUUID: string) => {
  const dispatch = useDispatchTS();
  const campaign = useGetCampaign(campaignUUID);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      if (campaignUUID) {
        const res = await dispatch(getCampaign(campaignUUID));
        if (res.status === 200) {
          const campaign = res.json;
          const campaignItemShowUUIDs = (campaign.items ?? []).map(
            ({ item }: { item: ICampaignItem }) => item.showUUID
          );
          setIsLoading(false);
          dispatch(fetchPublicShowsByUUIDs(campaignItemShowUUIDs));
        }

        dispatch(getCampaignStats(campaignUUID));
        dispatch(getBudgetsByCampaignUUID(campaignUUID));
        dispatch(getScriptsForCampaign(campaignUUID));
      }
    };
    fetch();
  }, [campaignUUID]);

  return { campaign, isLoading };
};

export const useGetCampaignAndShows = (
  { getCampaign, fetchPublicShowsByUUIDs }: any,
  campaignUUID: string
) => {
  useEffect(() => {
    if (campaignUUID) {
      getCampaign(campaignUUID).then((resp: any) => {
        if (resp.status === 200) {
          const showUUIDs = (resp.json?.items ?? []).map(({ item }: any) => item.showUUID);
          fetchPublicShowsByUUIDs(showUUIDs);
        }
      });
    }
  }, []);
};

export const useGetCampaigns = () => {
  const { user } = useSelectorTS((state) => state.user);
  const dispatch = useDispatchTS();
  const campaigns = useSelectorTS((state) => state.campaigns);

  const { campaignsForUserIsLoading, campaignsForUserInitialFetched } = campaigns;

  useEffect(() => {
    if (user?.uuid && !campaignsForUserIsLoading && !campaignsForUserInitialFetched) {
      dispatch(getCampaignsForUser(user.uuid));
    }
  }, [user?.uuid, campaignsForUserIsLoading, campaignsForUserInitialFetched, dispatch]);

  return campaigns;
};

export const useGetCampaignTags = () => {
  const { user } = useSelectorTS((state) => state.user);
  const dispatch = useDispatchTS();
  const { campaignTags } = useSelectorTS((state) => state.campaigns);

  useEffect(() => {
    if (
      user?.uuid &&
      userHasRole(user, [UserRoles.Advertiser, UserRoles.Admin]) &&
      !Array.isArray(campaignTags)
    ) {
      dispatch(getCampaignTags(user.uuid));
    }
  }, [user?.uuid, campaignTags, dispatch]);

  if (!Array.isArray(campaignTags)) return [];

  return campaignTags;
};

// retrieves all campaign items for a show - used in podcaster RAP
export const useGetShowCampaignItems = (showUUID: string) => {
  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);
  const authToken = user?.authToken; // used to refresh campaign items during login session

  const campaignItemState = useSelectorTS((state) => state.campaignItems);
  const { showCampaignItemUUIDs, campaignItems: campaignItemsByUUID } = campaignItemState;

  const thisShowCampaignItemUUIDs = showCampaignItemUUIDs[showUUID] ?? [];
  const showCampaignItems = thisShowCampaignItemUUIDs
    .map((uuid) => campaignItemsByUUID[uuid])
    .filter((i) => i);

  const [isShowCampaignsLoading, setIsShowCampaignsLoading] = useState(true);

  useEffect(() => {
    if (showUUID) {
      dispatch(getShowCampaigns(showUUID)).then(() => {
        if (isShowCampaignsLoading) setIsShowCampaignsLoading(false);
      });
      dispatch(getProgrammaticEarningsForShow(showUUID));
    }
  }, [showUUID, authToken]);

  return { isShowCampaignsLoading, showCampaignItems };
};

// attaches fields to all campaign items for a show - used in podcaster RAP
export type TAppendedCampaignItem = ICampaignItem & {
  show?: IShow;
  budget?: Budget;
  campaign?: ICampaign;
  vettingInvitations?: (VettingInvitation & { form?: VettingForm })[];
};

export const useAppendedCampaignItems = (
  campaignItems: ICampaignItem[]
): TAppendedCampaignItem[] => {
  const { shows } = useSelectorTS((state) => state.shows);
  const { campaigns } = useSelectorTS((state) => state.campaigns);
  const { invitationsByCampaignItemUUID, formsByUUID } = useSelectorTS((state) => state.vetting);

  return campaignItems.map((item) => {
    // map each invitation to its form
    const invitations = (invitationsByCampaignItemUUID[item.uuid] ?? []).map((invitation) => {
      return { ...invitation, form: formsByUUID[invitation.vettingFormUUID] };
    });

    return {
      ...item,
      show: shows[item.showUUID],
      campaign: campaigns[item.campaignUUID],
      state: processCampaignItemState(item as any).state,
      vettingInvitations: invitations,
    };
  });
};

/**
 * Helper hook, ensures that campaignItems for the campaigns in the provided list are fetched and available in redux state
 * @param {string[]}
 * @param callBack
 */
export const useEnsureCampaignItemsFetched = (
  campaignUUIDs: string[],
  successCallBack?: (campaigns: Array<ICampaign | undefined>) => void
) => {
  const dispatch = useDispatchTS();
  const [parallelFetchingDone, setParallelFetchingDone] = useState(true);
  const [failedAttempts, setFailedAttempts] = useState<{ [campaignUUID: string]: number }>({});
  const { isLoading: isCampaignItemsLoading, campaignItemByCampaignUUID } = useSelectorTS(
    (state) => state.campaignItems
  );

  const MAX_ALLOWED_FAILED_ATTEMPTS = 3; // allowing 3 failed attempts per campaign UUID

  const missingCampaigns = campaignUUIDs
    .filter((campaignUUID) => {
      return !Array.isArray(campaignItemByCampaignUUID?.[campaignUUID]);
    })
    .filter((campaignUUID) => {
      // incase failure of specific campaign fetches occurs, will limit the fetch for those campaigns
      const failCount = failedAttempts[campaignUUID] ?? 0;
      return failCount < MAX_ALLOWED_FAILED_ATTEMPTS;
    });

  useEffect(() => {
    if (missingCampaigns.length > 0 && !isCampaignItemsLoading && parallelFetchingDone) {
      const tasks = missingCampaigns.map((campaignUUID: string) => (callback: any) => {
        return dispatch(getCampaign(campaignUUID))
          .then((resp) => {
            if (resp.status === 200) {
              callback(null, resp.json);
            } else {
              callback({ err: resp.status, campaignUUID });
            }
          })
          .catch((err) => {
            callback({ err, campaignUUID });
          });
      });
      setParallelFetchingDone(false);
      parallelLimit<ICampaign, { errBlock: Error | 204 | 400 | 403 | 500; campaignUUID: string }>(
        tasks,
        4,
        (errBlock, results) => {
          if (errBlock) {
            const { campaignUUID } = errBlock;
            setFailedAttempts((prev) => {
              const prevCount = typeof prev?.[campaignUUID] === "number" ? prev[campaignUUID] : 0;
              return { ...prev, [campaignUUID]: prevCount + 1 };
            });
          }

          setParallelFetchingDone(true);
          if (results) {
            successCallBack?.(results);
          }
        }
      );
    }
  }, [missingCampaigns.length, isCampaignItemsLoading, parallelFetchingDone]);

  return { done: parallelFetchingDone };
};
