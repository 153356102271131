// See podcache_api for definitions

// TransactionTypes
export const SubscriptionSurchargeCredit = "SubscriptionCredit";
export const CreatorInsertionCredit = "CreatorInsertionCredit";
export const TipCredit = "TipCredit";
export const PendingInvoiceTransaction = "PendingInvoiceTransaction";
export const RecurringDonationCredit = "RecurringDonationCredit";
export const TierCreatorDebit = "TierCreatorDebit";

// TransactionStates
export const PendingInvoiceTransactionState = "InvoicePending";
