import { SortOrder } from "antd/es/table/interface";
import { useState } from "react";
import { ReportingPageState } from "src/components/pages/advertising_reporting/utilities";
import { UnixTimeStamp } from "src/lib/date";

/**
 * TODO need better type inference for individual key/value props
 */
/**
 * Central location for possible local storage values,
 * When adding new values, add it here as well. this enforces a type
 * check on the hook to ensure it is used with correct key/values.
 */
type LocalStorageReferenceMap = {
  campaign_table_sort: { columnKey: string; order: SortOrder };
  campaign_collapsed_folders: Record<string, boolean>;
  view_redirected_podcasts: { viewRedirectedPodcasts: boolean };
  campaign_list_filters: { archive: boolean };
  user_has_seen_post_vetting_modal: boolean;
  dismissed_warning_ts_by_credentialUUID: Record<string, string>;
  advertising_report_saved_configs: {
    name: string;
    state: ReportingPageState;
    createdAt: UnixTimeStamp;
  }[];
};

export const LOCAL_STORAGE_KEYS: Record<
  keyof LocalStorageReferenceMap,
  keyof LocalStorageReferenceMap
> = {
  campaign_table_sort: "campaign_table_sort",
  campaign_collapsed_folders: "campaign_collapsed_folders",
  view_redirected_podcasts: "view_redirected_podcasts",
  campaign_list_filters: "campaign_list_filters",
  user_has_seen_post_vetting_modal: "user_has_seen_post_vetting_modal",
  dismissed_warning_ts_by_credentialUUID: "dismissed_warning_ts_by_credentialUUID",
  advertising_report_saved_configs: "advertising_report_saved_configs",
};

export const useLocalStorage = <
  T,
  KEY extends keyof LocalStorageReferenceMap,
  VALUE extends Partial<LocalStorageReferenceMap[KEY]> = LocalStorageReferenceMap[KEY],
>(
  key: KEY, // requires "key" to be of type KEY, which is any key available the the reference map
  initialValue: T extends VALUE ? T : never // requires "value" to be similar to Generic VALUE type. which is known structure for the key
) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") return initialValue;

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: VALUE | ((prev: VALUE) => VALUE)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue] as [VALUE, typeof setValue];
};
