import get from "lodash/get";
import React, { Component } from "react";
import { connect } from "react-redux";
import { matchPath, withRouter } from "react-router-dom";
import { showModal } from "../../../actions/modal";
import { fetchPublicShow } from "../../../action_managers/public_show";
import { ShowsForUserActionManager } from "../../../action_managers/shows";
import { searchSimilarShows } from "../../../action_managers/show_search";
import { DEAL_CREATE_MODAL } from "../../modals/modal_root";
import PromotionDetails from "./promotions_details";
import "./promotions_show_page.scss";

export class PromotionsShowPage extends Component {
  state = {
    currentUUID: null,
  };

  componentDidMount() {
    let uuid = this.getShowUUIDFromURL(this.props.location.pathname);
    this.setState({ currentUUID: uuid });
    this.fetch(uuid);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.currentUUID && nextProps.location.pathname !== this.props.location.pathname) {
      let uuid = this.getShowUUIDFromURL(nextProps.location.pathname);
      this.setState({ currentUUID: uuid });
      this.fetch(uuid);
    }
  }

  fetch = (uuid) => {
    this.props.fetchShowsForUser(this.props.user).then(() => {
      this.props.fetchPublicShow(uuid).then((response) => {
        if (response.status === 200) {
          this.props.searchSimilarShows(uuid);
        }
      });
    });
  };

  getShowUUIDFromURL = (pathname) => {
    var result = matchPath(pathname, "/promotions/shows/:showUUID");
    return get(result, "params.showUUID");
  };

  isLoading = () => {
    return this.props.publicShows.isLoading || this.props.isLoadingShows;
  };

  shouldDisableButton = () => {
    return !Object.keys(get(this.props, "shows", {})).some((showUUID) => {
      let show = this.props.shows[showUUID];
      return (
        show.userUUID === this.props.user.uuid &&
        show.canShareAudienceSize &&
        show.isVisible &&
        (!show.importedURL || show.redirectVerified)
      );
    });
  };

  render() {
    const show = get(this.props.publicShows, this.getShowUUIDFromURL(this.props.location.pathname));
    return (
      <PromotionDetails
        handleClick={() => {
          this.props.showDealCreateModal(get(show, "userUUID"), get(show, "uuid"));
        }}
        buttonText={"Propose a Cross Promotion"}
        show={show}
        isLoading={this.props.publicShows.isLoading}
        categoryShows={this.props.categoryShows}
        shouldDisableButton={this.shouldDisableButton()}
      />
    );
  }
}

export default withRouter(
  connect(
    ({ publicShows, browsePromotion, shows, user }) => {
      return {
        user: user.user,
        publicShows,
        shows: shows.shows,
        categoryShows: browsePromotion.categoryShows,
        isLoadingShows: shows.isLoading,
      };
    },
    {
      fetchPublicShow: (showUUID) => fetchPublicShow(showUUID),
      fetchShowsForUser: (user) => new ShowsForUserActionManager({ user }).run(),
      searchSimilarShows,
      showDealCreateModal: (recipientUUID, showUUID) =>
        showModal(DEAL_CREATE_MODAL, { recipientUUID, showUUID }),
    }
  )(PromotionsShowPage)
);
