import { Alert, Tabs } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import Page from "src/components/lib/page";
import { useGetAllUserShows } from "src/hooks/shows";
import AudioBlocksSection from "./audio_blocks_section";
import CustomAudioSection from "./custom_audio_section";
import InsertionPointsSection from "./insertion_points_section";

export default function DynamicInsertionPage() {
  const { tab } = useParams<{ tab: string }>();
  const history = useHistory();

  const { isLoading, shows } = useGetAllUserShows();
  const isAllShowsRemote =
    Object.keys(shows).length > 0 && Object.values(shows).every((show) => show.isRemote);

  const handleTabChange = (key: string) => {
    history.push(`/dynamic-insertion/${key}`);
  };

  const items = [
    {
      label: "Custom Audio",
      key: "custom-audio",
      children: <CustomAudioSection />,
    },
    {
      label: "Audio Blocks",
      key: "audio-blocks",
      children: <AudioBlocksSection />,
    },
    {
      label: "Insertion Points",
      key: "insertion-points",
      children: <InsertionPointsSection />,
    },
  ];

  return (
    <Page className="dynamic-insertion-page" pageTitle="Dynamic Insertion">
      <Page.Header className="m-bxxs">
        <Page.Title>Dynamic Insertion</Page.Title>
      </Page.Header>

      {isAllShowsRemote && (
        <Alert
          message={
            <span>
              You must have at least one podcast hosted on RedCircle to use Dynamic Insertion.{" "}
              <span>
                <Link to="/shows">Add a podcast</Link> to get started.
              </span>
            </span>
          }
          type="error"
        />
      )}

      <Page.Section>
        <Tabs activeKey={tab || "custom-audio"} items={items} onChange={handleTabChange} />
      </Page.Section>
    </Page>
  );
}
