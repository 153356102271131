import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import StatsWidgetContainer from ".";
import { DownloadStatsActionManager } from "../../../action_managers/stats";

function mapStateToProps(state) {
  return {
    isLoading: state.stats.isLoading,
    user: state.user.user,
    stats: state.stats.stats,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchStats: (filters, user, requestID) => {
      return dispatch(new DownloadStatsActionManager({ filters, user, requestID }).run());
    },
  };
}

const StatsWidgetContainerWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StatsWidgetContainer)
);

export default StatsWidgetContainerWrapper;
