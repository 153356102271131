import DefaultActionManager from "src/action_managers/default_action_manager";

/*
 * action types
 */
export const RESET_USER = "RESET_USER";
export const USER_IMPORT_JOB = "USER_IMPORT_JOB";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_FETCH_START = "USER_FETCH_START";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_FAIL = "USER_FETCH_FAIL";
export const USER_ATTRIBUTE = "USER_ATTRIBUTE";
export const USER_ADD_ROLE = "USER_ADD_ROLE";

/*
 * action creators
 */

export function userLogin(user) {
  return {
    type: USER_LOGIN,
    user: user,
  };
}

export function userLogout() {
  return {
    type: USER_LOGOUT,
    user: null,
  };
}

export function userFetchStart(userUUID) {
  return {
    type: USER_FETCH_START,
    userUUID: userUUID,
  };
}

export function userFetchSuccess(user) {
  return {
    type: USER_FETCH_SUCCESS,
    user: user,
  };
}

export function userFetchFail() {
  return {
    type: USER_FETCH_FAIL,
  };
}

export const getUserImportJobs = (userUUID) =>
  new DefaultActionManager({
    auth: true,
    route: `users/${userUUID}/import-jobs`,
    actionName: USER_IMPORT_JOB,
  }).run();
