import { Col, Row } from "antd";
import React from "react";
import Button from "src/components/lib/button";
import HighlightBox from "src/components/lib/highlight_box";
import { useSelectorTS } from "src/hooks/redux-ts";
import { useCalcCampaignItemPaidData } from "src/hooks/transactions";
import { formatMoney } from "src/lib/format-money";
import { ICampaignItem } from "redcircle-types";

interface IProps {
  className?: string;
  isProgrammaticAllowed?: boolean;
  isProgrammaticEnabled: boolean;
  allAcceptedItems?: ICampaignItem[];
  onClickOptIn: () => void;
}

export default function AdvertisingMetadata({
  className,
  isProgrammaticAllowed,
  isProgrammaticEnabled,
  allAcceptedItems = [],
  onClickOptIn,
}: IProps) {
  const isLoading = useSelectorTS((state) => {
    return (
      state?.programmaticEarnings?.isLoading ||
      state?.shows?.isLoading ||
      state?.campaignItems?.isLoading ||
      state?.campaigns?.isLoading ||
      state?.transactions?.isLoading
    );
  });

  const { totalPaidAmount, totalUnpaidAmount } = useCalcCampaignItemPaidData(allAcceptedItems);

  const programmaticHistoricEarnings = useSelectorTS((state) => {
    const value = state?.programmaticEarnings?.programmaticEarnings?.historicEarnings;
    return Number.isInteger(value) ? value : 0;
  });

  const programmaticEstEarnings = useSelectorTS((state) => {
    const value =
      state?.programmaticEarnings?.programmaticEarnings?.estimatedEarningsSinceLastPayout;
    return Number.isInteger(value) ? value : 0;
  });

  return (
    <Row gutter={[16, 16]} className={className}>
      <Col xs={12} md={isProgrammaticAllowed ? 6 : 12}>
        <HighlightBox
          className="bordered"
          title="host-read earnings"
          isLoading={isLoading}
          value={formatMoney(totalPaidAmount)}
        />
      </Col>
      <Col xs={12} md={isProgrammaticAllowed ? 6 : 12}>
        <HighlightBox
          className="bordered"
          title="projected host-read earnings"
          isLoading={isLoading}
          infoText={"Estimated value of campaigns that have not yet been fully paid."}
          value={formatMoney(totalUnpaidAmount)}
        />
      </Col>
      {isProgrammaticAllowed && (
        <>
          <Col xs={12} md={6}>
            <HighlightBox className="bordered" title="programmatic earnings" isLoading={isLoading}>
              {isProgrammaticEnabled && (
                <h3 className="highlight-box__stat m-a0">
                  {formatMoney(programmaticHistoricEarnings)}
                </h3>
              )}
              {!isProgrammaticEnabled && (
                <Button type="secondary" size="small" onClick={onClickOptIn}>
                  Opt in
                </Button>
              )}
            </HighlightBox>
          </Col>
          <Col xs={12} md={6}>
            <HighlightBox
              className="bordered"
              title="projected programmatic earnings"
              infoText={"Estimated value of programmatic earnings since last monthly payment."}
              isLoading={isLoading}>
              {isProgrammaticEnabled && (
                <h3 className="highlight-box__stat m-a0">{formatMoney(programmaticEstEarnings)}</h3>
              )}
              {!isProgrammaticEnabled && (
                <Button type="secondary" size="small" onClick={onClickOptIn}>
                  Opt in
                </Button>
              )}
            </HighlightBox>
          </Col>
        </>
      )}
    </Row>
  );
}
