import React from "react";
import { Dispatch } from "redux";
import { v4 as uuid } from "uuid";
import messageTypes, { MessageTypes } from "../constants/message_types";

export const TOGGLE_SIDEBAR_OPEN = "TOGGLE_SIDEBAR_OPEN";
export const SET_SIDE_BAR_OPEN = "SET_SIDE_BAR_OPEN";
export const SET_HIDE_SIDEBAR = "SET_HIDE_SIDEBAR";
export const SET_HIDE_WARNING_BAR = "SET_HIDE_WARNING_BAR";
export const SHOW_MESSAGE_BAR = "SHOW_MESSAGE_BAR";
export const DISMISS_MESSAGE_BAR = "DISMISS_MESSAGE_BAR";
export const DISABLE_WAITLIST = "DISABLE_WAITLIST";

export function toggleSidebarOpen() {
  return { type: TOGGLE_SIDEBAR_OPEN };
}
export function setSidebarOpen(shouldOpen: boolean, auto = true) {
  return { type: SET_SIDE_BAR_OPEN, payload: { open: shouldOpen, auto } };
}
// this will hide the sidebar altogether, including mobile and desktop
// do not use to control state of the sidebar panel, use setSidebarOpen instead
export function setHideSidebar(shouldHide: boolean) {
  return { type: SET_HIDE_SIDEBAR, payload: shouldHide };
}

export function setHideWarningBar(shouldHide: boolean) {
  return { type: SET_HIDE_WARNING_BAR, payload: shouldHide };
}

export function showMessageBar(
  messageUUID: string,
  message: string | React.JSX.Element,
  messageType: MessageTypes
) {
  return {
    type: SHOW_MESSAGE_BAR,
    messageUUID: messageUUID,
    messageType: messageType,
    message: message,
  };
}

export function dismissMessageBar(messageUUID: string | null) {
  return {
    type: DISMISS_MESSAGE_BAR,
    messageUUID: messageUUID,
  };
}

export const disableWaitlist = () => {
  return {
    type: DISABLE_WAITLIST,
  };
};

export function showMessageWithTimeout(
  message: string | React.JSX.Element,
  messageType: MessageTypes,
  timeout = 5000
) {
  const messageUUID = uuid();
  return (dispatch: Dispatch) => {
    dispatch(showMessageBar(messageUUID, message, messageType));
    setTimeout(function () {
      dispatch(dismissMessageBar(messageUUID));
    }, timeout);
  };
}

export function showError(message: string, timeout?: number) {
  return showMessageWithTimeout(message, messageTypes.Error, timeout);
}

export function showWarning(message: string, timeout?: number) {
  return showMessageWithTimeout(message, messageTypes.Warning, timeout);
}
export function showInfo(message: string, timeout?: number) {
  return showMessageWithTimeout(message, messageTypes.Info, timeout);
}

export function showSuccess(message: string | React.JSX.Element, timeout?: number) {
  return showMessageWithTimeout(message, messageTypes.Success, timeout);
}
