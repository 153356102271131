/*
 * action types
 */

export const SIGN_IN_REQUEST_START = "SUBMIT_SIGN_IN_FORM";
export const SIGN_IN_REQUEST_SUCCESS = "SIGN_IN_REQUEST_SUCCESS";
export const SIGN_IN_REQUEST_ERROR = "SIGN_IN_REQUEST_ERROR";
export const SIGN_IN_REQUEST_FORBIDDEN = "SIGN_IN_REQUEST_FORBIDDEN";

/*
 * action creators
 */

export function signInStart() {
  return {
    type: SIGN_IN_REQUEST_START,
  };
}

export function signInSuccess() {
  return {
    type: SIGN_IN_REQUEST_SUCCESS,
  };
}

export function signInError() {
  return {
    type: SIGN_IN_REQUEST_ERROR,
  };
}

export function signInForbidden() {
  return {
    type: SIGN_IN_REQUEST_FORBIDDEN,
  };
}
