import React from "react";
import { Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import RCButton from "src/components/lib/button/button";
import FormElement from "../../forms/form_element";
import Spinner from "../../lib/spinner";

let ForgotPasswordForm = (props) => {
  if (typeof props.user !== "undefined" && props.user !== null) {
    return <Redirect to="/" />;
  }

  return (
    <form onSubmit={props.handleSubmit}>
      <Spinner isLoading={props.isLoading} />
      <div>
        <Field name="email" component={FormElement} type="email" label="Email" />
      </div>
      <hr className="redcircle-divider" />
      <RCButton
        disabled={!props.submitEnabled}
        type="primary"
        size="large"
        htmlType="submit"
        className="width-100 m-bxs">
        Send Password Reset Link
      </RCButton>
      <div className="user-form__fine-print">
        Need more help? <a href="mailto:support@getredcircle.com">Contact Support</a>
      </div>
    </form>
  );
};

ForgotPasswordForm = reduxForm({
  form: "forgot-password",
})(ForgotPasswordForm);

export default ForgotPasswordForm;
