import { CaretDownOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Switch } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Button from "src/components/lib/button";
import RCButton from "src/components/lib/button/button";
import EmptyStateBlock from "src/components/lib/empty_state_block/EmptyStateBlock";
import Loading from "src/components/lib/loading";
import Page from "src/components/lib/page";
import AddTeamSeatToPodcast from "src/components/modals/add_teamSeat_to_podcast";
import UpgradePlanModal from "src/components/modals/upgrade_plan";
import { permissionTypes } from "src/constants/permission_roles";
import { useSelectorTS } from "src/hooks/redux-ts";
import { useGetAllUserShowsWithCampaigns } from "src/hooks/shows";
import { LOCAL_STORAGE_KEYS, useLocalStorage } from "src/hooks/useLocalStorage";
import { useGetImportJobs } from "src/hooks/user";
import { addNewShowDefaultPermissions, useCanAccessBound } from "src/lib/permissions";
import { getActiveHostedPodcasts, isShowRedirected, isShowWaitListed } from "src/lib/show";
import { minimumAllowedTier } from "src/lib/tier-utils";
import { tierLevel, IShow } from "redcircle-types";
import ImportProgressBar from "./import_progress_bar";
import ShowList from "./show_list";
import ShowListOnboarding from "./show_list_onboarding";

// TODO: change this to a module
import "../shows.scss";

export default function ShowListPage() {
  const history = useHistory();
  const location = useLocation();
  const canAccess = useCanAccessBound();

  const [open, setOpen] = useState(false);
  const [podcastSettings, setPodcastSettings] = useLocalStorage(
    LOCAL_STORAGE_KEYS.view_redirected_podcasts,
    {
      viewRedirectedPodcasts: false,
    }
  );

  const [showUpgradePlanModal, setUpgradePlanModal] = useState(false);
  const [addTeamSeatModalUUID, setAddTeamSeatModal] = useState<string>("");

  const { tier } = useSelectorTS((state) => state.user);
  const { credentials } = useSelectorTS((state) => state.credentials);

  const { importJobs } = useGetImportJobs();
  const {
    shows = {},
    isInitiallyLoading,
  }: { shows: Record<string, IShow>; isInitiallyLoading?: boolean } =
    useGetAllUserShowsWithCampaigns((res) => {
      if (res.length === 1 && res[0].isDemo) {
        history.push(`/shows/${res[0].uuid}`);
      }
    });

  const canAddNewShows = canAccess(permissionTypes.createShow);
  const allowTeamMembersModal =
    minimumAllowedTier(tierLevel.pro, tier, false) && credentials?.length > 1;

  // track when new shows are added to conjure team seats modal - TODO: move this logic elsewhere?
  useEffect(() => {
    const { state }: { state: any } = location;
    if (state?.newShowUUID?.length > 0 && !addTeamSeatModalUUID) {
      if (allowTeamMembersModal) {
        setAddTeamSeatModal(state.newShowUUID);
      }

      // Add explicit default permission for this new show to all credentials
      addNewShowDefaultPermissions(state.newShowUUID);
      // Remove state from route location
      history.replace(location.pathname, {});
    }
  }, [location]);

  // generate array of shows

  const redirectedShows = Object.values(shows).filter((show: any) => isShowRedirected(show));

  let showList = Object.values(shows)
    .filter((show) => show.isVisible && !isShowRedirected(show) && !isShowWaitListed(show))
    .sort((a, b) => {
      if (!a.title || !b.title) return 0;
      return a.title?.localeCompare(b.title);
    });

  // Add redirected show if settings is enabled
  if (podcastSettings.viewRedirectedPodcasts) {
    showList = [...showList, ...redirectedShows];
  }

  const dropdownItems =
    showList?.length > 0 || (showList.length === 0 && redirectedShows?.length > 0)
      ? [
          {
            key: "1",
            label: (
              <div className="flex-row-container align-center">
                <p className="m-b0 m-rxxs">View redirected podcasts</p>
                <Switch
                  checked={podcastSettings.viewRedirectedPodcasts}
                  onChange={(flag) => {
                    setPodcastSettings((prev) => {
                      return { ...prev, viewRedirectedPodcasts: flag };
                    });
                  }}
                />
              </div>
            ),
          },
        ]
      : [];

  const numShows = Object.keys(showList).length; // Num of shows matches currently shown list

  return (
    <Page className="show-list-page" pageTitle="Podcasts">
      <ImportProgressBar importJobs={importJobs} shows={shows} />

      <Page.Header className="m-bxxs flex-row-container justify-space-between align-center">
        <Page.Title>Podcasts {numShows ? `(${numShows})` : ""}</Page.Title>
        <div className="flex-row-container align-center">
          {!isInitiallyLoading && canAddNewShows && (
            <ShowListPageActions
              showList={showList}
              onUpgradePrompt={() => setUpgradePlanModal(true)}
            />
          )}
          {dropdownItems.length > 0 && (
            <Dropdown
              trigger={["click"]}
              open={open}
              onOpenChange={(flag) => setOpen(flag)}
              menu={{ items: dropdownItems }}>
              <SettingOutlined className="m-lxxs" style={{ color: "#ea404d" }} />
            </Dropdown>
          )}
        </div>
      </Page.Header>
      <Page.Divider />

      <Page.Section>
        {isInitiallyLoading && <Loading />}
        {!isInitiallyLoading && showList.length > 0 && <ShowList shows={showList} />}
        {!isInitiallyLoading && showList.length === 0 && (
          <>
            {canAddNewShows ? (
              <ShowListOnboarding />
            ) : (
              <EmptyStateBlock>No podcasts available</EmptyStateBlock>
            )}
          </>
        )}
      </Page.Section>

      <UpgradePlanModal
        text="To add additional podcasts to your account, you’ll need to upgrade your plan."
        visible={showUpgradePlanModal}
        setVisible={setUpgradePlanModal}
      />
      <AddTeamSeatToPodcast
        showUUID={addTeamSeatModalUUID}
        visible={!!addTeamSeatModalUUID}
        setVisible={(visible) => setAddTeamSeatModal(visible ? addTeamSeatModalUUID : "")}
      />
    </Page>
  );
}

const ShowListPageActions = ({
  showList,
  onUpgradePrompt,
}: {
  showList: IShow[];
  onUpgradePrompt: () => void;
}) => {
  const history = useHistory();
  const canAccess = useCanAccessBound();
  const { tier } = useSelectorTS((state) => state.user);
  const canAddNewShows = canAccess(permissionTypes.createShow);
  const activeShows = getActiveHostedPodcasts(showList);
  const allowShowCreateOrImport =
    minimumAllowedTier(tierLevel.growth, tier, false) || activeShows.length === 0;

  const handleImport = () => {
    if (!allowShowCreateOrImport) onUpgradePrompt();
    else history.push("/shows/import");
  };

  const handleCreate = () => {
    if (!allowShowCreateOrImport) onUpgradePrompt();
    else history.push("/shows/create");
  };

  if (showList.length === 0) return null;
  if (showList.length === 1) {
    return (
      <div>
        {!showList[0].isRemote && (
          <Link to={`/shows/${showList[0].uuid}/ep/create`}>
            <Button type="primary" size="small">
              Add New Episode
            </Button>
          </Link>
        )}
        {canAddNewShows && (
          <Dropdown
            className="m-lxs"
            menu={{
              items: [
                {
                  key: "import",
                  label: (
                    <RCButton type="link" className="p-a0" onClick={handleImport}>
                      Import Podcast
                    </RCButton>
                  ),
                },
                {
                  key: "create",
                  label: (
                    <RCButton type="link" className="p-a0" onClick={handleCreate}>
                      Create New Podcast
                    </RCButton>
                  ),
                },
              ],
            }}>
            <Button type="secondary" size="small">
              Add Podcast <CaretDownOutlined />
            </Button>
          </Dropdown>
        )}
      </div>
    );
  }
  return (
    <div className="flex-row-container align-center justify-end flex-wrap">
      <Button type="link" size="small" onClick={handleImport}>
        Import Podcast
      </Button>
      <Button type="link" size="small" onClick={handleCreate} className="m-lxs">
        Create New Podcast
      </Button>
    </div>
  );
};
