import get from "lodash/get";
import { adminLoginAsActionPrefix } from "../../actions/admin_login_as";
import { httpReducer } from "../../lib/create-action";
import { setCurrentUserOnState } from "./user";
import { initialState } from "./initialState";

const adminLoginReducer = httpReducer(adminLoginAsActionPrefix, initialState, {
  started: (state = initialState, action) => {
    return { ...state, isLoading: true };
  },
  success: (state = initialState, action) => {
    const newState = setCurrentUserOnState(state, {
      user: get(action, "payload.resp"),
    });
    return { ...state, ...newState, isLoading: false };
  },
  failure: (state = initialState, action) => {
    return { ...state, isLoading: false };
  },
});

export default adminLoginReducer;
