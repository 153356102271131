import moment from "moment-timezone";

export const formatPublishDate = (publishedAt, isVisible) => {
  var publishedInFuture = publishedAt * 1000 > new Date().valueOf();
  var formattedDate = moment
    .tz(publishedAt * 1000, moment.tz.guess())
    .format("MMMM D, YYYY [at] h:mm a z");
  if (!isVisible) {
    return `Unpublished Draft`;
  }
  if (publishedInFuture) {
    return `Draft, To Publish on ${formattedDate}`;
  }
  return `Published on ${formattedDate}`;
};
