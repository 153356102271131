import get from "lodash/get";
import { Reducer } from "redux";
import {
  BALANCE_FETCH_ERROR,
  BALANCE_FETCH_START,
  BALANCE_FETCH_SUCCESS,
  BALANCE_STRIPE_FETCH,
} from "../../actions/balance";
import { httpReducer, reduceReducers } from "../../lib/create-action";
import { BalanceReduxState } from "./types";

const initialState: BalanceReduxState = {
  isLoading: false,
  balance: null,
  stripeBalance: [],
  stripeBalancePending: [],
};

const balance: Reducer<BalanceReduxState> = (state = initialState, action) => {
  switch (action.type) {
    case BALANCE_FETCH_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case BALANCE_FETCH_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case BALANCE_FETCH_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        balance: action.balance,
      });
    default:
      return state;
  }
};

const stripeBalance = httpReducer(BALANCE_STRIPE_FETCH, initialState, {
  success: (state, action) => ({
    ...state,
    stripeBalance: get(action.payload.resp, "available", []),
    stripeBalancePending: get(action.payload.resp, "pending", []),
  }),
});

export default reduceReducers(balance, stripeBalance);
