import { SignOutState, UpdateAccountReduxState } from "./account";
import { AdvertiserContactsReduxState } from "./advertiser_contacts";
import { AdPlacementModalReduxState } from "./ad_placements_modal";
import { AppReduxState } from "./app";
import { AudioBlocksReduxState } from "./audio_blocks";
import { BalanceReduxState } from "./balance";
import { BrandsReduxState } from "./brands";
import { BrowsePromotionsReduxState } from "./browse_promotion";
import { BudgetsReduxState } from "./budgets/types";
import { CampaignState } from "./campaigns/campaigns";
import { CampaignItemsReduxState } from "./campaign_items";
import { CredentialsReduxState } from "./credentials";
import { CustomAudiosReduxState } from "./custom_audio";
import { DealsReduxState } from "./deals";
import { DirectoryShowsReduxState } from "./directory_shows";
import { DistributionsReduxState } from "./distributions";
import { EmailVerificationReduxState, SendVerificationEmailReduxState } from "./email_verification";
import { EpisodesReduxState } from "./episodes";
import { episodeByShowType } from "./episode_by_show/episode_by_show";
import { EpisodeDeleteReduxState } from "./episode_delete";
import { EpisodeFeedImportReduxState, ShowFeedImportReduxState } from "./feed_import";
import { ForgotPasswordReduxState, RecoverPasswordReduxState } from "./forgot_password";
import { ImageUploaderReduxState } from "./image-uploader";
import { ManualPayoutsState } from "./manual_payouts/manual_payouts";
import { MarkersReduxState } from "./markers";
import { MediaFileReduxState } from "./media_file";
import { MediaFileUploadReduxState } from "./media_file_upload";
import { MessagesReduxState } from "./messages";
import { ModalReduxState } from "./modal";
import { PaymentReduxState } from "./payment";
import { PermissionsReduxState } from "./permissions";
import { ProgrammaticEarningsReduxState } from "./programmatic_earnings/types";
import { PublicShowsReduxState } from "./public_show";
import { PutAdsReduxState } from "./put_ads";
import { ScriptReduxState } from "./scripts";
import { showState } from "./shows/showReducer";
import { ShowFormReduxState } from "./show_form";
import { SignInState } from "./sign_in";
import { SignUpState } from "./sign_up";
import { StatsReduxState } from "./stats";
import { StatsOptionsReduxState } from "./stats_options";
import { StripeConnectReduxState } from "./stripe_connect";
import { SubscriptionsReduxState } from "./subscriptions";
import { ReduxTransactionState } from "./transactions";
import { UserReduxState } from "./user";
import { VettingReduxState } from "./vetting";

export interface IRootState {
  adPlacementsModal: AdPlacementModalReduxState;
  advertiserSignUp: AdvertiserContactsReduxState;
  app: AppReduxState;
  audioBlocks: AudioBlocksReduxState;
  balance: BalanceReduxState;
  brands: BrandsReduxState;
  browsePromotion: BrowsePromotionsReduxState;
  budgets: BudgetsReduxState;
  campaignItems: CampaignItemsReduxState;
  campaigns: CampaignState;
  credentials: CredentialsReduxState;
  customAudios: CustomAudiosReduxState;
  deals: DealsReduxState;
  directoryShows: DirectoryShowsReduxState;
  distributions: DistributionsReduxState;
  emailVerification: EmailVerificationReduxState;
  episodeDelete: EpisodeDeleteReduxState;
  episodeFeedImport: EpisodeFeedImportReduxState;
  episodes: EpisodesReduxState;
  episodesByShow: episodeByShowType;
  forgotPassword: ForgotPasswordReduxState;
  form: any;
  imageUploader: ImageUploaderReduxState;
  manualPayouts: ManualPayoutsState;
  markers: MarkersReduxState;
  mediaFiles: MediaFileReduxState;
  mediaFileUpload: MediaFileUploadReduxState;
  messages: MessagesReduxState;
  modal: ModalReduxState;
  payments: PaymentReduxState;
  permissions: PermissionsReduxState;
  programmaticEarnings: ProgrammaticEarningsReduxState;
  publicShows: PublicShowsReduxState;
  putAds: PutAdsReduxState;
  recoverPassword: RecoverPasswordReduxState;
  scheduledJobs: any;
  scripts: ScriptReduxState;
  sendVerificationEmail: SendVerificationEmailReduxState;
  showFeedImport: ShowFeedImportReduxState;
  showForm: ShowFormReduxState;
  shows: showState;
  signIn: SignInState;
  signOut: SignOutState;
  signUp: SignUpState;
  stats: StatsReduxState;
  statsOptions: StatsOptionsReduxState;
  stripeConnect: StripeConnectReduxState;
  subscriptions: SubscriptionsReduxState;
  transactions: ReduxTransactionState;
  youtube: any;
  updateAccount: UpdateAccountReduxState;
  user: UserReduxState;
  vetting: VettingReduxState;
}

/**
 * core       => TierA
 * growth     => TierB
 * pro        => TierC
 * enterprise => TierD
 * og         => tierOG
 */
export const tierLevel = {
  core: "core",
  growth: "growth",
  pro: "pro",
  enterprise: "enterprise",
  og: "og",
} as const;

export type ValidationError = { filedName: string; errorMessage: string }[] | null;
