import { SearchOutlined } from "@ant-design/icons";
import { Checkbox, Input, Popover, Radio } from "antd";
import { useEffect, useRef, useState } from "react";
import { classNames } from "react-extras";
import { AiOutlineFilter, AiOutlineSortDescending } from "react-icons/ai";
import RCButton from "src/components/lib/button/button";
import AdvertiserBrowseResults from "src/components/pages/advertiser_browse/advertiser_browse_page/advertiser_browse_results";
import {
  SHOW_SEARCH_MAX_CPM,
  SHOW_SEARCH_MAX_DOWNLOADS,
  SHOW_SEARCH_MIN_CPM,
  SHOW_SEARCH_MIN_DOWNLOADS,
  SHOW_SEARCH_NUM_RECOMMENDED_TO_SHOW,
  SHOW_SEARCH_SORT_OPTIONS,
} from "src/constants/search";
import { useDebounce } from "src/hooks/lib";
import { useShowSearch } from "src/hooks/shows";
import { ICampaign } from "redcircle-types";
import { IShow } from "redcircle-types";
import styles from "./PodcastSelector.module.scss";
import PodcastSelectorFilterPopover from "./PodcastSelectorFilterPopover";
import {
  DEFAULT_FILTERS,
  DEFAULT_SORT_KEY,
  markExcludedShows,
  shouldShowRecommendedShows,
  sortRecommendedShows,
} from "./PodcastSelectorUtils";

interface IProps {
  selectedShowUUIDs: string[];
  disabledShowUUIDs: Record<string, boolean>;
  onSelect: (selectedUUID: string) => void;
  recommendedShows?: IShow[];
  campaign?: ICampaign; // campaign we're assigning shows to, lets us check for exclusions
}

// Tightened version of Browse Podcasts flow in order to fit in a modal
export default function PodcastSelector({
  selectedShowUUIDs,
  disabledShowUUIDs,
  onSelect,
  recommendedShows,
  campaign,
}: IProps) {
  const searchResultsRef = useRef<HTMLDivElement>(null);

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedQuery = useDebounce(searchQuery);

  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState<keyof typeof SHOW_SEARCH_SORT_OPTIONS>(DEFAULT_SORT_KEY);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [showRecommended, setShowRecommended] = useState(true);

  const { isLoading, results } = useShowSearch(debouncedQuery, {
    page,
    sort: sortKey,
    categoryUUID: filters.categories.join(","),
    minEstimatedWeeklyDownloads:
      filters.downloads[0] !== SHOW_SEARCH_MIN_DOWNLOADS ? filters.downloads[0] : undefined,
    maxEstimatedWeeklyDownloads:
      filters.downloads[1] !== SHOW_SEARCH_MAX_DOWNLOADS ? filters.downloads[1] : undefined,
    minAverageCPM: filters.cpm[0] > SHOW_SEARCH_MIN_CPM ? filters.cpm[0] : undefined,
    maxAverageCPM: filters.cpm[1] !== SHOW_SEARCH_MAX_CPM ? filters.cpm[1] : undefined,
    episodeFrequency: filters.episodeFrequency,
    createdAtSince: filters.createdAtSince,
    primaryGender: filters.primaryGender,
    includeNSFW: filters.includeNSFW,
  });

  // combine recommended shows with search results, tag recommended shows in regular results
  const showCombinedResults =
    recommendedShows && page === 1 && searchQuery === "" && shouldShowRecommendedShows(filters);
  const sortedRecommendedShows = sortRecommendedShows(recommendedShows, sortKey)
    .slice(0, SHOW_SEARCH_NUM_RECOMMENDED_TO_SHOW)
    .map((s) => ({ ...s, isRecommended: true }) as IShow);
  const combinedResults =
    showCombinedResults && showRecommended
      ? [
          ...sortedRecommendedShows,
          ...results.filter((s) => !recommendedShows.find((rs) => rs.uuid === s.uuid)),
        ]
      : results.map((s) => {
          if (recommendedShows && recommendedShows.find((rs) => rs.uuid === s.uuid)) {
            return { ...s, isRecommended: true } as IShow;
          }
          return s;
        });
  const filteredCombinedResults = markExcludedShows(combinedResults, campaign);

  useEffect(() => {
    searchResultsRef?.current?.scroll({ top: 0, behavior: "smooth" });
    setPage(1);
  }, [debouncedQuery, sortKey, filters]);

  const handlePageChange = (page: number) => {
    searchResultsRef?.current?.scroll({ top: 0, behavior: "smooth" });
    setPage(page);
  };

  const handleFilterChange = (key: string, value: any) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleClickAdd = (show: IShow) => {
    onSelect(show.uuid);
  };

  return (
    <div className="flex-column-container">
      <div className="flex-row-container align-center">
        <Input
          type="search"
          size="large"
          placeholder="Search podcasts..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          allowClear
          prefix={<SearchOutlined />}
        />
        <Popover
          title={
            <div className="flex-row-container justify-space-between align-center">
              <span className="ant-popover-title m-b0">FILTER BY</span>
              <RCButton type="secondary" size="small" onClick={() => setFilters(DEFAULT_FILTERS)}>
                Reset All Filters
              </RCButton>
            </div>
          }
          content={() => (
            <PodcastSelectorFilterPopover values={filters} onChange={handleFilterChange} />
          )}
          trigger={["click"]}
          placement="bottom">
          <RCButton type="icon" className="m-lxs">
            <AiOutlineFilter />
          </RCButton>
        </Popover>

        <Popover
          title="SORT BY"
          content={() => (
            <>
              <Radio.Group
                value={sortKey}
                onChange={(e) => setSortKey(e.target.value)}
                className="flex-column-container">
                {Object.keys(SHOW_SEARCH_SORT_OPTIONS).map((key) => (
                  <Radio key={key} value={key}>
                    {SHOW_SEARCH_SORT_OPTIONS[key as keyof typeof SHOW_SEARCH_SORT_OPTIONS].label}
                  </Radio>
                ))}
              </Radio.Group>
              {showCombinedResults && (
                <Checkbox
                  checked={showRecommended}
                  onChange={(e) => setShowRecommended(e.target.checked)}
                  className="m-txs">
                  Show recommended
                  <br /> podcasts at top
                </Checkbox>
              )}
            </>
          )}
          trigger={["click"]}
          placement="bottom">
          <RCButton type="icon" className="m-lxs">
            <AiOutlineSortDescending />
          </RCButton>
        </Popover>
      </div>
      <div className={classNames(styles["table-wrapper"], "m-txs")} ref={searchResultsRef}>
        <AdvertiserBrowseResults
          view={"list"}
          results={filteredCombinedResults}
          isLoading={isLoading}
          currentPage={page.toString()}
          onPageChange={handlePageChange}
          onClickAdd={handleClickAdd}
          selectedShowUUIDs={selectedShowUUIDs}
          disabledShowUUIDs={disabledShowUUIDs}
          externalLink
        />
      </div>
    </div>
  );
}
