import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

/**
 * Extending dayjs with frequently used plugins so it can be used globally
 * without the need to extend again in a local file
 **/
dayjs.extend(quarterOfYear);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(tz);
