export const tierCopy = {
  core: {
    isFree: true,
    title: "Core Plan",
    desc: "Standard features for the individual podcaster.",
    featuresTitle: "Core Plan Features:",
    features: [
      "Unlimited Hosting & Distribution",
      "Subscriptions & Exclusive Content",
      "Donations",
      "RAP Programmatic Ads",
      "RAP Host-Read Ads",
      "Dynamic Insertion for RAP Ads",
      "Standard Analytics",
    ],
    ctaLink: "http://app.redcircle.com/sign-up",
    price: "FREE",
    monthlyPrice: "FREE",
    ctaText: "Get Started",
    borderTopColor: "#EBF0F1",
  },
  growth: {
    title: "Growth Plan",
    desc: "Advanced features for the podcaster looking to grow.",
    featuresTitle: "Everything in Core Plan, plus: ",
    features: [
      "Unlimited Podcasts ",
      "Cross Promotions Marketplace",
      "Unlimited Dynamic Insertion",
      "Instant Payouts",
      "Generate Video for Episodes",
      "Ad Free Podcast Webpages",
      "Advanced Analytics",
    ],
    price: "$14.99",
    monthlyPrice: "$19.99",
    yearlyPrice: "$179.88",

    perMonth: true,
    freeTrial: "*One free trial per account",
    ctaText: "Get 7 Day Free Trial",
    borderTopColor: "#EBF0F1",
    buttonType: "primary",
    monthly: true,
    hasPopular: false,
    savings: "Save $60 per year",
    savingsPercentage: 25,
  },
  pro: {
    title: "Pro Plan",
    desc: "Premium features for the professional podcaster.",
    featuresTitle: "Everything in Growth Plan, plus: ",
    features: [
      "5 Customizable Team Seats",
      "Generate Video w/ one-click distribution to YouTube",
      "Priority Support",
      "Premium Analytics",
      "Leverage Tracking URLs",
      "Run Your Own Ads",
    ],
    price: "$29.99",
    monthlyPrice: "$34.99",
    yearlyPrice: "$359.88",
    freeTrial: "*One free trial per account",
    ctaText: "Get 7-Day Free Trial",
    borderTopColor: "#EBF0F1",
    savings: "Save $60 per year",
    monthly: true,
    hasPopular: true,
    savingsPercentage: 14,
  },
  enterprise: {
    title: "Enterprise Plan",
    desc: "The most comprehensive and custom features for networks.",
    featuresTitle: "Everything in Pro Plan, plus: ",
    features: [
      "Unlimited Customizable Team Seats",
      "Dedicated Account Manager",
      "The most comprehensive analytics for growth, revenue, and dynamic insertion.",
    ],
    price: "$99.99",
    monthlyPrice: "$119.99",
    yearlyPrice: "$1199.88",
    freeTrial: "*One free trial per account",
    ctaText: "Get 7-Day Free Trial",
    borderTopColor: "#EBF0F1",
    savings: "Save $240 per year",
    monthly: true,
    hasPopular: true,
    savingsPercentage: 16.66,
  },
} as Record<string, ITierCopy>;

export interface ITierCopy {
  title?: string;
  desc?: string;
  featuresTitle?: string;
  features: string[];
  price?: string;
  monthlyPrice?: string;
  yearlyPrice?: string;
  freeTrial?: string;
  ctaText?: string;
  borderTopColor?: string;
  savings?: string;
  monthly?: true;
  hasPopular?: boolean;
  isFree?: boolean;
  savingsPercentage?: number;
}

export const tierDowngradeCopy = {
  "pro-growth": [
    "All Team Seats",
    "Embeddable Show Player (Customizable)",
    "Generate Video w/ one-click distribution to YouTube",
    "Full Customization of Programmatic Ads",
    "In-App Support",
    "Premium Analytics",
  ],
  "pro-core": [
    "Unlimited Podcasts",
    "All Team Seats",
    "Embeddable Show Player",
    "Unlimited Dynamic Insertion",
    "Generate Video w/ one-click distribution to YouTube",
    "Cross Promotion Marketplace",
    "Customization of Programmatic Ads",
    "Remove “Related Shows” from Podcast Webpage",
    "Ad-Free Podcast Webpage",
    "Instant Payouts",
    "In-App Support",
    "Premium Analytics",
  ],
  "growth-core": [
    "Unlimited Podcasts",
    "Embeddable Show Player",
    "Unlimited Dynamic Insertion",
    "Generate Video for Episodes",
    "Cross Promotion Marketplace",
    "Customization of Programmatic Ads",
    "Remove “Related Shows” from Podcast Webpage",
    "Ad-Free Podcast Webpage",
    "Instant Payouts",
    "Advanced Analytics",
  ],
  "og-growth": [
    "Embeddable Show Player (Customizable)",
    "Generate Video w/ one-click distribution to YouTube",
    "Full Customization of Programmatic Ads",
    "Original Plan’s Analytics",
  ],
  "og-core": [
    "More than 1 Podcast",
    "Embeddable Show Player",
    "Unlimited Dynamic Insertion",
    "Generate Video for Episodes",
    "Cross Promotion Marketplace",
    "Customization of Programmatic Ads",
    "Original Plan’s Analytics",
  ],
  "enterprise-core": [
    "Unlimited Podcasts",
    "Unlimited Dynamic Insertion",
    "Generate Video for Episodes",
    "Cross Promotion Marketplace",
    "Remove “Related Shows” from Podcast Webpage",
    "Ad-Free Podcast Webpage",
    "All Team Seats",
    "Unlimited Customizable Team Seats",
    "Dedicated Account Manager",
    "Embeddable Show Player (Customizable)",
    "Generate Video w/ one-click distribution to YouTube",
    "Full Customization of Programmatic Ads",
    "In-App Support",
    "Premium Analytics",
  ],
  "enterprise-growth": [
    "All Team Seats",
    "Unlimited Customizable Team Seats",
    "Dedicated Account Manager",
    "Embeddable Show Player (Customizable)",
    "Generate Video w/ one-click distribution to YouTube",
    "Full Customization of Programmatic Ads",
    "In-App Support",
    "Premium Analytics",
  ],
  "enterprise-pro": [
    "Unlimited Customizable Team Seats",
    "Dedicated Account Manager",
    "The most comprehensive analytics for growth, revenue, and dynamic insertion",
  ],
} as Record<string, string[]>;
