import React, { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import { classNames } from "react-extras";
import "./TextSwitch.scss";

interface option {
  value: string;
  key: string;
  hidden?: boolean;
}

interface ITextSwitch {
  options: option[];
  onSelect: (option: option) => void;
  defaultSelected?: string;
  value?: string;
  type?: "pill" | "tab";
  minTabWidth?: string;
  responsiveToTab?: boolean;
  className?: string;
}

const TextSwitch: FunctionComponent<ITextSwitch> = ({
  options = [],
  onSelect,
  defaultSelected = "",
  value: keyOfControlledValue = "",
  type = "pill",
  minTabWidth,
  responsiveToTab = false,
  className = "",
}) => {
  const defaultOption = defaultSelected.length > 0 ? defaultSelected : options[0]?.key;
  const [selected, setSelected] = useState<string>(defaultOption);

  const selectHandler = (option: option) => {
    return () => {
      setSelected(option.key);
      onSelect(option);
    };
  };

  useEffect(() => {
    if (keyOfControlledValue.length !== 0) {
      setSelected(keyOfControlledValue);
    }
  }, [keyOfControlledValue]);

  let style: CSSProperties;

  if (minTabWidth) {
    style = {
      minWidth: minTabWidth,
    };
  }

  return (
    <div
      className={classNames(className, "TextSwitch", type, {
        responsive: responsiveToTab,
      })}>
      {options?.map((option) => {
        return (
          <div
            className={classNames("TextSwitch--option", {
              selected: selected === option?.key,
              hidden: Boolean(option?.hidden),
            })}
            style={style}
            onClick={selectHandler(option)}
            key={option?.key}>
            {option?.value}
          </div>
        );
      })}
    </div>
  );
};

export default TextSwitch;
