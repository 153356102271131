import { Select, Spin, Tag } from "antd";
import { debounce, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { IBrand } from "redcircle-types";
import { getBrandsBulk, searchBrands } from "src/action_managers/brands";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";

interface IBrandsExcludeAutoComplete<T> {
  value?: T;
  onChange?: (value: T) => void;
}

const SEARCH_DEBOUNCE_WAIT_MILLISECONDS = 600;

export const BrandsExcludeAutoComplete = ({
  value = [],
  onChange,
}: IBrandsExcludeAutoComplete<string[]>) => {
  const dispatch = useDispatchTS();

  const [searchValue, setSearchValue] = useState("");

  const allBrandsByInstanceUUID = useSelectorTS((state) => {
    return { ...state.brands.brandsByInstanceUUID, ...state.brands.brandSearchByInstanceUUID };
  });

  const missingBrandInstanceUUIDs = value?.filter(
    (instanceUUID) => !allBrandsByInstanceUUID[instanceUUID]
  );

  // Brand options shown in dropdown post search obtained via search query
  const [brandOptions, setBrandOptions] = useState<Array<Partial<IBrand>>>([]);

  const handleChange = (values: string[]) => {
    onChange?.([...values]);
    setSearchValue("");
  };

  /**
   * Search function
   */
  const handleSearch = (value: string) => {
    setSearchValue(value);
    fetchSearchValue(value);
  };
  /**
   * Debounced search fetch function
   */
  const fetchSearchValue = useCallback(
    debounce((query: string) => {
      if (!isQueryValid(query)) return;

      dispatch(searchBrands(query)).then((res) => {
        if (res.status === 200) {
          const brands = res.json;
          setBrandOptions([...brands]);
        }
      });
    }, SEARCH_DEBOUNCE_WAIT_MILLISECONDS),
    []
  );

  /**
   * On Initialize, need to get brand info for currently excluded instance UUIDs
   */
  useEffect(() => {
    if (value.length === 0) return;

    if (missingBrandInstanceUUIDs.length === 0) {
      const brands = value.map((instanceUUID) => {
        return {
          ...allBrandsByInstanceUUID[instanceUUID],
        };
      });

      setBrandOptions(brands);
    } else {
      dispatch(getBrandsBulk(missingBrandInstanceUUIDs));
    }
  }, [missingBrandInstanceUUIDs?.length]);

  const tagRender = missingBrandInstanceUUIDs?.length === 0 ? undefined : LoadingTagRender;

  return (
    <Select<string[], { label?: string; value?: string }>
      showSearch
      size="large"
      mode="multiple"
      className="width-100"
      placeholder="example: Nike"
      defaultActiveFirstOption={false}
      autoClearSearchValue={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      options={transFormBrandToOptions(brandOptions)}
      value={value}
      searchValue={searchValue}
      tagRender={tagRender}
      onChange={handleChange}
    />
  );
};
/**
 * Simple validation for brand search query, can filter out simple things early on when user is typing.
 * i.e. empty strings, maybe certain characters
 */
const isQueryValid = (query?: string) => {
  if (typeof query !== "string") return false;
  if (query.length === 0) return false;

  return true;
};

/**
 * Helper func to transform brand selections to readable options in the search dropdown
 */
const transFormBrandToOptions = (brandOptions: Partial<IBrand>[]) => {
  return brandOptions?.map((brand) => {
    return { label: brand.name, value: brand.instanceUUID };
  });
};
/**
 *  Loading tag render component, used for init loading state
 */
const LoadingTagRender = () => {
  return (
    <Tag color="default">
      <div className="m-hxxs m-vxxxs">
        <Spin size="small" />
      </div>
    </Tag>
  );
};
