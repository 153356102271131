import { Button, Modal } from "redcircle-ui";

interface IDowngradeModalProps {
  closeModal: () => undefined;
  level: string;
  blockers: Array<{ copy: string; link: string }>;
  title: string;
}

const DowngradeBlockModal = ({ title, closeModal, blockers }: IDowngradeModalProps) => {
  return (
    <Modal open onClose={closeModal} size="xs">
      <Modal.Title>Downgrade My Account</Modal.Title>
      <Modal.Body>
        <span>
          Before we can downgrade your account to <span className="capitalize">{title}</span>,
          you’ll need to:
        </span>
        <ol style={{ border: `1px solid lightgray` }} className="p-ys m-txs">
          {blockers.map(({ copy, link }) => (
            <li key={link}>
              {copy}{" "}
              {link && (
                <a href={link}>
                  <strong>See How</strong>
                </a>
              )}
            </li>
          ))}{" "}
        </ol>
      </Modal.Body>
      <Modal.Footer noBorder className="p-t0">
        <Button type="primary" onClick={closeModal} size="large" className="m-la">
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DowngradeBlockModal;
