import React from "react";
import { getPricingPageURL } from "../../lib/config";
import ExclusiveTag from "./exclusive_tag";

const UpgradeTag = ({ className }: { className?: string }) => {
  return (
    <a className={className} href={getPricingPageURL()} target="_blank" rel="noreferrer">
      <ExclusiveTag backgroundRGB={[255, 224, 225]} backgroundOpacity={1} color="red">
        Upgrade
      </ExclusiveTag>
    </a>
  );
};

export default UpgradeTag;
