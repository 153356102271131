import { Modal, Select, TreeSelect, TreeSelectProps } from "antd";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import React, { useCallback, useState } from "react";
import {
  ConfigProps,
  Field,
  getFormAsyncErrors,
  getFormSyncErrors,
  getFormValues,
  InjectedFormProps,
  reduxForm,
  startAsyncValidation,
  stopAsyncValidation,
  SubmissionError,
} from "redux-form";
import { permissionObjectTypes } from "src/constants/permissions";
import { showError } from "../../actions/app";
import { addCredential, CreateCredential } from "../../action_managers/credentials";
import { checkIfEmailExists } from "../../api/org";
import {
  advertiserRoleMapping,
  creatorRoleMapping,
  permissionPresets,
} from "../../constants/permission_roles";
import { useCredentialsInOrg, usePermissionsInOrg } from "../../hooks/org";
import { useDispatchTS, useSelectorTS } from "../../hooks/redux-ts";
import { isEmail } from "../../lib/email";
import { isShowRedirected } from "../../lib/show";
import FormElement from "../forms/form_element";
import LoadingButton from "../forms/loading_button/loading_button";
import RCButton from "../lib/button";
import Divider from "../lib/divider";
import InfoTooltip from "../lib/info";
import "./modal.scss";
import { UserRole } from "redcircle-types";
import UserRoles from "src/constants/roles";

// FORM NAME
const formName = "AddNewTeamSeat";

// TYPES
interface IAddNewTeamSeat {
  visible: boolean; // Parent controlled modal visibility
  setVisible: React.Dispatch<React.SetStateAction<boolean>>; // Parent change function form visibility
}

export interface IReduxFormProps {
  firstName: string;
  lastName: string;
  email: string;
  entities: string[];
  role: keyof typeof permissionPresets | undefined;
  defaultRole: keyof typeof permissionPresets | undefined;
}

export type formError = Partial<Record<keyof IReduxFormProps, string>>;
export type formMeta = Partial<
  Record<
    keyof IReduxFormProps,
    {
      touched?: boolean;
      visible?: boolean;
      active?: boolean;
    }
  >
>;

// MODAL COMPONENT
const AddNewTeamSeat = (
  props: IAddNewTeamSeat & InjectedFormProps<IReduxFormProps, IAddNewTeamSeat>
) => {
  const {
    visible = false,
    setVisible,
    reset,
    asyncValidating,
    change,
    handleSubmit,
    submitting,
  } = props;
  const dispatch = useDispatchTS();

  // REDUX STATE
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const { user, currentRole: userLoginRole } = useSelectorTS((state) => state?.user);
  const { campaigns } = useSelectorTS((state) => state.campaigns);
  const formValues = useSelectorTS((state) => getFormValues(formName)(state)) as IReduxFormProps;
  const formSyncErrors = useSelectorTS((state) => getFormSyncErrors(formName)(state)) as formError;
  const formAsyncErrors = useSelectorTS((state) =>
    getFormAsyncErrors(formName)(state)
  ) as formError;
  const { getPermissions } = usePermissionsInOrg();
  const { getCredentials } = useCredentialsInOrg();
  // LOCAL STATE
  const [currentPage, setCurrentPage] = useState(0);

  const isCreator = userLoginRole === "creator";
  const isAdvertiser = userLoginRole === "advertiser";

  // Logic
  const listOfShows = isEmpty(shows)
    ? []
    : Object.keys(shows)
        .filter((showUUID) => !isShowRedirected(shows[showUUID]))
        .map((key) => {
          return {
            uuid: shows?.[key]?.uuid,
            title: shows?.[key]?.title || "",
          };
        })
        .sort((a, b) => a?.title?.localeCompare(b?.title));

  const listOfCampaigns = isEmpty(campaigns)
    ? []
    : Object.values(campaigns).sort((a, b) => a?.name?.localeCompare(b?.name));

  const treeData = isCreator
    ? listOfShows.map(({ title, uuid }) => {
        return {
          title,
          value: `${uuid}`,
        };
      })
    : listOfCampaigns.map(({ name, uuid }) => ({
        title: name,
        value: uuid,
      }));

  // Handlers
  const handleCancel = () => {
    setCurrentPage(0);
    setVisible(false);
    reset();
  };
  const handleEntitiesChange = (items: string[]) => {
    change("entities", [...items]);
  };

  const handleEmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(startAsyncValidation(formName));
    handleEmailVerify(e?.target?.value || "");
  };

  // debounced email verify api function call
  const handleEmailVerify = useCallback(
    debounce((email: string) => {
      // Start Form Async Validation
      checkIfEmailExists(email)
        .then((resp) => {
          const errors: formError = {};

          if (Math.floor(resp?.status / 100) !== 2) {
            throw resp;
          }

          return resp.json().then((response: { exists: boolean }) => {
            if (response?.exists) {
              errors.email = "This email already exists";
              dispatch(stopAsyncValidation(formName, errors));
            } else {
              dispatch(stopAsyncValidation(formName, errors));
            }
          });
        })
        .catch(() => {
          dispatch(stopAsyncValidation(formName, { email: "Cannot validate email at this time" }));
        });
    }, 500),
    []
  );

  const disableNextOrSubmit = ({
    page,
    formSyncErrors,
    formAsyncErrors,
    asyncValidating,
    numOfEntities,
    userLoginRole,
  }: {
    page: number;
    formSyncErrors: formError;
    formAsyncErrors: formError;
    asyncValidating: boolean | string;
    numOfEntities: number;
    formValues: IReduxFormProps;
    userLoginRole: UserRole;
  }) => {
    switch (page) {
      case 0:
        return (
          isString(formSyncErrors?.firstName) ||
          isString(formSyncErrors?.lastName) ||
          isString(formSyncErrors?.email) ||
          isString(formAsyncErrors?.email) ||
          Boolean(asyncValidating)
        );

      case 1:
        switch (userLoginRole) {
          case UserRoles.Advertiser:
            return (
              isString(formSyncErrors?.defaultRole) ||
              (Array.isArray(formValues.entities) && formValues.entities.length > 0 // role/campaign check is optional when user does not pick any campaigns in dropdown
                ? isString(formSyncErrors?.role) || isString(formSyncErrors?.entities)
                : false)
            );

          case UserRoles.Creator:
          default:
            return (
              isString(formSyncErrors?.defaultRole) ||
              (numOfEntities > 0 // role/podcast check is optional when account does not have any podcasts
                ? isString(formSyncErrors?.entities) || isString(formSyncErrors?.role)
                : false)
            );
        }

      default:
        return true;
    }
  };

  const handleModalBack = (page: number) => () => {
    if (page === 0) {
      handleCancel();
    } else {
      setCurrentPage((prev) => prev - 1);
    }
  };
  const handleModalNext = (page: number) => () => {
    if (page === pages?.length - 1) {
      // dispatch(submit(formName));
      handleSubmit(handleAddTeamMemberSubmit)();
    } else if (page >= 0 && page < pages?.length - 1) {
      setCurrentPage((prev) => prev + 1);
    } else {
      // Should never get here
      setCurrentPage(0);
    }
  };

  const handleAddTeamMemberSubmit = (formValues: IReduxFormProps) => {
    const { firstName, lastName, email, entities, role, defaultRole } = formValues;

    if (typeof defaultRole === "undefined") {
      return Promise.reject("Issue with default role");
    } // Should never get here

    // Adding credential permission to ORG
    const permissions: CreateCredential["permissions"] = [
      {
        objectUUID: user.uuid,
        objectType: "org",
        preset: defaultRole,
      },
    ];

    // Adding podcast permissions to credential
    if (Array.isArray(entities) && entities.length > 0 && typeof role !== "undefined") {
      entities.forEach((entityUUID) => {
        permissions.push({
          objectUUID: entityUUID,
          objectType: isCreator
            ? permissionObjectTypes.show
            : permissionObjectTypes.campaignOrCampaignTag,
          preset: role,
        });
      });
    }

    // New Credential
    const newCredential: CreateCredential = {
      userUUID: user.uuid,
      firstName,
      lastName,
      email,
      permissions,
    };

    return dispatch(addCredential(newCredential)).then((resp) => {
      if (resp.status !== 200) {
        const error = resp.json?.message || "Could not add team member at this time";
        throw new SubmissionError({ error, _error: error });
      }

      // On Success
      getPermissions();
      getCredentials();
      setVisible(false); // Close Modal
      setCurrentPage(0); // Set page to 0
    });
  };

  const handleFilter: TreeSelectProps["filterTreeNode"] = (inputValue, node) => {
    return node && node.title
      ? (node.title as string).toLowerCase().includes(inputValue.toLowerCase())
      : false;
  };

  /**
   * Defaulting Admin Users to Creator/Podcast view
   */
  const numOfEntities = isCreator ? listOfShows.length : listOfCampaigns.length;

  const currentRoleMapping = isCreator ? creatorRoleMapping : advertiserRoleMapping;

  const disabled = disableNextOrSubmit({
    page: currentPage,
    formSyncErrors,
    formAsyncErrors,
    asyncValidating,
    numOfEntities,
    formValues,
    userLoginRole,
  });

  let disabledMessage =
    disabled &&
    (Object.values(formAsyncErrors ?? {})?.[0] ?? Object.values(formSyncErrors ?? {})?.[0]);

  if (typeof disabledMessage === "string") {
    disabledMessage = disabledMessage
      .substring(0)
      .replace("entities", isCreator ? "podcasts" : "campaigns");
  }

  // MODAL CONTENT/LOGIC
  const pages = [
    {
      title: (
        <div>
          <p className="bold title--1 uppercase"> Add user | 1 of 2</p>
          <h2 className="m-b0">Add New Team Member</h2>
          <Divider marginTop={8} marginBottom={0} />
        </div>
      ),
      content: (
        <div>
          <p className="title--8">Enter the details of your new team member below.</p>
          <Field
            name="firstName"
            label="First name"
            type="text"
            placeholder="Enter user’s first name"
            component={FormElement}
            validateAfterTouch={true}
            validationErrors={formSyncErrors}
            required
          />
          <Field
            name="lastName"
            label="Last Name"
            type="text"
            placeholder="Enter user’s last name"
            component={FormElement}
            validateAfterTouch={true}
            validationErrors={formSyncErrors}
            required
          />
          <Field
            name="email"
            label="Email"
            type="text"
            placeholder="Enter user's email"
            component={FormElement}
            onChange={handleEmailOnChange}
            validateAfterTouch={true}
            validationErrors={{ ...formSyncErrors, ...formAsyncErrors }}
            required
          />
        </div>
      ),
      footer: (
        <div className="flex-row-container justify-space-between align-center">
          <RCButton type="link" className="capitalize" onClick={handleModalBack(currentPage)}>
            cancel
          </RCButton>
          <LoadingButton
            className="fs-xs lh-s p-hl capitalize"
            disabledMessage={disabledMessage}
            disabled={disabled}
            onClick={handleModalNext(currentPage)}>
            next
          </LoadingButton>
        </div>
      ),
    },
    {
      title: (
        <div>
          <p className="bold title--1 uppercase">Add user | 2 of 2</p>
          <h2 className="m-b0">Set Permissions</h2>
          <Divider marginTop={8} marginBottom={0} />
        </div>
      ),
      content: (
        <div>
          {(!!isCreator || !!isAdvertiser) && numOfEntities > 0 && (
            <>
              <p className="title--8">
                You’ve assigned this team member permissions to{" "}
                <strong>{formValues?.entities?.length}</strong> out of{" "}
                <strong>{numOfEntities}</strong> {isCreator ? "podcasts" : "campaigns"}.
              </p>
              <p className="title--1 m-bxxxs uppercase">{isCreator ? "Podcasts" : "Campaigns"}</p>
              <TreeSelect
                className="m-bs"
                style={{ width: "100%" }}
                showSearch
                value={formValues?.entities}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder={isCreator ? "Search Podcasts" : "Search Campaigns"}
                allowClear
                showArrow
                multiple
                treeCheckable
                treeData={treeData}
                filterTreeNode={handleFilter}
                onChange={handleEntitiesChange}
              />
              <p className="title--1 m-bxxxs uppercase">Role</p>
              <Select
                allowClear={true}
                style={{ width: "150px" }}
                virtual={false}
                placeholder="Select Role"
                value={formValues?.role}
                onChange={(value) => change("role", value)}>
                {Object.keys(currentRoleMapping).map((key) => {
                  return (
                    <Select.Option key={key} value={key}>
                      {currentRoleMapping[key as keyof typeof currentRoleMapping]}
                    </Select.Option>
                  );
                })}
              </Select>
              <Divider marginTop={24} marginBottom={24} />
            </>
          )}

          <div className="flex-row-container align-center">
            <p className="m-b0 title--3">
              {isCreator ? (
                <> Assign the following role to all other podcasts in the organization </>
              ) : (
                <> Assign the following role to all other campaigns in the organization </>
              )}
            </p>
            <span>
              <InfoTooltip
                helpText={`You can always update individual ${
                  isCreator ? "shows" : "campaigns"
                } manually, but this helps automate the process`}
                direction="top"
                height={20}
                style={{ color: "#C6C6C6", cursor: "pointer" }}
              />
            </span>

            <Select
              style={{ width: "150px" }}
              className="m-ls"
              virtual={false}
              value={formValues?.defaultRole}
              onChange={(value) => change("defaultRole", value)}
              placeholder="Default Role">
              {Object.keys(currentRoleMapping).map((key) => {
                return (
                  <Select.Option key={key} value={key}>
                    {currentRoleMapping[key as keyof typeof currentRoleMapping]}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </div>
      ),
      footer: (
        <div className="flex-row-container justify-space-between align-center">
          <RCButton type="link" className="capitalize" onClick={handleModalBack(currentPage)}>
            back
          </RCButton>
          <LoadingButton
            className="fs-xs lh-s p-hl capitalize"
            isLoading={Boolean(submitting)}
            disabledMessage={disabledMessage}
            disabled={disabled}
            onClick={handleModalNext(currentPage)}>
            Assign Permissions
          </LoadingButton>
        </div>
      ),
    },
  ];

  return (
    <Modal
      wrapClassName="RC-Antd-Override-Modal"
      maskClosable={false}
      width={600}
      open={visible}
      afterClose={() => (document?.activeElement as HTMLButtonElement)?.blur()}
      centered={true}
      onCancel={handleCancel}
      title={pages?.[currentPage]?.title}
      footer={pages?.[currentPage]?.footer}>
      <form>{pages?.[currentPage]?.content}</form>
    </Modal>
  );
};

const formInitialValues: IReduxFormProps = {
  firstName: "",
  lastName: "",
  email: "",
  entities: [],
  role: undefined,
  defaultRole: undefined,
};

// HELPER FUNCTIONS FOR FORM

const syncValidation = (values: IReduxFormProps = formInitialValues, props: any) => {
  const errors: Partial<Record<keyof IReduxFormProps, string>> = {};

  const minStringLimit = 1;
  const mapWord = {
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
  };

  const arrayOfProps = Object.keys(formInitialValues) as (keyof IReduxFormProps)[];
  for (const prop of arrayOfProps) {
    switch (prop) {
      case "firstName":
      case "lastName":
        if (isEmpty(values?.[prop]) || values?.[prop]?.length < minStringLimit) {
          errors[prop] = `${mapWord[prop]} cannot be empty`;
        }
        break;
      case "email":
        if (!isEmail(values?.[prop])) {
          errors[prop] = `Not a valid Email`;
        }
        if (isEmpty(values?.[prop]) || values?.[prop]?.length < minStringLimit) {
          errors[prop] = `${mapWord[prop]} cannot be empty`;
        }

        break;
      case "entities":
        if (values?.[prop]?.length === 0) {
          errors[prop] = `${prop} cannot be empty`;
        }
        break;
      case "role":
        if (!isString(values?.[prop])) {
          errors[prop] = `${prop} is required`;
        }
        if (values?.[prop]?.length === 0) {
          errors[prop] = `${prop} cannot be empty`;
        }
        break;

      case "defaultRole":
        if (!isString(values?.[prop])) {
          errors[prop] = `default role is required`;
        }
        if (values?.[prop]?.length === 0) {
          errors[prop] = `default role cannot be empty`;
        }
        break;

      default:
        break;
    }
  }

  return errors;
};

export const submitSuccess: ConfigProps<IReduxFormProps, IAddNewTeamSeat>["onSubmitSuccess"] = (
  result,
  dispatch,
  props
) => {
  props.reset && props.reset();
};

export const submitFailed: ConfigProps<IReduxFormProps, IAddNewTeamSeat>["onSubmitFail"] = (
  errors,
  dispatch: any
) => {
  const error = errors?._error || "Could not add team member at this time";
  dispatch(showError(error, 2000));
};

// WRAPPED MODAL
const AddNewTeamSeatWrapper = reduxForm<IReduxFormProps, IAddNewTeamSeat>({
  form: formName,
  initialValues: formInitialValues,
  validate: syncValidation,
  onSubmitSuccess: submitSuccess,
  onSubmitFail: submitFailed,
})(AddNewTeamSeat);

export default AddNewTeamSeatWrapper;
