import keyBy from "lodash/keyBy";
import omit from "lodash/omit";
import {
  AUDIO_BLOCK_CREATE,
  AUDIO_BLOCK_DELETE,
  AUDIO_BLOCK_GET,
  AUDIO_BLOCK_GET_FOR_USER,
  AUDIO_BLOCK_GET_STATS_FOR_USER,
  FETCH_RECENT_AUDIO_BLOCKS,
} from "../../action_managers/audio_management";
import { httpMultipleActionsReducer, httpReducer, reduceReducers } from "../../lib/create-action";
import { AudioBlock, AudioBlocksReduxState } from "./types";

const initialState: AudioBlocksReduxState = {
  audioBlocks: {},
  recentAudioBlockUUIDs: [],
  isLoading: false,
};

const getForUser = httpReducer(AUDIO_BLOCK_GET_FOR_USER, initialState, {
  success: (state, action) => {
    const res = keyBy(action.payload.resp, "uuid");
    const audioBlocks = { ...state?.audioBlocks };

    for (const uuid in res) {
      audioBlocks[uuid] = { ...audioBlocks[uuid], ...res[uuid] };
    }

    return { ...state, audioBlocks };
  },
});

const getStatsForUser = httpReducer(AUDIO_BLOCK_GET_STATS_FOR_USER, initialState, {
  success: (state, action) => {
    const updatedAudioBlocks = { ...state?.audioBlocks };
    const stats = action.payload.resp.audioBlockStats;

    Object.keys(stats).forEach((audioBlockUUID) => {
      updatedAudioBlocks[audioBlockUUID] = {
        ...updatedAudioBlocks[audioBlockUUID],
        stats: stats[audioBlockUUID],
      };
    });

    return { ...state, audioBlocks: updatedAudioBlocks };
  },
});

const get = httpReducer(AUDIO_BLOCK_GET, initialState, {
  success: (state, action) => ({
    ...state,
    audioBlocks: {
      ...state.audioBlocks,
      [action.data.uuid]: {
        ...action.payload.resp,
        stats: { ...state.audioBlocks[action.data.uuid].stats },
      },
    },
  }),
});

const create = httpReducer(AUDIO_BLOCK_CREATE, initialState, {
  success: (state, action) => ({
    ...state,
    audioBlocks: {
      ...state.audioBlocks,
      [action.payload.resp.uuid]: {
        ...action.payload.resp,
        stats: { insertionsLast30Days: 0, episodes: 0 },
      },
    },
  }),
});

const deleteAudioBlock = httpReducer(AUDIO_BLOCK_DELETE, initialState, {
  success: (state, action) => ({
    ...state,
    audioBlocks: omit(state.audioBlocks, action.data.uuid),
    recentAudioBlockUUIDs: state.recentAudioBlockUUIDs.filter((uuid) => uuid !== action.data.uuid),
  }),
});

const getAudioBlocks = httpMultipleActionsReducer([FETCH_RECENT_AUDIO_BLOCKS], initialState, {
  success: (state, action) => {
    const newState = keyBy(action.payload.resp, "uuid");
    return { ...state, audioBlocks: { ...state.audioBlocks, ...newState } };
  },
});

const getRecentAudioBlocks = httpReducer(FETCH_RECENT_AUDIO_BLOCKS, initialState, {
  success: (state, action) => {
    return {
      ...state,
      recentAudioBlockUUIDs: action.payload.resp.map((block: AudioBlock) => block.uuid),
    };
  },
});

export default reduceReducers(
  getForUser,
  getStatsForUser,
  get,
  create,
  deleteAudioBlock,
  getAudioBlocks,
  getRecentAudioBlocks
);
