import { Checkbox, Form, Radio } from "antd";

export default function CampaignFormTargetAudience() {
  const form = Form.useFormInstance();
  const targetAges = Form.useWatch("targetAges", form);
  const isAllAgesChecked = Array.isArray(targetAges) && targetAges.length === 0;

  const handleAllClick = () => {
    form.setFieldValue("targetAges", []);
  };

  return (
    <div className="flex-column-container">
      <label className="redcircle-form-label fs-11" htmlFor="targetGender">
        Primary Gender*
      </label>

      <Form.Item name="targetGender">
        <Radio.Group className="flex-column-container">
          <Radio value="all" className="m-bxs">
            All/No Preference
          </Radio>
          <Radio value="female" className="m-bxs">
            Primarily Female
          </Radio>
          <Radio value="male" className="m-bxs">
            Primarily Male
          </Radio>
        </Radio.Group>
      </Form.Item>

      <label className="redcircle-form-label fs-11" htmlFor="targetAges">
        Primary Age*
      </label>

      <Checkbox className="m-bxs" checked={isAllAgesChecked} onClick={handleAllClick}>
        Any Age
      </Checkbox>
      <Form.Item name="targetAges">
        <Checkbox.Group className="flex-column-container">
          <Checkbox value="0-17" className="m-bxs">
            &lt; 18
          </Checkbox>
          <Checkbox value="18-22" className="m-bxs">
            18-22
          </Checkbox>
          <Checkbox value="23-27" className="m-bxs">
            23-27
          </Checkbox>
          <Checkbox value="28-34" className="m-bxs">
            28-34
          </Checkbox>
          <Checkbox value="35-44" className="m-bxs">
            35-44
          </Checkbox>
          <Checkbox value="45-59" className="m-bxs">
            45-54
          </Checkbox>
          <Checkbox value="60-150" className="m-bxs">
            60+
          </Checkbox>
        </Checkbox.Group>
      </Form.Item>
    </div>
  );
}
