import classNames from "classnames";
import { useContext } from "react";
import { formatDurationPlayback } from "redcircle-lib";
import { AudioPlayerContext } from "./Wrapper";

export default function AudioPlayerTime({ className }: { className?: string }) {
  const { audioData } = useContext(AudioPlayerContext);
  const { currentTime, duration } = audioData;

  return (
    <span className={classNames("font-size-sm", className)}>
      {!currentTime || !duration ? (
        "Loading..."
      ) : (
        <>
          {formatDurationPlayback(currentTime)} / {formatDurationPlayback(duration)}
        </>
      )}
    </span>
  );
}
