import get from "lodash/get";
import mapValues from "lodash/mapValues";
import pick from "lodash/pick";
import React, { Component } from "react";

/**
 * This file sets up a wrapper around redux-form's reduxForm function that
 * does some special handling for seedcast-ui's specific needs.  It is expected
 * that all redux-forms are wrapped with this HOC to make it easy to migrate
 * the behavior of entire forms.
 *
 * When passing initialValues to a redux-form, all of the initialValues are
 * passed through when submitting, even if there is no Field registered on
 * the form with that name.  By default, a form wrapped with seedcastForm
 * will filter out any fields not present in the form itself, except for the key
 * 'uuid'.  By default, we also set any empty fields to `null` so the field will be
 * removed in update requests.
 *
 * If you want to have alternative filtering logic, you can pass an optional
 * config object to seedcastForm with a key 'submissionFilter'.  submissionFilter should
 * be a function which wraps a redux-forms onSubmit handler.
 *
 * Any behavior to be added to all forms should be included here.
 */

export function standardFilter(submitHandler, ...allowedKeys) {
  return function (values, dispatch, props) {
    // Only let registered fields through.
    if (props.registeredFields) {
      values = pick(values, Object.keys(props.registeredFields).concat(allowedKeys));
    }
    // Set any empty strings (or empty quill textarea values) to
    // null on their way out.
    values = mapValues(values, (val) => {
      if (allowedKeys.includes(val)) {
        return val;
      }
      return val === "" || quillIsEmpty(val) ? null : val;
    });

    return submitHandler(values, dispatch, props);
  };
}

export function standardFilterExceptUUID(submitHandler, whiteList = []) {
  return standardFilter(submitHandler, ...whiteList.concat("uuid"));
}

export function seedcastForm(WrappedForm, config) {
  return class extends Component {
    submissionFilter(submitFunction) {
      if (get(config, "submissionFilter")) {
        return config.submissionFilter(submitFunction);
      }
      return standardFilterExceptUUID(submitFunction, get(config, "whiteList"));
    }

    render() {
      var submitHandler = this.props.onSubmit;
      if (submitHandler) {
        submitHandler = this.submissionFilter(submitHandler);
      }
      return <WrappedForm {...this.props} onSubmit={submitHandler} />;
    }
  };
}

export const quillIsEmpty = (val) => val === "<p><br></p>";
