import React, { lazy, PropsWithChildren, Suspense } from "react";
import Spinner from "../../../lib/spinner";
import { graphFonts } from "../analyticsUtility";
import Message from "./Messages";
import "./redcircle_graphs.scss";

const Pie = lazy(() => import("@ant-design/plots/es/components/pie"));

const defaultColors = [1, 0.8, 0.6, 0.4, 0.2, 0.9, 0.7, 0.5, 0.3, 0.1].map((opacity) => {
  return `rgba(87, 125, 158, ${opacity.toFixed(1)})`;
});

// Inferring Graph config type
type graphConfig = Parameters<typeof Pie>[number];

// Interface with Generic T of type datapoint to restrict field types
interface IRCDonut<T> {
  data: T[];
  angleField: string & keyof T;
  colorField: string & keyof T;
  color?: string[];
  loading?: boolean;
  height?: Required<graphConfig>["height"];
  width?: Required<graphConfig>["width"];
  tooltip?: Required<graphConfig>["tooltip"];
  legend?: Required<graphConfig>["legend"];
  statistic?: Required<graphConfig>["statistic"];
  interactions?: Required<graphConfig>["interactions"];
  label?: Required<graphConfig>["label"];
}

const RCDonut = <T extends Record<string, any>>(props: PropsWithChildren<IRCDonut<T>>) => {
  const {
    data = [],
    angleField,
    colorField,
    color = defaultColors,
    height = 200,
    width = 200,
    loading,
    tooltip,
    legend,
    statistic,
    label = false as const,
    interactions = [{ type: "pie-statistic-active" }],
  } = props;

  const config: graphConfig = {
    style: {
      minHeight: `${height}px`,
    },
    loading,
    height,
    width,
    appendPadding: 10,
    legend,
    data,
    color,
    angleField,
    colorField,
    tooltip,
    radius: 1,
    innerRadius: 0.8,
    label,
    interactions,
    statistic,
    theme: {
      styleSheet: {
        fontFamily: graphFonts,
      },
    },
  };

  return (
    <div className="analytics-graphContainer height-100" style={{ minHeight: `${height}px` }}>
      <Suspense fallback={<Spinner />}>
        <Pie {...config} />
      </Suspense>
      <Message show={!loading && data?.length === 0} type="Insufficient Data" />
    </div>
  );
};

export default RCDonut;
