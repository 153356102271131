import { useState } from "react";
import { MultiModal } from "redcircle-ui";
import {
  getShowCampaign,
  updateCampaignOfferShowNotes,
  updateCampaignOfferUploadAudio,
} from "src/actions/campaigns";
import { useDispatchTS } from "src/hooks/redux-ts";
import { ICampaign } from "redcircle-types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "redcircle-types";
import ReviewShowNotesPage from "./review_show_notes_page";
import UploadAudioPage from "./upload_audio_page";

interface IProps {
  open: boolean;
  onClose: () => void;
  campaign: ICampaign;
  campaignItem: ICampaignItem;
  show: IShow;
}

export default function ReviewAndUploadAudioModal({
  open,
  onClose,
  campaign,
  campaignItem,
  show,
}: IProps) {
  const dispatch = useDispatchTS();
  const [currentPage, setCurrentPage] = useState(0);

  const handleClose = () => {
    onClose();
    setCurrentPage(0);
  };

  const pages = [
    {
      id: "review_show_notes",
      title: show.isRemote ? "Add The CTA To Your Show Notes" : "Review Your Show Notes",
      subtitle: show.isRemote
        ? "Your campaign will achieve higher performance if you add this into your episode notes while the campaign is ongoing. Here's a suggestion for the CTA to add:"
        : "Please review your show notes - we'll automatically add this to your episodes when the campaign begins and remove it when the campaign ends.",
      bodyClassName: "p-a0",
      body: (
        <ReviewShowNotesPage
          show={show}
          campaign={campaign}
          campaignItemUUID={campaignItem.uuid}
          onClose={onClose}
          onSubmit={async (feedCTA: string) => {
            const res = await dispatch(
              updateCampaignOfferShowNotes({
                campaign: { uuid: campaign.uuid, isV2: campaignItem.isV2 } as ICampaign,
                campaignItemUUID: campaignItem.uuid,
                feedCTA,
              })
            );

            if (
              res.status === 200 &&
              (Array.isArray(res.json) ? res.json[0]?.feedCTA : res.json?.feedCTA) // The response is different based on V1/V2 campaigns
            ) {
              dispatch(getShowCampaign(show.uuid, campaign.uuid));
              setCurrentPage(1);
            }
          }}
        />
      ),
    },
    {
      id: "upload_audio",
      title: "Upload & Submit Advertisement",
      subtitle: (
        <span>
          Review the details of the ad read below then record and upload your Host-Read audio for
          this promotion. Once uploaded, click <strong>Submit</strong>. This ad will automatically
          run on the available audio blocks on your show’s episodes.
        </span>
      ),
      bodyClassName: "p-a0",
      body: (
        <UploadAudioPage
          campaignItem={campaignItem}
          show={show}
          onBack={() => setCurrentPage(currentPage - 1)}
          onSubmit={async (mediaFileUUID: string) => {
            const res = await dispatch(
              updateCampaignOfferUploadAudio({
                campaignItemUUID: campaignItem.uuid,
                mediaFileUUID,
              })
            );
            if (res.status === 200 && res.json?.mediaFileUUID) {
              handleClose();
            }
          }}
        />
      ),
    },
  ];

  return (
    <MultiModal
      size="sm"
      open={open}
      onClose={handleClose}
      currentPage={currentPage}
      pages={pages}
      maskClosable={false}
    />
  );
}
