import get from "lodash/get";
import head from "lodash/head";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import toLower from "lodash/toLower";
import React, { Component } from "react";
import { Button, Col, ControlLabel, Grid, Row } from "react-bootstrap";
import FormControl from "react-bootstrap/es/FormControl";
import { HELP_GUIDE_BY_HOST } from "../../data/host_migration_guide";
import { ACTIONS, CATEGORIES, gtagEvent } from "../../lib/google_analytics";
import LoadingButton from "../forms/loading_button/loading_button";
import Divider from "../lib/divider";
import ExternalLink from "../lib/external_link";
import BaseModal from "./base_modal";

// Default option for redirect modal is other. We display this when we cannot detect which host the importedURL is from.
const DefaultOption = "Other";

class ShowRedirectHelpModal extends Component {
  state = { optionValue: DefaultOption, isLoading: false };

  componentDidMount() {
    // we do this because we are able to reach the modal directly (without going to the show list page first),
    // and we need the shows data for the modal to work as intended
    if (isEmpty(this.props.shows)) {
      this.props.fetchShowsForUser(this.props.user).then(() => {
        const show = this.getShow();
        const hostingService = this.getHostingServiceFromImportedURL(get(show, "importedURL"));
        this.setState({ optionValue: hostingService });
      });
    } else {
      const show = this.getShow();
      const hostingService = this.getHostingServiceFromImportedURL(get(show, "importedURL"));
      this.setState({ optionValue: hostingService });
    }
  }

  close = () => {
    this.props.history.push(this.props.closeRoute(this.props.location));
    this.props.closeModal();
  };

  onChange = (event) => {
    this.setState({ optionValue: event.target.value });
  };

  content = (props) => {
    const options = Object.keys(HELP_GUIDE_BY_HOST).map((name, index) => {
      return (
        <option key={index} value={name}>
          {name}
        </option>
      );
    });
    const rssURLAsInput = (
      <FormControl
        componentClass="input"
        className="m-ts"
        value={get(props, "rssFeedURL")}
      />
    );
    const getHelpContent = HELP_GUIDE_BY_HOST[this.state.optionValue];

    return (
      <Grid fluid className="show-redirect-help-modal">
        <Row>
          <Col xs={12} mdOffset={1} md={10}>
            To complete your switch onto RedCircle, your previous podcast hosting service must
            redirect requests to your RedCircle RSS URL. Follow the steps below for your
            corresponding hosting service. To learn more about the redirecting process click{" "}
            <a
              href="https://support.redcircle.com/redcircle-migration-guide"
              target="_blank"
              rel="noopener noreferrer">
              here
            </a>
            .
          </Col>
          <Col xs={12} md={10} mdOffset={1}>
            <div className="show-redirect-help-modal__select-wrapper">
              <ControlLabel className="redcircle-form-label">
                Select Your Hosting Service
              </ControlLabel>
              <FormControl
                value={this.state.optionValue}
                componentClass="select"
                onChange={this.onChange}>
                {options}
              </FormControl>
            </div>
          </Col>
          <Col xs={12} md={10} mdOffset={1}>
            <div className="help-content-wrapper">
              <ControlLabel className="redcircle-form-label">Directions</ControlLabel>
            </div>
            {getHelpContent(rssURLAsInput)}
          </Col>
          <Col xs={12} md={10} mdOffset={1}>
            <Divider smallMargin />
            <ControlLabel className="redcircle-form-label">Spotify</ControlLabel>

            <div className="help-content__support-section">
              Already distributed to Spotify? Follow these{" "}
              <ExternalLink href="http://support.redcircle.com/en/articles/3222319-updating-spotify-when-switching-to-redcircle">
                instructions.
              </ExternalLink>
            </div>
          </Col>
          <Col xs={12} md={10} mdOffset={1}>
            <Divider smallMargin />
            <ControlLabel className="redcircle-form-label">Having trouble?</ControlLabel>
            <div className="help-content__support-section">
              We're just{" "}
              <ExternalLink href="https://support.redcircle.com/">one click away.</ExternalLink>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  };

  footer = () => {
    return (
      <Grid fluid className="show-redirect-help-modal__footer">
        <Row>
          <Col xs={12} md={6}>
            <Button className="btn-secondary show-redirect-help-modal__close" onClick={this.close}>
              Cancel
            </Button>
          </Col>
          <Col xs={12} md={6}>
            <LoadingButton
              Button={Button}
              isLoading={this.state.isLoading}
              className="btn-primary show-redirect-help-modal__redirect"
              onClick={this.verifyRedirect}>
              Verify Redirect
            </LoadingButton>
          </Col>
        </Row>
      </Grid>
    );
  };

  verifyRedirect = () => {
    this.setState({ isLoading: true });
    this.props.fetchVerifyRedirect(this.getShow()).then((resp) => {
      // TODO backend returns `true` instead of valid json
      this.setState({ isLoading: false });
      if (resp.json === true) {
        this.close();
        gtagEvent(ACTIONS.SUBMIT, CATEGORIES.VERIFY_REDIRECT, { success: false });
      } else {
        gtagEvent(ACTIONS.SUBMIT, CATEGORIES.VERIFY_REDIRECT, { success: true });
      }
    });
  };

  getHostingServiceFromImportedURL = (importedURL) => {
    if (isEmpty(importedURL)) {
      return DefaultOption;
    }
    const hosts = Object.keys(HELP_GUIDE_BY_HOST).filter((host) => {
      return includes(importedURL, toLower(host));
    });
    if (isEmpty(hosts)) {
      return DefaultOption;
    }
    return head(hosts);
  };

  getShow = () => {
    const pathname = this.props.location.pathname;
    const [, , uuid] = pathname.split("/");
    return get(this.props.shows, uuid);
  };

  render() {
    const show = this.getShow();
    const rssFeedURL = get(show, "rssURL");

    return (
      <BaseModal
        title={"Switching to RedCircle"}
        close={this.close}
        showContent={true}
        Content={this.content}
        Footer={this.footer}
        rssFeedURL={rssFeedURL}
      />
    );
  }
}

export default ShowRedirectHelpModal;
