export const tierLevels = {
  core: "core",
  growth: "growth",
  pro: "pro",
  enterprise: "enterprise",
  og: "og",
};

export const TIER_LEVEL_ANNUAL = "annual";
export const TIER_LEVEL_MONTHLY = "monthly";

export type tierLevel = keyof typeof tierLevels;
