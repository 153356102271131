import Clipboard from "clipboard";
import get from "lodash/get";
import React, { Component } from "react";
import { Button, Col, Glyphicon, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { showMessageWithTimeout } from "../../actions/app";
import { hideModal } from "../../actions/modal";
import { clearDealValidationErrors, createDeal } from "../../action_managers/deals";
import messageType from "../../constants/message_types";
import Divider from "../lib/divider";
import ExternalLink from "../lib/external_link";
import BaseModal from "./base_modal";

class DirectoryInviteModal extends Component {
  getLink = () => {
    return this.props.directoryShow.inviteLink;
  };

  componentDidMount(prevProps, prevState, snapshot) {
    ["share-link-copy", "email-address-copy"].forEach((id) => {
      // have to use next tick because of reasons unknown to humans
      setTimeout(() => {
        let container = document.getElementsByClassName("modal")[0];
        if (container) {
          this.clipboard = new Clipboard("#" + id, {
            container: container,
          }).on("success", this.showCopySuccess);
        }
      }, 0);
    });
  }

  componentWillUnmount() {
    if (!this.clipboard) {
      this.clipboard.destroy();
    }
  }

  getBody = () => {
    let firstName = get(this.props, "directoryShow.invite.firstName");
    return encodeURIComponent(
      `Hi${
        firstName ? " " + firstName : ""
      }!\n\nI found your podcast on RedCircle, a podcast hosting platform that will let us promote each other's podcasts.  Check them out and sign up using the link below if you'd like to cross-promote your podcast with mine.\n\n${this.getLink()}`
    );
  };

  getSubject = () => {
    return encodeURIComponent("Collaborate with me on RedCircle");
  };

  getRecipientEmail = () => {
    return get(this.props, "directoryShow.invite.email", "");
  };

  getRecipientEmailEncoded = () => {
    return encodeURIComponent(this.getRecipientEmail());
  };

  getMailtoLink = () => {
    return `mailto:${this.getRecipientEmailEncoded()}?subject=${this.getSubject()}&body=${this.getBody()}`;
  };

  showCopySuccess = (e) => {
    this.props.showMessageWithTimeout("Successfully copied to clipboard!", messageType.Success);
    e.clearSelection();
  };

  modalContent = () => {
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} xsOffset={0} md={10} mdOffset={1}>
            <div className="directory-invite-modal__intro">
              This podcast isn't hosted on RedCircle yet. You can reach out to them, but our
              collaboration tools won{"'"}t be available until they join.
              <br />
              <br />
              Would you like to invite them to RedCircle?
            </div>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col
            xs={6}
            xsOffset={0}
            md={5}
            mdOffset={1}
            className="directory-invite-modal__button-column">
            <Button className={"btn btn-secondary"}>
              <ExternalLink
                href={this.getMailtoLink()}
                target="_blank"
                className="no-style-link directory-invite-modal__email-link">
                &nbsp;
              </ExternalLink>
              <Glyphicon glyph={"envelope"} /> Email
            </Button>
          </Col>
          <Col xs={6} md={5} className="directory-invite-modal__button-column">
            <Button
              data-clipboard-text={this.getLink()}
              id={"share-link-copy"}
              className={"btn btn-secondary share-link-copy"}>
              <Glyphicon glyph={"duplicate"} />
              &nbsp; Copy Invite Link
            </Button>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            md={10}
            mdOffset={1}
            className="directory-invite-modal__email-copy-link-wrapper">
            Email them at{" "}
            <Button
              id="email-address-copy"
              data-clipboard-text={this.getRecipientEmail()}
              className="btn-link seedcast-btn-link directory-invite-modal__email-copy-link">
              {this.getRecipientEmail()}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={10} xsOffset={1}>
            <div className="mini-modal-footer">
              <Button className="btn btn-primary" onClick={this.props.hideModal}>
                Okay
              </Button>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  };

  render() {
    return (
      <BaseModal
        isLoading={false}
        isMini={true}
        columns={10}
        offset={1}
        title={"Invite to RedCircle?"}
        close={this.props.hideModal}
        hideCloseButton={false}
        showContent={true}
        Content={this.modalContent}
      />
    );
  }
}

export default withRouter(
  connect(() => ({}), {
    hideModal,
    createDeal,
    clearDealValidationErrors,
    showMessageWithTimeout,
  })(DirectoryInviteModal)
);
