import React from "react";
import { Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { seedcastForm } from "../../../lib/forms";
import FormElement from "../../forms/form_element";
import LoadingButton from "../../forms/loading_button/loading_button";

let SignInForm = (props) => {
  if (typeof props.user !== "undefined" && props.user !== null) {
    return <Redirect to="/" />;
  }
  return (
    <form onSubmit={props.handleSubmit} data-testid="form">
      {props.hasError && (
        <div className="alert alert-danger" role="alert">
          {props.errorMessage}
        </div>
      )}
      <div>
        <Field component={FormElement} name="email" label="Email" type="email" aria-label="email" />
      </div>
      <div>
        <Field
          component={FormElement}
          aria-label="password"
          label="Password"
          name="password"
          type="password"
          style={{ fontSize: "28px" }}
        />
      </div>
      <hr className="redcircle-divider" />
      <LoadingButton
        disabled={!props.submitEnabled}
        type="submit"
        className="width-100 m-bxs"
        isLoading={props.isLoading}>
        Sign In
      </LoadingButton>
    </form>
  );
};

SignInForm = reduxForm({
  form: "sign-in",
})(SignInForm);
SignInForm = seedcastForm(SignInForm);

export default SignInForm;
