import { GET_SCHEDULED_JOB } from "../action_managers/scheduled_job";
import { httpReducer, reduceReducers } from "../lib/create-action";

const initialState = {
  scheduledJobs: {},
};
const getScheduledJob = httpReducer(GET_SCHEDULED_JOB, initialState, {
  success: (state, action) => ({
    ...state,
    scheduledJobs: { ...state.scheduledJobs, [action.payload.resp.uuid]: action.payload.resp },
  }),
});

export default reduceReducers(getScheduledJob);
