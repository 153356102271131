import get from "lodash/get";
import has from "lodash/has";
import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Grid, Row } from "react-bootstrap";
import { If } from "react-extras";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateDealMeta } from "../../../action_managers/deals";
import { permissionTypes } from "../../../constants/permission_roles";
import { withPermissions } from "../../../lib/permissions-js";
import { snakeCaseToTitleCase } from "../../../lib/strings";
import ContextMenu from "../../lib/context_menu";
import Divider from "../../lib/divider";
import "./my-cross-promotions-table.scss";

class MyCrossPromotionsTable extends Component {
  state = {
    showHiddenDeals: false,
  };

  sortDealsForUser = (deals = [], userUUID) => {
    const { withNewMessage, withoutNewMessage, isHidden } = deals.reduce(
      (agg, deal) => {
        const dealRequest = get(deal, ["dealRequests", userUUID], {});
        let category;
        if (dealRequest.isHidden) {
          category = "isHidden";
        } else if (dealRequest.unreadMessageCount) {
          category = "withNewMessage";
        } else {
          category = "withoutNewMessage";
        }
        agg[category].push(deal);
        return agg;
      },
      {
        withNewMessage: [],
        withoutNewMessage: [],
        isHidden: [],
      }
    );

    [withNewMessage, withoutNewMessage, isHidden].forEach((deals) => {
      deals.sort((deal1, deal2) => {
        const createdAt1 = get(deal1, ["dealRequests", userUUID, "createdAt"], 0);
        const createdAt2 = get(deal2, ["dealRequests", userUUID, "createdAt"], 0);
        return createdAt2 - createdAt1;
      });
    });

    return {
      dealsVisible: withNewMessage.concat(withoutNewMessage),
      dealsHidden: isHidden,
    };
  };

  userCanAccessDeal = (deal) => {
    return this.props.canAccess(permissionTypes.editCrossPromo, deal?.userShow?.uuid);
  };

  render() {
    const { deals = [], userUUID, updateDealMeta } = this.props;

    if (!deals.length) {
      return null;
    }

    const userDeals = deals.filter((deal) => has(deal, ["dealRequests", userUUID]));
    const { dealsVisible, dealsHidden } = this.sortDealsForUser(userDeals, userUUID);
    let dealsToShow = dealsVisible;
    if (this.state.showHiddenDeals) {
      dealsToShow = dealsToShow.concat(dealsHidden);
    }

    return (
      <Grid fluid>
        <Row className="my-cross-promotions-table__header flex-row-container">
          <Col className="bold" md={4}>
            Promotion
          </Col>
          <Col className="bold" md={3} smHidden={true} xsHidden={true}>
            Partner Podcast
          </Col>
          <Col className="bold" md={3} smHidden={true} xsHidden={true}>
            Your Podcast
          </Col>
          <Col className="bold" md={2}>
            Status
          </Col>
        </Row>
        {dealsToShow.map((deal) => (
          <MyCrossPromotionsRow
            key={deal.uuid}
            deal={deal}
            userUUID={userUUID}
            updateDealMeta={updateDealMeta}
            canEdit={this.userCanAccessDeal(deal)}
          />
        ))}
        <If condition={!this.state.showHiddenDeals}>
          <Row>
            <Button
              className="btn btn-link redcircle-btn-link"
              onClick={() => this.setState({ showHiddenDeals: true })}>
              View More ({dealsHidden.length})
            </Button>
          </Row>
        </If>
      </Grid>
    );
  }
}

class MyCrossPromotionsRow extends Component {
  renderPromotionColumn = (userDealRequest) => {
    const unreadMessageCount = get(userDealRequest, "unreadMessageCount", 0);
    const createdAt = moment.unix(get(userDealRequest, "createdAt", 0)).format("LL");

    return (
      <Col md={4}>
        <div className="promotions-deal-page__row-title bold">
          {userDealRequest.title}
          {!!unreadMessageCount && (
            <span className={"m-ls promotions-page__unread-message-count"}>
              {unreadMessageCount}
            </span>
          )}
        </div>
        <div className="my-cross-promotions-row__date">Initiated At {createdAt}</div>
      </Col>
    );
  };

  renderShowColumn = (show) => {
    const showTitle = get(show, "title", "");
    const showImage = get(show, "imageURL", "");

    return (
      <Col md={3} className="my-cross-promotions-row__podcast" smHidden={true} xsHidden={true}>
        <img width="36px" height="36px" src={`${showImage}?d=64x64`} alt={showTitle} />
        <span className="my-cross-promotions-row__show-title bold m-ls">
          {showTitle}
        </span>
      </Col>
    );
  };

  renderNoShowColumn = () => {
    return (
      <Col md={3} className="my-cross-promotions-row__podcast" smHidden={true} xsHidden={true}>
        <div className="my-cross-promotions-row__gray-box" />
        <span className="m-ls">None Selected</span>
      </Col>
    );
  };

  renderStatusColumn = () => {
    const { deal = {}, userUUID, updateDealMeta } = this.props;
    const userDealRequest = get(deal, ["dealRequests", userUUID], {});
    const dealState = get(deal, "state");
    const budgetUsed = get(deal, "userBudget.current", 0);
    const budgetTotal = get(deal, "userBudget.total", 100);
    const budgetPercentageUsed = (budgetUsed * 100) / budgetTotal;

    return (
      <Col md={2} className="flex-row-container align-center">
        <span className="my-cross-promotions-row__status bold">
          {snakeCaseToTitleCase(dealState)}
          <If condition={dealState === "running"}>
            <div className="my-cross-promotions-row__budget-progress-wrapper">
              <div
                className="my-cross-promotions-row__budget-progress"
                style={{ width: `${budgetPercentageUsed}%` }}
              />
            </div>
          </If>
        </span>
        {this.renderContextMenu(userUUID, userDealRequest, updateDealMeta)}
      </Col>
    );
  };

  renderContextMenu = (userUUID, dealRequest, updateDealMeta) => {
    if (!this.props.canEdit) {
      return null;
    }
    const { isHidden } = dealRequest;

    const menuItem = {
      [`${isHidden ? "Restore" : "Hide"} Deal`]: () => {
        updateDealMeta(dealRequest.dealUUID, {
          isHidden: !isHidden,
          ownerUUID: userUUID,
        });
      },
    };
    return (
      <ContextMenu
        className="my-cross-promotions-row__context-menu"
        noCircle={true}
        menuItems={menuItem}
      />
    );
  };

  render() {
    const { deal = {}, userUUID } = this.props;
    const userDealRequest = get(deal, ["dealRequests", userUUID], {});
    const partnerPodcastColumn = get(deal, "partnerShow")
      ? this.renderShowColumn(deal.partnerShow)
      : this.renderNoShowColumn();
    const yourPodcastColumn = get(deal, "userShow")
      ? this.renderShowColumn(deal.userShow)
      : this.renderNoShowColumn();

    return (
      <Row>
        <Link
          className={"promotions-page__deal-request-row no-style-link flex-1"}
          to={`/promotions/deals/${deal.uuid}`}>
          {this.renderPromotionColumn(userDealRequest)}
          {partnerPodcastColumn}
          {yourPodcastColumn}
          {this.renderStatusColumn()}
        </Link>
        <Divider className={"m-ym"} />
      </Row>
    );
  }
}

export default connect(
  (state) => {
    const dealsByUUID = get(state, "deals", {});
    const user = get(state, "user.user", {});

    return {
      deals: Object.values(dealsByUUID),
      userUUID: user.uuid,
    };
  },
  {
    updateDealMeta,
  }
)(withPermissions(MyCrossPromotionsTable));
