import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { Component } from "react";
import { If } from "react-extras";
import { connect } from "react-redux";
import { injectStripe, PaymentRequestButtonElement } from "react-stripe-elements";
import { showWarning } from "../../../actions/app";
import { addCard, getMyCards } from "../../../action_managers/subscriptions";
import { RadioInput } from "../radio-input/radio-input";
import { AddCardForm } from "./add_card_form";
import "./stripe_form.scss";

export const options = {
  fonts: [
    {
      src: "url(https://pod-public-media-files.s3.us-east-2.amazonaws.com/assets/fonts/gilroy-regular.woff)",
      family: "Gilroy",
      style: "normal",
    },
  ],
};

class StripeForm extends Component {
  constructor(props) {
    super(props);
    const paymentRequest = props.stripe.paymentRequest({
      requestPayerEmail: true,
      country: "US",
      currency: "usd",
      total: {
        label: "Subscription Amount",
        amount: this.props.amount || 500,
      },
    });

    // Handles Apple / Google Pay callback.
    paymentRequest.on("token", (e) => {
      let { token, error, complete, payerEmail } = e;
      if (error) {
        this.props.showWarning(error.message);
        return;
      }

      // If the user is logged in or we're subscribing and the user just signed up, we
      // add the card to their account.
      if (this.props.user) {
        this.props.addCard(token.id).then((resp) => {
          if (resp.status !== 200) {
            return;
          }
          this.props.submit({ card: resp.json });
          complete("success");
        });
        return;
      }

      // The user isn't logged in, so we pass up the card's token and the user's email to
      // the submit handler, letting it know not to save the card.
      this.props.submit({ token, email: payerEmail, bypassCardSave: true });
      complete("success");
    });

    paymentRequest.canMakePayment().then((result) => {
      this.setState({ canMakePayment: !!result });
    });
    this.state = {
      showAddCard: this.props.newCardOnly && !this.props.token,
      canMakePayment: false,
      paymentRequest,
      selectedCard: undefined,
    };
  }

  submit = async () => {
    const { onLoadingStatus } = this.props;
    onLoadingStatus(true);
    const resp = await this.getDetailsFromStripe();
    onLoadingStatus(false);
    return resp;
  };

  getDetailsFromStripe = async () => {
    if (this.state.showAddCard) {
      let { token, error } = await this.props.stripe.createToken({ name: this.state.name });
      if (error) {
        this.props.showWarning(error.message);
        return { error };
      }

      // add the card to the user for later
      if (this.props.user) {
        let resp = await this.props.addCard(token.id);
        if (resp.status !== 200) {
          return;
        }
        return { card: resp.json };
      }

      // no sign-in. one time card
      return { token: token, email: this.state.email };
    }

    // existing card
    if (this.props.token) {
      return { token: this.props.token };
    }
    return { card: this.state.selectedCard };
  };

  componentDidMount() {
    const card = get(this.props, ["payments", "cards", this.props.defaultSelectedCardID]);
    if (card) {
      this.selectCard(card);
    } else if (this.props.token) {
      this.selectCard(this.props.token.card);
    } else if (Object.keys(this.props.payments.cards)?.length === 1) {
      this.selectCard(this.props.payments.cards[Object.keys(this.props.payments.cards)[0]]);
    }
  }

  addCardView = () => {
    return (
      <AddCardForm
        user={this.props.user}
        onChange={(e) => this.setState({ email: e.target.value })}
        onNameChange={(e) => this.setState({ name: e.target.value })}
        canMakePayment={this.state.canMakePayment}
        paymentRequest={this.state.paymentRequest}
        onClick={() => this.setState({ showAddCard: false })}
      />
    );
  };

  getListOfCards = () => {
    if (!isEmpty(get(this.props, "token.card"))) {
      return [this.props.token.card];
    }

    return Object.keys(get(this.props, "payments.cards")).map(
      (key) => this.props.payments.cards[key]
    );
  };

  isFormReady = () => {
    return this.state.showAddCard || this.state.selectedCard;
  };

  showAddCard = () => {
    this.setState({ showAddCard: true }, () => this.props.onFormStatus(this.isFormReady()));
  };

  selectCard = (card) => {
    this.setState({ selectedCard: card }, () => this.props.onFormStatus(this.isFormReady()));
  };

  renderCards = () => {
    return this.getListOfCards().map((card, i) => {
      return (
        <div className={"card-selector"} key={card.id}>
          <RadioInput
            readOnly
            id={`card-option-${i}`}
            name="card-selector"
            onClick={() => this.selectCard(card)}
            checked={get(this.state.selectedCard, "id") === card.id}
            labelContent={() => (
              <>
                <h3 className={"m-a0"}>{card.brand}</h3>
                <div className={"card-description bold"}>
                  {get(card, "name")} **** **** **** {card.last4}{" "}
                  {moment()
                    /* moment months are zero indexed */
                    .month(card.exp_month - 1)
                    .year(card.exp_year)
                    .format("MM/YY")}
                </div>
              </>
            )}
          />
        </div>
      );
    });
  };

  render() {
    return (
      <div className="stripe-form">
        <If condition={this.state.showAddCard}>{this.addCardView()}</If>
        <If condition={!this.state.showAddCard}>
          <div className="card-view">{this.renderCards()}</div>
        </If>
        <If condition={!this.state.showAddCard}>
          <>
            {this.state.canMakePayment && (
              <>
                <PaymentRequestButtonElement
                  paymentRequest={this.state.paymentRequest}
                  className="PaymentRequestButton m-bl"
                  style={{
                    paymentRequestButton: {
                      theme: "light",
                      height: "64px",
                    },
                  }}
                />
              </>
            )}
            <button className="btn btn-secondary width-100" onClick={this.showAddCard}>
              Add New Card
            </button>
          </>
        </If>
      </div>
    );
  }
}

const StripeWithRef = (props) => {
  const { forwardedRefFromModal, ...rest } = props;
  return <StripeForm {...rest} ref={forwardedRefFromModal} />;
};

const StripeInjectWrapper = injectStripe(
  connect(
    (state) => ({
      payments: state.payments,
      user: state.user.user,
    }),
    {
      showWarning,
      getMyCards,
      addCard,
    }
  )(StripeWithRef)
);

export default React.forwardRef((props, ref) => {
  return <StripeInjectWrapper {...props} forwardedRefFromModal={ref} />;
});
