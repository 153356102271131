import { withdrawError, withdrawStart, withdrawSuccess } from "../actions/transactions";
import transactionsAPI from "../api/transactions";
import ActionManager from "./base";
import DefaultActionManager, { defaultActionManagerRunner } from "./default_action_manager";

export const getTransactionForUserActionName = "GET_TRANSACTION_FOR_USER_ACTION_NAME";
export const canInstantPayoutActionName = "CAN_INSTANT_PAYOUT";

export const getTransactionsForUser = () =>
  new DefaultActionManager({
    route: "transactions",
    auth: true,
    actionName: getTransactionForUserActionName,
  }).run();

export const getRecentTransactionsForUser = () =>
  new DefaultActionManager({
    route: "transactions-recent",
    auth: true,
    actionName: getTransactionForUserActionName,
  }).run();

export class TransactionWithdrawActionManager extends ActionManager {
  defaultErrorMessage() {
    return "Uh oh, something went wrong with cashing out. Please try again later.";
  }

  execute() {
    return transactionsAPI.withdrawFunds(this.args.user, this.args.instant);
  }

  defaultPreCallActionCreator() {
    return withdrawStart;
  }

  defaultSuccessActionCreator() {
    return withdrawSuccess;
  }

  defaultErrorActionCreator() {
    return withdrawError;
  }

  responseOK(dispatch, json) {
    super.responseOK(dispatch, json);
    this.showSuccess(
      dispatch,
      "Success! You should see the money in your bank account in the next day or two."
    );
  }
}

export const canInstantPayout = () =>
  defaultActionManagerRunner({
    route: "users/instant-pay-enabled",
    auth: true,
    actionName: canInstantPayoutActionName,
  });
