import forbiddenIcon from "@iconify-icons/ps/forbidden";
import { Icon } from "@iconify/react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { tierDowngradeCopy } from "../../../constants/tier_copy";
import { useSelectorTS } from "../../../hooks/redux-ts";
import UpdateTierPageWrapper from "./update-tier-page-wrapper";

export const UpgradeTierPage = () => {
  const tier = useSelectorTS((state) => state.user?.tier?.level);
  return (
    <UpdateTierPageWrapper
      ctaText={(levelDisplay) => `Downgrade to ${levelDisplay} Plan`}
      type={"downgrade"}
      pageDisplay={({ destinationLevel }) => {
        const copy = tierDowngradeCopy[`${tier}-${destinationLevel}`];
        const cols = copy?.reduce(
          (agg, next, i) => {
            agg[i % 2].push(next);
            return agg;
          },
          [[] as string[], [] as string[]]
        );
        return (
          <>
            <h3>Features you'll lose when downgrading</h3>
            <div className={"p-am "} style={{ border: "1px solid #e5e5e5" }}>
              <h5>You will lose:</h5>
              <Row>
                {cols?.map((col, i) => {
                  return (
                    <Col xs={6} key={i}>
                      {col?.map((feat) => (
                        <div
                          className={"flex-row-container"}
                          style={{ fontSize: 14, lineHeight: "22px" }}
                          key={feat}>
                          <div>
                            <Icon
                              icon={forbiddenIcon}
                              style={{ color: "#A94442" }}
                              className={"m-rxxs m-txxxs"}
                            />
                          </div>
                          <div style={{ color: "#979797" }}>{feat}</div>
                        </div>
                      ))}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </>
        );
      }}
    />
  );
};

export default UpgradeTierPage;
