import get from "lodash/get";
import { statsOptionsActionName } from "../../actions/stats_options";
import { httpReducer } from "../../lib/create-action";
import { StatsOptionsReduxState } from "./types";

const initialState: StatsOptionsReduxState = {
  isLoading: false,
  os: [],
  device: [],
  app: [],
};

export default httpReducer(statsOptionsActionName, initialState, {
  success: (state, action) => {
    return { ...state, ...get(action, "payload.resp", {}) };
  },
});
