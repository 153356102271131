import QuestionIcon from "@iconify/icons-mdi/question-mark-circle";
import { Select, Switch, Table } from "antd";
import capitalize from "lodash/capitalize";
import isUndefined from "lodash/isUndefined";
import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";
import moment from "moment-timezone";
import numeral from "numeral";
import Papa from "papaparse";
import React, { FunctionComponent, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import BlockFeature from "src/components/lib/block_feature";
import InfoTooltip from "src/components/lib/info";
import { shouldShowAnalyticsUpgrade } from "src/lib/feature_flag";
import { showError, showInfo } from "../../../../actions/app";
import { InsertionStatsActionManager } from "../../../../action_managers/stats";
import {
  CreatorInsertionCredit,
  PendingInvoiceTransaction,
  RecurringDonationCredit,
  SubscriptionSurchargeCredit,
  TipCredit,
} from "../../../../constants/transactions";
import { useDispatchTS, useSelectorTS } from "../../../../hooks/redux-ts";
import DonationsImgSrc from "../../../../images/Block_Donations_Revenue.png";
import HostReadImgSrc from "../../../../images/Block_HostRead_Revenue.png";
import ProgrammaticImgSrc from "../../../../images/Block_Programmatic_Revenue.png";
import SubscriptionImgSrc from "../../../../images/Block_Subscription_Revenue.png";
import TotalImgSrc from "../../../../images/Block_Total_Revenue.png";
import { goToPricingPage } from "../../../../lib/config";
import { formatMoney } from "../../../../lib/format-money";
import { isOrgOwner } from "../../../../lib/permissions";
import { IPayouts } from "../../../../reducers/manual_payouts/manual_payouts";
import { ITransaction } from "../../../../reducers/transactions";
import { downloadFile } from "../../../lib/download_file";
import {
  alignment,
  ALL_PODCASTS,
  DataIsLoadingMessage,
  graphRedColor,
  paginationConfig,
  RequestResponseDataPoint,
  RevenueGraphTypes,
  someProgrammaticEnabled,
  StatsRequestFilter,
  tooltipTitleFactory,
  useFetchAllShows,
  useSharedRevenueRequests,
  useTierAnalyticsPerm,
  yAxisLabelFormatter,
} from "../analyticsUtility";
import InfoCard from "../InfoCard";
import Area from "../RedCircle_graphs/Area";
import TextSwitch from "../TextSwitch";

// constants

const DEFAULT_PROGRAMMATIC_CUT = 5000;
const TOTAL_EARNINGS_PERCENTAGE = 10000;

// Utility functions

const aggregateMappers = (
  amount = 0,
  date: number,
  CurrentRevenueType: (typeof RevenueGraphTypes)[number],
  allMappers: Partial<Record<(typeof RevenueGraphTypes)[number], Record<number, number>>> = {}
) => {
  // aggregate the current map of all chronologically increasing dates with their current aggregated amount

  for (const [key, RevenueTypeMapper] of Object.entries(allMappers)) {
    if (RevenueTypeMapper)
      if (key === CurrentRevenueType) {
        // aggregate the current map of all chronologically increasing dates with their current aggregated amount

        RevenueTypeMapper[date] =
          typeof RevenueTypeMapper[date] === "number" ? RevenueTypeMapper[date] + amount : amount;
      } else {
        // Iterate through the other mappers in increasing chronological date values and keep their current values so their graphs show
        // nothing changed in those dates overtime

        RevenueTypeMapper[date] =
          typeof RevenueTypeMapper[date] === "number" ? RevenueTypeMapper[date] : 0;
      }
  }
};

const transformDataToCumulative = <T,>(data: any) => {
  const counterMap: Record<string, number> = {};

  const result = data.map((item: any) => {
    const { showTitle, type, count } = item;
    const key = `${showTitle}-${type}`;

    counterMap[key] = typeof counterMap[key] === "number" ? counterMap[key] + count : count;
    return { ...item, count: counterMap[key] };
  });

  return result as T;
};

const transformMapToDataPoint = (
  dataMap: Record<string, number>,
  type: (typeof RevenueGraphTypes)[number]
) => {
  const result: DataPoint[] = Object.keys(dataMap)
    .map((date) => {
      return {
        date: parseInt(date),
        count: parseFloat((dataMap[parseInt(date)] / 100000).toFixed(2)),
        type,
      };
    })
    .sort((a, b) => a.date - b.date);
  return result;
};

const getTotalFromGraphData = (data: DataPoint[]) => {
  return data?.reduce((accu, curr) => {
    return (accu += curr.count);
  }, 0);
};

const getTitleFromData = (
  totalRevenue: number,
  graphType: (typeof RevenueGraphTypes)[number],
  timeRange: (typeof TimeRangeTypes)[number],
  showDailyEstimate: boolean,
  loading: boolean
) => {
  if (totalRevenue === 0 || loading) {
    return "";
  }

  const formattedRevenue = numeral(totalRevenue).format(`$0,0[.]00`);
  const formattedType = graphType === RevenueGraphTypes[0] ? graphType.split(" ")[0] : graphType;

  const formattedTimeRange = timeRange === TimeRangeTypes[0] ? "this year" : "";

  if (showDailyEstimate) {
    return `Estimated earnings of ${formattedRevenue} in RAP Programmatic since last payout`;
  }

  return `You’ve earned ${formattedRevenue} in ${formattedType} Earnings ${formattedTimeRange}`;
};

const transformDailyEstData: (
  data: RequestResponseDataPoint["json"],
  isCumulative: boolean,
  manualPayoutsArray: IPayouts[],
  manualPayoutsLoading: boolean,
  selectedShow: string,
  programmaticCut: number
) => [DataPoint[], number, TableDataPoint[]] = (
  data: RequestResponseDataPoint["json"],
  isCumulative: boolean,
  manualPayoutsArray: IPayouts[],
  manualPayoutsLoading: boolean,
  selectedShow,
  programmaticCut = DEFAULT_PROGRAMMATIC_CUT
) => {
  if (isUndefined(data)) {
    return [[], 0, []];
  }

  let result: DataPoint[] = [];
  const firstFound = false;

  data?.forEach(({ date, count }) => {
    if (count === 0 && !firstFound) {
      return;
    }

    result.push({
      date: date * 1000,
      count: parseFloat(
        (
          (count / 100000) *
          ((TOTAL_EARNINGS_PERCENTAGE - programmaticCut) / TOTAL_EARNINGS_PERCENTAGE)
        ).toFixed(2)
      ),
      type: RevenueGraphTypes[2],
    });
  });

  if (manualPayoutsLoading === false && manualPayoutsArray?.length > 0) {
    const dates = manualPayoutsArray
      .filter((payout) => payout.type === "programmatic")
      ?.map((item) => item.periodEndsAt);

    const lastPayoutDate = Math.max(...dates) * 1000;
    result = result.filter((item) => item.date > lastPayoutDate);
  }

  const total = getTotalFromGraphData(result);

  // Aggregate data when cumulative
  if (isCumulative) {
    result = transformDataToCumulative(result);
  }

  let tableData: TableDataPoint[] = result?.map((item) => {
    return {
      ...item,
      showTitle: selectedShow,
      key: `${item?.date}`,
    };
  });

  tableData = orderBy(tableData, ["date"], ["desc"]);

  return [result, total, tableData];
};

// Graph config tooltip formatter
const customRevenueTooltipFactory = (showDailyEstimate: boolean) => {
  return (title: any, data: any) => {
    const date = moment(data?.[0]?.data?.date);
    const tooltipTitle = date.isValid() ? date.format("MMM YYYY") : title;
    const titleUsed = showDailyEstimate ? title : tooltipTitle;
    return `
      <div class="analyticsPage-GraphTooltip">
        <span class="analyticsPage-GraphTooltip--title">${titleUsed}</span>
          ${data
            ?.map((item: any) => {
              const value = showDailyEstimate
                ? numeral(item?.data?.count).format("$0,0[.]00")
                : item?.value;

              return `
                <div class="analyticsPage-GraphTooltip--item"> 
                  <span style="background-color:${item?.color}"> </span>  
                  <span class="text">${item?.name}</span>
                  <span>${value}</span>
                </div>`;
            })
            .join("")}
      </div>`;
  };
};

// types
const TimeRangeTypes = ["Year to Date", "All Time"] as const;
const RevenueDropdownValues = ["Actual Earnings", "Daily Estimates"] as const;
const timeRangeOptions = TimeRangeTypes.map((option) => {
  return {
    value: option,
    key: option,
  };
});

const graphTypeTitle = {
  [RevenueGraphTypes[0]]: "TotalRevenue",
  [RevenueGraphTypes[1]]: "RAPHostReadRevenue",
  [RevenueGraphTypes[2]]: "RAPProgrammaticRevenue",
  [RevenueGraphTypes[3]]: "ExclusiveSubscriptionsRevenue",
  [RevenueGraphTypes[4]]: "DonationsRevenue",
  [RevenueGraphTypes[5]]: "Other",
};

const tabOptions = RevenueGraphTypes.map((tab) => {
  return {
    value: tab,
    key: tab,
  };
});

const ProgramamticRevenueOptions = RevenueDropdownValues.map((option) => {
  return {
    value: option,
    key: option,
  };
});

const graphColorMap: Record<(typeof RevenueGraphTypes)[number], string> = {
  [RevenueGraphTypes[0]]: "",
  [RevenueGraphTypes[1]]: graphRedColor,
  [RevenueGraphTypes[2]]: "rgba(255,156,160,.7)",
  [RevenueGraphTypes[3]]: "rgba(255,156,160,.4)",
  [RevenueGraphTypes[4]]: "rgba(255,156,160,.1)",
  [RevenueGraphTypes[5]]: graphRedColor,
};

// Graph Configs
const defaultPointConfig = {
  size: 4,
  shape: "circle",
  style: (datum: any) => {
    return {
      fill: "white",
      stroke: graphColorMap[datum?.type as (typeof RevenueGraphTypes)[number]],
      lineWidth: 0.8,
    };
  },
};

const defaultLineConfig = (graphType: (typeof RevenueGraphTypes)[number]) => {
  return {
    style: (datum: any) => {
      return {
        stroke:
          graphType === RevenueGraphTypes[0]
            ? graphColorMap[datum?.type as (typeof RevenueGraphTypes)[number]]
            : graphRedColor,
      };
    },
  };
};

const defaultAreaStyle = (graphType: (typeof RevenueGraphTypes)[number]) => {
  return (datum: any) => {
    return {
      fill:
        graphType === RevenueGraphTypes[0]
          ? graphColorMap[datum?.type as (typeof RevenueGraphTypes)[number]]
          : graphRedColor,
    };
  };
};

const defaultColorConfig = ({ type }: any) => {
  return graphColorMap[type as (typeof RevenueGraphTypes)[number]];
};

const formatter = (text: any) => {
  return "$" + yAxisLabelFormatter(text);
};

// Table Columns
const dailyEstColumns = [
  {
    title: "Earnings Type",
    dataIndex: "type",
    className: "analyticsPage-table-cell",
    align: alignment[0],
    responsive: ["md", "lg", "xl", "xxl"],
  },
  {
    title: "Date",
    dataIndex: "date",
    className: "analyticsPage-table-cell",
    align: alignment[0],
    render: (date: number) => moment(date).format("MMM DD YYYY"),
    sorter: (a: TableDataPoint, b: TableDataPoint) => a.date - b.date,
  },
  {
    title: "Est. Earnings",
    dataIndex: "count",
    className: "analyticsPage-table-cell",
    align: alignment[2],
    render: (count: number) => formatMoney(count * 100),
  },
];

const columns = [
  {
    title: "Podcast",
    dataIndex: "showTitle",
    className: "analyticsPage-table-cell",
    align: alignment[0],
  },
  {
    title: "Earnings Type",
    dataIndex: "type",
    className: "analyticsPage-table-cell",
    align: alignment[0],
    responsive: ["md", "lg", "xl", "xxl"],
  },
  {
    title: "Date",
    dataIndex: "date",
    className: "analyticsPage-table-cell",
    align: alignment[0],
    render: (date: number) => moment(date).format("MMM YYYY"),
    sorter: (a: TableDataPoint, b: TableDataPoint) => a.date - b.date,
  },
  {
    title: "Revenue",
    dataIndex: "count",
    className: "analyticsPage-table-cell",
    align: alignment[2],
    render: (revenue: number) => formatMoney(revenue / 1000),
  },
];

const totalColumns = [
  {
    title: "Podcast",
    dataIndex: "showTitle",
    className: "analyticsPage-table-cell",
    align: alignment[0],
  },
  {
    title: "Date",
    dataIndex: "date",
    className: "analyticsPage-table-cell",
    align: alignment[0],
    render: (date: number) => moment(date).format("MMM YYYY"),
    sorter: (a: TableDataPoint, b: TableDataPoint) => a.date - b.date,
  },
  {
    title: "Earnings",
    dataIndex: "count",
    className: "analyticsPage-table-cell",
    align: alignment[2],
    render: (revenue: number) => formatMoney(revenue / 1000),
  },
];

interface TableDataPoint {
  key: string;
  showTitle: string;
  type: (typeof RevenueGraphTypes)[number];
  date: number;
  count: number;
  campaignTitle?: string;
  imageURL?: string;
}

interface DataPoint {
  date: number;
  count: number;
  type: (typeof RevenueGraphTypes)[number];
}

interface IRevenueSection {
  selectedShow: string;
}

const RevenueSection: FunctionComponent<IRevenueSection> = ({ selectedShow }) => {
  const dispatch = useDispatchTS();
  const shows = useSelectorTS((state) => state?.shows?.shows);
  const user = useSelectorTS((state) => state?.user?.user);
  const permissionsState = useSelectorTS((state) => state?.permissions);
  const tierPermission = useTierAnalyticsPerm();
  const { isLoading: manualPayoutsLoading, manualPayouts: manualPayoutsArray } = useSelectorTS(
    (state) => state?.manualPayouts
  );
  const { isLoading: allShowsIsLoading, allShows } = useFetchAllShows();

  const userTransactions = useSelectorTS((state) => state?.transactions?.userTransactions);
  const manualPayouts = keyBy(manualPayoutsArray, "uuid");

  const enableOtherRevenue =
    shouldShowAnalyticsUpgrade() && userTransactions.some((txn) => txn?.invoiceUUID?.length > 0);

  const cacheID = `RevenueSection-${selectedShow}`;

  const statsCacheData: RequestResponseDataPoint["json"] = useSelectorTS(
    (state) => state?.stats?.stats?.[cacheID],
    shallowEqual
  );

  /**
   * If users selects all podcasts currently cannot aggregate correctly so just default to 50%, if user selects a show
   * use that shows programmatic cut.
   */
  let programmaticCut = DEFAULT_PROGRAMMATIC_CUT;
  if (
    selectedShow !== ALL_PODCASTS &&
    shows[selectedShow] &&
    typeof shows[selectedShow].programmaticCutBasisPoints === "number" &&
    shows[selectedShow].programmaticCutBasisPoints != 0
    // zero is considered default
  ) {
    programmaticCut = shows[selectedShow].programmaticCutBasisPoints;
  }

  // Handles Fetching Data
  const [userTransactionsIsLoaded, manualPayoutsIsLoaded, initialDataIsLoaded] =
    useSharedRevenueRequests();

  const defaultSelectedTab =
    !tierPermission.Extra.showExtraWidgets && someProgrammaticEnabled(shows)
      ? RevenueGraphTypes[2]
      : RevenueGraphTypes[0];

  const [graphType, setGraphType] =
    useState<(typeof RevenueGraphTypes)[number]>(defaultSelectedTab);
  const [timeRange, setTimeRange] = useState<(typeof TimeRangeTypes)[number]>(TimeRangeTypes[0]);
  const [isCumulative, setIsCumulative] = useState<boolean>(true);
  const [dropdownVal, setDropdownVal] = useState<(typeof RevenueDropdownValues)[number]>(
    RevenueDropdownValues[0]
  );

  const [dailyEstLoading, setDailyEstLoading] = useState<boolean>(true);

  const isOwner = isOrgOwner(user, permissionsState);

  const transformTransactionData: (
    data: ITransaction[],
    graphType: (typeof RevenueGraphTypes)[number],
    timeRange: (typeof TimeRangeTypes)[number],
    isCumulative: boolean
  ) => [DataPoint[], number, TableDataPoint[]] = (data) => {
    if (!userTransactionsIsLoaded || !manualPayoutsIsLoaded || allShowsIsLoading) {
      return [[], 0, []];
    }
    // Aggregate revenue for each type in monthly intervals
    const allAggregateRevenueMaps = {
      [RevenueGraphTypes[1]]: {},
      [RevenueGraphTypes[2]]: {},
      [RevenueGraphTypes[3]]: {},
      [RevenueGraphTypes[4]]: {},
      [RevenueGraphTypes[5]]: {},
    };

    // Table data
    const tableData: TableDataPoint[] = [];

    data.forEach((transaction) => {
      if (selectedShow === ALL_PODCASTS || selectedShow === transaction.showUUID) {
        // Aggregate all dates to the first of the month
        const currentMoment = moment(transaction.createdAt * 1000).tz(moment.tz.guess());
        currentMoment.set({
          date: 1,
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const value = currentMoment.valueOf();

        switch (transaction.type) {
          case SubscriptionSurchargeCredit:
            // Aggregating revenue by month for each type

            aggregateMappers(
              transaction?.amount,
              value,
              RevenueGraphTypes[3],
              allAggregateRevenueMaps
            );

            // Adding table data point
            tableData.push({
              key: transaction.uuid,
              date: value,
              showTitle: allShows[transaction?.showUUID]?.title as string,
              count: transaction.amount,
              type: RevenueGraphTypes[3],
            });

            break;
          case TipCredit:
          case RecurringDonationCredit:
            // Aggregating revenue by month for each type
            aggregateMappers(
              transaction?.amount,
              value,
              RevenueGraphTypes[4],
              allAggregateRevenueMaps
            );

            // Adding table data point
            tableData.push({
              key: transaction.uuid,
              date: value,
              showTitle: allShows[transaction?.showUUID]?.title as string,
              count: transaction.amount,
              type: RevenueGraphTypes[4],
            });

            break;
          case CreatorInsertionCredit: {
            const { campaignUUID, manualPayoutUUID } = transaction;

            if (campaignUUID) {
              // Aggregating revenue by month for each type
              aggregateMappers(
                transaction?.amount,
                value,
                RevenueGraphTypes[1],
                allAggregateRevenueMaps
              );

              // Adding table data point
              tableData.push({
                key: transaction.uuid,
                date: value,
                showTitle: allShows[transaction?.showUUID]?.title as string,
                count: transaction.amount,
                type: RevenueGraphTypes[1],
              });
            } else {
              if (manualPayouts?.[manualPayoutUUID]?.type === "programmatic") {
                // Aggregating revenue by month for each type
                aggregateMappers(
                  transaction?.amount,
                  value,
                  RevenueGraphTypes[2],
                  allAggregateRevenueMaps
                );

                // Adding table data point
                tableData.push({
                  key: transaction.uuid,
                  date: value,
                  showTitle: allShows[transaction?.showUUID]?.title as string,
                  count: transaction.amount,
                  type: RevenueGraphTypes[2],
                });
              }
              // Offline campaigns
              if (manualPayouts?.[manualPayoutUUID]?.type === "offline-campaign") {
                aggregateMappers(
                  transaction?.amount,
                  value,
                  RevenueGraphTypes[5],
                  allAggregateRevenueMaps
                );

                // Adding table data point
                tableData.push({
                  key: transaction.uuid,
                  date: value,
                  showTitle: allShows[transaction?.showUUID]?.title as string,
                  count: transaction.amount,
                  type: RevenueGraphTypes[5],
                });
                // Another type of transaction, not needed
              }
            }

            break;
          }

          case PendingInvoiceTransaction: {
            const { campaignUUID } = transaction;

            if (campaignUUID) {
              // Aggregating revenue by month for each type
              aggregateMappers(
                transaction?.amount,
                value,
                RevenueGraphTypes[1],
                allAggregateRevenueMaps
              );

              // Adding table data point
              tableData.push({
                key: transaction.uuid,
                date: value,
                showTitle: allShows[transaction?.showUUID]?.title as string,
                count: transaction.amount,
                type: RevenueGraphTypes[1],
              });
            } else {
              // off line campaigns that are pending available
              aggregateMappers(
                transaction?.amount,
                value,
                RevenueGraphTypes[5],
                allAggregateRevenueMaps
              );

              // Adding table data point
              tableData.push({
                key: transaction.uuid,
                date: value,
                showTitle: allShows[transaction?.showUUID]?.title as string,
                count: transaction.amount,
                type: RevenueGraphTypes[5],
              });
            }

            break;
          }

          // Don't need these types
          case "SubscriptionPayout":
          case "TipPayout":
          case "RecurringDonationPayout":
          case "SubscriptionDebit":
          case "SubscriptionCollection":
            break;
          default:
        }
      }
    });

    // Aggregate Table data by month, keep row unique by showtitle, revenue type, and month date
    const tableDataMap: Record<string, number> = {};
    // Aggregate Table data by month, keep row unique by showtitle and month date
    const totalTableDataMap: Record<string, number> = {};

    tableData.forEach((item) => {
      const { showTitle, date, type, count } = item;

      const id = `${showTitle}_${type}_${date}`;
      tableDataMap[id] = typeof tableDataMap[id] === "number" ? tableDataMap[id] + count : count;

      const totalId = `${showTitle}_${date}`;
      totalTableDataMap[totalId] =
        typeof totalTableDataMap[totalId] === "number" ? totalTableDataMap[totalId] + count : count;
    });

    const tableDataByMonth: TableDataPoint[] = Object.keys(tableDataMap).map((key) => {
      const [showTitle, type, date] = key?.split("_");
      return {
        key,
        showTitle,
        type: type as (typeof RevenueGraphTypes)[number],
        date: parseInt(date),
        count: tableDataMap[key],
      };
    });

    const totalTableData: TableDataPoint[] = Object.keys(totalTableDataMap).map((key) => {
      const [showTitle, date] = key?.split("_");
      return {
        key,
        showTitle,
        type: RevenueGraphTypes[0],
        date: parseInt(date),
        count: totalTableDataMap[key],
      };
    });

    const HostRead = transformMapToDataPoint(
      allAggregateRevenueMaps[RevenueGraphTypes[1]],
      RevenueGraphTypes[1]
    );
    const Programmatic = transformMapToDataPoint(
      allAggregateRevenueMaps[RevenueGraphTypes[2]],
      RevenueGraphTypes[2]
    );
    const ExclusiveSubscription = transformMapToDataPoint(
      allAggregateRevenueMaps[RevenueGraphTypes[3]],
      RevenueGraphTypes[3]
    );
    const Donations = transformMapToDataPoint(
      allAggregateRevenueMaps[RevenueGraphTypes[4]],
      RevenueGraphTypes[4]
    );

    const Other = transformMapToDataPoint(
      allAggregateRevenueMaps[RevenueGraphTypes[5]],
      RevenueGraphTypes[5]
    );

    // Graph Data Transformations
    let graphData = enableOtherRevenue
      ? HostRead.concat(Programmatic, ExclusiveSubscription, Donations, Other)
      : HostRead.concat(Programmatic, ExclusiveSubscription, Donations);

    if (graphType !== RevenueGraphTypes[0]) {
      graphData = graphData.filter((item) => item.type === graphType);
    }

    if (timeRange === TimeRangeTypes[0]) {
      const year = moment().year();
      graphData = graphData.filter((item) => moment(item.date).year() === year);
    }
    const totalGraphDataValue = getTotalFromGraphData(graphData);
    if (isCumulative) {
      graphData = transformDataToCumulative(graphData);
    }

    // Table Data transformation
    let currentTableData =
      graphType === RevenueGraphTypes[0]
        ? totalTableData
        : tableDataByMonth.filter((item) => item.type === graphType);

    if (timeRange === TimeRangeTypes[0]) {
      const year = moment().year();
      currentTableData = currentTableData.filter((item) => moment(item.date).year() === year);
    }

    if (isCumulative) {
      currentTableData = transformDataToCumulative(currentTableData);
    }

    currentTableData = orderBy(currentTableData, ["date"], ["desc"]);

    return [graphData, totalGraphDataValue, currentTableData];
  };

  const showDailyEstimates =
    graphType === RevenueGraphTypes[2] && dropdownVal === RevenueDropdownValues[1];

  // Data Transformations to correct graph format
  const [totalGraphData, totalGraphDataValue, tableData] = transformTransactionData(
    userTransactions,
    graphType,
    timeRange,
    isCumulative
  );

  const [dailyEstData, totalEstDaily, dailyEstTable] = transformDailyEstData(
    statsCacheData,
    isCumulative,
    manualPayoutsArray,
    manualPayoutsLoading,
    selectedShow,
    programmaticCut
  );

  const dataProvidedToGraph = showDailyEstimates ? dailyEstData : totalGraphData;

  const dataProvidedToTable = showDailyEstimates ? dailyEstTable : tableData;

  // Manipulate title
  const totalRevenueFromGraph = showDailyEstimates ? totalEstDaily : totalGraphDataValue;

  const dailyEstGraphIsLoading = dailyEstLoading || isUndefined(statsCacheData);

  const currentLoading = showDailyEstimates ? dailyEstGraphIsLoading : !initialDataIsLoaded;

  const title = getTitleFromData(
    totalRevenueFromGraph,
    graphType,
    timeRange,
    showDailyEstimates,
    currentLoading
  );

  const currentColumns = showDailyEstimates
    ? dailyEstColumns
    : graphType === RevenueGraphTypes[0]
      ? totalColumns
      : columns;

  const timeZone = moment.tz.guess();

  const renderDropdown = () => {
    if (graphType !== RevenueGraphTypes[2] || (!isOwner && selectedShow === ALL_PODCASTS)) {
      return null;
    }

    return (
      <div className="flex-row-container align-center m-bxxs">
        <span className="m-la d-none d-block-md" />
        <Select
          size="small"
          className="RC-Antd-Override-Dropdown"
          value={dropdownVal}
          onSelect={handleIntervalChange}
          style={{ width: "150px" }}>
          {ProgramamticRevenueOptions.map(({ value, key }) => {
            return (
              <Select.Option key={key} value={value}>
                {value}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const renderTooltip = (loading: boolean) => {
    if (graphType !== RevenueGraphTypes[2] || dropdownVal !== RevenueDropdownValues[1] || loading) {
      return null;
    }

    return (
      <>
        <InfoTooltip
          direction="top"
          helpText="Real-time estimate (final amount may change). Confirmed monthly amounts will appear in Actual Earnings once you’ve been paid out."
          baseIcon={QuestionIcon}
          height={20}
          style={{
            color: "#C6C6C6",
            bottom: ".125em",
            position: "relative",
            cursor: "pointer",
          }}
        />
      </>
    );
  };

  useEffect(() => {
    if (dropdownVal === RevenueDropdownValues[1]) {
      const filters: StatsRequestFilter = {
        sumTerm: "insertion.programmaticData.price",
        type: "insertions",
        isProgrammatic: true,
        timeRange: "allTime",
        interval: "1d",
        timezone: timeZone,
      };

      if (typeof selectedShow === "string" && selectedShow !== ALL_PODCASTS) {
        filters.showUUID = selectedShow;
      }

      dispatch(
        new InsertionStatsActionManager({
          filters,
          user,
          requestID: cacheID,
        }).run()
      )
        .then((resp: RequestResponseDataPoint) => {
          if (resp?.status !== 200) {
            dispatch(showInfo("Please wait a few seconds and try the data request again", 5000));
          }
          setDailyEstLoading(false);
        })
        .catch(() => {
          dispatch(showError("An error has occured please reload the page and try again", 5000));
          setDailyEstLoading(false);
        });
    }
  }, [selectedShow, dropdownVal]);

  // Handlers
  const handleCummulativeSwitch = (checked: boolean) => setIsCumulative(checked);
  const handleTimeRangeSwitch = (option: any) => setTimeRange(option?.value);
  const handleGraphSwitch = (option: any) => setGraphType(option?.value);

  const handleIntervalChange = (newDropdownValue: (typeof RevenueDropdownValues)[number]) =>
    setDropdownVal(newDropdownValue);
  const handleReportDownload = () => {
    if (initialDataIsLoaded) {
      const csv = Papa.unparse(
        tableData.map((point) => {
          return {
            PodcastName: point.showTitle,
            "Earnings Type": point.type,
            Date: moment(point.date).utc().format("MMMM YYYY"),
            [`Earnings_${isCumulative ? "Cumulative" : "OverTime"}`]: formatMoney(
              point.count / 1000
            ),
          };
        })
      );

      const timeRangeTitle = timeRange
        .split(" ")
        .map((w) => capitalize(w))
        .join("");

      const aggregateTitle = isCumulative ? "Cumulative" : "OverTime";

      downloadFile(
        `EarningsReport_${graphTypeTitle[graphType]}_${aggregateTitle}_${timeRangeTitle}.csv`,
        csv
      );
    } else {
      dispatch(showInfo(DataIsLoadingMessage, 3000));
    }
  };

  // Permissions

  const permissioneByType = {
    [RevenueGraphTypes[0]]: tierPermission.Revenue.TotalRevenue,
    [RevenueGraphTypes[1]]: tierPermission.Revenue.HostRead,
    [RevenueGraphTypes[2]]: tierPermission.Revenue.Programmatic,
    [RevenueGraphTypes[3]]: tierPermission.Revenue.Subscriptions,
    [RevenueGraphTypes[4]]: tierPermission.Revenue.Donations,
    [RevenueGraphTypes[5]]: tierPermission.Revenue.Other,
  };

  const imgMap = {
    [RevenueGraphTypes[0]]: TotalImgSrc,
    [RevenueGraphTypes[1]]: HostReadImgSrc,
    [RevenueGraphTypes[2]]: ProgrammaticImgSrc,
    [RevenueGraphTypes[3]]: SubscriptionImgSrc,
    [RevenueGraphTypes[4]]: DonationsImgSrc,
    [RevenueGraphTypes[5]]: HostReadImgSrc, // re-using hostread blurred block image due to similar color styles
  };

  const downloadSettings =
    tierPermission.Revenue.TotalRevenue ||
    (!tierPermission.Extra.showExtraWidgets && someProgrammaticEnabled(shows))
      ? {
          allow: tierPermission.General.ExportDataWidgetLevel,
          text: "Earnings Report",
          hanlder: handleReportDownload,
        }
      : undefined;

  let filteredtabOptions =
    !tierPermission.Extra.showExtraWidgets && someProgrammaticEnabled(shows)
      ? tabOptions.map((item) =>
          item.value === RevenueGraphTypes[2] ? item : { ...item, hidden: true }
        )
      : tabOptions;

  // Enabling/disabling "Other" revenue section
  filteredtabOptions = filteredtabOptions.map((option) => {
    if (option.key === RevenueGraphTypes[5]) {
      return { ...option, hidden: !enableOtherRevenue };
    }
    return { ...option };
  });

  return (
    <InfoCard
      title={{
        text: "Earnings",
        tooltipText: "Net revenue made through RedCircle's products and services.",
        eyebrow: (
          <div>
            <TextSwitch
              onSelect={handleTimeRangeSwitch}
              options={timeRangeOptions}
              minTabWidth="100px"
            />
          </div>
        ),
        subHeader: (
          <div className="m-ts">
            <TextSwitch
              onSelect={handleGraphSwitch}
              type="tab"
              value={graphType}
              options={filteredtabOptions}
            />
            <div className="d-none d-block-md m-bs" />
          </div>
        ),
      }}
      download={downloadSettings}>
      <BlockFeature
        block={!permissioneByType[graphType]}
        CTA={{
          text: (
            <span className="h3 default-font m-b0 p-hxs text-center">
              <strong>{`${graphType}`}</strong> available starting from Pro Plan.
            </span>
          ),
          btn: {
            text: "Upgrade Your Plan",
            handler: () => goToPricingPage(),
          },
        }}
        blockByImg={{
          src: imgMap[graphType],
          minHeight: "900px",
        }}>
        <div style={{ width: "100%", height: "100%" }}>
          {renderDropdown()}
          <h3 className="analyticsPage-title">
            {title} {renderTooltip(currentLoading)}
          </h3>

          <div className="flex-row-container align-center m-bxxs">
            <h4 className="h4 m-a0 bold capitalize">Revenue</h4>
          </div>
          <div style={{ height: "260px", width: "100%" }}>
            <Area<DataPoint>
              data={dataProvidedToGraph}
              loading={currentLoading}
              xField="date"
              yField="count"
              seriesField="type"
              legend={false as const}
              line={defaultLineConfig(graphType)}
              point={defaultPointConfig}
              isStack={true}
              areaStyle={defaultAreaStyle(graphType)}
              color={defaultColorConfig}
              tooltip={{
                customContent: customRevenueTooltipFactory(showDailyEstimates),
                title: tooltipTitleFactory(showDailyEstimates ? "1d" : "1M"),
                showCrosshairs: true,
              }}
              meta={{
                date: {
                  type: "timeCat",
                  mask: showDailyEstimates ? "MMM DD" : "MMM YYYY",
                  range: [0, 1],
                },
                count: {
                  formatter,
                },
              }}
            />
          </div>

          <div className="flex-row-container justify-center align-center m-vxs">
            <span className="area-switch-text h3 m-a0">Over Time</span>
            <Switch
              className="RC-Antd-Override-Switch m-hs m-yauto"
              checked={isCumulative}
              onClick={handleCummulativeSwitch}
            />
            <span className="area-switch-text h3 m-a0">Cumulative</span>
          </div>
          <Table
            className="analyticsPage-table"
            loading={currentLoading}
            dataSource={dataProvidedToTable}
            columns={currentColumns}
            scroll={{ x: true }}
            pagination={paginationConfig}
          />
        </div>
      </BlockFeature>
    </InfoCard>
  );
};

// Optimizing re-rendering from parent.
const areEqual = (prevProps: IRevenueSection, nextProps: IRevenueSection) => {
  const selectShowIsSame = prevProps?.selectedShow === nextProps?.selectedShow;

  return selectShowIsSame;
};

const MemoizedRevenueSection = React.memo(RevenueSection, areEqual);

export default MemoizedRevenueSection;
