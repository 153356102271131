import { Col, Row } from "antd";
import filter from "lodash/filter";
import getFP from "lodash/fp/get";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import { Component } from "react";
import { If } from "react-extras";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import BlockFeature from "src/components/lib/block_feature";
import RCButton from "src/components/lib/button/button";
import EmptyStateBlock from "src/components/lib/empty_state_block/EmptyStateBlock";
import Loading from "src/components/lib/loading/loading";
import Page from "src/components/lib/page/page";
import { permissionTypes } from "../../../constants/permission_roles";
import { HAS_SEEN_PROMOTIONS_INTRO } from "../../../constants/user_attributes";
import { goToPricingPage } from "../../../lib/config";
import { shouldShowTierPerm } from "../../../lib/feature_flag";
import { getBiggestShow, isShowEligibleForCrossPromo } from "../../../lib/show";
import { minimumAllowedTier } from "../../../lib/tier-utils";
import { tierLevel } from "../../../reducers/types";
import Divider from "../../lib/divider";
import BrowseRow from "../browse_promotion/browse_row";
import MyCrossPromotionsTable from "./my_cross_promotions_table";

import "./promotions-dashboard.scss";
import "src/styles/icons.scss";

class PromotionsDashboard extends Component {
  state = {
    // we present recommendations based off of the showUUID in the state
    showUUID: null,
    showsFetched: false,
    showsToBrowse: [],
  };

  componentDidMount() {
    this.props.fetchShowsForUser(this.props.user).then(() => {
      this.fetchRelatedShows();
      this.setState({ showsFetched: true });
    });
    this.props.getDealsForUser(this.props.user);
  }

  fetchRelatedShows = () => {
    const shows = this.getShowArray();
    const eligibleShows = filter(shows, isShowEligibleForCrossPromo);
    const biggestShow = getBiggestShow(eligibleShows);
    if (!isEmpty(biggestShow.uuid)) {
      this.props.searchSimilarShows(biggestShow.uuid);
      this.setState({ showUUID: biggestShow.uuid });
    }
  };

  canEditCrossPromo = () => {
    return Boolean(this.props.hasAtLeastOnePermission(permissionTypes.editCrossPromo));
  };
  getShowArray = () => {
    return Object.values(get(this.props, "shows", {})).filter(getFP("isVisible"));
  };

  hasSeenIntro = () => {
    return get(this.props.user, ["userAttributes", HAS_SEEN_PROMOTIONS_INTRO]) === "true";
  };

  hasAnOptedInShow = () => {
    const showMap = get(this.props, "shows", {});
    return some(Object.keys(showMap), (key) => get(showMap[key], "canShareAudienceSize"));
  };

  getShowsToBrowse = () => {
    if (this.state.showsToBrowse.length) {
      return this.state.showsToBrowse;
    }

    if (this.props.categoryShows.isLoading || this.props.categoryDirectoryShows.isLoading) {
      return undefined;
    }

    if (!isEmpty(this.state.showUUID)) {
      const shows = get(this.props.categoryShows, [this.state.showUUID], []);
      const nonRemoteShows = shows.filter((show) => !show.isRemote);
      if (nonRemoteShows.length) {
        this.setState({ showsToBrowse: nonRemoteShows });
      }
      return nonRemoteShows;
    }
    return [];
  };

  renderEmptyState = () => {
    const { shows } = this.props;
    const isAllShowsRemote = Object.values(shows).every((show) => show.isRemote);

    const hasSeenIntro = this.hasSeenIntro();
    const hasOptedIn = this.hasAnOptedInShow();
    let button = (
      <RCButton type="primary" size="large" className="m-tm" onClick={this.props.showIntroModal}>
        Get Started
      </RCButton>
    );
    if (hasSeenIntro && hasOptedIn) {
      button = (
        <RCButton
          type="primary"
          size="large"
          className="m-tm"
          onClick={() => this.props.history.push("/promotions/browse")}>
          Find Podcast Partners
        </RCButton>
      );
    }

    return (
      <div className={"promotions-page__empty-state-container"}>
        <h3>You aren't running any cross promotions yet!</h3>
        {!isAllShowsRemote && !this.canEditCrossPromo() && button}
        {isAllShowsRemote && (
          <EmptyStateBlock className="m-txs">
            You must have at least one podcast hosted on RedCircle to run cross promotions.{" "}
            <span>
              <Link to="/shows">Add a podcast</Link> to get started.
            </span>
          </EmptyStateBlock>
        )}
      </div>
    );
  };

  render() {
    const { deals = {}, shows } = this.props;
    const hasOptedIn = this.hasAnOptedInShow();
    const numDeals = get(deals, "userDealUUIDs.length", 0);
    const isDealsLoading = get(deals, "isLoading", true);
    const isAllShowsRemote = Object.values(shows).every((show) => show.isRemote);

    const blockUser = shouldShowTierPerm()
      ? !minimumAllowedTier(tierLevel.growth, this?.props?.tier, true)
      : false;

    return (
      <Page className="promotions-page-content" pageTitle="Cross Promotions">
        <div className="flex-row-container align-center">
          <Page.Title>My Cross Promotions</Page.Title>{" "}
          {this.canEditCrossPromo() && (
            <RCButton
              type="icon"
              onClick={this.props.showIntroModal}
              className="m-lxxs"
              size="small">
              <AiOutlineQuestionCircle />
            </RCButton>
          )}
          {this.hasAnOptedInShow() && this.canEditCrossPromo() && !isAllShowsRemote && (
            <div className="promotions-dashboard__new-promotion">
              <Link to="/promotions/browse" className="bold">
                New Promotion
              </Link>
            </div>
          )}
        </div>

        <Page.Divider />
        <Page.Section>
          <BlockFeature
            block={blockUser}
            CTA={{
              text: (
                <span className="h3 default-font m-b0">
                  <strong>Cross Promotions</strong> available starting from Growth Plan.
                </span>
              ),
              btn: {
                text: "Upgrade Your Plan",
                handler: () => goToPricingPage(),
              },
            }}
            blockbyBlur={true}>
            <>
              <Row>
                <Col xs={24}>
                  <div className="promotions-dashboard__my-promos">
                    {isDealsLoading && <Loading />}
                    <If condition={!isDealsLoading}>
                      <If condition={numDeals > 0}>
                        <MyCrossPromotionsTable />
                      </If>
                      <If condition={!numDeals}>{this.renderEmptyState()}</If>
                    </If>
                  </div>
                </Col>
              </Row>
              <If condition={hasOptedIn || this.canEditCrossPromo()}>
                <Row>
                  <Col xs={24} style={{ marginTop: "114px" }}>
                    <div className={"flex-row-container align-center"}>
                      <h2 className={"promotions-dashboard__title m-a0"}>Browse</h2>
                      <span className="promotions-page__browse-description flex-1 m-ls">
                        <span className="hidden-xs">Find other podcasters to promote with</span>
                      </span>
                      {this.canEditCrossPromo() &&
                        !isEmpty(this.props.shows) &&
                        !isAllShowsRemote && (
                          <Link
                            onClick={() => !hasOptedIn && this.props.showOptInModal()}
                            to={hasOptedIn ? "/promotions/browse" : "#"}
                            className="bold no-style-link">
                            Browse All
                            <span className="chevron--right" />
                          </Link>
                        )}
                    </div>
                    <Divider smallMargin />
                    {isEmpty(shows) && (
                      <EmptyStateBlock>
                        You must have at least one podcast to browse cross promotion partners.
                      </EmptyStateBlock>
                    )}
                    {isAllShowsRemote && !isEmpty(shows) && (
                      <EmptyStateBlock>
                        You must have at least one podcast hosted on RedCircle to browse cross
                        promotion partners.
                      </EmptyStateBlock>
                    )}
                    {!isEmpty(shows) && !isAllShowsRemote && this.canEditCrossPromo() && (
                      <BrowseRow
                        shows={this.getShowsToBrowse()}
                        isLoading={this.props.isLoading || this.props.isLoadingBrowse}
                      />
                    )}
                  </Col>
                </Row>
              </If>
            </>
          </BlockFeature>
        </Page.Section>
      </Page>
    );
  }
}

export default PromotionsDashboard;
