import get from "lodash/get";
import { stringify } from "query-string";
import { v4 as uuid } from "uuid";
import DefaultActionManager, { defaultActionManagerRunner } from "./default_action_manager";

export const browseSearchShowActionName = "BROWSE_SEARCH_SHOW";
export const getCampaignRecommendedShowsActionName = "GET_CAMPAIGN_RECOMMENDED_SHOWS";
export const browseSearchDirectoryShowActionName = "BROWSE_SEARCH_DIRECTORY_SHOW";
export const clearShowsActionName = "BROWSE_CLEAR_SHOWS";
export const fetchCategoryActionName = "FETCH_CATEGORY";
export const fetchDirectoryCategoryActionName = "FETCH_DIRECTORY_CATEGORY";
export const searchSimilarShowsActionName = "SEARCH_SIMILAR_SHOWS_ACTION_NAME";
export const getCategoriesActionName = "GET_CATEGORIES";

export const fetchExistingCategoryShows = (
  user,
  categoryUUIDs,
  rootCategoryUUID,
  bucketedAudienceSizes,
  minAverageCPM
) => {
  let route = `shows?userUUID=${user.uuid}&categoryUUID=${categoryUUIDs.join(",")}`;
  if (bucketedAudienceSizes && bucketedAudienceSizes.length > 0) {
    route += `&bucketedAudienceSizes=${bucketedAudienceSizes.join(",")}`;
  }
  if (minAverageCPM) {
    route += `&minAverageCPM=${minAverageCPM}`;
  }

  return new DefaultActionManager({
    auth: true,
    route: route,
    actionName: fetchCategoryActionName,
    actionData: {
      rootCategoryUUID,
    },
  }).run();
};

export const fetchDirectoryCategoryShows = (categoryUUIDs, rootCategoryUUID) => {
  return new DefaultActionManager({
    route: `directory-shows?isPublic=true&categoryUUID=${categoryUUIDs.join(",")}`,
    actionName: fetchDirectoryCategoryActionName,
    auth: true,
    actionData: {
      rootCategoryUUID,
    },
  }).run();
};

export const fetchCategoryShows =
  (...args) =>
  (dispatch, getState) => {
    dispatch(fetchExistingCategoryShows(getState().user.user, ...args));
    dispatch(fetchDirectoryCategoryShows(...args));
  };

export const fetchCategoryShowsV2 =
  ({ showUUIDs = [], rootCategoryUUID }) =>
  (dispatch) => {
    showUUIDs.forEach((showUUID) =>
      dispatch(searchSimilarShows(showUUID, { categoryUUID: rootCategoryUUID }))
    );
  };

export const searchShows = ({ categoryUUIDs, bucketedAudienceSizes, ...filters }) => {
  let queryString = "";
  if (categoryUUIDs) {
    queryString = `categoryUUID=${categoryUUIDs.join(",")}&`;
  }
  if (bucketedAudienceSizes) {
    queryString =
      queryString +
      `bucketedAudienceSizes=${bucketedAudienceSizes
        .map((size) => encodeURIComponent(size))
        .join(",")}&`;
  }
  const newFilters = Object.keys(filters).reduce((acc, key) => {
    if (filters[key] !== undefined) acc[key] = filters[key];
    return acc;
  }, {});

  return new DefaultActionManager({
    auth: true,
    route: `shows?${queryString}${stringify(newFilters)}`,
    actionName: browseSearchShowActionName,
    requestID: uuid(),
  }).run();
};

export const getCampaignRecommendedShows = (filters) => {
  return new DefaultActionManager({
    auth: true,
    route: `shows?${stringify(filters)}`,
    actionName: getCampaignRecommendedShowsActionName,
    requestID: uuid(),
  }).run();
};

export const getActiveCategories = () =>
  defaultActionManagerRunner({
    route: "show-categories?adsEnabled=true",
    actionName: getCategoriesActionName,
  });

export const searchDirectoryShows = ({ categoryUUIDs, ...filters }) => {
  filters = { ...filters, isPublic: true };
  let queryString = "";
  if (categoryUUIDs) {
    queryString = `categoryUUID=${categoryUUIDs.join(",")}&`;
  }

  return new DefaultActionManager({
    route: `directory-shows?${queryString}${stringify(filters)}`,
    actionName: browseSearchDirectoryShowActionName,
    auth: true,
    requestID: uuid(),
  }).run();
};

export const searchSimilarShows = (showUUID, queryParams) => {
  let query = `shows/${showUUID}/similar?`;
  const categoryUUID = get(queryParams, "categoryUUID");
  if (categoryUUID) {
    query += `categoryUUID=${categoryUUID}`;
  }
  const searchType = get(queryParams, "searchType", "crossPromos");
  if (searchType) {
    query += `&showSearchType=${searchType}`;
  }

  return new DefaultActionManager({
    route: query,
    actionName: fetchCategoryActionName,
    actionData: {
      rootCategoryUUID: showUUID,
    },
  }).run();
};

export const clearShows = () => ({
  type: clearShowsActionName,
});
