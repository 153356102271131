import React from "react";
import { classNames } from "react-extras";
import "./redcircle_graphs.scss";

const messageTypes = ["Insufficient Data", "Browser Not Supported"] as const; // In case we need to add more message types

interface IMessage {
  show: boolean;
  type: typeof messageTypes[number];
  className?: string;
  text?: string;
}

const Message = ({ show, type, className, text = "" }: IMessage) => {
  if (!show) {
    return null;
  }

  if (text.length === 0) {
    switch (type) {
      case messageTypes[0]:
        text = "Insufficient data, please check back later or update your time range.";
        break;
      case messageTypes[1]:
        text = "Your browser does not support Mapbox GL.";
        break;
      default:
        text = "Insufficient data, please check back later or update your time range.";
    }
  }

  return (
    <div className={classNames("analytics-message", className)}>
      <h3>{text}</h3>
    </div>
  );
};

export default Message;
