import { parse } from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DefaultActionManager from "../../../action_managers/default_action_manager";

class YoutubeDummyPage extends Component {
  componentDidMount() {
    const showUUID = sessionStorage.getItem("oauthShowUUID");
    const parsedParams = parse(this.props.location.search);
    this.props.enableYouTube(showUUID, parsedParams.code).then((resp) => {
      if (resp.status === 200) {
        sessionStorage.removeItem("oauthShowUUID");
        window.close();
      }
    });
  }
  render() {
    return <div></div>;
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {
  enableYouTube: (showUUID, token) =>
    new DefaultActionManager({
      auth: true,
      route: `shows/${showUUID}/connect-youtube`,
      body: {
        AuthCode: token,
      },
      method: "POST",
      actionName: "a",
    }).run(),
})(withRouter(YoutubeDummyPage));
