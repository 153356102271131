import { Alert, Tabs } from "antd";
import moment, { Moment } from "moment-timezone";
import { useEffect } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import Loading from "src/components/lib/loading";
import Page from "src/components/lib/page";
import { permissionTypes } from "../../../constants/permission_roles";
import { useSelectorTS } from "../../../hooks/redux-ts";
import { hasAtLeastOnePermitted } from "../../../lib/permissions";
import "./analytics.scss";
import {
  DatePresets,
  someProgrammaticEnabled,
  useShowRequest,
  useTierAnalyticsPerm,
} from "./analyticsUtility";
import DownloadsTab from "./tabs/downloads";
import DynamicInsertionsTab from "./tabs/dynamicInsertions";
import RevenueTab from "./tabs/revenue";
import { shouldShowEarnings } from "src/lib/feature_flag";

const AnalyticsPage = () => {
  const history = useHistory();
  const { tab } = useParams<{ tab?: string }>();

  const { user, isLoading: userIsloading } = useSelectorTS((state) => state.user);
  const permissionsState = useSelectorTS((state) => state.permissions);
  const shows = useSelectorTS((state) => state?.shows?.shows);

  const tierPermission = useTierAnalyticsPerm();
  const [showsIsLoaded] = useShowRequest();

  const endOfToday = moment().endOf("day");
  const now = moment();
  const defaultPublishdate = now.unix();

  const earliestPublishedDate = Object.keys(shows).reduce(
    (accu: number, curr: string) =>
      shows?.[curr]?.createdAt < accu ? shows?.[curr]?.createdAt : accu,
    defaultPublishdate
  );

  const presetRanges: Record<(typeof DatePresets)[number], [Moment, Moment]> = {
    "Last 24 Hours": [now.clone().subtract({ hours: 24 }), now],
    "Last 48 Hours": [now.clone().subtract({ hours: 48 }), now],
    "Last 7 Days": [now.clone().subtract({ days: 7 }), now],
    "Last 30 Days": [now.clone().subtract({ days: 30 }), now],
    "Last 60 Days": [now.clone().subtract({ days: 60 }), now],
    "Last 90 Days": [now.clone().subtract({ days: 90 }), now],
    "Last 12 Months": [now.clone().subtract({ months: 12 }), now],
    "Year to Date": [moment().startOf("year"), now],
    "All Time": [moment(earliestPublishedDate * 1000).startOf("day"), endOfToday],
  };

  const allDefaultDatesValid = Object.entries(presetRanges).every((item) => {
    const [, value] = item;
    return value?.[0]?.isValid() && value?.[1]?.isValid();
  });

  const ready = showsIsLoaded && allDefaultDatesValid;

  // Scroll to top on page load.
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const showInsertionsTab =
    (tierPermission.Extra.showExtraWidgets || someProgrammaticEnabled(shows)) &&
    user &&
    permissionsState &&
    hasAtLeastOnePermitted(user, permissionsState, permissionTypes.viewDynamicInsertion);
  const showRevenueTab =
    user &&
    permissionsState &&
    hasAtLeastOnePermitted(user, permissionsState, permissionTypes.viewMoney) &&
    shouldShowEarnings(); // Check if user is Jocko

  if (
    user &&
    !userIsloading &&
    permissionsState &&
    !permissionsState?.isLoading &&
    !hasAtLeastOnePermitted(user, permissionsState, permissionTypes.viewStats)
  ) {
    return <Redirect to={"/shows"} />;
  }

  const handleTabChange = (key: string) => {
    history.push(`/stats/${key}`);
  };

  return (
    <Page pageTitle="Analytics">
      <Page.Header className="m-bxxs">
        <Page.Title>Analytics</Page.Title>
      </Page.Header>

      {!ready && (
        <>
          <Page.Divider />
          <Loading />
        </>
      )}
      {ready && (
        <Page.Section>
          <Tabs activeKey={tab || "downloads"} onChange={handleTabChange}>
            <Tabs.TabPane tab="Downloads" key="downloads">
              <DownloadsTab presetRanges={presetRanges} />
            </Tabs.TabPane>
            {showRevenueTab && (
              <Tabs.TabPane tab="Earnings" key="earnings">
                <RevenueTab />
              </Tabs.TabPane>
            )}
            {showInsertionsTab && (
              <Tabs.TabPane tab="Dynamic Insertions" key="insertions">
                <DynamicInsertionsTab presetRanges={presetRanges} />
              </Tabs.TabPane>
            )}
          </Tabs>
        </Page.Section>
      )}
    </Page>
  );
};

export default AnalyticsPage;
