import { isCampaign, isCampaignItem, Promotion } from "redcircle-types";
import { store } from "src";
import { ICampaignItem } from "src/reducers/campaign_items";
import { getCampaignItemTotalAmount } from "./campaigns";
import { getCampaignItemTotalCreatorAmount } from "src/api/campaigns";
import { showWarning } from "src/actions/app";

/**
 *  Checks which promotions are applicable to this campaign item
 * @param campaignItemUUID
 * @returns {Promotion[]}
 */
export const getActivePromotions = (campaignItemUUID?: string) => {
  /**
   * It is safe to grab redux state, because redux user slice is the first date fetch on app load. That determines if user is logged in
   * Any page in the app that loads will already have loaded a user object/ default state
   */
  const { user } = store.getState()?.user;

  const promotions = user?.promotions ?? [];

  return promotions.reduce((accu, curr) => {
    /**
     * Can add more logic to check which promotions apply to campaign Item
     */
    switch (curr.campaign) {
      case "openRAPLaunch":
        if (curr.campaignItemUUID === campaignItemUUID || curr.campaignItemUUID === "") {
          accu.push({ ...curr });
        }
        break;
      default:
      // nothing
    }

    return accu;
  }, [] as Promotion[]);
};

/**
 * Applies any current promotion to the campaign Item, calculates amounts and percentages
 * @param campaignItem
 * @returns
 */
export const calculateCampaignItemCuts = async ({
  campaignItem,
}: {
  campaignItem: ICampaignItem;
}) => {
  try {
    const activePromotions = getActivePromotions(campaignItem?.uuid);

    const totalCreatorAmount = campaignItem?.isV2
      ? (await getCampaignItemTotalCreatorAmount(campaignItem.uuid)).totalCreatorAmount
      : (campaignItem.totalCreatorAmount ?? 0);

    /**
     * Build default values with safety checks
     */
    const defaultCuts: {
      totalInCents: number;
      creatorTotalInCents: number;
      redCircleTotalInCents: number;
      newRedCircleCutInPercent: number;
      originalRedCircleCutInPercent: number;
      isPromotionActive: boolean;
      activePromotions: Promotion[];
      promotionsMaxDeductInCents: number;
    } = {
      totalInCents: typeof campaignItem?.totalBudget === "number" ? campaignItem.totalBudget : 0,
      creatorTotalInCents: totalCreatorAmount,
      redCircleTotalInCents:
        typeof campaignItem?.totalBudget === "number" &&
        typeof campaignItem?.totalCreatorAmount == "number"
          ? Math.max(campaignItem.totalBudget - campaignItem.totalCreatorAmount, 0)
          : 0,
      newRedCircleCutInPercent:
        typeof campaignItem?.advertisingCutBasisPoints == "number"
          ? campaignItem.advertisingCutBasisPoints / 100
          : 0,
      originalRedCircleCutInPercent:
        typeof campaignItem?.advertisingCutBasisPoints == "number"
          ? campaignItem.advertisingCutBasisPoints / 100
          : 0,
      isPromotionActive: false,
      activePromotions: [],
      promotionsMaxDeductInCents: 0,
    };

    /**
     * No promotions
     */
    if (activePromotions.length === 0) {
      return defaultCuts;
    }

    /**
     * This logic will need to be updated when we add more promotions and need to take into account Order/ Priority / Percentages / etc,
     * Will be updated when we cross that bridge since currently only 1 type of fixed promotion exists
     */
    const newCuts = activePromotions.reduce(
      (currentCuts, promotion) => {
        if (promotion) {
          if (promotion.type === "commissionFixedDiscount") {
            const newRedCircleTotalInCents =
              currentCuts.redCircleTotalInCents - promotion.amountCents;

            /**
             * RedCircle cut is positive
             */
            if (newRedCircleTotalInCents > 0) {
              return {
                ...currentCuts,
                isPromotionActive: true,
                activePromotions,
                creatorTotalInCents: currentCuts.totalInCents - newRedCircleTotalInCents,
                redCircleTotalInCents: newRedCircleTotalInCents,
                newRedCircleCutInPercent: Number.parseFloat(
                  ((newRedCircleTotalInCents / currentCuts.totalInCents) * 100).toFixed(2)
                ),
                promotionsMaxDeductInCents: promotion.amountCents,
              };
            } else {
              /**
               * RedCircle cut is 0 or negative
               */
              return {
                ...currentCuts,
                isPromotionActive: true,
                activePromotions,
                creatorTotalInCents: currentCuts.totalInCents,
                redCircleTotalInCents: 0,
                newRedCircleCutInPercent: 0,
                promotionsMaxDeductInCents: promotion.amountCents,
              };
            }
          }
        }

        return currentCuts;
      },
      { ...defaultCuts }
    );

    return newCuts;
  } catch (err) {
    store.dispatch(
      showWarning(
        "Could not calculate campaign item cuts. Please contact RedCircle support for more information."
      )
    );
    return {
      totalInCents: 0,
      creatorTotalInCents: 0,
      redCircleTotalInCents: 0,
      newRedCircleCutInPercent: 0,
      originalRedCircleCutInPercent: 0,
      isPromotionActive: false,
      activePromotions: [],
      promotionsMaxDeductInCents: 0,
    };
  }
};
