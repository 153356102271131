import { useEffect, useRef, useState } from "react";
import { getUserImportJobs } from "src/actions/user";
import { getMyCards } from "src/action_managers/subscriptions";
import { importJobIsUnfinished } from "src/lib/import_jobs_helper";
import { IImportJob } from "redcircle-types";
import { useDispatchTS, useSelectorTS } from "./redux-ts";

const POLL_INTERVAL_MS = 5000;

export const useGetImportJobs = () => {
  const dispatch = useDispatchTS();
  const { user, isLoading, importJobs } = useSelectorTS((state) => state.user);
  const { shows } = useSelectorTS((state) => state.shows);
  const poll = useRef<NodeJS.Timer>();

  const hasUnfinishedImportJobs = () => {
    if (!importJobs || !shows) return false;
    return (
      importJobs.length &&
      importJobs.some(
        (job: IImportJob) => shows[job.showUUID] && importJobIsUnfinished(job, shows[job.showUUID])
      )
    );
  };

  useEffect(() => {
    dispatch(getUserImportJobs(user.uuid));
  }, []);

  // poll if we have unfinished jobs
  useEffect(() => {
    if (hasUnfinishedImportJobs()) {
      if (!poll.current) {
        poll.current = setInterval(() => {
          dispatch(getUserImportJobs(user.uuid));
        }, POLL_INTERVAL_MS);
      }
    } else {
      if (poll.current) {
        clearInterval(poll.current);
      }
    }
  }, [importJobs, shows]);

  return { importJobs, isLoading };
};

export const useGetUserCards = () => {
  const dispatch = useDispatchTS();
  const payments = useSelectorTS((state) => state?.payments);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getMyCards()).then(() => {
      setIsLoading(false);
    });
  }, []);

  return { isLoading, cards: payments?.cards };
};
