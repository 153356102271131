import { isPossiblePhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import isEmpty from "lodash/isEmpty";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { If } from "react-extras";
import Spinner from "../../lib/spinner";
import UserForm from "../sign_up/user_form";
import "./account_page.scss";

class ProfileSettings extends Component {
  componentDidMount() {
    this.props.updateUserClear(); // clears success message.
    if (isEmpty(this.props.user)) {
      this.props.fetchUser(this.props.user.uuid, this.props.user.authToken);
    }
  }

  logOut = () => {
    if (this.props && this.props.user) {
      this.props.logOut(this.props.user.authToken);
    }
  };

  getInitialValues = () => {
    const { phoneNumber, phoneNumberCountryCode } = this.props?.user;
    /** Phone Input component prefers phone numbers in E 164 format in order to properly set the country code on startup  */
    if (
      phoneNumber &&
      phoneNumberCountryCode &&
      isPossiblePhoneNumber(phoneNumber, phoneNumberCountryCode)
    ) {
      return {
        ...this.props?.user,
        phoneNumber: parsePhoneNumber(phoneNumber, phoneNumberCountryCode)?.number || phoneNumber,
      };
    }
    return { ...this.props?.user };
  };

  render() {
    return (
      <Grid fluid>
        <Spinner isLoading={this.props.isLoading || this.props.isLoadingForm} />
        <If condition={!this.props.isLoading}>
          <Row>
            <Col xs={12}>
              <h3>Account Holder</h3>
              <p>This is the primary account holder information.</p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <UserForm
                onSubmit={(formData) => {
                  var newData = Object.assign({}, formData);
                  this.props.updateUser(newData, this.props.user);
                }}
                user={this.props.user}
                initialValues={this.getInitialValues()}
                isLoading={this.props.isLoadingForm || this.props.isLoadingVerification}
                hasError={this.props.hasError}
                errorMessage={this.props.errorMessage}
                validationErrors={this.props.validationErrors}
                sendVerificationEmail={this.props.sendVerificationEmail}
                password={this.props.password}
                logOut={this.logOut}
                phoneInputRef={this.props.phoneInputRef}
                mode="edit"
              />
            </Col>
          </Row>
        </If>
      </Grid>
    );
  }
}

export default ProfileSettings;
