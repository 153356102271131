import React, { Component } from "react";
import { Field } from "redux-form";
import FormElement from "../forms/form_element";

class PasswordField extends Component {
  getStrength = () => {
    if (!this.props.password) {
      return "Empty";
    }

    var hasLower = this.props.password.toUpperCase() !== this.props.password;
    var hasUpper = this.props.password.toLowerCase() !== this.props.password;
    var hasNumber = /\d/.test(this.props.password);
    var longEnough = this.props.password.length > 7;

    var score = [hasLower, hasUpper, hasNumber, longEnough].reduce((a, b) => a + b, 0);

    switch (score) {
      case 1:
        return "Poor";
      case 2:
        return "Weak";
      case 3:
        return "Almost There...";
      case 4:
        return "Strong";
      default:
        return "Empty";
    }
  };

  render() {
    return (
      <Field
        component={FormElement}
        name={this.props.name || "password"}
        label="Password"
        type="password"
        helpText={"Strength: " + this.getStrength()}
        validationErrors={this.props.validationErrors}
      />
    );
  }
}

export default PasswordField;
