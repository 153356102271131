import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { hideModal } from "../../actions/modal";
import { deleteEpisode } from "../../action_managers/episode_delete";
import EpisodeDeleteModal from "./episode_delete_modal";

function mapStateToProps(state) {
  return {
    user: state.user.user,
    isLoading: state.episodeDelete.isLoading,
    errorMessage: state.episodeDelete.errorMessage,
    isSuccess: state.episodeDelete.isSuccess,
  };
}

const mapDispatchToProps = {
  closeModal: hideModal,
  deleteEpisode: (episodeUUID, showUUID) => deleteEpisode(episodeUUID, showUUID),
};

const EpisodeDeleteModalWrapper = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EpisodeDeleteModal)
);

export default EpisodeDeleteModalWrapper;
