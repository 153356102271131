import { AnyAction } from "redux";
import { tierLevel } from "../constants/tiers";
import { IRootState } from "../reducers/types";
import { defaultActionManagerRunner } from "./default_action_manager";
export const FETCH_TIER_FOR_ORG = "FETCH_TIER_FOR_ORG";
export const UPDATE_TIER = "UPDATE_TIER";
export const GET_PAYMENT_FOR_TIER = "GET_PAYMENT_FOR_TIER";
export const GODMODE_UPDATE_TIER_LEVEL = "GODMODE_UPDATE_TIER_LEVEL";

export const fetchTierForOrg = () =>
  defaultActionManagerRunner({
    route: (state: IRootState) => `users/${state.user?.user.uuid}/tier`,
    auth: true,
    actionName: FETCH_TIER_FOR_ORG,
  });

export const updateTierAction = (body: {
  level: string;
  stripeCardId?: string;
  interval?: string;
}) =>
  defaultActionManagerRunner({
    route: (state: IRootState) => `tiers/${state.user?.tier?.uuid}`,
    auth: true,
    body,
    actionName: UPDATE_TIER,
    method: "put",
  });

export const getPaymentsForTier = () =>
  defaultActionManagerRunner({
    route: (state: IRootState) => `tiers/${state.user?.tier?.uuid}/payment-info`,
    auth: true,
    actionName: GET_PAYMENT_FOR_TIER,
  });

export const godmodeUpdateTierLevel = (level: tierLevel) => {
  return {
    type: GODMODE_UPDATE_TIER_LEVEL,
    payload: level,
  } as AnyAction;
};
