import { Row } from "antd";
import { useEffect } from "react";
import { classNames } from "react-extras";
import { useParams } from "react-router-dom";
import { showSuccess } from "src/actions/app";
import Breadcrumbs from "src/components/lib/breadcrumbs";
import RCButton from "src/components/lib/button/button";
import ClipboardAction from "src/components/lib/clipboard-action";
import ExternalLink from "src/components/lib/external_link/external_link";
import Loading from "src/components/lib/loading";
import Page from "src/components/lib/page";
import { useEpisode } from "src/hooks/episodes";
import { useDispatchTS } from "src/hooks/redux-ts";
import { useShow } from "src/hooks/shows";
import {
  getEmbeddableDivClassName,
  getEmbeddableHTML,
  getEmbedSrc,
} from "src/lib/copy_embedded_player";
import ShowPageWrapper from "../shows/show_page_wrapper";
import styles from "./episode_embed_page.module.scss";

export default function EpisodeEmbedPage() {
  const dispatch = useDispatchTS();
  const { showUUID, episodeUUID } = useParams<{ showUUID: string; episodeUUID: string }>();
  const { show, isLoading: isShowLoading } = useShow({ showUUID });
  const { episode, isLoading: isEpisodeLoading } = useEpisode({ episodeUUID });
  const isLoading = isShowLoading || isEpisodeLoading;

  useEffect(() => {
    const container = document.getElementById("script-container");
    if (show && episode && container) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.onload = () => {
        (window as any).redcircleIframe();
      };
      container.appendChild(script);
      script.src = getEmbedSrc(showUUID, episodeUUID);
    }
  }, [show, episode]);

  return (
    <ShowPageWrapper show={show} episode={episode} isLoading={isShowLoading || isEpisodeLoading}>
      <Breadcrumbs
        crumbs={[
          { path: `/shows`, name: "All Podcasts" },
          { path: `/shows/${showUUID}`, name: show?.title },
          { path: `/shows/${showUUID}/ep`, name: "Episodes" },
          { path: `/shows/${showUUID}/ep/${episodeUUID}`, name: episode?.title },
          { path: `/shows/${showUUID}/ep/${episodeUUID}/embed`, name: "Embed", active: true },
        ]}
      />
      {isLoading && <Loading />}

      {show && episode && (
        <Row>
          <ShowPageWrapper.Sidebar />
          <ShowPageWrapper.Body>
            <ShowPageWrapper.Header title={"Embed This Episode"} />
            <Page.Section className="m-bs">
              This is code to embed a player for this episode on your website or blog. This will let
              you contact your listeners and provide them with updates.{" "}
              <ExternalLink href="https://support.redcircle.com/embedding-your-podcast-on-another-website">
                <strong>Learn More</strong>
              </ExternalLink>
            </Page.Section>
            <Page.Section className="flex-column-container align-center">
              <RCButton
                size="large"
                type="primary"
                className="m-bxs"
                onClick={() => {
                  new ClipboardAction({
                    text: getEmbeddableHTML(showUUID, episodeUUID),
                    container: document.body,
                  });
                  dispatch(showSuccess("Copied HTML to clipboard"));
                }}>
                Copy HTML to Clipboard
              </RCButton>
              <div className="flex-column-container width-100">
                <div id="script-container" />
                <div className="redcircle-form-label">PREVIEW</div>
                <div
                  className={classNames(
                    `embed-container ${getEmbeddableDivClassName(episodeUUID)}`,
                    styles.embedContainer
                  )}
                />
              </div>

              <p className={"m-tm"}>
                Please see{" "}
                <ExternalLink
                  href={"https://support.redcircle.com/embedding-your-podcast-on-another-website"}>
                  this article
                </ExternalLink>{" "}
                for assistance with embedding the player and privacy information.
              </p>
            </Page.Section>
          </ShowPageWrapper.Body>
        </Row>
      )}
    </ShowPageWrapper>
  );
}
