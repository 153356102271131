/* global google */
import { countryCodesMap } from "src/data/countries";

const addressAttributes = {
  country: { format: "short_name", field: "Country" },
  locality: { format: "long_name", field: "Locality" },
  sublocality_level_1: { format: "long_name", field: "Locality" },
  postal_code: { format: "long_name", field: "PostCode" },
  administrative_area_level_1: { format: "short_name", field: "AdministrativeArea" },
  street_number: { format: "long_name", field: "StreetNumber" },
  route: { format: "short_name", field: "Route" },
};

export const placeToAddress = (place: google.maps.places.PlaceResult) => {
  if (!place.address_components) return;

  const addressAttributeKeys = Object.keys(addressAttributes);
  const newAddress: Record<string, string | string[]> = {};
  for (const component of place.address_components) {
    const key = component.types.find((type) => addressAttributeKeys.includes(type));
    if (!key) continue;
    const value = addressAttributes[key as keyof typeof addressAttributes];
    if (!value) continue;

    const field = value.field;
    const desiredFormat = component[value.format as keyof typeof component];
    newAddress[field] = desiredFormat;
  }

  if (newAddress.Route) {
    if (newAddress.StreetNumber) {
      newAddress.StreetAddress = [newAddress.StreetNumber + " " + newAddress.Route];
    } else {
      newAddress.StreetAddress = [newAddress.Route as string];
    }
    delete newAddress.StreetNumber;
    delete newAddress.Route;
  }
  return newAddress;
};

export const addressToString = (
  address?: Record<string, string | string[]>,
  includeLine2?: string
): string => {
  if (!address) return "";

  return ["StreetAddress", "Locality", "AdministrativeArea", "PostCode", "Country"].reduce(
    (agg, field) => {
      if (!address[field]) return agg;
      let value = address[field];
      if (field === "Country") value = countryCodesMap[value as keyof typeof countryCodesMap];
      if (field === "StreetAddress")
        return agg + (includeLine2 ? (value as string[]).join(", ") : value[0]);
      if (!agg) return value;
      if (field === "PostCode") return agg + " " + value;
      return (agg + ", " + value) as any;
    },
    ""
  );
};
