import { Alert, Tooltip } from "antd";
import { useContext, useState } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { Button } from "redcircle-ui";
import { showError } from "src/actions/app";
import { editCampaign, getCampaign } from "src/action_managers/campaigns";
import RadioSelectFrame from "src/components/lib/radio-select-frame/radio-select-frame";
import {
  CampaignInvoicingMonthTypeBroadcast,
  CampaignInvoicingMonthTypeCalendar,
  CampaignInvoicingStyleFull,
  CampaignInvoicingStyleMonthly,
} from "src/constants/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

export default function SchedulerPaymentTimelinePage() {
  const {
    campaign,
    timeline,
    invoicingStyle,
    setInvoicingStyle,
    invoicingMonthType,
    setInvoicingMonthType,
  } = useContext(CampaignSchedulerContext);

  const { user } = useSelectorTS((state) => state.user);

  const showLengthWarning =
    campaign?.pacing &&
    invoicingStyle === CampaignInvoicingStyleFull &&
    timeline[0].diff(timeline[1], "days") < 40;

  return (
    <div>
      {showLengthWarning && (
        <Alert description="Campaign length is less than 40 days." type="warning" />
      )}

      <RadioSelectFrame
        isSelected={invoicingStyle === CampaignInvoicingStyleFull}
        title={
          <div className="flex-row-container align-center">
            <span>Full Campaign</span>
            {!user.invoicingPaymentEnabled && (
              <Tooltip title="Invoicing is not enabled for this user">
                <AiFillInfoCircle className="m-lxxs" />
              </Tooltip>
            )}
          </div>
        }
        onClick={() =>
          user.invoicingPaymentEnabled && setInvoicingStyle(CampaignInvoicingStyleFull)
        }
        description="A bill is generated once at the end of the campaign."
        disabled={!user.invoicingPaymentEnabled}
      />
      <RadioSelectFrame
        isSelected={
          invoicingStyle === CampaignInvoicingStyleMonthly &&
          invoicingMonthType === CampaignInvoicingMonthTypeCalendar
        }
        onClick={() => {
          setInvoicingStyle(CampaignInvoicingStyleMonthly);
          setInvoicingMonthType(CampaignInvoicingMonthTypeCalendar);
        }}
        title="Calendar Month"
        description="A bill is generated at the end of every calendar month during the campaign."
      />
      <RadioSelectFrame
        isSelected={
          invoicingStyle === CampaignInvoicingStyleMonthly &&
          invoicingMonthType === CampaignInvoicingMonthTypeBroadcast
        }
        onClick={() => {
          setInvoicingStyle(CampaignInvoicingStyleMonthly);
          setInvoicingMonthType(CampaignInvoicingMonthTypeBroadcast);
        }}
        title="Broadcast Month"
        description="A bill is generated at the end of every broadcast month during the campaign."
      />
    </div>
  );
}

export const SchedulerPaymentTimelineFooter = ({
  onBack,
  onSubmit,
}: {
  onBack: () => void;
  onSubmit: () => void;
}) => {
  const dispatch = useDispatchTS();
  const { campaign, invoicingStyle, invoicingMonthType } = useContext(CampaignSchedulerContext);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    if (!campaign) return;
    if (
      invoicingStyle === campaign.invoicingStyle &&
      invoicingMonthType === campaign.invoicingMonthType
    ) {
      onSubmit();
      return;
    }

    setIsSubmitLoading(true);
    const res = await dispatch(
      editCampaign({ uuid: campaign.uuid, invoicingMonthType, invoicingStyle, isV2: campaign.isV2 })
    );
    if (res && res.status === 200) {
      await dispatch(getCampaign(campaign.uuid));
      if (onSubmit) onSubmit();
    } else {
      dispatch(showError("Failed to save payment timeline options"));
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
      <Button type="link" size="large" className="p-a0" onClick={onBack}>
        Back
      </Button>
      <Button type="primary" size="large" onClick={handleSubmit} loading={isSubmitLoading}>
        Next
      </Button>
    </>
  );
};
