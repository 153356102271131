import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { If } from "react-extras";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { hideModal } from "../../actions/modal";
import { clearDealValidationErrors, createDeal } from "../../action_managers/deals";
import { permissionTypes } from "../../constants/permission_roles";
import { withPermissions } from "../../lib/permissions-js";
import { isShowEligibleForCrossPromo } from "../../lib/show";
import FormElement from "../forms/form_element";
import LoadingButton from "../forms/loading_button/loading_button";
import RCButton from "../lib/button/button";
import Divider from "../lib/divider";
import BaseModal from "./base_modal";
import ConfirmationModal from "./confirmation_modal";
import "./monetization_intro_modal/promotions-intro-modal.scss";

const confirmationMessage = `Great job, your proposal has been sent. 
  You can continue to add more details to your proposal by clicking the View Proposal button.
  Otherwise, click Cancel to continue browsing.`;

class DealCreateModal extends Component {
  state = {
    message: null,
    showConfirmationModal: false,
    dealUUID: null,
    myShowUUID: undefined,
  };

  componentDidMount() {
    this.props.clearDealValidationErrors();
    if (!isEmpty(this.props.shows)) {
      this.setState({ myShowUUID: this.getEligibleShows()?.[0]?.uuid });
    }
  }

  createDeal = () => {
    this.props
      .createDeal(
        this.props.recipientUUID,
        this.state.message,
        this.props.showUUID,
        this.state.myShowUUID
      )
      .then((response) => {
        if (response.status === 200) {
          const dealUUID = get(response, "json.uuid");
          this.setState({ showConfirmationModal: true, dealUUID: dealUUID });
        }
      });
  };

  getEligibleShows = () => {
    return Object.values(this.props.shows)
      .filter(isShowEligibleForCrossPromo)
      .filter((show) => this.props.canAccess(permissionTypes.editCrossPromo, show.uuid))
      .filter((show) => !show.isRemote);
  };

  modalContent = () => {
    return (
      <Grid fluid>
        <Row>
          <Col xs={12} xsOffset={0} md={10} mdOffset={1}>
            <div className="deal-create-modal__intro">
              Introduce yourself, say a little something about your podcast, and why you think it’d
              be a good idea to run a cross promotion together.
            </div>
            <Divider />
            <FormElement
              onChange={(e) => this.setState({ myShowUUID: e.target.value })}
              value={this.state.myShowUUID}
              name="myShowUUID"
              label="My Podcast"
              type="select"
              componentClass="select"
              placeholder="Select a podcast"
              validationErrors={this.props.validationErrors}>
              {this.getEligibleShows().map((show) => (
                <option value={show.uuid} key={show.uuid}>
                  {show.title}
                </option>
              ))}
            </FormElement>
            <FormElement
              className="deal-create-modal__message"
              name="message"
              validationErrorKeyOverride="messageText"
              label="Message"
              type="textarea"
              rows="5"
              onChange={(e) => {
                this.setState({ message: e.target.value });
              }}
              componentClass="textarea"
              validationErrors={this.props.validationErrors}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={10} xsOffset={1}>
            <Row className="m-bs">
              <Col xs={6}>
                <RCButton
                  type="secondary"
                  size="large"
                  className="width-100"
                  onClick={this.props.hideModal}>
                  Cancel
                </RCButton>
              </Col>
              <Col xs={6}>
                <LoadingButton
                  disabled={!this.state.myShowUUID || !this.state.message}
                  className="width-100"
                  isLoading={this.props.isLoading}
                  onClick={this.createDeal}>
                  Send <span className="hidden-xs m-lxxxs">Message</span>
                </LoadingButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    );
  };

  closeModal = () => {
    this.setState({ showConfirmationModal: false }, this.props.hideModal);
  };

  gotoDealPage = (dealUUID) => {
    this.props.history.push(`/promotions/deals/${dealUUID}/`);
  };

  render() {
    return (
      <>
        <If condition={!this.state.showConfirmationModal}>
          <BaseModal
            isLoading={false}
            isMini={true}
            columns={10}
            offset={1}
            title={"Cross Promotion Proposal"}
            close={this.props.hideModal}
            hideCloseButton={false}
            showContent={true}
            Content={this.modalContent}
          />
        </If>
        <If condition={this.state.showConfirmationModal}>
          <ConfirmationModal
            closeModal={this.closeModal}
            callback={this.gotoDealPage.bind(this, this.state.dealUUID)}
            actionButtonName="View Proposal"
            confirmationMessage={confirmationMessage}
            modalTitle="Proposal Sent!"
          />
        </If>
      </>
    );
  }
}

export default withRouter(
  connect(
    ({ user, deals, shows }) => ({
      user,
      validationErrors: get(deals, "validationErrors", []),
      isLoading: get(deals, "isLoading"),
      shows: shows.shows,
    }),
    {
      hideModal,
      createDeal,
      clearDealValidationErrors,
    }
  )(withPermissions(DealCreateModal))
);
