import React from "react";
import { classNames } from "react-extras";
import { Link } from "react-router-dom";
import { ReactComponent as BackChevron } from "src/icons/back-chevron.svg";
import { ReactComponent as ForwardChevron } from "src/icons/forward-chevron.svg";
import styles from "./breadcrumbs.module.scss";

interface IProps {
  className?: string;
  crumbs: Crumb[];
}

type Crumb = {
  path: string;
  name?: string;
  active?: boolean;
};

export default function Breadcrumbs({ className, crumbs }: IProps) {
  if (!crumbs.length) return null;
  return (
    <nav className={classNames(styles.breadcrumbs, className)}>
      <ol>
        {crumbs.map((crumb, i) => (
          <React.Fragment key={crumb.path}>
            {crumbs.length === 1 && <BackChevron className={styles.icon} />}
            <li className={!crumb.active ? styles.inactive : styles.active}>
              <Link to={crumb.path} title={crumb.name}>
                {crumb.name || "..."}
              </Link>
            </li>
            {i < crumbs.length - 1 && <ForwardChevron className={styles.icon} />}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
}
