export const capitalize = (word: string) => {
  if (typeof word === "string" && word.length > 1)
    return word.charAt(0).toUpperCase() + word.slice(1);

  return typeof word === "string" ? word.toUpperCase() : word;
};

export const pluralize = (count: number, word: string) => {
  if (count === 1) return word;
  return word + "s";
};

export const convertHTMLLinksToNewWindow = (html: string) => {
  // DOMParser should be safe from XSS attacks
  if (typeof window != "undefined") {
    const parser = new DOMParser();
    const content = parser.parseFromString(html, "text/html");
    const anchors = content.getElementsByTagName("a");
    Array.from(anchors).forEach((a) => {
      a.setAttribute("target", "_blank");
    });
    return content.body.innerHTML;
  }

  return html;
};

export const copyToClipboard = (text: string) => {
  if (typeof window != "undefined") {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
};
