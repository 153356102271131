import Cookies from "js-cookie";
import isEmpty from "lodash/isEmpty";
import { RECAPTCHA_SITE_TOKEN } from "../constants/recaptcha";
import { utmCookieName } from "../lib/user_store";
import DefaultActionManager from "./default_action_manager";

export const ADVERTISER_SIGN_UP = "ADVERTISER_SIGN_UP";

class AdvertiserSignUpActionManager extends DefaultActionManager {
  execute(dispatch, getState) {
    return new Promise((resolve, reject) => {
      return window.grecaptcha
        .execute(RECAPTCHA_SITE_TOKEN, { action: "advertiser_contacts_create" })
        .then((token) => {
          this.args.headers = { ...this.args.headers, Recaptcha: token };
          return super.execute(dispatch, getState).then(resolve).catch(reject);
        });
    });
  }
}

export const createAdvertiserContact = (contact) => {
  const utmParams = Cookies.get(utmCookieName);
  if (!isEmpty(utmParams)) {
    try {
      contact.utmParams = JSON.parse(utmParams);
    } finally {
      // hope we don't get here!
    }
  }

  return new AdvertiserSignUpActionManager({
    actionName: ADVERTISER_SIGN_UP,
    route: `advertiser-contacts`,
    auth: true,
    body: contact,
    method: "POST",
  }).run();
};
