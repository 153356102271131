import { AnyAction } from "redux";
import {
  AD_PLACEMENTS_MODAL_CLEAR,
  AD_PLACEMENTS_MODAL_DELETE_PLACEMENT,
  AD_PLACEMENTS_MODAL_SAVE_ERROR,
  AD_PLACEMENTS_MODAL_SAVE_START,
  AD_PLACEMENTS_MODAL_SAVE_SUCCESS,
  AD_PLACEMENTS_MODAL_SET_CURSOR,
  AD_PLACEMENTS_MODAL_SET_PLACEMENT,
} from "../../actions/ad_placements_modal";
import { AdPlacementModalReduxState } from "./types";

const initialState: AdPlacementModalReduxState = {
  isLoading: false,
  isSuccess: false,
  placements: [],
  cursor: 0,
};

export default function adPlacementsModal(state = initialState, action: AnyAction) {
  const newPlacements = [...state.placements];
  switch (action.type) {
    case AD_PLACEMENTS_MODAL_CLEAR:
      return Object.assign({}, initialState, {
        placements: [...action.episode.adPlacements],
      });
    case AD_PLACEMENTS_MODAL_SET_CURSOR:
      return Object.assign({}, state, {
        cursor: action.cursor,
      });
    case AD_PLACEMENTS_MODAL_SAVE_START:
      return Object.assign({}, state, {
        isLoading: true,
        isSuccess: false,
      });
    case AD_PLACEMENTS_MODAL_SAVE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
      });
    case AD_PLACEMENTS_MODAL_SAVE_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case AD_PLACEMENTS_MODAL_SET_PLACEMENT:
      return Object.assign({}, state, {
        placements: [...state.placements, action.placement],
      });
    case AD_PLACEMENTS_MODAL_DELETE_PLACEMENT:
      newPlacements.splice(action.index, 1);
      return Object.assign({}, state, {
        placements: newPlacements,
      });
    default:
      return state;
  }
}
