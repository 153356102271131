import { Image, ImageProps } from "antd";
import React from "react";
import missingImageURL from "../../../images/missing.png";
import Loading from "../loading";
import "./loading_image.scss";

type OtherIMageProps = Omit<
  ImageProps,
  "src" | "preview" | "placeholder" | "fallback" | "rootClassName" | "alt"
>;

// Configurable image tag
export type ImageTag = {
  text: string;
  color: string;
  bgColor: string;
};

type LoadingImageProps = {
  className?: string;
  isLoading?: boolean;
  alt?: string;
  tag?: ImageTag;
  defaultImage?: string;
} & ({ src: string } | { imageURL: string }) &
  OtherIMageProps;

const LoadingImage = ({
  className,
  isLoading,
  defaultImage,
  alt,
  tag,
  wrapperStyle,
  ...rest
}: LoadingImageProps) => {
  let imageSrc: string | undefined;
  let otherImageProps: OtherIMageProps = {};

  if ("src" in rest) {
    imageSrc = rest.src || defaultImage || missingImageURL;
    const { src: _, ...other } = rest;
    otherImageProps = other;
  } else if ("imageURL" in rest) {
    imageSrc = rest.imageURL || defaultImage || missingImageURL;
    const { imageURL: _, ...other } = rest;
    otherImageProps = other;
  } else {
    imageSrc = defaultImage || missingImageURL;

    otherImageProps = rest;
  }

  // unset imageSrc for Loader placeHolder to take precedent
  if (isLoading) imageSrc = undefined;

  const cssProperties: {
    ["--image-tag-color"]?: string;
    ["--image-tag-bgcolor"]?: string;
  } & React.CSSProperties = {
    ...wrapperStyle,
  };

  if (tag) {
    cssProperties["--image-tag-color"] = tag.color;
    cssProperties["--image-tag-bgcolor"] = tag.bgColor;
  }

  return (
    <Image
      data-testid="image"
      data-image-tag-text={tag?.text?.toUpperCase()}
      src={imageSrc}
      preview={false}
      placeholder={isLoading && <Loading className="height-100 align-center" />}
      wrapperStyle={cssProperties}
      rootClassName={`loading-image-container ${className}`}
      alt={alt}
      {...otherImageProps}
    />
  );
};

export default LoadingImage;
