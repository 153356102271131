import { httpReducer, reduceReducers } from "src/lib/create-action";
import { BrandsReduxState } from "./types";
import {
  BRAND_CREATE,
  BRAND_GET,
  BRAND_GET_BULK,
  BRAND_SEARCH,
  BRAND_UPDATE,
} from "src/action_managers/brands";
import { IBrand } from "redcircle-types";
import { cloneDeep } from "lodash";

const initialState: BrandsReduxState = {
  brands: [],
  brandsByUUID: {},
  brandsByInstanceUUID: {},
  isLoading: false,
  initialFetched: false,

  brandSearchByInstanceUUID: {},
};

/**
 * Helper functions to merge new fetched brands to current redux state
 */
const mergeBrands = (initialState: BrandsReduxState, newBrands: IBrand | IBrand[]) => {
  const newBrandsByInstanceUUID = {
    ...initialState.brandsByInstanceUUID,
  };
  const newBrandsArray = [...initialState.brands];

  const brands = Array.isArray(newBrands) ? newBrands : [newBrands];

  for (const brand of brands) {
    if (!newBrandsByInstanceUUID[brand.instanceUUID]) {
      newBrandsArray.push({ ...brand });
    }

    newBrandsByInstanceUUID[brand.instanceUUID] = {
      ...newBrandsByInstanceUUID[brand.instanceUUID],
      ...brand,
    };
  }

  const newBrandsByUUID = newBrandsArray.reduce(
    (accu, curr) => {
      if (!Array.isArray(accu?.[curr.brandUUID])) accu[curr.brandUUID] = [];

      accu[curr.brandUUID].push({ ...curr });
      return accu;
    },
    {} as BrandsReduxState["brandsByUUID"]
  );

  return {
    brands: newBrandsArray,
    brandsByUUID: newBrandsByUUID,
    brandsByInstanceUUID: newBrandsByInstanceUUID,
  };
};

const getBrand = httpReducer(BRAND_GET, initialState, {
  started: (state = initialState) => {
    return { ...state, isLoading: true };
  },
  success: (state = initialState, action) => {
    const brand = action.payload.resp as IBrand;

    const { brands, brandsByInstanceUUID, brandsByUUID } = mergeBrands(state, brand);

    return {
      ...state,
      brands,
      brandsByInstanceUUID,
      brandsByUUID,
      isLoading: false,
      initialFetched: true,
    };
  },
});

const getBrandsBulk = httpReducer(BRAND_GET_BULK, initialState, {
  success: (state = initialState, action) => {
    const newBrands = action.payload.resp as IBrand[];

    const { brands, brandsByInstanceUUID, brandsByUUID } = mergeBrands(state, newBrands);

    return {
      ...state,
      brands,
      brandsByInstanceUUID,
      brandsByUUID,
      isLoading: false,
      initialFetched: true,
    };
  },
});

const createBrand = httpReducer(BRAND_CREATE, initialState, {
  started: (state = initialState) => {
    return { ...state, isLoading: true };
  },
  success: (state = initialState, action) => {
    const brand = action.payload.resp as IBrand;
    const { brands, brandsByInstanceUUID, brandsByUUID } = mergeBrands(state, brand);

    return {
      ...state,
      brands,
      brandsByInstanceUUID,
      brandsByUUID,
      isLoading: false,
      initialFetched: true,
    };
  },
});

const updateBrand = httpReducer(BRAND_UPDATE, initialState, {
  started: (state = initialState) => {
    return { ...state, isLoading: true };
  },
  success: (state = initialState, action) => {
    const brand = action.payload.resp as IBrand;
    const { brands, brandsByInstanceUUID, brandsByUUID } = mergeBrands(state, brand);

    return {
      ...state,
      brands,
      brandsByInstanceUUID,
      brandsByUUID,
      isLoading: false,
      initialFetched: true,
    };
  },
});

const searchBrands = httpReducer(BRAND_SEARCH, initialState, {
  success: (state = initialState, action) => {
    const newBrands = action.payload.resp as IBrand[];

    const newBrandSearchByInstanceUUID = {
      ...state.brandSearchByInstanceUUID,
    };

    for (const brand of newBrands) {
      newBrandSearchByInstanceUUID[brand.instanceUUID] = {
        ...newBrandSearchByInstanceUUID[brand.instanceUUID],
        ...brand,
      };
    }

    return { ...state, brandSearchByInstanceUUID: newBrandSearchByInstanceUUID };
  },
});

export default reduceReducers(getBrand, getBrandsBulk, createBrand, updateBrand, searchBrands);
